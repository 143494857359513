import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	experts: [],
	expert: {}
};

const CLEAR_EXPERTS = "CLEAR_EXPERTS";
const GET_EXPERTS_REQUEST = "GET_EXPERTS_REQUEST";
const GET_EXPERTS_SUCCESS = "GET_EXPERTS_SUCCESS";
const GET_EXPERTS_FAILURE = "GET_EXPERTS_FAILURE";
const SEARCH_EXPERTS_REQUEST = "SEARCH_EXPERTS_REQUEST";
const SEARCH_EXPERTS_SUCCESS = "SEARCH_EXPERTS_SUCCESS";
const SEARCH_EXPERTS_FAILURE = "SEARCH_EXPERTS_FAILURE";
const CLEAR_EXPERT = "CLEAR_EXPERT";
const GET_EXPERT_REQUEST = "GET_EXPERT_REQUEST";
const GET_EXPERT_SUCCESS = "GET_EXPERT_SUCCESS";
const GET_EXPERT_FAILURE = "GET_EXPERT_FAILURE";
const SAVE_EXPERT_REQUEST = "SAVE_EXPERT_REQUEST";
const SAVE_EXPERT_SUCCESS = "SAVE_EXPERT_SUCCESS";
const SAVE_EXPERT_FAILURE = "SAVE_EXPERT_FAILURE";
const DELETE_EXPERT_REQUEST = "DELETE_EXPERT_REQUEST";
const DELETE_EXPERT_SUCCESS = "DELETE_EXPERT_SUCCESS";
const DELETE_EXPERT_FAILURE = "DELETE_EXPERT_FAILURE";

export const isLoading = createLoadingSelector(["GET_EXPERTS", "SEARCH_EXPERTS", "GET_EXPERT"]);

export const clearExperts = () => ({ type: CLEAR_EXPERTS });
export const clearExpert = () => ({ type: CLEAR_EXPERT });

export const getExperts = () =>
	createFetchAction({
		url: "/api/experts",
		startAction: GET_EXPERTS_REQUEST,
		onError: error => [getExpertsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getExpertsSuccess(data)
	});

export const getExpertsSuccess = data => ({ type: GET_EXPERTS_SUCCESS, payload: { data } });
export const getExpertsFailure = error => ({ type: GET_EXPERTS_FAILURE, payload: { error } });

export const searchExperts = args => 
	createFetchAction({
		url: `/api/experts/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_EXPERTS_REQUEST,
		onError: error => [searchExpertsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchExpertsSuccess(data)
	});

export const searchExpertsSuccess = data => ({ type: SEARCH_EXPERTS_SUCCESS, payload: { data } });
export const searchExpertsFailure = error => ({ type: SEARCH_EXPERTS_FAILURE, payload: { error } });

export const getExpert = expertId =>
	createFetchAction({
		url: `/api/experts/${expertId}`,
		startAction: GET_EXPERT_REQUEST,
		onError: error => [getExpertFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getExpertSuccess(data)
	});

export const getExpertSuccess = data => ({ type: GET_EXPERT_SUCCESS, payload: { data } });
export const getExpertFailure = error => ({ type: GET_EXPERT_FAILURE, payload: { error } });

export const saveExpert = (expert, imageFile) => {
	const formData = new FormData();
	formData.append("Expert", JSON.stringify(expert));
	
	if (imageFile) formData.append("ImageFile", imageFile);

	return createFormPostAction({
		url: "/api/experts",
		data: formData,
		startAction: SAVE_EXPERT_REQUEST,
		onError: error => [saveExpertFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveExpertSuccess(data), showSuccessNotification(data.message), changeLocation(`/experts/${data.object.expertId}`)];
			} else {
				return [saveExpertSuccess(data), showErrorNotification(data.message)];
			}
		}
	})
}

export const saveExpertSuccess = data => ({ type: SAVE_EXPERT_SUCCESS, data });
export const saveExpertFailure = error => ({ type: SAVE_EXPERT_FAILURE, error });

export const deleteExpert = expertId =>
	createPostAction({
		url: `/api/experts/${expertId}/delete`,
		startAction: DELETE_EXPERT_REQUEST,
		onError: error => [deleteExpertFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteExpertSuccess(data), showSuccessNotification(data.message), changeLocation("/experts")];
			} else {
				return [deleteExpertSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteExpertSuccess = data => ({ type: DELETE_EXPERT_SUCCESS, data });
export const deleteExpertFailure = error => ({ type: DELETE_EXPERT_FAILURE, error });

const formatExpert = expert => ({
	...expert,
	professionalQualifications: DOMPurify.sanitize(expert.professionalQualifications),
	executiveExperience: DOMPurify.sanitize(expert.executiveExperience)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_EXPERTS:
			return { ...state, experts: [] };
		case GET_EXPERTS_REQUEST:
			return { ...state, experts: [], isLoading: true };
		case SEARCH_EXPERTS_REQUEST:
			return { ...state, isLoading: true };
		case GET_EXPERTS_SUCCESS:
		case SEARCH_EXPERTS_SUCCESS:
			return { ...state, experts: map(action.payload.data, formatExpert), isLoading: false };
		case GET_EXPERT_REQUEST:
			return { ...state, expert: {}, isLoading: true }
		case GET_EXPERT_SUCCESS:
			return { ...state, expert: formatExpert(action.payload.data), isLoading: true };
		case SAVE_EXPERT_REQUEST:
		case DELETE_EXPERT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_EXPERT_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_EXPERT_SUCCESS:
			return { ...state, experts: state.experts.filter(a => a.expertId !== action.data.objectId) };
		default:
			return state;
	}
};