/* eslint no-mixed-operators: "off" */
/* eslint no-useless-escape: "off" */
/* eslint no-unused-expressions: "off" */
/* eslint no-sequences: "off" */
/* eslint eqeqeq: "off" */
/* eslint no-param-reassign: "off" */

import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import ExtensionIcon from '@mui/icons-material/Extension';
import BuildIcon from '@mui/icons-material/Build';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WorkIcon from '@mui/icons-material/Work';
import StarIcon from '@mui/icons-material/Star';
import BookIcon from '@mui/icons-material/Book';
import SearchIcon from '@mui/icons-material/Search';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import SchoolIcon from '@mui/icons-material/School';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import PublicIcon from '@mui/icons-material/Public';
import CategoryIcon from '@mui/icons-material/Category';
import BarChartIcon from '@mui/icons-material/BarChart';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ForumIcon from '@mui/icons-material/Forum';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import AssistantIcon from '@mui/icons-material/Assistant';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import GroupIcon from '@mui/icons-material/Group';
import findIndex from 'lodash/findIndex';
import DescriptionIcon from '@mui/icons-material/Description';

// Add or update array set with value at predicate
export const addOrUpdate = (arr, obj, predicate) => {
	if (!arr) return [obj];
	const index = findIndex(arr, predicate);
	return index === -1 ? arr.concat(obj) : arr.slice(0, index).concat(obj, arr.slice(index + 1))
};

// Programmatically shade a colour
// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export const shadeColour = (color, percent) => {   
    var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
};
export const pSBC=(p,c0,c1,l)=>{
    let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
    if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
    const _pSBCr=(d)=>{
        let n=d.length,x={};
        if(n>9){
            [r,g,b,a]=d=d.split(","),n=d.length;
            if(n<3||n>4)return null;
            x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
        }else{
            if(n==8||n==6||n<4)return null;
            if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
            d=i(d.slice(1),16);
            if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
            else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
        }return x};
    h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=_pSBCr(c0),P=p<0,t=c1&&c1!="c"?_pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
    if(!f||!t)return null;
    if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
    else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
    a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
    if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
    else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
};

// Decode and return query parameter with name from URL
// Todo: find a cleaner way to pass in location.search instead
export const getQueryParameterByName = (name, url) => {
	const _url = url ? url : window.location.href;
    const _name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + _name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(_url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// Convert URL to object containing query parameters
// Note: Can't use negative lookahead because of lack of support in Firefox, IE and Safari
// (?<!=)=(?!=)
export const getQueryParameters = url =>
	url ? JSON.parse('{"' + url.substring(1).replace(/==/g, "DUMMY").replace(/&/g, '","').replace(/=/g, '":"').replace(/DUMMY/g, "==") + '"}', (key, value) => key === "" ? value : decodeURIComponent(value)) : {};

// Convert value to date object
export const toDate = date => date ? new Date(date) : null;

// Convert value to number
export const toNumber = value => parseInt(value, 10) || null;

// Truncate a string and apply ellipsis if over maxLength
export const truncateString = (str, maxLength) => 
	str && str.length > maxLength ? str.substr(0, maxLength - 1) + '...' : str;

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

// Covert bytes to decimal
export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / (k ** i)).toFixed(decimals))} ${sizes[i]}`;
};

// Helper method to cleanly format date value
export const prettifyDate = date => 
	new Date(date).toLocaleDateString("en-AU", { day: "numeric", month: "long", year: "numeric" });

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
	try {
	  decimalCount = Math.abs(decimalCount);
	  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
	  const negativeSign = amount < 0 ? "-" : "";
  
	  let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString();
	  let j = (i.length > 3) ? i.length % 3 : 0;
  
	  return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	} catch (e) {
	  console.log(e)
	}
};
		
// Attempt to parse json from string
export const tryParseJson = jsonString => {
	try {
		return JSON.parse(jsonString);
	} catch (ex) {
		return null;
	}
}

// Move item in array from origin position to target position
export const moveItem = (arr, from, to) => arr.splice(to, 0, arr.splice(from, 1)[0]);

// Helper function to retrieve video ID from youtube link
export const parseYoutube = url => {
	const match = url.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/);
	return match && match[2].length === 11 ? match[2] : url;
}

// Strip HTML tags and replace special characters with spaces
export const stripHtml = text => unescape(text).replace(/<\/?[^>]+(>|$)/g, "").replace(/\\n|&nbsp;/g, " ");

// Get value of cookie by name
export const getCookieValue = name => {
	const allCookies = decodeURIComponent(document.cookie).split("; ");
	for (let i = 0; i < allCookies.length; i++) {
		const cookie = allCookies[i];
		if (cookie.startsWith(name + "=")) return cookie.substring(name.length + 1);
	}

	return "";
};

export const getInitials = text => {
	return text.split(" ").map((n)=>n[0]).join(".");
};

/**
 * wkhtmltopdf (which uses QtWeb) fail silently when the second Intl.NumberFormat is setup.
 * No idea why. As a work around just dynamically call them for wkhtmltopdf
 */
const isQtWeb = () => {
	const windowIfDefined = typeof window === 'undefined' ? null : window;
	if (!windowIfDefined) return false;
	return navigator.appVersion.indexOf('QtWeb') > -1 || navigator.appVersion.indexOf('wkhtmltopdf') > -1;
};

const currencyFormatter = isQtWeb() ? null : new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' });
export const toCurrency = (value) => {
	if (currencyFormatter === null) return (value === null || value === undefined) ? '' : new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value || 0);
	return (value === null || value === undefined) ? '' : currencyFormatter.format(value || 0);
};


// Helper object containing colour set for page sections
// Previous project colour: "#bb611b"
export const sectionColours = {
	problems: "#74bf44",
	offers: "#f0ad4e",
	events: "#bb1b75",
	centres: "#44a4bf",
	users: "#1b75bb",
	tools: "#6FCFBD",
	courses: "#4E3EBB",
	academics: "#2C4216",
	organisations: "#162A42",
	activities: "#8C882F",
	communities: "#A7D47D",
	themes: "#CB6FCF",
	ip: "#52381B"
};

// Helper object containing icon set for page sections
export const sectionIcons = {
	about: ViewModuleIcon,
	academics: SearchIcon,
	activities: LocalActivityIcon,
	admin: SettingsApplicationsIcon,
	careerPathway: SchoolIcon,
	centres: AccountBalanceIcon,
	offers: BuildIcon,
	courses: BookIcon,
	dashboard: WorkIcon,
	engagement: ThumbsUpDownIcon,
	departments: CategoryIcon,
	discussion: ForumIcon,
	events: EventIcon,
	features: StarIcon,
	help: HelpOutlineIcon,
	home: HomeIcon,
	innovation: NaturePeopleIcon,
	ip: WorkIcon,
	library: LibraryBooksIcon,
	learningDesign: DonutSmallIcon,
	mentoring: PublicIcon,
	mentors: PublicIcon,
	myCommunity: SupervisedUserCircleIcon,
	organisations: LocationCityIcon,
	problems: ExtensionIcon,
	reports: BarChartIcon,
	resources: PermMediaIcon,
	skills: CheckCircleIcon,
	tools: SchoolIcon,
	users: PeopleIcon
};

export const menuIcons = {
	AccountBalance: AccountBalanceIcon,
	Assignment: AssignmentIcon,
	Assistant: AssistantIcon,
	BarChart: BarChartIcon,
	Build: BuildIcon,
	CheckCircle: CheckCircleIcon,
	Description: DescriptionIcon,
	DonutSmall: DonutSmallIcon,
	Event: EventIcon,
	Extension: ExtensionIcon,
	Forum: ForumIcon,
    Group: GroupIcon,
	Home: HomeIcon,
	LibraryBooks: LibraryBooksIcon,
	LocalActivity: LocalActivityIcon,
	NaturePeople: NaturePeopleIcon,
	PermMedia: PermMediaIcon,
	PersonOutline: PersonOutlineIcon,
	Public: PublicIcon,
	School: SchoolIcon,
	Search: SearchIcon,
	SettingsApplicationsIcon: SettingsApplicationsIcon,
	SupervisedUserCircle: SupervisedUserCircleIcon,
	Work: WorkIcon
};
