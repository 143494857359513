import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearCollaboration, getCollaboration, getEnquiries, getCollaborationEnquiries, isLoading } from '../../store/collaborations';
import { getQueryParameters, stripHtml, truncateString } from '../../utils/utils';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import SearchField from '../widgets/SearchField';
import Card from '../widgets/Card';
import PageLayout from '../PageLayout';
import { canViewUsers } from '../../store/security';

const CollaborationEnquiriesController = props => {
	const [search, setSearch] = React.useState("");
	const [collaborationTitles, setCollaborationTitles] = React.useState([]);
	const { collaboration, enquiries } = props,
		hasCollaboration = Boolean(collaboration.collaborationId);

	React.useEffect(() => {
		const { collaborationId } = getQueryParameters(props.location.search);

		props.getCollaboration(collaborationId);
		props.getEnquiries(collaborationId);
	}, [props.location.search]);

	// Store unique collaboration titles to save calculating on each cycle
	React.useEffect(() => {
		if (enquiries.length > 0) {
			setCollaborationTitles(enquiries.reduce((acc, e) => acc.includes(e.collaborationTitle) ? acc : [...acc, e.collaborationTitle], []));
		}
	}, [enquiries.length]);

	return (
		<PageLayout title="Collaboration Enquiries" pageContent={
			<Card title={`Search Enquiries${hasCollaboration ? `: ${collaboration.title}` : ""}`}>
				<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
				{/* Datatable */}
				<DataTable data={enquiries} search={search} canViewUsers={props.canViewUsers} enableExport>
					<DataTableColumn name="fullName" label="User" dataType="avatar" />
					{!hasCollaboration &&
						<DataTableColumn 
							name="collaborationTitle" 
							label="Collaboration"
							canFilter 
							filterType="AutoComplete"
							items={collaborationTitles}
							renderer={c => c.collaborationExists ? <Link to={`/collaborations/${c.collaborationId}`}>{c.collaborationTitle}</Link> : c.collaborationTitle} 
						/>
					}
					<DataTableColumn name="content" label="Content" renderer={e => truncateString(stripHtml(e.content), hasCollaboration ? 100 : 50)} />
					<DataTableColumn name="submissionDate" label="Submission Date" dataType="date" />
					<DataTableColumn name="status" label="Status" />
				</DataTable>
			</Card>
		} breadcrumbs={[{ label: "Collaborations", path: "/collaborations" }, "Collaboration Enquiries"]} />
	);
};

CollaborationEnquiriesController.propTypes = { 
	collaboration: PropTypes.object.isRequired,
	enquiries: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canViewUsers: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	collaboration: state.collaborations.collaboration,
	enquiries: state.collaborations.enquiries,
	loading: isLoading(state),
	canViewUsers: canViewUsers(state)
});

const mapDispatchToProps = dispatch => ({
	getCollaboration: collaborationId => dispatch(collaborationId ? getCollaboration(collaborationId) : clearCollaboration()),
	getEnquiries: collaborationId => dispatch(collaborationId ? getCollaborationEnquiries(collaborationId) : getEnquiries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationEnquiriesController);