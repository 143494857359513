import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ReactQuill from 'react-quill';
import Button from '../widgets/Button';
import map from 'lodash/map';

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

const TermsDialog = props =>
	<Dialog
		open={props.open}
		onClose={props.onClose}
		aria-labelledby="dialog-title"
		aria-describedby="dialog-description"
		maxWidth="md"
		scroll="body"
	>
		<DialogTitle id="dialog-title">Terms &amp; Conditions</DialogTitle>
		<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
			<ReactQuill className="terms-dialog" value={props.content} modules={{ toolbar: false }} readOnly />
		</DialogContent>
		<DialogActions>
			{map(props.showAgreeButton ? ["Disagree", "Agree"] : ["Close"], (option, i) =>
				<Button key={i} onClick={() => props[`on${option}`]()} variant="text">
					{option}
				</Button>
			)}
		</DialogActions>
	</Dialog>;

TermsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	showAgreeButton: PropTypes.bool,
	onAgree: PropTypes.func,
	onDisagree: PropTypes.func,
	content: PropTypes.string.isRequired
};

TermsDialog.defaultProps = {
	showAgreeButton: false,
	onAgree: () => {},
	onDisagree: () => {}
};

export default connect(state => ({
	content: state.settings.termsDialogContent || ""
}), null)(withMobileDialog()(TermsDialog));
