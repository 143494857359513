import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from './Button';
import Checkbox from './Checkbox';
import { toggleUserTour } from '../../store/layout';
import { updatePersistedState } from '../../store/persistedState';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	paper: {
	  height: '100%'
	}
});

const UserTour = props => {
	const classes = props.classes;
	const [doNotShowAgain, setDoNotShowAgain] = React.useState(false);

	const onClose = () => {
		if (doNotShowAgain) {
			props.hideAndDoNotShowAgain();
		} else {
			props.hideUserTour();
		}
	};

	
	return (
		<Dialog fullWidth={true} maxWidth='xl' open={props.show && !props.neverShow} onClose={onClose} style={{ zIndex: 9999 }} classes={classes}>
			<DialogTitle>User Tour</DialogTitle>
			<DialogContent style={{ padding: 0, height: '100%' }}>
			{/* <div className="embed-container" style={{ position: 'relative', width: '100%', height: 0 }}> */}
				<embed
					  src={`${props.userTour || "/uploads/SkillsLogic User Tour.pdf"}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
					  style={{
						 
						  width: '100%',
						  height: 'calc(100% - 7px)'
					  }}
				/>
{/* </div> */}
			</DialogContent>
			<DialogActions>
				<Checkbox fullWidth={false} label="Do not show again" checked={doNotShowAgain} onChange={e => setDoNotShowAgain(e.target.checked)} />
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

UserTour.propTypes = {
	show: PropTypes.bool,
	neverShow: PropTypes.bool,
	userTour: PropTypes.string
};

UserTour.defaultProps = {
	neverShow: false,
	show: false,
	userTour: ""
};

const mapStateToProps = state => ({
	show: state.layout.showUserTour,
	neverShow: state.persistedState.doNotShowUserTour,
	userTour: state.context.userTour
});

const mapDispatchToProps = dispatch => ({
	hideUserTour: () => dispatch(toggleUserTour(false)),
	hideAndDoNotShowAgain: () => {
		dispatch(toggleUserTour(false));
		dispatch(updatePersistedState('doNotShowUserTour', true));
	}
});

export default withStyles(
	connect(mapStateToProps, mapDispatchToProps)(UserTour),
	styles
	);
