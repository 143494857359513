import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// import EmailIcon from '@mui/icons-material/Email';
// import PhoneIcon from '@mui/icons-material/Phone';
import { prettifyDate } from '../../utils/utils';
import AttachmentList from '../widgets/AttachmentList';
import ButtonLink from '../widgets/ButtonLink';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';

const ViewStudentProject = props => {
	const { project, primaryColour, secondaryColour } = props;

	return (
		<Card className="student-project-details">
			<span className="h3">
				{project.title}
				{props.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
			</span>
			<div dangerouslySetInnerHTML={{ __html: project.details }} />
			{project.image && <img src={project.image} alt={project.title} />}
			{project.participants.length > 0 &&
				<section>
					<span className="h4">Participants</span>
					<ul>
						{project.participants.map((p, i) =>
							<li key={i} style={{ marginBottom: 5 }}>
								<Link to={`/students/${p.studentId}`} style={{ color: primaryColour }}>
									<Avatar src={p.avatar} fullName={p.name} style={{ marginRight: 5 }} />
									<span>{p.name}</span>
								</Link> &mdash; {p.institutionName}, <span style={{ fontStyle: "italic" }}>{p.currentProgram}</span>
							</li>
						)}
					</ul>
				</section>
			}
			<section>
				<span className="h4">Details</span>
				{project.location &&
					<div style={{ marginBottom: 10 }}>{project.location}, {prettifyDate(project.startDate)} ({project.duration})</div>
				}
			</section>
			<section>
				<span className="h4">Objectives</span>
				<div dangerouslySetInnerHTML={{ __html: project.objectives }} />
			</section>
			<section>
				<span className="h4">Requirements</span>
				<div dangerouslySetInnerHTML={{ __html: project.requirements }} />
			</section>
			{project.attachments.length > 0 &&
				<React.Fragment>
					<span className="h4">Attachments</span>
					<AttachmentList 
						attachments={project.attachments} 
						downloadLink={attachment => `/StudentProject/DownloadAttachment?projectId=${project.studentProjectId}&attachmentId=${attachment.studentProjectAttachmentId}`} 
					/>
				</React.Fragment>
			}
			<section>
				<span className="dates">
					Created {prettifyDate(project.createDate)}
					{project.updateDate && `, Updated ${prettifyDate(project.updateDate)}`}
				</span>
				<div>{project.channels.map((c, index) => <Tag key={index} label={c.channelName} colour={primaryColour} style={{ marginRight: 5 }} />)}</div>
				<div>{project.tags.map((t, index) => <Tag key={index} label={t.name} colour={secondaryColour} style={{ marginRight: 5 }} />)}</div>
			</section>
			{props.canEdit && 
				<ButtonLink to={`/business-challenges/edit/${project.studentProjectId}`} style={{ marginRight: 10 }}>
					Edit Challenge
				</ButtonLink>
			}
			{props.canViewEnquiries && 
				<ButtonLink to={`/business-challenges/enquiries?projectId=${project.studentProjectId}`} color="secondary">
					View Enquiries
				</ButtonLink>
			}
		</Card>
	);
};

ViewStudentProject.propTypes = {
	project: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canViewEnquiries: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired,
	secondaryColour: PropTypes.string.isRequired
};

export default ViewStudentProject;