import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import map from 'lodash/map';
import EditPortfolioItem from './EditPortfolioItem';

const form = {
	initValues: props => ({ portfolio: [], ...props.student }),
	fields: [
		{
			name: "name",
			label: "Full name",
			required: true,
			helpText: "Full name of student"
		},
		{
			name: "userId",
			label: "User",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "users",
				mapItem: user => ({ label: user.fullName, value: user.userId })
			}
		},
		{
			name: "email",
			label: "Email",
			required: true,
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "institutionId",
			label: "Institution",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations?type=Institution",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			helpText: "Current institution"
		},
		{
			name: "currentProgram",
			label: "Current Program",
			multiline: true
		},
		{
			name: "about",
			label: "About",
			type: "textEditor"
		},
		{
			name: "category",
			label: "Category",
			type: "select",
			items: ["Undergraduate", "Postgraduate", "Professional"],
			required: true
		}
	]
};

const emptyItem = {
	title: "",
	description: "",
	links: [],
	attachments: []
};

const EditStudent = props => {
	const [attachmentFiles, setAttachmentFiles] = React.useState([]);

	const { student, fields, loading, isNew } = props;
	const { portfolio } = props.values;

	const addItem = () => {
		props.updateValues({ portfolio: [...portfolio, { ...emptyItem }] });
	}

	const updateItem = (index, field, value) => {
		const copy = [...portfolio];
		copy[index][field] = value;

		props.updateValues({ portfolio: copy });
	}

	const deleteItem = index => {
		const copy = [...portfolio];
		copy.splice(index, 1);

		props.updateValues({ portfolio: copy });
	}

	return (
        <Card title={`${isNew ? "Create" : "Edit"} student`} loading={loading} className="edit-student">
			<Form onSubmit={() => props.validateFields() && props.saveStudent(props.values, attachmentFiles)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.name()}</Grid>
					<Grid item xs={12} sm={6}>{fields.userId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.email()}</Grid>
					<Grid item xs={12} sm={6}>{fields.institutionId()}</Grid>
					<Grid item xs={12}>{fields.currentProgram()}</Grid>
					<Grid item xs={12}>{fields.about()}</Grid>
					<Grid item xs={12} sm={6}>{fields.category()}</Grid>
				</Grid>
				<span className="h2" style={{ marginTop: 20 }}>
					Portfolio
					<IconButton style={{ marginLeft: 5 }} onClick={addItem} size="large"><AddIcon /></IconButton>
				</span>
				{portfolio && portfolio.length === 0 && "There are no portfolio items to display..."}
				{map(portfolio, (item, i) =>
					<EditPortfolioItem 
						key={i} 
						item={item} 
						updateItem={(field, value) => updateItem(i, field, value)} 
						addFiles={files => setAttachmentFiles([...attachmentFiles, ...files])}
						onDelete={() => deleteItem(i)} 
						studentId={props.values.studentId}
					/>
				)}
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} student</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteStudent(student.studentId)}>
							Delete Student
						</Button>
					}
				</div>
			</Form>
		</Card>
    );
};

EditStudent.propTypes = { 
	student: PropTypes.object,
	saveStudent: PropTypes.func.isRequired,
	deleteStudent: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditStudent.defaultProps = {
	student: {},
	deleteStudent: () => null,
	isNew: false
};

export default withForm(form)(EditStudent);