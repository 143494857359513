import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { Paper, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getDiscussions, saveDiscussion, isLoading, getSubscribedDiscussions } from '../../store/discussion';
import { getChannels } from '../../store/channels';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import TextField from '../widgets/TextField';
import Button from '../widgets/Button';
import CreateDiscussionDialog from '../widgets/CreateDiscussionDialog';
import PageLayout from '../PageLayout';
import distanceInWords from 'date-fns/formatDistance';

const DiscussionController = props => {
	const [search, setSearch] = React.useState("");
	const [showDiscussionDialog, setShowDiscussionDialog] = React.useState(false);

	React.useEffect(props.onLoad, [props.isCommunity]);

	return (
		<PageLayout title="Discussion" loading={props.loading} pageContent={
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs>
						<TextField
							placeholder="Search"
							value={search}
							onChange={e => setSearch(e.target.value)}
							startAdornment={<SearchIcon />}
						/>
					</Grid>
					<Grid item xs="auto">
						<Button onClick={() => setShowDiscussionDialog(true)}>Create discussion</Button>
					</Grid>
				</Grid>
				<Paper>
					<DataTable data={props.discussions} search={search} emptyText="There is no discussion to display...">
						<DataTableColumn name="subject" label="Subject" renderer={d => 
							<Link style={{ color: "inherit" }} to={`/${props.isCommunity ? "my-community/" : ""}discussion/${d.discussionId}`}>{d.subject}</Link>
						} />
						<DataTableColumn name="comments" label="Comments" />
						<DataTableColumn name="views" label="Views" />
						<DataTableColumn name="createDate" dataType="date" label="Created" />
						<DataTableColumn label="Last Comment" renderer={d => 
							d.lastComment ? distanceInWords(new Date(d.lastComment), new Date(), { addSuffix: true }) : ""
						} />
					</DataTable>
				</Paper>
				<CreateDiscussionDialog
					open={showDiscussionDialog}
					channels={props.channels}
					onSubmit={discussion => {
						setShowDiscussionDialog(false);
						props.saveDiscussion(discussion);
					}}
					onClose={() => setShowDiscussionDialog(false)} 
					hideTopic
				/>
			</React.Fragment>
		} breadcrumbs={[props.isCommunity ? { label: "My Community", path: "/my-community" } : "", "Discussion"]} />
	);
};

DiscussionController.propTypes = {
	onLoad: PropTypes.func.isRequired,
	discussions: PropTypes.arrayOf(PropTypes.object).isRequired,
	saveDiscussion: PropTypes.func.isRequired,
	channels: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool
};

DiscussionController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	discussions: state.discussion.discussions,
	channels: state.channels.channels,
	loading: isLoading(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onLoad: () => {
		dispatch(getChannels())
		dispatch(ownProps.isCommunity ? getSubscribedDiscussions() : getDiscussions())
	},
	saveDiscussion: discussion => dispatch(saveDiscussion(discussion, ownProps.isCommunity))
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionController);