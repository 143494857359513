import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../../utils/forms';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';
import Button from '../../widgets/Button';
import map from 'lodash/map';

const form = {
	initValues: ({ organisation: o = {} }) => ({ 
		showUserTour: true, 
		visibility: 'Private',
		secondaryProfileVisibility: 'Show',
		defefaultCoursesView: "Linked",
		...o, 
		defaultRoles: map(o.defaultRoles, r => ({ ...r, label: r.name, value: r.roleId })), 
		defaultChannels: map(o.defaultChannels, c => ({ ...c, label: c.name, value: c.channelId }))
	}),
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			loadItems: {
				route: "tenants",
				mapItem: t => ({ label: t.name, value: t.tenantId })
			}
		},
		{
			name: "name",
			label: "Name",
			required: true,
		},
		{
			name: "country",
			label: "Country"
		},
		{
			name: "type",
			label: "Type",
			required: true,
			type: "select",
			items: ["Organisation", "Institution"]
		},
		{
			name: "visibility",
			label: "Visibility",
			required: true,
			type: "select",
			items: ["Public", "Private"]
		},
		{
			name: "hide",
			label: "Hide",
			type: "checkbox"
		},
		{
			name: "logo",
			label: "Logo",
			type: "avatar"
		},
		{
			name: "accreditedLogo",
			label: "Accredited Logo",
			type: "avatar"
		},
		{
			name: "userTour",
			label: "User Tour",
			type: "fileUploader"
		},
		{
			name: "registrationProcess",
			label: "Registration Process",
			type: "select",
			items: ["Anonymous", "Controlled", { label: "Admin Only", value: "AdminOnly" }, "Hidden"]
		},
		{
			name: "userCanSelectSkillsFramework",
			label: "Can Select Competency Framework",
			type: "checkbox",
			helpText: "Allow User to select competency framework"
		},
		{
			name: "autoShowAddCompetencies",
			label: "Auto-show Add Competencies for primary Skills Profile",
			type: "checkbox",
			helpText: "Automatically show 'Add Competencies' dialog when users competencies are incomplete"
		},
		{
			name: "autoShowAddAspirationalCompetencies",
			label: "Auto-show Add Competencies for aspirational Skills Profile",
			type: "checkbox",
			helpText: "Automatically show 'Add Competencies' dialog when users competencies are incomplete"
		},
		{
			name: "autoExpandCompetencies",
			label: "Auto-expand Competencies",
			type: "checkbox",
			helpText: "Automatically expand competencies in 'Add Competencies' dialog"
		},
		{
			name: "showNotCompetent",
			label: "Show Not Competent",
			type: "checkbox",
			helpText: "Show or hide option to set user as not competent for unit in 'Add Competencies' dialog"
		},
		{
			name: "defaultDepartmentId",
			label: "Default Business Unit",
			type: "autoComplete",
			loadItems: {
				route: props => `departments/by-organisation/${props.organisationId}`,
				mapItem: d => ({ label: d.name, value: d.departmentId })
			},
			helpText: "Default business unit for new users"
		},
		{
			name: "defaultDepartmentName",
			label: "Default Business Unit",
			helpText: "Default business unit for new users (will be created upon submission)"
		},
		{
			name: "defaultPositionId",
			label: "Default Position",
			type: "autoComplete",
			loadItems: {
				route: props => `positions/by-organisation/${props.organisationId}`,
				mapItem: p => ({ label: p.title, value: p.positionId })
			},
			helpText: "Default position for new users"
		},
		{
			name: "defaultPositionTitle",
			label: "Default Position",
			helpText: "Default position for new users (will be created upon submission)"
		},
		{
			name: "defaultSkillsProfileId",
			label: "Default Skills Profile",
			type: "profilePicker",
			helpText: "Default skills profile for new users"
		},
		{
			name: "initialAssessmentProfileId",
			label: "Initial Assessment Profile",
			type: "profilePicker",
			helpText: "Initial Assessment Profile"
		},
		{
			name: "defaultRoles",
			label: "Default Roles",
			type: "autoComplete",
			loadItems: {
				route: "roles",
				mapItem: ({ roleId, name }) => ({ label: name, value: roleId, roleId })
			},
			isMulti: true,
			helpText: "Default roles for new users"
		},
		{
			name: "defaultChannels",
			label: "Default Channels",
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: ({ channelId, name }) => ({ label: name, value: channelId, channelId })
			},
			isMulti: true,
			helpText: "Default subscribed channels for new users",
			chipColour: props => props.secondaryColour
		},
		{
			name: "showUserTour",
			label: "Show User Tour",
			type: "checkbox",
			helpText: "Show the user tour when the user logs in"
		},
		{
			name: "contactEmail",
			label: "Contact Email"
		},
		{
			name: "primarySkillsProfileGroupId",
			label: "Primary Skills Profile Group",
			type: "profilePicker",
			groupOnly: true,
			helpText: "Default group for selecting skills profile within primary competencies page"
		},
		{
			name: "secondarySkillsProfileGroupId",
			label: "Secondary Skills Profile Group",
			type: "profilePicker",
			groupOnly: true,
			helpText: "Default group for selecting skills profile within secondary competencies page"
		},
		{
			name: "includePrimaryAndSecondaryProfilesInStats",
			label: "Include Primary and Secondary Profile in Competency Stats",
			type: "checkbox"
		},
		{
			name: "completeUserAssessment",
			label: "Complete Initial User Assessment",
			type: "checkbox",
			helpText: "Whether users should indicate that they have completed assessment"
		},
		{
			name: "skillsFrameworks",
			label: "Skills Frameworks",
			type: "frameworkPicker",
			isMulti: true
		},
		{
			name: "canCreatePublicTool",
			label: "Can Create Public Tools",
			type: "checkbox",
			helpText: "Enable users to set a tool's visibility to \"Public\""
		},
		{
			name: "hideDepartmentDuringRegistration",
			label: "Hide Department During Registration",
			type: "checkbox"
		},
		{
			name: "hidePositionDuringRegistration",
			label: "Hide Position During Registration",
			type: "checkbox"
		},
		{
			name: "secondaryProfileVisibility",
			label: "Secondary Profile Visibility",
			required: true,
			type: "select",
			items: ["Show", "Hide", "Disable"]
		},
		{
			name: "defaultCoursesView",
			label: "Default Courses View",
			type: "select",
			required: true,
			items: [
				{ label: "All Courses", value: "All" },
				{ label: "Linked Courses Only", value: "Linked" }
			]
		},
		{
			name: "showMembershipLevelId",
			label: "Show Membership Level",
			type: "checkbox"
		},
		{
			name: "showReviewStatus",
			label: "Show Review Status",
			type: "checkbox"
		},
		{
			name: "graphicalSkillsView",
			label: "Graphical Skills View",
			type: "checkbox"
		},
		{
			name: "initialAssessmentSkillsView",
			label: "Initial Assessment Skills View",
			required: true,
			type: "select",
			items: ["Graphical", "Textual"]
		}
	]
};

const EditOrganisation = props => {
	const { organisation, fields, loading, isNew } = props;

	return (
		<Card className="edit-organisation">
			<Form onSubmit={() => props.validateFields() && props.saveOrganisation(props.values, props.formFiles.logo, props.formFiles.accreditedLogo, props.formFiles.userTour)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={props.canEditTenant ? 6 : 12}>{fields.name()}</Grid>
					{props.canEditTenant && 
						<Grid item xs={12} md={6}>{fields.tenantId()}</Grid>
					}
					<Grid item xs={12} md>{fields.type()}</Grid>
					<Grid item xs md>{fields.country()}</Grid>
					<Grid item xs="auto">{fields.hide()}</Grid>
					{/* Spacer */}
					<Grid item xs={12} style={{ padding: 0 }} />
					<Grid item xs md={6}>{fields.registrationProcess()}</Grid>
					<Grid item xs="auto">{fields.logo()}</Grid>
					<Grid item xs="auto">{fields.accreditedLogo()}</Grid>
					<Grid item xs={12}>{fields.contactEmail()}</Grid>
					<Grid item xs={12} sm={6}>{fields.showUserTour()}</Grid>
					<Grid item xs={12} sm={6}>{fields.userTour()}</Grid>
					<Grid item xs={12}>{fields.autoShowAddCompetencies()}</Grid>
					<Grid item xs={12}>{fields.autoShowAddAspirationalCompetencies()}</Grid>
					<Grid item xs={12}>{fields.autoExpandCompetencies()}</Grid>
					<Grid item xs={12}>{fields.userCanSelectSkillsFramework()}</Grid>
					<Grid item xs={12}>{fields.showNotCompetent()}</Grid>
					<Grid item xs={12}>{fields.includePrimaryAndSecondaryProfilesInStats()}</Grid> 
					<Grid item xs={12}>{fields.completeUserAssessment()}</Grid> 
					<Grid item xs={12}>{fields.canCreatePublicTool()}</Grid>
					<Grid item xs={12} sm={6}>
						{isNew ? fields.defaultDepartmentName() : fields.defaultDepartmentId()}
					</Grid>
					<Grid item xs={12} sm={6}>{fields.hideDepartmentDuringRegistration()}</Grid>
					<Grid item xs={12} sm={6}>
						{isNew ? fields.defaultPositionTitle() : fields.defaultPositionId()}
					</Grid>
					<Grid item xs={12} sm={6}>{fields.hidePositionDuringRegistration()}</Grid>
					<Grid item xs={12} sm={6}>{fields.defaultSkillsProfileId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.initialAssessmentProfileId()}</Grid>
					<Grid item xs={12}>{fields.defaultRoles()}</Grid>
					<Grid item xs={12}>{fields.defaultChannels()}</Grid>
					<Grid item xs={12} sm={6}>{fields.primarySkillsProfileGroupId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.secondarySkillsProfileGroupId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.secondaryProfileVisibility()}</Grid>
					<Grid item xs={12} sm={6}>{fields.skillsFrameworks()}</Grid>
					<Grid item xs={12} sm={6}>{fields.defaultCoursesView()}</Grid>
					<Grid item xs={12} sm={6}>{fields.initialAssessmentSkillsView()}</Grid>
					<Grid item xs={12} sm="auto">{fields.showMembershipLevelId()}</Grid>
					<Grid item xs={12} sm="auto">{fields.showReviewStatus()}</Grid>
					<Grid item xs={12} sm="auto">{fields.graphicalSkillsView()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} organisation</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteOrganisation(organisation.organisationId)}>
							Delete Organisation
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditOrganisation.propTypes = { 
	organisationId: PropTypes.number,
	organisation: PropTypes.object,
	saveOrganisation: PropTypes.func.isRequired,
	deleteOrganisation: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	addSkillsFramework: PropTypes.func,
	canEditTenant: PropTypes.bool,
	secondaryColour: PropTypes.string.isRequired
};

EditOrganisation.defaultProps = {
	organisationId: 0,
	organisation: {},
	deleteOrganisation: () => null,
	isNew: false,
	addSkillsFramework: () => null,
	canEditTenant: false
};

export default withForm(form)(EditOrganisation);