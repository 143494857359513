import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canEditTeams } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewTeam from './ViewTeam';
import axios from 'axios';
import { get } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';

const defaultTeam = {
	name: "",
	description: "",
	teamLeader: null,
	members: []
};

const ViewTeamController = props => {
    const [state, setState] = React.useState({
		isLoading: false,
		team: defaultTeam
	});

    React.useEffect(() => {
		axios.all([
			get({
				url: `/api/teams/${props.match.params.id}`,
				onSuccess: data => setState(prevState => ({ ...prevState, team: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	}, [props.match.params.id]);


	return <PageLayout title="View Team" pageContent={
		<ViewTeam team={state.team} loading={state.isLoading} canEdit={props.canEdit} />
	} breadcrumbs={[{ label: "Teams", path: "/teams" }, "View Team"]} />;
};

ViewTeamController.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	canEdit: canEditTeams(state)
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTeamController);
