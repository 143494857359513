import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	communities: [],
	community: {}
};

const CLEAR_COMMUNITIES = "CLEAR_COMMUNITIES";
const GET_COMMUNITIES_REQUEST = "GET_COMMUNITIES_REQUEST";
const GET_COMMUNITIES_SUCCESS = "GET_COMMUNITIES_SUCCESS";
const GET_COMMUNITIES_FAILURE = "GET_COMMUNITIES_FAILURE";
const SEARCH_COMMUNITIES_REQUEST = "SEARCH_COMMUNITIES_REQUEST";
const SEARCH_COMMUNITIES_SUCCESS = "SEARCH_COMMUNITIES_SUCCESS";
const SEARCH_COMMUNITIES_FAILURE = "SEARCH_COMMUNITIES_FAILURE";
const GET_COMMUNITY_REQUEST = "GET_COMMUNITY_REQUEST";
const GET_COMMUNITY_SUCCESS = "GET_COMMUNITY_SUCCESS";
const GET_COMMUNITY_FAILURE = "GET_COMMUNITY_FAILURE";
const SAVE_COMMUNITY_REQUEST = "SAVE_COMMUNITY_REQUEST";
const SAVE_COMMUNITY_SUCCESS = "SAVE_COMMUNITY_SUCCESS";
const SAVE_COMMUNITY_FAILURE = "SAVE_COMMUNITY_FAILURE";
const DELETE_COMMUNITY_REQUEST = "DELETE_COMMUNITY_REQUEST";
const DELETE_COMMUNITY_SUCCESS = "DELETE_COMMUNITY_SUCCESS";
const DELETE_COMMUNITY_FAILURE = "DELETE_COMMUNITY_FAILURE";

export const isLoading = createLoadingSelector(["GET_COMMUNITIES", "SEARCH_COMMUNITIES", "GET_COMMUNITY"]);

export const getCommunities = () =>
	createFetchAction({
		url: "/api/communities",
		startAction: GET_COMMUNITIES_REQUEST,
		onError: error => [getCommunitiesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCommunitiesSuccess(data)
	});

export const getCommunitiesSuccess = data => ({ type: GET_COMMUNITIES_SUCCESS, payload: { data } });
export const getCommunitiesFailure = error => ({ type: GET_COMMUNITIES_FAILURE, payload: { error } });

export const searchCommunities = search => 
	createFetchAction({
		url: `/api/communities/search?search=${search || ""}`,
		startAction: SEARCH_COMMUNITIES_REQUEST,
		onError: error => [searchCommunitiesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchCommunitiesSuccess(data)
	});

export const searchCommunitiesSuccess = data => ({ type: SEARCH_COMMUNITIES_SUCCESS, payload: { data } });
export const searchCommunitiesFailure = error => ({ type: SEARCH_COMMUNITIES_FAILURE, payload: { error } });

export const getCommunity = communityId =>
	createFetchAction({
		url: `/api/communities/${communityId}`,
		startAction: GET_COMMUNITY_REQUEST,
		onError: error => [getCommunityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCommunitySuccess(data)
	});

export const getCommunitySuccess = data => ({ type: GET_COMMUNITY_SUCCESS, payload: { data } });
export const getCommunityFailure = error => ({ type: GET_COMMUNITY_FAILURE, payload: { error } });

export const saveCommunity = community => 
	createFormPostAction({
		url: "/api/communities",
		data: community,
		startAction: SAVE_COMMUNITY_REQUEST,
		onError: error => [saveCommunityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveCommunitySuccess(data), showSuccessNotification(data.message), changeLocation("/admin/communities")];
			} else {
				return [saveCommunitySuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveCommunitySuccess = data => ({ type: SAVE_COMMUNITY_SUCCESS, data });
export const saveCommunityFailure = error => ({ type: SAVE_COMMUNITY_FAILURE, error });

export const deleteCommunity = communityId =>
	createPostAction({
		url: `/api/communities/${communityId}/delete`,
		startAction: DELETE_COMMUNITY_REQUEST,
		onError: error => [deleteCommunityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteCommunitySuccess(data), showSuccessNotification(data.message), changeLocation(`/admin/communities`)];
			} else {
				return [deleteCommunitySuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteCommunitySuccess = data => ({ type: DELETE_COMMUNITY_SUCCESS, data });
export const deleteCommunityFailure = error => ({ type: DELETE_COMMUNITY_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_COMMUNITIES:
			return { ...state, communities: []};
		case GET_COMMUNITIES_REQUEST:
			return { ...state,communities: [], isLoading: true};
		case SEARCH_COMMUNITIES_REQUEST:
			return { ...state, isLoading: true };
		case GET_COMMUNITIES_SUCCESS:
		case SEARCH_COMMUNITIES_SUCCESS:
			return { ...state, communities: action.payload.data, isLoading: false };
		case GET_COMMUNITY_REQUEST:
			return { ...state, community: {}, isLoading: true };
		case GET_COMMUNITY_SUCCESS:
			return { ...state, community: action.payload.data, isLoading: true };
		case SAVE_COMMUNITY_REQUEST:
		case DELETE_COMMUNITY_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_COMMUNITY_SUCCESS:
			return {
				...state,
				...(action.data.success && { community: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_COMMUNITY_SUCCESS:
			return { ...state, communities: state.communities.filter(a => a.communityId !== action.data.objectId) };
		default:
			return state;
	}
};
