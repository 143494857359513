import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProblem, saveProblem, deleteProblem, isLoading } from '../../store/problems';
import { canEditProblem, canEditCommunityProblem } from '../../store/security';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditProblem from './EditProblem';
import capitalise from 'lodash/capitalize';

const EditProblemController = props => {
	const { problem, single, plural, isCommunity } = props,
		problemId = Number(props.match.params.problemId);

	React.useEffect(() => props.getProblem(problemId), [problemId]);

	React.useEffect(() => {
		if (problem.problemId === problemId && !props.canEdit) props.revokeAccess();
	}, [problem.problemId]);

	return <PageLayout title={`Edit ${single}`} pageContent={
		<EditProblem 
			problem={problem}
			loading={props.loading}
			onSave={props.saveProblem(single, plural)}
			onDelete={props.deleteProblem(single)}
			single={single}
			plural={plural}
			headerContent={props.headerContent}
			titleHelpText={props.titleHelpText}
			descriptionHelpText={props.descriptionHelpText}
			solutionHelptext={props.solutionHelptext}
		/>
	} breadcrumbs={[{ label: capitalise(plural), path: `/${isCommunity ? "my-community/" : ""}problems` }, `Edit ${single}`]} />;
};

EditProblemController.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	problem: PropTypes.object.isRequired,
	saveProblem: PropTypes.func.isRequired,
	deleteProblem: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool,
	headerContent: PropTypes.string.isRequired,
	titleHelpText: PropTypes.string.isRequired,
	descriptionHelpText: PropTypes.string.isRequired,
	solutionHelptext: PropTypes.string.isRequired
};

EditProblemController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	canEdit: ownProps.isCommunity ? canEditCommunityProblem(state) : canEditProblem(state),
	problem: state.problems.problem,
	loading: isLoading(state),
	single: state.settings.single,
	plural: state.settings.plural,
	headerContent: state.settings.submitProblemHeaderContent || "",
	titleHelpText: state.settings.submitProblemTitleHelpText || "",
	descriptionHelpText: state.settings.submitProblemDescriptionHelpText || "",
	solutionHelptext: state.settings.submitProblemSolutionHelpText || "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getProblem: (problemId, onSuccess) => dispatch(getProblem(problemId, onSuccess)),
	saveProblem: (single, plural) => (problem, newFiles) => {
		if (problem.status === "closed") {
			dispatch(showModal("SIMPLE", {
				title: `Are you sure you want to close this ${single}?`,
				message: `Closed ${plural} are unable to be endorsed or appear in leaderboards.`,
				onOk: () => dispatch(saveProblem(problem, newFiles, ownProps.isCommunity))
			}))
		} else {
			dispatch(saveProblem(problem, newFiles, ownProps.isCommunity));
		}
	},
	deleteProblem: single => problemId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: `Are you sure you want to delete this ${single}?`,
		onOk: () => dispatch(deleteProblem(problemId, ownProps.isCommunity))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProblemController);
