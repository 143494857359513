import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../../PageLayout';
import ReportContainer from '../ReportContainer';
import DevelopmentNeeds from '../../dashboardWidgets/DevelopmentNeeds';
import { CircularProgress, Tooltip } from '@mui/material';
import { get } from '../../../utils/ajax';
import map from 'lodash/map';
import CheckIcon from '@mui/icons-material/Check';
import { prettifyDate } from '../../../utils/utils';
import CoverImage from '../../images/reports/GeneralCompetencyAssessmentCover.png';
import GeneralCompetencyAssessmentFramework from '../../images/reports/GeneralCompetencyAssessmentFramework.png';

const GeneralCompetencyAssessmentReport  = (props) => {
	const [isDevelopmentNeedsLoading, setDevelopmentNeedsLoading] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [result, setResult] = React.useState({});
	const successColour = props.successColour;
	
	const loadData = () => {
		setIsLoading(true);
		setResult({});
		get({
			url: `/api/reports/general-competency-assessment?userId=${props.userId}`,
			onSuccess: data => {
				setIsLoading(false);
				setResult(data);
			},
			onError: () => {
				setIsLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (props.userId) {
			loadData();
		}
	}, [props.userId]);

	let user = {};
	if (result.success) {
		user = result.data.user[0];
	}
	const categories = result.data ? result.data.categories : [];
	const professionalSkillsProfile = result.data ? result.data.professionalSkillsProfile : [];
	const skillsGaps = result.data ? result.data.skillsGaps : [];

	const report = (
		<ReportContainer 
			isReportLayout={props.isReportLayout} 
			orientation={props.orientation}
		>
			<div className="valign-wrapper" style={{ pageBreakAfter: 'always', width: '100%' }}>
				<div className="valign center-align" style={{ width: '100%' }}>
					<img alt="Cover" className="responsive-img" src={CoverImage} style={{ width: '100%' }}/>
				</div>
			</div>

			<h1 className="justify-content-center">General Competency Assessment Report</h1>
			
			<table className="report-table">
				<tbody>
					<tr>
						<td>Name</td>
						<td>{user.fullName}</td>
					</tr>
					<tr>
						<td>Organisation</td>
						<td>{user.organisationName}</td>
					</tr>
					<tr>
						<td>Date of creation</td>
						<td>{user.dateReportGenerated ? prettifyDate(user.dateReportGenerated) : ''}</td>
					</tr>
					<tr>
						<td>Professional role selected </td>
						<td>{user.skillsProfileName ? user.skillsProfileName : 'NA'}</td>
					</tr>
				</tbody>
			</table>
			<h2>Overview:</h2>
			<p>The role of learning and development professionals is changing significantly, with a move from traditional learning methodologies to innovative ways to design programs and learning pathways to engage learners and achieve the desired outcomes.</p>
			<p>This <b>Personal L&amp;D Capability Report</b> is based on your assessment against the defined competencies of the Learning &amp; Development (L&amp;D) Capability Framework developed by the Institute for Learning &amp; Performance Asia Pacific (ILP).</p>

			<h2>Framework Assessment:</h2>
			<p>The framework covers the behaviours that L&amp;D professionals require for long-term success within their profession and industry. The framework is relevant and applicable to all learning and development professionals accountable for the design and/or delivery and implementation of learning initiatives for their clients or organisations. </p>
			<p>The L&amp;D Capability Framework consists of 54 individual professional skills, structured within 6 broad capability categories and covering 18 sub-categories as illustrated below.</p>
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				width: '100%'
			}}>
				<img src={GeneralCompetencyAssessmentFramework} alt="" />
			</div>
			<p>Once you have completed your assessment, if you have also selected one of the common professional roles provided, your assessment is compared to the typical competency profile for the selected Role. This allows for easy identification if 'skills gaps' for that selected role.</p>

			{/* <p>This is your General Competency Assessment Report.</p>
			<p>This assessment is based on the defined competencies based on the Learning &amp; Development (L&amp;D) Capability Framework developed by the Institute for Learning &amp; Performance Asia pacific (ILP).</p>
			<p>This framework is relevant and applicable to all learning and development professionals accountable for the design and/or delivery and implementation of learning initiatives for their clients or organisations. It covers the behaviours that L&amp;D professionals require for long-term success within their profession and industry.</p>
			<p>It can be used by both L&amp;D professionals to identify development needs for capability building, as well as employer of corporate L&amp;D teams to align their L&amp;D competencies to the needs of their organisation.</p>
			<p>Three types of assessment are available. The first is a <b>General Competency Assessment</b> which outlines your skills profile against the framework. This allows individual to obtain a summary profile of strengths and weaknesses.</p>
			<p>The second type of assessment if a <b>Role-Based Competency Assessment</b>. This allows individuals to profile their competencies against common industry-defined roles.  This will allow individuals to identify skills gaps against nominated roles, or indeed against roles to which individuals are aspiring.</p>
			<p>The third type of assessment is a <b>Teams-Based Competency Assessment</b>. This assessment can be on the basis of either a consolidated General competency assessment OR a Role-Based Assessment. This Team report allows organisations to profile their entire L&amp;D team or combined workgroups against competencies that can also be priorities for roles and the organisation. This provided a more in-depth report highlight teams’ skills gaps and performance risk assessments.  Importantly, this teams-based report allows organisations to determine development needs or how they may wish to partner or contract-in for capabilities not possessed by the team. <i>Teams-Based Competency Assessment are provided by request only.</i></p> */}

			<h2>L&amp;D Profile Levels:</h2>
			<p>Your L&amp;D competency assessment allows you identify and self-assess skills you possess against your level of capability. ILP attainment scoring for both the level of responsibility AND professional skills is defined from level 0 through 4 as summarised below.</p>

			<table className="report-table centered">
				<thead>
					<tr>
						<th>L&amp;D Capability</th>
						<th>Level</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Expert</td>
						<td>4</td>
					</tr>
					<tr>
						<td>Experienced</td>
						<td>3</td>
					</tr>
					<tr>
						<td>Capable</td>
						<td>2</td>
					</tr>
					<tr>
						<td>Foundation</td>
						<td>1</td>
					</tr>
					<tr>
						<td>Not assessed or does not meet minimum role requirement</td>
						<td>0</td>
					</tr>
				</tbody>
			</table>

			<p>The assessment allows individuals to identify any non-framework skills that can also be captured in the profile that they feel is important in the performance of their function within their organisation. </p>

			<h2>Other Reports:</h2>
			<p>In addition to this Personal L&amp;D Capability Report you are also able to build team capability profiles and obtain your L&amp;D Team Capability Report. This Team report allows organisations to profile their entire L&amp;D team or combined workgroups against competencies that can also be prioritised for roles and the organisation. This provides a more in-depth report highlight teams' skills gaps and performance risk assessments. Importantly, this teams-based report allows organisations to determine development needs across their L&amp;D function. </p>

			<h2 style={{ pageBreakBefore: 'always' }}>Your Results:</h2>
			{/* <h2>Framework Assessment:</h2>
			<p>The L&amp;D Capability Framework consists of 54 professional skills, each defined by the attainment level described above. To aid navigation, the assessment is structured 6 broad capability categories and covering 18 sub-categories. </p>
			<p>The assessment allows individuals to identify any non-framework skills that can also be captured in the profile. </p>

			<h2 style={{ pageBreakBefore: 'always' }}>Your Summary Profile:</h2> */}
			<p>Your summary profile across the 6 L&amp;D Framework categories is summarised below:</p>

			<div
				style={{
					width:' 100%',
					height: '600px'
				}}
			>
				{isDevelopmentNeedsLoading &&
					<div style={{
							height: "100%",
							marginTop: "-10px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}>
						<CircularProgress />
					</div>
				}
				<DevelopmentNeeds userId={props.userId} setLoading={(isLoading) => { setDevelopmentNeedsLoading(isLoading) }} />
			</div>

			<table className="report-table">
				<thead>
					<tr>
						<th>L&amp;D Category Description</th>
						<th>Description</th>
						<th>No. of Competencies assessed</th>
						<th>No. of Competencies not assessed</th>
					</tr>
				</thead>
				<tbody>
					{
						map(categories || [], (c, i) => 
							<tr key={`cat-${i}`}>
								<td>{c.specialisationGroup}</td>
								<td>{c.description}</td>
								<td>{c.numCompetenciesAssessed}</td>
								<td>{c.numCompetenciesNotAssessed}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		
			<h2>Detailed L&amp;D Profile:</h2>
			<p>The table below summarises your profile against each assessed competency.</p>
			<table className="report-table">
				<thead>
					<tr>
						<th rowSpan={2} style={{ verticalAlign: 'top' }}>Code</th>
						<th rowSpan={2} style={{ verticalAlign: 'top' }}>Description</th>
						<th colSpan={7}>Level</th>
					</tr>
					<tr>
						<th style={{ width: '35px' }}>0</th>
						<th style={{ width: '35px' }}>1</th>
						<th style={{ width: '35px' }}>2</th>
						<th style={{ width: '35px' }}>3</th>
						<th style={{ width: '35px' }}>4</th>
					</tr>
				</thead>
				<tbody>
					{
						map(professionalSkillsProfile, (p, i) => {
							const getInitials = text => {
								return text.replace(' and', '').split(" ").map((n)=>n[0]).join("");
							}
							const code = `${getInitials(p.specialisationGroup)}-${getInitials(p.specialisation)}-${p.unitCode}`;
							return (
								<Tooltip 
									key={`psp-tt-${i}`}
									title={
										<React.Fragment>
											<p>{p.unitTitle}</p>
											<p>{p.unitDescription}</p>
											<p>Level Assessed: {p.level}</p>
											<p>{p.levelName}</p>
										</React.Fragment>
									} 
									disableFocusListener
									arrow
								>
								<tr key={`psp-${i}`}>
									<td style={{ minWidth: '140px' }}>{code}</td>
									<td>{p.unitTitle}</td>
									<td>{p.level === 0 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
									<td>{p.level === 1 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
									<td>{p.level === 2 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
									<td>{p.level === 3 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
									<td>{p.level === 4 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
								</tr>	
								</Tooltip>
							);
						})
					}
				</tbody>
			</table>
 
			{user.skillsProfileName && 
				<React.Fragment>
					<h2>Your Skills Gaps:</h2>
					<p>Based on your assessment and your selected Professional Role the following are your identified skills gaps.</p>

					{
						map(skillsGaps, (p, i) => 
							<React.Fragment key={`psp-${i}`}>
							<p style={{ fontStyle: 'italic' }}>
								Competency:  {p.unitTitle}
							</p>
							<table className="report-table">
								<tbody>
									<tr>
										<td>Code</td>
										<td>{p.unitCode}</td>
										<td>Description</td>
										<td>{p.unitDescription}</td>
									</tr>
									<tr>
										<td>Your Score</td>
										<td>{p.assessedLevel}</td>
										<td>Target Score</td>
										<td>{p.targetLevel}</td>
									</tr>
								</tbody>
							</table>
							</React.Fragment>
						)
					}
				</React.Fragment>
			}

		</ReportContainer>
	);

	if (props.isReportLayout) return report;
	else return (
		<PageLayout 
			title="General Competency Assessment" 
			pageContent={report}
			loading={isLoading}
			breadcrumbs={["General Competency Assessment"]}
		/>
	);

};

GeneralCompetencyAssessmentReport.propTypes = {
	userId: PropTypes.string.isRequired,
	isReportLayout: PropTypes.bool,
	successColour: PropTypes.string.isRequired
};

GeneralCompetencyAssessmentReport.defaultProps = {
	isReportLayout: false
};

const mapStateToProps = state => ({
	userId: state.context.userId,
	successColour: state.theme.successColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralCompetencyAssessmentReport);
