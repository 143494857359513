import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import { parseYoutube } from '../../utils/utils';
import TabContainer from '../widgets/TabContainer';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import Tab from '../widgets/Tab';
import { default as formatDate } from 'date-fns/format';
import map from 'lodash/map';

const ViewEvent = props => {
	const { event } = props;

	return props.loading ? <CircularProgress size={24} /> : (
		<Grid container spacing={3} className="event">
			<Grid item xs={12}>
				<h2 style={{ margin: 0 }}>{event.title}</h2>
			</Grid>
			<Grid item xs={12} lg={8}>
				<TabContainer>
					<Tab label="Description">
						<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: event.description }}></div>
					</Tab>
					<Tab label="Documents">
						{(!event.attachments || event.attachments.length === 0) && 
							<p>There are no documents for this event.</p>
						}
						<div className="row">
							<ul className="list-unstyled">
								{map(event.attachments, (attachment, index) =>
									<li className="media" key={`event-attachment-${index}`}>
										<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
										<div className="media-body">
											<h5 className="mt-0 mb-1">{attachment.name}</h5>
											<p>{attachment.description}</p>
											<Button 
												variant="outlined" 
												onClick={() => props.downloadAttachment(attachment)}
											>
												Download now
											</Button>
										</div>
									</li>
								)}
							</ul>
						</div>
					</Tab>
					<Tab label="Video" show={Boolean(event.videoUrl)}>
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title="event-title" className="embed-responsive-item" src={`//www.youtube.com/embed/${parseYoutube(event.videoUrl || "")}`} allowFullScreen="" />
						</div>
					</Tab>
				</TabContainer>
				{props.canEdit && <ButtonLink to={`/${props.isCommunity ? "my-community/" : ""}events/edit/${event.eventId}`}>Edit event</ButtonLink>}
			</Grid>
			<Grid item xs={12} lg={4}>
				<div className="pcard m-b-5">
					<img className="pcard-img" src="/images/event/learning2-01.jpg" alt="" />
					<div className="pcard-img-overlay pcard-inverse event-details-overlay d-flex">
						<div className="align-self-center"> 
							<img src="/images/icon/010-event.svg" className="img-circle" width="100" alt="" />
							<h2 className="pcard-title text-white">{formatDate(event.eventStartDate, "MMMM do")}</h2>
							<h4 className="text-white-50">
								{formatDate(event.eventStartDate, "hh:mm a")}
								<span> to </span>
								{formatDate(event.eventEndDate, "hh:mm a")}
							</h4>
							<h6 className="pcard-subtitle text-white-50">{event.location}</h6>
							<p className="text-white">{event.address}</p>
							<p className="text-white-50">
								{props.visibilityDescriptions[event.visibility]}
							</p>
						</div>
					</div>
				</div>
				<Button style={{ width: "100%" }} loading={props.isRegistering} onClick={props.register}>
					Register now
				</Button>
				<div className="pcard m-t-5">
					<div className="pcard-body">
						<small>Enquiries </small>
						<h6 className="text-muted"><a className="text-muted" href="mailto:events-RBWH@health.qld.gov.au">{event.contactEmail}</a></h6>
						<small className="text-muted p-t-20 db">Phone</small>
						<h6>{event.contactPhone}</h6>
						<small className="text-muted p-t-20 db">Address</small>
						<h6>{event.eventAddress}</h6>
						<iframe title="event-map" src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(event.eventAddress)}&key=AIzaSyBUxOdI5yTXpvc6iwXBV6ViE2uqcU6vZDg`} width="100%" style={{ border: 0 }} allowFullScreen></iframe>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

ViewEvent.propTypes = {
	currentUserId: PropTypes.string,
	event: PropTypes.object,
	loading: PropTypes.bool,
	canEdit: PropTypes.bool.isRequired,
	downloadAttachment: PropTypes.func.isRequired,
	isRegistering: PropTypes.bool,
	register: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool.isRequired,
	visibilityDescriptions: PropTypes.object.isRequired
};

ViewEvent.defaultProps = {
	currentUserId: "",
	event: {},
	loading: false,
	isRegistering: false
};

export default ViewEvent;