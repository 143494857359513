import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';
import filter from 'lodash/filter';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	department: {}, 
	departments: [],
	isVoting: {}
};

const newDepartment = {
	departmentId: 0
};

const CREATE_NEW_DEPARTMENT = "CREATE_NEW_DEPARTMENT";
const CLEAR_DEPARTMENT = "CLEAR_DEPARTMENT";
const CLEAR_DEPARTMENTS = "CLEAR_DEPARTMENTS";
const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST";
const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
const GET_DEPARTMENT_FAILURE = "GET_DEPARTMENT_FAILURE";
const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";
const SAVE_DEPARTMENT_REQUEST = "SAVE_DEPARTMENT_REQUEST";
const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS";
const SAVE_DEPARTMENT_FAILURE = "SAVE_DEPARTMENT_FAILURE";
const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";
const SEARCH_DEPARTMENTS_REQUEST = "SEARCH_DEPARTMENTS_REQUEST";
const SEARCH_DEPARTMENTS_SUCCESS = "SEARCH_DEPARTMENTS_SUCCESS";
const SEARCH_DEPARTMENTS_FAILURE = "SEARCH_DEPARTMENTS_FAILURE";

export const isLoading = createLoadingSelector(["GET_DEPARTMENTS", "GET_DEPARTMENT", "DELETE_DEPARTMENT", "SAVE_DEPARTMENT", "SEARCH_DEPARTMENTS"]);

export const createNewDepartment = () => ({ type: CREATE_NEW_DEPARTMENT });
export const clearDepartments = () => ({ type: CLEAR_DEPARTMENTS });
export const clearDepartment = () => ({ type: CLEAR_DEPARTMENT });

export const getDepartments = () =>
	createFetchAction({
		url: "/api/departments",
		startAction: GET_DEPARTMENTS_REQUEST,
		onError: error => [getDepartmentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getDepartmentsSuccess(data)
	});

export const getUserDepartments = () =>
	createFetchAction({
		url: "/api/departments/user-departments",
		startAction: GET_DEPARTMENTS_REQUEST,
		onError: error => [getDepartmentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getDepartmentsSuccess(data)
	});

export const getDepartmentsSuccess = data => ({ type: GET_DEPARTMENTS_SUCCESS, payload: { data } });
export const getDepartmentsFailure = error => ({ type: GET_DEPARTMENTS_FAILURE, payload: { error } });

export const getDepartment = (departmentId, onSuccess, onError) =>
	createFetchAction({
		url: `/api/departments/${departmentId}`,
		startAction: GET_DEPARTMENT_REQUEST,
		onError: error => {
			if (onError) onError(error);
			return [getDepartmentFailure(error), showErrorNotification(error.message)];
		},
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getDepartmentSuccess(data);
		}
	});

export const getDepartmentSuccess = data => ({ type: GET_DEPARTMENT_SUCCESS, payload: { data } });
export const getDepartmentFailure = error => ({ type: GET_DEPARTMENT_FAILURE, payload: { error } });

export const searchDepartments = search => 
	createFetchAction({
		url: `/api/departments/search?search=${search}`,
		startAction: SEARCH_DEPARTMENTS_REQUEST,
		onError: error => [searchDepartmentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchDepartmentsSuccess(data)
	});

export const searchUserDepartments = search => 
	createFetchAction({
		url: `/api/departments/user-departments/search?search=${search}`,
		startAction: SEARCH_DEPARTMENTS_REQUEST,
		onError: error => [searchDepartmentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchDepartmentsSuccess(data)
	});

export const searchDepartmentsSuccess = data => ({ type: SEARCH_DEPARTMENTS_SUCCESS, payload: { data } });
export const searchDepartmentsFailure = error => ({ type: SEARCH_DEPARTMENTS_FAILURE, payload: { error } });

export const saveDepartment = department => 
	createPostAction({
		url: "/api/departments",
		data: department,
		startAction: SAVE_DEPARTMENT_REQUEST,
		onError: error => [saveDepartmentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveDepartmentSuccess(data), showSuccessNotification(data.message), changeLocation("/business-units")];
			} else {
				return [saveDepartmentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveDepartmentSuccess = data => ({ type: SAVE_DEPARTMENT_SUCCESS, data });
export const saveDepartmentFailure = error => ({ type: SAVE_DEPARTMENT_FAILURE, error });

export const deleteDepartment = departmentId =>
	createPostAction({
		url: `/api/departments/${departmentId}/delete`,
		startAction: DELETE_DEPARTMENT_REQUEST,
		onError: error => [deleteDepartmentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteDepartmentSuccess(data), showSuccessNotification(data.message), changeLocation("/business-units")];
			} else {
				return [deleteDepartmentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteDepartmentSuccess = data => ({ type: DELETE_DEPARTMENT_SUCCESS, data });
export const deleteDepartmentFailure = error => ({ type: DELETE_DEPARTMENT_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_DEPARTMENTS:
			return { ...state, departments: {} };
		case CLEAR_DEPARTMENT:
			return { ...state, department: {} };
		case GET_DEPARTMENTS_REQUEST:
			return { ...state, departments: [], saveResult: emptySaveResult };
		case GET_DEPARTMENTS_SUCCESS:
			return { ...state, departments: action.payload.data, isLoading: false };
		case GET_DEPARTMENT_REQUEST:
			return {
				...state,
				department: { ...newDepartment },
				isLoading: true,
				saveResult: emptySaveResult
			};
		case GET_DEPARTMENT_SUCCESS:
			return { ...state, department: action.payload.data, isLoading: false };
		case DELETE_DEPARTMENT_SUCCESS:
			return { ...state, departments: filter(state.departments, c => c.departmentId !== action.data.objectId) };
		case CREATE_NEW_DEPARTMENT:
			return { ...state, department: { ...newDepartment } };	
		case SAVE_DEPARTMENT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_DEPARTMENT_SUCCESS:
			return {
				...state,
				...(action.data.success && { departments: addOrUpdate(state.departments, action.data.object, { departmentId: action.data.object.departmentId }) }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case SEARCH_DEPARTMENTS_REQUEST:
			return { ...state, isLoading: true };
		case SEARCH_DEPARTMENTS_SUCCESS:
			return { ...state, departments: action.payload.data, isLoading: false };
		default:
			return state;
	}
};