 import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import Avatar from '../widgets/Avatar';
import EndorseButton from '../widgets/EndorseButton';
import ProblemStatus from '../widgets/ProblemStatus';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { prettifyDate } from '../../utils/utils';

const ProblemDetails = props => {
	const { problem, single, plural } = props;

	return (
		<div className="row">
			<div className="user-details">
				<div className="author-avatar">
					<Avatar src={problem.authorAvatar} fullName={problem.authorName} length={50} />
				</div>
				<div className="details-box">
					<span className="author-name">
						{problem.authorName}
						{problem.numAccepted > 0 &&
							<Tooltip title={`${problem.numAccepted} ${problem.numAccepted > 1 ? plural : single} accepted`} placement="right">
								<TurnedInIcon />
							</Tooltip>
						}
					</span>
					<span>{problem.numProblems} {problem.numProblems > 1 ? plural : single}</span>
					<span>Member since {prettifyDate(problem.registrationDate)}</span>
				</div>
				<div className="votes">
					<EndorseButton problemId={problem.problemId} hasVoted={problem.hasVoted} isClosed={problem.status === "closed"} />
					<div className="vote-count">
						<h3>{problem.votes}</h3>
						<h5>votes</h5>
					</div>
				</div>
			</div>
			<div className="tags">
				<ProblemStatus status={problem.status} />
				{map(problem.tags, t => <Tag key={t.problemTagId} label={t.name} />)}
				<span className="label label-rounded label-light-info mr-auto pull-right">{problem.views} views</span>
			</div>
		</div>
	);
};

ProblemDetails.propTypes = {
	problem: PropTypes.shape({
		problemId: PropTypes.number.isRequired,
		authorName: PropTypes.string,
		authorAvatar: PropTypes.string,
		numProblems: PropTypes.number,
		numAccepted: PropTypes.number,
		status: PropTypes.string.isRequired,
		votes: PropTypes.number.isRequired,
		hasVoted: PropTypes.bool.isRequired,
		tags: PropTypes.array.isRequired,
		views: PropTypes.number.isRequired,
	}).isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired
}

export default ProblemDetails;
