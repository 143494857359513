import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveEvent, isLoading } from '../../store/events';
import PageLayout from '../PageLayout';
import EditEvent from './EditEvent';
import { canApproveEvents } from '../../store/security';

const CreateEventController = props => 
	<PageLayout title="Create New Event" pageContent={
		<EditEvent saveEvent={props.saveEvent} loading={props.loading} isNew canApprove={props.canApprove} />
	} breadcrumbs={[{ label: "Events",  path: `/${props.isCommunity ? "my-community/" : ""}events` }, "Create Event"]} />;	

CreateEventController.propTypes = {
	saveEvent: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canApprove: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
};

CreateEventController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	canApprove: canApproveEvents(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveEvent: (event, newFiles) => dispatch(saveEvent(event, newFiles, ownProps.isCommunity))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventController);