import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import { Grid } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import LDResourcePicker from '../widgets/LDResourcePicker';
import FormButtons from '../widgets/FormButtons';
import TextField from '../widgets/TextField';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import ResourceCard from './CourseResourceCard';
import LDCourseSession from './LDCourseSession';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

const defaultSession = {
	name: "",
	time: "60min",
	tools: [],
	activities: [],
	resources: []
};

const form = {
	initValues: props => props.ldCourse,
	fields: [
		{
			name: "code",
			label: "Code",
			required: false,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "title",
			label: "Title",
			required: true,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "program",
			label: "Program",
			required: false,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor",
			required: false,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "objectives",
			label: "Learning Objectives",
			required: false,
			type: "dragList",
			simple: true,
			addToEnd: true,
			itemTemplate: { objective: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12}>
						<TextField label="Objective" value={item.objective} onChange={e => updateItem(e.target.value, "objective")} />
					</Grid>
				</Grid>,
			disabled: props => !props.canEdit
		},
		{
			name: "startDate",
			label: "Start Date",
			required: false,
			type: "date",
			disabled: props => !props.canEdit
		},
		{
			name: "endDate",
			label: "End Date",
			required: false,
			type: "date",
			disabled: props => !props.canEdit
		}
	]
};

const LDCourse = props => {
	const [showResourcePicker, setShowResourcePicker] = React.useState(false);
	const [formFiles, setFormFiles] = React.useState([]);

	const { fields, loading, values, values: { resources = [] }, isNew } = props;
	const orderedSessions = orderBy(values.sessions, s => s.sessionDate);

	return (
		<React.Fragment>
			<Form onSubmit={() => props.validateFields() && props.onSave(values)}>
				<Card 
					title={`${isNew ? "Add" : "Edit"} L&D Course`} 
					className="edit-ld-course" 
					loading={loading}
					titleRightContent={
						<Button onClick={window.print}>
							<PrintIcon style={{ marginRight: 5 }} />Print
						</Button>
					}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} lg={4}>{fields.code()}</Grid>
						<Grid item xs={12} sm={6} lg={4}>{fields.title()}</Grid>
						<Grid item xs={12} sm={6} lg={4}>{fields.program()}</Grid>
						<Grid item xs={12}>{fields.description()}</Grid>
						<Grid item xs={12}>{fields.objectives()}</Grid>
						<Grid item xs={12} sm={6} lg={6}>{fields.startDate()}</Grid>
						<Grid item xs={12} sm={6} lg={6}>{fields.endDate()}</Grid>
					</Grid>

						<span className="h3" style={{ position: 'relative', minHeight: '30px', marginTop: '20px' }}>
							Attachments
							<Button
								onClick={() => setShowResourcePicker(true)}
								style={{ position: 'absolute', left: '200px', top: '-5px' }}
							>
								Add
							</Button>
						</span>
						{resources.length > 0 &&
							<Grid container spacing={3}>
								{map(resources, (r, i) =>
									<Grid key={i} item xs={4}>
										<ResourceCard
											canEdit={true} 
											courseResource={r} 
											onDelete={() => {
												props.updateValues({ resources: resources.filter((r, i2) => i2 !== i)})
											}}
										/>
									</Grid>
								)}
							</Grid>
						}
				</Card>
				<span className="h3">Sessions</span>
				{map(orderedSessions, (s, i) =>
					<LDCourseSession
						canEdit={true}
						key={i}
						session={s}
						onChange={(newValues, file) => {
							const sessionsCopy = [...orderedSessions];
							orderedSessions[i] = newValues;

							if (file) setFormFiles([...formFiles, file]);
							
							props.updateValues({ sessions: sessionsCopy });
						}}
						onDelete={() => {
							props.updateValues({ sessions: orderedSessions.filter((s2, i2) => i2 !== i) })
						}}
						canSubmitActivities={props.canSubmitActivities}
						canSubmitTools={props.canSubmitTools}
					/>
				)}
			</Form>
			{props.canEdit && 
				<FormButtons 
					loading={loading}
					success={props.saveResult.success}
					onSave={() => props.validateFields() && props.onSave(values, formFiles)}
					showDelete={!isNew}
					onDelete={() => props.onDelete(values)}
					additionalButtons={
						<Button 
							onClick={() => props.updateValues({ sessions: [...values.sessions, { ...defaultSession }]})} 
							style={{ marginLeft: 10, padding: 10 }}
						>
							Add Session
						</Button>
					}
				/>
			}
			<LDResourcePicker 
				title="Add Attachment to Course"
				filePlaceholderText="Click here to select attachment file"
				open={showResourcePicker}
				onOk={(resource, file) => {
					if (file) setFormFiles([...formFiles, file]);

					props.updateValues({ resources: [...resources, resource] });
					setShowResourcePicker(false);
				}}
				onClose={() => setShowResourcePicker(false)}
				resourceTypes={["File"]}
			/>
		</React.Fragment>
	);
};

LDCourse.propTypes = { 
	canEdit: PropTypes.bool,
	ldCourse: PropTypes.object,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	canSubmitActivities: PropTypes.bool.isRequired,
	canSubmitTools: PropTypes.bool.isRequired
};

LDCourse.defaultProps = {
	canEdit: false,
	canSelectTenant: false,
	ldCourse: {},
	onDelete: () => null,
	isNew: false
};

export default withForm(form)(LDCourse);
