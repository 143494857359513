import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, IconButton, Avatar as MuiAvatar } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import uniqueId from 'lodash/uniqueId';

const AvatarUploader = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("avatar-uploader-"));
	const [temp, setTemp] = React.useState("");

	// Use a variable to store a processed version of the image temporarily
	const onChange = e => {
		const file = e.target.files[0];
		
		if (file) {
			const reader = new FileReader();
			reader.onload = ev => setTemp(ev.target.result);
			reader.readAsDataURL(file);

			props.onChange(file.name, file);
		}
	}

	// Reset temp in case value changes between renders
	React.useEffect(() => setTemp(""), [props.value]);

	return (
        <div style={props.style}>
			<span style={{ marginRight: 10 }}>{props.label || "Avatar"}</span>
			<input id={id} accept=".jpg, .jpeg, .png, .bmp" type="file" style={{ display: "none" }} onChange={onChange} />
			<label htmlFor={id}>
				<IconButton
                    component="span"
                    aria-label="Upload Avatar"
                    style={{ marginRight: 5 }}
                    size="large">
					{props.value === "" ? <PhotoCameraIcon /> : <MuiAvatar src={temp || props.value} imgProps={{ onError: props.onError }} />}
				</IconButton>
			</label>
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
		</div>
    );
});

AvatarUploader.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.object,
	helpText: PropTypes.node,
	onChange: PropTypes.func.isRequired,
	onError: PropTypes.func,
	broken: PropTypes.bool
};

AvatarUploader.defaultProps = {
	label: "",
	value: "",
	id: "",
	style: {},
	helpText: "",
	onError: () => null,
	broken: false
};

export default AvatarUploader;