import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ImportSkillsProfiles from './ImportSkillsProfiles';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';

const ImportSkillsProfilesController = props => {
	const [state, setState] = React.useState({
		isLoading: false
	});

	const importProfiles = (args, importFile) => {
		setState(prevState => ({ ...prevState, isLoading: true }));

		const formData = new FormData();
		formData.append("ProfileGroupId", args.profileGroupId);
		formData.append("ImportFile", importFile);
	
		post({
			url: '/api/skills-tracker/import-profiles',
			data: formData,
			onSuccess: (data) => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				if (data.success) {
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: (error) => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	return <PageLayout title="Import Skills Profiles" pageContent={
		<ImportSkillsProfiles importProfiles={importProfiles} loading={state.isLoading} />
	} breadcrumbs={[{ label: "Skills Profiles",  path: "/admin/skills-profiles" }, "Import Profiles"]} />;
};

ImportSkillsProfilesController.propTypes = { 
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportSkillsProfilesController);
