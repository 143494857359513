import React from 'react';
import PropTypes from 'prop-types';
import {
	Grid
} from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import { capabilityThemes } from '../../store/microlearn';

const form = {
	initValues: props => ({ ...props.microLearn }),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of Resource",
		},
		{
			name: "summary",
			label: "Summary",
			multiline: true,
			helpText: "(max 250 characters)",
			max: 250,
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true,
			required: false
		},
		{
			name: "video",
			label: "Video",
			type: "videoUploader",
			single: true
		},
		{
			name: "attachments",
			label: "Attachments",
			type: "attachmentEditor",
			download: attachment => {
				window.open(`/MicroLearn/DownloadAttachment?microLearnId=${attachment.microLearnId}&attachmentId=${attachment.microLearnAttachmentId}`, "_self");
			}
		},
		{
			name: "type",
			label: "Type",
			required: true,
			type: "select",
			items: ['Academic', 'Industry']
		},
		{
			name: "capabilityTheme",
			label: "Capability Theme",
			required: true,
			type: "select",
			items: capabilityThemes
		},
		{
			name: "creatorName",
			label: "Creator Name"
		},
		{
			name: "creatorInstitution",
			label: "Creator Institution"
		},
		{
			name: "creatorLinkedIn",
			label: "Creator LinkedIn"
		},
		{
			name: "creatorProfileUrl",
			label: "Creator Profile Url"
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor",
			scope: "MicroLearn"
		}
	]
};

const EditMicroLearn = props => {
	const { microLearn, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} Resource`} loading={loading} className="edit-microlearn">
			<Form onSubmit={() => props.validateFields() && props.saveMicroLearn(props.values, props.formFiles.image, props.formFiles.video, props.formFiles.attachments)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.title()}</Grid>
					<Grid item xs={12}>{fields.summary()}</Grid>
					<Grid item xs={12} sm={6}>{fields.type()}</Grid>
					<Grid item xs={12} sm={6}>{fields.capabilityTheme()}</Grid>
					<Grid item xs={12} sm={6}>{fields.creatorName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.creatorInstitution()}</Grid>
					<Grid item xs={12} sm={6}>{fields.creatorLinkedIn()}</Grid>
					<Grid item xs={12} sm={6}>{fields.creatorProfileUrl()}</Grid>
					<Grid item xs={12}>{fields.image()}</Grid>
					<Grid item xs={12}>{fields.video()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} Resource</Button>
					{!isNew &&
						<Button className="delete-button" loading={loading} onClick={() => props.deleteMicroLearn(microLearn.microLearnId)}>
							Delete Resource
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditMicroLearn.propTypes = {
	microLearn: PropTypes.object,
	saveMicroLearn: PropTypes.func.isRequired,
	deleteMicroLearn: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired
};

EditMicroLearn.defaultProps = {
	microLearn: {},
	deleteMicroLearn: null,
	isNew: false
};

export default withForm(form)(EditMicroLearn);