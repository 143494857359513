import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Collapse, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import LockIcon from '@mui/icons-material/Lock';
import { toggleSideMenu } from '../store/layout';
import { canViewProps } from '../store/security';
import { searchOffers } from '../store/offers';
import { changeLocation } from '../store/location';
import { searchProblems } from '../store/problems';
import { searchEvents } from '../store/events';
import { logOut } from '../store/context';
import map from 'lodash/map';

const disabledTooltip = <span style={{ fontSize: 13.5 }}>Sorry, your subscription does not provide you access to this area. Contact us to enquire.</span>;

const SidePanel = React.memo(props => {
	const [open, setOpen] = React.useState({});
	const [search, setSearch] = React.useState("");

	const { names = [], labels, icons, disabled, items } = props.userMenu;

	const searchAll = e => {
		e.preventDefault();
		props.search(search);
	}

	const renderMenu = (name, index) => {
		const menuItems = items[name];
		if (!menuItems || menuItems.length === 0) return null;

		// Show dropdown if menu is not disabled and either there is more than one item or the only item is disabled
		const showDropdown = !disabled[name] && (menuItems.length > 1 || menuItems[0].disabled);
		const menuProps = {
			key: index,
			label: labels[name],
			Icon: disabled[name] ? LockIcon : icons[name],
			disabled: disabled[name]
		};

		return showDropdown ? (
			<Menu {...menuProps} name={name}>
				{map(menuItems, (item, i) => <MenuItem key={i} {...item} />)}
			</Menu>
		) : <MenuItem {...menuProps} path={menuItems[0].path} />;
	}

	const Menu = ({ name, label, Icon, children }) => 
		<React.Fragment>
			<ListItem selected={name === props.active} button onClick={() => setOpen({ ...open, [name]: !open[name] })}>
				{Icon && <ListItemIcon><Icon /></ListItemIcon>}
				<ListItemText primary={label} />
				{open[name] ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open[name]} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{children}
				</List>
			</Collapse>
		</React.Fragment>

	const MenuItem = ({ label, path, Icon, disabled }) => disabled ?
		<Tooltip title={disabledTooltip} placement="bottom-start">
			<span>
				<ListItem button disabled>
					<ListItemIcon><LockIcon /></ListItemIcon>
					<ListItemText primary={label} />
				</ListItem>
			</span>
		</Tooltip> :
		<ListItem selected={Icon && path === props.location.pathname} button component={Link} to={path} onClick={() => props.toggleSidePanel(false)}>
			{Icon && <ListItemIcon><Icon /></ListItemIcon>}
			<ListItemText inset={!Icon} primary={label} />
		</ListItem>;

	return (
		<Drawer
			variant="persistent"
			open={props.isOpen}
			className="side-panel"
			SlideProps={{ unmountOnExit: true }}
		>
			<form className="search-form" onSubmit={searchAll}>
				<input type="text" name="query" className="search-form__input" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} aria-label="Search" />
				<input type="image" className="search-form__button" value="Search" src="/images/search-icon.png" alt="Search" width="30px" height="30px" />
			</form>
			<List component="nav" style={{ padding: 0 }}>
				{map(names, renderMenu)}
				<Divider />
				<ListItem button onClick={props.logOut}>
					<ListItemIcon><LogoutIcon /></ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItem>
			</List>
		</Drawer>
	);
});

SidePanel.propTypes = {
	userMenu: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	canView: PropTypes.object.isRequired,
	toggleSidePanel: PropTypes.func.isRequired,
	searchAll: PropTypes.func.isRequired,
	logOut: PropTypes.func.isRequired,
	active: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	userMenu: state.menu.userMenu,
	isOpen: state.layout.isSideMenuOpen,
	canView: canViewProps(state)
});

const mapDispatchToProps = dispatch => ({
	toggleSidePanel: isOpen => dispatch(toggleSideMenu(isOpen)),
	searchAll: search => [
		dispatch(toggleSideMenu(false)),
		dispatch(changeLocation(`/search?search=${encodeURIComponent(search)}`)),
		dispatch(searchProblems(search)),
		dispatch(searchEvents(search)),
		dispatch(searchOffers(search))
	],
	logOut: () => [dispatch(toggleSideMenu(false)), dispatch(logOut())]
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidePanel));