import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	mentors: [],
	mentor: {}
};

const CLEAR_MENTORS = "CLEAR_MENTORS";
const GET_MENTORS_REQUEST = "GET_MENTORS_REQUEST";
const GET_MENTORS_SUCCESS = "GET_MENTORS_SUCCESS";
const GET_MENTORS_FAILURE = "GET_MENTORS_FAILURE";
const SEARCH_MENTORS_REQUEST = "SEARCH_MENTORS_REQUEST";
const SEARCH_MENTORS_SUCCESS = "SEARCH_MENTORS_SUCCESS";
const SEARCH_MENTORS_FAILURE = "SEARCH_MENTORS_FAILURE";
const GET_MENTOR_REQUEST = "GET_MENTOR_REQUEST";
const GET_MENTOR_SUCCESS = "GET_MENTOR_SUCCESS";
const GET_MENTOR_FAILURE = "GET_MENTOR_FAILURE";
const SAVE_MENTOR_REQUEST = "SAVE_MENTOR_REQUEST";
const SAVE_MENTOR_SUCCESS = "SAVE_MENTOR_SUCCESS";
const SAVE_MENTOR_FAILURE = "SAVE_MENTOR_FAILURE";
const DELETE_MENTOR_REQUEST = "DELETE_MENTOR_REQUEST";
const DELETE_MENTOR_SUCCESS = "DELETE_MENTOR_SUCCESS";
const DELETE_MENTOR_FAILURE = "DELETE_MENTOR_FAILURE";

export const isLoading = createLoadingSelector(["GET_MENTORS", "SEARCH_MENTORS", "GET_MENTOR"]);

export const getMentors = () =>
	createFetchAction({
		url: "/api/mentors",
		startAction: GET_MENTORS_REQUEST,
		onError: error => [getMentorsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMentorsSuccess(data)
	});

export const getMentorsSuccess = data => ({ type: GET_MENTORS_SUCCESS, payload: { data } });
export const getMentorsFailure = error => ({ type: GET_MENTORS_FAILURE, payload: { error } });

export const searchMentors = args => 
	createFetchAction({
		url: `/api/mentors/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_MENTORS_REQUEST,
		onError: error => [searchMentorsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchMentorsSuccess(data)
	});

export const searchMentorsSuccess = data => ({ type: SEARCH_MENTORS_SUCCESS, payload: { data } });
export const searchMentorsFailure = error => ({ type: SEARCH_MENTORS_FAILURE, payload: { error } });

export const getMentor = mentorId =>
	createFetchAction({
		url: `/api/mentors/${mentorId}`,
		startAction: GET_MENTOR_REQUEST,
		onError: error => [getMentorFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMentorSuccess(data)
	});

export const getMentorSuccess = data => ({ type: GET_MENTOR_SUCCESS, payload: { data } });
export const getMentorFailure = error => ({ type: GET_MENTOR_FAILURE, payload: { error } });

export const saveMentor = (mentor, imageFile) => {
	const formData = new FormData();
	formData.append("Mentor", JSON.stringify(mentor));
	
	if (imageFile) formData.append("ImageFile", imageFile);

	return createFormPostAction({
		url: "/api/mentors",
		data: formData,
		startAction: SAVE_MENTOR_REQUEST,
		onError: error => [saveMentorFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveMentorSuccess(data), showSuccessNotification(data.message), changeLocation(`/mentors/${data.object.mentorId}`)];
			} else {
				return [saveMentorSuccess(data), showErrorNotification(data.message)];
			}
		}
	})
}

export const saveMentorSuccess = data => ({ type: SAVE_MENTOR_SUCCESS, data });
export const saveMentorFailure = error => ({ type: SAVE_MENTOR_FAILURE, error });

export const deleteMentor = mentorId =>
	createPostAction({
		url: `/api/mentors/${mentorId}/delete`,
		startAction: DELETE_MENTOR_REQUEST,
		onError: error => [deleteMentorFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteMentorSuccess(data), showSuccessNotification(data.message), changeLocation("/mentors")];
			} else {
				return [deleteMentorSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteMentorSuccess = data => ({ type: DELETE_MENTOR_SUCCESS, data });
export const deleteMentorFailure = error => ({ type: DELETE_MENTOR_FAILURE, error });

const formatMentor = mentor => ({
	...mentor,
	professionalQualifications: DOMPurify.sanitize(mentor.professionalQualifications),
	executiveExperience: DOMPurify.sanitize(mentor.executiveExperience)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_MENTORS:
			return { ...state, mentors: [] };
		case GET_MENTORS_REQUEST:
			return { ...state, mentors: [], isLoading: true };
		case SEARCH_MENTORS_REQUEST:
			return { ...state, isLoading: true };
		case GET_MENTORS_SUCCESS:
		case SEARCH_MENTORS_SUCCESS:
			return { ...state, mentors: map(action.payload.data, formatMentor), isLoading: false };
		case GET_MENTOR_REQUEST:
			return { ...state, mentor: {}, isLoading: true }
		case GET_MENTOR_SUCCESS:
			return { ...state, mentor: formatMentor(action.payload.data), isLoading: true };
		case SAVE_MENTOR_REQUEST:
		case DELETE_MENTOR_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_MENTOR_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_MENTOR_SUCCESS:
			return { ...state, mentors: state.mentors.filter(a => a.mentorId !== action.data.objectId) };
		default:
			return state;
	}
};