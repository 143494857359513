import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Typography, Divider } from '@mui/material';
import { LinkedInButton, WebsiteButton } from '../widgets/SocialMediaButtons';
import Card from '../widgets/Card';
import Avatar from '../widgets/Avatar';
import Tag from '../widgets/Tag';
import ButtonLink from '../widgets/ButtonLink';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

const ViewAcademic = props => {
	const { academic, canEdit } = props,
		{ linkedInUrl, institutionProfileUrl } = academic;

	return props.loading || isEmpty(academic) ? <CircularProgress size={24} style={{ marginLeft: 10 }} /> : (
		<Grid container spacing={4} className="academic-container">
			<Grid item xs="auto">
				<Card className="contact-info">
					<div style={{ textAlign: "center", padding: "1.25rem" }}>
						{/* Avatar */}
						<Avatar src={academic.avatar} fullName={academic.fullName} length={150} />
						{/* Name + Role */}
						<div style={{ paddingTop: 10 }}>
							<Typography variant="h6">{academic.fullName}</Typography>
							{academic.organisationLogo && <img className="logo" src={academic.organisationLogo} alt="Logo" />}
							{academic.organisationName}
						</div>
					</div>
					<Divider />
					<div style={{ padding: "1.25rem" }}>
						{canEdit && 
							<React.Fragment>
								<Typography variant="caption">Email</Typography>
								{academic.email}
							</React.Fragment>
						}
						{/* Social Links */}
						{(linkedInUrl || institutionProfileUrl) &&
							<React.Fragment>
								<Typography variant="caption" style={{ marginTop: 20 }}>Social links</Typography>
								{linkedInUrl && <LinkedInButton length={35} link={linkedInUrl} />}
								{institutionProfileUrl && <WebsiteButton length={35} link={institutionProfileUrl} />}
							</React.Fragment>
						}
					</div>
				</Card>
				<ButtonLink to={`/academics/edit/${academic.academicId}`}>Edit academic</ButtonLink>
			</Grid>
			<Grid item xs="auto" md>
				<Card style={{ marginBottom: 0 }}>
					<span className="h5">Faculty</span>
					{academic.faculty}
					<span className="h5" style={{ marginTop: 20 }}>Qualifications</span>
					{academic.qualifications}
					<span className="h5" style={{ marginTop: 20 }}>Courses taught</span>
					{academic.programs}
					<span className="h5" style={{ marginTop: 20 }}>Key research interests</span>
					{academic.researchInterests}
					<span className="h5" style={{ marginTop: 20 }}>Professional summary</span>
					<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: academic.professionalSummary }} />
					<span className="h5" style={{ marginTop: 20 }}>Tags</span>
					{map(academic.tags, (t, i) => <Tag key={i} label={t.name} />)}
				</Card>
			</Grid>
		</Grid>
	);
};

ViewAcademic.propTypes = {
	academic: PropTypes.shape({
		academicId: PropTypes.number,
		organisationId: PropTypes.number,
		organisationName: PropTypes.string,
		country: PropTypes.string,
		organisationType: PropTypes.string,
		organisationLogo: PropTypes.string,
		fullName: PropTypes.string,
		email: PropTypes.string,
		role: PropTypes.string,
		faculty: PropTypes.string,
		businessUnit: PropTypes.string,
		qualifications: PropTypes.string,
		programs: PropTypes.string,
		researchInterests: PropTypes.string,
		professionalSummary: PropTypes.string,
		institutionProfileUrl: PropTypes.string,
		programEnrolled: PropTypes.string,
		graduationYear: PropTypes.number,
		phone: PropTypes.string,
		privateEmail: PropTypes.string,
		linkedInUrl: PropTypes.string,
		icon: PropTypes.string,
		channelId: PropTypes.number,
		avatar: PropTypes.string,
		tags: PropTypes.array
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool
};

ViewAcademic.defaultProps = {
	canEdit: false
};

export default ViewAcademic;