import Button from '../widgets/Button';
import { styled } from '@mui/material/styles';
import { Icon } from '@mui/material';
import GoogleIcon from '../images/icons/google.svg';

const StyledButton = styled(Button)({
	borderRadius: '4px',
	border: '1px solid #dadce0',
	color: '#3c4043',
	backgroundColor: '#fff',
	boxShadow: 'none',

	'&:hover': {
		borderColor: '#d2e3fc',
		backgroundColor: '#fff',
		boxShadow: 'none',
	}
});

const GoogleButton = () => {
    return (
        <StyledButton
            fullWidth
            href="/auth/google"
            startIcon={
                <Icon>
                    <img src={GoogleIcon} style={{ verticalAlign: 'top' }} alt="" />
                </Icon>
            }
        >
            Sign in with Google
        </StyledButton>
    );
}

export default 	GoogleButton;
