/* eslint react/jsx-pascal-case: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import { 
	searchCLO_Courses, 
	isLoading, 
	deleteCLO_Course, 
	copyCLO_Course 
} from '../../store/cloCourses';
import { showModal } from '../../store/modal';
import { canEditCLO_Course, canSubmitCLO_Courses } from '../../store/security';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import PagePagination from '../widgets/PagePagination';
import CLO_CourseCard from './CLO_CourseCard';
import CopyCLO_CourseDialog from './CopyCLO_CourseDialog';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';

const courseTypes = ["In-House", "Short Course / Micro-Credential", "Award Course", "Platform Provider Course"];

let timer = null;
const form = {
	initValues: props => ({ ...props.searchArgs, _pageNum: props.pageNum, _pageSize: props.pageSize }),
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchCLO_Courses(values, 1, values._pageSize), ["search", "provider"].includes(name) ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "category",
			label: "Category",
			type: "select",
			defaultValue: "all",
			items: [{ label: "(All)", value: "all" }, ...courseTypes]
		},
		{
			name: "channel",
			label: "Channel",
			type: "autoComplete",
			defaultValue: "all",
			loadItems: { route: "channels", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "provider",
			placeholder: "Provider",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "mode",
			label: "Mode",
			type: "select",
			defaultValue: "all",
			items: [{ label: "(All)", value: "all" }, "Online", "Digital Classroom", "Face-to-face", "Blended"]
		},
		{
			name: "order",
			label: "Order By",
			type: "select",
			defaultValue: "Relevance",
			items: ["Relevance", "Title", "Provider", "Favourite"]
		},
		{
			name: 'acceleratorCourses',
			label: 'Accelerator Courses',
			type: 'checkbox'
		}
	]
};

const CLO_CoursesController = props => {
	const [copier, setCopier] = React.useState({ open: false, course: {} });

	React.useEffect(() => {
		props.searchCLO_Courses(props.searchArgs, 1, 25);
	}, []);


	const orderedByType = orderBy(props.courses, [(i) => courseTypes.indexOf(i.type)]);
	const sorted = reduce(orderedByType, (acc, c) => {
		(acc[c.type] || (acc[c.type] = [])).push(c);
		return acc;
	}, {});

	return <PageLayout title="Courses" pageContent={
		<React.Fragment>
			{props.canSubmit &&
				<div style={{ textAlign: "right" }}>
					<ButtonLink to="/clo-courses/create">Add Course</ButtonLink>
				</div>
			}
			<Card style={{ margin: "20px 0", zIndex: 10 }}>
				<Grid container spacing={3} alignItems="flex-end">
					<Grid item xs={12}>{props.fields.search()}</Grid>
					<Grid item xs={12} sm={4}>{props.fields.category()}</Grid>
					<Grid item xs={12} sm={4}>{props.fields.channel()}</Grid>
					<Grid item xs={12} sm={4}>{props.fields.acceleratorCourses()}</Grid>
					<Grid item xs={12} sm>{props.fields.provider()}</Grid>
					<Grid item xs={12} sm={3}>{props.fields.mode()}</Grid>
					<Grid item xs={12} sm={3}>{props.fields.order()}</Grid>
				</Grid>
			</Card>
			{props.courses.length === 0 && <p>No courses found...</p>}
			{map(Object.keys(sorted), type =>
				<React.Fragment key={type}>
					<h2 style={{ marginTop: 20 }}>{type}</h2>
					<Grid container spacing={3}>
						{map(sorted[type], (c, i) => 
							<CLO_CourseCard 
								key={i} 
								course={c} 
								canEdit={props.canEdit(c)} 
								deleteCLO_Course={props.deleteCLO_Course}
								copyCLO_Course={() => setCopier({ open: true, course: c })}
								organisationId={props.organisationId}
							/>
						)}
					</Grid>
				</React.Fragment>
			)}
			<PagePagination
				pageNum={props.pageNum}
				pageSize={props.pageSize}
				total={props.total}
				totalPages={props.totalPages}
				onChangePage={page => {
					props.updateValues({
						...props.values,
						_pageNum: page,
						_pageSize: props.pageSize
					});
					props.searchCLO_Courses(props.values, page, props.pageSize);
				}}
				onChangeRowsPerPage={rowsPerPage => {
					props.updateValues({
						...props.values,
						_pageNum: 1,
						_pageSize: rowsPerPage
					});
					props.searchCLO_Courses(props.values, 1, rowsPerPage);
				}}
			/>

			<CopyCLO_CourseDialog
				title={`${copier.course.title} - Copy`}
				open={copier.open} 
				onClose={() => setCopier({ open: false, course: {} })} 
				copyCLO_Course={(title) => {
					props.copyCLO_Course(copier.course.clO_CourseId, title);
					setCopier({ open: false, course: {} });
				}}
			/>
		</React.Fragment>
	} breadcrumbs={["Courses"]} />;
};

CLO_CoursesController.propTypes = { 
	courses: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	organisation: PropTypes.string.isRequired,
	canEdit: PropTypes.func.isRequired,
	searchCLO_Courses: PropTypes.func.isRequired,
	deleteCLO_Course: PropTypes.func.isRequired,
	copyCLO_Course: PropTypes.func.isRequired,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number
};

CLO_CoursesController.defaultProps = {
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null
};

const mapStateToProps = (state, ownProps) => ({
	courses: state.cloCourses.cloCourses,
	pageNum: state.cloCourses.pageNum,
	pageSize: state.cloCourses.pageSize,
	total: state.cloCourses.total,
	totalPages: state.cloCourses.totalPages,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	organisation: state.context.organisationTitle || "",
	searchArgs: mapValues(
		pick(getQueryParameters(ownProps.location.search), "search", "category", "channel", "provider", "mode", "businessProfileId", "order", "acceleratorCourses"),
		(value, key) => key === "businessProfileId" ? (value ? parseInt(value, 10) : value) : value
	),
	canSubmit: canSubmitCLO_Courses(state),
	canEdit: canEditCLO_Course(state)
});

const mapDispatchToProps = dispatch => ({
	searchCLO_Courses: (args, pageNum, pageSize) => dispatch(searchCLO_Courses(args, pageNum, pageSize)),
	deleteCLO_Course: courseId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this course?",
		onOk: () => dispatch(deleteCLO_Course(courseId))
	})),
	copyCLO_Course: (courseId, title) => 
		dispatch(copyCLO_Course(courseId, title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CLO_CoursesController));