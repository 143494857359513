import React from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import { canViewHome } from '../store/security';
import Home from './home/Home';

// Note: might cause a loop if the firstItemPath is home or redirects to home
// Commented out for now to be safe
const HomeWrapper = props => {
	if (props.canViewHome) {
		return <Home {...props} />;
	// } else if (props.firstItemPath) {
	// 	return <Redirect to={props.firstItemPath} />
	} else {
		return <div />;
	}
};

const mapStateToProps = state => {
	const { names, items } = state.menu.userMenu;
	
	return {
		isLoggedIn: state.context.isLoggedIn,
		canViewHome: canViewHome(state),
		firstItemPath: names && items[names[0]].length > 0 ? items[names[0]][0].path : ""
	};
};

export default connect(mapStateToProps, null)(HomeWrapper);