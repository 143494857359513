import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Select from '../widgets/Select';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';

const TeamSelector = props => {
	return (
		<div className="team-selector">
			<Select
				value={props.teamId}
				label="Team"
				fullWidth={false}
				displayEmpty={true}
				style={{ width: 300, marginRight: '10px' }}
				onChange={e => props.onChangeTeam(e.target.value)}
				items={map(props.teams, t => ({ label: t.name, value: t.teamId }))}
			/>

			{props.isTeamManager && 
				<ButtonLink 
					style={{ 
						float: "right",
						marginTop: "5px",
						marginLeft: "5px"
					}} 
					disabled={!props.teamId}
					to={`/teams/edit/${props.teamId}`}
				>
					Edit Team
				</ButtonLink>
			}
			<Button 
				style={{ 
					float: "right",
					marginTop: "5px"
				}}
				disabled={!props.teamId}
				onClick={props.onShowCompetencies}
			>
				View Competencies
			</Button>
		</div>
	);
}

TeamSelector.propTypes = {
	onChangeTeam: PropTypes.func.isRequired,
	onShowCompetencies: PropTypes.func.isRequired,
	teamId: PropTypes.number,
	teams: PropTypes.array,
	isTeamManager: PropTypes.bool
};

TeamSelector.defaultProps = {
	teamId: null,
	teams: [],
	isTeamManager: false
};

export default TeamSelector;
