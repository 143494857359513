import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	frameworks: [],
	framework: {}
};

const CLEAR_FRAMEWORKS = "CLEAR_FRAMEWORKS";
const GET_FRAMEWORKS_REQUEST = "GET_FRAMEWORKS_REQUEST";
const GET_FRAMEWORKS_SUCCESS = "GET_FRAMEWORKS_SUCCESS";
const GET_FRAMEWORKS_FAILURE = "GET_FRAMEWORKS_FAILURE";
const SEARCH_FRAMEWORKS_REQUEST = "SEARCH_FRAMEWORKS_REQUEST";
const SEARCH_FRAMEWORKS_SUCCESS = "SEARCH_FRAMEWORKS_SUCCESS";
const SEARCH_FRAMEWORKS_FAILURE = "SEARCH_FRAMEWORKS_FAILURE";
const GET_FRAMEWORK_REQUEST = "GET_FRAMEWORK_REQUEST";
const GET_FRAMEWORK_SUCCESS = "GET_FRAMEWORK_SUCCESS";
const GET_FRAMEWORK_FAILURE = "GET_FRAMEWORK_FAILURE";
const SAVE_FRAMEWORK_REQUEST = "SAVE_FRAMEWORK_REQUEST";
const SAVE_FRAMEWORK_SUCCESS = "SAVE_FRAMEWORK_SUCCESS";
const SAVE_FRAMEWORK_FAILURE = "SAVE_FRAMEWORK_FAILURE";
const DELETE_FRAMEWORK_REQUEST = "DELETE_FRAMEWORK_REQUEST";
const DELETE_FRAMEWORK_SUCCESS = "DELETE_FRAMEWORK_SUCCESS";
const DELETE_FRAMEWORK_FAILURE = "DELETE_FRAMEWORK_FAILURE";

export const isLoading = createLoadingSelector(["GET_FRAMEWORKS", "SEARCH_FRAMEWORKS", "GET_FRAMEWORK"]);

export const getFrameworks = organisationId =>
	createFetchAction({
		url: `/api/frameworks?organisationId=${organisationId || 0}`,
		startAction: GET_FRAMEWORKS_REQUEST,
		onError: error => [getFrameworksFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getFrameworksSuccess(data)
	});

export const getFrameworksSuccess = data => ({ type: GET_FRAMEWORKS_SUCCESS, payload: { data } });
export const getFrameworksFailure = error => ({ type: GET_FRAMEWORKS_FAILURE, payload: { error } });

export const searchFrameworks = search => 
	createFetchAction({
		url: `/api/frameworks/search?search=${search || ""}`,
		startAction: SEARCH_FRAMEWORKS_REQUEST,
		onError: error => [searchFrameworksFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchFrameworksSuccess(data)
	});

export const searchFrameworksSuccess = data => ({ type: SEARCH_FRAMEWORKS_SUCCESS, payload: { data } });
export const searchFrameworksFailure = error => ({ type: SEARCH_FRAMEWORKS_FAILURE, payload: { error } });

export const getFramework = frameworkId =>
	createFetchAction({
		url: `/api/frameworks/${frameworkId}`,
		startAction: GET_FRAMEWORK_REQUEST,
		onError: error => [getFrameworkFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getFrameworkSuccess(data)
	});

export const getFrameworkSuccess = data => ({ type: GET_FRAMEWORK_SUCCESS, payload: { data } });
export const getFrameworkFailure = error => ({ type: GET_FRAMEWORK_FAILURE, payload: { error } });

export const saveFramework = (framework, importFile, imageFile) => {
	const formData = new FormData();
	formData.append("Framework", encodeURIComponent(JSON.stringify(framework)));

	if (importFile) formData.append("ImportFile", importFile);
	if (imageFile) formData.append("ImageFile", imageFile);

	return createFormPostAction({
		url: "/api/frameworks",
		data: formData,
		startAction: SAVE_FRAMEWORK_REQUEST,
		onError: error => [saveFrameworkFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveFrameworkSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/frameworks")];
			} else {
				return [saveFrameworkSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveFrameworkSuccess = data => ({ type: SAVE_FRAMEWORK_SUCCESS, data });
export const saveFrameworkFailure = error => ({ type: SAVE_FRAMEWORK_FAILURE, error });

export const deleteFramework = frameworkId =>
	createPostAction({
		url: `/api/frameworks/${frameworkId}/delete`,
		startAction: DELETE_FRAMEWORK_REQUEST,
		onError: error => [deleteFrameworkFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteFrameworkSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/frameworks")];
			} else {
				return [deleteFrameworkSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteFrameworkSuccess = data => ({ type: DELETE_FRAMEWORK_SUCCESS, data });
export const deleteFrameworkFailure = error => ({ type: DELETE_FRAMEWORK_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_FRAMEWORKS:
			return { ...state, frameworks: []};
		case GET_FRAMEWORKS_REQUEST:
			return { ...state,frameworks: [], isLoading: true};
		case SEARCH_FRAMEWORKS_REQUEST:
			return { ...state, isLoading: true };
		case GET_FRAMEWORKS_SUCCESS:
		case SEARCH_FRAMEWORKS_SUCCESS:
			return { ...state, frameworks: action.payload.data, isLoading: false };
		case GET_FRAMEWORK_REQUEST:
			return { ...state, framework: {}, isLoading: true };
		case GET_FRAMEWORK_SUCCESS:
			return { ...state, framework: action.payload.data, isLoading: true };
		case SAVE_FRAMEWORK_REQUEST:
		case DELETE_FRAMEWORK_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_FRAMEWORK_SUCCESS:
			return {
				...state,
				...(action.data.success && { framework: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_FRAMEWORK_SUCCESS:
			return { ...state, frameworks: state.frameworks.filter(a => a.frameworkId !== action.data.objectId) };
		default:
			return state;
	}
};
