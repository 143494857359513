import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Select from '../widgets/Select';
import TextEditor from '../widgets/TextEditor';
import TextField from '../widgets/TextField';
import CopyContentDialog from './CopyContentDialog';
import map from 'lodash/map';
import isFuture from 'date-fns/isFuture';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import uniqueId from 'lodash/uniqueId';

const form = {
	initValues: ({ content = {}, organisationId }) => ({ 
		organisationId: organisationId, 
		...content,
		channels: map(content.channels, c => ({ ...c, label: c.name, value: c.channelId }))
	}),
	fields: [
		{
			name: "contentType",
			label: "Content Type",
			required: false,
			type: "select",
			items: ["Course Design", "Course Material", "Other"],
			helpText: "Type of Resource"
		},
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of Resource Bundle"
		},
		{
			name: "organisationId",
			label: "Provider",
			required: true,
			disabled: props => !props.canAdminContent,
			type: "autoComplete",
			loadItems: {
				route: "organisations/providers",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			helpText: "Resource Provider"
		},
		{
			name: "businessProfileId",
			label: "Business Profile",
			type: "autoComplete",
			loadItems: {
				route: "business-profiles",
				mapItem: p => ({ label: p.title, value: p.businessProfileId })
			},
			helpText: "Resource Provider"
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor",
			helpText: "Brief description of Resource Bundle"
		},
		{
			name: "expiryDate",
			label: "Review Date",
			type: "date",
			stripTime: true,
			validate: value => isFuture(new Date(value)) ? "" : "Expiry date must occur after today"
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor",
			scope: "Contents"
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment =>
				window.open(`/Content/DownloadAttachment?contentId=${attachment.contentId}&attachmentId=${attachment.contentAttachmentId}`, "_self"),
			attachmentTypes: ["Course Brochure", "Resource Bundle Description", "Non-Disclosure Agreement", "License Agreement"]
		},
		{
			name: "accredited",
			label: "APILP Accredited",
			type: "checkbox"
		},
		{
			name: "areaCategory",
			label: "Channel",
			required: false,
			type: "select",
			items: [
				"Business & Management", 
				"Coaching", 
				"Communications",
				"HR Management",
				"Leadership",
				"Organisation growth & resilience",
				"Program & project management",
				"Soft Skills"
			],
			helpText: "Channel"
		},
		{
			name: "useStandardLicenseAgreement",
			label: "Use Standard License Agreement",
			type: "checkbox"
		},
		{
			name: "thumbnail",
			label: "Thumbnail",
			type: "avatar"
		}
	]
};

const EditContent = props => {
	const [copyDialogOpen, setCopyDialogOpen] = React.useState(false);
	
	const { content, fields, loading, values, errorColour } = props;

	return (
        <Card>
			<Form onSubmit={() => props.validateFields() && props.saveContent(props.values, props.formFiles.attachments, props.formFiles.thumbnail)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.useStandardLicenseAgreement()}</Grid>
					<Grid item xs={12} sm={6}>{fields.areaCategory()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>

					<Grid item xs={12}>
						<span className="h3">
							Resource Bundle Summary
							<IconButton
                                onClick={() => { 
									props.updateValues({ 
										contentSummary: [...(values.contentSummary || []), { 
											_clientId:  uniqueId("content-summary-"),
											contentType: "Course Design",
											summaryDescription: ""
										}]
									});
								}}
                                style={{ marginLeft: 5 }}
                                size="large">
								<AddCircleIcon />
							</IconButton>
						</span>
						<Grid container spacing={3}>
							{(values.contentSummary || []).length === 0 && 
								<Grid item xs={12}>
									<p>Empty</p>
								</Grid>
							}
							{map(values.contentSummary, (s, i) =>
								<Grid item xs={12} sm={12} md={6}>
									<Card 
										className="content-summary-card"
									>
										<IconButton
                                            onClick={() => {
												const contentSummary = values.contentSummary;
												contentSummary.splice(i, 1);
												props.updateValues({ contentSummary: contentSummary });
											}}
                                            aria-label="Delete"
                                            style={{ 
												position: 'absolute',
												top: '0px',
												right: '0px',
												zIndex: 9
											}}
                                            size="large">
											<DeleteIcon style={{ color: errorColour }} fontSize="small" />
										</IconButton>
										<TextField
											label="Title"
											value={s.title} 
											onChange={e => {
												const contentSummary = [...values.contentSummary];
												contentSummary[i].title = e.target.value;
												props.updateValues({ contentSummary: contentSummary });
											}}
											style={{ marginBottom: '10px' }}
										/>
										<Select 
											label="Resource Type"
											items={[
												'Course Design',
												'Video',
												'Podcast',
												'Written material',
												'Images'
											]} 
											value={s.contentType} 
											onChange={e => {
												const contentSummary = [...values.contentSummary];
												contentSummary[i].contentType = e.target.value;
												props.updateValues({ contentSummary: contentSummary });
											}}
											style={{ marginBottom: '10px' }}
										/>
										<TextEditor 
											label="Summary Description"
											value={s.summaryDescription} 
											onChange={content => {
												const contentSummary = [...values.contentSummary];
												contentSummary[i].summaryDescription = content;
												props.updateValues({ contentSummary: contentSummary });
											}}
										/>
									</Card>
								</Grid>
							)}
						</Grid>
					</Grid>

					<Grid item xs={12} sm="auto">{fields.expiryDate()}</Grid>
					<Grid item xs={12} sm="auto">{fields.thumbnail()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>Save Resource Bundle</Button>
					{!props.isNew &&
						<React.Fragment>
							<Button className="delete-button" loading={loading} onClick={() => props.deleteContent(content.contentId)}>
								Delete Resource Bundle
							</Button>
							<Button 
								color="secondary" 
								loading={loading} 
								onClick={() => setCopyDialogOpen(true)}
								style={{ marginLeft: 10 }}	
							>
								Copy Resource Bundle
							</Button>
						</React.Fragment> 
					}
				</div>
			</Form>
			<CopyContentDialog 
				title={`${content.title} - Copy`}
				open={copyDialogOpen} 
				copyContent={title => {
					props.copyContent(content.contentId, title);
					setCopyDialogOpen(false);
				}}
				onClose={() => setCopyDialogOpen(false)} 
			/>
		</Card>
    );
};

EditContent.propTypes = { 
	content: PropTypes.object,
	saveContent: PropTypes.func.isRequired,
	deleteContent: PropTypes.func,
	copyContent: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number,
	organisations: PropTypes.array.isRequired,
	isNew: PropTypes.bool,
	canViewBusinessProfiles: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired
};

EditContent.defaultProps = {
	content: {},
	deleteContent: () => null,
	copyContent: () => null,
	organisationId: null,
	isNew: false
};

export default withForm(form)(EditContent);