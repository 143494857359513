import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../utils/ajax';
import { 
	Table, 
	TableHead, 
	TableRow, 
	TableCell, 
	TableBody 
} from '@mui/material';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';

const styles = () => ({
	memberRow: {
		'&:hover': {
			backgroundColor: '#ccc'
		}
	}
});

const TeamMemberList = ({ teamId, classes, setLoading, onMemberClick }) => {
	const [team, setTeam] = React.useState(null);

	const loadData = () => {
		setLoading(true);
		setTeam(null);
		get({
			url: `/api/teams/${teamId}`,
			onSuccess: data => {
				setLoading(false);
				setTeam(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (teamId) {
			loadData();
		}
	}, [teamId]);

	const members = team ? team.members || [] : [];
	return (
		<div style={{ overflow: 'auto', height: '100%' }}>
		<Table>
		<TableHead>
			<TableRow>
				<TableCell>Member</TableCell>
				<TableCell>Profile</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{map(members, (m, i) =>
				<TableRow 
					key={i} 
					className={classes.memberRow}
					onClick={() => {
						if (onMemberClick) onMemberClick(m);
					}}
					style={{ 
						cursor: onMemberClick ? 'pointer' : undefined 
					}}
				>
					<TableCell>{m.name}</TableCell>
					<TableCell>{m.calcSkillsProfileName}</TableCell>
				</TableRow>
			)}
		</TableBody>
	</Table>
	</div>
	);
};

TeamMemberList.propTypes = {
	teamId: PropTypes.number,
	onMemberClick: PropTypes.func,
	setLoading: PropTypes.func.isRequired
};

TeamMemberList.defaultProps = {
	teamId: null,
	onMemberClick: null
};


export default withStyles(TeamMemberList, styles);
