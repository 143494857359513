import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import EditMentor from './EditMentor';
import { isLoading, saveMentor } from '../../store/mentors';

const CreateMentorController = props => 
	<PageLayout title="Create Mentor" pageContent={
		<EditMentor saveMentor={props.saveMentor} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Mentors", path: `/mentors` }, "Create Mentor"]} />;

CreateMentorController.propTypes = {
	saveMentor: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveMentor: (mentor, imageFile) => dispatch(saveMentor(mentor, imageFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMentorController);