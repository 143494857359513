import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { withForm } from '../../utils/forms';
import Form from '../widgets/Form';
import Wizard from '../widgets/Wizard/Wizard';
import Card from '../widgets/Card';
import CheckIcon from '@mui/icons-material/Check';
import map from 'lodash/map';
import widgets from '../dashboardWidgets/dashboardWidgets';
import uniqueId from 'lodash/uniqueId';

const form = {
	initValues: props => props.group,
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Widget Title"
		},
		{
			name: "topicId",
			label: "Topic Id"
		}
	]
};

const AddWidgetDialog = props => {
	const { fields, values } = props;
	const [widget, setWidget] = React.useState(null);

	const onSubmit = () => {
		const savedWidget = {
			id: uniqueId("widget-"),
			type: widget.type
		};
		for (let i = 0, ii = widget.fields.length; i < ii; i++) {
			const field = widget.fields[i];
			savedWidget[field.name] = values[field.name];
		}
		props.onAdd(savedWidget);
	};

	const onSelectWidget = w => {
		setWidget(w);
		const values = { ...props.values };
		for (let i = 0, ii = w.fields.length; i < ii; i++) {
			const field = w.fields[i];
			if (field.default) {
				fields[field.name] = field.default;
			}
		}
		props.updateValues(values);
	};

	let widgetForm = null;
	let fnValidate = () => {};
	if (widget) {
		widgetForm = 
			<Form onSubmit={onSubmit}>
				<Grid container spacing={3}>
					{
						map(widget.fields, f =>
							<Grid key={f.name} item xs={12}>{fields[f.name]()}</Grid>
						)
					}
				</Grid>
			</Form>;
	}

	fnValidate = () => {
		for (let i = 0, ii = widget.fields.length; i < ii; i++) {
			const field = widget.fields[i];
			if (field.required && !values[field.name]) return false;
		}
		return true;
	}

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
	 		<DialogTitle>Add Widget</DialogTitle>
	 		<DialogContent style={{ overflow: 'visible' }}>
				<Wizard
					okButtonText="Add"
					onCancel={props.onClose}
					onComplete={onSubmit}
				 	steps={[
						{
							number: 1,
							title: 'Select Widget Type',
							validate: () => !!widget,
							content:
								<Grid key="select-widget-grid" container spacing={3}>
									{
										map(widgets, (w, i) => 
											<Grid key={`add-widget-${i}`} item xs={3} sm={6} md={4} lg={3}>
												<Card 
													title={w.name}
													ribbonStatus="success"
													ribbon={widget && widget.type === w.type ? <CheckIcon style={{ marginTop: '3px' }} /> : undefined}
													onClick={() => {onSelectWidget(w)}}
												>
												</Card>
											</Grid>
										)
									}
								</Grid>
						},
						{
							number: 2,
							title: 'Configure Widget',
							validate: fnValidate,
							content: widgetForm
						}
					]}
				>
				</Wizard>
			</DialogContent>
		</Dialog>
	);
};

AddWidgetDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired
};

AddWidgetDialog.defaultProps = {
	deleteGroup: null
};

export default withForm(form)(AddWidgetDialog);
