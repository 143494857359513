import React from 'react';
import PropTypes from 'prop-types';
import { 
	IconButton, 
	Grid 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { stripHtml } from '../../utils/utils';
import { withForm } from '../../utils/forms';
import UnitOfCompetencyPicker from './UnitOfCompetencyPicker';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import Card from '../widgets/Card';
import Button from '../widgets/Button';
import Form from '../widgets/Form';
import some from 'lodash/some';
import Checkbox from '../widgets/Checkbox';

const form = {
	initValues: props => props.skillsProfile,
	fields: [
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		},
		{
			name: "profileName",
			label: "Name",
			required: true
		},
		{
			name: "description",
			label: "Description",
			multiline: true,
			helpText: "Description of profile within profile picker"
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true,
			helpText: "Image used to represent profile within profile picker"
		},
		{
			name: "profileGroupId",
			label: "Profile Group",
			type: "profilePicker",
			groupOnly: true,
			organisationId: (props, values) => Number(values.organisationId)
		}
	]
};

const prioritySwitch = {
	"1": "High",
	"2": "Medium",
	"3": "Low"
};

const SkillsProfile = props => {
	const { skillsProfile: { units = [] }, loading, fields, errorColour } = props;
	const [state, setState] = React.useState({
		unitPickerOpen: false,
		unitViewerOpen: false
	});

	const viewUnit = (unit, elementId) =>
		setState({ ...state, unitViewerOpen: true, unitCode: unit.unitCode, elementId, selectedElements: unit.elements });

	return (
        <React.Fragment>
			<Form onSubmit={() => props.validateFields() && props.onSave(props.values, props.formFiles.image)}>
				<Card title="Edit Skills Profile" className="edit-skills-profile" loading={loading}>
					<Grid container spacing={3} style={{ marginBottom: 10 }}>
						<Grid item xs={12} md={6}>{fields.profileName()}</Grid>
						<Grid item xs={12} md={6}>{fields.organisationId()}</Grid>
						<Grid item xs={12} md={6}>{fields.description()}</Grid>
						<Grid item xs={12} md={6}>{fields.profileGroupId()}</Grid>
						<Grid item xs={12}>{fields.image()}</Grid>
					</Grid>
					<Button onClick={() => setState({ unitPickerOpen: true })}>Modify Units</Button>
					<DataTable 
						data={units} 
						emptyText="No units" 
						hidePagination
						detailData={c => c.elements} 	
						detailColumns={[	
							<DataTableColumn style={{ padding: 0, width: 35 }} renderer={(u, e) =>
								<Checkbox 
									checked={e.isSelected}
									onChange={evt => props.selectElement(u, e, evt.target.checked)}
								/>
							} />,
							<DataTableColumn colSpan={2} style={{ whiteSpace: "normal" }} renderer={(c, e) =>
								<a onClick={() => viewUnit(c, e.elementId)} style={{ cursor: "pointer" }}>
									<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />
								</a>
							} />
						]}
					>
						<DataTableColumn style={{ padding: 0, width: 35 }} headerStyle={{ padding: 0 }} renderer={u =>
							<IconButton
                                onClick={() => props.removeUnit(u.unitOfCompetencyId)}
                                style={{ padding: 2 }}
                                aria-label="Delete Competency"
                                size="large">
								<DeleteIcon style={{ color: errorColour }} />
							</IconButton>
						} />
						<DataTableColumn name="unitCode" label="Unit Code" renderer={u =>
							<a onClick={() => viewUnit(u)} style={{ cursor: "pointer" }}>{u.unitCode}</a>
						} />
						<DataTableColumn name="unitTitle" dataType="html" label="Unit" style={{ whiteSpace: "normal" }} renderer={u =>
							<React.Fragment>
								<strong>{stripHtml(u.unitTitle)}</strong>
								{u.unitDescriptionAndApplication && <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: u.unitDescriptionAndApplication }} />}
							</React.Fragment>
						} />
						<DataTableColumn label="Priority" renderer={u => prioritySwitch[u.priority] || ""} />
					</DataTable>
					<div className="form-buttons">
						<Button 
							type="submit" 
							warning={props.dirty} 
							error={some(props.error)} 
							loading={loading}
						>	
							Save
						</Button>
					</div>
				</Card>
			</Form>
			<UnitOfCompetencyPicker 
				open={state.unitPickerOpen}
				selectedUnits={units}
				onClose={() => {
					setState({ ...state, unitPickerOpen: false });
				}}
				onOk={units => {
					setState({ ...state, unitPickerOpen: false });
					props.addUnits(units);
				}}
			/>
		</React.Fragment>
    );
};

SkillsProfile.propTypes = {
	skillsProfile: PropTypes.object.isRequired,
	addUnits: PropTypes.func.isRequired,
	selectElement: PropTypes.func.isRequired,
	removeUnit: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	promptSelection: PropTypes.bool.isRequired,
	resetPromptSelection: PropTypes.func.isRequired,
	dirty: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired
};

SkillsProfile.defaultProps = {
	skillsProfile: {}
};

export default withForm(form)(SkillsProfile);