import React from 'react';

const HtmlDisplayField = ({ label, value }) =>
	<div className="html-display-field">
		{label && <label>{label}:</label>}
		<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: value }} />
	</div>;

HtmlDisplayField.propsTypes = {
};

HtmlDisplayField.defaultProps = {
};

export default HtmlDisplayField;
