import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import UserProblems from './UserProblems';
import { 
	getProblemsByAuthor,
	isLoading as isProblemsLoading
} from '../../store/problems';
import { 
	getUserInfo,
	isLoading as isUsersLoading
} from '../../store/users';
import capitalise from 'lodash/capitalize';

const UserProblemsController = props => {
	React.useEffect(() => {
		const { userId = props.currentUserId } = props.match.params;

		props.getProblems(userId);
		props.getUserInfo(userId);
	}, []);

	return (
		<PageLayout 
			title={capitalise(props.plural)} 
			pageContent={
				<UserProblems 
					problems={props.problems} 
					username={props.username} 
					loading={props.loading} 
					plural={props.plural} 
					primaryColour={props.primaryColour} 
				/>
			} 
			breadcrumbs={[
				`${capitalise(props.plural)} by User`
			]} 
		/>
	);
};

UserProblemsController.propTypes = { 
	problems: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentUserId: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	plural: PropTypes.string.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	problems: state.problems.problems,
	currentUserId: state.context.userId,
	username: state.users.user.userName || "",
	loading: isProblemsLoading(state) || isUsersLoading(state),
	plural: state.settings.plural,
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getProblems: userId => dispatch(getProblemsByAuthor(userId)),
	getUserInfo: userId => dispatch(getUserInfo(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProblemsController);