import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { prettifyDate } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import Avatar from '../widgets/Avatar';
import Chat from './Chat';
import map from 'lodash/map';

const ViewDiscussion = props => {
	const { discussion } = props;
	
	return (
		<div className="view-discussion">
			<Grid container direction="row-reverse" alignContent="flex-end" spacing={24}>
				<Grid item xs={12} md className="header">
					<Avatar src={discussion.authorAvatar} fullName={discussion.authorName} length={50} />
					<div className="subject">
						<h2>
							<span style={{ color: "#98aab3" }}>#{discussion.discussionId}</span> {discussion.subject}
						</h2>
					</div>
				</Grid>
			</Grid>
			<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: discussion.description }} />
			<div className="dates">
				Created {prettifyDate(discussion.createDate)}
				{discussion.updateDate && `, Updated ${prettifyDate(discussion.updateDate)}`}
			</div>
			{props.canEdit && 
				<ButtonLink to={`/collaborations/${discussion.collaborationId}/discussion/edit/${discussion.collaborationDiscussionId}`} style={{ marginTop: 10 }}>
					Edit details
				</ButtonLink>
			}
			<Chat
				title="Comments"
				currentUserId={props.currentUserId}
				messages={map(discussion.comments, c => ({ ...c, createDate: c.date }))}
				saveMessage={message => props.addComment(message)}
				canRemove={props.canRemoveChat}
				removeMessage={props.deleteComment}
				loading={props.commentsLoading}
				emptyText="There are no comments to display..."
			/>
		</div>
	);
};

ViewDiscussion.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	discussion: PropTypes.object.isRequired,
	getDiscussion: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	getComments: PropTypes.func.isRequired,
	addComment: PropTypes.func.isRequired,
	canRemoveChat: PropTypes.bool.isRequired,
	removeComment: PropTypes.func.isRequired,
	commentsLoading: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool.isRequired
};

ViewDiscussion.defaultProps = {
};

export default ViewDiscussion;
