import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveMenu, isLoading } from '../../../store/menu';
import PageLayout from '../../PageLayout';
import EditMenu from './EditMenu';

const CreateMenuController = props =>
	<PageLayout title="Create new menu" pageContent={
		<EditMenu saveMenu={props.saveMenu} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Menus", path: "/admin/menus" }, "Create menu"]} />;

CreateMenuController.propTypes = { 
	saveMenu: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveMenu: menu => dispatch(saveMenu(menu))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMenuController);
