import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import Impersonate from './Impersonate';
import { impersonate } from '../../store/context';
import { getUsers, isLoading } from '../../store/users';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';

const ImperonsateController = props => {
	React.useEffect(props.getUsers, []);

	const onImpersonate = (user) => {
		props.impersonate(user.userId, null);
	};

	return (
		<PageLayout 
			title="Manage users" 
			pageContent={
				<Impersonate 
					showTenant={props.isGlobalAdministrator}
					users={props.users} 
					loading={props.loading} 
					onImpersonate={onImpersonate}
					primaryColour={props.primaryColour}
					errorColour={props.errorColour}
				/>
			} 
			breadcrumbs={["Impersonate"]} 
		/>
	);
};

ImperonsateController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	users: state.users.users,
	loading: isLoading(state),
	errorColour: state.theme.errorColour,
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getUsers: () => dispatch(getUsers()),
	impersonate: (userId, onSuccess) => dispatch(impersonate(userId, onSuccess)),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImperonsateController);
