import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { 
	getUserApplications, 
	isLoading as isOffersLoading
} from '../../store/offers';
import { 
	getUser,
	clearUser,
	isLoading as isUsersLoading
} from '../../store/users';
import AllApplications from './AllApplications';

class UserApplicationsController extends React.Component {
	UNSAFE_componentWillMount() {
		const userId = this.props.match.params.userId;

		// Fetch user details if not viewing own application
		this.props.isOwn ? this.props.clearUser() : this.props.getUser(userId);
		this.props.getUserApplications(userId);
	}

	render = () =>
		<PageLayout title={`${this.props.isOwn ? "My" : "Offer"} Applications`} pageContent={
			<AllApplications
				user={this.props.user}
				applications={this.props.applications}
				loading={this.props.loading}
				isOwn={this.props.isOwn}
			/> 
		} breadcrumbs={[
			{ label: "Offers", path: "/offers" },
			"Applications"
		]} />;
};

UserApplicationsController.propTypes = { 
	isAdmin: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	applications: PropTypes.arrayOf(PropTypes.object).isRequired,
	isOwn: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	isAdmin: state.context.isAdministrator,
	user: state.users.user,
	applications: state.offers.applications,
	isOwn: state.context.userId === ownProps.match.params.userId,
	loading: isOffersLoading(state) || isUsersLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getUser: userId => dispatch(getUser(userId)),
	clearUser: () => dispatch(clearUser()),
	getUserApplications: userId => dispatch(getUserApplications(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserApplicationsController);
