import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { stripHtml, truncateString } from '../../utils/utils';
import Avatar from '../widgets/Avatar';
import Tag from '../widgets/Tag';
import unescape from 'lodash/unescape';
import map from 'lodash/map';
import EndorseButton from '../widgets/EndorseButton';

const ProblemRow = React.memo(({ problem: p }) => 
	<div className="problem-row">
		<div className="votes">
			<Tooltip title={p.authorName}>
				<div>
					<Avatar src={p.authorAvatar} fullName={p.authorName} length={50} />
				</div>
			</Tooltip>
			<span className="count">{p.votes}</span>
			<EndorseButton problemId={p.problemId} hasVoted={p.hasVoted} isClosed={p.status === "closed"} />
		</div>
		<div className="body">
			<Link className="h4" to={`/my-community/problems/${p.problemId}`}>{p.title}</Link>
			<span className="label">{p.channel}</span>
			<div className="description">{truncateString(unescape(stripHtml(p.description)), 250)}</div>
			<span className="label views">{p.views} views</span>
			{map(p.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
	</div>
);

ProblemRow.propTypes = {
	problem: PropTypes.shape({
		problemId: PropTypes.number,
		authorName: PropTypes.string,
		authorAvatar: PropTypes.string,
		votes: PropTypes.number,
		title: PropTypes.string,
		description: PropTypes.string,
		status: PropTypes.string,
		tags: PropTypes.array,
		createDate: PropTypes.string
	}).isRequired
};

export default ProblemRow;