/* eslint react/jsx-pascal-case: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveCLO_Course, isLoading } from '../../store/cloCourses';
import { get } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import EditCLO_Course from './EditCLO_Course';
import { canAdminCLO_Courses } from '../../store/security';

const CreateCLO_CourseController = props => {
	const [organisations, setOrganisations] = React.useState([]);

	React.useEffect(() => {
		get({
			url: "/api/organisations?type=Organisation",
			onSuccess: data => setOrganisations(data.map(o => ({ label: o.name, value: o.organisationId })))
		});
	}, []);

	return <PageLayout title="Create new course" pageContent={
		<EditCLO_Course 
			saveCLO_Course={props.saveCLO_Course(props.fromAdmin)} 
			loading={props.loading} 
			organisationId={props.organisationId} 
			canAdminCLO_Courses={props.canAdminCLO_Courses}
			organisations={organisations}
		/>
	} breadcrumbs={[{ label: "Courses", path:  "/clo-courses" }, "Create course"]} />;
};	

CreateCLO_CourseController.propTypes = {
	saveCLO_Course: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	fromAdmin: PropTypes.bool,
	canAdminCLO_Courses: PropTypes.bool.isRequired
};

CreateCLO_CourseController.defaultProps = {
	fromAdmin: false
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canAdminCLO_Courses: canAdminCLO_Courses(state)
});

const mapDispatchToProps = dispatch => ({
	saveCLO_Course: fromAdmin => (course, image, newFiles) => dispatch(saveCLO_Course(course, image, newFiles, fromAdmin))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCLO_CourseController);