import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import { IconButton } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import ClearIcon from '@mui/icons-material/Clear';
import { toggleSideMenu, collapseSideMenu, showFullMenu } from '../store/layout';
import { logOut } from '../store/context';
import { changeLocation } from '../store/location';
import { searchProblems } from '../store/problems';
import { searchEvents } from '../store/events';
import { searchOffers } from '../store/offers';
import { canSubscribe, canImpersonate } from '../store/security';
import LogoSrc from './images/logo/research2u_logo.png';
import Button from './widgets/Button';
import ButtonLink from './widgets/ButtonLink';
import { withStyles } from 'tss-react/mui';
import { post } from '../utils/ajax';
import {
	useStripe
} from '@stripe/react-stripe-js';
import { 
	showSuccessNotification, 
	showErrorNotification 
} from '../store/notifications';
import { Sling as Hamburger } from 'hamburger-react'

import {
	ClickAwayListener, Divider, List, ListItemIcon, ListItem,
	ListItemButton, Typography, ListItemText, ListItemAvatar, Avatar as MuiAvatar,
	CircularProgress, Grow,
	Paper, Badge
} from '@mui/material';
import { canViewProps } from '../store/security';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ImpersonateIcon from '@mui/icons-material/SupervisorAccount';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import NotificationRow from './widgets/NotificationRow';
import { Scrollbars } from 'react-custom-scrollbars-2';
import MessageIcon from '@mui/icons-material/Message';
import Popper from '@mui/material/Popper';
import Avatar from './widgets/Avatar';
import map from 'lodash/map';

const styles = (theme) => ({
	topbar: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText
	},
	subscribeButton: {
		backgroundColor: theme.palette.primary.main
	},
	appbarTenantTitle: {
		color: theme.palette.secondary.main
	},
	rightNav: {
		display: 'flex'
	},	
	listItemAvatar: {
		minWidth: 'auto'
	},
	avatar: {
		borderRadius: '12px',
		background: '#ECEEF5',
		border: `2px solid #ECEEF5`,
		color: '#7B849C'
	},
});

const TopBar = React.memo(props => {
	const stripe = useStripe();
	const [loading, setLoading] = React.useState(false);
	const [profileAnchor, setProfileAnchor] = React.useState(null);
	const [notificationsAnchor, setNotificationsAnchor] = React.useState(null);
	const { notifications, canView } = props;

	const closePopups = () => {
		setProfileAnchor(null);
		setNotificationsAnchor(null);
	};
	const togglePanel = panel => {
		closePopups();
	};
	const markNotification = n => {
		props.markNotification(n);
		togglePanel();
	};

	const subscribe = () => {
		const product = props.defaultProduct;
		setLoading(true);
		if (product.stripePriceId) {
			post({
				url: '/api/stripe/create-session',
				data: {
					priceId: product.stripePriceId
				},
				onSuccess: (data) => {
					setLoading(false);
					stripe.redirectToCheckout({
						// Make the id field from the Checkout Session creation API response
						// available to this file, so you can provide it as parameter here
						// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
						sessionId: data
					}).then(function (result) {
					});
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		} else {
			// No stripe product/price assigned to product so create subscription and return
			post({
				url: '/api/subscription/subscribe',
				data: {
					productId: product.productId
				},
				onSuccess: (data) => {
					setLoading(false);
					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToSubscriptionComplete();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		}
	};

	let SubscribeButton = null;
	if (props.defaultProduct) {
		SubscribeButton = 
			<Button 
				loading={loading}
				className={`subscribe-button ${props.classes.subscribeButton}`} 
				onClick={subscribe}
			>Upgrade</Button>;
	} else {
		SubscribeButton = <ButtonLink className={`subscribe-button ${props.classes.subscribeButton}`} to="/pricing">Upgrade</ButtonLink>;
	}

	return (
        <header className={`top-bar mui-fixed ${props.classes.topbar}`}>
            <nav className="top-appbar">
				<div className="side-menu-toggle">
					<Hamburger 
						color={props.secondaryColourText}
						size={20} 
						toggled={!props.isSideMenuCollapsed} 
						toggle={open => props.collapseSideMenu(!open)} 
					/>
				</div>
				<div className="full-menu-toggle">
					<Hamburger 
						color={props.secondaryColourText}
						size={20} 
						toggled={props.sidePanelIsOpen} 
						toggle={open => props.toggleSidePanel(open)} 
					/>
				</div>

                {/* Appbar Header */}
                <div className="appbar-header">
                    <h1 className="logo-primary text-center">
                        <Link to="/"><img src={props.logo || LogoSrc} height="60" className="logo" alt="Research 2 U" /></Link>
                    </h1>
                </div>
                {props.showTenantTitle &&
                    <h2 className={`appbar-tenant-title ${props.classes.appbarTenantTitle}`}>
                        <span>{props.tenantTitle}</span>
                    </h2>
                }
                {!props.isSubscribed && props.canSubscribe && !props.isLoginPage && 
                    SubscribeButton
                }
                {/* Nav Toggle */}
                {/* {!props.isLoginPage && props.isLoggedIn &&
                    <IconButton
                        className="nav-toggle"
                        aria-label="Open Menu"
                        onClick={() => props.toggleSidePanel(!props.sidePanelIsOpen)}
                        size="large">
                        {props.sidePanelIsOpen ? <ClearIcon /> : <MenuIcon />}
                    </IconButton>
                } */}


				<ClickAwayListener onClickAway={closePopups}>
					<div className={`right-nav ${props.classes.rightNav}`} onMouseEnter={closePopups}>
						{canView.contact &&
							<div >
								<ListItemButton
									component={Link} to="/contact-us"

								>
									<ListItemAvatar className={props.classes.listItemAvatar}>
										<MuiAvatar className={props.classes.avatar}>
											<ContactSupportIcon />
										</MuiAvatar>
									</ListItemAvatar>
								</ListItemButton>
							</div>
						}
						{/* Notifications */}
						<div id="nav-notifications">
							<ListItemButton
								onClick={(e) => {
									setProfileAnchor(null);
									setNotificationsAnchor(notificationsAnchor ? null : e.currentTarget);
								}}
								aria-label="Notifications"

							>
								<ListItemAvatar className={props.classes.listItemAvatar}>
									<MuiAvatar className={props.classes.avatar} sx={{ overflow: 'visible' }}>
										<Badge badgeContent={notifications.length} max={9} color="error"><MessageIcon /></Badge>
									</MuiAvatar>
								</ListItemAvatar>
							</ListItemButton>

							<Popper open={!!notificationsAnchor} anchorEl={notificationsAnchor} placement="top-end" style={{ zIndex: 99999 }} disablePortal={true}
								transition popperOptions={{ positionFixed: true }}>
								{({ TransitionProps }) =>
									<Grow {...TransitionProps} timeout={350} mountOnEnter unmountOnExit>
										<Paper className="notifications-panel" elevation={1}>
											<div style={{ padding: "15px 20px", borderBottom: "1px solid rgba(120, 130, 140, 0.13)" }}>
												<Typography variant="subtitle1">
													Notifications
													{props.notificationsLoading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
												</Typography>
												{notifications.length === 0 && <Typography variant="caption">You do not have any notifications...</Typography>}
											</div>
											{notifications.length > 0 &&
												<React.Fragment>
													<Scrollbars className="notifications" autoHide autoHeight autoHeightMax={250}>
														{map(notifications.slice(0, props.shownNotificationsCount), (n, i) =>
															<NotificationRow key={i} notification={n} onClick={markNotification} />
														)}
													</Scrollbars>
													<ButtonLink to="/profile#notifications" fullWidth size="large" onClick={togglePanel}>
														Check All Notifications
													</ButtonLink>
												</React.Fragment>
											}
										</Paper>
									</Grow>
								}
							</Popper>
						</div>
						<div>

						</div>

						{/* Profile */}
						<div id="nav-profile">
							<ListItemButton
								onClick={(e) => {
									setNotificationsAnchor(null);
									setProfileAnchor(profileAnchor ? null : e.currentTarget);
								}}
								aria-label="Profile"
								sx={{ height: '100%' }}
							>
								<ListItemAvatar className={props.classes.listItemAvatar}>
									<Avatar src={props.avatar} fullName={props.fullName} length={30} />
								</ListItemAvatar>
							</ListItemButton>

							<Popper open={!!profileAnchor} anchorEl={profileAnchor} placement="top-end" style={{ zIndex: 99999 }} disablePortal={true}
								transition popperOptions={{ positionFixed: true }}>
								{({ TransitionProps }) =>
									<Grow {...TransitionProps} timeout={350} mountOnEnter unmountOnExit>
										<Paper className="profile-panel" elevation={1}>
											{/* User Box */}
											<div className="user-box">
												{/* Avatar */}
												<Avatar src={props.avatar} fullName={props.fullName} length={80} style={{ borderRadius: 5 }} />
												{/* Details */}
												<div className="user-details">
													<Typography variant="h6">{props.fullName}</Typography>
													<span>{props.email}</span>
												</div>
												{/* Button */}
												<ButtonLink to="/profile" size="small" color="primary" onClick={togglePanel}>
													View profile
												</ButtonLink>
											</div>
											{/* Navigation Links + Logout */}
											<List component="nav">
												{props.canEditProblem &&
													<ListItem button component={Link} to="/profile/submissions" onClick={togglePanel}>
														<ListItemIcon><FolderSharedIcon /></ListItemIcon>
														<ListItemText primary="My submissions" />
													</ListItem>
												}
												{props.canSubscribe &&
													<ListItem button component={Link} to="/subscription" onClick={togglePanel}>
														<ListItemIcon><SubscriptionsIcon /></ListItemIcon>
														<ListItemText primary="Subscription" />
													</ListItem>
												}
												{props.canImpersonate &&
													<ListItem button component={Link} to="/impersonate" onClick={togglePanel}>
														<ListItemIcon><ImpersonateIcon /></ListItemIcon>
														<ListItemText primary="Impersonate" />
													</ListItem>
												}
												<Divider />
												<ListItem button onClick={props.logOut}>
													<ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
													<ListItemText primary="Logout" />
												</ListItem>
											</List>
										</Paper>
									</Grow>
								}
							</Popper>
						</div>
					</div>
				</ClickAwayListener>
            </nav>
        </header>
    );
});

TopBar.propTypes = { 
	sidePanelIsOpen: PropTypes.bool.isRequired,
	toggleSidePanel: PropTypes.func.isRequired,
	isLoggedIn: PropTypes.bool,
	isLoginPage: PropTypes.bool,
	logo: PropTypes.string,
	tenantTitle: PropTypes.string,
	showTenantTitle: PropTypes.bool,
	canSubscribe: PropTypes.bool.isRequired,
	isSubscribed: PropTypes.bool.isRequired,
	defaultProduct: PropTypes.object,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	goToSubscriptionComplete: PropTypes.func.isRequired,
	isSideMenuCollapsed: PropTypes.bool.isRequired,
	canImpersonate: PropTypes.bool.isRequired,
	logOut: PropTypes.func.isRequired
};

TopBar.defaultProps = {
	isLoggedIn: false,
	isLoginPage: false,
	logo: null,
	tenantTitle: '',
	showTenantTitle: false,
	defaultProduct: null
};

const mapStateToProps = state => ({
	sidePanelIsOpen: state.layout.isSideMenuOpen,
	isLoggedIn: state.context.isLoggedIn,
	tenantTitle: state.context.tenantTitle,
	showTenantTitle: state.settings.showTenantTitle,
	canSubscribe: canSubscribe(state),
	isSubscribed: state.context.isSubscribed,
	defaultProduct: state.context.defaultProduct,
	isSideMenuCollapsed: state.layout.isSideMenuCollapsed,
	isFullMenuOpen: state.layout.isFullMenuOpen,
	secondaryColourText: state.theme.secondaryColourText,
	canView: canViewProps(state),
	notifications: state.userNotifications.userNotifications.filter(n => !n.readDate),
	notificationsLoading: state.userNotifications.isLoading,
	avatar: state.context.avatar || "",
	fullName: state.context.fullName,
	canImpersonate: canImpersonate(state),
	email: state.context.email
});

const mapDispatchToProps = dispatch => ({
	collapseSideMenu: collapse => dispatch(collapseSideMenu(collapse)),
	showFullMenu: open => dispatch(showFullMenu(open)),
	toggleSidePanel: isOpen => dispatch(toggleSideMenu(isOpen)),
	searchProblems: search => [
		dispatch(changeLocation(`/search?search=${encodeURIComponent(search)}`)),
		dispatch(searchProblems(search)),
		dispatch(searchEvents(search)),
		dispatch(searchOffers(search))
	],
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	goToSubscriptionComplete: () => dispatch(changeLocation('subscription-complete')),
	logOut: () => dispatch(logOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(TopBar, styles)));
