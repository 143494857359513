import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconUploadButton from '../widgets/IconUploadButton';
import TextField from '../widgets/TextField';
import { formatBytes } from '../../utils/utils';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import Select from './Select';

const AttachmentEditor = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("attachment-editor-"));
	const { attachments, style } = props;
				
	return (
        <div id={id} style={style}>
			<span className="h3">
				{props.label}
				<IconUploadButton onChange={props.add} aria-label="Add document" />
			</span>
			{attachments.length === 0 && <p>There are currently no attachments...</p>}
			{attachments.length > 0 &&
				<table className="table stylish-table">
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Description</th>
							{props.types.length > 0 && <th>Type</th>}
							<th>Size</th>
						</tr>
					</thead>
					<tbody>
						{map(attachments, (file, index) =>
							<tr key={index}>
								<td style={{ width: !file._isNew && props.download ? 130 : 80 }}>
									{!file._isNew && props.download &&
										<IconButton
                                            onClick={() => props.download(file)}
                                            style={{ padding: 2 }}
                                            aria-label="Download attachment"
                                            size="large">
											<CloudDownloadIcon />
										</IconButton>
									}
									<IconButton
                                        onClick={() => props.delete(index)}
                                        style={{ padding: 2 }}
                                        aria-label="Delete attachment"
                                        size="large">
										<DeleteIcon />
									</IconButton>
								</td>
								<td>
									<TextField 
										aria-label="Document name"
										value={file.name || ""} 
										onChange={e => props.update(index, { ...file, name: e.target.value })}
									/>
								</td>
								<td>
									<TextField 
										aria-label="Document description"
										value={file.description || ""} 
										onChange={e => props.update(index, { ...file, description: e.target.value })}
									/>
								</td>
								{props.types.length > 0 &&
									<td>
										<Select 
											aria-label="Type" 
											value={file.attachmentType} 
											items={[{ label: "Document", value: "document" }, ...props.types]} 
											onChange={e => props.update(index, { ...file, attachmentType: e.target.value })}
											style={{ margin: 0 }}
										/>
									</td>
								}
								<td>
									{formatBytes(file.sizeBytes)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			}
		</div>
    );
});

AttachmentEditor.propTypes = {
	id: PropTypes.string,
	attachments: PropTypes.array,
	label: PropTypes.string,
	style: PropTypes.object,
	add: PropTypes.func.isRequired,
	delete: PropTypes.func.isRequired,
	download: PropTypes.func,
	types: PropTypes.arrayOf(PropTypes.string),
	update: PropTypes.func.isRequired
};

AttachmentEditor.defaultProps = {
	id: "",
	label: "Attachments",
	attachments: [],
	style: {},
	download: null,
	types: []
};

export default AttachmentEditor;
	