import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import { getRoles, saveRoles, getFunctions, isLoading } from '../../store/roles';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import AutoComplete from '../widgets/AutoComplete';
import TextField from '../widgets/TextField';
import map from 'lodash/map';

const form = {
	initValues: props => ({ roles: props.roles }),
	fields: [{
		name: "roles",
		label: "Roles",
		type: "dragList",
		itemTemplate: { name: "", description: "", functions: [] },
		renderItem: (item, index, updateItem, { functions }) => 
			<Grid key={index} container spacing={3}>
				<Grid item sm={12} md={4}>
					<TextField label="Name" value={item.name} onChange={e => updateItem(e.target.value, "name")} style={{ paddingBottom: 16 }} />
					<TextField label="Description" value={item.description} onChange={e => updateItem(e.target.value, "description")} multiline />
				</Grid>
				<Grid item xs={12} sm={12} md={8}>
					<AutoComplete 
						label="Functions"
						value={item.functions}
						onChange={values => updateItem(map(values, "value"), "functions")}
						items={functions}
						isMulti
						noOptionsMessage={() => null}
						closeMenuOnSelect={false}
					/>
				</Grid>
			</Grid>,
		simple: true,
		showBorder: true,
		cellStyle: { padding: "12px 0" }
	}]
};

const UserRoles = props => {
	React.useEffect(() => {
		props.getFunctions();
		props.getRoles();
	}, []);

	return <PageLayout title="User roles" pageContent={
		<Card>
			<Form onSubmit={() => props.saveRoles(props.values.roles)}>
				{props.fields.roles()}
				<Button type="submit" loading={props.loading} style={{ marginTop: 20 }}>Update roles</Button>
			</Form>
		</Card>
	} breadcrumbs={["User roles"]} />;
};

UserRoles.propTypes = {
	roles: PropTypes.array.isRequired,
	functions: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	getRoles: PropTypes.func.isRequired,
	getFunctions: PropTypes.func.isRequired,
	saveRoles: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	roles: state.roles.roles,
	functions: map(state.roles.functions, f => ({ label: f.name, value: f.functionId })),
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getRoles: () => dispatch(getRoles()),
	getFunctions: () => dispatch(getFunctions()),
	saveRoles: roles => dispatch(saveRoles(roles))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(UserRoles));
