import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from './Button';
import some from 'lodash/some';

const topicMap = {
	Course: { route: "courses", mapItem: course => ({ value: course.courseId, label: course.title }) },
	Event: { route: "events", mapItem: event => ({ value: event.eventId, label: event.title }) },
	Resource: { route: "resources", mapItem: resource => ({ value: resource.resourceId, label: resource.name }) },
	Tool: { route: "tools", mapItem: tool => ({ value: tool.toolId, label: tool.name }) }
};

const form = {
	initValues: props => ({ topicType: "None", ...props.discussion }),
	fields: [
		{
			name: "topicType",
			label: "Topic",
			type: "select",
			items: ["None", "Course", "Event", "Resource", "Tool"],
			required: true,
			disabled: props => props.disableTopic
		},
		{
			name: "topicId",
			label: "Topic Selection",
			type: "autoComplete",
			loadItems: (props, values) => values.topicType ? topicMap[values.topicType] : {},
			required: (props, values) => values.topicType !== "None",
			disabled: props => props.disableTopic
		},
		{
			name: "subject",
			label: "Subject",
			required: true,
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor"
		},
		{
			name: "channels",
			label: "Channels",
			type: "autoComplete",
			items: ({ channels }) => channels.map(c => ({ label: c.name, value: c.channelId })),
			isMulti: true,
			required: false
		}
	]
};

const CreateDiscussionDialog = props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth="md"
        TransitionProps={{
            onEnter: props.resetForm
        }}>
		<DialogTitle>Create new discussion</DialogTitle>
		<DialogContent className="discussion-dialog">
			<Grid container spacing={3}>
				{!props.hideTopic && <Grid item xs={12}>{props.fields.topicType()}</Grid>}
				{!props.hideTopic && props.values.topicType !== "None" && <Grid item xs={12}>{props.fields.topicId()}</Grid>}
				<Grid item xs={12}>{props.fields.subject()}</Grid>
				<Grid item xs={12}>{props.fields.description()}</Grid>
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose}>Cancel</Button>
			<Button disabled={some(props.error)} onClick={() => props.validateFields() && props.onSubmit(props.values)}>Create</Button>
		</DialogActions>
	</Dialog>;

CreateDiscussionDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	channels: PropTypes.array.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	discussion: PropTypes.object,
	hideTopic: PropTypes.bool,
	disableTopic: PropTypes.bool
};

CreateDiscussionDialog.defaultProps = {
	discussion: {},
	hideTopic: false,
	disableTopic: false
};

export default withForm(form)(CreateDiscussionDialog);