import React from 'react';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
	splashList: {
		listStyleType: 'none',
		padding: 0,
		margin: 0,
		'& li': {
			'&:before': {
				content: '\'✓\'',
				marginRight: '5px'
			}
		}
	}
});

const UpgradeTeamSkillsController = props => {
	const classes = props.classes;

	return (
		<PageLayout 
			title="Team L&D Skills Profile" 
			pageContent={
				<div style={{
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto',
					border: '1px solid #d1332e',
					borderRadius: '15px',
					padding: '5px',
					display: 'flex'
				}}>
					<div 
						style={{
							textAlign: 'left',
							padding: '20px'
						}}
					>
						<p style={{ }}>
							Corporate learning teams can create a team-based skills profile for their organisational learning team. This gives you an organisational-level view of L&D capabilities based on industry standards for the L&D profession.  
						</p>
						<p style={{  }}>
							This profile provides you with:
						</p>
						<div>
							<ul className={classes.splashList}>
								<li>a dashboard of key competency requirements to easily identify your existing team competencies.</li>
								<li>ability to prioritise competencies of importance to your organisation.</li>
								<li>Ability to identify ‘at risk’ competencies’ for hiring and service contracting for your future L&D personnel needs.</li>
								<li>To find out more…</li>
							</ul>
						</div>
						<div style={{
							marginTop: '20px'
						}}>
							<ButtonLink 
								to="/contact-us" 
							>Contact Us</ButtonLink>
						</div>
					</div>
					<div>
						<img src='/images/upgrade/teams-screenshot.png' alt="" style={{ width: '500px' }} />
					</div>
				</div>
			} 
			breadcrumbs={[
				"Upgrade"
			]} />
	);
};

UpgradeTeamSkillsController.propTypes = {
};

export default withStyles(UpgradeTeamSkillsController, styles, { withTheme: true });
