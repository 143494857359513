import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ProblemStats from '../viewProblem/ProblemStats';
import { getUser, getEndorsements, getViews, isLoading } from '../../store/users';
import { getQueryParameters } from '../../utils/utils';

const UserStatsController = props => {
	React.useEffect(() => {
		const { userId } = props.match.params;

		props.getUser(userId);
		props.getEndorsements(userId);
		props.getViews(userId);
	}, []);

	return <PageLayout title="User statistics" pageContent={
		<ProblemStats
			user={props.user}
			tab={getQueryParameters(props.location.search).tab || "endorsements"}
			endorsements={props.endorsements}
			views={props.views}
			loading={props.loading}
			single={props.single}
			plural={props.plural}
		/>
	} breadcrumbs={[
		{ label: "Users", path: "/users" },
		"User statistics"
	]} />;
};

UserStatsController.propTypes = {
	user: PropTypes.object.isRequired,
	endorsements: PropTypes.arrayOf(PropTypes.object).isRequired,
	views: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	user: state.users.user,
	endorsements: state.users.endorsements,
	views: state.users.views,
	loading: isLoading(state),
	single: state.settings.single,
	plural: state.settings.plural
});

const mapDispatchToProps = dispatch => ({
	getUser: userId => dispatch(getUser(userId)),
	getEndorsements: userId => dispatch(getEndorsements(userId)),
	getViews: userId => dispatch(getViews(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStatsController);
