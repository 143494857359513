import React from 'react';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogActions,
	DialogContent, 
	DialogContentText,
	DialogTitle, 
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import TextField from '../widgets/TextField';
import ColourEditor from '../widgets/ColourEditor';
import forEach from 'lodash/forEach';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import tinycolor from 'tinycolor2';

const form = {
	initValues: props => props.framework,
	fields: [
		{
			name: "frameworkCode",
			label: "Framework code",
			required: true,
			disabled: props => !props.isNew
		},
		{
			name: "setAsDefault",
			label: "Set as default framework for this organisation",
			type: "checkbox"
		},
		{
			name: "importFile",
			label: "Import file",
			type: "fileUploader",
			required: props => props.isNew,
			helpText: <span>File used to import framework (<a href="/templates/Framework%20Template.xlsx" download>Download Import Template</a>)</span>
		},
		{
			name: "description",
			label: "Description",
			multiline: true,
			helpText: "Description of framework within picker"
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true,
			helpText: "Image used to represent framework within picker"
		}
	]
};

const EditFramework = props => {
	const [showSpecGroupDialog, setShowSpecGroupDialog] = React.useState(false);
	const [specGroupIndex, setSpecGroupIndex] = React.useState(-1);
	const [specGroup, setSpecGroup] = React.useState(null);
	const [showSpecDialog, setShowSpecDialog] = React.useState(false);
	const [isNewSpecGroup, setIsNewSpecGroup] = React.useState(false);
	const [specIndex, setSpecIndex] = React.useState(-1);
	const [spec, setSpec] = React.useState(null);
	const [isNewSpec, setIsNewSpec] = React.useState(false);
	const [showConfirmDeleteSpecGroupDialog, setShowConfirmDeleteSpecGroupDialog] = React.useState(false);
	const [showConfirmDeleteSpecDialog, setShowConfirmDeleteSpecDialog] = React.useState(false);
	
	const { framework, fields, loading, isNew, values, errorColour } = props;


	return (
        <Card title={`${isNew ? "Create" : "Edit"} framework`} loading={loading} className="edit-framework">
			<Form onSubmit={() => props.validateFields() && props.saveFramework(props.values, props.formFiles.importFile, props.formFiles.image)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm>{fields.frameworkCode()}</Grid>
					<Grid item xs={12} sm="auto">{fields.setAsDefault()}</Grid>
					{isNew && <Grid item xs={12}>{fields.importFile()}</Grid>}
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.image()}</Grid>

					<Grid item xs={12}>
						<span className="h3">
							Specialisations
							<IconButton
                                onClick={() => {
									let seq = 0;
									forEach(values.specialisationGroups, g => {
										if (g.sequence > seq) seq = g.sequence;
									});
									const newGroup = {
										specialisationGroupId: 0,
										name: '',
										description: '',
										sequence: seq + 1,
										trainingPlanCode: framework.trainingPlanCode,
										colour: 'FCF8E3',
										specialisations: []
									};
									setSpecGroup(newGroup);
									setIsNewSpecGroup(true);
									setShowSpecGroupDialog(true);
								}}
                                style={{ marginLeft: 5 }}
                                size="large">
								<AddIcon />
							</IconButton>
						</span>

						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: 120 }}></TableCell>
									<TableCell colSpan={5}>Name</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(() => {
									const rows = [];

									forEach(values.specialisationGroups, (g, gi) => {
										const groupColour = tinycolor(g.colour);
										const groupForeColour = groupColour.isLight() ? '#000' : '#fff';
										const groupStyles = {
											background: groupColour.toHexString(),
											color: groupForeColour
										};

										rows.push(
											<TableRow>
												<TableCell>
													<IconButton
                                                        onClick={() => {
															setSpecGroupIndex(gi);
															setSpecGroup(g);
															setIsNewSpecGroup(false);
															setShowSpecGroupDialog(true);
														}}
                                                        aria-label="Edit Specialisation Group"
                                                        size="large">
														<EditIcon />
													</IconButton>
													<IconButton
                                                        onClick={() => {
															setSpecGroupIndex(gi);
															setSpecGroup(g);
															setShowConfirmDeleteSpecGroupDialog(true);
														}}
                                                        aria-label="Delete Specialisation Group"
                                                        size="large">
														<DeleteIcon style={{ color: errorColour }} />
													</IconButton>
												</TableCell>
												<TableCell colSpan={5} style={groupStyles}>
													{g.name}
												</TableCell>
											</TableRow>
										);

										forEach(g.specialisations, (s, si) => {
											const colour = tinycolor(s.colour);
											const foreColour = colour.isLight() ? '#000' : '#fff';
											const unitColour = s.unitColour ? tinycolor(s.unitColour) : groupColour;
											const unitForeColour = unitColour.isLight() ? '#000' : '#fff';

											const styles = {
												background: colour.toHexString(),
												color: foreColour
											};
											const unitStyles = {
												background: unitColour.toHexString(),
												color: unitForeColour,
												height: '30px',
												maxWidth: '70px',
												border: '1px solid #fff'
											};
	
											
											rows.push(
												<TableRow>
													<TableCell />
													<TableCell style={{ width: 120 }}>
														<IconButton
                                                            onClick={() => {
																setSpecGroupIndex(gi);
																setSpecGroup(g);
																setSpecIndex(si);
																setSpec(s);
																setIsNewSpec(false);
																setShowSpecDialog(true);
															}}
                                                            aria-label="Edit Specialisation Group"
                                                            size="large">
															<EditIcon />
														</IconButton>
														<IconButton
                                                            onClick={() => {
																setSpecGroupIndex(gi);
																setSpecGroup(g);
																setSpecIndex(si);
																setSpec(s);
																setShowConfirmDeleteSpecDialog(true);
															}}
                                                            aria-label="Delete Specialisation"
                                                            size="large">
															<DeleteIcon style={{ color: errorColour }} />
														</IconButton>
													</TableCell>
													<TableCell style={styles} colSpan={4}>
														{s.specialisationTitle}
													</TableCell>
												</TableRow>
											);

											const dummyUnitRow = (
												<TableRow >
													<TableCell colSpan={2} />
													<TableCell />
													<TableCell style={unitStyles} />
													<TableCell style={unitStyles} />
													<TableCell style={unitStyles} />
												</TableRow>
											);
											rows.push(dummyUnitRow);
											rows.push(dummyUnitRow);
										});

										rows.push(
											<TableRow>
												<TableCell />
												<TableCell style={{ width: 120 }}>
													<IconButton
                                                        onClick={() => {
															let seq = 0;
															forEach(g.specialisations, s => {
																if (s.specialisationSequence > seq) seq = s.specialisationSequence;
															});
															const newSpec = {
																specialisationId: 0,
																specialisationGroupId: g.specialisationGroupId,
																specialisationTitle: '',
																specialisationCode: '',
																specialisationDescription: '',
																specialisationSequence: seq + 1,
																trainingPlanCode: framework.trainingPlanCode,
																imagePath: null,
																colour: 'FFFFFF',
																unitColour: 'FCF8E3'
															};
															setSpecGroupIndex(gi);
															setSpecGroup(g);
															setSpec(newSpec);
															setIsNewSpec(true);
															setShowSpecDialog(true);
														}}
                                                        aria-label="Add Specialisation"
                                                        size="large">
														<AddIcon />
													</IconButton>
												</TableCell>
												<TableCell colSpan={4}>
												</TableCell>
											</TableRow>
										);
									})

									return rows;
								})()}
							</TableBody>
						</Table>

						<Dialog open={showSpecGroupDialog} onClose={() => setShowSpecGroupDialog(false)} maxWidth="md" fullWidth>
							<DialogTitle>{`${isNewSpecGroup ? "Add" : "Edit"} Specialisation Group`}</DialogTitle>
							<DialogContent>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6}>
										<TextField
											label="Name"
											value={specGroup ? specGroup.name : ''}
											onChange={e => setSpecGroup({
												...specGroup,
												name: e.target.value
											})}
											fullWidth={true}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<ColourEditor
											label="Colour"
											value={specGroup ? specGroup.colour : ''}
											onChange={e => setSpecGroup({
												...specGroup,
												colour: e.target.value
											})}
											fullWidth={true}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label="Description"
											value={specGroup ? specGroup.description : ''}
											onChange={e => setSpecGroup({
												...specGroup,
												description: e.target.value
											})}
											fullWidth={true}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label="Sequence"
											value={specGroup ? specGroup.sequence : 0}
											onChange={e => setSpecGroup({
												...specGroup,
												sequence: parseInt(e.target.value, 10)
											})}
											type="number"
											fullWidth={true}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setShowSpecGroupDialog(false)}>Cancel</Button>
								<Button onClick={() => {
									const groups = values.specialisationGroups;
									if (isNewSpecGroup) {
										groups.push(specGroup);
									} else {
										groups[specGroupIndex] = specGroup;
									}
									props.updateValues({
										...values,
										specialisationGroups: groups
									});
									setShowSpecGroupDialog(false);
								}}>Ok</Button>
							</DialogActions>
						</Dialog>

						<Dialog open={showSpecDialog} onClose={() => setShowSpecDialog(false)} maxWidth="md" fullWidth>
							<DialogTitle>{`${isNewSpec ? "Add" : "Edit"} Specialisation`}</DialogTitle>
							<DialogContent>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6}>
										<TextField
											label="Name"
											value={spec ? spec.specialisationTitle : ''}
											onChange={e => setSpec({
												...spec,
												specialisationTitle: e.target.value
											})}
											fullWidth={true}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label="Code"
											value={spec ? spec.specialisationCode : ''}
											onChange={e => setSpec({
												...spec,
												specialisationCode: e.target.value
											})}
											fullWidth={true}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label="Description"
											value={spec ? spec.specialisationDescription : ''}
											onChange={e => setSpec({
												...spec,
												description: e.target.value
											})}
											fullWidth={true}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<ColourEditor
											label="Colour"
											value={spec ? spec.colour : ''}
											onChange={e => setSpec({
												...spec,
												colour: e.target.value
											})}
											fullWidth={true}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<ColourEditor
											label="Unit Colour"
											value={spec ? spec.unitColour : ''}
											onChange={e => setSpec({
												...spec,
												unitColour: e.target.value
											})}
											fullWidth={true}
											helpText="Will default to the Specialisation Group Colour when not set"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label="Sequence"
											value={spec ? spec.specialisationSequence : 0}
											onChange={e => setSpec({
												...spec,
												specialisationSequence: parseInt(e.target.value, 10)
											})}
											type="number"
											fullWidth={true}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => setShowSpecDialog(false)}>Cancel</Button>
								<Button onClick={() => {
									const groups = values.specialisationGroups;
									if (isNewSpec) {
										specGroup.specialisations.push(spec);
									} else {
										specGroup.specialisations[specIndex] = spec;
									};
									groups[specGroupIndex] = specGroup;
									props.updateValues({
										...values,
										specialisationGroups: groups
									});
									setShowSpecDialog(false);
								}}>Ok</Button>
							</DialogActions>
						</Dialog>
						<Dialog open={showConfirmDeleteSpecGroupDialog} onClose={() => setShowConfirmDeleteSpecGroupDialog(false)} aria-labelledby={"Delete Specialisation Group"} className="delete-modal">
							<DialogTitle id="simple-dialog-title">Delete?</DialogTitle>
							<DialogContent>
								<DialogContentText>Deleting this Specialisation Group may result in orphaned Specialisations and Units. Are you sure?</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
                                    onClick={() => setShowConfirmDeleteSpecGroupDialog(false)}
                                    autoFocus
                                    variant="text">No</Button>
								<Button
                                    onClick={() => {
                                        const groups = values.specialisationGroups;
                                        groups.splice(specGroupIndex, 1);
                                        props.updateValues({
                                            ...values,
                                            specialisationGroups: groups
                                        });
                                        setShowConfirmDeleteSpecGroupDialog(false);
                                    }}
                                    className="ok-button"
                                    variant="text">Yes</Button>
							</DialogActions>
						</Dialog>
						<Dialog open={showConfirmDeleteSpecDialog} onClose={() => setShowConfirmDeleteSpecDialog(false)} aria-labelledby={"Delete Specialisation"} className="delete-modal">
							<DialogTitle id="simple-dialog-title">Delete?</DialogTitle>
							<DialogContent>
								<DialogContentText>Deleting this Specialisation may result in orphaned Units. Are you sure?</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
                                    onClick={() => setShowConfirmDeleteSpecDialog(false)}
                                    autoFocus
                                    variant="text">No</Button>
								<Button
                                    onClick={() => {
                                        const groups = values.specialisationGroups;
                                        specGroup.specialisations.splice(specIndex, 1);
                                        groups[specGroupIndex] = specGroup;
                                        
                                        props.updateValues({
                                            ...values,
                                            specialisationGroups: groups
                                        });
                                        setShowConfirmDeleteSpecDialog(false);
                                    }}
                                    className="ok-button"
                                    variant="text">Yes</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} framework</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteFramework(framework.frameworkId)}>
							Delete framework
						</Button>
					}
				</div>
			</Form>
		</Card>
    );
};

EditFramework.propTypes = { 
	framework: PropTypes.object,
	saveFramework: PropTypes.func.isRequired,
	deleteFramework: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

EditFramework.defaultProps = {
	framework: {},
	deleteFramework: () => null,
	isNew: false
};

export default withForm(form)(EditFramework);