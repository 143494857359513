import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';

const form = {
	initValues: props => props.position,
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Position Title"
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		}
	]
};

const EditPosition = props => {
	const { position, fields, loading } = props;

	return (
		<Card title="Position" loading={loading} className="edit-position">
			<Form onSubmit={() => props.validateFields() && props.savePosition(props.values)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.title()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{props.isNew ? "Save" : "Update"} Position</Button>
					{!props.isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deletePosition(position.positionId)}>
							Delete Position
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditPosition.propTypes = { 
	position: PropTypes.object,
	savePosition: PropTypes.func.isRequired,
	deletePosition: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditPosition.defaultProps = {
	position: {},
	deletePosition: () => null,
	isNew: false
};

export default withForm(form)(EditPosition);
