import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchStudentProjects, isLoading, deleteStudentProject } from '../../store/studentProjects';
import { canSubmitStudentProjects, canEditStudentProject, canMakePublic } from '../../store/security';
import { showModal } from '../../store/modal';
import { getQueryParameters } from '../../utils/utils';
import { withForm } from '../../utils/forms';
// import ButtonLink from '../widgets/ButtonLink';
import PageLayout from '../PageLayout';
import pick from 'lodash/pick';
import StudentProjectCard from './StudentProjectCard';
import map from 'lodash/map';
import PagePagination from '../widgets/PagePagination';
import MakePublicButton from '../widgets/MakePublicButton';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchProjects(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "postedBy",
			label: "Posted By",
			type: "select",
			items: [{ label: "(All)", value: "all" }, "Student", "Corporate"]
		},
		{
			name: "channel",
			label: "Filter by Channel",
			type: "autoComplete",
			loadItems: { route: "channels", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "student",
			label: "Filter by Student",
			type: "autoComplete",
			loadItems: { route: "students", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		}
	]
};

const StudentProjectsController = props => {
	const { projects, fields } = props;
	
	React.useEffect(() => props.searchProjects(props.searchArgs, 1, 8), []);

	return (
		<PageLayout 
			title="Research Match" 
			pageContent={
			<React.Fragment>
				<Grid container spacing={3} alignItems="flex-end">
					<Grid item xs={12}>{fields.search()}</Grid>
					<Grid item xs={12} sm={6}>{fields.channel()}</Grid>
					{/* {props.canSubmit && 
						<Grid item xs="auto">
							<ButtonLink to="/business-challenges/create">Create Your Challenge</ButtonLink>
						</Grid>
					} */}
				</Grid>
				{projects.length === 0 ? <p style={{ marginTop: 10 }}>No challenges found...</p> :
					<Grid container spacing={3}>
						{map(projects, (p, index) => <StudentProjectCard key={index} project={p} />)}
					</Grid>
				}
				<PagePagination
					showCount={false}
					pageNum={props.pageNum}
					pageSize={props.pageSize}
					total={props.total}
					totalPages={props.totalPages}
					onChangePage={page => {
						props.updateValues({
							...props.values,
							_pageNum: page,
							_pageSize: props.pageSize
						});
						props.searchProjects(props.values, page, props.pageSize);
					}}
					onChangeRowsPerPage={rowsPerPage => {
						props.updateValues({
							...props.values,
							_pageNum: 1,
							_pageSize: rowsPerPage
						});
						props.searchProjects(props.values, 1, rowsPerPage);
					}}
				/>
		</React.Fragment>
		} 
		breadcrumbs={["Research Match"]} 
		pageActions={
			props.canMakePublic ?
				<MakePublicButton />
				: undefined
		}
		/>
	);
};

StudentProjectsController.propTypes = { 
	projects: PropTypes.arrayOf(PropTypes.object).isRequired,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number,
	searchProjects: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	deleteProject: PropTypes.func.isRequired
};

StudentProjectsController.defaultProps = {
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null
};

const mapStateToProps = (state, ownProps) => ({
	projects: state.studentProjects.projects,
	pageNum: state.studentProjects.pageNum,
	pageSize: state.studentProjects.pageSize,
	total: state.studentProjects.total,
	totalPages: state.studentProjects.totalPages,
	loading: isLoading(state),
	canSubmit: canSubmitStudentProjects(state),
	canEdit: canEditStudentProject(state),
	canMakePublic: canMakePublic(state),
	searchArgs: {
		postedBy: "all",
		channel: "all", 
		student: "all",
		studentCategory: "all",
		...pick(getQueryParameters(ownProps.location.search), "search", "postedBy", "channel", "student", "studentCategory")
	}
});

const mapDispatchToProps = dispatch => ({
	searchProjects: (args, pageNum, pageSize) => dispatch(searchStudentProjects(args, pageNum, pageSize)),
	deleteProject: projectId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this challenge?",
		onOk: () => dispatch(deleteStudentProject(projectId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(StudentProjectsController));
