import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Chip } from '@mui/material';
import MediaCard from '../widgets/MediaCard';

const ReportCard = React.memo(({ report }) => 
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<MediaCard className="report-card" title={report.name} image={report.image} 
			link={report.fileName ? `/reports/view/${report.name}` : ""} linkStyle={{ flex: "0 0 auto" }}>
			<div style={{ marginBottom: 10 }}>{report.description}</div>
 			<Chip color="primary" label={report.category} style={{ height: 20 }} />
		</MediaCard>
	</Grid>
);

ReportCard.propTypes = {
	report: PropTypes.shape({
		name: PropTypes.string,
		category: PropTypes.string,
		fileName: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
		visible: PropTypes.bool
	}).isRequired
};

export default ReportCard;