import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import forEach from 'lodash/forEach';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	competencies: [],
	competency: {},
	userHasCompetencies: true,
	completeUserAssessment: false,
	userAssessmentComplete: false
};

const CLEAR_COMPETENCIES = "CLEAR_COMPETENCIES";
const GET_COMPETENCIES_REQUEST = "GET_COMPETENCIES_REQUEST";
const GET_COMPETENCIES_SUCCESS = "GET_COMPETENCIES_SUCCESS";
const GET_COMPETENCIES_FAILURE = "GET_COMPETENCIES_FAILURE";
const SAVE_COMPETENCIES_REQUEST = "SAVE_COMPETENCIES_REQUEST";
const SAVE_COMPETENCIES_SUCCESS = "SAVE_COMPETENCIES_SUCCESS";
const SAVE_COMPETENCIES_FAILURE = "SAVE_COMPETENCIES_FAILURE";
const DELETE_COMPETENCY_REQUEST = "DELETE_COMPETENCY_REQUEST";
const DELETE_COMPETENCY_SUCCESS = "DELETE_COMPETENCY_SUCCESS";
const DELETE_COMPETENCY_FAILURE = "DELETE_COMPETENCY_FAILURE";

export const isLoading = createLoadingSelector(["GET_COMPETENCIES", "GET_USER_HAS_COMPETENCIES", "ADD_COMPETENCIES", "DELETE_COMPETENCY", "SAVE_COMPETENCIES"]);

const GET_USER_HAS_COMPETENCIES_REQUEST = "GET_USER_HAS_COMPETENCIES_REQUEST";
const GET_USER_HAS_COMPETENCIES_SUCCESS = "GET_USER_HAS_COMPETENCIES_SUCCESS";
const GET_USER_HAS_COMPETENCIES_FAILURE = "GET_USER_HAS_COMPETENCIES_FAILURE";
export const getUserHasCompetencies = (userId, onSuccess) =>
	createFetchAction({
		url: `/api/competencies/${userId}/has-competencies`,
		startAction: GET_USER_HAS_COMPETENCIES_REQUEST,
		onError: error => [getUserHasCompetenciesFailure(error)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getUserHasCompetenciesSuccess(data);
		}
	});
export const getUserHasCompetenciesSuccess = data => ({ type: GET_USER_HAS_COMPETENCIES_SUCCESS, payload: { data } });
export const getUserHasCompetenciesFailure = error => ({ type: GET_USER_HAS_COMPETENCIES_FAILURE, payload: { error } });
const SET_USER_HAS_COMPETENCIES = "SET_USER_HAS_COMPETENCIES";
export const setUserHasCompetencies = hasCompetencies => ({ type: SET_USER_HAS_COMPETENCIES, hasCompetencies });

export const getCompetencies = (userId, aspirational = false, onSuccess, onError) =>
	createFetchAction({
		url: `/api/competencies/${userId}?aspirational=${aspirational}`,
		startAction: GET_COMPETENCIES_REQUEST,
		onError: error => {
			if (onError) onSuccess(error);
			return [getCompetenciesFailure(error), showErrorNotification(error.message)];
		},
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getCompetenciesSuccess(data);
		}
	});

export const getCompetenciesSuccess = data => ({ type: GET_COMPETENCIES_SUCCESS, payload: { data } });
export const getCompetenciesFailure = error => ({ type: GET_COMPETENCIES_FAILURE, payload: { error } });

export const getOrganisationCompetencies = (onSuccess, onError) =>
	createFetchAction({
		url: `/api/competencies/organisation-competencies`,
		startAction: GET_COMPETENCIES_REQUEST,
		onError: error => {
			if (onError) onSuccess(error);
			return [getCompetenciesFailure(error), showErrorNotification(error.message)];
		},
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getCompetenciesSuccess(data);
		}
	});

const ADD_COMPETENCIES_REQUEST = "ADD_COMPETENCIES_REQUEST";
const ADD_COMPETENCIES_SUCCESS = "ADD_COMPETENCIES_SUCCESS";
const ADD_COMPETENCIES_FAILURE = "ADD_COMPETENCIES_FAILURE";

export const addCompetencies = (userId, units) =>
	createPostAction({
		url: `/api/competencies/${userId}/add`,
		data: units,
		startAction: ADD_COMPETENCIES_REQUEST,
		onError: error => [addCompetenciesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [addCompetenciesSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [addCompetenciesSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const addCompetenciesSuccess = data => ({ type: ADD_COMPETENCIES_SUCCESS, data });
export const addCompetenciesFailure = error => ({ type: ADD_COMPETENCIES_FAILURE, error });

export const saveCompetencies = (mode, userId, competencies, skillsProfileId, skills, newFiles, reviewStatus, aspirational, onSuccess) => {
	const formData = new FormData();
	formData.append("Mode", mode);
	formData.append("SkillsProfileId", skillsProfileId);
	formData.append("Competencies", encodeURIComponent(JSON.stringify(competencies)));
	formData.append("Skills", encodeURIComponent(JSON.stringify(skills)));
	formData.append("ReviewStatus", reviewStatus);
	formData.append("Aspirational", aspirational);
	
	forEach(newFiles, file => formData.append("NewFiles", file));

	return createFormPostAction({
		url: `/api/competencies/${userId}/save`,
		data: formData,
		startAction: SAVE_COMPETENCIES_REQUEST,
		onError: error => [saveCompetenciesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess(data.object);
				return [saveCompetenciesSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [saveCompetenciesSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveCompetenciesSuccess = data => ({ type: SAVE_COMPETENCIES_SUCCESS, data });
export const saveCompetenciesFailure = error => ({ type: SAVE_COMPETENCIES_FAILURE, error });

export const deleteCompetency = competencyId =>
	createPostAction({
		url: `/api/competencies/${competencyId}/delete`,
		startAction: DELETE_COMPETENCY_REQUEST,
		onError: error => [deleteCompetencyFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteCompetencySuccess(data), showSuccessNotification(data.message)];
			} else {
				return [deleteCompetencySuccess(data), showErrorNotification(data.message)];
			}
		}
	});
	
export const deleteCompetencySuccess = data => ({ type: DELETE_COMPETENCY_SUCCESS, data });
export const deleteCompetencyFailure = error => ({ type: DELETE_COMPETENCY_FAILURE, error });

const UPDATE_USER_ASSESSMENT_COMPLETE_REQUEST = "UPDATE_USER_ASSESSMENT_COMPLETE_REQUEST";
const UPDATE_USER_ASSESSMENT_COMPLETE_SUCCESS = "UPDATE_USER_ASSESSMENT_COMPLETE_SUCCESS";
const UPDATE_USER_ASSESSMENT_COMPLETE_FAILURE = "UPDATE_USER_ASSESSMENT_COMPLETE_FAILURE";
export const updateUserAssessment = assessmentComplete =>
	createPostAction({
		url: `/api/users/update-assessment-complete?assessmentComplete=${assessmentComplete}`,
		startAction: UPDATE_USER_ASSESSMENT_COMPLETE_REQUEST,
		onError: error => [updateUserAssessmentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [updateUserAssessmentSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [updateUserAssessmentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
export const updateUserAssessmentSuccess = data => ({ type: UPDATE_USER_ASSESSMENT_COMPLETE_SUCCESS, data });
export const updateUserAssessmentFailure = error => ({ type: UPDATE_USER_ASSESSMENT_COMPLETE_FAILURE, error });


export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_COMPETENCIES:
			return { ...state, competencies: [] };
		case GET_USER_HAS_COMPETENCIES_SUCCESS:
			return { ...state, userHasCompetencies: action.payload.data };
		case SET_USER_HAS_COMPETENCIES:
			return { ...state, userHasCompetencies: action.hasCompetencies };
		case GET_COMPETENCIES_REQUEST:
			return { ...state, competencies: [], isLoading: true };
		case GET_COMPETENCIES_SUCCESS:
			return { ...state, competencies: action.payload.data, isLoading: false };
		case DELETE_COMPETENCY_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case DELETE_COMPETENCY_SUCCESS:
			return { ...state, competencies: state.competencies.filter(a => a.competencyId !== action.data.objectId) };
		case ADD_COMPETENCIES_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case ADD_COMPETENCIES_SUCCESS:
			return {
				...state,
				...(action.data.success && { competencies: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case SAVE_COMPETENCIES_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_COMPETENCIES_SUCCESS:
			return {
				...state,
				...(action.data.success && { competencies: action.data.object }),
				isLoading: false,
				userHasCompetencies: action.data.success && action.data.object ? action.data.object.competencies.length > 0 : state.userHasCompetencies, 
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case UPDATE_USER_ASSESSMENT_COMPLETE_SUCCESS:
			return {
				...state,
				userAssessmentComplete: action.data.object.userAssessmentComplete
			};
		default:
			return state;
	}
};
