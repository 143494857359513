import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import Card from '../widgets/Card';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import StatBox from '../widgets/StatBox';
import Avatar from '../widgets/Avatar';
import SearchField from '../widgets/SearchField';
import LogCategory from '../users/LogCategory';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import sumBy from 'lodash/sumBy';
import capitalise from 'lodash/capitalize';
import { Tooltip } from '@mui/material';

const ActivityLogs = props => {
	const [search, setSearch] = React.useState("");
	const [focusedInput, setFocusedInput] = React.useState(null);
	const [dates, setDates] = React.useState({
		startDate: moment(new Date()).add(-7, "days"),
		endDate: moment(new Date())
	});
	
	const handleDatesChange = ({ startDate, endDate }) => {
		if (startDate && endDate) props.getLogs(startDate.toDate(), endDate.toDate());
		setDates({ startDate, endDate });
	}

	const { loading, single, plural, logs, primaryColour, errorColour, warningColour } = props;
	
	return (
		<Card 
			title="User activity logs"
			subtitle="View activity logs of site users within selected range"
			loading={loading}
			titleRightContent={
				<React.Fragment>
					<label style={{ marginRight: 10 }}>Filter by date:</label>
					<DateRangePicker
						readOnly
						numberOfMonths={1}
						minimumNights={0}
						startDate={dates.startDate}
						startDateId="start-date"
						endDate={dates.endDate}
						endDateId="end-date"
						onDatesChange={handleDatesChange}
						isOutsideRange={day => day.isAfter()} // Prevent future dates
						focusedInput={focusedInput}
						onFocusChange={input => setFocusedInput(input)}
						anchorDirection="right"
					/>
				</React.Fragment>
			}
		>
			{/* Stats */}
			<div className="row-flex m-t-40">
				<StatBox text="Successful logins" number={sumBy(logs, l => l.event === "LogIn")} colour="#007C9A" />
				<StatBox text="User registrations" number={sumBy(logs, l => l.event === "UserRegistered")} colour="#1b75bb" />
				<StatBox
					text={`${capitalise(single)} endorsements`}
					number={sumBy(logs, l => l.event === "ProblemEndorsed")}
					colour="#447227"
				/>
				<StatBox text="Event registrations" number={sumBy(logs, l => l.event === "EventRegistered")} colour="#bb1b75" />
			</div>
			{/* Search Users */}
			<SearchField
				label="Search users" 
				value={search} 
				onChange={value => setSearch(value)} 
				loading={loading}
				addPadding
			/>
			{/* Datatable */}
			<DataTable data={logs} search={search} enableExport>
				<DataTableColumn 
					name="fullName" 
					label="User" 
					renderer={data => data.userId && data.fullName ?
						<React.Fragment>
							<Link to={`/users/${data.userId}`} style={{ color: primaryColour }}>
								<Avatar src={data.avatar} fullName={data.fullName} style={{ marginRight: 5 }} />
								<span>{data.fullName}</span>
							</Link>
							{!data.emailConfirmed &&
								<Tooltip title="Unconfirmed Email">
									<WarningIcon style={{ verticalAlign: "middle", marginLeft: 5, color: warningColour }} />
								</Tooltip>
							}
						</React.Fragment> :
						<React.Fragment>
							<Avatar fullName="?" style={{ marginRight: 5, backgroundColor: errorColour }} />
							<span>Unknown User</span>
						</React.Fragment>
					}
				/>
				<DataTableColumn name="userName" label="Username" />
				<DataTableColumn name="position" label="Position" hidden />
				<DataTableColumn name="department" label="Department" hidden />
				<DataTableColumn name="email" label="Email Address" />
				<DataTableColumn name="category" label="Category" renderer={data => 
					<LogCategory category={data.category} plural={plural} />
				} />
				{/* To-do: replace keyword before rendering to support excel export */}
				<DataTableColumn name="message" label="Message" renderer={data =>
					single !== "problem" && data.message ? data.message.replace("Problem", capitalise(single)) : data.message
				} />
				<DataTableColumn name="auditDate" label="Audit Date" dataType="date" />
				<DataTableColumn name="objectName" label="Comment" hidden />
			</DataTable>
		</Card>
	);
};

ActivityLogs.propTypes = {
	logs: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	getLogs: PropTypes.func.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired,
	warningColour: PropTypes.string.isRequired
};

export default ActivityLogs;