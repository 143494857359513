import { createSelector } from 'reselect';
import some from 'lodash/some';

const getUserFunctions = (state) => state.context.functions;

export const hasFunction = (state, f) => {
	return createSelector(
		[getUserFunctions],
		(userFunctions) => {
			if (!userFunctions) return false;

			const r = some(userFunctions, uf => uf === f);
			return r;
		}
	);
};
