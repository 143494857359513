import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';
import DOMPurify from 'dompurify';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [],
	pageNum: null,
	pageSize: null,
	total: null,
	totalPages: null
};

const initialState = {
	saveResult: emptySaveResult,
	projects: [],
	project: {},
	enquiries: []
};

const CLEAR_STUDENT_PROJECTS = "CLEAR_STUDENT_PROJECTS";
const CLEAR_STUDENT_PROJECT = "CLEAR_STUDENT_PROJECT";
const GET_STUDENT_PROJECTS_REQUEST = "GET_STUDENT_PROJECTS_REQUEST";
const GET_STUDENT_PROJECTS_SUCCESS = "GET_STUDENT_PROJECTS_SUCCESS";
const GET_STUDENT_PROJECTS_FAILURE = "GET_STUDENT_PROJECTS_FAILURE";
const SEARCH_STUDENT_PROJECTS_REQUEST = "SEARCH_STUDENT_PROJECTS_REQUEST";
const SEARCH_STUDENT_PROJECTS_SUCCESS = "SEARCH_STUDENT_PROJECTS_SUCCESS";
const SEARCH_STUDENT_PROJECTS_FAILURE = "SEARCH_STUDENT_PROJECTS_FAILURE";
const GET_STUDENT_PROJECT_REQUEST = "GET_STUDENT_PROJECT_REQUEST";
const GET_STUDENT_PROJECT_SUCCESS = "GET_STUDENT_PROJECT_SUCCESS";
const GET_STUDENT_PROJECT_FAILURE = "GET_STUDENT_PROJECT_FAILURE";
const SAVE_STUDENT_PROJECT_REQUEST = "SAVE_STUDENT_PROJECT_REQUEST";
const SAVE_STUDENT_PROJECT_SUCCESS = "SAVE_STUDENT_PROJECT_SUCCESS";
const SAVE_STUDENT_PROJECT_FAILURE = "SAVE_STUDENT_PROJECT_FAILURE";
const DELETE_STUDENT_PROJECT_REQUEST = "DELETE_STUDENT_PROJECT_REQUEST";
const DELETE_STUDENT_PROJECT_SUCCESS = "DELETE_STUDENT_PROJECT_SUCCESS";
const DELETE_STUDENT_PROJECT_FAILURE = "DELETE_STUDENT_PROJECT_FAILURE";

export const isLoading = createLoadingSelector(["GET_STUDENT_PROJECTS", "SEARCH_STUDENT_PROJECTS", "GET_STUDENT_PROJECT", "GET_ENQUIRIES", "SAVE_ENQUIRY"]);

export const clearStudentProjects = () => ({ type: CLEAR_STUDENT_PROJECTS });
export const clearStudentProject = () => ({ type: CLEAR_STUDENT_PROJECT });

export const getStudentProjects = (pageNum, pageSize) =>
	createFetchAction({
		url: `/api/student-projects?pageNum=${pageNum}&pageSize=${pageSize}`,
		startAction: GET_STUDENT_PROJECTS_REQUEST,
		onError: error => [getStudentProjectsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getStudentProjectsSuccess(data)
	});

export const getStudentProjectsSuccess = data => ({ type: GET_STUDENT_PROJECTS_SUCCESS, payload: { data } });
export const getStudentProjectsFailure = error => ({ type: GET_STUDENT_PROJECTS_FAILURE, payload: { error } });

export const searchStudentProjects = (args, pageNum, pageSize) => 
	createFetchAction({
		url: `/api/student-projects/search?PageNum=${pageNum}&PageSize=${pageSize}&${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_STUDENT_PROJECTS_REQUEST,
		onError: error => [searchStudentProjectsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchStudentProjectsSuccess(data)
	});

export const searchStudentProjectsSuccess = data => ({ type: SEARCH_STUDENT_PROJECTS_SUCCESS, payload: { data } });
export const searchStudentProjectsFailure = error => ({ type: SEARCH_STUDENT_PROJECTS_FAILURE, payload: { error } });

export const getStudentProject = projectId =>
	createFetchAction({
		url: `/api/student-projects/${projectId}`,
		startAction: GET_STUDENT_PROJECT_REQUEST,
		onError: error => [getStudentProjectFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getStudentProjectSuccess(data)
	});

export const getStudentProjectSuccess = data => ({ type: GET_STUDENT_PROJECT_SUCCESS, payload: { data } });
export const getStudentProjectFailure = error => ({ type: GET_STUDENT_PROJECT_FAILURE, payload: { error } });

export const saveStudentProject = (project, files) => {
	const formData = new FormData();
	formData.append("Project", encodeURIComponent(JSON.stringify(project)));

	if (files.image) formData.append("ImageFile", files.image);
	files.attachments.forEach(f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/student-projects",
		data: formData,
		startAction: SAVE_STUDENT_PROJECT_REQUEST,
		onError: error => [saveStudentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveStudentProjectSuccess(data), showSuccessNotification(data.message), changeLocation(`/business-challenges/${data.object.studentProjectId}`)];
			} else {
				return [saveStudentProjectSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveStudentProjectSuccess = data => ({ type: SAVE_STUDENT_PROJECT_SUCCESS, data });
export const saveStudentFailure = error => ({ type: SAVE_STUDENT_PROJECT_FAILURE, error });

export const deleteStudentProject = projectId =>
	createPostAction({
		url: `/api/student-projects/${projectId}/delete`,
		startAction: DELETE_STUDENT_PROJECT_REQUEST,
		onError: error => [deleteStudentProjectFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteStudentProjectSuccess(data), showSuccessNotification(data.message), changeLocation("/business-challenges")];
			} else {
				return [deleteStudentProjectSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteStudentProjectSuccess = data => ({ type: DELETE_STUDENT_PROJECT_SUCCESS, data });
export const deleteStudentProjectFailure = error => ({ type: DELETE_STUDENT_PROJECT_FAILURE, error });

const formatProject = project => ({ 
	...project, 
	details: DOMPurify.sanitize(project.details) ,
	objectives: DOMPurify.sanitize(project.objectives),
	requirements: DOMPurify.sanitize(project.requirements)
});

///////////////
// Enquiries //
///////////////

const GET_ENQUIRIES_REQUEST = "GET_ENQUIRIES_REQUEST";
const GET_ENQUIRIES_SUCCESS = "GET_ENQUIRIES_SUCCESS";
const GET_ENQUIRIES_FAILURE = "GET_ENQUIRIES_FAILURE";
const SAVE_ENQUIRY_REQUEST = "SAVE_ENQUIRY_REQUEST";
const SAVE_ENQUIRY_SUCCESS = "SAVE_ENQUIRY_SUCCESS";
const SAVE_ENQUIRY_FAILURE = "SAVE_ENQUIRY_FAILURE";

export const getEnquiries = () => 
	createFetchAction({
		url: "/api/student-projects/enquiries",
		startAction: GET_ENQUIRIES_REQUEST,
		onError: error => [getEnquiriesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEnquiriesSuccess(data)
	});

export const getProjectEnquiries = projectId => 
	createFetchAction({
		url: `/api/student-projects/${projectId}/enquiries`,
		startAction: GET_ENQUIRIES_REQUEST,
		onError: error => [getEnquiriesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEnquiriesSuccess(data)
	});

export const getEnquiriesSuccess = data => ({ type: GET_ENQUIRIES_SUCCESS, payload: { data } });
export const getEnquiriesFailure = error => ({ type: GET_ENQUIRIES_FAILURE, payload: { error } });

export const saveEnquiry = (projectId, content, file) => {
	const formData = new FormData();

	formData.append("ProjectId", projectId);
	formData.append("Content", content);
	if (file) formData.append("File", file);

	return createFormPostAction({
		url: "/api/student-projects/enquire",
		data: formData,
		startAction: SAVE_ENQUIRY_REQUEST,
		onError: error => [saveStudentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveEnquirySuccess(data), showSuccessNotification(data.message), changeLocation("/business-challenges")];
			} else {
				return [saveEnquirySuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}

export const saveEnquirySuccess = data => ({ type: SAVE_ENQUIRY_SUCCESS, data });
export const saveEnquiryFailure = error => ({ type: SAVE_ENQUIRY_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_STUDENT_PROJECTS:
			return { ...state, projects: [] };
		case GET_STUDENT_PROJECTS_REQUEST:
			return { ...state, projects: [], isLoading: true };
		case SEARCH_STUDENT_PROJECTS_REQUEST:
			return { ...state, isLoading: true };
		case GET_STUDENT_PROJECTS_SUCCESS:
		case SEARCH_STUDENT_PROJECTS_SUCCESS:
			return { 
				...state, 
				projects: action.payload.data.data, 
				isLoading: false,
				pageNum: action.payload.data.pageNum,
				pageSize: action.payload.data.pageSize,
				total: action.payload.data.total,
				totalPages: action.payload.data.totalPages
			};
		case CLEAR_STUDENT_PROJECT:
			return { ...state, project: {} };
		case GET_STUDENT_PROJECT_REQUEST:
			return { ...state, project: {}, isLoading: true };
		case GET_STUDENT_PROJECT_SUCCESS:
			return { ...state, isLoading: true, project: formatProject(action.payload.data) };
		case SAVE_STUDENT_PROJECT_REQUEST:
		case DELETE_STUDENT_PROJECT_REQUEST:
		case SAVE_ENQUIRY_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_STUDENT_PROJECT_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { project: formatProject(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_STUDENT_PROJECT_SUCCESS:
			return { ...state, projects: state.projects.filter(a => a.studentProjectId !== action.data.objectId) };
		case GET_ENQUIRIES_REQUEST:
			return { ...state, enquiries: [] };
		case GET_ENQUIRIES_SUCCESS:
			return { ...state, enquiries: action.payload.data, isLoading: false };
		case SAVE_ENQUIRY_SUCCESS:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};