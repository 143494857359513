import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Avatar from '../widgets/Avatar';
import map from 'lodash/map';
import find from 'lodash/find';
import Select from '../widgets/Select';
import AddCollaboratorDialog from './AddCollaboratorDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const form = {
	initValues: props => props.collaboration,
	fields: [
		{
			name: "title",
			label: "Project Title",
			required: true,
			helpText: "Title of collaboration"
		},
		{
			name: "email",
			label: "Contact email",
			required: true,
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address",
			helpText: "Primary email for collaboration enquiries"
		},
		{
			name: "summary",
			label: "Collaboration opportunity summary",
			type: "textEditor"
		},
		{
			name: "objectives",
			label: "Opportunity objectives",
			type: "textEditor"
		},
		{
			name: "requirements",
			label: "Specific requirements",
			type: "textEditor"
		},
		{
			name: "location",
			label: "Opportunity location"
		},
		{
			name: "startDate",
			label: "Start Date",
			type: "date",
			clearable: true
		},
		{
			name: "duration",
			label: "Duration"
		},
		{
			name: "participants",
			label: "Participants",
			type: "custom",
			widget: props => {
				const [showAddCollaboratorDialog, setShowAddCollaboratorDialog] = React.useState(false);
				return (
                    <React.Fragment>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '60px' }}>
									<IconButton
                                        onClick={() => { setShowAddCollaboratorDialog(true); }}
                                        style={{ marginLeft: 5 }}
                                        size="large"><AddCircleIcon /></IconButton>
								</TableCell>
								<TableCell>
									Participant
								</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{map(props.value, (p, i) =>
								<TableRow key={i}>
									<TableCell style={{ width: '60px' }}>
										<IconButton
                                            onClick={() => { 
												const participants = props.value;
												participants.splice(i, 1);
												props.onChange(participants);
											}}
                                            style={{ marginLeft: 5 }}
                                            size="large">
											<DeleteIcon />
										</IconButton>
									</TableCell>
									<TableCell>
										<Avatar src={p.avatar} fullName={p.fullName} style={{ marginRight: 5 }} />
										<span>{p.fullName}</span>
									</TableCell>
									<TableCell>
										<Select 
											disableUnderline={true}
											items={[
												'Invited',
												'Accepted',
												'Declined',
												'Invite on Save',
												'Added'
											]} 
											value={p.status} 
											onChange={e => {
												const participants = props.value;
												const participant = find(participants, p2 => p2.collaborationParticipantId === p.collaborationParticipantId);
												participant.status = e.target.value;
												props.onChange(participants);
											}}
										/>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
					<AddCollaboratorDialog
						open={showAddCollaboratorDialog}
						onClose={() => {
							setShowAddCollaboratorDialog(false);
						}}
						existingParticipants={props.value}
						onAddCollaborator={(collaborator) => {
							const participants = props.value || [];
							participants.push({
								userId: collaborator.userId,
								fullName: collaborator.fullName,
								email: collaborator.email,
								avatar: collaborator.avatar,
								status: 'Invite on Save'
							});
							props.onChange(participants);
							setShowAddCollaboratorDialog(false);
						}}
						onAddNonSLCollaborator={(email, name) => {
							const participants = props.value || [];
							participants.push({
								userId: null,
								fullName: name,
								email: email,
								avatar: null,
								status: 'Invite on Save'
							});
							props.onChange(participants);
							setShowAddCollaboratorDialog(false);

						}}
					/>
					</React.Fragment>
                );
			}
		},
		{
			name: "channels",
			label: "Categories",
			type: "autoComplete",
			isMulti: true,
			loadItems: {
				route: "channels",
				mapItem: c => ({ ...c, label: c.name, value: c.channelId })
			},
			mapValue: c => ({ ...c, label: c.channelName || c.name, value: c.channelId })
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment =>
				window.open(`/Collaboration/DownloadAttachment?collaborationId=${attachment.collaborationId}&attachmentId=${attachment.collaborationAttachmentId}`, "_self")
		}
	]
};

const EditCollaboration = props => {
	const { collaboration, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} collaboration`} loading={loading} className="edit-collaboration">
			<Form onSubmit={() => props.validateFields() && props.saveCollaboration(props.values, props.formFiles.attachments)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.email()}</Grid>
					<Grid item xs={12}>{fields.summary()}</Grid>
					<Grid item xs={12}>{fields.objectives()}</Grid>
					<Grid item xs={12}>{fields.requirements()}</Grid>
					<Grid item xs={12}>{fields.location()}</Grid>
					<Grid item xs={12} sm="auto">{fields.startDate()}</Grid>
					<Grid item xs={12} sm>{fields.duration()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
					<Grid item xs={12}>{fields.channels()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
					<Grid item xs={12}>{fields.participants()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} collaboration</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteCollaboration(collaboration.collaborationId)}>
							Delete collaboration
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditCollaboration.propTypes = { 
	collaboration: PropTypes.object,
	saveCollaboration: PropTypes.func.isRequired,
	deleteCollaboration: PropTypes.func,
	showMessage: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditCollaboration.defaultProps = {
	collaboration: {},
	deleteCollaboration: () => null,
	isNew: false
};

export default withForm(form)(EditCollaboration);