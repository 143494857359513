import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import SearchField from '../widgets/SearchField';
import ButtonLink from '../widgets/ButtonLink';
import { Link } from 'react-router-dom'

const Departments = props => {
	const [search, setSearch] = React.useState("");

	return (
		<Card loading={props.loading}>
			{/* Search Departments */}
			<SearchField label="Search Business Units" value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Departments Datatable */}
			<DataTable data={props.departments} search={search} enableExport tableFooter={
				<ButtonLink to="/business-units/create">Add new Business Unit</ButtonLink>
			}>
				<DataTableColumn name="name" label="Name" renderer={data => !props.canEdit ? data.name : 
					<Link to={`/business-units/edit/${data.departmentId}`} style={{ color: props.primaryColour }}>{data.name}</Link> 
				} />
				<DataTableColumn 
					hidden={!props.canViewOrganisations}
					name="organisationName" 
					label="Organisation"
					canFilter
					filterType="AutoComplete"
					loadItems={{
						route: "organisations", 
						mapItem: o => ({ label: o.name, value: o.organisationId })
					}}
				/>
			</DataTable>
		</Card>
	);
};

Departments.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	canViewOrganisations: PropTypes.bool.isRequired,
	departments: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

export default Departments;