import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import { stripHtml } from '../../utils/utils';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import TextField from '../widgets/TextField';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import find from 'lodash/find';
import toLower from 'lodash/toLower';

const defaultImages = [
	"/images/offers/summary-digitization_780x500.jpg", 
	"/images/showcase/computer-facebook.jpg", 
	"/images/showcase/laboratory.jpg", 
	"/images/showcase/dentist.jpg" 
];

const form = {
	initValues: ({ feature = {} }) => ({
		...feature,
		[toLower(feature.featureType)]: feature.targetId,
		location: feature.location === undefined ? "None" : ""
	}),
	fields: [
		{
			name: "featureType",
			label: "Type",
			required: true,
			type: "select",
			items: ["Problem", "Event", "Offer", "Contact", "Article", "Google Form"]
		},
		{
			name: "problem",
			required: f => f.featureType === "Problem",
			label: "Problem",
			type: "autoComplete",
			loadItems: {
				route: "problems", 
				mapItem: p => ({ label: p.title, value: p.problemId, object: p })
			},
			onChange: (values, option) => {
				values.targetId = option.object.problemId;
				values.title = option.object.title;
				values.description = stripHtml(option.object.description);
			}
		},
		{	
			name: "event",
			required: f => f.featureType === "Event",
			label: "Event",
			type: "autoComplete",
			loadItems: {
				route: "events", 
				mapItem: e => ({ label: e.title, value: e.eventId, object: e })
			},
			onChange: (values, option) => {
				values.targetId = option.object.eventId;
				values.title = option.object.title;
				values.description = stripHtml(option.object.description);
			}
		},
		{	
			name: "offer",
			required: f => f.featureType === "Offer",
			label: "Offer",
			type: "autoComplete",
			loadItems: {
				route: "offers", 
				mapItem: c => ({ label: c.title, value: c.offerId, object: c })
			},
			onChange: (values, option) => {
				values.targetId = option.object.offerId;
				values.title = option.object.title;
				values.description = stripHtml(option.object.description);
				values.imageUrl = option.object.coverImage;
				values.imageLocation = option.object.coverImageLocation;

				const field = find(form.fields, f => f.name === "imageUrl");
				field.images = defaultImages.indexOf(values.imageUrl) === -1 ? [...defaultImages, values.imageUrl] : defaultImages;
			}
		},
		{
			name: "title",
			label: "Title",
			required: true,
			max: 80
		},
		{
			name: "description",
			label: "Description",
			required: false,
			multiline: true,
			max: 150
		},
		{
			name: "order",
			label: "Order",
			type: "number",
			helpText: "Use to change the order of features. Lower numbers are displayed first."
		},
		{ 
			name: "imageUrl",
			label: "Feature image",
			type: "imageSelector",
			images: defaultImages
		},
		{
			name: "location",
			label: "Location",
			type: "select",
			displayEmpty: true,
			items: [{ label: "All", value: "" }, "Internal", "External", "None"]
		},
		{
			name: "articleImage",
			label: "Article image",
			type: "imageUploader",
			single: true,
			placeholder: "There is no image to display..."
		},
		{
			name: "articleContent",
			required: (props, f) => f.featureType === "Article",
			label: "Article content",
			type: "textEditor",
			showLink: true
		},
		{
			name: "links",
			label: "Links",
			type: "dragList",
			itemTemplate: { label: "", to: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={4}>
						<TextField label="Label" value={item.label} onChange={e => updateItem(e.target.value, "label")} />
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField startAdornment="https://" label="Link" value={item.to} onChange={e => updateItem(e.target.value, "to")} />
					</Grid>
				</Grid>
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Feature/DownloadAttachment?featureId=${attachment.featureId}&attachmentId=${attachment.attachmentId}`, "_self")
		},
		{
			name: "videos",
			label: "Videos",
			type: "dragList",
			itemTemplate: "",
			renderItem: (value, index, updateItem) => 
				<TextField key={index} label="Link" value={value} onChange={e => updateItem(e.target.value)} />
		},
		{
			name: "articleContentOnly",
			label: "Show article content only",
			type: "checkbox"
		},
		{
			name: "fullWidth",
			label: "Full width",
			type: "checkbox",
			helpText: "Feature will span full width on home page"
		},
		{
			name: "contactHeader",
			label: "Contact header",
			required: (props, f) => f.featureType === "Contact",
			helpText: "Header of contact form"
		},
		{
			name: "contactBody",
			label: "Contact body",
			type: "textEditor",
			required: (props, f) => f.featureType === "Contact",
			helpText: "Body of contact form"
		},
		{
			name: "contactHeader",
			label: "Contact header",
			required: (props, f) => f.featureType === "Contact",
			helpText: "Header of contact form"
		},
		{
			name: "contactSubjectRequired",
			label: "Contact subject required",
			type: "checkbox",
			helpText: "Set whether a subject is required for submission"
		},
		{
			name: "contactMessageRequired",
			label: "Contact message required",
			type: "checkbox",
			helpText: "Set whether a message is required for submission"
		},
		{
			name: "contactButtonText",
			label: "Contact button text",
			required: (props, f) => f.featureType === "Contact",
			helpText: "Text of button on contact feature"
		},
		{
			name: "contactEmail",
			label: "Contact email",
			required: (props, f) => f.featureType === "Contact",
			helpText: "Destination email address of contact form"
		},
		{
			name: "googleForm",
			label: "Form HTML",
			getValue: values => {
				return values.articleContent;
			},
			setValues: (newValue, values) => ({ ...values, googleForm: newValue, articleContent: newValue  }),
			required: (props, f) => f.featureType === "Google Form"
		}
	]
};

const pathSwitch = {
	Problem: "problems",
	Event: "events",
	Offer: "offers",
	Contact: "contact-us",
	Article: "articles"
};

const EditFeature = props => {
	const { feature: f, fields, loading, values, isNew } = props,
		previewLink = f.featureType === "Contact" ? "" : `/${pathSwitch[f.featureType]}${f.featureType === "Article" ? `/${f.featureId}` : `/${f.targetId}`}`;

	return (
		<Card title="Feature" loading={loading} className="edit-feature">
			<Form onSubmit={() => {
				if (props.validateFields()) {
					console.log(values);
					props.saveFeature(props.values, props.formFiles);
			}}}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.featureType()}</Grid>
					{values.featureType === "Problem" && <Grid item xs={12}>{fields.problem()}</Grid>}
					{values.featureType === "Event" && <Grid item xs={12}>{fields.event()}</Grid>}
					{values.featureType === "Offer" && <Grid item xs={12}>{fields.offer()}</Grid>}
					<Grid item xs={12}>{fields.title()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.imageUrl()}</Grid>
					<Grid item xs={6}>{fields.order()}</Grid>
					<Grid item xs={6}>{fields.location()}</Grid>
					<Grid item xs={12}>{fields.fullWidth()}</Grid>
					{values.featureType === "Article" && 
						<React.Fragment>
							<Grid item xs={12}>{fields.articleImage()}</Grid>
							<Grid item xs={12}>{fields.articleContent()}</Grid>
							<Grid item xs={12}>{fields.links()}</Grid>
							<Grid item xs={12}>{fields.videos()}</Grid>
							<Grid item xs={12}>{fields.attachments()}</Grid>
							<Grid item xs={12}>{fields.articleContentOnly()}</Grid>
						</React.Fragment>
					}
					{values.featureType === "Contact" && 
						<React.Fragment>
							<Grid item xs={12}>{fields.contactHeader()}</Grid>
							<Grid item xs={12}>{fields.contactBody()}</Grid>
							<Grid item xs="auto">{fields.contactSubjectRequired()}</Grid>
							<Grid item xs="auto">{fields.contactMessageRequired()}</Grid>
							<Grid item xs={12}>{fields.contactButtonText()}</Grid>
							<Grid item xs={12}>{fields.contactEmail()}</Grid>
						</React.Fragment>
					}
					{values.featureType === "Google Form" && 
						<React.Fragment>
							<Grid item xs={12}>{fields.googleForm()}</Grid>
						</React.Fragment>
					}
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} Feature</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteFeature(f.featureId)}>
							Delete Feature
						</Button>
					}
					{!isNew && previewLink && <ButtonLink to={previewLink} success style={{ marginLeft: 10 }}>Preview</ButtonLink>}
				</div>
			</Form>
		</Card>
	);
};

EditFeature.propTypes = { 
	saveFeature: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	feature: PropTypes.object,
	deleteFeature: PropTypes.func,
	isNew: PropTypes.bool
};

EditFeature.defaultProps = {
	feature: {},
	deleteFeature: () => null,
	isNew: false
};

export default withForm(form)(EditFeature);