import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { 
	getPositions,
	isLoading
} from '../../store/positions';
import { hasFunction } from '../../store/selectors/functions';
import Positions from './Positions';
import { canEditPosition } from '../../store/security';

const PositionsController = props => {
	React.useEffect(props.getPositions, []);

	return <PageLayout title="Positions" pageContent={
		<Positions 
			// Todo: add canSubmitPositions
			canEdit={props.canEdit}
			canViewOrganisations={props.canViewOrganisations}
			positions={props.positions} 
			loading={props.loading}
			primaryColour={props.primaryColour}
		/>
	} breadcrumbs={["Positions"]} />;
};

PositionsController.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	canViewOrganisations: PropTypes.bool.isRequired,
	positions: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	canEdit: canEditPosition(state),
	canViewOrganisations: hasFunction(state, "OrganisationsView")(state),
	positions: state.positions.positions,
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getPositions: () => dispatch(getPositions())
});

export default connect(mapStateToProps, mapDispatchToProps)(PositionsController);
