import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchCollaborations, isLoading, deleteCollaboration } from '../../store/collaborations';
import { canSubmitCollaborations, canEditCollaboration } from '../../store/security';
import { showModal } from '../../store/modal';
import { getQueryParameters } from '../../utils/utils';
import { withForm } from '../../utils/forms';
import ButtonLink from '../widgets/ButtonLink';
import Pagination from '../widgets/Pagination';
import Card from '../widgets/Card';
import PageLayout from '../PageLayout';
import CollaborationRow from './CollaborationRow';
import pick from 'lodash/pick';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchCollaborations(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "channel",
			label: "Filter by Category",
			type: "autoComplete",
			loadItems: { route: "channels", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "user",
			label: "Filter by Collaborator",
			type: "autoComplete",
			loadItems: { route: "users", mapItem: "fullName" },
			defaultItem: { label: "(All)", value: "all" }
		}
	]
};

const itemsPerPage = 10;

const CollaborationsController = props => {
	const [page, setPage] = React.useState(1);
	const { collaborations, fields } = props,
		pageCount = Math.ceil(collaborations.length / itemsPerPage),
		from = (page - 1) * itemsPerPage;
	
	React.useEffect(() => props.searchCollaborations(props.searchArgs), []);

	return <PageLayout title="Collaborations" pageContent={
		<React.Fragment>
			<Card style={{ marginBottom: 20, zIndex: 10 }}>
				<Grid container spacing={3} alignItems="flex-end">
					<Grid item xs={12}>{fields.search()}</Grid>
					<Grid item xs={12} sm={6}>{fields.channel()}</Grid>
					<Grid item xs={12} sm={6}>{fields.user()}</Grid>
				</Grid>
			</Card>
			<Card title="All Collaborations" loading={props.loading} titleRightContent={
				props.canSubmit && <ButtonLink to="/collaborations/create">Submit collaboration</ButtonLink>
			}>
				{collaborations.length === 0 && <p>No collaborations found...</p>}
				{collaborations.slice(from, from + itemsPerPage).map((p, i) =>
					<CollaborationRow 
						key={i} 
						collaboration={p} 
						canEdit={props.canEdit} 
						onDelete={props.deleteCollaboration} 
						primaryColour={props.primaryColour}
					/>
				)}
				{!props.loading &&
					<Pagination maxPages={10} pageCount={pageCount} currentPage={page} changePage={page => setPage(page)} />
				}
			</Card>
		</React.Fragment>
	} breadcrumbs={["Collaborations"]} />;
};

CollaborationsController.propTypes = { 
	collaborations: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchCollaborations: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	deleteCollaboration: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	collaborations: state.collaborations.collaborations,
	loading: isLoading(state),
	canSubmit: canSubmitCollaborations(state),
	canEdit: canEditCollaboration(state),
	searchArgs: {
		channel: "all", 
		user: "all",
		...pick(getQueryParameters(ownProps.location.search), "search", "channel", "user")
	},
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	searchCollaborations: args => dispatch(searchCollaborations(args)),
	deleteCollaboration: collaborationId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this collaboration?",
		onOk: () => dispatch(deleteCollaboration(collaborationId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CollaborationsController));
