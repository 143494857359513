import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveAcademic, isLoading } from '../../store/academics';
import PageLayout from '../PageLayout';
import EditAcademic from './EditAcademic';

const CreateAcademicController = props => 
	<PageLayout title="Create academic" pageContent={
		<EditAcademic saveAcademic={props.saveAcademic} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Academics", path: "/academics" }, "Create academic"]} />;

CreateAcademicController.propTypes = {
	saveAcademic: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveAcademic: (academic, avatarFile) => dispatch(saveAcademic(academic, avatarFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAcademicController);