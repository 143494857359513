import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveDepartment, isLoading } from '../../store/departments';
import PageLayout from '../PageLayout';
import EditDepartment from './EditDepartment';
import { get } from '../../utils/ajax';
import { showErrorNotification } from '../../store/notifications';
import axios from 'axios';
import { canManageDepartmentsAcrossTenant } from '../../store/security';

const CreateDepartmentController = props => {
	const [loading, setLoading] = React.useState(false);
	const [users, setUsers] = React.useState([]);
	const [departments, setDepartments] = React.useState([]);

	React.useEffect(() => {
		onOrganisationChanged(props.organisationId);
	}, []);

	const onOrganisationChanged = (organisationId) => {
		setLoading(true);
		axios.all([
			get({
				url: props.organisationId === organisationId ? `/api/departments/by-organisation` : `/api/departments/by-organisation/${organisationId}`,
				onSuccess: data => {
					setDepartments(data);
				},
				onError: error => props.showErrorNotification(error.message)
			}),
			get({
				url: props.organisationId === organisationId ? `/api/users/by-organisation` : `/api/users/by-organisation/${organisationId}`,
				onSuccess: data => {
					setUsers(data);
				},
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setLoading(false));
	};

	return (
		<PageLayout 
			title="Create business unit"
			pageContent={
				<EditDepartment 
					saveDepartment={props.saveDepartment} 
					loading={props.loading || loading} 
					users={users}
					isNew 
					departments={departments}
					organisationId={props.organisationId}
					onOrganisationChanged={onOrganisationChanged}
					canChangeOrganisation={props.canChangeOrganisation}
					department={{
						departmentId: 0,
						organisationId: props.organisationId
					}}
				/>
			} 
			breadcrumbs={[
				{ label: "Business units", path: "/business-units" }, 
				"Create business unit"
			]} 
		/>
	);
}

CreateDepartmentController.propTypes = {
	saveDepartment: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	canChangeOrganisation: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canChangeOrganisation: canManageDepartmentsAcrossTenant(state)
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	saveDepartment: department => dispatch(saveDepartment(department))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDepartmentController);