import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import TextField from '../widgets/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const form = {
	initValues: props => props.expert,
	fields: [
		{
			name: "userId",
			label: "User",
			type: "autoComplete",
			loadItems: {
				route: "users/experts?unregistered=true",
				mapItem: u => ({ ...u, label: u.fullName, value: u.userId })
			},
			required: true
		},
		{
			name: "industryId",
			label: "Industry",
			type: "autoComplete",
			loadItems: {
				route: "industries",
				mapItem: i => ({ label: i.name, value: i.industryId })
			}
		},
		{
			name: "seniority",
			label: "Seniority",
			type: "select",
			items: ["Line Management", "Executive Management"],
			required: true
		},
		{
			name: "yearsExperience",
			label: "Years of Experience",
			type: "number"
		},
		{
			name: "discipline",
			label: "Discipline"
		},
		{
			name: "courses",
			label: "Courses",
			type: "dragList",
			itemTemplate: { name: "", institution: "", year: new Date().getFullYear() },
			renderItem: (value, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={5}>
						<TextField label="Name" value={value.name} onChange={e => updateItem(e.target.value, "name")} />
					</Grid>
					<Grid item xs md={5}>
						<TextField label="Institution" value={value.institution} onChange={e => updateItem(e.target.value, "institution")} />
					</Grid>
					<Grid item xs="auto" md={2}>
						<DatePicker label="Year Completed" value={new Date().setFullYear(value.year)} views={["year"]} disableFuture onChange={date => updateItem(date.getFullYear(), "year")} />
					</Grid>
				</Grid>,
			simple: true
		},
		{
			name: "academicJobs",
			label: "Jobs",
			type: "dragList",
			itemTemplate: { type: "Academic", role: "", employer: "", years: 0 },
			renderItem: (value, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={5}>
						<TextField label="Role" value={value.role} onChange={e => updateItem(e.target.value, "role")} />
					</Grid>
					<Grid item xs md={5}>
						<TextField label="Employer" value={value.employer} onChange={e => updateItem(e.target.value, "employer")} />
					</Grid>
					<Grid item xs="auto" md={2}>
						<TextField label="Years" type="number" value={value.years} onChange={e => updateItem(e.target.value, "years")} />
					</Grid>
				</Grid>,
			simple: true
		},
		{
			name: "professionalQualifications",
			label: "Professional Qualifications",
			type: "textEditor"
		},
		{
			name: "professionalJobs",
			label: "Jobs",
			type: "dragList",
			itemTemplate: { type: "Professional", role: "", employer: "", years: 0 },
			renderItem: (value, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={5}>
						<TextField label="Role" value={value.role} onChange={e => updateItem(e.target.value, "role")} />
					</Grid>
					<Grid item xs md={5}>
						<TextField label="Employer" value={value.employer} onChange={e => updateItem(e.target.value, "employer")} />
					</Grid>
					<Grid item xs="auto" md={2}>
						<TextField label="Years" type="number" value={value.years} onChange={e => updateItem(e.target.value, "years")} />
					</Grid>
				</Grid>,
			simple: true
		},
		{
			name: "executiveExperience",
			label: "Executive Experience",
			type: "textEditor"
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true
		}
	]
};

const EditExpert = props => {
	const { fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} expert`} loading={loading} className="edit-expert">
			<Form onSubmit={() => props.validateFields() && props.saveExpert(props.values, props.formFiles.image)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.userId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.industryId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.discipline()}</Grid>
					<Grid item xs={12} sm={6}>{fields.seniority()}</Grid>
					<Grid item xs={12} sm={6}>{fields.yearsExperience()}</Grid>
					<Grid item xs={12}>{fields.image()}</Grid>
				</Grid>
				<span className="h3" style={{ marginTop: 20 }}>Academic Experience</span>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.courses()}</Grid>
					<Grid item xs={12}>{fields.academicJobs()}</Grid>
				</Grid>
				<span className="h3" style={{ marginTop: 20 }}>Professional Experience</span>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.professionalQualifications()}</Grid>
					<Grid item xs={12}>{fields.professionalJobs()}</Grid>
				</Grid>
				<span className="h3" style={{ marginTop: 20 }}>Executive Experience</span>
				{fields.executiveExperience()}
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} expert</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteExpert(props.expert.expertId)}>
							Delete expert
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditExpert.propTypes = { 
	expert: PropTypes.object,
	saveExpert: PropTypes.func.isRequired,
	deleteExpert: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditExpert.defaultProps = {
	expert: {},
	deleteExpert: () => null,
	isNew: false
};

export default withForm(form)(EditExpert);