import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';
import { changeLocation } from '../../../store/location';
import { showModal } from '../../../store/modal';
import { emptySaveResult } from '../../../variables';
import { get, post } from '../../../utils/ajax';
import PageLayout from '../../PageLayout';
import EditProduct from './EditProduct';
import axios from 'axios';
import { canAdminSubscriptions } from '../../../store/security';
import { revokeAccess } from '../../../store/context';

const EditProductController = props => {
	const [state, setState] = React.useState({
		product: {},
		loading: false,
		saveResult: emptySaveResult
	});

	React.useEffect(() => {
		if (!props.canView) props.revokeAccess();
	}, []);

	React.useEffect(() => {
		setState(prevState => ({ ...prevState, isLoading: true }));

		axios.all([
			get({
				url: `/api/stripe/product/${props.match.params.id}`,
				onSuccess: data => setState(prevState => ({ ...prevState, product: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, loading: false })));
	}, [props.match.params.productId]);

	const saveProduct = product => {
		setState(prevState => ({ ...prevState, isLoading: true }));
	
		post({
			url: "/api/stripe/product",
			data: product,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, saveResult: data, loading: false }));

				if (data.success) {
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, loading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	const deleteProduct = product => {
		props.promptDeleteProduct(product, () => {
			post({
				url: `/api/stripe/product/${product.productId}/delete`,
				onSuccess: data => {
					setState({ saveResult: data, loading: false });

					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToProductsPage();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, loading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	};

	return <PageLayout title="Edit product" pageContent={
		<EditProduct
			product={state.product}
			saveProduct={saveProduct}
			deleteProduct={deleteProduct}
			loading={state.loading}
			saveResult={state.saveResult}
			tenantId={props.tenantId}
			isGlobalAdministrator={props.isGlobalAdministrator}
		/>
	} breadcrumbs={[{ label: "Products", path: "/admin/products" }, "Edit product"]} />;
};

EditProductController.propTypes = {
	isGlobalAdministrator: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	promptDeleteProduct: PropTypes.func.isRequired,
	goToProductsPage: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToDispatch = state => ({
	canView: canAdminSubscriptions(state),
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	tenantId: state.context.tenantId
});

const mapDispatchToProps = dispatch => ({
	goToProductsPage: () => dispatch(changeLocation("/admin/products")),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptDeleteProduct: (product, onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this product?",
		onOk: () => onOk()
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToDispatch, mapDispatchToProps)(EditProductController);
