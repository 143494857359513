import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { canSubmitExperts } from '../../store/security';
import { searchExperts, isLoading } from '../../store/experts';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import ExpertCard from './ExpertCard';
import map from 'lodash/map';
import pick from 'lodash/pick';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchExperts(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		}
	]
};

const ExpertsController = props => {
	const { experts } = props;

	React.useEffect(() => props.searchExperts(props.searchArgs), []);
	
	return <PageLayout title="Experts" pageContent={
		<React.Fragment>
			<Grid container spacing={3} direction="row-reverse">
				{props.canSubmit &&
					<Grid item xs="auto">
						<ButtonLink to="/experts/create">Create expert</ButtonLink>
					</Grid>
				}
				<Grid item xs={12} sm>{props.fields.search()}</Grid>
			</Grid>
			{experts.length === 0 ? <p style={{ marginTop: 10 }}>No experts found...</p> :
				<Grid container spacing={3}>
					{map(experts, (m, i) => 
						<Grid key={i} item xs={12} sm={6} lg={4}>
							<ExpertCard expert={m} />
						</Grid>
					)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={["Experts"]} />;
};

ExpertsController.propTypes = { 
	experts: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchExperts: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	experts: state.experts.experts,
	loading: isLoading(state),
	searchArgs: {
		search: "",
		...pick(getQueryParameters(ownProps.location.search), "search")
	},
	canSubmit: canSubmitExperts(state)
});

const mapDispatchToProps = dispatch => ({
	searchExperts: args => dispatch(searchExperts(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ExpertsController));