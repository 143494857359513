import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Fab } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ExtensionIcon from '@mui/icons-material/Extension';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import { format, isToday } from 'date-fns';
import ButtonLink from './ButtonLink';

// Helper switch for icon
const iconSwitch = {
	NewProblemChat: MessageIcon,
	ProblemEndorsed: ThumbUpIcon,
	ProblemStatusChanged: ExtensionIcon,
	CollaborationInvite: MailIcon
}

const NotificationRow = ({ notification : n, onClick, extended }) => {
	const date = new Date(n.notificationDate);
	let url = "", colour = "";
	
	switch (n.notificationType) {
		case "NewProblemChat":
			url = n.details ? `/problems/${n.details.ProblemId}` : "";
			colour = "green";
			break;
		case "ProblemEndorsed":
			url = n.details ? `/problems/${n.details.ProblemId}` : "";
			colour = "blue";
			break;
		case "ProblemStatusChanged":
			url = n.details ? `/problems/${n.details.ProblemId}` : "";
			colour = "red";
			break;
		case "CollaborationInvite":
			url = n.details ? `/collaborations/${n.details.CollaborationId}` : "";
			colour = "green";
			break;
		default:
	}

	const content = (
		<React.Fragment>
			<Fab className={colour} size="small">
				{React.createElement(iconSwitch[n.notificationType])}	
			</Fab>
			<div className="content">
				<Typography variant="body2">{n.title}</Typography>
				{n.description && <span className="description">{n.description}</span>}
				<span className="time">
					{format(date, `h:mm a${!isToday(date) ? " d/M/yyyy" : ""}`)}
				</span>
			</div>
			{extended && <ButtonLink className={colour} to={url} onClick={() => onClick(n)} variant="outlined">View Details</ButtonLink>}
		</React.Fragment>
	);

	return extended ? <div className="notification-row extended">{content}</div> : 
		React.createElement(url ? Link : "a", { className: "notification-row", to: url, onClick: () => onClick(n) }, content);
};

NotificationRow.propTypes = {
	notification: PropTypes.shape({
		notificationId: PropTypes.number,
		title: PropTypes.string,
		message: PropTypes.string,
		notificationType: PropTypes.string,
		userId: PropTypes.string,
		readDate: PropTypes.string,
		notificationDate: PropTypes.string,
		notificationSentDate: PropTypes.string,
		details: PropTypes.object
	}).isRequired,
	onClick: PropTypes.func.isRequired,
	extended: PropTypes.bool
};

NotificationRow.defaultProps = {
	extended: false
}

export default NotificationRow;