import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab, Grid } from '@mui/material';
import { getProfile, updateProfile, changePassword, isLoading } from '../../store/profiles';
import { updateFromProfile } from '../../store/context';
import { markReadUserNotification, isLoading as isNotificationsLoading } from '../../store/userNotifications';
import { withForm } from '../../utils/forms';
import PageLayout from '../PageLayout';
import Card from '../widgets/Card';
import ContactInfo from './ContactInfo';
// import Details from './Details';
import Settings from './Settings';
// import Social from './Social';
import PasswordReset from './PasswordReset';
import pick from 'lodash/pick';

const form = {
	initValues: props => props.profile,
	fields: [
		{
			name: "userName",
			required: true,
			label: "Username"
		},
		{
			name: "email",
			required: true,
			label: "Email",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "firstName",
			required: true,
			label: "First name"
		},
		{
			name: "lastName",
			required: true,
			label: "Last name"
		},
		{
			name: "oldPassword",
			required: true,
			label: "Old password",
			type: "password"
		},
		{
			name: "newPassword",
			required: true,
			label: "New password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters"
		},
		{
			name: "cpassword",
			required: true,
			label: "Confirm password",
			type: "password",
			validate: (value, values) => value === values.newPassword ? "" : "Both passwords must be identical"
		},
		{
			name: "description",
			label: "About Me",
			type: "textEditor"
		},
		{
			name: "organisationId",
			label: "Site",
			type: "autoComplete",
			loadItems: {
				route: "organisations?type=Organisation", 
				mapItem: o => ({ label: o.name, value: o.organisationId })
			},
			onChange: values => {
				values.departmentId = null;
				values.positionId = null;
			}
		},
		{
			name: "userOrganisation",
			label: "Organisation"
		},
		{
			name: "departmentId",
			label: "Business unit",
			type: "autoComplete",
			loadItems: {
				route: (props, values) => `departments/by-organisation/${values.organisationId}`, 
				mapItem: d => ({ label: d.name, value: d.departmentId })
			}
		},
		{
			name: "positionId",
			label: "Position",
			type: "autoComplete",
			loadItems: {
				route: (props, values) => `positions/by-organisation/${values.organisationId}`, 
				mapItem: p => ({ label: p.title, value: p.positionId })
			}
		},
		{
			name: "emailNotifications",
			label: "Send notifications by email",
			type: "checkbox"
		},
		{
			name: "avatar",
			label: "Avatar",
			type: "avatar"
		},
		{
			name: "facebook",
			label: "Facebook account",
			startAdornment: "https://www.facebook.com/",
			validate: value => value.match(/^[a-z0-9_.]+$/i) ? "" : "Facebook Account should be a valid link"
		},
		{
			name: "twitter",
			label: "Twitter account",
			startAdornment: "https://www.twitter.com/",
			validate: value => value.match(/^[a-z0-9_.]+$/i) ? "" : "Twitter Account should be a valid link"
		},
		{
			name: "linkedIn",
			label: "LinkedIn account",
			startAdornment: "https://www.linkedin.com/in/",
			validate: value => value.match(/^[a-z0-9_.]+$/i) ? "" : "LinkedIn Account should be a valid link"
		},
		{
			name: "membershipLevelId",
			label: "Membership Level",
			type: "autoComplete",
			loadItems: {
				route: props => `organisations/membership-levels?organisationId=${props.organisationId}`,
				mapItem: level => ({ label: level.title, value: level.membershipLevelId })
			}
		}
	]
};

const ProfileController = props => {
	const [tab, setTab] = React.useState(0);
	const formProps = pick(props, "loading", "fields", "updateResult");

	React.useEffect(() => props.getProfile(props.userId), [props.userId]);
	
	const updateProfile = () => {
		const fields = ["username", "email", "firstName", "lastName", "avatar", "description", 
			"organisationId", "positionId", "departmentId", "emailNotifications", "membershipLevelId", "userOrganisation"];

		if (props.validateFields(fields)) {
			props.updateProfile({ ...props.profile, ...pick(props.values, fields) }, props.formFiles.avatar);
		}
	}

	// const updateSocial = () => {
	// 	const fields = ["facebook", "twitter", "linkedIn"];

	// 	if (props.validateFields(fields)) {
	// 		props.updateProfile({ ...props.profile, ...pick(props.values, fields) });
	// 	}
	// }

	const changePassword = () => {
		const fields = ["oldPassword", "newPassword", "cpassword"];

		if (props.validateFields(fields)) {
			props.changePassword(props.profile, pick(props.values, fields));
		}
	}

	const changeMembershipLevel = item => {
		props.updateProfile({ ...props.profile, membershipLevelId: item.value });
	}

	return <PageLayout title="Profile" pageContent={
		<Grid container spacing={4} className="profile-container">
			<Grid item xs="auto">
				<ContactInfo 
					profile={props.profile} 
					loading={props.loading} 
					organisationId={props.organisationId}
					showMembershipLevelId={props.showMembershipLevelId}
					membershipLevelId={props.values.membershipLevelId}
					changeMembershipLevel={changeMembershipLevel}
				/>
			</Grid>
			<Grid item xs="auto" md>
				<Card bodyStyle={{ padding: 0 }}>
					<div className="tab-content">
						<Tabs value={tab} indicatorColor="primary" onChange={(e, newTab) => setTab(newTab)}>
							<Tab label="Profile" />
							{/* <Tab label="Settings" />
							<Tab label="Social" /> */}
							<Tab label="Change password" />
						</Tabs>
						{/* Details */}
						{/* {tab === 0 && 
							<Details 
								profile={props.profile} 
								notifications={props.notifications} 
								markNotification={props.markNotification} 
								notificationsLoading={props.notificationsLoading} 
							/>
						} */}
						{/* Settings */}
						{tab === 0 && <Settings handleSubmit={updateProfile} showMembershipLevelId={props.showMembershipLevelId} {...formProps} />}
						{/* Social */}
						{/* {tab === 2 && <Social handleSubmit={updateSocial} {...formProps} />} */}
						{/* Password Reset */}
						{tab === 1 && <PasswordReset handleSubmit={changePassword} {...formProps} />}
					</div>
				</Card>
			</Grid>
		</Grid>
	} breadcrumbs={["Profile"]} />;
};

ProfileController.propTypes = { 
	userId: PropTypes.string.isRequired,
	profile: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	plural: PropTypes.string.isRequired,
	notifications: PropTypes.array.isRequired,
	notificationsLoading: PropTypes.bool.isRequired,
	markNotification: PropTypes.func.isRequired,
	showMembershipLevelId: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
	userId: state.context.userId,
	profile: state.profiles.profile,
	loading: isLoading(state),
	updateResult: state.profiles.updateResult,
	plural: state.settings.plural,
	notifications: state.userNotifications.userNotifications.filter(n => !n.readDate),
	notificationsLoading: isNotificationsLoading(state),
	showMembershipLevelId: state.context.showMembershipLevelId,
	organisationId: state.context.organisationId
});

const mapDispatchToProps = dispatch => ({
	getProfile: userId => dispatch(getProfile(userId)),
	updateProfile: (profile, avatarFile) => dispatch(updateProfile(profile, avatarFile, result => 
		result.object && dispatch(updateFromProfile(result.object))
	)),
	changePassword: (profile, password) => dispatch(changePassword(profile, password)),
	markNotification: notification => dispatch(markReadUserNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ProfileController));