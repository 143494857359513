import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Tooltip } from '@mui/material';
import FavouriteButton from '../tools/FavouriteButton';
import Tag from '../widgets/Tag';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	toolRow: {
		'& .label': {
			backgroundColor: theme.palette.primary.main
		},
		'& .academic': {
			'& svg': {
				color: theme.palette.secondary.main
			}
		}
	}
});

const ToolRow = ({ tool: t, isCommunity, classes }) => 
	<div className={`tool-row ${classes.toolRow}`}>
		<div className="header">
			<Link className="h4" to={`/${isCommunity ? "my-community/" : ""}tools/${t.toolId}`}>{t.name}</Link>
			<FavouriteButton toolId={t.toolId} isFavourite={t.isFavourite} />
		</div>
		{t.academicId &&
			<span className="h6 academic">
				<Tooltip title="Academic Author"><VerifiedUserIcon /></Tooltip>
				{t.academicName}
			</span>
		}
		<span className="label">{t.channel}</span>
		<div className="description">{t.description}</div>
		<div className="tags">
			{t.tags.map(t => <Tag key={t.tagId} label={t.name} />)}
		</div>
	</div>;

ToolRow.propTypes = {
	tool: PropTypes.object.isRequired,
	isCommunity: PropTypes.bool
};

ToolRow.defaultProps = {
	isCommunity: false
};

export default withStyles(ToolRow, styles);