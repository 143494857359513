import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions, 
	CircularProgress
} from '@mui/material';
import { getOrganisation } from '../../store/organisations';
import { 
	getUnitsOfCompetency,
	getProfileUnitsOfCompetency,
	getFrameworkUnitsOfCompetency,
	isLoading as isCompetenciesLoading
} from '../../store/unitsOfCompetency';
import { 
	getCompetencies
} from '../../store/competencies';
import { getSkillsProfiles, isLoading as isProfilesLoading } from '../../store/skillsProfiles';
import Button from '../widgets/Button';
import Select from '../widgets/Select';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import UnitOfCompetencyPickerUnits from '../competencies/UnitOfCompetencyPickerUnits';
import UnitOfCompetencyPickerBySpecialisation from '../competencies/UnitOfCompetencyPickerBySpecialisation';

const UnitsOfCompetencyDialog = props => {
	const dialogBody = React.useRef(null);
	const [loading, setLoading] = React.useState(false);
	const [organisationLoading, setOrganisationLoading] = React.useState(false);
	const [competenciesLoading, setCompetenciesLoading] = React.useState(false);
	const [state, setState] = React.useState({
		competencies: [],
		selectedProfile: "",
		selectedUnits: [],
		currentUnit: null,
		showPrompt: false,
		frameworks: [],
		selectedFramework: ""
	});

	React.useEffect(() => {
		setLoading(true);
		setOrganisationLoading(true);
		setCompetenciesLoading(true);

		props.getOrganisation(
			props.organisationId, 
			() => setOrganisationLoading(false),
			() => setOrganisationLoading(false)
		);

		props.getCompetencies(
			props.userId, 
			(userCompetencies) => {
				let { skillsProfileId, competencies } = userCompetencies;
				setState(prevState => ({ ...prevState, skillsProfileId, competencies }));
				setCompetenciesLoading(false);
			},
			() => setCompetenciesLoading(false)
		);
	}, []);

	React.useEffect(() => {
		if (!organisationLoading && !competenciesLoading) {
			setLoading(false);
		}
	}, [organisationLoading, competenciesLoading]);

	React.useEffect(() => {
		if (props.open) props.getSkillsProfiles(() => fetchUnits());
		setState({ ...state, selectedUnits: state.competencies, currentUnit: null });
	}, [props.open]);

	const fetchUnits = (profileId = state.skillsProfileId) => {
		setLoading(true);
		if (profileId) {
			props.getProfileUnitsOfCompetency(profileId, false, () => setLoading(false));
		} else {
			props.getUnitsOfCompetency(() => setLoading(false));
		}

		setState(prevState => ({ ...prevState, selectedProfile: profileId || 0 }));
	}

	const viewUnit = unit => {
		setState({ ...state, currentUnit: unit, currentScroll: dialogBody.current.scrollTop });
		window.setTimeout(() => dialogBody.current.scrollTop = 0);
	}

	const onOk = () => {
		if (state.currentUnit) {
			setState({ ...state, currentUnit: null });
			window.setTimeout(() => dialogBody.current.scrollTop = state.currentScroll);
		} else {
			props.onClose();
		}
	}

	const 
		currentUnits = map(state.competencies, u => u.unitOfCompetencyId),
		{ currentUnit } = state,
		filtered = currentUnits.length > 0 ? props.unitsOfCompetency.filter(u => !currentUnits.includes(u.unitOfCompetencyId)) : props.unitsOfCompetency,
		selectedElements = reduce(state.selectedUnits, (acc, u) => [...acc, ...u.elements], []),
		graphicalSkillsView = props.organisation ? props.organisation.graphicalSkillsView : true;

	const UnitComponent = graphicalSkillsView ? 
		<UnitOfCompetencyPickerBySpecialisation
			units={props.unitsOfCompetency}
			selectedElements={selectedElements}
			competencies={state.competencies}
			viewUnit={viewUnit}
			expandRows={props.expandRows}
			showNotCompetent={false}
			canEdit={false}
			errorColour={props.errorColour}
		/> : 
		<UnitOfCompetencyPickerUnits
			units={filtered}
			selectedElements={selectedElements}
			viewUnit={viewUnit}
			unitsOfCompetency={props.unitsOfCompetency}
			expandRows={props.expandRows}
			showNotCompetent={false}
			canEdit={false}
			errorColour={props.errorColour}
		/>

	return (
		<Dialog	
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
			fullWidth={true}
			maxWidth="xl"
		>
			<DialogTitle id="dialog-title">
				Units Of Competencies
			</DialogTitle>
			<>
				<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
					{loading ? <CircularProgress size={24} /> : 
						<React.Fragment>							
							{props.enableFrameworkSelection &&
								<Select
									value={state.selectedFramework}
									label="Framework"
									onChange={e => setState({ ...state, selectedFramework: e.target.value })}
									items={map(state.frameworks, f => f.frameworkCode)}
								/>
							}
							{currentUnit ?
								<div style={{ marginTop: 20 }}>
									<h3>{currentUnit.unitCode} {currentUnit.unitTitle}</h3>
									{currentUnit.unitDescriptionAndApplication && <p>{currentUnit.unitDescriptionAndApplication}</p>}
									{currentUnit.performanceEvidence && 
										<React.Fragment>
											<h3>Performance Evidence/Skill</h3>
											<p>{currentUnit.performanceEvidence}</p>
										</React.Fragment>
									}
									{currentUnit.knowledgeEvidence && 
										<React.Fragment>
											<h3>Knowledge Evidence</h3>
											<p>{currentUnit.knowledgeEvidence}</p>
										</React.Fragment>
									}
									{currentUnit.elements && currentUnit.elements.length > 0 && 
										<React.Fragment>
											<h3>Elements/Levels</h3>
											<ul>{map(currentUnit.elements, (e, i) => <li key={i} className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />)}</ul>
										</React.Fragment>
									}
								</div> :
								UnitComponent
							}
						</React.Fragment>
					}
				</DialogContent>
			</>
			<DialogActions>
				<Button onClick={onOk}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

UnitsOfCompetencyDialog.propTypes = {
	userId: PropTypes.string.isRequired,
	unitsOfCompetency: PropTypes.arrayOf(PropTypes.object).isRequired,
	profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	otherUnits: PropTypes.bool,
	allowMultiple: PropTypes.bool,
	skillsProfileId: PropTypes.number,
	competencies: PropTypes.array,
	promptSelection: PropTypes.bool,
	canEdit: PropTypes.bool,
	expandRows: PropTypes.bool,
	enableFrameworkSelection: PropTypes.bool,
	showNotCompetent: PropTypes.bool,
	okButtonLabel: PropTypes.string,
	organisationId: PropTypes.number
};

UnitsOfCompetencyDialog.defaultProps = {
	otherUnits: false,
	allowMultiple: false,
	skillsProfileId: 0,
	competencies: [],
	promptSelection: false,
	canEdit: true,
	expandRows: true,
	enableFrameworkSelection: false,
	showNotCompetent: false,
	okButtonLabel: "Add",
	organisationId: 0,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	userId: state.context.userId,
	organisationId: state.context.organisationId,
	organisation: state.organisations.organisation,
	unitsOfCompetency: state.unitsOfCompetency.unitsOfCompetency,
	profiles: state.skillsProfiles.skillsProfiles,
	loading: isCompetenciesLoading(state) || isProfilesLoading(state),
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getOrganisation: (organisationId, onSuccess, onError) => dispatch(getOrganisation(organisationId, onSuccess, onError)),
	getCompetencies: (userId, onSuccess, onError) => dispatch(getCompetencies(userId, false, onSuccess, onError)),
	getUnitsOfCompetency: (onSuccess) => dispatch(getUnitsOfCompetency(onSuccess)),
	getProfileUnitsOfCompetency: (profileId, other, onSuccess) => dispatch(getProfileUnitsOfCompetency(profileId, other, onSuccess)),
	getFrameworkUnitsOfCompetency: frameworkId => dispatch(getFrameworkUnitsOfCompetency(frameworkId)),
	getSkillsProfiles: onSuccess => dispatch(getSkillsProfiles(0, onSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitsOfCompetencyDialog);