import React from 'react';
import PropTypes from 'prop-types';
import capitalise from 'lodash/capitalize';

const LogCategory = props => {
	let { category } = props;
	const styles = {
		cursor: "default",
		fontWeight: "bold",
		marginRight: 5,
		color: "white",
		backgroundColor: ({
			Authentication: "#a4660e",
			Offers: "#f0ad4e",
			Events: "#bb1b75",
			Features: "#b31aff",
			Problems: "#447227",
			Profile: "#d1332e",
			Settings: "#257431",
			UserMaintenance: "#1b75bb",
			ResearchCentres: "#00b3b3",
			MyCommunity: "#a7d47d",
			Passport: "#e39b54"
		})[category] || "grey"
	};

	if (category === "Problems") category = capitalise(props.plural);

	return <span className="label" style={styles}>{category}</span>;
};

LogCategory.propTypes = {
	category: PropTypes.oneOf([
		"Applications",
		"Authentication", 
		"Offers",
		"Events", 
		"Features",
		"Problems", 
		"Profile",
		"Settings",
		"Support",
		"UserMaintenance",
		"Vendors",
		"ResearchCentres"
	]).isRequired,
	plural: PropTypes.string
};

LogCategory.defaultProps = {
	plural: "problems"
};

export default LogCategory;