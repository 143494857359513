import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveCommunity, isLoading as communitiesLoading } from '../../store/communities';
import { getChannels, isLoading as channelsLoading } from '../../store/channels';
import PageLayout from '../PageLayout';
import EditCommunity from './EditCommunity';

const CreateCommunityController = props => {
	React.useEffect(props.getChannels, []);

	return <PageLayout title="Create community" pageContent={
		<EditCommunity 
			saveCommunity={props.saveCommunity} 
			loading={props.loading} 
			channels={props.channels} 
			isNew 
		/>
	} breadcrumbs={[{ label: "Communities", path: "/admin/communities" }, "Create community"]} />;
}

CreateCommunityController.propTypes = {
	saveCommunity: PropTypes.func.isRequired,
	getChannels: PropTypes.func.isRequired,
	channels: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	channels: state.channels.channels,
	loading: communitiesLoading(state) || channelsLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveCommunity: (community, avatarFile) => dispatch(saveCommunity(community, avatarFile)),
	getChannels: () => dispatch(getChannels())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCommunityController);