import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../../utils/forms';
import { menuIcons } from '../../../utils/utils';
import DataTable from '../../widgets/dataTable/DataTable';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import FormButtons from '../../widgets/FormButtons';
import SearchField from '../../widgets/SearchField';
import TextField from '../../widgets/TextField';
import Checkbox from '../../widgets/Checkbox';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';
import some from 'lodash/some';

const form = {
	initValues: props => ({ functions: [], menus: [], ...props.role }),
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			loadItems: {
				route: "tenants",
				mapItem: t => ({ label: t.name, value: t.tenantId })
			}
		},
		{
			name: "name",
			label: "Role Name",
			required: true,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "description",
			label: "Description",
			placeholder: "",
			multiline: true,
			disabled: props => !props.canEdit
		},
		{
			name: "reportGroups",
			label: "Report Groups",
			type: "autoComplete",
			loadItems: {
				route: "reports/groups",
				mapItem: g => ({ ...g, label: g.name, value: g.groupId })
			},
			isMulti: true,
			mapValue: g => ({ ...g, label: g.name, value: g.groupId })
		}
	]
};

const EditRole = props => {
	const [functionSearch, setFunctionSearch] = React.useState("");
	const [menuSearch, setMenuSearch] = React.useState("");
	const [menuLabels, setMenuLabels] = React.useState(null);

	const { fields, values, loading, isNew } = props;
	const roleMenus = values.menus || [];

	React.useEffect(() => {
		if (roleMenus.length > 0 && !menuLabels) {
			const labels = {};

			roleMenus.forEach(m => labels[m.menuId] = m.label);
			setMenuLabels(labels);
		}
	}, [roleMenus.length]);

	const onSubmit = () => {
		if (props.validateFields()) {
			const menus = [...roleMenus];

			menus.forEach(m => m.label = menuLabels[m.menuId]);
			props.saveRole({ ...values, menus });
		}
	}

	const updateRoleMenu = (menuId, field, value) => {
		const copy = [...values.menus];
		copy.find(m2 => m2.menuId === menuId)[field] = value;

		props.updateValues({ menus: copy });
	}

	return (
		<Card title={isNew ? "Add Role" : "Edit Role"} className="edit-role" loading={loading}>
			<Form onSubmit={onSubmit}>
				<Grid container spacing={3}>
					{props.canSelectTenant && isNew && <Grid item xs={12}>{fields.tenantId()}</Grid>}
					<Grid item xs={12}>{fields.name()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.reportGroups()}</Grid>
				</Grid>
			</Form>
			<SearchField label="Search functions" value={functionSearch} onChange={value => setFunctionSearch(value)} style={{ marginTop: 20 }} />
			<DataTable data={props.functions} rowHeight={51} search={functionSearch}>
				<DataTableColumn renderer={f =>
					<Checkbox
						disabled={!props.canEdit}
						checked={some(values.functions, f2 => f2.functionId === f.functionId)}
						onChange={evt => {
							if (evt.target.checked) {
								props.updateValues({ functions: [...values.functions, f] });
							} else {
								props.updateValues({ functions: values.functions.filter(f2 => f2.functionId !== f.functionId) })
							}
						}}
					/>
				} />,
				<DataTableColumn name="name" label="Name" />
				<DataTableColumn name="description" label="Description" />
				<DataTableColumn name="group" label="Group" canFilter filterType="AutoComplete" items={props.functionGroups} />
			</DataTable>
			<SearchField label="Search menus" value={menuSearch} onChange={value => setMenuSearch(value)} style={{ marginTop: 20 }} />
			<DataTable data={props.menus} rowHeight={51} search={menuSearch}>
				<DataTableColumn renderer={m =>
					<Checkbox
						disabled={!props.canEdit}
						checked={some(roleMenus, m2 => m2.menuId === m.menuId)}
						onChange={evt => {
							if (evt.target.checked) {
								props.updateValues({ menus: [...roleMenus, { menuId: m.menuId, label: "", active: true }] });
							} else {
								props.updateValues({ menus: roleMenus.filter(m2 => m2.menuId !== m.menuId) })
							}
						}}
					/>
				} />,
				<DataTableColumn name="menuName" label="Name" />
				<DataTableColumn name="label" label="Label" width={150} renderer={m => {
					const selected = some(roleMenus, m2 => m2.menuId === m.menuId),
						value = (menuLabels && menuLabels[m.menuId]) || "";

					return <TextField 
						value={value}
						onChange={e => setMenuLabels({ ...menuLabels, [m.menuId]: e.target.value })} 
						disabled={!selected}
						error={Boolean(value && !selected)}
						helpText={(value && !selected) ? "This value will not be saved" : ""}
					/>
				}} />
				<DataTableColumn name="displaySequence" label="Display Seq." align="center" width={70} />
				<DataTableColumn label="Items" align="center" renderer={m => m.items.length} />
				<DataTableColumn name="description" label="Description" renderer={m => m.description || <em>No description provided...</em>} />
				<DataTableColumn name="iconRef" label="Icon" align="center" renderer={m => menuIcons[m.iconRef] ? React.createElement(menuIcons[m.iconRef]) : ""} />
				<DataTableColumn name="disabled" label="Disabled" align="center" dataType="bool" />
				<DataTableColumn name="active" label="Active" align="center" renderer={m => {
					const roleMenu = roleMenus.find(m2 => m2.menuId === m.menuId);

					return (
						<Checkbox
							checked={Boolean(roleMenu && roleMenu.active)}
							disabled={roleMenu === undefined}
							onChange={e => updateRoleMenu(m.menuId, "active", e.target.checked)}
						/>
					)
				}} />
			</DataTable>
			{props.canEdit && 
				<FormButtons 
					loading={loading}
					success={props.saveResult.success}
					onSave={onSubmit}
					showDelete={!isNew}
					onDelete={() => props.onDelete(values.roleId)}
				/>
			}
		</Card>
	);
};

EditRole.propTypes = { 
	canEdit: PropTypes.bool,
	canSelectTenant: PropTypes.bool,
	role: PropTypes.object,
	functions: PropTypes.arrayOf(PropTypes.object).isRequired,
	functionGroups: PropTypes.array.isRequired,
	saveRole: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditRole.defaultProps = {
	canEdit: false,
	canSelectTenant: false,
	role: {},
	onDelete: () => null,
	isNew: false
};

export default withForm(form)(EditRole);