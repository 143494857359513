import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Registration from './Registration';
import ResearchLogiqRegistration from './ResearchLogiqRegistration';
import { isResearchLogiq } from '../../store/selectors/tenants';

const RegistrationRouter = props => {
	if (props.isResearchLogiq) {
		return <ResearchLogiqRegistration {...props} />;
	} else {
		return <Registration {...props} />;
	}
};

RegistrationRouter.propTypes = {
	isResearchLogiq: PropTypes.bool.isRequired
};

RegistrationRouter.defaultProps = {
	isResearchLogiq: false
};

const mapStateToProps = (state, ownProps) => ({
	isResearchLogiq: isResearchLogiq(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationRouter);