import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { connect } from 'react-redux';
import { Grid, CircularProgress, Paper, Tabs, Tab } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { canSubmitResources, canSubmitCommunityResources } from '../../store/security';
import { searchResources, searchSubscribedResources, isLoading } from '../../store/resources';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import ResourceCard from './ResourceCard';
import map from 'lodash/map';
import pick from 'lodash/pick';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchResources(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "type",
			label: "Filter by type",
			type: "select",
			items: [{ label: "(All)", value: "all" }, "Video", "Article", "Case Study", "Template"]
		},
		{
			name: "tag",
			label: "Filter by tag",
			type: "autoComplete",
			loadItems: { 
				route: (props, values) => `tags/resources?visibility=${values.visibility}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		},
		{ name: "visibility" }
	]
};

const tabs = ["private", "all"];

const ResourcesController = props => {
	const [tab, setTab] = React.useState(0);
	const { resources, isCommunity } = props;

	React.useEffect(() => {
		const { searchArgs } = props;
		
		props.searchResources(searchArgs);
		setTab(tabs.indexOf(searchArgs.visibility));
	}, [isCommunity]);

	const handleVisibilityChange = (e, tab) => {
		const visibility = tabs[tab];

		props.updateValues({ visibility });
		props.searchResources({ ...props.values, visibility });
		setTab(tab);
	}
	
	return <PageLayout title="Resources" pageContent={
		<React.Fragment>
			<Paper style={{ display: "inline-block" }}>
				<Tabs value={tab} onChange={handleVisibilityChange}>
					<Tab label="Private" />
					<Tab label="All" />
				</Tabs>
			</Paper>
			{props.canSubmit && 
				<ButtonLink to={`/${props.isCommunity ? "my-community/" : ""}resources/create`} style={{ float: "right" }}>
					Create resource
				</ButtonLink>
			}
			<Grid container spacing={3} alignItems="flex-end">
				<Grid item xs={12} sm>{props.fields.search()}</Grid>
				<Grid item xs={12} sm={3}>{props.fields.type()}</Grid>
				<Grid item xs={12} sm={3}>{props.fields.tag()}</Grid>
			</Grid>
			{resources.length === 0 ? <p style={{ marginTop: 10 }}>No resources found...</p> :
				<Grid container spacing={3}>
					{map(resources, (c, i) => 
						<Grid key={i} item xs={12} sm={6} md={4} lg={3}>
							<ResourceCard isCommunity={isCommunity} resource={c} />
						</Grid>
					)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={[isCommunity ? { label: "My Community", path: "/my-community" } : "", "Resources"]} />;
};

ResourcesController.propTypes = { 
	resources: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchResources: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool
};

ResourcesController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	resources: state.resources.resources,
	loading: isLoading(state),
	searchArgs: { 
		type: "all", 
		tag: "all", 
		visibility: "all",
		...pick(getQueryParameters(ownProps.location.search), "search", "type", "tag", "visibility") 
	},
	canSubmit: ownProps.isCommunity ? canSubmitCommunityResources(state) : canSubmitResources(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	searchResources: args => dispatch(ownProps.isCommunity ? searchSubscribedResources(args) : searchResources(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ResourcesController));