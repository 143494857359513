import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import {
	pSBC
} from '../../utils/utils';

const DevelopmentNeeds = ({ userId, setLoading, title, description }) => {
	const [data, setData] = React.useState([]);
	const [specialisationGroupId, setSpecialisationGroupId] = React.useState(null);
	const [specialisationId, setSpecialisationId] = React.useState(null);

	const loadData = (specGroupId, specId) => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/development-needs?userId=${userId}&specialisationGroupId=${specGroupId}&specialisationId=${specId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (userId) {
			loadData(specialisationGroupId, specialisationId);
		}
	}, [userId]);

	const metData = map(data, d => ({
		name: `${d.label}:  ${d.unitCount} required, ${d.met} achieved`,
		fullLabel: `${d.label}:  ${d.unitCount} required, ${d.met} achieved`,
		value: d.met,
		met: d.met,
		unitCount: d.unitCount,
		itemStyle: {
			color: `#${d.colour}`
		},
		level: d.level,
		itemId: d.id
	}));
	const gapData = map(data, d => ({
		name: `${d.label}:  ${d.unitCount} required, ${d.met} achieved`,
		fullLabel: `${d.label}:  ${d.unitCount} required, ${d.met} achieved`,
		value: d.unitCount - d.met,
		met: d.met,
		unitCount: d.unitCount,
		itemStyle: {
			color: pSBC(-0.4, '#' + d.colour)
		},
		level: d.level,
		itemId: d.id
	}));

	const xAxisData = map(data || [], d => d.label);

	return (
		<Chart 
			info={{
				title: title,
				text: description
			}}
			toolbox={{
				myTool2: {
					show: !!specialisationGroupId || !!specialisationId,
					title: 'Drill Up',
					icon: 'path://M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z',
					onclick: () => {
						if (specialisationId) {
							setSpecialisationId(null);
							loadData(specialisationGroupId, null);
						} else if (specialisationGroupId) { 
							setSpecialisationGroupId(null);
							loadData(null, null);
						}
					}
				}
			}}
			option={{
				tooltip: {
					trigger: 'item',
					formatter: '{b}'
				},
				xAxis: {
					data: xAxisData.map((d, i) => {
						switch (i % 3) {
							case 0: return d;
							case 1: return `\n${d}`;
							case 2: return `\n\n${d}`;
							default: return d;
						}
					}),
					axisLabel: {
						interval: 0
					}
				},
				yAxis: {},
				series: [
					{
						name: 'meets',
						type: 'bar',
						stack: 'one',
						data: metData
					},
					{
						name: 'gap',
						type: 'bar',
						stack: 'one',
						data: gapData
					}
				]
			}}
			style={{ height: "100%" }} 
			onEvents={{
				"click": param => {
					switch (param.data.level) {
						case "SpecialisationGroup":
							setSpecialisationGroupId(param.data.itemId);
							loadData(param.data.itemId, specialisationId);
							break;
						case "Specialisation":
							setSpecialisationId(param.data.itemId);
							loadData(specialisationGroupId, param.data.itemId);
							break;
						default:
							break;
					}
				}
			}}
		/>
	);
};

DevelopmentNeeds.propTypes = {
	userId: PropTypes.string.isRequired,
	setLoading: PropTypes.func.isRequired
};

DevelopmentNeeds.defaultProps = {
};

export default DevelopmentNeeds;
