import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canSubmitTenants, canEditTenant } from '../../store/security';
import { showModal } from '../../store/modal';
import { 
	getTenants,
	deleteTenant,
	isLoading
} from '../../store/tenants';
import PageLayout from '../PageLayout';
import Tenants from './Tenants';

const TenantsController = props => {
	React.useEffect(props.getTenants, []);

	return <PageLayout title="Manage tenants" pageContent={
		<Tenants 
			tenants={props.tenants} 
			loading={props.loading} 
			canSubmit={props.canSubmit} 
			canEdit={props.canEdit}
			deleteTenant={props.deleteTenant} 
			primaryColour={props.primaryColour} 
			errorColour={props.errorColour} 
		/>
	} breadcrumbs={["Tenants"]} />;
};

TenantsController.propTypes = { 
	tenants: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	deleteTenant: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	tenants: state.tenants.tenants,
	loading: isLoading(state),
	canSubmit: canSubmitTenants(state),
	canEdit: canEditTenant(state),
	primaryColour: state.theme.primaryColour,
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getTenants: () => dispatch(getTenants()),
	deleteTenant: tenant => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this tenant?",
		onOk: () => dispatch(deleteTenant(tenant))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantsController);
