import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import Avatar from '../widgets/Avatar';

const ViewMentee = props => {
	const { mentee } = props;
	
	return props.loading || isEmpty(mentee) ? <CircularProgress size={24} /> : (
		<Card className="mentee-details">
			<div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
				<Avatar src={mentee.avatar} fullName={mentee.fullName} style={{ marginRight: 5 }} />
				<span className="h2" style={{ margin: 0 }}>{mentee.fullName}</span>
			</div>
			<section>
				<span className="h4">About</span>
				<div dangerouslySetInnerHTML={{ __html: mentee.about }} />
			</section>
			{props.canEdit && 
				<ButtonLink to={`/mentees/edit/${mentee.menteeId}`}>Edit mentee</ButtonLink>
			}
		</Card>
	);
};

ViewMentee.propTypes = {
	mentee: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool
};

ViewMentee.defaultProps = {
	canEdit: false
};

export default ViewMentee;