import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { registerResearchLogiq, isLoading } from '../../store/context';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import TermsDialog from '../modals/TermsDialog';
import lowerCase from 'lodash/lowerCase';
import Form from '../widgets/Form';
import { getQueryParameters } from '../../utils/utils';
import startsWith from 'lodash/startsWith';
import toLower from 'lodash/toLower';

const validUsername = /^[a-z0-9_.]+$/i,
	validEmail = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

const form = {
	initValues: props => {
		return { 
			organisationId: props.organisationId, 
			membershipLevelId: null
		};
	},
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "username",
			required: true,
			label: "Username",
			helpText: "Do NOT use your email address",
			validate: value => validUsername.test(value) ? "" : "Username must contain no spaces or special characters"
		},
		{
			name: "firstName",
			required: true,
			label: "First name",
			autoFocus: true,
		},
		{
			name: "lastName",
			required: true,
			label: "Last name",
			onBlur: (value, values, setValue) => {
				if (values.firstName && !values.username) {
					setValue("username", `${lowerCase(values.firstName)}.${lowerCase(values.lastName)}`);
				}
			}
		},
		{
			name: "email",
			required: true,
			label: "Email",
			validate: value => validEmail.test(value) ? "" : "Email must be a valid address",
			helpText: "Your company email address",
		},
		{
			name: "password",
			required: true,
			label: "Password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters"
		},
		{
			name: "cpassword",
			required: true,
			label: "Confirm password",
			type: "password",
			validate: (value, values) => value === values.password ? "" : "Both passwords must be identical"
		},
		{
			name: "userType",
			label: "You are registering as",
			type: "select",
			required: true,
			items: ["University User", "Organisational User"]
		},
		{
			name: "universityUserOrg",
			label: "Your affiliated organisation or institution",
			helpText: "If it already exists it will prepopulate for you",
			type: "autoComplete",
			disabled: (props, values) => {
				return !values.userType;
			},
			required: (props, values) => values.userType === "University User",
			loadItems: {
				route: props => `organisations/self-reg?type=Institution&subdomainId=${props.subdomainId || ""}`,
				mapItem: o => ({ label: o.name, value: o.organisationId, organisation: o }),
			},
			canCreate: false,
			openMenuOnClick: false,
			filterOption: (option, inputValue) => startsWith(toLower(option.label), toLower(inputValue)),
			formatCreateLabel: inputValue => inputValue,
			onCreateOption: (newValue, items, setItems, onChange) => {
				const newItem = { label: newValue, value: newValue };
				setItems([...items, newItem]);
				onChange(newItem);
			},
			onChange: (values, value) => {
				if (value.organisation) {
					values.organisationId = value.value;
					values.userOrganisation = '';
				} else {
					values.organisationId = 0;
					values.userOrganisation = value.value;
				}
			},
		},
		{
			name: "organisationalUserOrg",
			label: "Your affiliated organisation or institution",
			helpText: "If it already exists it will prepopulate for you",
			type: "autoComplete",
			disabled: (props, values) => {
				return !values.userType;
			},
			required: (props, values) => values.userType === "Organisational User",
			loadItems: {
				route: (props, values) => `organisations/self-reg?subdomainId=${props.subdomainId || ""}`,
				mapItem: o => ({ label: o.name, value: o.organisationId, organisation: o }),
			},
			canCreate: true,
			openMenuOnClick: false,
			filterOption: (option, inputValue) => startsWith(toLower(option.label), toLower(inputValue)),
			formatCreateLabel: inputValue => inputValue,
			onCreateOption: (newValue, items, setItems, onChange) => {
				const newItem = { label: newValue, value: newValue };
				setItems([...items, newItem]);
				onChange(newItem);
			},
			onChange: (values, value) => {
				if (value.organisation) {
					values.organisationId = value.value;
					values.userOrganisation = '';
				} else {
					values.organisationId = 0;
					values.userOrganisation = value.value;
				}
			},
		}
	]
};

const ResearchLogiqRegistration = props => {
	if (props.process === "AdminOnly") return <Redirect to="/login" />;

	const { fields, values, formFiles } = props;

	const registrationInitialText = props.registrationInitialText || "We invite you to create an account for Research 2 U.";

	return (
		<div className="registration-box">
			<span className="h3">Register</span>
			<small className="quill-display-block" dangerouslySetInnerHTML={{__html: registrationInitialText}}></small>
			<Form 
				onSubmit={() => {
					if (props.validateFields()) {
						const registerArgs = { ...values };
						props.register(registerArgs, formFiles.avatar, props.tenantId);
					} 
				}}
				style={{ textAlign: "center" }}
			>
				{fields.username()}
				{fields.firstName()}
				{fields.lastName()}
				{fields.email()}
				{fields.password()}
				{fields.cpassword()}
				{fields.userType()}
				{values.userType === "University User" && fields.universityUserOrg()}
				{values.userType !== "University User" && fields.organisationalUserOrg()}
				{/* Submit Button */}
				<p>If you are an Industry SME then contact for access</p>
				<Button type="submit" size="large" loading={props.loading} color="secondary">Register</Button>
			</Form>
			<div style={{ marginTop: 10, textAlign: "center" }}>
				Already have an account? <ButtonLink to="/login" variant="text" size="small" color="primary">Sign in</ButtonLink>
			</div>
			{/* Terms and Conditions Modal */}
			<TermsDialog
				open={Boolean(values._termsOpen)}
				onClose={() => props.updateValues({ _termsOpen: false })}
				showAgreeButton
				onAgree={() => props.updateValues({ _termsOpen: false, agreetc: true })}
				onDisagree={() => props.updateValues({ _termsOpen: false, agreetc: false })}
			/>
		</div>
	);
};

ResearchLogiqRegistration.propTypes = {
	loading: PropTypes.bool.isRequired,
	register: PropTypes.func.isRequired,
	subdomainId: PropTypes.number,
	tenantId: PropTypes.number,
	process: PropTypes.string,
	organisationId: PropTypes.number,
	organisationType: PropTypes.string,
	showMembershipLevelId: PropTypes.bool,
	bootstrapLoading: PropTypes.bool.isRequired,
	registrationInitialText: PropTypes.string,
	registerUserOrganisation: PropTypes.bool,
	isAPSC: PropTypes.bool
};

ResearchLogiqRegistration.defaultProps = {
	subdomainId: 0,
	tenantId: 0,
	process: "",
	organisationId: null,
	organisationType: "",
	showMembershipLevelId: false,
	registrationInitialText: null,
	registerUserOrganisation: false,
	isAPSC: false
};

const mapStateToProps = (state, ownProps) => ({
	loading: isLoading(state),
	subdomainId: state.context.subdomainId,
	tenantId: state.context.registrationTenantId,
	process: state.context.registrationProcess,
	organisationId: state.context.registrationOrganisationId,
	organisationType: state.context.organisationType,
	showMembershipLevelId: state.context.showMembershipLevelId,
	bootstrapLoading: state.bootstrap.loading,
	registrationInitialText: state.settings.registrationInitialText,
	registerUserOrganisation: state.context.registerUserOrganisation,
	queryParameters: getQueryParameters(ownProps.location.search),
	isAPSC: state.context.organisationId === state.settings.apsc_organisationId
});

const mapDispatchToProps = dispatch => ({
	register: (values, avatar, tenantId) => dispatch(registerResearchLogiq(values, avatar, tenantId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ResearchLogiqRegistration));