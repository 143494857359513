import React from 'react';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
	list: {
		listStylePosition: 'inside',
		padding: 0,
		margin: 0
	},
	tickedList: {
		listStyleType: 'none',
		padding: 0,
		margin: 0,
		'& li': {
			'&:before': {
				content: '\'✓\'',
				marginRight: '5px'
			}
		}
	}
});

const UpgradeCollaborationController = props => {
	const classes = props.classes;

	return (
		<PageLayout 
			title="Upgrade to Access" 
			pageContent={
				<div style={{
					maxWidth: '1000px',
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto'
				}}>
					<p style={{ }}>
						<span style={{ fontSize: 'large', marginRight: '10px', fontWeight: 'bold' }}>Collaboration</span>
					</p>
					<p style={{ textAlign: 'left' }}>
						Too often, small and micro-sized L&amp;D providers are inadequately positioned to be able to effectively capture new market supply opportunities from large corporate buyers. This can arise from having any of:
						<ul className={classes.list} style={{ textAlign: 'center' }}>
							<li>Too narrow of a capability set</li>
							<li>Insufficient market experience</li>
							<li>Inadequate resourcing</li>
							<li>Lack of capacity</li>
						</ul>
					</p>
					<p style={{ textAlign: 'left' }}>
						As a consequence, corporate buyers may view sole engagement with your business as representing too great a commercial risk.
					</p>
					<p style={{ textAlign: 'left' }}>
						Our <b>Collaboration</b> feature allows L&amp;D providers to identify and form collaborations between suppliers around new and emerging supply opportunities from corporate buyers of L&amp;D services.
					</p>
					<p>
						You will be able to:
						<ul className={classes.tickedList}>
							<li>Form multiple collaboration through creation of Market Supply ‘Project’ (each with potentially different collaborators)</li>
							<li>Search other suppliers by capability as potential collaborators</li>
							<li>Invite other suppliers to collaborate on market supply opportunities</li>
						</ul>
					</p>
					<p>
						The Collaboration feature is part of the Opportunities application which will be made available to subscribers shortly.
					</p>
					<ButtonLink 
						to="/pricing" 
					>Upgrade</ButtonLink>
				</div>
			} 
			breadcrumbs={[
				"Upgrade"
			]} />
	);
};

UpgradeCollaborationController.propTypes = {
};

export default withStyles(UpgradeCollaborationController, styles, { withTheme: true });
