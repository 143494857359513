import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { changeLocation } from '../../store/location';
import { get, post } from '../../utils/ajax';
import { showModal } from '../../store/modal';
import { emptySaveResult } from '../../variables';
import PageLayout from '../PageLayout';
import EditTheme from './EditTheme';

const EditThemeController = props => {
	const [state, setState] = React.useState({
		isLoading: false,
		theme: { ...props.currentTheme },
		saveResult: emptySaveResult
	});

	React.useEffect(() => {
		axios.all([
			get({
				url: `/api/application-themes/${props.match.params.themeId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, theme: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	}, [props.match.params.themeId]);

	const onSave = (theme) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
	
		post({
			url: "/api/application-themes",
			data: theme,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToThemesPage();
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	const onDelete = theme => {
		props.promptDeleteTheme(theme, () => {
			post({
				url: `/api/themes/${theme.themeId}/delete`,
				onSuccess: data => {
					setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToThemesPage();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	}

	return (
		<PageLayout 
			title="Edit Theme" 
			pageContent={
				<EditTheme
					canEdit={true}
					theme={state.theme}
					onSave={onSave} 
					saveResult={state.saveResult}
					loading={state.isLoading} 
					onDelete={onDelete}
				/>
			} 
			breadcrumbs={[
				{ label: "Themes",  path: "/ui-themes" }, 
				"Edit Theme"
			]} 
		/>
	);
};

EditThemeController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	promptDeleteTheme: PropTypes.func.isRequired,
	goToThemesPage: PropTypes.func.isRequired,
	currentTheme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	currentTheme: state.theme
});

const mapDispatchToProps = dispatch => ({
	goToThemesPage: () => dispatch(changeLocation('/ui-themes')),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptDeleteTheme: (theme, onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this Theme?",
		onOk: () => onOk(theme)
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditThemeController);
