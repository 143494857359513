import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import MaterialUIButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { shadeColour } from '../../utils/utils';

const styles = (theme) => ({
	buttonSuccess: {
		backgroundColor: theme.palette.success.main,
		'&:hover': {
			backgroundColor: shadeColour(theme.palette.success.main, -0.2),
		},
		color: '#fff'
	},
	buttonWarning: {
		backgroundColor: theme.palette.warning.main,
		'&:hover': {
			backgroundColor: shadeColour(theme.palette.warning.main, -0.2),
		},
		color: '#fff'
	},
	buttonError: {
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: shadeColour(theme.palette.error.main, -0.2),
		},
		color: '#fff'
	},
	buttonProgress: {
		color: theme.palette.success.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
});
  
const Button = React.memo(props => {
	const { classes, className, disabled, loading, success, warning, error, ...propsToPassOn } = props,
		buttonClasses = classNames({
			[classes.buttonSuccess]: success,
			[classes.buttonWarning]: warning,
			[classes.buttonError]: error
		}, className);

	return (
		<MaterialUIButton 
			{...propsToPassOn}
			className={buttonClasses}
			disabled={disabled || loading}
		>
			{props.children}
			{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
		</MaterialUIButton>
	);
});

Button.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.bool,
	warning: PropTypes.bool,
	disabled: PropTypes.bool,
	variant: PropTypes.string,
	color: PropTypes.string
};

Button.defaultProps = {
	disabled: false,
	loading: false,
	success: false,
	error: false,
	warning: false,
	variant: "contained",
	color: "primary"
};

export default withStyles(Button, styles);