import TeamCompetencies from "./TeamCompetencies";
import TeamGapProfile from "./TeamGapProfile";
import TeamMemberList from "./TeamMemberList";
import TeamRiskProfile from "./TeamRiskProfile";
import TeamSkillsProfile from "./TeamSkillsProfile";
import TeamSkillsStrengthProfile from "./TeamSkillsStrengthProfile";

const widgets = [
	{
		type: "WEF",
		category: "Community",
		name: "WEF",
		fields: [ 
			{ name: "title" },
			{ name: "topicId", required: true } 
		],
		widget: null
	},
	{
		type: "TeamMemberList",
		category: "Teams",
		name: "Member List",
		fields: [
			{ name: "title", default: "Member List" }
		],
		widget: TeamMemberList,
		tileStyles: {
			padding: 0
		}
	},
	{
		type: "TeamSkillsStrengthProfile",
		category: "Teams",
		name: "Team Skills Strength Profile",
		fields: [
			{ name: "title", default: "Team Skills Strength Profile" }
		],
		widget: TeamSkillsStrengthProfile
	},
	{
		type: "TeamSkillsProfile",
		category: "Teams",
		name: "Team Skills Profile",
		fields: [
			{ name: "title", default: "Team Skills Profile" }
		],
		widget: TeamSkillsProfile
	},
	{
		type: "TeamSkillsGapProfile",
		category: "Teams",
		name: "Team Skills Gap Profile",
		fields: [
			{ name: "title", default: "Team Skills Gap Profile" }
		],
		widget: TeamGapProfile
	},
	{
		type: "TeamPerformanceRiskProfile",
		category: "Teams",
		name: "Team Performance Risk Profile",
		fields: [
			{ name: "title", default: "Team Performance Risk Profile" }
		],
		widget: TeamRiskProfile
	},
	{
		type: "TeamCompetencies",
		category: "Teams",
		name: "Team Skills Strength Profile",
		fields: [
			{ name: "title", default: "Team Skills Strength Profile" }
		],
		widget: TeamCompetencies
	}
];

export default widgets;
