import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewActivity from './ViewActivity';
import { getActivity, isLoading } from '../../store/activities';
import { canEditActivity } from '../../store/security';

const ViewActivityController = props => {
	React.useEffect(() => props.getActivity(props.match.params.activityId), [props.match.params.activityId]);

	return <PageLayout title="View activity" pageContent={
		<ViewActivity 
			activity={props.activity}
			loading={props.loading}
			canEdit={props.canEdit}
			isAdmin={props.isAdmin}
		/>
	} breadcrumbs={[
		{ label: "Activities", path: "/activities" },
		"View activity"
	]} />;
};

ViewActivityController.propTypes = {
	activity: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	activity: state.activities.activity,
	loading: isLoading(state),
	canEdit: canEditActivity(state),
	isAdmin: state.context.isAdministrator
});

const mapDispatchToProps = dispatch => ({
	getActivity: activityId => dispatch(getActivity(activityId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewActivityController);
