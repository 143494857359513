import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCentre, saveCentre, deleteCentre, isLoading } from '../../store/centres';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditCentre from './EditCentre';

const EditCentreController = props => {
	React.useEffect(() => props.getCentre(props.match.params.centreId), [props.match.params.centreId]);

	return <PageLayout title="Edit research centre" pageContent={
		<EditCentre
			centre={props.centre}
			saveCentre={props.saveCentre}
			deleteCentre={props.deleteCentre}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Research centres", path: "/research-centres" }, "Edit research centre"]} />;
};

EditCentreController.propTypes = {
	centre: PropTypes.object.isRequired,
	saveCentre: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.centres.saveResult,
	centre: state.centres.centre,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getCentre: centreId => dispatch(getCentre(centreId)),
	saveCentre: (centre, imageFile) => dispatch(saveCentre(centre, imageFile)),
	deleteCentre: centreId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this centre?",
		onOk: () => dispatch(deleteCentre(centreId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCentreController);
