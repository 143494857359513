import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CreatableSelect  from 'react-select/creatable';
import Select  from 'react-select';
import { 
	Chip, 
	FormControl, 
	FormHelperText, 
	Input, 
	InputLabel, 
	MenuItem, 
	Paper, 
	Typography,
	InputAdornment
} from '@mui/material';
import { emphasize } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { default as _filter } from 'lodash/filter';

const styles = theme => ({
	input: {
		display: "flex",
		padding: 0
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center"
	},
	chip: {
		margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08
		)
	},
	noOptionsMessage: {
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`
	},
	singleValue: {
		fontSize: 16,
		fontWeight: 300
	},
	placeholder: {
		position: "absolute",
		top: 5,
		left: 2,
		right: 40,
		fontSize: 16,
		fontWeight: 300,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "clip"
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	divider: {
		height: theme.spacing(2)
	},
	underline: {
		"&::before": {
			borderBottom: "1px solid #d9d9d9 !important"
		}
	}
});

const NoOptionsMessage = props => 
	<Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
		{props.children}
	</Typography>;

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = props => {
	const { textFieldProps, classes } = props.selectProps;

	return (
		<FormControl 
			fullWidth 
			error={textFieldProps.error} 
			style={textFieldProps.style} 
			className={classes.formControl}
			variant="standard"
		>
			{textFieldProps.label &&
				<InputLabel {...textFieldProps.InputLabelProps}>
					{textFieldProps.label}
					{textFieldProps.required && <span style={{ color: props.errorColour }}> *</span>}
				</InputLabel>
			}
			<Input 
				inputComponent={inputComponent} 
				classes={{ underline: classes.underline }} 
				{...textFieldProps.InputProps} 
				inputProps={{
					className: classes.input,
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps
				}} 
			/>
			{textFieldProps.helpText && <FormHelperText>{textFieldProps.helpText}</FormHelperText>}
		</FormControl>
	);
}

const Option = props =>
	<MenuItem
		buttonRef={props.innerRef}
		selected={props.isFocused}
		component="div"
        disabled={props.data.isDisabled}
		style={{ fontWeight: props.isSelected ? 500 : 400 }}
		{...props.innerProps}
	>
		{props.children}
	</MenuItem>;

const Placeholder = props => 
	<Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
		{props.children}
	</Typography>;

const SingleValue = props => 
	<Typography className={props.selectProps.classes.singleValue} style={{ color: props.isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial" }} {...props.innerProps}>
		{props.children}
	</Typography>;

const ValueContainer = props => <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;

const MultiValue = props =>
	<Chip
		tabIndex={-1}
		label={props.children}
		className={classNames(props.selectProps.classes.chip, {
			[props.selectProps.classes.chipFocused]: props.isFocused,
		})}
		onDelete={props.removeProps.onClick}
		deleteIcon={<ClearIcon style={{ height: "15px", color: "#fff" }} onMouseDown={e => e.stopPropagation()} />}
		style={{ backgroundColor: props.selectProps.chipColour, ...props.selectProps.chipStyle }}
	/>;

const Menu = props => 
	<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
		{props.children}
	</Paper>;

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer
};

const AutoComplete = props => {
	const [itemsLoading, setItemsLoading] = React.useState(true);
	const [items, setItems] = React.useState([]);
	const ref = React.useRef(null);

	const { value, placeholder, defaultItem, isMulti, isSingleMulti } = props,
		hasValue = isMulti ? !isEmpty(value) : Boolean(value);

	React.useEffect(() => {
		if (props.loadItems.route && props.loadItems.mapItem && props.loadItems.waitFor !== false) {
			const { route, mapItem, filter, onSuccess } = props.loadItems;

			setItemsLoading(true);
			
			get({
				url: `/api/${route}`,
				ignoreSessionTimeout: true,
				onSuccess: newItems => {
					// Return if the component has failed to mount or has unmounted
					if (!ref.current) return;
					
					const filtered = filter ? _filter(newItems, filter) : newItems;
					const mapped = map(filtered, isString(mapItem) ? item => ({ label: item[mapItem], value: item[mapItem] }) : mapItem);
					setItems(defaultItem ? [defaultItem, ...mapped] : mapped);
					setItemsLoading(false);

					if (onSuccess) onSuccess(mapped);
				},
				onError: error => {
					console.error("Error fetching items:", error);
					setItemsLoading(false);
				}
			});
		} else {
			setItems(map(defaultItem ? [defaultItem, ...props.items] : props.items, item => isString(item) ? { label: item, value: item } : item));
			setItemsLoading(false);
		}
	}, [props.items, props.loadItems.route, props.loadItems.waitFor]);

	const findValue = v => {
		if (v === null) return v;
		return v.label && v.value ? v : items.find(i => i.value === (v.value || v))
	};

	// Memoize value to save calculations
	const memoValue = React.useMemo(() => {
		if (itemsLoading) {
			return isMulti ? [] : 0;
		} else {
			return map(isMulti ? value : [value], props.mapValue || findValue);
		}
	}, [value, items.length, itemsLoading]);

	// Handle onchange event, add latest value to items if new (created)
	const onChange = updated => {
		const latest = isMulti ? updated[updated.length - 1] : updated;
		if (isMulti && isSingleMulti && latest) {
			if (latest && latest.__isNew__) setItems([{ ...latest }]);
			props.onChange([{ ...latest }] || "");
		} else {
			if (latest && latest.__isNew__) setItems([...items, { ...latest }]);

			// Use default value if null
			props.onChange(updated || "");
		}
	}

	return React.createElement(props.canCreate ? CreatableSelect : Select, {
		ref,
		className: "dih-auto-complete",
		classes: props.classes,
		textFieldProps: {
			label: props.label,
			required: props.required,
			helpText: props.helpText,
			error: props.error,
			style: props.style,
			InputProps: {
				id: props.id,
				startAdornment: props.startAdornment && <InputAdornment position="start">{props.startAdornment}</InputAdornment>
			},
			InputLabelProps: { 
				shrink: Boolean(defaultItem || placeholder) || hasValue || undefined, 
				style: props.labelStyle,
				disabled: props.disabled
			}
		},
		"aria-label": props.label || placeholder,
		options: items,
		components,
		value: hasValue ? memoValue : defaultItem,
		onChange,
		placeholder,
		isMulti,
		chipColour: props.chipColour === null ? props.tagColour : props.chipColour,
		chipStyle: props.chipStyle,
		noOptionsMessage: props.noOptionsMessage,
		isDisabled: props.disabled,
		closeMenuOnSelect: props.closeMenuOnSelect,
		styles: {
			dropdownIndicator: base => ({ ...base, padding: "6px 8px" }),
			input: base => ({ ...base, color: "#555" }),
			menuPortal: base => ({ ...base, zIndex: 9999 })
		},
		isClearable: !(props.required || defaultItem) && props.clearable,
		formatCreateLabel: props.formatCreateLabel,
		onCreateOption: !props.onCreateOption ? undefined : newValue => {
			props.onCreateOption(newValue, items, setItems, props.onChange);
		},
		menuPortalTarget: document.body,
		openMenuOnClick: props.openMenuOnClick,
		filterOption: props.filterOption
	});
}

AutoComplete.propTypes = {
	id: PropTypes.string,
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	items: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			label: PropTypes.node,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		})
	])),
	label: PropTypes.string,
	placeholder: PropTypes.string,
	isMulti: PropTypes.bool,
	isSingleMulti: PropTypes.bool,
	canCreate: PropTypes.bool,
	openMenuOnClick: PropTypes.bool,
	filterOption: PropTypes.func,
	required: PropTypes.bool,
	helpText: PropTypes.node,
	error: PropTypes.bool,
	loadItems: PropTypes.shape({
		route: PropTypes.string,
		mapItem: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
		filter: PropTypes.func
	}),
	defaultItem: PropTypes.shape({
		label: PropTypes.node,
		value: PropTypes.string
	}),
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string, PropTypes.array]),
	chipColour: PropTypes.string,
	chipStyle: PropTypes.object,
	labelStyle: PropTypes.object,
	noOptionsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	clearable: PropTypes.bool,
	closeMenuOnSelect: PropTypes.bool,
	startAdornment: PropTypes.node,
	mapValue: PropTypes.func,
	formatCreateLabel: PropTypes.func,
	onCreateOption: PropTypes.func,
	errorColour: PropTypes.string.isRequired,
	tagColour: PropTypes.string.isRequired
};

AutoComplete.defaultProps = {
	id: undefined,
	items: [],
	label: "",
	placeholder: "",
	isMulti: false,
	isSingleMulti: false,
	canCreate: false,
	openMenuOnClick: undefined,
	required: false,
	helpText: "",
	error: false,
	loadItems: {},
	defaultItem: null,
	value: "",
	chipColour: null,
	chipStyle: { color: "white", height: 20 },
	labelStyle: undefined,
	noOptionsMessage: () => "No options",
	className: "",
	disabled: false,
	clearable: true,
	closeMenuOnSelect: true,
	startAdornment: "",
	mapValue: null,
	formatCreateLabel: undefined,
	onCreateOption: undefined,
	filterOption: undefined
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour,
	tagColour: state.theme.tagColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(AutoComplete, styles, { withTheme: true }));
