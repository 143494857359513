import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveCentre, isLoading } from '../../store/centres';
import PageLayout from '../PageLayout';
import EditCentre from './EditCentre';

const CreateCentreController = props => 
	<PageLayout title="Create research centre" pageContent={
		<EditCentre isNew saveCentre={props.saveCentre} loading={props.loading} />
	} breadcrumbs={[{ label: "Research centres", path: "/research-centres" }, "Create research centre"]} />;

CreateCentreController.propTypes = {
	saveCentre: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveCentre: (centre, avatarFile) => dispatch(saveCentre(centre, avatarFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCentreController);
