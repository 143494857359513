import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '../widgets/Card';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import StatBox from '../widgets/StatBox';
import reduce from 'lodash/reduce';
import capitalise from 'lodash/capitalize';

class UserProblems extends React.Component {
	render() {
		const { problems, user, loading, isOwn, plural, primaryColour } = this.props,
			hasProblems = problems && problems.length > 0;
		
		const labelLookup = {
			approved: "success",
			cancelled: "danger",
			new: "primary",
			pending: "info"
		}

		return (
			<Card 
				title={isOwn ? "Submissions" : capitalise(plural)}
				subtitle={`${capitalise(plural)} submitted by ${isOwn ? "you" : user.userName || ""}`}
				loading={loading}
			>
				{!loading && (hasProblems ? 
					<React.Fragment>
						<div className="row-flex m-t-40">
							<StatBox
								text={capitalise(plural)}
								number={problems.length}
								colour="#007C9A"
							/>
							<StatBox
								text="Total votes"
								number={reduce(problems, (sum, p) => sum + p.votes, 0)}
								colour="#7460ee"
							/>
							<StatBox
								text="Conversations"
								number={reduce(problems, (sum, p) => sum + Boolean(p.comments), 0)}
								colour="#27a9e1"
							/>
							<StatBox 
								text="Views"
								number={reduce(problems, (sum, p) => sum + p.views, 0)}
							/>
						</div>
						<DataTable data={problems}>
							<DataTableColumn name="title" label="Title" width={600} renderer={p => 
								<Link to={`/problems/${p.problemId}`} style={{ color: primaryColour }}>{p.title}</Link>
							} />
							<DataTableColumn name="votes" label="Votes" width={120} />
							<DataTableColumn name="comments" label="Comments" width={120} />
							<DataTableColumn name="status" label="Status" width={150} renderer={p =>
								<span className={`label label-light-${labelLookup[p.status]}`}>{p.status}</span>
							} />
							<DataTableColumn name="updateDate" label="Updated" dataType="date" />
						</DataTable>
					</React.Fragment> :
					<div style={{ marginBottom: 30 }}>{isOwn ? "You have" : "This user has"} not submitted any {plural}...</div>
				)}
			</Card>
		);
	}
};

UserProblems.propTypes = {
	problems: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	user: PropTypes.object,
	isOwn: PropTypes.bool,
	plural: PropTypes.string.isRequired,
	primaryColour: PropTypes.string.isRequired
};

UserProblems.defaultProps = {
	user: {},
	isOwn: false
};

export default UserProblems;
