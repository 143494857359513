import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../../PageLayout';
import ReportContainer from '../ReportContainer';
import DevelopmentNeeds from '../../dashboardWidgets/DevelopmentNeeds';
import { CircularProgress, Tooltip } from '@mui/material';
import { get } from '../../../utils/ajax';
import map from 'lodash/map';
import CheckIcon from '@mui/icons-material/Check';
import { prettifyDate } from '../../../utils/utils';

const IndividualSkillsReport = (props) => {
	const [isDevelopmentNeedsLoading, setDevelopmentNeedsLoading] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [result, setResult] = React.useState({});
	const successColour = props.successColour;
	
	const loadData = () => {
		setIsLoading(true);
		setResult({});
		get({
			url: `/api/reports/sfia-individual-skills-profile?userId=${props.userId}`,
			onSuccess: data => {
				setIsLoading(false);
				setResult(data);
			},
			onError: () => {
				setIsLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (props.userId) {
			loadData();
		}
	}, [props.userId]);

	let user = {};
	if (result.success) {
		user = result.data.user[0];
	}
	const categories = result.data ? result.data.categories : [];
	const professionalSkillsProfile = result.data ? result.data.professionalSkillsProfile : [];
	const personalSkills = result.data ? result.data.personalSkills : [];

	const report = (
		<ReportContainer 
			isReportLayout={props.isReportLayout} 
			orientation={props.orientation}
		>
			<h1 className="justify-content-center">Personal Skills Profile Report</h1>
			
			<table className="report-table">
				<tbody>
					<tr>
						<td>Name</td>
						<td>{user.fullName}</td>
					</tr>
					<tr>
						<td>Organisation</td>
						<td>{user.organisationName}</td>
					</tr>
					<tr>
						<td>Date of creation</td>
						<td>{user.dateReportGenerated ? prettifyDate(user.dateReportGenerated) : ''}</td>
					</tr>
					<tr>
						<td>APS role selected</td>
						<td>No</td>
					</tr>
				</tbody>
			</table>
			<p>This is your Personal Skills Profile Report.</p>
			<p>This assessment is based on the defined competencies according the Skills for the Information Age (SFIA) v.7. SFIA has been developed by the SFIA Foundation (www.sfia-online.org) and is used by employers of digital, IT and software engineering organisations to better manage internal workforces and align their workforce to their organisation’s strategic business objectives. Your Personal Skills Profile can also include non-SFIA defined skills that you also possess that you feel is of value to your job and career aspirations.</p>
			<p>Two types of assessment are currently available. The first is a General Profile which outlines your skills profile against SFIA-defined competencies. The other is a Role  Profile which is your SFIA-defined competencies profiled in accordance to your employer’s baseline Role profiles for each defined role in the organisation.</p>
			<p>[coming soon: Depending on your membership status, you also have the option of general industry-defined roles based on common advertised digital and IT roles across the Australian recruitment landscape together with market intelligence with respect to role demand and average salaries.]</p>
			<p>Currently, the career pathway model for the Australian Public Sector (APS) is available covering the defined role profiles common across the APS. Additionally, if your employer organisation has uploaded their own workforce model for their defined roles, they will have been automatically made available to you.</p>

			<h2>SFIA Profile Levels:</h2>
			<p>Your SFIA assessment allows you identify and self-assess skills you possess against your level of capability. SFIA attainment scoring for both the level of responsibility AND professional skills is defined from level 1 through 7 as summarised below.</p>

			<table className="report-table centered">
				<thead>
					<tr>
						<th>SFIA Capability</th>
						<th>Level</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>set strategy, inspire, mobilise</td>
						<td>7</td>
					</tr>
					<tr>
						<td>initiate/influence</td>
						<td>6</td>
					</tr>
					<tr>
						<td>ensure/advise</td>
						<td>5</td>
					</tr>
					<tr>
						<td>enable</td>
						<td>4</td>
					</tr>
					<tr>
						<td>apply</td>
						<td>3</td>
					</tr>
					<tr>
						<td>assist</td>
						<td>2</td>
					</tr>
					<tr>
						<td>follow</td>
						<td>1</td>
					</tr>
				</tbody>
			</table>

			<h2>Profile Assessment:</h2>
			<p>SFIA 7 consists of 102 professional skills, each described at one or more of the 7 levels of responsibility.  To aid navigation, SFIA structures the skills into 6 categories, each with a number of sub-categories.</p>
			<p>Any non-SFIA skills you included are summarised at the end of the Detailed report. </p>

			<h2 style={{ pageBreakBefore: 'always' }}>Your Summary Profile:</h2>
			<p>Your summary profile across the 6 SFIA categories is summarised below:</p>

			<div
				style={{
					width:' 100%',
					height: '600px'
				}}
			>
				{isDevelopmentNeedsLoading &&
					<div style={{
							height: "100%",
							marginTop: "-10px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}>
						<CircularProgress />
					</div>
				}
				<DevelopmentNeeds userId={props.userId} setLoading={(isLoading) => { setDevelopmentNeedsLoading(isLoading) }} />
			</div>

			<table className="report-table">
				<thead>
					<tr>
						<th>SFIA Category Description</th>
						<th>Description</th>
						<th>No. of Competencies assessed</th>
					</tr>
				</thead>
				<tbody>
					{
						map(categories || [], (c, i) => 
							<tr key={`cat-${i}`}>
								<td>{c.specialisationGroup}</td>
								<td>{c.description}</td>
								<td>{c.numCompetencies}</td>
							</tr>
						)
					}
				</tbody>
			</table>
		
			<h2>Detailed SFIA Profile:</h2>
			<h3>I.	Professional Skill Profile:</h3>
			<table className="report-table">
				<thead>
					<tr>
						<th rowSpan={2} style={{ verticalAlign: 'top' }}>Category</th>
						<th rowSpan={2} style={{ verticalAlign: 'top' }}>Sub-Category</th>
						<th rowSpan={2} style={{ verticalAlign: 'top' }}>Code</th>
						<th colSpan={7}>Level</th>
					</tr>
					<tr>
						<th style={{ width: '35px' }}>1</th>
						<th style={{ width: '35px' }}>2</th>
						<th style={{ width: '35px' }}>3</th>
						<th style={{ width: '35px' }}>4</th>
						<th style={{ width: '35px' }}>5</th>
						<th style={{ width: '35px' }}>6</th>
						<th style={{ width: '35px' }}>7</th>
					</tr>
				</thead>
				<tbody>
					{
						map(professionalSkillsProfile, (p, i) =>
							<Tooltip 
								key={`psp-tt-${i}`}
								title={
									<React.Fragment>
										<p>{p.unitTitle}</p>
										<p>{p.unitDescription}</p>
										<p>Level Assessed: {p.level}</p>
										<p>{p.levelName}</p>
									</React.Fragment>
								} 
								disableFocusListener
								arrow
							>
							<tr key={`psp-${i}`}>
								<td>{p.specialisationGroup}</td>
								<td>{p.specialisation}</td>
								<td>{p.unitCode}</td>
								<td>{p.level === 1 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
								<td>{p.level === 2 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
								<td>{p.level === 3 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
								<td>{p.level === 4 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
								<td>{p.level === 5 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
								<td>{p.level === 6 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
								<td>{p.level === 7 ? <CheckIcon style={{ color: successColour, verticalAlign: "middle" }} /> : undefined}</td>
							</tr>	
							</Tooltip>
						)
					}
				</tbody>
			</table>
 
			<h3>II.	Personal Skills Attributes (if any)</h3>
			<table className="report-table">
				<thead>
					<tr>
						<th>Skill</th>
						<th>Code</th>
						<th>Attributes</th>
					</tr>
				</thead>
				<tbody>
					{
						map(personalSkills, (p, i) =>
							<tr key={`ps-${i}`}>
								<td></td>
								<td>{p.unitCode}</td>
								<td>{p.levelName}</td>
							</tr>	
						)
					}
				</tbody>
			</table>

			<h2>Skills Profile Descriptions:</h2>
			<p>From your self-assessment summarised above, below are the detailed description of each defined SFIA skill assessed.</p>

			{
				map(professionalSkillsProfile, (p, i) => 
					<React.Fragment key={`psp-${i}`}>
					<p style={{ fontStyle: 'italic' }}>
						Category:  {p.unitTitle}
					</p>
					<p style={{ fontStyle: 'italic' }}>
						Overall description: {p.unitDescription}
					</p>
					<table className="report-table">
						<thead>
							<tr>
								<th>Level Assessed</th>
								<th>Level Description</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{p.level}</td>
								<td>{p.levelName}</td>
							</tr>
						</tbody>
					</table>
					</React.Fragment>
				)
			}
		</ReportContainer>
	);

	if (props.isReportLayout) return report;
	else return (
		<PageLayout 
			title="Individual Skills Report" 
			pageContent={report}
			loading={isLoading}
			breadcrumbs={["Individual Skills Report"]}
		/>
	);

};

IndividualSkillsReport.propTypes = {
	userId: PropTypes.string.isRequired,
	isReportLayout: PropTypes.bool,
	successColour: PropTypes.string.isRequired
};

IndividualSkillsReport.defaultProps = {
	isReportLayout: false
};

const mapStateToProps = state => ({
	userId: state.context.userId,
	successColour: state.theme.successColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualSkillsReport);
