import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
// import PrintIcon from '@mui/icons-material/Print';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import VideoPlayer from '../widgets/VideoPlayer';
import isEmpty from 'lodash/isEmpty';
import Button from '../widgets/Button';
import Tag from '../widgets/Tag';
import { prettifyDate } from '../../utils/utils';

const ViewCourse = props => {
	const { course } = props;
	const numCompetencies = course.competencies ? course.competencies.length : 0;
	
	return props.loading || isEmpty(course) ? <CircularProgress size={24} /> : (
		<Card className="course-details">
			<div className="header">
				<div>
					<h2>{course.title}</h2>
					{course.type && <Tag label={course.type} style={{ marginRight: 5 }} />}
					{course.mode && <Tag label={course.mode} />}
				</div>
				{/* <Button onClick={window.print}>
					<PrintIcon style={{ marginRight: 5}} />Print course
				</Button> */}
			</div>
			{course.website &&
				<section className="course-link">
					<span className="h5 print-show">{course.website}</span>
					<a href={course.website} target="_blank" rel="noopener noreferrer" className="h5 print-hide" style={{ fontWeight: "bold" }}>
						<ExitToAppIcon />
						View Course Website
					</a>
				</section>
			}
			{course.videoUrl &&
				<VideoPlayer url={course.videoUrl} />
			}
			<section>
				<span className="h5">Description</span>
				{!course.description ? "No description provided." : 
					<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: course.description }} />
				}
				<br />
				{course.commencementDate && 
					<div>
						Commences {prettifyDate(course.commencementDate)}
						{course.expiryDate && `, Expires ${prettifyDate(course.expiryDate)}`}
					</div>
				}
			</section>
			{(numCompetencies > 0 || course.channels.length > 0) && 
				<section>
					{numCompetencies > 0 &&
						<Tag label={`${numCompetencies} Competenc${numCompetencies > 1 ? "ies" : "y"}`} colour={props.primaryColour} />
					}
					{course.channels.map(c => <Tag key={c.channelId} label={c.name} />)}
				</section>
			}
			{course.contactName &&
				<div style={{ marginTop: 10 }}>
					<strong>{course.contactName}</strong><br />
					<span>{course.contactEmail}</span>
				</div>
			}
			{course.attachments && course.attachments.length > 0 &&
				<section>
					<span className="h4">Course Brochure(s)</span>
					<ul className="list-unstyled">
						{course.attachments.map((attachment, i) =>
							<li key={i} className="media">
								<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
								<div className="media-body">
									<h5 className="mt-0 mb-1">{attachment.name}</h5>
									<p>{attachment.description}</p>
									<Button 
										variant="outlined" 
										onClick={() => window.open(`/Course/DownloadAttachment?courseId=${course.courseId}&attachmentId=${attachment.attachmentId}`, "_self")}
									>
										Download Now
									</Button>
								</div>
							</li>
						)}
					</ul>
				</section>
			}
			{props.canEdit && 
				<ButtonLink to={`${props.fromAdmin ? "/admin" : ""}/courses/edit/${course.courseId}`} style={{ marginTop: 20 }}>
					Edit course
				</ButtonLink>
			}
		</Card>
	);
};

ViewCourse.propTypes = {
	course: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	fromAdmin: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired
};

ViewCourse.defaultProps = {
	canEdit: false,
	fromAdmin: false
};

export default ViewCourse;