import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Button from '../widgets/Button';
import Accordion from '../widgets/Accordion';
import Artifact from './Artifact';
import Agreement from './Agreement';
import ThirdParty from './ThirdParty';
import map from 'lodash/map';
import pullAt from 'lodash/pullAt';

const requiredText = "This field is required";

const artifactsList = ["Innovation", "Provisional Application", "Granted Patent", "Trademark",
	"Document", "Database", "Image", "Recording", "Software Code"];

const prototypes = {
	artifacts: {
		title: "",
		concerns: false,
		sharing: false,
		ownership: "",
		description: "",
		reference: "",
		authors: []
	},
	agreements: {
		party: "",
		contact: "",
		email: "",
		phone: ""
	},
	thirdParty: {
		party: "",
		contact: "",
		email: "",
		phone: ""
	}
};

const form = {
	initValues: props => ({ artifacts: [], agreements: [], thirdParty: [], ...props.ip }),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of IP"
		},
		{
			name: "projectName",
			label: "Project Name",
			helpText: "Enter the name of an associated or stand-alone project"
		},
		{
			name: "agency",
			label: "Agency",
			required: true,
			helpText: "Name of Agency"
		},
		{
			name: "businessUnit",
			label: "Business Unit",
			required: true,
			helpText: "Name of Business Unit"
		},
		{
			name: "projectContactPerson",
			label: "Project contact person",
			required: true,
			helpText: "Full name of contact person for project"
		},
		{
			name: "email",
			label: "Email",
			required: true,
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid address",
			helpText: "Contact email for inquiries",
		},
		{
			name: "commercialAvailable",
			label: "Available for commercialisation",
			type: "checkbox",
			helpText: "Is the IP available for commercialisation?"
		},
		{
			name: "commercialApproved",
			label: "Approved for commercialisation",
			type: "checkbox",
			helpText: "Is the IP approved for commercialisation?"
		},
		{
			name: "commercialApproverName",
			label: "Commercialisation Approver Name",
			required: (props, values) => Boolean(values.commercialApproved),
			helpText: "Name of commercialisation approver"
		},
		{
			name: "commercialApproverEmail",
			label: "Commercialisation Approver Email",
			required: (props, values) => Boolean(values.commercialApproved),
			helpText: "Email of commercialisation approver"
		},
		{
			name: "commercialApproverPhone",
			label: "Commercialisation Approver Phone",
			required: (props, values) => Boolean(values.commercialApproved),
			helpText: "Phone number of commercialisation approver"
		}
	]
};

const EditIP = props => {
	const [errors, setErrors] = React.useState({});

	const { ip, loading, fields, values, isNew, values: { artifacts = [] }, errorColour } = props;
	const authorsList = artifacts.reduce((acc, a) => a.authors ? [...acc, ...a.authors] : acc, []);

	const addItem = (list, itemProps) => {
		values[list].push({ ...prototypes[list], ...itemProps });
		props.updateValues(values);
	}

	const updateItem = (list, index, field, value) => {
		values[list][index][field] = value;
		props.updateValues(values);
	}

	const removeItem = (list, index) => {
		pullAt(values[list], index);
		props.updateValues(values);
	}

	const onSubmit = () => {
		let error = false;

		// Check Artifacts
		values.artifacts.forEach(a => {
			if ((!a.title || !a.ownership) && !errors[a.type]) {
				errors[a.type] = true;
				error = true;
			}
		});

		// Check Agreements/ Third Party IP
		["agreements", "thirdParty"].forEach(k =>
			values[k].forEach(a => {
				if (!a.party || !a.contact) error = true;
			})
		)

		setErrors(errors);

		if (!error && props.validateFields()) props.onSave(values);
	}

	return (
		<Card title={`${isNew ? "Register" : "Edit"} IP`} loading={loading} className="edit-ip">
			<Form onSubmit={onSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.title()}</Grid>
					<Grid item xs={12}>{fields.projectName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.agency()}</Grid>
					<Grid item xs={12} sm={6}>{fields.businessUnit()}</Grid>
					<Grid item xs={12} sm={6}>{fields.projectContactPerson()}</Grid>
					<Grid item xs={12} sm={6}>{fields.email()}</Grid>
					<Grid item xs="auto">{fields.commercialAvailable()}</Grid>
					<Grid item xs="auto">{fields.commercialApproved()}</Grid>
					{values.commercialApproved &&
						<React.Fragment>
							<Grid item xs={12}>{fields.commercialApproverName()}</Grid>
							<Grid item xs={12} sm={6}>{fields.commercialApproverEmail()}</Grid>
							<Grid item xs={12} sm={6}>{fields.commercialApproverPhone()}</Grid>
						</React.Fragment>
					}
					{/* Artifacts */}
					<Grid item xs={12}>
						<span className="card-title h3">Artifacts</span>
						{map(artifactsList, (k, listIndex) => {
							const list = artifacts.filter(a => a.type === k);

							return (
								<React.Fragment key={listIndex}>
									{/* {(listIndex === 0 || listIndex === 3 || listIndex === 4) && <span className="h4">{type}</span>} */}
									{listIndex === 0 && <span className="h4">Patent</span>}
									{listIndex === 3 && <span className="h4" style={{ marginTop: 20 }}>Trademark</span>}
									{listIndex === 4 && <span className="h4" style={{ marginTop: 20 }}>Copyright</span>}
									<Accordion key={k} summary={`${k} (${list.length})`} innerStyles={{ display: "block" }} error={errors[k]}>
										{map(list, (a, artifactIndex) =>
											<Artifact 
												key={artifactIndex}
												artifact={a} 
												type={listIndex < 3 ? "Patent" : listIndex === 3 ? "Trademark" : "Copyright"}
												update={(field, value) => updateItem("artifacts", artifacts.indexOf(a), field, value)}
												remove={() => removeItem("artifacts", artifacts.indexOf(a))}
												authorsList={authorsList}
												requiredText={requiredText}
												errorColour={errorColour}
											/>
										)}
										<Button variant="outlined" onClick={() => addItem("artifacts", { type: k })}>Add {k}</Button>
									</Accordion>
								</React.Fragment>
							)
						})}
					</Grid>
					{/* Confidentiality Agreements */}
					<Grid item xs={12}>
						<span className="card-title h3">Confidentiality Agreements ({values.agreements && values.agreements.length})</span>
						{map(values.agreements, (a, index) =>
							<Agreement
								key={index}
								agreement={a} 
								update={(field, value) => updateItem("agreements", index, field, value)}
								remove={() => removeItem("agreements", index)}
								requiredText={requiredText}
								errorColour={errorColour}
							/>
						)}
						<Button variant="outlined" onClick={() => addItem("agreements")}>Add Agreement</Button>
					</Grid>
					{/* Third Party IP */}
					<Grid item xs={12}>
						<span className="card-title h3">Third Party IP ({values.thirdParty && values.thirdParty.length})</span>
						{map(values.thirdParty, (t, index) =>
							<ThirdParty
								key={index}
								thirdParty={t}
								update={(field, value) => updateItem("thirdParty", index, field, value)}
								remove={() => removeItem("thirdParty", index)}
								requiredText={requiredText}
								errorColour={errorColour}
							/>
						)}
						<Button variant="outlined" onClick={() => addItem("thirdParty")}>Add IP</Button>
					</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Register" : "Update"} IP</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.onDelete(ip.ipId)}>
							Delete IP
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditIP.propTypes = {
	ip: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	isNew: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

EditIP.defaultProps = {
	ip: {},
	onDelete: () => null,
	isNew: false
};

export default withForm(form)(EditIP);