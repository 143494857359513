import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, FormHelperText, InputLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import DragItem from './DragItem';

const DragList = props => {
	const [id] = React.useState(props.id || uniqueId("drag-list-"));
	const [canDrag, setCanDrag] = React.useState(false);
	const numItems = props.children.length;

	return (
        <div id={id} className="drag-list" style={props.style}>
			<InputLabel error={props.error}>
				{props.label}{props.required && <span style={{ color: props.errorColour }}> *</span>}
				{(props.simple || numItems === 0) && props.showAdd &&
					<IconButton onClick={() => props.addItem(0)} style={{ marginLeft: 5 }} size="large"><AddCircleIcon /></IconButton>
				}
			</InputLabel>
			{numItems > 0 &&
				<table className={props.tableClassName}>
					{props.header}
					<tbody>
						{map(props.children, (child, index) => 
							<DragItem 
								key={index}
								index={index} 
								numItems={numItems}
								addItem={props.addItem}
								removeItem={props.removeItem}
								moveItem={props.moveItem}
								enableDrag={() => setCanDrag(true)}
								disableDrag={() => setCanDrag(false)}
								canDrag={canDrag}
								hideArrows={props.hideArrows}
								simple={props.simple}
								cellStyle={props.cellStyle}
								showBorder={props.showBorder && index !== numItems - 1}
								dragOnly={props.dragOnly}
								renderAsCells={props.renderAsCells}
								showAdd={props.showAdd}
							>
								{child}
							</DragItem>
						)}
					</tbody>
				</table>
			}
			{props.helpText && <FormHelperText error={props.error}>{props.helpText}</FormHelperText>}
		</div>
    );
};

DragList.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	addItem: PropTypes.func.isRequired,
	removeItem: PropTypes.func.isRequired,
	moveItem: PropTypes.func.isRequired,
	error: PropTypes.bool,
	style: PropTypes.object,
	cellStyle: PropTypes.object,
	helpText: PropTypes.node,
	hideArrows: PropTypes.bool,
	simple: PropTypes.bool,
	showBorder: PropTypes.bool,
	dragOnly: PropTypes.bool,
	header: PropTypes.object,
	tableClassName: PropTypes.string,
	showAdd: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

DragList.defaultProps = {
	id: "",
	required: false,
	error: false,
	style: {},
	cellStyle: {},
	helpText: "",
	hideArrows: false,
	simple: false,
	showBorder: false,
	dragOnly: false,
	header: <thead></thead>,
	tableClassName: "",
	showAdd: true
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

// eslint-disable-next-line new-cap
export default connect(mapStateToProps, mapDispatchToProps)(DragDropContext(HTML5Backend)(DragList));
