import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	contentCard: {
		'& .card-body': {
			'& .header': {
				'& span': {
					color: theme.palette.primary.main
				}
			}
		}
	}
});

const CourseCard = React.memo(props => {
	const { course: c, colourPalette } = props,
		organisation = (c.organisations || []).find(o => o.organisationId === props.organisationId) || {},
		price = organisation.price || c.price;

	const header = (
		<React.Fragment>
			<span className="h4">{c.title}</span>
			<span className="h5">{c.providerName}</span>
		</React.Fragment>
	);
	
	const tagColours = {};

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<Card className={`course-card ${props.classes.courseCard}`} ribbon={price ? `$${price}` : "P.O.A"} ribbonStatus={price ? "warning" : "info"}>
				<div className="score" style={{ textAlign: 'center' }}>
					<span className="score-label" style={{ 
						fontSize: '16px',
						fontWeight: 'bold',
						marginLeft: '-30px',
						marginRight: '20px'
					}}>RANK</span>
					<span className="score-value" style={{ fontSize: '50px' }}>{c.ranking}</span>
				</div>
				<div className="header">
					{(c.image || c.providerLogo) &&
						<img src={c.image || c.providerLogo} alt="Course" />
					}
					{c.website ? <a href={c.website} target="_blank" rel="noopener noreferrer">{header}</a> : <div>{header}</div>}
				</div>
				<div className="description quill-display-block" dangerouslySetInnerHTML={{ __html: c.description }} />
				<div className="footer">
				{
						(map(c.tags, (t, i) => {
							let tagColour = tagColours[t.category || 'NO_CATEGORY'];
							if (!tagColour) {
								tagColour = colourPalette[Object.keys(tagColours).length];
								tagColours[t.category || 'NO_CATEGORY'] = tagColour;
							}
							return (
								<Tag 
									key={`tag-${c.courseId}-${t.tagId}`}
									label={t.name} 
									colour={tagColour} 
								/>
							);
						}))
					}
				</div>
			</Card>
		</Grid>
	);
});

CourseCard.propTypes = { 
	course: PropTypes.shape({
		courseId: PropTypes.number,
		type: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		website: PropTypes.string,
		contactName: PropTypes.string,
		contactEmail: PropTypes.string,
		image: PropTypes.string,
		channels: PropTypes.arrayOf(PropTypes.object),
		organisations: PropTypes.arrayOf(PropTypes.object),
		mode: PropTypes.string,
		createdBy: PropTypes.string,
		createdByName: PropTypes.string,
		createdByAvatar: PropTypes.string,
		createdAt: PropTypes.string,
		updatedBy: PropTypes.string,
		updatedAt: PropTypes.string,
		providerId: PropTypes.number,
		providerName: PropTypes.string
	}).isRequired,
	canEdit: PropTypes.bool,
	deleteCourse: PropTypes.func,
	copyCourse: PropTypes.func,
	showCompetencies: PropTypes.func,
	organisationId: PropTypes.number.isRequired,
	colourPalette: PropTypes.array.isRequired
};

CourseCard.defaultProps = {
	canEdit: false,
	showCompetencies: undefined,
	deleteCourse: undefined,
	copyCourse: undefined
};

const mapStateToProps = state => ({
	colourPalette: state.theme.colourPalette
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CourseCard, styles));
