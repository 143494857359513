import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import classNames from 'classnames';
import map from 'lodash/map';
import capitalise from 'lodash/capitalize';
import CheckIcon from '@mui/icons-material/Check';
  
class ButtonGroup extends React.Component {
	render() {
		const { title, value, options, onChange, style } = this.props,
			Icon = this.props.icon;

		return (
			<div className="dih-button-group" style={style}>
				{title && <span style={{ marginRight: 10 }}>{title}:</span>}
				{map(options, (option, index) => 
					<Button 
						key={index} 
						className={classNames("option", { "active": option === value })}
						onClick={() => option !== value && onChange(option)}
					>
						{Icon && option === value && <Icon style={{ marginRight: 5, fontSize: 20 }} />}
						{capitalise(option)}
					</Button>
				)}
			</div>
		)
	}
}

ButtonGroup.propTypes = {
	title: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]).isRequired,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	icon: PropTypes.func,
	style: PropTypes.object
};

ButtonGroup.defaultProps = {
	title: "",
	icon: CheckIcon,
	style: {}
};

export default ButtonGroup;