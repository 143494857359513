import React from 'react';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import Card from '../widgets/Card';

const About = props =>
	<PageLayout title="About the hub" pageContent={
		<Card>
			<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.content }} />
		</Card>
	} breadcrumbs={["About"]} />;

export default connect(state => ({
	content: state.settings.aboutContent
}), null)(About);