import { createFetchAction, createFormPostAction, createPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	reports: [],
	report: {}, 
	groups: [],
	group: {}
};

const CLEAR_REPORT = "CLEAR_REPORT";
const CLEAR_REPORTS = "CLEAR_REPORTS";
const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";
const GET_REPORTS_REQUEST = "GET_REPORTS_REQUEST";
const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";
const SEARCH_REPORTS_REQUEST = "SEARCH_REPORTS_REQUEST";
const SEARCH_REPORTS_SUCCESS = "SEARCH_REPORTS_SUCCESS";
const SEARCH_REPORTS_FAILURE = "SEARCH_REPORTS_FAILURE";
const SAVE_REPORT_REQUEST = "SAVE_REPORT_REQUEST";
const SAVE_REPORT_SUCCESS = "SAVE_REPORT_SUCCESS";
const SAVE_REPORT_FAILURE = "SAVE_REPORT_FAILURE";
const DELETE_REPORT_REQUEST = "DELETE_REPORT_REQUEST";
const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE";
const CLEAR_GROUP = "CLEAR_GROUP";
const CLEAR_GROUPS = "CLEAR_GROUPS";
const GET_GROUP_REQUEST = "GET_GROUP_REQUEST";
const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
const GET_GROUP_FAILURE = "GET_GROUP_FAILURE";
const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST";
const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
const GET_GROUPS_FAILURE = "GET_GROUPS_FAILURE";
const SAVE_GROUP_REQUEST = "SAVE_GROUP_REQUEST";
const SAVE_GROUP_SUCCESS = "SAVE_GROUP_SUCCESS";
const SAVE_GROUP_FAILURE = "SAVE_GROUP_FAILURE";
const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";

export const isLoading = createLoadingSelector(["GET_REPORTS", "GET_REPORT", "SEARCH_REPORTS", "SAVE_REPORT", "DELETE_REPORT", 
	"GET_GROUPS", "GET_GROUP", "SAVE_GROUP", "DELETE_GROUP"]);

export const clearReports = () => ({ type: CLEAR_REPORTS });
export const clearReport = () => ({ type: CLEAR_REPORT });

export const getReports = () =>
	createFetchAction({
		url: "/api/reports",
		startAction: GET_REPORTS_REQUEST,
		onError: error => [getReportsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getReportsSuccess(data)
	});

export const getReportsSuccess = data => ({ type: GET_REPORTS_SUCCESS, payload: { data } });
export const getReportsFailure = error => ({ type: GET_REPORTS_FAILURE, payload: { error } });

export const getReport = reportId =>
	createFetchAction({
		url: `/api/reports/${reportId}`,
		startAction: GET_REPORT_REQUEST,
		onError: error => [getReportFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getReportSuccess(data)
	});

export const getReportSuccess = data => ({ type: GET_REPORT_SUCCESS, payload: { data } });
export const getReportFailure = error => ({ type: GET_REPORT_FAILURE, payload: { error } });

export const searchReports = search =>
	createFetchAction({
		url: `/api/reports/search?search=${search}`,
		startAction: SEARCH_REPORTS_REQUEST,
		onError: error => [searchReportsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchReportsSuccess(data)
	});

export const searchReportsSuccess = data => ({ type: SEARCH_REPORTS_SUCCESS, payload: { data } });
export const searchReportsFailure = error => ({ type: SEARCH_REPORTS_FAILURE, payload: { error } });

export const saveReport = (report, imageFile) => {
	const formData = new FormData();
	formData.append("Report", encodeURIComponent(JSON.stringify(report)));
	
	if (imageFile) formData.append("ImageFile", imageFile);

	return createFormPostAction({
		url: "/api/reports",
		data: formData,
		startAction: SAVE_REPORT_REQUEST,
		onError: error => [saveReportFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveReportSuccess(data), showSuccessNotification(data.message), changeLocation(`/admin/reports/${data.object.reportId}`)];
			} else {
				return [saveReportSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveReportSuccess = data => ({ type: SAVE_REPORT_SUCCESS, data });
export const saveReportFailure = error => ({ type: SAVE_REPORT_FAILURE, error });

export const deleteReport = reportId =>
	createPostAction({
		url: `/api/reports/${reportId}/delete`,
		startAction: DELETE_REPORT_REQUEST,
		onError: error => [deleteReportFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteReportSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/reports")];
			} else {
				return [deleteReportSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteReportSuccess = data => ({ type: DELETE_REPORT_SUCCESS, data });
export const deleteReportFailure = error => ({ type: DELETE_REPORT_FAILURE, error });

export const clearGroups = () => ({ type: CLEAR_GROUPS });
export const clearGroup = () => ({ type: CLEAR_GROUP });

export const getGroups = () =>
	createFetchAction({
		url: "/api/reports/groups",
		startAction: GET_GROUPS_REQUEST,
		onError: error => [getGroupsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getGroupsSuccess(data)
	});

export const getGroupsSuccess = data => ({ type: GET_GROUPS_SUCCESS, payload: { data } });
export const getGroupsFailure = error => ({ type: GET_GROUPS_FAILURE, payload: { error } });

export const getGroup = groupId =>
	createFetchAction({
		url: `/api/reports/groups/${groupId}`,
		startAction: GET_GROUP_REQUEST,
		onError: error => [getGroupFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getGroupSuccess(data)
	});

export const getGroupSuccess = data => ({ type: GET_GROUP_SUCCESS, payload: { data } });
export const getGroupFailure = error => ({ type: GET_GROUP_FAILURE, payload: { error } });

export const saveGroup = group =>
	createFormPostAction({
		url: "/api/reports/groups",
		data: group,
		startAction: SAVE_GROUP_REQUEST,
		onError: error => [saveGroupFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveGroupSuccess(data), showSuccessNotification(data.message), changeLocation(`/admin/report-groups/${data.object.groupId}`)];
			} else {
				return [saveGroupSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveGroupSuccess = data => ({ type: SAVE_GROUP_SUCCESS, data });
export const saveGroupFailure = error => ({ type: SAVE_GROUP_FAILURE, error });

export const deleteGroup = groupId =>
	createPostAction({
		url: `/api/reports/groups/${groupId}/delete`,
		startAction: DELETE_GROUP_REQUEST,
		onError: error => [deleteGroupFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteGroupSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/report-groups")];
			} else {
				return [deleteGroupSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteGroupSuccess = data => ({ type: DELETE_GROUP_SUCCESS, data });
export const deleteGroupFailure = error => ({ type: DELETE_GROUP_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_REPORTS:
			return { ...state, reports: [] };
		case CLEAR_REPORT:
			return { ...state, report: {} };
		case GET_REPORTS_REQUEST:
			return { ...state, saveResult: emptySaveResult, reports: [] };
		case GET_REPORTS_SUCCESS:
			return { ...state, isLoading: false, reports: action.payload.data };
		case GET_REPORT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult, report: {} };
		case GET_REPORT_SUCCESS:
			return { ...state, isLoading: false, report: action.payload.data };
		case SEARCH_REPORTS_REQUEST:
			return { ...state, saveResult: emptySaveResult, reports: [] };
		case SEARCH_REPORTS_SUCCESS:
			return { ...state, isLoading: false, reports: action.payload.data };
		case SAVE_REPORT_REQUEST:
		case DELETE_REPORT_REQUEST:
		case SAVE_GROUP_REQUEST:
		case DELETE_GROUP_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_REPORT_SUCCESS:
			return {
				...state,
				...(action.data.success && { report: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_REPORT_SUCCESS:
			return { ...state, reports: state.reports.filter(a => a.reportId !== action.data.objectId) };
		case CLEAR_GROUPS:
			return { ...state, groups: [] };
		case CLEAR_GROUP:
			return { ...state, group: {} };
		case GET_GROUPS_REQUEST:
			return { ...state, saveResult: emptySaveResult, groups: [] };
		case GET_GROUPS_SUCCESS:
			return { ...state, isLoading: false, groups: action.payload.data };
		case GET_GROUP_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult, group: {} };
		case GET_GROUP_SUCCESS:
			return { ...state, isLoading: false, group: action.payload.data };
		case SAVE_GROUP_SUCCESS:
			return {
				...state,
				...(action.data.success && { group: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_GROUP_SUCCESS:
			return { ...state, groups: state.groups.filter(a => a.groupId !== action.data.objectId) };
		default:
			return state;
	}
};
