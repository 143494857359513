import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import Button from '../widgets/Button';
import Tag from '../widgets/Tag';

const ViewCLO_Course = props => {
	const { course } = props;
	
	return props.loading || isEmpty(course) ? <CircularProgress size={24} /> : (
		<Card className="course-details">
			<div className="header">
				<div>
					<h2>{course.title}</h2>
					{course.type && <Tag label={course.type} style={{ marginRight: 5 }} />}
					{course.mode && <Tag label={course.mode} />}
				</div>
				<Button onClick={window.print}>
					<PrintIcon style={{ marginRight: 5}} />Print course
				</Button>
			</div>
			{course.website &&
				<section className="course-link">
					<span className="h5 print-show">{course.website}</span>
					<a href={course.website} target="_blank" rel="noopener noreferrer" className="h5 print-hide" style={{ fontWeight: "bold" }}>
						<ExitToAppIcon />
						View Course Website
					</a>
				</section>
			}
			<section>
				<span className="h5">Description</span>
				{!course.description ? "No description provided." : 
					<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: course.description }} />
				}
				<br />
			</section>
			{course.contactName &&
				<div style={{ marginTop: 10 }}>
					<strong>{course.contactName}</strong><br />
					<span>{course.contactEmail}</span>
				</div>
			}
			{course.attachments && course.attachments.length > 0 &&
				<section>
					<span className="h4">Course Brochure(s)</span>
					<ul className="list-unstyled">
						{course.attachments.map((attachment, i) =>
							<li key={i} className="media">
								<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
								<div className="media-body">
									<h5 className="mt-0 mb-1">{attachment.name}</h5>
									<p>{attachment.description}</p>
									<Button 
										variant="outlined" 
										onClick={() => window.open(`/CLO_Course/DownloadAttachment?courseId=${course.clO_CourseId}&attachmentId=${attachment.clO_AttachmentId}`, "_self")}
									>
										Download Now
									</Button>
								</div>
							</li>
						)}
					</ul>
				</section>
			}
			{props.canEdit && 
				<ButtonLink to={`/clo-courses/edit/${course.clO_CourseId}`} style={{ marginTop: 20 }}>
					Edit course
				</ButtonLink>
			}
		</Card>
	);
};

ViewCLO_Course.propTypes = {
	course: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	fromAdmin: PropTypes.bool
};

ViewCLO_Course.defaultProps = {
	canEdit: false,
	fromAdmin: false
};

export default ViewCLO_Course;