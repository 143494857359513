import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tooltip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import { getResources, getSubscribedResources } from '../../store/resources';
import { truncateString } from '../../utils/utils';
import Button from './Button';
import Card from './Card';
import Tag from './Tag';
import { map } from 'lodash';
import classNames from 'classnames';
import SearchField from './SearchField';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	resourceCard: {
		'& .card-body': {
			'& .label': {
				backgroundColor: theme.palette.primary.main
			},
			'& .academic': {
				'& svg': {
					color: theme.palette.secondary.main
				}
			}
		},
		'&.picker': {
			'&.selected': {
				borderColor: theme.palette.custom.selection
			},
			'&:hover': {
				borderColor: theme.palette.custom.selection
			}
		}
	}
});

const ResourceCard = ({ resource: r, onClick, selected, classes }) => 
	<Card 
		className={classNames("resource-card", classes.resourceCard, "picker", { "selected": selected })} 
		ribbon={r.isAcademic && "Academic"} 
		ribbonStatus="success" 
		onClick={() => onClick(r.resourceId)}
	>
		<span className="h3 name">{r.name}</span>
		{r.academicId &&
			<span className="h6 academic">
				<Tooltip title="Academic Author"><VerifiedUserIcon /></Tooltip>
				{r.academicName}
			</span>
		}
		{r.channel && <span className="label">{r.channel}</span>}
		<div className="body">{truncateString(r.description, 200)}</div>
		<div className="tags">
			{map(r.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
	</Card>;

const ResourceDialog = React.memo(props => {
	const [selected, setSelected] = React.useState([]);
	const [search, setSearch] = React.useState("");

	const { resources, value } = props;
	const query = new RegExp(search, "i"),
		filtered = search ? resources.filter(r => query.test(r.name)) : resources;

	React.useEffect(props.getResources, []);

	const onOpen = () => {
		setSearch("");

		if (props.isMulti) {
			setSelected(value ? value.filter(r => resources.some(r2 => r2.resourceId === r.resourceId)).map(r => r.resourceId) : []);
		}
	}

	const onClick = resourceId => {
		if (props.isMulti) {
			setSelected(selected.includes(resourceId) ? selected.filter(r => r !== resourceId) : [...selected, resourceId]);
		} else {
			onOk(resourceId);
		}
	};

	const onOk = newValue => {
		props.onChange(newValue);
		props.onClose();
	};
		
	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
            TransitionProps={{
                onEnter: onOpen
            }}>
			<DialogTitle>Select Resource{props.isMulti ? "s" : ""}</DialogTitle>
			<DialogContent>
				<SearchField placeholder="Search resources" value={search} onChange={value => setSearch(value)} style={{ marginBottom: 10 }} />
				{filtered.length === 0 ? "There are no resources to display" :
					<Grid container spacing={3}>
						{filtered.map((r, i) =>
							<Grid key={i} item xs={4}>
								<ResourceCard resource={r} onClick={onClick} selected={selected.includes(r.resourceId)} />
							</Grid>
						)}
					</Grid>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				{props.isMulti && 
					<Button disabled={selected.length === 0} onClick={() => onOk(resources.filter(r => selected.includes(r.resourceId)))}>Ok</Button>
				}
			</DialogActions>
		</Dialog>
    );
});

ResourceDialog.propTypes = {
	getResources: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	open: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onClose: PropTypes.func,
	isMulti: PropTypes.bool,
	subscribedOnly: PropTypes.bool.isRequired
};

ResourceDialog.defaultProps = {
	value: 0,
	onClose: () => {},
	isMulti: false
};

const mapStateToProps = state => ({
	resources: state.resources.resources
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getResources: () => dispatch(ownProps.subscribedOnly ? getSubscribedResources() : getResources())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ResourceDialog, styles));