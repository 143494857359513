import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import LDCourseCard from './LDCourseCard';
import ButtonLink from '../widgets/ButtonLink';
import TextField from '../widgets/TextField';
import Checkbox from '../widgets/Checkbox';
import pick from 'lodash/pick';
import CopyLDCourseDialog from './CopyLDCourseDialog';

const searchColumns = ["title", "code", "program", "description"];

const LDCourses = props => {
	const [search, setSearch] = React.useState("");
	const [mineOnly, setMineOnly] = React.useState(false);
	const [showCopyCourseDialog, setShowCopyCourseDialog] = React.useState(false);
	const [copiedCourse, setCopiedCourse] = React.useState({});

	const query = new RegExp(search, "i");
	const courses = (props.ldCourses || []).filter(c => (!mineOnly || c.createdBy === props.userId) && 
		(!search || Object.values(pick(c, searchColumns)).some(v => query.test(v)))
	);

	return (
		<React.Fragment>
			<Grid container spacing={3} className="ld-courses">
				<Grid item xs={12} sm>
					<TextField 
						placeholder="Search courses"
						value={search} 
						onChange={e => setSearch(e.target.value)} 
						startAdornment={props.loading ? <CircularProgress size={20} /> : <SearchIcon />}
					/>
				</Grid>	
				<Grid item xs sm="auto">
					<Checkbox 
						label="Show my courses only " 
						checked={mineOnly} 
						onChange={e => setMineOnly(e.target.checked)} 
					/>
				</Grid>
				<Grid item xs="auto">
					<ButtonLink to="/course-design/create">Add Course</ButtonLink>
				</Grid>
				<Grid item xs={12} style={{ padding: 0 }} />
				{courses.length === 0 && !props.loading ? <Grid item xs={12}>No courses found...</Grid> : 
					courses.map((c, i) => 
						<LDCourseCard 
							key={i} 
							ldCourse={c} 
							canEdit={props.isGlobalAdministrator || c.organisationId === props.organisationId} 
							copyCourse={() => {
								setCopiedCourse(c);
								setShowCopyCourseDialog(true);
							}}
							deleteCourse={() => props.deleteCourse(c)}
						/>
					)
				}
			</Grid>
			<CopyLDCourseDialog
				title={`${copiedCourse.title} - Copy`}
				code={`${copiedCourse.code}`}
				open={showCopyCourseDialog} 
				onClose={() => {
					setCopiedCourse({});
					setShowCopyCourseDialog(false);
				}}
				copyCourse={(title, code) => {
					props.copyCourse(copiedCourse.lD_CourseId, title, code);
					setCopiedCourse({});
					setShowCopyCourseDialog(false);
				}}
			/>
		</React.Fragment>
	);
};

LDCourses.propTypes = {
	isGlobalAdministrator: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	userId: PropTypes.string.isRequired,
	ldCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	copyCourse: PropTypes.func.isRequired,
	deleteCourse: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired
};

export default LDCourses;
