import { createFetchAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification } from './notifications';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	unitsOfCompetency: [],
	unitOfCompetency: {}
};

const CLEAR_UNITS_OF_COMPETENCY = "CLEAR_UNITS_OF_COMPETENCY";
const GET_UNITS_OF_COMPETENCY_REQUEST = "GET_UNITS_OF_COMPETENCY_REQUEST";
const GET_UNITS_OF_COMPETENCY_SUCCESS = "GET_UNITS_OF_COMPETENCY_SUCCESS";
const GET_UNITS_OF_COMPETENCY_FAILURE = "GET_UNITS_OF_COMPETENCY_FAILURE";
const SEARCH_UNITS_OF_COMPETENCY_REQUEST = "SEARCH_UNITS_OF_COMPETENCY_REQUEST";
const SEARCH_UNITS_OF_COMPETENCY_SUCCESS = "SEARCH_UNITS_OF_COMPETENCY_SUCCESS";
const SEARCH_UNITS_OF_COMPETENCY_FAILURE = "SEARCH_UNITS_OF_COMPETENCY_FAILURE";
const GET_UNIT_OF_COMPETENCY_REQUEST = "GET_UNIT_OF_COMPETENCY_REQUEST";
const GET_UNIT_OF_COMPETENCY_SUCCESS = "GET_UNIT_OF_COMPETENCY_SUCCESS";
const GET_UNIT_OF_COMPETENCY_FAILURE = "GET_UNIT_OF_COMPETENCY_FAILURE";

export const isLoading = createLoadingSelector([ 
	"GET_UNITS_OF_COMPETENCY", 
	"GET_PROFILE_UNITS_OF_COMPETENCY",
	"SEARCH_UNITS_OF_COMPETENCY",
	"GET_UNIT_OF_COMPETENCY"
]);

export const clearUnitsOfCompetency = () => ({ type: CLEAR_UNITS_OF_COMPETENCY });

export const getUnitsOfCompetency = onSuccess =>
	createFetchAction({
		url: "/api/skills-tracker/units",
		startAction: GET_UNITS_OF_COMPETENCY_REQUEST,
		onError: error => [getUnitsOfCompetencyFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getUnitsOfCompetencySuccess(data);
		}
	});

export const getUnitsOfCompetencySuccess = data => ({ type: GET_UNITS_OF_COMPETENCY_SUCCESS, payload: { data } });
export const getUnitsOfCompetencyFailure = error => ({ type: GET_UNITS_OF_COMPETENCY_FAILURE, payload: { error } });

const GET_PROFILE_UNITS_OF_COMPETENCY_REQUEST = "GET_PROFILE_UNITS_OF_COMPETENCY_REQUEST";
const GET_PROFILE_UNITS_OF_COMPETENCY_SUCCESS = "GET_PROFILE_UNITS_OF_COMPETENCY_SUCCESS";
const GET_PROFILE_UNITS_OF_COMPETENCY_FAILURE = "GET_PROFILE_UNITS_OF_COMPETENCY_FAILURE";

export const getProfileUnitsOfCompetency = (profileId, other, onSuccess) =>
	createFetchAction({
		url: `/api/skills-tracker/profiles/${profileId}${other ? "/other" : ""}`,
		startAction: GET_PROFILE_UNITS_OF_COMPETENCY_REQUEST,
		onError: error => [getProfileUnitsOfCompetencyFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getProfileUnitsOfCompetencySuccess(other ? data : data.units);
		}
	});

export const getProfileUnitsOfCompetencySuccess = data => ({ type: GET_PROFILE_UNITS_OF_COMPETENCY_SUCCESS, payload: { data } });
export const getProfileUnitsOfCompetencyFailure = error => ({ type: GET_PROFILE_UNITS_OF_COMPETENCY_FAILURE, payload: { error } });

export const getFrameworkUnitsOfCompetency = frameworkId =>
	createFetchAction({
		url: `/api/skills-tracker/frameworks/${frameworkId}`,
		startAction: GET_UNITS_OF_COMPETENCY_REQUEST,
		onError: error => [getUnitsOfCompetencyFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUnitsOfCompetencySuccess(data)
	});

export const searchUnitsOfCompetency = args => 
	createFetchAction({
		url: `/api/skills-tracker/units/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_UNITS_OF_COMPETENCY_REQUEST,
		onError: error => [searchUnitsOfCompetencyFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchUnitsOfCompetencySuccess(data)
	})

export const searchUnitsOfCompetencySuccess = data => ({ type: SEARCH_UNITS_OF_COMPETENCY_SUCCESS, payload: { data } });
export const searchUnitsOfCompetencyFailure = error => ({ type: SEARCH_UNITS_OF_COMPETENCY_FAILURE, payload: { error } });

export const getUnitOfCompetency = unitId =>
	createFetchAction({
		url: `/api/skills-tracker/units/${unitId}`,
		startAction: GET_UNIT_OF_COMPETENCY_REQUEST,
		onError: error => [getUnitOfCompetencyFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUnitOfCompetencySuccess(data)
	});

export const getUnitOfCompetencyByUnitCode = unitCode =>
	createFetchAction({
		url: `/api/skills-tracker/units/by-code/${unitCode}`,
		startAction: GET_UNIT_OF_COMPETENCY_REQUEST,
		onError: error => [getUnitOfCompetencyFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUnitOfCompetencySuccess(data)
	});

export const getUnitOfCompetencySuccess = data => ({ type: GET_UNIT_OF_COMPETENCY_SUCCESS, payload: { data } });
export const getUnitOfCompetencyFailure = error => ({ type: GET_UNIT_OF_COMPETENCY_FAILURE, payload: { error } });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_UNITS_OF_COMPETENCY:
			return { ...state, unitsOfCompetency: [] };
		case GET_UNITS_OF_COMPETENCY_REQUEST:
		case GET_PROFILE_UNITS_OF_COMPETENCY_REQUEST:
			return { ...state, unitsOfCompetency: [], isLoading: true };
		case SEARCH_UNITS_OF_COMPETENCY_REQUEST:
			return { ...state, isLoading: true };
		case GET_UNITS_OF_COMPETENCY_SUCCESS:
		case GET_PROFILE_UNITS_OF_COMPETENCY_SUCCESS:
		case SEARCH_UNITS_OF_COMPETENCY_SUCCESS:
			return { ...state, unitsOfCompetency: action.payload.data, isLoading: false };
		case GET_UNIT_OF_COMPETENCY_REQUEST:
			return { ...state, unitOfCompetency: {}, isLoading: true };
		case GET_UNIT_OF_COMPETENCY_SUCCESS:
			return { ...state, unitOfCompetency: action.payload.data, isLoading: true };
		default:
			return state;
	}
};