import React from 'react';
import PropTypes from 'prop-types';
import { 
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@mui/material';
import Checkbox from '../widgets/Checkbox';
import { stripHtml } from '../../utils/utils';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import CollapseIcon from '@mui/icons-material/ExpandLess';
import filter from 'lodash/filter';
import some from 'lodash/some';
import map from 'lodash/map';
import tinycolor from 'tinycolor2';

const UnitOfCompetencyPickerUnits = props => {
	const [expandedGroups, setExpandedGroups] = React.useState([]);
	const [expandedSpecialisations, setExpandedSpecialisations] = React.useState([]);
	const [expandedUnits, setExpandedUnits] = React.useState([]);
	const unitsBySpecialisationGroup = groupBy(props.units, 'specialisationGroupId');
	const errorColour = props.errorColour;

	React.useEffect(() => {
		let initialExpandedSpecialisations = [];
		let initialExpandedUnits = [];
		const initalExpandedGroups = map(unitsBySpecialisationGroup, (groupUnits, groupId) => {
			if (props.expandRows) {
				const unitsBySpecialisation = groupBy(groupUnits, 'specialisationId');
				forEach(unitsBySpecialisation, (units, specialisationId) => {
					initialExpandedSpecialisations.push(specialisationId.toString());
					forEach(units, u => {
						initialExpandedUnits.push(u.unitOfCompetencyId);
					});
				})
			}
			return groupId; 
		});

		setExpandedGroups(initalExpandedGroups);
		setExpandedSpecialisations(initialExpandedSpecialisations);
		setExpandedUnits(initialExpandedUnits);
	}, [props.unitsOfCompetency])

	return (
        <Table>
			<TableHead>
				<TableRow>
					<TableCell style={{ width: 35 }}></TableCell>
					<TableCell style={{  }}>Code</TableCell>
					<TableCell style={{ width: 35 }}>Level</TableCell>
					<TableCell style={{  }}>Unit</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{(() => {
					const rows = [];

					forEach(unitsBySpecialisationGroup, (groupUnits, groupId) => {
						const specialisationGroup = groupUnits[0].specialisationGroup;
						const specialisationGroupColour = tinycolor(groupUnits[0].specialisationGroupColour);
						const isGroupExpanded = expandedGroups.indexOf(groupId) > -1;

							
						if (rows.length > 0) {
							rows.push(
								<TableRow key={`spec-group-${groupId}-divider`}>
									<TableCell colSpan={3}></TableCell>
								</TableRow>
							)
						}

						rows.push(
							<TableRow 
								key={`spec-group-${groupId}`}
								style={{ 
									background: specialisationGroupColour.toHexString(),
									color: specialisationGroupColour.isLight() ? '#000' : '#fff'
								}}
							>
								<TableCell 
									colSpan={1}
									style={{ 
										backgroundColor: specialisationGroupColour.toHex8String(), 
										color: specialisationGroupColour.isLight() ? '#000' : '#fff'
									}}
								>
									<IconButton
                                        style={{ 
											color: specialisationGroupColour.isLight() ? '#000' : '#fff'
										}}
                                        onClick={() => {
											if (isGroupExpanded) {
												setExpandedGroups(filter(expandedGroups, g => g !== groupId));
											} else {
												setExpandedGroups([...expandedGroups, groupId])
											}
										}}
                                        size="large">
										{isGroupExpanded ? <CollapseIcon /> : <ExpandIcon />}
									</IconButton>
								</TableCell>
								<TableCell 
									colSpan={4} 
									style={{ 
										backgroundColor: specialisationGroupColour.toHex8String(), 
										color: specialisationGroupColour.isLight() ? '#000' : '#fff',
										fontWeight: 'bold', 
										position: 'relative' 
									}}
								>
									{specialisationGroup}
								</TableCell>
							</TableRow>
						);
						
						
						if (isGroupExpanded) {
							const unitsBySpecialisation = groupBy(groupUnits, 'specialisationId');
							forEach(unitsBySpecialisation, (units, specialisationId) => {
								const specialisation = units[0].specialisation;
								const specialisationColour = tinycolor(units[0].specialisationColour);
								const isExpanded = expandedSpecialisations.indexOf(specialisationId) > -1;

								rows.push(
									<TableRow key={`spec-${specialisationId}`}>
										<TableCell 
											colSpan={1} 
											style={{ 
												backgroundColor: specialisationColour.toHexString() , 
												color: specialisationColour.isLight() ? '#000' : '#fff'
											}}
										>
											<IconButton
                                                style={{ 
													color: specialisationColour.isLight() ? '#000' : '#fff'
												}}
                                                onClick={() => {
													if (isExpanded) {
														setExpandedSpecialisations(filter(expandedSpecialisations, s => s !== specialisationId));
													} else {
														setExpandedSpecialisations([...expandedSpecialisations, specialisationId])
													}
												}}
                                                size="large">
												{isExpanded ? <CollapseIcon /> : <ExpandIcon />}
											</IconButton>
										</TableCell>
										<TableCell 
											colSpan={4} 
											style={{ 
												backgroundColor: specialisationColour.toHex8String(), 
												color: specialisationColour.isLight() ? '#000' : '#fff', 
												fontWeight: 'bold', 
												position: 'relative'
											}}
										>
											{specialisation}
										</TableCell>
									</TableRow>
								);

								if (isExpanded) {
									forEach(units, (u, unitIndex) => {
										const rowKey = `unit-${u.unitOfCompetencyId}`;
										const isUnitExpanded = expandedUnits.indexOf(u.unitOfCompetencyId) > -1;

										rows.push(
											<TableRow key={rowKey}>
												<TableCell >
													<IconButton
                                                        onClick={() => {
															if (isUnitExpanded) {
																setExpandedUnits(filter(expandedUnits, u2 => u2 !== u.unitOfCompetencyId));
															} else {
																setExpandedUnits([...expandedUnits, u.unitOfCompetencyId])
															}
														}}
                                                        size="large">
														{isExpanded ? <CollapseIcon /> : <ExpandIcon />}
													</IconButton>
												</TableCell>
												<TableCell colSpan={2}>
													<a style={{ cursor: "pointer" }} onClick={() => props.viewUnit(u)}>{u.unitCode}</a>
												</TableCell>
												<TableCell style={{ whiteSpace: "normal" }} colSpan={2}>
													{stripHtml(u.unitTitle)}
												</TableCell>
											</TableRow>
										);

										if (isUnitExpanded) {
											forEach(u.elements, (e, elementIndex) => {
												const expandedUnitRowKey = `u-${u.unitOfCompetencyId}-e-${e.unitElementId}`;

												const detailCellStyle = {
													borderBottom: u.elements.length !== elementIndex + 1 && 'none'
												};
												rows.push(
													<TableRow key={expandedUnitRowKey}>
														<TableCell style={detailCellStyle}></TableCell>
														<TableCell style={detailCellStyle}>
															<Checkbox
																disabled={!props.canEdit || !props.selectElement}
																checked={some(props.selectedElements, e2 => e2.elementId === e.unitElementId)}
																onChange={evt => props.selectElement(u, e, evt.target.checked)}
															/>
														</TableCell>
														<TableCell style={detailCellStyle}>
															{e.order}
														</TableCell>
														<TableCell style={detailCellStyle}>
															<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />
														</TableCell>
														<TableCell style={{ ...detailCellStyle, whiteSpace: "normal" }} >
															{
																map(e.items, i => <div key={i.order} className="quill-display-block" dangerouslySetInnerHTML={{ __html: i.performanceCriteriaName }} />)
															}
														</TableCell>
												</TableRow>
												)
											});
											if (props.showNotCompetent) {
												rows.push(
													<TableRow key={`${rowKey}-nc`}>
														<TableCell />
														<TableCell>
															<Checkbox 
																disabled={!props.canEdit || !props.setCompetent}
																checked={props.selectedUnits && props.selectedUnits.some(u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId && !u2.competent)}
																onChange={evt => props.setCompetent(u, !evt.target.checked)}
															/>
														</TableCell>
														<TableCell colSpan={3} style={{ color: errorColour }}>
															I do not meet the required minimum level of attainment for this competency
														</TableCell>
													</TableRow>
												);
											}
										}
									});
								}
							});
						}
					});

					return rows;
				})()}
			</TableBody>
		</Table>
    );
};

UnitOfCompetencyPickerUnits.propTypes = {
	selectElement: PropTypes.func,
	selectedElements: PropTypes.array.isRequired,
	units: PropTypes.array.isRequired,
	setCompetent: PropTypes.func,
	viewUnit: PropTypes.func.isRequired,
	unitsOfCompetency: PropTypes.array,
	expandRows: PropTypes.bool.isRequired,
	showNotCompetent: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired
};

UnitOfCompetencyPickerUnits.defaultProps = {
	unitsOfCompetency: [],
	selectElement: null,
	setCompetent: null
};

export default UnitOfCompetencyPickerUnits;