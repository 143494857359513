import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from './AutoComplete';
import map from 'lodash/map';
import find from 'lodash/find';

const TagEditor = React.memo(props => 
	<AutoComplete 
		className="dih-input"
		label="Tags"
		placeholder={props.placeholder}
		isMulti
		value={map(props.value, v => ({ label: v.name, value: v._value || String(v.tagId), __isNew__: v.tagId === 0 }))}
		loadItems={{
			route: `tags?scope=${props.scope || ''}`,
			mapItem: t => ({ label: t.name, value: String(t.tagId) })
		}}
		onChange={values => props.onChange(map(values, t => {
			if (t.__isNew__) return { tagId: 0, name: t.label, _value: t.value };

			const tagId = Number(t.value);
			return find(props.value, t2 => t2.tagId === tagId) || { tagId, name: t.label };
		}))}
		canCreate
		helpText="Use your Enter or Tab key to separate each Tag"
	/>);

TagEditor.propTypes = {
	placeholder: PropTypes.string,
	value: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	styles: PropTypes.object
};

TagEditor.defaultProps = {
	placeholder: "",
	value: [],
	styles: {}
};

export default TagEditor;
