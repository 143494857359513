import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStudentProject, isLoading, saveEnquiry } from '../../store/studentProjects';
import { canEditStudentProject, canViewEnquiries, canMakePublic } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewStudentProject from './ViewStudentProject';
import CreateEnquiry from './CreateEnquiry';
import { showModal } from '../../store/modal';
import isEmpty from 'lodash/isEmpty';
import MakePublicButton from '../widgets/MakePublicButton';

const ViewStudentProjectController = props => {
	const { projectId } = props.match.params;
	React.useEffect(() => props.getProject(projectId), [projectId]);

	return (
		<PageLayout 
			title="View Business Challenge" 
			hideContent={isEmpty(props.project)} 
			pageContent={
				<React.Fragment>
					<ViewStudentProject 
						project={props.project} 
						loading={props.loading} 
						canEdit={props.canEdit} 
						canViewEnquiries={props.canViewEnquiries} 
						primaryColour={props.primaryColour} 
						secondaryColour={props.secondaryColour} 
					/>
					{!props.isPublicLink &&
						<CreateEnquiry saveEnquiry={(content, file) => props.saveEnquiry(projectId, content, file)} loading={props.loading} />
					}
				</React.Fragment>
			} 
			breadcrumbs={[{ label: "Research Match", path: "/business-challenges" }, "View Business Challenge"]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);
};

ViewStudentProjectController.propTypes = {
	project: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canMakePublic: PropTypes.bool.isRequired,
	saveEnquiry: PropTypes.func.isRequired,
	canViewEnquiries: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired,
	isPublicLink: PropTypes.bool,
	secondaryColour: PropTypes.string.isRequired
};

ViewStudentProjectController.defaultProps = {
	isPublicLink: false
};

const mapStateToProps = state => ({
	project: state.studentProjects.project,
	loading: isLoading(state),
	canEdit: canEditStudentProject(state),
	canViewEnquiries: canViewEnquiries(state),
	canMakePublic: canMakePublic(state),
	primaryColour: state.theme.primaryColour,
	secondaryColour: state.theme.secondaryColour,
	isPublicLink: state.context.isPublicLink
});

const mapDispatchToProps = dispatch => ({
	getProject: projectId => dispatch(getStudentProject(projectId)),
	saveEnquiry: (projectId, content, file) => dispatch(dispatch(showModal("SIMPLE", {
		title: "Are sure you want to submit an enquiry?",
		message: "The owners of this challenge will contact you with a response at a later date.",
		onOk: () => dispatch(saveEnquiry(projectId, content, file))
	})))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewStudentProjectController);