import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import Users from './Users';
import { canSubmitUsers, canViewOrganisations, canEditUser } from '../../store/security';
import { getUsers, isLoading } from '../../store/users';

const UsersController = props => {
	React.useEffect(props.getUsers, []);

	return <PageLayout title="Manage users" pageContent={
		<Users 
			showTenant={props.isGlobalAdministrator}
			users={props.users} 
			loading={props.loading} 
			canSubmit={props.canSubmit}
			canEdit={props.canEdit}
			canViewOrganisations={props.canViewOrganisations}
			primaryColour={props.primaryColour}
			errorColour={props.errorColour}
		/>
	} breadcrumbs={["Users"]} />;
};

UsersController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canViewOrganisations: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	users: state.users.users,
	loading: isLoading(state),
	canSubmit: canSubmitUsers(state),
	canEdit: canEditUser(state),
	canViewOrganisations: canViewOrganisations(state),
	primaryColour: state.theme.primaryColour,
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getUsers: () => dispatch(getUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersController);
