import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getExpert, isLoading } from '../../store/experts';
import { canEditExpert } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewExpert from './ViewExpert';
import {
    toggleChat,
    setToUser
} from '../../store/messages';

const ViewExpertController = props => {
	React.useEffect(() => props.getExpert(props.match.params.expertId), [props.match.params.expertId]);

	return <PageLayout title="View expert" pageContent={
		<ViewExpert 
            expert={props.expert} 
            loading={props.loading} 
            canEdit={props.canEdit} 
            showChat={(expertId) => {
                props.setChatToUser(expertId);
                props.toggleChat(true);
            }}
        />
	} breadcrumbs={[{ label: "Experts", path: "/experts" }, "View expert"]} />;
};

ViewExpertController.propTypes = {
	expert: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
    setChatToUser: PropTypes.func.isRequired,
    toggleChat: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	expert: state.experts.expert,
	loading: isLoading(state),
	canEdit: canEditExpert(state)
});

const mapDispatchToProps = dispatch => ({
	getExpert: expertId => dispatch(getExpert(expertId)),
    setChatToUser: userId => dispatch(setToUser(userId)), 
    toggleChat: show => dispatch(toggleChat(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewExpertController);