 import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Form from './Form';
import TextField from './TextField';
import Button from './Button';

const SearchField = React.memo(props => 
	<div className="search-field" style={{ padding: props.addPadding && "0 15px", ...props.style }}>
		{props.label && <h3>{props.label}</h3>}
		<Form onSubmit={props.onSubmit}>
			<TextField
				placeholder={props.placeholder}
				aria-label={props.label}
				fullWidth 
				value={props.value}
				onChange={e => props.onChange(e.target.value)}
				startAdornment={props.tag &&
					<Chip 
						label={props.tag} 
						deleteIcon={<ClearIcon style={{ color: "white" }} />} 
						onDelete={props.removeTag} 
						style={{ 
							backgroundColor: props.tagColour,
							color: "white",
							height: 20,
							marginRight: 5 
						}}
					/>
				}
			/>
			{props.onSubmit && <Button loading={props.loading} type="submit">Search</Button>}
		</Form>
	</div>
);

SearchField.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	tag: PropTypes.string,
	removeTag: PropTypes.func,
	addPadding: PropTypes.bool,
	style: PropTypes.object,
    placeholder: PropTypes.string,
	tagColour: PropTypes.string.isRequired
};

SearchField.defaultProps = {
	label: "",
	onSubmit: null,
	loading: false,
	tag: "",
	removeTag: null,
	addPadding: false,
	style: {},
    placeholder: "Enter your search here..."
};

const mapStateToProps = state => ({
	tagColour: state.theme.tagColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
