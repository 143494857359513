import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import ButtonLink from '../widgets/ButtonLink';
import Pagination from '../widgets/Pagination';
import IPRow from './IPRow';
import map from 'lodash/map';
import slice from 'lodash/slice';

const itemsPerPage = 10;

const AllIP = props => {
	const [page, setPage] = React.useState(1);
	const { loading, ips } = props, 
		pageCount = Math.ceil(ips.length / itemsPerPage),
		from = (page - 1) * itemsPerPage;

	return (
		<Card title="All IP" loading={loading} titleRightContent={props.canEdit && <ButtonLink to="/ip/create">Submit IP</ButtonLink>}>
			{ips.length === 0 && <p>No results returned.</p>}
			{map(slice(ips, from, from + itemsPerPage), (ip, index) =>
				<IPRow key={index} ip={ip} deleteIP={props.deleteIP} />
			)}
			{/* Only render Pagination after problems have finished loading */}
			{!props.loading &&
				<Pagination maxPages={10} pageCount={pageCount} currentPage={page} changePage={page => setPage(page)} />
			}
		</Card>
	);
};

AllIP.propTypes = {
	ips: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	deleteIP: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired
};

export default AllIP;
