import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import AttachmentList from '../widgets/AttachmentList';
import ButtonLink from '../widgets/ButtonLink';
import Accordion from '../widgets/Accordion';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';

const ViewStudent = props => {
	const { student } = props;

	return props.loading || isEmpty(student) ? <CircularProgress size={24} style={{ marginLeft: 10 }} /> : (
		<Card className="student-details">
			<div className="contact-info">
				{/* Avatar */}
				<Avatar src={student.avatar} fullName={student.name} length={50} />
				{/* Name + Role */}
				<div>
					<span className="h3">{student.name}</span>
					<span>{student.email}</span>
				</div>
			</div>
			<section style={{ display: "flex" }}>
				<img className="logo" src={student.institutionLogo} alt="Logo" style={{ maxWidth: 20, marginRight: 5 }} />
				{student.institutionName}
			</section>
			<section>
				<span className="h4">About</span>
				<div dangerouslySetInnerHTML={{ __html: student.about }} />
			</section>
			<section>
				<span className="h4">Current Program</span>
				{student.currentProgram}
			</section>
			<section>
				<span className="h4">Portfolio</span>
				{student.numPortfolioItems === 0 ? "There are no portfolio items..." : student.portfolio.map((item, itemIndex) => 
					<Accordion key={itemIndex} summary={item.title} style={{ marginBottom: 20 }}>
						<div>
							<div dangerouslySetInnerHTML={{ __html: item.description }} style={{ marginBottom: 10 }} />
							{item.links.length > 0 &&
								<React.Fragment>
									<span className="h5">Links</span>
									<ul>
										{item.links.map((l, i) => 
											<li key={i}>{l.label} &mdash; <a href={`https://${l.to}`} target="_blank" rel="noopener noreferrer">{l.to}</a></li>
										)}
									</ul>
								</React.Fragment>
							}
							{item.attachments.length > 0 &&
								<React.Fragment>
									<span className="h5">Attachments</span>
									<AttachmentList 
										attachments={item.attachments} 
										downloadLink={attachment => `/Student/DownloadAttachment?studentId=${student.studentId}&attachmentId=${attachment.attachmentId}`} 
									/>
								</React.Fragment>
							}
						</div>
					</Accordion>
				)}
			</section>
			{props.canEdit && <ButtonLink to={`/students/edit/${student.studentId}`}>Edit student</ButtonLink>}
		</Card>
	);
};

ViewStudent.propTypes = {
	student: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool
};

ViewStudent.defaultProps = {
	canEdit: false
};

export default ViewStudent;