import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, post } from '../../utils/ajax';
import { 
	getCompetencies,
	saveCompetencies, 
	updateUserAssessment,
	isLoading
} from '../../store/competencies';
import { getSkillsProfile } from '../../store/skillsProfiles';
import { getOrganisation } from '../../store/organisations';
import { getTeamSkillsUsers } from '../../store/users';
import Competencies from '../competencies/Competencies';
import DOMPurify from 'dompurify';
import produce from 'immer';
import find from 'lodash/find';
import remove from 'lodash/remove';
import some from 'lodash/some';
import axios from 'axios';
import { 
	showSuccessNotification, 
	showWarningNotification, 
	showErrorNotification 
} from '../../store/notifications';
import { Prompt } from 'react-router'
import { showModal } from '../../store/modal';

const TeamCompetencies = (props) => {
	const [organisationLoading, setOrganisationLoading] = React.useState(false);
	const [organisation, setOrganisation] = React.useState(null);
	const [usersLoading, setUsersLoading] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [users, setUsers] = React.useState([]);
	const [competenciesLoading, setCompetenciesLoading] = React.useState(false);
	const [unitsLoading, setUnitsLoading] = React.useState(false);
	// const [initComplete, setInitComplete] = React.useState(false);
	const [profileId, setProfileId] = React.useState(false);
	const [state, setState] = React.useState({
		skillsProfileId: 0,
		competencies: [],
		skills: { programs: "" },
		dirty: false,
		newFiles: [],
		profileUnits: [],
		showProfilePicker: false,
		triggerSave: false,
		reviewStatus: "In-Progress"
	});

	const { autoExpandCompetencies = true, showNotCompetent = false } = organisation || {};
	let graphicalSkillsView = organisation ? organisation.graphicalSkillsView : true;
	if (organisation && organisation.initialAssessmentSkillsView === 'Textual' && !props.userAssessmentComplete) {
		// initialAssessmentSkillsView can override when user assessment not complete
		graphicalSkillsView = false;
	}

	const getCompetencies = (user, loadCompetencies) => {
		setCompetenciesLoading(true);
		get({
			url: user === "team" ? `/api/teams/${props.teamId}/competencies` : `/api/competencies/${user}?aspirational=${false}`,
			onSuccess: (data) => {
				setCompetenciesLoading(false);
				if (user === "team") {
					setState(prevState => ({ ...prevState, competencies: data }));
				} else {
					loadCompetencies(data);
				}
			},
			onError: (error) => {
				setCompetenciesLoading(false);
					setState(prevState => ({ ...prevState, competencies: [] }));
				props.showErrorNotification(error.message);
			}
		});
	}

	const getUnits = (userId) => {
		setUnitsLoading(true);
		get({
			url: userId === "team" ? `/api/skills-tracker/units-by-team/${props.teamId}` : `/api/skills-tracker/units-by-team-user?teamId=${props.teamId}&userId=${userId}`,
			onSuccess: (data) => {
				setUnitsLoading(false);
				setState(prevState => ({ ...prevState, profileUnits: data }));
			},
			onError: (error) => {
				setUnitsLoading(false);
				setState(prevState => ({ ...prevState, profileUnits: [] }));
				props.showErrorNotification(error.message);
			}
		})
	};

	const loadData = () => {
		setLoading(true);
		setOrganisationLoading(true);
		setUsersLoading(true);
		setCompetenciesLoading(true);

		axios.all([
			get({
				url: `/api/organisations/${props.organisationId}`,
				onSuccess: (data) => {
					setOrganisationLoading(false);
					setOrganisation(data);
				},
				onError: (error) => {
					setOrganisationLoading(false);
					setOrganisation([]);
					props.showErrorNotification(error.message);
				}
			}),
			get({
				url: `/api/teams/${props.teamId}/team-users`,
				onSuccess: (data) => {
					setUsersLoading(false);
					setUsers(data);
				},
				onError: (error) => {
					setUsersLoading(false);
					setUsers([]);
					props.showErrorNotification(error.message);
				}
			}),
			get({
				url: props.user === "team" ? `/api/teams/${props.teamId}/competencies` : `/api/competencies/${props.user}?aspirational=${false}`,
				onSuccess: (data) => {
					setCompetenciesLoading(false);
					if (props.user === "team") {
						setState(prevState => ({ ...prevState, competencies: data }));
					} else {
						loadCompetencies(data);
					}
				},
				onError: (error) => {
					setCompetenciesLoading(false);
					setState(prevState => ({ ...prevState, competencies: [] }));
					props.showErrorNotification(error.message);
				}
			}),
			get({
				url: props.user === "team" ? `/api/skills-tracker/units-by-team/${props.teamId}` : `/api/skills-tracker/units-by-team-user?teamId=${props.teamId}&userId=${props.user}`,
				onSuccess: (data) => {
					setState(prevState => ({ ...prevState, profileUnits: data }));
				}
			})
		]).then(responseArr => {
			setLoading(false);
		});
	};

	React.useEffect(() => {
		if (props.teamId) {
			loadData();
		}
	}, [props.teamId]);

	React.useEffect(() => {
		if (!organisationLoading && !competenciesLoading && !usersLoading) {
			//(true);
		}
	}, [organisationLoading, competenciesLoading, usersLoading]);

	// Fetch filter units for profile whenever updated
	// React.useEffect(() => {
	// 	if (initComplete) {
	// 		if (state.skillsProfileId) {
	// 			setLoading(true);
	// 			get({
	// 				url: `/api/skills-tracker/profiles/${state.skillsProfileId}`,
	// 				onSuccess: ({ units }) => {
	// 					setLoading(false);
	// 					setState({ ...state, profileUnits: units });
	// 				},
	// 				onError: () => { setLoading(false) }
	// 			});
	// 		} else {
	// 			setLoading(false);
	// 		}
	// 	}
	// }, [state.skillsProfileId, initComplete]);

	const loadCompetencies = userCompetencies => {
		let { skillsProfileId, competencies, skills, reviewStatus } = userCompetencies;
		const cleanSkills = { ...skills, programs: skills.programs ? DOMPurify.sanitize(skills.programs) : "" };
		let showProfilePicker = false;
		if (!skillsProfileId) showProfilePicker = true;
		setProfileId(skillsProfileId);
		setState(prevState => ({ ...prevState, competencies: competencies, skills: cleanSkills, dirty: false, showProfilePicker, reviewStatus }));
	};

	const checkDirty = draft => {
		if (!draft.dirty) props.showWarning("You have unsaved changes.");
		draft.dirty = true;
	};

	// Generic method to help set state properties
	// Replicated checkDirty to avoid altering state directly
	const updateField = name => value => {
		if (!state.dirty) props.showWarning("You have unsaved changes.");
		setState(prevState => ({ ...prevState, [name]: value, dirty: true }));
	}

	const addUnits = units => setState(produce(draft => {
		units.forEach(u => {
			const competency = find(draft.competencies, c => c.unitOfCompetencyId === u.unitOfCompetencyId);
		
			if (!competency) {
				draft.competencies.push(u);
			} else {
				competency.elements = [...u.elements];
			}
		});

		checkDirty(draft);
		draft.promptSelection = false;
	}, state));

	const addUnitsAndSave = units => 
		setState(
			produce(draft => {
				remove(draft.competencies, c => !some(units, u => u.unitOfCompetencyId === c.unitOfCompetencyId));
				units.forEach(u => {
					const competency = find(draft.competencies, c => c.unitOfCompetencyId === u.unitOfCompetencyId);
				
					if (!competency) {
						draft.competencies.push(u);
					} else {
						competency.elements = [...u.elements];
					}
				});

				checkDirty(draft);
				draft.promptSelection = false;
				draft.triggerSave = true;
			}, state)
		);

	React.useEffect(() => {
		if (state.triggerSave) {
			setState(
				produce(draft => {
					draft.triggerSave = false;
				}, state)
			);
			saveCompetencies();
		}
	}, [state.triggerSave]);
	
	const handleUserChange = userId => {
		setState(prevState => ({ ...prevState, competencies: [], profileUnits: [] }));
		let teamLeaderCanEdit = false;
		if (userId !== "team") {
        	const user = find(users, u => u.userId === userId);
			teamLeaderCanEdit = user.teamLeaderCanEdit;
		}
		props.onUserChange(userId);
		setState(state => ({ ...state, teamLeaderCanEdit: teamLeaderCanEdit }));
		getCompetencies(userId, loadCompetencies);
		getUnits(userId);
	};

	const updateSkillsProfile = skillsProfileId => 
		post({
			url: `/api/competencies/${props.user}/calculate-competencies`,
			data: { skillsProfileId, competencies: state.competencies },
			onSuccess: data => {
				setState(produce(draft => {
					draft.skillsProfileId = skillsProfileId;

					data.forEach(c => {
						const c2 = find(draft.competencies, c3 => c3.competencyId === c.competencyId);
						c2.competent = c.competent;
					});
				}, state));
			},
			onError: error => props.showWarning(error.message)
		});
		
	const deleteCompetency = (unitId, elementId) => setState(produce(draft => {
		const competency = find(draft.competencies, c => c.unitOfCompetencyId === unitId);

		if (elementId) remove(competency.elements, e => e.competencyElementId === elementId);
		if (!elementId || competency.elements.length === 0) remove(draft.competencies, competency);
		
		checkDirty(draft);
	}, state));

	const updateCompetency = (unitId, elementId, field, data) => setState(produce(draft => {
		const competency = find(draft.competencies, c => c.unitOfCompetencyId === unitId),
			target = elementId ? find(competency.elements, e => e.elementId === elementId) : competency;

		switch (field) {
			case "Competent":
				target.competent = data;
				break;
			case "Reviewed":
				target.reviewed = data;
				break;
			case "AssessmentDate":
				target.assessmentDate = data;
				break;
			case "ExpiryDate":
				target.expiryDate = data;
				break;
			case "Evidence":
				target.evidence = { ...data.evidence };
				draft.newFiles = [...draft.newFiles, ...data.newFiles];
				break;
			case "Elements":
				competency.competent = some(data, e => e.competent);
				competency.elements = [...data];
				break;
			default:
		}

		checkDirty(draft);
	}, state));

	const saveCompetencies = () => {
		props.saveCompetencies(props.mode, props.user, state.competencies, profileId, state.skills, state.newFiles, state.reviewStatus, (data) => {
			loadCompetencies(data);
			if (props.completeUserAssessment && !props.userAssessmentComplete) {
				props.showYesNoDialog(
					"Is Initial Assessment Complete?",
					<React.Fragment>
						<p>If ‘No’ you be returned to this assessment page to continue your assessment on your next visit</p>
						<p>If ‘Yes’ you can continue and access your Skills Passport through the Skills menu at top of page</p>
					</React.Fragment>,
					() => {
						props.updateUserAssessment(true);
					},
					() => {}
				)
			}
		});
	};


	const isLoading = loading || props.isLoading || organisationLoading || competenciesLoading || unitsLoading;
	return (
		<React.Fragment>
			<Prompt when={state.dirty} message="You have unsaved changes. Are you sure you want to leave?" />	
			<Competencies 
				competencies={state.competencies} 
				skillsProfileId={profileId}
				profileUnits={state.profileUnits}
				updateSkillsProfile={updateSkillsProfile}
				skills={state.skills}
				reviewStatus={state.reviewStatus}
				loading={isLoading}
				promptSelection={false} 
				mode={'team'}
				printReport={""}
				lockCompetencyUpdates={props.lockCompetencyUpdates || !state.teamLeaderCanEdit}
				autoExpandCompetencies={autoExpandCompetencies}
				users={users}
				user={props.user}
				onUserChange={handleUserChange}
				showNotCompetent={showNotCompetent}
				showProfilePicker={state.showProfilePicker}
				onProfilePickerClose={() => setState(prevState => ({ ...prevState, showProfilePicker: false }))}
				profileGroupId={props.profileGroupId}
				showReviewStatus={props.showReviewStatus}
				graphicalSkillsView={graphicalSkillsView}
				dirty={state.dirty}

				addUnits={addUnits}
				addUnitsAndSave={addUnitsAndSave}
				deleteCompetency={deleteCompetency}
				updateCompetency={updateCompetency}
				saveCompetencies={saveCompetencies}
				updateSkills={updateField("skills")}
				updateReviewStatus={updateField("reviewStatus")}
				resetPromptSelection={() => setState(prevState => ({ ...prevState, promptSelection: false }))}
			/>
		</React.Fragment>
	);
};

TeamCompetencies.propTypes = {
	teamId: PropTypes.number.isRequired,
	organisationId: PropTypes.number.isRequired,
	organisation: PropTypes.object.isRequired,
	getTeamSkillsUsers: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	users: PropTypes.array.isRequired,
	mode: PropTypes.string,
	profileGroupId: PropTypes.number,
	completeUserAssessment: PropTypes.bool.isRequired,
	userAssessmentComplete: PropTypes.bool.isRequired,
	showReviewStatus: PropTypes.bool.isRequired,
	lockCompetencyUpdates: PropTypes.bool,
	showWarning: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

TeamCompetencies.defaultProps = {
	profileGroupId: 0,
	lockCompetencyUpdates: false,
	mode: "team"
};

const mapStateToProps = (state, ownProps) => ({
	organisationId: state.context.organisationId,
	organisation: state.organisations.organisation,
	userId: state.context.userId,
	users: state.users.users,
	lockCompetencyUpdates: state.context.lockCompetencyUpdates,
	profileGroupId: state.organisations.organisation[`${ownProps.mode === "secondary" ? "secondary" : "primary"}SkillsProfileGroupId`],
	completeUserAssessment: state.competencies.completeUserAssessment,
	userAssessmentComplete: state.competencies.userAssessmentComplete,
	showReviewStatus: state.context.showReviewStatus,
	isLoading: isLoading(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getOrganisation: (organisationId, onSuccess, onError) => dispatch(getOrganisation(organisationId, onSuccess, onError)),
	getTeamSkillsUsers: (onSuccess, onError) => dispatch(getTeamSkillsUsers(onSuccess, onError)),
	getCompetencies: (userId, onSuccess, onError) => dispatch(getCompetencies(userId, ownProps.mode === "secondary", onSuccess, onError)),
	getSkillsProfile: (skillsProfileId, onSuccess, onError) => dispatch(getSkillsProfile(skillsProfileId, onSuccess, onError)),
	saveCompetencies: (mode, userId, competencies, skillsProfileId, skills, newFiles, reviewStatus, onSuccess) => 
		dispatch(saveCompetencies(mode, userId, competencies, skillsProfileId, skills, newFiles, reviewStatus, ownProps.mode === "secondary", onSuccess)),
	updateUserAssessment: (assessmentComplete) => dispatch(updateUserAssessment(assessmentComplete)),
	showWarning: message => dispatch(showWarningNotification(message)),
	showYesNoDialog: (title, message, onOk, onCancel) => dispatch(showModal("SIMPLE", { title, message, onOk, onCancel })),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamCompetencies);
