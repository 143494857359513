import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import { Tooltip } from '@mui/material';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CompareIcon from '@mui/icons-material/Compare';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import { truncateString } from '../../utils/utils';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	resourceCard: {
		'& .card-body': {
			'& .label': {
				backgroundColor: theme.palette.primary.main
			},
			'& .academic': {
				'& svg': {
					color: theme.palette.secondary.main
				}
			}
		},
		'&.picker': {
			'&.selected': {
				borderColor: theme.palette.custom.selection
			},
			'&:hover': {
				borderColor: theme.palette.custom.selection
			}
		}
	}
});

const iconSwitch = {
	Video: OndemandVideoIcon,
	Article: DescriptionIcon,
	"Case Study": AssignmentIcon,
	Template: CompareIcon
};

const ResourceCard = React.memo(({ resource: r, isCommunity, classes }) => 
	<Card className={`resource-card ${classes.resourceCard}`} ribbon={r.isAcademic && "Academic"} ribbonStatus="success">
		<Link to={`/${isCommunity ? "my-community/" : ""}resources/${r.resourceId}`} className="h3 name">
			{iconSwitch[r.type] ? React.createElement(iconSwitch[r.type]) : ""}
			{r.name}
		</Link>
		{r.academicId &&
			<span className="h6 academic">
				<Tooltip title="Academic Author"><VerifiedUserIcon /></Tooltip>
				{r.academicName}
			</span>
		}
		{r.channel && <span className="label">{r.channel}</span>}
		<div className="body">{truncateString(r.description, 300)}</div>
		<div className="stats">
			{r.attachments && r.attachments.length > 0 && 
				<span className="label attachments">{r.attachments.length} Attachment{r.attachments.length > 1 ? "s" : ""}</span>
			}
			{r.links && r.links.length > 0 &&
				<span className="label links">{r.links.length} Link{r.links.length > 1 ? "s" : ""}</span>
			}
			{r.videos && r.videos.length > 0 &&
				<span className="label videos">{r.videos.length} Video{r.videos.length > 1 ? "s" : ""}</span>
			}
		</div>
		<div className="tags">
			{map(r.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
	</Card>
);

ResourceCard.propTypes = {
	resource: PropTypes.shape({
		resourceId: PropTypes.number,
		type: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		links: PropTypes.array,
		videos: PropTypes.array,
		tenantId: PropTypes.number,
		createDate: PropTypes.string,
		createdBy: PropTypes.string,
		updateDate: PropTypes.string,
		updatedBy: PropTypes.string,
		attachments: PropTypes.array,
		tags: PropTypes.array,
		isAcademic: PropTypes.bool
	}).isRequired,
	isCommunity: PropTypes.bool
};

ResourceCard.defaultProps = {
	isCommunity: false
};

export default withStyles(ResourceCard, styles);