import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveTenant, createNewTenant, isLoading } from '../../store/tenants';
import PageLayout from '../PageLayout';
import EditTenant from './EditTenant';

const AddTenantController = props => {
	React.useEffect(() => { props.createNewTenant() }, []);

	return <PageLayout title="Add new tenant" pageContent={
		<EditTenant 
			tenant={props.tenant} 
			loading={props.loading} 
			saveResult={props.saveResult} 
			onSave={props.saveTenant} 
		/>
	} breadcrumbs={[{ label: "Tenants", path: "/admin/tenants" }, "Add tenant"]} />;
};

AddTenantController.propTypes = { 
	tenant: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	tenant: state.tenants.tenant,
	saveResult: state.tenants.saveResult,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	createNewTenant: () => dispatch(createNewTenant()),
	saveTenant: (tenant, logoFile, backgroundImageFile) => dispatch(saveTenant(tenant, logoFile, backgroundImageFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTenantController);