import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import EditExpert from './EditExpert';
import { isLoading, saveExpert } from '../../store/experts';

const CreateExpertController = props => 
	<PageLayout title="Create Expert" pageContent={
		<EditExpert saveExpert={props.saveExpert} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Experts", path: `/experts` }, "Create Expert"]} />;

CreateExpertController.propTypes = {
	saveExpert: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveExpert: (expert, imageFile) => dispatch(saveExpert(expert, imageFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateExpertController);