import React from 'react';
import { connect } from 'react-redux';
import { withForm } from '../../utils/forms';
import Form from '../widgets/Form';
import Button from '../widgets/Button';
import { 
	Radio, 
    RadioGroup,
	FormControl,
    FormControlLabel,
	FormLabel,
	FormHelperText
} from '@mui/material';
import { getQueryParameterByName } from '../../utils/utils';
import { get, post } from '../../utils/ajax';

const form = {
	initValues: props => {
		return { 
		};
	},
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "worksInAPSCAcademy",
			label: "Do you work in the APS Academy?",
			required: true,
			type: "custom",
			widget: props => 
				<FormControl fullWidth={true} error={props.error} style={{
					textAlign: 'left',
					...props.style
				}}>
					<FormLabel>Do you work in the APS Academy?</FormLabel>
					<RadioGroup id="resource-type" row value={props.value} onChange={e => props.onChange(e.target.value)} >
						<FormControlLabel value="Yes" control={<Radio />} label="Yes" />
						<FormControlLabel value="No" control={<Radio />} label="No" />
					</RadioGroup>
					{props.helpText && <FormHelperText style={{ color: props.error ? props.errorColour : "" }}>{props.helpText}</FormHelperText>}
				</FormControl>
		},
		{
			name: "positionId",
			label: "What best describes your role (pick one)?",
			type: "autoComplete",
			required: props => true,
			loadItems: {
				route: (props, values) => `positions/by-organisation/${props.apsc_organisationId}`,
				mapItem: p => ({ label: p.title, value: p.positionId })
			}
		},

	]
};

const APS_Registration_Confirmation = props => {
	const { fields, values } = props;
    const [loading, setLoading] = React.useState(false);
    const userId = getQueryParameterByName('token');
    const [data, setData] = React.useState(null);
    const [complete, setComplete] = React.useState(false);

    React.useEffect(() => {
        if (userId) {
            setLoading(true);
            get({
                url: `/api/token/${userId}/aps_confirm_registration`,
                onSuccess: result => {
                    setLoading(false);
                    setData(result);
                },
                onError: error => setLoading(false)

            });
        }
    }, []);

	if (complete) {
		return (
			<div className="registration-box">
				<span className="h3">Confirm Registration</span>
				<p>Thank you. Your answers have been recorded.</p>
			</div>
		);
	}

	return (
		<div className="registration-box">
			<span className="h3">Confirm Registration</span>
            {data && <p>Hi {data.fullName}, thank you for completing your skills assessment. Prior to finalization of the process, we would like to confirm your  registration details, which we have currently recorded as follows:</p>}
            {data && <p>First Name: {data.firstName}</p>}
            {data && <p>Last Name: {data.lastName}</p>}
			<Form 
				onSubmit={() => {
					if (props.validateFields()) {
						post({
							url: `/api/questionnaire/${userId}/aps_confirm_registration`,
							data: {
								token: userId,
								positionId: values.positionId,
								worksInAPSCAcademy: values.worksInAPSCAcademy === "Yes"
							},
							onSuccess: result => {
								setLoading(false);
								setComplete(true);
							},
							onError: error => {
								setLoading(false);
								console.log(error);
							}
						});
					} 
				}}
				style={{ textAlign: "center" }}
			>
				{fields.positionId()}
				{fields.worksInAPSCAcademy()}
				<Button type="submit" size="large" loading={loading} color="secondary">Confirm</Button>
			</Form>
            <p>Thank you for your participation in this assessment.</p>
		</div>
	);
};

APS_Registration_Confirmation.propTypes = { 
};

const mapStateToProps = (state, ownProps) => ({
	apsc_organisationId: state.settings.apsc_organisationId
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(APS_Registration_Confirmation));
