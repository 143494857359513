import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { getGroups, isLoading } from '../../../store/reports';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import DataTable from '../../widgets/dataTable/DataTable';
import SearchField from '../../widgets/SearchField';
import ButtonLink from '../../widgets/ButtonLink';
import Card from '../../widgets/Card';
import PageLayout from '../../PageLayout';

const ManageGroups = props => {
	const [search, setSearch] = React.useState("");

	React.useEffect(props.getGroups, []);

	return <PageLayout title="Manage Report Groups" pageContent={
		<Card title="Search groups" loading={props.loading}>
			{/* Search Groups */}
			<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Groups Datatable */}
			<DataTable data={props.groups} search={search} tableFooter={<ButtonLink to="/admin/report-groups/add">Add group</ButtonLink>}>
				<DataTableColumn name="name" label="Name" renderer={group => 
					<Link to={`/admin/report-groups/${group.groupId}`} style={{ color: props.primaryColour }}>{group.name}</Link>
				} />
				<DataTableColumn name="description" label="Description" />
				<DataTableColumn 
					name="organisationName" 
					label="Organisation" 
					canFilter
					filterType="AutoComplete"
					loadItems={{
						route: "organisations", 
						mapItem: o => ({ label: o.name, value: o.organisationId })
					}}
				/>
				<DataTableColumn 
					label="Reports" 
					canFilter
					filterType="AutoComplete"
					loadItems={{
						route: "reports", 
						mapItem: r => ({ label: r.name, value: r.reportId })
					}} 
					filter={(group, f) => group.reports.some(r => r.reportId === f.value)}
					renderer={group => group.reports.map(r => r.name).join(", ")}
				/>
			</DataTable>
		</Card>
	} breadcrumbs={["Manage report groups"]} />;
};

ManageGroups.propTypes = { 
	groups: PropTypes.array.isRequired,
	getGroups: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	groups: state.reports.groups,
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getGroups: () => dispatch(getGroups())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageGroups);