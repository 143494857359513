import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getMenus, isLoading, updateAllMenus } from '../../../store/menu';
import { menuIcons } from '../../../utils/utils';
import DataTable from '../../widgets/dataTable/DataTable';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import SearchField from '../../widgets/SearchField';
import ButtonLink from '../../widgets/ButtonLink';
import Card from '../../widgets/Card';
import PageLayout from '../../PageLayout';
import TextField from '../../widgets/TextField';
import Button from '../../widgets/Button';
import Checkbox from '../../widgets/Checkbox';
import { showWarningNotification } from '../../../store/notifications';

const ManageMenus = props => {
	const [search, setSearch] = React.useState("");
	const [menus, setMenus] = React.useState([]);
	const [dirty, setDirty] = React.useState(false);

	React.useEffect(props.getMenus, []);

	React.useEffect(() => setMenus(props.menus), [props.menus.length]);

	const checkDirty = () => {
		if (!dirty) {
			setDirty(true);
			props.showSaveNotification();
		}
	}

	const setSequence = (menuId, value) => {
		const copy = [...menus];			
		copy[menus.findIndex(m => m.menuId === menuId)].displaySequence = value;
		copy.sort((m1, m2) => m1.displaySequence - m2.displaySequence);

		setMenus(copy);
		checkDirty();
	}

	const setDisabled = (menuId, value) => {
		const copy = [...menus];
		copy[menus.findIndex(m => m.menuId === menuId)].disabled = value;

		setMenus(copy);
		checkDirty();
	}

	const saveChanges = () => props.updateMenus(menus, () => setDirty(false));

	return (
        <PageLayout title="Manage Menus" pageContent={
            <Card title="Search menus" loading={props.loading}>
                {/* Search Menus */}
                <SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} style={{ paddingBottom: 20 }} />
                {/* Menus Datatable */}
                <DataTable 
                    data={menus} 
                    search={search} 
                    disableScroll
                    tableFooter={<ButtonLink to="/admin/menu/create">Add new menu</ButtonLink>} 
                    tableRightFooter={<Button warning={dirty} loading={props.loading} onClick={saveChanges}>Save changes</Button>}
                    rowHeight={57}
                >
                    <DataTableColumn width={70} renderer={data =>
                        <div>
                            {data.menuId !== menus[menus.length - 1].menuId && 
                                <IconButton
                                    onClick={() => setSequence(data.menuId, Math.max(data.displaySequence + 10, 0))}
                                    size="large">
                                    <ArrowDownwardIcon />
                                </IconButton>
                            }
                            {data.menuId !== menus[0].menuId &&
                                <IconButton
                                    style={{ marginLeft: data.menuId === menus[menus.length - 1].menuId ? 0 : 10 }}
                                    onClick={() => setSequence(data.menuId, data.displaySequence - 10)}
                                    size="large">
                                    <ArrowUpwardIcon />
                                </IconButton>
                            }
                        </div>
                    } />
                    <DataTableColumn name="menuName" label="Name" renderer={data => 
                        <Link to={`/admin/menu/${data.menuId}`} style={{ color: props.primaryColour }}>
                            <span>{data.menuName}</span>
                        </Link>
                    } />
                    <DataTableColumn name="displaySequence" label="Display Seq." width={50} renderer={data => 
                        <TextField type="number" value={data.displaySequence} onChange={e => setSequence(data.menuId, Number(e.target.value))} />
                    } />
                    <DataTableColumn label="Items" align="center" renderer={data => data.items.length} />
                    <DataTableColumn name="description" label="Description" renderer={data => data.description || <em>No description provided...</em>} />
                    <DataTableColumn name="iconRef" label="Icon" align="center" renderer={data => menuIcons[data.iconRef] ? React.createElement(menuIcons[data.iconRef]) : ""} />
                    <DataTableColumn name="disabled" label="Disabled" align="center" renderer={data => 
                        <Checkbox checked={data.disabled} fullWidth={false} onChange={e => setDisabled(data.menuId, e.target.checked)} />} 
                    />
                </DataTable>
            </Card>
        } breadcrumbs={["Manage menus"]} />
    );
};

ManageMenus.propTypes = { 
	getMenus: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	updateMenus: PropTypes.func.isRequired,
	showSaveNotification: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	menus: state.menu.menus,
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getMenus: () => dispatch(getMenus()),
	updateMenus: (menus, onSuccess) => dispatch(updateAllMenus(menus, onSuccess)),
	showSaveNotification: () => dispatch(showWarningNotification("You have unsaved changes."))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageMenus);