import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { parseYoutube } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import Gallery from '../widgets/Gallery';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import FavouriteButton from './FavouriteButton';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import CreateDiscussionDialog from '../widgets/CreateDiscussionDialog';
import ViewDiscussionDialog from '../widgets/ViewDiscussionDialog';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	resourceDetails: {
		'& .academic': {
			'& svg': {
				color: theme.palette.secondary.main
			}
		}
	}
});

const downloadToolAttachment = attachment =>
	window.open(`/Tool/DownloadAttachment?toolId=${attachment.toolId}&toolAttachmentId=${attachment.toolAttachmentId}`, "_self");

const downloadResourceAttachment = attachment =>
	window.open(`/Resource/DownloadAttachment?resourceId=${attachment.resourceId}&attachmentId=${attachment.attachmentId}`, "_self");

const ViewTool = props => {
	const [showCreateDialog, setShowCreateDialog] = React.useState(false);
	const [showViewDialog, setShowViewDialog] = React.useState(false);

	const { tool, primaryColour } = props;
	const links = tool.links ? [...tool.links] : [],
		videos = tool.videos ? [...tool.videos] : [],
		attachments = tool.attachments ? [...tool.attachments] : [];

	if (tool.linkedResources) {
		tool.linkedResources.forEach(r => {
			const parsedLinks = r.links ? JSON.parse(r.links) : [];
			if (parsedLinks.length > 0) links.push(...parsedLinks);
	
			const parsedVideos = r.videos ? JSON.parse(r.videos) : [];
			if (parsedVideos.length > 0) videos.push(...parsedVideos);

			attachments.push(...r.attachments);
		});
	}

	return props.loading || isEmpty(tool) ? <CircularProgress size={24} style={{ marginLeft: 10 }} /> : (
		<Card className={`tool-details ${props.classes.toolDetails}`}>
			<Grid container direction="row-reverse" alignContent="flex-end" spacing={3} style={{ marginBottom: 10 }}>
				<Grid item xs="auto" style={{ marginBottom: 10 }}>
					<Button color="secondary" onClick={() => setShowCreateDialog(true)}>Create Discussion</Button>
					{tool.hasDiscussion && <Button onClick={() => setShowViewDialog(true)} style={{ marginLeft: 10 }}>View Discussion</Button>}
				</Grid>
				<Grid item xs={12} md>
					<h2 style={{ marginBottom: 0 }}>
						{tool.name}
						<FavouriteButton toolId={tool.toolId} isFavourite={tool.isFavourite} />
					</h2>
					{tool.academicId &&
						<span className="h6 academic">
							<Tooltip title="Academic Author"><VerifiedUserIcon /></Tooltip>
							{tool.academicName}
						</span>
					}
					<Tag label={tool.channel} colour={primaryColour} />
				</Grid>
			</Grid>
			{tool.description &&
				<section>
					<span className="h4">Description</span>
					{tool.description}
				</section>
			}
			{tool.method &&
				<section>
					<span className="h4">Method</span>
					<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: tool.method }} />
				</section>
			}
			{tool.tags.length > 0 &&
				<section>
					<span className="h4">Tags</span>
					{map(tool.tags, (t, i) => <Tag key={i} label={t.name} />)}
				</section>
			}
			{tool.images.length > 0 &&
				<section>
					<Gallery heading="Images" images={map(tool.images, image => ({ src: image.location }))} />
				</section>
			}
			{tool.references &&
				<section>
					<span className="h4">References</span>
					<div style={{ whiteSpace: "pre-line" }}>{tool.references}</div>
				</section>
			}
			{links.length > 0 &&
				<section>
					<span className="h4">Links</span>
					<ul>
						{map(links, (l, i) => 
							<li key={i}>{l.label} &mdash; <a href={`https://${l.to}`} target="_blank" rel="noopener noreferrer">{l.to}</a></li>
						)}
					</ul>
				</section>
			}
			{videos.length > 0 && 
				<section>
					<span className="h4">Videos</span>
					<div className="youtube-embed">
						<div className="frame-wrapper">
							<iframe title="Videos" src={`//www.youtube.com/embed/${parseYoutube(videos[0])}?playlist=${map(videos, v => parseYoutube(v)).join(",")}`} frameBorder="" allowFullScreen />
						</div>
					</div>
				</section>
			}
			{attachments.length > 0 &&
				<section>
					<span className="h4">Attachments</span>
					<ul className="list-unstyled">
						{attachments.map((a, i) =>
							<li key={i} className="media">
								<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
								<div className="media-body">
									<h5 className="mt-0 mb-1">{a.name}</h5>
									<p>{a.description}</p>
									<Button variant="outlined" onClick={() => a.resourceId ? downloadResourceAttachment(a) : downloadToolAttachment(a)}>
										Download Now
									</Button>
								</div>
							</li>
						)}
					</ul>
				</section>
			}
			{props.canEdit && 
				<ButtonLink to={`/${props.isCommunity ? "my-community/" : ""}tools/edit/${tool.toolId}`}>
					Edit tool
				</ButtonLink>
			}
			<CreateDiscussionDialog
				open={showCreateDialog}
				channels={props.channels}
				discussion={{ topicType: "Tool", topicId: tool.toolId }}
				disableTopic
				onClose={() => setShowCreateDialog(false)} 
				onSubmit={discussion => {
					setShowCreateDialog(false);
					props.saveDiscussion(discussion);
				}}
			/>
			<ViewDiscussionDialog 
				open={showViewDialog} 
				onClose={() => setShowViewDialog(false)}
				isCommunity={props.isCommunity} 
				search={{ toolId: tool.toolId }}
			/>
		</Card>
	);
}

ViewTool.propTypes = {
	tool: PropTypes.shape({
		toolId: PropTypes.number,
		organisationId: PropTypes.number,
		organisationName: PropTypes.string,
		country: PropTypes.string,
		organisationType: PropTypes.string,
		organisationLogo: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		method: PropTypes.string,
		references: PropTypes.string,
		visibility: PropTypes.string,
		status: PropTypes.string,
		videos: PropTypes.array,
		images: PropTypes.array,
		files: PropTypes.array,
		channelId: PropTypes.number,
		channel: PropTypes.string,
		tags: PropTypes.array
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	isCommunity: PropTypes.bool.isRequired,
	channels: PropTypes.array.isRequired,
	saveDiscussion: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired
};

ViewTool.defaultProps = {
	canEdit: false
};

export default withStyles(ViewTool, styles);