import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { getFrameworks, isLoading } from '../../store/frameworks';
import { canViewFrameworks } from '../../store/security';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import SearchField from '../widgets/SearchField';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import PageLayout from '../PageLayout';

const FrameworksController = props => {
	const [search, setSearch] = React.useState("");

	React.useEffect(props.getFrameworks, []);

	return <PageLayout title="Frameworks" pageContent={
		<Card title="Search frameworks" loading={props.loading}>
			{/* Search frameworks */}
			<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Datatable */}
			<DataTable data={props.frameworks} search={search} enableExport tableFooter={<ButtonLink to="/admin/frameworks/import">Import framework</ButtonLink>}>
				<DataTableColumn name="frameworkCode" label="Code" renderer={f =>
					<Link to={`/admin/frameworks/${f.frameworkId}`} style={{ color: props.primaryColour }}>
						<span>{f.frameworkCode}</span>
					</Link>
				} />
				<DataTableColumn name="setAsDefault" label="Set as Default" dataType="bool" />
				<DataTableColumn name="description" label="Description" renderer={f => f.description || <em>There is no description provided...</em>} />
			</DataTable>
		</Card>
	} breadcrumbs={["Frameworks"]} />;
};

FrameworksController.propTypes = { 
	frameworks: PropTypes.array.isRequired,
	canView: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	frameworks: state.frameworks.frameworks,
	canView: canViewFrameworks(state),
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getFrameworks: () => dispatch(getFrameworks())
});

export default connect(mapStateToProps, mapDispatchToProps)(FrameworksController);