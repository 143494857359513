import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions, 
	Grid,
	RadioGroup,
	Radio,
	FormControlLabel,
	InputLabel
} from '@mui/material';
import { getResources } from '../../store/resources';
import Button from './Button';
import Card from './Card';
import map from 'lodash/map';
import some from 'lodash/some';
import Tag from './Tag';
import TextField from './TextField';
import FileUploader from './FileUploader';
import uniqueId from 'lodash/uniqueId';
import forEach from 'lodash/forEach';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	resourceCard: {
		'& .card-body': {
			'& .label': {
				backgroundColor: theme.palette.primary.main
			},
			'& .academic': {
				'& svg': {
					color: theme.palette.secondary.main
				}
			}
		},
		'&.picker': {
			'&.selected': {
				borderColor: theme.palette.custom.selection
			},
			'&:hover': {
				borderColor: theme.palette.custom.selection
			}
		}
	}
});

const ResourceCard = ({ resource, onClick, classes }) =>
	<Card 
		className={`resource-card ${classes.resourceCard}`}
		onClick={onClick} 
	>
		<div className="header">{resource.name}</div>
		<div className="description">{resource.description}</div>
		<div className="tags">
			{map(resource.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
	</Card>;

const LDResourcePicker = React.memo(props => {
	const [resourceType, setResourceType] = React.useState(props.resourceTypes[0]);
	const [description, setDescription] = React.useState("");
	const [link, setLink] = React.useState("");
	const [file, setFile] = React.useState("");
	const [fileName, setFileName] = React.useState("");
	const [video, setVideo] = React.useState("");

	let showSaved = false;
	let showFile = false;
	let showLink = false;
	let showVideo = false;
	forEach(props.resourceTypes, t => {
		switch (t) {
			case 'Saved': showSaved = true; break;
			case 'File': showFile = true; break;
			case 'Link': showLink = true; break;
			case 'Video': showVideo = true; break;
			default:
		}
	});
		
	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
            TransitionProps={{
                onEnter: props.getResources
            }}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				{props.resourceTypes.length > 1 && 
					<React.Fragment>
					<InputLabel htmlFor="resource-type">Resource Type</InputLabel>
					<Grid item xs={12} md>
						<RadioGroup id="resource-type" row value={resourceType} onChange={e => setResourceType(e.target.value)} >
							{showSaved && <FormControlLabel value="Saved" control={<Radio />} label="Saved Resource" />}
							{showFile && <FormControlLabel value="File" control={<Radio />} label="File" />}
							{showLink && <FormControlLabel value="Link" control={<Radio />} label="Link" />}
							{showVideo && <FormControlLabel value="Video" control={<Radio />} label="Video" />}
						</RadioGroup>
					</Grid>
					</React.Fragment>
				}
				{resourceType === "Saved" &&
					<Grid container spacing={3}>
						{map(props.resources, (t, i) =>
							<Grid key={i} item xs={4}>
								<ResourceCard 
									resource={t} 
									onClick={() => props.onOk(t, null)}
								/>
							</Grid>
						)}
					</Grid>
				}
				{resourceType === "Link" &&
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TextField 
								startAdornment="https://" 
								label="Link" 
								value={link} 
								onChange={e => setLink(e.target.value)} 
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField 
								label="Description" 
								value={description} 
								onChange={e => setDescription(e.target.value)} 
								variant="outlined"
							/>
						</Grid>
					</Grid>
				}
				{resourceType === "File" &&
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<FileUploader 
								file={file}
								value={file ? fileName : ""} 
								onChange={e => {
									setFile(e.target.files[0]);
									setFileName(e.target.files[0].name);
								}} 
								onChangeName={name => {
									setFileName(name);
								}}
								canEditName={true}
								placeholder={props.filePlaceholderText} 
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField 
								label="Description" 
								value={description} 
								onChange={e => setDescription(e.target.value)} 
								variant="outlined"
							/>
						</Grid>
					</Grid>
				}
				{resourceType === "Video" &&
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TextField 
								label="Video" 
								value={video} 
								onChange={e => setVideo(e.target.value)} 
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField 
								label="Description" 
								value={description} 
								onChange={e => setDescription(e.target.value)} 
								variant="outlined"
							/>
						</Grid>
					</Grid>
				}
			</DialogContent>
			<DialogActions>
				{some(["Link", "File", "Video"], t => t === resourceType) &&
					<Button onClick={() => {
						let value = null;
						let newFile = null;
						let attachment = null;
						switch (resourceType) {
							case "Link":
								value = link;
								break;
							case "File":
								const clientId = uniqueId("attachment-");
								value = fileName;
								newFile = {
									clientId: clientId,
									file: file
								};
								attachment = {
									clientId: clientId,
									attachmentType: "document",
									fileName: fileName,
									name: fileName,
									description: description,
									sizeBytes: file.size
								};
								break;
							case "Video":
								value = video;
								break;
							default:
						}
						const resource = {
							resourceType: resourceType,
							resourceValue: value,
							resourceId: null,
							description: description,
							attachment: attachment
						};
						props.onOk(resource, newFile);
					}}>Ok</Button>
				}
				<Button onClick={props.onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
    );
});

LDResourcePicker.propTypes = {
	open: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	resourceTypes: PropTypes.array,
	title: PropTypes.string,
	filePlaceholderText: PropTypes.string
};

LDResourcePicker.defaultProps = {
	open: false,
	onOk: () => {},
	onClose: () => {},
	resourceTypes: ['Saved', 'Link', 'File', 'Video'],
	title: "Select Resource",
	filePlaceholderText: "Select resource file"
};

const mapStateToProps = state => ({
	resources: state.resources.resources
});

const mapDispatchToProps = dispatch => ({
	getResources: () => dispatch(getResources())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(LDResourcePicker, styles));
