import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const FormButtons = (props) => 
	<div className="form-buttons">
		<Button 
			loading={props.loading} 
			success={props.success} 
			className="save-button"
			onClick={props.onSave}
		>
			Save
		</Button>
		{props.showDelete &&
			<Button
                loading={props.loading}
                className="delete-button"
                onClick={props.onDelete}
                type="submit">
				Delete
			</Button>
		}
		{props.additionalButtons}
	</div>
;

FormButtons.propTypes = {
	onSave: PropTypes.func,
	onDelete: PropTypes.func,
	showDelete: PropTypes.bool,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	additionalButtons: PropTypes.object
};

FormButtons.defaultProps = {
	onSave: null,
	onDelete: null,
	showDelete: false,
	loading: false,
	success: false,
	additionalButtons: undefined
};

export default FormButtons;
