import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { showModal } from '../../store/modal';
import { canEditTeams } from '../../store/security';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import TeamCard from './TeamCard';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { get, post } from '../../utils/ajax';
import { changeLocation } from '../../store/location';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { canManageTeamsAcrossTenant } from '../../store/security';
import reduce from 'lodash/reduce';
import orderBy from 'lodash/orderBy';

const TeamsController = props => {
	const [state, setState] = React.useState({ 
        teams: [], 
        isLoading: true 
    });

	React.useEffect(() => {
		get({
			url: props.canManageTeamsAcrossTenant ? '/api/teams/by-tenant' : '/api/teams/by-organisation',
			onSuccess: data => setState({ teams: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
    }, []);
    
    const deleteTeam = (team) => {
		props.confirmDelete(() => {
			setState(prevState => ({ ...prevState, isLoading: true }));
			
			post({
				url: `/api/teams/${team.teamId}/delete`,
				onSuccess: data => {
					setState({ isLoading: false, teams: filter(state.teams, t => t.teamId !== team.teamId) });

					if (data.success) {
						props.showSuccessNotification(data.message);
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	};

	const orderedByOrg = orderBy(state.teams, ['organisationName']);
	const sortedTeams = reduce(orderedByOrg, (groups, t) => {
		(groups[t.organisationName] || (groups[t.organisationName] = [])).push(t);
		return groups;
	}, {});

    return (
		<PageLayout 
			title="Teams" 
			pageContent={
                <React.Fragment>
                    <div style={{ float: "right" }}>
                        <ButtonLink to="/teams/create">Create Team</ButtonLink>
                    </div>
                    {state.teams.length === 0 && <p>No teams found...</p>}
					{props.canManageTeamsAcrossTenant &&
						map(Object.keys(sortedTeams), org =>
							<React.Fragment key={org}>
								<h2 style={{ marginTop: 20 }}>{org}</h2>
								<Grid container spacing={3}>
									{map(sortedTeams[org], (t, i) => 
										<TeamCard 
										key={i} 
										team={t} 
										canEdit={props.canEdit} 
										deleteTeam={deleteTeam}
									/>
									)}
								</Grid>
							</React.Fragment>
						)
					}
					{!props.canManageTeamsAcrossTenant && 
						<Grid container spacing={3}>
							{map(state.teams, (t, i) => 
								<TeamCard 
									key={i} 
									team={t} 
									canEdit={props.canEdit} 
									deleteTeam={deleteTeam}
								/>
							)}
						</Grid>
					}
                </React.Fragment>
			} 
			breadcrumbs={["Teams"]} 
		/>
	);
};

TeamsController.propTypes = { 
	canEdit: PropTypes.bool.isRequired,
	canManageTeamsAcrossTenant: PropTypes.bool.isRequired
};

TeamsController.defaultProps = {
};

const mapStateToProps = (state, ownProps) => ({
	canEdit: canEditTeams(state),
	canManageTeamsAcrossTenant: canManageTeamsAcrossTenant(state)
});

const mapDispatchToProps = dispatch => ({
    showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	goToViewUrl: (teamId) => dispatch(changeLocation(`/teams/${teamId}`)),
	confirmDelete: (onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this team?",
		onOk: onOk
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsController);
