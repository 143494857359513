import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import Tag from '../widgets/Tag';
// import map from 'lodash/map';

const CloCourseRow = React.memo(({ course: c }) => 
	<div className="clo-course-row" style={{ marginTop: '10px' }}>
		{React.createElement(!c.skipPreview && c.website ? "a" : Link, { className: "header", href: c.website, to: `/clo-courses/${c.clO_CourseId}` },
			(c.image || c.providerLogo) && <img src={c.image || c.providerLogo} alt="Course" style={{ maxHeight: '64px' }} />,
			<div>
				<span className="h4">{c.title}</span>
				<span className="h5">{c.providerName}</span>
			</div>
		)}
		{/* {c.contactName &&
			<div style={{ marginTop: 10 }}>
				<strong>{c.contactName}</strong><br />
				<span>{c.contactEmail}</span>
			</div>
		}
		<div className="description quill-display-block" dangerouslySetInnerHTML={{ __html: c.description }} />
		<div className="tags">
			{c.tags.map(t => <Tag key={t.tagId} label={t.name} />)}
			{map(c.channels, c => <Tag key={c.channelId} label={c.name} />)}
		</div> */}
	</div>
);

CloCourseRow.propTypes = {
	cloCourse: PropTypes.object.isRequired
};

export default CloCourseRow;