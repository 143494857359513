import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewDiscussion from './ViewDiscussion';
import { get, post } from '../../utils/ajax';
import filter from 'lodash/filter';
import { showModal } from '../../store/modal';

const ViewDiscussionController = props => {
	const { collaborationId, collaborationDiscussionId } = props.match.params;
	const [loading, setLoading] = React.useState(false);
	const [commentsLoading, setCommentsLoading] = React.useState(false);
	const [discussion, setDiscussion] = React.useState(null);

	const getComments = () => {
		setCommentsLoading(true);
		get({
			url: `/api/collaborations/discussion/${collaborationDiscussionId}`,
			onSuccess: data => {
				setDiscussion({
					...discussion,
					comments: data
				});
				setCommentsLoading(false);
			},
			onError: () => setCommentsLoading(false)
		});
	};
	const addComment = (message) => {
		setCommentsLoading(true);
		post({
			url: `/api/collaborations/${discussion.collaborationDiscussionId}/add-comment`,
			data: {
				message
			},
			onSuccess: data => {
				setDiscussion({
					...discussion,
					comments: data.object
				});			
				setCommentsLoading(false);
			},
			onError: () => setCommentsLoading(false)
		});
	};
	const deleteComment = (comment) => {
		setCommentsLoading(true);
		post({
			url: `/api/collaborations/remove-comment/${comment.collaborationDiscussionCommentId}`,
			onSuccess: data => {
				setDiscussion({
					...discussion,
					comments: filter(discussion.comments, c => c.collaborationDiscussionCommentId !== data.objectId)
				});
				setCommentsLoading(false);
			},
			onError: () => setCommentsLoading(false)
		});
	};

	React.useEffect(() => {
		setLoading(true);
		get({
			url: `/api/collaborations/discussion/${collaborationDiscussionId}`,
			onSuccess: data => {
				setLoading(false);
				setDiscussion(data);
			},
			onError: () => setLoading(false)
		});
	}, [collaborationDiscussionId]);

	return (
		<PageLayout 
			title="View Discussion" 
			loading={loading} 
			pageContent={
				discussion && 
				<ViewDiscussion 
					discussion={discussion} 
					currentUserId={props.currentUserId}
					canEdit={props.canEdit}
					canRemoveChat={props.canRemoveChat}
					loading={loading}
					commentsLoading={commentsLoading}
					getComments={getComments}
					addComment={addComment}
					deleteComment={deleteComment}
				/>
			} 
			breadcrumbs={[
				{ label: "Collaboration", path: `/collaborations/${collaborationId}` }, 
				"View Discussion"
			]} />
	);
};

ViewDiscussionController.propTypes = {
	isCommunity: PropTypes.bool,
	currentUserId: PropTypes.string.isRequired,
	canEdit: PropTypes.bool.isRequired,
};

ViewDiscussionController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	canEdit: true,
	canRemoveChat: true //state.context.isAdministrator
});

const mapDispathToProps = dispatch => ({
	promptDeleteComment: onOk => dispatch(showModal("CONFIRM_DELETE", {
		title: "Remove?",
		message: "Are you sure you want to remove this comment?",
		onOk: () => onOk()
	}))
});


export default connect(mapStateToProps, mapDispathToProps)(ViewDiscussionController);
