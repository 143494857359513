import { createFetchAction, createFormPostAction, createPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	channels: [],
	subscribed: [],
	allChannels: false
};

const CLEAR_CHANNELS = "CLEAR_CHANNELS";
const GET_CHANNELS_REQUEST = "GET_CHANNELS_REQUEST";
const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
const GET_CHANNELS_FAILURE = "GET_CHANNELS_FAILURE";
const SAVE_CHANNELS_REQUEST = "SAVE_CHANNELS_REQUEST";
const SAVE_CHANNELS_SUCCESS = "SAVE_CHANNELS_SUCCESS";
const SAVE_CHANNELS_FAILURE = "SAVE_CHANNELS_FAILURE";
const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
const GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";
const SAVE_SUBSCRIPTIONS_REQUEST = "SAVE_SUBSCRIPTIONS_REQUEST";
const SAVE_SUBSCRIPTIONS_SUCCESS = "SAVE_SUBSCRIPTIONS_SUCCESS";
const SAVE_SUBSCRIPTIONS_FAILURE = "SAVE_SUBSCRIPTIONS_FAILURE";
const UNSUBSCRIBE_REQUEST = "UNSUBSCRIBE_REQUEST";
const UNSUBSCRIBE_SUCCESS = "UNSUBSCRIBE_SUCCESS";
const UNSUBSCRIBE_FAILURE = "UNSUBSCRIBE_FAILURE";

export const isLoading = createLoadingSelector(["GET_CHANNELS", "SAVE_CHANNELS", "GET_SUBSCRIPTIONS", "SAVE_SUBSCRIPTIONS", "UNSUBSCRIBE"]);

export const clearChannels = () => ({ type: CLEAR_CHANNELS });

export const getChannels = () =>
	createFetchAction({
		url: "/api/channels",
		startAction: GET_CHANNELS_REQUEST,
		onError: error => [getChannelsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getChannelsSuccess(data)
	});

export const getChannelsByTenant = (tenantId) =>
	createFetchAction({
		url: `/api/channels/by-tenant/${tenantId}`,
		startAction: GET_CHANNELS_REQUEST,
		onError: error => [getChannelsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getChannelsSuccess(data)
	});

export const getChannelsSuccess = data => ({ type: GET_CHANNELS_SUCCESS, payload: { data } });
export const getChannelsFailure = error => ({ type: GET_CHANNELS_FAILURE, payload: { error } });

export const saveChannels = channels => 
	createFormPostAction({
		url: "/api/channels",
		data: channels,
		startAction: SAVE_CHANNELS_REQUEST,
		onError: error => [saveChannelsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveChannelsSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/channels")];
			} else {
				return [saveChannelsSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveChannelsSuccess = data => ({ type: SAVE_CHANNELS_SUCCESS, data });
export const saveChannelsFailure = error => ({ type: SAVE_CHANNELS_FAILURE, error });

export const getSubscriptions = () =>
	createFetchAction({
		url: "/api/channels/subscriptions",
		startAction: GET_SUBSCRIPTIONS_REQUEST,
		onError: error => [getSubscriptionsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getSubscriptionsSuccess(data)
	});

export const getSubscriptionsSuccess = data => ({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: { data } });
export const getSubscriptionsFailure = error => ({ type: GET_SUBSCRIPTIONS_FAILURE, payload: { error } });

export const saveSubscriptions = (channels, allChannels) =>
	createFormPostAction({
		url: "/api/channels/subscriptions",
		data: { channels, allChannels },
		startAction: SAVE_SUBSCRIPTIONS_REQUEST,
		onError: error => [saveSubscriptionsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveSubscriptionsSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [saveSubscriptionsFailure(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveSubscriptionsSuccess = data => ({ type: SAVE_SUBSCRIPTIONS_SUCCESS, data });
export const saveSubscriptionsFailure = error => ({ type: SAVE_SUBSCRIPTIONS_FAILURE, error });

export const unsubscribe = (channelId, onSuccess) => 
	createPostAction({
		url: `/api/channels/${channelId}/unsubscribe`,
		startAction: UNSUBSCRIBE_REQUEST,
		onError: error => [unsubscribeFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess(data);
				return [unsubscribeSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [unsubscribeFailure(data), showErrorNotification(data.message)];
			}
		}
	});

export const unsubscribeSuccess = data => ({ type: UNSUBSCRIBE_SUCCESS, data });
export const unsubscribeFailure = error => ({ type: UNSUBSCRIBE_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CHANNELS:
			return { ...state, channels: [] };
		case GET_CHANNELS_REQUEST:
			return { ...state, channels: [], isLoading: true };
		case GET_CHANNELS_SUCCESS:
			return { ...state, channels: action.payload.data, isLoading: false };
		case SAVE_CHANNELS_REQUEST:
		case SAVE_SUBSCRIPTIONS_REQUEST:
		case UNSUBSCRIBE_REQUEST:
			return { ...state, isLoading: true };
		case SAVE_CHANNELS_SUCCESS:
		case SAVE_SUBSCRIPTIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				subscribed: action.data.object.channels,
				allChannels: action.data.object.allChannels,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case GET_SUBSCRIPTIONS_REQUEST:
			return { ...state, subscribed: [], allChannels: false, isLoading: true };
		case GET_SUBSCRIPTIONS_SUCCESS:
			return { 
				...state, 
				subscribed: action.payload.data.channels, 
				allChannels: action.payload.data.allChannels, 
				isLoading: false 
			};
		case UNSUBSCRIBE_SUCCESS:
			return {
				...state,
				isLoading: false,
				subscribed: state.subscribed.filter(c => c !== action.data.objectId),
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		default:
			return state;
	}
};