import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';
import DOMPurify from 'dompurify';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [],
	pageNum: null,
	pageSize: null,
	total: null,
	totalPages: null
};

const initialState = {
	saveResult: emptySaveResult,
	insights: [],
	insight: {}
};

const CLEAR_INSIGHTS = "CLEAR_INSIGHTS";
const GET_INSIGHTS_REQUEST = "GET_INSIGHTS_REQUEST";
const GET_INSIGHTS_SUCCESS = "GET_INSIGHTS_SUCCESS";
const GET_INSIGHTS_FAILURE = "GET_INSIGHTS_FAILURE";
const SEARCH_INSIGHTS_REQUEST = "SEARCH_INSIGHTS_REQUEST";
const SEARCH_INSIGHTS_SUCCESS = "SEARCH_INSIGHTS_SUCCESS";
const SEARCH_INSIGHTS_FAILURE = "SEARCH_INSIGHTS_FAILURE";
const GET_INSIGHT_REQUEST = "GET_INSIGHT_REQUEST";
const GET_INSIGHT_SUCCESS = "GET_INSIGHT_SUCCESS";
const GET_INSIGHT_FAILURE = "GET_INSIGHT_FAILURE";
const SAVE_INSIGHT_REQUEST = "SAVE_INSIGHT_REQUEST";
const SAVE_INSIGHT_SUCCESS = "SAVE_INSIGHT_SUCCESS";
const SAVE_INSIGHT_FAILURE = "SAVE_INSIGHT_FAILURE";
const DELETE_INSIGHT_REQUEST = "DELETE_INSIGHT_REQUEST";
const DELETE_INSIGHT_SUCCESS = "DELETE_INSIGHT_SUCCESS";
const DELETE_INSIGHT_FAILURE = "DELETE_INSIGHT_FAILURE";
const PUBLISH_INSIGHT_REQUEST = "PUBLISH_INSIGHT_REQUEST";
const PUBLISH_INSIGHT_SUCCESS = "PUBLISH_INSIGHT_SUCCESS";
const PUBLISH_INSIGHT_FAILURE = "PUBLISH_INSIGHT_FAILURE";
const UNPUBLISH_INSIGHT_REQUEST = "UNPUBLISH_INSIGHT_REQUEST";
const UNPUBLISH_INSIGHT_SUCCESS = "UNPUBLISH_INSIGHT_SUCCESS";
const UNPUBLISH_INSIGHT_FAILURE = "UNPUBLISH_INSIGHT_FAILURE";

export const isLoading = createLoadingSelector(["GET_INSIGHTS", "SEARCH_INSIGHTS", "GET_INSIGHT", "SAVE_INSIGHT"]);

export const clearInsights = () => ({ type: CLEAR_INSIGHTS });

export const getInsights = (pageNum, pageSize) =>
	createFetchAction({
		url: `/api/insights?pageNum=${pageNum}&pageSize=${pageSize}`,
		startAction: GET_INSIGHTS_REQUEST,
		onError: error => [getInsightsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getInsightsSuccess(data)
	});

export const getSubscribedInsights = limit => 
	createFetchAction({
		url: `/api/insights/subscribed?limit=${limit}`,
		startAction: GET_INSIGHTS_REQUEST,
		onError: error => [getInsightsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getInsightsSuccess(data)
	});

export const getInsightsSuccess = data => ({ type: GET_INSIGHTS_SUCCESS, payload: { data } });
export const getInsightsFailure = error => ({ type: GET_INSIGHTS_FAILURE, payload: { error } });

export const searchInsights = (args, pageNum, pageSize) => 
	createFetchAction({
		url: `/api/insights/search?PageNum=${pageNum}&PageSize=${pageSize}&${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_INSIGHTS_REQUEST,
		onError: error => [searchInsightsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchInsightsSuccess(data)
	});

export const searchInsightsSuccess = data => ({ type: SEARCH_INSIGHTS_SUCCESS, payload: { data } });
export const searchInsightsFailure = error => ({ type: SEARCH_INSIGHTS_FAILURE, payload: { error } });

export const getInsight = insightId =>
	createFetchAction({
		url: `/api/insights/${insightId}`,
		startAction: GET_INSIGHT_REQUEST,
		onError: error => [getInsightFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getInsightSuccess(data)
	});

export const getInsightSuccess = data => ({ type: GET_INSIGHT_SUCCESS, payload: { data } });
export const getInsightFailure = error => ({ type: GET_INSIGHT_FAILURE, payload: { error } });

export const saveInsight = (insight, imageFile, newFiles, isCommunity) => {
	const formData = new FormData();
	formData.append("Insight", encodeURIComponent(JSON.stringify({
		...insight,
		links: JSON.stringify(insight.links),
		videos: JSON.stringify(insight.videos)
	})));

	if (imageFile) formData.append("ImageFile", imageFile);
	newFiles.forEach(f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/insights",
		data: formData,
		startAction: SAVE_INSIGHT_REQUEST,
		onError: error => [saveInsightFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveInsightSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}insights/${data.object.insightId}`)];
			} else {
				return [saveInsightSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveInsightSuccess = data => ({ type: SAVE_INSIGHT_SUCCESS, data });
export const saveInsightFailure = error => ({ type: SAVE_INSIGHT_FAILURE, error });

export const deleteInsight = (insightId, isCommunity) =>
	createPostAction({
		url: `/api/insights/${insightId}/delete`,
		startAction: DELETE_INSIGHT_REQUEST,
		onError: error => [deleteInsightFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteInsightSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}insights`)];
			} else {
				return [deleteInsightSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteInsightSuccess = data => ({ type: DELETE_INSIGHT_SUCCESS, data });
export const deleteInsightFailure = error => ({ type: DELETE_INSIGHT_FAILURE, error });


export const publishInsight = insightId =>
	createPostAction({
		url: `/api/insights/${insightId}/publish`,
		startAction: PUBLISH_INSIGHT_REQUEST,
		startActionData: { insightId },
		onError: error => [publishInsightFailure(error, insightId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [publishInsightSuccess(data, insightId), showSuccessNotification(data.message)];
			} else {
				return [publishInsightSuccess(data, insightId), showErrorNotification(data.message)];
			}
		}
	});
export const publishInsightSuccess = (data, insightId) => ({ type: PUBLISH_INSIGHT_SUCCESS, data, insightId });
export const publishInsightFailure = (error, insightId) => ({ type: PUBLISH_INSIGHT_FAILURE, error, insightId });

export const unpublishInsight = insightId =>
	createPostAction({
		url: `/api/insights/${insightId}/unpublish`,
		startAction: UNPUBLISH_INSIGHT_REQUEST,
		startActionData: { insightId },
		onError: error => [unpublishInsightFailure(error, insightId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [unpublishInsightSuccess(data, insightId), showSuccessNotification(data.message)];
			} else {
				return [unpublishInsightSuccess(data, insightId), showErrorNotification(data.message)];
			}
		}
	});
export const unpublishInsightSuccess = (data, insightId) => ({ type: UNPUBLISH_INSIGHT_SUCCESS, data, insightId });
export const unpublishInsightFailure = (error, insightId) => ({ type: UNPUBLISH_INSIGHT_FAILURE, error, insightId });

const formatInsight = insight => ({ 
	...insight, 
	links: insight.links ? JSON.parse(insight.links) : [],
	videos: insight.videos ? JSON.parse(insight.videos) : [],
	content: DOMPurify.sanitize(insight.content)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_INSIGHTS:
			return { ...state, insights: [] };
		case GET_INSIGHTS_REQUEST:
			return { ...state, insights: [], isLoading: true };
		case SEARCH_INSIGHTS_REQUEST:
			return { ...state, isLoading: true };
		case GET_INSIGHTS_SUCCESS:
		case SEARCH_INSIGHTS_SUCCESS:
			return { 
				...state, 
				insights: map(action.payload.data.data, formatInsight), 
				isLoading: false,
				pageNum: action.payload.data.pageNum,
				pageSize: action.payload.data.pageSize,
				total: action.payload.data.total,
				totalPages: action.payload.data.totalPages
			};
		case GET_INSIGHT_REQUEST:
			return { ...state, insight: {}, isLoading: true };
		case GET_INSIGHT_SUCCESS:
			return { ...state, isLoading: true, insight: formatInsight(action.payload.data) };
		case SAVE_INSIGHT_REQUEST:
		case DELETE_INSIGHT_REQUEST:
		case PUBLISH_INSIGHT_REQUEST:
		case UNPUBLISH_INSIGHT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_INSIGHT_SUCCESS:
		case PUBLISH_INSIGHT_SUCCESS:
		case UNPUBLISH_INSIGHT_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { insight: formatInsight(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_INSIGHT_SUCCESS:
			return { ...state, insights: state.insights.filter(a => a.insightId !== action.data.objectId) };
		default:
			return state;
	}
};
