import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import EndorseButton from '../widgets/EndorseButton';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import unescape from 'lodash/unescape';
import RowIcons from './RowIcons';
import { prettifyDate, stripHtml } from '../../utils/utils';
import Avatar from './Avatar';
import ProblemStatus from '../widgets/ProblemStatus';

const ProblemRow = React.memo(props => {
	const { problem, extended, isAdmin, isCommunity } = props;

	return (
		<div className="d-flex flex-row comment-row">
			<div className="p-2 text-center">
				<Tooltip title={problem.authorName || ''} placement="right">
					<div>
						<Avatar src={problem.authorAvatar} fullName={problem.authorName} length={50} style={{ margin: "auto" }} />
					</div>
				</Tooltip>
				<div className="vote-actions d-flex flex-column align-self-baseline">
					<div className="vote-count text-center">
						{isAdmin && !isCommunity ? 
							<Tooltip title="View stats" placement="right">
								<Link to={`/problems/stats/${problem.problemId}?tab=endorsements`} style={{ color: "inherit" }}>
									{problem.votes}
								</Link>
							</Tooltip> 
						: problem.votes}
					</div>
					<EndorseButton problemId={problem.problemId} hasVoted={problem.hasVoted} isClosed={problem.status === "closed"} />
				</div>
			</div>
			<div className="comment-text w-100">
				<Link className="h5 text-themecolor" to={`/${isCommunity ? "my-community/" : ""}problems/${problem.problemId}`}>{problem.title}</Link>
				<p className="m-b-5 truncate-text">{unescape(stripHtml(problem.description))}</p>
				<div className="comment-footer">
					<div className="pull-right">
						<span className="label label-rounded label-light-info" style={{ float: !extended && "right" }}>
							{isAdmin && !isCommunity ?
								<Tooltip title="View stats" placement="left">
									<Link to={`/problems/stats/${problem.problemId}?tab=views`}>{problem.views} views</Link>
								</Tooltip>
							: `${problem.views} views`}
						</span>
						{!extended && <br />}
						<span className="text-muted m-l-5">{prettifyDate(problem.updateDate)}</span>
					</div>
					<ProblemStatus status={problem.status} />
					{map(problem.tags, t => <Tag key={t.problemTagId} label={t.name} />)}
					{extended && isAdmin && !isCommunity &&
						<RowIcons 
							type={props.single} 
							editLink={`/problems/edit/${problem.problemId}`}
							statsLink={`/problems/stats/${problem.problemId}`}
							deleteFunc={() => props.deleteProblem(problem.problemId)}
						/>
					}
				</div>
			</div>
		</div>
	);
});

ProblemRow.propTypes = {
	problem: PropTypes.shape({
		problemId: PropTypes.number,
		authorName: PropTypes.string,
		authorAvatar: PropTypes.string,
		votes: PropTypes.number,
		title: PropTypes.string,
		description: PropTypes.string,
		status: PropTypes.string,
		tags: PropTypes.array,
		createDate: PropTypes.string
	}).isRequired,
	extended: PropTypes.bool,
	isAdmin: PropTypes.bool,
	deleteProblem: PropTypes.func,
	single: PropTypes.string,
	isCommunity: PropTypes.bool
};

ProblemRow.defaultProps = {
	extended: false,
	isAdmin: false,
	deleteProblem: () => null,
	single: "problem",
	isCommunity: false
};

export default ProblemRow;