import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getReport, saveReport, deleteReport, isLoading } from '../../../store/reports';
import { showModal } from '../../../store/modal';
import PageLayout from '../../PageLayout';
import EditReport from './EditReport';

const EditReportController = props => {
	React.useEffect(() => props.getReport(props.match.params.reportId), [props.match.params.reportId]);

	return <PageLayout title="Edit report" pageContent={
		<EditReport
			report={props.report}
			saveReport={props.saveReport}
			deleteReport={props.deleteReport}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Manage reports", path: "/admin/reports" }, "Edit report"]} />;
};

EditReportController.propTypes = {
	report: PropTypes.object.isRequired,
	getReport: PropTypes.func.isRequired,
	saveReport: PropTypes.func.isRequired,
	deleteReport: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	report: state.reports.report,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getReport: reportId => dispatch(getReport(reportId)),
	saveReport: (report, imageFile) => dispatch(saveReport(report, imageFile)),
	deleteReport: reportId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this report?",
		onOk: () => dispatch(deleteReport(reportId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReportController);