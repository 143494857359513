import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ButtonGroup from '../widgets/ButtonGroup';
import OfferBox from '../widgets/BusinessChallengeBox';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

const AllBusinessChallenges = props => {
	const { offers, status, isAdmin, loading } = props,
		displayCount = sumBy(offers, c => status === "all" || c.status === status);

	return (
		<Grid container spacing={3} style={{ marginTop: '0px' }}>
			<Grid container item>
				<Grid item xs={6} sm>
					<h3 style={{ marginBottom: 0 }}>
						Current Research Match
						{loading && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
					</h3>
					<p className="text-muted m-b-0">Browse our list of research match to connect and collaborate.</p>
				</Grid>
				<Grid item xs={12} sm="auto" style={{ textAlign: 'right' }}>
					<ButtonGroup 
						style={{
							display: 'inline-block',
							marginLeft: '5px'
						}}
						value={status} 
						options={["open", "closed", "all"]} 
						icon={CheckIcon} 
						onChange={option => props.setStatus(option)} 
					/>
				</Grid>
			</Grid>
			{(loading || displayCount === 0) &&
				<Grid item xs={12}>
					{loading ? <CircularProgress size={24} /> : `No ${status} match found...`}
				</Grid>
			}
			{map(offers, (c, i) => 
				<OfferBox key={i} offer={c} isAdmin={isAdmin} show={status === "all" || c.status === status} />
			)}
		</Grid>
	);
};

AllBusinessChallenges.propTypes = {
	offers: PropTypes.arrayOf(PropTypes.object).isRequired,
	status: PropTypes.string.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	setStatus: PropTypes.func
};

AllBusinessChallenges.defaultProps = {
	setStatus: null
};

export default AllBusinessChallenges;
