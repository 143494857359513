import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../widgets/Card';
import map from 'lodash/map';
import ProblemRow from '../widgets/ProblemRow';
import ButtonLink from '../widgets/ButtonLink';
import { getPopularProblems, isLoading } from '../../store/problems';

const PopularProblems = props => {
	const { problems, loading, plural } = props;

	React.useEffect(props.getPopularProblems, []);

	return (	
		<Card 
			title={`Trending ${plural}`}
			subtitle={`See what people are talking about and 'like' their ${plural} by giving them a thumbs up`}
			loading={loading}
			shrink
		>
			<div className="comment-widgets">
				{!loading && problems.length < 6 && map(problems, (problem, index) => 
					<ProblemRow key={index} problem={problem} isAdmin={props.isAdmin} />
				)}
			</div>
			<ButtonLink to="problems" style={{ float: "right" }} variant="outlined">View more offers</ButtonLink>
		</Card>
	);
};

PopularProblems.propTypes = {
	problems: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	plural: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	problems: state.problems.problems,
	loading: isLoading(state),
	isAdmin: state.context.isAdministrator,
	plural: state.settings.plural
});

const mapDispatchToProps = dispatch => ({
	getPopularProblems: () => dispatch(getPopularProblems())
});

export default connect(mapStateToProps, mapDispatchToProps)(PopularProblems);
