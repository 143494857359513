
const initialState = {
	isSideMenuOpen: false,
	isSideMenuCollapsed: true,
	isFullMenuOpen: false,
	showUserTour: false,
	showUserOrganisationDialog: false,
	showBusinessProfileDialog: false
};

const COLLAPSE_SIDE_PANEL = 'COLLAPSE_SIDE_PANEL';
export const collapseSideMenu = collapse => ({ type: COLLAPSE_SIDE_PANEL, collapse });

const SHOW_FULL_MENU = 'SHOW_FULL_MENU';
export const showFullMenu = open => ({ type: SHOW_FULL_MENU, open });

const TOGGLE_SIDE_PANEL = 'TOGGLE_SIDE_PANEL';
export const toggleSideMenu = isOpen => ({ type: TOGGLE_SIDE_PANEL, isOpen });

const TOGGLE_USER_TOUR = 'TOGGLE_USER_TOUR';
export const toggleUserTour = show => ({ type: TOGGLE_USER_TOUR, show });

const TOGGLE_USER_ORGANISATION_DIALOG = 'TOGGLE_USER_ORGANISATION_DIALOG';
export const toggleUserOrganisationDialog = show => ({ type: TOGGLE_USER_ORGANISATION_DIALOG, show });

const TOGGLE_BUSINESS_PROFILE_DIALOG = 'TOGGLE_BUSINESS_PROFILE_DIALOG';
export const toggleBusinessProfileDialog = show => ({ type: TOGGLE_BUSINESS_PROFILE_DIALOG, show });

export default (originalState, action) => {
	const state = originalState || initialState;

	switch (action.type) {
		case COLLAPSE_SIDE_PANEL:
			return { ...state, isSideMenuCollapsed: action.collapse };
		case SHOW_FULL_MENU:
			return { ...state, isFullMenuOpen: action.open };
		case TOGGLE_SIDE_PANEL:
			return { ...state, isSideMenuOpen: action.isOpen };
		case TOGGLE_USER_TOUR:
			return  { ...state, showUserTour: action.show };
		case TOGGLE_USER_ORGANISATION_DIALOG:
			return  { ...state, showUserOrganisationDialog: action.show };
		case TOGGLE_BUSINESS_PROFILE_DIALOG:
			return  { ...state, showBusinessProfileDialog: action.show };
		default:
			return state
	}
};
