import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpIconOutlined from '@mui/icons-material/ThumbUpOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { voteProblem, removeVoteFromProblem } from '../../store/problems';
import CircularProgress from '@mui/material/CircularProgress';

const EndorseButton = React.memo(props => {
	const { hasVoted, isVoting, isClosed, single, primaryColour } = props;
	let tooltip = `Endorse this ${single}`;

	if (hasVoted) {
		tooltip = "Take back your endorsement";
	} else if (isClosed) {
		tooltip = `Endorsing is disabled for this ${single}`;
	}

	const button = (
		<IconButton
            disabled={isVoting}
            onClick={props.endorse}
            style={{ color: isClosed ? "grey" : primaryColour }}
            aria-label={tooltip}
            size="large">
			{isVoting ? <CircularProgress size={24} /> : hasVoted ? <ThumbUpIcon /> : <ThumbUpIconOutlined />}
		</IconButton>
	);

	return isVoting ? button : (
		<Tooltip title={tooltip} disableFocusListener>
			<span>{button}</span>
		</Tooltip>
	);
});

EndorseButton.propTypes = {
	problemId: PropTypes.number.isRequired,
	hasVoted: PropTypes.bool.isRequired,
	isVoting: PropTypes.bool.isRequired,
	endorse: PropTypes.func.isRequired,
	isClosed: PropTypes.bool,
	single: PropTypes.string.isRequired,
	primaryColour: PropTypes.string.isRequired
};

EndorseButton.defaultProps = {
	isClosed: false
};

const mapStateToProps = (state, ownProps) => ({
	isVoting: state.problems.isVoting[ownProps.problemId] || false,
	single: state.settings.single,
	primaryColour: state.theme.primaryColour
});	

const mapDispatchToProps = (dispatch, ownProps) => ({
	endorse: () => dispatch(ownProps.hasVoted ? removeVoteFromProblem(ownProps.problemId) : voteProblem(ownProps.problemId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EndorseButton);
