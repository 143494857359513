import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';
import { toDate } from '../utils/utils';
import map from 'lodash/map';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	offer: {}, 
	offers: [],
	application: {},
	applications: []
};

const newOffer = { offerId: 0 };

const CLEAR_OFFER = "CLEAR_OFFER";
const CLEAR_OFFERS = "CLEAR_OFFERS";
const GET_OFFER_REQUEST = "GET_OFFER_REQUEST";
const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
const GET_OFFER_FAILURE = "GET_OFFER_FAILURE";
const GET_OFFERS_REQUEST = "GET_OFFERS_REQUEST";
const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS";
const GET_OFFERS_FAILURE = "GET_OFFERS_FAILURE";
const SAVE_OFFER_REQUEST = "SAVE_OFFER_REQUEST";
const SAVE_OFFER_SUCCESS = "SAVE_OFFER_SUCCESS";
const SAVE_OFFER_FAILURE = "SAVE_OFFER_FAILURE";
const DELETE_OFFER_REQUEST = "DELETE_OFFER_REQUEST";
const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
const DELETE_OFFER_FAILURE = "DELETE_OFFER_FAILURE";
const SEARCH_OFFERS_REQUEST = "SEARCH_OFFERS_REQUEST";
const SEARCH_OFFERS_SUCCESS = "SEARCH_OFFERS_SUCCESS";
const SEARCH_OFFERS_FAILURE = "SEARCH_OFFERS_FAILURE";
const GET_APPLICATION_REQUEST = "GET_APPLICATION_REQUEST";
const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";
const GET_APPLICATION_FAILURE = "GET_APPLICATION_FAILURE";
const GET_APPLICATIONS_REQUEST = "GET_APPLICATIONS_REQUEST";
const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
const GET_APPLICATIONS_FAILURE = "GET_APPLICATIONS_FAILURE";
const SAVE_APPLICATION_REQUEST = "SAVE_APPLICATION_REQUEST";
const SAVE_APPLICATION_SUCCESS = "SAVE_APPLICATION_SUCCESS";
const SAVE_APPLICATION_FAILURE = "SAVE_APPLICATION_FAILURE";
const SUBMIT_APPLICATION_REQUEST = "SUBMIT_APPLICATION_REQUEST";
const SUBMIT_APPLICATION_SUCCESS = "SUBMIT_APPLICATION_SUCCESS";
const SUBMIT_APPLICATION_FAILURE = "SUBMIT_APPLICATION_FAILURE";
const SET_APPLICATION_STATUS_REQUEST = "SET_APPLICATION_STATUS_REQUEST";
const SET_APPLICATION_STATUS_SUCCESS = "SET_APPLICATION_STATUS_SUCCESS";
const SET_APPLICATION_STATUS_FAILURE = "SET_APPLICATION_STATUS_FAILURE";

export const isLoading = createLoadingSelector(["GET_OFFER", "GET_OFFERS", "SAVE_OFFER", "DELETE_OFFER", "SEARCH_OFFERS"]);

export const clearOffers = () => ({ type: CLEAR_OFFERS });
export const clearOffer = () => ({ type: CLEAR_OFFER });

export const getOffer = offerId =>
	createFetchAction({
		url: `/api/offers/${offerId}`,
		startAction: GET_OFFER_REQUEST,
		onError: error => [getOfferFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getOfferSuccess({
			...data,
			startDate: toDate(data.startDate),
			finishDate: data.finishDate ? toDate(data.finishDate) : ""
		})
	});

export const getOfferSuccess = data => ({ type: GET_OFFER_SUCCESS, payload: { data } });
export const getOfferFailure = error => ({ type: GET_OFFER_FAILURE, payload: { error } });

export const getOffers = () =>
	createFetchAction({
		url: "/api/offers",
		startAction: GET_OFFERS_REQUEST,
		onError: error => [getOffersFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getOffersSuccess(data)
	});

export const getLatestOffers = () =>
	createFetchAction({
		url: "/api/offers/latest",
		startAction: GET_OFFERS_REQUEST,
		onError: error => [getOffersFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getOffersSuccess(data)
	});

export const getOffersSuccess = data => ({ type: GET_OFFERS_SUCCESS, payload: { data } });
export const getOffersFailure = error => ({ type: GET_OFFERS_FAILURE, payload: { error } });

export const saveOffer = (offer, files, returnRoute) => {
	const formData = new FormData();
	formData.append("Offer", encodeURIComponent(JSON.stringify(offer)));
	if (files.coverImage) formData.append("ImageFile", files.coverImage);
	files.attachments.forEach(f => formData.append("NewFiles", f));
	files.additionalChallengeDetails.forEach(f => formData.append("AdditionalChallengeDetailsFiles", f));
	files.nonDisclosureAgreement.forEach(f => formData.append("NonDisclosureAgreementFiles", f));

	return createFormPostAction({
		url: "/api/offers",
		data: formData,
		startAction: SAVE_OFFER_REQUEST,
		onError: error => [saveOfferFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveOfferSuccess(data), showSuccessNotification(data.message), changeLocation(`/${returnRoute || 'offers'}/${data.object.offerId}`)];
			} else {
				return [saveOfferSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveOfferSuccess = data => ({ type: SAVE_OFFER_SUCCESS, data });
export const saveOfferFailure = error => ({ type: SAVE_OFFER_FAILURE, error });

export const deleteOffer = (offerId, returnRoute) =>
	createPostAction({
		url: `/api/offers/${offerId}/delete`,
		startAction: DELETE_OFFER_REQUEST,
		onError: error => [deleteOfferFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteOfferSuccess(data), showSuccessNotification(data.message), changeLocation(`/${returnRoute || 'offers'}`)];
			} else {
				return [deleteOfferSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteOfferSuccess = data => ({ type: DELETE_OFFER_SUCCESS, data });
export const deleteOfferFailure = error => ({ type: DELETE_OFFER_FAILURE, error });

export const searchOffers = (args) =>
	createFetchAction({
		url: `/api/offers/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_OFFERS_REQUEST,
		onError: error => [searchOffersFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchOffersSuccess(data)
	});

export const searchOffersSuccess = data => ({ type: SEARCH_OFFERS_SUCCESS, payload: { data } });
export const searchOffersFailure = error => ({ type: SEARCH_OFFERS_FAILURE, payload: { error } });

//////////////////
// Applications //
//////////////////

export const getApplication = applicationId =>
	createFetchAction({
		url: `/api/offers/applications/${applicationId}`,
		startAction: GET_APPLICATION_REQUEST,
		onError: error => [getApplicationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getApplicationSuccess(data)
	});

export const getApplicationByOffer = offerId => 
	createFetchAction({
		url: `/api/offers/applications/by-offer/${offerId}`,
		startAction: GET_APPLICATION_REQUEST,
		onError: error => [getApplicationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getApplicationSuccess(data)
	});

export const getApplicationSuccess = data => ({ type: GET_APPLICATION_SUCCESS, payload: { data } });
export const getApplicationFailure = error => ({ type: GET_APPLICATION_FAILURE, payload: { error } });

export const getAllApplications = () =>
	createFetchAction({
		url: `/api/offers/all-applications`,
		startAction: GET_APPLICATIONS_REQUEST,
		onError: error => [getApplicationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getApplicationsSuccess(data)
	});

export const getApplications = offerId =>
	createFetchAction({
		url: `/api/offers/${offerId}/applications`,
		startAction: GET_APPLICATIONS_REQUEST,
		onError: error => [getApplicationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getApplicationsSuccess(data)
	});

export const getApplicationsSuccess = data => ({ type: GET_APPLICATIONS_SUCCESS, payload: { data } });
export const getApplicationsFailure = error => ({ type: GET_APPLICATIONS_FAILURE, payload: { error } });

export const saveApplication = (application, newFiles) => {
	const formData = new FormData();
	formData.append("Application", JSON.stringify(application));

	newFiles.attachments.forEach(f => formData.append("NewFiles", f));
	newFiles.additionalChallengeDetails.forEach(f => formData.append("AdditionalChallengeDetailsFiles", f));
	newFiles.nonDisclosureAgreement.forEach(f => formData.append("NonDisclosureAgreementFiles", f));

	return createFormPostAction({
		url: `/api/offers/save`,
		data: formData,
		startAction: SAVE_APPLICATION_REQUEST,
		onError: error => [saveApplicationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => [
			saveApplicationSuccess(data), 
			(data && data.success) ? showSuccessNotification(data.message) : showErrorNotification(data.message)
		]
	});
};

export const saveApplicationSuccess = data => ({ type: SAVE_APPLICATION_SUCCESS, data });
export const saveApplicationFailure = error => ({ type: SAVE_APPLICATION_FAILURE, error });

export const submitApplication = (application, newFiles, returnRoute) => {
	const formData = new FormData();
	formData.append("Application", JSON.stringify(application));

	newFiles.attachments.forEach(f => formData.append("NewFiles", f));
	newFiles.additionalChallengeDetails.forEach(f => formData.append("AdditionalChallengeDetailsFiles", f));
	newFiles.nonDisclosureAgreement.forEach(f => formData.append("NonDisclosureAgreementFiles", f));

	return createFormPostAction({
		url: `/api/offers/apply`,
		data: formData,
		startAction: SUBMIT_APPLICATION_REQUEST,
		onError: error => [submitApplicationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [submitApplicationSuccess(data), showSuccessNotification(data.message), changeLocation(`/${returnRoute || 'offers'}/${application.offerId}`)];
			} else {
				return [submitApplicationSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const submitApplicationSuccess = data => ({ type: SUBMIT_APPLICATION_SUCCESS, data });
export const submitApplicationFailure = error => ({ type: SUBMIT_APPLICATION_FAILURE, error });

export const setApplicationStatus = (applicationId, status, returnRoute) =>
	createFormPostAction({
		url: `/api/offers/applications/set-status`,
		data: { applicationId, status },
		startAction: SET_APPLICATION_STATUS_REQUEST,
		onError: error => [setApplicationStatusFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [setApplicationStatusSuccess(data), showSuccessNotification(data.message), changeLocation(`/${returnRoute || 'offers'}/applications`)];
			} else {
				return [setApplicationStatusSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const setApplicationStatusSuccess = data => ({ type: SET_APPLICATION_STATUS_SUCCESS, data });
export const setApplicationStatusFailure = error => ({ type: SET_APPLICATION_STATUS_FAILURE, error });

const GET_USER_APPLICATIONS_REQUEST = "GET_USER_APPLICATIONS_REQUEST";
const GET_USER_APPLICATIONS_SUCCESS = "GET_USER_APPLICATIONS_SUCCESS";
const GET_USER_APPLICATIONS_FAILURE = "GET_USER_APPLICATIONS_FAILURE";

export const getUserApplications = userId =>
	createFetchAction({
		url: `/api/offers/${userId}/applications`,
		startAction: GET_USER_APPLICATIONS_REQUEST,
		onError: error => [getUserApplicationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserApplicationsSuccess(map(data, a => ({
			...a,
			applicationDate: toDate(a.applicationDate)
		})))
	});

export const getUserApplicationsSuccess = data => ({ type: GET_USER_APPLICATIONS_SUCCESS, payload: { data } });
export const getUserApplicationsFailure = error => ({ type: GET_USER_APPLICATIONS_FAILURE, payload: { error } });

const sanitizeOffer = offer => ({
	...offer,
	description: DOMPurify.sanitize(offer.description),
	requirements: DOMPurify.sanitize(offer.requirements)
});

const sanitizeApplication = application => ({ ...application, solution: DOMPurify.sanitize(application.solution) });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_OFFERS:
			return { ...state, offers: [] };
		case CLEAR_OFFER:
			return { ...state, offer: {} };
		case GET_OFFERS_REQUEST:
		case SEARCH_OFFERS_REQUEST:
			return { ...state, offers: [], saveResult: emptySaveResult };
		case GET_OFFERS_SUCCESS:
		case SEARCH_OFFERS_SUCCESS:
			return { ...state, offers: map(action.payload.data, sanitizeOffer), isLoading: false };
		case GET_OFFER_REQUEST:
			return {
				...state,
				offer: { ...newOffer },
				isLoading: true,
				saveResult: emptySaveResult
			};
		case GET_OFFER_SUCCESS:
			return { ...state, offer: sanitizeOffer(action.payload.data), isLoading: false };
		case DELETE_OFFER_SUCCESS:
			return { ...state, offers: state.offers.filter(c => c.offerId !== action.data.objectId ) };
		case SAVE_OFFER_REQUEST:
		case SAVE_APPLICATION_REQUEST:
		case SAVE_OFFER_SUCCESS:
			return {
				...state,
				...(action.data.success && { offer: sanitizeOffer(action.data.object) }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case GET_APPLICATION_REQUEST:
			return { ...state, application: {}, saveResult: emptySaveResult };
		case GET_APPLICATION_SUCCESS:
			return { ...state, application: sanitizeApplication(action.payload.data), isLoading: false };
		case GET_APPLICATIONS_REQUEST:
		case GET_USER_APPLICATIONS_REQUEST:
			return { ...state, applications: [], saveResult: emptySaveResult };
		case GET_APPLICATIONS_SUCCESS:
		case GET_USER_APPLICATIONS_SUCCESS:
			return { ...state, applications: map(action.payload.data, sanitizeApplication), isLoading: false };
		case SAVE_APPLICATION_SUCCESS:
		case SUBMIT_APPLICATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case SET_APPLICATION_STATUS_SUCCESS:
			return { ...state, applications: state.applications.filter(a => a.applicationId !== action.data.objectId) };
		default:
			return state;
	}
};
