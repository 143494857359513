import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../widgets/Card';
import { isPopularTagsLoading, getPopularTags } from '../../store/dashboard';
import ReactEcharts from 'echarts-for-react';
import map from 'lodash/map';
import { withRouter } from 'react-router-dom';

const PopularTags = props => {
	React.useEffect(props.getPopularTags, []);

	return (
		<Card title="Trending tags" subtitle="The most popular tags are:" loading={props.loading} shrink>
			<ReactEcharts option={{
				tooltip: {
					trigger: "item",
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				color: props.colourPalette,
				calculable: true,
				series: [{
					name: "Trending tags",
					type: "pie",
					radius: [30, 110],
					center: ["50%", "50%"],
					roseType: "area",
					x: "50%",				// for funnel
					max: 40,				// for funnel
					sort: "ascending",		// for funnel
					data: map(props.popularTags, t => ({
						name: t.name,
						value: t.useCount
					}))
				}]
			}} onEvents={{
				"click": param => props.history.push(`/problems?tag=${encodeURIComponent(param.data.name)}`)
			}} />
		</Card>
	);
};

PopularTags.propTypes = {
	popularTags: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	colourPalette: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
	popularTags: state.dashboard.popularTags,
	loading: isPopularTagsLoading(state),
	colourPalette: state.theme.colourPalette
});

const mapDispatchToProps = dispatch => ({
	getPopularTags: () => dispatch(getPopularTags())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PopularTags));
