import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveRole, getFunctions, isLoading as isRolesLoading } from '../../../store/roles';
import { getMenus, isLoading as isMenusLoading } from '../../../store/menu';
import PageLayout from '../../PageLayout';
import EditRole from './EditRole';

const AddRoleController = props => {
	React.useEffect(props.onStartup, []);

	return <PageLayout title="Add Role" pageContent={
		<EditRole
			canSelectTenant={props.canSelectTenant}
			functions={props.functions}
			functionGroups={props.functionGroups}
			menus={props.menus}
			saveRole={props.saveRole} 
			saveResult={props.saveResult}
			loading={props.isLoading} 
			canEdit
			isNew
		/>
	} breadcrumbs={[{ label: "Manage roles",  path: "/admin/roles" }, "Add Role"]} />;
};

AddRoleController.propTypes = { 
	canSelectTenant: PropTypes.bool.isRequired,
	saveRole: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	onStartup: PropTypes.func.isRequired,
	functions: PropTypes.array.isRequired,
	functionGroups: PropTypes.array.isRequired,
	menus: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	canSelectTenant: state.context.isGlobalAdministrator,
	functions: state.roles.functions,
	functionGroups: state.roles.functionGroups,
	menus: state.menu.menus,
	saveResult: state.roles.saveResult,
	isLoading: isRolesLoading(state) || isMenusLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveRole: role => dispatch(saveRole(role)),
	onStartup: () => {
		dispatch(getFunctions());
		dispatch(getMenus());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleController);
