const initialState = {
	primaryColour: "#00224D",
	primaryColourText: "#fff",
	secondaryColour: "#D6E8FF",
	secondaryColourText: "#00224D",
	selectionColour: "#84c446",
	selectionColourText: "#00224D",
	successColour: "#35aa47",
	errorColour: "#d1332e",
	cancelColour: "#d84a38",
	warningColour: "#f0ad4e",
	infoColour: "#5bc0de",
	debugColour: "#aaa",
	tagColour: "#00897b",
	colourPalette: ["#89A54E", "#4572A7", "#80699B", "#3D96AE", "#DB843D", "#92A8CD", "#A47D7C", "#B5CA92"]
};

const UPDATE_THEME = "UPDATE_THEME";

export const updateTheme = (newTheme) => ({ type: UPDATE_THEME, newTheme });

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_THEME:
			return {
				...state,
				...action.newTheme
			};
		default:
			return state;
	}
};
