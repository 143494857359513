import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveActivity, isLoading } from '../../store/activities';
import { canSubmitActivities } from '../../store/security';
import { getQueryParameters } from '../../utils/utils';
import { revokeAccess } from '../../store/context';
import PageLayout from '../PageLayout';
import EditActivity from './EditActivity';

const EditActivityController = props => {
	const { fromLDCourse } = getQueryParameters(props.location.search),
		activity = { visibility: fromLDCourse === "true" ? "Private" : "Public" };
	
	React.useEffect(() => {
		if (!props.canView) props.revokeAccess();
	}, []);

	return <PageLayout title="Create activity" pageContent={
		<EditActivity activity={activity} saveActivity={props.saveActivity} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Activities", path: "/activities" }, "Create activity"]} />;
};	

EditActivityController.propTypes = {
	saveActivity: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canView: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.activities.saveResult,
	loading: isLoading(state),
	canView: canSubmitActivities(state)
});

const mapDispatchToProps = dispatch => ({
	saveActivity: (activity, newFiles) => dispatch(saveActivity(activity, newFiles)),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActivityController);
