import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import find from 'lodash/find';
import some from 'lodash/some';
import map from 'lodash/map';

const form = {
	initValues: props => props.department,
	onChange: (props, values, fieldName) => {
		if (fieldName === 'organisationId') {
			props.onOrganisationChanged(values.organisationId);
		}
	},
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
			helpText: "Name of business unit"
		},
		{
			name: "description",
			label: "Description",
			required: true,
			multiline: true,
			helpText: "Description of business unit",
			max: 400
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			disabled: props => !props.isNew,
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			onChange: values => {
				values.skillsProfileId = null;
				values.managerId = null;
				values.parentId = null;
			}
		},
		{
			name: "skillsProfileId",
			label: "Skills Profile",
			type: "profilePicker",
			organisationId: (props, values) => {
				return values.organisationId;
			} 
		},
		{
			name: "lockCompetencyUpdates",
			label: "Lock competency updates",
			type: "checkbox"
		},
		{
			name: "managerId",
			label: "Manager",
			type: "autoComplete",
			items: props => map(props.users, u => ({ ...u, label: u.fullName, value: u.userId }))
		},
		{
			name: "parentId",
			label: "Parent",
			type: "autoComplete",
			items: (props) => {
				if (!props.departments || props.departments.length === 0) return [];
				
				const items = [];

				const departmentIds = [];
				if (props.department && props.department.departmentId) {
					departmentIds.push(props.department.departmentId);
				}

				const isRepeating = (department) => {
					if (!department) return false;
					if (some(departmentIds, id => id === department.departmentId)) return true;
					departmentIds.push(department.departmentId);
					let parentDepartment = null;
					if (department.parentId) parentDepartment = find(props.departments, d2 => d2.departmentId === department.parentId);
					if (parentDepartment) {
						return isRepeating(parentDepartment);
					}

					return false;
				};

				for (var i = 0, ii = props.departments.length; i < ii; i++) {
					var d = props.departments[i];
                    items.push({ label: d.name, value: d.departmentId, isDisabled: isRepeating(d) });
				}
				return items;
			}
		}
	]
};

const EditDepartment = props => {
	const { department, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} business unit`} loading={loading} className="edit-department">
			<Form onSubmit={() => props.validateFields() && props.saveDepartment(props.values)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.name()}</Grid>
					{props.canChangeOrganisation &&
						<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					}
					<Grid item xs={12} sm={6}>{fields.managerId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.parentId()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12} sm={6}>{fields.skillsProfileId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.lockCompetencyUpdates()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} unit</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteDepartment(department.departmentId)}>
							Delete unit
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditDepartment.propTypes = { 
	department: PropTypes.object,
	saveDepartment: PropTypes.func.isRequired,
	deleteDepartment: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	users: PropTypes.array.isRequired,
	isNew: PropTypes.bool,
	departments: PropTypes.array.isRequired,
	canChangeOrganisation: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	onOrganisationChanged: PropTypes.func.isRequired
};

EditDepartment.defaultProps = {
	department: {},
	deleteDepartment: () => null,
	isNew: false
};

export default withForm(form)(EditDepartment);