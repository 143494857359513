import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import { getQueryParameters } from '../../utils/utils';
import Form from '../widgets/Form';

const form = {
	fields: [
		{
			name: "password",
			required: true,
			label: "New password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters",
			style: { margin: "10px 0 15px" },
			autoFocus: true
		},
		{
			name: "confirmPassword",
			required: true,
			label: "Confirm password",
			type: "password",
			validate: (value, values) => value === values.password ? "" : "Both passwords must match",
			style: { marginBottom: 15 }
		}
	]
};

const ConfirmPasswordReset = props => {
	const [loading, setLoading] = React.useState(false);

	const resetPassword = () => {
		const { userId, code } = getQueryParameters(props.location.search);

		setLoading(true);

		post({
			url: "/api/users/confirm-reset-password",
			data: { userId, code, newPassword: props.values.password },
			onSuccess: result => {
				setLoading(false);

				if (result.success) {
					props.showSuccessNotification(result.message);
					props.history.push("/login");
				} else {
					props.showErrorNotification(result.message);
				}
			},
			onError: error => {
				setLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	}

	return (
		<div className="reset-box">
			<h1 className="h3 m-t-40 m-b-0">Reset Password</h1>
			<small>Reset your password by filling out the fields below.</small>
			<Form onSubmit={resetPassword} style={{ textAlign: "center" }}>
				{props.fields.password()}
				{props.fields.confirmPassword()}
				<Button type="submit" size="large" loading={loading} color="secondary">Reset Password</Button>
			</Form>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(null, mapDispatchToProps)(withRouter(withForm(form)(ConfirmPasswordReset)));
