import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Form from '../widgets/Form';
import { getQueryParameters } from '../../utils/utils';
import lowerCase from 'lodash/lowerCase';
import { post } from '../../utils/ajax';
import { showErrorNotification } from '../../store/notifications';

const validEmail = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

const form = {
	initValues: props => {
		return { 
			organisationId: props.organisationId, 
			membershipLevelId: null
		};
	},
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "firstName",
			required: true,
			label: "First name",
			autoFocus: true,
		},
		{
			name: "lastName",
			required: true,
			label: "Last name",
			onBlur: (value, values, setValue) => {
				if (values.firstName && !values.username) {
					setValue("username", `${lowerCase(values.firstName)}.${lowerCase(values.lastName)}`);
				}
			}
		},
		{
			name: "email",
			required: true,
			label: "Email",
			validate: value => validEmail.test(value) ? "" : "Email must be a valid address",
			helpText: "Your company email address",
		},
		{
			name: "academicTitle",
			label: "Your Title",
			required: true
		},
		{
			name: "academicUrl",
			label: "Your organisations's web site URL",
			required: true
		},
		{
			name: "academicSchool",
			label: "Your Organisation",
			required: true
		},
		{
			name: "linkedInUrl",
			label: "LInkedIn URL",
			helpText: "Link to LinkedIn profile"
		},
		{
			name: "professionalSummary",
			label: "Provide a 1 page professional summary",
			type: "fileUploader"
		}
	]
};

const RequestAccess = props => {
	const { fields, values, formFiles } = props;
	const [loading, setLoading] = React.useState(false);
	const [result, setResult] = React.useState(null);

	const requestAccess = () => {
		const formData = new FormData();
		formData.append("Model", encodeURIComponent(JSON.stringify(values)));
		if (formFiles.professionalSummary) formData.append("ProfessionalSummary", formFiles.professionalSummary);

		setResult(null);
		setLoading(true);
		post({
			url: `/api/users/request-access`,
			data: formData,
			onSuccess: data => {
				setLoading(false);
				setResult(data);

				if (data.success) {
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	}

	if (result != null) {
		return (
			<div className="registration-box">
				<p>
					Thank you for request registration as an industry expert. 
				</p>
				<p>
					Your application is being reviews and we will be in touch as soon as possible.
				</p>
			</div>
		);
	}

	return (
		<div className="registration-box">
			<span className="h3">Create Your User Profile</span>
			<p>
				Access as an industry expert is by permission only.
			</p>
			<p>
				Please complete this form and it will be reviewed by our team.
			</p>

			<Form 
				onSubmit={() => {
					if (props.validateFields()) {
						requestAccess();
					} 
				}}
				style={{ textAlign: "center" }}
			>
				{fields.firstName()}
				{fields.lastName()}
				{fields.email()}
				{fields.academicSchool()}
				{fields.academicTitle()}
				{fields.academicUrl()}
				{fields.linkedInUrl()}
				<p
					style={{
						textAlign: 'center',
						fontWeight: 'bold',
						fontSize: '18px'
					}}
				>OR</p>
				{fields.professionalSummary()}

				<Button type="submit" size="large" loading={loading} color="secondary">Submit</Button>
			</Form>
		</div>
	);
};

RequestAccess.propTypes = {
	requestAccess: PropTypes.func.isRequired,
	subdomainId: PropTypes.number,
	tenantId: PropTypes.number,
	organisationId: PropTypes.number,
	organisationType: PropTypes.string
};

RequestAccess.defaultProps = {
	subdomainId: 0,
	tenantId: 0,
	organisationId: null,
	organisationType: ""
};

const mapStateToProps = (state, ownProps) => ({
	subdomainId: state.context.subdomainId,
	tenantId: state.context.registrationTenantId,
	organisationId: state.context.registrationOrganisationId,
	organisationType: state.context.organisationType,
	queryParameters: getQueryParameters(ownProps.location.search),
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(RequestAccess));