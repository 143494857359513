import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Select, MenuItem, Tooltip, IconButton } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import ClearIcon from '@mui/icons-material/Clear';
import { moveItem } from '../../utils/utils';
import Button from '../widgets/Button';
import DragFeature from './DragFeature';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import map from 'lodash/map';
import ButtonLink from '../widgets/ButtonLink';

const Features = props => {
	const [state, setState] = React.useState({ filter: "All", canDrag: false, reorder: false, tempFeatures: [] });
	const { filter, canDrag, reorder, tempFeatures } = state,
		filtered = props.features.filter(f => filter === "All" || f.location === filter);

	// Reset tempFeatures and reorder if finished loading new values
	React.useEffect(() => {
		if (!props.loading) setState({ ...state, reorder: false, tempFeatures: [] });
	}, [props.loading]);

	const handleReorder = () => setState({ ...state, reorder: !reorder, tempFeatures: reorder ? [] : props.features });

	return (
        <Grid container spacing={3}>
			<Grid item container sm={12} alignItems="flex-end">
				<Grid item xs={12} sm>
					<h3 style={{ marginBottom: 0 }}>
						Features
						<Tooltip title={reorder ? "Cancel reorder" : "Reorder features"} placement="right">
							<IconButton onClick={handleReorder} style={{ marginLeft: 10 }} size="large">
								{reorder ? <ClearIcon /> : <ReorderIcon  />}
							</IconButton>
						</Tooltip>
						{props.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
					</h3>
					<p className="text-muted m-b-0">List of all current features</p>
				</Grid>
				<Grid item xs={12} sm="auto" style={{ display: "flex", alignItems: "center" }}>
					{reorder ?
						<React.Fragment>
							<Button onClick={() => props.saveOrder(map(tempFeatures, "featureId"))}>Save</Button>
							<Button onClick={() => setState({ ...state, reorder: false })} style={{ marginLeft: 10 }} error>Cancel</Button>
						</React.Fragment> : 
						<React.Fragment>
							<label style={{ marginRight: 10 }}>Filter by location:</label>
							<Select value={filter} onChange={e => setState({ ...state, filter: e.target.value })}>
								{map(["All", "Internal", "External", "None"], (v, i) => <MenuItem key={i} value={v}>{v}</MenuItem>)}
							</Select>
						</React.Fragment>
					}
				</Grid>
			</Grid>
			{filtered.length === 0 && <Grid item xs={12}>No features found...</Grid>}
			{map(reorder ? tempFeatures : filtered, (f, i) =>
				<Grid key={i} item xs={12} sm={6} md={4} lg={3}>
					<DragFeature 
						feature={f}
						canEdit={props.canEdit}
						index={i} 
						canDrag={canDrag} 
						setDrag={canDrag => setState({ ...state, canDrag })}
						reorder={reorder}
						moveItem={(from, to) => {
							const copy = [...tempFeatures];
							moveItem(copy, from, to);
							setState({ ...state, tempFeatures: copy });
						}}
					/>
				</Grid>
			)}
			{props.canSubmit &&
				<Grid item xs={12}>
					<ButtonLink to="/features/create" style={{ float: "right" }}>Add feature</ButtonLink>
				</Grid>
			}
		</Grid>
    );
};

Features.propTypes = {
	features: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveOrder: PropTypes.func.isRequired
};

// eslint-disable-next-line new-cap
export default DragDropContext(HTML5Backend)(Features);