import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import Avatar from '../widgets/Avatar';

const ViewMentor = props => {
	const { mentor } = props;
	
	return props.loading || isEmpty(mentor) ? <CircularProgress size={24} /> : (
		<Card className="mentor-details">
			<h2>
				{mentor.image && <Avatar src={mentor.image} fullName={mentor.fullName} style={{ marginRight: 5 }} />}
				{mentor.fullName}
			</h2>
			<section>
				{mentor.industryName}, {mentor.discipline}
				<br />
				{mentor.seniority} {mentor.yearsExperience} Years
			</section>
			<section>
				<span className="h4">Academic Experience</span>
				{mentor.courses.length > 0 &&
					<Grid container spacing={3} style={{ marginBottom: 10 }}>
						{mentor.courses.map((c, i) => 
							<Grid key={i} item xs={12} sm={4} md={3}>
								<Card title={c.name} style={{ marginBottom: 0 }}>{c.institution}, Completed {c.year}</Card>
							</Grid>
						)}
					</Grid>
				}
				{mentor.academicJobs.length > 0 &&
					<React.Fragment>
						<span className="h6">Jobs</span>
						<ul>
							{mentor.academicJobs.map((j, i) => 
								<li key={i}><strong>{j.role}</strong>, {j.employer} ({j.years} Years)</li>
							)}
						</ul>
					</React.Fragment>
				}
			</section>
			<section>
				<span className="h4">Professional Experience</span>
				{mentor.professionalQualifications && 
					<div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: mentor.professionalQualifications }} />
				}
				{mentor.professionalJobs.length > 0 &&
					<section>
						<span className="h6">Jobs</span>
						<ul>
							{mentor.professionalJobs.map((j, i) => 
								<li key={i}><strong>{j.role}</strong>, {j.employer} ({j.years} Years)</li>
							)}
						</ul>
					</section>
				} 
			</section>
			<section>
				<span className="h4">Executive Experience</span>
				<div dangerouslySetInnerHTML={{ __html: mentor.executiveExperience }} />
			</section>
			{props.canEdit && 
				<ButtonLink to={`/mentors/edit/${mentor.mentorId}`}>
					Edit mentor
				</ButtonLink>
			}
		</Card>
	);
};

ViewMentor.propTypes = {
	mentor: PropTypes.shape({
		mentorId: PropTypes.number,
		fullName: PropTypes.string,
		industryName: PropTypes.string,
		industryCode: PropTypes.string,
		IndustryGroup: PropTypes.string,
		seniority: PropTypes.string,
		yearsExperience: PropTypes.number,
		discipline: PropTypes.string,
		courses: PropTypes.array,
		academicJobs: PropTypes.array,
		professionalQualifications: PropTypes.string,
		professionalJobs: PropTypes.array,
		executiveExperience: PropTypes.string,
		createdBy: PropTypes.string,
		createdAt: PropTypes.string,
		UpdatedBy: PropTypes.string,
		UpdatedAt: PropTypes.string,
		tenantId: PropTypes.number,
		image: PropTypes.string
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool
};

ViewMentor.defaultProps = {
	canEdit: false
};

export default ViewMentor;