import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import PageLayout from '../PageLayout';
import SuggestCourses from './SuggestCourses';
import { get } from '../../utils/ajax';

const SuggestCoursesController = props => {
	const [state, setState] = React.useState({ suggestion: null, isLoading: true });
	
	React.useEffect(() => {
		get({
			url: `/api/courses/suggest/${props.match.params.profileId}`,
			onSuccess: data => setState({ suggestion: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, []);

	return (
		<PageLayout 
			title="Suggested Courses for L&D Coordinator" 
			loading={state.isLoading}
			pageContent={
				<SuggestCourses 
					suggestion={state.suggestion} 
					loading={state.isLoading} 
					errorColour={props.errorColour} 
					successColour={props.successColour} 
				/>
			} 
			breadcrumbs={["Suggested Courses for L&D Coordinator"]} 
		/>
	);
};

SuggestCoursesController.propTypes = {
	errorColour: PropTypes.string.isRequired,
	successColour: PropTypes.string.isRequired
};

const mapStateToDispatch = state => ({
	errorColour: state.theme.errorColour,
	successColour: state.theme.successColour
});

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToDispatch, mapDispatchToProps)(SuggestCoursesController);
