import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import DOMPurify from 'dompurify';
import { toDate } from '../utils/utils';

const emptySaveResult = {
	success: "",
	message: "",
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	ip: {}, 
	ips: []
};

const newIP = {
	ipId: 0
};

const CREATE_NEW_IP = "CREATE_NEW_IP";
const CLEAR_IP = "CLEAR_IP";
const CLEAR_IPS = "CLEAR_IPS";
const GET_IP_REQUEST = "GET_IP_REQUEST";
const GET_IP_SUCCESS = "GET_IP_SUCCESS";
const GET_IP_FAILURE = "GET_IP_FAILURE";
const GET_IPS_REQUEST = "GET_IPS_REQUEST";
const GET_IPS_SUCCESS = "GET_IPS_SUCCESS";
const GET_IPS_FAILURE = "GET_IPS_FAILURE";
const SAVE_IP_REQUEST = "SAVE_IP_REQUEST";
const SAVE_IP_SUCCESS = "SAVE_IP_SUCCESS";
const SAVE_IP_FAILURE = "SAVE_IP_FAILURE";
const DELETE_IP_REQUEST = "DELETE_IP_REQUEST";
const DELETE_IP_SUCCESS = "DELETE_IP_SUCCESS";
const DELETE_IP_FAILURE = "DELETE_IP_FAILURE";
const SEARCH_IP_REQUEST = "SEARCH_IPS_REQUEST";
const SEARCH_IP_SUCCESS = "SEARCH_IPS_SUCCESS";
const SEARCH_IP_FAILURE = "SEARCH_IPS_FAILURE";

export const isLoading = createLoadingSelector(["GET_IPS", "GET_IP", "DELETE_IP", "SAVE_IP", "SEARCH_IPS"]);

export const createNewIP = () => ({ type: CREATE_NEW_IP });
export const clearIPs = () => ({ type: CLEAR_IPS });
export const clearIP = () => ({ type: CLEAR_IP });

export const getIPs = () =>
	createFetchAction({
		url: "/api/ip",
		startAction: GET_IPS_REQUEST,
		onError: error => [getIPsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getIPsSuccess(map(data, e => ({
			...e,
			lastUpdate: toDate(e.lastUpdate),
			dateCreated: toDate(e.dateCreated)
		})))
	});

export const getIPsSuccess = data => ({ type: GET_IPS_SUCCESS, payload: { data } });
export const getIPsFailure = error => ({ type: GET_IPS_FAILURE, payload: { error } });

export const getIP = ipId => 
	createFetchAction({
		url: `/api/ip/${ipId}`,
		startAction: GET_IP_REQUEST,
		onError: error => [getIPFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getIPSuccess({
			...data,
			lastUpdate: toDate(data.lastUpdate),
			dateCreated: toDate(data.dateCreated)
		})
	});

export const getIPSuccess = data => ({ type: GET_IP_SUCCESS, payload: { data } });
export const getIPFailure = error => ({ type: GET_IP_FAILURE, payload: { error } });

export const saveIP = ip => 
	createPostAction({
		url: "/api/ip",
		data: { ...ip, artifacts: map(ip.artifacts, a => ({ ...a, authors: map(a.authors, "value").join(";") })) },
		startAction: SAVE_IP_REQUEST,
		onError: error => [saveIPFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (data.object) {
					data.object = {
						...data.object,
						lastUpdate: toDate(data.object.lastUpdate),
						dateCreated: toDate(data.object.dateCreated)
					}
				}

				return [saveIPSuccess(data), showSuccessNotification(data.message), changeLocation(`/ip/${data.object.ipId}`)];
			} else {
				return [saveIPSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveIPSuccess = data => ({ type: SAVE_IP_SUCCESS, data });
export const saveIPFailure = error => ({ type: SAVE_IP_FAILURE, error });

export const deleteIP = ipId => 
	createPostAction({
		url: `/api/ip/${ipId}/delete`,
		startAction: DELETE_IP_REQUEST,
		onError: error => [deleteIPFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteIPSuccess(data), showSuccessNotification(data.message), changeLocation("/ip")];
			} else {
				return [deleteIPSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteIPSuccess = data => ({ type: DELETE_IP_SUCCESS, data });
export const deleteIPFailure = error => ({ type: DELETE_IP_FAILURE, error });

export const searchIP = search => 
	createFetchAction({
		url: `/api/ip/search?search=${search}`,
		startAction: SEARCH_IP_REQUEST,
		onError: error => [searchIPFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchIPuccess(map(data, e => ({
			...e,
			lastUpdate: toDate(e.lastUpdate),
			dateCreated: toDate(e.dateCreated)
		})))
	});

export const searchIPuccess = data => ({ type: SEARCH_IP_SUCCESS, payload: { data } });
export const searchIPFailure = error => ({ type: SEARCH_IP_FAILURE, payload: { error } });

const sanitizeIP = ip => ({ 
	...ip, 
	artifacts: map(ip.artifacts, a => ({ 
		...a, 
		authors: a.authors.split(";"),
		description: DOMPurify.sanitize(a.description)
	})) 
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_IPS:
			return { ...state, ips: [] };
		case CLEAR_IP:
			return { ...state, ip: {} };
		case GET_IPS_REQUEST:
			return { ...state, ips: [], saveResult: emptySaveResult };
		case GET_IPS_SUCCESS:
			return { ...state, ips: map(action.payload.data, sanitizeIP), isLoading: false };
		case GET_IP_REQUEST:
			return {
				...state,
				ip: { ...newIP },
				isLoading: true,
				saveResult: emptySaveResult
			};
		case GET_IP_SUCCESS:
			return { ...state, ip: sanitizeIP(action.payload.data), isLoading: false };
		case DELETE_IP_SUCCESS:
			return { ...state, ips: state.ips.filter(p => p.ipId !== action.data.objectId) };
		case CREATE_NEW_IP:
			return { ...state, ip: { ...newIP } };	
		case SAVE_IP_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		// Todo: check if addorupdate function is required (see events)
		case SAVE_IP_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { ip: sanitizeIP(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case SEARCH_IP_REQUEST:
			return { ...state, ips: [], saveResult: emptySaveResult };
		case SEARCH_IP_SUCCESS:
			return { ...state, ips: action.payload.data, isLoading: false };
		default:
			return state;
	}
};
