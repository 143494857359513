import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Zoom, InputLabel, Input, FormControl, FormHelperText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { getSkillsProfiles, getProfileGroups } from '../../store/skillsProfiles';
import Button from './Button';
import MediaCard from './MediaCard';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

// Default parameters do not override null values, need to use || instead
const ProfileCard = ({ title, image, description, onClick }) =>
	<MediaCard 
		title={title}
		onClick={onClick} 
		image={image || "/images/aspire-mountain.png"}
		style={{ cursor: "pointer" }}
	>
		{description || ""}
	</MediaCard>;

const ProfilePicker = React.memo(props => {
	const [state, setState] = React.useState({ open: false, group: {} });
	const [id] = React.useState(props.id || uniqueId("profile-picker-"));

	const { profileGroups: groups, skillsProfiles: profiles, organisationId, value, groupOnly } = props,
		{ profileGroupName: groupName, profileGroupId: groupId = 0 } = state.group,
		target = value && (groupOnly ? groups.find(g => g.profileGroupId === value) : profiles.find(p => p.profileId === value));

	React.useEffect(() => {
		props.getProfileGroups(organisationId);
		if (!groupOnly) props.getSkillsProfiles(organisationId);
	}, [organisationId]);
	
	// Open picker if showPicker is true and groups have finished loading
	React.useEffect(() => {
		if (props.showPicker && !state.open && groups.length > 0) onOpen();
	}, [props.showPicker, groups.length]);

	const onOpen = () => {
		setState({ open: true, group: groupOnly ? {} : (groups.length === 1 ? groups[0] : groups.find(g => g.profileGroupId === props.defaultGroup) || {}) });
	}
	
	const onCancel = () => {
		setState({ ...state, open: false });
		props.onClose();
	};

	const onOk = targetId => {
		setState({ ...state, open: false });
		props.onChange(targetId);
		props.onClose();
	};
		
	return (
		<React.Fragment>
			<FormControl className={classNames("card-picker", { "disabled": props.disabled })} fullWidth style={props.style}>
				<InputLabel htmlFor={id}>
					{props.label}{props.required && <span style={{ color: props.errorColour }}> *</span>}
				</InputLabel>
				<Input id={id} value={target ? target[props.groupOnly ? "profileGroupName" : "profileName"] : ""} disabled endAdornment={
					value === 0 || props.disableClearable ? <FilterListIcon fontSize="small" onClick={props.disabled ? undefined : onOpen} /> :
					<React.Fragment>
						<ClearIcon fontSize="small" onClick={props.disabled ? undefined : () => onOk(0)} /> |
						<FilterListIcon fontSize="small" onClick={props.disabled ? undefined : onOpen} />
					</React.Fragment>
				} />
				{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
			</FormControl>
			<Dialog open={state.open} onClose={onCancel} fullWidth maxWidth="md">
				<DialogTitle>
					{groupId === 0 ? "Select category" : 
						<React.Fragment>
							<span className="text-muted">
								<a onClick={() => setState({ ...state, group: {} })} style={{ color: "inherit", cursor: "pointer" }}>{groupName}</a> 
								<span style={{ padding: "0 0.5rem" }}>&gt;</span> 
							</span>
							Select profile
						</React.Fragment>
					}
				</DialogTitle>
				<DialogContent>
					<Zoom in={groupId === 0} unmountOnExit>
						<Grid container spacing={3}>
							{groups.map((g, i) =>
								<Grid key={i} item xs={4}>
									<ProfileCard 
										title={g.profileGroupName} 
										onClick={() => groupOnly ? onOk(g.profileGroupId) : setState({ ...state, group: g })} 
										description={g.description}
										image={g.image}
									/>
								</Grid>
							)}
						</Grid>
					</Zoom>
					<Zoom in={groupId > 0} unmountOnExit>
						<Grid container spacing={3}>
							{profiles.filter(p => p.profileGroupId === groupId).map((p, i) =>
								<Grid key={i} item xs={4}>
									<ProfileCard 
										title={p.profileName} 
										onClick={() => onOk(p.profileId)}
										description={p.description}
										image={p.image || state.group.image} 
									/>
								</Grid>
							)}
						</Grid>
					</Zoom>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCancel}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
});

ProfilePicker.propTypes = {
	label: PropTypes.node,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	organisationId: PropTypes.number,
	skillsProfiles: PropTypes.array.isRequired,
	getSkillsProfiles: PropTypes.func.isRequired,
	profileGroups: PropTypes.array.isRequired,
	getProfileGroups: PropTypes.func.isRequired,
	required: PropTypes.bool,
	helpText: PropTypes.node,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	showPicker: PropTypes.bool,
	onClose: PropTypes.func,
	defaultGroup: PropTypes.number,
	groupOnly: PropTypes.bool,
	disableClearable: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

ProfilePicker.defaultProps = {
	label: "Skills Profile",
	organisationId: 0,
	required: false,
	helpText: "",
	disabled: false,
	style: {},
	showPicker: false,
	onClose: () => {},
	defaultGroup: 0,
	groupOnly: false,
	disableClearable: false
};

const mapStateToProps = state => ({
	skillsProfiles: state.skillsProfiles.skillsProfiles,
	profileGroups: state.skillsProfiles.groups,
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getSkillsProfiles: organisationId => dispatch(getSkillsProfiles(organisationId)),
	getProfileGroups: organisationId => dispatch(getProfileGroups(organisationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicker);