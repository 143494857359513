import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CodeIcon from '@mui/icons-material/Code';
import EventIcon from '@mui/icons-material/Event';
import PaletteIcon from '@mui/icons-material/Palette';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { truncateString, stripHtml } from '../../utils/utils';
import { TrophyIcon, ClockInIcon, ClockOutIcon } from './Icons';
import Button from './Button';
import Card from './Card';
import { default as formatDate } from 'date-fns/format';
import toUpper from 'lodash/toUpper';

const EventCard = React.memo(props => {
	const { event } = props;
	
	const Icon = ({
		Showcase: TrophyIcon,
		Hackathon: CodeIcon,
		Event: EventIcon,
		"Innovation Jam": PaletteIcon,
		Webinar: LiveTvIcon,
		"Web series": SubscriptionsIcon
	})[event.eventType];

	return (
		<Card className="event-card" style={props.style} ribbon={event.approved ? "" : "Not Approved"} ribbonStatus="danger">
			<span className="h1">
				<Icon />
				{toUpper(formatDate(event.eventStartDate, "dd MMM"))}
			</span>
			<span className="h4">
				<ViewModuleIcon />
				{event.eventType}
			</span>
			<Link to={`/${props.isCommunity ? "my-community/" : ""}events/${event.eventId}`} className="h3 text-themecolor">{truncateString(event.title, 50)}</Link>
			<h3 className="h5 card-subtitle">
				<EventAvailableIcon />
				{formatDate(event.eventStartDate, "d MMMM yyyy")}
			</h3>
			<div className="content">
				<p>{truncateString(stripHtml(event.description), 100)}</p>
				<span className="h5 text-muted">
					<ClockInIcon />
					Start: {formatDate(event.eventStartDate, "hh:mm a")}
				</span>
				<span className="h5 text-muted">
					<ClockOutIcon />
					Finish: {formatDate(event.eventEndDate, "hh:mm a")}
				</span>
			</div>
			{props.register &&
				<Button variant="outlined" loading={props.loading} onClick={() => props.register(event)}>
					Register Now
				</Button>
			}
		</Card>
	);
});

EventCard.propTypes = {
	event: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	register: PropTypes.func,
	style: PropTypes.object,
	isCommunity: PropTypes.bool
};

EventCard.defaultProps = {
	loading: false,
	register: null,
	style: {},
	isCommunity: false
};

export default EventCard;