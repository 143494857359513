import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';

const form = {
	initValues: props => ({ ...props.discussion }),
	fields: [
		{
			name: "subject",
			label: "Subject",
			required: true,
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor"
		}
	]
};

const EditDiscussion = props => {
	const { fields, loading, isNew } = props;

	return (
		<Card title="Edit discussion" loading={loading} className="edit-discussion">
			<Form onSubmit={() => props.validateFields() && props.saveDiscussion(props.values)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.subject()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>Save</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteDiscussion(props.discussion.discussionId)}>
							Delete
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditDiscussion.propTypes = { 
	discussion: PropTypes.object,
	saveDiscussion: PropTypes.func.isRequired,
	deleteDiscussion: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditDiscussion.defaultProps = {
	discussion: {},
	deleteDiscussion: () => null,
	isNew: false
};

export default withForm(form)(EditDiscussion);
