import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import round from 'lodash/round';

const TeamGapProfile = ({ teamId, setLoading }) => {
	const [data, setData] = React.useState([]);

	const loadData = () => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/team-gap-profile?teamId=${teamId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (teamId) {
			loadData();
		}
	}, [teamId]);

	const chartData = map(data, d => ({
		name: `${d.specialisationTitle}`,
		fullLabel: `${d.specialisationTitle}`,
		value: d.competentPercentage,
		itemStyle: {
			//color: `#${d.colour}`
		}
	})) || [];

	return (
		<Chart 
			info={{
				title: 'Team Skills Gap Profile',
				text: 
					<React.Fragment>
						<p>The Team Skills Gap Profile chart shows the percentage of Units that are Not Competent across groups.</p>
						<p>The larger the segment, the less competent.</p>
					</React.Fragment>
			}}
			option={{
				tooltip: {
					trigger: 'item',
					formatter: params => `${params.data.name}: ${round(params.data.value * 100.0, 1)}%`
				},
				series: [
					{
						type: 'treemap',
						roam: false,
						nodeClick: false,
						breadcrumb: {
							show: false
						},
						data: chartData
					}
				]
			}}
			style={{ height: "100%" }} 
		/>
	);
};

TeamGapProfile.propTypes = {
	teamId: PropTypes.number,
	setLoading: PropTypes.func.isRequired
};

TeamGapProfile.defaultProps = {
	teamId: null
};

export default TeamGapProfile;
