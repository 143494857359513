import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../store/modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../widgets/Button';
import { withForm } from '../../utils/forms';
import { logIn } from '../../store/context';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { clearFailedAjaxCalls } from '../../store/context';
import { createFetchAction, createPostAction,createFormPostAction } from '../../utils/reducer-utils';

const form = {
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "identity",
			required: true,
			label: "Username or email",
			style: { marginTop: 10 },
			autoFocus: true
		},
		{
			name: "password",
			required: true,
			label: "Password",
			type: "password"
		},
		{
			name: "keepLoggedIn",
			label: "Keep me logged in",
			type: "checkbox",
			style: { marginBottom: 0 }
		}
	]
};

const LoginDialog = props => {
	const [loading, setLoading] = React.useState(false);

	const login = () => {
		if (props.validateFields()) {
			setLoading(true);

			props.logIn(props.values.identity, props.values.password, props.values.keepLoggedIn, result => {
				setLoading(false);

				if (result.success) {
					props.onSuccess();
					props.onOk();

					for (let i = 0, ii = props.failedAjaxCalls.length; i < ii; i++) {
						const ajaxCall = props.failedAjaxCalls[i];
						switch (ajaxCall.type) {
							case "get":
								createFetchAction(ajaxCall.args)(props.dispatch);
								break;
							case "post":
								createPostAction(ajaxCall.args)(props.dispatch);
								break;
							case "formPost":
								createFormPostAction(ajaxCall.args)(props.dispatch);
								break;
							default:
						}
					}

					props.clearFailedAjaxCalls();
				} else {
					props.onError(result.message);
				}
			});		
		}
	}

	return (
        <Dialog
            open
            onClose={props.onCancel}
            aria-labelledby="simple-dialog-title"
            className="login-modal"
            disableEscapeKeyDown>
			<DialogTitle id="simple-dialog-title">Login</DialogTitle>
			<DialogContent>
				Your session timed-out. Please enter your credentials to continue.
				{props.fields.identity()}
				{props.fields.password()}
				{props.fields.keepLoggedIn()}
			</DialogContent>
			<DialogActions>
				<Button onClick={login} loading={loading} className="ok-button">Login</Button>
			</DialogActions>
		</Dialog>
    );
}

LoginDialog.propTypes = {
	onOk: PropTypes.func,
	onCancel: PropTypes.func,
	logIn: PropTypes.func.isRequired,
	failedAjaxCalls: PropTypes.array.isRequired
};

LoginDialog.defaultProps = {
	onOk: () => null,
	onCancel: () => null
};

const mapStateToProps = state => ({
	failedAjaxCalls: state.context.failedAjaxCalls
});

const mapDispatchToProps = dispatch => ({
	dispatch,
	logIn: (identity, password, keepLoggedIn, onSuccess) => dispatch(logIn(identity, password, keepLoggedIn, onSuccess)),
	onSuccess: () => [
		dispatch(showSuccessNotification("You have successfully logged back in.")),
		dispatch(hideModal())
	],
	onError: message => dispatch(showErrorNotification(message)),
	clearFailedAjaxCalls: () => dispatch(clearFailedAjaxCalls())
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(LoginDialog));