import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';
import { changeLocation } from '../../../store/location';
import { post } from '../../../utils/ajax';
import { emptySaveResult } from '../../../variables';
import PageLayout from '../../PageLayout';
import EditUserOrganisation from './EditUserOrganisation';

const AddUserOrganisationController = props => {
	const [state, setState] = React.useState({ isLoading: false, saveResult: emptySaveResult });

	const onSave = (userOrganisation, attachments) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		
		post({
			url: "/api/user-organisations",
			data: userOrganisation,
			onSuccess: data => {
				setState({ saveResult: data, isLoading: false });

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToEditUrl(data.object);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	return (
		<PageLayout 
			title="Add User Organisation" 
			pageContent={
				<EditUserOrganisation 
					canEdit
					canSelectTenant={props.isGlobalAdministrator}
					userOrganisation={{
						tenantId: 0,
						allowSelfRegistration: true
					}}
					onSave={onSave} 
					saveResult={state.saveResult}
					loading={state.isLoading}
					isNew
				/>
			} 
			breadcrumbs={[
				{ label: "User Organisations",  path: "/admin/user-organisations" }, 
				"Add User Organisation"
			]} 
		/>
	);
};

AddUserOrganisationController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	goToEditUrl: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	tenantId: state.context.tenantId
});

const mapDispatchToProps = dispatch => ({
	goToEditUrl: userOrganisation => dispatch(changeLocation(`/admin/user-organisations/${userOrganisation.userOrganisationId}`)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserOrganisationController);
