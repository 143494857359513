import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import ButtonLink from '../widgets/ButtonLink';
import Pagination from '../widgets/Pagination';
import ProblemRow from '../widgets/ProblemRow';
import filter from 'lodash/filter';

const itemsPerPage = 10;

const AllProblems = props => {
	const [currentPage, setCurrentPage] = React.useState(1);
	const { loading, isCommunity } = props,
		problems = filter(props.problems, p => props.showArchived || p.status !== "archived"),
		pageCount = Math.ceil(problems.length / itemsPerPage),
		from = (currentPage - 1) * itemsPerPage;

	return (
		<Card 
			title={`All ${props.plural}`}
			loading={loading}
			titleRightContent={<ButtonLink to={`/${isCommunity ? "my-community/" : ""}problems/create`}>Submit {props.single}</ButtonLink>}
		>
			<div className="comment-widgets">
				{pageCount === 0 && !loading && <span>No matching {props.plural} found...</span>}
				{problems.slice(from, from + itemsPerPage).map((p, i) => 
					<ProblemRow key={i} problem={p} extended isAdmin={props.isAdmin} deleteProblem={props.deleteProblem} single={props.single} isCommunity={isCommunity} />
				)}
				{/* Only render Pagination after problems have finished loading */}
				{!loading &&
					<Pagination
						maxPages={10}
						pageCount={pageCount} 
						currentPage={currentPage}
						changePage={page => setCurrentPage(page)}
					/>
				}
			</div>
		</Card>
	);
};

AllProblems.propTypes = {
	problems: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	showArchived: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	isCommunity: PropTypes.bool.isRequired
};

export default AllProblems;
