import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Avatar from '../widgets/Avatar';
import Tag from '../widgets/Tag';
import { prettifyDate } from '../../utils/utils';
import Button from '../widgets/Button';
import map from 'lodash/map';
import Link from 'react-router-dom/Link';

class ViewApplication extends React.Component {
	render() {
		const { application, loading } = this.props;
		const stageColour = ({
			Idea: "#f0ad4e",
			Prototype: "#35aa47",
			Product: "#5bc0de"
		})[application.stage];

		const statusColour = ({
			Closed: "warning",
			Declined: "danger",
			Pending: "info",
			"Project Proposal": "megna",
			Showcase: "primary"
		})[application.status];

		return (
			<Card className="row application" loading={loading}>
				{!loading &&
					<div className="d-flex row application">
						<div className="user-avatar">
							<Avatar src={application.userAvatar} fullName={application.fullName} length={50} />
						</div>
						<div className="comment-text w-100">
							<div className="details-box">
								<Link to={`/vendors/${application.userId}`} target="_blank" className="user-name">
									{application.fullName}
								</Link>
								<span>{application.departmentTitle}</span>
							</div>
							<p dangerouslySetInnerHTML={{ __html: application.solution }}></p>
							<div className="row">
								<ul className="list-unstyled">
									{map(application.attachments, (attachment, index) =>
										<li className="media" key={`event-attachment-${index}`}>
											<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
											<div className="media-body">
												<h5 className="mt-0 mb-1">{attachment.name}</h5>
												<p>{attachment.description}</p>
												<Button 
													variant="outlined" 
													onClick={() => this.props.downloadAttachment(attachment)}
												>
													Download Now
												</Button>
											</div>
										</li>
									)}
								</ul>
							</div>
							<div className="comment-footer">
								<Tag label={application.stage} colour={stageColour} />
								<span className={`label label-light-${statusColour}`}>{application.status}</span>
								<span className="text-muted pull-right">{prettifyDate(application.applicationDate)}</span>
							</div>
							{this.props.canEdit && application.status !== "Declined" &&
								<div className="form-buttons pull-right">
									{application.status !== "Showcase" && 
										<Button onClick={() => this.props.setStatus(application.applicationId, "Showcase")}>Showcase</Button>
									}
									{application.status !== "Project Proposal" &&
										<Button 
											onClick={() => this.props.setStatus(application.applicationId, "Project Proposal")} 
											color="secondary" 
											style={{ marginLeft: 10 }}
										>
											Project Proposal
										</Button>
									}
									<Button 
										onClick={() => this.props.setStatus(application.applicationId, "Declined")} 
										className="delete-button"
										style={{ marginLeft: 10 }}
									>
										Decline
									</Button>
								</div>
							}
						</div>
					</div>
				}
			</Card>
		)
	}
};

ViewApplication.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	application: PropTypes.shape({
		applicationId: PropTypes.number,
		applicationDate: PropTypes.string,
		solution: PropTypes.string,
		stage: PropTypes.string,
		status: PropTypes.string,
		attachments: PropTypes.array
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	setStatus: PropTypes.func.isRequired,
	downloadAttachment: PropTypes.func.isRequired
};

export default ViewApplication;
