

import React from 'react';

const Landing = props =>  {
	
	return (
		<iframe 
            title="Landing" 
            src="https://chrish675.wixsite.com/my-site" 
            style={{
                height: '100%',
                width: '100%'
            }}
        />
	);
};

export default Landing;
