import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import TeamSkillsSummary from './TeamSkillsSummary';
import { get } from '../../utils/ajax';
import {
	teamsDashboardWidgetsDefault,
	teamsDashboardLayoutDefault
} from '../dashboard/dashboardDefaults';
import axios from 'axios';
import { showErrorNotification } from '../../store/notifications';
import find from 'lodash/find';
import { isTeamManager } from '../../store/security';

const TeamSkillsSummaryController = props => {
	const [loading, setLoading] = React.useState(false);
	const [dashboard, setDashboard] = React.useState(null);
	const [teamId, setTeamId] = React.useState(null);
	const [teams, setTeams] = React.useState(null);

	React.useEffect(() => {
		setLoading(true);

		axios.all([
			get({ 
				url: `/api/teams`,
				onSuccess: (data) => {
					setTeams(data);
					if (data && data.length > 0) {
						let defaultTeam = null;
						if (props.departmentId) {
							defaultTeam = find(data, t => t.teamId === -props.departmentId);
						}
						setTeamId(defaultTeam ? defaultTeam.teamId : data[0].teamId);
					}
				},
				onError: (error) => {
					setTeams([]);
					this.props.showErrorNotification(error.message);
				}
			}),
			get({
				url: "/api/dashboard/team-dashboard",
				onSuccess: data => {
					setDashboard({ 
						layout: data && data.layout ? JSON.parse(data.layout) : teamsDashboardLayoutDefault, 
						widgets: data && data.widgets ? JSON.parse(data.widgets) : teamsDashboardWidgetsDefault
					});
				},
				onError: error => {
					setDashboard({ layout: null, widgets: null });
					props.showErrorNotification(error.message);
				}
			})
		]).then(responseArr => {
			setLoading(false);
		});
	}, []);

	const onChangeTeam = teamId => {
		setTeamId(teamId);
	};

	return (
        <PageLayout 
            title="Team Skills Summary" 
            pageContent={
		        <TeamSkillsSummary 
                    dashboard={dashboard}
					teamId={teamId}
					teams={teams}
                    loading={props.loading || loading} 
					onChangeTeam={onChangeTeam}
					isTeamManager={props.isTeamManager}
                /> 
	        } 
            breadcrumbs={["Team Skills Summary"]} 
        />
    );
};

TeamSkillsSummaryController.propTypes = { 
	showErrorNotification: PropTypes.func.isRequired,
	departmentId: PropTypes.number,
	isTeamManager: PropTypes.bool.isRequired
};

TeamSkillsSummaryController.defaultProps = {
	departmentId: null,
	isTeamManager: false
};

const mapStateToProps = state => ({
	departmentId: state.context.departmentId,
	isTeamManager: isTeamManager(state)
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamSkillsSummaryController);
