import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../store/modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmArchiveModal = props => {
	const close = () => {
		props.hideModal(); 
		props.onCancel();
	}
	
	const cancel = () => {
		props.hideModal(); 
		props.onCancel();
	}

	const ok = () => {
		props.hideModal(); 
		props.onOk();
	}

	return (
		<Dialog open onClose={close} aria-labelledby={props.title} className="archive-modal">
			<DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancel} autoFocus>No</Button>
				<Button onClick={ok} className="ok-button">Yes</Button>
			</DialogActions>
		</Dialog>
	);
};

ConfirmArchiveModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

ConfirmArchiveModal.defaultProps = {
	onCancel: () => null
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(ConfirmArchiveModal);