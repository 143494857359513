import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	resources: [],
	resource: {}
};

const CLEAR_RESOURCES = "CLEAR_RESOURCES";
const GET_RESOURCES_REQUEST = "GET_RESOURCES_REQUEST";
const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
const GET_RESOURCES_FAILURE = "GET_RESOURCES_FAILURE";
const SEARCH_RESOURCES_REQUEST = "SEARCH_RESOURCES_REQUEST";
const SEARCH_RESOURCES_SUCCESS = "SEARCH_RESOURCES_SUCCESS";
const SEARCH_RESOURCES_FAILURE = "SEARCH_RESOURCES_FAILURE";
const GET_RESOURCE_REQUEST = "GET_RESOURCE_REQUEST";
const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS";
const GET_RESOURCE_FAILURE = "GET_RESOURCE_FAILURE";
const SAVE_RESOURCE_REQUEST = "SAVE_RESOURCE_REQUEST";
const SAVE_RESOURCE_SUCCESS = "SAVE_RESOURCE_SUCCESS";
const SAVE_RESOURCE_FAILURE = "SAVE_RESOURCE_FAILURE";
const DELETE_RESOURCE_REQUEST = "DELETE_RESOURCE_REQUEST";
const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS";
const DELETE_RESOURCE_FAILURE = "DELETE_RESOURCE_FAILURE";

export const isLoading = createLoadingSelector(["GET_RESOURCES", "SEARCH_RESOURCES", "GET_RESOURCE"]);

export const clearResources = () => ({ type: CLEAR_RESOURCES });

export const getResources = () =>
	createFetchAction({
		url: "/api/resources",
		startAction: GET_RESOURCES_REQUEST,
		onError: error => [getResourcesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getResourcesSuccess(data)
	});

export const getResourcesSuccess = data => ({ type: GET_RESOURCES_SUCCESS, payload: { data } });
export const getResourcesFailure = error => ({ type: GET_RESOURCES_FAILURE, payload: { error } });

export const searchResources = args => 
	createFetchAction({
		url: `/api/resources/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_RESOURCES_REQUEST,
		onError: error => [searchResourcesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchResourcesSuccess(data)
	});

export const searchResourcesSuccess = data => ({ type: SEARCH_RESOURCES_SUCCESS, payload: { data } });
export const searchResourcesFailure = error => ({ type: SEARCH_RESOURCES_FAILURE, payload: { error } });

export const getResource = resourceId =>
	createFetchAction({
		url: `/api/resources/${resourceId}`,
		startAction: GET_RESOURCE_REQUEST,
		onError: error => [getResourceFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getResourceSuccess(data)
	});

export const getResourceSuccess = data => ({ type: GET_RESOURCE_SUCCESS, payload: { data } });
export const getResourceFailure = error => ({ type: GET_RESOURCE_FAILURE, payload: { error } });

export const saveResource = (resource, imageFile, attachments, isCommunity) => {
	const videos = JSON.stringify(resource.videos),
		links = JSON.stringify(resource.links);

	const formData = new FormData();
	formData.append("Resource", encodeURIComponent(JSON.stringify({ ...resource, videos, links })));
	
	if (imageFile) formData.append("ImageFile", imageFile);
	forEach(attachments, a => formData.append("Attachments", a));

	return createFormPostAction({
		url: "/api/resources",
		data: formData,
		startAction: SAVE_RESOURCE_REQUEST,
		onError: error => [saveResourceFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveResourceSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}resources/${data.object.resourceId}`)];
			} else {
				return [saveResourceSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveResourceSuccess = data => ({ type: SAVE_RESOURCE_SUCCESS, data });
export const saveResourceFailure = error => ({ type: SAVE_RESOURCE_FAILURE, error });

export const deleteResource = (resourceId, isCommunity) =>
	createPostAction({
		url: `/api/resources/${resourceId}/delete`,
		startAction: DELETE_RESOURCE_REQUEST,
		onError: error => [deleteResourceFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteResourceSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}resources`)];
			} else {
				return [deleteResourceSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteResourceSuccess = data => ({ type: DELETE_RESOURCE_SUCCESS, data });
export const deleteResourceFailure = error => ({ type: DELETE_RESOURCE_FAILURE, error });

export const getSubscribedResources = limit =>
	createFetchAction({
		url: `/api/resources/subscribed?limit=${limit}`,
		startAction: GET_RESOURCES_REQUEST,
		onError: error => [getResourcesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getResourcesSuccess(data)
	});

export const searchSubscribedResources = args => 
	createFetchAction({
		url: `/api/resources/subscribed/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_RESOURCES_REQUEST,
		onError: error => [searchResourcesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchResourcesSuccess(data)
	});

const formatResource = resource => ({
	...resource,
	videos: resource.videos ? JSON.parse(resource.videos) : [],
	links: resource.links ? JSON.parse(resource.links) : [],
	method: DOMPurify.sanitize(resource.method)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_RESOURCES:
			return { ...state, resources: [] };
		case GET_RESOURCES_REQUEST:
			return { ...state, resources: [], isLoading: true };
		case SEARCH_RESOURCES_REQUEST:
			return { ...state, isLoading: true };
		case GET_RESOURCES_SUCCESS:
		case SEARCH_RESOURCES_SUCCESS:
			return { ...state, resources: map(action.payload.data, formatResource), isLoading: false };
		case GET_RESOURCE_REQUEST:
			return { ...state, resource: {}, isLoading: true }
		case GET_RESOURCE_SUCCESS:
			return { ...state, resource: formatResource(action.payload.data), isLoading: true };
		case SAVE_RESOURCE_REQUEST:
		case DELETE_RESOURCE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_RESOURCE_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_RESOURCE_SUCCESS:
			return { ...state, resources: state.resources.filter(a => a.resourceId !== action.data.objectId) };
		default:
			return state;
	}
};