import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showModal } from '../../store/modal';
import { getEvents, deleteEvent, isLoading } from '../../store/events';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { canApproveEvents, canRequestEvents, canSubmitEvents } from '../../store/security';
import { post } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import Events from './Events';

const EventsController = props => {
	const [showUnapproved, setShowUnapproved] = React.useState(false);
	const [isRegistering, setIsRegistering] = React.useState({});
	const [dates, setDates] = React.useState({ from: null, to: null });

	React.useEffect(() => props.getEvents(dates, showUnapproved), [dates.from, dates.to, showUnapproved, props.isCommunity]);

	const register = event => {
		if (event.registrationUrl) {
			window.open(event.registrationUrl, "_blank");
		} else {
			setIsRegistering({ ...isRegistering, [event.eventId]: true });

			post({
				url: `/api/events/${event.eventId}/register`,
				onSuccess: result => {
					setIsRegistering({ ...isRegistering, [event.eventId]: false });

					if (result.success) {
						props.showSuccessNotification(result.message);
					} else {
						props.showErrorNotification(result.message);
					}
				},
				onError: error => {
					setIsRegistering({ ...isRegistering, [event.eventId]: false });
					props.showErrorNotification(error.message);
				}
			});
		}
	}

	return <PageLayout title="Events" pageContent={
		<Events 
			events={props.events}
			fromDate={dates.from}
			toDate={dates.to}
			loading={props.loading}
			onFilter={(from, to) => setDates({ from, to })}
			register={register}
			isRegistering={isRegistering}
			canSubmit={props.canSubmit}
			canRequest={props.canRequest}
			isCommunity={props.isCommunity}
			canApprove={props.canApprove}
			showUnapproved={showUnapproved}
			setShowUnapproved={setShowUnapproved}
		/>
	} breadcrumbs={[props.isCommunity ? { label: "My Community", path: "/my-community" } : "", "Events"]} />;
};

EventsController.propTypes = { 
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canRequest: PropTypes.bool.isRequired,
	canApprove: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool
};

EventsController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	events: state.events.events,
	loading: isLoading(state),
	canSubmit: canSubmitEvents(state),
	canRequest: canRequestEvents(state),
	canApprove: canApproveEvents(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getEvents: ({ from, to }, showUnapproved) => dispatch(getEvents(from, to, showUnapproved, ownProps.isCommunity)),
	deleteEvent: event => {
		dispatch(showModal("CONFIRM_DELETE", {
			title: "Delete?",
			message: "Are you sure you wish to delete this event?",
			onOk: () => dispatch(deleteEvent(event, ownProps.isCommunity))
		}));
	},
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsController);