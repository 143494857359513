import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';

const Tag = React.memo(props => 
	<Chip
		id={props.id}
		className="tag"
		tabIndex={-1}
		label={props.label} 
		style={{
			backgroundColor: props.colour === null ? props.secondaryColour : props.colour,
			color: props.colour === null ? props.secondaryColourText : "#fff",
			marginRight: 5,
			height: 20,
			...props.style
		}}
		onClick={props.onClick}
		onDelete={props.onDelete}
		deleteIcon={props.deleteIcon}
	/>
);

Tag.propTypes = {
	label: PropTypes.string.isRequired,
	colour: PropTypes.string,
	styles: PropTypes.object,
	onClick: PropTypes.func,
	onDelete: PropTypes.func,
	deleteIcon: PropTypes.node,
	secondaryColour: PropTypes.string.isRequired,
	id: PropTypes.string
};

Tag.defaultProps = {
	colour: null,
	styles: {},
	onClick: undefined,
	onDelete: undefined,
	deleteIcon: undefined,
	id: undefined
};

const mapStateToProps = state => ({
	secondaryColour: state.theme.secondaryColour,
	secondaryColourText: state.theme.secondaryColourText
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Tag);
