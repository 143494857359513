import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewStudent from './ViewStudent';
import { getStudent, isLoading } from '../../store/students';
import { canEditStudent } from '../../store/security';

const ViewStudentController = props => {
	React.useEffect(() => props.getStudent(props.match.params.studentId), [props.match.params.studentId]);

	return <PageLayout title="View Student" pageContent={
		<ViewStudent student={props.student} loading={props.loading} canEdit={props.canEdit} />
	} breadcrumbs={[{ label: "Students", path: "/students" }, "View Student"]} />;
};

ViewStudentController.propTypes = {
	student: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	student: state.students.student,
	loading: isLoading(state),
	canEdit: canEditStudent(state)
});

const mapDispatchToProps = dispatch => ({
	getStudent: studentId => dispatch(getStudent(studentId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewStudentController);