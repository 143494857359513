import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../widgets/Card';
import { Link } from 'react-router-dom'
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import PageLayout from '../PageLayout';
import { get } from '../../utils/ajax';
import { 
	Grid
} from '@mui/material';
import { updateTheme } from '../../store/theme';
import map from 'lodash/map';
import { canCreateUIThemes } from '../../store/security';

const PaletteColour = ({ colour }) =>
	<div style={{
		backgroundColor: colour,
		width: '40px',
		height: '40px',
		display: 'inline-block',
		marginRight: '5px',
		border: '1px solid #333'
	}}>
	</div>;

const ThemesController = props => {
	const [state, setState] = React.useState({ themes: [], isLoading: true });

	React.useEffect(() => {
		get({
			url: '/api/application-themes',
			onSuccess: data => setState({ themes: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, []);

	return (
		<PageLayout 
			title="Manage Themes" 
			loading={state.loading}
			pageContent={
				<React.Fragment>
					<Grid container spacing={3} direction="row-reverse">
						{props.canCreate &&
							<Grid item xs="auto">
								<ButtonLink to="/ui-themes/create">Create Theme</ButtonLink>
							</Grid>
						}
					</Grid>
					{state.themes.length === 0 && <p>No themes found...</p>}
					<Grid container spacing={3}>
						{map(state.themes, (theme, i) =>
							<Grid key={i} item xs={12} sm={6} md={4} lg={3}>
								<Card>
									<Link to={`/ui-themes/${theme.applicationThemeId}`} className="h3 name">
										{theme.name}
									</Link>
									<Button
										style={{
											backgroundColor: theme.primaryColour,
											color: theme.primaryColourText,
											position: 'absolute',
											top: '5px',
											right: '5px'
										}}
										onClick={() => {
											props.updateTheme({
												primaryColour: theme.primaryColour,
												primaryColourText: theme.primaryColourText,
												secondaryColour: theme.secondaryColour,
												secondaryColourText: theme.selectionColourText
											});
										}}
									>Preview</Button>
									<div style={{
										background: theme.primaryColour,
										color: theme.primaryColourText,
										padding: '5px 20px',
										borderRadius: '5px',
										marginBottom: '5px'
									}}>
										Primary Colour
									</div>
									<div style={{
										background: theme.secondaryColour,
										color: theme.secondaryColourText,
										padding: '5px 20px',
										borderRadius: '5px',
										marginBottom: '5px'
									}}>
										Secondary Colour
									</div>

									{theme.colourPalette && theme.colourPalette.length > 0 &&
										<div>
											{map(theme.colourPalette, c =>
												<PaletteColour colour={c} />
											)}
										</div>
									}
								</Card>
							</Grid>
						)}
					</Grid>
				</React.Fragment>
			} 
			breadcrumbs={["Manage themes"]} 
		/>
	);
};

ThemesController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	canCreate: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	canCreate: canCreateUIThemes(state)
});

const mapDispatchToProps = dispatch => ({
	updateTheme: theme => dispatch(updateTheme(theme))
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemesController);