import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { canSubmitActivities, canSubmitTools } from '../../store/security';
import { changeLocation } from '../../store/location';
import { get, post } from '../../utils/ajax';
import { showModal } from '../../store/modal';
import { emptySaveResult } from '../../variables';
import PageLayout from '../PageLayout';
import LDCourse from './LDCourse';
import forEach from 'lodash/forEach';

const defaultCourse = {
	code: "",
	title: "",
	description: "",
	objectives: "",
	tools: [],
	resources: [],
	sessions: []
};

const EditLDCourseController = props => {
	const [state, setState] = React.useState({
		isLoading: false,
		ldCourse: defaultCourse,
		saveResult: emptySaveResult
	});

	React.useEffect(() => {
		axios.all([
			get({
				url: `/api/ld-courses/${props.match.params.id}`,
				onSuccess: data => setState(prevState => ({ ...prevState, ldCourse: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	}, [props.match.params.id]);

	const onSave = (ldCourse, attachments) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
	
		const formData = new FormData();
		formData.append("Course", encodeURIComponent(JSON.stringify(ldCourse)));
		forEach(attachments || [], a => formData.append("Attachments", a.file, a.clientId));

		post({
			url: "/api/ld-courses",
			data: formData,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

				if (data.success) {
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	const onDelete = ldCourse => {
		props.promptDeleteLDCourse(ldCourse, () => {
			post({
				url: `/api/ld-courses/${ldCourse.lD_CourseId}/delete`,
				onSuccess: data => {
					setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToLDCoursesPage();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	}

	return <PageLayout title="Edit L&amp;D Course" pageContent={
		<LDCourse
			canEdit={props.isGlobalAdministrator || state.ldCourse.organisationId === props.organisationId}
			ldCourse={state.ldCourse}
			onSave={onSave} 
			saveResult={state.saveResult}
			loading={state.isLoading} 
			onDelete={onDelete}
			canSubmitActivities={props.canSubmitActivities}
			canSubmitTools={props.canSubmitTools}
		/>
	} breadcrumbs={[{ label: "L&D Courses",  path: "/course-design" }, "Edit L&D Course"]} />;
};

EditLDCourseController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	promptDeleteLDCourse: PropTypes.func.isRequired,
	goToLDCoursesPage: PropTypes.func.isRequired,
	canSubmitActivities: PropTypes.bool.isRequired,
	canSubmitTools: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	organisationId: state.context.organisationId,
	canSubmitActivities: canSubmitActivities(state),
	canSubmitTools: canSubmitTools(state)
});

const mapDispatchToProps = dispatch => ({
	goToLDCoursesPage: () => dispatch(changeLocation('/course-design')),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptDeleteLDCourse: (ldCourse, onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this L&D Course?",
		onOk: () => onOk()
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLDCourseController);
