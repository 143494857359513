import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import TextField from '../widgets/TextField';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';

// Visibility is set to private if the user is unable to create public tools AND there is no current value set
const form = {
	initValues: props => ({ 
		visibility: props.isAcademic ? "Public" : "", 
		...props.tool,
		...(!props.canCreatePublicTool && (!props.tool || !props.tool.visibility) && { visibility: "Private" })
	}),
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
			helpText: "Name of tool"
		},
		{
			name: "channelId",
			label: "Channel",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: channel => ({ label: channel.name, value: channel.channelId })
			}
		},
		{
			name: "description",
			label: "Description",
			multiline: true,
			helpText: "Description of tool"
		},
		{
			name: "method",
			label: "Method",
			type: "textEditor",
			showImage: true
		}, 
		{
			name: "images",
			label: "Images",
			type: "imageUploader"
		},
		{
			name: "references",
			label: "References",
			multiline: true
		},
		{
			name: "videos",
			label: "Videos",
			type: "dragList",
			itemTemplate: "",
			renderItem: (value, index, updateItem) => 
				<TextField key={index} label="Link" value={value} onChange={e => updateItem(e.target.value)} />
		},
		{
			name: "files",
			type: "attachmentEditor",
			download: attachment =>
				window.open(`/Tool/DownloadAttachment?toolId=${attachment.toolId}&toolAttachmentId=${attachment.toolAttachmentId}`, "_self")
		},
		{
			name: "visibility",
			label: "Visibility",
			type: "select",
			required: true,
			disabled: props => !props.canCreatePublicTool,
			items: ["Public", "Private"]
		},
		{
			name: "status",
			label: "Status",
			type: "select",
			required: true,
			items: ["Published", "Draft"]
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		},
		{
			name: "links",
			label: "Links",
			type: "dragList",
			itemTemplate: { label: "", to: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={4}>
						<TextField label="Label" value={item.label} onChange={e => updateItem(e.target.value, "label")} />
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField startAdornment="https://" label="Link" value={item.to} onChange={e => updateItem(e.target.value, "to")} />
					</Grid>
				</Grid>
		},
		{
			name: "academicId",
			label: "Academic Author",
			type: "autoComplete",
			loadItems: {
				route: "academics",
				mapItem: academic => ({ value: academic.academicId, label: academic.fullName })
			},
			disabled: true
		},
		{
			name: "linkedResources",
			label: "Linked Resources",
			type: "resourcePicker",
			isMulti: true,
			subscribedOnly: props => props.isCommunity,
			disabled: true
		}
	]
};

const EditTool = props => {
	const { tool, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} tool`} loading={loading} className="edit-tool">
			<Form onSubmit={() => props.validateFields() && props.saveTool(props.values, [...props.formFiles.images, ...props.formFiles.files])}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.name()}</Grid>
					<Grid item xs={12} sm={6}>{fields.channelId()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.method()}</Grid>
					<Grid item xs={12}>{fields.images()}</Grid>
					<Grid item xs={12}>{fields.references()}</Grid>
					<Grid item xs={12}>{fields.videos()}</Grid>
					<Grid item xs={12}>{fields.links()}</Grid>
					<Grid item xs={12}>{fields.files()}</Grid>
					<Grid item xs={12} sm={6}>{fields.visibility()}</Grid>
					<Grid item xs={12} sm={6}>{fields.status()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
					<Grid item xs={12}>{fields.linkedResources()}</Grid>
					<Grid item xs={12} sm={6}>{fields.academicId()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} tool</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteTool(tool.toolId)}>
							Delete Tool
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditTool.propTypes = { 
	tool: PropTypes.object,
	saveTool: PropTypes.func.isRequired,
	deleteTool: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool.isRequired,
	canCreatePublicTool: PropTypes.bool.isRequired,
	isAcademic: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool.isRequired
};

EditTool.defaultProps = {
	tool: {},
	deleteTool: () => null,
	isNew: false
};

export default withForm(form)(EditTool);