import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import Button from '../widgets/Button';
import TextField from '../widgets/TextField';
import Checkbox from '../widgets/Checkbox';
import Select from '../widgets/Select';
import TextEditor from '../widgets/TextEditor';
import AutoComplete from '../widgets/AutoComplete';

const Artifact = ({ artifact, type, update, remove, authorsList, requiredText, errorColour }) => 
	<Grid container spacing={4}>
		<Grid item xs={12} sm={6}>
			<TextField
				label="Title"
				value={artifact.title}
				onChange={e => update("title", e.target.value)}
				helpText={artifact.title ? "Title of artifact" : requiredText}
				error={!artifact.title}
				required
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextField label="Type" value={type} required disabled />
		</Grid>
		<Grid item xs={12} sm="auto">
			<Checkbox
				label="Security/ Confidentiality concerns"
				checked={artifact.concerns}
				onChange={e => update("concerns", e.target.checked)}
				helpText="Will there be security or confidentiality concerns regarding the IP if released?"
			/>
		</Grid>
		<Grid item xs={12} sm="auto">
			<Checkbox
				label="Available for sharing"
				checked={artifact.sharing}
				onChange={e => update("sharing", e.target.checked)}
				helpText="Is the artifact available for sharing?"
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<Select
				label="Ownership"
				items={["Contractor", "Background", "Crown", "3rd Party"]}
				value={artifact.ownership}
				onChange={e => update("ownership", e.target.value)}
				helpText={artifact.ownership ? "Type of ownership" : requiredText}
				error={!artifact.ownership}
				required
			/>
		</Grid>
		<Grid item xs={12}>
			<TextEditor
				label="Description"
				value={artifact.description}
				onChange={content => update("description", content)}
				helpText="Description of Artifact"
			/>
		</Grid>
		<Grid item xs={6}>
			<TextField
				label="File Reference"
				value={artifact.reference}
				onChange={e => update("reference", e.target.value)}
			/>
		</Grid>
		<Grid item xs={12}>
			<AutoComplete 
				label="Authors"
				value={artifact.authors}
				onChange={values => update("authors", values)}
				items={authorsList}
				isMulti
				canCreate
				noOptionsMessage={() => null}
				helpText="Authors of artifact"
			/>
		</Grid>
		<Grid item xs={12} style={{ padding: "0 16px" }}>
			<Button 
				variant="outlined"
				style={{ color: errorColour, float: "right" }}
				onClick={remove}
			>
				Remove
			</Button>
		</Grid>
		<Grid item xs={12}>
			<Divider style={{ marginBottom: 16 }} />
		</Grid>
	</Grid>;

Artifact.propTypes = {
	artifact: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	update: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
	authorsList: PropTypes.array.isRequired,
	requiredText: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

export default Artifact;