import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import RowIcons from '../widgets/RowIcons';
import Card from '../widgets/Card';
import { Link } from 'react-router-dom';

const TeamCard = React.memo(props => {
	const { team: t, canEdit } = props;

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<Card className="team-card">
                <div>
                    <span className="h4"><Link to={`/teams/${t.teamId}`}>{t.name}</Link></span>
                </div>
				<div className="description quill-display-block" dangerouslySetInnerHTML={{ __html: t.description }} />
                {t.teamLeaderName &&
                    <div style={{ marginTop: 10 }}>
                        <strong>Team Leader: {t.teamLeaderName}</strong><br />
                        <span>{t.members.length} {t.members.length === 1 ? "Member" : "Members"}</span>
                    </div>
                } 
                {!t.teamLeaderName &&
                    <div style={{ marginTop: 10 }}>
                        <span>{t.members.length} {t.members.length === 1 ? "Member" : "Members"}</span>
                    </div>
                } 
                
                {canEdit && t.teamId > 0 &&
                    <div style={{ marginTop: 10 }}>
                        <RowIcons
                            type="team"
                            editLink={`/teams/edit/${t.teamId}`}
                            deleteFunc={() => props.deleteTeam(t)}
                        />
                    </div>
                }
			</Card>
		</Grid>
	);
});

TeamCard.propTypes = {
	team: PropTypes.shape({
		teamId: PropTypes.number,
		name: PropTypes.string,
		description: PropTypes.string
	}).isRequired,
	canEdit: PropTypes.bool,
	deleteTeam: PropTypes.func
};

TeamCard.defaultProps = {
	canEdit: false,
	deleteTeam: undefined,
};

export default TeamCard;
