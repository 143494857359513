import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import ButtonLink from '../widgets/ButtonLink';
import Gallery from '../widgets/Gallery';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { parseYoutube } from '../../utils/utils';
import Button from '../widgets/Button';

const ViewActivity = props => {
	const { activity, canEdit } = props;

	return props.loading || isEmpty(activity) ? <CircularProgress size={24} style={{ marginLeft: 10 }} /> : (
		<Card className="activity-details">
			<h2>{activity.name}</h2>
			{activity.summary &&
				<section style={{ marginTop: 20 }}>
					<span className="h4">Summary</span>
					{activity.summary}
				</section>
			}
			{activity.description &&
				<section>
					<span className="h4">Description</span>
					<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: activity.description }} />
				</section>
			}
			{activity.materialsNeeded.length > 0 &&
				<section>
					<span className="h4">Materials Needed ({activity.materialsNeeded.length})</span>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Material</TableCell>
								<TableCell align="right">Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{map(activity.materialsNeeded, (m, i) =>
								<TableRow key={i}>
									<TableCell>{m.material}</TableCell>
									<TableCell>{m.amount}</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</section>
			}
			{activity.tags &&
				<section>
					<span className="h4">Keywords</span>
					{map(activity.tags, (t, i) => <Tag key={i} label={t.name} />)}
				</section>
			}
			<Gallery heading="Images" images={map(activity.images, image => ({ src: image.location }))} style={{ paddingBottom: 20 }} />
			{activity.videos.length > 0 && 
				<section>
					<span className="h4">Videos</span>
					<div className="youtube-embed">
						<div className="frame-wrapper">
							<iframe title="Videos" src={`//www.youtube.com/embed/${parseYoutube(activity.videos[0])}?playlist=${map(activity.videos, v => parseYoutube(v)).join(",")}`} frameBorder="" allowFullScreen />
						</div>
					</div>
				</section>
			}
			{activity.links && activity.links.length > 0 &&
				<section>
					<span className="h4">Links</span>
					<ul>
						{map(activity.links, (l, i) => 
							<li key={i}>{l.label} &mdash; <a href={`https://${l.to}`} target="_blank" rel="noopener noreferrer">{l.to}</a></li>
						)}
					</ul>
				</section>
			}
			{activity.files && activity.files.length > 0 &&
				<section>
					<span className="h4">Attachments</span>
					<ul className="list-unstyled">
						{map(activity.files, (file, i) =>
							<li key={i} className="media">
								<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
								<div className="media-body">
									<h5 className="mt-0 mb-1">{file.name}</h5>
									<p>{file.description}</p>
									<Button 
										variant="outlined" 
										onClick={() => window.open(`/Activity/DownloadAttachment?activityId=${activity.activityId}&activityAttachmentId=${file.activityAttachmentId}`, "_self")}
									>
										Download Now
									</Button>
								</div>
							</li>
						)}
					</ul>
				</section>
			}
			{canEdit && <ButtonLink to={`/activities/edit/${activity.activityId}`}>Edit activity</ButtonLink>}
		</Card>
	);
};

ViewActivity.propTypes = {
	activity: PropTypes.shape({
		activityId: PropTypes.number,
		organisationId: PropTypes.number,
		organisationName: PropTypes.string,
		country: PropTypes.string,
		organisationType: PropTypes.string,
		organisationLogo: PropTypes.string,
		name: PropTypes.string,
		summary: PropTypes.string,
		description: PropTypes.string,
		materialsNeeded: PropTypes.array,
		status: PropTypes.string,
		visibility: PropTypes.string,
		images: PropTypes.array,
		videos: PropTypes.array,
		tags: PropTypes.array
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	isAdmin: PropTypes.bool
};

ViewActivity.defaultProps = {
	canEdit: false,
	isAdmin: false
}

export default ViewActivity;
