import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { getReports, isLoading } from '../../../store/reports';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import DataTable from '../../widgets/dataTable/DataTable';
import SearchField from '../../widgets/SearchField';
import ButtonLink from '../../widgets/ButtonLink';
import Avatar from '../../widgets/Avatar';
import Card from '../../widgets/Card';
import PageLayout from '../../PageLayout';

const ManageReports = props => {
	const [search, setSearch] = React.useState("");

	React.useEffect(props.getReports, []);

	return <PageLayout title="Manage Reports" pageContent={
		<Card title="Search reports" loading={props.loading}>
			{/* Search Reports */}
			<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Reports Datatable */}
			<DataTable data={props.reports} search={search} tableFooter={<ButtonLink to="/admin/reports/add">Add report</ButtonLink>}>
				<DataTableColumn name="name" label="Name" renderer={report => 
					<Link to={`/admin/reports/${report.reportId}`} style={{ color: props.primaryColour }}>
						<Avatar src={report.image} fullName={report.name} style={{ marginRight: 5 }} />
						<span>{report.name}</span>
					</Link>
				} />
				<DataTableColumn name="category" label="Category" />
				<DataTableColumn name="visible" label="Visible" dataType="bool" />
				<DataTableColumn name="description" label="Description" />
			</DataTable>
		</Card>
	} breadcrumbs={["Manage reports"]} />;
};

ManageReports.propTypes = { 
	reports: PropTypes.array.isRequired,
	getReports: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	reports: state.reports.reports,
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getReports: () => dispatch(getReports())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageReports);