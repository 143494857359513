import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOffer, saveOffer, deleteOffer, isLoading } from '../../store/offers';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditOffer from './EditOffer';
import { canEditOffer } from '../../store/security';
import { revokeAccess } from '../../store/context';

const EditOfferController = props => {
	const { offerId } = props.match.params;

	React.useEffect(() => props.getOffer(offerId), [offerId]);

	React.useEffect(() => {
		if (props.offer.offerId === offerId && !props.canEdit) props.revokeAccess();
	}, [props.offer.offerId]);

	return <PageLayout title="Edit Offer" pageContent={
		<EditOffer
			offer={props.offer}
			saveOffer={props.saveOffer}
			deleteOffer={props.deleteOffer}
			saveResult={props.saveResult}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Offers", path: "/offers" }, "Edit Offer"]} />;
};

EditOfferController.propTypes = {
	offer: PropTypes.object.isRequired,
	saveOffer: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.offers.saveResult,
	offer: state.offers.offer,
	loading: isLoading(state),
	canEdit: canEditOffer(state)
});

const mapDispatchToProps = dispatch => ({
	getOffer: offerId => dispatch(getOffer(offerId)),
	saveOffer: (offer, files) => dispatch(saveOffer(offer, files)),
	deleteOffer: offerId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this offer?",
		onOk: () => dispatch(deleteOffer(offerId))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOfferController);
