import { createFetchAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	themes: []
};

const CLEAR_THEMES = "CLEAR_THEMES";
const GET_THEMES_REQUEST = "GET_THEMES_REQUEST";
const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";
const GET_THEMES_FAILURE = "GET_THEMES_FAILURE";
const SAVE_THEMES_REQUEST = "SAVE_THEMES_REQUEST";
const SAVE_THEMES_SUCCESS = "SAVE_THEMES_SUCCESS";
const SAVE_THEMES_FAILURE = "SAVE_THEMES_FAILURE";

export const isLoading = createLoadingSelector(["GET_THEMES", "SAVE_THEMES"]);

export const clearThemes = () => ({ type: CLEAR_THEMES });

export const getThemes = () =>
	createFetchAction({
		url: "/api/themes",
		startAction: GET_THEMES_REQUEST,
		onError: error => [getThemesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getThemesSuccess(data)
	});

export const getThemesSuccess = data => ({ type: GET_THEMES_SUCCESS, payload: { data } });
export const getThemesFailure = error => ({ type: GET_THEMES_FAILURE, payload: { error } });

export const saveThemes = themes => 
	createFormPostAction({
		url: "/api/themes",
		data: themes,
		startAction: SAVE_THEMES_REQUEST,
		onError: error => [saveThemesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveThemesSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/themes")];
			} else {
				return [saveThemesSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveThemesSuccess = data => ({ type: SAVE_THEMES_SUCCESS, data });
export const saveThemesFailure = error => ({ type: SAVE_THEMES_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_THEMES:
			return { ...state, themes: [] };
		case GET_THEMES_REQUEST:
			return { ...state, themes: [], isLoading: true };
		case GET_THEMES_SUCCESS:
			return { ...state, themes: action.payload.data, isLoading: false };
		case SAVE_THEMES_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		default:
			return state;
	}
};