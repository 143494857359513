import React from 'react';
import MakePublicDialog from './MakePublicDialog';
import {
    Share as MakePublicIcon
}from '@mui/icons-material';
import {
    Fab as FloatingActionButton
} from '@mui/material';

const MakePublicButton = React.memo(({  }) => {
	const [showDialog, setShowDialog] = React.useState(false);

	return (
		<div>
			<FloatingActionButton 
				size="small"
				style={{
					right: '10px',
					bottom: '80px',
					position: 'fixed',
					zIndex: '9999'
				}}
				onClick={() => {
					setShowDialog(true);
				}}
			>
				<MakePublicIcon />
			</FloatingActionButton>

			<MakePublicDialog
				open={showDialog}
				onClose={() => {
					setShowDialog(false);
				}}
			/>
		</div>
	);
});

MakePublicButton.propTypes = {
};

MakePublicButton.defaultProps = {
};

export default MakePublicButton;