import React from 'react';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import Card from '../widgets/Card';
import { SingleDatePicker } from 'react-dates';

const Help = props =>  {
	const [focus, setFocus] = React.useState(false);
	const [date2, setDate2] = React.useState(null);
	
	return (
		<PageLayout title="Help" pageContent={
			<Card>
				<SingleDatePicker
					date={date2} // momentPropTypes.momentObj or null
					onDateChange={date => {
						setDate2(date);
					}} // PropTypes.func.isRequired
					focused={focus} // PropTypes.bool
					onFocusChange={(args) => {
						console.log(args);
						setFocus(args.focused);
					}} // PropTypes.func.isRequired
					id="your_unique_id" // PropTypes.string.isRequired,
					withPortal
				/>
						{/* <SingleDatePicker  
						id="sptest"
					focused={focus}
					isOutsideRange={() => false}
					date={date2}
					placeholder={""}
					numberOfMonths={1}
					onDateChange={(newDate) => setDate2(newDate)}
							withPortal
							onFocusChange={({ f }) => setFocus({ f }) } 
						/> */}
				{/* <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.content }} /> */}
			</Card>
		} breadcrumbs={["Help"]} />
	);
};

export default connect(state => ({
	content: state.settings.helpContent
}), null)(Help);
