import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { parseYoutube } from '../../utils/utils';
import CreateDiscussionDialog from '../widgets/CreateDiscussionDialog';
import ViewDiscussionDialog from '../widgets/ViewDiscussionDialog';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	resourceDetails: {
		'& .academic': {
			'& svg': {
				color: theme.palette.secondary.main
			}
		}
	}
});

const ViewResource = props => {
	const [showCreateDialog, setShowCreateDialog] = React.useState(false);
	const [showViewDialog, setShowViewDialog] = React.useState(false);

	const { resource } = props;
	
	return props.loading || isEmpty(resource) ? <CircularProgress size={24} /> : (
		<Card className={`resource-details ${props.classes.resourceDetails}`}>
			<Grid container direction="row-reverse" alignContent="flex-end" spacing={3}>
				<Grid item xs="auto" style={{ marginBottom: 10 }}>
					<Button color="secondary" onClick={() => setShowCreateDialog(true)}>Create Discussion</Button>
					{resource.hasDiscussion && <Button onClick={() => setShowViewDialog(true)} style={{ marginLeft: 10 }}>View Discussion</Button>}
				</Grid>
				<Grid item xs={12} md>
					<h2 style={{ marginBottom: 0 }}>{resource.name}</h2>
					{resource.academicId &&
						<span className="h6 academic">
							<Tooltip title="Academic Author"><VerifiedUserIcon /></Tooltip>
							{resource.academicName}
						</span>
					}
					<span>
						<Tag label={resource.channel} colour={props.primaryColour} style={{ marginRight: 5 }} />
						<Tag label={resource.type} colour={props.secondaryColour} />
					</span>
				</Grid>
			</Grid>
			{resource.description &&
				<section>
					<span className="h4">Description</span>
					{resource.description}
				</section>
			}
			{resource.details && resource.details !== "<p><br></p>" &&
				<section>
					<span className="h4">Details</span>
					<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: resource.details }} />
				</section>
			}
			{resource.tags.length > 0 &&
				<section>
					<span className="h4">Tags</span>
					{map(resource.tags, (t, i) => <Tag key={i} label={t.name} />)}
				</section>
			}
			{resource.links.length > 0 &&
				<section>
					<span className="h4">Links</span>
					<ul>
						{map(resource.links, (l, i) => 
							<li key={i}>{l.label} &mdash; <a href={`https://${l.to}`} target="_blank" rel="noopener noreferrer">{l.to}</a></li>
						)}
					</ul>
				</section>
			}
			{resource.videos.length > 0 && 
				<section>
					<span className="h4">Videos</span>
					<div className="youtube-embed">
						<div className="frame-wrapper">
							<iframe title="Videos" src={`//www.youtube.com/embed/${parseYoutube(resource.videos[0])}?playlist=${map(resource.videos, v => parseYoutube(v)).join(",")}`} frameBorder="" allowFullScreen />
						</div>
					</div>
				</section>
			}
			{resource.attachments.length > 0 &&
				<section>
					<span className="h4">Attachments</span>
					<ul className="list-unstyled">
						{map(resource.attachments, (attachment, i) =>
							<li key={i} className="media">
								<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
								<div className="media-body">
									<h5 className="mt-0 mb-1">{attachment.name}</h5>
									<p>{attachment.description}</p>
									<Button 
										variant="outlined" 
										onClick={() => window.open(`/Resource/DownloadAttachment?resourceId=${resource.resourceId}&attachmentId=${attachment.attachmentId}`, "_self")}
									>
										Download Now
									</Button>
								</div>
							</li>
						)}
					</ul>
				</section>
			}
			{props.canEdit && 
				<ButtonLink to={`/${props.isCommunity ? "my-community/" : ""}resources/edit/${resource.resourceId}`} style={{ marginTop: 20 }}>
					Edit resource
				</ButtonLink>
			}
			<CreateDiscussionDialog
				open={showCreateDialog}
				channels={props.channels}
				discussion={{ topicType: "Resource", topicId: resource.resourceId }}
				disableTopic
				onClose={() => setShowCreateDialog(false)} 
				onSubmit={discussion => {
					setShowCreateDialog(false);
					props.saveDiscussion(discussion);
				}}
			/>
			<ViewDiscussionDialog 
				open={showViewDialog} 
				onClose={() => setShowViewDialog(false)}
				isCommunity={props.isCommunity} 
				search={{ resourceId: resource.resourceId }}
			/>
		</Card>
	);
};

ViewResource.propTypes = {
	resource: PropTypes.shape({
		resourceId: PropTypes.number,
		type: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
		links: PropTypes.array,
		videos: PropTypes.array,
		tenantId: PropTypes.number,
		createDate: PropTypes.string,
		createdBy: PropTypes.string,
		updateDate: PropTypes.string,
		updatedBy: PropTypes.string,
		attachments: PropTypes.array,
		tags: PropTypes.array
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	isCommunity: PropTypes.bool.isRequired,
	channels: PropTypes.array.isRequired,
	saveDiscussion: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	secondaryColour: PropTypes.string.isRequired
};

ViewResource.defaultProps = {
	canEdit: false
};

export default withStyles(ViewResource, styles);