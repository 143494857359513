import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions, 
	CircularProgress
	// SnackbarContent, 
	// IconButton, 
	// Grow
} from '@mui/material';
// import WarningIcon from '@mui/icons-material/Warning';
// import CloseIcon from '@mui/icons-material/Close';
import { 
	clearUnitsOfCompetency,
	getUnitsOfCompetency,
	getProfileUnitsOfCompetency,
	getFrameworkUnitsOfCompetency,
	isLoading as isCompetenciesLoading
} from '../../store/unitsOfCompetency';
import { getSkillsProfiles, isLoading as isProfilesLoading } from '../../store/skillsProfiles';
import Button from '../widgets/Button';
import Select from '../widgets/Select';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { get } from '../../utils/ajax';
import UnitOfCompetencyPickerUnits from './UnitOfCompetencyPickerUnits';
// import UnitOfCompetencyPickerBySpecialisation from './UnitOfCompetencyPickerBySpecialisation';

const UnitOfCompetencyPicker = props => {
	const dialogBody = React.useRef(null);
	const [state, setState] = React.useState({
		selectedProfile: "",
		selectedUnits: [],
		currentUnit: null,
		showPrompt: false,
		frameworks: [],
		selectedFramework: ""
	});
	// const warningColour = props.warningColour;

	React.useEffect(() => {
		if (props.enableFrameworkSelection) {
			get({
				url: `/api/frameworks?organisationId=${props.organisationId}`,
				onSuccess: frameworks => setState(prevState => ({ ...prevState, frameworks }))
			});
		}
	}, []);

	React.useEffect(() => {
		if (state.selectedFramework) props.getFrameworkUnitsOfCompetency(state.selectedFramework);
	}, [state.selectedFramework]);

	React.useEffect(() => {
		if (props.open && !props.enableFrameworkSelection) props.getSkillsProfiles(() => fetchUnits());
		setState({ ...state, selectedUnits: props.competencies || [], currentUnit: null, showPrompt: props.promptSelection, selectedFramework: "" });
	}, [props.open]);

	const selectElement = (u, e, selected) => {
		const selectedUnits = [...state.selectedUnits],
			unit = find(selectedUnits, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);

		if (selected) {
			let competent = true;
			if (u.criteria && u.criteria.length > 0) {
				competent = e.order >= u.criteria[0].elementOrder;
			}
			
			const element = {
				competencyElementId: 0,
				competencyId: u.competencyId,
				element: e.element,
				elementId: e.unitElementId,
				competent: competent,
				assessmentDate: new Date(),
				expiryDate: new Date(),
				order: e.order
			};

			if (unit) {
				unit.competent = competent;
				unit.elements = props.allowMultiple ? sortBy([...unit.elements, element], "order") : [element];
			} else {
				selectedUnits.push({
					frameworkCode: state.selectedFramework,
					competencyId: 0,
					unitCode: u.unitCode,
					unitTitle: u.unitTitle,
					unitOfCompetencyId: u.unitOfCompetencyId,
					unitDescriptionAndApplication: u.unitDescriptionAndApplication,
					competent: competent,
					reviewed: false,
					assessmentDate: new Date(),
					expiryDate: new Date(),
					elements: [element]
				});
			}
		} else {
			if (!props.allowMultiple || unit.elements.length === 1) {
				remove(selectedUnits, unit);
			} else {
				remove(unit.elements, e2 => e2.elementId === e.unitElementId);
			}
		}

		setState({ ...state, selectedUnits });
	}

	const setCompetent = (u, competent) => {
		const selectedUnits = [...state.selectedUnits],
			unit = find(selectedUnits, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);

		if (unit) {
			unit.competent = competent;
			unit.elements = [];
		} else {
			selectedUnits.push({
				frameworkCode: state.selectedFramework,
				competencyId: 0,
				unitCode: u.unitCode,
				unitTitle: u.unitTitle,
				unitOfCompetencyId: u.unitOfCompetencyId,
				unitDescriptionAndApplication: u.unitDescriptionAndApplication,
				competent,
				reviewed: false,
				assessmentDate: new Date(),
				expiryDate: new Date(),
				elements: []
			});
		}

		setState({ ...state, selectedUnits });
	}

	const fetchUnits = (profileId = props.skillsProfileId) => {
		if (profileId) {
			props.getProfileUnitsOfCompetency(profileId, props.otherUnits);
		} else {
			props.getUnitsOfCompetency();
		}

		setState(prevState => ({ ...prevState, selectedProfile: profileId || 0 }));
	}

	const viewUnit = unit => {
		setState({ ...state, currentUnit: unit, currentScroll: dialogBody.current.scrollTop });
		window.setTimeout(() => dialogBody.current.scrollTop = 0);
	}

	const onOk = () => {
		if (state.currentUnit) {
			setState({ ...state, currentUnit: null });
			window.setTimeout(() => dialogBody.current.scrollTop = state.currentScroll);
		} else {
			props.onOk(state.selectedUnits);
		}
	}

	const 
		// currentUnits = props.competencies.map(u => u.unitOfCompetencyId),
		{ currentUnit } = state,
		filtered = props.unitsOfCompetency,
		selectedElements = reduce(state.selectedUnits, (acc, u) => [...acc, ...u.elements], []);

	// const UnitComponent = props.graphicalSkillsView ? 
	// 	<UnitOfCompetencyPickerBySpecialisation
	// 		units={props.unitsOfCompetency}
	// 		selectedElements={selectedElements}
	// 		competencies={props.competencies}
	// 		selectElement={selectElement}
	// 		setCompetent={setCompetent}
	// 		viewUnit={viewUnit}
	// 		expandRows={props.expandRows}
	// 		showNotCompetent={props.showNotCompetent}
	// 		canEdit={props.canEdit}
	// 	/> : 
	// 	<UnitOfCompetencyPickerUnits
	// 		units={filtered}
	// 		selectedElements={selectedElements}
	// 		selectElement={selectElement}
	// 		setCompetent={setCompetent}
	// 		viewUnit={viewUnit}
	// 		unitsOfCompetency={props.unitsOfCompetency}
	// 		expandRows={props.expandRows}
	// 		showNotCompetent={props.showNotCompetent}
	// 		canEdit={props.canEdit}
	// 	/>
	const UnitComponent = 
		<UnitOfCompetencyPickerUnits
			units={filtered}
			selectedElements={selectedElements}
			selectElement={selectElement}
			setCompetent={setCompetent}
			viewUnit={viewUnit}
			unitsOfCompetency={props.unitsOfCompetency}
			expandRows={props.expandRows}
			showNotCompetent={props.showNotCompetent}
			canEdit={props.canEdit}
		/>;
		
	let okButtonLabel = "Ok";
	if (!currentUnit) {
		okButtonLabel = props.graphicalSkillsView ? "Save" : props.okButtonLabel; 
	}

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            fullWidth={true}
            maxWidth="xl"
            TransitionProps={{
                onExited: props.clearUnitsOfCompetency
            }}>
			<DialogTitle id="dialog-title">
				Units Of Competencies
			</DialogTitle>
			<>
				<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
					{props.loading ? <CircularProgress size={24} /> : 
						<React.Fragment>							
							{props.enableFrameworkSelection &&
								<Select
									value={state.selectedFramework}
									label="Framework"
									onChange={e => setState({ ...state, selectedFramework: e.target.value })}
									items={state.frameworks.map(f => f.frameworkCode)}
								/>
							}
							{!props.otherUnits && !props.enableFrameworkSelection &&
								<Select
									value={state.selectedProfile}
									label="Skills Profile"
									onChange={e => fetchUnits(e.target.value)}
									disabled
									items={[
										{ label: "none", value: 0 },
										...map(props.profiles, p => ({ label: `${p.profileName} (Unit Count: ${p.unitCount})`, value: p.profileId }))
									]}
								/>
							}

							{/*  disable - 1.11 "on 1st pop up screen to complete assessement remove notice at top of page (in black)"
							{props.mode !== "all" &&
							<Grow in={state.showPrompt} unmountOnExit>
								<SnackbarContent 
									message={
										<span style={{ display: "flex", alignItems: "center" }}>
											<WarningIcon style={{ marginRight: 8 }} />
											All competencies required for the current role must be selected...
										</span>
									}
									style={{ backgroundColor: warningColour, maxWidth: "100%", marginTop: 10 }}
									action={[
										<IconButton key="close" aria-label="close" color="inherit" onClick={() => setState({ ...state, showPrompt: false })}>
											<CloseIcon />
										</IconButton>
									]}
								/>
							</Grow>
							} */}
							{currentUnit ?
								<div style={{ marginTop: 20 }}>
									<h3>{currentUnit.unitCode} {currentUnit.unitTitle}</h3>
									{currentUnit.unitDescriptionAndApplication && <p>{currentUnit.unitDescriptionAndApplication}</p>}
									{currentUnit.performanceEvidence && 
										<React.Fragment>
											<h3>Performance Evidence/Skill</h3>
											<p>{currentUnit.performanceEvidence}</p>
										</React.Fragment>
									}
									{currentUnit.knowledgeEvidence && 
										<React.Fragment>
											<h3>Knowledge Evidence</h3>
											<p>{currentUnit.knowledgeEvidence}</p>
										</React.Fragment>
									}
									{currentUnit.elements && currentUnit.elements.length > 0 && 
										<React.Fragment>
											<h3>Elements/Levels</h3>
											<ul>{map(currentUnit.elements, (e, i) => <li key={i} className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />)}</ul>
										</React.Fragment>
									}
								</div> :
								UnitComponent
							}
						</React.Fragment>
					}
				</DialogContent>
			</>
			<DialogActions>
				<Button onClick={onOk}>{okButtonLabel}</Button>
				{!currentUnit && <Button onClick={props.onClose} autoFocus>Cancel</Button>}
			</DialogActions>
		</Dialog>
    );
};

UnitOfCompetencyPicker.propTypes = {
	unitsOfCompetency: PropTypes.arrayOf(PropTypes.object).isRequired,
	profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	otherUnits: PropTypes.bool,
	allowMultiple: PropTypes.bool,
	skillsProfileId: PropTypes.number,
	competencies: PropTypes.array,
	promptSelection: PropTypes.bool,
	canEdit: PropTypes.bool,
	expandRows: PropTypes.bool,
	enableFrameworkSelection: PropTypes.bool,
	showNotCompetent: PropTypes.bool,
	mode: PropTypes.string.isRequired,
	okButtonLabel: PropTypes.string,
	organisationId: PropTypes.number,
	graphicalSkillsView: PropTypes.bool,
	warningColour: PropTypes.string.isRequired
};

UnitOfCompetencyPicker.defaultProps = {
	otherUnits: false,
	allowMultiple: false,
	skillsProfileId: 0,
	competencies: [],
	promptSelection: false,
	canEdit: true,
	expandRows: true,
	enableFrameworkSelection: false,
	showNotCompetent: false,
	okButtonLabel: "Add",
	organisationId: 0,
	graphicalSkillsView: false
};

const mapStateToProps = state => ({
	unitsOfCompetency: state.unitsOfCompetency.unitsOfCompetency,
	profiles: state.skillsProfiles.skillsProfiles,
	loading: isCompetenciesLoading(state) || isProfilesLoading(state),
	warningColour: PropTypes.string.isRequired
});

const mapDispatchToProps = dispatch => ({
	clearUnitsOfCompetency: () => dispatch(clearUnitsOfCompetency()),
	getUnitsOfCompetency: () => dispatch(getUnitsOfCompetency()),
	getProfileUnitsOfCompetency: (profileId, other) => dispatch(getProfileUnitsOfCompetency(profileId, other)),
	getFrameworkUnitsOfCompetency: frameworkId => dispatch(getFrameworkUnitsOfCompetency(frameworkId)),
	getSkillsProfiles: onSuccess => dispatch(getSkillsProfiles(0, onSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitOfCompetencyPicker);