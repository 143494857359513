import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	courses: [],
	pageNum: null,
	pageSize: null,
	total: null,
	totalPages: null,
	course: {},
	importing: false,
	isFavouriting: {}
};

const CLEAR_COURSES = "CLEAR_COURSES";
const GET_COURSES_REQUEST = "GET_COURSES_REQUEST";
const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";
const SEARCH_COURSES_REQUEST = "SEARCH_COURSES_REQUEST";
const SEARCH_COURSES_SUCCESS = "SEARCH_COURSES_SUCCESS";
const SEARCH_COURSES_FAILURE = "SEARCH_COURSES_FAILURE";
const GET_COURSE_REQUEST = "GET_COURSE_REQUEST";
const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
const GET_COURSE_FAILURE = "GET_COURSE_FAILURE";
const SAVE_COURSE_REQUEST = "SAVE_COURSE_REQUEST";
const SAVE_COURSE_SUCCESS = "SAVE_COURSE_SUCCESS";
const SAVE_COURSE_FAILURE = "SAVE_COURSE_FAILURE";
const REQUEST_COURSE_REQUEST = "REQUEST_COURSE_REQUEST";
const REQUEST_COURSE_SUCCESS = "REQUEST_COURSE_SUCCESS";
const REQUEST_COURSE_FAILURE = "REQUEST_COURSE_FAILURE";
const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";
const COPY_COURSE_REQUEST = "COPY_COURSE_REQUEST";
const COPY_COURSE_SUCCESS = "COPY_COURSE_SUCCESS";
const COPY_COURSE_FAILURE = "COPY_COURSE_FAILURE";
const IMPORT_DATA_REQUEST = "IMPORT_DATA_REQUEST";
const IMPORT_DATA_SUCCESS = "IMPORT_DATA_SUCCESS";
const IMPORT_DATA_FAILURE = "IMPORT_DATA_FAILURE";
const FAVOURITE_COURSE_REQUEST = "FAVOURITE_COURSE_REQUEST";
const FAVOURITE_COURSE_SUCCESS = "FAVOURITE_COURSE_SUCCESS";
const FAVOURITE_COURSE_FAILURE = "FAVOURITE_COURSE_FAILURE";

export const isLoading = createLoadingSelector(["GET_COURSES", "SEARCH_COURSES", "SAVE_COURSE", "REQUEST_COURSE", "COPY_COURSE"]);

export const clearCourses = () => ({ type: CLEAR_COURSES });

export const getCourses = (pageNum, pageSize) =>
	createFetchAction({
		url: `/api/courses?pageNum=${pageNum}&pageSize=${pageSize}`,
		startAction: GET_COURSES_REQUEST,
		onError: error => [getCoursesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCoursesSuccess(data)
	});

export const getCoursesSuccess = data => ({ type: GET_COURSES_SUCCESS, payload: { data } });
export const getCoursesFailure = error => ({ type: GET_COURSES_FAILURE, payload: { error } });

export const searchCourses = (args, pageNum, pageSize) => 
	createFetchAction({
		url: `/api/courses/search?PageNum=${pageNum}&PageSize=${pageSize}&${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_COURSES_REQUEST,
		onError: error => [searchCoursesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchCoursesSuccess(data)
	});

export const searchCoursesSuccess = data => ({ type: SEARCH_COURSES_SUCCESS, payload: { data } });
export const searchCoursesFailure = error => ({ type: SEARCH_COURSES_FAILURE, payload: { error } });

export const getCourse = courseId =>
	createFetchAction({
		url: `/api/courses/${courseId}`,
		startAction: GET_COURSE_REQUEST,
		onError: error => [getCourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCourseSuccess(data)
	});

export const getCourseSuccess = data => ({ type: GET_COURSE_SUCCESS, payload: { data } });
export const getCourseFailure = error => ({ type: GET_COURSE_FAILURE, payload: { error } });

export const saveCourse = (course, image, newFiles, fromAdmin) => {
	const formData = new FormData();

	formData.append("Course", encodeURIComponent(JSON.stringify(course)));
	if (image) formData.append("Image", image);

	forEach(newFiles, f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/courses",
		data: formData,
		startAction: SAVE_COURSE_REQUEST,
		onError: error => [saveCourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveCourseSuccess(data), showSuccessNotification(data.message), changeLocation(fromAdmin ? "/admin/courses" : "/courses")];
			} else {
				return [saveCourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}

export const saveCourseSuccess = data => ({ type: SAVE_COURSE_SUCCESS, data });
export const saveCourseFailure = error => ({ type: SAVE_COURSE_FAILURE, error });

export const requestCourse = course =>
	createFormPostAction({
		url: "/api/courses/request",
		data: course,
		startAction: REQUEST_COURSE_REQUEST,
		onError: error => [requestCourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [requestCourseSuccess(data), showSuccessNotification(data.message), changeLocation("/courses")];
			} else {
				return [requestCourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const requestCourseSuccess = data => ({ type: REQUEST_COURSE_SUCCESS, data });
export const requestCourseFailure = error => ({ type: REQUEST_COURSE_FAILURE, error });

export const deleteCourse = (courseId, fromAdmin) =>
	createPostAction({
		url: `/api/courses/${courseId}/delete`,
		startAction: DELETE_COURSE_REQUEST,
		onError: error => [deleteCourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteCourseSuccess(data), showSuccessNotification(data.message), changeLocation(fromAdmin ? "/admin/courses" : "/courses")];
			} else {
				return [deleteCourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteCourseSuccess = data => ({ type: DELETE_COURSE_SUCCESS, data });
export const deleteCourseFailure = error => ({ type: DELETE_COURSE_FAILURE, error });

export const copyCourse = (courseId, title, includeCompetencies) =>
	createPostAction({
		url: `/api/courses/${courseId}/copy?title=${title}&includeCompetencies=${includeCompetencies}`,
		startAction: COPY_COURSE_REQUEST,
		onError: error => [copyCourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [copyCourseSuccess(data), showSuccessNotification(data.message), changeLocation(`/courses/edit/${data.object}`)];
			} else {
				return [copyCourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const copyCourseSuccess = data => ({ type: COPY_COURSE_SUCCESS, data });
export const copyCourseFailure = error => ({ type: COPY_COURSE_FAILURE, error });

export const importData = (coursesFile, competenciesFile, replace, onSuccess) => {
	const formData = new FormData();

	formData.append("CoursesFile", coursesFile);
	formData.append("CompetenciesFile", competenciesFile);
	formData.append("Replace", replace);
	
	return createFormPostAction({
		url: "/api/courses/import",
		data: formData,
		startAction: IMPORT_DATA_REQUEST,
		onError: error => [importDataFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess();
				return [importDataSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [importDataSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}

export const importDataSuccess = data => ({ type: IMPORT_DATA_SUCCESS, data });
export const importDataFailure = error => ({ type: IMPORT_DATA_FAILURE, error });

export const favouriteCourse = courseId =>
	createPostAction({
		url: `/api/courses/${courseId}/favourite`,
		startAction: FAVOURITE_COURSE_REQUEST,
		startActionData: { courseId },
		onError: error => [favouriteCourseFailure(error, courseId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteCourseSuccess(data, courseId)];
			} else {
				return [favouriteCourseSuccess(data, courseId), showErrorNotification(data.message)];
			}
		}
	});

export const unfavouriteCourse = courseId =>
	createPostAction({
		url: `/api/courses/${courseId}/unfavourite`,
		startAction: FAVOURITE_COURSE_REQUEST,
		startActionData: { courseId },
		onError: error => [favouriteCourseFailure(error, courseId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteCourseSuccess(data, courseId)];
			} else {
				return [favouriteCourseSuccess(data, courseId), showErrorNotification(data.message)];
			}
		}
	});

export const favouriteCourseSuccess = (data, courseId) => ({ type: FAVOURITE_COURSE_SUCCESS, data, courseId });
export const favouriteCourseFailure = (error, courseId) => ({ type: FAVOURITE_COURSE_FAILURE, error, courseId });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_COURSES:
			return { ...state, courses: [], course: {} };
		case GET_COURSES_REQUEST:
			return { ...state, isLoading: true, courses: [] };
		case SEARCH_COURSES_REQUEST:
		case REQUEST_COURSE_REQUEST:
		case REQUEST_COURSE_SUCCESS:
			return { ...state, isLoading: true };
		case GET_COURSES_SUCCESS:
		case SEARCH_COURSES_SUCCESS:
			return { 
				...state, 
				isLoading: false, 
				courses: action.payload.data.data,
				pageNum: action.payload.data.pageNum,
				pageSize: action.payload.data.pageSize,
				total: action.payload.data.total,
				totalPages: action.payload.data.totalPages
			};
		case GET_COURSE_REQUEST:
			return { ...state, isLoading: true, course: {} };
		case GET_COURSE_SUCCESS:
			return { ...state, isLoading: false, course: action.payload.data };
		case SAVE_COURSE_REQUEST:
		case DELETE_COURSE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_COURSE_SUCCESS:
			return {
				...state,
				...(action.data.success && { course: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_COURSE_SUCCESS:
			return { ...state, courses: state.courses.filter(a => a.courseId !== action.data.objectId) };
		case IMPORT_DATA_REQUEST:
			return { ...state, importing: true };
		case IMPORT_DATA_SUCCESS:
		case IMPORT_DATA_FAILURE:
			return { ...state, importing: false };
			// ...(action.data.success && { courses: action.data.object }),
		case FAVOURITE_COURSE_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				message: null,
				isFavouriting: { ...state.isFavouriting, [action.data.courseId]: true }
			};
		case FAVOURITE_COURSE_FAILURE:
			return {
				...state,
				saveResult: emptySaveResult,
				isFavouriting: { ...state.isFavouriting, [action.courseId]: false }
			};
		case FAVOURITE_COURSE_SUCCESS:
			const { courseId, isFavourite, success, message } = action.data;

			return {
				...state,
				courses: map(state.courses, t => t.courseId === courseId ? { ...t, isFavourite } : t),
				course: state.course.courseId === courseId ? { ...state.course, isFavourite } : state.course,
				saveResult: { success, message },
				isFavouriting: { ...state.isFavouriting, [action.courseId]: false }
			}
		default:
			return state;
	}
};
