import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import RowIcons from '../widgets/RowIcons';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	contentCard: {
		'& .card-body': {
			'& .header': {
				'& span': {
					color: theme.palette.primary.main
				}
			}
		}
	}
});

const ContentCard = React.memo(props => {
	const { course: c, canEdit, colourPalette } = props;

	const tagColours = {};

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<Card className={`content-card ${props.classes.contentCard}`}>
				<Link className="header" to={`/resource-catalogue/${c.contentId}`}>
					{(c.thumbnail || c.providerLogo) && <img src={(c.thumbnail || c.providerLogo)} alt="Course" />}
					<div>
						<span className="h4">{c.title}</span>
						<span className="h5">{c.providerName}</span>
					</div>
				</Link>
				<div className="description quill-display-block" dangerouslySetInnerHTML={{ __html: c.description }} />
				<div className="footer">
					{map(c.tags, (t, i) => {
						let tagColour = tagColours[t.category || "NO_CATEGORY"];
						if (!tagColour) {
							tagColour = colourPalette[Object.keys(tagColours).length];
							tagColours[t.category || "NO_CATEGORY"] = tagColour;
						}

						return <Tag key={i} label={t.name} colour={tagColour} />;
					})}
					{c.accredited && props.accreditedLogo &&
						<div style={{ margin: "10px 0" }}>
							<img src={props.accreditedLogo} alt="Accredited" style={{ maxHeight: 40 }} />
						</div>
					}
					{canEdit &&
						<RowIcons
							type="course"
							editLink={`/resource-catalogue/edit/${c.contentId}`}
							deleteFunc={() => props.deleteContent(c.contentId)}
							copyFunc={props.copyContent}
						/>
					}
				</div>
			</Card>
		</Grid>
	);
});

ContentCard.propTypes = {
	course: PropTypes.object.isRequired,
	canEdit: PropTypes.bool,
	deleteCourse: PropTypes.func,
	copyCourse: PropTypes.func,
	organisationId: PropTypes.number.isRequired,
	accreditedLogo: PropTypes.string.isRequired,
	colourPalette: PropTypes.array.isRequired
};

ContentCard.defaultProps = {
	canEdit: false,
	deleteCourse: undefined,
	copyCourse: undefined
};

const mapStateToProps = state => ({
	colourPalette: state.theme.colourPalette
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ContentCard, styles));
