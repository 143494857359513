import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import Grid from '@mui/material/Grid';
import Card from '../../widgets/Card';
import { Chip } from '@mui/material';

const OrganisationCard = React.memo(({ organisation }) => 
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<Card className="organisation-card">
			<div className="header">
				<img src={organisation.logo} alt={`${organisation.name} Logo`} />
				<Link to={`/admin/organisations/edit/${organisation.organisationId}`} className="h3">{organisation.name}</Link>
			</div>
			<Chip color="primary" label={organisation.type} style={{ height: 20 }} />
			<Chip color="secondary" label={organisation.country} style={{ marginLeft: 5, height: 20 }} />
		</Card>
	</Grid>
);

OrganisationCard.propTypes = {
	organisation: PropTypes.shape({
		organisationId: PropTypes.number,
		name: PropTypes.string,
		country: PropTypes.string,
		type: PropTypes.string,
		logo: PropTypes.string
	}).isRequired
};

export default OrganisationCard;