import React from 'react';
import PropTypes from 'prop-types';
import TextEditor from '../widgets/TextEditor';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import { CircularProgress } from '@mui/material';
import FileUploader from '../widgets/FileUploader';

const CreateEnquiry = props => {
	const [enquiryContent, setEnquiryContent] = React.useState("");
	const [state, setState] = React.useState({
		fileName: null
	});


	const setFile = e => {
		const file = e.target.files[0];
		setState({ ...state, fileName: file.name, file: file });
	};

	return (
		<Card>
			<span className="h4" style={{ marginBottom: '10px' }}>
				Submit Enquiry
				{props.loading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
			</span>
			<TextEditor
				value={enquiryContent}
				onChange={content => setEnquiryContent(content)}
				placeholder="Placeholder text" 
				style={{ marginBottom: 20 }}
			/>
			<FileUploader value={state.fileName} onChange={setFile} placeholder="Attach a file" />

			<Button onClick={() => props.saveEnquiry(enquiryContent, state.file)} color="secondary" disabled={props.loading}>
				Submit
			</Button>
		</Card>
	);
};

CreateEnquiry.propTypes = {
	saveEnquiry: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

export default CreateEnquiry;