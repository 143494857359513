import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';

const form = {
	initValues: props => props.academic,
	fields: [
		{
			name: "fullName",
			label: "Full name",
			required: true,
			helpText: "Full name of academic"
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			helpText: "Current organisation"
		},
		{
			name: "email",
			label: "Public email",
			helpText: "Email displayed next to profile",
			required: true,
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "faculty",
			label: "Faculty",
			required: true,
			helpText: "Primary faculty within institution"
		},
		{
			name: "role",
			label: "Role",
			required: true,
			helpText: "Role within faculty"
		},
		{
			name: "qualifications",
			label: "Qualifications",
			required: true,
			multiline: true,
			helpText: "List any qualifications accumulated."
		},
		{
			name: "programs",
			label: "Programs",
			multiline: true,
			helpText: "Programs something something"
		},
		{
			name: "researchInterests",
			label: "Research interests",
			multiline: true,
			helpText: "List any interests in regards to the chosen field of study"
		},
		{
			name: "professionalSummary",
			label: "Professional summary",
			type: "textEditor",
			placeholder: "List any qualifications accumulated..."
		},
		{
			name: "institutionProfileUrl",
			label: "Institution profile URL",
			helpText: "Link to profile within chosen institution's website"
		},
		{
			name: "linkedInUrl",
			label: "LInkedIn URL",
			helpText: "Link to LinkedIn profile"
		},
		{
			name: "programEnrolled",
			label: "Program enrolled",
			helpText: "Name of program"
		},
		{
			name: "phone",
			label: "Phone number",
			helpText: "Contact number displayed on profile"
		},
		{
			name: "privateEmail",
			label: "Private email",
			helpText: "Preferred email for admin contact",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "avatar",
			label: "Avatar",
			type: "avatar"
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		},
		{
			name: "channelId",
			label: "Theme",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: channel => ({ label: channel.name, value: channel.channelId })
			}
		}
	]
};

const EditAcademic = props => {
	const { academic, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} academic`} loading={loading} className="edit-academic">
			<Form onSubmit={() => props.validateFields() && props.saveAcademic(props.values, props.formFiles.avatar)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.fullName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.channelId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.email()}</Grid>
					<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.faculty()}</Grid>
					<Grid item xs={12} sm={6}>{fields.role()}</Grid>
					<Grid item xs={12}>{fields.qualifications()}</Grid>
					<Grid item xs={12}>{fields.programs()}</Grid>
					<Grid item xs={12}>{fields.researchInterests()}</Grid>
					<Grid item xs={12}>{fields.professionalSummary()}</Grid>
					<Grid item xs={12} sm={6}>{fields.institutionProfileUrl()}</Grid>
					<Grid item xs={12} sm={6}>{fields.linkedInUrl()}</Grid>
					<Grid item xs={12}>{fields.programEnrolled()}</Grid>
					<Grid item xs={12} sm={6}>{fields.phone()}</Grid>
					<Grid item xs={12} sm={6}>{fields.privateEmail()}</Grid>
					<Grid item xs={12}>{fields.avatar()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} academic</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteAcademic(academic.academicId)}>
							Delete Academic
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditAcademic.propTypes = { 
	academic: PropTypes.object,
	saveAcademic: PropTypes.func.isRequired,
	deleteAcademic: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditAcademic.defaultProps = {
	academic: {},
	deleteAcademic: () => null,
	isNew: false
};

export default withForm(form)(EditAcademic);