
const initialState = {
};

const LOAD_PERSISTED_STATE = 'LOAD_PERSISTED_STATE';
const UPDATE_PERSISTED_STATE = 'UPDATE_PERSISTED_STATE';
const CLEAR_PERSISTED_STATE = 'CLEAR_PERSISTED_STATE';

export const loadPersistedState = (state) => ({ type: LOAD_PERSISTED_STATE, state });
export const updatePersistedState = (key, data) => ({ type: UPDATE_PERSISTED_STATE, key, data });
export const clearPersistedState = () => ({ type: CLEAR_PERSISTED_STATE });

export default (state = initialState, action) => {
	let newState = null;
	switch (action.type) {
		case LOAD_PERSISTED_STATE:
			return action.state ? {
				...initialState,
				...action.state
			} : state;
		case UPDATE_PERSISTED_STATE:
			newState = {
				...state
			};
			newState[action.key] = action.data;
			return newState;
		case CLEAR_PERSISTED_STATE:
			return initialState;
		default:
			return state;
	}
};
