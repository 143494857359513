import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewProblem from './ViewProblem';
import { 
	getProblemChat, 
	saveProblemChat, 
	deleteProblemChat,
	isLoading as isChatLoading 
} from '../../store/problemChat';
import { getProblem, archiveProblem, isLoading } from '../../store/problems';
import { canEditProblem, canSubmitOffers } from '../../store/security';
import { showModal } from '../../store/modal';
import { post } from '../../utils/ajax';
import capitalise from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { showWarningNotification } from '../../store/notifications';
import { goBack } from '../../store/location';

const ViewProblemController = props => {
	const { problemId } = props.match.params,
		{ single, plural, isCommunity } = props

	React.useEffect(() => {
		props.getProblem(single)(problemId);
		props.getProblemChat(problemId);
		
		post({ url: `/api/problems/${problemId}/log-view` });
	}, [problemId]);

	return <PageLayout title={`View ${single}`} pageContent={
		<ViewProblem 
			problem={props.problem} 
			currentUserId={props.currentUserId}
			isAdmin={props.isAdmin}
			archiveProblem={props.archiveProblem(single)}
			canEdit={props.canEdit}
			canSubmitOffers={props.canSubmitOffers}
			loading={props.loading} 
			chatLoading={props.chatLoading} 
			problemChat={props.problemChat} 
			saveProblemChat={props.saveProblemChat}
			deleteChatMessage={props.deleteChatMessage}
			canDeleteChatMessages={props.isAdmin}
			single={single}
			plural={plural}
			isCommunity={isCommunity}
		/>
	} breadcrumbs={[{ label: capitalise(plural), path: `/${isCommunity ? "my-community/" : ""}problems` }, `View ${single}`]} />;
};

ViewProblemController.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	problem: PropTypes.object.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canSubmitOffers: PropTypes.bool.isRequired,
	archiveProblem: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	chatLoading: PropTypes.bool.isRequired,
	problemChat: PropTypes.array.isRequired,
	saveProblemChat: PropTypes.func.isRequired,
	deleteChatMessage: PropTypes.func.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	isCommunity: PropTypes.bool
};

ViewProblemController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	problem: state.problems.problem,
	problemChat: state.problemChat.problemChat,
	loading: isLoading(state),
	chatLoading: isChatLoading(state),
	canEdit: canEditProblem(state),
	canSubmitOffers: canSubmitOffers(state),
	isAdmin: state.context.isAdministrator,
	single: state.settings.single,
	plural: state.settings.plural
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	deleteChatMessage: message => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this message?",
		onOk: () => dispatch(deleteProblemChat(message))
	})),
	getProblem: single => problemId => dispatch(getProblem(problemId), p => {
		if (isEmpty(p)) {
			dispatch(showWarningNotification(`The ${single} no longer exists`));
			dispatch(goBack());
		}
	}),
	getProblemChat: problemId => dispatch(getProblemChat(problemId)),
	saveProblemChat: (problemId, message) => dispatch(saveProblemChat(problemId, message)),
	archiveProblem: single => problemId => dispatch(showModal("CONFIRM_ARCHIVE", {
		title: "Archive?",
		message: `Are you sure you want to archive this ${single}?`,
		onOk: () => dispatch(archiveProblem(problemId, ownProps.isCommunity))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProblemController);