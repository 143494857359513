import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getGroup, saveGroup, deleteGroup, isLoading } from '../../../store/reports';
import { showModal } from '../../../store/modal';
import PageLayout from '../../PageLayout';
import EditGroup from './EditGroup';

const EditGroupController = props => {
	React.useEffect(() => props.getGroup(props.match.params.groupId), [props.match.params.groupId]);

	return <PageLayout title="Edit report group" pageContent={
		<EditGroup
			group={props.group}
			saveGroup={props.saveGroup}
			deleteGroup={props.deleteGroup}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Manage report groups", path: "/admin/report-groups" }, "Edit report group"]} />;
};

EditGroupController.propTypes = {
	group: PropTypes.object.isRequired,
	getGroup: PropTypes.func.isRequired,
	saveGroup: PropTypes.func.isRequired,
	deleteGroup: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	group: state.reports.group,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getGroup: groupId => dispatch(getGroup(groupId)),
	saveGroup: group => dispatch(saveGroup(group)),
	deleteGroup: groupId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this report group?",
		onOk: () => dispatch(deleteGroup(groupId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupController);