import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../widgets/Card';
import { Link } from 'react-router-dom'
import DataTable from '../../widgets/dataTable/DataTable';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import SearchField from '../../widgets/SearchField';
import ButtonLink from '../../widgets/ButtonLink';
import PageLayout from '../../PageLayout';
import { getRoles, isLoading } from '../../../store/roles';

const ManageRoles = props => {
	const [search, setSearch] = React.useState("");

	React.useEffect(props.getRoles, []);

	return <PageLayout title="Manage Roles" pageContent={
		<Card title="Search roles" loading={props.loading}>
			{/* Search Roles */}
			<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Roles Datatable */}
			<DataTable data={props.roles} search={search} tableFooter={<ButtonLink to="/admin/roles/add">Add new role</ButtonLink>}>
				<DataTableColumn name="name" label="Name" renderer={r => 
					<Link to={`/admin/roles/${r.roleId}`} style={{ color: props.primaryColour }}>
						<span>{r.name}</span>
					</Link>
				} />
				<DataTableColumn name="description" label="Description" />
				<DataTableColumn label="Menus" align="center" renderer={r => r.menus.length} />
				<DataTableColumn label="Is System Role" align="center" renderer={r => r.tenantId === null ? "Yes" : "No"} />
				{props.isGlobalAdministrator && <DataTableColumn name="tenantName" label="Tenant" />}
			</DataTable>
		</Card>
	} breadcrumbs={["Manage roles"]} />;
};

ManageRoles.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	getRoles: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	roles: state.roles.roles,
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getRoles: () => dispatch(getRoles())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageRoles);