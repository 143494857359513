import { get, post } from './ajax';
import { showErrorNotification } from '../store/notifications';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import { showModal } from '../store/modal';
import { addFailedAjaxCall } from '../store/context';
import history from './history';

let isLoaded = false;
window.addEventListener('load', () => {
	isLoaded = true;
});

const dispatchActions = (dispatch, actions) => {
	if (!actions) return;
	forEach(isArray(actions) ? actions : [actions], action => dispatch(action));
};

export const createFetchAction = (args) => 
	(dispatch, getState) => {
		get({
			url: args.url,
			data: args.data,
			onSuccess: (data) => {
				if (args.onSuccess) {
					const result = args.onSuccess.call(this, data, dispatch);
					dispatchActions(dispatch, result);
				}
			},
			onError: (error) => {
				if (args.onError) {
					const result = args.onError.call(this, error);
					dispatchActions(dispatch, result);
				} else {
					dispatch(showErrorNotification(error.message));
				}
			},
			onSessionTimeout: isLoaded ? () => {
				dispatch(addFailedAjaxCall({
					type: 'get',
					args: args
				}));
				if (history.location.pathname !== "/login") dispatch(showModal("LOGIN", { }));
			} : undefined
		});

		dispatch({ type: args.startAction, payload: null, data: args.startActionData || args.data });
	};

export const createPostAction = (args) => 
	(dispatch, getState) => {
		post({
			url: args.url,
			data: args.data,
			onSuccess: (data) => {
				if (args.onSuccess) {
					const result = args.onSuccess.call(this, data, dispatch);
					dispatchActions(dispatch, result);
				}
			},
			onError: (error) => {
				if (args.onError) {
					const result = args.onError.call(this, error);
					dispatchActions(dispatch, result);
				} else {
					dispatch(showErrorNotification(error.message));
				}
			},
			onSessionTimeout: isLoaded ? () => {
				dispatch(addFailedAjaxCall({
					type: 'post',
					args: args
				}));
				if (history.location.pathname !== "/login") dispatch(showModal("LOGIN", { }));
			} : undefined
		});

		dispatch({ type: args.startAction || null, payload: null, data: args.startActionData || args.data });
	};

export const createFormPostAction = (args) => 
	(dispatch, getState) => {
		post({
			url: args.url,
			data: args.data,
			onProgress: (progress) => {
				if (args.onProgress) {
					const result = args.onProgress.call(this, progress, dispatch);
					dispatchActions(dispatch, result);
				}
			},
			onSuccess: (data) => {
				if (args.onSuccess) {
					const result = args.onSuccess.call(this, data, dispatch, getState);
					dispatchActions(dispatch, result);
				}
			},
			onError: (error) => {
				if (args.onError) {
					const result = args.onError.call(this, error);
					dispatchActions(dispatch, result);
				} else {
					dispatch(showErrorNotification(error.message));
				}
			},
			onSessionTimeout: isLoaded ? () => {
				dispatch(addFailedAjaxCall({
					type: 'formPost',
					args: args
				}));
				if (history.location.pathname !== "/login") dispatch(showModal("LOGIN", { }));
			} : undefined
		});

		dispatch({ type: args.startAction || null, payload: null, data: args.startActionData || args.data });
	};