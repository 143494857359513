import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';
import DOMPurify from 'dompurify';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	students: [],
	student: {}
};

const CLEAR_STUDENTS = "CLEAR_STUDENTS";
const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST";
const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
const GET_STUDENTS_FAILURE = "GET_STUDENTS_FAILURE";
const SEARCH_STUDENTS_REQUEST = "SEARCH_STUDENTS_REQUEST";
const SEARCH_STUDENTS_SUCCESS = "SEARCH_STUDENTS_SUCCESS";
const SEARCH_STUDENTS_FAILURE = "SEARCH_STUDENTS_FAILURE";
const GET_STUDENT_REQUEST = "GET_STUDENT_REQUEST";
const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
const GET_STUDENT_FAILURE = "GET_STUDENT_FAILURE";
const SAVE_STUDENT_REQUEST = "SAVE_STUDENT_REQUEST";
const SAVE_STUDENT_SUCCESS = "SAVE_STUDENT_SUCCESS";
const SAVE_STUDENT_FAILURE = "SAVE_STUDENT_FAILURE";
const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
const DELETE_STUDENT_FAILURE = "DELETE_STUDENT_FAILURE";

export const isLoading = createLoadingSelector(["GET_STUDENTS", "SEARCH_STUDENTS", "GET_STUDENT"]);

export const getStudents = () =>
	createFetchAction({
		url: "/api/students",
		startAction: GET_STUDENTS_REQUEST,
		onError: error => [getStudentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getStudentsSuccess(data)
	});

export const getStudentsSuccess = data => ({ type: GET_STUDENTS_SUCCESS, payload: { data } });
export const getStudentsFailure = error => ({ type: GET_STUDENTS_FAILURE, payload: { error } });

export const searchStudents = args => 
	createFetchAction({
		url: `/api/students/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_STUDENTS_REQUEST,
		onError: error => [searchStudentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchStudentsSuccess(data)
	});

export const searchStudentsSuccess = data => ({ type: SEARCH_STUDENTS_SUCCESS, payload: { data } });
export const searchStudentsFailure = error => ({ type: SEARCH_STUDENTS_FAILURE, payload: { error } });

export const getStudent = studentId =>
	createFetchAction({
		url: `/api/students/${studentId}`,
		startAction: GET_STUDENT_REQUEST,
		onError: error => [getStudentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getStudentSuccess(data)
	});

export const getStudentSuccess = data => ({ type: GET_STUDENT_SUCCESS, payload: { data } });
export const getStudentFailure = error => ({ type: GET_STUDENT_FAILURE, payload: { error } });

export const saveStudent = (student, newFiles) => {
	// Store in temporary array to allow for link conversion
	const portfolio = [];

	// Stringify links for each portfolio item
	forEach(student.portfolio, item => {
		portfolio.push({ ...item, links: JSON.stringify(item.links) });
	});

	const formData = new FormData();
	formData.append("Student", encodeURIComponent(JSON.stringify({ ...student, portfolio })));
	forEach(newFiles, f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/students",
		data: formData,
		startAction: SAVE_STUDENT_REQUEST,
		onError: error => [saveStudentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveStudentSuccess(data), showSuccessNotification(data.message), changeLocation(`/students/${data.object.studentId}`)];
			} else {
				return [saveStudentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveStudentSuccess = data => ({ type: SAVE_STUDENT_SUCCESS, data });
export const saveStudentFailure = error => ({ type: SAVE_STUDENT_FAILURE, error });

export const deleteStudent = studentId =>
	createPostAction({
		url: `/api/students/${studentId}/delete`,
		startAction: DELETE_STUDENT_REQUEST,
		onError: error => [deleteStudentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteStudentSuccess(data), showSuccessNotification(data.message), changeLocation("/students")];
			} else {
				return [deleteStudentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteStudentSuccess = data => ({ type: DELETE_STUDENT_SUCCESS, data });
export const deleteStudentFailure = error => ({ type: DELETE_STUDENT_FAILURE, error });

const sanitizeStudent = student => {
	// Parse links for each item
	forEach(student.portfolio, item => {
		item.links = item.links ? JSON.parse(item.links) : []
	});

	return ({ 
		...student, 
		currentPrograms: DOMPurify.sanitize(student.currentPrograms) ,
		about: DOMPurify.sanitize(student.about)
	});
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_STUDENTS:
			return { ...state, students: [] };
		case GET_STUDENTS_REQUEST:
			return { ...state, students: [], isLoading: true };
		case SEARCH_STUDENTS_REQUEST:
			return { ...state, isLoading: true };
		case GET_STUDENTS_SUCCESS:
		case SEARCH_STUDENTS_SUCCESS:
			return { ...state, students: map(action.payload.data, sanitizeStudent), isLoading: false };
		case GET_STUDENT_REQUEST:
			return { ...state, student: {}, isLoading: true };
		case GET_STUDENT_SUCCESS:
			return { ...state, isLoading: true, student: sanitizeStudent(action.payload.data) };
		case SAVE_STUDENT_REQUEST:
		case DELETE_STUDENT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_STUDENT_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { student: sanitizeStudent(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_STUDENT_SUCCESS:
			return { ...state, students: state.students.filter(a => a.studentId !== action.data.objectId) };
		default:
			return state;
	}
};
