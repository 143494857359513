import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { getIPs, searchIP, deleteIP, isLoading } from '../../store/ipRegister';
import { showModal } from '../../store/modal';
import { getQueryParameters } from '../../utils/utils';
import { canEditIP } from '../../store/security';
import Card from '../widgets/Card';
import SearchField from '../widgets/SearchField';
import AllIP from './AllIP';

const IPsController = props => {
	const [search, setSearch] = React.useState("");

	React.useEffect(() => {
		const { search = "" } = getQueryParameters(props.location.search);
	
		setSearch(search);
		props.searchIP(search);
	}, []);

	return <PageLayout title="Registered IP" pageContent={
		<React.Fragment>
			<Card title="Search IP" subtitle="Search the current collection of registered IP">
				<SearchField
					value={search} 
					onChange={value => setSearch(value)} 
					onSubmit={() => props.searchIP(search)}
					loading={props.loading}
				/>
			</Card>
			<AllIP ips={props.ips} loading={props.loading} deleteIP={props.deleteIP} canEdit={props.canEdit} /> 
		</React.Fragment>
	} breadcrumbs={["Registered IP"]} />;
};

IPsController.propTypes = { 
	ips: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	ips: state.ipRegister.ips,
	loading: isLoading(state),
	canEdit: canEditIP(state)
});

const mapDispatchToProps = dispatch => ({
	searchIP: search => dispatch(search ? searchIP(search) : getIPs()),
	deleteIP: ipId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this IP?",
		onOk: () => dispatch(deleteIP(ipId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(IPsController);
