import React from 'react';
import PropTypes from 'prop-types';
import { 
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip
} from '@mui/material';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import CollapseIcon from '@mui/icons-material/ExpandLess';
import CheckIcon from '@mui/icons-material/Check';
import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';
import times from 'lodash/times';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import {
	pSBC
} from '../../utils/utils';
import tinycolor from 'tinycolor2';

const UnitOfCompetencyPickerBySpecialisation = props => {
	const [expandedSpecialisations, setExpandedSpecialisations] = React.useState([]);
	const unitsBySpecialisationGroup = groupBy(props.units, 'specialisationGroupId');
	const [expandedGroups, setExpandedGroups] = React.useState([]);

	React.useEffect(() => {
		let initialExpandedSpecialisations = [];
		const initalExpandedGroups = map(unitsBySpecialisationGroup, (items, key) => {
			if (props.expandRows) {
				initialExpandedSpecialisations = initialExpandedSpecialisations.concat(uniq(map(items, i => i.specialisationId.toString())));
			}
			return key; 
		});
		setExpandedGroups(initalExpandedGroups);
		setExpandedSpecialisations(initialExpandedSpecialisations);

	}, [props.units])

	const minLevel = 1;
	let maxLevel = 1;
	// const levelNames = [];
	forEach(props.units, u => {
		forEach(u.elements, e => {
			if (e.order > maxLevel) maxLevel = e.order;
			// if (levelNames.length < (maxLevel - minLevel + 1)) levelNames.push(e.element);
		});
	});

	return (
        <Table>
			<TableHead>
				<TableRow>
					<TableCell style={{ width: 35 }}></TableCell>
					<TableCell style={{ width: '100%' }}></TableCell>
					{
						times(maxLevel - minLevel + 1, i => 
							<TableCell key={i} style={{ textAlign: 'center', minWidth: '100px' }}>{i + 1}</TableCell>
						)
					}
				</TableRow>
			</TableHead>

			<TableBody>
				{(() => {
					const rows = [];

					forEach(unitsBySpecialisationGroup, (groupUnits, groupId) => {
						const specialisationGroup = groupUnits[0].specialisationGroup;
						const specialisationGroupColour = tinycolor(groupUnits[0].specialisationGroupColour);
						const isGroupExpanded = expandedGroups.indexOf(groupId) > -1;

							
						if (rows.length > 0) {
							rows.push(
								<TableRow key={`spec-group-${groupId}-divider`}>
									<TableCell colSpan={maxLevel - minLevel + 3}></TableCell>
								</TableRow>
							)
						}

						rows.push(
							<TableRow 
								key={`spec-group-${groupId}`}
								style={{ 
									background: specialisationGroupColour.toHexString(),
									color: specialisationGroupColour.isLight() ? '#000' : '#fff'
								}}
							>
								<TableCell 
									colSpan={1}
									style={{ 
										backgroundColor: specialisationGroupColour.toHex8String(), 
										color: specialisationGroupColour.isLight() ? '#000' : '#fff'
									}}
								>
									<IconButton
                                        style={{ 
											color: specialisationGroupColour.isLight() ? '#000' : '#fff'
										}}
                                        onClick={() => {
											if (isGroupExpanded) {
												setExpandedGroups(filter(expandedGroups, g => g !== groupId));
											} else {
												setExpandedGroups([...expandedGroups, groupId])
											}
										}}
                                        size="large">
										{isGroupExpanded ? <CollapseIcon /> : <ExpandIcon />}
									</IconButton>
								</TableCell>
								<TableCell 
									colSpan={maxLevel - minLevel + 2} 
									style={{ 
										backgroundColor: specialisationGroupColour.toHex8String(), 
										color: specialisationGroupColour.isLight() ? '#000' : '#fff',
										fontWeight: 'bold', 
										position: 'relative' 
									}}
								>
									{/* <span style={cellHint}>Specialisation Group</span> */}
									{specialisationGroup}
								</TableCell>
							</TableRow>
						);
						
						
						if (isGroupExpanded) {
							const unitsBySpecialisation = groupBy(groupUnits, 'specialisationId');
							forEach(unitsBySpecialisation, (units, specialisationId) => {
								const specialisation = units[0].specialisation;
								const specialisationColour = tinycolor(units[0].specialisationColour);
								const unitColour = units[0].unitColour ? tinycolor(units[0].unitColour) : specialisationGroupColour;
								const isExpanded = expandedSpecialisations.indexOf(specialisationId) > -1;

								rows.push(
									<TableRow key={`spec-${specialisationId}`}>
										<TableCell 
											colSpan={1} 
											style={{ 
												backgroundColor: specialisationColour.toHexString() , 
												color: specialisationColour.isLight() ? '#000' : '#fff'
											}}
										>
											<IconButton
                                                style={{ 
													color: specialisationColour.isLight() ? '#000' : '#fff'
												}}
                                                onClick={() => {
													if (isExpanded) {
														setExpandedSpecialisations(filter(expandedSpecialisations, s => s !== specialisationId));
													} else {
														setExpandedSpecialisations([...expandedSpecialisations, specialisationId])
													}
												}}
                                                size="large">
												{isExpanded ? <CollapseIcon /> : <ExpandIcon />}
											</IconButton>
										</TableCell>
										<TableCell 
											colSpan={maxLevel - minLevel + 2} 
											style={{ 
												backgroundColor: specialisationColour.toHex8String(), 
												color: specialisationColour.isLight() ? '#000' : '#fff', 
												fontWeight: 'bold', 
												position: 'relative'
											}}
										>
											{/* <span style={cellHint}>Specialisation</span> */}
						
											{specialisation}
										</TableCell>
									</TableRow>
								);

								const invalidElementStyles = {
								};
								const backgroundColor = unitColour.toHexString();
								const selectedBackground = pSBC(-0.3, backgroundColor);
								const validElementStyles = {
									textAlign: 'left',
									overflow: 'visible',
									whiteSpace: 'nowrap',
									maxWidth: '70px',
									fontWeight: 'bold',
									cursor: props.canEdit ? 'pointer' : 'auto',
									border: '1px solid #fff'
								};

								if (isExpanded) {
									forEach(units, u => {
										let maxElement = null, minElement = null;
										forEach(u.elements, e => {
											if (minElement === null || e.order < minElement) minElement = e.order;
											if (maxElement === null || e.order > maxElement) maxElement = e.order;
										});

										const rowKey = `unit-${u.unitOfCompetencyId}`;

										rows.push(
											<TableRow key={rowKey}>
												<TableCell key={`${rowKey}-x`} colSpan={2}>{u.unitTitle}</TableCell>
												{times(maxLevel - minLevel + 1, i => {
													if (i + 1 < minElement) {
														return <TableCell key={`${rowKey}-${i}`} style={invalidElementStyles}></TableCell>;
													}
													if (i + 1 > maxElement) {
														return <TableCell key={`${rowKey}-${i}`} style={invalidElementStyles}></TableCell>;
													}
													const e = find(u.elements, e => e.order === i + 1);
													let isSelected = false;
													let tooltip = '';
													if (e) {
														isSelected = some(props.selectedElements, e2 => e2.elementId === e.unitElementId); // || (competency && some(competency.elements, e2 => e2.elementId === e.unitElementId));
														tooltip = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />;
														if (e.items && e.items.length > 0) {
															if (e.element) {
																tooltip = (
																	<React.Fragment>
																		<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />
																		<br />
																		<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.items[0].performanceCriteriaName }} />
																	</React.Fragment>
																);
															} else {
																tooltip = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.items[0].performanceCriteriaName }} />;
															}
														};
													}
													const cellBackground = tinycolor(isSelected ? selectedBackground : backgroundColor);
													return (
														<Tooltip 
															key={`${rowKey}-${i}`}
															title={tooltip} 
															disableFocusListener
															arrow
														>
															<TableCell 
																key={`${rowKey}-${i}`}
																style={{
																	...validElementStyles,
																	backgroundColor: cellBackground.toHexString(),
																	color: cellBackground.isLight() ? '#000' : '#fff',
																	position: 'relative',
																	padding: 0
																}}
																onClick={() => {
																	if (props.canEdit) props.selectElement(u, e, !isSelected)
																}}
															>{isSelected && <CheckIcon style={{ position: 'absolute', top: '3px', width: '100%' }} />}</TableCell>
														</Tooltip>
													);
												})}
											</TableRow>
										);
									});
								}

							});
						}
					});

					return rows;
				})()}
			</TableBody>
		</Table>
    );
};

UnitOfCompetencyPickerBySpecialisation.propTypes = {
	selectElement: PropTypes.func,
	units: PropTypes.array.isRequired,
	selectedElements: PropTypes.array.isRequired,
	viewUnit: PropTypes.func.isRequired,
	expandRows: PropTypes.bool.isRequired,
	showNotCompetent: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired
};

UnitOfCompetencyPickerBySpecialisation.defaultProps = {
	selectElement: () => {}
};

export default UnitOfCompetencyPickerBySpecialisation;