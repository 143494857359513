/* eslint react/jsx-pascal-case: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCLO_Course, saveCLO_Course, deleteCLO_Course, copyCLO_Course, isLoading } from '../../store/cloCourses';
import { showModal } from '../../store/modal';
import { get } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import EditCLO_Course from './EditCLO_Course';
import { canAdminCLO_Courses } from '../../store/security';

const EditCLO_CourseController = props => {
	const [organisations, setOrganisations] = React.useState([]);

	React.useEffect(() => props.getCLO_Course(props.match.params.courseId), [props.match.params.courseId]);

	React.useEffect(() => {
		get({
			url: "/api/organisations?type=Organisation",
			onSuccess: data => setOrganisations(data.map(o => ({ label: o.name, value: o.organisationId })))
		});
	}, []);

	return <PageLayout title="Edit course" pageContent={
		<EditCLO_Course
			course={props.course}
			saveCLO_Course={props.saveCLO_Course(props.fromAdmin)}
			deleteCLO_Course={props.deleteCLO_Course(props.fromAdmin)}
			copyCLO_Course={props.copyCLO_Course}
			loading={props.loading}
			organisationId={props.organisationId}
			canAdminCLO_Courses={props.canAdminCLO_Courses}
			organisations={organisations}
		/>
	} breadcrumbs={[{ label: "Courses", path: "/clo-courses" }, "Edit course"]} />;
};

EditCLO_CourseController.propTypes = {
	course: PropTypes.object.isRequired,
	saveCLO_Course: PropTypes.func.isRequired,
	deleteCLO_Course: PropTypes.func.isRequired,
	copyCLO_Course: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	fromAdmin: PropTypes.bool,
	canAdminCLO_Courses: PropTypes.bool.isRequired
};

EditCLO_CourseController.defaultProps = {
	fromAdmin: false
};

const mapStateToProps = state => ({
	course: state.cloCourses.cloCourse,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canAdminCLO_Courses: canAdminCLO_Courses(state)
});

const mapDispatchToProps = dispatch => ({
	getCLO_Course: courseId => dispatch(getCLO_Course(courseId)),
	saveCLO_Course: fromAdmin => (course, imageFile, newFiles) => dispatch(saveCLO_Course(course, imageFile, newFiles, fromAdmin)),
	deleteCLO_Course: fromAdmin => courseId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this course?",
		onOk: () => dispatch(deleteCLO_Course(courseId, fromAdmin))
	})),
	copyCLO_Course: (courseId, title, includeCompetencies) => dispatch(copyCLO_Course(courseId, title, includeCompetencies))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCLO_CourseController);
