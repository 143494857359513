import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	academics: [],
	academic: {}
};

const CLEAR_ACADEMICS = "CLEAR_ACADEMICS";
const GET_ACADEMICS_REQUEST = "GET_ACADEMICS_REQUEST";
const GET_ACADEMICS_SUCCESS = "GET_ACADEMICS_SUCCESS";
const GET_ACADEMICS_FAILURE = "GET_ACADEMICS_FAILURE";
const SEARCH_ACADEMICS_REQUEST = "SEARCH_ACADEMICS_REQUEST";
const SEARCH_ACADEMICS_SUCCESS = "SEARCH_ACADEMICS_SUCCESS";
const SEARCH_ACADEMICS_FAILURE = "SEARCH_ACADEMICS_FAILURE";
const GET_ACADEMIC_REQUEST = "GET_ACADEMIC_REQUEST";
const GET_ACADEMIC_SUCCESS = "GET_ACADEMIC_SUCCESS";
const GET_ACADEMIC_FAILURE = "GET_ACADEMIC_FAILURE";
const SAVE_ACADEMIC_REQUEST = "SAVE_ACADEMIC_REQUEST";
const SAVE_ACADEMIC_SUCCESS = "SAVE_ACADEMIC_SUCCESS";
const SAVE_ACADEMIC_FAILURE = "SAVE_ACADEMIC_FAILURE";
const DELETE_ACADEMIC_REQUEST = "DELETE_ACADEMIC_REQUEST";
const DELETE_ACADEMIC_SUCCESS = "DELETE_ACADEMIC_SUCCESS";
const DELETE_ACADEMIC_FAILURE = "DELETE_ACADEMIC_FAILURE";

export const isLoading = createLoadingSelector(["GET_ACADEMICS", "SEARCH_ACADEMICS", "GET_ACADEMIC"]);

export const getAcademics = () =>
	createFetchAction({
		url: "/api/academics",
		startAction: GET_ACADEMICS_REQUEST,
		onError: error => [getAcademicsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getAcademicsSuccess(data)
	});

export const getAcademicsSuccess = data => ({ type: GET_ACADEMICS_SUCCESS, payload: { data } });
export const getAcademicsFailure = error => ({ type: GET_ACADEMICS_FAILURE, payload: { error } });

export const searchAcademics = args => 
	createFetchAction({
		url: `/api/academics/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_ACADEMICS_REQUEST,
		onError: error => [searchAcademicsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchAcademicsSuccess(data)
	});

export const searchAcademicsSuccess = data => ({ type: SEARCH_ACADEMICS_SUCCESS, payload: { data } });
export const searchAcademicsFailure = error => ({ type: SEARCH_ACADEMICS_FAILURE, payload: { error } });

export const getAcademic = academicId =>
	createFetchAction({
		url: `/api/academics/${academicId}`,
		startAction: GET_ACADEMIC_REQUEST,
		onError: error => [getAcademicFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getAcademicSuccess(data)
	});

export const getAcademicSuccess = data => ({ type: GET_ACADEMIC_SUCCESS, payload: { data } });
export const getAcademicFailure = error => ({ type: GET_ACADEMIC_FAILURE, payload: { error } });

export const saveAcademic = (academic, avatarFile) => {
	const formData = new FormData();
	formData.append("Academic", encodeURIComponent(JSON.stringify(academic)));
	if (avatarFile) formData.append("AvatarFile", avatarFile);

	return createFormPostAction({
		url: "/api/academics",
		data: formData,
		startAction: SAVE_ACADEMIC_REQUEST,
		onError: error => [saveAcademicFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveAcademicSuccess(data), showSuccessNotification(data.message), changeLocation(`/academics/${data.object.academicId}`)];
			} else {
				return [saveAcademicSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveAcademicSuccess = data => ({ type: SAVE_ACADEMIC_SUCCESS, data });
export const saveAcademicFailure = error => ({ type: SAVE_ACADEMIC_FAILURE, error });

export const deleteAcademic = academicId =>
	createPostAction({
		url: `/api/academics/${academicId}/delete`,
		startAction: DELETE_ACADEMIC_REQUEST,
		onError: error => [deleteAcademicFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteAcademicSuccess(data), showSuccessNotification(data.message), changeLocation(`/academics`)];
			} else {
				return [deleteAcademicSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteAcademicSuccess = data => ({ type: DELETE_ACADEMIC_SUCCESS, data });
export const deleteAcademicFailure = error => ({ type: DELETE_ACADEMIC_FAILURE, error });

const sanitizeAcademic = academic => ({ ...academic, professionalSummary: DOMPurify.sanitize(academic.professionalSummary) });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ACADEMICS:
			return { ...state, academics: [] };
		case GET_ACADEMICS_REQUEST:
			return { ...state, academics: [], isLoading: true };
		case SEARCH_ACADEMICS_REQUEST:
			return { ...state, isLoading: true };
		case GET_ACADEMICS_SUCCESS:
		case SEARCH_ACADEMICS_SUCCESS:
			return { ...state, academics: map(action.payload.data, sanitizeAcademic), isLoading: false };
		case GET_ACADEMIC_REQUEST:
			return { ...state, academic: {}, isLoading: true };
		case GET_ACADEMIC_SUCCESS:
			return { ...state, isLoading: true, academic: sanitizeAcademic(action.payload.data) };
		case SAVE_ACADEMIC_REQUEST:
		case DELETE_ACADEMIC_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_ACADEMIC_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { academic: sanitizeAcademic(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_ACADEMIC_SUCCESS:
			return { ...state, academics: state.academics.filter(a => a.academicId !== action.data.objectId) };
		default:
			return state;
	}
};
