import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import RowIcons from '../widgets/RowIcons';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';
import FavouriteButton from './FavouriteButton';

const styles = (theme) => ({
	contentCard: {
		'& .card-body': {
			'& .header': {
				'& span': {
					color: theme.palette.primary.main
				}
			}
		}
	}
});
const CLO_CourseCard = React.memo(props => {
	const { course: c, canEdit, colourPalette } = props;

	const tagColours = {};

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<Card className={`course-card ${props.classes.courseCard}`}>
				{React.createElement(!c.skipPreview && c.website ? "a" : Link, { className: "header", href: c.website, to: `/clo-courses/${c.clO_CourseId}` },
					(c.image || c.providerLogo) && <img src={c.image || c.providerLogo} alt="Course" />,
					<div>
						<span className="h4">{c.title}</span>
						<span className="h5">{c.providerName}</span>
					</div>
				)}
				<FavouriteButton clO_CourseId={c.clO_CourseId} isFavourite={c.isFavourite} />
				<div className="description quill-display-block" dangerouslySetInnerHTML={{ __html: c.description }} />
				<div className="footer">
					{map(c.tags, (t, i) => {
						let tagColour = tagColours[t.category || "NO_CATEGORY"];
						if (!tagColour) {
							tagColour = colourPalette[Object.keys(tagColours).length];
							tagColours[t.category || "NO_CATEGORY"] = tagColour;
						}

						return <Tag key={i} label={t.name} colour={tagColour} />;
					})}
					{map(c.channels, c => <Tag key={c.channelId} label={c.name} />)}
					{c.contactName &&
						<div style={{ marginTop: 10 }}>
							<strong>{c.contactName}</strong><br />
							<span>{c.contactEmail}</span>
						</div>
					}
					{canEdit &&
						<div style={{ marginTop: 10 }}>
							<RowIcons
								type="course"
								editLink={`/clo-courses/edit/${c.clO_CourseId}`}
								deleteFunc={() => props.deleteCLO_Course(c.clO_CourseId)}
								copyFunc={props.copyCLO_Course}
							/>
						</div>
					}
				</div>
			</Card>
		</Grid>
	);
});

CLO_CourseCard.propTypes = {
	course: PropTypes.shape({
		clO_CourseId: PropTypes.number,
		type: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		website: PropTypes.string,
		contactName: PropTypes.string,
		contactEmail: PropTypes.string,
		image: PropTypes.string,
		channels: PropTypes.arrayOf(PropTypes.object),
		organisations: PropTypes.arrayOf(PropTypes.object),
		mode: PropTypes.string,
		createdBy: PropTypes.string,
		createdByName: PropTypes.string,
		createdByAvatar: PropTypes.string,
		createdAt: PropTypes.string,
		updatedBy: PropTypes.string,
		updatedAt: PropTypes.string,
		providerId: PropTypes.number,
		providerName: PropTypes.string
	}).isRequired,
	canEdit: PropTypes.bool,
	deleteCLO_Course: PropTypes.func,
	copyCLO_Course: PropTypes.func,
	showCompetencies: PropTypes.func,
	organisationId: PropTypes.number.isRequired,
	fromAdmin: PropTypes.bool,
	colourPalette: PropTypes.array.isRequired
};

CLO_CourseCard.defaultProps = {
	canEdit: false,
	showCompetencies: undefined,
	deleteCLO_Course: undefined,
	copyCLO_Course: undefined,
	fromAdmin: false
};

const mapStateToProps = state => ({
	colourPalette: state.theme.colourPalette
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CLO_CourseCard, styles));

