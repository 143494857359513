import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import Button from './Button';
import ColourEditor from './ColourEditor';

const ColourDialog = React.memo(props => {
	const [colour, setColour] = React.useState(props.colour);

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
			<DialogTitle>Select Colour</DialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<ColourEditor
							value={colour}
							label="Colour"
							onChange={e => setColour(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button onClick={() => {
					props.onOk(colour);
					props.onClose();
				}}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
});

ColourDialog.propTypes = {
	colour: PropTypes.string,
	open: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onClose: PropTypes.func
};

ColourDialog.defaultProps = {
	colour: "#FFFFFF",
	onClose: () => {}
};

export default ColourDialog;
