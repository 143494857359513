import React from 'react';
import PropTypes from 'prop-types';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography
} from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import TextField from '../widgets/TextField';
import debounce from 'lodash/debounce';
import { get } from '../../utils/ajax';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import map from 'lodash/map';
import take from 'lodash/take';
import { channelImages } from './InsightImages';
import Link from 'react-router-dom/Link';

const form = {
	initValues: props => ({ authorId: props.userId, layoutType: "Flat", flatContent: props.insight ? props.insight.content : "", ...props.insight }),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of insight (max 60 characters)",
		},
		{
			name: "description",
			label: "Summary",
			multiline: true,
			helpText: "(max 250 characters)",
			max: 250,
		},
		{
			name: "authorName",
			label: "Author",
		},
		{
			name: "authors",
			label: "Authors",
		},
		{
			name: "content",
			label: "Research Insight",
			type: "textEditor",
			helpText: "(no more than 1,200 characters)",
			max: (props, values) => values.layoutType === "Flat" ? undefined : 1200
		},
		{
			name: "keyFindings",
			label: "Key Findings",
			type: "textEditor",
			helpText: "(no more than 2,400 characters)",
			max: 2400,
		},
		{
			name: "implicationsForPractice",
			label: "Implications For Practice",
			type: "textEditor",
			helpText: "(no more than 2,400 characters)",
			max: 2400,
		},
		{
			name: "links",
			label: "Website Links",
			type: "dragList",
			itemTemplate: { label: "", to: "" },
			renderItem: (item, index, updateItem) =>
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={4}>
						<TextField label="Label" value={item.label} onChange={e => updateItem(e.target.value, "label")} />
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField startAdornment="https://" label="Link" value={item.to} onChange={e => updateItem(e.target.value, "to")} />
					</Grid>
				</Grid>
		},
		{
			name: "videos",
			label: "Videos",
			type: "dragList",
			itemTemplate: "",
			renderItem: (value, index, updateItem) =>
				<TextField key={index} label="Link" value={value} onChange={e => updateItem(e.target.value)} />
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true
		},
		{
			name: "expertId",
			label: "Expert",
			type: "autoComplete",
			loadItems: {
				route: "experts",
				mapItem: e => ({ ...e, label: e.fullName, value: e.expertId })
			}
		},
		{
			name: "channels",
			label: "Channels",
			type: "autoComplete",
			isMulti: true,
			isSingleMulti: true,
			loadItems: {
				route: "channels",
				mapItem: c => ({ ...c, label: c.name, value: c.channelId })
			},
			mapValue: c => ({ ...c, label: c.name, value: c.channelId }),
			chipColour: (props) => props.primaryColour,
			validate: channels => channels.length > 1 ? "Please select only one channel" : ""
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		},
		{
			name: "attachments",
			label: "Attachments",
			type: "insightAttachmentEditor",
			download: attachment => {
				window.open(`/Insight/DownloadAttachment?insightId=${attachment.insightId}&attachmentId=${attachment.insightAttachmentId}`, "_self");
			}
		},
		{
			name: "isBlog",
			label: "Is Blog",
			type: "checkbox"
		},
		{
			name: "isJournalArticle",
			label: "Is Journal Article",
			type: "checkbox"
		},
		{
			name: "isVideo",
			label: "Is Video",
			type: "checkbox"
		},
		{
			name: "articleType",
			label: "Article Type",
			required: true,
			type: "select",
			items: ['Blog', 'Publication', 'Video']
		},
		{
			name: "contentType",
			label: "Publication Type",
			required: true,
			type: "select",
			items: [
				{ label: "Academic Authored", value: "Academic" },
				{ label: "Industry Authored", value: "Industry" },
				{ label: "Market Insight", value: "MarketInsight" }
			]
		},
		{
			name: "layoutType",
			type: "custom",
			widget: props => 
				<FormControl fullWidth={true} error={props.error} style={{
					textAlign: 'left',
					...props.style
				}}>
					<FormLabel>Layout:</FormLabel>
					<RadioGroup id="insight-layout" row value={props.value} onChange={e => props.onChange(e.target.value)} >
						<FormControlLabel value="Structured" control={<Radio />} label="Structured" />
						<FormControlLabel value="Flat" control={<Radio />} label="Flat" />
					</RadioGroup>
				</FormControl>
		},
		{
			name: "flatContent",
			label: "Research Insight",
			type: "textEditor",
			getValue: values => {
				return values.content;
			},
			setValues: (newValue, values) => ({ ...values, content: newValue, flatContent: newValue  }),
			required: (props, i) => i.layoutType === "Flat"
		}
	]
};

let cancelToken = null;

const getSimilarInsights = debounce((title, callback) => {
	callback(title);
}, 500);

const EditInsight = props => {
	const { insight, fields, values, loading, isNew } = props;
	const [similarInsights, setSimilarInsights] = React.useState({items: []});
	const [showSimilarInsights, setShowSimilarInsights] = React.useState(false);
	const [loadingSimilarInsights, setLoadingSimilarInsights] = React.useState(false);

	const fetchSimilarInsights = (title) => {
		setLoadingSimilarInsights(true);

		if (cancelToken) cancelToken.cancel();
		cancelToken = axios.CancelToken.source();

		get({
			url: `/api/insights/get-similar-insights?title=${title}`,
			cancelToken: cancelToken.token,
			onSuccess: data => {
				if (data.items.length > 0) {
					setShowSimilarInsights(true);
				}
				setLoadingSimilarInsights(false);
				setSimilarInsights(data);
			},
			onError: (a,b,c) => {
				setLoadingSimilarInsights(false);
				setSimilarInsights({items: []});
			}
		});

	};

	React.useEffect(() => {
		if (isNew && values) {
			if (values.title) {
				getSimilarInsights(values.title, (title) => {
					fetchSimilarInsights(title);
				});
			} else {
				setSimilarInsights({items: []});
			}
		}
	}, [values.title])

	return (
		<Card title={`${isNew ? "Create" : "Edit"} Insight`} loading={loading} className="edit-insight">
			<Form onSubmit={() => props.validateFields() && props.saveInsight(props.values, props.formFiles.image, props.formFiles.attachments)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.title()}</Grid>
					{showSimilarInsights &&
						<Grid item xs={12}>
							<Accordion
								defaultExpanded={true}
								style={{
									width: 'calc(100% + 40px)',
									marginLeft: '-20px',
									borderRadius: 0,
									background: '#eee',
									boxShadow: 'none',
									borderTop: '1px solid #ddd',
									borderBottom: '1px solid #ddd'
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
								>
								<Typography>Similar Insights</Typography>
								</AccordionSummary>
								<AccordionDetails
									style={{
										flexDirection: 'column',
										padding: 0
									}}
								>
									{loadingSimilarInsights && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
									{!loadingSimilarInsights && map(take(similarInsights.items, 2), (item) => {
										const i = item.item;
										const numChannels = i.channels ? i.channels.length : 0;
										const image = numChannels > 0 && channelImages[i.channels[0].name] ? channelImages[i.channels[0].name] : null;
										const link = `/insights/${i.insightId}`;
										const imageComponent = <img className="pcard-img-top img-responsive" src={image} />;
										return (
											<div
												style={{
													display: 'flex',
													borderTop: '1px solid #ddd',
													padding: '10px'
												}}
											>
												{image && <Link to={link} style={{ flex: 1 }}>{imageComponent}</Link>}
												<div style={{ flex: 6, paddingLeft: '10px' }}>
													<Link to={link}><h3>{i.title}</h3></Link>
													{i.authors &&
														<div className="insight-authors" style={{ fontStyle: 'italic', fontSize: 'small' }}>
															{i.authors}
														</div>
													}
												</div>
											</div>
										);
									})}
								</AccordionDetails>
							</Accordion>
						</Grid>
					}
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12} sm={6}>{fields.articleType()}</Grid>
					<Grid item xs={12} sm={6}>{fields.contentType()}</Grid>
					{props.canViewExperts &&
						<Grid item xs={12} sm={6}>{fields.expertId()}</Grid>
					}
					<Grid item xs={12}>{fields.authors()}</Grid>
					<Grid item xs={12}>{fields.image()}</Grid>
					{values.layoutType === "Structured" && 
						<React.Fragment>
							<Grid item xs={12}>{fields.content()}</Grid>
							<Grid item xs={12}>{fields.keyFindings()}</Grid>
							<Grid item xs={12}>{fields.implicationsForPractice()}</Grid>
						</React.Fragment>
					}
					{values.layoutType === "Flat" && 
						<React.Fragment>
							<Grid item xs={12}>{fields.flatContent()}</Grid>
						</React.Fragment>
					}
					<Grid item xs={12}>{fields.links()}</Grid>
					<Grid item xs={12}>{fields.videos()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
					<Grid item xs={12}>{fields.channels()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} insight</Button>
					{!isNew &&
						<Button className="delete-button" loading={loading} onClick={() => props.deleteInsight(insight.insightId)}>
							Delete insight
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditInsight.propTypes = {
	insight: PropTypes.object,
	saveInsight: PropTypes.func.isRequired,
	deleteInsight: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	canViewExperts: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

EditInsight.defaultProps = {
	insight: {},
	deleteInsight: null,
	isNew: false
};

export default withForm(form)(EditInsight);