import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { sectionColours, sectionIcons } from '../utils/utils';
import { isStatsLoading } from '../store/dashboard';
import { canViewProps } from '../store/security';
import UnitsOfCompetencyDialog from './passport/UnitsOfCompetencyDialog';
import capitalise from 'lodash/capitalize';
import map from 'lodash/map';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { withStyles } from 'tss-react/mui';
import MakePublicButton from './widgets/MakePublicButton';

const styles = (theme) => ({
	pageTitle: {
		'& h1': {
			color: theme.palette.primary.main
		}
	}
});

const statKeys = {
	academics: "academicCount",
	careerPathway: () => "Career",
	offer: "offerCount",
	centres: "researchCentreCount",
	competencies: "competencyCount",
	courses: "courseCount",
	events: "eventCount",
	tools: "toolCount",
	organisations: "institutionCount",
	problems: "problemCount",
	reports: "reportCount",
	users: "userCount"
};

const statCountStyle = {
	careerPathway: {
		fontSize: '16px'
	}
};

const PageLayout = props => {
	const { title, breadcrumbs, isLoggedIn } = props;

	const [showUnitsDialog, setShowUnitsDialog] = React.useState(false);

	const Stat = ({ name, section, label, link, onClick }) => {
		if (!props.canView[section || name] || !statKeys[name]) return null;
		
		const Icon = sectionIcons[section || name],
			count = isFunction(statKeys[name]) ? statKeys[name]() : props.stats[statKeys[name]],
			to = onClick ? null : link || `/${name}`;

		return (
			<div className="stat">
				{React.createElement(to ? Link : "div", { to, className: "stat-icon", onClick, "aria-label": label, style: { background: sectionColours[name] } }, <Icon />)}
				<div className="stat-text">
					<span className="h3" style={statCountStyle[name]}>{count}</span>
					<span className="h5">{label || capitalise(name)}</span>
				</div>
			</div>
		);
	};

	const renderWidget = (name, index) => ({
		"Academic count": <Stat key={index} name="academics" />,
		"Offer count": <Stat key={index} name="offer" />,
		"Course count": <Stat key={index} name="courses" />,
		"Event count": <Stat key={index} name="events" />,
		"Organisation count": <Stat key={index} name="organisations" label="Institutions" link="/admin/institutions" />,
		"Problem count": <Stat key={index} name="problems" label={capitalise(props.plural)} />,
		"Research centre count": <Stat key={index} name="centres" link="/research-centres" />,
		"Report count": <Stat key={index} name="reports" />,
		"Tool count": <Stat key={index} name="tools" />,
		"User count": <Stat key={index} name="users" section="admin" />,
		"My Competencies": <Stat key={index} name="competencies" section="skills" onClick={() => setShowUnitsDialog(true)} />,
		"Career Pathway": <Stat key={index} name="careerPathway" label="Pathway" section="careerPathway" link="/career-pathway" />
	})[name];

	return (
		<div className={classNames("page-layout", { "login-view": !isLoggedIn, "side-menu-collapsed": props.isSideMenuCollapsed })}>
			<div className="page-header">
				<div className={`page-title ${props.classes.pageTitle}`}>
					<h1 style={{ display: "inline-block" }}>{title}</h1>
					{props.loading && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
					{!props.isPublicLink && breadcrumbs.length > 0 &&
						<ol className="breadcrumb">
							{/* Render home crumb first */}
							<li className="breadcrumb-item">
								{isLoggedIn ? <Link to="/">Home</Link> : <Link to="/login">Login</Link>}
							</li>
							{/* Render remaining crumbs */}
							{map(breadcrumbs, (breadcrumb, index) => breadcrumb &&
								<li key={index} className={classNames("breadcrumb-item", { "active": index === breadcrumbs.length })}>
									<span className="breadcrumb-divider">&gt;</span>
									{/* Render crumb as span if string */}
									{typeof(breadcrumb) === "string" ? <span>{breadcrumb}</span> :
										<Link to={breadcrumb.path}>{breadcrumb.label}</Link>
									}
								</li>
							)}
						</ol>
					}
				</div>
				{(props.pageActions || props.canMakePublic) && 
					<div className="page-actions">
						{props.pageActions}
						{props.canMakePublic && <MakePublicButton />}
					</div>
				}
				{isLoggedIn &&
					<div className="widgets">
						{props.widgetContent ? props.widgetContent : props.statsLoading ? <CircularProgress size={24} /> : map(props.widgets, renderWidget)}
					</div>
				}
			</div>
			<div className={classNames("page-content", props.pageClass)} style={{...props.pageStyle}}>
				{!props.hideContent && props.pageContent}
			</div>
			<UnitsOfCompetencyDialog open={showUnitsDialog} onClose={() => setShowUnitsDialog(false)} />
		</div>
	);
};

PageLayout.propTypes = {
  	title: PropTypes.string.isRequired,
	pageContent: PropTypes.node.isRequired,
	breadcrumbs: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			path: PropTypes.string
		}),
		PropTypes.string
	])),
	pageClass: PropTypes.string,
	stats: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	statsLoading: PropTypes.bool.isRequired,
	canView: PropTypes.object.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	plural: PropTypes.string.isRequired,
	widgets: PropTypes.array.isRequired,
	pageActions: PropTypes.node,
	hideContent: PropTypes.bool,
	isPublicLink: PropTypes.bool,
	isSideMenuCollapsed: PropTypes.bool.isRequired
};

PageLayout.defaultProps = {
	breadcrumbs: [],
	pageClass: "",
	loading: false,
	pageActions: undefined,
	hideContent: false,
	isPublicLink: false
};

const mapStateToProps = state => ({
	stats: state.dashboard.stats,
	statsLoading: isStatsLoading(state),
	canView: canViewProps(state),
	isLoggedIn: state.context.isLoggedIn,
	plural: state.settings.plural,
	widgets: state.settings.navbarWidgets,
	isPublicLink: state.context.isPublicLink,
	isSideMenuCollapsed: state.layout.isSideMenuCollapsed
});

export default connect(mapStateToProps, null)(withStyles(PageLayout, styles));