import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import AllBusinessChallenges from './AllBusinessChallenges';
import { showModal } from '../../store/modal';
import { 
	searchOffers, 
	deleteOffer, 
	isLoading 
} from '../../store/offers';
import { 
	canEditOffer, 
	canSubmitOffers,
	showBusinessChallengesRequestListing,
	canMakePublic
} from '../../store/security';
import Button from '../widgets/Button';
// import ButtonLink from '../widgets/ButtonLink';
import { withForm } from '../../utils/forms';
import { CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import pick from 'lodash/pick';
import { getQueryParameters } from '../../utils/utils';
import RequestListingDialog from './RequestListingDialog';
import MakePublicButton from '../widgets/MakePublicButton';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchOffers(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} style={{ marginRight: 4 }} /> : <SearchIcon />
		},
		{
			name: "channel",
			label: "Filter by channel",
			type: "autoComplete",
			loadItems: { 
				route: props => `channels?subscribedOnly=${props.isCommunity}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "tag",
			label: "Filter by tag",
			type: "autoComplete",
			loadItems: { 
				route: (props, values) => `tags/insights?visibility=${values.visibility}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		}
	]
};

const BusinessChallengesController = props => {
	const { fields } = props;
	const [state, setState] = React.useState({ search: "", tag: "", status: "open" });
	const [showRequestListingDialog, setShowRequestListingDialog] = React.useState(false);

	React.useEffect(() => props.searchOffers(props.searchArgs), []);

	const content = (
		<React.Fragment>
			<Grid container spacing={3} alignItems="flex-end">
				<Grid item xs={12} sm={3}>{fields.search()}</Grid>
				<Grid item xs={12} sm={3}>{fields.tag()}</Grid>
				<Grid item xs={12} sm={3}>{fields.channel()}</Grid>
				{/* {props.canSubmit && 
					<Grid item xs="auto">
						<ButtonLink to="/research-match/create">Add Match</ButtonLink>
					</Grid>
				} */}
				{props.showBusinessChallengesRequestListing && 
					<Grid item xs="auto">
						<Button onClick={() => setShowRequestListingDialog(true) }>Request Listing Access</Button>
					</Grid>
				}
			</Grid>

			<AllBusinessChallenges  
				offers={props.offers} 
				status={state.status}
				isAdmin={props.isAdmin}
				loading={props.loading || !props.offers}
				setStatus={status => setState({ ...state, status })}
			/> 

			<RequestListingDialog 
				open={showRequestListingDialog}
				onClose={() => setShowRequestListingDialog(false)}
			/>
		</React.Fragment>
	);

	return (
		<PageLayout 
			title="Research Match" 
			pageContent={content} 
			breadcrumbs={["Research Match"]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);
};

BusinessChallengesController.propTypes = { 
	offers: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	showBusinessChallengesRequestListing: PropTypes.bool.isRequired,
	canMakePublic: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	offers: state.offers.offers,
	loading: isLoading(state),
	isAdmin: state.context.isAdministrator,
	searchArgs: pick(getQueryParameters(ownProps.location.search), "search"),
	canEdit: canEditOffer(state),
	canSubmit: canSubmitOffers(state),
	showBusinessChallengesRequestListing: showBusinessChallengesRequestListing(state),
	canMakePublic: canMakePublic(state)
});

const mapDispatchToProps = dispatch => ({
	searchOffers: (args) => dispatch(searchOffers(args)),
	deleteOffer: offerId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this offer?",
		onOk: () => dispatch(deleteOffer(offerId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(BusinessChallengesController));
