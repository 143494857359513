import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isStatsLoading, getProductsChartData as getDashboard } from '../../store/dashboard';
import { withRouter } from 'react-router-dom';
import GridLayout from 'react-grid-layout';
import { get } from '../../utils/ajax';
import { 
	Dialog, 
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton
} from '@mui/material';
import Select from '../widgets/Select';
import Checkbox from '../widgets/Checkbox';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import DashboardWidget from '../dashboardWidgets/DashboardWidget';
import Competency from '../dashboardWidgets/Competency';
import CompetencyPercentage from '../dashboardWidgets/CompetencyPercentage';
import TopCompetencies from '../dashboardWidgets/TopCompetencies';
import MyTopCompetencies from '../dashboardWidgets/MyTopCompetencies';
import BottomCompetencies from '../dashboardWidgets/BottomCompetencies';
import HighPriorityMissingCompetencies from '../dashboardWidgets/HighPriorityMissingCompetencies';
import CombinedProfiles from '../dashboardWidgets/CombinedProfiles';
import PrimaryProfile from '../dashboardWidgets/PrimaryProfile';
import SecondaryProfile from '../dashboardWidgets/SecondaryProfile';
import DevelopmentNeeds from '../dashboardWidgets/DevelopmentNeeds';
import SkillsGap from '../dashboardWidgets/SkillsGap';
import CloseIcon from '@mui/icons-material/Close';
import AddWidgetDialog from '../dashboard/AddWidgetDialog';
import widgets from '../dashboardWidgets/dashboardWidgets';
import {
	teamsDashboardWidgetsDefault,
	teamsDashboardLayoutDefault
} from '../dashboard/dashboardDefaults';
import { canViewDepartments } from '../../store/security';

const PlaceholderWidget = ({ name }) => 
	<div 
		style={{ 
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%'
		}}
	>
		<span style={{ fontSize: '30px' }}>{name}</span>
	</div>;

const tileStyles = {
	padding: "60px 0 0 0",
	backgroundColor: "#fff",
	// overflow: "hidden", 
	borderRadius: "4px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)" 
};

const Dashboard = (props) => {
	const [state, setState] = React.useState({
		departmentId: props.canViewAllDepartments ? 0 : props.departmentId,
		departments: [],
		departmentsLoading: true
	});
	const [showWidgetPicker, setShowWidgetPicker] = React.useState(false);
	const [showAddWidgetDialog, setShowAddWidgetDialog] = React.useState(false);
	const [dashboardLayout, setDashboardLayout] = React.useState(null);
	const homeDashboardWidgetsDefault = {
		competencyStatus: true,
		competencyStatusPercent: true,
		topCompetencies: true,
		myTopCompetencies: false,
		highPriorityMissingCompetencies: true,
		bottomCompetencies: false,
		combinedProfiles: false,
		competencyProfile: false,
		aspirationalCompetencyProfile: false,
		developmentNeeds: false,
		skillsGap: false,
		customWidgets: []
	};
	const skillsDashboardWidgetsDefault = {
		competencyStatus: false,
		competencyStatusPercent: false,
		topCompetencies: false,
		myTopCompetencies: true,
		highPriorityMissingCompetencies: false,
		bottomCompetencies: false,
		combinedProfiles: true,
		competencyProfile: true,
		aspirationalCompetencyProfile: true,
		developmentNeeds: false,
		skillsGap: false,
		customWidgets: [] 
	};

	const dashboardWidgetsDefault = [];
	const [dashboardWidgets, setDashboardWidgets] = React.useState(dashboardWidgetsDefault);

	React.useEffect(() => {
		if (props.dashboard) {
			switch (props.dashboardLocation) {
				case 'TeamSkillsSummary':
					setDashboardLayout(props.dashboard.layout || teamsDashboardLayoutDefault);
					break;
				default:
					setDashboardLayout(props.dashboard.layout || []);
			}

			if (props.dashboard.widgets) {
				setDashboardWidgets(props.dashboard.widgets);
			} else {
				switch (props.dashboardLocation) {
					case 'Home':
						setDashboardWidgets(homeDashboardWidgetsDefault);
						break;
					case 'Skills':
						setDashboardWidgets(skillsDashboardWidgetsDefault);
						break;
					case 'MyCommunity':
						setDashboardWidgets(dashboardWidgetsDefault);
						break;
                    case 'TeamSkillsSummary':
                        setDashboardWidgets(teamsDashboardWidgetsDefault);
                        break;
                    default:
				}
			}
		}
	}, [props.dashboard]);

	React.useEffect(() => {
		setState(prevState => ({
			...prevState,
			departmentsLoading: true
		}));
		if (props.organisationId) {
			get({
				url: `/api/departments/by-organisation/${props.organisationId}`,
				onSuccess: data => {
					setState(prevState => ({
						...prevState,
						departments: data,
						departmentsLoading: false
					}));
				},
				onError: () => {
					setState(prevState => ({
						...prevState,
						departmentsLoading: false
					}));
				}
			});
		} else {
			get({
				url: "/api/departments/user-departments",
				onSuccess: data => {
					setState(prevState => ({
						...prevState,
						departments: data,
						departmentsLoading: false
					}));
				},
				onError: () => {
					setState(prevState => ({
						...prevState,
						departmentsLoading: false
					}));
				}
			});
		}
	}, [props.organisationId]);

	const onChangeDepartment = departmentId => {
		setState(prevState => ({
			...prevState,
			departmentId: departmentId
		}));
	};

	const by = state.departmentId === 0 ? "department" : "position";
	const byChartSuffix = state.departmentId === 0 ? "" : " by Role";

	const onSelectCharts = () => {
		setShowWidgetPicker(true);
	};
	const onOkWidgetPicker = () => {
		setShowWidgetPicker(false);
	};
	const onCloseWidgetPicker = () => {
		setShowWidgetPicker(false);
	};
	const onReset = () => {
		switch (props.dashboardLocation) {
			case 'Home':
				setDashboardWidgets(homeDashboardWidgetsDefault);
				setDashboardLayout([]);
				break;
			case 'Skills':
				setDashboardWidgets(skillsDashboardWidgetsDefault);
				setDashboardLayout([]);
				break;
			case 'MyCommunity':
				setDashboardWidgets(dashboardWidgetsDefault);
				setDashboardLayout([]);
				break;
            case 'TeamSkillsSummary':
                setDashboardWidgets(teamsDashboardWidgetsDefault);
				setDashboardLayout(teamsDashboardLayoutDefault);
                break;
            default:
        }
	}
	const onSaveChanges = () => {
		props.onSaveDashboard(dashboardLayout, dashboardWidgets);
	};
	const onChangeDashboardLocation = (newLocation) => {
		switch (newLocation) {
			case 'Home':
				setDashboardWidgets(homeDashboardWidgetsDefault);
				setDashboardLayout([]);
				break;
			case 'Skills':	
				setDashboardWidgets(skillsDashboardWidgetsDefault);
				setDashboardLayout([]);
				break;
			case 'MyCommunity':
				setDashboardWidgets(dashboardWidgetsDefault);
				setDashboardLayout([]);
				break;
            case 'TeamSkillsSummary':
                setDashboardWidgets(teamsDashboardWidgetsDefault);
				setDashboardLayout(teamsDashboardLayoutDefault);
                break;
            default:
		}
		props.onChangeDashboardLocation(newLocation);
	};
	const onAddWidget = () => {
		setShowAddWidgetDialog(true);
	};
	const onRemoveWidget = (w) => {
		setDashboardWidgets(filter(dashboardWidgets, w2 => w2 !== w));
	};

	const LocationSelect = 
		<Select
			key="location-select"
			value={props.dashboardLocation}
			label="Location"
			fullWidth={false}
			style={{ width: 300, marginRight: '10px' }}
			onChange={e => onChangeDashboardLocation(e.target.value)}
			items={
				[
					{ label: 'Home Page', value: 'Home' },
					{ label: 'My Skills Passport', value: 'Skills' },
					{ label: 'My Community', value: 'MyCommunity' },
					{ label: 'Team Skills Summary', value: 'TeamSkillsSummary' }
				]
			}
		/>;

	let items = [];
	let layoutType = "";
	const fixedWidgets = dashboardWidgets && !isArray(dashboardWidgets);

	switch (props.dashboardLocation) {
		case "MyCommunity":
			layoutType = "Card";
			if (dashboardWidgets && isArray(dashboardWidgets)) {
				items = map(dashboardWidgets, (w, i) => 
					<Card key={`my-community-widget-${i}`}>
						<IconButton
                            onClick={() => onRemoveWidget(w)}
                            style={{
								position: 'absolute',
								top: '15px',
								right: '15px'
							}}
                            size="large">
							<CloseIcon />
						</IconButton>
						<DashboardWidget {...w} />
					</Card>
				);
			}
			break;
		default:
			layoutType = "Grid";
            const selectorItems = []
            if (props.admin) selectorItems.push(LocationSelect);

			if (props.admin && props.canEditOrganisations) {
				selectorItems.push(
					<Select
						key="org-select"
						value={props.organisationId}
						label="Organisation"
						fullWidth={false}
						style={{ width: 300, marginRight: '10px' }}
						onChange={e => props.onChangeOrganisation(e.target.value)}
						items={map(props.organisations, o => ({ label: o.name, value: o.organisationId }))}
					/>
				);
			}

            if (props.dashboardLocation !== "TeamSkillsSummary") {
				const departmentItems = map(state.departments || [], d => ({ label: `${d.name}`, value: d.departmentId }));
				if (props.canViewAllDepartments) {
					departmentItems.unshift({ label: "All", value: 0 });
				}
                selectorItems.push(
                    <Select
						key="department-select"
                        value={state.departmentId}
                        label="Department"
                        fullWidth={false}
                        style={{ width: 300 }}
                        onChange={e => onChangeDepartment(e.target.value)}
                        items={departmentItems}
                    />
                );
            }

            items.push(
                <div key="1.1" data-grid={{ x: 0, y: 0, w: 11, h: 1, static: true }}>
                    {selectorItems}
                </div>
            );

            if (props.dashboardLocation === "TeamSkillsSummary") {
                items.push(
					<div key="team-selector" data-grid={{ x: 0, y: 1, w: 6, h: 4 }} style={{ ...tileStyles, padding: '0' }}>
						<PlaceholderWidget name='Team Selector' />
					</div>
                );
            }

			if (dashboardWidgets) {
				if (!fixedWidgets) {
					forEach(dashboardWidgets, w => {
						const baseWidget = find(widgets, w2 => w2.type === w.type);
						items.push(
							<div key={w.id} data-grid={{ x: 0, y: 1, w: 6, h: 6 }} style={{ ...tileStyles, ...baseWidget.tileStyles }}>
								<DashboardWidget 
									{...baseWidget}
									{...w} 
									organisationId={props.organisationId}
									departmentId={state.departmentId}
									by={by}
									onChangeDepartment={onChangeDepartment}
									onDelete={() => {
										setDashboardWidgets(filter(dashboardWidgets, w2 => w2.id !== w.id));
									}}
								/>
							</div>
						);
					});
				} else {
					if (props.dashboardLocation === 'Skills') {
						items.push(
							<div key="1.2" data-grid={{ x: 0, y: 1, w: 6, h: 6 }} style={{ ...tileStyles, padding: '0' }}>
								<PlaceholderWidget name='Skills Placeholder' />
							</div>
						);
					}

					if (dashboardWidgets.competencyStatus) {
						items.push(
							<div key="2" data-grid={{ x: 0, y: 1, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title={`Competency Status${byChartSuffix}`}
									organisationId={props.organisationId}
									departmentId={state.departmentId}
									by={by}
									onChangeDepartment={onChangeDepartment}
									widget={Competency}
								/>
							</div>
						);
					}
					if (dashboardWidgets.competencyStatusPercent) {
						items.push(
							<div key="3" data-grid={{ x: 6, y: 1, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title={`Competency Status${byChartSuffix} %`}
									organisationId={props.organisationId}
									departmentId={state.departmentId}
									by={by}
									onChangeDepartment={onChangeDepartment}
									widget={CompetencyPercentage}
								/>
							</div>
						);
					}
					if (dashboardWidgets.topCompetencies) {
						items.push(
							<div key="4" data-grid={{ x: 0, y: 7, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title="Top Competencies"
									organisationId={props.organisationId}
									departmentId={state.departmentId}
									by={by}
									onChangeDepartment={onChangeDepartment}
									widget={TopCompetencies}
								/>
							</div>
						);
					}
					if (dashboardWidgets.bottomCompetencies) {
						items.push(
							<div key="5" data-grid={{ x: 0, y: 13, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title="Bottom Competencies"
									organisationId={props.organisationId}
									departmentId={state.departmentId}
									by={by}
									onChangeDepartment={onChangeDepartment}
									widget={BottomCompetencies}
								/>
							</div>
						);
					}
				/* <div key="5" data-grid={{ x: 0, y: 13, w: 6, h: 6 }} style={tileStyles}>
					<h2 style={titleHeaderStyles}>Missing Competencies by Priority By {byChartSuffix}</h2>
					{state.missingCompetenciesByPriorityByRoleLoading && <WidgetLoading />}
					{!state.missingCompetenciesByPriorityByRoleLoading && <MissingCompetenciesByPriority by={by} data={state.missingCompetenciesByPriorityByRole} onChangeDepartment={onChangeDepartment} />}
				</div> */
					if (dashboardWidgets.highPriorityMissingCompetencies) {
						items.push(
							<div key="6" data-grid={{ x: 7, y: 7, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title={`High Priority Missing Competencies ${byChartSuffix}`}
									organisationId={props.organisationId}
									departmentId={state.departmentId}
									by={by}
									onChangeDepartment={onChangeDepartment}
									widget={HighPriorityMissingCompetencies}
								/>
							</div>
						);
					}

					if (dashboardWidgets.combinedProfiles) {
						items.push(
							<div key="7" data-grid={{ x: 7, y: 1, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title="Combined Profiles: How I Compare"
									userId={props.userId}
									widget={CombinedProfiles}
								/>
							</div>
						);
					}

					if (dashboardWidgets.competencyProfile) {
						items.push(
							<div key="8" data-grid={{ x: 0, y: 7, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title={`${props.pcSingle} Profile`}
									userId={props.userId}
									widget={PrimaryProfile}
								/>
							</div>
						);
					}


					if (dashboardWidgets.aspirationalCompetencyProfile) {
						items.push(
							<div key="9" data-grid={{ x: 7, y: 7, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title={`${props.acSingle} Profile`}
									userId={props.userId}
									widget={SecondaryProfile}
								/>
							</div>
						);
					}

					if (dashboardWidgets.developmentNeeds) {
						items.push(
							<div key="10" data-grid={{ x: 0, y: 13, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title="My Skills Profile Summary"
									description="The My Skills Profile Summary chart shows a summary of required and achieved competencies across groups."
									userId={props.userId}
									widget={DevelopmentNeeds}
								/>
							</div>
						);
					}

					if (dashboardWidgets.myTopCompetencies) {
						items.push(
							<div key="11" data-grid={{ x: 7, y: 13, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title="My Top Competencies"
									userId={props.userId}
									widget={MyTopCompetencies}
								/>
							</div>
						);
					}

					if (dashboardWidgets.skillsGap) {
						items.push(
							<div key="12" data-grid={{ x: 0, y: 19, w: 6, h: 6 }} style={tileStyles}>
								<DashboardWidget
									title="SkillsGap"
									organisationId={props.organisationId}
									departmentId={state.departmentId}
									widget={SkillsGap}
								/>
							</div>
						);
					}
				}
			}
			break;
	}

	if (!dashboardLayout) return <div></div>;

	return (
		<React.Fragment>
			{props.admin &&
				<div style={{ marginBottom: '20px' }}>
					{!fixedWidgets && <Button onClick={onAddWidget} style={{ marginRight: '20px' }}>Add Widget</Button>}
					{fixedWidgets && <Button onClick={onSelectCharts} style={{ marginRight: '20px' }}>Select Charts</Button>}
					<Button onClick={onSaveChanges} style={{ marginRight: '20px' }}>Save Changes</Button>
					<Button onClick={onReset}>Reset</Button>
				</div>
			}
		{layoutType === "Card" && 
			<React.Fragment>
				{props.admin && LocationSelect}
				{items}
			</React.Fragment>
		}
		{layoutType === "Grid" && 
			<GridLayout 
				className="layout" 
				cols={12} 
				rowHeight={60} 
				width={1250} 
				containerPadding={[0, 0]}
				margin={[30, 30]}
				style={{ marginLeft: "15px" }}
				layout={dashboardLayout}
				onLayoutChange={(layout) => {
					setDashboardLayout(layout);
				}}
				isDraggable={props.admin}
				isResizable={props.admin}
			>
				{items}
			</GridLayout>
		}
		<AddWidgetDialog
			open={showAddWidgetDialog}
			onClose={() => setShowAddWidgetDialog(false)}
			onAdd={(w) => { 
				setShowAddWidgetDialog(false); 
				setDashboardWidgets([...dashboardWidgets, w]);
			}}
		/>
		<Dialog open={showWidgetPicker} onClose={onCloseWidgetPicker}>
			<DialogTitle>Select the charts to display</DialogTitle>
			<DialogContent>
				<Checkbox 
					label="Competency Status" 
					checked={dashboardWidgets.competencyStatus || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, competencyStatus: e.target.checked
						})
					} 
				/>
				<Checkbox 
					label="Competency Status %" 
					checked={dashboardWidgets.competencyStatusPercent || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, competencyStatusPercent: e.target.checked
						})
					}
				/>
				<Checkbox	 
					label="Top Competencies"
					checked={dashboardWidgets.topCompetencies || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, topCompetencies: e.target.checked
						})
					}
				/>
				<Checkbox 
					label="My Top Competencies"
					checked={dashboardWidgets.myTopCompetencies || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, myTopCompetencies: e.target.checked
						})
					}
				/>
				<Checkbox 
					label="Bottom Competencies" 
					checked={dashboardWidgets.bottomCompetencies || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, bottomCompetencies: e.target.checked
						})
					}
				/>
				<Checkbox 
					label="High Priority Missing Competencies" 
					checked={dashboardWidgets.highPriorityMissingCompetencies || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, highPriorityMissingCompetencies: e.target.checked
						})
					}
				/>
				<Checkbox 
					label="Combined Profiles: How I Compare" 
					checked={dashboardWidgets.combinedProfiles || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, combinedProfiles: e.target.checked
						})
					}
				/>
				<Checkbox 
					label={`${props.pcSingle} Profile`}
					checked={dashboardWidgets.competencyProfile || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, competencyProfile: e.target.checked
						})
					}
				/>
				<Checkbox 
					label={`${props.acSingle} Profile`}
					checked={dashboardWidgets.aspirationalCompetencyProfile || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, aspirationalCompetencyProfile: e.target.checked
						})
					}
				/>
				<Checkbox 
					label="My Skills Profile Summary" 
					checked={dashboardWidgets.developmentNeeds || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, developmentNeeds: e.target.checked
						})
					}
				/>
				<Checkbox 
					label="Skills Gap" 
					checked={dashboardWidgets.skillsGap || false} 
					onChange={(e) => 
						setDashboardWidgets({ 
							...dashboardWidgets, skillsGap: e.target.checked
						})
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onOkWidgetPicker}>Ok</Button>
			</DialogActions>
		</Dialog>
		</React.Fragment>
	);
};

Dashboard.propTypes = {
	admin: PropTypes.bool,
	loading: PropTypes.bool.isRequired,
	dashboard: PropTypes.object,
	dashboardLocation: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	organisationId: PropTypes.number,
	departmentId: PropTypes.number,
	teamId: PropTypes.number,
	canEditOrganisations: PropTypes.bool,
	onChangeDashboardLocation: PropTypes.func.isRequired,
	pcSingle: PropTypes.string.isRequired,
	acSingle: PropTypes.string.isRequired,
	canViewAllDepartments: PropTypes.bool.isRequired
};

Dashboard.defaultProps = {
	admin: false,
	dashboard: null,
	dashboardLocation: 'Home',
	organisationId: null,
	departmentId: null,
	teamId: null,
	canEditOrganisations: false,
	onChangeDashboardLocation: () => {}
};

const mapStateToProps = state => ({
	loading: isStatsLoading(state),
	canViewAllDepartments: canViewDepartments(state),
	departmentId: state.context.departmentId
});

const mapDispatchToProps = dispatch => ({
	getDashboard: () => dispatch(getDashboard())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
