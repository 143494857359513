import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { completeProfile, isLoading } from '../../store/context';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Form from '../widgets/Form';
import { getQueryParameters } from '../../utils/utils';

const form = {
	initValues: props => {
		return { 
			organisationId: props.organisationId, 
			membershipLevelId: null
		};
	},
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "organisationId",
			required: props => props.queryParameters.path !== 'buyer' && props.queryParameters.path !== 'seller',
			label: "Site",
			type: "autoComplete",
			loadItems: {
				route: props => `organisations?subdomainId=${props.subdomainId || ""}`, 
				mapItem: o => ({ label: o.name, value: o.organisationId, organisation: o }),
				onSuccess: (props, setValue, mapped) => {
					// Need to use props because initValues has not been called yet
					if (props.organisationId) {
						const { organisation } = mapped.find(item => item.value === props.organisationId);
						setValue("departmentId", organisation.defaultDepartmentId);
						setValue("positionId", organisation.defaultPositionId);
						setValue("_hideDepartmentDuringRegistration", organisation.hideDepartmentDuringRegistration);
						setValue("_hidePositionDuringRegistration", organisation.hidePositionDuringRegistration);
					}
					setValue("_organisationCount", mapped.length);
				}
			},
			onChange: (values, value) => {
				values.departmentId = value.organisation.defaultDepartmentId;
				values.positionId = value.organisation.defaultPositionId;
				values._hideDepartmentDuringRegistration = value.organisation.hideDepartmentDuringRegistration;
				values._hidePositionDuringRegistration = value.organisation.hidePositionDuringRegistration;
			},
			disabled: props => props.process === "Controlled",
			style: { marginTop: 10 }
		},
		{
			name: "academicRole",
			label: "Your Status",
			required: true,
			type: "select",
			items: ['Academic/Researcher', 'Student']
		},
		{
			name: "academicTitle",
			label: "Your Title",
			required: f => f.academicRole === "Academic/Researcher"
		},
		{
			name: "academicUrl",
			label: "Your institution's web site URL with your profile",
			required: f => f.academicRole === "Academic/Researcher"
		},
		{
			name: "academicSchool",
			label: "Your Faculty/School",
			required: f => f.academicRole === "Academic/Researcher"
		}
	]
};

const CompleteProfile = props => {
	const { fields, values } = props;

	return (
		<div className="registration-box">
			<span className="h3">Create Your User Profile</span>
			<small>Welcome - {props.fullName}</small>
			<p>
				To get started you now need to complete yor profile
			</p>

			<Form 
				onSubmit={() => {
					if (props.validateFields()) {
						const registerArgs = { ...values };
						props.completeProfile(registerArgs);
					} 
				}}
				style={{ textAlign: "center" }}
			>
				{fields.organisationId()}

				{fields.academicRole()}
				{values.academicRole === "Academic/Researcher"  && fields.academicTitle()}
				{values.academicRole === "Academic/Researcher"  && fields.academicUrl()}
				{values.academicRole === "Academic/Researcher"  && fields.academicSchool()}

				<Button type="submit" size="large" loading={props.loading} color="secondary">Submit</Button>
			</Form>
		</div>
	);
};

CompleteProfile.propTypes = {
	loading: PropTypes.bool.isRequired,
	completeProfile: PropTypes.func.isRequired,
	subdomainId: PropTypes.number,
	tenantId: PropTypes.number,
	organisationId: PropTypes.number,
	organisationType: PropTypes.string
};

CompleteProfile.defaultProps = {
	subdomainId: 0,
	tenantId: 0,
	organisationId: null,
	organisationType: ""
};

const mapStateToProps = (state, ownProps) => ({
	loading: isLoading(state),
	subdomainId: state.context.subdomainId,
	fullName: state.context.fullName,
	tenantId: state.context.registrationTenantId,
	organisationId: state.context.registrationOrganisationId,
	organisationType: state.context.organisationType,
	queryParameters: getQueryParameters(ownProps.location.search),
});

const mapDispatchToProps = dispatch => ({
	completeProfile: (values) => dispatch(completeProfile(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CompleteProfile));