import React from 'react';
import PropTypes from 'prop-types';
import MediaCard from './MediaCard';
import Button from '../widgets/Button';
import { truncateString, stripHtml } from '../../utils/utils';

const ribbonSwitch = {
	External: "warning",
	Internal: "info",
	None: "default"
};

const pathSwitch = {
	Problem: "problems",
	Event: "events",
	Offer: "offers"
};

const getLink = f => {
	if (f.featureType === "Contact") return "";
	if (f.featureType === "Article") return `/articles/${f.featureId}`;
	if (f.featureType === "Google Form") return `/forms/${f.featureId}`;

	return `/${pathSwitch[f.featureType]}${f.targetExists ? `/${f.targetId}` : ""}`;
}

const FeatureBox = React.memo(props => {
	const { feature: f, canEdit } = props;
	const contentOnly = f.featureType === "Article" && f.articleContentOnly,
		link = canEdit ? `/features/edit/${f.featureId}` : getLink(f);

	const handleClick = () => {
		if (f.featureType === "Contact" && props.showContact) {
			props.showContact(f);
		} else if (f.featureType !== "Article" && f.featureType !== "Google Form" && !f.targetExists) {
			props.showWarning(f.featureType);
		}
	}

	return (
		<MediaCard 
			className="feature-box"
			title={f.title} 
			link={link} 
			onClick={handleClick}
			image={contentOnly ? undefined : f.imageUrl}
			ribbon={canEdit ? f.location : ""}
			ribbonStatus={ribbonSwitch[f.location]}
		>
			{contentOnly ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: f.articleContent }} /> : 
				<p>{truncateString(stripHtml(f.description), 150)}</p>
			}
			{f.featureType === "Contact" &&
				<Button onClick={handleClick} style={{ alignSelf: "flex-end" }}>
					{f.contactButtonText}
				</Button>
			}
		</MediaCard>
	);
});

FeatureBox.propTypes = {
	feature: PropTypes.shape({
		title: PropTypes.string,
		featureType: PropTypes.string,
		targetExists: PropTypes.bool,
		targetId: PropTypes.number,
		imageUrl: PropTypes.string,
		description: PropTypes.string
	}).isRequired,
	showWarning: PropTypes.func,
	canEdit: PropTypes.bool,
	showContact: PropTypes.func
};

FeatureBox.defaultProps = {
	showWarning: () => null,
	canEdit: false,
	showContact: undefined
};

export default FeatureBox;