import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions, 
	CircularProgress
} from '@mui/material';
import { 
	clearUnitsOfCompetency,
	getUnitsOfCompetency,
	isLoading
} from '../../store/unitsOfCompetency';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import Button from '../widgets/Button';
import Checkbox from '../widgets/Checkbox';
import map from 'lodash/map';
import some from 'lodash/some';
import remove from 'lodash/remove';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import { stripHtml } from '../../utils/utils';
import Select from '../widgets/Select';

class UnitOfCompetencyPicker extends React.Component {
	state = {
		selectedProfile: "",
		selectedUnits: [],
		currentUnit: null,
		defaultProfile: null,
		originalSelection: []
	};

	constructor() {
		super();
		this.dialogBodyRef = React.createRef();
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (!prevProps.open && this.props.open) {
			this.props.getUnitsOfCompetency(units => {
				this.setState({ units: units });
			});
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (state.originalSelection !== props.selectedUnits) {
			return {
				originalSelection: props.selectedUnits,
				selectedUnits: props.selectedUnits ? [...props.selectedUnits] : props.selectedUnits
			};
		}
		return null;
	}

	selectUnit = (u, selected) => {
		const { selectedUnits } = this.state;

		if (selected) {
			selectedUnits.push(u);
		} else {
			const unit = find(selectedUnits, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
			remove(selectedUnits, unit);
		}

		this.setState({ selectedUnits });
	}

	setPriority = (u, priority) => {
		const { units, selectedUnits } = this.state;

		let unit = find(selectedUnits, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
		if (unit) {
			unit.priority = priority;
		}
		
		unit = find(units, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
		if (unit) {
			unit.priority = priority;
		}

		this.setState({ units, selectedUnits });
	}

	render = () => {
		const { currentUnit, selectedUnits } = this.state;
		const units = this.state.units;

		forEach(units, u => {
			const su = find(selectedUnits, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
			u.priority = su ? su.priority : u.priority || 2;
		});

		// forEach(selectedUnits, su => {
		// 	const u = find(units, u2 => u2.unitOfCompetencyId = su.unitOfCompetencyId);
		// 	if (u) u.priority = su.priority; 
		// });

		return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                className={this.props.className}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="md"
                TransitionProps={{
                    onExited: this.props.clearUnitsOfCompetency
                }}>
				<DialogTitle id="dialog-title">Units Of Competencies</DialogTitle>
				<>
				<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
					{this.props.loading ? <CircularProgress size={24} /> : 
						<React.Fragment>							
							{currentUnit ?
								<div>	
									<h3 className="quill-display-block" dangerouslySetInnerHTML={{ __html: `${currentUnit.unitCode} ${currentUnit.unitTitle}` }} style={{ display: "inline-block" }} />
									<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: currentUnit.unitDescriptionAndApplication }} />
									<h3>Performance Evidence/Skill</h3>
									<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: currentUnit.performanceEvidence }} />
									<h3>Knowledge Evidence</h3>
									<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: currentUnit.knowledgeEvidence }} />
									<h3>Elements/Levels</h3>
									<ul>{map(currentUnit.elements, (e, i) => <li key={i}>{stripHtml(e.element)}</li>)}</ul>
								</div>
								:
								<React.Fragment></React.Fragment>
							}
							<div
								style={{
									display: currentUnit ? 'none' : undefined
								}}
							>
								<DataTable 
									data={units}
									hidePagination
									disableScroll
									expandRow={r => Boolean(this.state.defaultProfile) && !this.props.otherUnits && r.isPreContextualised}
									detailData={u => u.elements}
									detailColumns={[
										<DataTableColumn label="Element" style={{ whiteSpace: "normal" }} renderer={(u, e) =>
											<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />
										} />,
										<DataTableColumn label="Performance Criteria" style={{ whiteSpace: "normal" }} renderer={(u, e) => map(e.items, i => 
											<div key={i.order} className="quill-display-block" dangerouslySetInnerHTML={{ __html: i.performanceCriteriaName }} />
										)} />
									]}
								>
									<DataTableColumn renderer={(u) =>
										<Checkbox
											checked={some(selectedUnits, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId)}
											onChange={evt => this.selectUnit(u, evt.target.checked)}
										/>
									} />,
									<DataTableColumn label="Unit Code" renderer={u =>
										<a style={{ cursor: "pointer" }} onClick={() => {
											this.setState({ ...this.state, currentUnit: u, currentScroll: this.dialogBodyRef.current.scrollTop });
										}}>
											{u.unitCode}
										</a>
									} />
									<DataTableColumn label="Unit Title" colSpan={2} style={{ whiteSpace: "normal" }} renderer={u => stripHtml(u.unitTitle)} />
									<DataTableColumn label="" renderer={(u) =>
										<Select
											label=""
											value={u.priority}
											onChange={evt => this.setPriority(u, evt.target.value)}
											items={[
												{ label: "High", value: 1 },
												{ label: "Medium", value: 2 },
												{ label: "Low", value: 3 }
											]}
										/>
									} />
								</DataTable>
							</div>
						</React.Fragment>
					}
				</DialogContent>
				</>
				{currentUnit ? 
					<DialogActions>
						<Button onClick={() => {
							this.setState({ ...this.state, currentUnit: null }, () => {
								if (this.dialogBodyRef) {
									window.setTimeout(() => {
										this.dialogBodyRef.current.scrollTop = this.state.currentScroll;
									}, 200);
								}
							});
						}}>
							Ok
						</Button> 
					</DialogActions>
					:
					<DialogActions>
						<Button onClick={() => this.props.onOk(this.state.selectedUnits)}>Ok</Button>
						<Button onClick={this.props.onClose} autoFocus>Cancel</Button>
					</DialogActions>
				}
			</Dialog>
        );
	}
}

UnitOfCompetencyPicker.propTypes = {
	className: PropTypes.string,
	selectedUnits: PropTypes.array,
	unitsOfCompetency: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
};

UnitOfCompetencyPicker.defaultProps = {
	className: "",
	unitsOfCompetency: [],
	otherUnits: false,
	allowMultiple: false.priority,
	selectedUnits: []
};

const mapStateToProps = state => ({
	unitsOfCompetency: state.unitsOfCompetency.unitsOfCompetency,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	clearUnitsOfCompetency: () => dispatch(clearUnitsOfCompetency()),
	getUnitsOfCompetency: (onSuccess) => dispatch(getUnitsOfCompetency(onSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitOfCompetencyPicker);
