import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import Button from '../widgets/Button';
import { withForm } from '../../utils/forms';

const form = {
	fields: [
		{
			name: "title",
			label: "Course title",
			required: true
		}
	]
}

const CopyCLO_CourseDialog = props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        TransitionProps={{
            onEntering: () => props.updateValues({ title: props.title, include: false })
        }}>
		<DialogTitle>Copy course</DialogTitle>
		<DialogContent>
			<Grid container spacing={3}>
				<Grid item xs={12}>{props.fields.title()}</Grid>
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose}>Cancel</Button>
			<Button onClick={() => props.copyCLO_Course(props.values.title)}>Confirm</Button>
		</DialogActions>
	</Dialog>;

CopyCLO_CourseDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	copyCLO_Course: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default withForm(form)(CopyCLO_CourseDialog);
