import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import {  
	searchProblems,
	deleteProblem,
	isLoading 
} from '../../store/problems';
import { showModal } from '../../store/modal';
import { getQueryParameters } from '../../utils/utils';
import Card from '../widgets/Card';
import Checkbox from '../widgets/Checkbox';
import AllProblems from './AllProblems';
import capitalise from 'lodash/capitalize';
import SearchField from '../widgets/SearchField';

const ProblemsController = props => {
	const [state, setState] = React.useState({ search: "", tag: "", showArchived: true });
	const { search, tag, showArchived } = state;

	React.useEffect(() => {
		const { search = "", tag = "" } = getQueryParameters(props.location.search);

		setState({ ...state, search, tag });
		props.searchProblems(search, tag);
	}, [props.isCommunity]);
	
	return <PageLayout title={capitalise(props.plural)} pageContent={
		<React.Fragment>
			<Card title={`Search for ${props.plural}`} subtitle={`Search the collection of ${props.plural}`}>
				<SearchField
					value={search} 
					onChange={value => setState({ ...state, search: value })} 
					onSubmit={() => props.searchProblems(search, tag)}
					loading={props.loading}
					tag={tag}
					removeTag={() => setState({ ...state, tag: "" })}
				/>
				{props.isAdmin &&
					<Checkbox 
						label={`Show archived ${props.plural}`}
						checked={showArchived} 
						onChange={() => setState({ ...state, showArchived: !showArchived })} 
						style={{ marginTop: 10 }}
					/>
				}
			</Card>
			<AllProblems 
				problems={props.problems} 
				isAdmin={props.isAdmin} 
				loading={props.loading} 
				showArchived={showArchived} 
				deleteProblem={props.deleteProblem(props.single)}
				single={props.single}
				plural={props.plural}
				isCommunity={props.isCommunity}
			/> 
		</React.Fragment>
	} breadcrumbs={[props.isCommunity ? { label: "My Community", path: "/my-community" } : "", capitalise(props.plural)]} />;
};

ProblemsController.propTypes = { 
	problems: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	isCommunity: PropTypes.bool
};

ProblemsController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	problems: state.problems.problems,
	loading: isLoading(state),
	isAdmin: state.context.isAdministrator,
	single: state.settings.single,
	plural: state.settings.plural
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	searchProblems: (search, tag) => dispatch(searchProblems(search, tag, ownProps.isCommunity)),
	deleteProblem: single => problemId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: `Are you sure you wish to delete this ${single}?`,
		onOk: () => dispatch(deleteProblem(problemId, ownProps.isCommunity))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProblemsController);
