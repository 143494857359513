import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditFramework from './EditFramework';
import { showModal } from '../../store/modal';
import { getFramework, saveFramework, deleteFramework, isLoading } from '../../store/frameworks';
import PageLayout from '../PageLayout';

const EditFrameworkController = props => {
	React.useEffect(() => props.getFramework(props.match.params.frameworkId), [props.match.params.frameworkId]);

	return <PageLayout title="Edit framework" pageContent={
		<EditFramework
			framework={props.framework}
			saveFramework={props.saveFramework}
			deleteFramework={props.deleteFramework}
			loading={props.loading}
			errorColour={props.errorColour}
		/>
	} breadcrumbs={[{ label: "Frameworks", path: "/admin/frameworks" }, "Edit framework"]} />;
};

EditFrameworkController.propTypes = {
	framework: PropTypes.object.isRequired,
	saveFramework: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	framework: state.frameworks.framework,
	loading: isLoading(state),
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getFramework: frameworkId => dispatch(getFramework(frameworkId)),
	saveFramework: (framework, importFile, imageFile) => dispatch(saveFramework(framework, importFile, imageFile)),
	deleteFramework: frameworkId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this framework?",
		onOk: () => dispatch(deleteFramework(frameworkId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFrameworkController);
