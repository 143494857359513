import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showModal } from '../../store/modal';
import { get, post } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import EditTeam from './EditTeam';
import { emptySaveResult } from '../../variables';
import axios from 'axios';
import { changeLocation } from '../../store/location';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { canManageTeamsAcrossTenant } from '../../store/security';

const defaultTeam = {
	name: "",
	description: "",
	teamLeader: null,
	members: []
};

const EditTeamController = props => {
    const [state, setState] = React.useState({
		isLoading: false,
		team: defaultTeam,
        users: [],
        skillsProfiles: [],
		teams: [],
		saveResult: emptySaveResult
	});

    React.useEffect(() => {
		get({
			url: `/api/teams/${props.match.params.id}`,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, team: data, isLoading: false }));
				if (data) onOrganisationChanged(data.organisationId);
			},
			onError: error => props.showErrorNotification(error.message)
		});
	}, [props.match.params.id]);

	const onOrganisationChanged = (organisationId) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		axios.all([
			get({
				url: props.organisationId === organisationId ? `/api/teams/by-organisation` : `/api/teams/by-organisation/${organisationId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, teams: data })),
				onError: error => props.showErrorNotification(error.message)
			}),
			get({
				url: props.organisationId === organisationId ? `/api/users/by-organisation` : `/api/users/by-organisation/${organisationId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, users: data })),
				onError: error => props.showErrorNotification(error.message)
			}),
			get({
				url: `/api/skills-tracker/profiles?organisationId=${organisationId || 0}`,
				onSuccess: data => setState(prevState => ({ ...prevState, skillsProfiles: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	};


	const onSave = (team) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
	
		post({
			url: "/api/teams",
			data: team,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

				if (data.success) {
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	const onDelete = team => {
			props.promptDeleteTeam(team, () => {
			post({
				url: `/api/teams/${team.teamId}/delete`,
				onSuccess: data => {
					setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToTeamsPage();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	}

	return <PageLayout title="Edit team" pageContent={
		<EditTeam
			isNew={false}
			currentUserId={props.currentUserId}
			team={state.team}
			teams={state.teams}
            users={state.users}
            skillsProfiles={state.skillsProfiles}
			saveTeam={onSave}
			deleteTeam={onDelete}
			loading={state.isLoading}
			saveResult={state.saveResult}
			canChangeOrganisation={props.canChangeOrganisation}
			organisationId={props.organisationId}
			onOrganisationChanged={onOrganisationChanged}
		/>
	} breadcrumbs={[{ label: "Teams", path: "/teams" }, "Edit team"]} />;
};

EditTeamController.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	organisationId: PropTypes.number.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	promptDeleteTeam: PropTypes.func.isRequired,
	goToTeamsPage: PropTypes.func.isRequired,
	canChangeOrganisation: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	organisationId: state.context.organisationId,
	canChangeOrganisation: canManageTeamsAcrossTenant(state)
});

const mapDispatchToProps = dispatch => ({
    goToTeamsPage: () => dispatch(changeLocation('/teams')),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptDeleteTeam: (ldCourse, onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this Team?",
		onOk: () => onOk()
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamController);
