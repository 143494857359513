import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import Button from '../widgets/Button';
import TextField from '../widgets/TextField';

const Agreement = ({ agreement, update, remove, requiredText, errorColour }) =>
	<Grid container spacing={4}>
		<Grid item xs={12} sm={6}>
			<TextField
				label="Party"
				value={agreement.party}
				onChange={e => update("party", e.target.value)}
				helpText={agreement.party ? "Title of party" : requiredText}
				error={!agreement.party}
				required
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextField 
				label="Contact"
				value={agreement.contact}
				onChange={e => update("contact", e.target.value)}
				helpText={agreement.contact ? "Full name of designated contact for Agreement": requiredText}
				error={!agreement.contact}
				required
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextField
				label="Email"
				value={agreement.email}
				onChange={e => update("email", e.target.value)}
				helpText="Email of designated contact for Agreement"
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextField
				label="Phone"
				value={agreement.phone}
				onChange={e => update("phone", e.target.value)}
				helpText="Phone number of designated contact for Agreement"
			/>
		</Grid>
		<Grid item xs={12} style={{ padding: "0 16px" }}>
			<Button 
				variant="outlined"
				style={{ color: errorColour, float: "right" }}
				onClick={remove}
			>
				Remove
			</Button>
		</Grid>
		<Grid item xs={12}>
			<Divider style={{ marginBottom: 16 }} />
		</Grid>
	</Grid>

Agreement.propTypes = {
	agreement: PropTypes.object.isRequired,
	update: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
	requiredText: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

export default Agreement;