import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import Button from '../widgets/Button';
import TextField from '../widgets/TextField';

const ThirdParty = ({ thirdParty, update, remove, requiredText, errorColour }) => 
	<Grid container spacing={4}>
		<Grid item xs={12} sm={6}>
			<TextField
				label="Party"
				value={thirdParty.party}
				onChange={e => update("party", e.target.value)}
				helpText={thirdParty.party ? "Title of party" : requiredText}
				error={!thirdParty.party}
				required
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextField 
				label="Contact"
				value={thirdParty.contact}
				onChange={e => update("contact", e.target.value)}
				helpText={thirdParty.contact ? "Full name of designated contact for IP": requiredText}
				error={!thirdParty.contact}
				required
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextField
				label="Email"
				value={thirdParty.email}
				onChange={e => update("email", e.target.value)}
				helpText="Email of designated contact for IP"
			/>
		</Grid>
		<Grid item xs={12} sm={6}>
			<TextField
				label="Phone"
				value={thirdParty.phone}
				onChange={e => update("phone", e.target.value)}
				helpText="Phone number of designated contact for IP"
			/>
		</Grid>
		<Grid item xs={12} style={{ padding: "0 16px" }}>
			<Button 
				variant="outlined"
				style={{ color: errorColour, float: "right" }}
				onClick={remove}
			>
				Remove
			</Button>
		</Grid>
		<Grid item xs={12}>
			<Divider style={{ marginBottom: 16 }} />
		</Grid>
	</Grid>;

ThirdParty.propTypes = {
	thirdParty: PropTypes.object.isRequired,
	update: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
	requiredText: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

export default ThirdParty;