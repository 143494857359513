import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from '../widgets/Button';
import { get, post } from '../../utils/ajax';
import { CircularProgress } from '@mui/material';
import Checkbox from '../widgets/Checkbox';
import Avatar from '../widgets/Avatar';
import map from 'lodash/map';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { Chip } from '@mui/material';

const InviteCollaboratorDialog = props => {
	const [collaborationLoading, setCollaborationLoading] = React.useState(false);
	const [usersLoading, setUsersLoading] = React.useState(false);
	const [collaboration, setCollaboration] = React.useState(null);
	const [users, setUsers] = React.useState([]);
	const [selection, setSelection] = React.useState([]);

	const getCollaboration = () => {
		setCollaborationLoading(true);
	
		get({
			url: `/api/collaborations/${props.collaborationId}`,
			onSuccess: data => {
				setCollaborationLoading(false);
				setCollaboration(data);
			},
			onError: () => {
				setCollaborationLoading(false);
			}
		});
	};
	const getInviteableUsers = () => {
		setUsersLoading(true);
	
		get({
			url: "/api/collaborations/inviteable-users",
			onSuccess: data => {
				setUsersLoading(false);
				setUsers(orderBy(data, 'fullName'));
			},
			onError: () => {
				setUsersLoading(false);
			}
		});
	}

	const loadData = () => {
		getCollaboration();
		getInviteableUsers();
	};

	const invite = () => {
		post({
			url: `/api/collaborations/invite-collaborators?collaborationId=${props.collaborationId}`,
			data: selection,
			onSuccess: data => {
				if (data.success) {
					props.showSuccessNotification(data.message);
					props.onClose();
					if (props.onSuccess) {
						props.onSuccess(data);
					}
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				props.showErrorNotification(error.message);
			}
		});
	};

	const loading = collaborationLoading || usersLoading;

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            TransitionProps={{
                onEnter: loadData
            }}>
			<DialogTitle>Select the users to invite</DialogTitle>
			<DialogContent>
				{loading ? <CircularProgress size={24} /> : map(users, (u, i) => {
					const participant = collaboration ? find(collaboration.participants, p => p.userId === u.userId) : null;
					const status = participant ? participant.status : null;
					return (
						<Checkbox 
							key={i}
							disabled={status === "Accepted"}
							label={
								<React.Fragment>
									<Avatar src={u.avatar} fullName={u.fullName} style={{ marginRight: 5 }} />
									<span>{u.fullName}</span>
									{status && <Chip label={status} style={{ height: 20, marginLeft: 10 }} />}
								</React.Fragment>
							}
							checked={selection.some(s => s === u.userId)}
							onChange={() => {
								if (selection.some(s => s === u.userId)) {
									setSelection(filter(selection, s => s !== u.userId));
								} else {
									setSelection([...selection, u.userId]);
								}
							}}
						/>
					);
				})}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button disabled={loading} onClick={() => invite()}>
					Invite
				</Button>
			</DialogActions>
		</Dialog>
    );
};

InviteCollaboratorDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteCollaboratorDialog);
