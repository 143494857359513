import React from 'react';
import PropTypes from 'prop-types';
import { 
	FormControl,
	FormLabel,
	Grid
} from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Checkbox from '../widgets/Checkbox';
import includes from 'lodash/includes';
import filter from 'lodash/filter';

const form = {
	initValues: props => props.project,
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of Challenge"
		},
		{
			name: "postedBy",
			label: "Posted By",
			required: false,
			type: "select",
			items: ["Student", "Corporate"]
		},
		{
			name: "organisationName",
			label: "Organisation Name"
		},
		{
			name: "website",
			label: "Website"
		},
		{
			name: "primaryName",
			label: "Name",
			helpText: "Name of primary contact"
		},
		{
			name: "primaryEmail",
			label: "Email",
			helpText: "Email of primary contact",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "primaryPhone",
			label: "Phone",
			helpText: "Phone number of primary contact"
		},
		{
			name: "description",
			label: "Brief Description",
			multiline: true,
			helpText: "(max 250 characters)",
			max: 250
		},
		{
			name: "details",
			label: "The Problem or Opportunity you are addressing",
			type: "textEditor"
		},
		{
			name: "objectives",
			label: "Additional Information",
			type: "textEditor"
		},
		{
			name: "requirements",
			label: "Requirements",
			type: "textEditor"
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true
		},
		{
			name: "isConfidentialityRequired",
			label: "Is a Confidentiality Agreement required?",
			type: "checkbox",
			helpText: "(tick if yes)"
		},
		{
			name: "locationType",
			label: "Location",
			required: true,
			type: "select",
			items: ["Virtually", "On Premises"]
		},
		{
			name: "location",
			label: "Location"
		},
		{
			name: "startDate",
			label: "Start Date",
			type: "date",
			clearable: true
		},
		{
			name: "duration",
			label: "Duration"
		},
		{
			name: "participants",
			label: "Participants",
			type: "autoComplete",
			isMulti: true,
			loadItems: {
				route: "students",
				mapItem: s => ({ ...s, label: s.name, value: s.studentId })
			},
			mapValue: s => ({ ...s, label: s.name, value: s.studentId }),
			chipColour: props => props.primaryColour
		},
		{
			name: "channels",
			label: "Channels",
			type: "autoComplete",
			required: true,
			isMulti: true,
			loadItems: {
				route: "channels",
				mapItem: c => ({ ...c, label: c.name, value: c.channelId })
			},
			mapValue: c => ({ ...c, label: c.channelName || c.name, value: c.channelId })
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment =>
				window.open(`/StudentProject/DownloadAttachment?projectId=${attachment.studentProjectId}&attachmentId=${attachment.studentProjectAttachmentId}`, "_self")
		},
		{
			name: "openTo",
			type: "custom",
			widget: props => {
				const isSelected = (opt) => {
					return includes((props.value || "").split(","), opt);
				};
				const selectOpt = (opt) => {
					let v = (props.value || "").split(",");
					if (isSelected(opt)) {
						v = filter(v, v => v !== opt);
					} else {
						v.push(opt);
					}
					props.onChange(v.join(","));
				};
				return (
					<FormControl fullWidth={true} error={props.error} style={{
						textAlign: 'left',
						...props.style
					}}>
						<FormLabel>Open to responses from (tick all that apply):</FormLabel>
							<Checkbox checked={isSelected("Organisations")} onChange={() => selectOpt("Organisations")} label="Organisations" />
							<Checkbox checked={isSelected("Institutions")} onChange={() => selectOpt("Institutions")} label="Institutions" />
							<Checkbox checked={isSelected("Undergraduate")} onChange={() => selectOpt("Undergraduate")} label="Students (undergraduate)" />
							<Checkbox checked={isSelected("Postgraduate")} onChange={() => selectOpt("Postgraduate")} label="Students (Postgraduate)" />
					</FormControl>
				);
			}
		},
		{
			name: "outcome",
			type: "custom",
			widget: props => {
				const isSelected = (opt) => {
					return includes((props.value || "").split(","), opt);
				};
				const selectOpt = (opt) => {
					let v = props.value ? props.value.split(",") : [];
					if (isSelected(opt)) {
						v = filter(v, v => v !== opt);
					} else {
						v.push(opt);
					}
					props.onChange(v.join(","));
				};
				return (
					<FormControl fullWidth={true} error={props.error} style={{
						textAlign: 'left',
						...props.style
					}}>
						<FormLabel>Specify the outcome you are seeking from a project (tick all that apply):</FormLabel>
							<Checkbox checked={isSelected("Research")} onChange={() => selectOpt("Research")} label="Research and report for your organisation" />
							<Checkbox checked={isSelected("ProofOfConcept")} onChange={() => selectOpt("ProofOfConcept")} label="Development of a proof of concept (software application)" />
							<Checkbox checked={isSelected("Design")} onChange={() => selectOpt("Design")} label="Application solution design " />
							<Checkbox checked={isSelected("Collaboration")} onChange={() => selectOpt("Collaboration")} label="Research collaboration to solve your problem" />
					</FormControl>
				);
			}
		},
	]
};

const EditStudentProject = props => {
	const { project, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} Challenge`} loading={loading} className="edit-business-challenge">
			<Form onSubmit={() => props.validateFields() && props.saveProject(props.values, props.formFiles)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.openTo()}</Grid>
					<Grid item xs={12}>{fields.participants()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.details()}</Grid>
					<Grid item xs={12}>{fields.image()}</Grid>
					<Grid item xs={12}>{fields.outcome()}</Grid>
					<Grid item xs={12}>{fields.objectives()}</Grid>
					
					<Grid item xs={12} sm={6}>{fields.locationType()}</Grid>
					{props.values.locationType === "On Premises" && 
						<Grid item xs={12} sm={6}>{fields.location()}</Grid>
					}
					<Grid item xs={12}>
						<span className="h3" style={{ marginBottom: 0 }}>Primary Contact Details</span>
					</Grid>
					<Grid item xs={12} sm={6}>{fields.organisationName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.website()}</Grid>
					<Grid item xs={12} sm={6}>{fields.primaryName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.primaryEmail()}</Grid>
					<Grid item xs={12} sm={6}>{fields.primaryPhone()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
					<Grid item xs={12}>{fields.isConfidentialityRequired()}</Grid>
					<Grid item xs={12}>{fields.channels()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} Challenge</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteProject(project.studentProjectId)}>
							Delete Challenge
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditStudentProject.propTypes = { 
	project: PropTypes.object,
	saveProject: PropTypes.func.isRequired,
	deleteProject: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired
};

EditStudentProject.defaultProps = {
	project: {},
	deleteProject: () => null,
	isNew: false
};

export default withForm(form)(EditStudentProject);