import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { withForm } from '../../utils/forms';
import { post } from '../../utils/ajax';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import TextField from '../widgets/TextField';
import PageLayout from '../PageLayout';
import LogRocket from 'logrocket';

const form = {
	fields: [
		{
			name: "subject",
			required: true,
			label: "Subject"
		},
		{
			name: "message",
			required: true,
			label: "Message",
			type: "textEditor"
		}
	]
};

const ContactUs = props => {
	const [loading, setLoading] = React.useState(false);

	const handleSubmit = () => {
		if (props.validateFields()) {
			setLoading(true);

			post({
				url: "/api/support/contact-us",
				data: {
					subject: props.values.subject,
					message: props.values.message,
					logRocketUrl: LogRocket.recordingURL
				},
				onSuccess: result => {
					setLoading(false);
					props.updateValues({ subject: "", message: "" });

					if (result.success) {
						props.showSuccessNotification(result.message);
					} else {
						props.showErrorNotification(result.message);
					}
				},
				onError: error => {
					setLoading(false);
					props.showErrorNotification(error.message);
				}
			});
		}
	}

	return (
		<PageLayout title="Contact us" pageContent={
			<Card title="Contact us" subtitle={props.contactUsText}>
				<Form onSubmit={handleSubmit}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TextField label="Full name" value={props.fullName} disabled />
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField label="Email" value={props.email} disabled />
						</Grid>
						<Grid item xs={12}>{props.fields.subject()}</Grid>
						<Grid item xs={12}>{props.fields.message()}</Grid>
					</Grid>
					<Button type="submit" loading={loading} style={{ marginTop: 20 }}>Submit</Button>
				</Form>
			</Card>
		} breadcrumbs={["Contact us"]} />
	);
};

ContactUs.propTypes = {
	fullName: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	contactUsText: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	fullName: state.context.fullName,
	email: state.context.email,
	contactUsText: state.settings.contactUsText || ""
});

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ContactUs));
