import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { tryParseJson } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification } from '../store/notifications';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	isLoading: false,
	userNotifications: []
};

const CLEAR_USER_NOTIFICATIONS = "CLEAR_USER_NOTIFICATIONS";
const GET_USER_NOTIFICATIONS_REQUEST = "GET_USER_NOTIFICATIONS_REQUEST";
const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";
const GET_USER_NOTIFICATIONS_FAILURE = "GET_USER_NOTIFICATIONS_FAILURE";
const MARK_READ_USER_NOTIFICATION_REQUEST = "MARK_READ_USER_NOTIFICATION_REQUEST";
const MARK_READ_USER_NOTIFICATION_SUCCESS = "MARK_READ_USER_NOTIFICATION_SUCCESS";
const MARK_READ_USER_NOTIFICATION_FAILURE = "MARK_READ_USER_NOTIFICATION_FAILURE";

export const isLoading = createLoadingSelector(["GET_USER_NOTIFICATIONS", "MARK_READ_USER_NOTIFICATION"]);

export const clearUserNotifications = () => ({ type: CLEAR_USER_NOTIFICATIONS });

export const getUserNotifications = count =>
	createFetchAction({
		url: `/api/notifications?count=${count}`,
		startAction: GET_USER_NOTIFICATIONS_REQUEST,
		onError: error => [getUserNotificationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserNotificationsSuccess(data)
	});

export const getUserNotificationsSuccess = data => ({ type: GET_USER_NOTIFICATIONS_SUCCESS, payload: { data } });
export const getUserNotificationsFailure = error => ({ type: GET_USER_NOTIFICATIONS_FAILURE, payload: { error } });

export const markReadUserNotification = userNotification =>
	createPostAction({
		url: `/api/notifications/${userNotification.notificationId}/mark-read`,
		data: userNotification,
		startAction: MARK_READ_USER_NOTIFICATION_REQUEST,
		onError: error => markReadUserNotificationFailure(error),
		onSuccess: data => markReadUserNotificationSuccess(data)
	});

export const markReadUserNotificationSuccess = data => ({ type: MARK_READ_USER_NOTIFICATION_SUCCESS, data });
export const markReadUserNotificationFailure = error => ({ type: MARK_READ_USER_NOTIFICATION_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_USER_NOTIFICATIONS:
			return { ...state, userNotifications: [] };
		case GET_USER_NOTIFICATIONS_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				userNotifications: []
			};
		case GET_USER_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				userNotifications: map(action.payload.data, n => ({ ...n, details: tryParseJson(n.details) }))
			};
		case MARK_READ_USER_NOTIFICATION_REQUEST:
			return {
				...state,
				isLoading: true,
				userNotifications: map(state.userNotifications, n => 
					n.notificationId === action.data.notificationId ? { ...n, readDate: new Date() } : n
				)
			};
		case MARK_READ_USER_NOTIFICATION_SUCCESS:
		case MARK_READ_USER_NOTIFICATION_FAILURE:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};
