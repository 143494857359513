import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import uniqueId from 'lodash/uniqueId';
import { MenuItem, Grow, Paper, MenuList, Popper } from '@mui/material';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';

const ProblemTitleField = props => {
	const inputRef = React.useRef(null);
	const [state, setState] = React.useState({
		id: props.id || uniqueId("text-field-"),
		anchorEl: null,
		showDropdown: false,
		allProblems: [],
		matchingProblems: [],
		loading: false,
		fuse: null
	});

	React.useEffect(() => {
		get({
			url: "/api/problems",
			onSuccess: problems =>
				setState({ 
					...state,
					loading: false,
					allProblems: problems,
					fuse: new Fuse(problems, {
						shouldSort: true,
						threshold: 0.3,
						location: 0,
						distance: 100,
						maxPatternLength: 32,
						minMatchCharLength: 1,
						keys: ["title"]
					})
				}),
			onError: () => setState({ ...state, loading: false })
		});
	}, []);

	const onChange = e => {
		const { value } = e.target;

		if (value && !state.loading && state.allProblems && state.allProblems.length > 0 && state.fuse) {
			const result = state.fuse.search(value) || [];

			setState({ ...state, showDropdown: result.length > 0, loading: false, matchingProblems: result.slice(0, 5) });
		} else if (value && state.loading) {
			setState({ ...state, showDropdown: true, matchingProblems: [] });
		} else {
			setState({ ...state, showDropdown: false, loading: false, matchingProblems: [] });
		}

		return props.onChange(e);
	};

	return (
		<FormControl fullWidth error={props.error} style={props.style}>
			{props.label &&
				<InputLabel 
					htmlFor={state.id}
					shrink={Boolean(props.placeholder) || props.type === "date" || undefined}
					classes={{
						shrink: props.classes.shrink
					}}
					{...props.InputLabelProps}
				>
					{props.label}
					{props.required && <span style={{ color: props.errorColour }}> *</span>}
				</InputLabel>
			}
			<Input 
				id={state.id}
				name={props.name}
				autoComplete="off"
				type={props.type}
				value={props.value}
				onChange={onChange}
				onBlur={() => setState({ ...state, showDropdown: false })}
				onKeyUp={e => e.keyCode === 13 ? props.onEnter(e) : props.onKeyUp(e)}
				placeholder={props.placeholder}
				autoFocus={props.autoFocus}
				className="dih-input"
				multiline={props.multiline}
				startAdornment={props.startAdornment ? <InputAdornment position="start">{props.startAdornment}</InputAdornment> : null}
				disabled={props.disabled}
				inputRef={inputRef}
				inputProps={{
					"aria-label": props.label || props.placeholder,
					...props.inputProps
				}}
			/>
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
			<Popper 
				open={state.showDropdown} 
				anchorEl={inputRef.current} 
				placement="bottom-start" 
				style={{ zIndex: 30 }} 
				popperOptions={{ positionFixed: false }}
				transition
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps} timeout={350} onExited={() => setState({ ...state, currentTab: "" })}>
						<Paper>
							{state.loading ? <CircularProgress size={24} /> :
								<React.Fragment>
									<span style={{ fontWeight: "bold", fontSize: "12px", margin: 10 }}>Do any of the following items match? Click to view.</span>
									<MenuList>
										{map(state.matchingProblems, (p, index) => 
											<MenuItem 
												key={`prob-title-suggestion-${index}`}
												className="nav-link-sub-menu-item" 
												component={Link} 
												to={`/problems/${p.problemId}`}
											>
												{p.title}
											</MenuItem>
										)}
									</MenuList>
								</React.Fragment>
							}
						</Paper>
					</Grow>
				)}
			</Popper>
		</FormControl>
	);
};

ProblemTitleField.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	onChange: PropTypes.func,
	onEnter: PropTypes.func,
	onKeyUp: PropTypes.func,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	error: PropTypes.bool,
	helpText: PropTypes.node,
	autoFocus: PropTypes.bool,
	multiline: PropTypes.bool,
	startAdornment: PropTypes.string,
	disabled: PropTypes.bool,
	inputProps: PropTypes.object,
	errorColour: PropTypes.string.isRequired
};

ProblemTitleField.defaultProps = {
	id: "",
	name: "",
	type: "text",
	label: "",
	onChange: () => null,
	onEnter: () => null,
	onKeyUp: () => null,
	required: false,
	placeholder: "",
	error: false,
	helpText: "",
	value: "",
	autoFocus: false,
	multiline: false,
	startAdornment: "",
	disabled: false,
	inputProps: {}
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProblemTitleField);

