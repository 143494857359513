import { createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { showSuccessNotification, showErrorNotification, showInfoNotification, showWarningNotification } from '../store/notifications';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';
import { get, post } from '../utils/ajax';
import LogRocket from 'logrocket';
import Startup from '../Startup';
// import { setUserHasCompetencies } from './competencies';
import { toggleUserTour, toggleUserOrganisationDialog, toggleBusinessProfileDialog } from './layout';
import { clearSettings } from './settings';
import localforage from 'localforage';
import { bootstrapSuccess } from './bootstrap';
import { requireBusinessProfile } from './security';
import { getQueryParameterByName } from '../utils/utils';

export const isLoading = createLoadingSelector(["REGISTER"]);

const UPDATE_CONTEXT = "UPDATE_CONTEXT";
export const updateContext = () => ({ type: UPDATE_CONTEXT, context: readContextFromCookie() });

const readContextFromCookie = () => {
	const parts = `; ${document.cookie}`.split("; PR_UserData=");
	return parts.length === 2 ? JSON.parse(decodeURIComponent(parts.pop().split(";").shift())) : {}
};

const UPDATE_CONTEXT_FROM_PROFILE = "UPDATE_CONTEXT_FROM_PROFILE";
export const updateFromProfile = profile => ({ type: UPDATE_CONTEXT_FROM_PROFILE, profile });

export const logIn = (identity, password, keepLoggedIn, onSuccess) =>
	createFormPostAction({
		url: "/api/users/login",
		data: { identity, password, rememberMe: keepLoggedIn },
		onSuccess: (result, dispatch, getState) => {
			if (result.success) {
				const bootstrapData = result.bootstrapData;
				dispatch(bootstrapSuccess(bootstrapData));

				get({
					url: "/api/antiforgery",
					onSuccess: () => {
						if (onSuccess) onSuccess(result);

						dispatch(toggleUserOrganisationDialog(bootstrapData.registerUserOrganisation && !bootstrapData.userOrganisation));
						dispatch(toggleUserTour(bootstrapData.showUserTour));

						const store = getState();
						if (requireBusinessProfile(store)) {
							dispatch(toggleBusinessProfileDialog(!bootstrapData.hasBusinessProfile));
						}
						
						dispatch(changeLocation(getQueryParameterByName("redirectUrl") || "/"));
						Startup.init(dispatch, true);
					}
				});
			} else {
				if (result.resetPassword) {
					dispatch(changeLocation(result.redirectUrl));
					dispatch(showWarningNotification(result.message));
				} else if (onSuccess) {
					// Should onSuccess be called despite failure?
					onSuccess(result);
				} else {
					dispatch(showErrorNotification(result.message));
				}
			}
		},
		onError: error => console.error(error.message)
	});

export const impersonate = (userId, onSuccess) =>
	createPostAction({
		url: `/api/users/${userId}/impersonate`,
		onSuccess: (result, dispatch) => {
			if (result.success) {
				get({
					url: "/api/antiforgery",
					onSuccess: () => {
						if (onSuccess) onSuccess(result);

						// clear cached state since switching to a user should not use it at all.
						// TODO: store state using user id
						localforage.setItem('persistedState', {});

						// Force full page reload to ensure page is setup correctly
						// for the new user. Had strange session expired issues when
						// using the logic in login()
						window.location = '/';
					}
				});
			} else {
				dispatch(showErrorNotification(result.message));
			}
		},
		onError: error => console.error(error.message)
	});
	
// Prevent redirect and logout notifiction on timeout
// export const logOut = timeout => window.location = '/Account/LogOut';
export const logOut = timeout => 
	createPostAction({
		url: "/api/users/logout",
		onSuccess: result => result.success && [
			clearSettings(),
			userLoggedOut(), 
			bootstrapSuccess(result.bootstrapData), 
			...(timeout ? [showInfoNotification("Your session has expired, please login again.")] : [changeLocation("/login"), showSuccessNotification(result.message)])
		],
		onError: error => console.error(error.message)
	});

const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const userLoggedOut = () => ({ type: USER_LOGGED_OUT });

const CREATE_ACCOUNT_REQUEST = "CREATE_ACCOUNT_REQUEST";
const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
const CREATE_ACCOUNT_FAILURE = "CREATE_ACCOUNT_FAILURE";

export const createAccount = (values) => {
	return createPostAction({
		url: "/api/users/create-account",
		data: values,
		startAction: CREATE_ACCOUNT_REQUEST,
		onSuccess: (result, dispatch) => {
			if (result.success) {
				// const bootstrapData = result.bootstrapData;
				// dispatch(bootstrapSuccess(bootstrapData));
				// dispatch(updateContext());
				dispatch(createAccountSuccess(result));
				dispatch(changeLocation("/login"));
				dispatch(showInfoNotification(result.message));
			} else {
				dispatch(createAccountFailure(result));
				dispatch(showErrorNotification(result.message));
			}
		},
		onError: error => [registerFailure(error), showErrorNotification(error.message)]
	});
}

const createAccountSuccess = result => ({ type: CREATE_ACCOUNT_SUCCESS, result });
const createAccountFailure = error => ({ type: CREATE_ACCOUNT_FAILURE, error });

const COMPLETE_PROFILE_REQUEST = "COMPLETE_PROFILE_REQUEST";
const COMPLETE_PROFILE_SUCCESS = "COMPLETE_PROFILE_SUCCESS";
const COMPLETE_PROFILE_FAILURE = "COMPLETE_PROFILE_FAILURE";

export const completeProfile = (values) => {
	return createPostAction({
		url: "/api/users/complete-profile",
		data: values,
		startAction: COMPLETE_PROFILE_REQUEST,
		onSuccess: (result, dispatch) => {
			if (result.success) {
				const bootstrapData = result.bootstrapData;
				dispatch(bootstrapSuccess(bootstrapData));
				dispatch(updateContext());
				dispatch(completeProfileSuccess(result));
				dispatch(changeLocation("/"));
				dispatch(showInfoNotification(result.message));
			} else {
				dispatch(completeProfileFailure(result));
				dispatch(showErrorNotification(result.message));
			}
		},
		onError: error => [registerFailure(error), showErrorNotification(error.message)]
	});
}

const completeProfileSuccess = result => ({ type: COMPLETE_PROFILE_SUCCESS, result });
const completeProfileFailure = error => ({ type: COMPLETE_PROFILE_FAILURE, error });


export const registerResearchLogiq = (values, avatar, tenantId) => {
	const formData = new FormData();
	formData.append("User", JSON.stringify({
		organisationId: values.organisationId,
		userOrganisation: values.userOrganisation,
		username: values.username,
		firstName: values.firstName,
		lastName: values.lastName,
		email: values.email,
		userType: values.userType,
		password: values.password
	}));

	if (avatar) formData.append("AvatarFile", avatar);
	if (tenantId) formData.append("TenantId", tenantId);

	return createFormPostAction({
		url: "/api/research-logiq/register",
		data: formData,
		startAction: REGISTER_REQUEST,
		onSuccess: result => result.success ? [updateContext(), registerSuccess(result), changeLocation("/"), showInfoNotification(result.message)] : 
			[registerFailure(result), showErrorNotification(result.message)],
		onError: error => [registerFailure(error), showErrorNotification(error.message)]
	});
}


const REGISTER_REQUEST = "REGISTER_REQUEST";
const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const REGISTER_FAILURE = "REGISTER_FAILURE";

export const register = (values, avatar, tenantId) => {
	const formData = new FormData();
	formData.append("User", JSON.stringify({
		organisationId: values.organisationId,
		userOrganisation: values.userOrganisation,
		username: values.username,
		firstName: values.firstName,
		lastName: values.lastName,
		email: values.email,
		positionId: values.positionId,
		departmentId: values.departmentId,
		password: values.password,
		isAcademic: values.isAcademic,
		isExpert: values.isExpert,
		membershipLevelId: values.membershipLevelId,
		selfRegRole: values.selfRegRole,
		publishBusinessProfile: values.publishBusinessProfile
	}));

	if (avatar) formData.append("AvatarFile", avatar);
	if (tenantId) formData.append("TenantId", tenantId);

	return createFormPostAction({
		url: "/api/users/register",
		data: formData,
		startAction: REGISTER_REQUEST,
		onSuccess: result => result.success ? [updateContext(), registerSuccess(result), changeLocation("/"), showInfoNotification(result.message)] : 
			[registerFailure(result), showErrorNotification(result.message)],
		onError: error => [registerFailure(error), showErrorNotification(error.message)]
	});
}

const registerSuccess = result => ({ type: REGISTER_SUCCESS, result });
const registerFailure = error => ({ type: REGISTER_FAILURE, error });

const ADD_FAILED_AJAX_CALL = "ADD_FAILED_AJAX_CALL";
export const addFailedAjaxCall = ajaxCall => ({ type: ADD_FAILED_AJAX_CALL, ajaxCall });
const CLEAR_FAILED_AJAX_CALLS = "CLEAR_FAILED_AJAX_CALLS";
export const clearFailedAjaxCalls = () => ({ type: CLEAR_FAILED_AJAX_CALLS });

const identifyWithLogRocket = user => {
	if (!user) return;
	LogRocket.identify(user.userId, {
		name: user.fullName,
		...user,
		functions: JSON.stringify(user.functions)
	});
};


const SET_SHOW_PASSPORT_INTRO = "SET_SHOW_PASSPORT_INTRO";
const SET_SHOW_PASSPORT_INTRO_REQUEST = "SET_SHOW_PASSPORT_INTRO_REQUEST";
const SET_SHOW_PASSPORT_INTRO_SUCCESS = "SET_SHOW_PASSPORT_INTRO_SUCCESS";
const SET_SHOW_PASSPORT_INTRO_FAILURE = "SET_SHOW_PASSPORT_INTRO_FAILURE";
export const setShowSkillsPassportIntro = show => {
	return (dispatch) => {
		dispatch({ type: SET_SHOW_PASSPORT_INTRO, payload: { show } });
		post({
			url: `api/users/set-do-not-show-skills-passport-intro?show=${show}`,
			onSuccess: (data) => {
				dispatch({ type: SET_SHOW_PASSPORT_INTRO_SUCCESS, payload: { data } });
			},
			onError: (error) => {
				dispatch({ type: SET_SHOW_PASSPORT_INTRO_FAILURE, payload: { error } });
			}
		});

		dispatch({ type: SET_SHOW_PASSPORT_INTRO_REQUEST });
	};
};


const initialState = { 
	failedAjaxCalls: [], 
	functions: [], 
	isDev: false,
	iGlobalAdministrator: false,
	isTenantAdministrator: false,
	isDepartmentAdministrator: false,
	isAdministrator: false,
	isMember: false,
	isSubscribed: false,
	roles: [],
	hasAccess: true
};

const SET_ACCESS = "SET_ACCESS";
export const revokeAccess = () => ({ type: SET_ACCESS, hasAccess: false });
export const resetAccess = () => ({ type: SET_ACCESS, hasAccess: true });

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_CONTEXT:
			identifyWithLogRocket(action.context);
			return { ...state, ...action.context };
		case UPDATE_CONTEXT_FROM_PROFILE:
			return {
				...state,
				avatar: action.profile.avatar,
				email: action.profile.email,
				fullName: action.profile.fullName
			};
		case USER_LOGGED_OUT:
			return { ...state, isLoggedIn: false };
		case REGISTER_REQUEST:
			return { ...state, isLoading: true };
		case REGISTER_SUCCESS:
		case REGISTER_FAILURE:
			return { ...state, isLoading: false };
		case CREATE_ACCOUNT_REQUEST:
			return { ...state, isLoading: true };
		case CREATE_ACCOUNT_SUCCESS:
		case CREATE_ACCOUNT_FAILURE:
			return { ...state, isLoading: false };
		case COMPLETE_PROFILE_REQUEST:
			return { ...state, isLoading: true };
		case COMPLETE_PROFILE_SUCCESS:
		case COMPLETE_PROFILE_FAILURE:
			return { ...state, isLoading: false };
		case ADD_FAILED_AJAX_CALL:
			return { ...state, failedAjaxCalls: state.failedAjaxCalls.concat([action.ajaxCall]) };
		case CLEAR_FAILED_AJAX_CALLS:
			return { ...state, failedAjaxCalls: [] };
		case SET_ACCESS:
			return { ...state, hasAccess: action.hasAccess };
		case SET_SHOW_PASSPORT_INTRO:
			return { ...state, showSkillsPassportIntro: action.show };
		default:
			return state;
	}
};
