import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import DataTable from '../../widgets/dataTable/DataTable';
import SearchField from '../../widgets/SearchField';
import Card from '../../widgets/Card';
import Checkbox from '../../widgets/Checkbox';
import FormButtons from '../../widgets/FormButtons';
import PageLayout from '../../PageLayout';
import { get, post } from '../../../utils/ajax';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import find from 'lodash/find';
import map from 'lodash/map';
import { emptySaveResult } from '../../../variables';
import { showModal } from '../../../store/modal';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';

const PublishAdmin = props => {
	const [search, setSearch] = React.useState("");
    const [statuses, setStatuses] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [saveResult, setSaveResult] = React.useState({ ...emptySaveResult });
    
	React.useEffect(() => {
		get({
			url: '/api/users/get-publishing-statuses',
			onSuccess: data => {
                setLoading(false);
                setStatuses(data);
			},
			onError: () => {
                setLoading(false);
			}
		});
	}, []);

    const update = (sendNotifications) => {
        setLoading(true);
		post({
			url: '/api/users/update-publishing-statuses',
            data: {
                sendNotifications: sendNotifications,
                statuses:filter(statuses, s => s._dirty)
            },
			onSuccess: data => {
                setSaveResult(data);
                setLoading(false);
                setStatuses(map(statuses, s => ({...s, _dirty: false })));
                props.showSuccessNotification("Publishing status updated");
			},
			onError: error => {
                setLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	};

    const filteredStatuses = search === '' ? statuses : filter(statuses, s => includes(toLower(s.userName), toLower(search)));

	return (
        <PageLayout 
            title="Administer Publishing Status" 
            pageContent={
                <Card title="Search" loading={loading}>
                    <SearchField value={search} onChange={value => setSearch(value)} loading={loading} />
                    
                    <DataTable 
                        data={filteredStatuses} 
                    >
                        <DataTableColumn name="userName" label="User" />
                        <DataTableColumn name="email" label="Email" />
                        <DataTableColumn
                            name="OrganisationTitle" 
                            label="Organisation" 
                            canFilter
                            filterType="AutoComplete"
                            loadItems={{
                                route: "organisations", 
                                mapItem: o => ({ label: o.name, value: o.organisationId })
                            }}
                        />
                        <DataTableColumn 
                            label="Publish Insights" 
                            renderer={d => {
                                return (
                                    <Checkbox
                                        checked={d.canPublishInsights}
                                        onChange={evt => {
                                            const status = find(statuses, s => s.userId === d.userId);
                                            status.canPublishInsights = evt.target.checked;
                                            status._dirty = true;
                                            setStatuses([...statuses]);
                                        }}
                                    />
                                );
                            }}
                        />
                        <DataTableColumn 
                            label="Publish Centres" 
                            renderer={d => {
                                return (
                                    <Checkbox
                                        checked={d.canPublishCentres}
                                        onChange={evt => {
                                            const status = find(statuses, s => s.userId === d.userId);
                                            status.canPublishCentres = evt.target.checked;
                                            status._dirty = true;
                                            setStatuses([...statuses]);
                                        }}
                                    />
                                );
                            }}
                        />

                    </DataTable>

                    <FormButtons 
                        loading={loading}
                        success={saveResult.success}
                        onSave={() => { props.updatePublishingStatuses(update); }}
                    />
                </Card>
            } 
            breadcrumbs={["Administer Publishing Status"]} 
        />
    );
};

PublishAdmin.propTypes = { 
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    updatePublishingStatuses: (update) => dispatch(dispatch(showModal("SIMPLE", {
		title: "Send Notifications?",
		message: "Do you want to notify users that have been granted publishing access?",
		onOk: () => update(true),
		onCancel: () => update(false)
	}))),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishAdmin);
