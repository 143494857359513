import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import { parseYoutube, prettifyDate } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import map from 'lodash/map';
import Tag from '../widgets/Tag';
import DownloadIcon from '@mui/icons-material/GetApp';
import LinkIcon from '@mui/icons-material/Link';
import PrintIcon from '@mui/icons-material/Print';
import AcademicLogo from '../images/insights/A icon-01.svg';
import IndustryLogo from '../images/insights/I icon.svg';
import MarketInsightLogo from '../images/insights/M 1.png';
import CreativeCommonLicenseLogo from '../images/insights/Cc.org_cc_by_license.jpg';
import TabContainer from '../widgets/TabContainer';
import Tab from '../widgets/Tab';
import { withStyles } from 'tss-react/mui';
import take from 'lodash/take';

const styles = (theme) => ({
	otherInsightsTitle: {
		fontWeight: 500,
		display: 'block',
		marginTop: '10px'
	},
	otherInsightsAuthors: {
		fontStyle: 'italic',
		fontSize: 'small'
	}
});

const downloadAttachment = (insightId, attachmentId) =>
	window.open(`/Insight/DownloadAttachment?insightId=${insightId}&attachmentId=${attachmentId}`, "_self");

const ViewInsight = props => {
	const { insight, classes } = props;

	let channel = null;
	if (insight.channels && insight.channels.length > 0) {
		channel = insight.channels[0];
	}

	return props.loading || isEmpty(insight) ? <CircularProgress size={24} /> : (
		<Grid container spacing={4} className="insight-details">
			<Grid item xs={12} md={12} style={{ paddingBottom: 0, position: 'relative' }}>
				<Button 
					onClick={window.print}
					style={{ float: 'right', marginLeft: '10px' }}
				>
					<PrintIcon style={{ marginRight: 5}} />Print
				</Button>
				{props.canPublishInsights && 
					<Button 
						onClick={() => {
							if (insight.visibility === "Public") {
								props.unpublishInsight(insight.insightId);
							} else {
								props.publishInsight(insight.insightId);
							}
						}}
						style={{ float: 'right', marginLeft: '10px' }}
					>{insight.visibility === "Public" ? "Unpublish" : "Publish"}
					</Button>
				}
				{props.canEdit && <ButtonLink style={{ float: 'right', marginLeft: '10px' }} to={`/${props.isCommunity ? "my-community/" : ""}insights/edit/${insight.insightId}`}>Edit insight</ButtonLink>}
				{/* <Button
					onClick={() => { props.showChat(insight.expertUserId) }} 
					color="secondary" 
					style={{ float: 'right', marginRight: '10px' }}
				>
					Chat with {insight.expertName}
				</Button> */}
				<span className="h1">{insight.title}</span>
				<div className="insight-content-type">
					{insight.contentType === "Academic" && <img src={AcademicLogo} style={{ height: '50px' }} alt="Academic" />}
					{insight.contentType === "Industry" && <img src={IndustryLogo} style={{ height: '50px' }} alt="Industry" />}
					{insight.contentType === "MarketInsight" && <img src={MarketInsightLogo} style={{ height: '50px' }} alt="Market Insight" />}
					{insight.visibility === "Public" && 
						<Tag label="Published" id="published-tag" style={{ marginLeft: '10px' }} />
					}
				</div>
				<div className="dates">
					Published {prettifyDate(insight.createdAt)}
					{insight.createdByName && ` by ${insight.createdByName}`}
					{insight.updated && `, Updated ${prettifyDate(insight.updatedAt)}`}
				</div>
				{insight.authors && 
					<div style={{ fontSize: 'small', fontStyle: 'italic' }}>
						{insight.authors}
					</div>
				}
				{insight.authorName &&
					<React.Fragment>
						<span className="h4" style={{ fontWeight: 500 }}>
							Author
							{insight.authorBusinessProfileId && 
								<ButtonLink 
									to={`/business-profile/${insight.authorBusinessProfileId}`}
									style={{ marginLeft: '20px' }}
									color="secondary" 
								>Author Profile</ButtonLink>
							}
						</span>

						<p style={{ marginBottom: 0 }}>{insight.authorName}</p>
					</React.Fragment>
				}
			</Grid>
{/* 
			<Grid item xs={12} md={8}>
				<Card className="other-insight-suggestions">

					<span className="h4" style={{ fontWeight: 500 }}>Description</span>
					<p>{insight.description}</p>
					<span className="h4" style={{ fontWeight: 500 }}>Summary</span>
					<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: insight.content }} />
					{insight.keyFindings && 
						<React.Fragment>
							<span className="h4" style={{ fontWeight: 500 }}>Key Findings</span>
							<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: insight.keyFindings }} />
						</React.Fragment>
					}
					{insight.implicationsForPractice && 
						<React.Fragment>
							<span className="h4" style={{ fontWeight: 500 }}>Implications For Practice</span>
							<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: insight.implicationsForPractice }} />
						</React.Fragment>
					}
					{insight.attachments && insight.attachments.length > 0 &&
						<section>
							<span className="h4" style={{ fontWeight: 500 }}>Attachments</span>
							<div className="row">
								<ul className="list-unstyled">
									{map(insight.attachments, (a, i) =>
										<li className="media" key={i}>
											<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
											<div className="media-body">
												<h5 className="mt-0 mb-1">{a.name}</h5>
												{/* <p>{a.description}</p> * / }
												<p>Reference: {a.reference}</p>
												<Button 
													variant="outlined" 
													startIcon={<DownloadIcon />}
													onClick={() => downloadAttachment(insight.insightId, a.insightAttachmentId)}>
													Download now
												</Button>
											</div>
										</li>
									)}
								</ul>
							</div>
						</section>
					}
					{insight.links && insight.links.length > 0 &&
						<section>
							<span className="h4" style={{ fontWeight: 500 }}>Web Links</span>
							<ul style={{
								listStyle: 'none',
								position: 'relative'
							}}>
								{insight.links.map((l, i) => 
									<li key={i}><LinkIcon style={{ position: 'absolute', left: '10px' }} /> {l.label} &mdash; <a href={`https://${l.to}`} target="_blank" rel="noopener noreferrer">Go to website to view</a></li>
								)}
							</ul>
						</section>
					}
					{insight.videos && insight.videos.length > 0 && 
						<section>
							<span className="h4" style={{ fontWeight: 500 }}>Videos</span>
							{insight.videos.map((v, i) => {
								if (startsWith(v, "<")) {
									return (
										<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: v }} />
									);
								} else {
									return (
										<div key={i} className="youtube-embed">
											<div className="frame-wrapper">
												<iframe 
													title="Videos" 
													src={`//www.youtube.com/embed/${parseYoutube(v)}`} 
													frameBorder="" 
													allowFullScreen 
												/>
											</div>
										</div>
									);
								}
							})}
						</section>
					}
				</Card>
			</Grid> */}

			<Grid item xs={12} md={8}>
				<TabContainer>
					<Tab label="Insight">
						<p>{insight.description}</p>

						<span className="h1">Research Insight</span>
						<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: insight.content }} />
{/* 
						{insight.keyFindings && 
							<React.Fragment>
								<span className="h4" style={{ fontWeight: 500 }}>Key Findings</span>
								<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: insight.keyFindings }} />
							</React.Fragment>
						} */}
						{insight.image && 
							<div style={{ textAlign: 'center', marginBottom: '10px' }}>
								<img src={insight.image} alt={insight.title} style={{ maxWidth: '70%' }} />
							</div>
						}
						{/* {insight.implicationsForPractice && 
							<React.Fragment>
								<span className="h4" style={{ fontWeight: 500 }}>Implications For Practice</span>
								<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: insight.implicationsForPractice }} />
							</React.Fragment>
						} */}

						{insight.videos && insight.videos.length > 0 && 
							<div>
								{insight.videos.map((v, i) => {
									if (startsWith(v, "<")) {
										return (
											<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: v }} />
										);
									} else {
										return (
											<div key={i} className="youtube-embed">
												<div className="frame-wrapper">
													<iframe 
														title="Videos" 
														src={`//www.youtube.com/embed/${parseYoutube(v)}`} 
														frameBorder="" 
														allowFullScreen 
													/>
												</div>
											</div>
										);
									}
								})}
							</div>
						}
					</Tab>
					{insight.attachments && insight.attachments.length > 0 &&
					<Tab label="Attachments">

							<div className="row">
								<ul className="list-unstyled">
									{map(insight.attachments, (a, i) =>
										<li className="media" key={i}>
											<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
											<div className="media-body">
												{/* <h5 className="mt-0 mb-1">{a.name}</h5> */}
												{/* <p>{a.description}</p> */}
												<p>Citation: {a.reference}</p>
												<Button 
													variant="outlined" 
													startIcon={<DownloadIcon />}
													onClick={() => downloadAttachment(insight.insightId, a.insightAttachmentId)}>
													Download now
												</Button>
											</div>
										</li>
									)}
								</ul>
								</div>
								</Tab>

					}
					{insight.links && insight.links.length > 0 &&
					<Tab label="Web Links">
							<ul style={{
								listStyle: 'none',
								position: 'relative'
							}}>
								{insight.links.map((l, i) => 
									<li key={i}><LinkIcon style={{ position: 'absolute', left: '10px' }} /> {l.label} &mdash; <a href={`https://${l.to}`} target="_blank" rel="noopener noreferrer">Go to website to view</a></li>
								)}
							</ul>
						</Tab>
					}
				</TabContainer>
			</Grid>	


			<Grid item xs={12} md={4}>
				{channel && channel.image && 
					<div style={{ textAlign: "center", padding: 0, marginBottom: '10px' }}>
						{channel.image && <img src={channel.image} alt={channel.name} style={{ width: '100%' }} />}
					</div>
				}
				<section className="channels-tags">
					{insight.channels.map((c, index) => <Tag key={index} label={c.name} colour={props.primaryColour} />)}
					<br />
					{insight.tags.map((t, index) => <Tag key={index} label={t.name} />)}
				</section>

				<Card className="other-insight-suggestions">
					<h4>Other insight suggestions</h4>

					{
						map(take(insight.suggestions, 4), sug => {
							return (
								<React.Fragment>
									<a className={classes.otherInsightsTitle} href={`/insights/${sug.suggestedInsightId}`}>{sug.title}</a>
									<span className={classes.otherInsightsAuthors}>{sug.authors}</span>
								</React.Fragment>
							);
						})
					}

					{/* <a className={classes.otherInsightsTitle} href="/insights">Diversifying the health workforce: a mixed methods analysis of an employment integration strategy</a>
					<span className={classes.otherInsightsAuthors}>David Smith</span>

					<a className={classes.otherInsightsTitle} href="/insights">Barriers Impeding the Adoption of Information and Communication Technology (ICT) in Construction Project Management</a>
					<span className={classes.otherInsightsAuthors}>David Smith</span> */}
				</Card>

				<img src={CreativeCommonLicenseLogo} style={{ }} alt="Creative Commons" />
			</Grid>
		</Grid>
	);
};

ViewInsight.propTypes = {
	insight: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canMakePublic: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool.isRequired,
    showChat: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	isApplying: PropTypes.bool.isRequired,
	canPublishInsights: PropTypes.bool.isRequired,
	publishInsight: PropTypes.func.isRequired,
	unpublishInsight: PropTypes.func.isRequired
};

export default withStyles(ViewInsight, styles);