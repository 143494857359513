import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import { toDate } from '../utils/utils';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	passport: {}
};

const CLEAR_PASSPORT = 'CLEAR_PASSPORT';
const GET_PASSPORT_REQUEST = 'GET_PASSPORT_REQUEST';
const GET_PASSPORT_SUCCESS = 'GET_PASSPORT_SUCCESS';
const GET_PASSPORT_FAILURE = 'GET_PASSPORT_FAILURE';
const SAVE_PASSPORT_REQUEST = 'SAVE_PASSPORT_REQUEST';
const SAVE_PASSPORT_SUCCESS = 'SAVE_PASSPORT_SUCCESS';
const SAVE_PASSPORT_FAILURE = 'SAVE_PASSPORT_FAILURE';

export const isLoading = createLoadingSelector([ 
	'GET_PASSPORT', 
	'SAVE_PASSPORT'
]);

export const clearPassport = () => ({ type: CLEAR_PASSPORT });

export const getPassport = () => (
	createFetchAction({
		url: `/api/passport`,
		startAction: GET_PASSPORT_REQUEST,
		onError: (error) => [getPassportFailure(error), showErrorNotification(error.message)],
		onSuccess: (data) => getPassportSuccess({
			...data,
			updateDate: toDate(data.updateDate)
		})
	})
);
export const getPassportSuccess = (data) => ({ type: GET_PASSPORT_SUCCESS, payload: { data } });
export const getPassportFailure = (error) => ({ type: GET_PASSPORT_FAILURE, payload: { error } });

export const savePassport = (passport, onSuccess) => {
	return createPostAction({
		url: '/api/passport',
		data: passport,
		startAction: SAVE_PASSPORT_REQUEST,
		onError: (error) => [savePassportFailure(error), showErrorNotification(error.message)],
		onSuccess: (data) => {
			if (data && data.success) {
				return [savePassportSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [savePassportSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};
export const savePassportSuccess = (data) => ({ type: SAVE_PASSPORT_SUCCESS, data });
export const savePassportFailure = (error) => ({ type: SAVE_PASSPORT_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_PASSPORT:
			return {
				...state,
				passport: {}
			};
		case GET_PASSPORT_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				passport: {}
			};
		case GET_PASSPORT_SUCCESS:
			return {
				...state,
				isLoading: false,
				passport: action.payload.data
			};
		case SAVE_PASSPORT_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: emptySaveResult
			};
		case SAVE_PASSPORT_SUCCESS:
			return {
				...state,
				...(action.data.success && { passport: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		default:
			return state;
	}
};
