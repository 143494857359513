import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showModal } from '../../../store/modal';
import PageLayout from '../../PageLayout';
import EditUserOrganisation from './EditUserOrganisation';
import axios from 'axios';
import { emptySaveResult } from '../../../variables';
import { get, post } from '../../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';
import { changeLocation } from '../../../store/location';

const EditUserOrganisationController = props => {
	const [state, setState] = React.useState({
		isLoading: false,
		userOrganisation: {},
		saveResult: emptySaveResult
	});

	React.useEffect(() => {
		axios.all([
			get({
				url: `/api/user-organisations/${props.match.params.id}`,
				onSuccess: data => setState(prevState => ({ ...prevState, userOrganisation: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	}, [props.match.params.id]);

	const onSave = (userOrganisation) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
	
		post({
			url: "/api/user-organisations",
			data: userOrganisation,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

				if (data.success) {
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	const onDelete = userOrganisation => {
		props.promptDelete(userOrganisation, () => {
			post({
				url: `/api/user-organisations/${userOrganisation.UserOrganisation}/delete`,
				onSuccess: data => {
					setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToUserOrganisationsPage();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	}


	return (
		<PageLayout 
			title="Edit User Organisation" 
			pageContent={
				<EditUserOrganisation
					canEdit={props.canEdit}
					canSelectTenant={props.isGlobalAdministrator}
					userOrganistion={state.userOrganistion}
					saveResult={props.saveResult}
					loading={props.isLoading} 
					onSave={onSave} 
					onDelete={onDelete}
				/>
			} 
			breadcrumbs={[
				{ label: "User Organisations",  path: "/admin/user-organistaions" }, 
				"Edit User Organisation"
			]} />
	);
};

EditUserOrganisationController.propTypes = { 
	canEdit: PropTypes.bool.isRequired,
	role: PropTypes.object.isRequired,
	getRole: PropTypes.func.isRequired,
	saveRole: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	deleteRole: PropTypes.func.isRequired,
	onStartup: PropTypes.func.isRequired,
	functions: PropTypes.array.isRequired,
	functionGroups: PropTypes.array.isRequired,
	menus: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	canEdit: true
});

const mapDispatchToProps = dispatch => ({
	goToUserOrganisationsPage: () => dispatch(changeLocation('/course-design')),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptDelete: (userOrganisation, onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this User Organisation?",
		onOk: () => onOk()
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserOrganisationController);
