import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';
import { changeLocation } from '../../../store/location';
import { get, post } from '../../../utils/ajax';
import { showModal } from '../../../store/modal';
import { emptySaveResult } from '../../../variables';
import PageLayout from '../../PageLayout';
import EditTag from './EditTag';

const defaultTag = {
	name: "",
	description: ""
};

const EditLDTagController = props => {
	const [state, setState] = React.useState({
		isLoading: false,
		tag: defaultTag,
		saveResult: emptySaveResult
	});

	React.useEffect(() => {
		axios.all([
			get({
				url: `/api/tags/${props.match.params.tagId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, tag: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	}, [props.match.params.id]);

	const onSave = (tag) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
	
		post({
			url: "/api/tags",
			data: tag,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

				if (data.success) {
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	const onDelete = tag => {
		props.promptDeleteTag(tag, () => {
			post({
				url: `/api/tags/${tag.tagId}/delete`,
				onSuccess: data => {
					setState(prevState => ({ ...prevState, saveResult: data, isLoading: false }));

					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToTagsPage();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	}

	return (
		<PageLayout 
			title="Edit Tag" 
			pageContent={
				<EditTag
					canEdit={props.isGlobalAdministrator}
					tag={state.tag}
					onSave={onSave} 
					saveResult={state.saveResult}
					loading={state.isLoading} 
					onDelete={onDelete}
				/>
			} 
			breadcrumbs={[
				{ label: "Tags",  path: "/tags" }, 
				"Edit Tag"
			]} 
		/>
	);
};

EditLDTagController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	promptDeleteTag: PropTypes.func.isRequired,
	goToTagsPage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator
});

const mapDispatchToProps = dispatch => ({
	goToTagsPage: () => dispatch(changeLocation('/tags')),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptDeleteTag: (tag, onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this Tag?",
		onOk: () => onOk(tag)
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLDTagController);
