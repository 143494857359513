import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import uniqueId from 'lodash/uniqueId';
import { IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const itemSource = {
	beginDrag: props => ({ index: props.index }),
	canDrag: props => props.canDrag
};
  
const itemTarget = {
	hover(props, monitor) {
		const { index: dragIndex } = monitor.getItem();

		// Swap position if hover target index is different
		if (dragIndex !== props.index) {
			props.moveItem(dragIndex, props.index);

			// Update index position of drag item in monitor
			monitor.getItem().index = props.index;
		}
	}
};

// To-do: make arrows and add/ remove buttons stack depending on screen width
const DragItem = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("drag-item-"));
	const { index, connectDragSource, connectDropTarget } = props;

	return connectDragSource(connectDropTarget(
		<tr id={id} style={{ opacity: props.isDragging ? 0.5 : 1, borderBottom: props.showBorder && "1px dashed #dfdfdf" }} className="drag-item">
			{!props.simple &&
				<td className="auto-width drag-handle" style={props.cellStyle}>
					<DragIndicatorIcon 
						style={{ verticalAlign: "middle", cursor: "move" }} 
						onMouseEnter={props.enableDrag} 
						onMouseLeave={props.disableDrag} 
					/>
					{!props.hideArrows &&
						<React.Fragment>
							{index > 0 && <IconButton
                                className="arrow"
                                onClick={() => props.moveItem(index, index - 1)}
                                size="large"><ArrowUpwardIcon /></IconButton>}
							{index < props.numItems - 1 && <IconButton
                                className="arrow"
                                onClick={() => props.moveItem(index, index + 1)}
                                size="large"><ArrowDownwardIcon /></IconButton>}
						</React.Fragment>
					}
				</td>
			}
			{props.renderAsCells ? 
				props.children
			:
				<td className="drag-wrapper" style={props.cellStyle}>{props.children}</td>
			}
			{!props.dragOnly &&
				<td className="auto-width" style={props.cellStyle}>
					<IconButton onClick={() => props.removeItem(index)} size="large"><RemoveCircleIcon /></IconButton>
					{!props.simple && props.showAdd && <IconButton onClick={() => props.addItem(index + 1)} size="large"><AddCircleIcon /></IconButton>}
				</td>
			}
		</tr>
	));
});

DragItem.props = {
	index: PropTypes.number.isRequired,
	numItems: PropTypes.number.isRequired,
	addItem: PropTypes.func.isRequired,
	removeItem: PropTypes.func.isRequired,
	moveItem: PropTypes.func.isRequired,
	enableDrag: PropTypes.func.isRequired,
	disableDrag: PropTypes.func.isRequired,
	canDrag: PropTypes.bool.isRequired,
	hideArrows: PropTypes.bool.isRequired,
	simple: PropTypes.bool.isRequired,
	dragOnly: PropTypes.bool.isRequired,
	cellStyle: PropTypes.object.isRequired,
	showAdd: PropTypes.bool.isRequired
};

const connectTarget = connect => ({
	connectDropTarget: connect.dropTarget()
});
  
const connectSource = (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
});

/* eslint-disable new-cap */
export default DropTarget("dragItem", itemTarget, connectTarget)(
	DragSource("dragItem", itemSource, connectSource)(DragItem)
);