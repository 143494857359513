import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrganisation, saveOrganisation, deleteOrganisation, isLoading } from '../../../store/organisations';
import { showModal } from '../../../store/modal';
import PageLayout from '../../PageLayout';
import EditOrganisation from './EditOrganisation';

const EditOrganisationController = props => {
	const organisationId = Number(props.match.params.organisationId);

	React.useEffect(() => props.getOrganisation(organisationId), [organisationId]);

	return <PageLayout title="Edit organisation" pageContent={
		<EditOrganisation
			organisationId={organisationId}
			canEditTenant={props.isGlobalAdministrator}
			organisation={props.organisation}
			saveOrganisation={props.saveOrganisation}
			deleteOrganisation={props.deleteOrganisation}
			loading={props.loading}
			secondaryColour={props.secondaryColour}
		/>
	} breadcrumbs={[{ label: "Organisations", path: "/admin/organisations" }, "Edit organisation"]} />;
};

EditOrganisationController.propTypes = {
	isGlobalAdministrator: PropTypes.bool.isRequired,
	organisation: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	getOrganisation: PropTypes.func.isRequired,
	saveOrganisation: PropTypes.func.isRequired,
	deleteOrganisation: PropTypes.func.isRequired,
	secondaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	organisation: state.organisations.organisation,
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	loading: isLoading(state),
	secondaryColour: state.theme.secondaryColour
});

const mapDispatchToProps = dispatch => ({
	getOrganisation: organisationId => dispatch(getOrganisation(organisationId)),
	saveOrganisation: (organisation, logoFile, accreditedLogoFile, userTour) => dispatch(saveOrganisation(organisation, logoFile, accreditedLogoFile, userTour)),
	deleteOrganisation: organisationId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this organisation?",
		onOk: () => dispatch(deleteOrganisation(organisationId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganisationController);
