/* eslint react/jsx-pascal-case: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCLO_Course, isLoading } from '../../store/cloCourses';
import { canEditCLO_Course } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewCLO_Course from './ViewCLO_Course';

const ViewCLO_CourseController = props => {
	React.useEffect(() => props.getCLO_Course(props.match.params.courseId), [props.match.params.courseId]);

	return <PageLayout title="View course" pageContent={
		<ViewCLO_Course course={props.course} loading={props.loading} canEdit={props.canEdit(props.course)} fromAdmin={props.fromAdmin} />
	} breadcrumbs={[{ label: "Courses", path: "/clo-courses" }, "View course"]} />;
};

ViewCLO_CourseController.propTypes = {
	course: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.func.isRequired,
	fromAdmin: PropTypes.bool
};

ViewCLO_CourseController.defaultProps = {
	fromAdmin: false
};

const mapStateToProps = state => ({
	course: state.cloCourses.cloCourse,
	loading: isLoading(state),
	canEdit: canEditCLO_Course(state)
});

const mapDispatchToProps = dispatch => ({
	getCLO_Course: courseId => dispatch(getCLO_Course(courseId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCLO_CourseController);