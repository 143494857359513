import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import { Grid, Tooltip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import FavouriteButton from './FavouriteButton';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	toolCard: {
		'& .card-body': {
			'& .label': {
				backgroundColor: theme.palette.primary.main
			},
			'& .academic': {
				'& svg': {
					color: theme.palette.secondary.main
				}
			}
		}
	}
});

const ToolCard = ({ tool, isCommunity, classes }) => 
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<Card style={{ margin: 0 }} className={`tool-card ${classes.toolCard}`}>
			<div className="header">
				<Link className="h3" to={`/${isCommunity ? "my-community/" : ""}tools/${tool.toolId}`} style={{ margin: 0 }}>{tool.name}</Link>
				<FavouriteButton toolId={tool.toolId} isFavourite={tool.isFavourite} />
			</div>
			{tool.academicId &&
				<span className="h6 academic">
					<Tooltip title="Academic Author"><VerifiedUserIcon /></Tooltip>
					{tool.academicName}
				</span>
			}
			{tool.channel && <span className="label">{tool.channel}</span>}
			<div className="description">{tool.description}</div>
			<div className="tags">
				{map(tool.tags, (t, i) => <Tag key={i} label={t.name} />)}
			</div>
		</Card>
	</Grid>;

ToolCard.propTypes = { 
	tool: PropTypes.shape({
		toolId: PropTypes.number,
		organisationId: PropTypes.number,
		organisationName: PropTypes.string,
		country: PropTypes.string,
		organisationType: PropTypes.string,
		organisationLogo: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		method: PropTypes.string,
		references: PropTypes.string,
		visibility: PropTypes.string,
		status: PropTypes.string,
		videos: PropTypes.array,
		links: PropTypes.array,
		channelId: PropTypes.number,
		channel: PropTypes.string,
		tags: PropTypes.array,
		isFavourite: PropTypes.bool
	}).isRequired,
	isCommunity: PropTypes.bool
};

ToolCard.defaultProps = {
	isCommunity: false
};

export default withStyles(ToolCard, styles);