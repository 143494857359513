import { createFetchAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification } from './notifications';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	badges: [],
	badge: {}
};

const CLEAR_BADGES = "CLEAR_BADGES";
const GET_BADGES_REQUEST = "GET_BADGES_REQUEST";
const GET_BADGES_SUCCESS = "GET_BADGES_SUCCESS";
const GET_BADGES_FAILURE = "GET_BADGES_FAILURE";
const GET_BADGE_REQUEST = "GET_BADGE_REQUEST";
const GET_BADGE_SUCCESS = "GET_BADGE_SUCCESS";
const GET_BADGE_FAILURE = "GET_BADGE_FAILURE";

export const isLoading = createLoadingSelector(["GET_BADGES", "GET_BADGE"]);

export const getBadges = () =>
	createFetchAction({
		url: "/api/badges",
		startAction: GET_BADGES_REQUEST,
		onError: error => [getBadgesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getBadgesSuccess(data)
	});

export const getBadgesSuccess = data => ({ type: GET_BADGES_SUCCESS, payload: { data } });
export const getBadgesFailure = error => ({ type: GET_BADGES_FAILURE, payload: { error } });

export const getUserBadges = () => 
	createFetchAction({
		url: "/api/badges/user",
		startAction: GET_BADGES_REQUEST,
		onError: error => [getBadgesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getBadgesSuccess(data)
	});

export const getBadge = badgeId =>
	createFetchAction({
		url: `/api/badges/${badgeId}`,
		startAction: GET_BADGE_REQUEST,
		onError: error => [getBadgeFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getBadgeSuccess(data)
	});

export const getBadgeSuccess = data => ({ type: GET_BADGE_SUCCESS, payload: { data } });
export const getBadgeFailure = error => ({ type: GET_BADGE_FAILURE, payload: { error } });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_BADGES:
			return { ...state, badges: [] };
		case GET_BADGES_REQUEST:
			return { ...state, badges: [], isLoading: true };
		case GET_BADGES_SUCCESS:
			return { ...state, badges: action.payload.data, isLoading: false };
		case GET_BADGE_REQUEST:
			return { ...state, badge: {}, isLoading: true };
		case GET_BADGE_SUCCESS:
			return { ...state, badge: action.payload.data, isLoading: true };
		default:
			return state;
	}
};