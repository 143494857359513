import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInsight, saveInsight, deleteInsight, isLoading } from '../../store/insights';
import { canEditInsight, canEditCommunityInsight, canViewExperts } from '../../store/security';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditInsight from './EditInsight';

const EditInsightController = props => {
	const insightId = Number(props.match.params.insightId);

	React.useEffect(() => props.getInsight(insightId), [insightId]);

	React.useEffect(() => {
		if (props.insight.insightId === insightId && !props.canEdit) props.revokeAccess();
	}, [props.insight.insightId]);

	return <PageLayout title="Edit Insight" pageContent={
		<EditInsight
			userId={props.userId}
			insight={props.insight}
			saveInsight={props.saveInsight}
			deleteInsight={props.deleteInsight}
			loading={props.loading}
			canViewExperts={props.canViewExperts}
			primaryColour={props.primaryColour}
		/>
	} breadcrumbs={[{ label: "Research Insights", path: `/${props.isCommunity ? "my-community/" : ""}insights` }, "Edit Insight"]} />;
};

EditInsightController.propTypes = {
	userId: PropTypes.string.isRequired,
	insight: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveInsight: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canViewExperts: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired
};

EditInsightController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	userId: state.context.userId,
	insight: state.insights.insight,
	canEdit: ownProps.isCommunity ? canEditCommunityInsight(state) : canEditInsight(state),
	loading: isLoading(state),
	canViewExperts: canViewExperts(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getInsight: insightId => dispatch(getInsight(insightId)),
	saveInsight: (insight, imageFile, newFiles) => dispatch(saveInsight(insight, imageFile, newFiles, ownProps.isCommunity)),
	deleteInsight: insightId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this insight?",
		onOk: () => dispatch(deleteInsight(insightId, ownProps.isCommunity))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInsightController);