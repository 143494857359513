import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Card from '../widgets/Card';
import Link from 'react-router-dom/Link';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import RowIcons from '../widgets/RowIcons';

const LDCourseCard = React.memo(({ ldCourse, copyCourse, deleteCourse, canEdit }) =>
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<Card className="ld-course-card">
			<Link to={`/course-design/${ldCourse.lD_CourseId}`} className="h3 name">
				<LibraryBooks />
				{ldCourse.title}
			</Link>
			<div className="body quill-display-block" dangerouslySetInnerHTML={{ __html: ldCourse.description }} />
			{canEdit &&
				<div style={{ marginTop: 10 }}>
					<RowIcons
						type="course"
						editLink={`/course-design/edit/${ldCourse.lD_CourseId}`} 
						deleteFunc={() => deleteCourse(ldCourse.lD_CourseId)}
						copyFunc={() => copyCourse(ldCourse.lD_CourseId)}
					/>
				</div>
			}			
		</Card>
	</Grid>
);

LDCourseCard.propTypes = {
	ldCourse: PropTypes.object.isRequired,
	deleteCourse: PropTypes.func.isRequired,
	copyCourse: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired
};

export default LDCourseCard;
