export const channelImages = {
	"Business Management": "/images/insight-banners-lq/Business Management.jpg",
	"Communications": "/images/insight-banners-lq/Communications.jpg",
	"Computer Sciences": "/images/insight-banners-lq/Computer Sciences.jpg",
	"Digital Capability": "/images/insight-banners-lq/Digital Capability.jpg",
	"Engineering": "/images/insight-banners-lq/Engineering.jpg",
	"Finance": "/images/insight-banners-lq/Finance.jpg",
	"Financial Management": "/images/insight-banners-lq/Finance.jpg",
	"Healthcare": "/images/insight-banners-lq/Healthcare.jpg",
	"Hospitality Management": "/images/insight-banners-lq/Human Resource Management.jpg",
	"Human Resource Management": "/images/insight-banners-lq/Human Resource Management.jpg",
	"Industrial Relations": "/images/insight-banners-lq/Information Management.jpg",
	"Information Systems": "/images/insight-banners-lq/Information Management.jpg",
	"Information Management": "/images/insight-banners-lq/Information Management.jpg",
	"Innovation": "/images/insight-banners-lq/Innovation Management.jpg",
	"Innovation Management": "/images/insight-banners-lq/Innovation Management.jpg",
	"International Business": "/images/insight-banners-lq/International Business.jpg",
	"Law": "/images/insight-banners-lq/International Business.jpg",
	"Leadership": "/images/insight-banners-lq/Leadership.jpg",
	"Learning & Development": "/images/insight-banners-lq/Learning & Development.jpg",
	"Manufacturing": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Manufacturing Engineering & Technology": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Organisational Growth & Resilience": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Organisation Management": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Personal Management Training": "/images/insight-banners-lq/Program & Project Management.jpg",
	"Program & Project Management": "/images/insight-banners-lq/Program & Project Management.jpg",
	"Procurement & Contract Management": "/images/insight-banners-lq/Program & Project Management.jpg",
	"Public and Health Care Administration": "/images/insight-banners-lq/Healthcare.jpg",
	"Quality Management": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Robotics": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Supply Chain & Logistics": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Systems Thinking": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Strategy": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Transformation": "/images/insight-banners-lq/Transformation.jpg"
};
