import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormHelperText } from '@mui/material';
import IconUploadButton from './IconUploadButton';
import VideoGallery from './VideoGallery';
import uniqueId from 'lodash/uniqueId';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

const VideoUploader = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("video-uploader-"));
	const { value, videos: tempVideos, single } = props,
		disabled = single ? Boolean(value) : value.length >= props.maxUploads;

	let videos = [];
	if (!isEmpty(value)) {
		if (single) {
			if (tempVideos) {
				videos = [{ src: tempVideos || value || "" }];
			} else {
				videos = [{ src: value || "" }];
			}
		} else {
			videos = map(value, video => {
				let src = tempVideos[video.name] || video[props.srcProperty] || "";
				return { src: src };
			});
		}
	}
		
	// Store videos in temp structure
	const upload = files => forEach(files, f => {
		props.addVideo(f);
	});

	return (
		<div id={id} style={props.style}>
			<span className="h3">
				{props.label}{props.required && <span style={{ color: props.errorColour }}> *</span>}
				<IconUploadButton onChange={upload} aria-label="Add video" accept={[".mp4"]} disabled={disabled} multiple={!single} />
			</span>
			<VideoGallery 
				videos={videos} 
				remove={props.removeVideo} 
				placeholder={props.placeholder} 
				hidePlaceholder={Boolean(props.single)} 
			/>
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
		</div>
	);
});

VideoUploader.propTypes = {
	id: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	label: PropTypes.string,
	style: PropTypes.object,
	helpText: PropTypes.node,
	maxUploads: PropTypes.number,
	addVideo: PropTypes.func.isRequired,
	removeVideo: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	single: PropTypes.bool,
	srcProperty: PropTypes.string,
	required: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

VideoUploader.defaultProps = {
	id: "",
	value: [],
	label: "Videos",
	style: {},
	helpText: "",
	maxUploads: 10,
	placeholder: undefined,
	single: false,
	required: false,
	srcProperty: 'location'
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoUploader);
