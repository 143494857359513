import { createLoadingSelector } from './loading';

const initialState = {
	showChat: false,
    toUserId: null
};

const TOGGLE_CHAT = "TOGGLE_CHAT";
const SET_TO_USER = "SET_TO_USER";
const USER_LOGGED_OUT = "USER_LOGGED_OUT"; // from context.js

export const isLoading = createLoadingSelector(["GET_SESSIONS"]);

export const toggleChat = show => ({ type: TOGGLE_CHAT, show });
export const setToUser = userId => ({ type: SET_TO_USER, userId });

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_CHAT:
			return { 
                ...state, 
                toUserId: action.show ? state.toUserId : null,
                showChat: action.show 
            };
        case SET_TO_USER:
            return { ...state, toUserId: action.userId };
        case USER_LOGGED_OUT:
            return { ...state, showChat: false };
		default:
			return state;
	}
};
