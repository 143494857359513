import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import { CircularProgress, Tooltip, Grow, Grid } from '@mui/material';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import ButtonLink from '../widgets/ButtonLink';
import Tag from '../widgets/Tag';
import TextField from '../widgets/TextField';
import { prettifyDate } from '../../utils/utils';
import distanceInWords from 'date-fns/formatDistance';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import Form from '../widgets/Form';
import { Link } from 'react-router-dom';

const form = {
	initValues: props => ({ message: `<p><strong>RE: ${props.offer.title}</strong></p><p>...</p>` }),
	fields: [
		{
			name: "subject",
			required: true,
			label: "Subject"
		},
		{
			name: "message",
			required: true,
			label: "Message",
			type: "textEditor"
		}
	]
};

const today = new Date();

const downloadAttachment = ({ offerId, attachmentId }) =>
	window.open(`/Offer/DownloadAttachment?offerId=${offerId}&attachmentId=${attachmentId}`, "_self");
const downloadAdditionalChallengeDetails = ({ offerId, offerAdditionalChallengeDetailsId }) =>
	window.open(`/Offer/DownloadAdditionalChallengeDetails?offerId=${offerId}&additionalChallengeDetailsId=${offerAdditionalChallengeDetailsId}`, "_self");
const downloadNonDisclosureAgreement = ({ offerId, offerNonDisclosureAgreementId }) =>
	window.open(`/Offer/DownloadNonDisclosureAgreement?offerId=${offerId}&nonDisclosureAgreementId=${offerNonDisclosureAgreementId}`, "_self");

const ViewOffer = props => {
	const { offer, preview, showEnquiry, fields, values } = props,
		description = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: offer.description }}></div>,
		requirements = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: offer.requirements }}></div>,
		expiryDistance = offer.expiryDate ? distanceInWords(today, new Date(offer.expiryDate)) : "";

	return props.loading || isEmpty(offer) ? <CircularProgress size={24} style={{ marginLeft: 10 }} /> : (
		<div className="offer-container">
			<h2>{offer.title}</h2>
			<div className="offer-details" style={{ marginBottom: !preview && 30 }}>
				<div className="user-details">
					<div className="details-box">
						<span className="owner-name">{offer.ownerName}</span>
						<span className="owner-name">{offer.organisationName}</span>
						<h5 style={{ marginBottom: 2 }}>
							<Tooltip title={prettifyDate(offer.expiryDate)} placement="right">
								<span className="badge badge-danger">
									{offer.status === "closed" ? `Closed ${expiryDistance} ago` : `Closes in ${expiryDistance}`}
								</span>
							</Tooltip>
						</h5>
						<small className="text-muted">Published {prettifyDate(offer.datePublished)}</small>
						{offer.lastUpdate && <small className="text-muted">Updated {prettifyDate(offer.lastUpdate)}</small>}
					</div>
					{!preview && offer.status !== "closed" &&
						<div className="registration-box">
							{(offer.applicationStatus && offer.applicationStatus !== "Draft") ? <Button disabled>You have already applied</Button> : 
								<ButtonLink to={`/research-match/${offer.offerId}/apply`}>
									{offer.applicationStatus === "Draft" ? "Resume application" : "Apply Now"}
								</ButtonLink>
							}
							<br />
							{offer.numApplications > 0 &&
								<span className="label label-rounded label-light-info" style={{ marginTop: 5 }}>
									{!props.isAdmin ? `${offer.numApplications} Applications` :
										<Tooltip title="View Applications" placement="bottom">
											<Link to={`/research-match/applications?offerId=${offer.offerId}`}>
												{offer.numApplications} Applications
											</Link> 
										</Tooltip>
									}
								</span>
							}
						</div>
					}
				</div>
				<div style={{ marginBottom: 5 }}>
					{map(offer.tags, (tag, i) => <Tag key={i} label={tag.name} />)}
				</div>
				<div style={{ marginBottom: 20 }}>
					<span className={`label label-light-${offer.status === "open" ? "success" : "danger"}`}>{offer.status}</span>
					<span className="label label-rounded label-light-info" style={{ marginLeft: 5 }}>{offer.views} views</span>
				</div>
				{preview ?
					<React.Fragment>
						<h5>Description</h5>
						{description}
						<h5 style={{ marginTop: 20 }}>Requirements</h5>
						{requirements}
					</React.Fragment>
				:
					<React.Fragment>
						<Card header="Description">{description}</Card>
						<Card header="Requirements">{requirements}</Card>
					</React.Fragment>
				}
				{offer.attachments && offer.attachments.length > 0 &&
					<React.Fragment>
						<span className="h4">Attachments</span>
						<div className="row">
							<ul className="list-unstyled">
								{map(offer.attachments, (a, i) =>
									<li className="media" key={i}>
										<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
										<div className="media-body">
											<h5 className="mt-0 mb-1">{a.name}</h5>
											<p>{a.description}</p>
											<Button variant="outlined" onClick={() => downloadAttachment(a)}>Download now</Button>
										</div>
									</li>
								)}
							</ul>
						</div>
					</React.Fragment>
				}
				{offer.additionalChallengeDetails && offer.additionalChallengeDetails.length > 0 &&
					<React.Fragment>
						<span className="h4">Additional Match Details</span>
						<div className="row">
							<ul className="list-unstyled">
								{map(offer.additionalChallengeDetails, (a, i) =>
									<li className="media" key={i}>
										<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
										<div className="media-body">
											<h5 className="mt-0 mb-1">{a.name}</h5>
											<p>{a.description}</p>
											<Button variant="outlined" onClick={() => downloadAdditionalChallengeDetails(a)}>Download now</Button>
										</div>
									</li>
								)}
							</ul>
						</div>
					</React.Fragment>
				}
				{offer.nonDisclosureAgreement && offer.nonDisclosureAgreement.length > 0 &&
					<React.Fragment>
						<span className="h4">Non Disclosure Agreement</span>
						<div className="row">
							<ul className="list-unstyled">
								{map(offer.nonDisclosureAgreement, (a, i) =>
									<li className="media" key={i}>
										<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
										<div className="media-body">
											<h5 className="mt-0 mb-1">{a.name}</h5>
											<p>{a.description}</p>
											<Button variant="outlined" onClick={() => downloadNonDisclosureAgreement(a)}>Download now</Button>
										</div>
									</li>
								)}
							</ul>
						</div>
					</React.Fragment>
				}
				{props.canEdit && <ButtonLink to={`/research-match/edit/${offer.offerId}`} style={{ marginRight: 10 }}>Edit Research Match</ButtonLink>}
				{!preview && offer.status === "open" && 
					<React.Fragment>
						<Button onClick={props.updateShowEnquiry} color="secondary">Submit Enquiry</Button>
						<Grow in={showEnquiry} style={{ transformOrigin: "0 0 0" }} unmountOnExit>
							<Card style={{ marginTop: 20 }}>
								<Form onSubmit={() => props.validateFields() && props.sendEnquiry(values)}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<TextField label="Full Name" value={props.fullName} disabled />
										</Grid>
										<Grid item xs={12}>
											<TextField label="Email" value={props.email} disabled />
										</Grid>
										<Grid item xs={12}>{fields.subject()}</Grid>
										<Grid item xs={12}>{fields.message()}</Grid>
									</Grid>
									<Button type="submit" loading={props.enquiryLoading} style={{ marginTop: 20 }}>
										Submit
									</Button>
								</Form>
							</Card>
						</Grow>
					</React.Fragment>
				}
			</div>
		</div>
	);
};

ViewOffer.propTypes = {
	offer: PropTypes.shape({
		title: PropTypes.string,
		status: PropTypes.string,
		category: PropTypes.string,
		coverImage: PropTypes.string,
		ownerId: PropTypes.string,
		ownerName: PropTypes.string,
		ownerAvatar: PropTypes.string,
		datePublished: PropTypes.string,
		lastUpdate: PropTypes.string,
		expiryDate: PropTypes.string,
		description: PropTypes.string,
		requirements: PropTypes.string,
		views: PropTypes.number,
		tags: PropTypes.array,
		hasApplied: PropTypes.bool
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	isAdmin: PropTypes.bool,
	preview: PropTypes.bool,
	fullName: PropTypes.string,
	email: PropTypes.string,
	showEnquiry: PropTypes.bool,
	updateShowEnquiry: PropTypes.func,
	enquiryLoading: PropTypes.bool,
	sendEnquiry: PropTypes.func
};

ViewOffer.defaultProps = {
	canEdit: false,
	isAdmin: false,
	preview: false,	
	fullName: "",
	email: "",
	showEnquiry: false,
	updateShowEnquiry: () => null,
	enquiryLoading: false,
	sendEnquiry: () => null
}

export default withForm(form)(ViewOffer);
