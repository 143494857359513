import React from 'react';
import PropTypes from 'prop-types';
import TextEditor from '../widgets/TextEditor';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import { CircularProgress } from '@mui/material';

const CreateEnquiry = props => {
	const [enquiryContent, setEnquiryContent] = React.useState("");

	return (
		<Card style={{ marginTop: '10px' }}>
			<span className="h4">
				Submit Enquiry
				{props.loading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
			</span>
			<TextEditor
				value={enquiryContent}
				onChange={content => setEnquiryContent(content)}
				placeholder="" 
				style={{ marginBottom: 20 }}
			/>
			<Button onClick={() => props.saveEnquiry(enquiryContent)} color="secondary" disabled={props.loading}>
				Submit
			</Button>
		</Card>
	);
};

CreateEnquiry.propTypes = {
	saveEnquiry: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

export default CreateEnquiry;