import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewReport from './ViewReport';
import { getQueryParameters } from '../../utils/utils';

const ViewReportController = props => {
	const fromSkills = (getQueryParameters(props.location.search) || {}).fromSkills;

	return (
		<PageLayout title="View report" pageContent={
			<ViewReport report={props.report} userId={props.userId} />
		} breadcrumbs={[
			fromSkills ? { label: "My Skills Passport", path: "/skills" } : { label: "Reports", path: "/reports" }, 
			"View Report"
		]} />
	);
};

ViewReportController.propTypes = {
	report: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired
};

export default connect((state, ownProps) => ({
	report: (state.settings.reports || []).find(r => r.name === ownProps.match.params.reportId) || {},
	userId: state.context.userId
}), null)(ViewReportController);