import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Slide, Tooltip, CircularProgress, Fab } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '../widgets/Card';
import TextField from '../widgets/TextField';
import Avatar from '../widgets/Avatar';
import classNames from 'classnames';
import map from 'lodash/map';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { default as formatDate } from 'date-fns/format';
import isToday from 'date-fns/isToday';

const Chat = props => {
	const [message, setMessage] = React.useState("");
	const discussion = React.useRef(null);
	let previousAuthor = "";

	React.useEffect(() => discussion.current.scrollToBottom(), [props.messages.length]);

	const sendMessage = () => {
		if (message) {
			setMessage("");
			props.saveMessage(message);
		}
	}
	
	const handleKeyDown = e => {
		if (e.keyCode === 13 && !e.shiftKey) {
			e.preventDefault();
			sendMessage();
		}
	}

	const renderMessage = (message, index) => {
		const date = message.createDate ? new Date(message.createDate) : null,
			isAuthor =  message.userId === props.currentUserId,
			wasPrevious = message.userId === previousAuthor;

		previousAuthor = message.userId;

		return (
			<Slide key={index} direction={isAuthor ? "right" : "left"} in timeout={500} mountOnEnter>
				<li className={classNames({ "hide-author": isAuthor || wasPrevious, "new-author": !wasPrevious })}>
					<Avatar src={message.authorAvatar} fullName={message.authorName} length={45} />
					<div className={classNames("content", { "odd": isAuthor })}>
						<span className="h5">{message.authorName}</span>
						<div className="box">{message.message}</div>
						{props.canRemove &&
							<Tooltip title="Delete Message">
								<a className="remove" onClick={() => props.removeMessage(message)}><DeleteIcon /></a>
							</Tooltip>
						}
					</div>
					<span className="time">
						{date ? formatDate(date, `h:mm a${isToday(date) ? "" : " d/M/yyyy"}`) : ""}
					</span>
				</li>
			</Slide>
		);
	}

	return (
		<Card className="chat">
			<div className="message-section">
				{props.title &&
					<h4 style={{ marginBottom: "0.75rem" }}>
						{props.title}
						{props.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
					</h4>
				}
				<Scrollbars autoHeight autoHeightMax={400} renderTrackHorizontal={() => <div style={{ display: "none" }} />} ref={discussion}>
					{props.messages.length === 0 ? <span>{props.emptyText}</span> :
						<ul className="message-list">
							{map(props.messages, (message, index) => renderMessage(message, index))}
						</ul>
					}
				</Scrollbars>
			</div>
			<Divider />
			<div className="message-editor">
				<Grid container alignItems="center" spacing={3}>
					<Grid item xs>
						<TextField 
							value={message} 
							placeholder="Type your message here..." 
							onChange={e => setMessage(e.target.value)}
							onKeyDown={handleKeyDown}
							fullWidth
							multiline
							disabled={props.loading}
							helpText={<span>Start new paragraph with <kbd>SHIFT</kbd> + <kbd>ENTER</kbd></span>}
						/>
					</Grid>
					<Grid item xs="auto" style={{ position: "relative" }}>
						<Fab color="primary" aria-label="send" disabled={props.loading} onClick={sendMessage}>
							<SendIcon />
						</Fab>
						{props.loading && <CircularProgress size={68} color="secondary" style={{ position: "absolute", top: 6, left: 6, zIndex: 1 }} />}
					</Grid>
				</Grid>
			</div>
		</Card>
	);
};

Chat.propTypes = {
	title: PropTypes.string,
	currentUserId: PropTypes.string.isRequired,
	saveMessage: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	messages: PropTypes.arrayOf(PropTypes.shape({
		userId: PropTypes.string,
		authorName: PropTypes.string,
		authorAvatar: PropTypes.string,
		message: PropTypes.string,
		createDate: PropTypes.string
	})).isRequired,
	canRemove: PropTypes.bool.isRequired,
	removeMessage: PropTypes.func.isRequired,
	emptyText: PropTypes.string
};

Chat.defaultProps = {
	title: "",
	emptyText: "There doesn't seem to be anything here..."
};

export default Chat;