import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveUser, isLoading } from '../../store/users';
import PageLayout from '../PageLayout';
import EditUser from './EditUser';

const AddUserController = props =>
	<PageLayout title="Add new user" pageContent={
		<EditUser
			isNew
			canChangeTenant={props.isGlobalAdministrator}
			canChangeRole
			loading={props.loading}
			saveResult={props.saveResult}
			onSave={props.saveUser}
			tenantId={props.tenantId}
			organisationId={props.organisationId}
			organisationType={props.organisationType}
			showMembershipLevelId={props.showMembershipLevelId}
		/>
	} breadcrumbs={[{ label: "Users", path: "/users" }, "Add user"]} />;

AddUserController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	tenantId: PropTypes.number.isRequired,
	organisationType: PropTypes.string.isRequired,
	showMembershipLevelId: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	user: state.users.user,
	saveResult: state.users.saveResult,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	tenantId: state.context.tenantId,
	showMembershipLevelId: state.context.showMembershipLevelId
});

const mapDispatchToProps = dispatch => ({
	saveUser: (user, avatarFile) => dispatch(saveUser(user, avatarFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserController);