import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import EditTeam from './EditTeam';
import { emptySaveResult } from '../../variables';
import { get, post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { changeLocation } from '../../store/location';
import axios from 'axios';
import { canManageTeamsAcrossTenant } from '../../store/security';

const CreateTeamController = props => {
	const [state, setState] = React.useState({ 
        isLoading: false, 
        saveResult: emptySaveResult.fields,
		teams: [],
        users: [],
        skillsProfiles: []
    });

    React.useEffect(() => {
		onOrganisationChanged(props.organisationId);
	}, []);

	const onOrganisationChanged = (organisationId) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		axios.all([
			get({
				url: props.organisationId === organisationId ? `/api/teams/by-organisation` : `/api/teams/by-organisation/${organisationId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, teams: data })),
				onError: error => props.showErrorNotification(error.message)
			}),
			get({
				url: props.organisationId === organisationId ? `/api/users/by-organisation` : `/api/users/by-organisation/${organisationId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, users: data })),
				onError: error => props.showErrorNotification(error.message)
			}),
			get({
				url: `/api/skills-tracker/profiles?organisationId=${organisationId || 0}`,
				onSuccess: data => setState(prevState => ({ ...prevState, skillsProfiles: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	};

	const saveTeam = (team) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		
		post({
			url: "/api/teams",
			data: team,
			onSuccess: data => {
				setState({ saveResult: data, isLoading: false });

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToEditUrl(data.object);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	return <PageLayout title="Create new team" pageContent={
		<EditTeam 
			isNew={true}
			currentUserId={props.currentUserId}
			saveTeam={saveTeam} 
			teams={state.teams}
            users={state.users}
            skillsProfiles={state.skillsProfiles}
			loading={state.isLoading} 
			canChangeOrganisation={props.canChangeOrganisation}
			organisationId={props.organisationId}
			onOrganisationChanged={onOrganisationChanged}
		/>
	} breadcrumbs={[{ label: "Teams", path: "/teams" }, "Create team"]} />;
};	

CreateTeamController.propTypes = {
	currentUserId: PropTypes.string.isRequired,
    goToEditUrl: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	canChangeOrganisation: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	organisationId: state.context.organisationId,
	canChangeOrganisation: canManageTeamsAcrossTenant(state)
});

const mapDispatchToProps = dispatch => ({
	goToEditUrl: team => dispatch(changeLocation(`/teams/${team.teamId}`)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeamController);