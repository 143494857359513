import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Modal from './components/modals/Modal';
import Notifications from './components/notifications/Notifications';
import Routes from './Routes';
import { pSBC } from './utils/utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const App = ({ theme }) => {
	const muiTheme = createTheme(adaptV4Theme({
		palette: {
			custom: {
				colourPalette: theme.colourPalette,
				tag: theme.tagColour,
				debug: theme.debugColour,
				cancel: theme.cancelColour,
				selection: theme.selectionColour
			},
			primary: {
				// light: "#53a3d4",
				light: pSBC(0.2, theme.primaryColour),
				main: theme.primaryColour,
				dark: pSBC(-0.3, theme.primaryColour),
				// dark: "#002354",
				contrastText: theme.primaryColourText
			},
			secondary: {
				// light: "#b7f779",
				light: pSBC(0.2, theme.secondaryColour),
				main: theme.secondaryColour,
				dark: pSBC(-0.3, theme.secondaryColour),
				// dark: "#519113",
				contrastText: theme.secondaryColourText
			},
			error: {
				main: theme.errorColour
			},
			success: {
				main: theme.successColour
			},
			warning: {
				main: theme.warningColour
			},
			info: {
				main: theme.infoColour
			}
		},
		typography: {
			fontFamily: [
				"Poppins", 
				"sans-serif"
			].join(","),
			body2: { // applied to body element
				color: '#555',
				fontWeight: 300,
				fontSize: '.9rem',
				lineHeight: 1.6
			}
		},
		overrides: {
			MuiButton: {
				root: {
					textTransform: "none",
					fontWeight: 300
				}
			},
			MuiInput: {
				input: {
					"&::placeholder": {
						opacity: "0.62"
					},
					height: 'auto'
				},
				underline: {
					"&:before": {
						borderBottom: "1px solid #d9d9d9 !important"
					}
				}  
			},
			MuiInputLabel: {
				root: {
					color: "rgba(0, 0, 0, 1.0)",
					fontSize: "1.0rem"
				},
				shrink: {
					
				}
			},
			MuiTableCell: {
				root: {
					padding: "4px 0px 4px 15px"
				}
			},
			MuiAvatar: {
				root: {
					lineHeight: 1.5
				}
			}
		}
	}));

	return (
		<StyledEngineProvider injectFirst>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<ThemeProvider theme={muiTheme}>
					<CssBaseline />
						<Routes />
					<Modal />
					<Notifications />
				</ThemeProvider>
			</LocalizationProvider>
		</StyledEngineProvider>
    );
};

const mapStateToProps = state => ({
	theme: state.theme
});

export default connect(mapStateToProps, null)(App);
