import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ViewEvent from './ViewEvent';
import { getEvent, isLoading } from '../../store/events';
import { canEditEvent, canEditCommunityEvent } from '../../store/security';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import PageLayout from '../PageLayout';
import isEmpty from 'lodash/isEmpty';

const ViewEventController = props => {
	const [isRegistering, setIsRegistering] = React.useState(false);

	React.useEffect(() => {
		const { eventId } = props.match.params;

		props.getEvent(eventId);
		post({ url: `/api/events/${eventId}/log-view` });
	}, []);

	const downloadAttachment = attachment =>
		window.open(`/Event/DownloadAttachment?eventId=${props.event.eventId}&eventAttachmentId=${attachment.eventAttachmentId}`, '_self');

	const register = () => {
		if (props.event.registrationUrl) {
			window.open(props.event.registrationUrl, "_blank");
		} else {
			setIsRegistering(true);

			post({
				url: `/api/events/${props.event.eventId}/register`,
				onSuccess: result => {
					setIsRegistering(false);
					if (result.success) {
						props.showSuccessNotification(result.message);
					} else {
						props.showErrorNotification(result.message);
					}
				},
				onError: error => {
					setIsRegistering(false);
					props.showErrorNotification(error.message);
				}
			});
		}
	}

	return <PageLayout title="View event" pageContent={
		<ViewEvent 
			event={props.event} 
			downloadAttachment={downloadAttachment}
			register={register}
			isRegistering={isRegistering}
			loading={props.loading || isEmpty(props.event)} 
			canEdit={props.canEdit}
			currentUserId={props.currentUserId}
			isCommunity={props.isCommunity}
			visibilityDescriptions={props.visibilityDescriptions}
		/>
	} breadcrumbs={[{ label: "Events", path: `/${props.isCommunity ? "my-community/" : ""}events` }, "View event"]} />;
};

ViewEventController.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	visibilityDescriptions: PropTypes.object.isRequired
};

ViewEventController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	currentUserId: state.context.userId,
	event: state.events.event,
	loading: isLoading(state),
	canEdit: ownProps.isCommunity ? canEditCommunityEvent(state) : canEditEvent(state),
	visibilityDescriptions: {
		"Internal": state.settings.eventVisibilityInternalDescription || "",
		"External": state.settings.eventVisibilityExternalDescription || "",
		"Internal and External": state.settings.eventVisibilityInternalAndExternalDescription || ""
	}
});

const mapDispatchToProps = dispatch => ({
	getEvent: event => dispatch(getEvent(event)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEventController);