import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import Avatar from '../widgets/Avatar';
import Button from '../widgets/Button';

const imageStyle = {
	maxWidth: "100%",
	display: "block",
	margin: "20px auto"
};

// Remember to update ExpertDialog in MyCommunity
const ViewExpert = props => {
	const { expert } = props;
	
	return props.loading || isEmpty(expert) ? <CircularProgress size={24} /> : (
		<Card className="expert-details">
			<div style={{ display: "flex", alignItems: "center" }}>
				<Avatar src={expert.avatar} fullName={expert.fullName} style={{ marginRight: 5 }} />
				<span className="h2" style={{ margin: 0 }}>{expert.fullName}</span>
			</div>
			{expert.image && <img src={expert.image} alt={expert.fullName} style={imageStyle} />}
			<section>
				{expert.industryName}, {expert.discipline}
				<br />
				{expert.seniority} {expert.yearsExperience} Years
			</section>
			<section>
				<span className="h4">Academic Experience</span>
				{expert.courses.length > 0 &&
					<Grid container spacing={3} style={{ marginBottom: 10 }}>
						{expert.courses.map((c, i) => 
							<Grid key={i} item xs={12} sm={4} md={3}>
								<Card title={c.name} style={{ marginBottom: 0 }}>{c.institution}, Completed {c.year}</Card>
							</Grid>
						)}
					</Grid>
				}
				{expert.academicJobs.length > 0 &&
					<React.Fragment>
						<span className="h6">Jobs</span>
						<ul>
							{expert.academicJobs.map((j, i) => 
								<li key={i}><strong>{j.role}</strong>, {j.employer} ({j.years} Years)</li>
							)}
						</ul>
					</React.Fragment>
				}
			</section>
			<section>
				<span className="h4">Professional Experience</span>
				{expert.professionalQualifications && 
					<div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: expert.professionalQualifications }} />
				}
				{expert.professionalJobs.length > 0 &&
					<section>
						<span className="h6">Jobs</span>
						<ul>
							{expert.professionalJobs.map((j, i) => 
								<li key={i}><strong>{j.role}</strong>, {j.employer} ({j.years} Years)</li>
							)}
						</ul>
					</section>
				} 
			</section>
			<section>
				<span className="h4">Executive Experience</span>
				<div dangerouslySetInnerHTML={{ __html: expert.executiveExperience }} />
			</section>
			{props.canEdit && 
				<ButtonLink to={`/experts/edit/${expert.expertId}`}>
					Edit expert
				</ButtonLink>
			}
            <Button
                onClick={() => { props.showChat(expert.userId) }} 
                color="secondary" 
                style={{ marginLeft: '10px' }}
            >
                Chat with {expert.fullName}
            </Button>
		</Card>
	);
};

ViewExpert.propTypes = {
	expert: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
    showChat: PropTypes.func.isRequired
};

ViewExpert.defaultProps = {
	canEdit: false
};

export default ViewExpert;