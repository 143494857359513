import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveCollaboration, isLoading } from '../../store/collaborations';
import PageLayout from '../PageLayout';
import EditCollaboration from './EditCollaboration';
import { showModal } from '../../store/modal';

const CreateCollaborationController = props => 
	<PageLayout 
		title="Create collaboration" 
		pageContent={
			<EditCollaboration 
				saveCollaboration={props.saveCollaboration} 
				loading={props.loading} 
				isNew 
				showMessage={props.showMessage}
			/>
		} 
		breadcrumbs={[{ label: "Collaborations", path: "/collaborations" }, "Create collaboration"]} 
	/>;

CreateCollaborationController.propTypes = {
	saveCollaboration: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveCollaboration: (collaboration, files) => dispatch(saveCollaboration(collaboration, files)),
	showMessage: (title, message, onOk) => dispatch(showModal("MESSAGE", {
		title: title,
		message: message,
		onOk: onOk
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCollaborationController);