import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import { getThemes, saveThemes, isLoading } from '../../store/themes';
import Button from '../widgets/Button';
import TextField from '../widgets/TextField';
import Card from '../widgets/Card';
import Checkbox from '../widgets/Checkbox';
import Form from '../widgets/Form';

const form = {
	initValues: props => ({ themes: props.themes }),
	fields: [{
		name: "themes",
		label: "Themes",
		type: "dragList",
		itemTemplate: { name: "", hide: false },
		renderItem: (item, index, updateItem) =>
			<Grid key={index} container spacing={3}>
				<Grid item xs={8} sm={10} md={11}>
					<TextField label="Name" value={item.name} onChange={e => updateItem(e.target.value, "name")} />
				</Grid>
				<Grid item xs={4} sm={2} md={1}>
					<Checkbox label="Hide" checked={item.hide} onChange={e => updateItem(e.target.checked, "hide")} />
				</Grid>
			</Grid>,
		simple: true,
		cellStyle: { paddingBottom: 20 }
	}]
};

const ThemesController = props => {
	React.useEffect(props.getThemes, []);
	
	return <PageLayout title="Themes" pageContent={
		<Card>
			<Form onSubmit={() => props.saveThemes(props.values.themes)}>
				{props.fields.themes()}
				<Button type="submit" loading={props.loading} style={{ marginTop: 20 }}>
					Update themes
				</Button>
			</Form>
		</Card>
	} breadcrumbs={["Themes"]} />;
};

ThemesController.propTypes = {
	themes: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	getThemes: PropTypes.func.isRequired,
	saveThemes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	themes: state.themes.themes,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getThemes: () => dispatch(getThemes()),
	saveThemes: themes => dispatch(saveThemes(themes))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ThemesController));