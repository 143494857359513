import React from 'react';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';
import widgets from '../dashboardWidgets/dashboardWidgets';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import DashboardWidget from '../dashboardWidgets/DashboardWidget';
import TeamSelector from './TeamSelector';
import TeamCompetencies from '../dashboardWidgets/TeamCompetencies';
import { 
	Dialog, 
	DialogContent, 
	DialogTitle, 
	DialogActions 
} from '@mui/material';
import Button from '../widgets/Button';

const tileStyles = {
	padding: "60px 0 0 0",
	backgroundColor: "#fff",
	// overflow: "hidden", 
	borderRadius: "4px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)" 
};

const TeamSummary = props => {
	const [showCompetencies, setShowCompetencies] = React.useState(false);
	const [user, setUser] = React.useState("team");
	const dashboardLayout = props.dashboard ? props.dashboard.layout || [] : [];
	const dashboardWidgets = props.dashboard ? props.dashboard.widgets || [] : [];
	const items = [];

	const onUserChange = (user) => {
		setUser(user);
	};

	items.push(
		<div key="team-selector">
			<TeamSelector 
				teamId={props.teamId}
				teams={props.teams}
				onChangeTeam={props.onChangeTeam}
				onShowCompetencies={() => {
					setUser("team");
					setShowCompetencies(true);
				}}
				isTeamManager={props.isTeamManager}
			/>
		</div>
	);

	forEach(dashboardWidgets, w => {
		const baseWidget = find(widgets, w2 => w2.type === w.type);
		items.push(
			<div key={w.id} style={{ ...tileStyles, ...baseWidget.tileStyles }}>
				<DashboardWidget 
					{...baseWidget}
					{...w} 
					teamId={props.teamId}
					onMemberClick={(member) => {
						setUser(member.userId);
						setShowCompetencies(true);
					}}
				/>
			</div>
		);
	});

	return (
		<div className="team-skills-summary">
			<GridLayout 
				className="layout" 
				cols={12} 
				rowHeight={45} 
				width={1280} 
				containerPadding={[0, 0]}
				margin={[30, 30]}
				style={{ marginLeft: "0" }}
				layout={dashboardLayout}
				isDraggable={false}
				isResizable={false}
			>
				{items}
			</GridLayout>
			
			<Dialog open={showCompetencies} onClose={() => setShowCompetencies(false)} maxWidth="xl" fullWidth>
				<DialogTitle>Competencies</DialogTitle>
				<DialogContent>
					<TeamCompetencies 
						teamId={props.teamId}
						user={user}
						onUserChange={onUserChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowCompetencies(false)} autoFocus>Close</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

TeamSummary.propTypes = {
	dashboard: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	teamId: PropTypes.number,
	teams: PropTypes.array,
	isTeamManager: PropTypes.bool
};

TeamSummary.defaultProps = {
	dashboard: null,
	teamId: null,
	teams: [],
	isTeamManager: false
};

export default TeamSummary;
