import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router'
import { 
	getCompetencies, 
	getOrganisationCompetencies,
	saveCompetencies, 
	updateUserAssessment,
	isLoading
} from '../../store/competencies';
import { getSkillsProfile } from '../../store/skillsProfiles';
import { getOrganisation } from '../../store/organisations';
import { showWarningNotification } from '../../store/notifications';
import { getTeamSkillsUsers } from '../../store/users';
import { get, post } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import Competencies from './Competencies';
import DOMPurify from 'dompurify';
import find from 'lodash/find';
import remove from 'lodash/remove';
import some from 'lodash/some';
import produce from 'immer';
import UserTour from '../widgets/UserTour';
import { showModal } from '../../store/modal';
import { revokeAccess } from '../../store/context';

const CompetenciesController = props => {
	const [initComplete, setInitComplete] = React.useState(false);
	const [organisationsLoading, setOrganisationsLoading] = React.useState(false);
	const [competenciesLoading, setCompetenciesLoading] = React.useState(false);
	const [usersLoading, setUsersLoading] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [state, setState] = React.useState({
		skillsProfileId: 0,
		competencies: [],
		skills: { programs: "" },
		newFiles: [],
		promptSelection: false,
		dirty: false,
		profileUnits: [],
		user: props.userId,
		showProfilePicker: false,
		triggerSave: false,
		reviewStatus: "In-Progress",
        teamLeaderCanEdit: true
	});

	const { autoExpandCompetencies = true, showNotCompetent = false  } = props.organisation;
	let graphicalSkillsView = props.organisation ? props.organisation.graphicalSkillsView : true;
	if (props.organisation.initialAssessmentSkillsView === 'Textual' && !props.userAssessmentComplete) {
		// initialAssessmentSkillsView can override when user assessment not complete
		graphicalSkillsView = false;
	}
	
	let title = "";
	let printReport = "";
	switch (props.mode) {
		case 'all':
			title = "My Skills";
			break;
		case 'team':
			title = "Team Skills";
			break;
		case 'primary':
			title = "My Skills";
			printReport = props.mySkillsButton1Report;
			break;
		case 'secondary':
			title = `My Skills ${props.acSingle} Profile`;
			printReport = props.mySkillsButton2Report;
			break;
		default:
	}

	React.useEffect(() => {
		setLoading(true);
		setOrganisationsLoading(true);
		setUsersLoading(true );
		setCompetenciesLoading(true);

		props.getOrganisation(
			props.organisationId, 
			() => setOrganisationsLoading(false),
			() => setOrganisationsLoading(false)
		);

		props.getCompetencies(
			props.userId, 
			(data) => {
				setCompetenciesLoading(false);
				loadCompetencies(data);
			},
			() => setCompetenciesLoading(false)
		);

		if (props.mode === "team") {
			props.getTeamSkillsUsers(
				() => setUsersLoading(false),
				() => setUsersLoading(false)
			);
		} else {
			setUsersLoading(false);
		}

		// Revoke access if user does not have access to secondary profile
		if (props.mode === "secondary" && props.disableSecondaryProfile) props.revokeAccess();
	}, []);

	React.useEffect(() => {
		if (!organisationsLoading && !competenciesLoading && !usersLoading) {
			setInitComplete(true);
		}
	}, [organisationsLoading, competenciesLoading, usersLoading]);

	// Fetch filter units for profile whenever updated
	React.useEffect(() => {
		if (initComplete) {
			if (state.skillsProfileId) {
				setLoading(true);
				get({
					url: `/api/skills-tracker/profiles/${state.skillsProfileId}`,
					onSuccess: ({ units }) => {
						let promptSelection = (props.completeUserAssessment && !props.userAssessmentComplete) && !props.lockCompetencyUpdates && props.mode !== "team";
						setLoading(false);
						setState({ ...state, profileUnits: units, promptSelection });
					},
					onError: () => { setLoading(false) }
				});
			} else {
				setLoading(false);
			}
		}
	}, [state.skillsProfileId, initComplete]);

	const loadCompetencies = userCompetencies => {
		let { skillsProfileId, competencies, skills, reviewStatus } = userCompetencies;
		const cleanSkills = { ...skills, programs: skills.programs ? DOMPurify.sanitize(skills.programs) : "" };
		let showProfilePicker = false;
		if (props.mode === "all" && !skillsProfileId) skillsProfileId = props.initialAssessmentProfileId;
		if (!skillsProfileId) showProfilePicker = true;
		setState(prevState => ({ ...prevState, skillsProfileId, skills: cleanSkills, competencies, dirty: false, showProfilePicker, reviewStatus }));

		if (props.mode === "secondary" && !skillsProfileId) {
			props.showWarning("Please select a skills profile to get started.");
		}
	};

	const checkDirty = draft => {
		if (!draft.dirty) props.showWarning("You have unsaved changes.");
		draft.dirty = true;
	};

	// Generic method to help set state properties
	// Replicated checkDirty to avoid altering state directly
	const updateField = name => value => {
		if (!state.dirty) props.showWarning("You have unsaved changes.");
		setState({ ...state, [name]: value, dirty: true });
	}

	const addUnits = units => setState(produce(draft => {
		units.forEach(u => {
			const competency = find(draft.competencies, c => c.unitOfCompetencyId === u.unitOfCompetencyId);
		
			if (!competency) {
				draft.competencies.push(u);
			} else {
				competency.elements = [...u.elements];
			}
		});

		checkDirty(draft);
		draft.promptSelection = false;
	}, state));

	const addUnitsAndSave = units => 
		setState(
			produce(draft => {
				remove(draft.competencies, c => !some(units, u => u.unitOfCompetencyId === c.unitOfCompetencyId));
				units.forEach(u => {
					const competency = find(draft.competencies, c => c.unitOfCompetencyId === u.unitOfCompetencyId);
				
					if (!competency) {
						draft.competencies.push(u);
					} else {
						competency.elements = [...u.elements];
					}
				});

				checkDirty(draft);
				draft.promptSelection = false;
				draft.triggerSave = true;
			}, state)
		);

	React.useEffect(() => {
		if (state.triggerSave) {
			setState(
				produce(draft => {
					draft.triggerSave = false;
				}, state)
			);
			saveCompetencies();
		}
	}, [state.triggerSave]);
	
	const updateSkillsProfile = skillsProfileId => 
		post({
			url: `/api/competencies/${state.user}/calculate-competencies`,
			data: { skillsProfileId, competencies: state.competencies },
			onSuccess: data => {
				setState(produce(draft => {
					draft.skillsProfileId = skillsProfileId;

					data.forEach(c => {
						const c2 = find(draft.competencies, c3 => c3.competencyId === c.competencyId);
						c2.competent = c.competent;
					});

					checkDirty(draft);
				}, state));
			},
			onError: error => props.showWarning(error.message)
		});
	
	const deleteCompetency = (unitId, elementId) => setState(produce(draft => {
		const competency = find(draft.competencies, c => c.unitOfCompetencyId === unitId);

		if (elementId) remove(competency.elements, e => e.competencyElementId === elementId);
		if (!elementId || competency.elements.length === 0) remove(draft.competencies, competency);
		
		checkDirty(draft);
	}, state));

	const updateCompetency = (unitId, elementId, field, data) => setState(produce(draft => {
		const competency = find(draft.competencies, c => c.unitOfCompetencyId === unitId),
			target = elementId ? find(competency.elements, e => e.elementId === elementId) : competency;

		switch (field) {
			case "Competent":
				target.competent = data;
				break;
			case "Reviewed":
				target.reviewed = data;
				break;
			case "AssessmentDate":
				target.assessmentDate = data;
				break;
			case "ExpiryDate":
				target.expiryDate = data;
				break;
			case "Evidence":
				target.evidence = { ...data.evidence };
				draft.newFiles = [...draft.newFiles, ...data.newFiles];
				break;
			case "Elements":
				competency.competent = some(data, e => e.competent);
				competency.elements = [...data];
				break;
			default:
		}

		checkDirty(draft);
	}, state));

	const handleUserChange = userId => {
		if (userId === "team") {
			setState({ ...state, user: userId, teamLeaderCanEdit: false });
			props.getOrganisationCompetencies((data) => {
				setState(prevState => ({ 
					...prevState, 
					skillsProfileId: null,
					skills: {
						programs: ""
					}, 
					competencies: data, 
					dirty: false, 
					showProfilePicker: false, 
					reviewStatus: "In-Progress"
				}));
			});
		} else {
			const user = find(props.users, u => u.userId === userId);
			setState({ ...state, user: userId, teamLeaderCanEdit: user.teamLeaderCanEdit });
			props.getCompetencies(userId, loadCompetencies);
		}
	};

	const saveCompetencies = () => {
		props.saveCompetencies(props.mode, state.user, state.competencies, state.skillsProfileId, state.skills, state.newFiles, state.reviewStatus, (data) => {
			loadCompetencies(data);
			if (props.completeUserAssessment && !props.userAssessmentComplete) {
				props.showYesNoDialog(
					"Is Initial Assessment Complete?",
					<React.Fragment>
						<p>If ‘No’ you be returned to this assessment page to continue your assessment on your next visit</p>
						<p>If ‘Yes’ you can continue and access your Skills Passport through the Skills menu at top of page</p>
					</React.Fragment>,
					() => {
						props.updateUserAssessment(true);
					},
					() => {}
				)
			}
		});
	};

	let breadcrumbs = [];
	switch (props.mode) {
		case 'all':
			breadcrumbs = [];
			break;
		case 'team':
			breadcrumbs = [title];
			break;
		default:
			breadcrumbs = [{ label: "My Skills Passport", path: "/skills" }, title];
	}
	
	return (
		<PageLayout 
			title={title} 
			loading={loading || props.isLoading}
			pageContent={
				<React.Fragment>
					<Prompt when={state.dirty} message="You have unsaved changes. Are you sure you want to leave?" />	
					<Competencies 
						competencies={state.competencies} 
						skillsProfileId={state.skillsProfileId}
						profileUnits={state.profileUnits}
						addUnits={addUnits}
						addUnitsAndSave={addUnitsAndSave}
						updateSkillsProfile={updateSkillsProfile}
						deleteCompetency={deleteCompetency}
						updateCompetency={updateCompetency}
						saveCompetencies={saveCompetencies}
						skills={state.skills}
						updateSkills={updateField("skills")}
						reviewStatus={state.reviewStatus}
						updateReviewStatus={updateField("reviewStatus")}
						loading={loading || props.isLoading}
						promptSelection={state.promptSelection}
						resetPromptSelection={() => setState({ ...state, promptSelection: false })}
						dirty={state.dirty}
						mode={props.mode}
						printReport={printReport}
						lockCompetencyUpdates={props.lockCompetencyUpdates || !state.teamLeaderCanEdit}
						autoExpandCompetencies={autoExpandCompetencies}
						users={props.users}
						user={state.user}
						onUserChange={handleUserChange}
						showNotCompetent={showNotCompetent}
						showProfilePicker={state.showProfilePicker}
						onProfilePickerClose={() => setState({ ...state, showProfilePicker: false })}
						profileGroupId={props.profileGroupId}
						showReviewStatus={props.showReviewStatus && props.mode === "team"}
						graphicalSkillsView={graphicalSkillsView}
					/>
					<UserTour />
				</React.Fragment>
			} 
			breadcrumbs={breadcrumbs} 
		/>
	);
};

CompetenciesController.propTypes = { 
	organisationId: PropTypes.number.isRequired,
	organisation: PropTypes.object.isRequired,
	departmentId: PropTypes.number.isRequired,
	getTeamSkillsUsers: PropTypes.func.isRequired,
	lockCompetencyUpdates: PropTypes.bool,
	acSingle: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	users: PropTypes.array.isRequired,
	showWarning: PropTypes.func.isRequired,
	mode: PropTypes.string,
	profileGroupId: PropTypes.number,
	completeUserAssessment: PropTypes.bool.isRequired,
	userAssessmentComplete: PropTypes.bool.isRequired,
	organisationDefaultSkillsProfileId: PropTypes.number,
	initialAssessmentProfileId: PropTypes.number,
	mySkillsButton1Report: PropTypes.string,
	mySkillsButton2Report: PropTypes.string,
	disableSecondaryProfile: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	showReviewStatus: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

CompetenciesController.defaultProps = {
	profileGroupId: 0,
	lockCompetencyUpdates: false,
	mode: "all",
	organisationDefaultSkillsProfileId: 0,
	initialAssessmentProfileId: 0,
	mySkillsButton1Report: '',
	mySkillsButton2Report: ''
};

const mapStateToProps = (state, ownProps) => ({
	organisationId: state.context.organisationId,
	organisation: state.organisations.organisation,
	departmentId: state.context.departmentId,
	lockCompetencyUpdates: state.context.lockCompetencyUpdates,
	userId: state.context.userId,
	users: state.users.users,
	acSingle: state.settings.acSingle,
	profileGroupId: state.organisations.organisation[`${ownProps.mode === "secondary" ? "secondary" : "primary"}SkillsProfileGroupId`],
	completeUserAssessment: state.competencies.completeUserAssessment,
	userAssessmentComplete: state.competencies.userAssessmentComplete,
	organisationDefaultSkillsProfileId: state.context.organisationDefaultSkillsProfileId,
	initialAssessmentProfileId: state.context.initialAssessmentProfileId,
	mySkillsButton1Report: state.settings.mySkillsButton1Report,
	mySkillsButton2Report: state.settings.mySkillsButton2Report,
	disableSecondaryProfile: state.context.disableSecondaryProfile,
	showReviewStatus: state.context.showReviewStatus,
	isLoading: isLoading(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getOrganisation: (organisationId, onSuccess, onError) => dispatch(getOrganisation(organisationId, onSuccess, onError)),
	getTeamSkillsUsers: (onSuccess, onError) => dispatch(getTeamSkillsUsers(onSuccess, onError)),
	getCompetencies: (userId, onSuccess, onError) => dispatch(getCompetencies(userId, ownProps.mode === "secondary", onSuccess, onError)),
	getOrganisationCompetencies: (onSuccess, onError) => dispatch(getOrganisationCompetencies(onSuccess, onError)),
	getSkillsProfile: (skillsProfileId, onSuccess, onError) => dispatch(getSkillsProfile(skillsProfileId, onSuccess, onError)),
	saveCompetencies: (mode, userId, competencies, skillsProfileId, skills, newFiles, reviewStatus, onSuccess) => 
		dispatch(saveCompetencies(mode, userId, competencies, skillsProfileId, skills, newFiles, reviewStatus, ownProps.mode === "secondary", onSuccess)),
	updateUserAssessment: (assessmentComplete) => dispatch(updateUserAssessment(assessmentComplete)),
	showWarning: message => dispatch(showWarningNotification(message)),
	showYesNoDialog: (title, message, onOk, onCancel) => dispatch(showModal("SIMPLE", { title, message, onOk, onCancel })),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetenciesController);