import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { prettifyDate, moveItem } from '../../utils/utils';
import ActivityPicker from '../widgets/ActivityPicker';
import LDResourcePicker from '../widgets/LDResourcePicker';
import ButtonLink from '../widgets/ButtonLink';
import ToolPicker from '../widgets/ToolPicker';
import TextField from '../widgets/TextField';
import DragList from '../widgets/DragList';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import SessionResourceCard from './SessionResourceCard';
import SessionEditor from './SessionEditor';
import ToolCard from './ToolCard';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import Link from 'react-router-dom/Link';

const LDCourseSession = props => {
	const [showSessionEditor, setShowSessionEditor] = React.useState(false);
	const [showActivityPicker, setShowActivityPicker] = React.useState(false);
	const [showToolPicker, setShowToolPicker] = React.useState(false);
	const [showResourcePicker, setShowResourcePicker] = React.useState(false);

	const { session, onChange, onDelete, canEdit } = props;

	return (
        <Card 
			className="ld-course-session"
			header={
				<div style={{ width: '100%' }}>
					{canEdit &&
						<React.Fragment>
							<IconButton
                                onClick={onDelete}
                                style={{ 
									marginLeft: 5,
									float: 'right',
									padding: 0,
									color: '#fff'
								}}
                                size="large">
								<DeleteIcon style={{ color: props.errorColour }} />
							</IconButton>
							<IconButton
                                onClick={() => setShowSessionEditor(true)}
                                style={{ 
									marginLeft: 5,
									float: 'right',
									padding: 0,
									color: '#fff'
								}}
                                size="large">
								<EditIcon />
							</IconButton>
						</React.Fragment>
			}
					<span>{session.name}</span>
					<span style={{ float: 'right' }}>{session.time}</span>
					{session.sessionDate && <span style={{ float: 'right', marginRight: '10px' }}>{prettifyDate(session.sessionDate)}</span>}
				</div>
			}
			headerStyle={{
				background: props.primaryColour,
				color: '#fff'
			}}
			style={{ width: '100%' }}
		>
			<span className="h4" style={{ position: 'relative', minHeight: '30px' }}>
				Activities
				{canEdit && <Button onClick={() => setShowActivityPicker(true)} style={{ position: 'absolute', left: '200px', top: '-10px' }}>Add</Button>}
				{canEdit && props.canSubmitActivities &&
					<ButtonLink 
						to="/activities/create?fromLDCourse=true" 
						target="_blank" 
						style={{ position: 'absolute', left: '275px', top: '-10px' }}
					>
						Create Activity
					</ButtonLink>
				}
			</span>
			{canEdit ?
				<DragList
					tableClassName="table"
					header={
						<thead style={{ background: '#eee', color: '#333' }}>
							<tr>
								<th></th>
								<th width="300px">When</th>
								<th>Activity</th>
								<th></th>
							</tr>
						</thead>
					}
					removeItem={index => {
						session.activities.splice(index, 1);
						forEach(session.activities, (a, i) => a.order = i);
						onChange(session);
					}}
					moveItem={(from, to) => {
						// Move value
						const copy = [...session.activities];
						moveItem(copy, from, to);
						session.activities = copy;
						forEach(session.activities, (a, i) => a.order = i);
						onChange(session);
					}}
					hideArrows={true}
					renderAsCells={true}
					showAdd={false}
				>
					{map(session.activities, (item, index) => 
						<React.Fragment>
							<td>
								<TextField 
									readOnly={!canEdit}
									value={item.when}
									onChange={e => {
										const activity = session.activities[index];
										activity.when = e.target.value;
										onChange(session);
									}} 
								/>
							</td>
							<td>
								<Link to={`/activities/${item.activityId}`}>
									<span>{item.name}</span>
								</Link>
							</td>
						</React.Fragment>
					)}
				</DragList>
				:
				<table className="table">
					<thead style={{ background: '#eee', color: '#333' }}>
						<tr>
							<th width="300px">When</th>
							<th>Activity</th>
						</tr>
					</thead>
					<tbody>
						{map(session.activities, (item, index) => 
							<tr>
								<td>
									{item.when}
								</td>
								<td>
									<Link to={`/activities/${item.activityId}`}>
										<span>{item.name}</span>
									</Link>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			}
			<br />
			<span className="h4" style={{ position: 'relative', minHeight: '30px' }}>
				Learning Tools
				{canEdit && <Button onClick={() => setShowToolPicker(true)} style={{ position: 'absolute', left: '200px', top: '-5px' }}>Add</Button>}
				{canEdit && props.canSubmitTools &&
					<ButtonLink 
						to="/tools/create?fromLDCourse=true"
						target="_blank" 
						style={{ position: 'absolute', left: '275px', top: '-5px' }}
					>
						Create Tool
					</ButtonLink>
				}
			</span>
			<Grid container spacing={3} style={{ minHeight: '30px' }}>
				{map(session.tools, (t, i) =>
					<Grid key={i} item xs={4}>
						<ToolCard 
							canEdit={canEdit}
							toolId={t.toolId} 
							onDelete={() => {
								session.tools.splice(i, 1);
								onChange(session);
							}}
						/>
					</Grid>
				)}
			</Grid>
			<span className="h4" style={{ position: 'relative', minHeight: '30px', marginTop: '15px' }}>
				Resources
				{canEdit && <Button onClick={() => setShowResourcePicker(true)} style={{ position: 'absolute', left: '200px', top: '-5px' }}>Add</Button>}
			</span>
			<Grid container spacing={3} style={{ minHeight: '30px' }}>
				{map(session.resources, (r, i) =>
					<Grid key={i} item xs={4}>
						<SessionResourceCard 
							canEdit={canEdit}
							sessionResource={r} 
							onDelete={() => {
								session.resources.splice(i, 1);
								onChange(session);
							}}
						/>
					</Grid>
				)}
			</Grid>
			<SessionEditor
				open={showSessionEditor}
				session={session}
				onUpdate={(b) => {
					setShowSessionEditor(false);
					session.name = b.name;
					session.time = b.time;
					session.sessionDate = b.sessionDate;
					onChange(session);
				}}
				onClose={() => { setShowSessionEditor(false); }}
			/>
			<ActivityPicker 
				open={showActivityPicker}
				onOk={(activity) => {
					setShowActivityPicker(false);
					session.activities.push({
						when: '',
						order: session.activities.length,
						...activity
					});
					onChange(session);
				}}
				onClose={() => { setShowActivityPicker(false); }}
			/>
			<ToolPicker 
				open={showToolPicker}
				onOk={(tool) => {
					setShowToolPicker(false);
					session.tools.push(tool);
					onChange(session);
				}}
				onClose={() => { setShowToolPicker(false); }}
			/>
			<LDResourcePicker 
				open={showResourcePicker}
				onOk={(resource, file) => {
					setShowResourcePicker(false);
					session.resources.push(resource);
					onChange(session, file);
				}}
				onClose={() => { setShowResourcePicker(false); }}
				resourceTypes={['File', 'Link', 'Video']}
			/>
		</Card>
    );
}

LDCourseSession.propTypes = { 
	canEdit: PropTypes.bool,
	session: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	canSubmitActivities: PropTypes.bool.isRequired,
	canSubmitTools: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

LDCourseSession.defaultProps = {
	canEdit: false,
	session: {},
	onChange: () => null
};

const mapStateToProps = state => ({
	primaryColour: state.theme.primaryColour,
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LDCourseSession);
