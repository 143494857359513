import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import Avatar from '../widgets/Avatar';
import SearchField from '../widgets/SearchField';

const Impersonate = props => {
	const [search, setSearch] = React.useState("");
	const { users, loading } = props;
	
	return (
		<Card title="Select a user to impersonate" loading={loading}>
			<SearchField label="Search users" value={search} onChange={value => setSearch(value)} loading={loading} addPadding />
			<DataTable data={users} search={search}>
				<DataTableColumn name="fullName" label="User" renderer={data => 
					<a onClick={(e) => { e.preventDefault(); props.onImpersonate(data); }} style={{ color: props.primaryColour, cursor: 'pointer' }}>
						<Avatar src={data.avatar} fullName={data.fullName} style={{ marginRight: 5 }} />
						<span>{data.fullName}</span>
					</a>
				} />
				<DataTableColumn name="userName" label="Username" />
				{props.showTenant &&
					<DataTableColumn 
						name="tenantTitle" 
						label="Tenant"
						canFilter
						filterType="AutoComplete"
						loadItems={{
							route: "tenants", 
							mapItem: c => ({ label: c.title, value: c.tenantId })
						}}
					/>
				}
				<DataTableColumn name="email" label="Email address" renderer={
					data => data.isActive ? data.email : <span style={{ color: props.errorColour }}>**** DEACTIVATED ****</span>} 
				/>
				<DataTableColumn name="departmentTitle" label="Business unit" />
				<DataTableColumn name="positionTitle" label="Position" />
				<DataTableColumn 
					name="organisationTitle" 
					label="Organisation"
					canFilter
					filterType="AutoComplete"
					loadItems={{
						route: "organisations", 
						mapItem: c => ({ label: c.name, value: c.organisationId })
					}}
				/>
			</DataTable>
		</Card>
	);
};

Impersonate.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	showTenant: PropTypes.bool.isRequired,
	onImpersonate: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

export default Impersonate;
