import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Typography, Divider } from '@mui/material';
import TabContainer from '../widgets/TabContainer';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import Tab from '../widgets/Tab';
import map from 'lodash/map';
import Rating from '@mui/material/Rating';

const ViewBusinessProfile = ({ profile, loading, canEdit, canViewCourses, canViewContent, ratingLoading, changeRating, userOrganisationId, accreditedLogo, primaryColour, isSubscribed }) => {
	if (loading) return <CircularProgress size={24} />;

	const isOwnOrg = profile.organisationId === userOrganisationId;;
	// const promptToSubscribe = profile.organisationId === userOrganisationId && profile.visibility === "Private";
	// const isPrivate = profile.visibility === "Private";
	let AboutContent = profile.about ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: profile.about }} /> : "There is no about provided...";
	let ProductsContent = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: profile.products }} />;
	let CaseStudiesContent = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: profile.caseStudies }} />;
    if (isOwnOrg && !isSubscribed) {
        AboutContent = "This is available to Premiuim Subscribers ONLY";
        ProductsContent = "This is available to Premiuim Subscribers ONLY";
        CaseStudiesContent = "This is available to Premiuim Subscribers ONLY";
    }
	;
	// if (promptToSubscribe) {
	// 	ProductsContent = CaseStudiesContent = 
	// 		<div>
	// 			<p>This content can only be provided for premium subscribers.</p>
	// 			<ButtonLink className="subscribe-button" to="/subscription">Upgrade</ButtonLink>
	// 		</div>;
	// } else if (isPrivate) {
	// 	ProductsContent = CaseStudiesContent = 
	// 		<div>
	// 			<p>This information has not been provided.</p>
	// 		</div>;
	// } else { 
	// 	ProductsContent = profile.products ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: profile.products }} /> : "There are no products or services provided...";
	// 	CaseStudiesContent = profile.caseStudies ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: profile.caseStudies }} /> : "There are no case studies provided...";
	// }

	return (
	<Grid container spacing={4} className="business-profile-container">
		<Grid item xs="auto">
			<Card className="contact-info">
				<div style={{ textAlign: "center", padding: "1.25rem" }}>
					{profile.logo && <img src={profile.logo} alt={profile.title} />}
					<div style={{ paddingTop: 10 }}>
						<Typography variant="h6">{profile.title}</Typography>
					</div>
					{profile.accredited &&
						<img src='/images/logo/ILP logo CLO final.png' alt="" />
					}
				</div>
				<Divider />
				<div style={{ padding: "1.25rem" }}>
					{profile.email && 
						<section>
							<Typography variant="caption">Email</Typography>
							<Typography component="a" href={`mailto:${profile.email}`} variant="body2" style={{ display: "block" }}>
								{profile.email}
							</Typography>
						</section>
					}
					{profile.phone && 
						<section>
							<Typography variant="caption">Phone</Typography>
							<Typography variant="body2">{profile.phone}</Typography>
						</section>
					}
					{profile.website && 
						<section>
							<Typography variant="caption">Website</Typography>
							<Typography variant="body2" component="a" href={profile.website} target="_blank" rel="noopener noreferrer" style={{ display: "block", color: primaryColour }}>
								{profile.website}
							</Typography>
						</section>
					}
					{profile.location && 
						<section>
							<Typography variant="caption">Location</Typography>
							<Typography variant="body2">{profile.location}</Typography>
						</section>
					}
				</div>
			</Card>
			{canEdit && <div><ButtonLink to={`/business-profiles/edit/${profile.businessProfileId}`}>Edit business profile</ButtonLink></div>}
			{/* {isSubscribed && canViewCourses && profile.organisationName && <div style={{ marginTop: '10px' }}><ButtonLink to={`/courses?provider=${profile.organisationName}`} color="secondary">View Courses</ButtonLink></div>}
			{isSubscribed && canViewContent && profile.organisationName && <div style={{ marginTop: '10px' }}><ButtonLink to={`/resource-catalogue?provider=${profile.organisationName}`} color="secondary">View Resources</ButtonLink></div>} */}
		</Grid>
		<Grid item xs="auto" md>
            <div
                style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    marginTop: '-23px'
                }}
            >
                <span style={{ fontWeight: 'bold' }}>Basic Profile Member</span>
            </div>
			<Card 
				header={
					<div style={{ display: "flex", width: "100%", alignItems: "center" }}>
						<span className="h3" style={{ flex: 1, margin: 0 }}>{profile.title}</span>
						{profile.accreditationLogo && <img src={profile.accreditationLogo} alt="Accredited" style={{ maxHeight: 40 }} />}
						{ratingLoading ? 
							<CircularProgress size={16} />
							:
							<Rating
								value={profile.rating}
								onChange={(e, newValue) => {
									changeRating(newValue);
								}}
							/>
						}
					</div>
				} 
				style={{ marginBottom: 20 }}
			>
				{profile.organisationName && 
					<div>
						<span className="h5" style={{ margin: '0 10px 0 0', display: 'inline-block' }}>Organisation:</span>
						<span>{profile.organisationName}</span>
					</div>
				}
				{profile.summary && 
					<div>
						<span className="h5" style={{ marginTop: '0 10px 0 0', display: 'inline-block' }}>Summary: </span>
						<span className="quill-display-block" dangerouslySetInnerHTML={{ __html: profile.summary }} />
					</div>
				}
				{profile.areasOfExpertise && profile.areasOfExpertise.length > 0 && <span className="h5" style={{ marginTop: 20 }}>Areas Of Expertise</span>}
				{map(profile.areasOfExpertise, (t, i) => <Tag key={i} label={t.name} />)}
				{profile.categories && profile.categories.length > 0 && <span className="h5" style={{ marginTop: 20 }}>Categories</span>}
				{map(profile.categories, (c, i) => <Tag key={i} label={c.name} colour={primaryColour} />)}
			</Card>
			<TabContainer>
				<Tab label="About">
					{AboutContent}
				</Tab>
				<Tab label="Products/Services">
					{ProductsContent}
				</Tab>
				<Tab label="Case studies">
					{CaseStudiesContent}
				</Tab>
			</TabContainer>
		</Grid>
	</Grid>
	);
};

ViewBusinessProfile.propTypes = {
	profile: PropTypes.shape({
		businessProfileId: PropTypes.number,
		title: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
		website: PropTypes.string,
		location: PropTypes.string,
		description: PropTypes.string,
		logo: PropTypes.string,
		research: PropTypes.string,
		institutions: PropTypes.string,
		caseStudies: PropTypes.string,
		tags: PropTypes.array
	}).isRequired,
	userOrganisationId: PropTypes.number.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	canViewContent: PropTypes.bool,
	canViewCourses: PropTypes.bool,
	ratingLoading: PropTypes.bool.isRequired,
	changeRating: PropTypes.func.isRequired,
	accreditedLogo: PropTypes.string,
	primaryColour: PropTypes.string.isRequired
};

ViewBusinessProfile.defaultProps = {
	canEdit: false,
	canViewCourses: false,
	canViewContent: false,
	accreditedLogo: null
};

export default ViewBusinessProfile;