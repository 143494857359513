import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Avatar from '../widgets/Avatar';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { prettifyDate } from '../../utils/utils';

const bannerSwitch = {
	"Business Management": "/images/insight-banners-lq/Business Management.jpg",
	"Communications": "/images/insight-banners-lq/Communications.jpg",
	"Computer Sciences": "/images/insight-banners-lq/Computer Sciences.jpg",
	"Digital Capability": "/images/insight-banners-lq/Digital Capability.jpg",
	"Engineering": "/images/insight-banners-lq/Engineering.jpg",
	"Finance": "/images/insight-banners-lq/Finance.jpg",
	"Financial Management": "/images/insight-banners-lq/Finance.jpg",
	"Healthcare": "/images/insight-banners-lq/Healthcare.jpg",
	"Hospitality Management": "/images/insight-banners-lq/Human Resource Management.jpg",
	"Human Resource Management": "/images/insight-banners-lq/Human Resource Management.jpg",
	"Industrial Relations": "/images/insight-banners-lq/Information Management.jpg",
	"Information Systems": "/images/insight-banners-lq/Information Management.jpg",
	"Information Management": "/images/insight-banners-lq/Information Management.jpg",
	"Innovation": "/images/insight-banners-lq/Innovation Management.jpg",
	"Innovation Management": "/images/insight-banners-lq/Innovation Management.jpg",
	"International Business": "/images/insight-banners-lq/International Business.jpg",
	"Law": "/images/insight-banners-lq/International Business.jpg",
	"Leadership": "/images/insight-banners-lq/Leadership.jpg",
	"Learning & Development": "/images/insight-banners-lq/Learning & Development.jpg",
	"Manufacturing": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Manufacturing Engineering & Technology": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Organisational Growth & Resilience": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Organisation Management": "/images/insight-banners-lq/Organisational Growth & Resilience.jpg",
	"Personal Management Training": "/images/insight-banners-lq/Program & Project Management.jpg",
	"Program & Project Management": "/images/insight-banners-lq/Program & Project Management.jpg",
	"Procurement & Contract Management": "/images/insight-banners-lq/Program & Project Management.jpg",
	"Public and Health Care Administration": "/images/insight-banners-lq/Healthcare.jpg",
	"Quality Management": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Robotics": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Supply Chain & Logistics": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Systems Thinking": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Strategy": "/images/insight-banners-lq/Systems Thinking.jpg",
	"Transformation": "/images/insight-banners-lq/Transformation.jpg"
};

const InsightRow = React.memo(({ insight, onExpertClick }) => {
	const primaryChannel = insight.channels[0] ? insight.channels[0].name : '';

	return (
		<div className="insight-row">
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ flex: "1" }}>
					<Link className="h4" to={`/my-community/insights/${insight.insightId}`}>{insight.title}</Link>
					<div>
						{insight.channels.slice(0, 2).map((c, i) => <span key={i} className="label">{c.name}</span>)}
						{insight.channels.length > 2 && 
							<Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{insight.channels.slice(2).map(c => c.name + "\n")}</div>}>
								<span className="label">+ {insight.channels.length - 2} more...</span>
							</Tooltip>
						}
					</div>
					<div className="expert" onClick={() => onExpertClick(insight.expertId)} style={{ cursor: "pointer" }}>
						<Avatar src={insight.expertAvatar} fullName={insight.expertName} />
						<span className="h5">{insight.expertName}</span>
					</div>
				</div>
				{bannerSwitch[primaryChannel] && <img src={bannerSwitch[primaryChannel]} alt={primaryChannel} style={{ height: 70 }} />}
			</div>
			<div className="description">{insight.description}</div>
			<div className="dates">
				Published {prettifyDate(insight.createdAt)}
				{insight.updatedAt && `, Updated ${prettifyDate(insight.updatedAt)}`}
			</div>
			{map(insight.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
	)
});

InsightRow.propTypes = {
	insight: PropTypes.object.isRequired,
	onExpertClick: PropTypes.func.isRequired
};

export default InsightRow;