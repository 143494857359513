import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDepartment, saveDepartment, deleteDepartment, isLoading } from '../../store/departments';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditDepartment from './EditDepartment';
import { showErrorNotification } from '../../store/notifications';
import axios from 'axios';
import { get } from '../../utils/ajax';
import { canManageDepartmentsAcrossTenant } from '../../store/security';

const EditDepartmentController = props => {
	const [loading, setLoading] = React.useState(false);
	const [users, setUsers] = React.useState([]);
	const [departments, setDepartments] = React.useState([]);

	React.useEffect(() => {
		props.getDepartment(props.match.params.departmentId, (data) => {
			onOrganisationChanged(data.organisationId);
		});
	}, [props.match.params.departmentId]);

	const onOrganisationChanged = (organisationId) => {
		setLoading(true);
		axios.all([
			get({
				url: props.organisationId === organisationId ? `/api/departments/by-organisation` : `/api/departments/by-organisation/${organisationId}`,
				onSuccess: data => {
					setDepartments(data);
				},
				onError: error => props.showErrorNotification(error.message)
			}),
			get({
				url: props.organisationId === organisationId ? `/api/users/by-organisation` : `/api/users/by-organisation/${organisationId}`,
				onSuccess: data => {
					setUsers(data);
				},
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setLoading(false));
	};

	return (
		<PageLayout 
			title="Edit business unit" 
			pageContent={
				<EditDepartment
					isNew={false}
					department={props.department}
					saveDepartment={props.saveDepartment}
					deleteDepartment={props.deleteDepartment}
					loading={props.loading || loading}
					users={users}
					departments={departments}
					onOrganisationChanged={onOrganisationChanged}
					canChangeOrganisation={props.canChangeOrganisation}
				/>
			} 
			breadcrumbs={[
				{ label: "Business units", path: "/business-units" }, 
				"Edit business unit"
			]} 
		/>
	);
};

EditDepartmentController.propTypes = {
	department: PropTypes.object.isRequired,
	saveDepartment: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
	organisationId: PropTypes.number.isRequired,
	canChangeOrganisation: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	department: state.departments.department,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canChangeOrganisation: canManageDepartmentsAcrossTenant(state)
});

const mapDispatchToProps = dispatch => ({
	getDepartment: (departmentId, onSuccess) => dispatch(getDepartment(departmentId, onSuccess)),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	saveDepartment: department => dispatch(saveDepartment(department)),
	deleteDepartment: departmentId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this business unit?",
		onOk: () => dispatch(deleteDepartment(departmentId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDepartmentController);