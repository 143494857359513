import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewApplication from './ViewApplication';
import { 
	getApplication, 
	isLoading, 
	setApplicationStatus 
} from '../../store/offers';
import { canEditApplication } from '../../store/security';
import isEmpty from 'lodash/isEmpty';
import { showModal } from '../../store/modal';

class ViewApplicationController extends React.Component {
	UNSAFE_componentWillMount() {
		const applicationId = this.props.match.params.applicationId;

		this.props.getApplication(applicationId);
	}

	downloadAttachment = attachment =>
		window.open(`/Application/DownloadAttachment?applicationId=${this.props.application.applicationId}&applicationAttachmentId=${attachment.applicationAttachmentId}`, '_self');

	render = () => 
		<PageLayout title="View Application" pageContent={
			<ViewApplication 
				canEdit={this.props.canEdit}
				application={this.props.application}
				loading={this.props.loading || isEmpty(this.props.application)}
				setStatus={this.props.setStatus}
				downloadAttachment={this.downloadAttachment}
			/>
		} breadcrumbs={[
			{ label: "Offers", path: "/offers" },
			"View Application"
		]} />;
};

ViewApplicationController.propTypes = {
	application: PropTypes.object.isRequired,
	setStatus: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
	application: state.offers.application,
	canEdit: canEditApplication(state),
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getApplication: applicationId => dispatch(getApplication(applicationId)),
	setStatus: (applicationId, status) => dispatch(showModal("SIMPLE", {
		title: status === "Declined" ? "Decline?" : status + "?",
		message: status === "Declined" ? "Are you sure you wish to decline this application?" : 
			`Are you sure you wish to proceed with this application as a ${status}?`,
		onOk: () => dispatch(setApplicationStatus(applicationId, status))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewApplicationController);
