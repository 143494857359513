import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import { createFetchAction, createFormPostAction, createPostAction } from '../utils/reducer-utils';
import { menuIcons } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';

const initialState = {
	menus: [],
	userMenu: {},
	menu: {}
};

const CLEAR_MENU = "CLEAR_MENU";
const CLEAR_MENUS = "CLEAR_MENUS";
const GET_MENUS_REQUEST = "GET_MENUS_REQUEST";
const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";
const GET_MENUS_FAILURE = "GET_MENUS_FAILURE";
const GET_USER_MENU_REQUEST = "GET_USER_MENU_REQUEST";
const GET_USER_MENU_SUCCESS = "GET_USER_MENU_SUCCESS";
const GET_USER_MENU_FAILURE = "GET_USER_MENU_FAILURE";
const GET_MENU_REQUEST = "GET_MENU_REQUEST";
const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
const GET_MENU_FAILURE = "GET_MENU_FAILURE";
const SAVE_MENU_REQUEST = "SAVE_MENU_REQUEST";
const SAVE_MENU_SUCCESS = "SAVE_MENU_SUCCESS";
const SAVE_MENU_FAILURE = "SAVE_MENU_FAILURE";
const UPDATE_ALL_REQUEST = "UPDATE_ALL_REQUEST";
const UPDATE_ALL_SUCCESS = "UPDATE_ALL_SUCCESS";
const UPDATE_ALL_FAILURE = "UPDATE_ALL_FAILURE";
const DELETE_MENU_REQUEST = "DELETE_MENU_REQUEST";
const DELETE_MENU_SUCCESS = "DELETE_MENU_SUCCESS";
const DELETE_MENU_FAILURE = "DELETE_MENU_FAILURE";

export const isLoading = createLoadingSelector(["GET_MENUS", "GET_USER_MENU", "GET_MENU", "SAVE_MENU", "UPDATE_ALL", "DELETE_MENU"]);

export const clearMenus = () => ({ type: CLEAR_MENUS });
export const clearMenu = () => ({ type: CLEAR_MENU });

export const getMenus = () =>
	createFetchAction({
		url: "/api/menu",
		startAction: GET_MENUS_REQUEST,
		onError: error => [getMenusFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMenusSuccess(data)
	});

export const getMenusSuccess = data => ({ type: GET_MENUS_SUCCESS, payload: { data } });
export const getMenusFailure = error => ({ type: GET_MENUS_FAILURE, payload: { error } });

export const getUserMenu = () =>
	createFetchAction({
		url: "/api/menu/user",
		startAction: GET_USER_MENU_REQUEST,
		onError: error => [getUserMenuFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserMenuSuccess(data)
	});

export const getUserMenuSuccess = data => ({ type: GET_USER_MENU_SUCCESS, payload: { data } });
export const getUserMenuFailure = error => ({ type: GET_USER_MENU_FAILURE, payload: { error } });

export const getMenu = menuId =>
	createFetchAction({
		url: `/api/menu/${menuId}`,
		startAction: GET_MENU_REQUEST,
		onError: error => [getMenuFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMenuSuccess(data)
	});

export const getMenuSuccess = data => ({ type: GET_MENU_SUCCESS, payload: { data } });
export const getMenuFailure = error => ({ type: GET_MENU_FAILURE, payload: { error } });

export const saveMenu = menu => 
	createFormPostAction({
		url: "/api/menu",
		data: menu,
		startAction: SAVE_MENU_REQUEST,
		onError: error => [saveMenuFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveMenuSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/menu")];
			} else {
				return [saveMenuSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveMenuSuccess = data => ({ type: SAVE_MENU_SUCCESS, data });
export const saveMenuFailure = error => ({ type: SAVE_MENU_FAILURE, error });

export const updateAllMenus = (menus, onSuccess) =>
	createFormPostAction({
		url: "/api/menu/all",
		data: menus,
		startAction: UPDATE_ALL_REQUEST,
		onError: error => [updateAllFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess(data);
				return [updateAllSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [updateAllSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const updateAllSuccess = data => ({ type: UPDATE_ALL_SUCCESS, data });
export const updateAllFailure = error => ({ type: UPDATE_ALL_FAILURE, error });

export const deleteMenu = menuId =>
	createPostAction({
		url: `/api/menu/${menuId}/delete`,
		startAction: DELETE_MENU_REQUEST,
		onError: error => [deleteMenuFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteMenuSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/menu")];
			} else {
				return [deleteMenuSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteMenuSuccess = data => ({ type: DELETE_MENU_SUCCESS, data });
export const deleteMenuFailure = error => ({ type: DELETE_MENU_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_MENUS:
			return { ...state, menus: [] };
		case CLEAR_MENU:
			return { ...state, menu: {} };
		case GET_MENUS_REQUEST:
			return { ...state, menus: [] };
		case GET_MENUS_SUCCESS:
			return { ...state, menus: action.payload.data };
		case GET_USER_MENU_REQUEST:
			return { ...state, isLoading: true, userMenu: {} };
		case GET_USER_MENU_SUCCESS:
			return { 
				...state, 
				isLoading: false, 
				userMenu: action.payload.data.reduce((acc, m) => {
					acc.names.push(m.menuName);
					acc.labels[m.menuName] = m.label;
					acc.icons[m.menuName] = menuIcons[m.iconRef] ?? m.iconRef;
					acc.disabled[m.menuName] = m.disabled;
					acc.items[m.menuName] = m.items;
					acc.disabledText[m.menuName] = m.disabledText;

					return acc;
				}, { names: [], labels: {}, icons: {}, disabled: {}, disabledText: {}, items: [] })
			};
		case GET_MENU_REQUEST:
			return { ...state, isLoading: true, menu: {} };
		case GET_MENU_SUCCESS:
			return { ...state, isLoading: false, menu: action.payload.data };
		case SAVE_MENU_REQUEST:
		case DELETE_MENU_REQUEST:
			return { ...state, isLoading: true };
		case SAVE_MENU_SUCCESS:
			return { ...state, ...(action.data.success && { menu: action.data.object }), isLoading: false };
		case DELETE_MENU_SUCCESS:
			return { ...state, menus: state.menus.filter(a => a.menuId !== action.data.objectId) };
		case UPDATE_ALL_SUCCESS:
			return { ...state, ...(action.data.success && { menus: action.data.object })};
		default:
			return state;
	}
};