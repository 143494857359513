import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, CircularProgress, Divider } from '@mui/material';
import { getExpert, isLoading } from '../../store/experts';
import { canEditExpert } from '../../store/security';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';

const imageStyle = {
	display: "block",
	margin: "0 auto 20px",
	maxWidth: 500,
	maxHeight: 300
};

const ExpertDialog = props => {
	const { expert } = props;

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
            TransitionProps={{
                onEnter: () => props.getExpert(props.expertId)
            }}>
			<DialogTitle>
				<div style={{ display: "flex", alignItems: "center" }}>
					{props.loading ? "View Expert" :
						<React.Fragment>
							<Avatar src={expert.avatar} fullName={expert.fullName} style={{ marginRight: 5 }} />
							<span className="h2" style={{ margin: 0 }}>{expert.fullName}</span>
						</React.Fragment>
					}
					{props.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
				</div>
			</DialogTitle>
			{props.loading || isEmpty(expert) ? <DialogContent>Loading expert...</DialogContent> :
				<DialogContent>
					{expert.image && <img src={expert.image} alt={expert.fullName} style={imageStyle} />}
					<section>
						{expert.industryName}, {expert.discipline}
						<br />
						{expert.seniority} {expert.yearsExperience} Years
					</section>
					<Divider style={{ margin: "20px 0" }} />
					<section>
						<span className="h4">Academic Experience</span>
						{expert.courses.length > 0 &&
							<Grid container spacing={3} style={{ marginBottom: 10 }}>
								{expert.courses.map((c, i) => 
									<Grid key={i} item xs={12} sm={6}>
										<Card title={c.name} style={{ marginBottom: 0 }}>{c.institution}, Completed {c.year}</Card>
									</Grid>
								)}
							</Grid>
						}
						{expert.academicJobs.length > 0 &&
							<React.Fragment>
								<span className="h6">Jobs</span>
								<ul>
									{expert.academicJobs.map((j, i) => 
										<li key={i}><strong>{j.role}</strong>, {j.employer} ({j.years} Years)</li>
									)}
								</ul>
							</React.Fragment>
						}
					</section>
					<Divider style={{ margin: "20px 0" }} />
					<section>
						<span className="h4">Professional Experience</span>
						{expert.professionalQualifications && 
							<div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: expert.professionalQualifications }} />
						}
						{expert.professionalJobs.length > 0 &&
							<section>
								<span className="h6">Jobs</span>
								<ul>
									{expert.professionalJobs.map((j, i) => 
										<li key={i}><strong>{j.role}</strong>, {j.employer} ({j.years} Years)</li>
									)}
								</ul>
							</section>
						} 
					</section>
					<Divider style={{ margin: "20px 0" }} />
					<section>
						<span className="h4">Executive Experience</span>
						<div dangerouslySetInnerHTML={{ __html: expert.executiveExperience }} />
					</section>
				</DialogContent>
			}
			<DialogActions>
				{props.canEdit && <ButtonLink to={`/experts/edit/${expert.expertId}`}>Edit expert</ButtonLink>}
				<Button onClick={props.onClose}>Cancel</Button>
                <Button
                    onClick={() => { props.chatWithExpert(expert.userId) }} 
                    color="secondary" 
                    style={{ marginLeft: '10px' }}
                >
                    Chat with {expert.fullName}
                </Button>
			</DialogActions>
		</Dialog>
    );
};

ExpertDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	expertId: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
	expert: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
    chatWithExpert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	expert: state.experts.expert,
	loading: isLoading(state),
	canEdit: canEditExpert(state)
});

const mapDispatchToProps = dispatch => ({
	getExpert: expertId => dispatch(getExpert(expertId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertDialog);