import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPosition, savePosition, deletePosition, isLoading } from '../../store/positions';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditPosition from './EditPosition';

const EditPositionController = props => {
	React.useEffect(() => props.getPosition(props.match.params.positionId), [props.match.params.positionId]);

	return <PageLayout title="Edit Position" pageContent={
		<EditPosition
			position={props.position}
			savePosition={props.savePosition}
			deletePosition={props.deletePosition}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Positions", path: "/admin/positions" }, "Edit Position"]} />;
};

EditPositionController.propTypes = {
	position: PropTypes.object.isRequired,
	savePosition: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	position: state.positions.position,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getPosition: positionId => dispatch(getPosition(positionId)),
	savePosition: position => dispatch(savePosition(position)),
	deletePosition: positionId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this Position?",
		onOk: () => dispatch(deletePosition(positionId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPositionController);
