import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveBusinessProfile, isLoading } from '../../store/businessProfiles';
import PageLayout from '../PageLayout';
import EditBusinessProfile from './EditBusinessProfile';

const CreateBusinessProfileController = props => 
	<PageLayout 
		title="Create business profile" 
		pageContent={
			<EditBusinessProfile 
				isNew 
				profile={{
					organisationId: 0
				}}
				saveProfile={props.saveBusinessProfile} 
				loading={props.loading} 
				isSubscribed={props.isSubscribed}
			/>
		} 
		breadcrumbs={[
			{ label: "Business profiles", path: "/business-profiles" }, 
			"Create business profile"
		]} 
	/>;

CreateBusinessProfileController.propTypes = {
	saveBusinessProfile: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isSubscribed: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	isSubscribed: state.context.isSubscribed
});

const mapDispatchToProps = dispatch => ({
	saveBusinessProfile: (profile, logoFile) => dispatch(saveBusinessProfile(profile, logoFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBusinessProfileController);
