import React from 'react';

const NotSubscribedField = ({ label }) =>
<React.Fragment>
    <label style={{ marginRight: 10 }}>{label}</label>
    <div style={{ padding: '10px', background: '#eee' }}>
        <p style={{ margin: 0 }}>Subscriber only content</p>
    </div>
</React.Fragment>;

export default NotSubscribedField;
