import React from 'react';
import PropTypes from 'prop-types';
import { 
	Paper 
} from '@mui/material';
import Button from '../widgets/Button';
import PrintIcon from '@mui/icons-material/Print';

const ReportContainer = ({ isReportLayout, orientation, children}) => 
	isReportLayout ? 
		<div className='report-container'>{children}</div>
		:
		<Paper 
			className='report-container' 
			style={{ 
				padding: '10px', 
				// width: orientation === 'landscape' ? '28.7cm' : '20cm',
				width: orientation === 'landscape' ? '28.7cm' : '28.7cm', // Not matching print view for some reason
				minHeight: orientation === 'landscape' ? '20cm' : '28.7cm',
				marginLeft: 'auto',
				marginRight: 'auto'
			}}
		>
			<Button onClick={() => window.print()} className="report-print-button">
				<PrintIcon style={{ marginRight: 5 }} />Print
			</Button>
			{children}
		</Paper>;

ReportContainer.propTypes = {
	isReportLayout: PropTypes.bool,
	orientation: PropTypes.string,
	children: PropTypes.any.isRequired
};

ReportContainer.defaultProps = {
	isReportLayout: false,
	orientation: 'portrait'
};

export default ReportContainer;
