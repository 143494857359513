import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MenuItem, Select, Tooltip } from '@mui/material';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';
import { isContributorLeaderboardLoading, getContributorLeaderboard } from '../../store/dashboard';
import map from 'lodash/map';

const ContributorLeaderBoard = props => {
	const [range, setRange] = React.useState(30);
	const tableBody = React.useRef(null),
		tableHeader = React.useRef(null);

	React.useEffect(() => props.getContributorLeaderboard(range), [range]);

	React.useEffect(() => {
		const fixTableHeader = () => {
			// To-do: additional testing required
			if (tableHeader.current && tableBody.current) {
				const headerCells = tableHeader.current.children[0].children,
					bodyCells = tableBody.current.children[0].children;

				headerCells[0].width = `${bodyCells[0].offsetWidth + bodyCells[1].offsetWidth}px`;
				headerCells[1].width = `${bodyCells[2].offsetWidth}px`;
				headerCells[2].width = `${bodyCells[3].offsetWidth}px`;
			}
		}

		window.addEventListener("resize", fixTableHeader);

		return () => window.removeEventListener("resize", fixTableHeader);
	}, []);

	const { leaderboard, isAdmin } = props;

	return (
		<Card 
			title="Contributor leader board"
			subtitle="Are you on the leader board? See who is generating ideas, getting endorsements and views"
			loading={props.loading}
			titleRightContent={
				<Select value={range} onChange={e => setRange(e.target.value)}>
					{map([30, 60, 90], (value, i) => <MenuItem key={i} value={value}>Last {value} days</MenuItem>)}
				</Select>
			}
		>
			{leaderboard.length > 0 &&
				<table className="table stylish-table" style={{ tableLayout: "fixed" }}>
					<thead ref={tableHeader}>
						<tr>
							<th colSpan="2">Contributor</th>
							<th align="center">Total views</th>
							<th align="center">Endorsements received</th>
						</tr>
					</thead>
					<tbody ref={tableBody}>
						{map(leaderboard, (leader, index) =>
							<tr key={index}>
								<td style={{ width: 50 }}>
									<Avatar src={leader.avatar} fullName={leader.userName} length={50} />
								</td>
								<td style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
									<span className="h6"><Link to={`/problems/by-user/${leader.userId}`} className="text-themecolor">{leader.userName}</Link></span>
									<small className="text-muted">{leader.position}</small>
								</td>
								<td style={{ textAlign: "center" }}>
									{isAdmin ? 
										<Tooltip title="View stats">
											<Link to={`/users/stats/${leader.userId}?tab=views`} style={{ color: "inherit" }}>{leader.viewsReceived}</Link>
										</Tooltip> 
									: leader.viewsReceived}
								</td>
								<td style={{ textAlign: "center" }}>
									{isAdmin ? 
										<Tooltip title="View stats">
											<Link to={`/users/stats/${leader.userId}?tab=endorsements`} style={{ color: "inherit" }}>{leader.votesReceived}</Link>
										</Tooltip> 
									: leader.votesReceived}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			}
		</Card>
	);
};

ContributorLeaderBoard.propTypes = {
	leaderboard: PropTypes.array.isRequired,
	loading: PropTypes.bool,
	isAdmin: PropTypes.bool
};

ContributorLeaderBoard.defaultProps = {
	loading: false,
	isAdmin: false
};

const mapStateToProps = state => ({
	leaderboard: state.dashboard.leaderboard,
	loading: isContributorLeaderboardLoading(state),
	isAdmin: state.context.isAdministrator
});

const mapDispatchToProps = dispatch => ({
	getContributorLeaderboard: days => dispatch(getContributorLeaderboard(days))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContributorLeaderBoard);
