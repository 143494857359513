import React from 'react';
import PropTypes from 'prop-types';

const CardContainer = props => 
	<div className={`card-container ${props.className}`}>
		{props.children}
	</div>
;

CardContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string
};

CardContainer.defaultProps = {
	className: ""
};

export default CardContainer;
