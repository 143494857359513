import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { withForm } from '../../utils/forms';
import { stripHtml } from '../../utils/utils';
import UnitOfCompetencyPicker from '../competencies/UnitOfCompetencyPicker';
import UnitOfCompetencyViewer from '../competencies/UnitOfCompetencyViewer';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import AutoComplete from '../widgets/AutoComplete';
import TextField from '../widgets/TextField';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import ColourEditor from '../widgets/ColourEditor';
import CopyCourseDialog from './CopyCourseDialog';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import map from 'lodash/map';
import { isFuture } from 'date-fns';

const form = {
	initValues: ({ course = {}, organisationId }) => ({ 
		competencies: [], 
		providerId: organisationId, 
		...course,
		channels: map(course.channels, c => ({ ...c, label: c.name, value: c.channelId })), 
		organisations: map(course.organisations, o => ({ ...o, label: o.name, value: o.organisationId }))
	}),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of Course"
		},
		{
			name: "providerId",
			label: "Provider",
			required: true,
			disabled: props => !props.canAdminCourses,
			type: "autoComplete",
			loadItems: {
				route: "organisations/providers",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			helpText: "Course Provider"
		},
		{
			name: "courseCategoryId",
			label: "Category",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: (props, values) => {
					if (values && values.tenantId) {
						return `course-categories/by-tenant?tenantId=${values.tenantId}`;
					} else {
						return "course-categories";
					}
				},
				mapItem: cat => ({ label: cat.name, value: cat.courseCategoryId })
			},
			helpText: "Category of Course"
		},
		{
			name: "mode",
			label: "Mode",
			required: true,
			type: "select",
			items: [{ label: "(All)", value: "all" }, "Online", "Digital Classroom", "Face-to-face", "Blended"],
			helpText: "Mode used for duration of Course"
		},
		{
			name: "summary",
			label: "Summary",
			type: "textEditor",
			helpText: "Summary of Course to be displayed in card"
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor",
			helpText: "Brief description of Course"
		},
		{
			name: "contactName",
			label: "Contact Name",
			helpText: "Name of individual to contact for inquries"
		},
		{
			name: "contactEmail",
			label: "Contact Email",
			helpText: "Destination email for contact requests"
		},
		{
			name: "website",
			label: "Website",
			helpText: "URL to course brochure or registration page"
		},
		{
			name: "videoUrl",
			label: "Video URL",
			helpText: "Direct URL to course video"
		},
		{
			name: "image",
			label: "Image",
			type: "imageSelector",
			helpText: "Select an image to represent the course",
			height: 64,
			width: 64
		},
		{
			name: "channels",
			label: "Channels",
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: ({ channelId, name }) => ({ label: name, value: channelId, channelId })
			},
			isMulti: true,
			helpText: "Course channels (please select all relevant options)"
		},
		{ name: "competencies" },
		{
			name: "organisations",
			label: "Organisations",
			type: "dragList",
			simple: true,
			itemTemplate: { organisationId: 0, price: "" },
			renderItem: (item, index, updateItem, props) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={6}>
						<AutoComplete 
							label="Organisation"
							items={props.organisations}
							value={item.organisationId} 
							onChange={item => updateItem(item.value, "organisationId")}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField 
							label="Price"
							placeholder="0.00"
							startAdornment="$"
							value={item.price || ""} 
							onChange={e => updateItem(e.target.value, "price")} 
						/>
					</Grid>
				</Grid>,
			helpText: "Organisations managing Course (Price optional)",
			validate: organisations => {
				const ids = organisations.map(o => o.organisationId);

				return new Set(ids).size !== ids.length ? "An organisation may only be added once" : "";
			}
		},
		{
			name: "price",
			label: "Price",
			placeholder: "0.00",
			startAdornment: ""
		},
		{
			name: "commencementDate",
			label: "Commencement Date",
			type: "date",
			stripTime: true
		},
		{
			name: "expiryDate",
			label: "Expiry Date",
			type: "date",
			stripTime: true,
			validate: value => isFuture(new Date(value)) ? "" : "Expiry date must occur after today"
		},
		{
			name: "partnerPrice",
			label: "Partner Price",
			placeholder: "0.00"
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor",
			scope: "Courses"
		},
		{
			name: "attachments",
			label: "Course Brochure(s)",
			type: "attachmentEditor",
			download: attachment =>
				window.open(`/Course/DownloadAttachment?courseId=${attachment.courseId}&attachmentId=${attachment.attachmentId}`, "_self")
		},
		{
			name: "skipPreview",
			label: "Show Preview",
			type: "checkbox"
		},
		{
			name: "discountCode",
			label: "Access Coupon"
		},
		{
			name: "partnerPriceApplies",
			label: "Partner Price Applies",
			type: "checkbox"
		},
		{
			name: "prices",
			label: "Prices",
			type: "dragList",
			simple: false,
			itemTemplate: { price: "", backColour: "ffb22b", foreColour: "FFFFFF" },
			renderItem: (item, index, updateItem, props) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={3}>
						<TextField 
							label="Name"
							value={item.name || ""} 
							onChange={e => updateItem(e.target.value, "name")} 
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField 
							label="Price"
							placeholder="0.00"
							startAdornment=""
							value={item.price || ""} 
							onChange={e => updateItem(e.target.value, "price")} 
						/>
					</Grid>
					<Grid item xs={3}>
						<ColourEditor
							label="Back Colour"
							value={item.backColour ? item.backColour : ''}
							onChange={e => updateItem(e.target.value, "backColour")}
						/>
					</Grid>
					<Grid item xs={3}>
						<ColourEditor
							label="Fore Colour"
							value={item.foreColour ? item.foreColour : ''}
							onChange={e => updateItem(e.target.value, "foreColour")}
						/>
					</Grid>
				</Grid>,
			validate: prices => {
				for (let i = 0, ii = prices.length; i < ii; i++) {
					if (!prices[0].price) {
						return "Price is required";
					}
				}
				return "";
			}
		}
	]
};

const EditCourse = props => {
	const [state, setState] = React.useState({
		unitPickerOpen: false,
		unitViewerOpen: false,
		unitId: 0,
		elementId: 0,
		selectedElements: [],
		copyDialogOpen: false
	});
	
	const { course, fields, loading, values: { competencies }, errorColour } = props;

	const addCompetencies = units => {
		setState({ ...state, unitPickerOpen: false });

		units.forEach(u => {
			const competency = find(competencies, c => c.unitOfCompetencyId === u.unitOfCompetencyId);
			
			if (!competency) {
				competencies.push(u);
			} else {
				competency.elements = uniqBy([...competency.elements, ...u.elements], "elementId");
			}
		});

		props.updateValues({ competencies });
	}

	const setElements = elements => {
		find(competencies, c => c.unitOfCompetencyId === state.unitId).elements = [...elements];
		
		props.updateValues({ competencies });
		setState({ ...state, unitViewerOpen: false });
	}

	const deleteCompetency = unitId => {
		props.updateValues({ competencies: competencies.filter(u => u.unitOfCompetencyId !== unitId) });
	}

	const viewUnit = ({ unitOfCompetencyId, elements }, elementId) => {
		setState({ ...state, unitViewerOpen: true, unitId: unitOfCompetencyId, elementId, selectedElements: elements });
	}

	return (
        <Card>
			<Form onSubmit={() => props.validateFields() && props.saveCourse(props.values, props.formFiles.image, props.formFiles.attachments)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.providerId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.courseCategoryId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.mode()}</Grid>
					<Grid item xs={12}>{fields.summary()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12} sm={6}>{fields.contactName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.contactEmail()}</Grid>
					<Grid item xs={12}>{fields.website()}</Grid>
					<Grid item xs={12}>{fields.videoUrl()}</Grid>
					<Grid item xs={12}>
						<div className="h3">Pricing</div>
						<p style={{ fontSize: 'smaller' }}>You can set an single price or add multiple prices. If multiple prices are added then the single price will be ignored.</p>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>{fields.price()}</Grid>
							<Grid item xs={12} sm={6}>{fields.discountCode()}</Grid>
							<Grid item xs={12}>{fields.prices()}</Grid>
						</Grid>
					</Grid>
					{/* <Grid item xs={12} sm={6}>{fields.partnerPrice()}</Grid>
					<Grid item xs={12}>{fields.organisations()}</Grid> */}
					<Grid item xs={12}>{fields.image()}</Grid>
					<Grid item xs={12}>{fields.channels()}</Grid>
					<Grid item xs={12} sm="auto">{fields.commencementDate()}</Grid>
					<Grid item xs={12} sm="auto">{fields.expiryDate()}</Grid>
					<Grid item xs={12}>{fields.skipPreview()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
					<Grid item xs={12}>
						<React.Fragment>
							<span className="h3">
								Competencies
								<IconButton
                                    onClick={() => setState({ ...state, unitPickerOpen: true })}
                                    style={{ marginLeft: 5 }}
                                    size="large"><AddIcon /></IconButton>
							</span>
							<span>(optional competency mapping of course)</span>
							<DataTable 
								data={competencies || []}
								emptyText="No competencies"
								detailData={c => c.elements}
								hidePagination
								disableScroll
								detailColumns={[
									<DataTableColumn colSpan={2} style={{ padding: 0 }} />,
									<DataTableColumn colSpan={2} style={{ whiteSpace: "normal" }} renderer={(c, e) =>
										<a onClick={() => viewUnit(c, e.elementId)} style={{ cursor: "pointer" }}>
											<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }}></div>
										</a>
									} />
								]}
							>
								<DataTableColumn style={{ padding: "0", width: "35px" }} headerStyle={{ padding: 0 }} renderer={u =>
									<IconButton
                                        onClick={() => deleteCompetency(u.unitOfCompetencyId)}
                                        style={{ padding: 2 }}
                                        aria-label="Delete Competency"
                                        size="large">
										<DeleteIcon style={{ color: errorColour }} />
									</IconButton>
								} />
								<DataTableColumn label="Framework" name="frameworkCode" />
								<DataTableColumn label="Code" renderer={u =>
									<a onClick={() => viewUnit(u)} style={{ cursor: "pointer" }}>{u.unitCode}</a>
								} />
								<DataTableColumn label="Unit" style={{ whiteSpace: "normal" }} renderer={u =>
									<React.Fragment>
										<strong>{stripHtml(u.unitTitle)}</strong>
										{u.unitDescriptionAndApplication && <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: u.unitDescriptionAndApplication }} />}
									</React.Fragment>
								} />
							</DataTable>
						</React.Fragment>
					</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>Save course</Button>
					{!isEmpty(course) &&
						<React.Fragment>
							<Button className="delete-button" loading={loading} onClick={() => props.deleteCourse(course.courseId)}>
								Delete course
							</Button>
							<Button 
								color="secondary" 
								loading={loading} 
								onClick={() => setState({ ...state, copyDialogOpen: true })}
								style={{ marginLeft: 10 }}	
							>
								Copy course
							</Button>
						</React.Fragment> 
					}
				</div>
			</Form>
			<UnitOfCompetencyPicker 
				open={state.unitPickerOpen}
				onClose={() => setState({ ...state, unitPickerOpen: false })}
				onOk={addCompetencies}
				allowMultiple
				enableFrameworkSelection
				organisationId={props.organisationId}
				mode="all"
			/>
			<UnitOfCompetencyViewer
				open={state.unitViewerOpen}
				unitId={state.unitId}
				elementId={state.elementId}
				selectedElements={state.selectedElements}
				onClose={() => setState({ ...state, unitViewerOpen: false })}
				onOk={setElements}
				allowMultiple
			/>
			<CopyCourseDialog 
				title={`${course.title} - Copy`}
				open={state.copyDialogOpen} 
				copyCourse={(title, include) => {
					props.copyCourse(course.courseId, title, include);
					setState({ ...state, copyDialogOpen: false });
				}}
				onClose={() => setState({ ...state, copyDialogOpen: false })} 
			/>
		</Card>
    );
};

EditCourse.propTypes = { 
	course: PropTypes.object,
	saveCourse: PropTypes.func.isRequired,
	deleteCourse: PropTypes.func,
	copyCourse: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number,
	organisations: PropTypes.array.isRequired,
	canAdminCourses: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired
};

EditCourse.defaultProps = {
	course: {},
	deleteCourse: () => null,
	copyCourse: () => null,
	organisationId: null
};

export default withForm(form)(EditCourse);