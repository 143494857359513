import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import filter from 'lodash/filter';
import {
	pSBC
} from '../../utils/utils';

const TeamSkillsStrengthProfile = ({ teamId, setLoading }) => {
	const [data, setData] = React.useState([]);

	const loadData = () => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/team-skills-strengths?teamId=${teamId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (teamId) {
			loadData();
		}
	}, [teamId]);

	const itemsWithData = filter(data || [], d => d.unitCount || d.met);

	const metData = map(itemsWithData, d => ({
		name: `${d.specialisationGroup}:  ${d.met}/${d.unitCount} Achieved`,
		fullLabel: `${d.specialisationGroup}:  ${d.met}/${d.unitCount} Achieved`,
		value: d.met,
		met: d.met,
		unitCount: d.unitCount,
		itemStyle: {
			color: `#${d.specialisationGroupColour}`
		}
	}));
	const gapData = map(itemsWithData, d => ({
		name: `${d.specialisationGroup}:  ${d.unitCount - d.met}/${d.unitCount} Below Target or Unassessed`,
		fullLabel: `${d.specialisationGroup}:  ${d.unitCount - d.met}/${d.unitCount} Below Target or Unassessed`,
		value: d.unitCount - d.met,
		met: d.met,
		unitCount: d.unitCount,
		itemStyle: {
			color: pSBC(-0.4, '#' + d.specialisationGroupColour)
		}
	}));

	const xAxisData = map(itemsWithData, d => d.specialisationGroup);

	return (
		<Chart 
			info={{
				title: 'Team Skills Strength Profile',
				text: 'The Team Skills Strength Profile chart shows the total Competencies acress groups'	
			}}
			option={{
				tooltip: {
					trigger: 'item',
					formatter: params => {
						if (params.componentIndex === 0) {
							return `${params.data.name}: ${params.data.value}/${params.data.unitCount} Achieved`
						} else {
							return `${params.data.name}: ${params.data.value}/${params.data.unitCount} Below Target or Unassessed`
						}
					}
				},
				xAxis: {
					data: xAxisData.map((d, i) => {
						switch (i % 3) {
							case 0: return d;
							case 1: return `\n${d}`;
							case 2: return `\n\n${d}`;
							default: return d;
						}
					}),
					axisLabel: {
						interval: 0
					}
				},
				yAxis: {},
				series: [
					{
						name: 'meets',
						type: 'bar',
						stack: 'one',
						data: metData
					},
					{
						name: 'gap',
						type: 'bar',
						stack: 'one',
						data: gapData
					}
				]
			}}
			style={{ height: "100%" }} 
		/>
	);
};

TeamSkillsStrengthProfile.propTypes = {
	teamId: PropTypes.number,
	setLoading: PropTypes.func.isRequired
};

TeamSkillsStrengthProfile.defaultProps = {
	teamId: null
};

export default TeamSkillsStrengthProfile;
