import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const StripeWrapper = (props) => {
	const [stripe, setStripe] = React.useState(null);

	React.useEffect(() => {
		if (!stripe && props.stripePublicApiKey) {
			setStripe(loadStripe(props.stripePublicApiKey));
		}
	}, [props.stripePublicApiKey]);

	return (
		<Elements stripe={stripe}>
			{props.children}
		</Elements>
	);
};

StripeWrapper.propTypes = {
	children: PropTypes.object.isRequired,
	stripePublicApiKey: PropTypes.string
};

StripeWrapper.defaultProps = {
	stripePublicApiKey: null
};

const mapStateToProps = state => ({
	stripePublicApiKey: state.settings ? state.settings.stripePublicApiKey : null
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeWrapper);
