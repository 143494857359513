import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import TextField from '../widgets/TextField';
import TextEditor from '../widgets/TextEditor';
import AttachmentEditor from '../widgets/AttachmentEditor';
import forEach from 'lodash/forEach';
import DragList from '../widgets/DragList';
import { moveItem } from '../../utils/utils';

const linkTemplate = { label: "", to: "" };

const download = (studentId, attachment) =>
	window.open(`/Student/DownloadAttachment?studentId=${studentId}&attachmentId=${attachment.attachmentId}`, "_self");

const EditPortfolioItem = props => {
	const { item, updateItem } = props;

	const updateLink = (index, field, value) => {
		const linksCopy = [...item.links];
		linksCopy[index][field] = value;

		updateItem("links", linksCopy);
	}

	return (
		<Card>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField label="Title" value={item.title} onChange={e => updateItem("title", e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<TextEditor label="Description" value={item.description} onChange={value => updateItem("description", value)} />
				</Grid>
				<Grid item xs={12}>
					<DragList 
						label="Links"
						addItem={index => {
							const linksCopy = [...item.links];
							linksCopy.splice(index, 0, { ...linkTemplate });

							updateItem("links", linksCopy);
						}}
						removeItem={index => {
							const linksCopy = [...item.links];
							linksCopy.splice(index, 1);

							updateItem("links", linksCopy);
						}}
						moveItem={(from, to) => {
							const linksCopy = [...item.links];
							moveItem(linksCopy, from, to);

							updateItem("links", linksCopy);
						}}
					>
						{item.links.map((link, index) => 
							<Grid key={index} container spacing={3}>
								<Grid item xs={12} md={4}>
									<TextField label="Label" value={link.label} onChange={e => updateLink(index, "label", e.target.value)} />
								</Grid>
								<Grid item xs={12} md={8}>
									<TextField startAdornment="https://" label="Link" value={link.to} onChange={e => updateLink(index, "to", e.target.value)} />
								</Grid>
							</Grid>
						)}
					</DragList>
				</Grid>
				<Grid item xs={12}>
					<AttachmentEditor
						attachments={item.attachments}
						download={attachment => download(props.studentId, attachment)}
						add={files => {
							const newAttachments = [], newFormFiles = [];

							forEach(files, file => {
								newAttachments.push({
									fileName: file.name,
									name: file.name,
									sizeBytes: file.size,
									attachmentType: "document",
									_isNew: true
								});
					
								newFormFiles.push(file);
							});

							updateItem("attachments", [...item.attachments, ...newAttachments]);
							props.addFiles(newFormFiles);
						}}
						delete={fileIndex => {
							const copy = [...item.attachments];
							copy.splice(fileIndex, 1);

							updateItem("attachments", copy)
						}}
						update={(fileIndex, newValues) => {
							const copy = [...item.attachments];
							copy[fileIndex] = newValues;

							updateItem("attachments", copy);
						}}
					/>
				</Grid>
			</Grid>
			<Button error onClick={props.onDelete} style={{ marginTop: 20 }}>Delete Item</Button>
		</Card>
	);
};	

EditPortfolioItem.propTypes = { 
	item: PropTypes.object.isRequired,
	updateItem: PropTypes.func.isRequired,
	addFiles: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	studentId: PropTypes.number
};

EditPortfolioItem.defaultProps = {
	studentId: 0
};

export default EditPortfolioItem;