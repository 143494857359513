import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	profiles: [],
	profile: {},
	ratingLoading: false
};

const newBP = {
	about: "",
	accreditationId: "",
	accredited: false,
	caseStudies: "",
	categories: [],
	email: "",
	location: "",
	logo: "",
	organisationId: 0,
	phone: "",
	products: "",
	summary: "",
	tags: [],
	title: "",
	website: ""
};

const CLEAR_BUSINESS_PROFILES = "CLEAR_BUSINESS_PROFILES";
const GET_BUSINESS_PROFILES_REQUEST = "GET_BUSINESS_PROFILES_REQUEST";
const GET_BUSINESS_PROFILES_SUCCESS = "GET_BUSINESS_PROFILES_SUCCESS";
const GET_BUSINESS_PROFILES_FAILURE = "GET_BUSINESS_PROFILES_FAILURE";
const SEARCH_BUSINESS_PROFILES_REQUEST = "SEARCH_BUSINESS_PROFILES_REQUEST";
const SEARCH_BUSINESS_PROFILES_SUCCESS = "SEARCH_BUSINESS_PROFILES_SUCCESS";
const SEARCH_BUSINESS_PROFILES_FAILURE = "SEARCH_BUSINESS_PROFILES_FAILURE";
const GET_BUSINESS_PROFILE_REQUEST = "GET_BUSINESS_PROFILE_REQUEST";
const GET_BUSINESS_PROFILE_SUCCESS = "GET_BUSINESS_PROFILE_SUCCESS";
const GET_BUSINESS_PROFILE_FAILURE = "GET_BUSINESS_PROFILE_FAILURE";
const SAVE_BUSINESS_PROFILE_REQUEST = "SAVE_BUSINESS_PROFILE_REQUEST";
const SAVE_BUSINESS_PROFILE_SUCCESS = "SAVE_BUSINESS_PROFILE_SUCCESS";
const SAVE_BUSINESS_PROFILE_FAILURE = "SAVE_BUSINESS_PROFILE_FAILURE";
const DELETE_BUSINESS_PROFILE_REQUEST = "DELETE_BUSINESS_PROFILE_REQUEST";
const DELETE_BUSINESS_PROFILE_SUCCESS = "DELETE_BUSINESS_PROFILE_SUCCESS";
const DELETE_BUSINESS_PROFILE_FAILURE = "DELETE_BUSINESS_PROFILE_FAILURE";
const RATE_BUSINESS_PROFILE_REQUEST = "RATE_BUSINESS_PROFILE_REQUEST";
const RATE_BUSINESS_PROFILE_SUCCESS = "RATE_BUSINESS_PROFILE_SUCCESS";
const RATE_BUSINESS_PROFILE_FAILURE = "RATE_BUSINESS_PROFILE_FAILURE";

export const isLoading = createLoadingSelector(["GET_BUSINESS_PROFILES", "SEARCH_BUSINESS_PROFILES", "GET_BUSINESS_PROFILE"]);

export const getBusinessProfiles = () =>
	createFetchAction({
		url: "/api/business-profiles",
		startAction: GET_BUSINESS_PROFILES_REQUEST,
		onError: error => [getBusinessProfilesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getBusinessProfilesSuccess(data)
	});

export const getBusinessProfilesSuccess = data => ({ type: GET_BUSINESS_PROFILES_SUCCESS, payload: { data } });
export const getBusinessProfilesFailure = error => ({ type: GET_BUSINESS_PROFILES_FAILURE, payload: { error } });

export const searchBusinessProfiles = args => 
	createFetchAction({
		url: `/api/business-profiles/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_BUSINESS_PROFILES_REQUEST,
		onError: error => [searchBusinessProfilesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchBusinessProfilesSuccess(data)
	});

export const searchBusinessProfilesSuccess = data => ({ type: SEARCH_BUSINESS_PROFILES_SUCCESS, payload: { data } });
export const searchBusinessProfilesFailure = error => ({ type: SEARCH_BUSINESS_PROFILES_FAILURE, payload: { error } });

export const getBusinessProfile = profileId =>
	createFetchAction({
		url: profileId ? `/api/business-profiles/${profileId}` : '/api/business-profiles/own',
		startAction: GET_BUSINESS_PROFILE_REQUEST,
		onError: error => [getBusinessProfileFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getBusinessProfileSuccess(data === '' ? { ...newBP } : data)
	});

export const getBusinessProfileSuccess = data => ({ type: GET_BUSINESS_PROFILE_SUCCESS, payload: { data } });
export const getBusinessProfileFailure = error => ({ type: GET_BUSINESS_PROFILE_FAILURE, payload: { error } });

export const saveBusinessProfile = (profile, logoFile) => {
	const formData = new FormData();
	formData.append("BusinessProfile", encodeURIComponent(JSON.stringify(profile)));

	if (logoFile) formData.append("LogoFile", logoFile);

	return createFormPostAction({
		url: "/api/business-profiles",
		data: formData,
		startAction: SAVE_BUSINESS_PROFILE_REQUEST,
		onError: error => [saveBusinessProfileFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveBusinessProfileSuccess(data), showSuccessNotification(data.message), changeLocation(`/business-profiles/${data.object.businessProfileId}`)];
			} else {
				return [saveBusinessProfileSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveBusinessProfileSuccess = data => ({ type: SAVE_BUSINESS_PROFILE_SUCCESS, data });
export const saveBusinessProfileFailure = error => ({ type: SAVE_BUSINESS_PROFILE_FAILURE, error });

export const deleteBusinessProfile = profileId =>
	createPostAction({
		url: `/api/business-profiles/${profileId}/delete`,
		startAction: DELETE_BUSINESS_PROFILE_REQUEST,
		onError: error => [deleteBusinessProfileFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteBusinessProfileSuccess(data), showSuccessNotification(data.message), changeLocation("/business-profiles")];
			} else {
				return [deleteBusinessProfileSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteBusinessProfileSuccess = data => ({ type: DELETE_BUSINESS_PROFILE_SUCCESS, data });
export const deleteBusinessProfileFailure = error => ({ type: DELETE_BUSINESS_PROFILE_FAILURE, error });

export const rateBusinessProfile = (businessProfileId, rating) => {
	return createPostAction({
		url: "/api/business-profiles/rate",
		data: {
			businessProfileId: businessProfileId,
			rating: rating
		},
		startAction: RATE_BUSINESS_PROFILE_REQUEST,
		onError: error => [rateBusinessProfileFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [rateBusinessProfileSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [rateBusinessProfileSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};
export const rateBusinessProfileSuccess = data => ({ type: RATE_BUSINESS_PROFILE_SUCCESS, data });
export const rateBusinessProfileFailure = error => ({ type: RATE_BUSINESS_PROFILE_FAILURE, error });

const sanitizeProfile = profile => ({
	...profile,
	research: DOMPurify.sanitize(profile.research),
	institutions: DOMPurify.sanitize(profile.institutions),
	caseStudies: DOMPurify.sanitize(profile.caseStudies)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_BUSINESS_PROFILES:
			return { ...state, profiles: [] };
		case GET_BUSINESS_PROFILES_REQUEST:
			return { ...state, profiles: [], isLoading: true };
		case SEARCH_BUSINESS_PROFILES_REQUEST:
			return { ...state, isLoading: true };
		case GET_BUSINESS_PROFILES_SUCCESS:
		case SEARCH_BUSINESS_PROFILES_SUCCESS:
			return { ...state, profiles: map(action.payload.data, sanitizeProfile), isLoading: false };
		case GET_BUSINESS_PROFILE_REQUEST:
			return { ...state, profile: {}, isLoading: true };
		case GET_BUSINESS_PROFILE_SUCCESS:
			return { ...state, profile: sanitizeProfile(action.payload.data), isLoading: false };
		case SAVE_BUSINESS_PROFILE_REQUEST:
		case DELETE_BUSINESS_PROFILE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_BUSINESS_PROFILE_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { profile: sanitizeProfile(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_BUSINESS_PROFILE_SUCCESS:
			return { ...state, profiles: state.profiles.filter(p => p.businessProfileId !== action.data.objectId) };
		case RATE_BUSINESS_PROFILE_REQUEST:
			return { ...state, ratingLoading: true };
		case RATE_BUSINESS_PROFILE_FAILURE:
			return { ...state, ratingLoading: false };
		case RATE_BUSINESS_PROFILE_SUCCESS:
			return {
				...state,
				profile: {
					...state.profile,
					rating: action.data.object.rating
				},
				profiles: map(state.profiles, p => ({
					...p,
					rating: p.businessProfileId === action.data.object.businessProfileId ? action.data.object.rating : p.rating
				})),
				ratingLoading: false
			};
		default:
			return state;
	}
};