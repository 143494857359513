import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	FormHelperText, 
	IconButton,
	FormControl,
	OutlinedInput,
	InputAdornment
} from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const FileUploader = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("file-uploader-"));

	let fileName = "";
	let extension = "";
	if (props.value) {
		const dotIndex = props.value.lastIndexOf('.');
		if (dotIndex < 0) {
			fileName = props.value;
			extension = "";
		} else {
			fileName = props.value.substring(0, dotIndex);
			extension = props.value.substring(dotIndex);
		}
	}

	return (
        <div style={props.style}>
			{props.label}{props.required && <span style={{ color: props.errorColour }}> *</span>}
			<input id={id} type="file" style={{ display: "none" }} onChange={props.onChange} accept={props.accept} />
			<label htmlFor={id}>
				<IconButton
                    component="span"
                    aria-label="Upload file"
                    style={{ margin: "0 5px" }}
                    size="large">
					<InsertDriveFileIcon />
				</IconButton>
				{!props.canEditName && props.value &&
					<span>{props.value.replace(/^.*[\\/]/, "")}</span>
				}
				{!props.value && <em>{props.placeholder}</em>}
			</label>
			{props.file && props.canEditName &&
				<FormControl variant="outlined">
					<OutlinedInput
						value={fileName}
						onChange={e => props.onChangeName(e.target.value + extension)}
						endAdornment={<InputAdornment position="end">{extension}</InputAdornment>}
						labelWidth={0}
					/>
				</FormControl>
			}
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
		</div>
    );
});

FileUploader.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.object,
	helpText: PropTypes.node,
	onChange: PropTypes.func.isRequired,
	onChangeName: PropTypes.func,
	accept: PropTypes.string,
	placeholder: PropTypes.string,
	canEditName: PropTypes.bool,
	required: PropTypes.bool,
	file: PropTypes.object,
	errorColour: PropTypes.string.isRequired
};

FileUploader.defaultProps = {
	label: "",
	value: [],
	id: "",
	style: {},
	helpText: "",
	accept: "",
	placeholder: "Upload file",
	required: false,
	canEditName: false,
	onChangeName: () => {},
	file: null
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);

	