import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import TermsDialog from '../modals/TermsDialog';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Button from '../widgets/Button';
import some from 'lodash/some';

const form = {
	initValues: props => ({ ...props.problem, _isNew: props.isNew }),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			placeholder: props => props.titleHelpText
		},
		{
			name: "description",
			label: "Description",
			placeholder: props => props.descriptionHelpText,
			type: "textEditor",
			required: true
		},
		{
			name: "status",
			type: "select",
			label: "Status",
			items: ["new", "pending", "approved", "cancelled", "closed"],
			required: props => !props.isNew,
		},
		{
			name: "solution",
			label: "Solution",
			placeholder: props => props.solutionHelptext,
			type: "textEditor"
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor",
			placeholder: ({ plural }) => `Please add key word ‘Tags’ as they provide a useful way to group related ${plural} together and to quickly tell users what it’s about.`
		},
		{
			name: "channelId",
			label: "Channel",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: channel => ({ label: channel.name, value: channel.channelId })
			}
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Problem/DownloadAttachment?problemId=${attachment.problemId}&attachmentId=${attachment.attachmentId}`, "_self")
		}
	]
};

const EditProblem = props => {
	const [termsOpen, setTermsOpen] = React.useState(false);
	const { problem, loading, fields, single, isNew } = props;

	return (
		<React.Fragment>
			<Card title={isNew ? "Submission Form" : `Edit ${single}`} loading={loading} className="edit-problem" subtitle={
				<React.Fragment>
					<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.headerContent }} />
					<p>Please abide by the <a style={{ cursor: "pointer" }} onClick={() => setTermsOpen(true)}>Terms and conditions</a></p>
				</React.Fragment>
			}>
				<Form onSubmit={() => {
					if (props.validateFields()) {
						props.onSave(props.values, props.formFiles.attachments);
					}
				}}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>{fields.title()}</Grid>
						<Grid item xs={12} sm={6}>{fields.channelId()}</Grid>
						<Grid item xs={12}>{fields.description()}</Grid>
						{!isNew && <Grid item xs={12}>{fields.status()}</Grid>}
						<Grid item xs={12}>{fields.solution()}</Grid>
						<Grid item xs={12}>{fields.attachments()}</Grid>
						<Grid item xs={12}>{fields.tags()}</Grid>
					</Grid>
					<div className="form-buttons">
						<Button type="submit" warning={props.dirty} error={some(props.error)} loading={loading}>{isNew ? "Submit" : "Update"} problem</Button>
						{!isNew && 
							<Button className="delete-button" loading={loading} onClick={() => props.onDelete(problem.problemId)}>
								Delete problem
							</Button>
						}
					</div>
				</Form>
			</Card>
			<TermsDialog open={termsOpen} onClose={() => setTermsOpen(false)} />
		</React.Fragment>
	);
};

EditProblem.propTypes = {
	problem: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	headerContent: PropTypes.string.isRequired,
	titleHelpText: PropTypes.string.isRequired,
	descriptionHelpText: PropTypes.string.isRequired,
	solutionHelptext: PropTypes.string.isRequired,
	isNew: PropTypes.bool
};

EditProblem.defaultProps = {
	problem: {},
	onDelete: () => null,
	isNew: false
};

export default withForm(form)(EditProblem);