import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSettings } from '../../store/settings';
// import ButtonLink from '../widgets/ButtonLink';
import { styled } from '@mui/material/styles';
import {
    ArrowBack as BackIcon
} from '@mui/icons-material';
import {
    Fab
} from '@mui/material';
import { Link } from 'react-router-dom';
import SellerImage from '../images/login/LD provider Panel.svg';
import BuyerImage from '../images/login/TD member Panel.svg';

// const Button = styled(ButtonLink)({
// 	backgroundColor: 'rgba(255,255,255,0.7)',
// 	'&:hover': {
// 		backgroundColor: 'rgba(255,255,255,0.9)',
// 	}
// });

const FloatingActionButton = styled(Fab)({
	color: 'rgb(0,0,0)',
	'&:hover': {
		color: '#156184',
	}
});

const RegistrationPath = props => {
	React.useEffect(() => {
		// Logout user if timed out
		if (props.timeout) props.logout();

		if (!props.isLoggedIn || props.timeout) {
			props.fetchSettings([
				"loginBackground",
				"buyerImage",
				"sellerImage"
			]);
		}
	}, []);

	// let backgroundImage = null;
	// if (!props.loading) {
	// 	if (props.centerContent) {
	// 		backgroundImage = props.backgroundImage || props.loginBackground || "/images/aspire-mountain.png";
	// 	} else {
	// 		backgroundImage = props.backgroundImage || props.loginBackground || "/images/login_background.jpg";
	// 	}
	// }

	
	let buyerImage = BuyerImage;
	let sellerImage = SellerImage;
	if (props.buyerImage) buyerImage = props.buyerImage;
	if (props.sellerImage) sellerImage = props.sellerImage;

	return (
		<main role="main" className="registration-path">
			<div 
				className="registration-path__background" 
				style={{ 
					// backgroundImage: `url(${backgroundImage})`,
					width: '100%',
					height: '100%',
					position: 'fixed',
					top: 0,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					background: '#F3F8FB'
				}} 
			/>
			<FloatingActionButton 
				aria-label="Back" 
				to="/login"
				style={{
					position: 'absolute',
					top: '10px',
					left: '10px',
					zIndex: 9
				}}
				component={Link}
			>
				<BackIcon />
			</FloatingActionButton>
			<div 
				className="registration-path-options"
				style={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<div className="leftPath">
					<Link to="/register?path=buyer">
						<img 
							src={buyerImage} 
							alt="" 
							style={{ height: '400px', margin: '0 40px' }} 
						/>
					</Link>
					{/* <Button
						to="/register?path=buyer"
						variant="outlined"
						style={{
							height: '150px',
							width: '150px',
							marginRight: '20px'
						}}
					>Buyer</Button> */}
				</div>
				<div className="rightPath">
					<Link to="/register?path=seller">
						<img 
							src={sellerImage} 
							alt="" 
							style={{ height: '400px', margin: '0 40px' }} 
						/>
					</Link>
					{/* <Button
						to="/register?path=seller"
						variant="outlined"
						style={{
							height: '150px',
							width: '150px',
							marginLeft: '20px'
						}}
					>Seller</Button> */}
				</div>
			</div>
		</main>
	);
};

RegistrationPath.propTypes = {
	isLoggedIn: PropTypes.bool,
	loginBackground: PropTypes.string,
	backgroundImage: PropTypes.string,
	buyerImage: PropTypes.string,
	sellerImage: PropTypes.string,
	timeout: PropTypes.bool.isRequired
};

RegistrationPath.defaultProps = {
	isLoggedIn: false,
	loginBackground: null,
	backgroundImage: null,
	buyerImage: null,
	sellerImage: null
};

const mapStateToProps = state => ({
	isLoggedIn: state.context.isLoggedIn,
	loginBackground: state.settings.loginBackground,
	backgroundImage: state.settings.backgroundImage,
	buyerImage: state.settings.buyerImage,
	sellerImage: state.settings.sellerImage
});

const mapDispatchToProps = dispatch => ({
	fetchSettings: names => dispatch(fetchSettings(names))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPath);