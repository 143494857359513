import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFeature, saveFeature, deleteFeature, isLoading } from '../../store/features';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditFeature from './EditFeature';

const EditFeatureController = props => {
	React.useEffect(() => props.getFeature(props.match.params.featureId), [props.match.params.featureId]);

	return <PageLayout title="Edit Feature" pageContent={
		<EditFeature
			feature={props.feature}
			saveFeature={props.saveFeature}
			deleteFeature={props.deleteFeature}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Features", path: "/features" }, "Edit Feature"]} />;
};

EditFeatureController.propTypes = {
	feature: PropTypes.object.isRequired,
	saveFeature: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	feature: state.features.feature,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getFeature: featureId => dispatch(getFeature(featureId)),
	saveFeature: (feature, files) => dispatch(saveFeature(feature, files)),
	deleteFeature: featureId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this feature?",
		onOk: () => dispatch(deleteFeature(featureId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFeatureController);