import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Grow, Paper, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { hasFunctions } from '../../store/security';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import CollapseIcon from '@mui/icons-material/ExpandLess';

const styles = (theme) => ({
	cardTitle: {
		'& .title': {
			'& h2': {
				color: theme.palette.primary.main
			}
		}
	}
});

const Card = React.memo(props => {
	const hasAccess = props.hasFunctions(props.functions);
	const [open, setOpen] = React.useState(true);
	const [expanded, setExpanded] = React.useState(true);
	const { header, title, subtitle, titleRightContent, loading } = props,
		loadingIcon = <CircularProgress size={24} style={{ marginLeft: 15 }} />;
	
	const handleClose = () => { 
		setOpen(false); 
		props.onClose();
	};

	let minHeight = '130px';
	if (!expanded) minHeight = '40px';

	const card = (
		<Paper 
			id={props.id}
			className={classNames("card", props.className)} 
			elevation={1} 
			style={{ 
				alignSelf: props.shrink && "flex-start", 
				paddingTop: props.ribbon ? 35 : 0,  
				minHeight: hasAccess ? minHeight : undefined,
				...props.style 
			}}
			onClick={props.onClick}
			aria-owns={props.ariaOwns}
			aria-haspopup={props.areaHaspopup}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			{props.ribbon && <div className={`ribbon ribbon-bookmark ribbon-${props.ribbonStatus}`} onClick={props.ribbonOnClick} style={props.ribbonStyle}>{props.ribbon}</div>}
			{header &&
				<div className="card-header" style={props.headerStyle}>
					{header}
					{loading && loadingIcon}
					{props.onClose &&
						<div className="ml-auto">
							<IconButton
                                style={{ height: 24, width: 24 }}
                                onClick={handleClose}
                                aria-label="Close"
                                size="large">
								<CloseIcon style={{ fontSize: 18 }} />
							</IconButton>
						</div>
					}
				</div>
			}
			{props.expandable && 
				<div className="ml-auto" style={{ position: 'absolute', right: '5px', top: '5px', zIndex: 9 }}>
					<IconButton style={{ height: 24, width: 24 }} onClick={() => { setExpanded(!expanded)}} aria-label="Close">
						{expanded ? <CollapseIcon /> : <ExpandIcon />}
					</IconButton>
				</div>
			}
			{expanded &&
				<div className="card-body" style={props.bodyStyle}>
					{(title || titleRightContent) &&
						<div className={`card-title ${props.classes.cardTitle}`}>
							<div className="title">
								{title &&
									<React.Fragment>
										<h2>{title}{loading && loadingIcon}</h2>
										{subtitle && <span className="subtitle h5">{subtitle}</span>}
									</React.Fragment>
								}
							</div>
							{hasAccess && titleRightContent}
						</div>
					}
					{hasAccess ? props.children : <span style={{ textAlign: 'center' }}>You do not have access</span>}
				</div>
			}
			{!expanded && props.collapsedContent && 
				<div className="card-body" style={props.bodyStyle}>
					{props.collapsedContent}
				</div>
			}
		</Paper>
	);
	
	return props.disableGrow ? card : <Grow in={open} unmountOnExit>{card}</Grow>;
});

Card.propTypes = {
	header: PropTypes.node,
	headerStyle: PropTypes.object,
	title: PropTypes.node,
	subtitle: PropTypes.node,
	titleRightContent: PropTypes.node,
	loading: PropTypes.bool,
	children: PropTypes.node.isRequired,
	onClose: PropTypes.func,
	bodyStyle: PropTypes.object,
	style: PropTypes.object,
	shrink: PropTypes.bool,
	onClick: PropTypes.func,
	disableGrow: PropTypes.bool,
	ribbon: PropTypes.node,
	ribbonStats: PropTypes.string,
	functions: PropTypes.array,
	ribbonOnClick: PropTypes.func,
	ribbonStyle: PropTypes.object,
	expandable: PropTypes.bool,
	collapsedContent: PropTypes.node
};

Card.defaultProps = {
	header: "",
	headerStyle: {},
	title: "",
	subtitle: "",
	titleRightContent: null,
	loading: false,
	onClose: undefined,
	bodyStyle: null,
	style: null,
	shrink: false,
	onClick: undefined,
	disableGrow: false,
	ribbon: "",
	ribbonStatus: "default",
	ribbonStats: "",
	functions: [],
	ribbonOnClick: undefined,
	ribbonStyle: null,
	expandable: false,
	collapsedContent: null
};

const mapStateToProps = state => ({
	hasFunctions: functions => hasFunctions(functions)(state)
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Card, styles));