import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import Card from '../widgets/Card';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import SearchField from '../widgets/SearchField';
import ButtonLink from '../widgets/ButtonLink';

const ProfileGroups = props => {
	const [search, setSearch] = React.useState("");

	return (
		<Card title="Search groups" loading={props.loading}>
			{/* Search groups */}
			<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Datatable */}
			<DataTable data={props.groups} search={search} enableExport tableFooter={<ButtonLink to="/admin/profile-groups/create">Create group</ButtonLink>}>
				<DataTableColumn name="profileGroupName" label="Group" renderer={g => !props.canEdit ? g.profileGroupName :
					<Link to={`/admin/profile-groups/${g.profileGroupId}`} style={{ color: props.primaryColour }}>
						{g.profileGroupName}
					</Link>
				} />
				<DataTableColumn name="description" label="Description" renderer={g => g.description || <em>There is no description provided...</em>} />
				<DataTableColumn 
					label="Organisations" 
					canFilter
					filterType="AutoComplete"
					loadItems={{
						route: "organisations", 
						mapItem: c => ({ label: c.name, value: c.organisationId })
					}} 
					filter={(row, f) => row.organisations.includes(f.label)}
					renderer={data => data.organisations.join(", ")}
				/>
			</DataTable>
		</Card>
	);
};

ProfileGroups.propTypes = {
	groups: PropTypes.arrayOf(PropTypes.object).isRequired,
	canEdit: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

export default ProfileGroups;