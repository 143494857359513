import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import Button from '../widgets/Button';
import Checkbox from '../widgets/Checkbox';
import { getUnitOfCompetency, isLoading } from '../../store/unitsOfCompetency';
import map from 'lodash/map';
import find from 'lodash/find';
import { get } from '../../utils/ajax';

const UnitOfCompetencyViewer = props => {
	const [ profileTarget, setProfileTarget] = React.useState(0);
	const [selectedElements, setSelected] = React.useState([]);
	const { unit, elementId } = props,
		element = (unit && elementId) ? find(unit.elements, e => e.unitElementId === elementId) : null;

	// Fetch details if loading new unit and set selected elements
	const fetchUnitDetails = () => {
		if (unit.unitOfCompetencyId !== props.unitId) {
			get({
				url: `/api/skills-tracker/profile-target?profileId=${props.skillsProfileId}&unitId=${props.unitId}`,
				onSuccess: data => {
					setProfileTarget(data);
				},
				onError: () => {
				}
			});
			props.getUnit(props.unitId);
		}

		setSelected(map(props.selectedElements, "elementId"));
	}

	// Return filtered array of unit elements to preserve order
	const updateElements = () => {
		props.onOk(map(unit.elements.filter(e2 => selectedElements.includes(e2.unitElementId)), e => ({
				competencyElementId: 0,
				competencyId: unit.competencyId,
				element: e.element,
				elementId: e.unitElementId,
				competent: profileTarget !== "" && e.order >= profileTarget,
				assessmentDate: new Date(),
				expiryDate: new Date()
		})));
	}

	// Add or remove elementId from selected elements
	const setElement = (elementId, checked) => {
		if (checked) {
			setSelected(props.allowMultiple ? [...selectedElements, elementId] : [elementId]);
		} else {
			setSelected(props.allowMultiple ? selectedElements.filter(id => id !== elementId) : []);
		}
	}

	let title = "", content = "";

	if (props.loading) { 
		content = <CircularProgress size={24} />;
	} else if (element) {
		title = element.element;
		content = map(element.items, (i, index) => <div key={index}>{i.performanceCriteriaName}</div>);
	} else if (unit) {
		title = unit.unitCode || "";
		content = (
			<React.Fragment>
				<h3>{unit.unitCode} {unit.unitTitle}</h3>
				{unit.unitDescriptionAndApplication && <p>{unit.unitDescriptionAndApplication}</p>}
				{unit.performanceEvidence && 
					<React.Fragment>
						<h3>Performance Evidence/Skill</h3>
						<p>{unit.performanceEvidence}</p>
					</React.Fragment>
				}
				{unit.knowledgeEvidence && 
					<React.Fragment>
						<h3>Knowledge Evidence</h3>
						<p>{unit.knowledgeEvidence}</p>
					</React.Fragment>
				}
				{unit.elements && unit.elements.length > 0 && <h3>Elements/Levels</h3>}
				{map(unit.elements, e =>
					<Checkbox
						key={e.unitElementId}
						label={<span className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />}
						disabled={!props.canEdit}
						checked={selectedElements.includes(e.unitElementId)}
						onChange={evt => setElement(e.unitElementId, evt.target.checked)}
						style={{ marginBottom: 5 }}
					/>
				)}
			</React.Fragment>
		);
	}

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth="md"
            TransitionProps={{
                onEnter: fetchUnitDetails
            }}>
			<DialogTitle id="dialog-title">{title}</DialogTitle>
			<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>{content}</DialogContent>
			<DialogActions>
				{!element && <Button onClick={updateElements}>Ok</Button>}
				<Button onClick={props.onClose} autoFocus>{element ? "Ok" : "Cancel"}</Button>
			</DialogActions>
		</Dialog>
    );
}

UnitOfCompetencyViewer.propTypes = {
	skillsProfileId: PropTypes.number,
	unitId: PropTypes.number.isRequired,
	unit: PropTypes.object.isRequired,
	elementId: PropTypes.number,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	selectedElements: PropTypes.array.isRequired,
	allowMultiple: PropTypes.bool,
	canEdit: PropTypes.bool
};

UnitOfCompetencyViewer.defaultProps = {
	skillsProfileId: 0,
	elementId: null,
	allowMultiple: false,
	canEdit: true
};

const mapStateToProps = state => ({
	unit: state.unitsOfCompetency.unitOfCompetency,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getUnit: unitId => dispatch(getUnitOfCompetency(unitId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitOfCompetencyViewer);