import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab as MuiTab } from '@mui/material';
import Tab from './Tab';
import Card from './Card';
import map from 'lodash/map';

const TabContainer = React.memo(props => {
	const [tab, setTab] = React.useState(0);
	const tabs = React.Children.toArray(props.children).filter(c => c.type === Tab && c.props.show).map(c => c.props);

	return tabs.length === 0 ? "There is nothing to display. Please contact your administrator..." : (
		<Card bodyStyle={{ padding: 0 }}>
			<Tabs value={tab} indicatorColor="primary" onChange={(e, tab) => setTab(tab)} variant="scrollable">
				{map(tabs, (t, index) => <MuiTab key={index} label={t.label} style={{ color: t.error && props.errorColour, fontWeight: 'bold' }} />)}
			</Tabs>
			<div style={{ padding: "1.5rem" }}>
				{props.renderOnLoad ? tabs[tab].children : map(tabs, (t, index) => <div key={index} hidden={index !== tab}>{t.children}</div>)}
			</div>
		</Card>
	);
});

TabContainer.propTypes = {
	children: PropTypes.arrayOf(Tab).isRequired,
	renderOnLoad: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

TabContainer.defaultProps = {
	renderOnLoad: false
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TabContainer);
