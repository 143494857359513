import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFeatures, deleteFeature, saveFeatureOrder, isLoading } from '../../store/features';
import { canSubmitFeatures, canEditFeature } from '../../store/security';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import Features from './Features';

const FeaturesController = props => {
	React.useEffect(props.getFeatures, []);

	return <PageLayout title="Features" pageContent={
		<Features 
			features={props.features} 
			loading={props.loading} 
			canSubmit={props.canSubmit} 
			canEdit={props.canEdit}
			saveOrder={props.saveOrder} 
		/> 
	} breadcrumbs={["Features"]} />;
};

FeaturesController.propTypes = { 
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	features: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	canSubmit: canSubmitFeatures(state),
	canEdit: canEditFeature(state),
	features: state.features.features,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getFeatures: () => dispatch(getFeatures()),
	deleteFeature: featureId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this Feature?",
		onOk: () => dispatch(deleteFeature(featureId))
	})),
	saveOrder: order => dispatch(saveFeatureOrder(order))
});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesController);
