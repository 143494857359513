import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canEditMentor } from '../../store/security';
import { getMentor, saveMentor, deleteMentor, isLoading } from '../../store/mentors';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditMentor from './EditMentor';

const EditMentorController = props => {
	const { mentorId } = props.match.params;

	React.useEffect(() => props.getMentor(mentorId), [mentorId]);

	React.useEffect(() => {
		if (props.mentor.mentorId === mentorId && !props.canEdit) props.revokeAccess();
	}, [props.mentor.mentorId]);

	return <PageLayout title="Edit mentor" pageContent={
		<EditMentor
			mentor={props.mentor}
			saveMentor={props.saveMentor}
			deleteMentor={props.deleteMentor}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Mentors", path: "/mentors" }, "Edit mentor"]} />;
};

EditMentorController.propTypes = {
	mentor: PropTypes.object.isRequired,
	getMentor: PropTypes.func.isRequired,
	saveMentor: PropTypes.func.isRequired,
	deleteMentor: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	mentor: state.mentors.mentor,
	loading: isLoading(state),
	canEdit: canEditMentor(state)
});

const mapDispatchToProps = dispatch => ({
	getMentor: mentorId => dispatch(getMentor(mentorId)),
	saveMentor: (mentor, imageFile) => dispatch(saveMentor(mentor, imageFile)),
	deleteMentor: mentorId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this mentor?",
		onOk: () => dispatch(deleteMentor(mentorId))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMentorController);
