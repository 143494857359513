import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialUICheckbox from '@mui/material/Checkbox';
import { FormControlLabel, FormHelperText, FormControl } from '@mui/material';
import uniqueId from 'lodash/uniqueId';

const Checkbox = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("checkbox-"));
	const control = <MaterialUICheckbox id={id} checked={props.checked} onChange={props.onChange} />;

	return (
		<FormControl fullWidth={props.fullWidth} error={props.error} disabled={props.disabled} style={props.style} className="checkbox-control">
			{!props.label ? control :
				<FormControlLabel control={control} label={
					<React.Fragment>
						{props.label}{props.required && <span style={{ color: props.errorColour }}> *</span>}
					</React.Fragment>
				} labelPlacement={props.labelPlacement} />
			}
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
		</FormControl>
	);
});

Checkbox.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.node,
	checked: PropTypes.bool.isRequired,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	helpText: PropTypes.node,
	fullWidth: PropTypes.bool,
	labelPlacement: PropTypes.string,
	errorColour: PropTypes.string.isRequired
};

Checkbox.defaultProps = {
	id: "",
	name: "",
	label: "",
	required: false,
	onChange: () => {},
	error: false,
	disabled: false,
	helpText: "",
	fullWidth: true,
	labelPlacement: undefined
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
