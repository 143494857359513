import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canEditExpert } from '../../store/security';
import { getExpert, saveExpert, deleteExpert, isLoading } from '../../store/experts';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditExpert from './EditExpert';

const EditExpertController = props => {
	const { expertId } = props.match.params;

	React.useEffect(() => props.getExpert(expertId), [expertId]);

	React.useEffect(() => {
		if (props.expert.expertId === expertId && !props.canEdit) props.revokeAccess();
	}, [props.expert.expertId]);

	return <PageLayout title="Edit expert" pageContent={
		<EditExpert
			expert={props.expert}
			saveExpert={props.saveExpert}
			deleteExpert={props.deleteExpert}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Experts", path: "/experts" }, "Edit expert"]} />;
};

EditExpertController.propTypes = {
	expert: PropTypes.object.isRequired,
	getExpert: PropTypes.func.isRequired,
	saveExpert: PropTypes.func.isRequired,
	deleteExpert: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	expert: state.experts.expert,
	loading: isLoading(state),
	canEdit: canEditExpert(state)
});

const mapDispatchToProps = dispatch => ({
	getExpert: expertId => dispatch(getExpert(expertId)),
	saveExpert: (expert, imageFile) => dispatch(saveExpert(expert, imageFile)),
	deleteExpert: expertId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this expert?",
		onOk: () => dispatch(deleteExpert(expertId))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditExpertController);
