import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, post } from '../../utils/ajax';
import {
	useStripe
} from '@stripe/react-stripe-js';
import { changeLocation } from '../../store/location';
import { 
	showSuccessNotification, 
	showErrorNotification 
} from '../../store/notifications';

const UpgradeController = props => {
	const stripe = useStripe();
	const [loading, setLoading] = React.useState(false);
	const [page, setPage] = React.useState(null);

	React.useEffect(() => {
		setLoading(false);
		get({
			url: `/api/upgrade-page/${props.page}`,
			onSuccess: (data) => {
				setLoading(false);
				setPage(data);
			},
			onError: (error) => {
				setLoading(false);
			}
		});
	}, []);

	const subscribe = () => {
		const product = page.product;
		setLoading(true);
		if (product.stripePriceId) {
			post({
				url: '/api/stripe/create-session',
				data: {
					priceId: product.stripePriceId
				},
				onSuccess: (data) => {
					setLoading(false);
					stripe.redirectToCheckout({
						// Make the id field from the Checkout Session creation API response
						// available to this file, so you can provide it as parameter here
						// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
						sessionId: data
					}).then(function (result) {
					});
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		} else {
			// No stripe product/price assigned to product so create subscription and return
			post({
				url: '/api/subscription/subscribe',
				data: {
					productId: product.productId
				},
				onSuccess: (data) => {
					setLoading(false);
					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToSubscriptionComplete();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		}
	};

	const Content = props.content;

	return (
		<Content
			loading={loading}
			page={page}
			subscribe={subscribe}
		/>
	);
};

UpgradeController.propTypes = { 
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	goToSubscriptionComplete: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	goToSubscriptionComplete: () => dispatch(changeLocation('subscription-complete'))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeController);
