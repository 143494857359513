import { createFetchAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';

const initialState = {
	stats: {}, 
	leaderboard: [],
	popularTags: []
};

const CLEAR_DASHBOARD_STATS = 'CLEAR_DASHBOARD_STATS';
const GET_DASHBOARD_STATS_REQUEST = 'GET_DASHBOARD_STATS_REQUEST';
const GET_DASHBOARD_STATS_SUCCESS = 'GET_DASHBOARD_STATS_SUCCESS';
const GET_DASHBOARD_STATS_FAILURE = 'GET_DASHBOARD_STATS_FAILURE';
const CLEAR_CONTRIBUTOR_LEADERBOARD = 'CLEAR_CONTRIBUTOR_LEADERBOARD';
const GET_CONTRIBUTOR_LEADERBOARD_REQUEST = 'GET_CONTRIBUTOR_LEADERBOARD_REQUEST';
const GET_CONTRIBUTOR_LEADERBOARD_SUCCESS = 'GET_CONTRIBUTOR_LEADERBOARD_SUCCESS';
const GET_CONTRIBUTOR_LEADERBOARD_FAILURE = 'GET_CONTRIBUTOR_LEADERBOARD_FAILURE';


/////////////////////////////////
///   DASHBOARD STATS         ///
/////////////////////////////////
export const isStatsLoading = createLoadingSelector([ 'GET_DASHBOARD_STATS' ]);

export const clearStats = () => ({ type: CLEAR_DASHBOARD_STATS });

export const getStats = () => (
	createFetchAction({
		url: '/api/dashboard/stats',
		startAction: GET_DASHBOARD_STATS_REQUEST,
		onError: (error) => getStatsFailure(error),
		onSuccess: (data) => getStatsSuccess(data)
	})
);

export const getStatsSuccess = (data) => ({ type: GET_DASHBOARD_STATS_SUCCESS, payload: { data } });
export const getStatsFailure = (error) => ({ type: GET_DASHBOARD_STATS_FAILURE, payload: { error } });

/////////////////////////////////
///   CONTRIBUTOR LEADERBOARD ///
/////////////////////////////////
export const isContributorLeaderboardLoading = createLoadingSelector([ 'GET_CONTRIBUTOR_LEADERBOARD' ]);

export const clearContributorLeaderboard = () => ({ type: CLEAR_CONTRIBUTOR_LEADERBOARD });
export const getContributorLeaderboard = (days) => (
	createFetchAction({
		url: `/api/dashboard/contributor-leaderboard?range=${days}`,
		startAction: GET_CONTRIBUTOR_LEADERBOARD_REQUEST,
		onError: (error) => [getContributorLeaderboardFailure(error)],
		onSuccess: (data) => getContributorLeaderboardSuccess(data)
	})
);

export const getContributorLeaderboardSuccess = (data) => ({ type: GET_CONTRIBUTOR_LEADERBOARD_SUCCESS, payload: { data } });
export const getContributorLeaderboardFailure = (error) => ({ type: GET_CONTRIBUTOR_LEADERBOARD_FAILURE, payload: { error } });


/////////////////////////////////
///   POPULAR TAGS            ///
/////////////////////////////////
const CLEAR_POPULAR_TAGS = 'CLEAR_POPULAR_TAGS';
const GET_POPULAR_TAGS_REQUEST = 'GET_POPULAR_TAGS_REQUEST';
const GET_POPULAR_TAGS_SUCCESS = 'GET_POPULAR_TAGS_SUCCESS';
const GET_POPULAR_TAGS_FAILURE = 'GET_POPULAR_TAGS_FAILURE';

export const isPopularTagsLoading = createLoadingSelector([ 'GET_POPULAR_TAGS' ]);
export const clearPopularTags = () => ({ type: CLEAR_POPULAR_TAGS });
export const getPopularTags = () => (
	createFetchAction({
		url: '/api/dashboard/popular-tags',
		startAction: GET_POPULAR_TAGS_REQUEST,
		onError: (error) => [getPopularTagsFailure(error)],
		onSuccess: (data) => getPopularTagsSuccess(data)
	})
);

export const getPopularTagsSuccess = (data) => ({ type: GET_POPULAR_TAGS_SUCCESS, payload: { data } });
export const getPopularTagsFailure = (error) => ({ type: GET_POPULAR_TAGS_FAILURE, payload: { error } });


/////////////////////////////////
///   APPLICATIONS CHART DATA ///
/////////////////////////////////
const CLEAR_APPLICATIONS_CHART_DATA = 'CLEAR_APPLICATIONS_CHART_DATA';
const GET_APPLICATIONS_CHART_DATA_REQUEST = 'GET_APPLICATIONS_CHART_DATA_REQUEST';
const GET_APPLICATIONS_CHART_DATA_SUCCESS = 'GET_APPLICATIONS_CHART_DATA_SUCCESS';
const GET_APPLICATIONS_CHART_DATA_FAILURE = 'GET_APPLICATIONS_CHART_DATA_FAILURE';

export const isApplicationsChartDataLoading = createLoadingSelector([ 'GET_APPLICATIONS_CHART_DATA' ]);
export const clearApplicationsChartData = () => ({ type: CLEAR_APPLICATIONS_CHART_DATA });
export const getApplicationsChartData = () => (
	createFetchAction({
		url: '/api/dashboard/application-count-last-12-months',
		startAction: GET_APPLICATIONS_CHART_DATA_REQUEST,
		onError: (error) => [getApplicationsChartDataFailure(error)],
		onSuccess: (data) => getApplicationsChartDataSuccess(data)
	})
);

export const getApplicationsChartDataSuccess = (data) => ({ type: GET_APPLICATIONS_CHART_DATA_SUCCESS, payload: { data } });
export const getApplicationsChartDataFailure = (error) => ({ type: GET_APPLICATIONS_CHART_DATA_FAILURE, payload: { error } });


////////////////////////////////////
///   OFFERS CHART DATA   ///
////////////////////////////////////

const CLEAR_OFFERS_CHART_DATA = 'CLEAR_OFFERS_CHART_DATA';
const GET_OFFERS_CHART_DATA_REQUEST = 'GET_OFFERS_CHART_DATA_REQUEST';
const GET_OFFERS_CHART_DATA_SUCCESS = 'GET_OFFERS_CHART_DATA_SUCCESS';
const GET_OFFERS_CHART_DATA_FAILURE = 'GET_OFFERS_CHART_DATA_FAILURE';

export const isOffersChartDataLoading = createLoadingSelector([ 'GET_OFFERS_CHART_DATA' ]);
export const clearOffersChartData = () => ({ type: CLEAR_OFFERS_CHART_DATA });
export const getOffersChartData = () => (
	createFetchAction({
		url: '/api/dashboard/offer-count-last-12-months',
		startAction: GET_OFFERS_CHART_DATA_REQUEST,
		onError: (error) => [getOffersChartDataFailure(error)],
		onSuccess: (data) => getOffersChartDataSuccess(data)
	})
);

export const getOffersChartDataSuccess = (data) => ({ type: GET_OFFERS_CHART_DATA_SUCCESS, payload: { data } });
export const getOffersChartDataFailure = (error) => ({ type: GET_OFFERS_CHART_DATA_FAILURE, payload: { error } });

/////////////////////////////////
///    PRODUCTS CHART DATA    ///
/////////////////////////////////

const CLEAR_PRODUCTS_CHART_DATA = 'CLEAR_PRODUCTS_CHART_DATA';
const GET_PRODUCTS_CHART_DATA_REQUEST = 'GET_PRODUCTS_CHART_DATA_REQUEST';
const GET_PRODUCTS_CHART_DATA_SUCCESS = 'GET_PRODUCTS_CHART_DATA_SUCCESS';
const GET_PRODUCTS_CHART_DATA_FAILURE = 'GET_PRODUCTS_CHART_DATA_FAILURE';

export const isProductsChartDataLoading = createLoadingSelector([ 'GET_PRODUCTS_CHART_DATA' ]);
export const clearProductsChartData = () => ({ type: CLEAR_PRODUCTS_CHART_DATA });
export const getProductsChartData = () => (
	createFetchAction({
		url: '/api/dashboard/product-count-last-12-months',
		startAction: GET_PRODUCTS_CHART_DATA_REQUEST,
		onError: (error) => [getProductsChartDataFailure(error)],
		onSuccess: (data) => getProductsChartDataSuccess(data)
	})
);

export const getProductsChartDataSuccess = (data) => ({ type: GET_PRODUCTS_CHART_DATA_SUCCESS, payload: { data } });
export const getProductsChartDataFailure = (error) => ({ type: GET_PRODUCTS_CHART_DATA_FAILURE, payload: { error } });

/////////////////////////////////
///   REDUCERS                ///
/////////////////////////////////
export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_DASHBOARD_STATS:
			return {
				...state,
				stats: {}
			};
		case GET_DASHBOARD_STATS_REQUEST:
			return {
				...state,
				stats: {}
			};
		case GET_DASHBOARD_STATS_SUCCESS:
			return {
				...state,
				isLoading: false,
				stats: action.payload.data
			};
		case GET_DASHBOARD_STATS_FAILURE:
			return {
				...state,
				isLoading: false,
				stats: {}
			};
		case CLEAR_CONTRIBUTOR_LEADERBOARD:
			return {
				...state,	
				leaderboard: []
			};
		case GET_CONTRIBUTOR_LEADERBOARD_REQUEST:
			return {
				...state,
				leaderboard: []
			};
		case GET_CONTRIBUTOR_LEADERBOARD_SUCCESS:
			return {
				...state,
				leaderboard: action.payload.data
			};
		case GET_CONTRIBUTOR_LEADERBOARD_FAILURE:
			return {
				...state,
				leaderboard: []
			};
		case CLEAR_POPULAR_TAGS:
			return {
				...state,	
				popularTags: []
			};
		case GET_POPULAR_TAGS_REQUEST:
			return {
				...state,
				popularTags: []
			};
		case GET_POPULAR_TAGS_SUCCESS:
			return {
				...state,
				popularTags: action.payload.data
			};
		case GET_POPULAR_TAGS_FAILURE:
			return {
				...state,
				popularTags: []
			};
		case CLEAR_APPLICATIONS_CHART_DATA:
			return {
				...state,	
				applicationsChartData: []
			};
		case GET_APPLICATIONS_CHART_DATA_REQUEST:
			return {
				...state,
				applicationsChartData: []
			};
		case GET_APPLICATIONS_CHART_DATA_SUCCESS:
			return {
				...state,
				applicationsChartData: action.payload.data
			};
		case GET_APPLICATIONS_CHART_DATA_FAILURE:
			return {
				...state,
				applicationsChartData: []
			};
		case CLEAR_OFFERS_CHART_DATA:
			return {
				...state,	
				offersChartData: []
			};
		case GET_OFFERS_CHART_DATA_REQUEST:
			return {
				...state,
				offersChartData: []
			};
		case GET_OFFERS_CHART_DATA_SUCCESS:
			return {
				...state,
				offersChartData: action.payload.data
			};
		case GET_OFFERS_CHART_DATA_FAILURE:
			return {
				...state,
				offersChartData: []
			};
		case CLEAR_PRODUCTS_CHART_DATA:
			return {
				...state,	
				productsChartData: []
			};
		case GET_PRODUCTS_CHART_DATA_REQUEST:
			return {
				...state,
				productsChartData: []
			};
		case GET_PRODUCTS_CHART_DATA_SUCCESS:
			return {
				...state,
				productsChartData: action.payload.data
			};
		case GET_PRODUCTS_CHART_DATA_FAILURE:
			return {
				...state,
				productsChartData: []
			};
		default:
			return state;
	}
};
