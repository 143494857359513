import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { register, isLoading } from '../../store/context';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import TermsDialog from '../modals/TermsDialog';
import lowerCase from 'lodash/lowerCase';
import Form from '../widgets/Form';
import { getQueryParameters } from '../../utils/utils';
import startsWith from 'lodash/startsWith';
import toLower from 'lodash/toLower';
import { 
	Radio, 
    RadioGroup,
	FormControl,
    FormControlLabel,
	FormLabel,
	FormHelperText
} from '@mui/material';

const validUsername = /^[a-z0-9_.]+$/i,
	validEmail = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

const form = {
	initValues: props => {
		return { 
			organisationId: props.organisationId, 
			membershipLevelId: null
		};
	},
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "organisationId",
			required: props => props.queryParameters.path !== 'buyer' && props.queryParameters.path !== 'seller',
			label: "Site",
			type: "autoComplete",
			loadItems: {
				route: props => `organisations?subdomainId=${props.subdomainId || ""}`, 
				mapItem: o => ({ label: o.name, value: o.organisationId, organisation: o }),
				onSuccess: (props, setValue, mapped) => {
					// Need to use props because initValues has not been called yet
					if (props.organisationId) {
						const { organisation } = mapped.find(item => item.value === props.organisationId);
						setValue("departmentId", organisation.defaultDepartmentId);
						setValue("positionId", organisation.defaultPositionId);
						setValue("_hideDepartmentDuringRegistration", organisation.hideDepartmentDuringRegistration);
						setValue("_hidePositionDuringRegistration", organisation.hidePositionDuringRegistration);
					}
					setValue("_organisationCount", mapped.length);
				}
			},
			onChange: (values, value) => {
				values.departmentId = value.organisation.defaultDepartmentId;
				values.positionId = value.organisation.defaultPositionId;
				values._hideDepartmentDuringRegistration = value.organisation.hideDepartmentDuringRegistration;
				values._hidePositionDuringRegistration = value.organisation.hidePositionDuringRegistration;
			},
			disabled: props => props.process === "Controlled",
			style: { marginTop: 10 }
		},
		{
			name: "userOrganisation",
			label: "Your affiliated organisation or institution",
			type: "autoComplete",
			loadItems: {
				route: "user-organisations/self-reg",
				mapItem: uo => ({ label: uo.name, value: uo.name })
			},
			canCreate: true,
			openMenuOnClick: false,
			filterOption: (option, inputValue) => startsWith(toLower(option.label), toLower(inputValue)),
			formatCreateLabel: inputValue => inputValue,
			onCreateOption: (newValue, items, setItems, onChange) => {
				const newItem = { label: newValue, value: newValue };
				setItems([...items, newItem]);
				onChange(newItem);
			}
		},
		{
			name: "selfRegOrganisation",
			label: "Your affiliated organisation or institution",
			helpText: "If it already exists it will prepopulate for you",
			type: "autoComplete",
			required: props => props.queryParameters.path === 'buyer' || props.queryParameters.path === 'seller',
			loadItems: {
				route: props => `organisations/self-reg?subdomainId=${props.subdomainId || ""}`, 
				mapItem: o => ({ label: o.name, value: o.organisationId, organisation: o }),
			},
			canCreate: true,
			openMenuOnClick: false,
			filterOption: (option, inputValue) => startsWith(toLower(option.label), toLower(inputValue)),
			formatCreateLabel: inputValue => inputValue,
			onCreateOption: (newValue, items, setItems, onChange) => {
				const newItem = { label: newValue, value: newValue };
				setItems([...items, newItem]);
				onChange(newItem);
			},
			onChange: (values, value) => {
				if (value.organisation) {
					values.organisationId = value.value;
					values.userOrganisation = '';
				} else {
					values.organisationId = 0;
					values.userOrganisation = value.value;
				}
			},
		},
		{
			name: "firstName",
			required: true,
			label: "First name",
			autoFocus: true,
		},
		{
			name: "lastName",
			required: true,
			label: "Last name",
			onBlur: (value, values, setValue) => {
				if (values.firstName && !values.username) {
					setValue("username", `${lowerCase(values.firstName)}.${lowerCase(values.lastName)}`);
				}
			}
		},
		{
			name: "email",
			required: true,
			label: "Email",
			validate: value => validEmail.test(value) ? "" : "Email must be a valid address",
			helpText: "Your company email address",
		},
		{
			name: "username",
			required: true,
			label: "Username",
			helpText: "Do NOT use your email address",
			validate: value => validUsername.test(value) ? "" : "Username must contain no spaces or special characters"
		},
		{
			name: "departmentId",
			label: "Business unit",
			type: "autoComplete",
			loadItems: {
				route: (props, values) => `departments/by-organisation/${values.organisationId}`, 
				mapItem: d => ({ label: d.name, value: d.departmentId }),
				onSuccess: (props, setValue, mapped) => {
					if (mapped.length === 1) {
						setValue("departmentId", mapped[0].value);
						setValue("_hideDepartment", true);
					}
				}
			}
		},
		{
			name: "positionId",
			label: "Position",
			type: "autoComplete",
			loadItems: {
				route: (props, values) => values.organisationId ? `positions/by-organisation/${values.organisationId}` : null, 
				mapItem: p => ({ label: p.title, value: p.positionId })
			}
		},
		{
			name: "worksInAPSCAcademy",
			label: "Do you work in the APS Academy?",
			type: "custom",
			required: props => props.isAPSC,
			widget: props => 
				<FormControl fullWidth={true} error={props.error} style={{
					textAlign: 'left',
					...props.style
				}}>
					<FormLabel>Do you work in the APS Academy?</FormLabel>
					<RadioGroup id="resource-type" row value={props.value} onChange={e => props.onChange(e.target.value)} >
						<FormControlLabel value="Yes" control={<Radio />} label="Yes" />
						<FormControlLabel value="No" control={<Radio />} label="No" />
					</RadioGroup>
					{props.helpText && <FormHelperText style={{ color: props.error ? props.errorColour : "" }}>{props.helpText}</FormHelperText>}
				</FormControl>
		},
		{
			name: "apscPositionId",
			label: "What best describes your role (pick one)?",
			type: "autoComplete",
			required: props => props.isAPSC,
			loadItems: {
				route: (props, values) => values.organisationId ? `positions/by-organisation/${values.organisationId}` : null, 
				mapItem: p => ({ label: p.title, value: p.positionId })
			}
		},
		{
			name: "avatar",
			label: "Avatar",
			type: "avatar",
			helpText: "Chosen avatar for use within hub (Default avatar provided)"
		},
		{
			name: "agreetc",
			required: true,
			label: setValue => <span>I agree to all <a onClick={e => { e.preventDefault(); setValue("_termsOpen", true); }}>Terms &amp; Conditions</a></span>,
			type: "checkbox",
			requiredText: "Please accept the terms and conditions"
		},
		{
			name: "password",
			required: true,
			label: "Password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters"
		},
		{
			name: "cpassword",
			required: true,
			label: "Confirm password",
			type: "password",
			validate: (value, values) => value === values.password ? "" : "Both passwords must be identical"
		},
		{
			name: "isAcademic",
			label: "Are you an Academic?",
			type: "checkbox"
		},
		{
			name: "membershipLevelId",
			label: "Membership Level",
			type: "autoComplete",
			loadItems: {
				route: props => `organisations/membership-levels?organisationId=${props.organisationId}`,
				mapItem: level => ({ label: level.title, value: level.membershipLevelId })
			}
		},
		{
			name: "publishBusinessProfile",
			label: "Publish Business Profile",
			type: "checkbox"
		}
	]
};

const Registration = props => {
	if (props.process === "AdminOnly") return <Redirect to="/login" />;

	const { fields, values, formFiles } = props;
	const isSelfReg = props.queryParameters.path === 'buyer' || props.queryParameters.path === 'seller';
	const selfRegRole = props.queryParameters.path;

	const registrationInitialText = props.registrationInitialText || "We invite you to create an account for Research 2 U.";

	const hideOrganisation = values._organisationCount > 1 && values.organisationId;

	return (
		<div className="registration-box">
			<span className="h3">Register</span>
			<small className="quill-display-block" dangerouslySetInnerHTML={{__html: registrationInitialText}}></small>
			<Form 
				onSubmit={() => {
					if (props.validateFields()) {
						const registerArgs = { ...values, selfRegRole };
						if (props.isAPSC) {
							registerArgs.positionId = values.apscPositionId;
							registerArgs.isExpert = values.worksInAPSCAcademy === "Yes";
						}
						props.register(registerArgs, formFiles.avatar, props.tenantId);
					} 
				}}
				style={{ textAlign: "center" }}
			>
				{!isSelfReg && <div style={{ display: hideOrganisation ? 'none' : undefined }}>
					{fields.organisationId()}
				</div>}
				{(props.registerUserOrganisation && !isSelfReg) && fields.userOrganisation()}
				{isSelfReg && fields.selfRegOrganisation()}
				{fields.username()}
				{fields.firstName()}
				{fields.lastName()}
				{fields.email()}
				{Boolean(values.organisationId) && !values._hideDepartmentDuringRegistration && !values._hideDepartment && !isSelfReg && fields.departmentId()}
				{Boolean(values.departmentId) && !values._hidePositionDuringRegistration && !isSelfReg && !props.isAPSC && fields.positionId()}
				{props.isAPSC && fields.apscPositionId()}
				{props.isAPSC && fields.worksInAPSCAcademy()}
				{props.showMembershipLevelId && fields.membershipLevelId()}
				{fields.password()}
				{fields.cpassword()}
				{props.organisationType === "Institution" && fields.isAcademic()}
				{fields.agreetc()}
				{/* Submit Button */}
				<p>Please check your email for a Confirmation Text</p>
				<Button type="submit" size="large" loading={props.loading} color="secondary">Register</Button>
			</Form>
			<div style={{ marginTop: 10, textAlign: "center" }}>
				Already have an account? <ButtonLink to="/login" variant="text" size="small" color="primary">Sign in</ButtonLink>
			</div>
			{/* Terms and Conditions Modal */}
			<TermsDialog
				open={Boolean(values._termsOpen)}
				onClose={() => props.updateValues({ _termsOpen: false })}
				showAgreeButton
				onAgree={() => props.updateValues({ _termsOpen: false, agreetc: true })}
				onDisagree={() => props.updateValues({ _termsOpen: false, agreetc: false })}
			/>
		</div>
	);
};

Registration.propTypes = {
	loading: PropTypes.bool.isRequired,
	register: PropTypes.func.isRequired,
	subdomainId: PropTypes.number,
	tenantId: PropTypes.number,
	process: PropTypes.string,
	organisationId: PropTypes.number,
	organisationType: PropTypes.string,
	showMembershipLevelId: PropTypes.bool,
	bootstrapLoading: PropTypes.bool.isRequired,
	registrationInitialText: PropTypes.string,
	registerUserOrganisation: PropTypes.bool,
	isAPSC: PropTypes.bool
};

Registration.defaultProps = {
	subdomainId: 0,
	tenantId: 0,
	process: "",
	organisationId: null,
	organisationType: "",
	showMembershipLevelId: false,
	registrationInitialText: null,
	registerUserOrganisation: false,
	isAPSC: false
};

const mapStateToProps = (state, ownProps) => ({
	loading: isLoading(state),
	subdomainId: state.context.subdomainId,
	tenantId: state.context.registrationTenantId,
	process: state.context.registrationProcess,
	organisationId: state.context.registrationOrganisationId,
	organisationType: state.context.organisationType,
	showMembershipLevelId: state.context.showMembershipLevelId,
	bootstrapLoading: state.bootstrap.loading,
	registrationInitialText: state.settings.registrationInitialText,
	registerUserOrganisation: state.context.registerUserOrganisation,
	queryParameters: getQueryParameters(ownProps.location.search),
	isAPSC: state.context.organisationId === state.settings.apsc_organisationId
});

const mapDispatchToProps = dispatch => ({
	register: (values, avatar, tenantId) => dispatch(register(values, avatar, tenantId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(Registration));