import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { withForm } from '../../utils/forms';
import { searchCommunities, isLoading } from '../../store/communities';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CommunityCard from './CommunityCard';
import ButtonLink from '../widgets/ButtonLink';
import map from 'lodash/map';
import { canSubmitCommunities, canEditCommunity, viewCommunityProps } from '../../store/security';

let timer = null;
const form = {
	onChange: (props, values) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchCommunities(values.search), 500);
	},
	fields: [{
		name: "search",
		placeholder: "Search",
		startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
	}]
};

const CommunitiesController = props => {
	React.useEffect(props.searchCommunities, []);

	return <PageLayout title="Communities" pageContent={
		<React.Fragment>
			<Grid container spacing={3} direction="row-reverse">
				{props.canSubmit &&
					<Grid item xs="auto">
						<ButtonLink to="/admin/communities/create">Create community</ButtonLink>
					</Grid>
				}
				<Grid item xs={12} sm>{props.fields.search()}</Grid>
			</Grid>
			{props.communities.length === 0 ? <p style={{ marginTop: 10 }}>No communities found...</p> :
				<Grid container spacing={3}>
					{map(props.communities, (c, i) => 
						<CommunityCard key={i} community={c} canEdit={props.canEdit} communityProps={props.communityProps} />
					)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={["Communities"]} />;
};

CommunitiesController.propTypes = { 
	communities: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	communityProps: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	communities: state.communities.communities,
	loading: isLoading(state),
	canSubmit: canSubmitCommunities(state),
	canEdit: canEditCommunity(state),
	communityProps: viewCommunityProps(state)
});

const mapDispatchToProps = dispatch => ({
	searchCommunities: search => dispatch(searchCommunities(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CommunitiesController));