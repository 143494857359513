import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showModal } from '../../store/modal';
import { getCollaboration, saveCollaboration, deleteCollaboration, isLoading } from '../../store/collaborations';
import PageLayout from '../PageLayout';
import EditCollaboration from './EditCollaboration';
import { changeLocation } from '../../store/location';
import { canEditCollaboration } from '../../store/security';
//import some from 'lodash/some';

const EditCollaborationController = props => {
	const { collaborationId } = props.match.params;
	React.useEffect(() => props.getCollaboration(collaborationId), [collaborationId]);

	//const isParticipant = props.collaboration ? some(props.collaboration.participants, p => p.userId === props.currentUserId && p.status === 'Accepted') : false;
	const authorised = !props.collaboration || !props.collaboration.collaborationId || props.canEdit; // || isParticipant;
	if (!authorised) {
		props.goToUnauthorisedPage();
	}

	return <PageLayout title="Edit collaboration" pageContent={
		<EditCollaboration
			collaboration={props.collaboration}
			saveCollaboration={props.saveCollaboration}
			deleteCollaboration={props.deleteCollaboration}
			showMessage={props.showMessage}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Collaborations", path: "/collaborations" }, "Edit collaboration"]} />;
};

EditCollaborationController.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	collaboration: PropTypes.object.isRequired,
	saveCollaboration: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	goToUnauthorisedPage: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	saveResult: state.collaborations.saveResult,
	collaboration: state.collaborations.collaboration,
	canEdit: canEditCollaboration(state),
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	goToUnauthorisedPage: () => dispatch(changeLocation("/unauthorised")),
	getCollaboration: collaborationId => dispatch(getCollaboration(collaborationId)),
	saveCollaboration: (collaboration, newFiles) => dispatch(saveCollaboration(collaboration, newFiles)),
	deleteCollaboration: collaborationId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this collaboration?",
		onOk: () => dispatch(deleteCollaboration(collaborationId))
	})),
	showMessage: (title, message, onOk) => dispatch(showModal("MESSAGE", {
		title: title,
		message: message,
		onOk: onOk
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCollaborationController);