import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import Grid from '@mui/material/Grid';
import Card from '../../widgets/Card';

const ProductCard = React.memo(({ product }) => 
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<Card className="product-card">
			<div className="header">
				<Link to={`/admin/products/edit/${product.productId}`} className="h3">{product.name}</Link>
			</div>
			<div className="product-card__price">
				<span className="product-card__price_dollar-sign">$</span>{product.price}
			</div>
			<div className="product-card__duration">
				{product.duration}
			</div>
		</Card>
	</Grid>
);

ProductCard.propTypes = {
	product: PropTypes.shape({
		productId: PropTypes.number,
		name: PropTypes.string,
		price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		duration: PropTypes.string,
		description: PropTypes.string
	}).isRequired
};

export default ProductCard;