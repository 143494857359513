import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { IconButton, CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { 
	getSubscribedDiscussions, 
	saveDiscussion, 
	isLoading as isDiscussionLoading ,
	clearDiscussions
} from '../../store/discussion';
import { 
	getSubscribedResources, 
	isLoading as isResourcesLoading ,
	clearResources
} from '../../store/resources';
import { 
	getSubscribedProblems, 
	isLoading as isProblemsLoading ,
	clearProblems
} from '../../store/problems';
import { 
	getSubscribedInsights, 
	isLoading as isInsightsLoading,
	clearInsights
} from '../../store/insights';
import { 
	getSubscribedEvents, 
	isLoading as isEventsLoading,
	clearEvents 
} from '../../store/events';
import { 
	getSubscribedTools, 
	isLoading as isToolsLoading,
	clearTools
} from '../../store/tools';
import { 
	getTopCLO_Courses, 
	isLoading as isCLOCoursesLoading,
	clearCLO_Courses
} from '../../store/cloCourses';
import { getChannels, getSubscriptions, saveSubscriptions, unsubscribe } from '../../store/channels';
import { viewCommunityProps, submitCommunityProps, canRequestEvents } from '../../store/security';
import { get, post } from '../../utils/ajax';
import CreateDiscussionDialog from '../widgets/CreateDiscussionDialog';
import ButtonLink from '../widgets/ButtonLink';
import TextField from '../widgets/TextField';
import ToolRow from '../widgets/ToolRow';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import DashboardWidget from '../dashboardWidgets/DashboardWidget';
import PageLayout from '../PageLayout';
import ChannelsDialog from './ChannelsDialog';
import DiscussionRow from './DiscussionRow';
import ExpertDialog from './ExpertDialog';
import ResourceRow from './ResourceRow';
import ProblemRow from './ProblemRow';
import InsightRow from './InsightRow';
import CloCourseRow from './CloCourseRow';
import EventRow from './EventRow';
import capitalise from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import {
    toggleChat,
    setToUser
} from '../../store/messages';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	feed: {
		'& .label': {
			backgroundColor: theme.palette.primary.main
		},
		'& .event-row': {
			'& .date': {
				'> svg': {
					color: theme.palette.secondary.main
				}
			}
		},
		'& .resource-row': {
			'& .academic': {
				'& svg': {
					color: theme.palette.secondary.main
				}
			}
		}
	}
});


const limit = 5;
let timer = null;

const FindMore = () =>
	<span style={{ fontSize: '0.5em', lineHeight: 0 }}> (find more...)</span>;

const MyCommunityController = props => {
	const [pageLoaded, setPageLoaded] = React.useState(false);
	const [problemSearch, setProblemSearch] = React.useState("");
	const [showChannelsDialog, setShowChannelsDialog] = React.useState(false);
	const [showDiscussionDialog, setShowDiscussionDialog] = React.useState(false);
	const [dashboard, setDashboard] = React.useState({ layout: null, widgets: null });
	const [expertDialog, setExpertDialog] = React.useState({ open: false, expertId: 0 });

	const { subscribed, channels, allChannels, loading, canView, canSubmit } = props;
	const eventRequestOnly = !props.canSubmit.events && props.canRequestEvent;

	React.useEffect(() => {
		post({ url: "/api/communities/log-view" });

		props.getSubscriptions();
		setPageLoaded(true);

		get({
			url: "/api/dashboard/community-dashboard",
			onSuccess: data => {
				setDashboard({
					layout: JSON.parse(data.layout),
					widgets: JSON.parse(data.widgets)
				});
			}
		});
	}, []);

	React.useEffect(() => {
		if (allChannels || subscribed.length > 0) {
			props.refreshFeed(canView, props.communityType);
		} else {
			props.clearFeed();
		}
	}, [subscribed.length, allChannels]);

	const handleProblemSearchChange = e => {
		setProblemSearch(e.target.value);

		// Set timeout to trigger search
		clearTimeout(timer);
		timer = setTimeout(() => props.searchProblems(problemSearch), 500);
	}
    
    const chatWithExpert = (expertUserId) => {
        setExpertDialog({ open: false, expertId: 0 });
        props.setChatToUser(expertUserId);
        props.toggleChat(true);
    };

	return <PageLayout title={props.communityType === "CLO Accelerator" ? "Learning Accelerator" : "My Community"} pageClass="my-community" pageContent={
		<React.Fragment>
			<h3 style={{ marginBottom: 0 }}>
				Channels
				<IconButton size="small" onClick={() => setShowChannelsDialog(true)} style={{ marginLeft: 5 }}>
					<EditIcon />
				</IconButton>
			</h3>
			{allChannels ? <Tag label="All Channels" style={{ height: 30, marginBottom: 25 }} colour={props.primaryColour} /> :
				<div style={{ marginBottom: 20 }}>
					{subscribed.length === 0 && "You are not subscribed to any channels..."}
					{channels.filter(c => subscribed.includes(c.channelId)).map((c, i) =>
						<Tag key={i} label={c.name} style={{ height: 30, marginBottom: 5 }} onDelete={() => props.unsubscribe(c.channelId)} />
					)}
				</div>
			}
			{/* Need to delay loading the feed so lists can refresh, prevent items without themes appearing */}
			{pageLoaded && 
				<div className={`feed ${props.classes.feed}`}>
					{/* Tools */}
					{canView.tools &&
						<Card 
							title={<Link to="/my-community/tools">Tools<FindMore /></Link>} 
							functions={["CommunityTools"]} 
							loading={loading.tools} 
							titleRightContent={canSubmit.tools &&
								<ButtonLink to="/my-community/tools/create" variant="outlined" className="add-button">
									Create tool
								</ButtonLink>
							}
						>
							{props.tools.length === 0 && "There are no tools to display..."}
							{props.tools.map((t, i) => <ToolRow isCommunity key={i} tool={t} />)}
						</Card>
					}
					{/* Resources */}
					{canView.resources &&
						<Card 
							title={<Link to="/my-community/resources">Resources<FindMore /></Link>} 
							functions={["CommunityResources"]} 
							loading={loading.resources} 
							titleRightContent={canSubmit.resources &&
								<ButtonLink to="/my-community/resources/create" variant="outlined" className="add-button">
									Create resource
								</ButtonLink>
							}
						>
							{props.resources.length === 0 && "There are no resources to display..."}
							{props.resources.map((r, i) => <ResourceRow key={i} resource={r} />)}
						</Card>
					}
					{/* Discussion */}
					{canView.discussion &&
						<Card 
							title={<Link to="/my-community/discussion">Discussion<FindMore /></Link>} 
							functions={["CommunityDiscussion"]} 
							loading={loading.discussion} 
							titleRightContent={canSubmit.discussion &&
								<Button className="add-button" variant="outlined" onClick={() => setShowDiscussionDialog(true)}>
									Create discussion
								</Button>
							}
						>
							{props.discussions.length === 0 && "There is no new discussion to display..."}
							{props.discussions.map((t, i) => <DiscussionRow key={i} discussion={t} />)}
						</Card>
					}
					{/* Events */}
					{canView.events &&
						<Card 
							title={<Link to="/my-community/events">Events<FindMore /></Link>} 
							functions={["CommunityEvents"]} 
							loading={loading.events} 
							titleRightContent={(canSubmit.events || props.canRequestEvent) &&
								<ButtonLink to={`/my-community/events/${eventRequestOnly ? "request" : "create"}`} variant="outlined" className="add-button">
									{eventRequestOnly ? "Request" : "Create"} event
								</ButtonLink>
							}
						>
							{props.events.length === 0 && "There are no events to display..."}
							{props.events.map((e, i) => <EventRow key={i} event={e} />)}
						</Card>
					}
					{/* Problems */}
					{canView.problems &&
						<Card 
							title={<Link to="/my-community/problems">{capitalise(props.problemPlural)}<FindMore /></Link>} 
							functions={["CommunityProblems"]} 
							loading={loading.problems} 
							titleRightContent={canSubmit.problems &&
								<ButtonLink to="/my-community/problems/create" variant="outlined" className="add-button">
									Create {props.problemSingle}
								</ButtonLink>
							}
						>
							<TextField
								placeholder={`Search ${props.problemPlural}`}
								value={problemSearch}
								onChange={handleProblemSearchChange}
								startAdornment={loading.problems ? <CircularProgress size={20} /> : <SearchIcon />}
							/>
							{props.problems.length === 0 && <span style={{ marginTop: 10 }}>There are no {props.problemPlural} to display...</span>}
							{props.problems.map((p, i) => <ProblemRow key={i} problem={p} />)}
						</Card>
					}
					{/* Insights */}
					{canView.insights &&
						<Card 
							title={<Link to="/my-community/insights">Research Insights<FindMore /></Link>} 
							functions={["CommunityInsights"]} 
							loading={loading.insights} 
							titleRightContent={canSubmit.insights &&
								<ButtonLink to="my-community/insights/create" className="add-button" variant="outlined">
									Create insight
								</ButtonLink>
							}
						>
							{props.insights.length === 0 && "There are no new research insights to display..."}
							{props.insights.map((t, i) =>
								<InsightRow key={i} insight={t} onExpertClick={expertId => setExpertDialog({ open: true, expertId })} />
							)}
						</Card>
					}
					{/* CLO Courses */}
					{canView.cloCourses && props.communityType === "CLO Accelerator" &&
						<Card 
							title={<Link to="/clo-courses?acceleratorCourses=true">Courses<FindMore /></Link>} 
							loading={loading.cloCourses} 
							titleRightContent={canSubmit.cloCourses &&
								<ButtonLink to="/clo-courses/create" className="add-button" variant="outlined">
									Create Course
								</ButtonLink>
							}
						>
							{props.cloCourses.length === 0 && "There are no courses to display..."}
							{props.cloCourses.map((c, i) =>
								<CloCourseRow key={i} course={c} />
							)}
						</Card>
					}
				</div>
			}
			<Grid container spacing={3}>
				{map(dashboard.widgets, w =>
					<Grid item xs={12}>
						<Card>
							<DashboardWidget {...w} />
						</Card>
					</Grid>
				)}
			</Grid>
			<ChannelsDialog
				open={showChannelsDialog}
				subscribed={subscribed}
				channels={channels}
				allChannels={allChannels}
				onClose={() => setShowChannelsDialog(false)}
				onSubmit={(newSubs, allChannels) => {
					setShowChannelsDialog(false);
					props.saveSubscriptions(newSubs, allChannels);
				}}
			/>
			<CreateDiscussionDialog
				open={showDiscussionDialog}
				channels={allChannels ? channels : channels.filter(c => subscribed.includes(c.channelId))}
				onClose={() => setShowDiscussionDialog(false)}
				onSubmit={discussion => {
					setShowDiscussionDialog(false);
					props.saveDiscussion(discussion);
				}}
				hideTopic
			/>
			<ExpertDialog
				expertId={expertDialog.expertId}
				open={expertDialog.open}
				onClose={() => setExpertDialog({ open: false, expertId: 0 })}
                chatWithExpert={chatWithExpert}
			/>
		</React.Fragment>
	} breadcrumbs={[ props.communityType === "CLO Accelerator" ? "Learning Accelerator" : "My Community" ]} />;
};

MyCommunityController.propTypes = {
	getSubscriptions: PropTypes.func.isRequired,
	channels: PropTypes.arrayOf(PropTypes.object).isRequired,
	subscribed: PropTypes.array.isRequired,
	saveSubscriptions: PropTypes.func.isRequired,
	unsubscribe: PropTypes.func.isRequired,
	refreshFeed: PropTypes.func.isRequired,
	tools: PropTypes.arrayOf(PropTypes.object).isRequired,
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	resources: PropTypes.arrayOf(PropTypes.object).isRequired,
	discussions: PropTypes.arrayOf(PropTypes.object).isRequired,
	problems: PropTypes.arrayOf(PropTypes.object).isRequired,
	insights: PropTypes.arrayOf(PropTypes.object).isRequired,
	cloCourses: PropTypes.arrayOf(PropTypes.object).isRequired,
	saveDiscussion: PropTypes.func.isRequired,
	searchProblems: PropTypes.func.isRequired,
	canView: PropTypes.object.isRequired,
	canSubmit: PropTypes.object.isRequired,
	loading: PropTypes.object.isRequired,
	problemSingle: PropTypes.string.isRequired,
	problemPlural: PropTypes.string.isRequired,
	allChannels: PropTypes.bool.isRequired,
    setChatToUser: PropTypes.func.isRequired,
    toggleChat: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	communityType: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	channels: state.channels.channels,
	subscribed: state.channels.subscribed,
	tools: state.tools.tools,
	events: state.events.events,
	resources: state.resources.resources,
	discussions: orderBy(state.discussion.discussions || [], "updateDate", "desc"),
	problems: state.problems.problems,
	insights: state.insights.insights,
	cloCourses: state.cloCourses.cloCourses,
	canView: viewCommunityProps(state),
	canSubmit: submitCommunityProps(state),
	loading: {
		tools: isToolsLoading(state),
		events: isEventsLoading(state),
		resources: isResourcesLoading(state),
		discussion: isDiscussionLoading(state),
		problems: isProblemsLoading(state),
		insights: isInsightsLoading(state),
		cloCourses: isCLOCoursesLoading(state)
	},
	problemSingle: state.settings.single,
	problemPlural: state.settings.plural,
	allChannels: state.channels.allChannels,
	canRequestEvent: canRequestEvents(state),
	primaryColour: state.theme.primaryColour,
	communityType: state.context.communityType
});

const mapDispatchToProps = dispatch => ({
	getSubscriptions: () => {
		dispatch(getChannels());
		dispatch(getSubscriptions());
	},
	saveSubscriptions: (channels, allChannels) => dispatch(saveSubscriptions(channels, allChannels)),
	unsubscribe: channelId => dispatch(unsubscribe(channelId)),
	refreshFeed: (canView, communityType) => {
		if (canView.tools) dispatch(getSubscribedTools(limit));
		if (canView.events) dispatch(getSubscribedEvents(limit));
		if (canView.resources) dispatch(getSubscribedResources(limit));
		if (canView.discussion) dispatch(getSubscribedDiscussions(limit));
		if (canView.problems) dispatch(getSubscribedProblems("", limit));
		if (canView.insights) dispatch(getSubscribedInsights(limit));
		if (canView.cloCourses && communityType === "CLO Accelerator") dispatch(getTopCLO_Courses(limit, true))
	},
	clearFeed: () => {
		dispatch(clearTools());
		dispatch(clearEvents());
		dispatch(clearResources());
		dispatch(clearDiscussions());
		dispatch(clearProblems());
		dispatch(clearInsights());
		dispatch(clearCLO_Courses());
	},
	saveDiscussion: discussion => dispatch(saveDiscussion(discussion, true)),
	searchProblems: search => dispatch(getSubscribedProblems(search)),
    setChatToUser: userId => dispatch(setToUser(userId)), 
    toggleChat: show => dispatch(toggleChat(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(MyCommunityController, styles));