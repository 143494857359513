import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInsight, isLoading, publishInsight, unpublishInsight } from '../../store/insights';
import { canEditCommunityInsight, canEditInsight, canMakePublic, hasFunction } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewInsight from './ViewInsight';
import {
    toggleChat,
    setToUser
} from '../../store/messages';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import MakePublicButton from '../widgets/MakePublicButton';

const ViewInsightController = props => {
    const [isApplying, setIsApplying] = React.useState(false);

	const insightId = Number(props.match.params.insightId);
	React.useEffect(() => props.getInsight(insightId), [insightId]);

    const applyToBeAnAuthor = (insight) => {
        setIsApplying(true);
        post({
			url: `/api/insights/${insight.insightId}/apply-to-be-author`,
			onSuccess: data => {
				setIsApplying(false);
                if (data.success) {
                    props.showSuccessNotification(data.message);
                } else {
    				props.showErrorNotification(data.message);
                }
			},
			onError: error => {
				setIsApplying(false);
				props.showErrorNotification(error.message);
			}
		})
    };

	return (
		<PageLayout 
			title="View Insight" 
			pageStyle={{ fontSize: 'medium' }} 
			pageContent={
				<ViewInsight 
					insight={props.insight} 
					loading={props.loading}
					isApplying={isApplying} 
					canEdit={props.canEdit} 
					canMakePublic={props.canMakePublic} 
					isCommunity={props.isCommunity} 
					primaryColour={props.primaryColour} 
					applyToBeAnAuthor={applyToBeAnAuthor}
					showChat={(expertId) => {
						props.setChatToUser(expertId);
						props.toggleChat(true);
					}}
					canPublishInsights={props.canPublishInsights}
					publishInsight={props.publishInsight}
					unpublishInsight={props.unpublishInsight}
        		/>
			} 
			breadcrumbs={[{ label: "Research Insights", path: `/${props.isCommunity ? "my-community/" : ""}insights` }, "View Insight"]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);
};

ViewInsightController.propTypes = {
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	insight: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canMakePublic: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
    setChatToUser: PropTypes.func.isRequired,
    toggleChat: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	canPublishInsights: PropTypes.bool.isRequired,
	publishInsight: PropTypes.func.isRequired,
	unpublishInsight: PropTypes.func.isRequired
};

ViewInsightController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	insight: state.insights.insight,
	loading: isLoading(state),
	canEdit: ownProps.isCommunity ? canEditCommunityInsight(state) : canEditInsight(state),
	canMakePublic: canMakePublic(state),
	primaryColour: state.theme.primaryColour,
	canPublishInsights: hasFunction("CanPublishInsights")(state)
});

const mapDispatchToProps = dispatch => ({
	getInsight: insightId => dispatch(getInsight(insightId)),
    setChatToUser: userId => dispatch(setToUser(userId)), 
    toggleChat: show => dispatch(toggleChat(show)),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	publishInsight: insightId => dispatch(publishInsight(insightId)),
	unpublishInsight: insightId => dispatch(unpublishInsight(insightId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewInsightController);