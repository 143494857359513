import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMicroLearn, saveMicroLearn, deleteMicroLearn, isLoading } from '../../store/microlearn';
import { canEditMicroLearn } from '../../store/security';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditMicroLearn from './EditMicroLearn';

const EditMicroLearnController = props => {
	const microLearnId = Number(props.match.params.microLearnId);

	React.useEffect(() => props.getMicroLearn(microLearnId), [microLearnId]);

	React.useEffect(() => {
		if (props.microLearn.microLearnId === microLearnId && !props.canEdit) props.revokeAccess();
	}, [props.microLearn.microLearnId]);

	return (
		<PageLayout 
			title="Edit Skill" 
			pageContent={
				<EditMicroLearn
					userId={props.userId}
					microLearn={props.microLearn}
					saveMicroLearn={props.saveMicroLearn}
					deleteMicroLearn={props.deleteMicroLearn}
					loading={props.loading}
					primaryColour={props.primaryColour}
				/>
			} 
			breadcrumbs={[
				{ label: "SkillsLab", path: `/resources` }, 
				"Edit Skill"
			]} 
		/>
	);
};

EditMicroLearnController.propTypes = {
	userId: PropTypes.string.isRequired,
	microLearn: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveMicroLearn: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

EditMicroLearnController.defaultProps = {
};

const mapStateToProps = (state, ownProps) => ({
	userId: state.context.userId,
	microLearn: state.microlearn.microLearn,
	canEdit: canEditMicroLearn(state),
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getMicroLearn: microLearnId => dispatch(getMicroLearn(microLearnId)),
	saveMicroLearn: (microLearn, imageFile, videoFile, newFiles) => dispatch(saveMicroLearn(microLearn, imageFile, videoFile, newFiles, ownProps.isCommunity)),
	deleteMicroLearn: microLearnId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this Resource?",
		onOk: () => dispatch(deleteMicroLearn(microLearnId, ownProps.isCommunity))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMicroLearnController);