import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import EventCard from '../widgets/EventCard';
import ButtonLink from '../widgets/ButtonLink';
import Checkbox from '../widgets/Checkbox';

const Events = props => {
	const [focusedInput, setFocusedInput] = React.useState(null);
	const { events, loading } = props;
	const requestOnly = !props.canSubmit && props.canRequest;

	return (
		<React.Fragment>
			<Grid container spacing={3} alignItems="center" style={{ paddingBottom: 8 }}>
				<Grid item xs={12} md>
					<h3 style={{ marginBottom: 0 }}>
						Current Public Events
						{loading && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
					</h3>
					<p className="text-muted" style={{ margin: 0 }}>Browse and register for our upcoming events.</p>
				</Grid>
				<Grid item xs={12} md="auto">
					<label style={{ marginRight: 10 }}>Filter by date:</label>
					<DateRangePicker
						readOnly
						numberOfMonths={1}
						minimumNights={0}
						startDate={props.fromDate ? moment(props.fromDate) : null}
						startDateId="EventsFromDate"
						endDate={props.toDate ? moment(props.toDate) : null}
						endDateId="EventsToDate"
						onDatesChange={({ startDate, endDate }) => props.onFilter(startDate ? startDate.toDate() : startDate, endDate ? endDate.endOf("day").toDate() : endDate)}
						isOutsideRange={() => false}
						focusedInput={focusedInput}
						onFocusChange={focus => setFocusedInput(focus)}
					/>
				</Grid>
				{props.canApprove &&
					<Grid item xs="auto">
						<Checkbox label="Show Unapproved events" checked={props.showUnapproved} onChange={e => props.setShowUnapproved(e.target.checked)} />
					</Grid>
				}
				{(props.canSubmit || props.canRequest) &&
					<Grid item xs={12} md="auto">
						<ButtonLink to={`/${props.isCommunity ? "my-community/" : ""}events/${requestOnly ? "request" : "create"}`}>
							{requestOnly ? "Request" : "Add"} Event
						</ButtonLink>
					</Grid>
				}
			</Grid>
			{events.length === 0 ? <p>There are no events in the {props.fromDate && props.toDate ? "selected date filter" : "system"}...</p> :
				<Grid container spacing={3}>
					{events.map((e, i) => 
						<Grid key={i} item lg={3} md={4} sm={6}>
							<EventCard event={e} isRegistering={props.isRegistering[e.eventId]} register={props.register} isCommunity={props.isCommunity} />
						</Grid>
					)}
				</Grid>
			}
		</React.Fragment>
	);
};

Events.propTypes = {
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	fromDate: PropTypes.instanceOf(Date),
	toDate: PropTypes.instanceOf(Date),
	onFilter: PropTypes.func.isRequired,
	isRegistering: PropTypes.object.isRequired,
	register: PropTypes.func.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canApprove: PropTypes.bool.isRequired,
	showUnapproved: PropTypes.bool.isRequired,
	setShowUnapproved: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool.isRequired
};

Events.defaultProps = {
	fromDate: null,
	toDate: null
};

export default Events;