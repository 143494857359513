import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from '../widgets/Button';
import DataTable from './dataTable/DataTable';
import DataTableColumn from './dataTable/DataTableColumn';
import { stripHtml } from '../../utils/utils';
import map from 'lodash/map';

const CompetenciesViewer = React.memo(props => {
	const [currentUnit, setCurrentUnit] = React.useState(null);

	return (
		<Dialog	
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
			maxWidth="md"
		>
			<DialogTitle id="dialog-title">This course provides training to support the following competencies:</DialogTitle>
			<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
				{currentUnit ? 
					<React.Fragment>
						<h3 className="quill-display-block" dangerouslySetInnerHTML={{ __html: `${currentUnit.unitCode} ${currentUnit.unitTitle}` }} style={{ display: "inline-block" }} />
						<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: currentUnit.unitDescriptionAndApplication }} />
						<h3>Performance Evidence/Skill</h3>
						<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: currentUnit.performanceEvidence }} />
						<h3>Knowledge Evidence</h3>
						<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: currentUnit.knowledgeEvidence }} />
						<h3>Elements/Levels</h3>
						<ul>{map(currentUnit.elements, (e, i) => <li key={i}>{stripHtml(e.element)}</li>)}</ul>
					</React.Fragment> :
					<DataTable 
						data={props.competencies}
						emptyText="No competencies"
						detailData={c => c.elements}
						hidePagination
						disableScroll
						detailColumns={[
							<DataTableColumn style={{ padding: 0, width: "35px" }} />,
							<DataTableColumn colSpan={2} style={{ whiteSpace: "normal" }} renderer={(c, e) =>
								<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }}></div>
							} />
						]}
					>
						<DataTableColumn label="Unit Code" renderer={u =>
							<a onClick={() => setCurrentUnit(u)} style={{ cursor: "pointer" }}>{u.unitCode}</a>
						} />
						<DataTableColumn label="Unit" style={{ whiteSpace: "normal" }} renderer={u =>
							<React.Fragment>
								<strong>{stripHtml(u.unitTitle)}</strong>
								{u.unitDescriptionAndApplication && <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: u.unitDescriptionAndApplication }} />}
							</React.Fragment>
						} />
					</DataTable>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={currentUnit ? () => setCurrentUnit(null) : props.onClose}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
});

CompetenciesViewer.propTypes = {
	competencies: PropTypes.array.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default CompetenciesViewer;
