import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../widgets/Card';
import { Link } from 'react-router-dom'
import DataTable from '../../widgets/dataTable/DataTable';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import SearchField from '../../widgets/SearchField';
import ButtonLink from '../../widgets/ButtonLink';
import PageLayout from '../../PageLayout';
import { get } from '../../../utils/ajax';

const TagsController = props => {
	const [search, setSearch] = React.useState("");
	const [state, setState] = React.useState({ tags: [], isLoading: true });

	React.useEffect(() => {
		get({
			url: '/api/tags',
			onSuccess: data => setState({ tags: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, []);

	return (
		<PageLayout 
			title="Manage Tags" 
			pageContent={
				<Card title="Search tags" loading={state.loading}>
					{/* Search Tags */}
					<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
					{/* Tags Datatable */}
					<DataTable data={state.tags} search={search} tableFooter={<ButtonLink to="/admin/tags/add">Add new tag</ButtonLink>}>
						<DataTableColumn name="name" label="Name" renderer={r => 
							<Link to={`/admin/tags/${r.tagId}`} style={{ color: props.primaryColour }}>
								<span>{r.name}</span>
							</Link>
						} />
						<DataTableColumn name="description" label="Description" />
						<DataTableColumn name="category" label="Category" />
						<DataTableColumn name="scope" label="Scope" />
						{props.isGlobalAdministrator && <DataTableColumn name="tenantName" label="Tenant" />}
					</DataTable>
				</Card>
			} 
			breadcrumbs={["Manage tags"]} 
		/>
	);
};

TagsController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsController);