import { createFetchAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import DOMPurify from 'dompurify';

const emptyUpdateResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	updateResult: emptyUpdateResult,
	profile: {}
};

const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const isLoading = createLoadingSelector(["GET_PROFILE", "UPDATE_PROFILE", "DELETE_PROFILE"]);

export const getProfile = userId =>
	createFetchAction({
		url: `/api/profiles/${userId}`,
		startAction: GET_PROFILE_REQUEST,
		onError: error => [getProfileFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getProfileSuccess(data)
	});

export const getProfileSuccess = data => ({ type: GET_PROFILE_SUCCESS, payload: { data } });
export const getProfileFailure = error => ({ type: GET_PROFILE_FAILURE, payload: { error } });

export const updateProfile = (profile, avatarFile, onSuccess) => {
	const formData = new FormData();
	formData.append("Profile", encodeURIComponent(JSON.stringify(profile)));
	if (avatarFile) formData.append("AvatarFile", avatarFile);

	return createFormPostAction({
		url: `/api/profiles/${profile.userId}/update`,
		data: formData,
		startAction: UPDATE_PROFILE_REQUEST,
		onError: error => [updateProfileFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess(data);
				return [updateProfileSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [updateProfileSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const updateProfileSuccess = data => ({ type: UPDATE_PROFILE_SUCCESS, data });
export const updateProfileFailure = error => ({ type: UPDATE_PROFILE_FAILURE, error });

export const changePassword = (profile, password) =>
	createFormPostAction({
		url: `/api/profiles/${profile.userId}/change-password`,
		data: { profile, password },
		startAction: CHANGE_PASSWORD_REQUEST,
		onError: error => [changePasswordFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [changePasswordSuccess(data), showSuccessNotification("Password successfully updated.")];
			} else {
				return [changePasswordFailure(data), showErrorNotification(data.message)];
			}
		}
	});

export const changePasswordSuccess = data => ({ type: CHANGE_PASSWORD_SUCCESS, data });
export const changePasswordFailure = error => ({ type: CHANGE_PASSWORD_FAILURE, error });

const sanitizeProfile = profile => ({ ...profile, description: DOMPurify.sanitize(profile.description) });

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PROFILE_REQUEST:
			return {
				...state,
				profile: {},
				isLoading: true,
				updateResult: emptyUpdateResult
			};
		case GET_PROFILE_SUCCESS:
			return {
				...state,
				profile: sanitizeProfile(action.payload.data),
				isLoading: false
			};
		case UPDATE_PROFILE_REQUEST:
		case CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				isLoading: true,
				updateResult: emptyUpdateResult,
				message: null
			};
		case UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				...(action.data.success && { profile: sanitizeProfile(action.data.object) }),
				isLoading: false,
				updateResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				profile: {
					...state.profile,
					oldPassword: "",
					newPassword: "",
					cPassword: ""
				},
				isLoading: false,
				updateResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case GET_PROFILE_FAILURE:
		case UPDATE_PROFILE_FAILURE:
			return {
				...state,
				isLoading: false,
				saveResult: { success: false, message: "", fields: action.data }
			};
		default:
			return state;
	}
};
