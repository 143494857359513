import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CodeIcon from '@mui/icons-material/Code';
import EventIcon from '@mui/icons-material/Event';
import PaletteIcon from '@mui/icons-material/Palette';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { TrophyIcon, ClockInIcon, ClockOutIcon } from '../widgets/Icons';
import { default as formatDate } from 'date-fns/format';
import { stripHtml } from '../../utils/utils';

const EventRow = React.memo(({ event: e }) => {
	const Icon = ({
		Showcase: TrophyIcon,
		Hackathon: CodeIcon,
		Event: EventIcon,
		"Innovation Jam": PaletteIcon,
		Webinar: LiveTvIcon,
		"Web series": SubscriptionsIcon
	})[e.eventType];

	return (
		<div className="event-row">
			<div className="date">
				<Icon /><span className="h4">{formatDate(e.eventStartDate, "dd MMM")}</span>
			</div>
			<div className="body">
				<Link className="h4" to={`/my-community/events/${e.eventId}`}>{e.title}</Link>
				<span className="label">{e.channel}</span>
				<div className="description">{stripHtml(e.description)}</div>
				<span className="time">
					<ClockInIcon style={{ marginRight: 5 }} />
					Start: {formatDate(e.eventStartDate, "hh:mm a")}
					<ClockOutIcon style={{ margin: "0 5px 0px 10px" }} />
					Finish: {formatDate(e.eventEndDate, "hh:mm a")}
				</span>
			</div>
		</div>
	);
});

EventRow.propTypes = {
	event: PropTypes.object.isRequired
};

export default EventRow;