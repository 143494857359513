import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ReportLayout = props => {

	React.useEffect(() => {
		document.documentElement.className = document.documentElement.className + ' report-layout';
	}, []);

	return (
		<main 
			role="main" 
			className={classNames("report-layout", { 
				"portrait": props.orientation === 'Portrait',
				"landscape": props.orientation === 'Landscape'
			})} 
		>
			{props.children}
		</main>
	);
};

ReportLayout.propTypes = {
	orientation: PropTypes.any
};

ReportLayout.defaultProps = {
	orientation: 'Portrait'
};

export default ReportLayout;
