import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions,
	Grid
} from '@mui/material';
import Button from './Button';
import { withStyles } from 'tss-react/mui';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { withForm } from '../../utils/forms';
import Form from './Form';
import map from 'lodash/map';

const styles = (theme) => ({
	paper: {
		overflow: 'visible'
	}
});

const form = {
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "userName",
			label: "Username",
			validate: value => value.match(/^[a-zA-Z0-9_. ]+$/i) ? "" : "Username must contain no special characters",
			required: true
		},
		{
			name: "password",
			label: "Password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters",
			required: props => props.isNew
		},
		{
			name: "firstName",
			label: "First name",
			required: true
		},
		{
			name: "lastName",
			label: "Last name",
			required: true
		},
		{
			name: "email",
			label: "Email address",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid address",
			required: true
		},
		{
			name: "department",
			label: "Business unit",
			type: "autoComplete",
			required: true,
			items: props => props.departments
		},
		{
			name: "position",
			label: "Position",
			type: "autoComplete",
			required: true,
			items: props => props.positions
		},
		{
			name: "roles",
			label: "Roles",
			type: "autoComplete",
			loadItems: {
				route: props => `roles/by-tenant/${props.tenantId}`,
				mapItem: r => ({ label: r.name, value: r.roleId })
			},
			isMulti: true,
			required: true,
			getValue: values => map(values.roles, "roleId"),
			setValues: (newValue, values) => ({ ...values, roles: map(newValue, r => ({ name: r.label, roleId: r.value })) })
		}
	]
};

const UserDialog = props => {
	const classes = props.classes;
	const { fields, values, isNew } = props;

	React.useEffect(() => {
		props.updateValues(props.user);
	}, props.user);

	const onClose = () => {
		props.onCancel();
	};
	const onSubmit = () => {
		if (props.validateFields()) {
			props.onOk(values);
		} 
	}
	console.log('user dialog');

	return (
		<Dialog fullWidth={true} maxWidth="lg" open={props.show} onClose={onClose} style={{ zIndex: 9990 }} classes={classes}>
			<DialogTitle>{props.isNew ? "Add User" : "Edit User"}</DialogTitle>
			<DialogContent style={{ }}>
				<Form 
					onSubmit={onSubmit}
					style={{ textAlign: "center" }}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>{fields.firstName()}</Grid>
						<Grid item xs={12} md={6}>{fields.lastName()}</Grid>
						<Grid item xs={12} md={6}>{fields.userName()}</Grid>
						<Grid item xs={12} md={6}>{fields.password()}</Grid>
						<Grid item xs={12} md={6}>{fields.email()}</Grid>
						<Grid item xs={12} md={6}>{fields.department()}</Grid>
						<Grid item xs={12} md={6}>{fields.position()}</Grid>
						<Grid item xs={12} md={6}>{fields.roles()}</Grid>
					</Grid>
				</Form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
	 			<Button onClick={onSubmit}>{isNew ? "Add" : "Update"}</Button>
			</DialogActions>
		</Dialog>
	);
};

UserDialog.propTypes = {
	tenantId: PropTypes.number.isRequired,
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

UserDialog.defaultProps = {
	show: false
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default withStyles(
	connect(mapStateToProps, mapDispatchToProps)(withForm(form)(UserDialog)),
	styles
	);
