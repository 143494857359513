import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import addMonths from 'date-fns/addMonths';
import isFuture from 'date-fns/isFuture';
import format from 'date-fns/format';

const defaultExpiryDate = format(addMonths(new Date(), 1), "yyyy-MM-dd");

const form = {
	initValues: props => ({ expiryDate: defaultExpiryDate, ...props.offer }),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Provide a descriptive name to assist others in finding your offer"
		},
		{
			name: "expiryDate",
			label: "Closing Date",
			type: "date",
			clearable: true,
			stripTime: true,
			validate: value => isFuture(new Date(value)) ? "" : "Closing Date must occur after today"
		},
		{
			name: "description",
			label: "Description",
			required: true,
			type: "textEditor",
			placeholder: "Concise description of the offer..."
		},
		{
			name: "requirements",
			label: "Requirements",
			required: true,
			type: "textEditor",
			placeholder: "Contact set of requirements for applicants..."
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor",
			placeholder: "Please enter any relevant tags for this offer..."
		},
		{
			name: "status",
			label: "Status",
			type: "select",
			items: [
				{ label: "Open", value: "open" },
				{ label: "Closed", value: "closed" }
			]
		},
		{ 
			name: "coverImage",
			required: true,
			label: "Cover Image",
			type: "imageSelector",
			images: [
				"/images/offers/p1Low.png", 
				"/images/offers/p2Low.png", 
				"/images/offers/p3Low.png", 
				"/images/offers/p4Low.png", 
				"/images/offers/p5Low.png"
			]
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Offer/DownloadAttachment?offerId=${attachment.offerId}&attachmentId=${attachment.attachmentId}`, "_self")
		},
		{
			name: "additionalChallengeDetails",
			label: "Additional Match Details",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Offer/DownloadAdditionalChallengeDetails?offerId=${attachment.offerId}&additionalChallengeDetailsId=${attachment.offerAdditionalChallengeDetailsId}`, "_self")
		},
		{
			name: "nonDisclosureAgreement",
			label: "Non Disclosure Agreement",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Offer/DownloadNonDisclosureAgreement?offerId=${attachment.offerId}&nonDisclosureAgreementId=${attachment.offerNonDisclosureAgreementId}`, "_self")
		},
		{
			name: "areasOfExpertise",
			label: "Area Of Expertise",
			type: "areaOfExpertiseEditor"
		}
	]
};

const EditOffer = props => {
	const { offer, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} offer`} loading={loading} className="edit-offer">
			<Form onSubmit={() => props.validateFields() && props.saveOffer(props.values, props.formFiles)}>
				<Grid container spacing={3}>
					<Grid item xs={6}>{fields.title()}</Grid>
					<Grid item xs={6}>{fields.areasOfExpertise()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.requirements()}</Grid>
					<Grid item xs={12}>{fields.coverImage()}</Grid>
					<Grid item xs={12} sm={6}>{fields.expiryDate()}</Grid>
					{!isNew && <Grid item xs={12} sm={6}>{fields.status()}</Grid>}
					<Grid item xs={12}>{fields.tags()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? props.submitText : "Update offer"}</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteOffer(offer.offerId)}>
							Delete Offer
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditOffer.propTypes = { 
	offer: PropTypes.object,
	saveOffer: PropTypes.func.isRequired,
	deleteOffer: PropTypes.func,
	saveResult: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	submitText: PropTypes.string,
	isNew: PropTypes.bool,
};

EditOffer.defaultProps = {
	offer: {},
	deleteOffer: () => null,
	submitText: "",
	isNew: false
};

export default withForm(form)(EditOffer);
