import { createFetchAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import map from 'lodash/map';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	skillsProfiles: [],
	skillsProfile: {},
	groups: [],
	group: {}
};

const CLEAR_SKILLS_PROFILES = "CLEAR_SKILLS_PROFILES";
const GET_SKILLS_PROFILES_REQUEST = "GET_SKILLS_PROFILES_REQUEST";
const GET_SKILLS_PROFILES_SUCCESS = "GET_SKILLS_PROFILES_SUCCESS";
const GET_SKILLS_PROFILES_FAILURE = "GET_SKILLS_PROFILES_FAILURE";
const SEARCH_SKILLS_PROFILES_REQUEST = "SEARCH_SKILLS_PROFILES_REQUEST";
const SEARCH_SKILLS_PROFILES_SUCCESS = "SEARCH_SKILLS_PROFILES_SUCCESS";
const SEARCH_SKILLS_PROFILES_FAILURE = "SEARCH_SKILLS_PROFILES_FAILURE";
const GET_SKILLS_PROFILE_REQUEST = "GET_SKILLS_PROFILE_REQUEST";
const GET_SKILLS_PROFILE_SUCCESS = "GET_SKILLS_PROFILE_SUCCESS";
const GET_SKILLS_PROFILE_FAILURE = "GET_SKILLS_PROFILE_FAILURE";
const SAVE_SKILLS_PROFILE_REQUEST = "SAVE_SKILLS_PROFILE_REQUEST";
const SAVE_SKILLS_PROFILE_SUCCESS = "SAVE_SKILLS_PROFILE_SUCCESS";
const SAVE_SKILLS_PROFILE_FAILURE = "SAVE_SKILLS_PROFILE_FAILURE";
const GET_PROFILE_GROUPS_REQUEST = "GET_PROFILE_GROUPS_REQUEST";
const GET_PROFILE_GROUPS_SUCCESS = "GET_PROFILE_GROUPS_SUCCESS";
const GET_PROFILE_GROUPS_FAILURE = "GET_PROFILE_GROUPS_FAILURE";
const GET_PROFILE_GROUP_REQUEST = "GET_PROFILE_GROUP_REQUEST";
const GET_PROFILE_GROUP_SUCCESS = "GET_PROFILE_GROUP_SUCCESS";
const GET_PROFILE_GROUP_FAILURE = "GET_PROFILE_GROUP_FAILURE";
const SAVE_PROFILE_GROUP_REQUEST = "SAVE_PROFILE_GROUP_REQUEST";
const SAVE_PROFILE_GROUP_SUCCESS = "SAVE_PROFILE_GROUP_SUCCESS";
const SAVE_PROFILE_GROUP_FAILURE = "SAVE_PROFILE_GROUP_FAILURE";

export const isLoading = createLoadingSelector([
	"GET_SKILLS_PROFILES", 
	"SEARCH_SKILLS_PROFILES", 
	"GET_SKILLS_PROFILE", 
	"GET_PROFILE_GROUPS", 
	"GET_PROFILE_GROUP",
	"SAVE_PROFILE_GROUP"
]);

export const getSkillsProfiles = (organisationId, onSuccess) =>
	createFetchAction({
		url: `/api/skills-tracker/profiles?organisationId=${organisationId || 0}`,
		startAction: GET_SKILLS_PROFILES_REQUEST,
		onError: error => [getSkillsProfilesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getSkillsProfilesSuccess(data);
		}
	});

export const getSkillsProfilesByTenant = onSuccess =>
	createFetchAction({
		url: "/api/skills-tracker/profiles-by-tenant",
		startAction: GET_SKILLS_PROFILES_REQUEST,
		onError: error => [getSkillsProfilesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getSkillsProfilesSuccess(data);
		}
	});

export const getSkillsProfilesSuccess = data => ({ type: GET_SKILLS_PROFILES_SUCCESS, payload: { data } });
export const getSkillsProfilesFailure = error => ({ type: GET_SKILLS_PROFILES_FAILURE, payload: { error } });

export const searchSkillsProfiles = args => 
	createFetchAction({
		url: `/api/skills-tracker/profiles/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_SKILLS_PROFILES_REQUEST,
		onError: error => [searchSkillsProfilesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchSkillsProfilesSuccess(data)
	});

export const searchSkillsProfilesSuccess = data => ({ type: SEARCH_SKILLS_PROFILES_SUCCESS, payload: { data } });
export const searchSkillsProfilesFailure = error => ({ type: SEARCH_SKILLS_PROFILES_FAILURE, payload: { error } });

export const getSkillsProfile = (skillsProfileId, onSuccess, onError) =>
	createFetchAction({
		url: `/api/skills-tracker/profiles/${skillsProfileId}`,
		startAction: GET_SKILLS_PROFILE_REQUEST,
		onError: error => {
			if (onError) onSuccess(error);
			return [getSkillsProfileFailure(error), showErrorNotification(error.message)];
		},
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getSkillsProfileSuccess(data);
		}
	});

export const getSkillsProfileSuccess = data => ({ type: GET_SKILLS_PROFILE_SUCCESS, payload: { data } });
export const getSkillsProfileFailure = error => ({ type: GET_SKILLS_PROFILE_FAILURE, payload: { error } });

export const saveSkillsProfile = (skillsProfile, imageFile) => {
	const formData = new FormData();
	formData.append("SkillsProfile", encodeURIComponent(JSON.stringify(skillsProfile)));

	if (imageFile) formData.append("ImageFile", imageFile);

	return createFormPostAction({
		url: "/api/skills-tracker/profiles",
		data: formData,
		startAction: SAVE_SKILLS_PROFILE_REQUEST,
		onError: error => [saveSkillsProfileFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveSkillsProfileSuccess(data), showSuccessNotification(data.message), changeLocation(`/admin/skills-profile/${data.object.profileId}`)];
			} else {
				return [saveSkillsProfileSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveSkillsProfileSuccess = data => ({ type: SAVE_SKILLS_PROFILE_SUCCESS, data });
export const saveSkillsProfileFailure = error => ({ type: SAVE_SKILLS_PROFILE_FAILURE, error });

export const getProfileGroups = (organisationId, onSuccess) =>
	createFetchAction({
		url: `/api/skills-tracker/profile-groups?organisationId=${organisationId || 0}`,
		startAction: GET_PROFILE_GROUPS_REQUEST,
		onError: error => [getProfileGroupsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getProfileGroupsSuccess(data);
		}
	});

export const getProfileGroupsSuccess = data => ({ type: GET_PROFILE_GROUPS_SUCCESS, payload: { data } });
export const getProfileGroupsFailure = error => ({ type: GET_PROFILE_GROUPS_FAILURE, payload: { error } });

export const getProfileGroup = (groupId, onSuccess) =>
	createFetchAction({
		url: `/api/skills-tracker/profile-groups/${groupId}`,
		startAction: GET_PROFILE_GROUP_REQUEST,
		onError: error => [getProfileGroupFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getProfileGroupSuccess(data);
		}
	});

export const getProfileGroupSuccess = data => ({ type: GET_PROFILE_GROUP_SUCCESS, payload: { data } });
export const getProfileGroupFailure = error => ({ type: GET_PROFILE_GROUP_FAILURE, payload: { error } });

export const saveProfileGroup = (profileGroup, imageFile) => {
	const formData = new FormData();
	formData.append("ProfileGroup", encodeURIComponent(JSON.stringify(profileGroup)));

	if (imageFile) formData.append("ImageFile", imageFile);

	return createFormPostAction({
		url: "/api/skills-tracker/profile-groups",
		data: formData,
		startAction: SAVE_PROFILE_GROUP_REQUEST,
		onError: error => [saveProfileGroupFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveProfileGroupSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/profile-groups")];
			} else {
				return [saveProfileGroupSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveProfileGroupSuccess = data => ({ type: SAVE_PROFILE_GROUP_SUCCESS, data });
export const saveProfileGroupFailure = error => ({ type: SAVE_PROFILE_GROUP_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_SKILLS_PROFILES:
			return { ...state, skillsProfiles: [] };
		case GET_SKILLS_PROFILES_REQUEST:
			return { ...state, skillsProfiles: [], isLoading: true };
		case SEARCH_SKILLS_PROFILES_REQUEST:
			return { ...state, isLoading: true }
		case GET_SKILLS_PROFILES_SUCCESS:
		case SEARCH_SKILLS_PROFILES_SUCCESS:
			return { ...state, skillsProfiles: action.payload.data, isLoading: false };
		case GET_SKILLS_PROFILE_REQUEST:
			return { ...state, skillsProfile: {}, isLoading: true }
		case GET_SKILLS_PROFILE_SUCCESS:
			return { ...state, skillsProfile: action.payload.data, isLoading: false };
		case SAVE_SKILLS_PROFILE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_SKILLS_PROFILE_SUCCESS:
			return {
				...state,
				...(action.data.success && { skillsProfile: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case GET_PROFILE_GROUPS_REQUEST:
			return { ...state, groups: [], isLoading: true }
		case GET_PROFILE_GROUPS_SUCCESS:
			return { ...state, groups: action.payload.data, isLoading: false };
		case GET_PROFILE_GROUP_REQUEST:
			return { ...state, group: {}, isLoading: true }
		case GET_PROFILE_GROUP_SUCCESS:
			return { ...state, group: action.payload.data, isLoading: false };
		case SAVE_PROFILE_GROUP_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_PROFILE_GROUP_SUCCESS:
			return {
				...state,
				...(action.data.success && { group: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		default:
			return state;
	}
};
