import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withForm } from '../../utils/forms';
import { 
	requestCourse, 
	isLoading 
} from '../../store/courses';
import { Grid } from '@mui/material';
import Card from '../widgets/Card';
import Button from '../widgets/Button';
import PageLayout from '../PageLayout';
import Form from '../widgets/Form';

const form = {
	fields: [
		{
			name: "title",
			label: "Course Title",
			required: true
		},
		{
			name: "description",
			label: "Course Description",
			required: true
		},
		{
			name: "notes",
			label: "Notes",
			type: 'textEditor',
			helpText: "Please enter any additional comments you may have"
		},
		{
			name: "organisationId",
			label: "Provider",
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				filter: org => org.type === "Institution" && org.contactEmail,
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		}

	]
}

const CustomCourse = props => {
	const { loading, fields } = props;

	const content = (
		<Card>
			<Form onSubmit={() => props.validateFields() && props.requestCourse(props.values)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.title()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.organisationId()}</Grid>
					<Grid item xs={12}>{fields.notes()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>Send</Button>
				</div>
			</Form>
		</Card>
	);

	return <PageLayout title="Request Custom Course" pageContent={content} breadcrumbs={[
		{ label: "Courses", path: "/courses" },
		"Request Custom Course"
	]} />
};

CustomCourse.propTypes = { 
	loading: PropTypes.bool.isRequired,
	requestCourse: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	requestCourse: course => dispatch(requestCourse(course))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CustomCourse));
