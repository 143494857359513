import React from 'react';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
	list: {
		listStylePosition: 'inside',
		padding: 0,
		margin: 0
	}
});

const UpgradeIpController = props => {
	// const classes = props.classes;

	return (
		<PageLayout 
			title="Upgrade to Access" 
			pageContent={
				<div style={{
					maxWidth: '1000px',
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto'
				}}>
					<p style={{ }}>
						<span style={{ fontSize: 'large', marginRight: '10px', fontWeight: 'bold' }}>IP</span>
					</p>
					<ButtonLink 
						to="/pricing" 
					>Upgrade</ButtonLink>
				</div>
			} 
			breadcrumbs={[
				"Upgrade"
			]} />
	);
};

UpgradeIpController.propTypes = {
};

export default withStyles(UpgradeIpController, styles, { withTheme: true });
