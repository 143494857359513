import React from 'react';
import { Divider, Typography } from '@mui/material';
import { FacebookButton, LinkedInButton, TwitterButton } from '../widgets/SocialMediaButtons';
import AutoComplete from '../widgets/AutoComplete';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';

const ContactInfo = props => {
	const { profile } = props,
		{ phone, address, facebook, twitter, linkedIn, registrationDate } = profile;

	return (
		<Card className="contact-info" bodyStyle={{ padding: 0 }}>
			<div className="content">
				{/* Quick Info */}
				<div style={{ textAlign: "center" }}>
					{/* Avatar */}
					<Avatar src={profile.avatar} fullName={profile.fullName} length={150} />
					{/* Name + Role */}
					<div className="titles">
						<Typography variant="h6">{profile.fullName}</Typography>
						{profile.organisationTitle && <span>{profile.organisationTitle}</span>}
						<span>{profile.positionTitle}</span>
					</div>
					{props.showMembershipLevelId && 
						<AutoComplete
							label="Membership Level"
							value={props.membershipLevelId}
							onChange={props.changeMembershipLevel}
							loadItems={{
								route: `organisations/membership-levels?organisationId=${props.organisationId}`,
								mapItem: level => ({ label: level.title, value: level.membershipLevelId })
							}}
						/>
					}
				</div>
				<Divider />
				{/* User Details */}
				<div>
					{/* Email */}
					<Typography variant="caption">Email</Typography>
					<Typography variant="body2" className="email">{props.email}</Typography>
					{/* Phone */}
					{phone &&
						<section>
							<Typography variant="caption">Phone</Typography>
							<Typography variant="body2">{phone}</Typography>
						</section>
					}
					{/* Address */}
					{address &&
						<section>
							<Typography variant="caption">Address</Typography>
							<Typography variant="body2">{address}</Typography>
							{/* Google Maps Integration */}
							<iframe title="map" src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(address)}&key=AIzaSyBUxOdI5yTXpvc6iwXBV6ViE2uqcU6vZDg`} width="100%" allowFullScreen />
						</section>
					}
					{/* Registration date */}
					{registrationDate && 
						<React.Fragment>
							<Typography variant="caption">Registration date</Typography>
							<Typography variant="body2">{new Date(registrationDate).toDateString()}</Typography>
						</React.Fragment>
					}
					{/* Social Links */}
					{(facebook || twitter || linkedIn) &&
						<section>
							<Typography variant="caption">Social links</Typography>
							{facebook && <FacebookButton length={35} link={`https://www.facebook.com/${facebook}`} style={{ marginRight: 10 }}></FacebookButton>}
							{twitter && <TwitterButton length={35} link={`https://www.twitter.com/${twitter}`} style={{ marginRight: 10 }}></TwitterButton>}
							{linkedIn && <LinkedInButton length={35} link={`https://www.linkedin.com/in/${linkedIn}`}></LinkedInButton>}
						</section>
					}
				</div>
			</div>
		</Card>
	);
};

export default ContactInfo;