import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDepartments, isLoading } from '../../store/departments';
import { hasFunction } from '../../store/selectors/functions';
import { canEditDepartment } from '../../store/security';
import PageLayout from '../PageLayout';
import Departments from './Departments';

const DepartmentsController = props => {
	React.useEffect(props.getDepartments, []);

	return <PageLayout title="Business Units" pageContent={
		<Departments 
			canEdit={props.canEdit}
			canViewOrganisations={props.canViewOrganisations}
			departments={props.departments} 
			loading={props.loading}
			primaryColour={props.primaryColour}
		/>
	} breadcrumbs={["Business Units"]} />;
};

DepartmentsController.propTypes = { 
	canEdit: PropTypes.bool.isRequired,
	canViewOrganisations: PropTypes.bool.isRequired,
	departments: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	canEdit: canEditDepartment(state),
	canViewOrganisations: hasFunction(state, "OrganisationsView")(state),
	departments: state.departments.departments,
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getDepartments: () => dispatch(getDepartments())
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsController);
