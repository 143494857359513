import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMentor, isLoading } from '../../store/mentors';
import { canEditMentor } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewMentor from './ViewMentor';

const ViewMentorController = props => {
	React.useEffect(() => props.getMentor(props.match.params.mentorId), [props.match.params.mentorId]);

	return <PageLayout title="View mentor" pageContent={
		<ViewMentor mentor={props.mentor} loading={props.loading} canEdit={props.canEdit} />
	} breadcrumbs={[{ label: "Mentors", path: "/mentors" }, "View mentor"]} />;
};

ViewMentorController.propTypes = {
	mentor: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	mentor: state.mentors.mentor,
	loading: isLoading(state),
	canEdit: canEditMentor(state)
});

const mapDispatchToProps = dispatch => ({
	getMentor: mentorId => dispatch(getMentor(mentorId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMentorController);