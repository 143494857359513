import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uniqueId from 'lodash/uniqueId';
import { default as formatDate } from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { toNumber } from '../../utils/utils';
import toUpper from 'lodash/toUpper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import parseISO from 'date-fns/parseISO';
import isValidDate from 'date-fns/isValid';
import isString from 'lodash/isString';
import isDate from 'lodash/isDate';
import moment from 'moment';

const timeRegex = /^([0-9]+):([0-9][0-9])\s*([AP]M)*$/i;

// Fails in IE because of array destructuring
const getTimeFromString = timeString => {
	if (!timeString) return null;

	let [hours, minutes, period] = timeRegex.exec(timeString.trim());

	if (hours && minutes) {
		hours = toNumber(hours);
		minutes = toNumber(minutes);

		// Add 12 hours if representing 24 hour time
		if (period && toUpper(period) === "PM" && hours > 12) hours += 12;

		return { hours, minutes };
	}

	return null;
}


const DateField = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("date-field-"));
	const [state, setState] = React.useState({ focused: false, time: null, timeIsValid: true });
	let { value, errorColour } = props;
	let dateValue = null;
	if (value) {
		if (isString(value)) dateValue = parseISO(value);
		if (isDate(value)) dateValue = value;
	} else if (value === "") {
		value = null; // "" triggers invalid date validation, we just want to leave blank in this case
	}

	const setDate = newDateMoment => {
		if (newDateMoment === null && props.clearable) {
			props.onChange(newDateMoment);
			return;
		}

		const newDate = newDateMoment.toDate();

		if (!isValid(newDate)) return;
		
		
		// Set time component
		if (props.showTime) {
			const newTime = getTimeFromString(state.time);

			if (newTime) {
				newDate.setHours(newTime.hours);
				newDate.setMinutes(newTime.minutes);
			}
		}

		props.onChange(newDate);
	}

	const setTime = inputValue => {
		const newTime = getTimeFromString(inputValue);

		if (newTime) {
			setState({ ...state, time: inputValue, timeIsValid: true });

			if (value) {
				let newDate = value;
				newDate.setHours(newTime.hours);
				newDate.setMinutes(newTime.minutes);
				props.onChange(newDate);
			}
		} else {
			setState({ ...state, time: inputValue, timeIsValid: false });
		}
	}


	// IE11 doesn't have a native time input so in the case of an invalid
	// time entered we will track the input state so that they can edit
	// their input. The actual time will only be updated when a valid time is 
	// entered
	let time = null;
	if (state.time !== null) {
		time = state.time;
	} else if (isValidDate(dateValue)) {
		time = formatDate(dateValue, "HH:mm");
	}
// 			{props.showTime && !state.timeIsValid && <FormHelperText>Invalid time entered</FormHelperText>}

	let helperText = props.helpText;

	if (props.showTime && !state.timeIsValid) {
		helperText = helperText ? <><span>{helperText}</span><br /><span>Invalid time entered</span></> : helperText;
	}

	return (
		<span className="date-field">
				<DatePicker
					id={id}
					label={<span>{props.label} {props.required && <span style={{ color: errorColour }}> *</span>}</span>}
					value={value  && value !== '' ? moment(value) : null}
					onChange={setDate}
					inputFormat={props.format}
					views={props.views}
					slotProps={{
						textField: {
							error: props.error,
							helperText: helperText
						}
					}}
				/>
 				{props.showTime &&
 					<input 
 						type="time" 
 						className="time-input" 
						value={time || ""}
						aria-label="Time"
						onChange={e => setTime(e.target.value)}
 					/>
 				}
		</span>
	);
});

DateField.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string
	]),
	onChange: PropTypes.func,
	required: PropTypes.bool,
	error: PropTypes.bool,
	placeholder: PropTypes.string,
	helpText: PropTypes.node,
	showTime: PropTypes.bool,
	disabled: PropTypes.bool,
	format: PropTypes.string,
	views: PropTypes.array,
	clearable: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

DateField.defaultProps = {
	id: "",
	name: "",
	type: "text",
	label: "",
	onChange: () => {},
	required: false,
	error: false,
	placeholder: "",
	helpText: "",
	value: null,
	showTime: false,
	disabled: false,
	format: "dd/MM/yyyy",
	views: ["year", "month", "date"],
	clearable: false
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DateField);



// import React from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import uniqueId from 'lodash/uniqueId';
// import { default as formatDate } from 'date-fns/format';
// import isValid from 'date-fns/isValid';
// import { toNumber } from '../../utils/utils';
// import toUpper from 'lodash/toUpper';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import parseISO from 'date-fns/parseISO';
// import isValidDate from 'date-fns/isValid';
// import isString from 'lodash/isString';
// import isDate from 'lodash/isDate';
// import moment from 'moment';

// const timeRegex = /^([0-9]+):([0-9][0-9])\s*([AP]M)*$/i;

// // Fails in IE because of array destructuring
// // const getTimeFromString = timeString => {
// // 	if (!timeString) return null;

// // 	let [hours, minutes, period] = timeRegex.exec(timeString.trim());

// // 	if (hours && minutes) {
// // 		hours = toNumber(hours);
// // 		minutes = toNumber(minutes);

// // 		// Add 12 hours if representing 24 hour time
// // 		if (period && toUpper(period) === "PM" && hours > 12) hours += 12;

// // 		return { hours, minutes };
// // 	}

// // 	return null;
// // }

// const getTimeFromString = timeString => {
// 	if (!timeString) return null;

// 	const matches = timeRegex.exec(timeString.trim());
// 	if (matches !== null) {
// 		let hours = toNumber(matches[1]);
// 		const minutes = toNumber(matches[2]);

// 		if (matches[3] && toUpper(matches[3]) === "PM" && hours > 12) hours += 12;

// 		return { hours, minutes };
// 	}

// 	return null;
// }

// const DateField = React.memo(props => {
// 	const [id] = React.useState(props.id || uniqueId("date-field-"));
// 	const [state, setState] = React.useState({ focused: false, time: null, timeIsValid: true });
// 	let { value, errorColour } = props;
// 	let dateValue = null;
// 	if (value) {
// 		if (isString(value)) dateValue = parseISO(value);
// 		if (isDate(value)) dateValue = value;
// 	} else if (value === "") {
// 		value = null; // "" triggers invalid date validation, we just want to leave blank in this case
// 	}

// 	const setDate = newDateMoment => {
// 		if (newDateMoment === null && props.clearable) {
// 			props.onChange(newDateMoment);
// 			return;
// 		}

// 		const newDate = newDateMoment.toDate();

// 		if (!isValid(newDate)) return;
		
// 		// Set time component
// 		if (props.showTime) {
// 			const newTime = getTimeFromString(state.time);

// 			if (newTime) {
// 				newDate.setHours(newTime.hours);
// 				newDate.setMinutes(newTime.minutes);
// 			}
// 		}

// 		props.onChange(newDate);
// 	}
	
// 	const setTime = inputValue => {
// 		const newTime = getTimeFromString(inputValue);

// 		if (newTime) {
// 			setState({ ...state, time: inputValue, timeIsValid: true });

// 			if (value) {
// 				let newDate = value;
// 				newDate.setHours(newTime.hours);
// 				newDate.setMinutes(newTime.minutes);
// 				props.onChange(newDate);
// 			}
// 		} else {
// 			setState({ ...state, time: inputValue, timeIsValid: false });
// 		}
// 	}

// 	// IE11 doesn't have a native time input so in the case of an invalid
// 	// time entered we will track the input state so that they can edit
// 	// their input. The actual time will only be updated when a valid time is 
// 	// entered
// 	let time = null;
// 	if (state.time !== null) {
// 		time = state.time;
// 	} else if (isValidDate(dateValue)) {
// 		time = formatDate(dateValue, "HH:mm");
// 	}

// 	console.log(moment(value), value);
// 	return (
// 		<FormControl fullWidth error={props.error || (props.showTime && !state.timeIsValid)} style={props.style} className="date-field">
// 			{props.label &&
// 				<InputLabel htmlFor={id} shrink {...props.InputLabelProps}>
// 					{props.label}
// 					{props.required && <span style={{ color: errorColour }}> *</span>}
// 				</InputLabel>
// 			}
// 			<div style={{ marginTop: "0px" }}>
// 				<DatePicker
// 					id={id}
// 					placeholder={props.placeholder}
// 					disableToolbar
// 					variant="inline"
// 					margin="normal"
// 					value={moment(value)}
// 					onChange={setDate}
// 					inputFormat={props.format}
// 					views={props.views}
// 				/>
// 				{props.showTime &&
// 					<input 
// 						type="time" 
// 						className="time-input" 
// 						value={time || ""}
// 						aria-label="Time"
// 						onChange={e => setTime(e.target.value)}
// 					/>
// 				}
// 			</div>
// 			{props.showTime && !state.timeIsValid && <FormHelperText>Invalid time entered</FormHelperText>}
// 			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
// 		</FormControl>
// 	);
// });

// DateField.propTypes = {
// 	id: PropTypes.string,
// 	name: PropTypes.string,
// 	type: PropTypes.string,
// 	label: PropTypes.string,
// 	value: PropTypes.oneOfType([
// 		PropTypes.instanceOf(Date),
// 		PropTypes.string
// 	]),
// 	onChange: PropTypes.func,
// 	required: PropTypes.bool,
// 	error: PropTypes.bool,
// 	placeholder: PropTypes.string,
// 	helpText: PropTypes.node,
// 	showTime: PropTypes.bool,
// 	disabled: PropTypes.bool,
// 	format: PropTypes.string,
// 	views: PropTypes.array,
// 	clearable: PropTypes.bool,
// 	errorColour: PropTypes.string.isRequired
// };

// DateField.defaultProps = {
// 	id: "",
// 	name: "",
// 	type: "text",
// 	label: "",
// 	onChange: () => {},
// 	required: false,
// 	error: false,
// 	placeholder: "",
// 	helpText: "",
// 	value: null,
// 	showTime: false,
// 	disabled: false,
// 	format: "dd/MM/yyyy",
// 	views: ["year", "month", "date"],
// 	clearable: false
// };

// const mapStateToProps = state => ({
// 	errorColour: state.theme.errorColour
// });

// const mapDispatchToProps = dispatch => ({
// });

// export default connect(mapStateToProps, mapDispatchToProps)(DateField);
