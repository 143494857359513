import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';
import filter from 'lodash/filter';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};
const initialState = {
	saveResult: emptySaveResult,
	position: null, 
	positions: [],
	isVoting: {}
};

const newPosition = {
	'positionId': 0
};

const CREATE_NEW_POSITION = 'CREATE_NEW_POSITION';
const CLEAR_POSITION = 'CLEAR_POSITION';
const CLEAR_POSITIONS = 'CLEAR_POSITIONS';
const GET_POSITION_REQUEST = 'GET_POSITION_REQUEST';
const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS';
const GET_POSITION_FAILURE = 'GET_POSITION_FAILURE';
const GET_POSITIONS_REQUEST = 'GET_POSITIONS_REQUEST';
const GET_POSITIONS_SUCCESS = 'GET_POSITIONS_SUCCESS';
const GET_POSITIONS_FAILURE = 'GET_POSITIONS_FAILURE';
const SAVE_POSITION_REQUEST = 'SAVE_POSITION_REQUEST';
const SAVE_POSITION_SUCCESS = 'SAVE_POSITION_SUCCESS';
const SAVE_POSITION_FAILURE = 'SAVE_POSITION_FAILURE';
const DELETE_POSITION_REQUEST = 'DELETE_POSITION_REQUEST';
const DELETE_POSITION_SUCCESS = 'DELETE_POSITION_SUCCESS';
const DELETE_POSITION_FAILURE = 'DELETE_POSITION_FAILURE';

export const isLoading = createLoadingSelector([ 'GET_POSITIONS', 'GET_POSITION', 'DELETE_POSITION', 'SAVE_POSITION', 'SEARCH_POSITIONS' ]);

export const createNewPosition = () => ({ type: CREATE_NEW_POSITION });
export const clearPositions = () => ({ type: CLEAR_POSITIONS });
export const clearPosition = () => ({ type: CLEAR_POSITION });

export const getPositions = () => (
	createFetchAction({
		url: '/api/positions',
		startAction: GET_POSITIONS_REQUEST,
		onError: (error) => [getPositionsFailure(error), showErrorNotification(error.message)],
		onSuccess: (data) => getPositionsSuccess(data)
	})
);

export const getPositionsSuccess = (data) => ({ type: GET_POSITIONS_SUCCESS, payload: { data } });
export const getPositionsFailure = (error) => ({ type: GET_POSITIONS_FAILURE, payload: { error } });

export const getPosition = (positionId) => (
	createFetchAction({
		url: `/api/positions/${positionId}`,
		startAction: GET_POSITION_REQUEST,
		onError: (error) => [getPositionFailure(error), showErrorNotification(error.message)],
		onSuccess: (data) => getPositionSuccess(data)
	})
);

export const getPositionSuccess = (data) => ({ type: GET_POSITION_SUCCESS, payload: { data } });
export const getPositionFailure = (error) => ({ type: GET_POSITION_FAILURE, payload: { error } });

export const savePosition = (position, onSuccess) => {
	const errors = [];

	if (!position.title) {
		errors.push({
			fieldName: 'Title',
			valid: false,
			message: 'Title is required'
		});
	}

	if (errors.length > 0) return { type: SAVE_POSITION_SUCCESS, data: errors };

	return createPostAction({
		url: '/api/positions',
		data: position,
		startAction: SAVE_POSITION_REQUEST,
		onError: (error) => [savePositionFailure(error), showErrorNotification(error.message)],
		onSuccess: (data) => {
			if (data && data.success) {
				return [savePositionSuccess(data), showSuccessNotification(data.message), changeLocation(`/admin/positions/${data.object.positionId}`)];
			} else {
				return [savePositionSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const savePositionSuccess = (data) => ({ type: SAVE_POSITION_SUCCESS, data });
export const savePositionFailure = (error) => ({ type: SAVE_POSITION_FAILURE, error });

export const deletePosition = (positionId) => (
	createPostAction({
		url: `/api/positions/${positionId}/delete`,
		startAction: DELETE_POSITION_REQUEST,
		onError: (error) => [deletePositionFailure(error), showErrorNotification(error.message)],
		onSuccess: (data) => {
			if (data && data.success) {
				return [deletePositionSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/positions")];
			} else {
				return [deletePositionSuccess(data), showErrorNotification(data.message)];
			}
		}
	})
);
export const deletePositionSuccess = (data) => ({ type: DELETE_POSITION_SUCCESS, data });
export const deletePositionFailure = (error) => ({ type: DELETE_POSITION_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_POSITIONS:
			return {
				...state,
				positions: null
			};
		case CLEAR_POSITION:
			return {
				...state,
				position: null
			};
		case GET_POSITIONS_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				positions: []
			};
		case GET_POSITIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				positions: action.payload.data
			};
		case GET_POSITION_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: emptySaveResult,
				position: { ...newPosition }
			};
		case GET_POSITION_SUCCESS:
			return {
				...state,
				isLoading: false,
				position: action.payload.data
			};
		case DELETE_POSITION_REQUEST:
			return { 
				...state
			};
		case DELETE_POSITION_SUCCESS:
			return {
				...state,
				positions: filter(state.positions, c => c.positionId !== action.data.objectId) 
			};
		case CREATE_NEW_POSITION:
			return {
				...state,
				position: { ...newPosition }
			};	
		case SAVE_POSITION_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: emptySaveResult
			};
		case SAVE_POSITION_SUCCESS:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				positions: addOrUpdate(state.positions, action.data.object, { positionId: action.data.object.positionId }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};

		default:
			return state;
	}
};
