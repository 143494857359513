import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';

const form = {
	initValues: props => props.mentee,
	fields: [
		{
			name: "userId",
			label: "User",
			type: "autoComplete",
			loadItems: {
				route: "users",
				mapItem: u => ({ ...u, label: u.fullName, value: u.userId })
			},
			required: true
		},
		{
			name: "institutionId",
			label: "Institution",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations?type=Institution",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			helpText: "Current institution"
		},
		{
			name: "about",
			label: "About",
			type: "textEditor"
		}
	]
};

const EditMentee = props => {
	const { fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} mentee`} loading={loading} className="edit-mentee">
			<Form onSubmit={() => props.validateFields() && props.saveMentee(props.values, props.formFiles.image)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.userId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.institutionId()}</Grid>
					<Grid item xs={12}>{fields.about()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} mentee</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteMentee(props.mentee.menteeId)}>
							Delete mentee
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditMentee.propTypes = { 
	mentee: PropTypes.object,
	saveMentee: PropTypes.func.isRequired,
	deleteMentee: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditMentee.defaultProps = {
	mentee: {},
	deleteMentee: () => null,
	isNew: false
};

export default withForm(form)(EditMentee);