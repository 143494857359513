import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import { Grid } from '@mui/material';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import map from 'lodash/map';

const ActivityCard = React.memo(({ activity }) =>
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<Card style={{ margin: 0 }} className="activity-card">
			<Link to={`activities/${activity.activityId}`}>
				<span className="h3">{activity.name}</span>
			</Link>
			<div className="summary">{activity.summary}</div>
			<div className="tags">
				{map(activity.tags, (t, i) => <Tag key={i} label={t.name} />)}
			</div>
		</Card>
	</Grid>
);

ActivityCard.propTypes = { 
	activity: PropTypes.shape({
		activityId: PropTypes.number,
		organisationId: PropTypes.number,
		organisationName: PropTypes.string,
		country: PropTypes.string,
		organisationType: PropTypes.string,
		organisationLogo: PropTypes.string,
		name: PropTypes.string,
		summary: PropTypes.string,
		description: PropTypes.string,
		materialsNeeded: PropTypes.array,
		status: PropTypes.string,
		visibility: PropTypes.string,
		images: PropTypes.array,
		videos: PropTypes.array,
		tags: PropTypes.array
	}).isRequired
};

export default ActivityCard;
