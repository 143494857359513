import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { savePosition, isLoading } from '../../store/positions';
import PageLayout from '../PageLayout';
import EditPosition from './EditPosition';

const CreatePositionController = props => 
	<PageLayout title="Create Position" pageContent={
		<EditPosition savePosition={props.savePosition} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Positions", path: "/admin/positions" }, "Create Position"]} />;

CreatePositionController.propTypes = {
	savePosition: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	savePosition: position => dispatch(savePosition(position))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePositionController);
