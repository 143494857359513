import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canEditTool, hasFunction, canEditCommunityTool } from '../../store/security';
import { getTool, saveTool, deleteTool, isLoading } from '../../store/tools';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditTool from './EditTool';

const EditToolController = props => {
	const { toolId } = props.match.params;

	React.useEffect(() => props.getTool(toolId), [toolId]);

	React.useEffect(() => {
		if (props.tool.toolId === toolId && !props.canEdit) props.revokeAccess();
	}, [props.tool.toolId]);

	return <PageLayout title="Edit tool" pageContent={
		<EditTool 
			tool={props.tool}
			saveTool={props.saveTool} 
			deleteTool={props.deleteTool} loading={props.loading}
			canCreatePublicTool={props.canCreatePublicTool}
			isAcademic={props.isAcademic}
			isCommunity={props.isCommunity}
		/>
	} breadcrumbs={[{ label: "Tools", path: `/${props.isCommunity ? "my-community/" : ""}tools` }, "Edit tool"]} />;
};

EditToolController.propTypes = {
	tool: PropTypes.object.isRequired,
	saveTool: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	canCreatePublicTool: PropTypes.bool,
	isAcademic: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool
};

EditToolController.defaultProps = {
	canCreatePublicTool: false,
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	saveResult: state.tools.saveResult,
	tool: state.tools.tool,
	loading: isLoading(state),
	canEdit: ownProps.isCommunity ? canEditCommunityTool(state) : canEditTool(state),
	canCreatePublicTool: state.context.canCreatePublicTool || Boolean(state.context.academicId) || hasFunction("ToolEdit")(state),
	isAcademic: Boolean(state.context.academicId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getTool: toolId => dispatch(getTool(toolId)),
	saveTool: (tool, newFiles) => dispatch(saveTool(tool, newFiles, ownProps.isCommunity)),
	deleteTool: toolId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this tool?",
		onOk: () => dispatch(deleteTool(toolId, ownProps.isCommunity))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditToolController);