import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../../utils/forms';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';
import Button from '../../widgets/Button';
import { menuIcons } from '../../../utils/utils';
import TextField from '../../widgets/TextField';
import Checkbox from '../../widgets/Checkbox';

const drawIconLabel = ref =>
	<span style={{ display: "flex", alignItems: "center", fontWeight: 400, color: "#555" }}>
		{ref}{React.createElement(menuIcons[ref], { style: { marginLeft: 10 } })}
	</span>;

const form = {
	initValues: props => props.menu,
	fields: [
		{
			name: "menuName",
			label: "Name",
			required: true
		},
		{
			name: "description",
			label: "Description",
			multiline: true
		},
		{
			name: "displaySequence",
			label: "Display Sequence",
			type: "number"
		},
		{
			name: "iconRef",
			label: "Icon",
			type: "autoComplete",
			items: Object.keys(menuIcons).reduce((acc, ref) => [...acc, { label: drawIconLabel(ref), value: ref }], [])
		},
		{
			name: "disabled",
			label: "Disabled",
			type: "checkbox"
		},
		{
			name: "disabledText",
			label: "Disabled Text"
		},
		{
			name: "items",
			label: "Items",
			type: "dragList",
			showBorder: true,
			cellStyle: { padding: "10px 5px" },
			itemTemplate: { label: "", path: "", functionId: null, displaySequence: 0, disabled: false, active: true },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={4}>
						<TextField label="Label" value={item.label} onChange={e => updateItem(e.target.value, "label")} />
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField label="Path" value={item.path} onChange={e => updateItem(e.target.value, "path")} />
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField type="number" label="Function ID" value={item.functionId || ""} onChange={e => updateItem(e.target.value, "functionId")} />
					</Grid>
					<Grid item xs={12} md="auto">
						<Checkbox label="Disabled" checked={item.disabled} onChange={e => updateItem(e.target.checked, "disabled")} />
					</Grid>
					<Grid item xs={12} md="auto">
						<Checkbox label="Active" checked={item.active} onChange={e => updateItem(e.target.checked, "active")} />
					</Grid>
				</Grid>
		}
	]
};

const EditMenu = props => {
	const { menu, fields, values, loading, isNew } = props;

	const onSubmit = () => {
		if (props.validateFields) {
			const items = values.items.reduce((acc, item, i) => [...acc, { ...item, displaySequence: (i + 1) * 10 }], []);
			props.saveMenu({ ...values, items });
		}
	}

	return (
		<Card className="edit-menu">
			<Form onSubmit={onSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>{fields.menuName()}</Grid>
					<Grid item xs={12} md={6}>{fields.displaySequence()}</Grid>
					<Grid item xs={12} md={8}>{fields.description()}</Grid>
					<Grid item xs={12} md={4}>{fields.iconRef()}</Grid>
					<Grid item xs={12} md={6}>{fields.disabled()}</Grid>
					<Grid item xs={12} md={6}>{fields.disabledText()}</Grid>
					<Grid item xs={12}>{fields.items()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} menu</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteMenu(menu.menuId)}>
							Delete Menu
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditMenu.propTypes = { 
	menu: PropTypes.object,
	saveMenu: PropTypes.func.isRequired,
	deleteMenu: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditMenu.defaultProps = {
	menu: {},
	deleteMenu: () => null,
	isNew: false
};

export default withForm(form)(EditMenu);