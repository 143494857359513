import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialUIAccordion from '@mui/material/Accordion';
import { AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = props =>
	<MaterialUIAccordion className="accordion-panel">
		<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ color: props.error && props.errorColour }}>
			{props.summary}
		</AccordionSummary>
		<AccordionDetails style={props.innerStyles}>
			{props.children}
		</AccordionDetails>
	</MaterialUIAccordion>;

Accordion.propTypes = {
	summary: PropTypes.string.isRequired,
	innerStyles: PropTypes.object,
	error: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

Accordion.defaultProps = {
	innerStyles: {},
	error: false
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);
