import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Button from '../widgets/Button';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import StatBox from '../widgets/StatBox';
import Checkbox from '../widgets/Checkbox';
import SearchField from '../widgets/SearchField';
import capitalise from 'lodash/capitalize';

const ProblemStats = props => {
	const [state, setState] = React.useState({ search: "", currentTab: props.tab, hideTitle: true });
	const { search, currentTab, hideTitle } = state,
		{ user, single, plural } = props;
	
	return (
		<Card 
			title={`${user ? "User" : capitalise(single)} statistics: ${user ? user.userName : props.problem.title}`}
			subtitle={`View users which endorsed or viewed ${user ? `${plural} submitted by the user` : `the ${single}`}`}
			loading={props.loading}
			className="problem-stats"
		>
			{/* Stats */}
			<div className="row-flex m-t-40">
				<StatBox text="Total endorsements" number={props.endorsements.length} colour="#007C9A" />
				<StatBox text="Total views" number={props.views.length} colour="#7460EE" />
			</div>
			{/* Search Users */}
			<SearchField
				label="Search users" 
				value={search} 
				onChange={search => setState({ ...state, search })} 
				loading={props.loading}
				addPadding
			/>
			<div className="toggle-panel">
				<Button onClick={() => setState({ ...state, currentTab: "endorsements" })} disabled={currentTab === "endorsements"}>
					Endorsements
				</Button>
				<Button onClick={() => setState({ ...state, currentTab: "views" })} disabled={currentTab === "views"}>
					Views
				</Button>
				{user && <Checkbox checked={hideTitle} label="Hide Title" onChange={() => setState({ ...state, hideTitle: !hideTitle })} />}
			</div>
			{/* Datatable */}
			<DataTable data={currentTab === "views" ? props.views : props.endorsements} search={search} enableExport>
				<DataTableColumn name="fullName" label="Name" dataType="avatar" />
				<DataTableColumn name="problemTitle" label={`${capitalise(single)} Title`} hidden={hideTitle} width={400} />
				<DataTableColumn name="email" label="Email address" />
				<DataTableColumn name="positionTitle" label="Position" />
				<DataTableColumn name="viewDate" label="View date" hidden={currentTab === "endorsements"} dataType="date" />
				<DataTableColumn name="voteDate" label="Endorse date" hidden={currentTab === "views"} dataType="date" />
			</DataTable>
		</Card>
	);
};

ProblemStats.propTypes = {
	problem: PropTypes.object,
	user: PropTypes.object,
	tab: PropTypes.string.isRequired,
	endorsements: PropTypes.arrayOf(PropTypes.object).isRequired,
	views: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired
};

ProblemStats.defaultProps = {
	problem: null,
	user: null
}

export default ProblemStats;
