import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { fetchSettings } from '../../store/settings';
import { logOut } from '../../store/context';
import PageBanner from '../PageBanner';
// import Footer from '../Footer';
import classNames from 'classnames';
import LogoSrc from '../images/logo/research2u_logo.png';
import { Helmet } from 'react-helmet';

const Questionnaire = props => {
	React.useEffect(() => {
		if (!props.isLoggedIn || props.timeout) {
			props.fetchSettings([
				"loginBackground"
			]);
		}
	}, []);

	let backgroundImage = null;
	if (!props.loading) {
		if (props.centerContent) {
			backgroundImage = props.backgroundImage || props.loginBackground || "/images/aspire-mountain.png";
		} else {
			backgroundImage = props.backgroundImage || props.loginBackground || "/images/login_background.jpg";
		}
	}

	return (
		<main role="main" className={classNames({ "login-layout": true, "centered-layout": props.centerContent })}>
            <Helmet>
                <title>{props.tenantTitle}</title>
            </Helmet>

			{props.showBanner && <PageBanner />}
			<div className="signin__main">
				<div className="signin__background" style={{ backgroundImage: `url(${backgroundImage})` }} />
				<div className="signin__content">
					<h1 className="signin__logo logo-primary text-center">
						{!props.loading && <Link to="/"><img src={props.logo || LogoSrc} height="100" className="logo" alt="Research 2 U" /></Link>}
					</h1>
					{props.children}
				</div>
			</div>
			{/* <Footer /> */}
		</main>
	);
};

Questionnaire.props = {
	loading: PropTypes.bool.isRequired,
	logo: PropTypes.string,
	loginBackground: PropTypes.string,
	backgroundImage: PropTypes.string,
	timeout: PropTypes.bool.isRequired,
	centerContent: PropTypes.bool,
	tenantTitle: PropTypes.string
};

Questionnaire.defaultProps = {
	logo: null,
	loginBackground: null,
	backgroundImage: null,
	centerContent: false,
	tenantTitle: ''
};

const mapStateToProps = state => ({
	logo: state.settings.logo,
	loading: state.bootstrap.loading,
	loginBackground: state.settings.loginBackground,
	backgroundImage: state.settings.backgroundImage,
	tenantTitle: state.context.tenantTitle
});

const mapDispatchToProps = dispatch => ({
	fetchSettings: names => dispatch(fetchSettings(names)),
	logout: () => dispatch(logOut(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Questionnaire));
