import React from 'react';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import Card from '../widgets/Card';

const Copyright = props =>
	<PageLayout title="Copyright" pageContent={
		<Card>
			<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.content }} />
		</Card> 
	} breadcrumbs={["Copyright"]} />;

export default connect(state => ({
	content: state.settings.copyrightContent
}), null)(Copyright);