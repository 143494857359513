import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { hideNotification } from '../../store/notifications';
import { withStyles } from 'tss-react/mui';
import classNames from 'classnames';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const styles = theme => ({
	success: {
		backgroundColor: theme.palette.success.main,
	},
	error: {
		backgroundColor: theme.palette.error.main,
	},
	info: {
	  	backgroundColor: theme.palette.info.main,
	},
	warning: {
		backgroundColor: theme.palette.warning.main
	},
	icon: {
		fontSize: 20,
	},
	typeIcon: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: "flex",
		alignItems: "center",
	}
});

const iconSwitch = {
	success: SuccessIcon,
	error: ErrorIcon,
	info: InfoIcon,
	warning: WarningIcon
}

const Notifications = props => {
	const { type, classes } = props;
	const Icon = iconSwitch[type];

	return (
        <div role="alert">
			<Snackbar
				open={props.show}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={4000}
				onClose={() => props.hideNotification()}
			>
				<SnackbarContent
					className={classes[type]}
					message={
						<span id="notification-content" className={classes.message}>
							<Icon className={classNames(classes.icon, classes.typeIcon)} />
							{props.message}
						</span>
					}
					action={[
						<IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => props.hideNotification()}
                            size="large">
							<CloseIcon className={classes.icon} />
						</IconButton>
					]}
				/>
			</Snackbar>
		</div>
    );
}

Notifications.propTypes = {
	show: PropTypes.bool.isRequired,
	type: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
	message: PropTypes.any,
	hideNotification: PropTypes.func.isRequired
};

Notifications.defaultProps = {
	message: ""
};

const mapStateToProps = (state) => ({
	show: state.notifications.show,
	type: state.notifications.type,
	message: state.notifications.message
});

const mapDispatchToProps = dispatch => ({
	hideNotification: () => dispatch(hideNotification())
});

export default withStyles(
	connect(mapStateToProps, mapDispatchToProps)(Notifications),
	styles
	);
