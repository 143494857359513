import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Typography, Divider } from '@mui/material';
import TabContainer from '../widgets/TabContainer';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import Tab from '../widgets/Tab';
import map from 'lodash/map';
import FavouriteButton from './FavouriteButton';

const ViewCentre = ({ centre, loading, canEdit, canPublishCentres, publishCentre, unpublishCentre }) => loading ? <CircularProgress size={24} /> : (
	<Grid container spacing={4} className="centre-container">
		<Grid item xs={12} md={4}>
			<Card className="contact-info" style={{ position: 'relative' }}>
				{centre.visibility === "Public" && 
					<div style={{
						position: 'absolute',
						top: '5px',
						right: '5px'
					}}>
						<Tag label="Published" id="published-tag" />
					</div>
				}
				<div style={{ textAlign: "center", padding: "1.25rem" }}>
					{centre.logo && <img src={centre.logo} alt={centre.title} />}
					<div style={{ paddingTop: 10 }}>
						<Typography variant="h6">{centre.title}</Typography>
						<FavouriteButton centreId={centre.centreId} isFavourite={centre.isFavourite} />
					</div>
				</div>
				<Divider />
				<div style={{ padding: "1.25rem" }}>
					{centre.email && 
						<section>
							<Typography variant="caption" style={{ fontWeight: 'bold' }}>Email</Typography>
							<Typography component="a" href={`mailto:${centre.email}`} variant="body2" style={{ display: "block" }}>
								{centre.email}
							</Typography>
						</section>
					}
					{centre.phone && 
						<section>
							<Typography variant="caption" style={{ fontWeight: 'bold' }}>Phone</Typography>
							<Typography variant="body2">{centre.phone}</Typography>
						</section>
					}
					{centre.website && 
						<section>
							<Typography variant="caption" style={{ fontWeight: 'bold' }}>Website</Typography>
							<Typography variant="body2" component="a" href={centre.website} target="_blank" style={{ display: "block" }}>
								{centre.website}
							</Typography>
						</section>
					}
					{centre.location && 
						<section>
							<Typography variant="caption" style={{ fontWeight: 'bold' }}>Location</Typography>
							<Typography variant="body2">{centre.location}</Typography>
						</section>
					}
				</div>
			</Card>
			{canEdit && <ButtonLink to={`/research-centres/edit/${centre.centreId}`}>Edit research centre</ButtonLink>}
			{canPublishCentres && 
				<Button 
					onClick={() => {
						if (centre.visibility === "Public") {
							unpublishCentre(centre.centreId);
						} else {
							publishCentre(centre.centreId);
						}
					}}
					style={{ marginLeft: '10px' }}
				>{centre.visibility === "Public" ? "Unpublish" : "Publish"}
				</Button>
			}
		</Grid>
		<Grid item xs={12} md={8}>
			<Card header="About" style={{ marginBottom: 20 }}>
				<span className="h5" style={{ fontWeight: 'bold' }}>Description</span>
				<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: centre.description }} />
				{centre.tags && centre.tags.length > 0 && <span className="h5" style={{ marginTop: 20, fontWeight: 'bold' }}>Tags</span>}
				{map(centre.tags, (t, i) => <Tag key={i} label={t.name} />)}
			</Card>
			<TabContainer>
				<Tab label="Research">
					{centre.research ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: centre.research }} /> : "There is no research provided..."}
				</Tab>
				<Tab label="Institutions">
					{centre.institutions ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: centre.institutions }} /> : "There are no institutions provided..."}
				</Tab>
				<Tab label="Case studies">
					{centre.caseStudies ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: centre.caseStudies }} /> : "There are no case studies provided..."}
				</Tab>
			</TabContainer>
		</Grid>
	</Grid>
);

ViewCentre.propTypes = {
	centre: PropTypes.shape({
		centreId: PropTypes.number,
		title: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
		website: PropTypes.string,
		location: PropTypes.string,
		description: PropTypes.string,
		logo: PropTypes.string,
		research: PropTypes.string,
		institutions: PropTypes.string,
		caseStudies: PropTypes.string,
		tags: PropTypes.array
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool,
	canPublishCentres: PropTypes.bool.isRequired,
	publishCentre: PropTypes.func.isRequired,
	unpublishCentre: PropTypes.func.isRequired
};

ViewCentre.defaultProps = {
	canEdit: false
};

export default ViewCentre;