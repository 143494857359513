import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import ErrorPageLayout from '../ErrorPageLayout';
import { CircularProgress } from '@mui/material';
import { showModal } from '../../store/modal';

const ConfirmEmail = props => {
	const [state, setState] = React.useState({
		confirming: true,
		resending: false,
		error: false,
		errorTitle: "ERROR",
		message: ""
	});

	React.useEffect(() => {
		const search = decodeURIComponent(props.location.search);

		const userIdQuery = search.match(/\?userId=([a-z0-9-]+)/i);
		if (!userIdQuery) {
			setState({
				...state,
				confirming: false,
				error: true,
				errorTitle: "Confirmation Failed",
				message: "Confirmation failed due to an invalid User."
			});
			return;
		}
		const codeQuery = search.match(/&code=([a-z0-9+/=]+)/i);
		if (!codeQuery) {
			setState({
				...state,
				confirming: false,
				error: true,
				errorTitle: "Expired",
				message: "Expired Confirmation Token. Please use the Resend Verification button to generate a new one."
			});
			return;
		}

		post({
			url: "/api/users/confirm-email",
			data: { 
				userId: userIdQuery[1], 
				code: codeQuery[1]
			},
			onSuccess: result => {
				if (result.success) {
					setState({ ...state, confirming: false });
					props.showSuccessNotification(result.message);
					props.history.push("/login");
				} else {
					if (result.message === "Invalid token.") {
						setState({ 
							...state, 
							confirming: false, 
							error: true, 
							errorTitle: "Expired",
							message: "Expired Confirmation Token. Please use the Resend Verification button to send another confirmation email."
						});
					} else {
						setState({ 
							...state, 
							confirming: false, 
							error: true, 
							errorTitle: "Confirmation Failed",
							message: result.message
						});
					}
				}
			},
			onError: error => setState({ ...state, confirming: false, error: true, errorTitle: "ERROR", message: error.message })
		});
	}, []);

	const resendVerification = () => {
		props.showResendDialog(identity => {
			setState({ ...state, resending: true });
			const search = decodeURIComponent(props.location.search);
			const userId = search.match(/\?userId=([a-z0-9-]+)/i)[1];

			post({
				url: `/api/users/resend-verification?userId=${userId}&email=${identity}`,
				onSuccess: result => {
					if (result.success) {
						setState({ ...state, resending: false });
						props.showSuccessNotification(result.message);
						props.history.push("/login");
					} else {
						setState({ ...state, resending: false, error: true, errorTitle: "ERROR", message: result.message });
					}
				},
				onError: error => setState({ ...state, resending: false, error: true, errorTitle: "ERROR", message: error.message })
			});
		})
	}

	let header = null, body = null;

	if (state.confirming || state.resending) {
		header = (
			<div>
				<CircularProgress size={24} style={{ marginLeft: 15 }} />
				<span style={{ fontSize: 32, marginLeft: 10 }}>
					{state.confirming ? "Confirming" : "Resending Verification Email"}
				</span>
			</div>
		);
	} else if (state.error) {
		header = <div className="text-black text-5xl md-text-15xl font-black">{state.errorTitle}</div>;
		body = (
			<React.Fragment>
				<p className="text-grey-darker text-2xl md-text-3xl font-light mb-8 leading-normal">
					{state.message}
				</p>
				<a href="/">
					<button className="bg-transparent text-grey-darkest font-bold uppercase tracking-wide py-3 px-6 border-2 border-grey-light hover:border-grey rounded-lg">
						Go Home
					</button>
				</a>
				<a onClick={resendVerification} style={{ marginLeft: 10 }}>
					<button className="bg-transparent text-grey-darkest font-bold uppercase tracking-wide py-3 px-6 border-2 border-grey-light hover:border-grey rounded-lg">
						Resend Verification
					</button>
				</a>
			</React.Fragment>
		);
	}

	return <ErrorPageLayout headerContent={header} bodyContent={body} />;
}

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showResendDialog: onOk => dispatch(showModal("PROMPT", { 
		title: "Resend Email Confirmation",
		label: "Email",
		placeholder: "Enter your email address",
		onOk
	}))
});

export default connect(null, mapDispatchToProps)(withRouter(ConfirmEmail));