import React from 'react';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import PropTypes from 'prop-types';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
const SurveyController = props => {
	const [state, setState] = React.useState({
		responses: {},
		loading: true
	});
		
	React.useEffect(() => {
		get({
			url: '/api/survey',
			onSuccess: data => {
				setState(prevState => ({
					...prevState,
					responses: data,
					loading: false
				}));
			},
			onError: () => {
				setState(prevState => ({
					...prevState,
					loading: false
				}));
			}
		});
	}, []);

	return (
		<PageLayout title="Survey" pageContent={
			<React.Fragment>
				<h2>Survey</h2>
				<iframe 
					title="survey"
					src={`https://survey.alchemer.com/s3/6186746/SkilsLogiq-Test?userId=${props.currentUserId}`}
					frameborder="0" 
					style={{ 
						'width': '100%',
						'height': '700px',
						'overflow': 'hidden'
					}}
				>
				</iframe>
				<h2>Responses</h2>
				<ul>
					{map(state.responses ? state.responses.data : [], (r) => 
						<li>{JSON.stringify(r)}</li>
					)}
				</ul>
			</React.Fragment>
		} breadcrumbs={["Survey"]} />
	);
};

SurveyController.propTypes = { 
	currentUserId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyController);
