import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveTool, isLoading } from '../../store/tools';
import { canSubmitTools, hasFunction } from '../../store/security';
import { revokeAccess } from '../../store/context';
import { getQueryParameters } from '../../utils/utils';
import PageLayout from '../PageLayout';
import EditTool from './EditTool';

const EditToolController = props => {
	const { fromLDCourse } = getQueryParameters(props.location.search),
		tool = { visibility: fromLDCourse === "true" ? "Private" : "" };

	React.useEffect(() => {
		if (!props.isCommunity && !props.canView) props.revokeAccess();
	}, []);

	return <PageLayout title="Create tool" pageContent={
		<EditTool 
			tool={tool}
			saveTool={props.saveTool} 
			loading={props.loading} 
			canCreatePublicTool={props.canCreatePublicTool}
			isAcademic={props.isAcademic}
			isCommunity={props.isCommunity}
			isNew 
		/>
	} breadcrumbs={[{ label: "Tools", path: `/${props.isCommunity ? "my-community/" : ""}tools`  }, "Create tool"]} />;
};

EditToolController.propTypes = {
	saveTool: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canCreatePublicTool: PropTypes.bool.isRequired,
	isAcademic: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	canView: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

EditToolController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	saveResult: state.tools.saveResult,
	loading: isLoading(state),
	canCreatePublicTool: state.context.canCreatePublicTool || Boolean(state.context.academicId) || hasFunction("ToolEdit")(state),
	isAcademic: Boolean(state.context.academicId),
	canView: canSubmitTools(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveTool: (tool, newFiles) => dispatch(saveTool(tool, newFiles, ownProps.isCommunity)),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditToolController);