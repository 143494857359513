import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import { map } from 'lodash';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	discussions: [],
	discussion: {},
	comments: []
};

const CLEAR_DISCUSSIONS = "CLEAR_DISCUSSIONS";
const GET_DISCUSSIONS_REQUEST = "GET_DISCUSSIONS_REQUEST";
const GET_DISCUSSIONS_SUCCESS = "GET_DISCUSSIONS_SUCCESS";
const GET_DISCUSSIONS_FAILURE = "GET_DISCUSSIONS_FAILURE";
const GET_DISCUSSION_REQUEST = "GET_DISCUSSION_REQUEST";
const GET_DISCUSSION_SUCCESS = "GET_DISCUSSION_SUCCESS";
const GET_DISCUSSION_FAILURE = "GET_DISCUSSION_FAILURE";
const SAVE_DISCUSSION_REQUEST = "SAVE_DISCUSSION_REQUEST";
const SAVE_DISCUSSION_SUCCESS = "SAVE_DISCUSSION_SUCCESS";
const SAVE_DISCUSSION_FAILURE = "SAVE_DISCUSSION_FAILURE";
const DELETE_DISCUSSION_REQUEST = "DELETE_DISCUSSION_REQUEST";
const DELETE_DISCUSSION_SUCCESS = "DELETE_DISCUSSION_SUCCESS";
const DELETE_DISCUSSION_FAILURE = "DELETE_DISCUSSION_FAILURE";

export const isLoading = createLoadingSelector(["GET_DISCUSSIONS", "GET_DISCUSSION"]);

export const clearDiscussions = () => ({ type: CLEAR_DISCUSSIONS });

export const getDiscussions = () =>
	createFetchAction({
		url: "/api/discussion",
		startAction: GET_DISCUSSIONS_REQUEST,
		onError: error => [getDiscussionsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getDiscussionsSuccess(data)
	});

export const searchDiscussions = (args, onSuccess) =>
	createFetchAction({
		url: `/api/discussion/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: GET_DISCUSSIONS_REQUEST,
		onError: error => [getDiscussionsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getDiscussionsSuccess(data);
		}
	});

export const getDiscussionsSuccess = data => ({ type: GET_DISCUSSIONS_SUCCESS, payload: { data } });
export const getDiscussionsFailure = error => ({ type: GET_DISCUSSIONS_FAILURE, payload: { error } });

export const getDiscussion = discussionId =>
	createFetchAction({
		url: `/api/discussion/${discussionId}`,
		startAction: GET_DISCUSSION_REQUEST,
		onError: error => [getDiscussionFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getDiscussionSuccess(data)
	});

export const getDiscussionSuccess = data => ({ type: GET_DISCUSSION_SUCCESS, payload: { data } });
export const getDiscussionFailure = error => ({ type: GET_DISCUSSION_FAILURE, payload: { error } });

export const saveDiscussion = (discussion, isCommunity) => 
	createFormPostAction({
		url: "/api/discussion",
		data: { ...discussion, channels: discussion.channels.map(t => ({ name: t.label, channelId: t.value })) },
		startAction: SAVE_DISCUSSION_REQUEST,
		onError: error => [saveDiscussionFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveDiscussionSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}discussion/${data.object.discussionId}`)];
			} else {
				return [saveDiscussionSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveDiscussionSuccess = data => ({ type: SAVE_DISCUSSION_SUCCESS, data });
export const saveDiscussionFailure = error => ({ type: SAVE_DISCUSSION_FAILURE, error });

export const deleteDiscussion = (discussionId, isCommunity) =>
	createPostAction({
		url: `/api/discussion/${discussionId}/delete`,
		startAction: DELETE_DISCUSSION_REQUEST,
		onError: error => [deleteDiscussionFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteDiscussionSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}discussion`)];
			} else {
				return [deleteDiscussionSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteDiscussionSuccess = data => ({ type: DELETE_DISCUSSION_SUCCESS, data });
export const deleteDiscussionFailure = error => ({ type: DELETE_DISCUSSION_FAILURE, error });

export const getSubscribedDiscussions = limit =>
	createFetchAction({
		url: `/api/discussion/subscribed?limit=${limit}`,
		startAction: GET_DISCUSSIONS_REQUEST,
		onError: error => [getDiscussionsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getDiscussionsSuccess(data)
	});

export const getUpdates = () =>
	createFetchAction({
		url: "/api/discussion/updates",
		startAction: GET_DISCUSSIONS_REQUEST,
		onError: error => [getDiscussionsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getDiscussionsSuccess(data)
	});

// ----------
//  Comments
// ----------

const CLEAR_COMMENTS = "CLEAR_COMMENTS";
const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST";
const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
const GET_COMMENTS_FAILURE = "GET_COMMENTS_FAILURE";
const SAVE_COMMENT_REQUEST = "SAVE_COMMENT_REQUEST";
const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";
const REMOVE_COMMENT_REQUEST = "REMOVE_COMMENT_REQUEST";
const REMOVE_COMMENT_SUCCESS = "REMOVE_COMMENT_SUCCESS";
const REMOVE_COMMENT_FAILURE = "REMOVE_COMMENT_FAILURE";

export const isCommentsLoading = createLoadingSelector(["GET_COMMENTS", "SAVE_COMMENT"]);

export const clearComments = () => ({ type: CLEAR_COMMENTS });

export const getComments = discussionId =>
	createFetchAction({
		url: `/api/discussion/${discussionId}/comments`,
		startAction: GET_COMMENTS_REQUEST,
		onError: error => [getCommentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCommentsSuccess(data)
	});

export const getCommentsSuccess = data => ({ type: GET_COMMENTS_SUCCESS, payload: { data } });
export const getCommentsFailure = error => ({ type: GET_COMMENTS_FAILURE, payload: { error } });

export const saveComment = (discussionId, message) =>
	createPostAction({
		url: `/api/discussion/${discussionId}/comments`,
		data: { message },
		startAction: SAVE_COMMENT_REQUEST,
		onError: error => [saveCommentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveCommentSuccess(data)];
			} else {
				return [saveCommentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveCommentSuccess = data => ({ type: SAVE_COMMENT_SUCCESS, data });
export const saveCommentFailure = error => ({ type: SAVE_COMMENT_FAILURE, error });

export const removeComment = comment =>
	createPostAction({
		url: `/api/discussion/remove-comment/${comment.commentId}`,
		startAction: REMOVE_COMMENT_REQUEST,
		onError: error => [removeCommentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [removeCommentSuccess(data)];
			} else {
				return [removeCommentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const removeCommentSuccess = data => ({ type: REMOVE_COMMENT_SUCCESS, data });
export const removeCommentFailure = error => ({ type: REMOVE_COMMENT_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_DISCUSSIONS:
			return { ...state, discussions: [] };
		case GET_DISCUSSIONS_REQUEST:
			return { ...state, discussions: [], isLoading: true };
		case GET_DISCUSSIONS_SUCCESS:
			return { ...state, discussions: action.payload.data, isLoading: false };
		case GET_DISCUSSION_REQUEST:
			return { ...state, discussion: {}, isLoading: true }
		case GET_DISCUSSION_SUCCESS:
			return { ...state, discussion: action.payload.data, isLoading: true };
		case SAVE_DISCUSSION_REQUEST:
		case DELETE_DISCUSSION_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_DISCUSSION_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_DISCUSSION_SUCCESS:
			return { ...state, discussions: state.discussions.filter(a => a.discussionId !== action.data.objectId) };
		case CLEAR_COMMENTS:
			return { ...state, comments: [] };
		case GET_COMMENTS_REQUEST:
			return { ...state, comments: [], isCommentsLoading: true };
		case GET_COMMENTS_SUCCESS:
			return { ...state, comments: action.payload.data, isCommentsLoading: false };
		case SAVE_COMMENT_REQUEST:
		case REMOVE_COMMENT_REQUEST:
			return { ...state, isCommentsLoading: true };
		case SAVE_COMMENT_SUCCESS:
			return {
				...state,
				...(action.data.success && { comments: action.data.object }), 
				isCommentsLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case REMOVE_COMMENT_SUCCESS:
			return { ...state, comments: state.comments.filter(c => c.commentId !== action.data.objectId), isCommentsLoading: false };
		default:
			return state;
	}
};