import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../widgets/Card';
import map from 'lodash/map';
import Tag from '../widgets/Tag';
import { get } from '../../utils/ajax';
import { CircularProgress, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CompareIcon from '@mui/icons-material/Compare';
import { truncateString } from '../../utils/utils';
import Button from '../widgets/Button';
import FileIcon from '@mui/icons-material/Attachment';
import LinkIcon from '@mui/icons-material/Link';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	resourceCard: {
		'& .card-body': {
			'& .label': {
				color: theme.palette.primary.main
			}
		}
	}
});

const iconSwitch = {
	Video: OndemandVideoIcon,
	Article: DescriptionIcon,
	"Case Study": AssignmentIcon,
	Template: CompareIcon
};

const CourseResourceCard = ({ courseResource, onDelete, canEdit, errorColour }) => {
	const [loading, setLoading] = React.useState(true);
	const [resource, setResource] = React.useState({});

	React.useEffect(() => {
		if (courseResource.resourceType === 'Saved') {
			get({
				url: `/api/resources/${courseResource.resourceId}`,
				onSuccess: (data) => {
					setLoading(false);
					setResource(data);
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		}
	}, [courseResource]);

	switch (courseResource.resourceType) {
		case "Saved":
			return (
                <Card 
					className="resource-card"
					ribbon={resource.isAcademic && "Academic"} 
					ribbonStatus="success"
					title={
						<React.Fragment>
							{iconSwitch[resource.type] ? React.createElement(iconSwitch[resource.type]) : ""}
							{resource.name}
						</React.Fragment>
					}
					titleRightContent={canEdit ? 
						<IconButton onClick={onDelete} aria-label="Delete Resource" size="large">
							<ClearIcon style={{ errorColour }} />
						</IconButton>
						: undefined
					}
				>
					{loading ? 
						<CircularProgress size={24} /> : 
						<React.Fragment>
							<div className="body">{truncateString(resource.description, 300)}</div>
							<div className="stats">
								{resource && resource.attachments && resource.attachments.length > 0 && 
									<span className="label attachments">{resource.attachments.length} Attachment{resource.attachments.length > 1 ? "s" : ""}</span>
								}
								{resource && resource.links && resource.links.length > 0 &&
									<span className="label links">{resource.links.length} Link{resource.links.length > 1 ? "s" : ""}</span>
								}
								{resource && resource.videos && resource.videos.length > 0 &&
									<span className="label videos">{resource.videos.length} Video{resource.videos.length > 1 ? "s" : ""}</span>
								}
							</div>
							<div className="tags">
								{map(resource.tags, (t, i) => <Tag key={i} label={t.name} />)}
							</div>
						</React.Fragment>
					}
				</Card>
            );
		case "Link":
			return (
                <Card 
					className="resource-card"
				>
					<span className="h3 name">
						<LinkIcon />
						{courseResource.description}
					</span>
					{canEdit && 
						<IconButton
                            className="ld-resource-card__remove"
                            onClick={onDelete}
                            aria-label="Delete Resource"
                            size="large">
							<ClearIcon style={{ errorColour }} fontSize="small" />
						</IconButton>
					}
					<div className="body" style={{ textAlign: 'center' }}>
						<a href={courseResource.resourceValue}>{courseResource.resourceValue}</a>
					</div>
				</Card>
            );			
		case "Video":
			return (
                <Card 
					className="resource-card ld-video-resource-card"
				>
					<span className="h3 name">
						<OndemandVideoIcon />
						{courseResource.description}
					</span>
					{canEdit && 
						<IconButton
                            className="ld-resource-card__remove"
                            onClick={onDelete}
                            aria-label="Delete Resource"
                            size="large">
							<ClearIcon style={{ errorColour }} fontSize="small" />
						</IconButton>
					}
					<div className="frame-wrapper">
						<iframe title="Videos" src={courseResource.resourceValue} frameBorder="" allowFullScreen />
					</div>
					<div className="body" style={{ textAlign: 'center' }}>
						<a href={courseResource.resourceValue}>{courseResource.resourceValue}</a>
					</div>
				</Card>
            );
		case "File":
			return (
                <Card 
					className="resource-card"
				>
					<span className="h3 name">
						<FileIcon />
						{truncateString(courseResource.description, 300) || courseResource.resourceValue}
					</span>
					{canEdit &&
						<IconButton
                            className="ld-resource-card__remove"
                            onClick={onDelete}
                            aria-label="Delete Resource"
                            size="large">
							<ClearIcon style={{ errorColour }} fontSize="small" />
						</IconButton>
					}
					<div className="body" style={{ textAlign: 'center' }}>
						{courseResource.description && <div>{courseResource.resourceValue}</div>}
						<Button 
							variant="outlined" 
							onClick={() => window.open(`/Resource/DownloadCourseAttachment?courseResourceId=${courseResource.lD_CourseResourceId}`, "_self")}
							style={{ marginTop: '10px' }}
						>
							Download
						</Button>
					</div>
				</Card>
            );
		default:
			return <React.Fragment></React.Fragment>
	}
};

CourseResourceCard.propTypes = { 
	canEdit: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

CourseResourceCard.defaultProps = {
	canEdit: false
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CourseResourceCard, styles));

