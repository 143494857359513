import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../store/modal';
import { changeLocation } from '../../store/location';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const IncompleteAcademicProfileModal = props => {	
	const cancel = () => {
		props.hideModal(); 
		props.onCancel();
	}

	const ok = () => {
		props.hideModal();
		props.changeLocation(`/academics/edit/${props.academicId}`);
	}

	return (
		<Dialog open onClose={cancel} aria-labelledby="incomplete-academic" className="incomplete-academic">
			<DialogTitle id="incomplete-academic">Incomplete Academic Profile</DialogTitle>
			<DialogContent>
				<DialogContentText>
					We just need to collect a few more details. Click Ok to proceed to the academic screen.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={cancel} autoFocus>No</Button>
				<Button variant="text" color="primary" onClick={ok}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

IncompleteAcademicProfileModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	academicId: PropTypes.number.isRequired,
	changeLocation: PropTypes.func.isRequired
};

IncompleteAcademicProfileModal.defaultProps = {
	onCancel: () => null
};

const mapStateToProps = state => ({
	academicId: state.context.academicId
})

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	changeLocation: location => dispatch(changeLocation(location))
});

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteAcademicProfileModal);
