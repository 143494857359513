import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import Button from '../widgets/Button';
import { withForm } from '../../utils/forms';
import Form from '../widgets/Form';

const form = {
	initValues: props => ({ ...props.session }),
	fieldStyle: { marginBottom: "10px" },
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
			placeholder: ""
		},
		{
			name: "time",
			label: "Time",
			required: true
		},
		{
			name: "sessionDate",
			label: "Date",
			type: "date",
			required: false
		}
	]
};

const SessionEditor = React.memo(props => {
	const { fields } = props;

	return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
			<DialogTitle>Edit Session</DialogTitle>
			<DialogContent>
				<Form onSubmit={() => {
					if (props.validateFields()) {
						props.onUpdate(props.values);
					}
				}}>
					<Grid container spacing={3}>
						<Grid item xs={12}>{fields.name()}</Grid>
						<Grid item xs={12}>{fields.time()}</Grid>
						<Grid item xs={12}>{fields.sessionDate()}</Grid>
					</Grid>
				</Form>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					if (props.validateFields()) {
						props.onUpdate(props.values);
					}
				}}>Update</Button>
				<Button onClick={props.onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
    );
});

SessionEditor.propTypes = {
	open: PropTypes.bool.isRequired,
	session: PropTypes.object.isRequired,
	onOk: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

SessionEditor.defaultProps = {
	open: false,
	onUpdate: () => {},
	onClose: () => {}
};

export default withForm(form)(SessionEditor);
