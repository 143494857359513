import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isLoading, saveFramework } from '../../store/frameworks';
import PageLayout from '../PageLayout';
import EditFramework from './EditFramework';

const ImportFrameworkController = props =>
	<PageLayout title="Import framework" pageContent={
		<EditFramework saveFramework={props.saveFramework} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Frameworks", path: "/admin/frameworks" }, "Import framework"]} />;

ImportFrameworkController.propTypes = { 
	saveFramework: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveFramework: (framework, importFile, imageFile) => dispatch(saveFramework(framework, importFile, imageFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportFrameworkController);