import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCommunity, saveCommunity, deleteCommunity, isLoading as communitiesLoading } from '../../store/communities';
import { getChannels, isLoading as channelsLoading } from '../../store/channels';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditCommunity from './EditCommunity';

const EditCommunityController = props => {
	React.useEffect(() => props.getCommunity(props.match.params.communityId), [props.match.params.communityId]);

	// Fetch channels only once regardless of communityId
	React.useEffect(props.getChannels, []);

	return <PageLayout title="Edit community" pageContent={
		<EditCommunity
			community={props.community}
			saveCommunity={props.saveCommunity}
			deleteCommunity={props.deleteCommunity}
			channels={props.channels}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Communities", path: "/admin/communities" }, "Edit community"]} />;
};

EditCommunityController.propTypes = {
	community: PropTypes.object.isRequired,
	saveCommunity: PropTypes.func.isRequired,
	getChannels: PropTypes.func.isRequired,
	channels: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	community: state.communities.community,
	channels: state.channels.channels,
	loading: communitiesLoading(state) || channelsLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getCommunity: communityId => dispatch(getCommunity(communityId)),
	saveCommunity: (community, imageFile) => dispatch(saveCommunity(community, imageFile)),
	deleteCommunity: communityId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this community?",
		onOk: () => dispatch(deleteCommunity(communityId))
	})),
	getChannels: () => dispatch(getChannels())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCommunityController);
