import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserBadges, isLoading as isBadgesLoading } from '../../store/badges';
import { getPassport, savePassport, isLoading } from '../../store/passport';
import { getCredentials, saveCredentials } from '../../store/users';
import { canViewBadges, hasFunction } from '../../store/security';
import { changeLocation } from '../../store/location';
import { fetchSettings } from '../../store/settings';
import { setShowSkillsPassportIntro } from '../../store/context';
import { get, post } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import Passport from './Passport';
import { Redirect } from 'react-router-dom';
import PassportIntro from './PassportIntro';

const PassportController = props => {
	const [loading, setLoading] = React.useState(false);
	const [dashboard, setDashboard] = React.useState(null);
	const [showUnitsDialog, setShowUnitsDialog] = React.useState(false);

	if (!props.showSkillsPassportIntro && props.completeUserAssessment && !props.userAssessmentComplete) {
		return <Redirect to={"skills/primary"} />
	}

	React.useEffect(() => {
		post({ url: "/api/passport/log-view" });

		props.fetchSettings([
			"mySkillsButton1Label",
			"mySkillsButton1Report",
			"mySkillsButton2Label",
			"mySkillsButton2Report",
			"primaryCompetencySingle",
			"aspirationalCompetencySingle"
		]);

		props.getCredentials();
		props.getPassport();
		props.getBadges();
		setLoading(true);

		get({
			url: "/api/dashboard/skills-dashboard",
			onSuccess: data => {
				setLoading(false);
				setDashboard({ layout: JSON.parse(data.layout), widgets: JSON.parse(data.widgets) });
			},
			onError: error => {
				setLoading(false);
				setDashboard({ layout: null, widgets: null });
				props.showErrorNotification(error.message);
			}
		})
	}, []);

	const setSkillsFrameworks = frameworks => {
		props.savePassport({ ...props.passport, skillsFrameworks: frameworks.map(f => f.frameworkCode) });
	}

	const removeSkillsFramework = frameworkCode => {
		props.savePassport({ ...props.passport, skillsFrameworks: props.passport.skillsFrameworks.filter(f => f !== frameworkCode) });
	};

	const secondaryProfileVisibility = props.showSecondaryProfile ? "Show" : props.disableSecondaryProfile ? "Hide" : props.passport.secondaryProfileVisibility || "Hide";

	let PageContent;
	if (props.showSkillsPassportIntro) {
		PageContent = 
			<PassportIntro 
				onSkip={() => {
					if (props.completeUserAssessment && !props.userAssessmentComplete) {
						props.goToCompetenciesPage();
					} else {
						props.setShowSkillsPassportIntro(false);
					}
				}}
			/>;
	} else {
		PageContent = 
			<Passport 
				dashboard={dashboard}
				passport={props.passport} 
				loading={props.loading || loading} 
				mySkillsButton1Label={props.mySkillsButton1Label}
				mySkillsButton1Report={props.mySkillsButton1Report}
				mySkillsButton2Label={props.mySkillsButton2Label}
				mySkillsButton2Report={props.mySkillsButton2Report}
				pcSingle={props.pcSingle}
				acSingle={props.acSingle}
				pcDescription={props.pcDescription}
				acDescription={props.acDescription}
				credentials={props.credentials}
				updateCredentials={props.updateCredentials}
				setSkillsFrameworks={setSkillsFrameworks}
				removeSkillsFramework={removeSkillsFramework}
				userId={props.userId}
				badges={props.badges}
				canViewBadges={props.canViewBadges}
				badgesLoading={props.badgesLoading}
				secondaryProfileVisibility={secondaryProfileVisibility}
				showUnitsDialog={showUnitsDialog}
				onCloseUnitsDialog={() => setShowUnitsDialog(false)}
				errorColour={props.errorColour}
			/>;
	}
	return (
		<PageLayout 
			title="My Skills Passport" 
			pageContent={PageContent} 
			breadcrumbs={["My Skills Passport"]}
		/>		
	);
};

PassportController.propTypes = { 
	passport: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	mySkillsButton1Label: PropTypes.string.isRequired,
	mySkillsButton1Report: PropTypes.string.isRequired,
	mySkillsButton2Label: PropTypes.string.isRequired,
	mySkillsButton2Report: PropTypes.string.isRequired,
	pcSingle: PropTypes.string.isRequired,
	acSingle: PropTypes.string.isRequired,
	pcDescription: PropTypes.string.isRequired,
	acDescription: PropTypes.string.isRequired,
	credentials: PropTypes.object.isRequired,
	updateCredentials: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	badges: PropTypes.array.isRequired,
	getBadges: PropTypes.func.isRequired,
	canViewBadges: PropTypes.bool.isRequired,
	badgesLoading: PropTypes.bool.isRequired,
	disableSecondaryProfile: PropTypes.bool.isRequired,
	showSecondaryProfile: PropTypes.bool.isRequired,
	completeUserAssessment: PropTypes.bool.isRequired,
	userAssessmentComplete: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired,
	showSkillsPassportIntro: PropTypes.bool.isRequired,
	setShowSkillsPassportIntro: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	passport: state.passport.passport,
	loading: isLoading(state),
	mySkillsButton1Label: state.settings.mySkillsButton1Label || "",
	mySkillsButton1Report: state.settings.mySkillsButton1Report || "",
	mySkillsButton2Label: state.settings.mySkillsButton2Label || "",
	mySkillsButton2Report: state.settings.mySkillsButton2Report || "",
	pcSingle: state.settings.pcSingle,
	acSingle: state.settings.acSingle,
	pcDescription: state.settings.primaryCompetencyDescription || "",
	acDescription: state.settings.aspirationalCompetencyDescription || "",
	credentials: state.users.credentials,
	userId: state.context.userId,
	badges: state.badges.badges,
	canViewBadges: canViewBadges(state),
	badgesLoading: isBadgesLoading(state),
	disableSecondaryProfile: state.context.disableSecondaryProfile,
	showSecondaryProfile: hasFunction("ShowSecondaryProfile")(state),
	completeUserAssessment: state.competencies.completeUserAssessment,
	userAssessmentComplete: state.competencies.userAssessmentComplete,
	errorColour: state.theme.errorColour,
	showSkillsPassportIntro: state.context.showSkillsPassportIntro
});

const mapDispatchToProps = dispatch => ({
	fetchSettings: names => dispatch(fetchSettings(names)),
	getPassport: () => dispatch(getPassport()),
	savePassport: passport => dispatch(savePassport(passport)),
	getCredentials: () => dispatch(getCredentials()),
	updateCredentials: (credentials, newFiles) => dispatch(saveCredentials(credentials, newFiles)),
	getBadges: () => dispatch(getUserBadges()),
	setShowSkillsPassportIntro: show => dispatch(setShowSkillsPassportIntro(show)),
	goToCompetenciesPage: () => dispatch(changeLocation('/skills/primary'))
});

export default connect(mapStateToProps, mapDispatchToProps)(PassportController);
