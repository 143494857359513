import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../../utils/forms';
import Button from '../../widgets/Button';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';

const form = {
	initValues: props => props.group,
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
			helpText: "Name of report group"
		},
		{
			name: "description",
			label: "Description",
			multiline: true
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		},
		{
			name: "reports",
			label: "Reports",
			type: "autoComplete",
			loadItems: {
				route: "reports",
				mapItem: r => ({ ...r, label: r.name, value: r.reportId })
			},
			isMulti: true,
			mapValue: r => ({ ...r, label: r.name, value: r.reportId })
		}
	]
};

const EditGroup = props => {
	const { fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} group`} loading={loading} className="edit-group">
			<Form onSubmit={() => props.validateFields() && props.saveGroup(props.values)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.name()}</Grid>
					<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.reports()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} group</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteGroup(props.group.groupId)}>
							Delete group
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditGroup.propTypes = { 
	group: PropTypes.object,
	saveGroup: PropTypes.func.isRequired,
	deleteGroup: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditGroup.defaultProps = {
	group: {},
	deleteGroup: () => null,
	isNew: false
};

export default withForm(form)(EditGroup);