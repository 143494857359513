import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, Input, InputLabel, FormHelperText, FormControl } from '@mui/material';
import { default as MaterialUISelect } from '@mui/material/Select';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import isString from 'lodash/isString';

const Select = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("select-"));
		
	return (
		<FormControl fullWidth={props.fullWidth} error={props.error} style={props.style} disabled={props.disabled} variant="standard">
			{props.label &&
				<InputLabel htmlFor={id} shrink={props.displayEmpty || undefined}>
					{props.label}
					{props.required && <span style={{ color: props.errorColour }}> *</span>}
				</InputLabel>
			}
			<MaterialUISelect
				required={props.required}
				input={<Input id={id} name={props.name} />}
				value={props.value}
				onChange={props.onChange}
				className="dih-input"
				displayEmpty={props.displayEmpty}
				disableUnderline={props.disableUnderline}
			>
				{map(props.items, (item, index) => 
					<MenuItem 
						key={index} 
						value={isString(item) ? item : item.value}
					>
						{item.imageUrl && 
							<img 
								src={item.imageUrl}
								style={{
									maxWidth: '100px',
									maxHeight: '50px',
									marginRight: '5px'
								}}
								alt=""
							/>
						}
						{isString(item) ? item : item.label}
					</MenuItem>
				)}
			</MaterialUISelect>
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
		</FormControl>
	);
});

Select.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	items: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		})
	])).isRequired,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	error: PropTypes.bool,
	style: PropTypes.object,
	helpText: PropTypes.node,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	disableUnderline: PropTypes.bool,
	displayEmpty: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

Select.defaultProps = {
	id: "",
	name: "",
	label: "",
	value: "",
	onChange: () => null,
	required: false,
	placeholder: "",
	error: false,
	style: {},
	helpText: "",
	disabled: false,
	fullWidth: true,
	disableUnderline: false,
	displayEmpty: false
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
