import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const RowIcons = React.memo(props => 
	<span className="action-icons">
		{props.statsLink && 
			<Tooltip title="View stats">
				<Link to={props.statsLink} name="View stats"><AssessmentIcon /></Link>
			</Tooltip>
		}
		{props.editLink && 
			<Tooltip title={`Edit ${props.type}`}>
				<Link to={props.editLink} name={`Edit ${props.type}`}><EditIcon /></Link>
			</Tooltip>
		}
		{props.approveFunc &&
			<Tooltip title={`Approve ${props.type}`}>
				<a onClick={props.approveFunc} name={`Approve ${props.type}`}><CheckIcon /></a>
			</Tooltip>
		}
		{props.deleteFunc &&
			<Tooltip title={`Delete ${props.type}`}>
				<a onClick={props.deleteFunc} name={`Delete ${props.type}`}><DeleteIcon /></a>
			</Tooltip>
		}
		{props.copyFunc &&
			<Tooltip title={`Copy ${props.type}`}>
				<a onClick={props.copyFunc} name={`Copy ${props.type}`}><FileCopyIcon /></a>
			</Tooltip>
		}
	</span>
);

RowIcons.propTypes = {
	type: PropTypes.string.isRequired,
	statsLink: PropTypes.string,
	editLink: PropTypes.string,
	approveFunc: PropTypes.func,
	deleteFunc: PropTypes.func,
	copyFunc: PropTypes.func
};

RowIcons.defaultProps = {
	statsLink: "",
	editLink: "",
	approveFunc: null,
	deleteFunc: null,
	copyFunc: null
};

export default RowIcons;