import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';
import { changeLocation } from '../../../store/location';
import { emptySaveResult } from '../../../variables';
import { post } from '../../../utils/ajax';
import PageLayout from '../../PageLayout';
import EditProduct from './EditProduct';
import { canAdminSubscriptions } from '../../../store/security';
import { revokeAccess } from '../../../store/context';

const CreateProductController = props => {
	const [state, setState] = React.useState({
		product: { 
			tenantId: props.tenantId,
			organisationId: props.organisationId,
			productType: "Subscription", 
			active: true, 
			requiresMembership: false, 
			order: 1, 
			clearRolesOnSubscription: false 
		},
		loading: false,
		saveResult: emptySaveResult
	});

	React.useEffect(() => {
		if (!props.canView) props.revokeAccess();
	}, []);

	const saveProduct = product => {
		setState(prevState => ({ ...prevState, isLoading: true }));
	
		post({
			url: "/api/stripe/product",
			data: product,
			onSuccess: data => {
				setState(prevState => ({ ...prevState, saveResult: data, loading: false }));

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToProductsPage();
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, loading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	return <PageLayout title="Edit product" pageContent={
		<EditProduct
			product={state.product}
			saveProduct={saveProduct}
			loading={state.loading}
			saveResult={state.saveResult}
			tenantId={props.tenantId}
			isGlobalAdministrator={props.isGlobalAdministrator}
			isNew
		/>
	} breadcrumbs={[{ label: "Products", path: "/admin/products" }, "Create product"]} />;
};

CreateProductController.propTypes = {
	isGlobalAdministrator: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	organisationId: PropTypes.number.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	goToProductsPage: PropTypes.func.isRequired,
	canView: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	canView: canAdminSubscriptions(state),
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	tenantId: state.context.tenantId,
	organisationId: state.context.organisationId
});

const mapDispatchToProps = dispatch => ({
	goToProductsPage: () => dispatch(changeLocation("/admin/products")),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductController);
