import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createNewOrganisation, isLoading } from '../../../store/organisations';
import PageLayout from '../../PageLayout';
import CreateOrganisation from './CreateOrganisation';

const CreateOrganisationController = props => {
	return (
		<PageLayout 
			title="New Organisation Wizard"
			pageContent={
				<CreateOrganisation 
					canEditTenant={props.isGlobalAdministrator}
					tenantId={props.tenantId}
					createNewOrganisation={props.createNewOrganisation} 
					loading={props.loading} 
					theme={props.theme}
					saveResult={props.saveResult}
					isNew
					defaultAreaId={props.defaultAreaId}
					defaultSkillsFramework={props.defaultSkillsFramework}
					defaultSkillsProfileId={props.defaultSkillsProfileId}
					defaultPrimarySkillsProfileGroupId={props.defaultPrimarySkillsProfileGroupId}
					defaultSecondarySkillsProfileGroupId={props.defaultSecondarySkillsProfileGroupId}
					defaultInitialAssessmentProfileId={props.defaultInitialAssessmentProfileId}
				/>
			} 
			breadcrumbs={[
				{ label: "Organisations", path: "/admin/organisations" }, 
				"New Organisation Wizard"
			]} 
		/>
	);
};

CreateOrganisationController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	createNewOrganisation: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	theme: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	tenantId: state.context.tenantId,
	isAdmin: state.context.isAdministrator,
	saveResult: state.organisations.saveResult,
	theme: state.theme,
	defaultAreaId: state.context.defaultAreaId,
	defaultSkillsFramework: state.context.defaultSkillsFramework,
	defaultSkillsProfileId: state.context.defaultSkillsProfileId,
	defaultPrimarySkillsProfileGroupId: state.context.defaultPrimarySkillsProfileGroupId,
	defaultSecondarySkillsProfileGroupId: state.context.defaultSecondarySkillsProfileGroupId,
	defaultInitialAssessmentProfileId: state.context.defaultInitialAssessmentProfileId
});

const mapDispatchToProps = dispatch => ({
	createNewOrganisation: (organisation, logoFile, accreditedLogoFile, userTour, onSuccess, onError) => 
		dispatch(createNewOrganisation(organisation, logoFile, accreditedLogoFile, userTour, onSuccess, onError))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganisationController);
