import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { getTools } from '../../store/tools';
import Button from './Button';
import Card from './Card';
import map from 'lodash/map';
import Tag from '../widgets/Tag';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	toolCard: {
		'& .card-body': {
			'& .label': {
				backgroundColor: theme.palette.primary.main
			},
			'& .academic': {
				'& svg': {
					color: theme.palette.secondary.main
				}
			}
		}
	}
});

const ToolCard = ({ tool, onClick, classes }) =>
	<Card className={`tool-card ${classes.toolCard}`} onClick={onClick}>
		<div className="header">{tool.name}</div>
		<div className="description">{tool.description}</div>
		<div className="tags">
			{map(tool.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
	</Card>;

const ToolPicker = React.memo(props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth="md"
        TransitionProps={{
            onEnter: props.getTools
        }}>
		<DialogTitle>Select Tool</DialogTitle>
		<DialogContent>
			<Grid container spacing={3}>
				{map(props.tools, (t, i) =>
					<Grid key={i} item xs={4}>
						<ToolCard tool={t} onClick={() => props.onOk(t)} />
					</Grid>
				)}
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose}>Cancel</Button>
		</DialogActions>
	</Dialog>
);

ToolPicker.propTypes = {
	open: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

ToolPicker.defaultProps = {
	open: false,
	onOk: () => {},
	onClose: () => {}
};

const mapStateToProps = state => ({
	tools: state.tools.tools
});

const mapDispatchToProps = dispatch => ({
	getTools: () => dispatch(getTools())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ToolPicker, styles));