import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginDialog from './LoginDialog';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import ConfirmArchiveModal from './ConfirmArchiveModal';
import SimpleModal from './SimpleModal';
import MessageDialog from './MessageDialog';
import Prompt from './Prompt';
import IncompleteAcadedmicProfileModal from './IncompleteAcadedmicProfileModal';

const MODAL_COMPONENTS = {
	"LOGIN": LoginDialog,
	"CONFIRM_DELETE": ConfirmDeleteModal,
	"CONFIRM_ARCHIVE": ConfirmArchiveModal,
	"SIMPLE": SimpleModal,
	"MESSAGE": MessageDialog,
	"PROMPT": Prompt,
	"INCOMPLETE_ACADEMIC_PROFILE": IncompleteAcadedmicProfileModal
};

const Modal = ({ modalType, modalProps }) => modalType ? React.createElement(MODAL_COMPONENTS[modalType], modalProps) : null;

Modal.propTypes = {
	modalType: PropTypes.string,
	modalProps: PropTypes.object
};

Modal.defaultProps = {
	modalType: null,
	modalProps: {}
};

export default connect(state => state.modal)(Modal);