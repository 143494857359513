import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	organisations: [],
	organisation: { showUserTour: true }
};

const CLEAR_ORGANISATIONS = "CLEAR_ORGANISATIONS";
const GET_ORGANISATIONS_REQUEST = "GET_ORGANISATIONS_REQUEST";
const GET_ORGANISATIONS_SUCCESS = "GET_ORGANISATIONS_SUCCESS";
const GET_ORGANISATIONS_FAILURE = "GET_ORGANISATIONS_FAILURE";
const SEARCH_ORGANISATIONS_REQUEST = "SEARCH_ORGANISATIONS_REQUEST";
const SEARCH_ORGANISATIONS_SUCCESS = "SEARCH_ORGANISATIONS_SUCCESS";
const SEARCH_ORGANISATIONS_FAILURE = "SEARCH_ORGANISATIONS_FAILURE";
const GET_ORGANISATION_REQUEST = "GET_ORGANISATION_REQUEST";
const GET_ORGANISATION_SUCCESS = "GET_ORGANISATION_SUCCESS";
const GET_ORGANISATION_FAILURE = "GET_ORGANISATION_FAILURE";
const SAVE_ORGANISATION_REQUEST = "SAVE_ORGANISATION_REQUEST";
const SAVE_ORGANISATION_SUCCESS = "SAVE_ORGANISATION_SUCCESS";
const SAVE_ORGANISATION_FAILURE = "SAVE_ORGANISATION_FAILURE";
const DELETE_ORGANISATION_REQUEST = "DELETE_ORGANISATION_REQUEST";
const DELETE_ORGANISATION_SUCCESS = "DELETE_ORGANISATION_SUCCESS";
const DELETE_ORGANISATION_FAILURE = "DELETE_ORGANISATION_FAILURE";

export const isLoading = createLoadingSelector(["GET_ORGANISATIONS", "SEARCH_ORGANISATIONS", "SAVE_ORGANISATION", "REQUEST_ORGANISATION"]);

export const clearOrganisations = () => ({ type: CLEAR_ORGANISATIONS });

export const getOrganisations = () =>
	createFetchAction({
		url: "/api/organisations",
		startAction: GET_ORGANISATIONS_REQUEST,
		onError: error => [getOrganisationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getOrganisationsSuccess(data)
	});

export const getOrganisationsSuccess = data => ({ type: GET_ORGANISATIONS_SUCCESS, payload: { data } });
export const getOrganisationsFailure = error => ({ type: GET_ORGANISATIONS_FAILURE, payload: { error } });

export const searchOrganisations = search => 
	createFetchAction({
		url: `/api/organisations/search?search=${search}`,
		startAction: SEARCH_ORGANISATIONS_REQUEST,
		onError: error => [searchOrganisationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchOrganisationsSuccess(data)
	});

export const searchOrganisationsSuccess = data => ({ type: SEARCH_ORGANISATIONS_SUCCESS, payload: { data } });
export const searchOrganisationsFailure = error => ({ type: SEARCH_ORGANISATIONS_FAILURE, payload: { error } });

export const getOrganisation = (organisationId, onSuccess, onError) =>
	createFetchAction({
		url: `/api/organisations/${organisationId}`,
		startAction: GET_ORGANISATION_REQUEST,
		onError: error => {
			if (onError) onError(error);
			return [getOrganisationFailure(error), showErrorNotification(error.message)];
		},
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getOrganisationSuccess(data);
		}
	});

export const getOrganisationSuccess = data => ({ type: GET_ORGANISATION_SUCCESS, payload: { data } });
export const getOrganisationFailure = error => ({ type: GET_ORGANISATION_FAILURE, payload: { error } });

export const saveOrganisation = (organisation, logoFile, accreditedLogoFile, userTourFile) => {
	const formData = new FormData(),
		isNew = organisation.organisationId === 0;

	formData.append("Organisation", encodeURIComponent(JSON.stringify(organisation)));
	if (logoFile) formData.append("LogoFile", logoFile);
	if (accreditedLogoFile) formData.append("AccreditedLogoFile", accreditedLogoFile);
	if (userTourFile) formData.append("UserTourFile", userTourFile);

	return createFormPostAction({
		url: "/api/organisations",
		data: formData,
		startAction: SAVE_ORGANISATION_REQUEST,
		onError: error => [saveOrganisationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveOrganisationSuccess(data), showSuccessNotification(data.message), changeLocation(`/${!isNew && "admin/"}organisations`)];
			} else {
				return [saveOrganisationSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}

export const saveOrganisationSuccess = data => ({ type: SAVE_ORGANISATION_SUCCESS, data });
export const saveOrganisationFailure = error => ({ type: SAVE_ORGANISATION_FAILURE, error });

export const createNewOrganisation = (organisation, logoFile, accreditedLogoFile, userTourFile, onSuccess, onError) => {
	const formData = new FormData(),
		isNew = organisation.organisationId === 0;

	formData.append("Organisation", encodeURIComponent(JSON.stringify(organisation)));
	if (logoFile) formData.append("LogoFile", logoFile);
	if (accreditedLogoFile) formData.append("AccreditedLogoFile", accreditedLogoFile);
	if (userTourFile) formData.append("UserTourFile", userTourFile);

	return createFormPostAction({
		url: "/api/organisations/create",
		data: formData,
		startAction: SAVE_ORGANISATION_REQUEST,
		onError: error => {
			if (onError) onError(error);
			return [saveOrganisationFailure(error), showErrorNotification(error.message)];
		},
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess(data);
				return [saveOrganisationSuccess(data), showSuccessNotification(data.message), changeLocation(`/${!isNew && "admin/"}organisations`)];
			} else {
				if (onError) onError(data);
				return [saveOrganisationSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const deleteOrganisation = organisationId =>
	createPostAction({
		url: `/api/organisations/${organisationId}/delete`,
		startAction: DELETE_ORGANISATION_REQUEST,
		onError: error => [deleteOrganisationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteOrganisationSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/organisations")];
			} else {
				return [deleteOrganisationSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteOrganisationSuccess = data => ({ type: DELETE_ORGANISATION_SUCCESS, data });
export const deleteOrganisationFailure = error => ({ type: DELETE_ORGANISATION_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ORGANISATIONS:
			return { ...state, organisations: [], organisation: {} };
		case GET_ORGANISATIONS_REQUEST:
			return { ...state, organisations: [], isLoading: true };
		case SEARCH_ORGANISATIONS_REQUEST:
			return { ...state, isLoading: true };
		case GET_ORGANISATIONS_SUCCESS:
		case SEARCH_ORGANISATIONS_SUCCESS:
			return { ...state, isLoading: false, organisations: action.payload.data };
		case GET_ORGANISATION_REQUEST:
			return { ...state, isLoading: true, organisation: {} };
		case GET_ORGANISATION_SUCCESS:
			return { ...state, isLoading: true, organisation: action.payload.data };
		case SAVE_ORGANISATION_REQUEST:
		case DELETE_ORGANISATION_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_ORGANISATION_SUCCESS:
			return {
				...state,
				...(action.data.success && { organisation: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_ORGANISATION_SUCCESS:
			return { ...state, organisations: state.organisations.filter(a => a.organisationId !== action.data.objectId) };
		default:
			return state;
	}
};