import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

const ViewReport = ({ report: r, userId }) => {
	const [loading, setLoading] = React.useState(true);
	
	return isEmpty(r) ? <CircularProgress size={24} /> : (
		<div className="report-container">
			<h2>{r.name}</h2>
			{loading && <CircularProgress size={24} />}
			<div className="embed-responsive" style={{ height: 1600 }}>
				<iframe 
					title="report-title" 
					className="embed-responsive-item" 
					src={`/ViewReport?userId=${userId}&reportName=${r.name}`}
					allowFullScreen=""
					onLoad={() => setLoading(false)}
				/>
			</div>
		</div>
	);
}

ViewReport.propTypes = {
	report: PropTypes.shape({
		name: PropTypes.string,
		category: PropTypes.string,
		fileName: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
		visible: PropTypes.bool
	}).isRequired,
	userId: PropTypes.string.isRequired
};

export default ViewReport;