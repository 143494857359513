import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import { 
	getAcademics, 
	searchAcademics, 
	isLoading 
} from '../../store/academics';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import map from 'lodash/map';
import AcademicCard from './AcademicCard';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchAcademics(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "country",
			label: "Filter by Country",
			type: "autoComplete",
			loadItems: {
				route: "academics/countries",
				mapItem: c => ({ label: c, value: c })
			},
			defaultItem: { label: "(All)", value: "" }
		}
	]
}

const AcademicsController = props => {
	const { academics, fields } = props;

	React.useEffect(() => props.searchAcademics(props.searchArgs), []);

	return (
        <PageLayout title="Academics" pageContent={
            <React.Fragment>
                <Grid container spacing={3} justifyContent="space-between" alignItems="flex-end">
                    <Grid item xs={12} sm={8} md={6}>{fields.search()}</Grid>
                    <Grid item xs={12} sm={4} md={3}>{fields.country()}</Grid>
                </Grid>
                {academics.length === 0 ? <p style={{ marginTop: 10 }}>No academics found...</p> :
                    <Grid container spacing={3}>
                        {map(academics, (a, i) => <AcademicCard key={i} academic={a} />)}
                    </Grid>
                }
            </React.Fragment>
        } breadcrumbs={["Academics"]} />
    );
};

AcademicsController.propTypes = { 
	academics: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	academics: state.academics.academics,
	loading: isLoading(state),
	searchArgs: pick(getQueryParameters(ownProps.location.search), "search", "country")
});

const mapDispatchToProps = dispatch => ({
	searchAcademics: args => dispatch(isEmpty(args) ? getAcademics() : searchAcademics(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(AcademicsController));
