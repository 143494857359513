import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Grow, IconButton, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { withForm } from '../../utils/forms';
import TermsDialog from '../modals/TermsDialog';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Button from '../widgets/Button';

const form = {
	initValues: props => ({ eventType: "Event", visibility: "Internal and External", approved: false, ...props.event }),
	fields: [
		{
			name: "title",
			label: "Event title",
			required: true
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor",
			required: true
		},
		{
			name: "eventType",
			label: "Event type",
			type: "select",
			required: true,
			items: ["Event", "Showcase", "Hackathon", "Innovation Jam", "Webinar", "Web series"]
		},
		{
			name: "visibility",
			label: "Visibility",
			type: "select",
			required: true,
			items: ["Internal", "External", "Internal and External"]
		},
		{
			name: "eventStartDate",
			label: "Start date",
			required: true,
			showTime: true,
			type: "date"
		},
		{
			name: "eventEndDate",
			label: "End date",
			required: true,
			showTime: true,
			type: "date",
			validate: (value, values) => value < values.eventStartDate ? "End Date cannot be before the Start Date" : ""
		},
		{
			name: "organiser",
			label: "Organiser",
			required: true
		},
		{
			name: "contactEmail",
			label: "Contact email",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid address"
		},
		{
			name: "contactPhone",
			label: "Contact phone"
		},
		{
			name: "eventLocation",
			label: "Event location"
		},
		{
			name: "eventAddress",
			label: "Event address"
		},
		{
			name: "videoUrl",
			label: "Video url"
		},
		{
			name: "registrationUrl",
			label: "Registration url",
			placeholder: "Users will be sent to the Url provided. If not set then the Contact Email address will be emailed the details instead."
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Event/DownloadAttachment?eventId=${attachment.eventId}&eventAttachmentId=${attachment.eventAttachmentId}`, "_self")
		},
		{
			name: "channelId",
			label: "Channel",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: channel => ({ label: channel.name, value: channel.channelId })
			}
		},
		{
			name: "approved",
			label: "Approved",
			type: "checkbox",
			helpText: "Appears in events pages"
		}
	]
};

const EditEvent = props => {
	const [termsOpen, setTermsOpen] = React.useState(false);
	const [alertOpen, setAlertOpen] = React.useState(true);

	const { event, fields, loading, isNew, isRequest, infoColour } = props;

	return (
        <Card title={`${isRequest ? "Request" : isNew ? "Create" : "Edit"} event`} className="edit-event" loading={loading}>
			<TermsDialog open={termsOpen} onClose={() => setTermsOpen(false)} />
			{isRequest &&
				<Grow in={alertOpen} unmountOnExit>
					<SnackbarContent 
						message={
							<span style={{ display: "flex", alignItems: "center" }}>
								<InfoIcon style={{ marginRight: 8 }} />
								This page is for requesting an event. Events submitted using this form will be sent in for approval...
							</span>
						} 
						style={{ backgroundColor: infoColour, maxWidth: "100%", marginBottom: 20 }}
						action={
							<IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setAlertOpen(false)}
                                size="large">
								<CloseIcon />
							</IconButton>
						}
					/>
				</Grow>
			}
			<Form onSubmit={() => props.validateFields() && props.saveEvent(props.values, props.formFiles["attachments"])}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.channelId()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12} sm={6}>{fields.eventType()}</Grid>
					<Grid item xs={12} sm={6}>{fields.visibility()}</Grid>
					<Grid item xs={12} style={{ padding: 0 }} />
					<Grid item xs="auto">{fields.eventStartDate()}</Grid>
					<Grid item xs="auto">{fields.eventEndDate()}</Grid>
					<Grid item xs={12}>{fields.organiser()}</Grid>
					<Grid item xs={12} sm={6}>{fields.contactEmail()}</Grid>
					<Grid item xs={12} sm={6}>{fields.contactPhone()}</Grid>
					<Grid item xs={12} sm={6}>{fields.eventLocation()}</Grid>
					<Grid item xs={12} sm={6}>{fields.eventAddress()}</Grid>
					<Grid item xs={12}>{fields.registrationUrl()}</Grid>
					<Grid item xs={12}>{fields.videoUrl()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
					{props.canApprove && <Grid item xs={12} sm={6}>{fields.approved()}</Grid>}
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isRequest ? "Request" : isNew ? "Save" : "Update"} Event</Button>
					{!isNew && !isRequest &&
						<Button className="delete-button" loading={loading} onClick={() => props.deleteEvent(event.eventId)}>
							Delete Event
						</Button>
					}
				</div>
			</Form>
		</Card>
    );
};

EditEvent.propTypes = { 
	event: PropTypes.object,
	saveEvent: PropTypes.func.isRequired,
	deleteEvent: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	defaultTitle: PropTypes.string,
	isNew: PropTypes.bool,
	isRequest: PropTypes.bool,
	canApprove: PropTypes.bool,
	infoColour: PropTypes.string.isRequired
};

EditEvent.defaultProps = {
	event: {},
	deleteEvent: () => null,
	defaultTitle: "",
	isNew: false,
	isRequest: false,
	canApprove: false
};

export default withForm(form)(EditEvent);
