import React from 'react';
import PageLayout from '../PageLayout';
import Button from '../widgets/Button';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
	catalogueBoxContainer: {
		display: 'flex',
		margin: '0 -5px 10px -5px'
	},
	catalogueBox: {
		flex: 1,
		border: '1px solid #d1332e',
		borderRadius: '15px',
		margin: '0 5px',
		paddingTop: '5px'
	},
	catalogueBoxHeader: {
		fontWeight: 'bold'
	}
});

const UpgradeCataloguesController = props => {
	const classes = props.classes;

	return (
		<PageLayout 
			title="Catalogues" 
			pageContent={
				<div style={{
					maxWidth: '1000px',
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto'
				}}>
					<p style={{ }}>
						<span style={{ fontSize: 'large', marginRight: '10px', fontWeight: 'bold' }}>Catalogues</span>
					</p>
					<p style={{ }}>
						The Catalogues are designed for you to list and promote existing courses, Intellectual Property (IP), content and resources to corporate buyers. 
					</p>
					<p style={{ fontWeight: 'bold' }}>
						Did you know that 70% of all training in the $260B corporate training market is developed in-house ! (Josh Bersin 2020)
					</p>
					<p>
						Our Catalogues allow you to leverage on the demand for growing ‘creator economy’ market for training provision by leveraging your content into the corporate L&mp;D environment. Don’t let your content sit on the shelf – create once and commercialise effectively!
					</p>
					<div className={classes.catalogueBoxContainer}>
						<div className={classes.catalogueBox}>
							<p className={classes.catalogueBoxHeader}>Course Catalogue</p>
							<p>
								A master course catalogue to link buyers to your course URL and your business profile
							</p>
						</div>
						<div className={classes.catalogueBox}>
							<p className={classes.catalogueBoxHeader}>Intellectual Property Catalogue</p>
							<p>
								An IP register for buyers to identify your IP Rights for your content and resources which they may wish to license for the creation of their own courses.
							</p>
						</div>
						<div className={classes.catalogueBox}>
							<p className={classes.catalogueBoxHeader}>MicroLearn</p>
							<p>
								A delivery platform for corporate buyers to access your content and resources. 
							</p>
							<p>
								Content can be integrated into their own in-house LMS where you get paid per view
							</p>
						</div>
					</div>
					<p style={{  }}>
						CLO Central Catalogues will be released in the near future.
						<br />
						As an early user, obtain your FREE bundled offer now by committing now – click button below.
					</p>
					<p style={{ fontWeight: 'bold' }}>
						Offer is strictly limited to take advantage now. 
					</p>

					<Button 
						className="skip-button" 
					>Coming Soon</Button>
				</div>
			} 
			breadcrumbs={[
				"Upgrade"
			]} />
	);
};

UpgradeCataloguesController.propTypes = {
};

export default withStyles(UpgradeCataloguesController, styles, { withTheme: true });
