import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveContent, isLoading } from '../../store/content';
import { canViewBusinessProfiles } from '../../store/security';
import { get } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import EditContent from './EditContent';
import { canAdminContent } from '../../store/security';

const CreateContentController = props => {
	const [organisations, setOrganisations] = React.useState([]);

	React.useEffect(() => {
		get({
			url: "/api/organisations?type=Organisation",
			onSuccess: data => setOrganisations(data.map(o => ({ label: o.name, value: o.organisationId })))
		});
	}, []);

	return <PageLayout title="Add Resource Bundle" pageContent={
		<EditContent 
			isNew
			saveContent={props.saveContent} 
			loading={props.loading} 
			organisationId={props.organisationId} 
			organisations={organisations}
			canAdminContent={props.canAdminContent}
			canViewBusinessProfiles={props.canViewBusinessProfiles}
		/>
	} breadcrumbs={[{ label: "Resource Catalogue", path: "/resource-catalogue" }, "Add Resource Bundle"]} />;
};	

CreateContentController.propTypes = {
	saveContent: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	canViewBusinessProfiles: PropTypes.bool.isRequired,
	canAdminContent: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canViewBusinessProfiles: canViewBusinessProfiles(state),
	canAdminContent: canAdminContent(state)
});

const mapDispatchToProps = dispatch => ({
	saveContent: (content, newFiles) => dispatch(saveContent(content, newFiles))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateContentController);