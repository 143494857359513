import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showModal } from '../../store/modal';
import { getTenant, deleteTenant, saveTenant, isLoading } from '../../store/tenants';
import PageLayout from '../PageLayout';
import EditTenant from './EditTenant';

const EditTenantController = props => {
	React.useEffect(() => props.getTenant(props.match.params.tenantId), [props.match.params.tenantId]);

	return <PageLayout title="Edit tenant" pageContent={
		<EditTenant
			loading={props.loading}
			tenant={props.tenant}
			saveResult={props.saveResult}
			onSave={props.saveTenant}
			onDelete={props.deleteTenant}
		/>
	} breadcrumbs={[{ label: "Tenants", path: "/admin/tenants" }, "Tenant"]} />;
};

EditTenantController.propTypes = { 
	tenant: PropTypes.object.isRequired,
	saveResult: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	tenant: state.tenants.tenant,
	saveResult: state.tenants.saveResult,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getTenant: tenantId => dispatch(getTenant(tenantId)),
	saveTenant: (tenant, logoFile, backgroundImageFile, faviconFile) => dispatch(saveTenant(tenant, logoFile, backgroundImageFile, faviconFile)),
	deleteTenant: tenant => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this tenant?",
		onOk: () => dispatch(deleteTenant(tenant))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTenantController);
