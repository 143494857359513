import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	pageNum: null,
	pageSize: null,
	total: null,
	totalPages: null,
	contents: [],
	content: {}
};

const CLEAR_CONTENTS = "CLEAR_CONTENTS";
const GET_CONTENTS_REQUEST = "GET_CONTENTS_REQUEST";
const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";
const GET_CONTENTS_FAILURE = "GET_CONTENTS_FAILURE";
const SEARCH_CONTENTS_REQUEST = "SEARCH_CONTENTS_REQUEST";
const SEARCH_CONTENTS_SUCCESS = "SEARCH_CONTENTS_SUCCESS";
const SEARCH_CONTENTS_FAILURE = "SEARCH_CONTENTS_FAILURE";
const GET_CONTENT_REQUEST = "GET_CONTENT_REQUEST";
const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
const GET_CONTENT_FAILURE = "GET_CONTENT_FAILURE";
const SAVE_CONTENT_REQUEST = "SAVE_CONTENT_REQUEST";
const SAVE_CONTENT_SUCCESS = "SAVE_CONTENT_SUCCESS";
const SAVE_CONTENT_FAILURE = "SAVE_CONTENT_FAILURE";
const DELETE_CONTENT_REQUEST = "DELETE_CONTENT_REQUEST";
const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
const DELETE_CONTENT_FAILURE = "DELETE_CONTENT_FAILURE";
const COPY_CONTENT_REQUEST = "COPY_CONTENT_REQUEST";
const COPY_CONTENT_SUCCESS = "COPY_CONTENT_SUCCESS";
const COPY_CONTENT_FAILURE = "COPY_CONTENT_FAILURE";

export const isLoading = createLoadingSelector(["GET_CONTENTS", "SEARCH_CONTENTS", "SAVE_CONTENT", "REQUEST_CONTENT", "COPY_CONTENT"]);

export const clearContents = () => ({ type: CLEAR_CONTENTS });

export const getContents = (pageNum, pageSize) =>
	createFetchAction({
		url: `/api/content?pageNum=${pageNum}&pageSize=${pageSize}`,
		startAction: GET_CONTENTS_REQUEST,
		onError: error => [getContentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getContentsSuccess(data)
	});

export const getContentsSuccess = data => ({ type: GET_CONTENTS_SUCCESS, payload: { data } });
export const getContentsFailure = error => ({ type: GET_CONTENTS_FAILURE, payload: { error } });

export const searchContents = (args, pageNum, pageSize) => 
	createFetchAction({
		url: `/api/content/search?pageNum=${pageNum}&pageSize=${pageSize}&${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_CONTENTS_REQUEST,
		onError: error => [searchContentsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchContentsSuccess(data)
	});

export const searchContentsSuccess = data => ({ type: SEARCH_CONTENTS_SUCCESS, payload: { data } });
export const searchContentsFailure = error => ({ type: SEARCH_CONTENTS_FAILURE, payload: { error } });

export const getContent = contentId =>
	createFetchAction({
		url: `/api/content/${contentId}`,
		startAction: GET_CONTENT_REQUEST,
		onError: error => [getContentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getContentSuccess(data)
	});

export const getContentSuccess = data => ({ type: GET_CONTENT_SUCCESS, payload: { data } });
export const getContentFailure = error => ({ type: GET_CONTENT_FAILURE, payload: { error } });

export const saveContent = (content, newFiles, thumbnail) => {
	const formData = new FormData();

	formData.append("Content", encodeURIComponent(JSON.stringify(content)));
	forEach(newFiles, f => formData.append("NewFiles", f));
	if (thumbnail) formData.append("Thumbnail", thumbnail);

	return createFormPostAction({
		url: "/api/content",
		data: formData,
		startAction: SAVE_CONTENT_REQUEST,
		onError: error => [saveContentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveContentSuccess(data), showSuccessNotification(data.message), changeLocation("/resource-catalogue")];
			} else {
				return [saveContentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}

export const saveContentSuccess = data => ({ type: SAVE_CONTENT_SUCCESS, data });
export const saveContentFailure = error => ({ type: SAVE_CONTENT_FAILURE, error });

export const deleteContent = contentId =>
	createPostAction({
		url: `/api/content/${contentId}/delete`,
		startAction: DELETE_CONTENT_REQUEST,
		onError: error => [deleteContentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteContentSuccess(data), showSuccessNotification(data.message), changeLocation("/resource-catalogue")];
			} else {
				return [deleteContentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteContentSuccess = data => ({ type: DELETE_CONTENT_SUCCESS, data });
export const deleteContentFailure = error => ({ type: DELETE_CONTENT_FAILURE, error });

export const copyContent = (contentId, title, includeCompetencies) =>
	createPostAction({
		url: `/api/content/${contentId}/copy?title=${title}&includeCompetencies=${includeCompetencies}`,
		startAction: COPY_CONTENT_REQUEST,
		onError: error => [copyContentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [copyContentSuccess(data), showSuccessNotification(data.message), changeLocation(`/resource-catalogue/edit/${data.object}`)];
			} else {
				return [copyContentSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const copyContentSuccess = data => ({ type: COPY_CONTENT_SUCCESS, data });
export const copyContentFailure = error => ({ type: COPY_CONTENT_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CONTENTS:
			return { ...state, contents: [], content: {} };
		case GET_CONTENTS_REQUEST:
			return { ...state, isLoading: true, contents: [] };
		case SEARCH_CONTENTS_REQUEST:
			return { ...state, isLoading: true };
		case GET_CONTENTS_SUCCESS:
		case SEARCH_CONTENTS_SUCCESS:
			return { 
				...state, 
				isLoading: false, 
				contents: action.payload.data.data,
				pageNum: action.payload.data.pageNum,
				pageSize: action.payload.data.pageSize,
				total: action.payload.data.total,
				totalPages: action.payload.data.totalPages
			};
		case GET_CONTENT_REQUEST:
			return { ...state, isLoading: true, content: {} };
		case GET_CONTENT_SUCCESS:
			return { ...state, isLoading: false, content: action.payload.data };
		case SAVE_CONTENT_REQUEST:
		case DELETE_CONTENT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_CONTENT_SUCCESS:
			return {
				...state,
				...(action.data.success && { content: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_CONTENT_SUCCESS:
			return { ...state, contents: state.contents.filter(a => a.contentId !== action.data.objectId) };
		default:
			return state;
	}
};
