import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveEvent, deleteEvent, getEvent, isLoading } from '../../store/events';
import { canApproveEvents, canEditCommunityEvent, canEditEvent } from '../../store/security';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditEvent from './EditEvent';

const EditEventController = props => {
	const { eventId } = props.match.params;

	React.useEffect(() => props.getEvent(eventId), []);

	React.useEffect(() => {
		if (props.event.eventId === eventId && !props.canEdit) props.revokeAccess();
	}, [props.event.eventId]);

	return <PageLayout title="Edit event" pageContent={
		<EditEvent 
			event={props.event}
			saveEvent={props.saveEvent}
			deleteEvent={props.deleteEvent}
			loading={props.loading}
			canApprove={props.canApprove}
			infoColour={props.infoColour}
		/>
	} breadcrumbs={[{ label: "Events", path: `/${props.isCommunity ? "my-community/" : ""}events` }, "Edit event"]} />;
};

EditEventController.propTypes = { 
	event: PropTypes.object.isRequired,
	saveEvent: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	canApprove: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	infoColour: PropTypes.string.isRequired
};

EditEventController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	event: state.events.event,
	loading: isLoading(state),
	canEdit: ownProps.isCommunity ? canEditCommunityEvent(state) : canEditEvent(state),
	canApprove: canApproveEvents(state),
	infoColour: state.theme.infoColour
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getEvent: eventId => dispatch(getEvent(eventId)),
	saveEvent: (event, newFiles) => dispatch(saveEvent(event, newFiles, ownProps.isCommunity)),
	deleteEvent: eventId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this event?",
		onOk: () => dispatch(deleteEvent(eventId, ownProps.isCommunity))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEventController);