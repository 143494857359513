import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, CircularProgress, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { get } from '../../utils/ajax';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import CompetencyCredentialsDialog from './CredentialsDialog';
import Scrollbars from 'react-custom-scrollbars-2';
import Badge from './Badge';
import PassportStat from './PassportStat';
import AssessedIcon from '@mui/icons-material/Grade';
import ReviewedIcon from '@mui/icons-material/AssignmentTurnedIn';
import TotalIcon from '@mui/icons-material/TrendingUp';
import FrameworkDialog from '../widgets/FrameworkDialog';
import GridLayout from 'react-grid-layout';
import Dashboardwidget from '../dashboardWidgets/DashboardWidget';
import Competency from '../dashboardWidgets/Competency';
import CompetencyPercentage from '../dashboardWidgets/CompetencyPercentage';
import TopCompetencies from '../dashboardWidgets/TopCompetencies';
import MyTopCompetencies from '../dashboardWidgets/MyTopCompetencies';
import BottomCompetencies from '../dashboardWidgets/BottomCompetencies';
import HighPriorityMissingCompetencies from '../dashboardWidgets/HighPriorityMissingCompetencies';
import CombinedProfiles from '../dashboardWidgets/CombinedProfiles';
import PrimaryProfile from '../dashboardWidgets/PrimaryProfile';
import SecondaryProfile from '../dashboardWidgets/SecondaryProfile';
import DevelopmentNeeds from '../dashboardWidgets/DevelopmentNeeds';
import SkillsGap from '../dashboardWidgets/SkillsGap';
import UnitsOfCompetencyDialog from './UnitsOfCompetencyDialog';
import startsWith from 'lodash/startsWith';
import trimStart from 'lodash/trimStart';

const tileStyles = {
	padding: "60px 0 0 0",
	backgroundColor: "#fff",
	// overflow: "hidden", 
	borderRadius: "4px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)" 
};

const Passport = props => {
	const [credentialsOpen, setCredentialsOpen] = React.useState(false);
	const [state, setState] = React.useState({
		profileChartLoading: true,
		profileChartData: [],
		aspirationalChartLoading: true,
		aspirationalChartData: [],
		frameworkDialogOpen: false,
		unitsDialogOpen: false
	});
	
	React.useEffect(() => {
		get({
			url: `/api/competencies/${props.userId}/competencies-for-profile-by-priority`,
			onSuccess: data => setState(prevState => ({
				...prevState,
				profileChartData: data,
				profileChartLoading: false
			})),
			onError: () => setState(prevState => ({ ...prevState, profileChartLoading: false }))
		});
		
		get({
			url: `/api/competencies/${props.userId}/competencies-for-aspirational-profile-by-priority`,
			onSuccess: data => setState(prevState => ({
				...prevState,
				aspirationalChartData: data,
				aspirationalChartLoading: false
			})),
			onError: () => setState(prevState => ({ ...prevState, aspirationalChartLoading: false }))
		});
	}, []);
	
	const { passport, pcSingle, acSingle } = props;
	const primaryButton = <ButtonLink to="/skills/primary" size="large">Add/Edit {pcSingle} Profile</ButtonLink>,
		secondaryButton = <ButtonLink to="/skills/secondary" size="large" disabled={props.secondaryProfileVisibility === 'Disable'}>Add/Edit {acSingle} Profile</ButtonLink>;

	const dashboardLayout = props.dashboard ? props.dashboard.layout : [];

	const skillsDashboardWidgetsDefault = {
		competencyStatus: false,
		competencyStatusPercent: false,
		topCompetencies: false,
		myTopCompetencies: true,
		highPriorityMissingCompetencies: false,
		bottomCompetencies: false,
		combinedProfiles: true,
		competencyProfile: true,
		aspirationalCompetencyProfile: true,
		developmentNeeds: false,
		skillsGap: false
	};

	const dashboardWidgets = props.dashboard ? props.dashboard.widgets || skillsDashboardWidgetsDefault : skillsDashboardWidgetsDefault;
	const items = [];

	items.push(
		<div 
			key="1.2" 
			data-grid={{ x: 0, y: 1, w: 6, h: 6 }} 
		>
			<Grid container spacing={3}>
				<Grid item xs={4}>
					<span className="h3">Name:</span>
					<div className="passport-field-text" style={{ minHeight: '45px' }}><span>{passport.name}</span></div>
					<PassportStat 
						count={passport.targetCompetencies || 0} 
						label="Meets Target" 
						icon={<AssessedIcon className="stat-icon"  />}
						colour="#836BA9"
					/>
				</Grid>
				<Grid item xs={4}>
					<span className="h3">Organisation:</span>
					<div className="passport-field-text" style={{ minHeight: '45px' }}><span>{passport.userOrganisation}</span></div>
					<PassportStat 
						count={passport.notTargetOrAssessedCompetencies || 0} 
						label="Below Target or Unassessed" 
						icon={<ReviewedIcon className="stat-icon" />}
						colour="#7CA363"
					/>
				</Grid>
				<Grid item xs={4}>
					<span className="h3">Profile:</span>
					<div className="passport-field-text" style={{ minHeight: '45px' }}><span>{passport.skillsProfileName}</span></div>
					<PassportStat 
						count={passport.totalCompetencies || 0} 
						label="Total" 
						icon={<TotalIcon className="stat-icon"  />}
						colour="#D05957"
					/>
				</Grid>
				{passport.userCanSelectSkillsFramework &&
					<Grid item xs={12}>
						<span className="h3">
							Current {pcSingle} Framework(s): 
							<IconButton
                                onClick={() => setState({ ...state, frameworkDialogOpen: true })}
                                style={{ marginLeft: 5 }}
                                size="large">
								<EditIcon />
							</IconButton>
						</span>
						{passport.skillsFrameworks.map((f, i) => 
							<span key={i} style={{ marginLeft: i > 0 ? 10 : 0 }}>
								<IconButton
                                    onClick={() => props.removeSkillsFramework(f)}
                                    style={{ marginRight: 5 }}
                                    size="large">
									<DeleteIcon style={{ color: props.errorColour }} />
								</IconButton>
								{f}
							</span>
						)}
					</Grid>
				}
				<Grid item xs={12} className="button-container">
					<div>
						<div 
							className="skill-button-prompt-text"
							style={{
								visibility: passport.skillsProfileId === 0 ? 'visible' : 'hidden'
							}}
						>Click to select skills profile</div>
						{props.pcDescription ? <Tooltip title={props.pcDescription}>{primaryButton}</Tooltip> : primaryButton}
					</div>
					{props.secondaryProfileVisibility !== 'Hide' &&
					<div>
						<div 
							className="skill-button-prompt-text"
							style={{
								visibility: passport.aspirationalSkillsProfileId === 0 && props.secondaryProfileVisibility === 'Show' ? 'visible' : 'hidden'
							}}
						>Click to select skills profile</div>
						{props.acDescription ? <Tooltip title={props.acDescription}>{secondaryButton}</Tooltip> : secondaryButton}
					</div>
					}
				</Grid>
			</Grid>		
		</div>
	);

	const by = "department";
	const byChartSuffix = "";
	const onChangeDepartment = () => {};
	const departmentId = 0;

	if (dashboardWidgets.competencyStatus) {
		items.push(
			<div key="2" data-grid={{ x: 0, y: 1, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title={`Competency Status`}
					organisationId={passport.organisationId}
					departmentId={departmentId}
					by={by}
					widget={Competency}
				/>
			</div>
		);
	}

	if (dashboardWidgets.competencyStatusPercent) {
		items.push(
			<div key="3" data-grid={{ x: 6, y: 1, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title={`Competency Status${byChartSuffix} %`}
					organisationId={passport.organisationId}
					departmentId={departmentId}
					by={by}
					onChangeDepartment={onChangeDepartment}
					widget={CompetencyPercentage}
				/>
			</div>
		);
	}
	
	if (dashboardWidgets.topCompetencies) {
		items.push(
			<div key="4" data-grid={{ x: 0, y: 7, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title="Top Competencies"
					organisationId={passport.organisationId}
					departmentId={departmentId}
					by={by}
					onChangeDepartment={onChangeDepartment}
					widget={TopCompetencies}
				/>
			</div>
		);
	}

	if (dashboardWidgets.bottomCompetencies) {
		items.push(
			<div key="5" data-grid={{ x: 0, y: 13, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title="Bottom Competencies"
					organisationId={passport.organisationId}
					departmentId={departmentId}
					by={by}
					onChangeDepartment={onChangeDepartment}
					widget={BottomCompetencies}
				/>
			</div>
		);
	}

/* <div key="5" data-grid={{ x: 0, y: 13, w: 6, h: 6 }} style={tileStyles}>
	<h2 style={titleHeaderStyles}>Missing Competencies by Priority By {byChartSuffix}</h2>
	{state.missingCompetenciesByPriorityByRoleLoading && <WidgetLoading />}
	{!state.missingCompetenciesByPriorityByRoleLoading && <MissingCompetenciesByPriority by={by} data={state.missingCompetenciesByPriorityByRole} onChangeDepartment={onChangeDepartment} />}
</div> */

	if (dashboardWidgets.highPriorityMissingCompetencies) {
		items.push(
			<div key="6" data-grid={{ x: 7, y: 7, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title={`High Priority Missing Competencies by ${byChartSuffix}`}
					organisationId={passport.organisationId}
					departmentId={departmentId}
					by={by}
					onChangeDepartment={onChangeDepartment}
					widget={HighPriorityMissingCompetencies}
				/>
			</div>
		);
	}

	if (dashboardWidgets.combinedProfiles) {
		items.push(
			<div key="7" data-grid={{ x: 7, y: 1, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title="Combined Profiles: How I Compare"
					userId={props.userId}
					widget={CombinedProfiles}
				/>
			</div>
		);
	}

	if (dashboardWidgets.competencyProfile) {
		items.push(
			<div key="8" data-grid={{ x: 0, y: 7, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title={`${props.pcSingle} Profile`}
					userId={props.userId}
					widget={PrimaryProfile}
				/>
			</div>
		);
	}

	if (dashboardWidgets.aspirationalCompetencyProfile) {
		items.push(
			<div key="9" data-grid={{ x: 7, y: 7, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title={`${props.acSingle} Profile`}
					userId={props.userId}
					widget={SecondaryProfile}
				/>
			</div>
		);
	}

	if (dashboardWidgets.developmentNeeds) {
		items.push(
			<div key="10" data-grid={{ x: 0, y: 13, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title="My Skills Profile Summary"
					description="The My Skills Profile Summary chart shows a summary of required and achieved competencies across groups."
					userId={props.userId}
					widget={DevelopmentNeeds}
				/>
			</div>
		);
	}

	if (dashboardWidgets.myTopCompetencies) {
		items.push(
			<div key="11" data-grid={{ x: 7, y: 13, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title="My Top Competencies"
					userId={props.userId}
					widget={MyTopCompetencies}
				/>
			</div>
		);
	}
	
	if (dashboardWidgets.skillsGap) {
		items.push(
			<div key="12" data-grid={{ x: 0, y: 19, w: 6, h: 6 }} style={tileStyles}>
				<Dashboardwidget
					title="Skills Gap"
					userId={props.userId}
					widget={SkillsGap}
				/>
			</div>
		);
	}

	return (
		<div className="passport">
			<GridLayout 
				className="layout" 
				cols={12} 
				rowHeight={45} 
				width={1280} 
				containerPadding={[0, 0]}
				margin={[30, 30]}
				style={{ marginLeft: "0" }}
				layout={dashboardLayout}
				isDraggable={false}
				isResizable={false}
			>
				{items}
			</GridLayout>
			
			<Grid container spacing={3}>
				<Grid item xs={12} className="button-container" style={{ marginTop: 20 }}>
					<ButtonLink to={startsWith(props.mySkillsButton1Report, '/') ? props.mySkillsButton1Report : `/reports/view/${trimStart(props.mySkillsButton1Report, '/')}?fromSkills=true`} color="secondary" size="large">
						{props.mySkillsButton1Label}
					</ButtonLink>
					<Button disabled onClick={() => setCredentialsOpen(true)} size="large">Edit Credentials &amp; Evidence</Button>	
					{props.secondaryProfileVisibility !== 'Hide' && props.mySkillsButton2Report &&
						<ButtonLink to={startsWith(props.mySkillsButton2Report, '/') ? props.mySkillsButton2Report : `/reports/view/${trimStart(props.mySkillsButton2Report, '/')}?fromSkills=true`} color="secondary" size="large" disabled={props.secondaryProfileVisibility === 'Disable'}>
							{props.mySkillsButton2Label}
						</ButtonLink>
					}
				</Grid>
			</Grid>
			{props.canViewBadges &&
				<div className="badges">
					<h3>
						Badges
						{props.badgesLoading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
					</h3>
					{props.badges.length === 0 ? <p>You have no badges</p> :
						<Scrollbars autoHeight autoHeightMax="100%">
							<div style={{ display: "flex", height: 305 }}>
								{props.badges.map((b, i) => <Badge key={i} badge={b} />)}
							</div>
						</Scrollbars>
					}
				</div>
			}
			<CompetencyCredentialsDialog
				credentials={props.credentials}
				open={credentialsOpen}
				onClose={() => setCredentialsOpen(false)}
				onOk={(credentials, newFiles) => {
					setCredentialsOpen(false);
					props.updateCredentials(credentials, newFiles);
				}}
			/>
			<FrameworkDialog
				open={state.frameworkDialogOpen}
				onChange={frameworks => {
					setState({ ...state, frameworkDialogOpen: false });
					props.setSkillsFrameworks(frameworks);
				}}		
				onClose={() => setState({ ...state, frameworkDialogOpen: false })}		
				organisationId={passport.organisationId}
				isMulti
			/>
			<UnitsOfCompetencyDialog
				open={props.showUnitsDialog}
				onClose={() => {
					props.onCloseUnitsDialog();
				}}
			/>
		</div>
	);
}

Passport.propTypes = {
	dashboard: PropTypes.object,
	passport: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	mySkillsButton1Label: PropTypes.string.isRequired,
	mySkillsButton1Report: PropTypes.string.isRequired,
	mySkillsButton2Label: PropTypes.string.isRequired,
	mySkillsButton2Report: PropTypes.string.isRequired,
	pcSingle: PropTypes.string.isRequired,
	acSingle: PropTypes.string.isRequired,
	pcDescription: PropTypes.string.isRequired,
	acDescription: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	secondaryProfileVisibility: PropTypes.string.isRequired,
	setSkillsFrameworks: PropTypes.func.isRequired,
	removeSkillsFramework: PropTypes.func.isRequired,
	showUnitsDialog: PropTypes.bool.isRequired,
	onCloseUnitsDialog: PropTypes.func.isRequired,
	errorColour: PropTypes.string.isRequired
};

Passport.defaultProps = {
	dashboard: null
};

export default Passport;