import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import { logIn } from '../../store/context';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import Form from '../widgets/Form';
import { withStyles } from 'tss-react/mui';
// import { GoogleLogin } from '@react-oauth/google';
import GoogleButton from '../widgets/GoogleButton';
import LinkedInButton from '../widgets/LinkedInButton';

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

const styles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
};

const form = {
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "identity",
			required: true,
			label: "Username",
			style: { marginTop: 10 },
			autoFocus: true
		},
		{
			name: "password",
			required: true,
			label: "Password",
			type: "password"
		},
		{
			name: "keepLoggedIn",
			label: "Keep me logged in",
			type: "checkbox",
			style: { marginBottom: 0 }
		}
	]
};

const defaultAcceptTermsContent = `In accessing the Research 2 U platform you are agreeing to our standard <a href="/documents/R2B Terms.pdf" target="_blank" rel="noopener noreferrer">terms and conditions</a> and our associated <a href="/R2B Privacy Policy.pdf" target="_blank" rel="noopener noreferrer">privacy policy</a>.`;

const Login = props => {
	const { fields, values } = props;
	const [showRegistrationPathDialog, setShowRegistrationPathDialog] = React.useState(false);

	const RegisterButton = props.canSelfRegister ? 
		<ButtonLink to="/register-path" onClick={() => setShowRegistrationPathDialog(true)} className="grey button" size="large" style={{ marginLeft: 20 }}>Register</ButtonLink>
		: <ButtonLink to="/create-account" className="grey button" size="large" style={{ marginLeft: 20 }}>Register</ButtonLink>
	;
	// const responseMessage = (response) => {
    //     console.log(response);
    // };
    // const errorMessage = (error) => {
    //     console.log(error);
    // };


	// const RegisterButton  = 
	// 	<Button
	// 	onClick={() => {
	// 			// window.auth0lock.show();
	// 			window.auth0.popup.authorize({
	// 				responseType: 'token',
	// 				redirectUri: 'https://localhost:5102/register',
	// 				connection: 'google-oauth2'

	// 				//Any additional options can go here
	// 			  }, function(err, authResult) {
	// 				//do something
	// 			  });

	// 	}}
	// 	>
	// 		Register
	// 	</Button>;

	return (
        <React.Fragment>
			<h1 className="h3">Login</h1>
			<small>Please login or register below.</small>
			<Form onSubmit={() => props.validateFields() && props.logIn(values.identity, values.password, values.keepLoggedIn)}>
				{fields.identity()}
				{fields.password()}
				{/* Submit + Register */}
				<div style={{ textAlign: "center" }}>
					<Button type="submit" size="large" color="secondary">Login</Button>
					{props.showRegistration && RegisterButton}
				</div>
			</Form>

			{/* <div id="auth0-test"></div>
<div id="g_id_onload"
     data-client_id="921376810884-9l954f3acj1bcf54eqr81ms4dmfr81hq.apps.googleusercontent.com"
     data-context="signin"
     data-ux_mode="redirect"
     data-login_uri="https://localhost:5102/signin-google"
     data-auto_prompt="false">
</div>

<div class="g_id_signin"
     data-type="standard"
     data-shape="rectangular"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
</div>
			*/}



            {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />  */}

			{/* Remember Me + Reset Password */}
			<Grid container spacing={3} justifyContent="center" alignItems="center">
				<Grid item xs="auto">{fields.keepLoggedIn()}</Grid>
				<Grid item xs="auto">
					<ButtonLink to="/account/reset-password" variant="text" size="small" color="primary">Forgot password?</ButtonLink>
				</Grid>
			</Grid>
			<Grid container spacing={3} justifyContent="center" alignItems="center">
				<Grid item>
				<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.loginAcceptTermsContent || defaultAcceptTermsContent }} />
				</Grid>
			</Grid>

			<p
				style={{
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '18px'
				}}
			>OR USE</p>
			<GoogleButton />
			<div style={{ marginTop: '10px' }}>
				<LinkedInButton />
			</div>


			<Dialog
				open={showRegistrationPathDialog}
				onClose={() => setShowRegistrationPathDialog(false)}
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
				maxWidth="xl"
				fullWidth={true}
				scroll="body"
				classes={{ paper: props.classes.dialogPaper }}
			>
				<DialogTitle id="dialog-title">Registration Type</DialogTitle>
				<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
					<div 
						className="registration-path"
						style={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							paddingTop: '64px',
							left: 0,
							right: 0,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<div className="leftPath">
							<ButtonLink
								to="/register?path=buyer"
								variant="outlined"
								style={{
									height: '150px',
									width: '150px',
									marginRight: '20px'
								}}
							>Buyer</ButtonLink>
						</div>
						<div className="rightPath">
							<ButtonLink 
								to="/register?path=seller"
								variant="outlined"
								style={{
									height: '150px',
									width: '150px',
									marginLeft: '20px'
								}}
							>Seller</ButtonLink>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</React.Fragment>
    );
};

Login.props = {
	logIn: PropTypes.func.isRequired,
	showRegistration: PropTypes.bool.isRequired,
	loginAcceptTermsContent: PropTypes.string.isRequired,
	canSelfRegister: PropTypes.bool
};

Login.defaultProps = {
	canSelfRegister: false
};

const mapStateToProps = state => ({
	showRegistration: state.context.registrationProcess !== "AdminOnly" && state.context.registrationProcess !== "Hidden",
	loginAcceptTermsContent: state.settings.loginAcceptTermsContent,
	canSelfRegister: state.context.canSelfRegister
});

const mapDispatchToProps = dispatch => ({
	logIn: (identity, password, keepLoggedIn) => dispatch(logIn(identity, password, keepLoggedIn))
});

export default connect(mapStateToProps, mapDispatchToProps)(
	withStyles(withForm(form)(withMobileDialog()(Login)), styles)
);