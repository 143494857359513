import every from 'lodash/every';

// Function Helpers
export const hasFunction = name => state => state.context.functions.includes(name);
export const hasFunctions = functions => state => every(functions, f => state.context.functions.includes(f));
export const hasAnyFunction = functions => state => functions.some(f => state.context.functions.includes(f));

// Role Helpers
export const hasRole = name => state => state.context.roles.includes(name);
export const hasRoles = roles => state => every(roles, r => state.context.roles.includes(r));
export const hasAnyRole = roles => state => roles.some(r => state.context.roles.includes(r));

// ----------
//	Tenants
// ----------

export const canViewTenants = state => state.context.isGlobalAdministrator;
export const canSubmitTenants = state => state.context.isGlobalAdministrator;
export const canEditTenant = state => state.context.isGlobalAdministrator;

// ------------
//	Frameworks
// ------------

export const canViewFrameworks = hasFunction("ViewFrameworks");
export const canEditFrameworks = hasFunction("EitFrameworks");

// ----------
//	Problems
// ----------

export const canViewProblems = hasFunction("ProblemView");

export const canSubmitProblems = hasFunction("ProblemSubmit");

export const canEditProblem = state => 
	hasFunction("ProblemEdit")(state) || state.problems.problem.authorId === state.context.userId;

// --------
//	Events
// --------

export const canViewEvents = hasFunction("EventView");

export const canSubmitEvents = hasFunction("EventSubmit");

export const canEditEvent = state => 
	hasFunction("EventEdit")(state) || state.events.event.createdByUserId === state.context.userId;

export const canRequestEvents = hasFunction("EventRequest");

export const canApproveEvents = hasFunction("EventApprove");

// ---------------
//	Offers
// ---------------

export const canViewOffers = hasFunction("OfferView");

export const canSubmitOffers = hasFunction("OfferSubmit");

export const canEditOffer = state =>
	hasFunction("OfferEdit")(state) || state.offers.offer.ownerId === state.context.userId;

export const canViewApplications = hasFunction("ApplicationView");

export const canEditApplication = hasFunction("ApplicationEdit");

export const isOfferAdmin = hasFunction("OfferAdmin");

// ---------------
//	Business Challenges (mainly piggy back off offers)
// ---------------

export const showBusinessChallengesRequestListing = hasFunction("BusinessChallengesRequestListing");

// -------
//	Users
// -------

export const canViewUsers = hasFunction("UserView");

export const canSubmitUsers = hasFunction("UserSubmit");

export const canEditUser = hasFunction("UserEdit");

// -----------------
//	Member Features
// -----------------

export const canViewFeatures = hasFunction("FeatureView");

export const canSubmitFeatures = hasFunction("FeatureSubmit");

export const canEditFeature = hasFunction("FeatureEdit");

// -----------------
//	IP Registration
// -----------------

export const canViewIP = hasFunction("IpView");

export const canSubmitIP = hasFunction("IpSubmit");

export const canEditIP = state => 
	state.context.functions.includes("IpEdit") || state.ipRegister.ip.ownerId === state.context.userId;

export const canViewIPEnquiries = hasFunction("IPEnquiryAdmin");

// ----------
//  AspireIQ
// ----------

export const canViewHome = hasFunction("HomeView");

export const canViewAdmin = hasFunction("AdminView");

export const canViewPassport = hasFunction("PassportView");

export const canViewCompetencies = hasFunction("CompetenciesView");

export const canViewTools = hasAnyFunction(["ToolView", "LearningDesignTools"]);

export const canSubmitTools = hasAnyFunction(["ToolSubmit", "LearningDesignToolsAdd"]);

export const canEditTool = state => 
	hasFunction("ToolEdit")(state) || state.tools.tool.createdBy === state.context.userId;

export const canViewCourses = hasFunction("CourseView");
export const canOnlyViewLDCourses = hasFunction("CoursesViewL&DOnly");

export const canSubmitCourses = hasFunction("CourseSubmit");

export const canEditCourse = state => course => hasFunction("CoursesAdmin")(state) || (hasFunction("CourseEdit")(state) && course && course.providerId === state.context.organisationId);

export const canAdminCLO_Courses = hasFunction("CLO_CoursesAdmin");
export const canEditCLO_Course = state => course => hasFunction("CLO_CoursesAdmin")(state) || (hasFunction("CLO_CourseEdit")(state) && course && course.providerId === state.context.organisationId);
export const canSubmitCLO_Courses = hasFunction("CLO_CourseSubmit");
export const canViewCLO_Courses = hasFunction("CLO_CourseView");

export const canViewAcademics = hasFunction("AcademicView");

export const canSubmitAcademics = hasFunction("AcademicSubmit");

export const canEditAcademic = hasFunction("AcademicEdit");

export const canViewCommunities = hasFunction("CommunityView");

export const canViewUserCommunities = state => true;

export const canSubmitCommunities = hasFunction("CommunitySubmit");

export const canEditCommunity = hasFunction("CommunityEdit");

export const canViewActivities = hasAnyFunction(["ActivityView", "LearningDesignActivities"]);

export const canSubmitActivities = hasAnyFunction(["ActivitySubmit", "LearningDesignActivitiesAdd"]);

export const canEditActivity = hasFunction("ActivityEdit");

export const canViewDashboard = hasFunction("DashboardView");

export const canViewUserDashboard = hasFunction("UserDashboard");

export const canViewSkillsProfiles = hasFunction("SkillsProfilesView");

export const canViewSettings = hasFunction("SettingsView");

export const canViewAdminCourses = hasFunction("AdminCoursesView");

export const canViewAdminCommunities = hasFunction("AdminCommunitiesView");

export const canViewOrganisations = hasFunction("OrganisationsView");

export const canManageTeamsAcrossTenant = hasFunction("TenantTeamManage");

export const canViewThemes = hasFunction("ThemesView");

export const canViewLogs = state => state.context.isGlobalAdministrator; // To-Do: Add Function

export const canViewRoles = hasFunction("RolesView");

export const canEditRole = state =>
	state.context.isGlobalAdministrator || state.roles.role.tenantId === state.context.tenantId;

export const canViewReports = hasFunction("ReportsView");

export const canViewCentres = hasFunction("ResearchCentreView");

export const canSubmitCentres = hasFunction("ResearchCentreSubmit");

export const canEditCentre = hasFunction("ResearchCentreEdit");

export const canViewInnovation = hasFunction("Innovation");

export const canViewMentoring = hasFunction("MentoringView");

export const canViewDepartments = hasFunction("DepartmentView");
export const canEditDepartment = hasFunction("DepartmentEdit");
export const canManageDepartmentsAcrossTenant = hasFunction("TenantDepartmentManage");

export const canViewPositions = hasFunction("PositionView");

export const canEditPosition = hasFunction("PositionEdit");

export const canViewContactUs = hasFunction("ContactUsView");

export const canViewLibrary = hasFunction("LibraryView");

export const canViewResources = hasFunction("ResourceView");

export const canSubmitResources = hasFunction("ResourceSubmit");

export const canEditResource = state =>
	hasFunction("ResourceEdit")(state) || state.resources.resource.createdBy === state.context.userId;

export const canViewDiscussion = hasFunction("DiscussionView");

export const canEditDiscussion = state => 
	hasFunction("DiscussionEdit")(state) || state.discussion.discussion.createdBy === state.context.userId;

export const canViewBadges = hasFunction("BadgesView");

export const canViewTeamSkills = hasFunction("TeamSkillsView")
export const canViewTeamSkillsSummary = hasFunction("TeamSkillsSummary")

export const canViewAdminDashboard = hasFunction("OrganisationsView");

export const canViewProfileGroups = hasFunction("ProfileGroupsView");

export const canEditProfileGroup = hasFunction("ProfileGroupsEdit");

export const canViewLearningDesign = hasFunction("LearningDesign");

export const canViewLearningDesignTools = hasFunction("LearningDesignTools");

export const canViewLearningDesignActivities = hasFunction("LearningDesignActivities");

export const canViewCareerPathway = hasFunction("CareerPathway");

export const canViewAdminReports = hasFunction("AdminReportsView");

export const viewProblemsDisabled = hasFunction("ViewProblemsDisabled");

export const canSubscribe = hasFunction("CanSubscribe");

export const canImpersonate = state => state.context.isGlobalAdministrator || state.context.isTenantAdministrator;

export const canAdminSubscriptions = state => state.context.isGlobalAdministrator || hasFunction("SubscriptionAdmin");

export const canViewMentors = hasFunction("MentorView");

export const canSubmitMentors = hasFunction("MentorSubmit");

export const canEditMentor = state =>
	hasFunction("MentorEdit")(state) || state.mentors.mentor.createdBy === state.context.userId;

export const canViewMentees = hasFunction("MenteeView");

export const canSubmitMentees = hasFunction("MenteeSubmit");

export const canEditMentee = state =>
	hasFunction("MenteeEdit")(state) || state.mentees.mentee.createdBy === state.context.userId;

export const canViewStudents = hasFunction("StudentView");

export const canSubmitStudents = hasFunction("StudentSubmit");

export const canEditStudent = hasFunction("StudentEdit");

export const canViewStudentProjects = hasFunction("StudentProjectView");

export const canSubmitStudentProjects = hasFunction("StudentProjectSubmit");

export const canEditStudentProject = hasFunction("StudentProjectEdit");

export const canViewEnquiries = state =>
	hasFunction("EnquiryAdmin")(state) || state.studentProjects.project.createdBy === state.context.userId;

export const canViewInsights = hasFunction("InsightView");

export const canSubmitInsights = hasFunction("InsightSubmit");

export const canEditInsight = state =>
	hasFunction("InsightEdit")(state) || state.insights.insight.createdBy === state.context.userId;

export const isInsightAdmin = hasFunction("InsightAdmin");

export const canViewExperts = hasFunction("ExpertView");

export const canSubmitExperts = hasFunction("ExpertSubmit");

export const canEditExpert = state =>
	hasFunction("ExpertEdit")(state) || state.experts.expert.createdBy === state.context.userId;

export const canViewTeams = () => hasFunction("TeamLeader") || hasFunction("DepartmentView") || hasFunction("TeamManager");

export const canSubmitTeams = () => hasFunction("DepartmentEdit") || hasFunction("TeamManager");
export const isTeamManager = hasFunction("TeamManager");

export const canEditTeams = () => hasFunction("TeamLeader") || hasFunction("DepartmentEdit") || hasFunction("TeamManager");

export const canViewBusinessProfiles = () => hasFunction("BusinessProfileAdmin") || hasFunction("BusinessProfileView");

export const canSubmitBusinessProfiles = hasFunction("BusinessProfileAdmin") || hasFunction("BusinessProfileSubmit");

export const canEditBusinessProfile = state => profile => hasFunction("BusinessProfileAdmin")(state) || (profile && profile.organisationId === state.context.organisationId && hasFunction("BusinessProfileEdit"));

export const canAdminBusinessProfile = hasFunction("BusinessProfileAdmin");

export const canViewContent = hasFunction("ContentView");

export const canSubmiContentBusinessProfiles = hasFunction("ContentSubmit");

export const canEditContent = state => content => hasFunction("ContentAdmin")(state) || (hasFunction("ContentEdit")(state) && content && content.organisationId === state.context.organisationId);

export const canViewCollaborations = hasFunction("CollaborationView");

export const canSubmitCollaborations = hasFunction("CollaborationSubmit");

export const canEditCollaboration = hasFunction("CollaborationEdit");

export const canViewCollaborationEnquiries = state =>
	hasFunction("CollaborationEnquiryAdmin")(state) || state.collaborations.collaboration.createdBy === state.context.userId;

export const canAdminCourses = hasFunction("CoursesAdmin");
export const canAdminContent = hasFunction("ContentAdmin");

export const canViewMicroLearn = hasFunction("MicroLearnView");
export const canSubmitMicroLearn = hasFunction("MicroLearnSubmit");
export const canEditMicroLearn = hasFunction("MicroLearnEdit");

// --------------
//  My Community
// --------------

export const canViewMyCommunity = hasFunction("MyCommunityView");

export const myCommunityDisabled = hasFunction("MyCommunityDisabled");

export const canViewCommunityDiscussion = hasFunction("CommunityDiscussion");

export const canEditCommunityDiscussion = state =>
	hasFunction("CommunityDiscussionEdit")(state) || state.discussion.discussion.createdBy === state.context.userId;

export const canViewCommunityEvents = hasFunction("CommunityEvents");

export const canSubmitCommunityEvents = hasFunction("CommunityEventsAdd");

export const canEditCommunityEvent = state =>
	hasFunction("CommunityEventsEdit")(state) || state.events.event.createdByUserId === state.context.userId;

export const canViewCommunityProblems = hasFunction("CommunityProblems");

export const canSubmitCommunityProblems = hasFunction("CommunityProblemsAdd");

export const canEditCommunityProblem = state =>
	hasFunction("CommunityProblemsEdit")(state) || state.problems.problem.authorId === state.context.userId;

export const canViewCommunityResources = hasFunction("CommunityResources");

export const canSubmitCommunityResources = hasFunction("CommunityResourcesAdd");

export const canEditCommunityResource = state =>
	hasFunction("CommunityResourcesEdit")(state) || state.resources.resource.createdBy === state.context.userId;

export const canViewCommunityTools = hasFunction("CommunityTools");

export const canSubmitCommunityTools = hasFunction("CommunityToolsAdd");

export const canEditCommunityTool = state =>
	hasFunction("CommunityInsightsEdit")(state) || state.tools.tool.createdBy === state.context.userId;

export const canViewCommunityInsights = hasFunction("CommunityInsights");

export const canSubmitCommunityInsights = hasFunction("CommunityInsightsAdd");

export const canEditCommunityInsight = state =>
	hasFunction("CommunityInsightsEdit")(state) || state.insights.insight.createdBy === state.context.userId;

export const canViewUIThemes = hasFunction("ApplicationThemeView");
export const canCreateUIThemes = hasFunction("ApplicationThemeCreate");
export const canEditUIThemes = hasFunction("ApplicationThemeEdit");

export const requireBusinessProfile = hasFunction("RequireBusinessProfile");

export const canMakePublic = hasFunction("CanMakePublic");

// --------------
//	Prop Helpers
// --------------

export const canViewProps = state => ({
	academics: canViewAcademics(state),
	activities: canViewActivities(state),
	admin: canViewAdmin(state),
	base: state.context.isLoggedIn,
	businessProfiles: canViewBusinessProfiles(state),
	centres: canViewCentres(state),
	collaborations: canViewCollaborations(state),
	contact: canViewContactUs(state),
	content: canViewContent(state),
	copyright: true,
	courses: canViewCourses(state),
	cloCourses: canViewCLO_Courses(state),
	departments: canViewDepartments(state),
	disclaimer: true,
	discussion: canViewDiscussion(state),
	engagement: true,
	events: canViewEvents(state),
	experts: canViewExperts(state),
	home: canViewHome(state),
	innovation: canViewInnovation(state),
	insights: canViewInsights(state),
	ip: canViewIP(state),
	library: canViewLibrary(state),
	mentoring: canViewMentoring(state),
	mentors: canViewMentors(state),
	mentees: canViewMentees(state),
	microlearn: canViewMicroLearn(state),
	myCommunity: canViewMyCommunity(state),
	myCommunityDiscussion: canViewCommunityDiscussion(state),
	myCommunityEvents: canViewCommunityEvents(state),
	myCommunityInsights: canViewCommunityInsights(state),
	myCommunityProblems: canViewCommunityProblems(state),
	myCommunityResources: canViewCommunityResources(state),
	myCommunityTools: canViewCommunityTools(state), 
	offers: canViewOffers(state),
	businessChallenges: canViewOffers(state),
	positions: canViewPositions(state),
	problems: canViewProblems(state),
	reports: canViewReports(state),
	resources: canViewResources(state),
	skills: canViewPassport(state),
	students: canViewStudents(state),
	studentProjects: canViewStudentProjects(state),
	survey: true,
	teams: canViewTeams(state),
	teamSkills: canViewTeamSkills(state),
	teamSkillsSummary: canViewTeamSkillsSummary(state),
	tools: canViewTools(state),
	learningDesign: canViewLearningDesign(state),
	careerPathway: canViewCareerPathway(state),
	subscription: canAdminSubscriptions(state),
	impersonate: canImpersonate(state),
	subscriptionComplete: true,
	sfiaReport: true,
	cloReport: true,
	uiThemes: canViewUIThemes(state),
	upgrade: true,
	wef: true
});

export const viewAdminProps = state => ({
	communities: canViewCommunities(state),
	courses: canViewAdminCourses(state),
	dashboard: canViewAdminDashboard(state),
	departments: canViewDepartments(state),
	features: canViewFeatures(state),
	frameworks: canViewFrameworks(state),
	groups: canViewProfileGroups(state),
	logs: canViewLogs(state),
	organisations: canViewOrganisations(state),
	positions: canViewPositions(state),
	profiles: canViewSkillsProfiles(state),
	reports: canViewAdminReports(state),
	roles: canViewRoles(state),
	settings: canViewSettings(state),
	tenants: canViewTenants(state),
	themes: canViewThemes(state),
	users: canViewUsers(state),
	subscriptions: canAdminSubscriptions(state)
});

export const viewInnovationProps = state => ({
	academics: state.context.functions.includes("InnovationAcademics"),
	centres: state.context.functions.includes("InnovationResearchCentres"),
	offers: state.context.functions.includes("InnovationOffers"),
	ip: state.context.functions.includes("InnovationIPRegister"),
	problems: state.context.functions.includes("InnovationProblems"),
});

export const disabledInnovationProps = state => ({
	academics: state.context.functions.includes("InnovationAcademicsDisabled"),
	centres: state.context.functions.includes("InnovationResearchCentresDisabled"),
	offers: state.context.functions.includes("InnovationOffersDisabled"),
	ip: state.context.functions.includes("InnovationIPRegisterDisabled"),
	problems: state.context.functions.includes("InnovationProblemsDisabled"),
});

export const viewCommunityProps = state => ({
	discussion: canViewCommunityDiscussion(state),
	events: canViewCommunityEvents(state),
	insights: canViewCommunityInsights(state),
	problems: canViewCommunityProblems(state),
	resources: canViewCommunityResources(state),
	tools: canViewCommunityTools(state),
	cloCourses: canViewCLO_Courses(state)
});

export const submitCommunityProps = state => ({
	discussion: true,
	events: canSubmitCommunityEvents(state),
	insights: canSubmitCommunityInsights(state),
	problems: canSubmitCommunityProblems(state),
	resources: canSubmitCommunityResources(state),
	tools: canSubmitCommunityTools(state),
	cloCourses: canSubmitCLO_Courses(state)
});

export const disabledLearningDesignProps = state => ({
	courseDesign: state.context.functions.includes("LearningDesignCourseDesignDisabled"),
	activities: state.context.functions.includes("LearningDesignActivitiesDisabled"),
	viewTools: state.context.functions.includes("LearningDesignViewToolsDisabled"),
	addTool: state.context.functions.includes("LearningDesignAddToolDisabled")
});

export const showProps = state => ({
	library: state.context.functions.includes("ShowLibrary"),
	mentoring: state.context.functions.includes("ShowMentoring"),
	tools: state.context.functions.includes("ShowTools")
});

export const settingsTabViewProps = state => ({
	theme: state.context.functions.includes("SettingsTabTheme"),
	problemSettings: state.context.functions.includes("SettingsTabProblemSettings"),
	helpResources: state.context.functions.includes("SettingsTabHelpResources"),
	siteLinks: state.context.functions.includes("SettingsTabSiteLinks"),
	generalAdministration: state.context.functions.includes("SettingsTabGeneralAdministration"),
	homePage: state.context.functions.includes("SettingsTabHomePage"),
	navigation: state.context.functions.includes("SettingsTabNavigation"),
	manageReports: state.context.functions.includes("SettingsTabManageReports"),
	terminology: state.context.functions.includes("SettingsTabTerminology"),
	notifications: state.context.functions.includes("SettingsTabNotifications")
});

export const canSubmitProps = state => ({
	academics: canSubmitAcademics(state),
	activities: canSubmitActivities(state),
	centres: canSubmitCentres(state),
	offers: canSubmitOffers(state),
	businessChallenges: canSubmitOffers(state),
	communities: canSubmitCommunities(state),
	competencies: false,
	courses: canSubmitCourses(state),
	cloCourses: canSubmitCLO_Courses(state),
	departments: canEditDepartment(state),
	events: canSubmitEvents(state),
	features: canSubmitFeatures(state),
	ip: canSubmitIP(state),
	mentors: canSubmitMentors(state),
	positions: canEditPosition(state),
	problems: canSubmitProblems(state),
	resources: canSubmitResources(state),
	skills: false,
    teams: canSubmitTeams(state),
	tenants: canSubmitTenants(state),
	tools: canSubmitTools(state),
	uiThemes: canCreateUIThemes(state),
	users: canSubmitUsers(state)
});

export const canEditProps = state => ({
	academic: canEditAcademic(state),
	activity: canEditActivity(state),
	centres: canEditCentre(state),
	offer: canEditOffer(state),
	businessChallenge: canEditOffer(state),
	community: canEditCommunity(state),
	course: canEditCourse(state),
	cloCourse: canEditCLO_Course(state),
	event: canEditEvent(state),
	ip: canEditIP(state),
	mentors: canEditMentor(state),
	problem: canEditProblem(state),
	teams: canEditTeams(state),
	tenants: canEditTenant(state),
	tool: canEditTool(state),
	resources: canEditResource(state),
	uiThemes: canEditUIThemes(state),
	user: canEditUser(state)
});