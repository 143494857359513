import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import some from 'lodash/some';

const form = {
	initValues: props => ({ 
		authorName: props.fullName,
		authorAvatar: props.avatar,
		...props.discussion 
	}),
	fields: [
		{
			name: "subject",
			label: "Subject",
			required: true,
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor"
		}
	]
};

const CreateCollaborationDiscussionDialog = props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth="md"
        TransitionProps={{
            onEnter: props.resetForm
        }}>
		<DialogTitle>Create new discussion</DialogTitle>
		<DialogContent className="discussion-dialog">
			<Grid container spacing={3}>
				<Grid item xs={12}>{props.fields.subject()}</Grid>
				<Grid item xs={12}>{props.fields.description()}</Grid>
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose}>Cancel</Button>
			<Button disabled={some(props.error)} onClick={() => props.validateFields() && props.onSubmit(props.values)}>Create</Button>
		</DialogActions>
	</Dialog>;

CreateCollaborationDiscussionDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	discussion: PropTypes.object
};

CreateCollaborationDiscussionDialog.defaultProps = {
	discussion: {}
};


const mapStateToProps = state => ({
	fullName: state.context.fullName,
	avatar: state.context.avatar || ""
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CreateCollaborationDiscussionDialog));