import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../../utils/forms';
import Button from '../../widgets/Button';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';

const form = {
	initValues: props => props.report,
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
			helpText: "Name of report"
		},
		{
			name: "category",
			label: "Category",
			required: true
		},
		{
			name: "description",
			label: "Description",
			multiline: true
		},
		{
			name: "fileName",
			label: "File name",
			required: true,
			helpText: "Name of target report file",
			endAdornment: ".rdl"
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true
		},
		{
			name: "visible",
			label: "Visible",
			type: "checkbox"
		}
	]
};

const EditReport = props => {
	const { fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit" } report`} loading={loading} className="edit-report">
			<Form onSubmit={() => props.validateFields() && props.saveReport(props.values, props.formFiles.image)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.name()}</Grid>
					<Grid item xs={12} sm={6}>{fields.category()}</Grid>
					<Grid item xs={12} sm={6}>{fields.fileName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.visible()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.image()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} report</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteReport(props.report.reportId)}>
							Delete report
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditReport.propTypes = { 
	report: PropTypes.object,
	saveReport: PropTypes.func.isRequired,
	deleteReport: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditReport.defaultProps = {
	report: {},
	deleteReport: () => null,
	isNew: false
};

export default withForm(form)(EditReport);