import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { showModal } from '../../store/modal';

const Chart = props => {
	const option = props.option;
	if (props.info && !props.option.toolbox) {
		option.toolbox = {
			feature: {
				...props.toolbox,
				myTool1: {
					show: true,
					title: 'Info',
					icon: 'path://M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z',
					onclick: function (){
						props.showInfo(props.info.title, props.info.text);
					}
				}
			}
		};
	} else if (props.toolbox) {
		option.toolbox = {
			feature: {
				...props.toolbox
			}
		};
	}
	
	return (
		<ReactEcharts
			option={option}
			onEvents={props.onEvents}
			style={props.style}
		/>
	);
};

Chart.propTypes = {
	info: PropTypes.object,
	onEvents: PropTypes.object,
	toolbox: PropTypes.object
};

Chart.defaultProps = {
	info: null,
	onEvents: undefined,
	toolbox: undefined
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	showInfo: (title, message) => dispatch(showModal("MESSAGE", { 
		title: title, 
		message: message
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
