import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill'; 
import uniqueId from 'lodash/uniqueId';
import { FormHelperText } from '@mui/material';
import map from 'lodash/map';

const TextEditor = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("text-editor-"));

	return (
		<div style={props.style}>
			<label htmlFor={id} style={{ color: props.error ? props.errorColour : "", ...props.labelStyle }}>
				{props.label}
				{props.required && <span style={{ color: props.errorColour }}> *</span>}
			</label>
			<ReactQuill 
				required={props.required}
				id={id}
				name={props.name}
				value={props.value}
				onChange={(value, delta, source) => {
					// Trigger onChange if user initiated event
					if (source === "user") props.onChange(value);
				}}
				placeholder={props.placeholder}
				modules={{ toolbar: props.hideModules ? false : [
					[{ "size": [] }],
					["bold", "italic", "underline", "strike"],		// toggled buttons
					[{ "list": "ordered" }, { "list": "bullet" }],
					[{ "indent": "-1" }, { "indent": "+1" }],		// outdent/indent
					[{ "color": [] }, { "background": [] }],		// dropdown with defaults from theme
					[{ "font": ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Verdana'] }],
					[{ "align": [] }],
					...[props.showLink ? ["link"] : []], 		// add link module
					...[props.showImage ? ["image"] : []] 		// add image module
				] }}
			/>
			{props.helpText && <FormHelperText style={{ color: props.error ? props.errorColour : "" }}>{props.helpText}</FormHelperText>}
			{props.variables.length > 0 && 
				<FormHelperText>
					{map(props.variables, f => `[[${f.name}]] = ${f.description}`).join(", ")}
				</FormHelperText>
			}
		</div>
	);
});

TextEditor.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	error: PropTypes.bool,
	style: PropTypes.object,
	labelStyle: PropTypes.object,
	helpText: PropTypes.node,
	hideModules: PropTypes.bool,
	showLink: PropTypes.bool,
	showImage: PropTypes.bool,
	variables: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		description: PropTypes.string
	})),
	errorColour: PropTypes.string.isRequired
};

TextEditor.defaultProps = {
	id: "",
	name: "",
	label: "",
	value: "",
	onChange: () => {},
	required: false,
	placeholder: "",
	error: false,
	style: {},
	labelStyle: {},
	helpText: "",
	hideModules: false,
	showLink: false,
	showImage: false,
	variables: []
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
