import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMentee, saveMentee, deleteMentee, isLoading } from '../../store/mentees';
import { canEditMentee } from '../../store/security';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditMentee from './EditMentee';

const EditMenteeController = props => {
	const { menteeId } = props.match.params;

	React.useEffect(() => props.getMentee(menteeId), [menteeId]);

	React.useEffect(() => {
		if (props.mentee.menteeId === menteeId && !props.canEdit) props.revokeAccess();
	}, [props.mentee.menteeId]);

	return <PageLayout title="Edit mentee" pageContent={
		<EditMentee
			mentee={props.mentee}
			saveMentee={props.saveMentee}
			deleteMentee={props.deleteMentee}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Mentees", path: "/mentees" }, "Edit mentee"]} />;
};

EditMenteeController.propTypes = {
	mentee: PropTypes.object.isRequired,
	getMentee: PropTypes.func.isRequired,
	saveMentee: PropTypes.func.isRequired,
	deleteMentee: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	mentee: state.mentees.mentee,
	loading: isLoading(state),
	canEdit: canEditMentee(state)
});

const mapDispatchToProps = dispatch => ({
	getMentee: menteeId => dispatch(getMentee(menteeId)),
	saveMentee: (mentee, imageFile) => dispatch(saveMentee(mentee, imageFile)),
	deleteMentee: menteeId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this mentee?",
		onOk: () => dispatch(deleteMentee(menteeId))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMenteeController);
