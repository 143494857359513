import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { get } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import LDCourses from './LDCourses';
import { post } from '../../utils/ajax';
import { changeLocation } from '../../store/location';
import filter from 'lodash/filter';
import { showModal } from '../../store/modal';

const LDCoursesController = props => {
	const [state, setState] = React.useState({ ldCourses: [], isLoading: true });

	React.useEffect(() => {
		get({
			url: '/api/ld-courses',
			onSuccess: data => setState({ ldCourses: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, []);

	const copyCourse = (ldCourseId, title, code) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		
		post({
			url: `/api/ld-courses/${ldCourseId}/copy?title=${title}&code=${code}`,
			onSuccess: data => {
				setState({ isLoading: false });

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToViewUrl(data.object);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};


	const deleteCourse = (course) => {
		props.confirmDelete(() => {
			setState(prevState => ({ ...prevState, isLoading: true }));
			
			post({
				url: `/api/ld-courses/${course.lD_CourseId}/delete`,
				onSuccess: data => {
					setState({ isLoading: false, ldCourses: filter(state.ldCourses, c => c.lD_CourseId !== course.lD_CourseId) });

					if (data.success) {
						props.showSuccessNotification(data.message);
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setState(prevState => ({ ...prevState, isLoading: false }));
					props.showErrorNotification(error.message);
				}
			});
		});
	};

	return (
		<PageLayout 
			title="L&amp;D Courses" 
			pageContent={
				<LDCourses 
					userId={props.userId} 
					isGlobalAdministrator={props.isGlobalAdministrator}
					organisationId={props.organisationId}
					ldCourses={state.ldCourses} 
					loading={state.isLoading} 
					copyCourse={copyCourse}
					deleteCourse={deleteCourse}
				/>
			} 
			breadcrumbs={["L&D Courses"]} 
		/>
	);
};

LDCoursesController.propTypes = {
	isGlobalAdministrator: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	userId: PropTypes.string.isRequired
};

const mapStateToDispatch = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	organisationId: state.context.organisationId,
	userId: state.context.userId
});

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	goToViewUrl: (ldCourseId) => dispatch(changeLocation(`/course-design/${ldCourseId}`)),
	confirmDelete: (onOk) => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this course?",
		onOk: onOk
	}))
});

export default connect(mapStateToDispatch, mapDispatchToProps)(LDCoursesController);