import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canEditResource, hasFunction, canEditCommunityResource } from '../../store/security';
import { getResource, saveResource, deleteResource, isLoading } from '../../store/resources';
import { revokeAccess } from '../../store/context';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditResource from './EditResource';

const EditResourceController = props => {
	const { resourceId } = props.match.params;

	React.useEffect(() => props.getResource(resourceId), [resourceId]);

	React.useEffect(() => {
		if (props.resource.resourceId === resourceId && !props.canEdit) props.revokeAccess();
	}, [props.resource.resourceId]);

	return <PageLayout title="Edit resource" pageContent={
		<EditResource
			resource={props.resource}
			saveResource={props.saveResource}
			deleteResource={props.deleteResource}
			loading={props.loading}
			canCreatePublicResource={props.canCreatePublicResource}
		/>
	} breadcrumbs={[{ label: "Resources", path: `/${props.isCommunity ? "my-community/" : ""}resources` }, "Edit resource"]} />;
};

EditResourceController.propTypes = {
	resource: PropTypes.object.isRequired,
	getResource: PropTypes.func.isRequired,
	saveResource: PropTypes.func.isRequired,
	deleteResource: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	canEdit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	canCreatePublicResource: PropTypes.bool.isRequired,
};

EditResourceController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	resource: state.resources.resource,
	loading: isLoading(state),
	canEdit: ownProps.isCommunity ? canEditCommunityResource(state) : canEditResource(state),
	canCreatePublicResource: hasFunction("ResourceEdit")(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getResource: resourceId => dispatch(getResource(resourceId)),
	saveResource: (resource, imageFile, attachments) => dispatch(saveResource(resource, imageFile, attachments, ownProps.isCommunity)),
	deleteResource: resourceId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this resource?",
		onOk: () => dispatch(deleteResource(resourceId, ownProps.isCommunity))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditResourceController);
