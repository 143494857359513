import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../store/modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const MessageDialog = props => {
	const ok = () => {
		props.hideModal(); 
		props.onOk();
	}

	return (
		<Dialog open aria-labelledby={props.title} className="message-dialog">
			<DialogTitle id="message-dialog__title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{props.message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button className="message-dialog__ok" onClick={ok}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

MessageDialog.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onOk: PropTypes.func,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

MessageDialog.defaultProps = {
	onOk: () => null
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(MessageDialog);