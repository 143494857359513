import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewBusinessProfile from './ViewBusinessProfile';
import { getBusinessProfile, isLoading, rateBusinessProfile } from '../../store/businessProfiles';
import { canEditBusinessProfile, canViewContent } from '../../store/security';

const ViewBusinessProfileController = props => {
	const { profileId } = props.match.params;
	React.useEffect(() => props.getProfile(profileId), [profileId]);

	const changeRating = (rating) => {
		props.rateBusinessProfile(props.profile.businessProfileId, rating);
	};

	return (
		<PageLayout 
			title="View business profile" 
			pageContent={
				<ViewBusinessProfile 
					profile={props.profile} 
					loading={props.loading} 
					canEdit={props.canEdit(props.profile)} 
					canViewContent={props.canViewContent}
					canViewCourses={props.canViewCourses}
					ratingLoading={props.ratingLoading}
					changeRating={changeRating}
					userOrganisationId={props.userOrganisationId}
					accreditedLogo={props.accreditedLogo}
					primaryColour={props.primaryColour}
                    isSubscribed={props.isSubscribed}
				/>
			} 
			breadcrumbs={[
				{ label: "Business profiles", path: "/business-profiles" }, 
				"View business profile"
			]} 
		/>
	);
};

ViewBusinessProfileController.propTypes = {
	profile: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	ratingLoading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	userOrganisationId: PropTypes.number.isRequired,
	accreditedLogo: PropTypes.string,
	primaryColour: PropTypes.string.isRequired,
    isSubscribed: PropTypes.bool.isRequired
};

ViewBusinessProfileController.defaultProps = {
	accreditedLogo: null
};

const mapStateToProps = state => ({
	profile: state.businessProfiles.profile,
	ratingLoading: state.businessProfiles.ratingLoading,
	loading: isLoading(state),
	canEdit: canEditBusinessProfile(state),
	canViewCourses: canViewContent(state),
	canViewContent: canViewContent(state),
	userOrganisationId: state.context.organisationId,
	accreditedLogo: state.context.organisationAccreditedLogo,
	primaryColour: state.theme.primaryColour,
    isSubscribed: state.context.isSubscribed
});

const mapDispatchToProps = dispatch => ({
	getProfile: profileId => dispatch(getBusinessProfile(profileId)),
	rateBusinessProfile: (profileId, rating) => dispatch(rateBusinessProfile(profileId, rating)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBusinessProfileController);