import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import Button from '../widgets/Button';
import { withForm } from '../../utils/forms';
import { withStyles } from 'tss-react/mui';
import { toggleBusinessProfileDialog } from '../../store/layout';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import NotSubscribedField from '../widgets/NotSubscribedField';
import { get, post } from '../../utils/ajax';

const styles = (theme) => ({
	paper: {
		overflow: 'visible'
	}
});

const form = {
	initValues: ({ profile: p = {}, userOrgId }) => ({ 
		...p, 
		organisationId: userOrgId,
		categories: []
	}),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of profile"
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				filter: (props, value) => {
					return value.organisationId === props.userOrgId;
				},
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		},
		{
			name: "summary",
			label: "Summary",
			required: true,
			multiline: true
		},
		{
			name: "email",
			label: "Email",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "phone",
			label: "Phone number"
		},
		{
			name: "website",
			label: "Website"
		},
		{
			name: "location",
			label: "Location"
		},
		{
			name: "logo",
			label: "Logo",
			type: "imageUploader",
			single: true
		},
		{
			name: "areasOfExpertise",
			label: "Area Of Expertise",
			type: "areaOfExpertiseEditor"
		},
		{
			name: "about",
			label: "About",
			type: "textEditor"
		},
		{
			name: "products",
			label: "Products/Services",
			type: "textEditor"
		},
		{
			name: "caseStudies",
			label: "Case Studies",
			type: "textEditor"
		},
		{
			name: "accredited",
			label: "ILP Member",
			type: "checkbox"
		},
		{
			name: "accreditationId",
			label: "Membership Status",
			type: "autoComplete",
			loadItems: {
				route: "accreditations",
				mapItem: a => ({ label: a.name, value: a.accreditationId })
			},
			defaultItem: ({ label: "None", value: null })
		},
		{
			name: "categories",
			label: "Categories",
			type: "autoComplete",
			loadItems: {
				route: "categories",
				mapItem: ({ categoryId, name }) => ({ label: name, value: categoryId, categoryId })
			},
			isMulti: true
		},
		{
			name: "publishBusinessProfile",
			label: "Publish Business Profile",
			type: "checkbox"
		}
	]
}

const BusinessProfileDialog = props => {
	const { fields, values } = props;
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		if (props.show) {
			setLoading(true);
			get({
				url: 'api/business-profiles/own',
				onSuccess: data => {
					setLoading(false);
					props.updateValues({
						...data
					});
				}, 
				onError: error => {
					setLoading(false);
					props.showErrorNotification(error.message);
				}
			})

		}
	}, [props.show]);

    const onClose = () => {
		props.hideBusinessProfileDialog();
	};
    const onOk = () => {
		if (props.validateFields()) {
			const formData = new FormData();
			formData.append("BusinessProfile", encodeURIComponent(JSON.stringify(values)));
			if (props.formFiles.logo) formData.append("LogoFile", props.formFiles.logo);

			setLoading(true);
			post({
				url: `/api/business-profiles`,
				data: formData,
				onSuccess: (data) => {
					setLoading(false);

					if (data.success) {
						props.showSuccessNotification(data.message);
						props.hideBusinessProfileDialog();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: (error) => {
					setLoading(false);
					props.showErrorNotification(error.message);
				}
			});
		}
    };

    return (
        <Dialog
            open={props.show}
            onClose={props.onClose}
            TransitionProps={{
                onEntering: () => props.updateValues({ title: props.title, code: props.code, include: false })
            }}>
            <DialogTitle>Please enter your Business Profile information</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
					<Grid item xs={12}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.location()}</Grid>
					<Grid item xs={12} sm={6}>{fields.website()}</Grid>
					<Grid item xs={12}>{fields.summary()}</Grid>
					<Grid item xs={12}>
						{props.isSubscribed ?
							fields.about()
							:
							<NotSubscribedField label="About" />
						}
					</Grid>
					<Grid item xs={12}>
						{props.isSubscribed ?
							fields.products()
							:
							<NotSubscribedField label="Products/Services" />
						}
					</Grid>
					<Grid item xs={12}>
						{props.isSubscribed ?
							fields.caseStudies()
							:
							<NotSubscribedField label="Case studies" />
						}
					</Grid>
					<Grid item xs={12} sm={6}>{fields.email()}</Grid>
					<Grid item xs={12} sm={6}>{fields.phone()}</Grid>
					<Grid item xs={12}>{fields.logo()}</Grid>
					<Grid item xs={12}>{fields.areasOfExpertise()}</Grid>
					<Grid item xs={12}>{fields.categories()}</Grid>
					<Grid item xs={12} sm={6}>{fields.publishBusinessProfile()}</Grid>
					<Grid item xs={12} sm={6}>{fields.accredited()}</Grid>
					{values.accredited && 
						<Grid item xs={12}>{fields.accreditationId()}</Grid>
					}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button 
					onClick={onOk}
					loading={loading}
				>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

BusinessProfileDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	show: state.layout.showBusinessProfileDialog,
	userOrgId: state.context.organisationId
});

const mapDispatchToProps = dispatch => ({
	hideBusinessProfileDialog: () => dispatch(toggleBusinessProfileDialog(false)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default withStyles(
	connect(mapStateToProps, mapDispatchToProps)
	(withForm(form)(BusinessProfileDialog)),
	styles);
