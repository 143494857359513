import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCollaboration, isLoading, saveEnquiry } from '../../store/collaborations';
import { canEditCollaboration, canViewEnquiries } from '../../store/security';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import CreateEnquiry from './CreateEnquiry';
import ViewCollaboration from './ViewCollaboration';
import { showErrorNotification, showSuccessNotification } from '../../store/notifications';
import some from 'lodash/some';
import { get, post } from '../../utils/ajax';

const ViewCollaborationController = props => {
	const { collaborationId } = props.match.params;
	const [loading, setLoading] = React.useState(false);
	const [discussionsLoading, setDiscussionsLoading] = React.useState(false);
	const [discussions, setDiscussions] = React.useState([]);

	React.useEffect(() => {
		props.getCollaboration(collaborationId);

		setDiscussionsLoading(true);
		get({
			url: `/api/collaborations/${collaborationId}/discussions`,
			onSuccess: data => {
				setDiscussionsLoading(false);
				setDiscussions(data);
			},
			onError: () => setDiscussionsLoading(false)
		});
	}, [collaborationId]);

	const saveDiscussion = (discussion) => {
		discussion.collaborationId = collaborationId;
		setDiscussionsLoading(true);
	
		post({
			url: `/api/collaborations/${collaborationId}/save-discussion`,
			data: discussion,
			onSuccess: data => {
				setDiscussionsLoading(false);
				setDiscussions([...discussions, data.object]);

				if (data.success) {
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setDiscussionsLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	};

	const isLoading = loading || props.loading;
	const isParticipant = props.collaboration ? some(props.collaboration.participants, p => p.userId === props.currentUserId && p.status === 'Accepted') : false;

	return <PageLayout title="View Collaboration" hideContent={!Boolean(props.collaboration.collaborationId)} pageContent={
		<React.Fragment>
			<ViewCollaboration 
				collaboration={props.collaboration} 
				loading={isLoading} 
				canEdit={props.canEdit} 
				isParticipant={isParticipant} 
				canViewEnquiries={props.canViewEnquiries} 
				currentUserId={props.currentUserId}
				showErrorNotification={props.showErrorNotification}
				showSuccessNotification={props.showSuccessNotification}
				setLoading={setLoading}
				primaryColour={props.primaryColour}
				discussionsLoading={discussionsLoading} 
				discussions={discussions}
				saveDiscussion={saveDiscussion}
			/>
			<CreateEnquiry saveEnquiry={content => props.saveEnquiry(collaborationId, content)} loading={props.loading} />
		</React.Fragment>
	} breadcrumbs={[{ label: "Collaborations", path: "/collaborations" }, "View Collaboration"]} />;
};

ViewCollaborationController.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	collaboration: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveEnquiry: PropTypes.func.isRequired,
	canViewEnquiries: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	collaboration: state.collaborations.collaboration,
	loading: isLoading(state),
	canEdit: canEditCollaboration(state),
	canViewEnquiries: canViewEnquiries(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	getCollaboration: collaborationId => dispatch(getCollaboration(collaborationId)),
	saveEnquiry: (collaborationId, content) => dispatch(dispatch(showModal("SIMPLE", {
		title: "Are sure you want to submit an enquiry?",
		message: "The owners of this collaboration will contact you with a response at a later date.",
		onOk: () => dispatch(saveEnquiry(collaborationId, content))
	})))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCollaborationController);