import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveGroup, isLoading } from '../../../store/reports';
import PageLayout from '../../PageLayout';
import EditGroup from './EditGroup';

const CreateGroupController = props => 
	<PageLayout title="Create report group" pageContent={
		<EditGroup saveGroup={props.saveGroup} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Manage report groups", path: "/admin/report-groups" }, "Create report group"]} />;

CreateGroupController.propTypes = {
	saveGroup: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveGroup: group => dispatch(saveGroup(group))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupController);