import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCourse, saveCourse, deleteCourse, copyCourse, isLoading } from '../../store/courses';
import { showModal } from '../../store/modal';
import { get } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import EditCourse from './EditCourse';
import { canAdminCourses } from '../../store/security';

const EditCourseController = props => {
	const [organisations, setOrganisations] = React.useState([]);

	React.useEffect(() => props.getCourse(props.match.params.courseId), [props.match.params.courseId]);

	React.useEffect(() => {
		get({
			url: "/api/organisations?type=Organisation",
			onSuccess: data => setOrganisations(data.map(o => ({ label: o.name, value: o.organisationId })))
		});
	}, []);

	return <PageLayout title="Edit course" pageContent={
		<EditCourse
			course={props.course}
			saveCourse={props.saveCourse(props.fromAdmin)}
			deleteCourse={props.deleteCourse(props.fromAdmin)}
			copyCourse={props.copyCourse}
			loading={props.loading}
			organisationId={props.organisationId}
			canAdminCourses={props.canAdminCourses}
			organisations={organisations}
			errorColour={props.errorColour}
		/>
	} breadcrumbs={[{ label: "Courses", path: props.fromAdmin ? "/admin/courses" : "/courses" }, "Edit course"]} />;
};

EditCourseController.propTypes = {
	course: PropTypes.object.isRequired,
	saveCourse: PropTypes.func.isRequired,
	deleteCourse: PropTypes.func.isRequired,
	copyCourse: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	fromAdmin: PropTypes.bool,
	canAdminCourses: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired
};

EditCourseController.defaultProps = {
	fromAdmin: false
};

const mapStateToProps = state => ({
	course: state.courses.course,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canAdminCourses: canAdminCourses(state),
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getCourse: courseId => dispatch(getCourse(courseId)),
	saveCourse: fromAdmin => (course, imageFile, newFiles) => dispatch(saveCourse(course, imageFile, newFiles, fromAdmin)),
	deleteCourse: fromAdmin => courseId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this course?",
		onOk: () => dispatch(deleteCourse(courseId, fromAdmin))
	})),
	copyCourse: (courseId, title, includeCompetencies) => dispatch(copyCourse(courseId, title, includeCompetencies))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCourseController);
