import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import round from 'lodash/round';

const TeamSkillsProfile = ({ teamId, setLoading }) => {
	const [data, setData] = React.useState([]);

	const loadData = () => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/team-skills-profile?teamId=${teamId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (teamId) {
			loadData();
		}
	}, [teamId]);

	const meetsData = map(data, d => ({
		name: `${d.fullName}`,
		fullLabel: `${d.fullName}`,
		value: d.meets
	})) || [];

	const gapData = map(data, d => ({
		name: `${d.fullName}`,
		fullLabel: `${d.fullName}`,
		value: d.gap
	})) || [];

	const xAxisData = map(data || [], d => d.fullName);

	return (
		<Chart 
			info={{
				title: 'Team Skills Profile',
				text: 'The Team Skills Profile chart shows the Competencies Met vs the Competencies Needed for a user'	
			}}
			option={{
				tooltip: {
					trigger: 'item',
					formatter: params => {
						if (params.componentIndex === 0) {
							return `Competencies Needed<br />\n${params.data.name}: ${round(params.data.value, 1)}`
						} else {
							return `Competencies Met<br />\n${params.data.name}: ${round(params.data.value, 1)}`
						}
					}
				},
				xAxis: {
					data: xAxisData
				},
				yAxis: {},
				series: [
					{
						name: 'gap',
						type: 'bar',
						stack: 'one',
						data: gapData
					},
					{
						name: 'meets',
						type: 'bar',
						stack: 'one',
						data: meetsData
					}
				]
			}}
			style={{ height: "100%" }} 
		/>
	);
};

TeamSkillsProfile.propTypes = {
	teamId: PropTypes.number,
	setLoading: PropTypes.func.isRequired
};

TeamSkillsProfile.defaultProps = {
	teamId: null
};

export default TeamSkillsProfile;
