import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import { Link } from 'react-router-dom'
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import StatBox from '../widgets/StatBox';
import Avatar from '../widgets/Avatar';
import ButtonLink from '../widgets/ButtonLink';
import sumBy from 'lodash/sumBy';
import ConditionalWrapper from '../widgets/ConditionalWrapper';
import { Grid } from '@mui/material';
import TextField from '../widgets/TextField';
import Select from '../widgets/Select';

const Users = props => {
	const [userOrganisations, setUserOrganisations] = React.useState([]);
	const [search, setSearch] = React.useState("");
	const [orderBy, setOrderBy] = React.useState("userName");
	const [direction, setDirection] = React.useState("asc");
	const { users, loading, primaryColour, errorColour } = props;

	React.useEffect(() => {
		if (users.length > 0) {
			setUserOrganisations(users.reduce((acc, u) => {
				if (u.userOrganisation && !acc.includes(u.userOrganisation)) {
					acc.push(u.userOrganisation);
				}

				return acc;
			}, []));
		}
	}, [users.length]);
	
	return (
		<Card title="Manage users" loading={loading} titleRightContent={props.canSubmit && <ButtonLink to="/users/add">Add new user</ButtonLink>}>
			{/* Stats */}
			<div className="row-flex m-t-40">
				<StatBox text="Total registrations" number={users.length} colour="#007C9A" />
				<StatBox text="Users submitting" number={sumBy(users, u => u.countProblems > 0)} colour="#7460ee" />
				<StatBox text="Users voting" number={sumBy(users, u => u.countVotes > 0)} colour="#15749d" />
				<StatBox text="Users commenting" number={sumBy(users, u => u.countComments > 0)} />
			</div>
			{/* Search Users */}
			<span className="h3" style={{ color: "#007C9A", margin: 0 }}>Search users</span>
			<Grid container spacing={3} alignItems="flex-end" style={{ marginBottom: 10 }}>
				<Grid item xs={12} sm={9}>
					<TextField placeholder="Enter your search here" value={search} onChange={e => setSearch(e.target.value)} />
				</Grid>
				<Grid item xs={12} sm={3}>
					<Select 
						label="Order by" 
						value={orderBy} 
						items={[
							{ label: "Username", value: "userName" }, 
							{ label: "RegistrationDate", value: "registrationDate" }, 
							{ label: "UserOrg", value: "userOrganisation" }
						]} 
						onChange={e => {
							setOrderBy(e.target.value);
							setDirection(e.target.value === "registrationDate" ? "desc" : "asc");
						}} 
					/>
				</Grid>
			</Grid>
			{/* Users Datatable */}
			<DataTable data={users} search={search} orderBy={orderBy} direction={direction} enableExport>
				<DataTableColumn name="fullName" label="User" renderer={data =>
					<ConditionalWrapper
						on={props.canEdit}
						wrapper={children => <Link to={`/users/${data.userId}`} style={{ color: primaryColour }}>{children}</Link>}
					>
						<Avatar src={data.avatar} fullName={data.fullName} style={{ marginRight: 5 }} />
						<span>{data.fullName}</span>
					</ConditionalWrapper>
				} />
				<DataTableColumn name="userName" label="Username" />
				{props.showTenant &&
					<DataTableColumn 
						name="tenantTitle" 
						label="Tenant"
						canFilter
						filterType="AutoComplete"
						loadItems={{
							route: "tenants", 
							mapItem: c => ({ label: c.title, value: c.tenantId })
						}}
					/>
				}
				<DataTableColumn name="email" label="Email address" renderer={
					data => data.isActive ? data.email : <span style={{ color: errorColour }}>**** DEACTIVATED ****</span>} 
				/>
				<DataTableColumn 
					name="userOrganisation" 
					label="User Org" 
					canFilter 
					filterType="AutoComplete" 
					items={userOrganisations}
				/>
				{props.canViewOrganisations &&
					<DataTableColumn 
						name="organisationTitle" 
						label="Organisation"
						canFilter
						filterType="AutoComplete"
						loadItems={{
							route: "organisations", 
							mapItem: o => ({ label: o.name, value: o.organisationId })
						}}
					/>
				}
				<DataTableColumn name="departmentTitle" label="Business unit" />
				<DataTableColumn name="lastAssessmentDate" label="Last Assessment Date" dataType="date" />
			</DataTable>
		</Card>
	);
};

Users.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	showTenant: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

export default Users;
