import { createFetchAction, createFormPostAction, createPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	roles: [],
	role: {},
	functions: [],
	functionGroups: []
};

const CLEAR_ROLES = "CLEAR_ROLES";
const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";
const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";
const SAVE_ROLES_REQUEST = "SAVE_ROLES_REQUEST";
const SAVE_ROLES_SUCCESS = "SAVE_ROLES_SUCCESS";
const SAVE_ROLES_FAILURE = "SAVE_ROLES_FAILURE";
const SAVE_ROLE_REQUEST = "SAVE_ROLE_REQUEST";
const SAVE_ROLE_SUCCESS = "SAVE_ROLE_SUCCESS";
const SAVE_ROLE_FAILURE = "SAVE_ROLE_FAILURE";
const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";
const GET_FUNCTIONS_REQUEST = "GET_FUNCTIONS_REQUEST";
const GET_FUNCTIONS_SUCCESS = "GET_FUNCTIONS_SUCCESS";
const GET_FUNCTIONS_FAILURE = "GET_FUNCTIONS_FAILURE";

export const isLoading = createLoadingSelector(["GET_ROLES", "GET_ROLE", "SAVE_ROLES", "DELETE_ROLE", "GET_FUNCTIONS"]);

export const getRoles = () =>
	createFetchAction({
		url: "/api/roles",
		startAction: GET_ROLES_REQUEST,
		onError: error => [getRolesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getRolesSuccess(data)
	});

export const getRolesSuccess = data => ({ type: GET_ROLES_SUCCESS, payload: { data } });
export const getRolesFailure = error => ({ type: GET_ROLES_FAILURE, payload: { error } });

export const getRole = roleId =>
	createFetchAction({
		url: `/api/roles/${roleId}`,
		startAction: GET_ROLE_REQUEST,
		onError: error => [getRoleFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getRoleSuccess(data)
	});

export const getRoleSuccess = data => ({ type: GET_ROLE_SUCCESS, payload: { data } });
export const getRoleFailure = error => ({ type: GET_ROLE_FAILURE, payload: { error } });

// Not sure if this is still used, API only handles single role
export const saveRoles = data => 
	createFormPostAction({
		url: "/api/roles",
		data,
		startAction: SAVE_ROLES_REQUEST,
		onError: error => [saveRolesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveRolesSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [saveRolesSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveRolesSuccess = data => ({ type: SAVE_ROLES_SUCCESS, data });
export const saveRolesFailure = error => ({ type: SAVE_ROLES_FAILURE, error });

export const saveRole = role => 
	createFormPostAction({
		url: "/api/roles",
		data: role,
		startAction: SAVE_ROLE_REQUEST,
		onError: error => [saveRoleFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveRoleSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [saveRoleSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveRoleSuccess = data => ({ type: SAVE_ROLE_SUCCESS, data });
export const saveRoleFailure = error => ({ type: SAVE_ROLE_FAILURE, error });

export const deleteRole = roleId => 
	createPostAction({
		url: `/api/roles/${roleId}/delete`,
		startAction: DELETE_ROLE_REQUEST,
		onError: error => [deleteRoleFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteRoleSuccess(data), showSuccessNotification(data.message), changeLocation("/admin/roles")];
			} else {
				return [deleteRoleSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteRoleSuccess = data => ({ type: DELETE_ROLE_SUCCESS, data });
export const deleteRoleFailure = error => ({ type: DELETE_ROLE_FAILURE, error });

export const getFunctions = () =>
	createFetchAction({
		url: "/api/roles/functions",
		startAction: GET_FUNCTIONS_REQUEST,
		onError: error => [getFunctionsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getFunctionsSuccess(data)
	});

export const getFunctionsSuccess = data => ({ type: GET_FUNCTIONS_SUCCESS, payload: { data } });
export const getFunctionsFailure = error => ({ type: GET_FUNCTIONS_FAILURE, payload: { error } });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ROLES:
			return { ...state, roles: [], functions: [], functionGroups: [] };
		case GET_ROLES_REQUEST:
			return { ...state, isLoading: true, roles: [] };
		case GET_ROLES_SUCCESS:
			return { ...state, isLoading: false, roles: action.payload.data };
		case GET_ROLE_REQUEST:
			return { ...state, isLoading: true, role: {} };
		case GET_ROLE_SUCCESS:
			return { ...state, isLoading: true, role: action.payload.data };
		case SAVE_ROLES_REQUEST:
		case SAVE_ROLE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_ROLES_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case SAVE_ROLE_SUCCESS:
			return {
				...state,
				...(action.data.success && { role: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_ROLE_REQUEST:
			return { ...state, isLoading: true };
		case DELETE_ROLE_SUCCESS:
			return { ...state, isLoading: false, roles: state.roles.filter(r => r.roleId !== action.data.objectId) };
		case GET_FUNCTIONS_REQUEST:
			return { ...state, isLoading: true, functions: [], functionGroups: [] };
		case GET_FUNCTIONS_SUCCESS:
			return { 
				...state, 
				isLoading: false, 
				functions: action.payload.data, 
				functionGroups: action.payload.data.map(f => f.group).filter((f, i, a) => f && a.indexOf(f) === i)
			};
		default:
			return state;
	}
};
