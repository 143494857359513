import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getOrganisations, searchOrganisations, isLoading } from '../../../store/organisations';
import { withForm } from '../../../utils/forms';
import PageLayout from '../../PageLayout';
import ButtonLink from '../../widgets/ButtonLink';
import OrganisationCard from './OrganisationCard';
import map from 'lodash/map';
import filter from 'lodash/filter';

let timer = null;
const form = {
	onChange: (props, values) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchOrganisations(values.search), 500);
	},
	fields: [{
		name: "search",
		placeholder: "Search",
		startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />,
		style: { paddingBottom: 12 }
	}]
};

const InstitutionsController = ({ organisations, fields }) => {
	const institutions = filter(organisations, o => o.type === "Institution");

	return <PageLayout title="Institutions" pageContent={
		<React.Fragment>
			{fields.search()}
			{institutions.length === 0 ? <p>No institutions found...</p> : 
				<Grid container spacing={3}>
					{map(institutions, (o, i) => <OrganisationCard key={i} organisation={o} />)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={["Institutions"]} />;
}

const OrganisationsController = props => {
	React.useEffect(props.getOrganisations, []);

	return props.institutionsOnly ? <InstitutionsController {...props} /> : (
		<PageLayout title="Organisations" pageContent={
			<React.Fragment>
				<Grid container spacing={3} direction="row-reverse">
					<Grid item xs="auto">
						<ButtonLink to="/admin/organisations/create">Create organisation</ButtonLink>
					</Grid>
					<Grid item xs={12} sm>{props.fields.search()}</Grid>
				</Grid>
				{props.organisations.length === 0 ? <p>No organisations found...</p> : 
					<Grid container spacing={3}>
						{map(props.organisations, (o, i) => <OrganisationCard key={i} organisation={o} />)}
					</Grid>
				}
			</React.Fragment>
		} breadcrumbs={["Organisations"]} />
	)
};

OrganisationsController.propTypes = {
	getOrganisations: PropTypes.func.isRequired,
	organisations: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	institutionsOnly: PropTypes.bool
};

OrganisationsController.defaultProps = {
	institutionsOnly: false
};

const mapStateToProps = state => ({
	organisations: state.organisations.organisations,
	loading: isLoading(state),
	isAdmin: state.context.isAdministrator
});

const mapDispatchToProps = dispatch => ({
	getOrganisations: () => dispatch(getOrganisations()),
	searchOrganisations: search => dispatch(searchOrganisations(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(OrganisationsController));
