import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import map from 'lodash/map';

const form = {
	initValues: ({ centre: c }) => ({ ...c, channels: c ? map(c.channels, c => ({ label: c.name, value: c.channelId })) : [] }),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of centre"
		},
		{
			name: "description",
			label: "Description",
			required: true,
			multiline: true
		},
		{
			name: "email",
			label: "Email",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "phone",
			label: "Phone number"
		},
		{
			name: "website",
			label: "Website"
		},
		{
			name: "location",
			label: "Location"
		},
		{
			name: "logo",
			label: "Logo",
			type: "imageUploader",
			single: true
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		},
		{
			name: "research",
			label: "Research",
			type: "textEditor"
		},
		{
			name: "institutions",
			label: "Institutions",
			type: "textEditor"
		},
		{
			name: "caseStudies",
			label: "Case Studies",
			type: "textEditor"
		},
		{
			name: "channels",
			label: "Channels",
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: ({ channelId, name }) => ({ label: name, value: channelId, channelId })
			},
			isMulti: true,
			helpText: "Centre channels (please select all relevant options)"
		}
	]
};

const EditCentre = props => {
	const { fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} centre`} loading={loading} className="edit-centre">
			<Form onSubmit={() => props.validateFields() && props.saveCentre(props.values, props.formFiles.logo)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.location()}</Grid>
					<Grid item xs={12} sm={6}>{fields.website()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12} sm={6}>{fields.email()}</Grid>
					<Grid item xs={12} sm={6}>{fields.phone()}</Grid>
					<Grid item xs={12}>{fields.research()}</Grid>
					<Grid item xs={12}>{fields.institutions()}</Grid>
					<Grid item xs={12}>{fields.caseStudies()}</Grid>
					<Grid item xs={12}>{fields.logo()}</Grid>
					<Grid item xs={12}>{fields.channels()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} research centre</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteCentre(props.centre.centreId)}>
							Delete research centre
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditCentre.propTypes = { 
	centre: PropTypes.object,
	saveCentre: PropTypes.func.isRequired,
	deleteCentre: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditCentre.defaultProps = {
	centre: {},
	deleteCentre: () => null,
	isNew: false
};

export default withForm(form)(EditCentre);