import React from 'react';
import PropTypes from 'prop-types';
import MaterialUIAvatar from '@mui/material/Avatar';

const backgrounds = [
	"#F44336",
	"#E91E63",
	"#9C27B0",
	"#673AB7",
	"#3F51B5",
	"#2196F3",
	"#03A9F4",
	"#00BCD4",
	"#009688",
	"#4CAF50",
	"#8BC34A",
	"#CDDC39",
	"#FFC107",
	"#FF9800",
	"#FF5722"
];

const getAvatarInitials = string => {
	const names = string.split(" ");
	let initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
		for (let i = 1, j = names.length; i < j; i++) {
			const initial = names[i].substring(0, 1);

			if (/[A-Za-z]/.test(initial)) {
				initials += initial.toUpperCase();
				break;
			}
		}
	} else if (names[0].length > 1) {
        initials += names[0].substring(1, 2);
	}
	
    return initials;
};

const Avatar = React.memo(props => {
	const { src, fullName, length, style } = props;
	const initials = fullName ? getAvatarInitials(fullName) : "";

	const styleProps = { 
		height: length, 
		width: length,
		backgroundColor: src ? "white" : 
			// Get background based on first initial to avoid randomisation
			backgrounds[initials ? (initials.charCodeAt(initials[0]) - 65) % backgrounds.length : 0],
		fontSize: length / 100 * 60,
		...style
	};

	return (
		<MaterialUIAvatar className={`dih-avatar ${props.className}`} src={src} alt={fullName} style={styleProps}>
			{!src && initials}
		</MaterialUIAvatar>
	);
});

Avatar.propTypes = {
	src: PropTypes.string,
	fullName: PropTypes.string,
	length: PropTypes.number,
	className: PropTypes.string,
	variant: PropTypes.string,
	style: PropTypes.object
};

Avatar.defaultProps = {
	src: "",
	fullName: "",
	length: 30,
	className: "",
	variant: "",
	style: {}
};

export default Avatar;
