import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTopTools, isLoading } from '../../store/tools';
import Card from '../widgets/Card';
import ButtonLink from '../widgets/ButtonLink';
import map from 'lodash/map';
import ToolRow from '../widgets/ToolRow';

const TopTools = ({ tools, loading, getTopTools }) => {
	React.useEffect(getTopTools, []);

	return (	
		<Card title="Top Tools" loading={loading} shrink>
			{tools.length === 0 ? "You have not set any tools as favourite..." : map(tools, (tool, i) => <ToolRow key={i} tool={tool} />)}
			<ButtonLink to="tools" style={{ float: "right" }} variant="outlined">View more tools</ButtonLink>
		</Card>
	);
};

TopTools.propTypes = {
	tools: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	tools: state.tools.tools,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getTopTools: () => dispatch(getTopTools())
});

export default connect(mapStateToProps, mapDispatchToProps)(TopTools);
