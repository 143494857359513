import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { getFrameworks } from '../../store/frameworks';
import Button from './Button';
import MediaCard from './MediaCard';
import classNames from 'classnames';
import map from 'lodash/map';

const FrameworkDialog = React.memo(props => {
	const [selected, setSelected] = React.useState([]);
	const { frameworks, value } = props;

	React.useEffect(() => props.getFrameworks(props.organisationId), [props.organisationId]);

	React.useEffect(() => {
		if (props.open && props.isMulti) {
			setSelected(value ? value.filter(f => frameworks.some(f2 => f2.frameworkId === f.frameworkId)).map(f => f.frameworkId) : []);
		}
	}, [props.open]);

	const onClick = frameworkId => {
		if (props.isMulti) {
			setSelected(selected.includes(frameworkId) ? selected.filter(f => f !== frameworkId) : [...selected, frameworkId]);
		} else {
			onOk(frameworkId);
		}
	};

	const onOk = newValue => {
		props.onChange(newValue);
		props.onClose();
	};
		
	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
			<DialogTitle>Select Framework{props.isMulti ? "s" : ""}</DialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					{map(frameworks, (f, i) =>
						<Grid key={i} item xs={4}>
							<MediaCard
								className={classNames("picker-card", { "selected": selected.includes(f.frameworkId) })}
								title={f.frameworkCode}
								onClick={() => onClick(f.frameworkId)} 
								image={f.image || "/images/aspire-mountain.png"}
								// imageStyle={{ background: `url(${f.image || "/images/aspire-mountain.png"})` }}
							>
								{f.description || ""}
							</MediaCard>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				{props.isMulti && 
					<Button disabled={selected.length === 0} onClick={() => onOk(frameworks.filter(f => selected.includes(f.frameworkId)))}>Ok</Button>
				}
			</DialogActions>
		</Dialog>
	);
});

FrameworkDialog.propTypes = {
	getFrameworks: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	open: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onClose: PropTypes.func,
	isMulti: PropTypes.bool,
	organisationId: PropTypes.number
};

FrameworkDialog.defaultProps = {
	value: 0,
	onClose: () => {},
	isMulti: false,
	organisationId: 0
};

const mapStateToProps = state => ({
	frameworks: state.frameworks.frameworks
});

const mapDispatchToProps = dispatch => ({
	getFrameworks: organisationId => dispatch(getFrameworks(organisationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(FrameworkDialog);