import React from 'react';
import PropTypes from 'prop-types';
import { 
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip
} from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import CollapseIcon from '@mui/icons-material/ExpandLess';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import Checkbox from '../widgets/Checkbox';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import ConditionalWrapper from '../widgets/ConditionalWrapper';
import { Scrollbars } from 'react-custom-scrollbars-2';
import find from 'lodash/find';
import some from 'lodash/some';
import times from 'lodash/times';
import size from 'lodash/size';
import {
	pSBC
} from '../../utils/utils';
import tinycolor from 'tinycolor2';
import CheckIcon from '@mui/icons-material/Check';
import TargetIcon from '@mui/icons-material/GpsFixed';
import NotCompetentIcon from '@mui/icons-material/Clear';

// const cellHint = {
// 	fontWeight: 'normal',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     fontStyle: 'italic',
//     fontSize: '0.8em'
// };

// const cellEditIconStyles = {
// 	position: "absolute",
// 	right: 0,
// 	top: "50%", // Top 50% and transform translate(0, -50%) will vert center icon (IE9+)
// 	transform: "translate(0, -50%)"
// };

const CompetenciesGridBySpecialisation = props => {
	const [expandedGroups, setExpandedGroups] = React.useState([]);
	const [expandedSpecialisations, setExpandedSpecialisations] = React.useState([]);
	const competenciesBySpecialisationGroup = groupBy(props.units, 'specialisationGroupId');
	const rowsPerPage = props.rows;

	const minLevel = 1;
	let maxLevel = 1;
	// const levelNames = [];
	forEach(props.units, u => {
		forEach(u.elements, e => {
			if (e.order > maxLevel) maxLevel = e.order;
			// if (levelNames.length < (maxLevel - minLevel + 1)) levelNames.push(e.element);
		});
	});

	return (
        <React.Fragment>
		<ConditionalWrapper
			on={!props.disableScroll}

			wrapper={children => 
				<Scrollbars 
					className='scrollbar-container'
					renderView={props => <div {...props} className="scrollbar-view"/>
				}
					autoHeight 
					autoHeightMin={props.rowHeight * rowsPerPage + 60} 
					autoHide>{children}
				</Scrollbars>
			}
		>
			<Table className="print-table-colours">
				<TableHead>
					<TableRow>
						<TableCell colSpan={(maxLevel - minLevel) > 2 ? maxLevel - minLevel + 1: 3}>
							Competency
						</TableCell>
						<TableCell style={{ textAlign: 'center', width: '100px' }}>
							Assessed
						</TableCell>
						<TableCell style={{ textAlign: 'center', width: '100px' }}>
							Competent
						</TableCell>
						<TableCell style={{ textAlign: 'center', width: '100px' }}>
							Below Target
						</TableCell>
						<TableCell style={{ textAlign: 'center', width: '100px' }}>
							Total
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
				{(() => {
					const rows = [];

					forEach(competenciesBySpecialisationGroup, (groupUnits, groupId) => {
						const specialisationGroup = groupUnits[0].specialisationGroup;
						const specialisationGroupColour = tinycolor(groupUnits[0].specialisationGroupColour);
						let isGroupExpanded = expandedGroups.indexOf(groupId) > -1;
						const onlyOneGroupInTotal = size(competenciesBySpecialisationGroup) === 1;

						// If only one group then dont show group row
						if (onlyOneGroupInTotal) {
							isGroupExpanded = true;
						} else {
							let groupTotal = 0;
							let groupCompetent = 0;
							let groupAssessed = 0;
							let groupBelowTarget = 0;
							forEach(groupUnits, gc => {
								groupTotal += 1;
								const competencyUnit = find(props.competencies, u2 => u2.unitOfCompetencyId === gc.unitOfCompetencyId);
								if (competencyUnit && competencyUnit.competencyId) {
									let targetIndex = null;
									let selectedIndex = null;
									forEach(gc.elements, (e, i) => {
										if (gc.criteria && gc.criteria.length > 0) {
											if (gc.criteria[0].unitElementId === e.unitElementId) targetIndex = i;
										}
										if (some(competencyUnit.elements, e2 => e2.elementId === e.unitElementId && e2.competent)) selectedIndex = i;
									});
									if (selectedIndex !== null && selectedIndex >= targetIndex) groupCompetent += 1;
									if (targetIndex !== null && (selectedIndex === null || selectedIndex < targetIndex)) groupBelowTarget += 1;
									groupAssessed += 1;
								}
							});

							if (rows.length > 0) {
								rows.push(
									<TableRow key={`spec-group-${groupId}-divider`}>
										<TableCell colSpan={maxLevel - minLevel + 4}></TableCell>
									</TableRow>
								)
							}
							rows.push(
								<TableRow 
									key={`spec-group-${groupId}`}
									style={{ 
										background: specialisationGroupColour.toHexString(),
										color: specialisationGroupColour.isLight() ? '#000' : '#fff'
									}}
								>
									<TableCell 
										colSpan={1}
										style={{ 
											backgroundColor: specialisationGroupColour.toHex8String(), 
											color: specialisationGroupColour.isLight() ? '#000' : '#fff',
											width: '100px'
										}}
									>
										<IconButton
                                            style={{ 
												color: specialisationGroupColour.isLight() ? '#000' : '#fff'
											}}
                                            onClick={() => {
												if (isGroupExpanded) {
													setExpandedGroups(filter(expandedGroups, g => g !== groupId));
												} else {
													setExpandedGroups([...expandedGroups, groupId])
												}
											}}
                                            size="large">
											{isGroupExpanded ? <CollapseIcon /> : <ExpandIcon />}
										</IconButton>
									</TableCell>
									<TableCell 
										colSpan={(maxLevel - minLevel)} 
										style={{ 
											backgroundColor: specialisationGroupColour.toHex8String(), 
											color: specialisationGroupColour.isLight() ? '#000' : '#fff',
											fontWeight: 'bold', 
											position: 'relative' 
										}}
									>
										{/* <span style={cellHint}>Specialisation Group</span> */}
										{specialisationGroup}
									</TableCell>
									<TableCell style={{ textAlign: 'center', fontWeight: 'bold'  }}>
										{groupAssessed}
									</TableCell>
									<TableCell style={{ textAlign: 'center', fontWeight: 'bold'  }}>
										{groupCompetent}
									</TableCell>
									<TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>
										{groupBelowTarget}
									</TableCell>
									<TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>
										{groupTotal}
									</TableCell>
								</TableRow>
							);
						}
						if (isGroupExpanded) {
							const unitsBySpecialisation = groupBy(groupUnits, 'specialisationId');
							forEach(unitsBySpecialisation, (units, specialisationId) => {
								const specialisation = units[0].specialisation;
								const specialisationColour = tinycolor(units[0].specialisationColour);
								const unitColour = units[0].unitColour ? tinycolor(units[0].unitColour) : specialisationGroupColour;
								const isExpanded = expandedSpecialisations.indexOf(specialisationId) > -1;

								let specialisationTotal = 0;
								let specialisationCompetent = 0;
								let specialisationAssessed = 0;
								let specialisationBelowTarget = 0;
								forEach(units, u => {
									specialisationTotal += 1;
									const competencyUnit = find(props.competencies, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
									if (competencyUnit && competencyUnit.competencyId) {
										let targetIndex = null;
										let selectedIndex = null;
										forEach(u.elements, (e, i) => {
											if (u.criteria && u.criteria.length > 0) {
												if (u.criteria[0].unitElementId === e.unitElementId) targetIndex = i;
											}
											if (some(competencyUnit.elements, e2 => e2.elementId === e.unitElementId && e2.competent)) selectedIndex = i;
										});
										if (selectedIndex !== null && selectedIndex >= targetIndex) specialisationCompetent += 1;
										if (targetIndex !== null && (selectedIndex === null || selectedIndex < targetIndex)) specialisationBelowTarget += 1;
										specialisationAssessed += 1;
									}
								});

								const cellStyle = { 
									backgroundColor: specialisationColour.toHex8String(), 
									color: specialisationColour.isLight() ? '#000' : '#fff', 
									fontWeight: 'bold', 
									position: 'relative'
								};

								rows.push(
									<TableRow key={`spec-${specialisationId}`}>
										<TableCell 
											colSpan={1} 
											style={{ 
												backgroundColor: specialisationColour.toHexString() , 
												color: specialisationColour.isLight() ? '#000' : '#fff',
												width: '100px'
											}}
										>
											<IconButton
                                                style={{ 
													color: specialisationColour.isLight() ? '#000' : '#fff'
												}}
                                                onClick={() => {
													if (isExpanded) {
														setExpandedSpecialisations(filter(expandedSpecialisations, s => s !== specialisationId));
													} else {
														setExpandedSpecialisations([...expandedSpecialisations, specialisationId])
													}
												}}
                                                size="large">
												{isExpanded ? <CollapseIcon /> : <ExpandIcon />}
											</IconButton>
										</TableCell>
										<TableCell 
											colSpan={(maxLevel - minLevel)} 
											style={cellStyle}
										>
											{/* <span style={cellHint}>Specialisation</span> */}
						
											{specialisation}
										</TableCell>
										<TableCell style={{ ...cellStyle, textAlign: 'center' }}>
											{specialisationAssessed}
										</TableCell>
										<TableCell style={{ ...cellStyle, textAlign: 'center' }}>
											{specialisationCompetent}
										</TableCell>
										<TableCell style={{ ...cellStyle, textAlign: 'center' }}>
											{specialisationBelowTarget}
										</TableCell>
										<TableCell style={{ ...cellStyle, textAlign: 'center' }}>
											{specialisationTotal}
										</TableCell>
									</TableRow>
								);

								const invalidElementStyles = {
								};
								const backgroundColor = unitColour.toHexString();
								const selectedBackground = pSBC(-0.3, backgroundColor);
								const validElementStyles = {
									textAlign: 'left',
									overflow: 'visible',
									whiteSpace: 'nowrap',
									maxWidth: '70px',
									fontWeight: 'bold',
									border: '1px solid #fff'
								};

								const minSpecLevel = 1;
								let maxSpecLevel = 1;
								forEach(units, u => {
									forEach(u.elements, e => {
										if (e.order > maxSpecLevel) maxSpecLevel = e.order;
									});
								});
									
								if (isExpanded) {
									rows.push(
										<TableRow>
											<TableCell colSpan={4}></TableCell>
											{
												/* Add dummy cells to line things up */
												times(2 - (maxSpecLevel - minSpecLevel), i => 
													<TableCell key={i} style={{ textAlign: 'center', width: '100px' }}></TableCell>
												)
											}
											{
												times(maxSpecLevel - minSpecLevel + 1, i => 
													<TableCell key={i} style={{ textAlign: 'center', width: '100px' }}>L{i + 1}</TableCell>
												)
											}
										</TableRow>
									);

									forEach(units, (u, unitIndex) => {
										let maxElement = null, minElement = null;
										forEach(u.elements, e => {
											if (minElement === null || e.order < minElement) minElement = e.order;
											if (maxElement === null || e.order > maxElement) maxElement = e.order;
										});

										const rowKey = `g-${groupId}-s${specialisationId}-u${unitIndex}`;

										let targetOrder = null;
										if (u.criteria && u.criteria.length > 0) {
											targetOrder = u.criteria[0].elementOrder;
										}

										rows.push(
											<TableRow key={rowKey}>
												<TableCell key={`${rowKey}-x`} colSpan={4}>{u.unitTitle}</TableCell>
												{times(maxLevel - minLevel + 1, i => {
													if (i + 1 < minElement) {
														return <TableCell key={`${rowKey}-${i}`} style={invalidElementStyles}></TableCell>;
													}
													if (i + 1 > maxElement) {
														return <TableCell key={`${rowKey}-${i}`} style={invalidElementStyles}></TableCell>;
													}
													const e = find(u.elements, e => e.order === i + 1);
													let isTarget = false;
													if (u.criteria && u.criteria.length > 0) {
														if (u.criteria[0].unitElementId === e.unitElementId) {
															isTarget = true;
														}
													}

													let isSelected = false;
													let isCompetent = false;
													let tooltip = '';
													if (e) {
														const competencyUnit = find(props.competencies, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
														if (competencyUnit) {
															isSelected = some(competencyUnit.elements, e2 => e2.elementId === e.unitElementId); // || (competency && some(competency.elements, e2 => e2.elementId === e.unitElementId));
															isCompetent = targetOrder !== null && isSelected && e.order >= targetOrder;
														}
														tooltip = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />;
														if (e.items && e.items.length > 0) {
															if (e.element) {
																tooltip = (
																	<React.Fragment>
																		<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.element }} />
																		<br />
																		<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.items[0].performanceCriteriaName }} />
																	</React.Fragment>
																);
															} else {
																tooltip = <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: e.items[0].performanceCriteriaName }} />;
															}
														};
													}
													const cellBackground = tinycolor(isSelected ? selectedBackground : backgroundColor);
													return (
														<Tooltip 
															key={`${rowKey}-${i}`}
															title={tooltip} 
															disableFocusListener
															arrow
														>
															<TableCell 
																key={`${rowKey}-${i}`}
																style={{
																	...validElementStyles,
																	backgroundColor: cellBackground.toHexString(),
																	color: cellBackground.isLight() ? '#000' : '#fff',
																	position: 'relative',
																	padding: 0
																}}
															>
																{isCompetent && <CheckIcon style={{ verticalAlign: 'middle', width: '100%' }} />}
																{!isCompetent && isTarget && <TargetIcon style={{ verticalAlign: 'middle', width: '100%' }} />}
																{!isCompetent && !isTarget && isSelected && <NotCompetentIcon style={{ verticalAlign: 'middle', width: '100%' }} />}
																</TableCell>
														</Tooltip>
													);
												})}
											</TableRow>
										);
									});
								}

							});
						}
					});

					return rows;
				})()}
			</TableBody>
		</Table>
		</ConditionalWrapper>
		<Grid container alignItems="center" justifyContent="flex-end">
			<TargetIcon /><span style={{ marginLeft: '5px' }}>Target level of competency</span>
			<CheckIcon /><span style={{ marginLeft: '5px' }}>Your level of competency</span>
		</Grid>
		<Grid className="competencies-options" container alignItems="center" justifyContent="flex-end" style={{ marginTop: props.hidePagination ? 20 : 0 }}>
			<Grid item xs={12} sm>
				<div style={{ display: "flex" }}>
					<Checkbox 
						label="Show All Competencies"
						checked={props.showAllCompetences} 
						onChange={e => props.setShowAllCompetencies(e.target.checked)} 
						style={{ width: "auto" }}
					/>
				</div>
			</Grid>
			<Grid item xs="auto">
				{props.printReport &&
					<Button onClick={() => props.showReportViewer()} style={{ marginLeft: 10 }}>
						<PrintIcon style={{ marginRight: 5 }} />Print Report
					</Button>
				}
				<Button onClick={() => {
					window.print();
				}} style={{ marginLeft: 10 }}>
					<PrintIcon style={{ marginRight: 5 }} />Print Summary
				</Button>
				<ButtonLink disabled={true || !props.profileId} to={`/courses/suggest/${props.profileId}`} style={{ marginLeft: 10 }}>Suggest Courses</ButtonLink>
			</Grid>
		</Grid>
		</React.Fragment>
    );
};

CompetenciesGridBySpecialisation.propTypes = {
	profileId: PropTypes.number.isRequired,
	viewUnit: PropTypes.func.isRequired,
	editDate: PropTypes.func.isRequired,
	deleteCompetency: PropTypes.func.isRequired,
	updateCompetency: PropTypes.func.isRequired,
	lockCompetencyUpdates: PropTypes.bool.isRequired,
	printReport: PropTypes.string.isRequired,
	showReportViewer: PropTypes.func.isRequired,
	showAllCompetences: PropTypes.bool.isRequired,
	setShowAllCompetencies: PropTypes.func.isRequired,
	disableScroll: PropTypes.bool,
	rows: PropTypes.number,
	rowHeight: PropTypes.number
};

CompetenciesGridBySpecialisation.defaultProps = {
	disableScroll: false,
	rows: 10,
	rowHeight: 40
};

export default CompetenciesGridBySpecialisation;