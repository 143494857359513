import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	mentees: [],
	mentee: {}
};

const CLEAR_MENTEES = "CLEAR_MENTEES";
const GET_MENTEES_REQUEST = "GET_MENTEES_REQUEST";
const GET_MENTEES_SUCCESS = "GET_MENTEES_SUCCESS";
const GET_MENTEES_FAILURE = "GET_MENTEES_FAILURE";
const SEARCH_MENTEES_REQUEST = "SEARCH_MENTEES_REQUEST";
const SEARCH_MENTEES_SUCCESS = "SEARCH_MENTEES_SUCCESS";
const SEARCH_MENTEES_FAILURE = "SEARCH_MENTEES_FAILURE";
const CLEAR_MENTEE = "CLEAR_MENTEE";
const GET_MENTEE_REQUEST = "GET_MENTEE_REQUEST";
const GET_MENTEE_SUCCESS = "GET_MENTEE_SUCCESS";
const GET_MENTEE_FAILURE = "GET_MENTEE_FAILURE";
const SAVE_MENTEE_REQUEST = "SAVE_MENTEE_REQUEST";
const SAVE_MENTEE_SUCCESS = "SAVE_MENTEE_SUCCESS";
const SAVE_MENTEE_FAILURE = "SAVE_MENTEE_FAILURE";
const DELETE_MENTEE_REQUEST = "DELETE_MENTEE_REQUEST";
const DELETE_MENTEE_SUCCESS = "DELETE_MENTEE_SUCCESS";
const DELETE_MENTEE_FAILURE = "DELETE_MENTEE_FAILURE";

export const isLoading = createLoadingSelector(["GET_MENTEES", "SEARCH_MENTEES", "GET_MENTEE"]);

export const clearMentees = () => ({ type: CLEAR_MENTEES });
export const clearMentee = () => ({ type: CLEAR_MENTEE });

export const getMentees = () =>
	createFetchAction({
		url: "/api/mentees",
		startAction: GET_MENTEES_REQUEST,
		onError: error => [getMenteesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMenteesSuccess(data)
	});

export const getMenteesSuccess = data => ({ type: GET_MENTEES_SUCCESS, payload: { data } });
export const getMenteesFailure = error => ({ type: GET_MENTEES_FAILURE, payload: { error } });

export const searchMentees = args => 
	createFetchAction({
		url: `/api/mentees/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_MENTEES_REQUEST,
		onError: error => [searchMenteesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchMenteesSuccess(data)
	});

export const searchMenteesSuccess = data => ({ type: SEARCH_MENTEES_SUCCESS, payload: { data } });
export const searchMenteesFailure = error => ({ type: SEARCH_MENTEES_FAILURE, payload: { error } });

export const getMentee = menteeId =>
	createFetchAction({
		url: `/api/mentees/${menteeId}`,
		startAction: GET_MENTEE_REQUEST,
		onError: error => [getMenteeFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMenteeSuccess(data)
	});

export const getMenteeSuccess = data => ({ type: GET_MENTEE_SUCCESS, payload: { data } });
export const getMenteeFailure = error => ({ type: GET_MENTEE_FAILURE, payload: { error } });

export const saveMentee = mentee => {
	const formData = new FormData();
	formData.append("Mentee", JSON.stringify(mentee));

	return createFormPostAction({
		url: "/api/mentees",
		data: formData,
		startAction: SAVE_MENTEE_REQUEST,
		onError: error => [saveMenteeFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveMenteeSuccess(data), showSuccessNotification(data.message), changeLocation(`/mentees/${data.object.menteeId}`)];
			} else {
				return [saveMenteeSuccess(data), showErrorNotification(data.message)];
			}
		}
	})
}

export const saveMenteeSuccess = data => ({ type: SAVE_MENTEE_SUCCESS, data });
export const saveMenteeFailure = error => ({ type: SAVE_MENTEE_FAILURE, error });

export const deleteMentee = menteeId =>
	createPostAction({
		url: `/api/mentees/${menteeId}/delete`,
		startAction: DELETE_MENTEE_REQUEST,
		onError: error => [deleteMenteeFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteMenteeSuccess(data), showSuccessNotification(data.message), changeLocation("/mentees")];
			} else {
				return [deleteMenteeSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteMenteeSuccess = data => ({ type: DELETE_MENTEE_SUCCESS, data });
export const deleteMenteeFailure = error => ({ type: DELETE_MENTEE_FAILURE, error });

const formatMentee = mentee => ({
	...mentee,
	about: DOMPurify.sanitize(mentee.about)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_MENTEES:
			return { ...state, mentees: [] };
		case GET_MENTEES_REQUEST:
			return { ...state, mentees: [], isLoading: true };
		case SEARCH_MENTEES_REQUEST:
			return { ...state, isLoading: true };
		case GET_MENTEES_SUCCESS:
		case SEARCH_MENTEES_SUCCESS:
			return { ...state, mentees: map(action.payload.data, formatMentee), isLoading: false };
		case GET_MENTEE_REQUEST:
			return { ...state, mentee: {}, isLoading: true }
		case GET_MENTEE_SUCCESS:
			return { ...state, mentee: formatMentee(action.payload.data), isLoading: true };
		case SAVE_MENTEE_REQUEST:
		case DELETE_MENTEE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_MENTEE_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_MENTEE_SUCCESS:
			return { ...state, mentees: state.mentees.filter(a => a.menteeId !== action.data.objectId) };
		default:
			return state;
	}
};