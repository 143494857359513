import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../widgets/Button';
import { getQueryParameters } from '../../utils/utils';
import { changeLocation } from '../../store/location';
import { post } from '../../utils/ajax';
import { showErrorNotification } from '../../store/notifications';

const AcceptCollaborationInviteController = props => {
	const [accepted, setAccepted] = React.useState(false);
	const queryParameters = getQueryParameters(props.location.search);
	const collaborationId = queryParameters.id;
	const collaborationTitle = queryParameters.collaboration;
	const inviteToken = queryParameters.token;

	const declineInvitation = () => {
		props.goToHomePage();
	};
	
	const acceptInvitation = () => {
		post({
			url: `/api/collaborations/accept-invite?collaborationId=${collaborationId}&inviteToken=${inviteToken}`,
			data: null,
			onSuccess: data => {
				if (data.success) {
					setAccepted(true);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				props.showErrorNotification(error.message);
			}
		});
	};
	
	if (accepted) {
		return (
			<React.Fragment>
				<h1 className="h3">Invitation Accepted</h1>
				<p>Thank you for accepting the invitation to collaborate on {collaborationTitle}</p>

				<Button 
					onClick={() => {
						props.goToCollaborationPage(collaborationId);
					}}
					style={{ marginRight: 10 }}
					color="primary"
				>
					View {collaborationTitle}
				</Button>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<h1 className="h3">Accept Invite</h1>
				<p>Do you wish to accept the invite to collaborate on {collaborationTitle}</p>

				<Button 
					onClick={() => {
						declineInvitation();
					}}
					style={{ marginRight: 10 }}
					color="secondary"
				>
					No
				</Button>
				<Button 
					onClick={() => {
						acceptInvitation();
					}}
					style={{ marginRight: 10 }}
					color="primary"
				>
					Yes
				</Button>
			</React.Fragment>
		);
	}
};

AcceptCollaborationInviteController.propTypes = {
	goToHomePage: PropTypes.func.isRequired,
	goToCollaborationPage: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	goToHomePage: () => dispatch(changeLocation("/")),
	goToCollaborationPage: (collaborationId) => dispatch(changeLocation(`/collaborations/${collaborationId}`)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});


export default connect(mapStateToProps, mapDispatchToProps)(AcceptCollaborationInviteController);