import React from 'react';
import PropTypes from 'prop-types';

class DataTableColumn extends React.Component { };

DataTableColumn.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	dataType: PropTypes.oneOf(["string", "number", "date", "avatar", "bool", "html"]),
	width: PropTypes.number,
	renderer: PropTypes.func,
	exportRenderer: PropTypes.func,
	canFilter: PropTypes.bool,
	filterType: PropTypes.string,
	items: PropTypes.array,
	loadItems: PropTypes.object,
	headerRenderer: PropTypes.func
};

DataTableColumn.defaultProps = {
	name: "",
	label: "",
	dataType: "string",
	width: null,
	renderer: null,
	exportRenderer: null,
	canFilter: false,
	filterType: null,
	items: [],
	loadItems: {},
	headerRenderer: null
};

export default DataTableColumn;
