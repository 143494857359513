
// export const primaryColour = "#156184";
// export const secondaryColour = "#84c446";
// export const selectionColour = "#35aa47";
// export const successColour = "#35aa47";
// export const errorColour = "#d1332e";
// export const cancelColour = "#d84a38";
// export const warningColour = "#f0ad4e";
// export const infoColour = "#5bc0de";
// export const debugColour = "#aaa";
// export const tagColour = "#00897b";
// export const colourPalette = ["#89A54E", "#4572A7", "#80699B", "#3D96AE", "#DB843D", "#92A8CD", "#A47D7C", "#B5CA92"];

export const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};
