import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import AllApplications from './AllApplications';
import { 
	clearOffer,
	getOffer,
	getAllApplications,
	getApplications, 
	isLoading 
} from '../../store/offers';
import { getQueryParameters } from '../../utils/utils';

class ApplicationsController extends React.Component {
	UNSAFE_componentWillMount() {
		const { offerId } = getQueryParameters(this.props.location.search);

		this.props.getOffer(offerId);
		this.props.getApplications(offerId);
	}

	render = () => 
		<PageLayout title="Research Match Applications" pageContent={
			<AllApplications 
				offer={this.props.offer}
				applications={this.props.applications}
				loading={this.props.loading} 
			/> 
		} breadcrumbs={[
			{ label: "Research Match", path: "/research-match" },
			"Applications"
		]} />;
};

ApplicationsController.propTypes = { 
	offer: PropTypes.object.isRequired,
	applications: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	offer: state.offers.offer,
	applications: state.offers.applications,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getOffer: offerId => dispatch(offerId ? getOffer(offerId) : clearOffer()),
	getApplications: offerId => dispatch(offerId ? getApplications(offerId) : getAllApplications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsController);
