import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	getUser, 
	saveUser, 
	isLoading, 
	changePassword,
	reactivateUser,
	deactivateUser,
	deleteUser
} from '../../store/users';
import { showModal } from '../../store/modal';
import { hasFunction } from '../../store/selectors/functions';
import TabContainer from '../widgets/TabContainer';
import Tab from '../widgets/Tab';
import PageLayout from '../PageLayout';
import EditUser from './EditUser';
import ChangePassword from './ChangePassword';

const EditUserController = props => {
	const { userId } = props.match.params;
	
	React.useEffect(() => props.getUser(userId), [userId]);

	return <PageLayout title="Edit user" pageContent={
		<TabContainer>
			<Tab label="Edit details">
				<EditUser
					user={props.user}
					canChangeTenant={props.isGlobalAdministrator}
					canChangeRole={props.canChangeRole}
					loading={props.loading}
					saveResult={props.saveResult}
					onSave={props.saveUser}
					onDelete={props.deleteUser}
					deactivate={props.deactivateUser}
					reactivate={props.reactivateUser}
					tenantId={props.tenantId}
					organisationId={props.organisationId}
					organisationType={props.organisationType}
					showMembershipLevelId={props.showMembershipLevelId}
					warningColour={props.warningColour}
					successColour={props.successColour}
				/>
			</Tab>
			<Tab label="Change password">
				<ChangePassword onSubmit={newPassword => props.changePassword(userId, newPassword)} loading={props.loading} />
			</Tab>
		</TabContainer>
	} breadcrumbs={[{ label: "Users", path: "/users" }, "User"]} />;
};

EditUserController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	canChangeRole: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	getUser: PropTypes.func.isRequired,
	saveUser: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	deleteUser: PropTypes.func.isRequired,
	deactivateUser: PropTypes.func.isRequired,
	reactivateUser: PropTypes.func.isRequired,
	changePassword: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	organisationId: PropTypes.number.isRequired,
	organisationType: PropTypes.string.isRequired,
	showMembershipLevelId: PropTypes.bool.isRequired,
	successColour: PropTypes.string.isRequired,
	warningColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	canChangeRole: hasFunction(state, "UserSubmit")(state),
	user: state.users.user,
	saveResult: state.users.saveResult,
	loading: isLoading(state),
	tenantId: state.context.tenantId,
	organisationId: state.context.organisationId,
	organisationType: state.context.organisationType || "",
	showMembershipLevelId: state.context.showMembershipLevelId,
	successColour: state.theme.successColour,
	warningColour: state.theme.warningColour
});

const mapDispatchToProps = dispatch => ({
	getUser: userId => dispatch(getUser(userId)),
	saveUser: (user, avatarFile) => dispatch(saveUser(user, avatarFile)),
	deleteUser: userId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this user?",
		onOk: () => dispatch(deleteUser(userId))
	})),
	deactivateUser: userId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Deactivate?",
		message: "Are you sure you wish to deactivate this user?",
		onOk: () => dispatch(deactivateUser(userId))
	})),
	reactivateUser: userId => dispatch(reactivateUser(userId)),
	changePassword: (userId, newPassword) => dispatch(changePassword(userId, newPassword))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserController);