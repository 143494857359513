import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const AttachmentList = React.memo(props => 
	<ul className="list-unstyled">
		{props.attachments.map((attachment, index) =>
			<li key={index} className="media">
				<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
				<div className="media-body">
					<h5 className="mt-0 mb-1">{attachment.name}</h5>
					<p>{attachment.description}</p>
					{props.downloadLink &&
						<Button variant="outlined" onClick={() => window.open(props.downloadLink(attachment), "_self")}>Download Now</Button>
					}
				</div>
			</li>
		)}
	</ul>
);

AttachmentList.propTypes = {
	attachments: PropTypes.arrayOf(PropTypes.shape({
		attachmentType: PropTypes.string,
		fileName: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		location: PropTypes.string,
		sizeBytes: PropTypes.number,
		createdByUserId: PropTypes.string,
		createDate: PropTypes.string
	})),
	downloadLink: PropTypes.func
};

AttachmentList.defaultProps = {
	attachments: [],
	downloadLink: null
};

export default AttachmentList;