import React from 'react';
import PropTypes from 'prop-types';
import { 
	Grid, 
	IconButton,
	List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { withForm } from '../../utils/forms';
import FormButtons from '../widgets/FormButtons';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import map from 'lodash/map';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import ColourDialog from '../widgets/ColourDialog';

const form = {
	initValues: props => ({ ...props.theme }),
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			loadItems: {
				route: "tenants",
				mapItem: t => ({ label: t.name, value: t.tenantId })
			}
		},
		{
			name: "name",
			label: "Name",
			required: true,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "primaryColour",
			label: "Primary Colour",
			type: "colourEditor",
			disabled: props => !props.canEdit
		},
		{
			name: "primaryColourText",
			label: "Primary Colour Text",
			type: "colourEditor",
			disabled: props => !props.canEdit
		},
		{
			name: "secondaryColour",
			label: "Secondary Colour",
			type: "colourEditor",
			disabled: props => !props.canEdit
		},
		{
			name: "secondaryColourText",
			label: "Secondary Colour Text",
			type: "colourEditor",
			disabled: props => !props.canEdit
		},
		{
			name: "colourPalette",
			label: "Colour Palette",
			type: "custom",
			widget: props => {
				const [showColourDialog, setShowColourDialog] = React.useState(false);

				return (
                    <div>
						<span className="h3">
							Colour Palette
							<IconButton
                                onClick={() => { setShowColourDialog(true) }}
                                style={{ marginLeft: 5 }}
                                size="large"><AddCircleIcon /></IconButton>
						</span>
						{props.value && props.value.length > 0
							?
							<List>
							{map(props.value, (c, i) =>
								<ListItem key={`pc-${i}`} role={undefined} dense>
								<ListItemIcon>
									<IconButton
                                        onClick={() => {  
                                            const palette = props.value;
                                            palette.splice(i, 1);
                                            props.onChange(palette);
                                        }}
                                        style={{  }}
                                        size="large"><ClearIcon style={{ color: '#d1332e' }} /></IconButton>
								</ListItemIcon>
								<ListItemText primary={
									<div>
										<div style={{
											backgroundColor: c,
											width: '40px',
											height: '40px',
											display: 'inline-block',
											marginRight: '5px',
											border: '1px solid #333'
										}}>
										</div>
										<span style={{ position: 'absolute', top: '17px' }}>{c}</span>
									</div>
								} />
								</ListItem>
							)}
							</List>
							:
							<p>Empty</p>
						}
						<ColourDialog
							open={showColourDialog}
							onClose={() => { setShowColourDialog(false) }}
							onOk={(newColour) => {
								const palette = props.value || [];
								palette.push(newColour);
								props.onChange(palette);
								setShowColourDialog(false);		
							}}
						/>
					</div>
                );
			}
		}
	]
};

const EditTheme = props => {
	const { fields, values, loading, isNew } = props;

	const onSubmit = () => {
		if (props.validateFields()) {
			props.onSave({ ...values });
		}
	}

	return (
		<Card 
			title={`${isNew ? "Add" : "Edit"} Theme`} 
			className="edit-theme" 
			loading={loading}
		>
			<Form onSubmit={onSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.name()}</Grid>
					<Grid item xs={12}>{fields.primaryColour()}</Grid>
					<Grid item xs={12}>{fields.primaryColourText()}</Grid>
					<Grid item xs={12}>{fields.secondaryColour()}</Grid>
					<Grid item xs={12}>{fields.secondaryColourText()}</Grid>
					<Grid item xs={12}>{fields.colourPalette()}</Grid>
				</Grid>
			</Form>
			{props.canEdit && 
				<FormButtons 
					loading={loading}
					success={props.saveResult.success}
					onSave={onSubmit}
					showDelete={!isNew}
					onDelete={() => props.onDelete(values)}
				/>
			}
		</Card>
	);
};

EditTheme.propTypes = { 
	canEdit: PropTypes.bool,
	canSelectTenant: PropTypes.bool,
	theme: PropTypes.object,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditTheme.defaultProps = {
	canEdit: false,
	canSelectTenant: false,
	theme: {},
	onDelete: () => null,
	isNew: false
};

export default withForm(form)(EditTheme);
