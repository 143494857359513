import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import { searchActivities, isLoading } from '../../store/activities';
import { Grid, CircularProgress, Tabs, Tab, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import pick from 'lodash/pick';
import map from 'lodash/map';
import ActivityCard from './ActivityCard';
import ButtonLink from '../widgets/ButtonLink';
import { canSubmitActivities } from '../../store/security';

let timer = null;
const form = {
	initValues: props => ({ status: "all", visibility: "Public", ...props.searchArgs }),
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchActivities(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "status",
			label: "Filter by status",
			type: "select",
			items: [{ label: "(All)", value: "all" }, "Published", "Draft"]
		},
		{
			name: "visibility"
		}
	]
};

const tabs = ["My", "Private", "Public"];

const ActivitiesController = props => {
	const [tab, setTab] = React.useState(tabs.indexOf(props.searchArgs.visibility || "Public"))
	const { activities, fields } = props;

	React.useEffect(() => props.searchActivities(props.searchArgs), []);

	const handleVisibilityChange = (event, tab) => {
		const visibility = tabs[tab];

		props.updateValues({ visibility });
		props.searchActivities({ ...props.values, visibility });
		setTab(tab);
	}
	
	const content = (
		<React.Fragment>
			<Paper style={{ display: "inline-block" }}>
				<Tabs value={tab} onChange={handleVisibilityChange}>
					<Tab label="My" />
					<Tab label="Private" />
					<Tab label="All" />
				</Tabs>
			</Paper>
			{props.canSubmit && <ButtonLink style={{ float: "right" }} to="/activities/create">Create new activity</ButtonLink>}
			<Grid container spacing={3} justifyContent="space-between" alignItems="flex-end">
				<Grid item xs={12} sm={8} md={6}>{fields.search()}</Grid>
				<Grid item xs={12} sm={4} md={3}>{fields.status()}</Grid>
			</Grid>
			{activities.length === 0 ? <p style={{ marginTop: 10 }}>No activities found...</p> :
				<Grid container spacing={3}>
					{map(activities, (a, i) => <ActivityCard key={i} activity={a} />)}
				</Grid>
			}
		</React.Fragment>
	);

	return <PageLayout title="Activities" pageContent={content} breadcrumbs={["Activities"]} />;
};

ActivitiesController.propTypes = { 
	activities: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	activities: state.activities.activities,
	loading: isLoading(state),
	canSubmit: canSubmitActivities(state),
	searchArgs: pick(getQueryParameters(ownProps.location.search), "search", "status", "visibility")
});

const mapDispatchToProps = dispatch => ({
	searchActivities: args => dispatch(searchActivities(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ActivitiesController));
