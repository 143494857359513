import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import TextField from '../widgets/TextField';

const form = {
	initValues: props => ({ visibility: "Public", ...props.activity }),
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
			helpText: "Name of activity"
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		},
		{
			name: "summary",
			label: "Summary",
			multiline: true,
			helpText: "Summmary of activity"
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor"
		},
		{
			name: "materialsNeeded",
			label: "Materials needed",
			type: "dragList",
			itemTemplate: { material: "", amount: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={8}>
						<TextField label="Material" value={item.material} onChange={e => updateItem(e.target.value, "material")} />
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField label="Amount" value={item.amount} onChange={e => updateItem(e.target.value, "amount")} />
					</Grid>
				</Grid>,
			helpText: "Materials needed for activity"
		},
		{
			name: "visibility",
			label: "Visibility",
			required: true,
			type: "select",
			items: ["Public", "Private"]
		},
		{
			name: "status",
			label: "Status",
			required: true,
			type: "select",
			items: ["Published", "Draft"]
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		}, 
		{
			name: "images",
			label: "Images",
			type: "imageUploader"
		},
		{
			name: "videos",
			label: "Videos",
			type: "dragList",
			itemTemplate: "",
			renderItem: (value, index, updateItem) => 
				<TextField key={index} label="Link" value={value} onChange={e => updateItem(e.target.value)} />
		},
		{
			name: "files",
			type: "attachmentEditor",
			download: attachment =>
				window.open(`/Activity/DownloadAttachment?activityId=${attachment.activityId}&activityAttachmentId=${attachment.activityAttachmentId}`, "_self")
		},
		{
			name: "links",
			label: "Links",
			type: "dragList",
			itemTemplate: { label: "", to: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={24}>
					<Grid item xs={12} md={4}>
						<TextField label="Label" value={item.label} onChange={e => updateItem(e.target.value, "label")} />
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField startAdornment="https://" label="Link" value={item.to} onChange={e => updateItem(e.target.value, "to")} />
					</Grid>
				</Grid>
		}
	]
};

const EditActivity = props => {
	const { activity, fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} activity`} loading={loading} className="edit-activity">
			<Form onSubmit={() => props.validateFields() && props.saveActivity(props.values, [...props.formFiles.images, ...props.formFiles.files])}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.name()}</Grid>
					<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12}>{fields.summary()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.materialsNeeded()}</Grid>
					<Grid item xs={12} sm={6}>{fields.visibility()}</Grid>
					<Grid item xs={12} sm={6}>{fields.status()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
					<Grid item xs={12}>{fields.images()}</Grid>
					<Grid item xs={12}>{fields.videos()}</Grid>
					<Grid item xs={12}>{fields.links()}</Grid>
					<Grid item xs={12}>{fields.files()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} activity</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteActivity(activity.activityId)}>
							Delete Activity
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditActivity.propTypes = { 
	activity: PropTypes.object,
	saveActivity: PropTypes.func.isRequired,
	deleteActivity: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditActivity.defaultProps = {
	activity: {},
	deleteActivity: () => null,
	isNew: false
};

export default withForm(form)(EditActivity);
