import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';

const ExpertCard = React.memo(({ expert: e }) => 
	<Card className="expert-card">
		<Link to={`/experts/${e.expertId}`} className="h3">
			<Avatar src={e.avatar} fullName={e.fullName} style={{ marginRight: 5 }} />
			{e.fullName}
		</Link>
		{e.industryName}, {e.discipline}
		<br />
		{e.seniority} {e.yearsExperience} Years
	</Card>
);

ExpertCard.propTypes = {
	expert: PropTypes.object.isRequired
};

export default ExpertCard;