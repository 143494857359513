import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditAcademic from './EditAcademic';
import { showModal } from '../../store/modal';
import { getAcademic, saveAcademic, deleteAcademic, isLoading } from '../../store/academics';
import PageLayout from '../PageLayout';

const EditAcademicController = props => {
	React.useEffect(() => props.getAcademic(props.match.params.academicId), [props.match.params.academicId]);

	return <PageLayout title="Edit academic" pageContent={
		<EditAcademic
			academic={props.academic}
			saveAcademic={props.saveAcademic}
			deleteAcademic={props.deleteAcademic}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Academics", path: "/academics" }, "Edit academic"]} />;
};

EditAcademicController.propTypes = {
	academic: PropTypes.object.isRequired,
	saveAcademic: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.academics.saveResult,
	academic: state.academics.academic,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getAcademic: academicId => dispatch(getAcademic(academicId)),
	saveAcademic: (academic, imageFile) => dispatch(saveAcademic(academic, imageFile)),
	deleteAcademic: academicId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this academic?",
		onOk: () => dispatch(deleteAcademic(academicId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAcademicController);
