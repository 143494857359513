/* eslint react/jsx-pascal-case: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import AutoComplete from '../widgets/AutoComplete';
import TextField from '../widgets/TextField';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import CopyCLO_CourseDialog from './CopyCLO_CourseDialog';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { isFuture } from 'date-fns';

const form = {
		initValues: ({ course = { acceleratorCourse: true }, organisationId }) => ({ 
		providerId: organisationId, 
		...course,
		channels: map(course.channels, c => ({ ...c, label: c.name, value: c.channelId })), 
		organisations: map(course.organisations, o => ({ ...o, label: o.name, value: o.organisationId }))
	}),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of Course"
		},
		{
			name: "providerId",
			label: "Provider",
			required: true,
			disabled: props => !props.canAdminCLO_Courses,
			type: "autoComplete",
			loadItems: {
				route: "organisations/providers",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			helpText: "Course Provider"
		},
		{
			name: "type",
			label: "Type",
			required: true,
			type: "select",
			items: ["In-House", "Award Course", "Platform Provider Course", "Short Course / Micro-Credential"],
			helpText: "Type of Course"
		},
		{
			name: "mode",
			label: "Mode",
			required: true,
			type: "select",
			items: [{ label: "(All)", value: "all" }, "Online", "Digital Classroom", "Face-to-face", "Blended"],
			helpText: "Mode used for duration of Course"
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor",
			helpText: "Brief description of Course"
		},
		{
			name: "contactName",
			label: "Contact Name",
			helpText: "Name of individual to contact for inquries"
		},
		{
			name: "contactEmail",
			label: "Contact Email",
			helpText: "Destination email for contact requests"
		},
		{
			name: "website",
			label: "Website",
			helpText: "URL to course brochure or registration page"
		},
		{
			name: "image",
			label: "Logo",
			type: "imageSelector",
			helpText: "Select an image to represent the course",
			imageText: "",
			height: 64,
			width: 64
		},
		{
			name: "channels",
			label: "Channels",
			type: "autoComplete",
			loadItems: {
				route: "channels",
				mapItem: ({ channelId, name }) => ({ label: name, value: channelId, channelId })
			},
			isMulti: true,
			helpText: "Course channels (please select all relevant options)"
		},
		{
			name: "organisations",
			label: "Organisations",
			type: "dragList",
			simple: true,
			itemTemplate: { organisationId: 0, price: "" },
			renderItem: (item, index, updateItem, props) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={6}>
						<AutoComplete 
							label="Organisation"
							items={props.organisations}
							value={item.organisationId} 
							onChange={item => updateItem(item.value, "organisationId")}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField 
							label="Price"
							placeholder="0.00"
							startAdornment="$"
							value={item.price || ""} 
							onChange={e => updateItem(e.target.value, "price")} 
						/>
					</Grid>
				</Grid>,
			helpText: "Organisations managing Course (Price optional)",
			validate: organisations => {
				const ids = organisations.map(o => o.organisationId);

				return new Set(ids).size !== ids.length ? "An organisation may only be added once" : "";
			}
		},
		{
			name: "price",
			label: "Price",
			placeholder: "0.00",
			startAdornment: "$"
		},
		{
			name: "commencementDate",
			label: "Commencement Date",
			type: "date",
			stripTime: true
		},
		{
			name: "expiryDate",
			label: "Expiry Date",
			type: "date",
			stripTime: true,
			validate: value => isFuture(new Date(value)) ? "" : "Expiry date must occur after today"
		},
		{
			name: "partnerPrice",
			label: "Partner Price",
			placeholder: "0.00"
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor",
			scope: "Courses"
		},
		{
			name: "attachments",
			label: "Course Brochure(s)",
			type: "attachmentEditor",
			download: attachment => {
				window.open(`/CLO_Course/DownloadAttachment?courseId=${attachment.clO_CourseId}&attachmentId=${attachment.clO_AttachmentId}`, "_self");
			}
		},
		{
			name: "skipPreview",
			label: "Show Preview",
			type: "checkbox"
		},
		{
			name: "acceleratorCourse",
			label: "Accelerator Course",
			type: "checkbox"
		},
		{
			name: "partnerPriceApplies",
			label: "Partner Price Applies",
			type: "checkbox"
		}
	]
};

const EditCLO_Course = props => {
	const [state, setState] = React.useState({
		unitId: 0,
		copyDialogOpen: false
	});
	
	const { course, fields, loading } = props;
	return (
		<Card>
			<Form onSubmit={() => props.validateFields() && props.saveCLO_Course(props.values, props.formFiles.image, props.formFiles.attachments)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.title()}</Grid>
					<Grid item xs={12} sm={6}>{fields.providerId()}</Grid>
					<Grid item xs={12} sm={6}>{fields.type()}</Grid>
					<Grid item xs={12} sm={6}>{fields.mode()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12} sm={6}>{fields.contactName()}</Grid>
					<Grid item xs={12} sm={6}>{fields.contactEmail()}</Grid>
					<Grid item xs={12}>{fields.website()}</Grid>
					<Grid item xs={12} sm={6}>{fields.price()}</Grid>
					{/* <Grid item xs={12} sm={6}>{fields.partnerPrice()}</Grid>
					<Grid item xs={12}>{fields.organisations()}</Grid> */}
					<Grid item xs={12}>{fields.image()}</Grid>
					<Grid item xs={12}>{fields.channels()}</Grid>
					<Grid item xs={12}>{fields.skipPreview()}</Grid>
					<Grid item xs={12}>{fields.acceleratorCourse()}</Grid>
					<Grid item xs={12}>{fields.attachments()}</Grid>
					<Grid item xs={12}>{fields.tags()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>Save course</Button>
					{!isEmpty(course) &&
						<React.Fragment>
							<Button className="delete-button" loading={loading} onClick={() => props.deleteCLO_Course(course.clO_CourseId)}>
								Delete course
							</Button>
							<Button 
								color="secondary" 
								loading={loading} 
								onClick={() => setState({ ...state, copyDialogOpen: true })}
								style={{ marginLeft: 10 }}	
							>
								Copy course
							</Button>
						</React.Fragment> 
					}
				</div>
			</Form>
			<CopyCLO_CourseDialog 
				title={`${course.title} - Copy`}
				open={state.copyDialogOpen} 
				copyCLO_Course={(title) => {
					props.copyCLO_Course(course.clO_CourseId, title);
					setState({ ...state, copyDialogOpen: false });
				}}
				onClose={() => setState({ ...state, copyDialogOpen: false })} 
			/>
		</Card>
	);
};

EditCLO_Course.propTypes = { 
	course: PropTypes.object,
	saveCLO_Course: PropTypes.func.isRequired,
	deleteCLO_Course: PropTypes.func,
	copyCLO_Course: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number,
	organisations: PropTypes.array.isRequired,
	canAdminCLO_Courses: PropTypes.bool.isRequired
};

EditCLO_Course.defaultProps = {
	course: {},
	deleteCLO_Course: () => null,
	copyCLO_Course: () => null,
	organisationId: null
};

export default withForm(form)(EditCLO_Course);