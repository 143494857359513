import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditStudent from './EditStudent';
import { showModal } from '../../store/modal';
import { getStudent, saveStudent, deleteStudent, isLoading } from '../../store/students';
import PageLayout from '../PageLayout';

const EditStudentController = props => {
	React.useEffect(() => props.getStudent(props.match.params.studentId), [props.match.params.studentId]);

	return <PageLayout title="Edit student" pageContent={
		<EditStudent
			student={props.student}
			saveStudent={props.saveStudent}
			deleteStudent={props.deleteStudent}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Students", path: "/students" }, "Edit student"]} />;
};

EditStudentController.propTypes = {
	student: PropTypes.object.isRequired,
	saveStudent: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.students.saveResult,
	student: state.students.student,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getStudent: studentId => dispatch(getStudent(studentId)),
	saveStudent: (student, newFiles) => dispatch(saveStudent(student, newFiles)),
	deleteStudent: studentId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this student?",
		onOk: () => dispatch(deleteStudent(studentId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStudentController);