import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { canSubmitMentors } from '../../store/security';
import { searchMentors, isLoading } from '../../store/mentors';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import MentorCard from './MentorCard';
import map from 'lodash/map';
import pick from 'lodash/pick';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchMentors(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		}
	]
};

const MentorsController = props => {
	const { mentors } = props;

	React.useEffect(() => {
		const { searchArgs } = props;
		
		props.searchMentors(searchArgs);
	}, []);
	
	return <PageLayout title="Mentors" pageContent={
		<React.Fragment>
			<Grid container spacing={3} direction="row-reverse">
				{props.canSubmit &&
					<Grid item xs="auto">
						<ButtonLink to="/mentors/create">Create mentor</ButtonLink>
					</Grid>
				}
				<Grid item xs={12} sm>{props.fields.search()}</Grid>
			</Grid>
			{mentors.length === 0 ? <p style={{ marginTop: 10 }}>No mentors found...</p> :
				<Grid container spacing={3}>
					{map(mentors, (m, i) => 
						<Grid key={i} item xs={12} sm={6} lg={4}>
							<MentorCard mentor={m} />
						</Grid>
					)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={["Mentors"]} />;
};

MentorsController.propTypes = { 
	mentors: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchMentors: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	mentors: state.mentors.mentors,
	loading: isLoading(state),
	searchArgs: {
		search: "",
		...pick(getQueryParameters(ownProps.location.search), "search")
	},
	canSubmit: canSubmitMentors(state)
});

const mapDispatchToProps = dispatch => ({
	searchMentors: args => dispatch(searchMentors(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(MentorsController));