import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createAccount, isLoading } from '../../store/context';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import TermsDialog from '../modals/TermsDialog';
import lowerCase from 'lodash/lowerCase';
import Form from '../widgets/Form';
import { getQueryParameters } from '../../utils/utils';
import GoogleButton from '../widgets/GoogleButton';

const validEmail = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

const form = {
	initValues: props => {
		return { 
			organisationId: props.organisationId, 
			membershipLevelId: null
		};
	},
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "firstName",
			required: true,
			label: "First name",
			autoFocus: true,
		},
		{
			name: "lastName",
			required: true,
			label: "Last name",
			onBlur: (value, values, setValue) => {
				if (values.firstName && !values.username) {
					setValue("username", `${lowerCase(values.firstName)}.${lowerCase(values.lastName)}`);
				}
			}
		},
		{
			name: "email",
			required: true,
			label: "Email",
			validate: value => validEmail.test(value) ? "" : "Email must be a valid address",
			helpText: "Your company email address",
		},
		{
			name: "password",
			required: true,
			label: "Password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters"
		},
		{
			name: "cpassword",
			required: true,
			label: "Confirm password",
			type: "password",
			validate: (value, values) => value === values.password ? "" : "Both passwords must be identical"
		}
	]
};

const CreateAccount = props => {
	const { fields, values } = props;
	
	const defaultAcceptTermsContent = `In accessing the Research 2 U platform you are agreeing to our standard <a href="/documents/R2B Terms.pdf" target="_blank" rel="noopener noreferrer">terms and conditions</a> and our associated <a href="/R2B Privacy Policy.pdf" target="_blank" rel="noopener noreferrer">privacy policy</a>.`;

	return (
		<div className="registration-box">
			<span className="h3">Create Account</span>
			<small>This registration process is for those associated with a university ONLY. If you are an industry expert wishing to publish you need to <a href="/request-access">request access here</a></small>
			<Form 
				onSubmit={() => {
					if (props.validateFields()) {
						const registerArgs = { ...values };
						props.createAccount(registerArgs);
					} 
				}}
				style={{ textAlign: "center" }}
			>
				{fields.firstName()}
				{fields.lastName()}
				{fields.email()}
				{fields.password()}
				{fields.cpassword()}
				<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.loginAcceptTermsContent || defaultAcceptTermsContent }} />
				<Button type="submit" size="large" loading={props.loading} color="secondary">Create Account</Button>
			</Form>
			<p
				style={{
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '18px'
				}}
			>OR USE</p>
			<GoogleButton />

			<div style={{ marginTop: 10, textAlign: "center" }}>
				Already registered? <ButtonLink to="/login" variant="text" size="small" color="primary">Sign in</ButtonLink>
			</div>
			{/* Terms and Conditions Modal */}
			<TermsDialog
				open={Boolean(values._termsOpen)}
				onClose={() => props.updateValues({ _termsOpen: false })}
				showAgreeButton
				onAgree={() => props.updateValues({ _termsOpen: false, agreetc: true })}
				onDisagree={() => props.updateValues({ _termsOpen: false, agreetc: false })}
			/>
		</div>
	);
};

CreateAccount.propTypes = {
	loading: PropTypes.bool.isRequired,
	createAccount: PropTypes.func.isRequired,
	tenantId: PropTypes.number,
	loginAcceptTermsContent: PropTypes.string.isRequired
};

CreateAccount.defaultProps = {
	tenantId: 0
};

const mapStateToProps = (state, ownProps) => ({
	loading: isLoading(state),
	tenantId: state.context.registrationTenantId,
	queryParameters: getQueryParameters(ownProps.location.search),
	loginAcceptTermsContent: state.settings.loginAcceptTermsContent,
});

const mapDispatchToProps = dispatch => ({
	createAccount: (values) => dispatch(createAccount(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CreateAccount));