import React from 'react';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogContent, 
	DialogActions, 
	DialogTitle, 
	Grid, 
	CircularProgress, 
	LinearProgress 
} from '@mui/material';
import { DayPickerSingleDateController } from 'react-dates';
import { withForm } from '../../utils/forms';
import UnitOfCompetencyPicker from './UnitOfCompetencyPicker';
import UnitOfCompetencyViewer from './UnitOfCompetencyViewer';
import Card from '../widgets/Card';
import Button from '../widgets/Button';
import AutoComplete from '../widgets/AutoComplete';
import ProfilePicker from '../widgets/ProfilePicker';
import Select from '../widgets/Select';
import forEach from 'lodash/forEach';
import startsWith from 'lodash/startsWith';
import CompetenciesGrid from './CompetenciesGrid';
import TeamCompetenciesGrid from './TeamCompetenciesGrid';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	competencies: {
		'& .competency-progress': {
			'> div': {
				backgroundColor: theme.palette.secondary.main
			},
			'&.warning': {
				'> div': {
					backgroundColor: theme.palette.warning.main
				}
			},
			'&.error': {
				'> div': {
					backgroundColor: theme.palette.error.main
				}
			}
		}
	}
});

const form = {
	initValues: props => props.skills,
	fields: [{
		name: "programs",
		type: "textEditor"
	}]
};

const Competencies = props => {
	const [state, setState] = React.useState({
		unitPickerOpen: false,
		unitViewerOpen: false,
		date: null,
		showDatePicker: false,
		showProgramDialog: false,
		evidence: null,
		dateField: null,
		unitId: 0,
		elementId: null,
		otherUnits: false,
		elements: [],
		filter: "all",
		position: 1,
		reportViewerOpen: false,
		reportLoading: false,
		showAllCompetencies: false,
		false: true,
		showBySpecialisation: true
	});

	const editDate = ({ unitOfCompetencyId }, elementId, dateField) => 
		setState(prevState => ({ ...prevState, showDatePicker: true, unitId: unitOfCompetencyId, elementId, dateField }));

	const viewUnit = ({ unitOfCompetencyId, elements }, elementId) => 
		setState(prevState => ({ ...prevState, unitViewerOpen: true, unitId: unitOfCompetencyId, elementId, elements }));

	const units = [...props.profileUnits];
	const data = {
		all: [],
		assessed: [],
		notAssessed: [],
		competent: [],
		incompetent: []
	};
	
	const competencyUnits = [...units]; 
	forEach(props.competencies, c => {
		const index = competencyUnits.findIndex(u => u.unitOfCompetencyId === c.unitOfCompetencyId);
		if (index > -1) competencyUnits.splice(index, 1);

		if (index > -1 || state.showAllCompetencies) {
			const competency = { ...c, canEdit: true };
			data.all.push(competency);
			data.assessed.push(competency);
			if (c.competent) {
				data.competent.push(competency);
			} else {
				data.incompetent.push(competency);
			}
		}
	});
	forEach(competencyUnits, u => {
		data.all.push(u);
		data.notAssessed.push(u);
	});

	const competencies = props.user === "team" ? props.competencies : data[state.filter];
	const competencyRate = Math.round((props.profileUnits.length - data.notAssessed.length) / props.profileUnits.length * 100);

	const reviewStatus = !props.showReviewStatus ? null : (
		<Select 
			label="Review Status" 
			items={["In-Progress", "Complete"]} 
			value={props.reviewStatus} 
			onChange={e => props.updateReviewStatus(e.target.value)} 
			style={{ width: 150, marginRight: 10 }} 
		/>
	);

	const CompetenciesControl = props.user === "team" ? TeamCompetenciesGrid : CompetenciesGrid;

	return (
        <Card loading={props.loading} className={`competencies card-hide-borders-on-print ${props.classes.competencies}`}>
			<Grid container spacing={3} justifyContent="space-between" alignItems="flex-end">
				{/* User Selection */}
				{props.mode === "team" ?
					<React.Fragment>
						{/* User Selection */}
						<Grid item xs={12} md={3}>
							<AutoComplete 
								label="User"
								value={props.user}
								items={[
									{ label: "All Team", value: "team" },
									...props.users.map(u => ({ label: u.fullName, value: u.userId }))
								]}
								onChange={e => props.onUserChange(e.value)}
								clearable={false}
							/>
						</Grid>
						{/* Dialog Buttons */}
						{!props.isViewOnly && 
							<Grid item xs={12} md="auto">
								{reviewStatus}
								<Button onClick={() => setState({ ...state, unitPickerOpen: true, otherUnits: false })} disabled={props.lockCompetencyUpdates}>Add/Edit Competencies</Button>
								<Button onClick={() => setState({ ...state, showProgramDialog: true })} disabled={props.lockCompetencyUpdates} style={{ marginLeft: 10 }}>Other Skills</Button>
								<Button warning={props.dirty} loading={props.loading} onClick={props.saveCompetencies} disabled={props.lockCompetencyUpdates} style={{ marginLeft: 10 }}>Save</Button>
							</Grid>
						}
					</React.Fragment> :
					<React.Fragment>
						{/* User Selection */}
						<Grid item xs="auto">
							<Button onClick={() => setState({ ...state, unitPickerOpen: true, otherUnits: false })} disabled={props.lockCompetencyUpdates}>Add/Edit Competencies</Button>
							<Button onClick={() => setState({ ...state, showProgramDialog: true })} disabled={props.lockCompetencyUpdates} style={{ marginLeft: 10 }}>Other Skills</Button>
						</Grid>
						{/* Dialog Buttons */}
						<Grid item xs="auto">
							{reviewStatus}
							<Button warning={props.dirty} loading={props.loading} onClick={props.saveCompetencies} disabled={props.lockCompetencyUpdates} style={{ marginLeft: 10 }}>Save</Button>
						</Grid>
					</React.Fragment>
				}
				<Grid item xs={12} style={{ padding: 0 }} />
				{/* <Grid item xs={12} md>
					<RadioGroup className="competency-filter" row value={state.filter} onChange={e => setState({ ...state, filter: e.target.value })}>
						<FormControlLabel value="all" control={<Radio />} label={`All (${data.all.length})`} />
						<FormControlLabel value="assessed" control={<Radio />} label={`Assessed (${data.assessed.length})`} />
						<FormControlLabel value="competent" control={<Radio />} label={`Meets target (${data.competent.length})`} />
						<FormControlLabel value="incompetent" control={<Radio />} label={`Below target (${data.incompetent.length})`} />
						<FormControlLabel value="notAssessed" control={<Radio />} label={`Not assessed (${data.notAssessed.length})`} />
					</RadioGroup>
				</Grid> */}
				{props.user !== "team" &&
				<Grid item xs={12} md="auto">
					<ProfilePicker 
						value={props.skillsProfileId} 
						onChange={value => props.updateSkillsProfile(value)} 
						showPicker={props.showProfilePicker}
						onClose={props.onProfilePickerClose}
						defaultGroup={props.profileGroupId}
						disableClearable
						disabled={props.isViewOnly}
					/>
				</Grid>
				}
			</Grid>
			<LinearProgress
				className="competency-progress"
				variant="determinate" 
				value={competencyRate} 
				style={{ marginTop: 10 }} 
			/>
			<div className="marker">
				<div style={{ left: competencyRate < 4 ? 0 : `calc(${competencyRate}% - 115px)` }}>{competencyRate || 0}% Assessed</div>
			</div>
			<CompetenciesControl
					profileId={props.skillsProfileId} 
					units={props.profileUnits}
					competencies={competencies}
					users={props.users}
					viewUnit={viewUnit}
					editDate={editDate}
					deleteCompetency={props.deleteCompetency}
					updateCompetency={props.updateCompetency}
					lockCompetencyUpdates={props.lockCompetencyUpdates}
					printReport={props.printReport}
					showReportViewer={() => {
						if (startsWith(props.printReport, '/')) {
							window.open(`/reports/view${props.printReport}`, "_blank");
						} else {
							setState({ ...state, reportViewerOpen: true, reportLoading: true });
						}
					}}
					showAllCompetencies={state.showAllCompetencies}
					setShowAllCompetencies={v => setState({ ...state, showAllCompetencies: v })}
				/>
			{/* {state.showBySpecialisation ? 
				<CompetenciesGridBySpecialisation
					profileId={props.skillsProfileId} 
					competencies={competencies}
					viewUnit={viewUnit}
					editDate={editDate}
					deleteCompetency={props.deleteCompetency}
					updateCompetency={props.updateCompetency}
					lockCompetencyUpdates={props.lockCompetencyUpdates}
					printReport={props.printReport}
					showReportViewer={() => {
						if (startsWith(props.printReport, '/')) {
							window.open(`/reports/view${props.printReport}`, "_blank");
						} else {
							setState({ ...state, reportViewerOpen: true, reportLoading: true });
						}
					}}
					showAllCompetencies={state.showAllCompetencies}
					setShowAllCompetencies={v => setState({ ...state, showAllCompetencies: v })}
					successColour={props.successColour}
					errorColour={props.errorColour}
				/>
				:
				<CompetenciesGridTable
					profileId={props.skillsProfileId} 
					competencies={competencies}
					viewUnit={viewUnit}
					editDate={editDate}
					deleteCompetency={props.deleteCompetency}
					updateCompetency={props.updateCompetency}
					lockCompetencyUpdates={props.lockCompetencyUpdates}
					printReport={props.printReport}
					showReportViewer={() => {
						if (startsWith(props.printReport, '/')) {
							window.open(`/reports/view${props.printReport}`, "_blank");
						} else {
							setState({ ...state, reportViewerOpen: true, reportLoading: true });
						}
					}}
					showAllCompetencies={state.showAllCompetencies}
					setShowAllCompetencies={v => setState({ ...state, showAllCompetencies: v })}
					successColour={props.successColour}
					errorColour={props.errorColour}
				/>
			} */}
			<Dialog scroll="body" open={state.reportViewerOpen} onClose={() => setState({ ...state, reportViewerOpen: false })} maxWidth="lg" fullWidth>
				<DialogContent style={{ padding: 0 }}>
					{state.reportLoading && <CircularProgress size={24} style={{ margin: 20 }} />}
					<iframe 
						title="report-viewer"
						src={`/ViewReport?userId=${props.user}&reportName=${props.printReport}`}
						width="100%"
						height={state.reportLoading ? undefined : "1485"}
						frameBorder="0"
						onLoad={() => setState({ ...state, reportLoading: false })}
					/>
				</DialogContent>
			</Dialog>
			<UnitOfCompetencyViewer
				open={state.unitViewerOpen}
				skillsProfileId={props.skillsProfileId}
				unitId={state.unitId}
				elementId={state.elementId}
				selectedElements={state.elements}
				onClose={() => setState({ ...state, unitViewerOpen: false })}
				canEdit={!props.lockCompetencyUpdates}
				onOk={elements => {
					setState({ ...state, unitViewerOpen: false });
					if (props.lockCompetencyUpdates) return;

					if (props.competencies.some(c => c.unitOfCompetencyId === state.unitId)) {
						props.updateCompetency(state.unitId, null, "Elements", elements);
					} else {
						const unit = props.profileUnits.find(u => u.unitOfCompetencyId === state.unitId);

						props.addUnits([{
							competencyId: 0,
							unitCode: unit.unitCode,
							unitTitle: unit.unitTitle,
							unitOfCompetencyId: unit.unitOfCompetencyId,
							unitDescriptionAndApplication: unit.unitDescriptionAndApplication,
							specialisationId: unit.specialisationId,
							specialisation: unit.specialisation,
							specialisationColour: unit.specialisationColour,
							specialisationGroupId: unit.specialisationGroupId,
							specialisationGroup: unit.specialisationGroup,
							specialisationGroupColour: unit.specialisationGroupColour,
							unitColour: unit.unitColour,
							competent: true,
							assessmentDate: new Date(),
							expiryDate: new Date(),
							elements
						}]);
					}
				}}
			/>
			<UnitOfCompetencyPicker 
				mode={props.mode}
				open={state.unitPickerOpen || props.promptSelection}
				promptSelection={props.promptSelection}
				expandRows={props.autoExpandCompetencies}
				otherUnits={state.otherUnits}
				skillsProfileId={props.skillsProfileId}
				useDepartment
				canEdit={!props.lockCompetencyUpdates}
				competencies={props.competencies}
				currentUnits={props.competencies.map(u => u.unitOfCompetencyId)}
				onClose={() => {
					setState({ ...state, unitPickerOpen: false });
					props.resetPromptSelection();
				}}
				okButtonLabel="Add and Save"
				onOk={units => {
					setState({ ...state, unitPickerOpen: false });
					if (props.lockCompetencyUpdates) return;
					props.addUnitsAndSave(units);
				}}
				showNotCompetent={props.showNotCompetent}
				graphicalSkillsView={props.graphicalSkillsView}
			/>
			<Dialog	open={state.showDatePicker} onClose={() => setState({ ...state, showDatePicker: false })}>
				<DialogContent style={{ padding: 0 }}>
					<DayPickerSingleDateController onDateChange={e => {
						setState({ ...state, showDatePicker: false });
						props.updateCompetency(state.unitId, state.elementId, state.dateField, e.toDate());
					}} />
				</DialogContent>
			</Dialog>
			<Dialog	open={state.showProgramDialog} maxWidth="md" onClose={() => setState({ ...state, showProgramDialog: false })}>
				<DialogTitle id="dialog-title">Other Skills</DialogTitle>
				<DialogContent>{props.fields.programs()}</DialogContent>
				<DialogActions>
					<Button onClick={() => setState({ ...state, showProgramDialog: false })} autoFocus>Cancel</Button>
					<Button onClick={() => {
						setState({ ...state, showProgramDialog: false });
						props.updateSkills(props.values);
					}} className="ok-button">Ok</Button>
				</DialogActions>
			</Dialog>
		</Card>
    );
};

Competencies.propTypes = {
	competencies: PropTypes.array.isRequired,
	skillsProfileId: PropTypes.number.isRequired,
	profileUnits: PropTypes.array.isRequired,
	addUnits: PropTypes.func.isRequired,
	addUnitsAndSave: PropTypes.func.isRequired,
	updateSkillsProfile: PropTypes.func.isRequired,
	deleteCompetency: PropTypes.func.isRequired,
	saveCompetencies: PropTypes.func.isRequired,
	updateCompetency: PropTypes.func.isRequired,
	skills: PropTypes.object.isRequired,
	updateSkills: PropTypes.func.isRequired,
	reviewStatus: PropTypes.string.isRequired,
	updateReviewStatus: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	promptSelection: PropTypes.bool.isRequired,
	resetPromptSelection: PropTypes.func.isRequired,
	dirty: PropTypes.bool.isRequired,
	printReport: PropTypes.string.isRequired,
	lockCompetencyUpdates: PropTypes.bool.isRequired,
	autoExpandCompetencies: PropTypes.bool.isRequired,
	mode: PropTypes.string.isRequired,
	users: PropTypes.array.isRequired,
	user: PropTypes.string.isRequired,
	onUserChange: PropTypes.func.isRequired,
	showNotCompetent: PropTypes.bool.isRequired,
	profileGroupId: PropTypes.number.isRequired,
	showReviewStatus: PropTypes.bool.isRequired,
	graphicalSkillsView: PropTypes.bool.isRequired,
	isViewOnly: PropTypes.bool
};

Competencies.defaultProps = {
	isViewOnly: false
};

export default withForm(form)(withStyles(Competencies, styles));