import React from 'react';
import PropTypes from 'prop-types';
import MediaCard from './MediaCard';
import { Fade, Tooltip } from '@mui/material';
import distanceInWords from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';
import isPast from 'date-fns/isPast';
import map from 'lodash/map';
import Tag from './Tag';
import Grid from '@mui/material/Grid';

const OfferBox = React.memo(({ offer, style, show }) => {
	const today = new Date();
	const daysTillClosing = offer.expiryDate ? differenceInDays(new Date(offer.expiryDate), today) : 999;
	const daysSincePublished = offer.datePublished ? differenceInDays(today, new Date(offer.datePublished)) : 0;
	const lastUpdate = new Date(offer.lastUpdate || offer.datePublished);
	const isClosed = offer.expiryDate && (isPast(new Date(offer.expiryDate)) || offer.status === "closed");
	const isNew = !isClosed && daysSincePublished < 7;

	return (
		<Fade in={show} unmountOnExit>
			<Grid item key={offer.offerId} xs={12} sm={6} md={4} style={{ width: "100%", ...style }}>
				<MediaCard 
					className="offer-box"
					title={offer.title} 
					image={offer.coverImage}
					linkStyle={{ height: 250 }}
					link={`/offers/${offer.offerId}`}
					ribbon={isNew ? "New" : isClosed ? "Closed" : daysTillClosing < 7 && "Closes Soon"}
					ribbonStatus={isNew ? "success" : isClosed ? "danger" : "warning"}
				>
					{/* Status */}
					<span className={`label label-light-${offer.status === "open" ? "success" : "danger"}`}>{offer.status}</span>
					<br />
					{/* Tags */}
					{map(offer.tags, (tag, i) => <Tag key={i} label={tag.name} />)}
					{/* Dates */}
					<div className="offer-dates">
						<Tooltip title={lastUpdate.toLocaleDateString("en-AU")}>
							<div style={{ float: "left" }}>
								<small className="text-muted">
									{offer.lastUpdate ? "Updated" : "Published"} {distanceInWords(today, lastUpdate)} ago
								</small>
							</div>
						</Tooltip>
						{!isClosed &&
							<Tooltip title={new Date(offer.expiryDate).toLocaleDateString("en-AU")}>
								<div style={{ float: "right" }}>
									<small className="text-muted">
										Expires in {distanceInWords(today, new Date(offer.expiryDate))}
									</small>
								</div>
							</Tooltip>
						}
					</div>
				</MediaCard>
			</Grid>
		</Fade>
	);
});

OfferBox.propTypes = {
	offer: PropTypes.shape({
		offerId: PropTypes.number,
		title: PropTypes.string,
		status: PropTypes.string,
		category: PropTypes.string,
		coverImage: PropTypes.string,
		ownerName: PropTypes.string,
		ownerAvatar: PropTypes.string,
		datePublished: PropTypes.string,
		lastUpdate: PropTypes.string,
		expiryDate: PropTypes.string,
		description: PropTypes.string,
		requirements: PropTypes.string,
		views: PropTypes.number,
		tags: PropTypes.array
	}).isRequired,
	isAdmin: PropTypes.bool.isRequired,
	show: PropTypes.bool,
	style: PropTypes.object
};

OfferBox.defaultProps = {
	show: true,
	style: {}
};

export default OfferBox;