import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { get } from '../../utils/ajax';
import orderBy from 'lodash/orderBy';

const TopCompetencies = ({ organisationId, departmentId, by, setLoading, onChangeDepartment, colourPalette }) => {
	const [data, setData] = React.useState([]);

	const loadData = () => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/top-competencies?organisationId=${organisationId}&departmentId=${departmentId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (organisationId) {
			loadData();
		}
	}, [organisationId, departmentId]);

	const chartData = orderBy(data, "competencyTotal");

	return (
		<ReactEcharts 
			option={{
				color: colourPalette,
				legend: { show: false },
				tooltip: {},
				grid: { containLabel: true },
				yAxis: {
					type: "category", 
					data: chartData.map(d => d.unitTitle)
				},
				xAxis: {
					type : "value",
					axisLabel: { formatter: "{value}" }
				},
				series: [
					{
						type: "bar", 
						name: "Skill Level", 
						data: chartData.map(d => ({
							value: d.competencyTotal,
							itemStyle: {
								color: `#${d.colour}`
							}
						}))
					}
				]
			}}
			style={{ height: "100%" }} 
		/>
	);
};

TopCompetencies.propTypes = {
	organisationId: PropTypes.number.isRequired,
	departmentId: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired,
	onChangeDepartment: PropTypes.func.isRequired,
	by: PropTypes.string.isRequired,
	colourPalette: PropTypes.array
};

TopCompetencies.defaultProps = {
	colourPalette: undefined
};

export default TopCompetencies;