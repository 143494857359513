import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewAcademic from './ViewAcademic';
import { getAcademic, isLoading } from '../../store/academics';
import { canEditAcademic } from '../../store/security';

const ViewAcademicController = props => {
	React.useEffect(() => props.getAcademic(props.match.params.academicId), [props.match.params.academicId]);

	return <PageLayout title="View Academic" pageContent={
		<ViewAcademic academic={props.academic} loading={props.loading} canEdit={props.canEdit} />
	} breadcrumbs={[{ label: "Academics", path: "/academics" }, "View Academic"]} />;
};

ViewAcademicController.propTypes = {
	academic: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	academic: state.academics.academic,
	loading: isLoading(state),
	canEdit: canEditAcademic(state)
});

const mapDispatchToProps = dispatch => ({
	getAcademic: academicId => dispatch(getAcademic(academicId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAcademicController);