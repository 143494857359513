import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, IconButton } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

const  PagePagination = (props) => 
	(props.pageNum && props.pageSize) ?
	<div className="pagination-container" style={{ marginTop: '10px' }}>
		{props.showCount && 
			<div style={{ textAlign: 'center' }}>
				{props.total > 0 && `Showing ${(props.pageNum - 1) * props.pageSize + 1} - ${Math.min(props.pageNum * props.pageSize, props.total)} of ${props.total} ${props.pluralItemName}`}
				{props.total < 1 && `0 ${props.pluralItemName}`}
			</div>
		}
		<div className="pagination justify-content-center" style={{ marginTop: 0 }}>
			<span style={{ padding: '5px 5px 5px 0px' }}>Rows per page:</span>
			<Select
				value={props.pageSize}
				label="Rows per page"
				onChange={e => {
					const newPageSize = parseInt(e.target.value, 10);
					props.onChangeRowsPerPage(newPageSize);
				}}
				fullWidth={false}
				style={{ width: '50px' }}
				disableUnderline={true}

			>
				<MenuItem value={25}>25</MenuItem>
				<MenuItem value={50}>50</MenuItem>
				<MenuItem value={100}>100</MenuItem>
			</Select>
			<IconButton
                style={{ padding: '0 6px' }}
                disabled={props.pageNum === 1}
                onClick={() => props.onChangePage(1)}
                size="large">
				<FirstPageIcon fontSize="small" />
			</IconButton>
			<Pagination 
				count={props.totalPages} 
				page={props.pageNum} 
				onChange={(e, page) => {
					props.onChangePage(page);
				}}
			/>
			<IconButton
                style={{ padding: '0 6px' }}
                disabled={props.pageNum === props.totalPages}
                onClick={() => props.onChangePage(props.totalPages)}
                size="large">
				<LastPageIcon fontSize="small" />
			</IconButton>
		</div>
	</div>
	:
	<div></div>
;

PagePagination.propTypes = {
	pluralItemName: PropTypes.string,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	total: PropTypes.number,
	totalPages: PropTypes.number,
	onChangePage: PropTypes.func.isRequired,
	onChangeRowsPerPage: PropTypes.func.isRequired,
	showCount: PropTypes.bool
};

PagePagination.defaultProps = {
	pluralItemName: "Records",
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null,
	showCount: true
};

export default PagePagination;
