import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewLDCourse from './ViewLDCourse';
import axios from 'axios';
import { get } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { changeLocation } from '../../store/location';

const defaultCourse = {
	code: "",
	title: "",
	description: "",
	objectives: "",
	tools: [],
	resources: [],
	sessions: []
};

const ViewLDCourseController = props => {
	const [state, setState] = React.useState({
		isLoading: false,
		ldCourse: defaultCourse
	});

	React.useEffect(() => {
		axios.all([
			get({
				url: `/api/ld-courses/${props.match.params.id}`,
				onSuccess: data => setState(prevState => ({ ...prevState, ldCourse: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	}, [props.match.params.id]);

	return <PageLayout title="View L&amp;D Course" pageContent={
		<ViewLDCourse
			ldCourse={state.ldCourse}
			loading={state.isLoading} 
		/>
	} breadcrumbs={[{ label: "L&D Courses",  path: "/course-design" }, "View L&D Course"]} />;
};

ViewLDCourseController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	promptDeleteLDCourse: PropTypes.func.isRequired,
	goToLDCoursesPage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	organisationId: state.context.organisationId
});

const mapDispatchToProps = dispatch => ({
	goToLDCoursesPage: () => dispatch(changeLocation('/course-design')),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewLDCourseController);
