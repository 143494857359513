import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import Grid from '@mui/material/Grid';
import MailIcon from '@mui/icons-material/Mail';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Tag from '../widgets/Tag';
import MediaCard from '../widgets/MediaCard';
import { LinkedInButton } from '../widgets/SocialMediaButtons';
import map from 'lodash/map';

const AcademicCard = React.memo(({ academic }) => 
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<MediaCard className="academic-card" image={academic.avatar || "/images/icon/user-avatar.svg"} imageStyle={{ height: 60, width: 60, alignSelf: "center", marginTop: "1.25rem", borderRadius: 0 }}>
			<Link to={`/academics/${academic.academicId}`}><span className="h3">{academic.fullName}</span></Link>
			<span className="organisation">
				{academic.organisationLogo && <img src={academic.organisationLogo} alt={`${academic.organisationName} Logo`} />}
				<strong>{academic.organisationName}</strong>
			</span>
			<p>{academic.faculty}</p>
			<div className="body">
				{map(academic.tags, (t, i) => <Tag key={i} label={t.name} />)}
			</div>
			<div className="action-icons">
				<a href={`/academics/${academic.academicId}`}><MailIcon /></a>
				<a href={`/academics/${academic.academicId}`}><PersonAddIcon /></a>
				{academic.linkedInUrl && <LinkedInButton length={35} link={academic.linkedInUrl} />}
			</div>
		</MediaCard>
	</Grid>
);

AcademicCard.propTypes = {
	academic: PropTypes.shape({
		academicId: PropTypes.number,
		organisationId: PropTypes.number,
		organisationName: PropTypes.string,
		country: PropTypes.string,
		organisationType: PropTypes.string,
		organisationLogo: PropTypes.string,
		fullName: PropTypes.string,
		email: PropTypes.string,
		role: PropTypes.string,
		faculty: PropTypes.string,
		businessUnit: PropTypes.string,
		qualifications: PropTypes.string,
		programs: PropTypes.string,
		researchInterests: PropTypes.string,
		professionalSummary: PropTypes.string,
		institutionProfileUrl: PropTypes.string,
		programEnrolled: PropTypes.string,
		graduationYear: PropTypes.number,
		phone: PropTypes.string,
		privateEmail: PropTypes.string,
		linkedInUrl: PropTypes.string,
		icon: PropTypes.string,
		channelId: PropTypes.number,
		avatar: PropTypes.string,
		tags: PropTypes.array
	}).isRequired
};

export default AcademicCard;