import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getQueryParameters } from '../../utils/utils';
import { withForm } from '../../utils/forms';
import PageLayout from '../PageLayout';
import ReportCard from './ReportCard';
import map from 'lodash/map';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';

const form = {
	initValues: props => props.searchArgs,
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: <SearchIcon />
		},
		{
			name: "category",
			label: "Filter by Category",
			type: "autoComplete",
			items: props => uniqBy(map(props.reports, r => ({ label: r.category, value: r.category })), "value"),
			defaultItem: { label: "(All)", value: "" }
		}
	]
}

const ReportsController = ({ reports, fields, values }) => {
	const filtered = reports.filter(r => r.name.toLowerCase().includes(values.search) && 
		(values.category ? r.category === values.category : true));

	return (
        <PageLayout title="Reports" pageContent={
            <React.Fragment>
                <Grid container spacing={3} justifyContent="space-between" alignItems="flex-end">
                    <Grid item xs={12} sm={8} md={6}>{fields.search()}</Grid>
                    <Grid item xs={12} sm={4} md={3}>{fields.category()}</Grid>
                </Grid>
                <Grid container spacing={3}>
                    {map(filtered, (r, i) => <ReportCard key={i} report={r} />)}
                </Grid>
            </React.Fragment>
        } breadcrumbs={["Reports"]} />
    );
};

ReportsController.propTypes = { 
	reports: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect((state, ownProps) => ({
	reports: state.settings.reports || [],
	searchArgs: pick(getQueryParameters(ownProps.location.search), "search", "country")
}), null)(withForm(form)(ReportsController));