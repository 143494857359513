import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	viewPageContainer: {
		'& .view-page-title-container': {
			'& .view-page-title': {
				'& h2': {
					color: theme.palette.primary.main
				}
			}
		}
	}
});

const ViewPageContainer = props => {
	const { className, title, titleRightContent, children, classes } = props;

	return (
		<div className={`view-page-container ${className} ${classes.viewPageContainer}`}>
			{(title || titleRightContent) &&
				<div className="view-page-title-container">
					<div className="view-page-title">
						<h2>{title}</h2>
					</div>
					{titleRightContent}
				</div>
			}
			{children}
		</div>
	);
};

ViewPageContainer.propTypes = {
	className: PropTypes.string,
	title: PropTypes.node,
	titleRightContent: PropTypes.node,
};

ViewPageContainer.defaultProps = {
	className: "",
	title: undefined,
	titleRightContent: undefined
};


export default withStyles(ViewPageContainer, styles);
