import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import DescriptionIcon from '@mui/icons-material/Description';
import PrintIcon from '@mui/icons-material/Print';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import CourseDesignIcon from '@mui/icons-material/Create';
import PodcastIcon from '@mui/icons-material/RecordVoiceOver';
import ImageIcon from '@mui/icons-material/Image';
import WrittenMaterialIcon from '@mui/icons-material/Description';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import Button from '../widgets/Button';
import ViewPageContainer from '../widgets/ViewPageContainer';
import HtmlDisplayField from '../widgets/HtmlDisplayField';
import Tag from '../widgets/Tag';

const attachmentIcons = {
	document: InsertDriveFileIcon,
	"Course Brochure": CallToActionIcon, 
	"Content Description": DescriptionIcon, 
	"Non-Disclosure Agreement": AssignmentLateIcon, 
	"License Agreement": AssignmentTurnedInIcon
};

const contentTypeIcons = {
	"Course Design": CourseDesignIcon, 
	"Video": VideoIcon, 
	"Podcast": PodcastIcon, 
	"Written material": WrittenMaterialIcon, 
	"Images": ImageIcon
};

const ContentAttachment = ({ contentId, attachment: a }) => {
	const Icon = attachmentIcons[a.attachmentType];

	return (
		<Card className="content-attachment" onClick={() => window.open(`/Content/DownloadAttachment?contentId=${contentId}&attachmentId=${a.contentAttachmentId}`, "_self")}>
			<span className="h4">{a.name}</span>
			<Icon />
			<span className="h6">Download Now</span>
		</Card>
	);
};

const ContentSummary = ({ contentSummary }) => {
	const { title, contentType, summaryDescription } = contentSummary;
	const Icon = contentTypeIcons[contentType];

	return (
		<Card
			title={
				<React.Fragment>
					<Icon
						style={{
							marginRight: '5px',
							verticalAlign: 'middle'
						}}
					/>
					<span>{title}</span>
				</React.Fragment>
			}
			style={{ height: '100%' }}
		>
			<HtmlDisplayField value={summaryDescription} />
		</Card>
	);
};

const ViewContent = props => {
	const { content } = props;
	
	return props.loading	 || isEmpty(content) ? <CircularProgress size={24} /> : (
		<ViewPageContainer
			className="view-content"
			title={
				<React.Fragment>
				{content.title}{content.businessProfileTitle ? ` - ${content.businessProfileTitle}` : ""}
				{content.accredited && props.accreditedLogo &&
					<img src={props.accreditedLogo} alt="Accredited" style={{ maxHeight: 25, marginLeft: 10 }} />
				}
				</React.Fragment>
			}
			titleRightContent={
				<React.Fragment>
					{content.businessProfileLogo &&
						<img src={content.businessProfileLogo} alt={content.businessProfileTitle} style={{ maxHeight: 37, marginRight: 10 }} />
					}
					{props.canViewBusinessProfiles && content.businessProfileId &&
						<ButtonLink to={`/business-profiles/${content.businessProfileId}`} color="secondary" style={{ marginRight: 5 }}>
							View provider
						</ButtonLink>
					}
					<Button onClick={window.print}>
						<PrintIcon style={{ marginRight: 5}} />Print Resource Bundle
					</Button>
				</React.Fragment>
			}
		>
		<div className="tags">
			<Tag label={content.areaCategory} colour={props.primaryColour} style={{ marginRight: 5 }} />
			{map(content.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
		<Card className="content-details">
			<HtmlDisplayField 
				value={!content.description ? "No description provided." : content.description}
			/>
		</Card>

		<div style={{ marginBottom: "30px" }}>
			<span className="h3">Resource Bundle Summary</span>
			<Grid container spacing={3}>
				{map(content.contentSummary, (s, i) =>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<ContentSummary
							key={`content-summary-${i}`}
							contentSummary={s}
						/>
					</Grid>
				)}
			</Grid>
		</div>

		{content.attachments && content.attachments.length > 0 &&
			<React.Fragment>
				<span className="h3">Attachments</span>
				<Grid container spacing={3}>
					{content.attachments.map((a, i) => 
						<Grid key={i} item xs={4}>
							<ContentAttachment contentId={content.contentId} attachment={a} />
						</Grid>
					)}
				</Grid>
			</React.Fragment>
		}

		{props.canEdit && 
			<Grid item xs={12}>
				<ButtonLink to={`/resource-catalogue/edit/${content.contentId}`} style={{ marginTop: 20 }}>
					Edit Resource Bundle
				</ButtonLink>
			</Grid>
		}
		</ViewPageContainer>
	);
};

ViewContent.propTypes = {
	content: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canViewBusinessProfiles: PropTypes.bool.isRequired,
	accreditedLogo: PropTypes.string.isRequired,
	primaryColour: PropTypes.string.isRequired
};

export default ViewContent;