import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import filter from 'lodash/filter';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	problem: {}, 
	problems: [],
	endorsements: [],
	views: [],
	isVoting: {}
};

const newProblem = {
	problemId: 0
};

const CREATE_NEW_PROBLEM = "CREATE_NEW_PROBLEM";
const CLEAR_PROBLEM = "CLEAR_PROBLEM";
const CLEAR_PROBLEMS = "CLEAR_PROBLEMS";
const GET_PROBLEM_REQUEST = "GET_PROBLEM_REQUEST";
const GET_PROBLEM_SUCCESS = "GET_PROBLEM_SUCCESS";
const GET_PROBLEM_FAILURE = "GET_PROBLEM_FAILURE";
const GET_PROBLEMS_REQUEST = "GET_PROBLEMS_REQUEST";
const GET_PROBLEMS_SUCCESS = "GET_PROBLEMS_SUCCESS";
const GET_PROBLEMS_FAILURE = "GET_PROBLEMS_FAILURE";
const SAVE_PROBLEM_REQUEST = "SAVE_PROBLEM_REQUEST";
const SAVE_PROBLEM_SUCCESS = "SAVE_PROBLEM_SUCCESS";
const SAVE_PROBLEM_FAILURE = "SAVE_PROBLEM_FAILURE";
const DELETE_PROBLEM_REQUEST = "DELETE_PROBLEM_REQUEST";
const DELETE_PROBLEM_SUCCESS = "DELETE_PROBLEM_SUCCESS";
const DELETE_PROBLEM_FAILURE = "DELETE_PROBLEM_FAILURE";
const ARCHIVE_PROBLEM_REQUEST = "ARCHIVE_PROBLEM_REQUEST";
const ARCHIVE_PROBLEM_SUCCESS = "ARCHIVE_PROBLEM_SUCCESS";
const ARCHIVE_PROBLEM_FAILURE = "ARCHIVE_PROBLEM_FAILURE";
const VOTE_PROBLEM_REQUEST = "VOTE_PROBLEM_REQUEST";
const VOTE_PROBLEM_SUCCESS = "VOTE_PROBLEM_SUCCESS";
const VOTE_PROBLEM_FAILURE = "VOTE_PROBLEM_FAILURE";
const GET_ENDORSEMENTS_REQUEST = "GET_ENDORSEMENTS_REQUEST";
const GET_ENDORSEMENTS_SUCCESS = "GET_ENDORSEMENTS_SUCCESS";
const GET_ENDORSEMENTS_FAILURE = "GET_ENDORSEMENTS_FAILURE";
const GET_VIEWS_REQUEST = "GET_VIEWS_REQUEST";
const GET_VIEWS_SUCCESS = "GET_VIEWS_SUCCESS";
const GET_VIEWS_FAILURE = "GET_VIEWS_FAILURE";

export const isLoading = createLoadingSelector(["GET_PROBLEMS", "GET_PROBLEM", "DELETE_PROBLEM", "ARCHIVE_PROBLEM",
	"SAVE_PROBLEM", "SEARCH_PROBLEMS", "GET_ENDORSEMENTS", "GET_VIEWS"]);

export const createNewProblem = () => ({ type: CREATE_NEW_PROBLEM });
export const clearProblems = () => ({ type: CLEAR_PROBLEMS });
export const clearProblem = () => ({ type: CLEAR_PROBLEM });

export const getProblems = () =>
	createFetchAction({
		url: "/api/problems",
		startAction: GET_PROBLEMS_REQUEST,
		onError: error => [getProblemsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getProblemsSuccess(data)
	});

export const getPopularProblems = () =>
	createFetchAction({
		url: "/api/problems/popular",
		startAction: GET_PROBLEMS_REQUEST,
		onError: error => [getProblemsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getProblemsSuccess(data)
	});

export const getProblemsByAuthor = authorId =>
	createFetchAction({
		url: `/api/problems/author/${authorId}`,
		startAction: GET_PROBLEMS_REQUEST,
		onError: error => [getProblemsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getProblemsSuccess(data)
	});

export const getProblemsSuccess = data => ({ type: GET_PROBLEMS_SUCCESS, payload: { data } });
export const getProblemsFailure = error => ({ type: GET_PROBLEMS_FAILURE, payload: { error } });

export const getProblem = (problemId, onSuccess) =>
	createFetchAction({
		url: `/api/problems/${problemId}`,
		startAction: GET_PROBLEM_REQUEST,
		onError: error => [getProblemFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getProblemSuccess(data);
		}
	});

export const getProblemSuccess = data => ({ type: GET_PROBLEM_SUCCESS, payload: { data } });
export const getProblemFailure = error => ({ type: GET_PROBLEM_FAILURE, payload: { error } });

export const saveProblem = (problem, newFiles, isCommunity) => {
	const errors = [];

	if (!problem.title) {
		errors.push({
			fieldName: "Title",
			valid: false,
			message: "Title is required"
		});
	}

	if (errors.length > 0) return { type: SAVE_PROBLEM_SUCCESS, data: errors };

	const formData = new FormData();
	formData.append("Problem", encodeURIComponent(JSON.stringify(problem)));
	newFiles.forEach(f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/problems",
		data: formData,
		startAction: SAVE_PROBLEM_REQUEST,
		onError: error => [saveProblemFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveProblemSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}problems/${data.object.problemId}`)];
			} else {
				return [saveProblemSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveProblemSuccess = data => ({ type: SAVE_PROBLEM_SUCCESS, data });
export const saveProblemFailure = error => ({ type: SAVE_PROBLEM_FAILURE, error });

export const deleteProblem = (problemId, isCommunity) =>
	createPostAction({
		url: `/api/problems/${problemId}/delete`,
		// data: problem,
		startAction: DELETE_PROBLEM_REQUEST,
		onError: error => [deleteProblemFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteProblemSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}problems`)];
			} else {
				return [deleteProblemSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteProblemSuccess = data => ({ type: DELETE_PROBLEM_SUCCESS, data });
export const deleteProblemFailure = error => ({ type: DELETE_PROBLEM_FAILURE, error });

export const archiveProblem = (problemId, isCommunity) =>
	createPostAction({
		url: `/api/problems/${problemId}/archive`,
		startAction: ARCHIVE_PROBLEM_REQUEST,
		onError: error => [archiveProblemFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [archiveProblemSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}problems`)];
			} else {
				return [archiveProblemSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const archiveProblemSuccess = data => ({ type: ARCHIVE_PROBLEM_SUCCESS, data });
export const archiveProblemFailure = error => ({ type: ARCHIVE_PROBLEM_FAILURE, error });

export const voteProblem = problemId =>
	createPostAction({
		url: `/api/problems/${problemId}/vote`,
		startAction: VOTE_PROBLEM_REQUEST,
		startActionData: { problemId },
		onError: error => [voteProblemFailure(error, problemId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [voteProblemSuccess(data, problemId), showSuccessNotification(data.message)];
			} else {
				return [voteProblemSuccess(data, problemId), showErrorNotification(data.message)];
			}
		}
	});

export const removeVoteFromProblem = problemId =>
	createPostAction({
		url: `/api/problems/${problemId}/remove-vote`,
		startAction: VOTE_PROBLEM_REQUEST,
		startActionData: { problemId },
		onError: error => [voteProblemFailure(error, problemId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [voteProblemSuccess(data, problemId), showSuccessNotification(data.message)];
			} else {
				return [voteProblemSuccess(data, problemId), showErrorNotification(data.message)];
			}
		}
	});

export const voteProblemSuccess = (data, problemId) => ({ type: VOTE_PROBLEM_SUCCESS, data, problemId });
export const voteProblemFailure = (error, problemId) => ({ type: VOTE_PROBLEM_FAILURE, error, problemId });

const SEARCH_PROBLEMS_REQUEST = "SEARCH_PROBLEMS_REQUEST";
const SEARCH_PROBLEMS_SUCCESS = "SEARCH_PROBLEMS_SUCCESS";
const SEARCH_PROBLEMS_FAILURE = "SEARCH_PROBLEMS_FAILURE";

export const searchProblems = (search, tag, subscribedOnly) =>
	createFetchAction({
		url: `/api/problems/search?search=${encodeURIComponent(search)}&tag=${encodeURIComponent(tag || "")}&subscribedOnly=${Boolean(subscribedOnly)}`,
		startAction: SEARCH_PROBLEMS_REQUEST,
		onError: error => [searchProblemsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchProblemsSuccess(data)
	});

export const searchProblemsSuccess = data => ({ type: SEARCH_PROBLEMS_SUCCESS, payload: { data } });
export const searchProblemsFailure = error => ({ type: SEARCH_PROBLEMS_FAILURE, payload: { error } });

export const getEndorsements = problemId =>
	createFetchAction({
		url: `/api/problems/${problemId}/endorsements`,
		startAction: GET_ENDORSEMENTS_REQUEST,
		onError: error => [getEndorsementsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEndorsementsSuccess(data)
	});

export const getEndorsementsSuccess = data => ({ type: GET_ENDORSEMENTS_SUCCESS, payload: { data } });
export const getEndorsementsFailure = error => ({ type: GET_ENDORSEMENTS_FAILURE, payload: { error } });

export const getViews = problemId =>
	createFetchAction({
		url: `/api/problems/${problemId}/views`,
		startAction: GET_VIEWS_REQUEST,
		onError: error => [getViewsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getViewsSuccess(data)
	});

export const getViewsSuccess = data => ({ type: GET_VIEWS_SUCCESS, payload: { data } });
export const getViewsFailure = error => ({ type: GET_VIEWS_FAILURE, payload: { error } });

export const getSubscribedProblems = (search, limit) => 
	createFetchAction({
		url: `/api/problems/subscribed?search=${encodeURIComponent(search || "")}&limit=${limit}`,
		startAction: GET_PROBLEMS_REQUEST,
		onError: error => [getProblemsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getProblemsSuccess(data)
	});

const sanitizeProblem = problem => ({ 
	...problem, 
	description: DOMPurify.sanitize(problem.description),
	solution: DOMPurify.sanitize(problem.solution)
});

export default (state = initialState, action) => {
	const { success, message, fields, object, problemId } = (action.data || {});

	switch (action.type) {
		case CLEAR_PROBLEMS:
			return { ...state, problems: [] };
		case CLEAR_PROBLEM:
			return { ...state, problem: {} };
		case GET_PROBLEMS_REQUEST:
			return { ...state, problems: [], saveResult: emptySaveResult };
		case GET_PROBLEMS_SUCCESS:
			return { ...state, problems: map(action.payload.data, sanitizeProblem), isLoading: false };
		case GET_PROBLEM_REQUEST:
			return {
				...state,
				problem: { ...newProblem },
				isLoading: true,
				saveResult: emptySaveResult
			};
		case GET_PROBLEM_SUCCESS:
			return { ...state, problem: sanitizeProblem(action.payload.data), isLoading: false };
		case DELETE_PROBLEM_SUCCESS:
			return { ...state, problems: filter(state.problems, c => c.problemId !== action.data.objectId) };
		case ARCHIVE_PROBLEM_SUCCESS:
			return { ...state, problems: filter(state.problems, c => c.problemId !== action.data.objectId) };
		case CREATE_NEW_PROBLEM:
			return { ...state, problem: { ...newProblem } };	
		case SAVE_PROBLEM_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_PROBLEM_SUCCESS:
			return {
				...state,
				...(success && { problems: addOrUpdate(state.problems, sanitizeProblem(object), { problemId: object.problemId }) }), 
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case VOTE_PROBLEM_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				message: null,
				isVoting: { ...state.isVoting, [problemId]: true }
			};
		case VOTE_PROBLEM_FAILURE:
			return {
				...state,
				saveResult: emptySaveResult,
				isVoting: {...state.isVoting, [action.problemId]: false}
			};
		case VOTE_PROBLEM_SUCCESS:
			const { totalVotes: votes, hasVoted } = action.data;

			return {
				...state,
				...(success && {
					problems: map(state.problems, p => p.problemId === problemId ? { ...p, votes, hasVoted } : p), 
					problem: state.problem.problemId === problemId ? { ...state.problem, votes, hasVoted } : state.problem,
				}),
				isVoting: { ...state.isVoting, [problemId]: false },
				saveResult: { success, message }
			};
		case SEARCH_PROBLEMS_REQUEST:
			return { ...state, problems: [], saveResult: emptySaveResult };
		case SEARCH_PROBLEMS_SUCCESS:
			return { ...state, problems: map(action.payload.data, sanitizeProblem), isLoading: false };
		case GET_ENDORSEMENTS_REQUEST:
			return { ...state, endorsements: [] };
		case GET_ENDORSEMENTS_SUCCESS:
			return { ...state, endorsements: action.payload.data, isLoading: false };
		case GET_VIEWS_REQUEST:
			return { ...state, views: [] };
		case GET_VIEWS_SUCCESS:
			return { ...state, views: action.payload.data, isLoading: false };
		default:
			return state;
	}
};
