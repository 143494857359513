import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import EditTheme from './EditTheme';
import { post } from '../../utils/ajax';
import { emptySaveResult } from '../../variables';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { changeLocation } from '../../store/location';

const AddThemeController = props => {
	const [state, setState] = React.useState({ isLoading: false, saveResult: emptySaveResult });

	const saveTheme = (theme) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		
		post({
			url: "/api/application-themes",
			data: theme,
			onSuccess: data => {
				setState({ saveResult: data, isLoading: false });

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToEditUrl(data.object);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	return (
		<PageLayout 
			title="Add Theme" 
			pageContent={
				<EditTheme
					canEdit
					theme={{...props.currentTheme}}
					onSave={saveTheme} 
					saveResult={state.saveResult}
					loading={state.isLoading}
					isNew
				/>
			} 
			breadcrumbs={[
				{ label: "Themes",  path: "/ui-themes" }, 
				"Add Theme"
			]} 	
		/>
	);

};

AddThemeController.propTypes = { 
	canSelectTenant: PropTypes.bool.isRequired,
	goToEditUrl: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	currentTheme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	currentTheme: state.theme
});

const mapDispatchToProps = dispatch => ({
	goToEditUrl: theme => dispatch(changeLocation(`/ui-themes/${theme.applicationThemeId}`)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddThemeController);
