import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContent, saveContent, deleteContent, copyContent, isLoading } from '../../store/content';
import { canViewBusinessProfiles } from '../../store/security';
import { showModal } from '../../store/modal';
import { get } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import EditContent from './EditContent';
import { canAdminContent } from '../../store/security';

const EditContentController = props => {
	const [organisations, setOrganisations] = React.useState([]);

	React.useEffect(() => props.getContent(props.match.params.contentId), [props.match.params.contentId]);

	React.useEffect(() => {
		get({
			url: "/api/organisations?type=Organisation",
			onSuccess: data => setOrganisations(data.map(o => ({ label: o.name, value: o.organisationId })))
		});
	}, []);

	return <PageLayout title="Edit Resource Bundle" pageContent={
		<EditContent
			content={props.content}
			saveContent={props.saveContent}
			deleteContent={props.deleteContent}
			copyContent={props.copyContent}
			loading={props.loading}
			organisationId={props.organisationId}
			organisations={organisations}
			canAdminContent={props.canAdminContent}
			canViewBusinessProfiles={props.canViewBusinessProfiles}
			errorColour={props.errorColour}
		/>
	} breadcrumbs={[{ label: "Resource Catalogue", path: "/resource-catalogue" }, "Edit Resource Bundle"]} />;
};

EditContentController.propTypes = {
	content: PropTypes.object.isRequired,
	saveContent: PropTypes.func.isRequired,
	deleteContent: PropTypes.func.isRequired,
	copyContent: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	canViewBusinessProfiles: PropTypes.bool.isRequired,
	canAdminContent: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	content: state.content.content,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canViewBusinessProfiles: canViewBusinessProfiles(state),
	canAdminContent: canAdminContent(state),
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getContent: contentId => dispatch(getContent(contentId)),
	saveContent: (content, newFiles, thumbnail) => dispatch(saveContent(content, newFiles, thumbnail)),
	deleteContent: contentId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this Resource Bundle?",
		onOk: () => dispatch(deleteContent(contentId))
	})),
	copyContent: (contentId, title) => dispatch(copyContent(contentId, title))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditContentController);
