import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const WizardStep = ({ number, title, current, complete, disabled, error, isLastStepDone }) => {
	const cls = classNames({
		'step': true,
		'current': current,
		'complete': complete,
		'is-last-step-done': isLastStepDone,
		'disabled': disabled,
		'error': error
	});
	return (
		<li className={cls}>
			<a>
				<span className='step-number'>{number}</span>
				{title}
			</a>
		</li>
	);
};

WizardStep.propTypes = {
	number: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	current: PropTypes.bool.isRequired,
	isLastStepDone: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	complete: PropTypes.bool.isRequired
};


export default WizardStep;
