import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Button from '../widgets/Button';
import Form from '../widgets/Form';

const Settings = ({ loading, fields, showMembershipLevelId, handleSubmit }) =>
	<div style={{ padding: "1.5rem 1.5rem 3rem" }}>
		<Form onSubmit={handleSubmit}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>{fields.userName()}</Grid>
				<Grid item xs={12} sm={6}>{fields.email()}</Grid>
				<Grid item xs={12} sm>{fields.firstName()}</Grid>
				<Grid item xs={12} sm>{fields.lastName()}</Grid>
				<Grid item xs="auto">{fields.avatar()}</Grid>
				<Grid item xs={12}>{fields.description()}</Grid>
				<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
				<Grid item xs={12} sm={6}>{fields.userOrganisation()}</Grid>
				<Grid item xs={12} sm={6}>{fields.departmentId()}</Grid>
				<Grid item xs={12} sm={6}>{fields.positionId()}</Grid>
				{showMembershipLevelId && <Grid item xs={12} sm={6}>{fields.membershipLevelId()}</Grid>}
				<Grid item xs={12} sm="auto">{fields.emailNotifications()}</Grid>
			</Grid>
			<Button type="submit" loading={loading} style={{ marginTop: 20 }}>Update profile</Button>
		</Form>
	</div>;

export default connect()(Settings);