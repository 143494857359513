import { createFetchAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	available: [],
	subscribed: [],
	community: {},
	themes: []
};

const CLEAR_USER_COMMUNITIES = "CLEAR_USER_COMMUNITIES";
const GET_USER_COMMUNITIES_REQUEST = "GET_USER_COMMUNITIES_REQUEST";
const GET_USER_COMMUNITIES_SUCCESS = "GET_USER_COMMUNITIES_SUCCESS";
const GET_USER_COMMUNITIES_FAILURE = "GET_USER_COMMUNITIES_FAILURE";
const SEARCH_USER_COMMUNITIES_REQUEST = "SEARCH_USER_COMMUNITIES_REQUEST";
const SEARCH_USER_COMMUNITIES_SUCCESS = "SEARCH_USER_COMMUNITIES_SUCCESS";
const SEARCH_USER_COMMUNITIES_FAILURE = "SEARCH_USER_COMMUNITIES_FAILURE";
const GET_USER_COMMUNITY_REQUEST = "GET_USER_COMMUNITY_REQUEST";
const GET_USER_COMMUNITY_SUCCESS = "GET_USER_COMMUNITY_SUCCESS";
const GET_USER_COMMUNITY_FAILURE = "GET_USER_COMMUNITY_FAILURE";
const SAVE_USER_COMMUNITY_REQUEST = "SAVE_USER_COMMUNITY_REQUEST";
const SAVE_USER_COMMUNITY_SUCCESS = "SAVE_USER_COMMUNITY_SUCCESS";
const SAVE_USER_COMMUNITY_FAILURE = "SAVE_USER_COMMUNITY_FAILURE";
const GET_USER_THEMES_REQUEST = "GET_USER_THEMES_REQUEST";
const GET_USER_THEMES_SUCCESS = "GET_USER_THEMES_SUCCESS";
const GET_USER_THEMES_FAILURE = "GET_USER_THEMES_FAILURE";
const REMOVE_CHANNEL_REQUEST = "REMOVE_CHANNEL_REQUEST";
const REMOVE_CHANNEL_SUCCESS = "REMOVE_CHANNEL_SUCCESS";
const REMOVE_CHANNEL_FAILURE = "REMOVE_CHANNEL_FAILURE";
const UPDATE_CHANNELS_REQUEST = "UPDATE_CHANNELS_REQUEST";
const UPDATE_CHANNELS_SUCCESS = "UPDATE_CHANNELS_SUCCESS";
const UPDATE_CHANNELS_FAILURE = "UPDATE_CHANNELS_FAILURE";

export const isLoading = createLoadingSelector([ 
	"GET_USER_COMMUNITIES", 
	"SEARCH_USER_COMMUNITIES",
	"GET_USER_COMMUNITY",
	"SUBSCRIBE_TO_COMMUNITY",
	"UNSUBSCRIBE_FROM_COMMUNITY",
	"GET_USER_THEMES",
	"REMOVE_CHANNEL"
]);

export const getUserCommunities = search =>
	createFetchAction({
		url: `/api/user-communities?search=${search ? search : ""}`,
		startAction: GET_USER_COMMUNITIES_REQUEST,
		onError: error => [getUserCommunitiesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserCommunitiesSuccess(data)
	});

export const getUserCommunitiesSuccess = data => ({ type: GET_USER_COMMUNITIES_SUCCESS, payload: { data } });
export const getUserCommunitiesFailure = error => ({ type: GET_USER_COMMUNITIES_FAILURE, payload: { error } });

export const searchUserCommunities = search => 
	createFetchAction({
		url: `/user-communities/search?search=${search}`,
		startAction: SEARCH_USER_COMMUNITIES_REQUEST,
		onError: error => [searchUserCommunitiesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchUserCommunitiesSuccess(data)
	})

export const searchUserCommunitiesSuccess = data => ({ type: SEARCH_USER_COMMUNITIES_SUCCESS, payload: { data } });
export const searchUserCommunitiesFailure = error => ({ type: SEARCH_USER_COMMUNITIES_FAILURE, payload: { error } });

export const getUserCommunity = communityId =>
	createFetchAction({
		url: `/api/user-communities/${communityId}`,
		startAction: GET_USER_COMMUNITY_REQUEST,
		onError: error => [getUserCommunityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserCommunitySuccess(data)
	});

export const getUserCommunitySuccess = data => ({ type: GET_USER_COMMUNITY_SUCCESS, payload: { data } });
export const getUserCommunityFailure = error => ({ type: GET_USER_COMMUNITY_FAILURE, payload: { error } });

export const saveUserCommunity = community => 
	createFormPostAction({
		url: "/api/user-communities",
		data: community,
		startAction: SAVE_USER_COMMUNITY_REQUEST,
		onError: error => [saveUserCommunityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveUserCommunitySuccess(data), showSuccessNotification(data.message), changeLocation("/communities")];
			} else {
				return [saveUserCommunitySuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveUserCommunitySuccess = data => ({ type: SAVE_USER_COMMUNITY_SUCCESS, data });
export const saveUserCommunityFailure = error => ({ type: SAVE_USER_COMMUNITY_FAILURE, error });

const SUBSCRIBE_TO_COMMUNITY_REQUEST = "SUBSCRIBE_TO_COMMUNITY_REQUEST";
const SUBSCRIBE_TO_COMMUNITY_SUCCESS = "SUBSCRIBE_TO_COMMUNITY_SUCCESS";
const SUBSCRIBE_TO_COMMUNITY_FAILURE = "SUBSCRIBE_TO_COMMUNITY_FAILURE";

export const subscribeToCommunity = (communityId, themes, onSuccess) => 
	createFormPostAction({
		url: "/api/user-communities/subscribe",
		data: { communityId, themes },
		startAction: SUBSCRIBE_TO_COMMUNITY_REQUEST,
		onError: error => [subscribeToCommunityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess();
				return [subscribeToCommunitySuccess(data), showSuccessNotification(data.message), changeLocation("/communities")];
			} else {
				return [subscribeToCommunitySuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const subscribeToCommunitySuccess = data => ({ type: SUBSCRIBE_TO_COMMUNITY_SUCCESS, data });
export const subscribeToCommunityFailure = error => ({ type: SUBSCRIBE_TO_COMMUNITY_FAILURE, error });

const UNSUBSCRIBE_FROM_COMMUNITY_REQUEST = "UNSUBSCRIBE_FROM_COMMUNITY_REQUEST";
const UNSUBSCRIBE_FROM_COMMUNITY_SUCCESS = "UNSUBSCRIBE_FROM_COMMUNITY_SUCCESS";
const UNSUBSCRIBE_FROM_COMMUNITY_FAILURE = "UNSUBSCRIBE_FROM_COMMUNITY_FAILURE";

export const unsubscribeFromCommunity = (communityId, onSuccess) => 
	createFormPostAction({
		url: `/api/user-communities/${communityId}/unsubscribe`,
		startAction: UNSUBSCRIBE_FROM_COMMUNITY_REQUEST,
		onError: error => [unsubscribeFromCommunityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess(data);
				return [unsubscribeFromCommunitySuccess(data), showSuccessNotification(data.message), changeLocation("/communities")];
			} else {
				return [unsubscribeFromCommunitySuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const unsubscribeFromCommunitySuccess = data => ({ type: UNSUBSCRIBE_FROM_COMMUNITY_SUCCESS, data });
export const unsubscribeFromCommunityFailure = error => ({ type: UNSUBSCRIBE_FROM_COMMUNITY_FAILURE, error });

export const removeChannel = (communityId, themeId, onSuccess) => 
	createFormPostAction({
		url: `/api/user-communities/${communityId}/remove-channel?themeId=${themeId}`,
		startAction: REMOVE_CHANNEL_REQUEST,
		onError: error => [removeChannelFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess();
				return [removeChannelSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [removeChannelSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const removeChannelSuccess = data => ({ type: REMOVE_CHANNEL_SUCCESS, data });
export const removeChannelFailure = error => ({ type: REMOVE_CHANNEL_FAILURE, error });

export const updateChannels = (communityId, themes, onSuccess) =>
	createFormPostAction({
		url: `/api/user-communities/${communityId}/update`,
		data: themes,
		startAction: UPDATE_CHANNELS_REQUEST,
		onError: error => [updateChannelsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				if (onSuccess) onSuccess();
				return [updateChannelsSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [updateChannelsSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const updateChannelsSuccess = data => ({ type: UPDATE_CHANNELS_SUCCESS, data });
export const updateChannelsFailure = error => ({ type: UPDATE_CHANNELS_FAILURE, error });

export const getUserThemes = () =>
	createFetchAction({
		url: "/api/user-communities/themes",
		startAction: GET_USER_THEMES_REQUEST,
		onError: error => [getUserThemesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserThemesSuccess(data)
	});

export const getUserThemesSuccess = data => ({ type: GET_USER_THEMES_SUCCESS, data });
export const getUserThemesFailure = error => ({ type: GET_USER_THEMES_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_USER_COMMUNITIES:
			return { ...state, available: [], subscribed: [] };
		case GET_USER_COMMUNITIES_REQUEST:
			return { ...state, available: [], subscribed: [], isLoading: true };
		case SEARCH_USER_COMMUNITIES_REQUEST:
			return { ...state, isLoading: true }
		case GET_USER_COMMUNITIES_SUCCESS:
		case SEARCH_USER_COMMUNITIES_SUCCESS:
			const { availableCommunities: available, subscribedCommunities: subscribed } = action.payload.data;

			return { ...state, available, subscribed, isLoading: false };
		case GET_USER_COMMUNITY_REQUEST:
			return { ...state, community: {}, isLoading: true }
		case GET_USER_COMMUNITY_SUCCESS:
			return { ...state, community: action.payload.data, isLoading: true };
		case SAVE_USER_COMMUNITY_REQUEST:
		case SUBSCRIBE_TO_COMMUNITY_REQUEST:
		case UNSUBSCRIBE_FROM_COMMUNITY_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_USER_COMMUNITY_SUCCESS:
			return {
				...state,
				...(action.data.success && { community: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case SUBSCRIBE_TO_COMMUNITY_SUCCESS:
		case UNSUBSCRIBE_FROM_COMMUNITY_SUCCESS:
			const { success, message, fields, object } = action.data;
			
			return {
				...state,
				isLoading: false,
				subscribed: object.subscribedCommunities,
				available: object.availableCommunities,
				saveResult: { success, message, fields }
			};
		case REMOVE_CHANNEL_SUCCESS:
		case UPDATE_CHANNELS_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				},
				subscribed: action.data.object
			};
		case GET_USER_THEMES_REQUEST:
			return { ...state, themes: [], isLoading: true };
		case GET_USER_THEMES_SUCCESS:
			return { ...state, themes: action.data, isLoading: false };
		default:
			return state;
	}
};
