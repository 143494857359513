import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import MediaCard from '../widgets/MediaCard';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import take from 'lodash/take';
import startsWith from 'lodash/startsWith';
import { truncateString } from '../../utils/utils';
import Rating from '@mui/material/Rating';
import PremiumLogoSrc from '../images/premium.png';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import WebsiteIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';

const BusinessProfileCard = React.memo(({ profile, accreditedLogo, canAdmin, onChangeRating }) =>{
	const bpLink = (profile.isSubscribed || canAdmin) ? `/business-profiles/${profile.businessProfileId}` : undefined;
	const MoreLink = bpLink ? <Link to={bpLink}>more...</Link> : <span>more...</span>;

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<MediaCard 
				link={bpLink}
				title={profile.title} 
				image={profile.logo} 
				className="business-profile-card"
			>
				{profile.isSubscribed &&
					<div style={{
							padding: '0 10px',
							height: '30px',
							lineHeight: '30px',
							clear: 'left',
							position: 'absolute',
							top: '6px',
							left: '-2px',
					}}>
						<img src={PremiumLogoSrc} height="50" className="logo" alt="" />
					</div>
				}

				<div className="body">{truncateString(profile.summary, 300)}</div>

				<div className="action-icons">
					<Tooltip title={profile.email}>
						<a href={`mailto:${profile.email}`}><MailIcon /></a>
					</Tooltip>
					<Tooltip title={profile.phone}>
						<a href={`tel:${profile.phone}`}><PhoneIcon /></a>
					</Tooltip>
					<Tooltip title={profile.website}>
						<a href={startsWith(profile.website, 'http') ? profile.website : `https://${profile.website}`} target="_blank" rel="noopener noreferrer"><WebsiteIcon /></a>
					</Tooltip>
				</div>			

				<div className="tags">
					{map(take(profile.areasOfExpertise, 3), (t, i) => <Tag key={i} label={t.name} />)}
					{profile.areasOfExpertise.length > 3 && MoreLink}
				</div>
				<div style={{ marginTop: "10px" }}>
					{profile.accredited &&
						<img 
							src='/images/logo/ILP logo CLO final.png' 
							alt="" 
							style={{ 
								width: "100px",
								height: "auto" 
							}} 
						/>
					}
					<Rating
						value={profile.rating}
						readOnly={!onChangeRating}
						onChange={(e, newValue) => {
							if (onChangeRating) onChangeRating(profile, newValue);
						}}
						style={{
							verticalAlign: "middle"
						}}
					/>
				</div>
			</MediaCard>
		</Grid>
	);
});

BusinessProfileCard.propTypes = {
	profile: PropTypes.object.isRequired,
	accreditedLogo: PropTypes.string,
	onChangeRating: PropTypes.func,
	canAdmin: PropTypes.bool
};

BusinessProfileCard.defaultProps = {
	accreditedLogo: null,
	onChangeRating: null,
	canAdmin: false
};

export default BusinessProfileCard;