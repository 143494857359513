import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from './Button';
import { toggleUserOrganisationDialog } from '../../store/layout';
import { withStyles } from 'tss-react/mui';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { withForm } from '../../utils/forms';
import Form from './Form';

const styles = (theme) => ({
	paper: {
		overflow: 'visible'
	}
});

const form = {
	fieldStyle: { marginBottom: 15 },
	fields: [
		{
			name: "userOrganisation",
			label: "Your affiliated organisation or institution",
			type: "autoComplete",
			loadItems: {
				route: "user-organisations",
				mapItem: uo => ({ label: uo.name, value: uo.name })
			},
			canCreate: true,
			formatCreateLabel: inputValue => inputValue,
			onCreateOption: (newValue, items, setItems, onChange) => {
				const newItem = { label: newValue, value: newValue };
				setItems([...items, newItem]);
				onChange(newItem);
			}
		}
	]
};

const UserOrganisationDialog = props => {
	const classes = props.classes;
	const [loading, setLoading] = React.useState(false);
	const { fields, values } = props;

	const onClose = () => {
		props.hideUserOrganisationDialog();
	};
	const onSubmit = () => {
		if (props.validateFields()) {
			post({
				url: `/api/users/set-user-organisation?userOrganisation=${values.userOrganisation}`,
				onSuccess: data => {
					setLoading(false);
	
					if (data.success) {
						props.showSuccessNotification(data.message);
						props.hideUserOrganisationDialog();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: error => {
					setLoading(false);
					props.showErrorNotification(error.message);
				}
			});
		} 
	}

	return (
		<Dialog fullWidth={true} open={props.show} onClose={onClose} style={{ zIndex: 9999 }} classes={classes}>
			<DialogTitle>Please enter your affiliated organisation or institution</DialogTitle>
			<DialogContent style={{ padding: '20px', overflow: 'visible' }}>
				<Form 
					onSubmit={onSubmit}
					style={{ textAlign: "center" }}
				>
					{fields.userOrganisation()}
				</Form>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
	 			<Button disabled={!values.userOrganisation} loading={loading} onClick={onSubmit}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

UserOrganisationDialog.propTypes = {
	show: PropTypes.bool,
	hideUserOrganisationDialog: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

UserOrganisationDialog.defaultProps = {
	show: false
};

const mapStateToProps = state => ({
	show: state.layout.showUserOrganisationDialog
});

const mapDispatchToProps = dispatch => ({
	hideUserOrganisationDialog: () => dispatch(toggleUserOrganisationDialog(false)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default withStyles(
	connect(mapStateToProps, mapDispatchToProps)(withForm(form)(UserOrganisationDialog)),
	styles
	);
