import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import { Grid, Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Card from '../widgets/Card';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';

const disabledTooltip = "Sorry, your subscription does not provide you access to this area. Contact us to enquire.";

const CommunityCard = React.memo(({ community, canEdit, communityProps: view }) => {
	const title = <h2 className="card-title">{community.name}</h2>;

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<Card className="community-card">
				{canEdit ? <Link to={`communities/edit/${community.communityId}`}>{title}</Link> : title}
				<div className="description">{community.description}</div>
				<Grid container spacing={1} className="counters">
					{/* Events */}
					<Grid item xs={6}>
						{view.events ? <ButtonLink to={`/events?community=${community.communityId}`}>Events (0)</ButtonLink> :
						<Tooltip title={disabledTooltip}>
							<span>
								<Button disabled><LockIcon style={{ marginRight: 5 }} />Events</Button>
							</span>
						</Tooltip>}
					</Grid>
					{/* Toolboxes */}
					<Grid item xs={6}>
						{view.tools ? <ButtonLink to={`/tools?community=${community.communityId}`}>Tools (0)</ButtonLink> :
						<Tooltip title={disabledTooltip}>
							<span>
								<Button disabled><LockIcon style={{ marginRight: 5 }} />Resources</Button>
							</span>
						</Tooltip>}
					</Grid>
					{/* Courses */}
					<Grid item xs={6}>
						{view.courses ? <ButtonLink to={`/courses?community=${community.communityId}`}>Courses (0)</ButtonLink> :
						<Tooltip title={disabledTooltip}>
							<span>
								<Button disabled><LockIcon style={{ marginRight: 5 }} />Courses</Button>
							</span>
						</Tooltip>}
					</Grid>
					{/* Discussion */}
					<Grid item xs={6}>
						{view.discussion ? <Button color="secondary">Discuss</Button> :
						<Tooltip title={disabledTooltip}>
							<span>
								<Button disabled><LockIcon style={{ marginRight: 5 }} />Discuss</Button>
							</span>
						</Tooltip>}
					</Grid>
				</Grid>
			</Card>
		</Grid>
	);
});

CommunityCard.propTypes = {
	community: PropTypes.shape({
		communityId: PropTypes.number,
		organisationId: PropTypes.number,
		organisationName: PropTypes.string,
		country: PropTypes.string,
		organisationType: PropTypes.string,
		organisationLogo: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string
	}).isRequired,
	canEdit: PropTypes.bool.isRequired
};

export default CommunityCard;