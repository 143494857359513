import React from 'react';
import LogoSrc from './images/logo/iEC professional logo.png';
import { connect } from 'react-redux';

const Footer = (props) => {
	let copyright = `© 2024 iEC Professional Pty Ltd. All rights reserved.`;

	if (props.tenantHasCopyright) {
		copyright = `© 2024 ${props.tenantTitle}. All rights reserved.`;;
		if (props.tenantCopyright) {
			copyright = props.tenantCopyright;
		}
	}
	return (
		<footer style={{ marginBottom: 0, textAlign: 'center' }} role="contentinfo">
			{props.showPoweredBy && <span>Powered by</span>}
			<img src={props.tenantHasCopyright ? (props.logo || LogoSrc) : LogoSrc} height="50" className="logo" alt="" style={{ padding: '10px' }} />
			<span>{copyright}</span>
		</footer>
	);
};

const mapStateToProps = state => ({
	showPoweredBy: state.settings.showPoweredBy
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);