import React from 'react';
import PropTypes from 'prop-types';
import { Grid, SnackbarContent } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { withForm } from '../../utils/forms';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Button from '../widgets/Button';
import map from 'lodash/map';

const form = {
	initValues: props => ({ isActive: true, tenantId: props.tenantId, organisationId: props.organisationId, positionId: 0, departmentId: 0, reportingDepartmentId: 0, ...props.user }),
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			loadItems: {
				route: "tenants", 
				mapItem: t => ({ label: t.name, value: t.tenantId })
			},
			onChange: values => {
				values.organisationId = null;
				values.departmentId = null;
				values.positionId = null;
			},
			disabled: props => !props.isNew
		},
		{
			name: "userName",
			label: "Username",
			validate: value => value.match(/^[a-zA-Z0-9_. ]+$/i) ? "" : "Username must contain no special characters",
			required: true
		},
		{
			name: "password",
			label: "Password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters",
			required: props => props.isNew
		},
		{
			name: "firstName",
			label: "First name",
			required: true
		},
		{
			name: "lastName",
			label: "Last name",
			required: true
		},
		{
			name: "email",
			label: "Email address",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid address",
			required: true
		},
		{
			name: "organisationId",
			label: "Site",
			type: "autoComplete",
			loadItems: {
				route: (props, values) => `organisations/by-tenant/${(props.canChangeTenant && values.tenantId) || props.tenantId}?type=Organisation`,
				mapItem: o => ({ label: o.name, value: o.organisationId, organisation: o })
			},
			required: true,
			onChange: (values, value) => {
				values.departmentId = value.organisation.defaultDepartmentId;
				values.reportingDepartmentId = 0;
				values.positionId = value.organisation.defaultPositionId;
				values.skillsProfileId = 0;
			}
		},
		{
			name: "userOrganisation",
			label: "User Organisation"
		},
		{
			name: "departmentId",
			label: "Business unit",
			type: "autoComplete",
			required: true,
			loadItems: {
				route: (props, values) => `departments/by-organisation/${values.organisationId || props.organisationId}`, 
				mapItem: d => ({ label: d.name, value: d.departmentId })
			},
			onChange: values => {
				if (values.reportingDepartmentId === 0) {
					values.reportingDepartmentId = values.departmentId;
				}
			}
		},
		{
			name: "reportingDepartmentId",
			label: "Reporting Business unit",
			type: "autoComplete",
			loadItems: {
				route: (props, values) => `departments/by-organisation/${values.organisationId || props.organisationId}`, 
				mapItem: d => ({ label: d.name, value: d.departmentId })
			}
		},
		{
			name: "positionId",
			label: "Position",
			type: "autoComplete",
			required: true,
			loadItems: {
				route: (props, values) => `positions/by-organisation/${values.organisationId || props.organisationId}`, 
				mapItem: p => ({ label: p.title, value: p.positionId })
			}
		},
		{
			name: "emailConfirmed",
			label: "Email confirmed",
			type: "checkbox"
		},
		{
			name: "roles",
			label: "Roles",
			type: "autoComplete",
			loadItems: {
				route: "roles",
				mapItem: r => ({ label: r.name, value: r.roleId })
			},
			isMulti: true,
			required: true,
			getValue: values => map(values.roles, "roleId"),
			setValues: (newValue, values) => ({ ...values, roles: map(newValue, r => ({ name: r.label, roleId: r.value })) }),
			disabled: props => !props.canChangeRole
		},
		{
			name: "avatar",
			label: "Avatar",
			type: "avatar"
		},
		{
			name: "skillsProfileId",
			label: "Skills Profile",
			type: "profilePicker",
			organisationId: (props, values) => values.organisationId || props.organisationId
		},
		{
			name: "userAssessmentComplete",
			label: "User Assessment Complete",
			type: "checkbox"
		},
		{
			name: "resetPassword",
			label: "Reset password",
			type: "checkbox",
			helpText: "Prompt user to reset password at next login"
		},
		{
			name: "isAcademic",
			label: "Is Academic",
			type: "checkbox"
		},
		{
			name: "disableSecondaryProfile",
			label: "Disable secondary profile",
			type: "checkbox",
			helpText: "Hide links and remove access to secondary skills profile"
		},
		{
			name: "membershipLevelId",
			label: "Membership Level",
			type: "autoComplete",
			loadItems: {
				route: props => `organisations/membership-levels?organisationId=${props.organisationId}`,
				mapItem: level => ({ label: level.title, value: level.membershipLevelId })
			}
		},
		{
			name: "allChannels",
			label: "All Channels",
			type: "checkbox",
			helpText: "Subscribes user to all channels"
		},
		{
			name: "isExpert",
			label: "Is Expert",
			type: "checkbox",
			helpText: "Allows the user to be configured as an expert"
		}
	]
};

const EditUser = props => {
	const { user, values, fields, isNew, loading, warningColour, successColour } = props,
		{ isActive = true, userId } = user;

	const content = (
		<React.Fragment>
			{!isNew && !isActive &&
				<SnackbarContent 
					message={
						<span style={{ display: "flex", alignItems: "center" }}>
							<WarningIcon style={{ marginRight: 8 }} />
							Login is disabled for this user until their account has been reactivated...
						</span>
					}
					style={{ backgroundColor: warningColour, maxWidth: "100%", marginBottom: 20 }}
				/>
			}
			<Form onSubmit={() => props.validateFields() && props.onSave(values, props.formFiles.avatar)}>
				<Grid container spacing={3}>
					{props.canChangeTenant && <Grid item xs={12}>{fields.tenantId()}</Grid>}
					<Grid item xs={12} md={6} sm={3}>{fields.firstName()}</Grid>
					<Grid item xs={12} md={6} sm={3}>{fields.lastName()}</Grid>
					<Grid item xs={12} md={6}>{fields.userName()}</Grid>
					<Grid item xs={12} md={6}>{fields.email()}</Grid>
					<Grid item xs={12} md={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12}>{fields.userOrganisation()}</Grid>
					<Grid item xs={12} md={6}>{fields.departmentId()}</Grid>
					<Grid item xs={12} md={6}>{fields.reportingDepartmentId()}</Grid>
					<Grid item xs={12} md={6}>{fields.positionId()}</Grid>
					<Grid item xs={12} style={{ padding: 0 }} />
					<Grid item xs={12} md={6}>{fields.roles()}</Grid>
					<Grid item xs={12} md={6} style={{ alignSelf: "flex-end" }}>{fields.skillsProfileId()}</Grid>
					{props.showMembershipLevelId && <Grid item xs={12} sm={6}>{fields.membershipLevelId()}</Grid>}
					{isNew && <Grid item xs={12} md={6}>{fields.password()}</Grid>}
					{/* Spacer */}
					<Grid item xs={12} style={{ padding: 0 }} />
					{isNew && props.organisationType === "Institution" &&
						<Grid item xs={12} md="auto">{fields.isAcademic()}</Grid>
					}
					<Grid item xs={12} md="auto">{fields.userAssessmentComplete()}</Grid>
					<Grid item xs={12} md="auto">{fields.emailConfirmed()}</Grid>
					<Grid item xs={12} md="auto">{fields.resetPassword()}</Grid>
					<Grid item xs={12} md="auto">{fields.disableSecondaryProfile()}</Grid>
					<Grid item xs={12} md="auto">{fields.allChannels()}</Grid>
					<Grid item xs={12} md="auto">{fields.isExpert()}</Grid>
					<Grid item xs={12}>{fields.avatar()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} user</Button>
					{!isNew && 
						<React.Fragment>
							<Button className="delete-button" loading={loading} onClick={() => props.onDelete(userId)}>
								Delete user
							</Button>
							<Button 
								style={{ marginLeft: 10, backgroundColor: isActive ? warningColour : successColour }} 
								onClick={() => isActive ? props.deactivate(userId) : props.reactivate(userId)}
								loading={loading} 
							>
								{isActive ? "Deactivate" : "Reactivate"} user
							</Button>
						</React.Fragment>
					}
				</div>
			</Form>
		</React.Fragment>
	);

	return isNew ? <Card loading={props.loading}>{content}</Card> : content;
};

EditUser.propTypes = {
	user: PropTypes.object,
	canChangeTenant: PropTypes.bool,
	canChangeRole: PropTypes.bool,
	isNew: PropTypes.bool,
	saveResult: PropTypes.object.isRequired,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	deactivate: PropTypes.func,
	reactivate: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	organisationId: PropTypes.number.isRequired,
	organisationType: PropTypes.string.isRequired,
	showMembershipLevelId: PropTypes.bool.isRequired,
	successColour: PropTypes.string.isRequired,
	warningColour: PropTypes.string.isRequired
};

EditUser.defaultProps = {
	canChangeTenant: false,
	canChangeRole: false,
	user: {},
	isNew: false,
	onDelete: null,
	deactivate: null,
	reactivate: null
};

export default withForm(form)(EditUser);