import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRole, saveRole, deleteRole, getFunctions, isLoading as isRolesLoading } from '../../../store/roles';
import { getMenus, isLoading as isMenusLoading } from '../../../store/menu';
import { canEditRole } from '../../../store/security';
import { showModal } from '../../../store/modal';
import PageLayout from '../../PageLayout';
import EditRole from './EditRole';

const EditRoleController = props => {
	React.useEffect(props.onStartup, []);
	
	React.useEffect(() => props.getRole(props.match.params.roleId), [props.match.params.roleId]);

	return <PageLayout title="Edit Role" pageContent={
		<EditRole
			canEdit={props.canEdit}
			role={props.role}
			functions={props.functions}
			menus={props.menus}
			functionGroups={props.functionGroups}
			saveRole={props.saveRole} 
			saveResult={props.saveResult}
			loading={props.isLoading} 
			onDelete={props.deleteRole}
		/>
	} breadcrumbs={[{ label: "Manage roles",  path: "/admin/roles" }, "Edit Role"]} />;
};

EditRoleController.propTypes = { 
	canEdit: PropTypes.bool.isRequired,
	role: PropTypes.object.isRequired,
	getRole: PropTypes.func.isRequired,
	saveRole: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	deleteRole: PropTypes.func.isRequired,
	onStartup: PropTypes.func.isRequired,
	functions: PropTypes.array.isRequired,
	functionGroups: PropTypes.array.isRequired,
	menus: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	canEdit: canEditRole(state),
	role: state.roles.role,
	functions: state.roles.functions,
	functionGroups: state.roles.functionGroups,
	saveResult: state.roles.saveResult,
	menus: state.menu.menus,
	isLoading: isRolesLoading(state) || isMenusLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getRole: roleId => dispatch(getRole(roleId)),
	saveRole: role => dispatch(saveRole(role)),
	deleteRole: roleId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you wish to delete this role?",
		onOk: () => dispatch(deleteRole(roleId))
	})),
	onStartup: () => {
		dispatch(getFunctions());
		dispatch(getMenus());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleController);
