/* eslint no-cond-assign: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import { 
	Dialog, 
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Grid, 
	Paper, 
	RadioGroup, 
	FormControlLabel, 
	Radio
} from '@mui/material';
import Button from '../widgets/Button';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { get } from '../../utils/ajax';
import TextField from '../widgets/TextField';
import forEach from 'lodash/forEach';
import ReactEcharts from 'echarts-for-react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { formatMoney } from '../../utils/utils';

const SalaryDistribution = ({ salaryDistribution, colourPalette }) => {
	const data = salaryDistribution || [];
	return (
	<ReactEcharts 
		option={{
			color: colourPalette,
			legend: {},
			tooltip: {},
			grid: { containLabel: true },
			xAxis: {
				type: "category", 
				data: data.map(d => d.key),
				axisLabel: { rotate: -45 }				
			},
			yAxis: [
				{
					type : "value",
					name: "$ USD",
					nameLocation: "center",
					nameGap: "30"
				}
			],
			series: [
				{
					type: "bar", 
					data: data.map(d => d.value)
				}
			]
		}}
		style={{ height: "300px" }} 
	/>
	);
};

class CareerPathway extends React.Component {
	state = {
		step: 'EnterOccupation',
		occupation: this.props.defaultOccupation,
		occupations: [],
		role: null,
		roles: [],
		previousRoles: [],
		showOccupationPicker: false
	}

	componentDidMount() {
		this.getPathwayByName(this.state.occupation);
	}

	onChangeOccupation = () => {
		this.getMatchingOccupations(this.state.occupation);
		this.setState({
			...this.state,
			showOccupationPicker: true
		});
	}
	onCloseOccupationPicker = () => {
		this.setState({
			...this.state,
			showOccupationPicker: false,
			occupations: []
		});
	}
	onOkOccupationPicker = () => {
		this.setState({
			...this.state,
			showOccupationPicker: false
		}, () => {
			this.getPathwayById(this.state.occupationId);
		});
	}

	getMatchingOccupations = (occupation) => {
		this.props.setLoading(true);
		get({
			url: `/api/career-pathway/predict-occupations?occupation=${occupation}`,
			onSuccess: data => {
				this.props.setLoading(false);
				const occupations = [];
				forEach(data, o => {
					occupations.push({
						id: o.Id,
						name: o.name, 
						description: o.Description,
						score: o.score
					});
				});
				this.setState({ 
					...this.state, 
					occupations: occupations,
					occupationId: occupations.length > 0 ? occupations[0].id : 0
				});
			},
			onError: () => {
				this.props.setLoading(false);
			}
		});
	}

	getPathwayByName = (occupationName, prevRole) => {
		this.props.setLoading(true);
		get({
			url: `/api/career-pathway/pathway-by-name?occupationName=${occupationName}`,
			onSuccess: data => {
				this.props.setLoading(false);
				this.loadPathway(data, prevRole);
			},
			onError: () => {
				this.props.setLoading(false);
			}
		});
	}

	getPathwayById = (occupationId, prevRole) => {
		this.props.setLoading(true);
		get({
			url: `/api/career-pathway/pathway-by-id?occupationId=${occupationId}`,
			onSuccess: data => {
				this.props.setLoading(false);
				this.loadPathway(data, prevRole);
			},
			onError: () => {
				this.props.setLoading(false);
			}
		});
	}

	loadPathway = (pathway, prevRole) => {
		let showOccupationPicker = false;
		let occupations = [];
		if (pathway.matchingOccupations && pathway.matchingOccupations.length > 0 && pathway.matchingOccupations[0].name !== this.state.occupation) {
			showOccupationPicker = true;
			occupations = map(pathway.matchingOccupations, o => ({
				id: o.Id,
				name: o.name,
				description: o.Description,
				score: o.score
			}));
		}
		let occupation = pathway.occupation[0];
		let roles = this.state.roles;
		let role = find(roles, r2 => r2.id === occupation.id);
		if (!role) {
			role = { id: occupation.id, progression: [] };
			roles.push(role);
		}
		role.name = occupation.name;
		role.description = occupation.description;
		role.typicalEducation = occupation.typicalEducation;
		role.salary = `${formatMoney(pathway.rolePricing.salary.amount)} ${pathway.rolePricing.salary.currencyCode}`;
		role.progression = [];
		
		if (pathway.nextOccupations) {
			forEach(pathway.nextOccupations, o => {
				let nextOccupation = find(roles, r2 => r2.id === o.id);
				if (!nextOccupation) {
					nextOccupation = { id: o.id, progression: [] };
					roles.push(nextOccupation);
				}
				nextOccupation.name = o.name;
				nextOccupation.description = o.description;
				nextOccupation.salaryDistribution = o.salaryDistribution;
				role.progression.push(o);
			});
		}

		this.setState({ 
			...this.state, 
			showOccupationPicker: showOccupationPicker,
			occupations: occupations,
			role: role, 
			roles: roles,
			previousRoles: prevRole ? [
				...this.state.previousRoles, 
				this.state.role
			] : this.state.previousRoles
		}, () => {
			// this.createChart(this.state.role);
		});
	}

	removePreviousChart = () => {
		const chart = this._rootNode;
		if (!chart) return;
		while(chart.hasChildNodes()) {
		  chart.removeChild(chart.lastChild);
		}
	}

// 	createChart = (role) => {
// 		var 
// 			svgWidth = 600,
// 			svgHeight = 500,
// 			radius = Math.min(svgWidth, svgHeight) / 2;
// 		this.removePreviousChart();

// 		const colours = ["#89A54E", "#4572A7", "#80699B", "#3D96AE", "#DB843D", "#92A8CD", "#A47D7C", "#B5CA92"];
// 		var color = d3.scaleOrdinal()
// 			.range(colours);
		
// 		var arc = d3.arc()
// 			.outerRadius(radius - 10)
// 			.innerRadius(radius - 100);
// 		// var arcStart = d3.arc()
// 		// 	.outerRadius(radius - 100)
// 		// 	.innerRadius(radius - 100);

// 		var innerCircle = d3.arc()
// 			.outerRadius(radius - 100)
// 			.innerRadius(0);
// 		// var innerCircleStart = d3.arc()
// 		// 	.outerRadius(10)
// 		// 	.innerRadius(0);
		
// 		// var innerCircleLabel = d3.arc()
// 		// 	.outerRadius(radius - 110)
// 		// 	.innerRadius(radius - 110);

// 		const wrapText = (text, width, x)  => {
// 			text.each(function() {
// 				var text = d3.select(this),
// 					words = text.text().split(/\s+/).reverse(),
// 					word,
// 					line = [],
// 					lineNumber = 0,
// 					lineHeight = 1.1, // ems
// 					y = text.attr("y"),
// 					dy = parseFloat(text.attr("dy")),
// 					tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");
// 				while (word = words.pop()) {
// 					line.push(word);
// 					tspan.text(line.join(" "));
// 					if (tspan.node().getComputedTextLength() > width) {
// 						line.pop();
// 						tspan.text(line.join(" "));
// 						line = [word];
// 						tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
// 					}
// 				}
// 			});
// 		}
		
// 		const wrapArcText = (text) => {
// 			text.each(function () {
// 				var text = d3.select(this),
// 					letters = text.text().split('').reverse(),
// 					letter,
// 					line = [],
// 					lineNumber = 0,
// 					lineHeight = 1.1, // ems
// 					y = text.attr("y"),
// 					dy = parseFloat(text.attr("dy")),
// 					textPath = text.text(null).append("textPath") // Add a textPath element
// 						.attr("startOffset", '50%')
// 						.style("text-anchor", "middle")
// 						.attr("xlink:href", function (d) { return "#hiddenArc" + d.i; }),
// 					tspan = textPath.append('tspan'), // Inslide textPath, add a tspan element, for offset feature later.
// 					path = d3.select(text.select('textPath').attr('xlink:href')); // Get the path to compute width of text later.

// 				var startLoc = /M(.*?)A/;
// 				var newStart = path.attr('d').match(startLoc)[1];
// 				var newEnd = path.attr('d').indexOf(' 0 0 0 ') > -1
// 					? path.attr('d').split(' 0 0 0 ')[1]
// 					: path.attr('d').split(' 0 0 1 ')[1];

// 				// Compute coordinate points of the arc that the text will follow.
// 				var x1 = parseFloat(newStart.split(' ')[0]),
// 					y1 = parseFloat(newStart.split(' ')[1]),
// 					x2 = parseFloat(newEnd.split(' ')[0]),
// 					y2 = parseFloat(newEnd.split(' ')[1]);

// 				// Compute the length of the segment between the arc start/end points. This will be the
// 				// width which the labels should wrap when reaching it.
// 				var width = Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2)) - 50;
// 				debugger;

// 				// And then we go on (with slight changes) with the example from Mike Bostock 
// 				// from here https://bl.ocks.org/mbostock/7555	321
// 				while (letter = letters.pop()) {
// 					line.push(letter);
// 					tspan.text(line.join(""));
// 					if (tspan.node().getComputedTextLength() > width) {
// 						line.pop();
// 						tspan.text(line.join(""));
// 						line = [letter];

// 						// Instead of adding only a tspan element, add a new textPath so that the wrapped 
// 						// letters will be aligned to center. Without it, the letters will start drawing 
// 						// from right with part of them invisible, like if the labels are not wrapped. 
// 						textPath = text.append("textPath")
// 							.attr("startOffset", '50%')
// 							.style("text-anchor", "middle")
// 							.attr("xlink:href", function (d) { return "#hiddenArc" + d.i; }),

// 							// Add a tspan element to offset the wrapped letters from the previous line
// 							tspan = textPath.append("tspan")
// 								.attr('dy', '1em')
// 								.attr('text-anchor', 'middle')
// 								.text(letter);
// 					}
// 				}
// 			});
// 		}

// 		var pie = d3.pie()
// 			.sort(null)
// 			.value(function(d) { return d.value; });
		
// 		// Setup chart SVG
// 		var svg = d3.select(this._rootNode).append("svg")
// 			.attr("width", "100%")
// 			.attr("height", "100%")
// 			.attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`)
			
// 		var defs = svg.append("defs");
// 		// Add clip for label over inner circle
// 		defs.append('clipPath')
// 			.attr('id', 'innerCircleLabelClip')
// 			.append("circle")
// 			.attr('cx', 0)
// 			.attr('cy', 0)
// 			.attr("r", radius - 100);

// 		// This will allow use to add an image to the inner circle
// 		defs.append('pattern')
// 			.attr('id', 'innerCircleBackground')
// 			.attr('patternUnits', 'objectBoundingBox')
// 			.attr('width', "100%")
// 			.attr('height', "100%")
// 			.append("image")
// 			.attr("xlink:href", "/images/chart/programmer.png")
// 			.attr('x', 0)
// 			.attr('y', 0)
// 			.attr("preserveAspectRatio","none")
// 			.attr('width', 300)
// 			.attr('height', 300);
		
		
// 		var chart = svg
// 			.append("g")
// 			.attr("transform", "translate(" + (svgWidth / 2 + 75) + "," + svgHeight / 2 + ")");

// 		// Create inner circle 
// 		chart.selectAll(".wedge")
// 			.data(pie([{ ...role, value: 1 }]))
// 			.enter().append("g")
// 			.attr("class", "wedge")
// 			.append("path")
// 			.attr('x', 0)
// 			.attr('y', 0)
// 			// .style("fill", 'red')
// 			.style("fill", 'url(#innerCircleBackground)')
// 			// .attr("d", innerCircleStart)
// 			// .transition().duration(500)
// 			.attr("d", innerCircle);

// 		// Add label to inner circle
// 		chart.append("rect")
// 			.attr("clip-path", "url(#innerCircleLabelClip)")
// 			.attr('x', -150)
// 			.attr('y', 60)
// 			.attr('width', 300)
// 			.attr('height', 90)
// 			.attr('fill', '#000');
// 		chart.append("text")
// 			.attr("class", "roleLabel")
// 			.attr("x", -100)
// 			.attr("y", 90)
// 			.attr("dy", ".35em")
// 			.text(role.name);

// 		// Add chart arcs
// 		const roleProgression = role.progression.length > 0 ? role.progression : [''];
// 		const arcData = map(roleProgression, (r, i) => ({ name: r, value: 1, colour: i }));
// 		var g = chart.selectAll(".arc")
// 			.data(pie(arcData))
// 			.enter().append("path")
// 			.attr("class", "arc")
// 			.attr("id", function(d,i) { return "arc_"+i; })
// 			.attr("d", arc);

// 		svg.selectAll(".arcText")
// 			.data(arcData)
// 			   .enter().append("text")
// 				.attr("class", "arcText")
// 				.attr("x", 5) //Move the text from the start angle of the arc
// 				.attr("dy", 18) //Move the text down
// 			   .append("textPath")
// 				.attr("xlink:href",function(d,i){return "#arc_"+i;})
// 				.text(function(d){return d.name;});

// 		// g.append("path")
// 		// 	.attr("id", function(d,i) { return "arc" + i; })
// 		// 	.attr("d", arc)
// 		// 	.attr('opacity', 0)
// 		// 	.style("fill", function(d) { return color(d.data.colour); })
// 		// 	.on("click", (args) => {
// 		// 		const nextRole = find(this.state.roles, r => r.name === args.data.name);
// 		// 		if (!nextRole) return;
// 		// 		this.getPathwayById(nextRole.id); return(nextRole.id, this.state.role);
// 		// 	})
// 		// 	.call(function(d,i) {
// 		// 		debugger;
// 		// 		if (!d.data.name) return;

// 		// 		//A regular expression that captures all in between the start of a string
// 		// 		//(denoted by ^) and the first capital letter L
// 		// 		var firstArcSection = /(^.+?)L/;
		
// 		// 		//The [1] gives back the expression between the () (thus not the L as well)
// 		// 		//which is exactly the arc statement
// 		// 		var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];
// 		// 		//Replace all the comma's so that IE can handle it -_-
// 		// 		//The g after the / is a modifier that "find all matches rather than
// 		// 		//stopping after the first match"
// 		// 		newArc = newArc.replace(/,/g , " ");
		
// 		// 		//Create a new invisible arc that the text can flow along
// 		// 		g.append("path")
// 		// 			.attr("class", "hiddenArcs")
// 		// 			.attr("id", "hiddenArc"+i)
// 		// 			.attr("d", newArc)
// 		// 			.style("fill", "none");
// 		// 	})
// 		// 	// .transition().duration(500)
// 		// 	// .attr('opacity', 0)
// 		// 	// .transition().duration(1)
// 		// 	.transition().duration(500)
// 		// 	.attr('opacity', 1);

// 		// Chart arc text
// 		/*
// 		g.selectAll(".text")
// 			.data(arcData)
// 			.enter().append("text")
// 			.attr("class", "arcText")
// 			.attr("font-size", '12px')
// 			.attr("letter-spacing", '5')
// 			.attr("x", 0) 
// 			.attr("dy", function (d, i) {
// 				d.i = i;
// 				return 58;
// 			})
// 			// .append("textPath")
// 			// .attr("xlink:href",function(d,i){return "#hiddenArc" + i;})
// 			// .attr("startOffset","50%")
// 			// .style("text-anchor","middle")
// 			// .on("click", (args) => {
// 			// 	const nextRole = find(this.state.roles, r => r.name === args.name);
// 			// 	if (!nextRole) return;
// 			// 	this.getPathwayById(nextRole.id, this.state.role);
// 			// })
// 			.text(function(d){return d.name;})
// 			// .call(wrapArcText)
// 		;
// */

// 		// // Career progression UI
// 		// const progressionWidth = 125;
// 		// const progressionHeight = 50;
// 		// const progression = [...this.state.previousRoles, this.state.role];
// 		// const progressionG = svg
// 		// 	.selectAll(".progression")
// 		// 	.data(progression)
// 		// 	.enter()
// 		// 	.append('g')
// 		// 	.attr("transform", (d, i) => `translate(0,${10 + (i * 75)})`);
// 		// const goToRole = (roleName) => {
// 		// 	if (roleName === this.state.role.name) return;
// 		// 	const role = find(roles, r => r.name === roleName);
// 		// 	if (!role) return;
// 		// 	let prevRoles = [...this.state.previousRoles];
// 		// 	let lastRoleName = prevRoles[prevRoles.length - 1].name;
// 		// 	while (lastRoleName !== roleName) {
// 		// 		prevRoles = prevRoles.slice(0, prevRoles.length - 1);
// 		// 		lastRoleName = prevRoles[prevRoles.length - 1].name;
// 		// 	}
// 		// 	prevRoles = prevRoles.slice(0, prevRoles.length - 1);
// 		// 	this.setState({ 
// 		// 		role: role,
// 		// 		previousRoles: prevRoles
// 		// 	});
// 		// 	this.createChart(role);
// 		// };
// 		// progressionG.append('path')
// 		// 	.attr("id", function(d,i) { return "progression" + i; })
// 		// 	.attr("class", "progression")
// 		// 	.attr('d', (d, i) => {
// 		// 		const x = 0;
// 		// 		const y = 0;

// 		// 		return `
// 		// 			M${x},${y} 
// 		// 			L${x+progressionWidth},${y} 
// 		// 			L${x+progressionWidth},${y+progressionHeight} 
// 		// 			L${x+(progressionWidth*0.5)},${y+(progressionHeight*1.75)}
// 		// 			L${x},${y+progressionHeight}
// 		// 			Z`;
// 		// 	})
// 		// 	.on("click", (d) => {
// 		// 		goToRole(d.name);
// 		// 	})
// 		// 	.style("fill", function(d, i) { return color(i); });
// 		// progressionG.append('text')
// 		// 	.attr("class", "progressionLabel")
// 		// 	.attr("text-anchor", "middle")
// 		// 	.attr("x", progressionWidth * 0.5)
// 		// 	.attr("y", 25)
// 		// 	.attr("dy", ".35em")
// 		// 	.text(d => d.name)
// 		// 	.on("click", (d) => {
// 		// 		goToRole(d.name);
// 		// 	})
// 		// 	.call(wrapText, progressionWidth, progressionWidth * 0.5);


// 	}

    // shouldComponentUpdate() {
    //     // Prevents component re-rendering
    //     // return false;
	// }
	
	goToPreviousRole = () => {
		if (this.state.previousRoles.length < 1) return;
		const prevRole = this.state.previousRoles[this.state.previousRoles.length - 1];
		this.setState({ 
			role: prevRole,
			previousRoles: [
				...this.state.previousRoles.slice(0, this.state.previousRoles.length - 1)
			]
		}, () => {
			// this.createChart(prevRole);
		});
	}

    _setRef(componentNode) {
        this._rootNode = componentNode;
	}
	
	render = () => {
		const colourPalette = this.props.colourPalette;

		return (
            <React.Fragment>
				<Grid className="career-pathway" container spacing={3}>
					<Grid item xs={12}>
						<div style={{ display: 'flex' }}>
							<TextField
								value={this.state.occupation}
								onChange={(e) => this.setState({ ...this.state, occupation: e.target.value })}
							/>
							<Button 
								onClick={() => this.onChangeOccupation()}
								style={{
									width: '200px', 
									marginLeft: '10px'
								}}
							>
								Change Career
							</Button>
						</div>
					</Grid>
					{this.state.role && 
						<Grid item xs={12}>
							<Grid className="career-progression" container spacing={3}>
								<Grid item xs={12} md={5}>
									<Paper style={{ height: '100%', padding: '10px' }}>
										<h2><span>{this.state.role ? this.state.role.name: ''}</span></h2>
										<p>{this.state.role ? this.state.role.description: ''}</p>
										<div className='stats'>
											<div className='stat'>
												<div className='stat-label'>Salary</div>
												<div className='stat-wrapper'>
													<div className='stat-values'>
														${this.state.role && this.state.role.salary ? this.state.role.salary : 'N/A'}
													</div>
													<div className='stat-desc'>Most common salary</div>
												</div>
											</div>
											<div className='stat'>
												<div className='stat-label'>Typical Education</div>
												<div className='stat-wrapper'>
													<div className='stat-values'>
														{this.state.role && this.state.role.typicalEducation ? this.state.role.typicalEducation : ''}
													</div>
												</div>

											</div>
										</div>
										{this.state.role && this.state.role.salaryDistribution &&
											<SalaryDistribution 
												salaryDistribution={this.state.role.salaryDistribution} 
												colourPalette={this.props.colourPalette}
											/>
										}
										{/* <h2>Career Progression</h2>
										{
											map(this.state.previousRoles, r => 
												<div>{r.name} -></div>
											)
										}
										<div>{this.state.role.name}</div> */}
									</Paper>
								</Grid>
								<Grid item xs={12} md={7}>
									<Paper style={{ height: '100%', padding: '10px' }}>
										<React.Fragment>
											<Grid item xs={12}>
												{this.state.previousRoles.length > 0 && 
													<IconButton
                                                        onClick={() => {
                                                            const prevRole = this.state.previousRoles[this.state.previousRoles.length - 1];
                                                            this.setState({ 
                                                                ...this.state,
                                                                role: prevRole, 
                                                                previousRoles: this.state.previousRoles.slice(0, this.state.previousRoles.length - 1)
                                                            });
                                                        }}
                                                        size="large">
														<ArrowLeftIcon />
													</IconButton>
												}
												<span>Select the next role to progress through your career pathway</span>
											</Grid>
											<Grid item xs={12}>
												<ul className="progression">
												{
													(() => {
														const listItems = [];
														let level = 0;

														forEach(this.state.previousRoles, (p, i) => {
															listItems.push(
																<li 
																	className="progression-role"
																	onClick={() => { 
																		this.setState({ 
																			...this.state,
																			role: p,
																			roles: [],
																			previousRoles: this.state.previousRoles.slice(0, i)
																		}, () => { 
																			this.getPathwayById(p.id);
																		});
																	}}
																	style={{
																		marginLeft: level > 0 ? `${level * 20}px` : undefined,
																		backgroundColor: colourPalette.length < level ? '#84c446' : colourPalette[level]
																	}}
																>
																	<div className="progression-role-name">
																		{p.name}
																	</div>
																	<div className="progression-role-score">
																		${p.salary}
																	</div>
																</li>
															);
															level += 1;
														});

														listItems.push(
															<li 
																className="progression-role"
																style={{
																	marginLeft: level > 0 ? `${level * 20}px` : undefined,
																	backgroundColor: colourPalette.length < level ? '#84c446' : colourPalette[level]
																}}
															>
																<div className="progression-role-name">
																	{this.state.role.name}
																</div>
																<div className="progression-role-score">
																	${this.state.role.salary}
																</div>
															</li>
														);
														level += 1;
															
														const lateralRoles = filter(this.state.role.progression, r2 => r2.category === 'LateralAdvancement');
														const advancementRoles = filter(this.state.role.progression, r2 => r2.category !== 'LateralAdvancement');

														const addProgressionRoles = (roles) => {
															forEach(roles, p => {
																listItems.push (
																	<li 
																		className="progression-role"
																		onClick={() => { 
																			this.setState({ 
																				...this.state,
																				role: p,
																				roles: [],
																				previousRoles: [ ...this.state.previousRoles, this.state.role ]
																			}, () => { 
																				this.getPathwayById(p.id);
																			});
																		}}
																		style={{
																			marginLeft: `${level * 20}px`,
																			backgroundColor: colourPalette.length < level ? '#84c446' : colourPalette[level]
																		}}
																	>
																		<div className="progression-role-name">
																			{p.name}
																		</div>
																		<div className="progression-role-score">
																			Match {Math.floor(p.score * 100)}%
																		</div>
																	</li>
																);
															});
														};
														if (lateralRoles.length > 0) {
															listItems.push(
																<li 
																	className="progression-role-header h4"
																	style={{
																		marginLeft: `${level * 20}px`,
																		listStyle: 'none'
																	}}
																>
																	Lateral Roles
																</li>
															);
															addProgressionRoles(lateralRoles);
														}
														if (advancementRoles.length > 0) {
															listItems.push(
																<li 
																	className="progression-role-header h4"
																	style={{
																		marginLeft: `${level * 20}px`,
																		listStyle: 'none'
																	}}
																>
																	Advancement Roles
																</li>
															);
															addProgressionRoles(advancementRoles);
														}
														if (lateralRoles.length === 0 && advancementRoles.length === 0 && !this.props.loading) {
															listItems.push(
																<li 
																	className="progression-role-header h4"
																	style={{
																		marginLeft: `${level * 20}px`,
																		listStyle: 'none'
																	}}
																>
																	No further roles
																</li>
															);
														}

														return listItems;
													})()
												}
												</ul>
											</Grid>
										</React.Fragment>
										{/* <Grid item xs={12}>
											{/* Hack for matching chart height to width - https://stackoverflow.com/a/6615994/77067 * /}
											<div
												style={{
													display: 'inline-block',
													position: 'relative',
													width: '100%'
												}}
												>
												<div style={{ marginTop: '75%' }}></div>
												<div 
													className="line-container" 
													ref={this._setRef.bind(this)}
													style={{ 
														position: 'absolute',
														top: 0,
														bottom: 0,
														left: 0,
														right: 0
													}}
												>
												</div>
											</div>
										</Grid> */}
									</Paper>
								</Grid>
								<Grid item xs={12} className="button-container" >
									<Button onClick={this.goToPreviousRole} color="secondary" size="large">
										Return to Previous Role
									</Button>
									<Button onClick={() => {}} color="secondary" size="large" style={{ marginLeft: '5px' }}>
										Required Competencies
									</Button>	
									<Button onClick={() => {}} color="secondary" size="large" style={{ marginLeft: '5px' }}>
										Print Gap Analysis
									</Button>	
								</Grid>
							</Grid>
						</Grid>
					}
				</Grid>
				<Dialog open={this.state.showOccupationPicker} onClose={this.onCloseOccupationPicker}>
					<DialogTitle>Select the best matching occupation</DialogTitle>
					<DialogContent style={{ overflow: 'visible' }}>
					<Grid className="select-occupation" container spacing={3}>
						<Grid item xs={12}>
							<RadioGroup value={this.state.occupationId ? this.state.occupationId.toString() : 0} onChange={e => {
								this.setState({ ...this.state, occupationId: parseInt(e.target.value, 10) })
							}}>
								{
									map(this.state.occupations, o =>
										<FormControlLabel value={o.id.toString()} control={<Radio />} label={`${o.name} (${Math.floor(o.score * 100)}%)`} />
									)
								}
							</RadioGroup>
						</Grid>
					</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseOccupationPicker}>Cancel</Button>
						<Button onClick={this.onOkOccupationPicker}>Ok</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
        );
	}
		
};

CareerPathway.propTypes = { 
	defaultOccupation: PropTypes.string,
	loading: PropTypes.bool,
	colourPalette: PropTypes.array.isRequired
};

CareerPathway.defaultProps = { 
	defaultOccupation: '',
	loading: false,
	colourPalette: undefined
};

export default CareerPathway;

