import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import RowIcons from '../widgets/RowIcons';
import Card from '../widgets/Card';
import MediaCard from '../widgets/MediaCard';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { withStyles } from 'tss-react/mui';
import FavouriteButton from './FavouriteButton';
import Popover from '@mui/material/Popover';
import { truncateString } from '../../utils/utils';
import Stack from '@mui/material/Stack';
import sortBy from 'lodash/sortBy';

const styles = (theme) => ({
	contentCard: {
		'& .card-body': {
			'& .header': {
				'& span': {
					color: theme.palette.primary.main
				}
			}
		}
	}
});
const CourseCard = React.memo(props => {
	const { course: c, canEdit } = props,
		numChannels = c.channels ? c.channels.length : 0,
		numCompetencies = c.competencies.length,
		organisation = c.organisations.find(o => o.organisationId === props.organisationId) || {},
		price = organisation.price || c.price,
		prices = sortBy(c.prices || [], 'order');
	
	const tagColours = {};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const showPricingPopup = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const closePricingPopup = () => {
		setAnchorEl(null);
	};
	const pricingPopupOpen = Boolean(anchorEl);

	let pricingText = "";
	let pricingStatus = "";
	let pricingStyle = null;
	let pricingOnClick = undefined;
	if (prices && prices.length > 0) {
		pricingText = 
			<div>
				Click for Pricing
			</div>;
		pricingStatus = "info";
		pricingStyle = { cursor: 'pointer' };
		pricingOnClick = showPricingPopup;
	} else if (price) {
		pricingText = price;
		pricingStatus = "warning";
	} else {
		pricingText = "P.O.A";
		pricingStatus = "info";
	}

	let summary = c.summary || c.description || '';
	if (summary.length > 300) {
		if (summary.indexOf("</p>") > -1) {
			const paragraphs = summary.split("</p>")
			const firstParagraph = paragraphs[0]+'</p>';
			summary = firstParagraph;
		} else {
			summary = truncateString(summary, 300);
		}
	}
	
	let courseImage = null;
	if (numChannels > 0) {
		courseImage = c.channels[0].image;
	} else if (c.image) {
		courseImage = c.image;
	}

	return (
		<Grid item xs={12} sm={12} md={6} lg={4} key={props.key}>
			<Popover
				id={'pricing-popup'}
				open={pricingPopupOpen}
				anchorEl={anchorEl}
				onClose={closePricingPopup}
				anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
				}}
			>
				<div 
					style={{ 
						padding: '20px',
						display: 'flex',
						gap: '20px'
					}}
				>
					{map(prices, p => (
						<Card
							style={{ margin: 0, minHeight: '80px' }}
							bodyStyle={{
								height: '100%',
								display: 'flex',
								width: '200px',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '10px 20px',
								fontSize: '16px',
								fontWeight: '400',
								border: p.backColour ? `2px solid #${p.backColour}` : undefined,
								color: '#333'
								// background: p.backColour ? `#${p.backColour}` : undefined,
								// color: p.foreColour ? `#${p.foreColour}` : undefined
							}}
						>
							<div
								style={{
									whiteSpace: 'nowrap',
									width: '100%',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>{p.name}</div>
							<div>{p.price}</div>
						</Card>
					))}
				</div>
			</Popover>
			<MediaCard 
				className={`course-card ${props.classes.courseCard}`} 
				image={courseImage} 
				ribbon={pricingText} 
				ribbonStatus={pricingStatus}
				ribbonStyle={pricingStyle}
				ribbonOnClick={pricingOnClick}
				bodyStyle={{ marginTop: '0px' }}
			>
				<Stack justifyContent="space-between" style={{ height: '100%' }}>
					<div>
						{c.providerLogo && <div style={{ textAlign: 'center', marginBottom: '10px' }}><img src={c.providerLogo} alt="" role="presentation" style={{ maxWidth: '70%' }} /></div>}
						{React.createElement(!c.skipPreview && c.website ? "a" : Link, { className: "header", href: c.website, to: `${props.fromAdmin ? "/admin" : ""}/courses/${c.courseId}`, target: "_blank"  },
							<div>
								<span className="h4">{c.title}</span>
								{c.providerName && !c.providerLogo && <span className="h5">Provider: {c.providerName}</span>}
							</div>
						)}
						<FavouriteButton courseId={c.courseId} isFavourite={c.isFavourite} />
						<div className="description quill-display-block" dangerouslySetInnerHTML={{ __html: summary }} />
					</div>
					<div className="footer">
						{map(c.tags, (t, i) => {
							let tagColour = tagColours[t.category || "NO_CATEGORY"];
							if (!tagColour) {
								tagColour = props.colourPalette[Object.keys(tagColours).length];
								tagColours[t.category || "NO_CATEGORY"] = tagColour;
							}

							return <Tag key={i} label={t.name} colour={tagColour} />;
						})}
						{numCompetencies > 0 &&
							<Tag
								label={`${numCompetencies} Competenc${numCompetencies > 1 ? "ies" : "y"}`}
								colour={props.primaryColour}
								onClick={props.showCompetencies}
							/>
						}
						{map(c.channels, c => <Tag key={c.channelId} label={c.name} />)}
						{c.contactName &&
							<div style={{ marginTop: 10 }}>
								<strong>{c.contactName}</strong><br />
								<span>{c.contactEmail}</span>
							</div>
						}
						{canEdit &&
							<div style={{ marginTop: 10 }}>
								<RowIcons
									type="course"
									editLink={`${props.fromAdmin ? "/admin" : ""}/courses/edit/${c.courseId}`}
									deleteFunc={() => props.deleteCourse(c.courseId)}
									copyFunc={props.copyCourse}
								/>
							</div>
						}
						{c.discountCode && 
							<div
								style={{
									fontWeight: 500
								}}
							>
								{`Access Coupon: ${c.discountCode}`}
							</div>
						}
					</div>
				</Stack>
			</MediaCard>
		</Grid>
	);
});

CourseCard.propTypes = {
	course: PropTypes.shape({
		courseId: PropTypes.number,
		type: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		website: PropTypes.string,
		contactName: PropTypes.string,
		contactEmail: PropTypes.string,
		image: PropTypes.string,
		channels: PropTypes.arrayOf(PropTypes.object),
		organisations: PropTypes.arrayOf(PropTypes.object),
		mode: PropTypes.string,
		createdBy: PropTypes.string,
		createdByName: PropTypes.string,
		createdByAvatar: PropTypes.string,
		createdAt: PropTypes.string,
		updatedBy: PropTypes.string,
		updatedAt: PropTypes.string,
		providerId: PropTypes.number,
		providerName: PropTypes.string
	}).isRequired,
	canEdit: PropTypes.bool,
	deleteCourse: PropTypes.func,
	copyCourse: PropTypes.func,
	showCompetencies: PropTypes.func,
	organisationId: PropTypes.number.isRequired,
	fromAdmin: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired,
	colourPalette: PropTypes.array.isRequired
};

CourseCard.defaultProps = {
	canEdit: false,
	showCompetencies: undefined,
	deleteCourse: undefined,
	copyCourse: undefined,
	fromAdmin: false
};

const mapStateToProps = state => ({
	primaryColour: state.theme.primaryColour,
	colourPalette: state.theme.colourPalette
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CourseCard, styles));
