import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import PageLayout from '../../PageLayout';
import ButtonLink from '../../widgets/ButtonLink';
import ProductCard from './ProductCard';
import map from 'lodash/map';
import { get } from '../../../utils/ajax';

const ProductsController = props => {
	const [state, setState] = React.useState({
		products: [],
		isLoading: true
	});

	React.useEffect(() => {
		get({
			url: '/api/stripe/all-products',
			onSuccess: data => {
				setState(prevState => ({
					...prevState,
					products: data,
					isLoading: false
				}));
			},
			onError: () => {
				setState(prevState => ({
					...prevState,
					isLoading: false
				}));
			}
		});
	}, []);

	return (
		<PageLayout 
			title="Products" 
			loading={state.isLoading}
			pageContent={
				<React.Fragment>
					<Grid container spacing={3} direction="row-reverse">
						<Grid item xs="auto">
							<ButtonLink to="/admin/products/create">Create product</ButtonLink>
						</Grid>
					</Grid>
					{!state.isLoading && state.products.length === 0 ? <p>No products found...</p> : 
						<Grid container spacing={3}>
							{map(state.products, (o, i) => <ProductCard key={i} product={o} />)}
						</Grid>
					}
				</React.Fragment>
			} 
			breadcrumbs={["Products"]} 
		/>
	)
};

ProductsController.propTypes = {
};

ProductsController.defaultProps = {
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsController);
