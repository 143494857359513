import React from 'react';
import PropTypes from 'prop-types';
import MediaCard from '../widgets/MediaCard';
import Tag from '../widgets/Tag';
import { prettifyDate } from '../../utils/utils';

const Badge = React.memo(({ badge: b }) => 
	<MediaCard 
		className="badge" 
		image={b.image} 
		title={b.name} 
		style={{ width: 200, height: 290, marginRight: 20 }}
		bodyStyle={{ padding: '1.0rem 0 0.5rem 0' }}
	>
		<p>{b.description}</p>
		<Tag label={`Awarded ${prettifyDate(b.date)}`} />
	</MediaCard>
);

Badge.propTypes = {
	badge: PropTypes.object.isRequired
};

export default Badge;