import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { isLoading, searchDiscussions } from '../../store/discussion';
import ViewDiscussion from '../discussion/ViewDiscussion';
import DataTable from './dataTable/DataTable';
import DataTableColumn from './dataTable/DataTableColumn';
import Button from './Button';
import distanceInWords from 'date-fns/formatDistance';

const ViewDiscussionDialog = props => {
	const [discussionId, setDiscussionId] = React.useState(0);

	const getDiscussions = () => {
		props.searchDiscussions(props.search, discussions => {
			if (discussions.length === 1) setDiscussionId(discussions[0].discussionId);
		});
	}

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="lg"
            TransitionProps={{
                onEnter: getDiscussions,
                onExited: () => setDiscussionId(0)
            }}>
			<DialogTitle>
				{props.discussions.length > 1 && (discussionId === 0 ? "Select discussion" :
					<span className="text-muted">
						<a onClick={() => setDiscussionId(0)} style={{ color: "inherit", cursor: "pointer" }}>Select discussion</a> 
						<span style={{ padding: "0 0.5rem" }}>&gt;</span> 
					</span>
				)}
				{discussionId > 0 && "View discussion"}
				{props.loading && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
			</DialogTitle>
			<DialogContent className="discussion-dialog">
				{discussionId === 0 &&
					<DataTable data={props.discussions} loading emptyText="There is no discussion to display...">
						<DataTableColumn name="subject" label="Subject" renderer={d =>
							<Button variant="text" onClick={() => setDiscussionId(d.discussionId)}>{d.subject}</Button>
						} />
						<DataTableColumn name="comments" label="Comments" />
						<DataTableColumn name="views" label="Views" />
						<DataTableColumn name="createDate" dataType="date" label="Created" />
						<DataTableColumn label="Last Comment" renderer={d => 
							d.lastComment ? distanceInWords(new Date(d.lastComment), new Date(), { addSuffix: true }) : ""
						} />
					</DataTable>	
				}
				{/* <Button onClick={() => setDiscussionId(0)} style={{ marginBottom: 10 }}>
					<ArrowBackIcon style={{ marginRight: 5 }} />
					Return to Selection
				</Button> */}
				{discussionId > 0 && <ViewDiscussion discussionId={discussionId} isCommunity={props.isCommunity} hideTopic />}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
    );
};

ViewDiscussionDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool,
	discussions: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired
};

ViewDiscussionDialog.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	discussions: state.discussion.discussions,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	searchDiscussions: (args, onSuccess) => dispatch(searchDiscussions(args, onSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDiscussionDialog);