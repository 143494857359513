import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	tools: [],
	tool: {},
	isFavouriting: {}
};

const CLEAR_TOOLS = "CLEAR_TOOLS";
const GET_TOOLS_REQUEST = "GET_TOOLS_REQUEST";
const GET_TOOLS_SUCCESS = "GET_TOOLS_SUCCESS";
const GET_TOOLS_FAILURE = "GET_TOOLS_FAILURE";
const SEARCH_TOOLS_REQUEST = "SEARCH_TOOLS_REQUEST";
const SEARCH_TOOLS_SUCCESS = "SEARCH_TOOLS_SUCCESS";
const SEARCH_TOOLS_FAILURE = "SEARCH_TOOLS_FAILURE";
const GET_TOOL_REQUEST = "GET_TOOL_REQUEST";
const GET_TOOL_SUCCESS = "GET_TOOL_SUCCESS";
const GET_TOOL_FAILURE = "GET_TOOL_FAILURE";
const SAVE_TOOL_REQUEST = "SAVE_TOOL_REQUEST";
const SAVE_TOOL_SUCCESS = "SAVE_TOOL_SUCCESS";
const SAVE_TOOL_FAILURE = "SAVE_TOOL_FAILURE";
const DELETE_TOOL_REQUEST = "DELETE_TOOL_REQUEST";
const DELETE_TOOL_SUCCESS = "DELETE_TOOL_SUCCESS";
const DELETE_TOOL_FAILURE = "DELETE_TOOL_FAILURE";
const FAVOURITE_TOOL_REQUEST = "FAVOURITE_TOOL_REQUEST";
const FAVOURITE_TOOL_SUCCESS = "FAVOURITE_TOOL_SUCCESS";
const FAVOURITE_TOOL_FAILURE = "FAVOURITE_TOOL_FAILURE";

export const isLoading = createLoadingSelector(["GET_TOOLS", "SEARCH_TOOLS", "GET_TOOL"]);

export const clearTools = () => ({ type: CLEAR_TOOLS });

export const getTools = () =>
	createFetchAction({
		url: "/api/tools",
		startAction: GET_TOOLS_REQUEST,
		onError: error => [getToolsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getToolsSuccess(data)
	});

export const getToolsSuccess = data => ({ type: GET_TOOLS_SUCCESS, payload: { data } });
export const getToolsFailure = error => ({ type: GET_TOOLS_FAILURE, payload: { error } });

export const searchTools = args => 
	createFetchAction({
		url: `/api/tools/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_TOOLS_REQUEST,
		onError: error => [searchToolsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchToolsSuccess(data)
	});

export const searchToolsSuccess = data => ({ type: SEARCH_TOOLS_SUCCESS, payload: { data } });
export const searchToolsFailure = error => ({ type: SEARCH_TOOLS_FAILURE, payload: { error } });

export const getTool = toolId =>
	createFetchAction({
		url: `/api/tools/${toolId}`,
		startAction: GET_TOOL_REQUEST,
		onError: error => [getToolFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getToolSuccess(data)
	});

export const getToolSuccess = data => ({ type: GET_TOOL_SUCCESS, payload: { data } });
export const getToolFailure = error => ({ type: GET_TOOL_FAILURE, payload: { error } });

export const saveTool = (tool, newFiles, isCommunity) => {
	const attachments = [...tool.images, ...tool.files],
		videos = JSON.stringify(tool.videos),
		links = JSON.stringify(tool.links);

	// Stringify links and videos in linked resources
	forEach(tool.linkedResources, r => {
		r.links = JSON.stringify(r.links);
		r.videos = JSON.stringify(r.videos);
	});

	const formData = new FormData();
	formData.append("Tool", encodeURIComponent(JSON.stringify({ ...tool, attachments, videos, links })));
	forEach(newFiles, i => formData.append("NewFiles", i));

	return createFormPostAction({
		url: "/api/tools",
		data: formData,
		startAction: SAVE_TOOL_REQUEST,
		onError: error => [saveToolFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveToolSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}tools/${data.object.toolId}`)];
			} else {
				return [saveToolSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}

export const saveToolSuccess = (data) => ({ type: SAVE_TOOL_SUCCESS, data });
export const saveToolFailure = (error) => ({ type: SAVE_TOOL_FAILURE, error });

export const deleteTool = (toolId, isCommunity) =>
	createPostAction({
		url: `/api/tools/${toolId}/delete`,
		startAction: DELETE_TOOL_REQUEST,
		onError: error => [deleteToolFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteToolSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}tools`)];
			} else {
				return [deleteToolSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteToolSuccess = data => ({ type: DELETE_TOOL_SUCCESS, data });
export const deleteToolFailure = error => ({ type: DELETE_TOOL_FAILURE, error });

export const favouriteTool = toolId =>
	createPostAction({
		url: `/api/tools/${toolId}/favourite`,
		startAction: FAVOURITE_TOOL_REQUEST,
		startActionData: { toolId },
		onError: error => [favouriteToolFailure(error, toolId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteToolSuccess(data, toolId)];
			} else {
				return [favouriteToolSuccess(data, toolId), showErrorNotification(data.message)];
			}
		}
	});

export const unfavouriteTool = toolId =>
	createPostAction({
		url: `/api/tools/${toolId}/unfavourite`,
		startAction: FAVOURITE_TOOL_REQUEST,
		startActionData: { toolId },
		onError: error => [favouriteToolFailure(error, toolId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteToolSuccess(data, toolId)];
			} else {
				return [favouriteToolSuccess(data, toolId), showErrorNotification(data.message)];
			}
		}
	});

export const favouriteToolSuccess = (data, toolId) => ({ type: FAVOURITE_TOOL_SUCCESS, data, toolId });
export const favouriteToolFailure = (error, toolId) => ({ type: FAVOURITE_TOOL_FAILURE, error, toolId });

export const getTopTools = () =>
	createFetchAction({
		url: "/api/tools/top",
		startAction: GET_TOOLS_REQUEST,
		onError: error => [getToolsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getToolsSuccess(data)
	});

export const getSubscribedTools = limit =>
	createFetchAction({
		url: `/api/tools/subscribed?limit=${limit}`,
		startAction: GET_TOOLS_REQUEST,
		onError: error => [getToolsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getToolsSuccess(data)
	});

export const searchSubscribedTools = args => 
	createFetchAction({
		url: `/api/tools/subscribed/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: GET_TOOLS_REQUEST,
		onError: error => [getToolsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getToolsSuccess(data)
	});

const formatTool = tool => ({
	...tool,
	images: tool.attachments ? tool.attachments.filter(a => a.attachmentType === "image") : [],
	files: tool.attachments ? tool.attachments.filter(a => a.attachmentType !== "image") : [],
	videos: tool.videos ? JSON.parse(tool.videos) : [],
	links: tool.links ? JSON.parse(tool.links) : [],
	method: DOMPurify.sanitize(tool.method)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_TOOLS:
			return { ...state, tools: [] };
		case GET_TOOLS_REQUEST:
			return { ...state, tools: [], isLoading: true };
		case SEARCH_TOOLS_REQUEST:
			return { ...state, isLoading: true };
		case GET_TOOLS_SUCCESS:
		case SEARCH_TOOLS_SUCCESS:
			return { ...state, tools: map(action.payload.data, formatTool), isLoading: false };
		case GET_TOOL_REQUEST:
			return { ...state, tool: {}, isLoading: true }
		case GET_TOOL_SUCCESS:
			return { ...state, tool: formatTool(action.payload.data), isLoading: true };
		case SAVE_TOOL_REQUEST:
		case DELETE_TOOL_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_TOOL_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_TOOL_SUCCESS:
			return { ...state, tools: state.tools.filter(a => a.toolId !== action.data.objectId) };
		case FAVOURITE_TOOL_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				message: null,
				isFavouriting: { ...state.isFavouriting, [action.data.toolId]: true }
			};
		case FAVOURITE_TOOL_FAILURE:
			return {
				...state,
				saveResult: emptySaveResult,
				isFavouriting: { ...state.isFavouriting, [action.toolId]: false }
			};
		case FAVOURITE_TOOL_SUCCESS:
			const { toolId, isFavourite, success, message } = action.data;

			return {
				...state,
				tools: map(state.tools, t => t.toolId === toolId ? { ...t, isFavourite } : t),
				tool: state.tool.toolId === toolId ? { ...state.tool, isFavourite } : state.tool,
				saveResult: { success, message },
				isFavouriting: { ...state.isFavouriting, [action.toolId]: false }
			}
		default:
			return state;
	}
};
