import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIP, saveIP, deleteIP, isLoading } from '../../store/ipRegister';
import { canEditIP } from '../../store/security';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditIP from './EditIP';
import { revokeAccess } from '../../store/context';

const EditIPController = props => {
	const ipId = Number(props.match.params.ipId);

	React.useEffect(() => props.getIP(ipId), [ipId]);

	React.useEffect(() => {
		if (props.ip.ipId === ipId && !props.canEdit) props.revokeAccess();
	}, [props.ip.ipId]);

	return <PageLayout title="Edit IP" pageContent={
		<EditIP 
			ip={props.ip} 
			loading={props.loading} 
			onSave={props.saveIP} 
			onDelete={props.deleteIP} 
			errorColour={props.errorColour} 
		/>
	} breadcrumbs={[{ label: "View all IP", path: "/ip" }, "Edit IP"]} />;
};

EditIPController.propTypes = {
	ip: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	saveIP: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	ip: state.ipRegister.ip,
	canEdit: canEditIP(state),
	loading: isLoading(state),
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getIP: ipId => dispatch(getIP(ipId)),
	saveIP: ip => dispatch(saveIP(ip)),
	deleteIP: ipId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this IP?",
		onOk: () => dispatch(deleteIP(ipId))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditIPController);
