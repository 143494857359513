import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import ResourceCard from './CourseResourceCard';
import LDCourseSession from './LDCourseSession';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import ReadOnlyField from '../widgets/ReadOnlyField';
import { default as formatDate } from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';

/*
const form = {
	initValues: props => props.ldCourse,
	fields: [
		{
			name: "code",
			label: "Code",
			required: false,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "title",
			label: "Title",
			required: true,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "program",
			label: "Program",
			required: false,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor",
			required: false,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "objectives",
			label: "Learning Objectives",
			required: false,
			type: "dragList",
			simple: true,
			addToEnd: true,
			itemTemplate: { objective: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12}>
						<TextField label="Objective" value={item.objective} onChange={e => updateItem(e.target.value, "objective")} />
					</Grid>
				</Grid>,
			disabled: props => !props.canEdit
		},
		{
			name: "startDate",
			label: "Start Date",
			required: false,
			type: "date",
			disabled: props => !props.canEdit
		},
		{
			name: "endDate",
			label: "End Date",
			required: false,
			type: "date",
			disabled: props => !props.canEdit
		}
	]
};
*/

const LDCourse = props => {
	const loading = props.loading;
	const course = props.ldCourse || { resources: [], sessions: [] };
	const resources = course ? course.resources : [];
	const orderedSessions = orderBy(course ? course.sessions : [], s => s.sessionDate);
	const startDate = parseISO(course.startDate);
	const endDate = parseISO(course.endDate);

	return (
		<React.Fragment>
			<Card 
				title={`View L&D Course`} 
				className="edit-ld-course" 
				loading={loading}
				titleRightContent={
					<Button onClick={window.print}>
						<PrintIcon style={{ marginRight: 5 }} />Print
					</Button>
				}
			>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} lg={4}>{<ReadOnlyField value={course.code} label="Code" />}</Grid>
					<Grid item xs={12} sm={6} lg={4}>{<ReadOnlyField value={course.title} label="Title" />}</Grid>
					<Grid item xs={12} sm={6} lg={4}>{<ReadOnlyField value={course.program} label="Program" />}</Grid>
					<Grid item xs={12}>
						<span className="h5">Description</span>
						<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: course.description }}></div>
					</Grid>
					<Grid item xs={12}>
						<span className="h5">Objectives</span>
						<ul>
							{map(course.objectives, (o) => 
								<li>{o.objective}</li>
							)}
						</ul>
					</Grid>
					<Grid item xs={12} sm={6} lg={6}>{<ReadOnlyField value={isValid(startDate) ? formatDate(startDate, "do MMMM yyyy") : ""} label="Start Date" />}</Grid>
					<Grid item xs={12} sm={6} lg={6}>{<ReadOnlyField value={isValid(endDate) ? formatDate(endDate, "do MMMM yyyy") : ""} label="End Date" />}</Grid>
				</Grid>

					<span className="h3" style={{ position: 'relative', minHeight: '30px', marginTop: '20px' }}>
						Attachments
					</span>
					{resources.length > 0 &&
						<Grid container spacing={3}>
							{map(resources, (r, i) =>
								<Grid key={i} item xs={4}>
									<ResourceCard 
										canEdit={false}
										courseResource={r} 
										onDelete={() => {
											props.updateValues({ resources: resources.filter((r, i2) => i2 !== i)})
										}}
									/>
								</Grid>
							)}
						</Grid>
					}
			</Card>
			<span className="h3">Sessions</span>
			{map(orderedSessions, (s, i) =>
				<LDCourseSession
					key={i}
					session={s}
				/>
			)}
		</React.Fragment>
	);
};

LDCourse.propTypes = { 
	ldCourse: PropTypes.object,
	loading: PropTypes.bool.isRequired
};

LDCourse.defaultProps = {
	ldCourse: {}
};

export default LDCourse;
