import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditProfileGroup from './EditProfileGroup';
import { saveProfileGroup, isLoading } from '../../store/skillsProfiles';
import PageLayout from '../PageLayout';

const CreateProfileGroupController = props =>
	<PageLayout title="Create profile group" pageContent={
		<EditProfileGroup saveGroup={props.saveGroup} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Profile groups", path: "/admin/profile-groups" }, "Create profile group"]} />;

CreateProfileGroupController.propTypes = {
	saveGroup: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveGroup: (group, imageFile) => dispatch(saveProfileGroup(group, imageFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfileGroupController);
