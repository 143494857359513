import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ProblemStats from './ProblemStats';
import { getProblem, getEndorsements, getViews, isLoading } from '../../store/problems';
import { getQueryParameters } from '../../utils/utils';
import capitalise from 'lodash/capitalize';

const ProblemStatsController = props => {
	React.useEffect(() => {
		const { problemId } = props.match.params;

		props.getProblem(problemId);
		props.getEndorsements(problemId);
		props.getViews(problemId);
	}, [props.match.params.problemId]);

	return <PageLayout title={`${capitalise(props.single)} statistics`} pageContent={
		<ProblemStats
			problem={props.problem}
			tab={getQueryParameters(props.location.search).tab || "endorsements"}
			endorsements={props.endorsements}
			views={props.views}
			loading={props.loading}
			single={props.single}
		/>
	} breadcrumbs={[
		{ label: capitalise(props.plural), path: "/problems"},
		`${capitalise(props.single)} statistics`
	]} />;
};

ProblemStatsController.propTypes = { 
	problem: PropTypes.object.isRequired,
	endorsements: PropTypes.arrayOf(PropTypes.object).isRequired,
	views: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	problem: state.problems.problem,
	endorsements: state.problems.endorsements,
	views: state.problems.views,
	loading: isLoading(state),
	single: state.settings.single,
	plural: state.settings.plural
});

const mapDispatchToProps = dispatch => ({
	getProblem: problemId => dispatch(getProblem(problemId)),
	getEndorsements: problemId => dispatch(getEndorsements(problemId)),
	getViews: problemId => dispatch(getViews(problemId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProblemStatsController);
