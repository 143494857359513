import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';
import DOMPurify from 'dompurify';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [],
	pageNum: null,
	pageSize: null,
	total: null,
	totalPages: null
};

export const capabilityThemes = [
	{ label: 'Asset Management', value: 'Asset Management', imageUrl: '/images/capability-themes/Asset Management.png' },
	{ label: 'Contracting & Procurement', value: 'Contracting & Procurement', imageUrl: '/images/capability-themes/procurement.png' },
	{ label: 'Digital', value: 'Digital', imageUrl: '/images/capability-themes/Digital.png' },
	{ label: 'ESG', value: 'ESG', imageUrl: '/images/capability-themes/ESG.png' },
	{ label: 'Organisational', value: 'Organisational', imageUrl: '/images/capability-themes/Organisational.png' },
	{ label: 'Project Management', value: 'Project Management', imageUrl: '/images/capability-themes/Project Management.png' },
	{ label: 'Service Design', value: 'Service Design', imageUrl: '/images/capability-themes/Service Design.png' },
];

const initialState = {
	saveResult: emptySaveResult,
	microLearns: [],
	microLearn: {}
};

const CLEAR_MICROLEARNS = "CLEAR_MICROLEARNS";
const GET_MICROLEARNS_REQUEST = "GET_MICROLEARNS_REQUEST";
const GET_MICROLEARNS_SUCCESS = "GET_MICROLEARNS_SUCCESS";
const GET_MICROLEARNS_FAILURE = "GET_MICROLEARNS_FAILURE";
const SEARCH_MICROLEARNS_REQUEST = "SEARCH_MICROLEARNS_REQUEST";
const SEARCH_MICROLEARNS_SUCCESS = "SEARCH_MICROLEARNS_SUCCESS";
const SEARCH_MICROLEARNS_FAILURE = "SEARCH_MICROLEARNS_FAILURE";
const GET_MICROLEARN_REQUEST = "GET_MICROLEARN_REQUEST";
const GET_MICROLEARN_SUCCESS = "GET_MICROLEARN_SUCCESS";
const GET_MICROLEARN_FAILURE = "GET_MICROLEARN_FAILURE";
const SAVE_MICROLEARN_REQUEST = "SAVE_MICROLEARN_REQUEST";
const SAVE_MICROLEARN_SUCCESS = "SAVE_MICROLEARN_SUCCESS";
const SAVE_MICROLEARN_FAILURE = "SAVE_MICROLEARN_FAILURE";
const DELETE_MICROLEARN_REQUEST = "DELETE_MICROLEARN_REQUEST";
const DELETE_MICROLEARN_SUCCESS = "DELETE_MICROLEARN_SUCCESS";
const DELETE_MICROLEARN_FAILURE = "DELETE_MICROLEARN_FAILURE";


const FAVOURITE_MICROLEARN_REQUEST = "FAVOURITE_MICROLEARN_REQUEST";
const FAVOURITE_MICROLEARN_SUCCESS = "FAVOURITE_MICROLEARN_SUCCESS";
const FAVOURITE_MICROLEARN_FAILURE = "FAVOURITE_MICROLEARN_FAILURE";
const UNFAVOURITE_MICROLEARN_REQUEST = "UNFAVOURITE_MICROLEARN_REQUEST";
const UNFAVOURITE_MICROLEARN_SUCCESS = "UNFAVOURITE_MICROLEARN_SUCCESS";
const UNFAVOURITE_MICROLEARN_FAILURE = "UNFAVOURITE_MICROLEARN_FAILURE";
const RATE_MICROLEARN_REQUEST = "RATE_MICROLEARN_REQUEST";
const RATE_MICROLEARN_SUCCESS = "RATE_MICROLEARN_SUCCESS";
const RATE_MICROLEARN_FAILURE = "RATE_MICROLEARN_FAILURE";

export const isLoading = createLoadingSelector(["GET_MICROLEARNS", "SEARCH_MICROLEARNS", "GET_MICROLEARN", "SAVE_MICROLEARN"]);
export const isRatingLoading = createLoadingSelector(["RATE_MICROLEARN"]);
export const isFavouriteLoading = createLoadingSelector(["FAVOURITE_MICROLEARN", "UNFAVOURITE_MICROLEARN"]);

export const clearMicroLearns = () => ({ type: CLEAR_MICROLEARNS });

export const getMicroLearns = (pageNum, pageSize) =>
	createFetchAction({
		url: `/api/microlearn?pageNum=${pageNum}&pageSize=${pageSize}`,
		startAction: GET_MICROLEARNS_REQUEST,
		onError: error => [getMicroLearnsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMicroLearnsSuccess(data)
	});

export const getMicroLearnsSuccess = data => ({ type: GET_MICROLEARNS_SUCCESS, payload: { data } });
export const getMicroLearnsFailure = error => ({ type: GET_MICROLEARNS_FAILURE, payload: { error } });

export const searchMicroLearns = (args, pageNum, pageSize) => 
	createFetchAction({
		url: `/api/microlearn/search?PageNum=${pageNum}&PageSize=${pageSize}&${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_MICROLEARNS_REQUEST,
		onError: error => [searchMicroLearnsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchMicroLearnsSuccess(data)
	});

export const searchMicroLearnsSuccess = data => ({ type: SEARCH_MICROLEARNS_SUCCESS, payload: { data } });
export const searchMicroLearnsFailure = error => ({ type: SEARCH_MICROLEARNS_FAILURE, payload: { error } });

export const getMicroLearn = microLearnId =>
	createFetchAction({
		url: `/api/microlearn/${microLearnId}`,
		startAction: GET_MICROLEARN_REQUEST,
		onError: error => [getMicroLearnFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getMicroLearnSuccess(data)
	});

export const getMicroLearnSuccess = data => ({ type: GET_MICROLEARN_SUCCESS, payload: { data } });
export const getMicroLearnFailure = error => ({ type: GET_MICROLEARN_FAILURE, payload: { error } });

export const saveMicroLearn = (microLearn, imageFile, videoFile, newFiles) => {
	const formData = new FormData();
	formData.append("MicroLearn", encodeURIComponent(JSON.stringify({
		...microLearn
	})));

	if (imageFile) formData.append("ImageFile", imageFile);
	if (videoFile) formData.append("VideoFile", videoFile);
	newFiles.forEach(f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/microlearn",
		data: formData,
		startAction: SAVE_MICROLEARN_REQUEST,
		onError: error => [saveMicroLearnFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveMicroLearnSuccess(data), showSuccessNotification(data.message), changeLocation(`/resources/${data.object.microLearnId}`)];
			} else {
				return [saveMicroLearnSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveMicroLearnSuccess = data => ({ type: SAVE_MICROLEARN_SUCCESS, data });
export const saveMicroLearnFailure = error => ({ type: SAVE_MICROLEARN_FAILURE, error });

export const deleteMicroLearn = (microLearnId) =>
	createPostAction({
		url: `/api/microlearn/${microLearnId}/delete`,
		startAction: DELETE_MICROLEARN_REQUEST,
		onError: error => [deleteMicroLearnFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteMicroLearnSuccess(data), showSuccessNotification(data.message), changeLocation(`/resources`)];
			} else {
				return [deleteMicroLearnSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteMicroLearnSuccess = data => ({ type: DELETE_MICROLEARN_SUCCESS, data });
export const deleteMicroLearnFailure = error => ({ type: DELETE_MICROLEARN_FAILURE, error });


export const favouriteMicroLearn = microLearnId =>
	createPostAction({
		url: `/api/microlearn/${microLearnId}/favourite`,
		startAction: FAVOURITE_MICROLEARN_REQUEST,
		startActionData: { microLearnId },
		onError: error => [favouriteMicroLearnFailure(error, microLearnId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteMicroLearnSuccess(data, microLearnId), showSuccessNotification(data.message)];
			} else {
				return [favouriteMicroLearnSuccess(data, microLearnId), showErrorNotification(data.message)];
			}
		}
	});
export const favouriteMicroLearnSuccess = (data, microLearnId) => ({ type: FAVOURITE_MICROLEARN_SUCCESS, data, microLearnId });
export const favouriteMicroLearnFailure = (error, microLearnId) => ({ type: FAVOURITE_MICROLEARN_FAILURE, error, microLearnId });

export const unfavouriteMicroLearn = microLearnId =>
	createPostAction({
		url: `/api/microlearn/${microLearnId}/unfavourite`,
		startAction: UNFAVOURITE_MICROLEARN_REQUEST,
		startActionData: { microLearnId },
		onError: error => [unfavouriteMicroLearnFailure(error, microLearnId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [unfavouriteMicroLearnSuccess(data, microLearnId), showSuccessNotification(data.message)];
			} else {
				return [unfavouriteMicroLearnSuccess(data, microLearnId), showErrorNotification(data.message)];
			}
		}
	});
export const unfavouriteMicroLearnSuccess = (data, microLearnId) => ({ type: UNFAVOURITE_MICROLEARN_SUCCESS, data, microLearnId });
export const unfavouriteMicroLearnFailure = (error, microLearnId) => ({ type: UNFAVOURITE_MICROLEARN_FAILURE, error, microLearnId });

export const rateMicroLearn = (microLearnId, rating) =>
	createPostAction({
		url: `/api/microlearn/${microLearnId}/rate?rating=${rating}`,
		startAction: RATE_MICROLEARN_REQUEST,
		startActionData: { microLearnId },
		onError: error => [rateMicroLearnFailure(error, microLearnId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [rateMicroLearnSuccess(data, microLearnId, rating), showSuccessNotification(data.message)];
			} else {
				return [rateMicroLearnSuccess(data, microLearnId, rating), showErrorNotification(data.message)];
			}
		}
	});
export const rateMicroLearnSuccess = (data, microLearnId, rating) => ({ type: RATE_MICROLEARN_SUCCESS, data, microLearnId, rating });
export const rateMicroLearnFailure = (error, microLearnId) => ({ type: RATE_MICROLEARN_FAILURE, error, microLearnId });


const formatMicroLearn = microLearn => ({ 
	...microLearn, 
	links: microLearn.links ? JSON.parse(microLearn.links) : [],
	videos: microLearn.videos ? JSON.parse(microLearn.videos) : [],
	content: DOMPurify.sanitize(microLearn.content)
});

export default (state = initialState, action) => {
	let microLearnId;

	switch (action.type) {
		case CLEAR_MICROLEARNS:
			return { ...state, microLearns: [] };
		case GET_MICROLEARNS_REQUEST:
			return { ...state, microLearns: [], isLoading: true };
		case SEARCH_MICROLEARNS_REQUEST:
			return { ...state, isLoading: true };
		case GET_MICROLEARNS_SUCCESS:
		case SEARCH_MICROLEARNS_SUCCESS:
			return { 
				...state, 
				microLearns: map(action.payload.data.data, formatMicroLearn), 
				isLoading: false,
				pageNum: action.payload.data.pageNum,
				pageSize: action.payload.data.pageSize,
				total: action.payload.data.total,
				totalPages: action.payload.data.totalPages
			};
		case GET_MICROLEARN_REQUEST:
			return { ...state, microLearn: {}, isLoading: true };
		case GET_MICROLEARN_SUCCESS:
			return { ...state, isLoading: true, microLearn: formatMicroLearn(action.payload.data) };
		case SAVE_MICROLEARN_REQUEST:
		case DELETE_MICROLEARN_REQUEST:
		case FAVOURITE_MICROLEARN_REQUEST:
		case UNFAVOURITE_MICROLEARN_REQUEST:
		case RATE_MICROLEARN_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_MICROLEARN_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { microLearn: formatMicroLearn(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case FAVOURITE_MICROLEARN_SUCCESS:
			microLearnId = action.microLearnId;

			return {
				...state,
				microLearns: map(state.microLearns, t => t.microLearnId === microLearnId ? { ...t, isFavourite: true } : t),
				microLearn: state.microLearn.microLearnId === microLearnId ? { ...state.microLearn, isFavourite: true } : state.microLearn
			};
		case UNFAVOURITE_MICROLEARN_SUCCESS:
			microLearnId = action.microLearnId;

			return {
				...state,
				microLearns: map(state.microLearns, t => t.microLearnId === microLearnId ? { ...t, isFavourite: false } : t),
				microLearn: state.microLearn.microLearnId === microLearnId ? { ...state.microLearn, isFavourite: false } : state.microLearn
			};
		case RATE_MICROLEARN_SUCCESS:
			microLearnId = action.microLearnId;

			return {
				...state,
				microLearns: map(state.microLearns, t => t.microLearnId === microLearnId ? { ...t, rating: action.rating } : t),
				microLearn: state.microLearn.microLearnId === microLearnId ? { ...state.microLearn, rating: action.rating } : state.microLearn
			};
		case DELETE_MICROLEARN_SUCCESS:
			return { ...state, microLearns: state.microLearns.filter(a => a.microLearnId !== action.data.objectId) };
		default:
			return state;
	}
};
