import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';

const ViewTeam = props => {
	const { team } = props;
	
	return props.loading || isEmpty(team) ? <CircularProgress size={24} /> : (
		<Card className="team-details">
			<div className="header">
					<h2>{team.name}</h2>
			</div>
			<section>
				<span className="h5">Description</span>
				{!team.description ? "No description provided." : 
					<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: team.description }} />
				}
				<br />
			</section>
			{team.teamLeaderName &&
				<div style={{ marginTop: 10 }}>
					<strong>Team Leader: {team.teamLeaderName}</strong><br />
				</div>
			} 

            <DataTable data={team.members}>
				<DataTableColumn name="name" label="Member" />
				<DataTableColumn name="skillsProfileName" label="Skills Profile" />
			</DataTable>
			{props.canEdit && team.teamId > 0 && 
				<ButtonLink to={`/teams/edit/${team.teamId}`} style={{ marginTop: 20 }}>
					Edit team
				</ButtonLink>
			}
		</Card>
	);
};

ViewTeam.propTypes = {
	team: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool
};

ViewTeam.defaultProps = {
	canEdit: false
};

export default ViewTeam;