import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { getActivities } from '../../store/activities';
import Button from './Button';
import Card from './Card';
import map from 'lodash/map';
import Tag from '../widgets/Tag';
import ActivityIcon from '@mui/icons-material/History';

const ActivityCard = ({ activity, onClick }) =>
	<Card className="activity-card" onClick={onClick}>
		<div className="h3 name">
			<ActivityIcon />
			{activity.name}
		</div>
		<div className="summary">{activity.summary}</div>
		<div className="tags">
			{map(activity.tags, (t, i) => <Tag key={i} label={t.name} />)}
		</div>
	</Card>;

const ActivityPicker = React.memo(props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth="md"
        TransitionProps={{
            onEnter: props.getActivities
        }}>
		<DialogTitle>Select Activity</DialogTitle>
		<DialogContent>
			<Grid container spacing={3}>
				{map(props.activities, (a, i) =>
					<Grid key={i} item xs={4}>
						<ActivityCard activity={a} onClick={() => props.onOk(a)} />
					</Grid>
				)}
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose}>Cancel</Button>
		</DialogActions>
	</Dialog>
);

ActivityPicker.propTypes = {
	open: PropTypes.bool.isRequired,
	onOk: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

ActivityPicker.defaultProps = {
	open: false,
	onOk: () => {},
	onClose: () => {}
};

const mapStateToProps = state => ({
	activities: state.activities.activities
});

const mapDispatchToProps = dispatch => ({
	getActivities: () => dispatch(getActivities())
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPicker);