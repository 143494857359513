import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfileGroups, isLoading } from '../../store/skillsProfiles';
import { canEditProfileGroup } from '../../store/security';
import PageLayout from '../PageLayout';
import ProfileGroups from './ProfileGroups';

const ProfileGroupsController = props => {
	React.useEffect(props.getProfileGroups, []);

	return (
		<PageLayout 
			title="Profile groups" 
			pageContent={
				<ProfileGroups 
					groups={props.groups} 
					canEdit={props.canEdit} 
					loading={props.loading} 
					primaryColour={props.primaryColour} 
				/>
			} 
			breadcrumbs={["Profile groups"]} 
		/>
	);
};

ProfileGroupsController.propTypes = { 
	groups: PropTypes.array.isRequired,
	canEdit: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	groups: state.skillsProfiles.groups,
	canEdit: canEditProfileGroup(state),
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getProfileGroups: () => dispatch(getProfileGroups())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGroupsController);
