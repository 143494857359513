import { createFetchAction } from '../utils/reducer-utils';
import { tryParseJson } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification } from '../store/notifications';
import map from 'lodash/map';

const initialState = {
	logs: []
};

const CLEAR_LOGS = 'CLEAR_LOGS';
const GET_LOGS_REQUEST = 'GET_LOGS_REQUEST';
const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
const GET_LOGS_FAILURE = 'GET_LOGS_FAILURE';

export const isLoading = createLoadingSelector(["GET_LOGS", "SEARCH_LOGS"]);

export const clearLogs = () => ({ type: CLEAR_LOGS });

export const getLogs = (start, end) => 
	createFetchAction({
		url: `/api/audits/logs?start=${start.toISOString()}&end=${end.toISOString()}`,
		startAction: GET_LOGS_REQUEST,
		onError: error => [getLogsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getLogsSuccess(data)
	});

export const searchLogs = query =>
	createFetchAction({
		url: `/api/audits/logs/search?query=${query}`,
		startAction: GET_LOGS_REQUEST,
		onError: error => [getLogsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getLogsSuccess(data)
	});

export const getLogsSuccess = data => ({ type: GET_LOGS_SUCCESS, payload: { data } });
export const getLogsFailure = error => ({ type: GET_LOGS_FAILURE, payload: { error } });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_LOGS:
			return { ...state, logs: null };
		case GET_LOGS_REQUEST:
			return { ...state, logs: [] }
		case GET_LOGS_SUCCESS:
			return {
				...state,
				isLoading: false,
				logs: map(action.payload.data, l => {
					if (l.details) l.details = tryParseJson(l.details);
					return l;
				})
			}
		case GET_LOGS_FAILURE:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};