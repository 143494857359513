import React from 'react';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
	list: {
		listStylePosition: 'inside',
		padding: 0,
		margin: 0
	}
});

const UpgradeOffersController = props => {
	const classes = props.classes;

	return (
		<PageLayout 
			title="Upgrade to Access" 
			pageContent={
				<div style={{
					maxWidth: '1000px',
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto'
				}}>
					<p style={{ }}>
						<span style={{ fontSize: 'large', marginRight: '10px', fontWeight: 'bold' }}>Offers</span>
					</p>
					<p style={{ textAlign: 'left' }}>
						Our <b>Offers</b> feature allows our Corporate members to post emerging market opportunities being:
						<ul className={classes.list} style={{ }}>
							<li>Requests for Quotation (RFQs)</li>
							<li>Expressions of Interest (EOIs)</li>
							<li>Tenders</li>
						</ul>
					</p>
					<p style={{ textAlign: 'left' }}>
						L&amp;D Suppliers are able to search available market offers in order to capture to supply opportunities. 
					</p>
					<p style={{ textAlign: 'left' }}>
						Our <b>Offers</b> feature allows L&amp;D providers to identify and form collaborations between suppliers around new and emerging supply opportunities from corporate buyers of L&amp;D services.
					</p>
					<p>
						The Offers feature is part of the Opportunities application which is now accessible to subscribers.
					</p>
					<ButtonLink 
						to="/pricing" 
					>Upgrade</ButtonLink>
				</div>
			} 
			breadcrumbs={[
				"Upgrade"
			]} />
	);
};

UpgradeOffersController.propTypes = {
};

export default withStyles(UpgradeOffersController, styles, { withTheme: true });
