import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getChannels } from '../../store/channels';
import { saveDiscussion } from '../../store/discussion';
import { canEditTool, canEditCommunityTool } from '../../store/security';
import { getTool, isLoading } from '../../store/tools';
import PageLayout from '../PageLayout';
import ViewTool from './ViewTool';

const ViewToolController = props => {
	React.useEffect(() => props.getTool(props.match.params.toolId), [props.match.params.toolId]);

	React.useEffect(props.getChannels, []);

	return <PageLayout title="View tool" pageContent={
		<ViewTool 
			tool={props.tool} 
			loading={props.loading} 
			canEdit={props.canEdit} 
			isCommunity={props.isCommunity} 
			channels={props.channels}
			saveDiscussion={props.saveDiscussion}
			primaryColour={props.primaryColour}
		/>
	} breadcrumbs={[{ label: "Tools", path: `/${props.isCommunity ? "my-community/" : ""}tools` }, "View tools"]} />;
};

ViewToolController.propTypes = {
	tool: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	channels: PropTypes.array.isRequired,
	getChannels: PropTypes.func.isRequired,
	saveDiscussion: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired
};

ViewToolController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	tool: state.tools.tool,
	loading: isLoading(state),
	canEdit: ownProps.isCommunity ? canEditCommunityTool(state) : canEditTool(state),
	isAdmin: state.context.isAdministrator,
	channels: state.channels.channels,
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getTool: toolId => dispatch(getTool(toolId)),
	getChannels: () => dispatch(getChannels()),
	saveDiscussion: discussion => dispatch(saveDiscussion(discussion))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewToolController);