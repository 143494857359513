import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActivityLogs from './ActivityLogs';
import PageLayout from '../PageLayout';
import { getLogs, isLoading } from '../../store/audits';
import addDays from 'date-fns/addDays';

const ActivityLogsController = props => {
	React.useEffect(() => {
		const end = new Date();
		props.getLogs(addDays(end, -7), end);
	}, []);

	return <PageLayout title="Activity logs" pageContent={
		<ActivityLogs
			logs={props.logs}
			getLogs={props.getLogs}
			loading={props.loading}
			single={props.single}
			plural={props.plural}
			primaryColour={props.primaryColour}
		/>
	} breadcrumbs={[{ label: "Users", path: "/users" }, "Activity logs"]} />;
};

ActivityLogsController.propTypes = {
	logs: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	logs: state.audits.logs,
	loading: isLoading(state),
	single: state.settings.single,
	plural: state.settings.plural,
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getLogs: (start, end) => dispatch(getLogs(start, end))
	// Filtering server-side is redundant due to dynamic client-side filtering
	// searchLogs: search => dispatch(searchLogs(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogsController);
