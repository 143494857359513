import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { favouriteTool, unfavouriteTool } from '../../store/tools';

const FavouriteButton = React.memo(props => {
	const { isFavourite, isActive, primaryColour } = props,
		Icon = isFavourite ? StarIcon : StarBorderIcon;

	return (
		<Icon 
			onClick={() => !isActive && props.favourite()} 
			style={{ color: primaryColour, cursor: "pointer", marginLeft: 5 }}
		/>
	);
});

FavouriteButton.propTypes = {
	toolId: PropTypes.number.isRequired,
	isFavourite: PropTypes.bool.isRequired,
	isActive: PropTypes.bool.isRequired,
	favourite: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	isActive: state.tools.isFavouriting[ownProps.toolId] || false,
	primaryColour: state.theme.primaryColour
});	

const mapDispatchToProps = (dispatch, ownProps) => ({
	favourite: () => dispatch(ownProps.isFavourite ? unfavouriteTool(ownProps.toolId) : favouriteTool(ownProps.toolId))
});

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteButton);
