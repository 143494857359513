import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	cloCourses: [],
	pageNum: null,
	pageSize: null,
	total: null,
	totalPages: null,
	cloCourse: {},
	importing: false,
	isFavouriting: {}
};

const CLEAR_CLO_COURSES = "CLEAR_CLO_COURSES";
const GET_CLO_COURSES_REQUEST = "GET_CLO_COURSES_REQUEST";
const GET_CLO_COURSES_SUCCESS = "GET_CLO_COURSES_SUCCESS";
const GET_CLO_COURSES_FAILURE = "GET_CLO_COURSES_FAILURE";
const SEARCH_CLO_COURSES_REQUEST = "SEARCH_CLO_COURSES_REQUEST";
const SEARCH_CLO_COURSES_SUCCESS = "SEARCH_CLO_COURSES_SUCCESS";
const SEARCH_CLO_COURSES_FAILURE = "SEARCH_CLO_COURSES_FAILURE";
const GET_CLO_COURSE_REQUEST = "GET_CLO_COURSE_REQUEST";
const GET_CLO_COURSE_SUCCESS = "GET_CLO_COURSE_SUCCESS";
const GET_CLO_COURSE_FAILURE = "GET_CLO_COURSE_FAILURE";
const SAVE_CLO_COURSE_REQUEST = "SAVE_CLO_COURSE_REQUEST";
const SAVE_CLO_COURSE_SUCCESS = "SAVE_CLO_COURSE_SUCCESS";
const SAVE_CLO_COURSE_FAILURE = "SAVE_CLO_COURSE_FAILURE";
const REQUEST_CLO_COURSE_REQUEST = "REQUEST_CLO_COURSE_REQUEST";
const REQUEST_CLO_COURSE_SUCCESS = "REQUEST_CLO_COURSE_SUCCESS";
const REQUEST_CLO_COURSE_FAILURE = "REQUEST_CLO_COURSE_FAILURE";
const DELETE_CLO_COURSE_REQUEST = "DELETE_CLO_COURSE_REQUEST";
const DELETE_CLO_COURSE_SUCCESS = "DELETE_CLO_COURSE_SUCCESS";
const DELETE_CLO_COURSE_FAILURE = "DELETE_CLO_COURSE_FAILURE";
const COPY_CLO_COURSE_REQUEST = "COPY_CLO_COURSE_REQUEST";
const COPY_CLO_COURSE_SUCCESS = "COPY_CLO_COURSE_SUCCESS";
const COPY_CLO_COURSE_FAILURE = "COPY_CLO_COURSE_FAILURE";
const FAVOURITE_CLO_COURSE_REQUEST = "FAVOURITE_CLO_COURSE_REQUEST";
const FAVOURITE_CLO_COURSE_SUCCESS = "FAVOURITE_CLO_COURSE_SUCCESS";
const FAVOURITE_CLO_COURSE_FAILURE = "FAVOURITE_CLO_COURSE_FAILURE";

export const isLoading = createLoadingSelector(["GET_CLO_COURSES", "SEARCH_CLO_COURSES", "GET_TOP_CLO_COURSES", "SAVE_CLO_COURSE", "REQUEST_CLO_COURSE", "COPY_CLO_COURSE"]);

export const clearCLO_Courses = () => ({ type: CLEAR_CLO_COURSES });

export const getCLO_Courses = (pageNum, pageSize) =>
	createFetchAction({
		url: `/api/clo-courses?pageNum=${pageNum}&pageSize=${pageSize}`,
		startAction: GET_CLO_COURSES_REQUEST,
		onError: error => [getCLO_CoursesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCLO_CoursesSuccess(data)
	});

export const getCLO_CoursesSuccess = data => ({ type: GET_CLO_COURSES_SUCCESS, payload: { data } });
export const getCLO_CoursesFailure = error => ({ type: GET_CLO_COURSES_FAILURE, payload: { error } });

const GET_TOP_CLO_COURSES_REQUEST = "GET_TOP_CLO_COURSES_REQUEST";
const GET_TOP_CLO_COURSES_SUCCESS = "GET_TOP_CLO_COURSES_SUCCESS";
const GET_TOP_CLO_COURSES_FAILURE = "GET_TOP_CLO_COURSES_FAILURE";
export const getTopCLO_Courses = (count, acceleratorCourses) =>
	createFetchAction({
		url: `/api/clo-courses/top?count=${count}${(acceleratorCourses === true || acceleratorCourses === false) ? `&acceleratorCourses=${acceleratorCourses}` : ''}`,
		startAction: GET_TOP_CLO_COURSES_REQUEST,
		onError: error => [getTopCLO_CoursesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getTopCLO_CoursesSuccess(data)
	});
export const getTopCLO_CoursesSuccess = data => ({ type: GET_TOP_CLO_COURSES_SUCCESS, payload: { data } });
export const getTopCLO_CoursesFailure = error => ({ type: GET_TOP_CLO_COURSES_FAILURE, payload: { error } });

export const searchCLO_Courses = (args, pageNum, pageSize) => 
	createFetchAction({
		url: `/api/clo-courses/search?PageNum=${pageNum}&PageSize=${pageSize}&${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_CLO_COURSES_REQUEST,
		onError: error => [searchCLO_CoursesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchCLO_CoursesSuccess(data)
	});

export const searchCLO_CoursesSuccess = data => ({ type: SEARCH_CLO_COURSES_SUCCESS, payload: { data } });
export const searchCLO_CoursesFailure = error => ({ type: SEARCH_CLO_COURSES_FAILURE, payload: { error } });

export const getCLO_Course = clO_CourseId =>
	createFetchAction({
		url: `/api/clo-courses/${clO_CourseId}`,
		startAction: GET_CLO_COURSE_REQUEST,
		onError: error => [getCLO_CourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCLO_CourseSuccess(data)
	});

export const getCLO_CourseSuccess = data => ({ type: GET_CLO_COURSE_SUCCESS, payload: { data } });
export const getCLO_CourseFailure = error => ({ type: GET_CLO_COURSE_FAILURE, payload: { error } });

export const saveCLO_Course = (cloCourse, image, newFiles, fromAdmin) => {
	const formData = new FormData();

	formData.append("CLO_Course", encodeURIComponent(JSON.stringify(cloCourse)));
	if (image) formData.append("Image", image);

	forEach(newFiles, f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/clo-courses",
		data: formData,
		startAction: SAVE_CLO_COURSE_REQUEST,
		onError: error => [saveCLO_CourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveCLO_CourseSuccess(data), showSuccessNotification(data.message), changeLocation("/clo-courses")];
			} else {
				return [saveCLO_CourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}

export const saveCLO_CourseSuccess = data => ({ type: SAVE_CLO_COURSE_SUCCESS, data });
export const saveCLO_CourseFailure = error => ({ type: SAVE_CLO_COURSE_FAILURE, error });

export const requestCLO_Course = cloCourse =>
	createFormPostAction({
		url: "/api/clo-courses/request",
		data: cloCourse,
		startAction: REQUEST_CLO_COURSE_REQUEST,
		onError: error => [requestCLO_CourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [requestCLO_CourseSuccess(data), showSuccessNotification(data.message), changeLocation("/clo-courses")];
			} else {
				return [requestCLO_CourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const requestCLO_CourseSuccess = data => ({ type: REQUEST_CLO_COURSE_SUCCESS, data });
export const requestCLO_CourseFailure = error => ({ type: REQUEST_CLO_COURSE_FAILURE, error });

export const deleteCLO_Course = (clO_CourseId, fromAdmin) =>
	createPostAction({
		url: `/api/clo-courses/${clO_CourseId}/delete`,
		startAction: DELETE_CLO_COURSE_REQUEST,
		onError: error => [deleteCLO_CourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteCLO_CourseSuccess(data), showSuccessNotification(data.message), changeLocation("/clo-courses")];
			} else {
				return [deleteCLO_CourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteCLO_CourseSuccess = data => ({ type: DELETE_CLO_COURSE_SUCCESS, data });
export const deleteCLO_CourseFailure = error => ({ type: DELETE_CLO_COURSE_FAILURE, error });

export const copyCLO_Course = (clO_CourseId, title, includeCompetencies) =>
	createPostAction({
		url: `/api/clo-courses/${clO_CourseId}/copy?title=${title}&includeCompetencies=${includeCompetencies}`,
		startAction: COPY_CLO_COURSE_REQUEST,
		onError: error => [copyCLO_CourseFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [copyCLO_CourseSuccess(data), showSuccessNotification(data.message), changeLocation(`/clo-courses/edit/${data.object}`)];
			} else {
				return [copyCLO_CourseSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const copyCLO_CourseSuccess = data => ({ type: COPY_CLO_COURSE_SUCCESS, data });
export const copyCLO_CourseFailure = error => ({ type: COPY_CLO_COURSE_FAILURE, error });


export const favouriteCLO_Course = clo_CourseId =>
	createPostAction({
		url: `/api/clo-courses/${clo_CourseId}/favourite`,
		startAction: FAVOURITE_CLO_COURSE_REQUEST,
		startActionData: { clo_CourseId },
		onError: error => [favouriteCLO_CourseFailure(error, clo_CourseId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteCLO_CourseSuccess(data, clo_CourseId)];
			} else {
				return [favouriteCLO_CourseSuccess(data, clo_CourseId), showErrorNotification(data.message)];
			}
		}
	});

export const unfavouriteCLO_Course = clo_CourseId =>
	createPostAction({
		url: `/api/clo-courses/${clo_CourseId}/unfavourite`,
		startAction: FAVOURITE_CLO_COURSE_REQUEST,
		startActionData: { clo_CourseId },
		onError: error => [favouriteCLO_CourseFailure(error, clo_CourseId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteCLO_CourseSuccess(data, clo_CourseId)];
			} else {
				return [favouriteCLO_CourseSuccess(data, clo_CourseId), showErrorNotification(data.message)];
			}
		}
	});

export const favouriteCLO_CourseSuccess = (data, clo_CourseId) => ({ type: FAVOURITE_CLO_COURSE_SUCCESS, data, clo_CourseId });
export const favouriteCLO_CourseFailure = (error, clo_CourseId) => ({ type: FAVOURITE_CLO_COURSE_FAILURE, error, clo_CourseId });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CLO_COURSES:
			return { ...state, cloCourses: [], cloCourse: {} };
		case GET_CLO_COURSES_REQUEST:
		case GET_TOP_CLO_COURSES_REQUEST:
			return { ...state, isLoading: true, cloCourses: [] };
		case SEARCH_CLO_COURSES_REQUEST:
		case REQUEST_CLO_COURSE_REQUEST:
		case REQUEST_CLO_COURSE_SUCCESS:
			return { ...state, isLoading: true };
		case GET_CLO_COURSES_SUCCESS:
		case SEARCH_CLO_COURSES_SUCCESS:
		case GET_TOP_CLO_COURSES_SUCCESS:
			return { 
				...state, 
				isLoading: false, 
				cloCourses: action.payload.data.data,
				pageNum: action.payload.data.pageNum,
				pageSize: action.payload.data.pageSize,
				total: action.payload.data.total,
				totalPages: action.payload.data.totalPages
			};
		case GET_CLO_COURSE_REQUEST:
			return { ...state, isLoading: true, cloCourse: {} };
		case GET_CLO_COURSE_SUCCESS:
			return { ...state, isLoading: false, cloCourse: action.payload.data };
		case SAVE_CLO_COURSE_REQUEST:
		case DELETE_CLO_COURSE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_CLO_COURSE_SUCCESS:
			return {
				...state,
				...(action.data.success && { cloCourse: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_CLO_COURSE_SUCCESS:
			return { ...state, cloCourses: state.cloCourses.filter(a => a.clO_CourseId !== action.data.objectId) };
		case FAVOURITE_CLO_COURSE_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				message: null,
				isFavouriting: { ...state.isFavouriting, [action.data.clO_CourseId]: true }
			};
		case FAVOURITE_CLO_COURSE_FAILURE:
			return {
				...state,
				saveResult: emptySaveResult,
				isFavouriting: { ...state.isFavouriting, [action.clO_CourseId]: false }
			};
		case FAVOURITE_CLO_COURSE_SUCCESS:
			const { clO_CourseId, isFavourite, success, message } = action.data;

			return {
				...state,
				cloCourses: map(state.cloCourses, t => t.clO_CourseId === clO_CourseId ? { ...t, isFavourite } : t),
				cloCourse: state.cloCourse.clO_CourseId === clO_CourseId ? { ...state.cloCourse, isFavourite } : state.cloCourse,
				saveResult: { success, message },
				isFavouriting: { ...state.isFavouriting, [action.clO_CourseId]: false }
			}
		default:
			return state;
	}
};
