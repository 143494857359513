import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import round from 'lodash/round';
import map from 'lodash/map';

const HighPriorityMissingCompetencies = (props) => {
	const { organisationId, setLoading, onChangeDepartment, colourPalette } = props;
	const [data, setData] = React.useState([]);
	const [departmentId, setDepartmentId] = React.useState(0);
	const by = departmentId > 0 ? 'position' : 'department';

	const loadData = (depId) => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/high-priority-missing-competencies?organisationId=${organisationId}&departmentId=${depId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		setDepartmentId(props.departmentId);
		if (organisationId) {
			loadData(props.departmentId);
		}
	}, [organisationId, props.departmentId]);
	const chartData = map(data || [], d => ({ ...d, missingPercent: (d.unitCount - d.competent) / d.unitCount * 100}));

	return (
		<Chart 
			toolbox={{
				myTool2: {
					show: (departmentId > 0),
					title: 'Drill Up',
					icon: 'path://M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z',
					onclick: () => {
						if (onChangeDepartment) {
							onChangeDepartment(0);
						} else if (departmentId > 0) {
							setDepartmentId(0);
							loadData(0);
						}
					}
				}
			}}
			option={{
				color: colourPalette,
				legend: {
					data: chartData.map(d => d[by])
				},
				xAxis: {
					splitLine: {
						lineStyle: {
							type: "dashed"
						}
					},
					name: "% Missing Competencies",
					nameLocation: "center",
					nameGap: "20"
				},
				yAxis: {
					splitLine: {
						lineStyle: {
							type: "dashed"
						}
					},
					scale: true,
					name: "Units",
					nameLocation: "center",
					nameGap: "30"
				},
				series: chartData.map(d => {
					return {
						name: d[by],
						data: [[round(d.missingPercent, 2), d.unitCount, d[by], round(d.averagePriority, 2), d.competent, d]],
						type: "scatter",
						symbolSize: function (data) {
							return Math.max(d.averagePriority * 20, 20);
						},
						label: {
							emphasis: {
								show: true,
								formatter: function (param) {
									return `${param.data[2]}\nUnits: ${param.data[1]}\nCompetent: ${param.data[4]}\n% Missing: ${param.data[0]}\nAverage Priority:${param.data[3]}`;
								},
								position: "top"
							}
						}
					};
				})
			}}
			style={{ height: "100%" }} 
			onEvents={{
				"click": by === "department" ? param => {
					if (onChangeDepartment) {
						onChangeDepartment(param.data[5].departmentId);
					} else if (departmentId === 0) {
						setDepartmentId(param.data[5].departmentId);
						loadData(param.data[5].departmentId);
					}
				} : undefined
			}}
		/>
	);
};

HighPriorityMissingCompetencies.propTypes = {
	organisationId: PropTypes.number.isRequired,
	departmentId: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired,
	onChangeDepartment: PropTypes.func,
	colourPalette: PropTypes.array
};

HighPriorityMissingCompetencies.defaultProps = {
	onChangeDepartment: null,
	colourPalette: undefined
};

export default HighPriorityMissingCompetencies;
