import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../../PageLayout';
import EditTag from './EditTag';
import { post } from '../../../utils/ajax';
import { emptySaveResult } from '../../../variables';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';
import { changeLocation } from '../../../store/location';

const defaultTag = {
	name: "",
	description: ""
};

const AddTagController = props => {
	const [state, setState] = React.useState({ isLoading: false, saveResult: emptySaveResult });

	const saveTag = (tag) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		
		post({
			url: "/api/tags",
			data: tag,
			onSuccess: data => {
				setState({ saveResult: data, isLoading: false });

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToEditUrl(data.object);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	return (
		<PageLayout 
			title="Add Tag" 
			pageContent={
				<EditTag
					canEdit
					tag={defaultTag}
					onSave={saveTag} 
					saveResult={state.saveResult}
					loading={state.isLoading}
					isNew
				/>
			} 
			breadcrumbs={[
				{ label: "Tags",  path: "/tags" }, 
				"Add Tag"
			]} 	
		/>
	);

};

AddTagController.propTypes = { 
	canSelectTenant: PropTypes.bool.isRequired,
	goToEditUrl: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	canSelectTenant: state.context.isGlobalAdministrator
});

const mapDispatchToProps = dispatch => ({
	goToEditUrl: tag => dispatch(changeLocation(`/tags/${tag.tagId}`)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTagController);
