
export const teamsDashboardWidgetsDefault = [
	{
		id: "widget-1",
		type: "TeamMemberList",
		title: ""
	},
	{
		id: "widget-2",
		type: "TeamSkillsStrengthProfile",
		title: "Team Skills Strength Profile"
	},
	{
		id: "widget-3",
		type: "TeamSkillsProfile",
		title: "Team Skills Profile"
	},
	{
		id: "widget-4",
		type: "TeamSkillsGapProfile",
		title: "Team Skills Gap Profile"
	},
	{
		id: "widget-5",
		type: "TeamPerformanceRiskProfile",
		title: "Team Performance Risk Profile"
	}
];
export const teamsDashboardLayoutDefault = [
	{
		"w":11, "h":1, "x":0, "y":0, "i":"1.1", "moved": false, "static": true
	},
	{
		"w":6,"h":1,"x":0,"y":1,"i":"team-selector","moved":false,"static":false
	},
	{ 
		"w":6,"h":4,"x":0,"y":1,"i":"widget-1" 
	},
	{ 
		"w":6,"h":6,"x":6,"y":0,"i":"widget-2" 
	},
	{ 
		"w":6,"h":7,"x":6,"y":7,"i":"widget-3" 
	},
	{ 
		"w":6,"h":4,"x":0,"y":2,"i":"widget-4" 
	},
	{ 
		"w":6,"h":4,"x":0,"y":7,"i":"widget-5" 
	}
];
