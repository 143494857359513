import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from './WizardStep';
import forEach from 'lodash/forEach';

const WizardSteps = ({ steps }) => 
		<ul className='wizard-steps'>
				{
					(() => {
						const elements = [];
						
						forEach(steps, (s) => {
							// if (elements.length > 0) {
							// 	elements.push(
							// 		<td><div className='divider' /></td>
							// 	);
							// }
							elements.push(
								<WizardStep 
									key={`wizard-step-${s.number}`} 
									number={s.number} 
									title={s.title} 
									current={s.current} 
									complete={s.complete} 
									disabled={s.disabled} 
									isLastStepDone={s.isLastStepDone}
									error={s.error} 
								/>
							);
						});

						return elements;
					})()

				}
		</ul>;

WizardSteps.propTypes = {
	steps: PropTypes.array.isRequired
};


export default WizardSteps;
