import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import ViewDiscussion from './ViewDiscussion';

const ViewDiscussionController = props => {
	const { discussionId } = props.match.params;

	return (
		<PageLayout title="View Discussion" loading={props.loading} pageContent={
			<ViewDiscussion discussionId={discussionId} isCommunity={props.isCommunity} />
		} breadcrumbs={[{ label: "Discussion", path: `/${props.isCommunity ? "my-community/" : ""}discussion` }, "View Discussion"]} />
	);
};

ViewDiscussionController.propTypes = {
	isCommunity: PropTypes.bool
};

ViewDiscussionController.defaultProps = {
	isCommunity: false
};

export default ViewDiscussionController;