import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Tooltip } from '@mui/material';
import { prettifyDate } from '../../utils/utils';
import MediaCard from '../widgets/MediaCard';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import AcademicLogo from '../images/insights/A icon-01.svg';
import IndustryLogo from '../images/insights/I icon.svg';
import MarketInsightLogo from '../images/insights/M 1.png';

const InsightCard = React.memo(props => {
	const { insight } = props,
		numChannels = insight.channels ? insight.channels.length : 0,
		numTags = insight.tags ? insight.tags.length : 0;

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<MediaCard 
				className="insight-card" 
				image={numChannels > 0 ? insight.channels[0].image : null} 
				link={`${props.isCommunity ? "/my-community" : ""}/insights/${insight.insightId}`} 
				title={insight.title}
			>
				<div className="insight-content-type" style={{ display: 'flex' }}>
					{insight.contentType === "Academic" && <img src={AcademicLogo} style={{ height: '50px' }} alt="Academic" />}
					{insight.contentType === "Industry" && <img src={IndustryLogo} style={{ height: '50px' }} alt="Industry" />}
					{insight.contentType === "MarketInsight" && <img src={MarketInsightLogo} style={{ height: '50px' }} alt="Market Insight" />}
					{insight.authors &&
						<div className="insight-authors" style={{ marginLeft: '10px', fontStyle: 'italic', fontSize: 'small' }}>
							{insight.authors}
						</div>
					}
				</div>
				<div className="description">
					{insight.description.length === 0 ? "No description provided." : insight.description}
				</div>
				<div className="dates">
					Published {prettifyDate(insight.createdAt)}
					{insight.updatedAt &&
						<React.Fragment>
							<br />
							Updated {prettifyDate(insight.updatedAt)}
						</React.Fragment>
					}
				</div>
				<div>
					{map(insight.channels, c => <Tag key={c.channelId} label={c.name} colour={props.primaryColour} />)}
					{numTags > 0 &&
						<Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{map(insight.tags, t => (t ? t.name : "") + "\n")}</div>}>
							<span>
								<Tag label={`${numTags} Tag${numTags > 1 ? "s" : ""}`} />
							</span>
						</Tooltip>
					}
					{insight.isBlog && <Tag label="Blog" />}
					{insight.isJournalArticle && <Tag label="Journal Article" />}
					{insight.isVideo && <Tag label="Video" />}
				</div>
			</MediaCard>
		</Grid>
	);
});

InsightCard.propTypes = {
	insight: PropTypes.object.isRequired,
	isCommunity: PropTypes.bool.isRequired
};

InsightCard.defaultProps = {
};

const mapStateToProps = state => ({
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightCard);