import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../../utils/forms';
import FormButtons from '../../widgets/FormButtons';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';

const form = {
	initValues: props => ({ ...props.tag }),
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			loadItems: {
				route: "tenants",
				mapItem: t => ({ label: t.name, value: t.tenantId })
			}
		},
		{
			name: "name",
			label: "Name",
			required: true,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "description",
			label: "Description",
			placeholder: "",
			multiline: true,
			disabled: props => !props.canEdit
		},
		{
			name: "category",
			label: "Category",
			disabled: props => !props.canEdit
		},
		{
			name: "scope",
			label: "Scope",
			disabled: props => !props.canEdit
		},
	]
};

const EditTag = props => {
	const { fields, values, loading, isNew } = props;

	const onSubmit = () => {
		if (props.validateFields()) {
			props.onSave({ ...values });
		}
	}

	return (
		<Card 
			title={`${isNew ? "Add" : "Edit"} Tag`} 
			className="edit-tag" 
			loading={loading}
		>
			<Form onSubmit={onSubmit}>
				<Grid container spacing={3}>
					{props.canSelectTenant && isNew && <Grid item xs={12}>{fields.tenantId()}</Grid>}
					<Grid item xs={12}>{fields.name()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.category()}</Grid>
					<Grid item xs={12}>{fields.scope()}</Grid>
				</Grid>
			</Form>
			{props.canEdit && 
				<FormButtons 
					loading={loading}
					success={props.saveResult.success}
					onSave={onSubmit}
					showDelete={!isNew}
					onDelete={() => props.onDelete(values)}
				/>
			}
		</Card>
	);
};

EditTag.propTypes = { 
	canEdit: PropTypes.bool,
	canSelectTenant: PropTypes.bool,
	tag: PropTypes.object,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditTag.defaultProps = {
	canEdit: false,
	canSelectTenant: false,
	tag: {},
	onDelete: () => null,
	isNew: false
};

export default withForm(form)(EditTag);
