import React from 'react';
import { Paper } from '@mui/material';

export default ({ count, label, icon, colour }) => (
	<Paper className="passport-stat">
		<div className="left-side" style={{ backgroundColor: colour, color: "white" }}>
			{icon}
		</div>
		<div className="right-side">
			<span className="stat-count">{count}</span>
			<div className="stat-label"><span>{label}</span></div>
		</div>
	</Paper>
);