import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import RowIcons from '../widgets/RowIcons';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	ipRow: {
		'&:hover': {
			borderColor: theme.palette.primary.main
		},
		'&.active': {
			borderColor: theme.palette.primary.main
		}
	}
});

const YesIcon = ({ colour }) => <DoneIcon style={{ color: colour, marginRight: 5 }} />,
	NoIcon = ({ colour }) => <ClearIcon style={{ color: colour, marginRight: 5 }} />;

const IPRow = React.memo(({ ip, deleteIP, successColour, errorColour, classes }) => 
	<div className={`ip-row ${classes.ipRow}`}>
		<span className="h5">
			<Link to={`/ip/${ip.ipId}`} className="text-themecolor">{ip.title}</Link>
			{ip.projectName && ` — ${ip.projectName}`}
		</span>
		<span>{ip.agency}, {ip.businessUnit}</span>
		<div className="row-footer">
			<span>{ip.commercialAvailable ? <YesIcon colour={successColour} /> : <NoIcon colour={errorColour} />} Available for Commercialisation</span>
			<span style={{ marginLeft: 10 }}>{ip.commercialApproved ? <YesIcon /> : <NoIcon />} Approved for Commercialisation</span>
			<span className="label label-rounded label-light-info pull-right">{ip.views} views</span>
			<RowIcons 
				type="IP" 
				editLink={`/ip/edit/${ip.ipId}`} 
				deleteFunc={() => deleteIP(ip.ipId)} 
			/>
		</div>
	</div>
);

IPRow.propTypes = {
	ip: PropTypes.shape({
		ipId: PropTypes.number,
		title: PropTypes.string,
		agency: PropTypes.string,
		businessUnit: PropTypes.string,
		projectContactPerson: PropTypes.string,
		email: PropTypes.string,
		commercialAvailable: PropTypes.bool,
		commercialApproved: PropTypes.bool,
		commercialApproverName: PropTypes.string,
		commercialApproverPhone: PropTypes.string,
		commercialApproverEmail: PropTypes.string,
		numArtifacts: PropTypes.number,
		numAgreements: PropTypes.number,
		numThirdParty: PropTypes.number,
		DateCreated: PropTypes.string,
		LastUpdate: PropTypes.string
	}).isRequired,
	isAdmin: PropTypes.bool,
	deleteIP: PropTypes.func.isRequired,
	errorColour: PropTypes.string.isRequired,
	successColour: PropTypes.string.isRequired
};

IPRow.defaultProps = {
	isAdmin: false,
	deleteIP: null
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour,
	successColour: state.theme.successColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(IPRow, styles));

