import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import uniqueId from 'lodash/uniqueId';

const ReadOnlyField = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("text-field-"));
	const inputProps = { ...props.inputProps };

	// Add minimum value to Number Input
	if (props.type === "number") inputProps.min = 0;

	return (
		<FormControl fullWidth={props.fullWidth} error={props.error} style={props.style}>
			{props.label &&
				<InputLabel 
					htmlFor={id}
					shrink={Boolean(props.placeholder) || props.type === "date" || undefined}
					{...props.InputLabelProps}
				>
					{props.label}
				</InputLabel>
			}
			<Input 
				className="dih-input"
				id={id}
				name={props.name}
				type={props.type}
				value={props.value}
				multiline={props.multiline}
				rowsMax={props.rowsMax}
				inputProps={{ "aria-label": props["aria-label"], ...inputProps }}
				startAdornment={props.startAdornment && <InputAdornment position="start">{props.startAdornment}</InputAdornment>}
				endAdornment={props.endAdornment && <InputAdornment position="end">{props.endAdornment}</InputAdornment>}
				readOnly={props.true}
				disableUnderline={props.disableUnderline}
				variant={props.variant}
			/>
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
		</FormControl>
	);
});

ReadOnlyField.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.bool,
	helpText: PropTypes.node,
	multiline: PropTypes.bool,
	rowsMax: PropTypes.number,
	startAdornment: PropTypes.node,
	endAdornment: PropTypes.node,
	inputProps: PropTypes.object,
	disableUnderline: PropTypes.bool,
	fullWidth: PropTypes.bool,
	variant: PropTypes.string
};

ReadOnlyField.defaultProps = {
	id: "",
	name: "",
	type: "text",
	label: "",
	placeholder: "",
	error: false,
	helpText: "",
	value: "",
	multiline: false,
	rowsMax: 4,
	startAdornment: "",
	endAdornment: "",
	inputProps: {},
	disableUnderline: false,
	fullWidth: true,
	variant: "standard"
};

export default ReadOnlyField;
