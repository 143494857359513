import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormHelperText } from '@mui/material';
import IconUploadButton from './IconUploadButton';
import Gallery from './Gallery';
import uniqueId from 'lodash/uniqueId';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

const ImageUploader = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("image-uploader-"));
	const { value, images: tempImages, single } = props,
		disabled = single ? Boolean(value) : value.length >= props.maxUploads;

	let images = [];
	if (!isEmpty(value)) {
		if (single) {
			if (tempImages) {
				images = [{ src: tempImages || value || "" }];
			} else {
				images = [{ src: value || "" }];
			}
		} else {
			images = map(value, image => {
				let src = tempImages[image.name] || image[props.srcProperty] || "";
				return { src: src };
			});
		}
	}
		
	// Store images in temp structure
	const upload = files => forEach(files, f => {
		props.addImage(f);
	});

	return (
		<div id={id} style={props.style}>
			<span className="h3">
				{props.label}{props.required && <span style={{ color: props.errorColour }}> *</span>}
				<IconUploadButton onChange={upload} aria-label="Add image" accept={[".jpg", ".jpeg", ".png"]} disabled={disabled} multiple={!single} />
			</span>
			<Gallery images={images} remove={props.removeImage} placeholder={props.placeholder} hidePlaceholder={Boolean(props.single)} />
			{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
		</div>
	);
});

ImageUploader.propTypes = {
	id: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	label: PropTypes.string,
	style: PropTypes.object,
	helpText: PropTypes.node,
	maxUploads: PropTypes.number,
	addImage: PropTypes.func.isRequired,
	removeImage: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	single: PropTypes.bool,
	srcProperty: PropTypes.string,
	required: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

ImageUploader.defaultProps = {
	id: "",
	value: [],
	label: "Images",
	style: {},
	helpText: "",
	maxUploads: 10,
	placeholder: undefined,
	single: false,
	required: false,
	srcProperty: 'location'
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader);
