import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import Form from '../widgets/Form';

const form = {
	fields: [
		{
			name: "newPassword",
			required: true,
			label: "New password",
			type: "password",
			validate: value => value.length >= 6 ? "" : "Password must be six or more characters"
		},
		{
			name: "confirmPassword",
			required: true,
			label: "Confirm password",
			type: "password",
			validate: (value, values) => value === values.newPassword ? "" : "Both passwords must be identical"
		},
	]
};

const ChangePassword = props => {
	const handleSubmit = () => {
		if (props.validateFields()) {
			props.onSubmit(props.values.newPassword);
			props.resetForm();
		}
	}

	return (
		<Form onSubmit={handleSubmit}>
			<Grid container spacing={3} style={{ marginBottom: 20 }}>
				<Grid item xs={12} sm={6}>{props.fields.newPassword()}</Grid>
				<Grid item xs={12} sm={6}>{props.fields.confirmPassword()}</Grid>
			</Grid>
			<Button type="submit" loading={props.loading}>Update password</Button>
		</Form>
	);
};

ChangePassword.props = {
	onSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

export default withForm(form)(ChangePassword);