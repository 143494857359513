import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFeature, isLoading } from '../../store/features';
import { revokeAccess } from '../../store/context';
import { prettifyDate, parseYoutube } from '../../utils/utils';
import Card from '../widgets/Card';
import Button from '../widgets/Button';
import PageLayout from '../PageLayout';
import map from 'lodash/map';

const downloadAttachment = (featureId, attachmentId) =>
	window.open(`/Feature/DownloadAttachment?featureId=${featureId}&attachmentId=${attachmentId}`, "_self");

const FeatureGoogleForm = props => {
	const { featureId } = props.match.params,
		{ feature } = props;

	React.useEffect(() => props.getFeature(featureId), [featureId]);

	React.useEffect(() => {
		if (feature.featureId === featureId && feature.featureType !== "Article") props.revokeAccess();
	}, [feature.featureId]);

	console.log(feature);

	return <PageLayout title="View Form" pageContent={
		<Card className="article" loading={props.loading}>
			<span className="h1">{feature.title}</span>
			<p>Published {prettifyDate(feature.createDate)}</p>
			{feature.articleImage && <img src={feature.articleImage} alt={feature.title} />}
			<div dangerouslySetInnerHTML={{ __html: feature.articleContent }} />
			{feature.links && feature.links.length > 0 &&
				<React.Fragment>
					<span className="h4">Links</span>
					<ul>
						{map(feature.links, (l, i) => 
							<li key={i}>{l.label} &mdash; <a href={`https://${l.to}`} target="_blank" rel="noopener noreferrer">{l.to}</a></li>
						)}
					</ul>
				</React.Fragment>
			}
			{feature.attachments && feature.attachments.length > 0 &&
				<React.Fragment>
					<span className="h4">Attachments</span>
					<div className="row">
						<ul className="list-unstyled">
							{map(feature.attachments, (a, i) =>
								<li className="media" key={i}>
									<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
									<div className="media-body">
										<h5 className="mt-0 mb-1">{a.name}</h5>
										<p>{a.description}</p>
										<Button variant="outlined" onClick={() => downloadAttachment(featureId, a.attachmentId)}>
											Download now
										</Button>
									</div>
								</li>
							)}
						</ul>
					</div>
				</React.Fragment>
			}
			{feature.videos && feature.videos.length > 0 && 
				<React.Fragment>
					<span className="h4">Videos</span>
					{map(feature.videos, (v, i) =>
						<div className="youtube-embed">
							<div className="frame-wrapper">
								<iframe 
									title="Videos" 
									src={`//www.youtube.com/embed/${parseYoutube(v)}`} 
									frameBorder="" 
									allowFullScreen 
								/>
							</div>
						</div>
					)}
				</React.Fragment>
			}
		</Card>
	} breadcrumbs={["View Form"]} />
};

FeatureGoogleForm.propTypes = {
	feature: PropTypes.shape({
		featureId: PropTypes.number,
		title: PropTypes.string,
		description: PropTypes.string,
		createDate: PropTypes.any,
		articleImage: PropTypes.string,
		articleContent: PropTypes.string,
		links: PropTypes.array,
		videos: PropTypes.array,
		attachments: PropTypes.array
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	feature: state.features.feature,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getFeature: featureId => dispatch(getFeature(featureId)),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureGoogleForm);