import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canSubmitProblems, canViewDashboard, canViewUserDashboard } from '../../store/security';
import { changeLocation } from '../../store/location';
import ProblemTitleField from '../widgets/ProblemTitleField';
import CardContainer from '../widgets/CardContainer';
import SearchField from '../widgets/SearchField';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import PageLayout from '../PageLayout';
import ContributorLeaderBoard from './ContributorLeaderBoard';
import DashboardWrapper from './DashboardWrapper';
import UserCompetencies from './UserCompetencies';
import FeatureShowcase from './FeatureShowcase';
import PopularProblems from './PopularProblems';
import DemoContent from './DemoContent';
import PopularTags from './PopularTags';
import TopTools from './TopTools';
import map from 'lodash/map';

const Home = props => {
	const [state, setState] = React.useState({ problemSearch: "", toolSearch: "", newProblem: "" });
	const { loading, single, plural } = props;

	const layoutSwitch = {
		"Problem search": (
			<Card title={`Search ${plural}`} subtitle={`Search for current innovation ${plural}`}>
				<SearchField
					value={state.problemSearch} 
					onChange={search => setState({ ...state, problemSearch: search })} 
					onSubmit={() => props.searchProblems(state.problemSearch)}
					loading={loading}
				/>
			</Card>
		), 
		"Submit problem": !props.canSubmitProblems ? undefined : (
			<Card title={`Submit ${single}`} subtitle="Enter a short description to get started">
				<Form style={{ display: "flex", alignItems: "flex-start" }} onSubmit={() => props.newProblem(state.newProblem)}>
					<ProblemTitleField
						placeholder={`Enter your ${single} here...`}
						value={state.newProblem} 
						onChange={e => setState({ ...state, newProblem: e.target.value })}
						fullWidth 
					/>
					<Button type="submit">Submit</Button>
				</Form>
			</Card>
		), 
		"Tool search": (
			<Card title="Search tools" subtitle="Search for current tools">
				<SearchField
					value={state.toolSearch} 
					onChange={search => setState({ ...state, toolSearch: search })} 
					onSubmit={() => props.searchTools(state.toolSearch)}
					loading={loading}
				/>
			</Card>
		), 
		"Top tools": <TopTools />, 
		"Feature showcase": <FeatureShowcase />, 
		"Trending problems": <PopularProblems />, 
		"Contributor leaderboard": <ContributorLeaderBoard />, 
		"Trending tags": <PopularTags />,
		"Dashboard": props.canViewDashboard ? <DashboardWrapper /> : undefined,
		"User Competencies": props.canViewUserDashboard ? <UserCompetencies /> : undefined,
		"Demo Content": <DemoContent />
	};

	return (
		<PageLayout 
			title="Home" 
			canMakePublic={true}
			pageContent={
				<React.Fragment>
					<p className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.headerText }} />
					{map(props.layout, (row, i) =>
						<CardContainer key={i}>
							{layoutSwitch[row.primary]}
							{row.secondary && layoutSwitch[row.secondary]}
						</CardContainer>
					)}
				</React.Fragment>
			} 
		/>
	);
};

Home.propTypes = { 
	searchProblems: PropTypes.func.isRequired,
	searchTools: PropTypes.func.isRequired,
	newProblem: PropTypes.func.isRequired,
	headerText: PropTypes.string,
	single: PropTypes.string,
	plural: PropTypes.string,
	canSubmitProblems: PropTypes.bool.isRequired
};

Home.defaultProps = {
	headerText: "",
	single: "",
	plural: ""
};

const mapStateToProps = state => ({
	headerText: state.settings.homeHeaderText,
	single: state.settings.single,
	plural: state.settings.plural,
	layout: state.settings.homePageLayout,
	canSubmitProblems: canSubmitProblems(state),
	canViewDashboard: canViewDashboard(state),
	canViewUserDashboard: canViewUserDashboard(state),
});

const mapDispatchToProps = dispatch => ({
	searchProblems: search => dispatch(changeLocation(`/problems?search=${encodeURIComponent(search)}`)),
	searchTools: search => dispatch(changeLocation(`/tools?search=${encodeURIComponent(search)}`)),
	newProblem: title => dispatch(changeLocation(`/problems/create?title=${encodeURIComponent(title)}`))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
