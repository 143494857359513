import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import CreateInsight from './CreateInsight';
import { saveInsight, isLoading } from '../../store/insights';
import { canViewExperts, hasFunction } from '../../store/security';
import ButtonLink from '../widgets/ButtonLink';
import { isResearchLogiq } from '../../store/selectors/tenants';

const CreateInsightController = props => 
	<PageLayout 
		title="Create Insight" 
		widgetContent={
			<React.Fragment>
				{
					props.isResearchLogiq ? 
					<div>
						<ButtonLink 
							to="/documents/SAMPLE INSIGHT.pdf" 
							target="_blank" 
							rel="noopener noreferrer" 
							style={{ marginRight: '15px' }}
						>Sample</ButtonLink>
					</div> : undefined
				}
			</React.Fragment>
		}
		pageContent={
			<CreateInsight 
				userId={props.userId} 
				saveInsight={props.saveInsight} 
				loading={props.loading} 
				canViewExperts={props.canViewExperts} 
				canAnalyzePDFs={props.canAnalyzePDFs}
				organisationType={props.organisationType}
				isNew 
			/>
		} 
		breadcrumbs={[{ label: "Research Insights", path: `/${props.isCommunity ? "my-community/" : ""}insights` }, "Create Insight"]} 
	/>;

CreateInsightController.propTypes = {
	userId: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	canViewExperts: PropTypes.bool.isRequired,
	saveInsight: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool,
	organisationType: PropTypes.string,
	isResearchLogiq: PropTypes.bool.isRequired,
	canAnalyzePDFs: PropTypes.bool.isRequired
};

CreateInsightController.defaultProps = {
	isCommunity: false,
	organisationType: ''
};

const mapStateToProps = state => ({
	userId: state.context.userId,
	loading: isLoading(state),
	canViewExperts: canViewExperts(state),
	organisationType: state.context.organisationType,
	isResearchLogiq: isResearchLogiq(state),
	canAnalyzePDFs: hasFunction("CanAnalyzePDFs")(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveInsight: (insight, imageFile, files) => dispatch(saveInsight(insight, imageFile, files, ownProps.isCommunity))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInsightController);