import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditActivity from './EditActivity';
import { showModal } from '../../store/modal';
import { getActivity, saveActivity, deleteActivity, isLoading } from '../../store/activities';
import PageLayout from '../PageLayout';

const EditActivityController = props => {
	React.useEffect(() => props.getActivity(props.match.params.activityId), [props.match.params.activityId]);

	return <PageLayout title="Edit activity" pageContent={
		<EditActivity
			activity={props.activity}
			saveActivity={props.saveActivity}
			deleteActivity={props.deleteActivity}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Activities", path: "/activities" }, "Edit activity"]} />;
};

EditActivityController.propTypes = {
	activity: PropTypes.object.isRequired,
	saveActivity: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.activities.saveResult,
	activity: state.activities.activity,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getActivity: activityId => dispatch(getActivity(activityId)),
	saveActivity: (activity, newFiles) => dispatch(saveActivity(activity, newFiles)),
	deleteActivity: activityId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this activity?",
		onOk: () => dispatch(deleteActivity(activityId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActivityController);
