import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveResource, isLoading } from '../../store/resources';
import PageLayout from '../PageLayout';
import EditResource from './EditResource';
import { hasFunction } from '../../store/security';

const CreateResourceController = props => 
	<PageLayout title="Create resource" pageContent={
		<EditResource 
			saveResource={props.saveResource} 
			loading={props.loading} 
			canCreatePublicResource={props.canCreatePublicResource}
			isNew 
		/>
	} breadcrumbs={[{ label: "Resources", path: `/${props.isCommunity ? "my-community/" : ""}resources` }, "Create resource"]} />;

CreateResourceController.propTypes = {
	saveResource: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	canCreatePublicResource: PropTypes.bool.isRequired
};

CreateResourceController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	canCreatePublicResource: hasFunction("ResourceEdit")(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveResource: (resource, imageFile, attachments) => dispatch(saveResource(resource, imageFile, attachments, ownProps.isCommunity))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateResourceController);