import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	TablePagination,
	Grid
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import CollapseIcon from '@mui/icons-material/ExpandLess';
import { downloadFile } from '../../../utils/ajax';
import { stripHtml } from '../../../utils/utils';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DataTableColumn from './DataTableColumn';
import Avatar from '../Avatar';
import Button from '../Button';
import AutoComplete from '../AutoComplete';
import ConditionalWrapper from '../ConditionalWrapper';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import pick from 'lodash/pick';
import some from 'lodash/some';
import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import produce from 'immer';
import orderBy from 'lodash/orderBy';

const columnFields = ["name", "label", "dataType", "width", "hidden", "colSpan", "headerStyle", "style", 
	"renderer", "exportRenderer", "canFilter", "filterType", "filter", "items", "loadItems", "align", "headerRenderer"];

const DataTable = props => {
	const [state, setState] = React.useState({
		rowsPerPage: props.rows,
		page: 0,
		expandedRows: [],
		expanded: false,
		columnFilters: []
	});

	const children = isArray(props.children) ? props.children : [props.children];
	const columns = map(filter(children, c => c && c.type === DataTableColumn), c => pick(c.props, ...columnFields));

	React.useEffect(() => {
		const rowReducer = (acc, r, i) => (isFunction(props.expandRow) ? props.expandRow(r) : props.expandRow) ? [...acc, i] : acc;

		const expanded = props.expandRow && !state.expanded,
			expandedRows = !expanded ? [] : reduce(props.data, rowReducer, []);

		setState({ ...state, expandedRows, expanded });
	}, []);

	const { rowsPerPage, page } = state,
		visibleColumns = filter(columns, c => !c.hidden),
		query = new RegExp(props.search, "i"),
		names = map(visibleColumns, "name"),
		hasDetail = props.detailRenderer || props.detailData;

	let filtered = props.search ? filter(props.data, r => some(pick(r, names), v => isString(v) && query.test(v))) : props.data;

	forEach(state.columnFilters, (f, i) => {
		if (f) {
			const column = visibleColumns[i];
			switch (column.filterType) {
				case "AutoComplete":
					if (f.value) filtered = filter(filtered, r => column.filter ? column.filter(r, f) : r[column.name] === f.label);
					break;
				default:
			}
		}
	});

	// Apply OrderBy
	if (props.orderBy) {
		filtered = orderBy(filtered, props.orderBy, props.direction);
	}

	const formatExportCell = (row, { exportRenderer, name, dataType }) => exportRenderer ? exportRenderer(row) : {
		bool: row[name] ? "Yes" : "No",
		date: new Date(row[name]).toLocaleString("en-AU"),
		avatar: row.fullName,
		html: stripHtml(row[name])
	}[dataType] || row[name];

	const exportToExcel = () => {
		const columnsWithLabel = filter(columns, c => c.label !== "");

		downloadFile("/api/export/excel", [{ 
			name: "export",
			value: {
				columns: map(columnsWithLabel, c => ({ label: c.label })),
				rows: map(filtered, r => ({
					cells: map(columnsWithLabel, c => ({ value: formatExportCell(r, c) }))
				}))
			}
		}]);
	};
	
	const toggleExpanded = (rowNumber, expanded) =>
		setState({ ...state, expandedRows: expanded ? filter(state.expandedRows, r => r !== rowNumber) : [...state.expandedRows, rowNumber] });
	
	const formatCell = (row, { renderer, name, dataType }) => renderer ? renderer(row) : {
		bool: row[name] ? "Yes" : "No",
		date: row[name] ? new Date(row[name]).toLocaleString("en-AU", { day: "numeric", month: "long", year: "numeric" }) : "",
		avatar: (
			<ConditionalWrapper 
				on={props.canViewUsers} 
				wrapper={children => <Link to={`/users/${row.userId}`} style={{ color: props.primaryColour }}>{children}</Link>}
			>
				<Avatar src={row.avatar} fullName={row.fullName} style={{ marginRight: 5 }} />
				<span>{row.fullName}</span>
			</ConditionalWrapper>
		)
	}[dataType] || row[name];

	const renderColumn = (column, index) => {
		if (column.headerRenderer) {
			return column.headerRenderer();
		} else {
			return ({
				AutoComplete: (
					<AutoComplete
						items={column.items}
						loadItems={column.loadItems}
						defaultItem={{ label: "(All)", value: "" }}
						value={state.columnFilters[index] && state.columnFilters[index].value}
						onChange={v => setState(produce(draft => {
							draft.columnFilters[index] = v;
						}, state))}
						label={column.label}
						labelStyle={{
							fontSize: '0.75rem',
							fontWeight: 500,
							color: "rgba(0, 0, 0, 0.54)"
						}}
						style={{ minWidth: 200, fontSize: 8 }}
					/>
				)
			})[column.filterType] || <span>{column.label}</span>;
		}
	}
	
	const renderDetail = row => {
		if (props.detailData) {
			const data = props.detailData(row);

			return map(data, (r2, i) =>
				<TableRow key={i}>
					<TableCell style={{ borderBottom: data.length !== i + 1 && "none" }} />
					{map(props.detailColumns, ({ props }, colIndex) =>
						<TableCell 
							key={colIndex}
							colSpan={props.colSpan} 
							style={{ borderBottom: data.length !== i + 1 && "none", ...props.style }}
						>
							{props.renderer ? props.renderer(row, r2) : formatCell(r2, props)}
						</TableCell>
					)}
				</TableRow>
			);
		}

		return (
			<TableRow>
				<TableCell />
				<TableCell colSpan={visibleColumns.length - 1}>{props.detailRenderer(row)}</TableCell>
			</TableRow>
		);
	}

	const headerRow = React.useRef(null),
		height = props.rowHeight * rowsPerPage + (headerRow.current ? headerRow.current.offsetHeight : 20) + 5; // Add buffer

	return (
        <div className={`data-table ${props.className}`} style={props.style}>
			<ConditionalWrapper 
				on={!props.disableScroll}
				wrapper={children => <Scrollbars autoHeight autoHeightMin={height} autoHide={!props.hidePagination}>{children}</Scrollbars>}
			>
				<Table>
					<TableHead>
						<TableRow ref={headerRow}>
							{hasDetail && filtered.length > 0 && <TableCell key={0} style={{ padding: 0, width: 35 }}></TableCell>}
							{map(visibleColumns, (c, colIndex) =>
								<TableCell key={colIndex + 1} align={c.align} style={{ maxWidth: c.width, overflow: "visible", ...c.headerStyle }}>
									{renderColumn(c, colIndex)}
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{filtered.length === 0 && <TableRow><TableCell colSpan={visibleColumns.length}>{props.emptyText}</TableCell></TableRow>}
						{map(props.hidePagination ? filtered : filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), (r, rowIndex) => {
							const rowNumber = page * rowsPerPage + rowIndex,
								isExpanded = state.expandedRows.includes(rowNumber);

							return (
                                <React.Fragment key={rowIndex}>
									<TableRow style={{ height: props.rowHeight }}>
										{hasDetail &&
											<TableCell key={0} style={{ padding: 0, width: 35 }}>
												<IconButton onClick={() => toggleExpanded(rowNumber, isExpanded)} size="large">
													{isExpanded ? <CollapseIcon /> : <ExpandIcon />}
												</IconButton>
											</TableCell>
										}
										{map(visibleColumns, (c, colIndex) => 
											<TableCell key={colIndex + 1} align={c.align} style={{ maxWidth: c.width, ...(isFunction(c.style) ? c.style(r) : c.style)}} colSpan={c.colSpan}>
												{formatCell(r, c)}
											</TableCell>
										)}
									</TableRow>
									{isExpanded && renderDetail(r)}
									{props.detailFooter && isExpanded && props.detailFooter(r)}
								</React.Fragment>
                            );
						})}
					</TableBody>
				</Table>
			</ConditionalWrapper>
			{!props.hidePagination &&
				<TablePagination
					component="div"
					count={filtered.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{ "aria-label": "Previous Page" }}
					nextIconButtonProps={{ "aria-label": "Next Page" }}
					onPageChange={(e, page) => setState({ ...state, page })}
					onRowsPerPageChange={e => setState({ ...state, rowsPerPage: e.target.value })}
				/>
			}
			{(props.tableFooter|| props.enableExport || props.tableRightFooter ) &&
				<Grid container alignItems="center" justifyContent="flex-end" style={{ marginTop: props.hidePagination ? 20 : 0 }}>
					{props.tableFooter && <Grid item xs={12} sm>{props.tableFooter}</Grid>}
					{props.enableExport && 
						<Grid item xs="auto">
							<Button color="secondary" onClick={exportToExcel}>Export to Excel</Button>
						</Grid>
					}
					{props.tableRightFooter && <Grid item xs="auto">{props.tableRightFooter}</Grid>}
				</Grid>
			}
		</div>
    );
};

DataTable.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
	search: PropTypes.string,
	orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	direction: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	enableExport: PropTypes.bool,
	emptyText: PropTypes.string,
	disableScroll: PropTypes.bool,
	hidePagination: PropTypes.bool,
	style: PropTypes.object,
	expandRow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
	tableFooter: PropTypes.node,
	tableRightFooter: PropTypes.node,
	detailFooter: PropTypes.func,
	rows: PropTypes.number,
	rowHeight: PropTypes.number,
	canViewUsers: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired
};

DataTable.defaultProps = {
	className: "",
	data: [],
	search: "",
	orderBy: "",
	direction: "",
	enableExport: false,
	emptyText: "No data to display...",
	disableScroll: false,
	hidePagination: false,
	style: {},
	expandRow: false,
	tableFooter: "",
	tableRightFooter: "",
	detailFooter: null,
	rows: 10,
	rowHeight: 48,
	canViewUsers: false
};

const mapStateToProps = state => ({
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
