import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfileGroup, saveProfileGroup, isLoading } from '../../store/skillsProfiles';
import PageLayout from '../PageLayout';
import EditProfileGroup from './EditProfileGroup';

const EditProfileGroupController = props => {
	React.useEffect(() => props.getGroup(props.match.params.groupId), [props.match.params.groupId]);

	return <PageLayout title="Edit profile group" pageContent={
		<EditProfileGroup group={props.group} saveGroup={props.saveGroup} loading={props.loading} />
	} breadcrumbs={[{ label: "Profile groups", path: "/admin/profile-groups" }, "Edit profile group"]} />;
};

EditProfileGroupController.propTypes = {
	group: PropTypes.object.isRequired,
	getGroup: PropTypes.func.isRequired,
	saveGroup: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	group: state.skillsProfiles.group,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getGroup: groupId => dispatch(getProfileGroup(groupId)),
	saveGroup: (group, imageFile) => dispatch(saveProfileGroup(group, imageFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileGroupController);
