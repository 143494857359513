import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import uniqueId from 'lodash/uniqueId';
import tinycolor from 'tinycolor2';

const ColourEditor = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("text-field-"));
	const inputProps = { ...props.inputProps };

	// Add minimum value to Number Input
	if (props.type === "number") inputProps.min = 0;

	const handleKeyDown = e => {
		props.onKeyDown(e);

		// Ignore Special Characters for Number Input
		if (props.type === "number" && ["e", "+", "-"].includes(e.key)) {
			e.preventDefault();
		}
	}

	const style = {
		...props.style,
		position: 'relative'
	};

	const backgroundColour = tinycolor(props.value).toHexString();

	return (
        <Grid container spacing={1} alignItems="flex-end">
			<Grid item>
				<div style={{
					width: '30px',
					height: '30px',
					marginBottom: props.helpText ? '28px' : '5px',
					border: '1px solid #333',
					backgroundColor: backgroundColour
				}}>
				</div>
          	</Grid>
          	<Grid item style={{ flexGrow: 1 }}>
				<FormControl fullWidth={props.fullWidth} error={props.error} style={style} variant={props.variant}>
					{props.label &&
						<InputLabel 
							htmlFor={id}
							shrink={Boolean(props.placeholder) || props.type === "date" || undefined}
							{...props.InputLabelProps}
						>
							{props.label}
							{props.required && <span style={{ color: props.errorColour }}> *</span>}
						</InputLabel>
					}
					<Input 
						className="dih-input"
						id={id}
						name={props.name}
						type={props.type}
						value={props.value}
						onChange={props.onChange}
						onBlur={props.onBlur}
						onKeyUp={e => e.keyCode === 13 ? props.onEnter(e) : props.onKeyUp(e)}
						onKeyDown={handleKeyDown}
						placeholder={props.placeholder}
						autoFocus={props.autoFocus}
						multiline={props.multiline}
						rowsMax={props.rowsMax}
						inputProps={{ "aria-label": props["aria-label"], ...inputProps }}
						startAdornment={props.startAdornment && <InputAdornment position="start">{props.startAdornment}</InputAdornment>}
						endAdornment={props.endAdornment && <InputAdornment position="end">{props.endAdornment}</InputAdornment>}
						readOnly={props.readOnly}
						disabled={props.disabled}
						disableUnderline={props.disableUnderline}
						variant={props.variant}
					/>
					{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
				</FormControl>
			</Grid>
		</Grid>
	);
});

ColourEditor.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onEnter: PropTypes.func,
	onKeyUp: PropTypes.func,
	onKeyDown: PropTypes.func,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	error: PropTypes.bool,
	helpText: PropTypes.node,
	autoFocus: PropTypes.bool,
	multiline: PropTypes.bool,
	rowsMax: PropTypes.number,
	startAdornment: PropTypes.node,
	endAdornment: PropTypes.node,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	inputProps: PropTypes.object,
	disableUnderline: PropTypes.bool,
	fullWidth: PropTypes.bool,
	variant: PropTypes.string,
	errorColour: PropTypes.string.isRequired
};

ColourEditor.defaultProps = {
	id: "",
	name: "",
	type: "text",
	label: "",
	onChange: () => null,
	onBlur: () => null,
	onEnter: () => null,
	onKeyUp: () => null,
	onKeyDown: () => null,
	required: false,
	placeholder: "<Not Set>",
	error: false,
	helpText: "",
	value: "",
	autoFocus: false,
	multiline: false,
	rowsMax: 4,
	startAdornment: "",
	endAdornment: "",
	disabled: false,
	readOnly: false,
	inputProps: {},
	disableUnderline: false,
	fullWidth: true,
	variant: "standard"
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ColourEditor);
