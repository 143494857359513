import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../../PageLayout';
import Dashboard from '../../home/Dashboard';
import { get, post } from '../../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../../store/notifications';
import axios from 'axios';
import { canViewOrganisations } from '../../../store/security';

class DashboardController extends React.Component {
	state = {
		loading: false, 
		organisationId: this.props.organisationId,
		organisations: [],
		dashboard: null, 
		dashboardLocation: 'Home'
	};

	componentDidMount() {
		this.setState({ ...this.state, loading: true });

		axios.all([
			get({
				url: `/api/organisations`,
				onSuccess: (data) => {
					this.setState({ ...this.state, organisations: data });
				},
				onError: (error) => {
					this.setState({ ...this.state, organisations: [] });
					this.props.showErrorNotification(error.message);
				}
			}),
			get({
				url: `/api/dashboard`,
				onSuccess: (data) => {
					this.setState({ 
						...this.state,
						dashboard: {
							layout: JSON.parse(data.layout),
							widgets: JSON.parse(data.widgets)
						}
					});
				},
				onError: (error) => {
					this.setState({ 
						...this.state,
						dashboard: {
							layout: null,
							widgets: null
						}
					});
					this.props.showErrorNotification(error.message);
				}
			})
		]).then(responseArr => {
			this.setState({ ...this.state, loading: false });
		});
	}
	
	getDashboard = () => {
		this.setState({ ...this.state, loading: true });
		let url = '';
		switch (this.state.dashboardLocation) {
			case 'Home':
				url = `/api/dashboard?organisationId=${this.state.organisationId}`;
				break;
			case 'Skills':
				url = `/api/dashboard/skills-dashboard?organisationId=${this.state.organisationId}`;
				break;
			case 'MyCommunity':
				url = `/api/dashboard/community-dashboard?organisationId=${this.state.organisationId}`;
				break;
            case 'TeamSkillsSummary':
                url = `/api/dashboard/team-dashboard?organisationId=${this.state.organisationId}`;
                break;
            default:
		}
		get({
			url: url,
			onSuccess: (data) => {
				this.setState({
					...this.state, 
					dashboard: {
						layout: data ? JSON.parse(data.layout) : null,
						widgets: data ? JSON.parse(data.widgets) : null
					},
					loading: false 
				});
			},
			onError: (error) => {
				this.setState({
					...this.state, 
					dashboard: {
						layout: null,
						widgets: null
					},
					loading: false 
				});
				this.props.showErrorNotification(error.message);
			}
		});
	};
	
	onChangeDashboardLocation = newLocation => {
		this.setState(
			{ 
				...this.state,
				dashboardLocation: newLocation
			},
			() => {
				this.getDashboard();
			}
		);
	};

	onChangeOrganisation = organisationId => {
		this.setState(
			{ 
				...this.state,
				organisationId: organisationId
			},
			() => {
				this.getDashboard();
			}
		);
	};

	onSaveDashboard = (layout, widgets) => {
		this.setState({ ...this.state, loading: true });
	
		const formData = new FormData();
		formData.append("Layout", JSON.stringify(layout));
		formData.append("Widgets", JSON.stringify(widgets));

		let url = '';
		switch (this.state.dashboardLocation) {
			case 'Home':
				url = `/api/dashboard?organisationId=${this.state.organisationId}`;
				break;
			case 'Skills':
				url = `/api/dashboard/skills-dashboard?organisationId=${this.state.organisationId}`;
				break;
			case 'MyCommunity':
				url = `/api/dashboard/community-dashboard?organisationId=${this.state.organisationId}`;
				break;
            case 'TeamSkillsSummary':
                url = `/api/dashboard/team-dashboard?organisationId=${this.state.organisationId}`;
                break;
            default:
		}

		post({
			url: url,
			data: formData,
			onSuccess: (data) => {
				this.setState({
					...this.state, 
					dashboard: {
						layout: JSON.parse(data.object.layout),
						widgets: JSON.parse(data.object.widgets)
					},
					loading: false 
				});
				
				if (data.success) {
					this.props.showSuccessNotification(data.message);
				} else {
					this.props.showErrorNotification(data.message);
				}
			},
			onError: (error) => {
				this.setState({
					...this.state, 
					loading: false 
				});
				this.props.showErrorNotification(error.message);
			}
		});
	};

	render = () =>
		<PageLayout 
			title="Dashboard" 
			pageContent={
				<Dashboard 
					admin={true}
					dashboardLocation={this.state.dashboardLocation}
					onChangeDashboardLocation={this.onChangeDashboardLocation}
					userId={this.props.userId}
					organisationId={this.state.organisationId}
					organisations={this.state.organisations}
					canEditOrganisations={this.props.canEditOrganisations}
					onChangeOrganisation={this.onChangeOrganisation}
					dashboard={this.state.dashboard}
					loading={this.state.loading}
					onSaveDashboard={this.onSaveDashboard}
					pcSingle={this.props.pcSingle}
					acSingle={this.props.acSingle}
				/> 
			}
			breadcrumbs={["Dashboard Admin"]} 
		/>;
};

DashboardController.propTypes = { 
	userId: PropTypes.string.isRequired,
	organisationId: PropTypes.number,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	canEditOrganisations: PropTypes.bool,
	pcSingle: PropTypes.string.isRequired,
	acSingle: PropTypes.string.isRequired
};

DashboardController.defaultProps = {
	organisationId: null,
	canEditOrganisations: false
};

const mapStateToProps = state => ({
	userId: state.context.userId,
	organisationId: state.context.organisationId,
	canEditOrganisations: canViewOrganisations(state),
	pcSingle: state.settings.pcSingle,
	acSingle: state.settings.acSingle
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardController);
