import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { get } from '../../utils/ajax';
import round from 'lodash/round';

const CombinedProfiles = ({ userId, setLoading, colourPalette }) => {
	const [data, setData] = React.useState([]);

	const loadData = () => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/competencies/${userId}/competencies-and-comparisons`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (userId) {
			loadData();
		}
	}, [userId]);

	return (
		<ReactEcharts style={{ height: "100%" }} option={{
			color: colourPalette,
			legend: {},
			tooltip: {
				formatter: params => `${round(params.data[0], 1)}%`
			},
			grid: { containLabel: true },
			yAxis: {
				type: "category", 
				data: data.map(d => d.category)
			},
			xAxis: {
				type : "value",
				axisLabel: { formatter: "{value}%" }
			},
			series: [
				{ type: "bar", name: "Meets Target %", data: data.map(d => [d.competentPercentage * 100, d.category]) },
			]
		}} />
	);
};

CombinedProfiles.propTypes = {
	userId: PropTypes.string.isRequired,
	setLoading: PropTypes.func.isRequired,
	colourPalette: PropTypes.array
};

CombinedProfiles.defaultProps = {
	colourPalette: undefined
};

export default CombinedProfiles;
