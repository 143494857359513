import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import AutoComplete from '../widgets/AutoComplete';
import Button from '../widgets/Button';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Checkbox from '../widgets/Checkbox';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';
import isObject from 'lodash/isObject';

const form = {
	initValues: props => ({
		...props.team,
		organisationId: props.organisationId
	}),
	onChange: (props, values, fieldName) => {
		if (fieldName === 'organisationId') {
			props.onOrganisationChanged(values.organisationId);
		}
	},
	fields: [
		{
			name: "name",
			label: "Name",
			required: true,
			helpText: "Name of Team"
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor",
			helpText: "Brief description of Team"
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			disabled: props => !props.isNew,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			onChange: values => {
				values.parentId = null;
				values.teamLeaderId = null;
				values.members = [];
			}
		},
		{
			name: "parentId",
			label: "Parent Team",
			type: "autoComplete",
			items: (props) => {
				if (!props.teams || props.teams.length === 0) return [];
				
				const items = [];

				const teamIds = [];
				if (props.team && props.team.teamId) {
					teamIds.push(props.team.teamId);
				}

				const isRepeating = (team) => {
					if (!team) return false;
					if (some(teamIds, id => id === team.teamId)) return true;
					teamIds.push(team.teamId);
					let parentTeam = null;
					if (team.parentId) parentTeam = find(props.teams, t2 => t2.teamId === team.parentId);
					if (parentTeam) {
						return isRepeating(parentTeam);
					}

					return false;
				};

				for (var i = 0, ii = props.teams.length; i < ii; i++) {
					var t = props.teams[i];
                    items.push({ label: t.name, value: t.teamId, isDisabled: isRepeating(t) });
				}
				return items;
			}
		},
		{
			name: "teamLeaderId",
			label: "Team Leader",
			type: "autoComplete",
			items: props => map(props.users, u => ({ ...u, label: u.fullName, value: u.userId })),
			required: true
		},
		{
			name: "members",
			label: "Members",
			type: "dragList",
			simple: true,
			itemTemplate: { teamMemberId: 0, name: "", skillsProfileId: null, skillsProfileName: "", teamLeaderCanEdit: true },
			renderItem: (item, index, updateItem, props) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={4}>
						<AutoComplete 
							label="Member"
							items={map(props.users, u => ({ label: u.fullName, value: u.userId }))}
							value={item.userId} 
							onChange={item => updateItem(item.value, "userId")}
						/>
					</Grid>
					<Grid item xs={4}>
                        <AutoComplete 
							label="Skills Profile Override"
							items={map(props.skillsProfiles, p => ({ label: p.profileName, value: p.profileId }))}
							value={item.skillsProfileId} 
							onChange={item => {
								if (isObject(item)) {
									updateItem(item.value > 0 ? item.value : null, "skillsProfileId");
								} else {
									updateItem(null, "skillsProfileId");

								}
							}}
						/>
					</Grid>
					<Grid item xs={4}>
                        <Checkbox
                            label="Team Leader Can Edit Competencies"
                            checked={item.teamLeaderCanEdit}
                            onChange={evt => updateItem(evt.target.checked, "teamLeaderCanEdit")}
						/>
					</Grid>
				</Grid>,
			validate: members => {
				const ids = members.map(m => m.userId);

				return new Set(ids).size !== ids.length ? "An member may only be added once" : "";
			}
		}
	]
};

const EditTeam = props => {
	const { team, fields, loading } = props;

	
	return (
		<Card>
			<Form onSubmit={() => props.validateFields() && props.saveTeam(props.values, props.formFiles.image, props.formFiles.attachments)}>
				<Grid container spacing={3}>
					{props.canChangeOrganisation &&
						<Grid item xs={12} sm={12}>{fields.organisationId()}</Grid>
					}
					<Grid item xs={12} sm={12}>{fields.name()}</Grid>
					<Grid item xs={12} sm={12}>{fields.description()}</Grid>
					<Grid item xs={12} sm={12}>{fields.parentId()}</Grid>
					<Grid item xs={12} sm={12}>{fields.teamLeaderId()}</Grid>
					<Grid item xs={12} sm={12}>{fields.members()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>Save team</Button>
					{!isEmpty(team) &&
						<React.Fragment>
							<Button className="delete-button" loading={loading} onClick={() => props.deleteTeam(team)}>
								Delete team
							</Button>
						</React.Fragment> 
					}
				</div>
			</Form>
		</Card>
	);
};

EditTeam.propTypes = { 
	currentUserId: PropTypes.string.isRequired,
	team: PropTypes.object,
	saveTeam: PropTypes.func.isRequired,
	deleteTeam: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	teams: PropTypes.array.isRequired,
	users: PropTypes.array.isRequired,
	skillsProfiles: PropTypes.array.isRequired,
	canChangeOrganisation: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	onOrganisationChanged: PropTypes.func.isRequired
};

EditTeam.defaultProps = {
	team: {},
	deleteTeam: () => null
};

export default withForm(form)(EditTeam);