import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchContents, isLoading, deleteContent, copyContent } from '../../store/content';
import { canEditContent, canSubmiContentBusinessProfiles } from '../../store/security';
import { showModal } from '../../store/modal';
import { getQueryParameters } from '../../utils/utils';
import { withForm } from '../../utils/forms';
import PagePagination from '../widgets/PagePagination';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import PageLayout from '../PageLayout';
import CopyContentDialog from './CopyContentDialog';
import ContentCard from './ContentCard';
import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';

const contentTypes = ["Course Design", "Course Material", "Other"];

let timer = null;
const form = {
	initValues: props => ({ ...props.searchArgs, _pageNum: props.pageNum, _pageSize: props.pageSize }),
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchContents(values, 1, values._pageSize), ["search", "provider"].includes(name) ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "type",
			label: "Type",
			type: "select",
			defaultValue: "all",
			items: [{ label: "(All)", value: "all" }, ...contentTypes]
		},
		{
			name: "tag",
			label: "Tag",
			type: "autoComplete",
			defaultValue: "all",
			loadItems: { route: "tags?scope=Contents", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "order",
			label: "Order By",
			type: "select",
			defaultValue: "Title",
			items: ["Title", "APILP Accredited"]
		},
		{
			name: "provider",
			placeholder: "Provider",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		}
	]
};

const ContentsController = props => {
	const [copier, setCopier] = React.useState({ open: false, content: {} });

	React.useEffect(() => props.searchContents(props.searchArgs, 1, 25), []);

	return <PageLayout title="Resource Catalogue" pageContent={
		<React.Fragment>
			{props.canAdd &&
				<div style={{ textAlign: "right" }}>
					<ButtonLink to="/resource-catalogue/create">Add Resource Bundle</ButtonLink>
				</div>
			}
			<Card style={{ margin: "20px 0", zIndex: 10 }}>
				<Grid container spacing={3} alignItems="flex-end">
					<Grid item xs={12}>{props.fields.search()}</Grid>
					<Grid item xs={12} sm>{props.fields.type()}</Grid>
					<Grid item xs={12} sm>{props.fields.provider()}</Grid>
					<Grid item xs={12} sm>{props.fields.tag()}</Grid>
					<Grid item xs={12} sm={3}>{props.fields.order()}</Grid>
				</Grid>
			</Card>
			{props.contents.length === 0 ? <p>No resource bundles found...</p> :
				<Grid container spacing={3} style={{ marginBottom: 20 }}>
					{props.contents.map((c, i) =>
						<ContentCard 
							key={i} 
							course={c} 
							canEdit={props.canEdit(c)} 
							deleteContent={props.deleteContent}
							copyContent={() => setCopier({ open: true, content: c })}
							organisationId={props.organisationId}
							accreditedLogo={props.accreditedLogo}
						/>
					)}
				</Grid>
			}
			<PagePagination
				pageNum={props.pageNum}
				pageSize={props.pageSize}
				total={props.total}
				totalPages={props.totalPages}
				onChangePage={page => {
					props.updateValues({ ...props.values, _pageNum: page, _pageSize: props.pageSize });
					props.searchContents(props.values, page, props.pageSize);
				}}
				onChangeRowsPerPage={rowsPerPage => {
					props.updateValues({ ...props.values, _pageNum: 1, _pageSize: rowsPerPage });
					props.searchContents(props.values, 1, rowsPerPage);
				}}
			/>
			<CopyContentDialog
				title={`${copier.content.title} - Copy`}
				open={copier.open} 
				onClose={() => setCopier({ open: false, content: {} })} 
				copyContent={title => {
					props.copyContent(copier.content.contentId, title);
					setCopier({ open: false, content: {} });
				}}
			/>
		</React.Fragment>
	} breadcrumbs={["Resource Catalogue"]} />;
};

ContentsController.propTypes = { 
	contents: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	canEdit: PropTypes.func.isRequired,
	searchContents: PropTypes.func.isRequired,
	deleteContent: PropTypes.func.isRequired,
	copyContent: PropTypes.func.isRequired,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number,
	accreditedLogo: PropTypes.string.isRequired
};

ContentsController.defaultProps = {
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null
};

const mapStateToProps = (state, ownProps) => ({
	contents: state.content.contents,
	pageNum: state.content.pageNum,
	pageSize: state.content.pageSize,
	total: state.content.total,
	totalPages: state.content.totalPages,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	searchArgs: mapValues(
		pick(getQueryParameters(ownProps.location.search), "search", "type", "tag", "provider", "order"),
		(value, key) => key === "businessProfileId" ? (value ? parseInt(value, 10) : value) : value
	),
	canEdit: canEditContent(state),
	canAdd: canSubmiContentBusinessProfiles(state),
	accreditedLogo: state.context.organisationAccreditedLogo || ""
});

const mapDispatchToProps = dispatch => ({
	searchContents: (args, pageNum, pageSize) => dispatch(searchContents(args, pageNum, pageSize)),
	deleteContent: contentId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this Resource Bundle?",
		onOk: () => dispatch(deleteContent(contentId))
	})),
	copyContent: (contentId, title) => dispatch(copyContent(contentId, title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ContentsController));