import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Tooltip } from '@mui/material';
import { prettifyDate } from '../../utils/utils';
import MediaCard from '../widgets/MediaCard';
import Tag from '../widgets/Tag';
import map from 'lodash/map';

const StudentProjectCard = React.memo(props => {
	const { project } = props,
		numChannels = project.channels ? project.channels.length : 0,
		numTags = project.tags ? project.tags.length : 0;

	return (
		<Grid item xs={12} sm={6} md={4} lg={4}>
			<MediaCard 
				className="project-card" 
				image={numChannels > 0 ? project.channels[0].image : null} 
				link={`/business-challenges/${project.studentProjectId}`} 
				title={project.title}
			>
				{project.primaryName &&
					<div className="primary-name">
						{project.primaryName}
					</div>
				}
				{project.description && project.description.length &&
					<div style={{ fontSize: '0.9em'}} className="quill-display-block" dangerouslySetInnerHTML={{ __html: project.description }} />
				}
				<div className="dates">
					Created {prettifyDate(project.createDate)}
					{project.updateDate &&
						<React.Fragment>
							<br />
							Updated {prettifyDate(project.updateDate)}
						</React.Fragment>
					}
				</div>
				<div>
					{map(project.channels, c => <Tag key={c.channelId} label={c.channelName} colour={props.primaryColour} />)}
					{numTags > 0 &&
						<Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{map(project.tags, t => (t ? t.name : "") + "\n")}</div>}>
							<span>
								<Tag label={`${numTags} Tag${numTags > 1 ? "s" : ""}`} />
							</span>
						</Tooltip>
					}
					{/* {project.postedBy && <Tag label={`Posted by ${project.postedBy}`} />} */}
				</div>
			</MediaCard>
		</Grid>
	);
});

StudentProjectCard.propTypes = {
	insight: PropTypes.object.isRequired,
	isCommunity: PropTypes.bool.isRequired
};

StudentProjectCard.defaultProps = {
};

const mapStateToProps = state => ({
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentProjectCard);