import React from 'react';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
	cloList: {
		listStyleType: 'none',
		padding: 0,
		margin: 0,
		'& li': {
			'&:before': {
				content: '\'✓\'',
				marginRight: '5px'
			}
		}
	}
});

const UpgradeCloController = props => {
	const classes = props.classes;

	return (
		<PageLayout 
			title="Learning Accelerator" 
			pageContent={
				<div style={{
					maxWidth: '1000px',
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto'
				}}>
					<p style={{ }}>
						<span style={{ fontSize: 'large', marginRight: '10px', fontWeight: 600 }}>Welcome to the Learning Accelerator ~ powered by</span>
						<img src='/images/upgrade/qutex logo.png' alt="" style={{ }} />
					</p>
					<p style={{ }}>
						“In today’s dynamic business environment, workplace learning has become a key lever for success. And with that shift, the traditional role of the chief learning officer is changing. No longer are CLOs responsible just for training …. Instead, they’re embracing a more powerful role in which they reshape capabilities and organizational culture. We call this new type of leader the transformer CLO” 
						<br /><span style={{ fontStyle: 'italic' }}>Harvard Business Review Feb 2020</span>

					</p>
					<p style={{  }}>
						The Learning Accelerator is an integrated Learning Community to build the ‘Transformer CLO’.
						<br />
						If you are a member of a corporate learning or talent development team with a focus on people capability – Learning Accelerator is for you. 
					</p>
					<div style={{
							margin: '0 0 5px 0',
							padding: '5px',
							border: '1px solid #d1332e',
							borderRadius: '15px'
					}}>
						<ul className={classes.cloList}>
							<li>Assess the competencies of your L&amp;D team</li>
							<li>Global Executive Roundtable Series</li>
							<li>Post market opportunities for corporate learning services and content development to a global L&amp;D supplier market</li>
							<li>Education Marketplace</li>
							<li>Research Insights</li>
							<li>Transformer Corporate Learning Program ~ a masterclass series to build capability of aspiring CLOs</li>
						</ul>
					</div>
					<ButtonLink 
						to="/pricing" 
					>Upgrade</ButtonLink>
				</div>
			} 
			breadcrumbs={[
				"Upgrade"
			]} />
	);
};

UpgradeCloController.propTypes = {
};

export default withStyles(UpgradeCloController, styles, { withTheme: true });
