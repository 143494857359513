import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { createLoadingSelector } from './loading';
import { changeLocation } from './location';
import DOMPurify from 'dompurify';
import map from 'lodash/map';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	collaborations: [],
	collaboration: {},
	enquiries: []
};

const CLEAR_COLLABORATIONS = "CLEAR_COLLABORATIONS";
const CLEAR_COLLABORATION = "CLEAR_COLLABORATION";
const GET_COLLABORATIONS_REQUEST = "GET_COLLABORATIONS_REQUEST";
const GET_COLLABORATIONS_SUCCESS = "GET_COLLABORATIONS_SUCCESS";
const GET_COLLABORATIONS_FAILURE = "GET_COLLABORATIONS_FAILURE";
const SEARCH_COLLABORATIONS_REQUEST = "SEARCH_COLLABORATIONS_REQUEST";
const SEARCH_COLLABORATIONS_SUCCESS = "SEARCH_COLLABORATIONS_SUCCESS";
const SEARCH_COLLABORATIONS_FAILURE = "SEARCH_COLLABORATIONS_FAILURE";
const GET_COLLABORATION_REQUEST = "GET_COLLABORATION_REQUEST";
const GET_COLLABORATION_SUCCESS = "GET_COLLABORATION_SUCCESS";
const GET_COLLABORATION_FAILURE = "GET_COLLABORATION_FAILURE";
const SAVE_COLLABORATION_REQUEST = "SAVE_COLLABORATION_REQUEST";
const SAVE_COLLABORATION_SUCCESS = "SAVE_COLLABORATION_SUCCESS";
const SAVE_COLLABORATION_FAILURE = "SAVE_COLLABORATION_FAILURE";
const DELETE_COLLABORATION_REQUEST = "DELETE_COLLABORATION_REQUEST";
const DELETE_COLLABORATION_SUCCESS = "DELETE_COLLABORATION_SUCCESS";
const DELETE_COLLABORATION_FAILURE = "DELETE_COLLABORATION_FAILURE";

export const isLoading = createLoadingSelector(["GET_COLLABORATIONS", "SEARCH_COLLABORATIONS", "GET_COLLABORATION", "GET_ENQUIRIES", "SAVE_ENQUIRY"]);

export const clearCollaborations = () => ({ type: CLEAR_COLLABORATIONS });
export const clearCollaboration = () => ({ type: CLEAR_COLLABORATION });

export const getCollaborations = () =>
	createFetchAction({
		url: "/api/collaborations",
		startAction: GET_COLLABORATIONS_REQUEST,
		onError: error => [getCollaborationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCollaborationsSuccess(data)
	});

export const getCollaborationsSuccess = data => ({ type: GET_COLLABORATIONS_SUCCESS, payload: { data } });
export const getCollaborationsFailure = error => ({ type: GET_COLLABORATIONS_FAILURE, payload: { error } });

export const searchCollaborations = args => 
	createFetchAction({
		url: `/api/collaborations/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_COLLABORATIONS_REQUEST,
		onError: error => [searchCollaborationsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchCollaborationsSuccess(data)
	});

export const searchCollaborationsSuccess = data => ({ type: SEARCH_COLLABORATIONS_SUCCESS, payload: { data } });
export const searchCollaborationsFailure = error => ({ type: SEARCH_COLLABORATIONS_FAILURE, payload: { error } });

export const getCollaboration = collaborationId =>
	createFetchAction({
		url: `/api/collaborations/${collaborationId}`,
		startAction: GET_COLLABORATION_REQUEST,
		onError: error => [getCollaborationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCollaborationSuccess(data)
	});

export const getCollaborationSuccess = data => ({ type: GET_COLLABORATION_SUCCESS, payload: { data } });
export const getCollaborationFailure = error => ({ type: GET_COLLABORATION_FAILURE, payload: { error } });

export const saveCollaboration = (collaboration, newFiles) => {
	const formData = new FormData();
	
	formData.append("Collaboration", encodeURIComponent(JSON.stringify(collaboration)));
	newFiles.forEach(f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/collaborations",
		data: formData,
		startAction: SAVE_COLLABORATION_REQUEST,
		onError: error => [saveStudentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveCollaborationSuccess(data), showSuccessNotification(data.message), changeLocation(`/collaborations/${data.object.collaborationId}`)];
			} else {
				return [saveCollaborationSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveCollaborationSuccess = data => ({ type: SAVE_COLLABORATION_SUCCESS, data });
export const saveStudentFailure = error => ({ type: SAVE_COLLABORATION_FAILURE, error });

export const deleteCollaboration = collaborationId =>
	createPostAction({
		url: `/api/collaborations/${collaborationId}/delete`,
		startAction: DELETE_COLLABORATION_REQUEST,
		onError: error => [deleteCollaborationFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteCollaborationSuccess(data), showSuccessNotification(data.message), changeLocation("/collaborations")];
			} else {
				return [deleteCollaborationSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteCollaborationSuccess = data => ({ type: DELETE_COLLABORATION_SUCCESS, data });
export const deleteCollaborationFailure = error => ({ type: DELETE_COLLABORATION_FAILURE, error });

const formatCollaboration = collaboration => ({ 
	...collaboration, 
	details: DOMPurify.sanitize(collaboration.details) ,
	objectives: DOMPurify.sanitize(collaboration.objectives),
	requirements: DOMPurify.sanitize(collaboration.requirements)
});

///////////////
// Enquiries //
///////////////

const GET_ENQUIRIES_REQUEST = "GET_ENQUIRIES_REQUEST";
const GET_ENQUIRIES_SUCCESS = "GET_ENQUIRIES_SUCCESS";
const GET_ENQUIRIES_FAILURE = "GET_ENQUIRIES_FAILURE";
const SAVE_ENQUIRY_REQUEST = "SAVE_ENQUIRY_REQUEST";
const SAVE_ENQUIRY_SUCCESS = "SAVE_ENQUIRY_SUCCESS";
const SAVE_ENQUIRY_FAILURE = "SAVE_ENQUIRY_FAILURE";

export const getEnquiries = () => 
	createFetchAction({
		url: "/api/collaborations/enquiries",
		startAction: GET_ENQUIRIES_REQUEST,
		onError: error => [getEnquiriesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEnquiriesSuccess(data)
	});

export const getCollaborationEnquiries = collaborationId => 
	createFetchAction({
		url: `/api/collaborations/${collaborationId}/enquiries`,
		startAction: GET_ENQUIRIES_REQUEST,
		onError: error => [getEnquiriesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEnquiriesSuccess(data)
	});

export const getEnquiriesSuccess = data => ({ type: GET_ENQUIRIES_SUCCESS, payload: { data } });
export const getEnquiriesFailure = error => ({ type: GET_ENQUIRIES_FAILURE, payload: { error } });

export const saveEnquiry = (collaborationId, content) =>
	createFormPostAction({
		url: "/api/collaborations/enquire",
		data: { collaborationId, content },
		startAction: SAVE_ENQUIRY_REQUEST,
		onError: error => [saveStudentFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveEnquirySuccess(data), showSuccessNotification(data.message), changeLocation("/collaborations")];
			} else {
				return [saveEnquirySuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveEnquirySuccess = data => ({ type: SAVE_ENQUIRY_SUCCESS, data });
export const saveEnquiryFailure = error => ({ type: SAVE_ENQUIRY_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_COLLABORATIONS:
			return { ...state, collaborations: [] };
		case GET_COLLABORATIONS_REQUEST:
			return { ...state, collaborations: [], isLoading: true };
		case SEARCH_COLLABORATIONS_REQUEST:
			return { ...state, isLoading: true };
		case GET_COLLABORATIONS_SUCCESS:
		case SEARCH_COLLABORATIONS_SUCCESS:
			return { ...state, collaborations: map(action.payload.data, formatCollaboration), isLoading: false };
		case CLEAR_COLLABORATION:
			return { ...state, collaboration: {} };
		case GET_COLLABORATION_REQUEST:
			return { ...state, collaboration: {}, isLoading: true };
		case GET_COLLABORATION_SUCCESS:
			return { ...state, isLoading: true, collaboration: formatCollaboration(action.payload.data) };
		case SAVE_COLLABORATION_REQUEST:
		case DELETE_COLLABORATION_REQUEST:
		case SAVE_ENQUIRY_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_COLLABORATION_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { collaboration: formatCollaboration(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_COLLABORATION_SUCCESS:
			return { ...state, collaborations: state.collaborations.filter(a => a.collaborationId !== action.data.objectId) };
		case GET_ENQUIRIES_REQUEST:
			return { ...state, enquiries: [] };
		case GET_ENQUIRIES_SUCCESS:
			return { ...state, enquiries: action.payload.data, isLoading: false };
		case SAVE_ENQUIRY_SUCCESS:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};