import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveStudent, isLoading } from '../../store/students';
import PageLayout from '../PageLayout';
import EditStudent from './EditStudent';

const CreateStudentController = props => 
	<PageLayout title="Create student" pageContent={
		<EditStudent saveStudent={props.saveStudent} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Students", path: "/students" }, "Create student"]} />;

CreateStudentController.propTypes = {
	saveStudent: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveStudent: (student, newFiles) => dispatch(saveStudent(student, newFiles))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStudentController);