import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Paper } from '@mui/material';
import { prettifyDate } from '../../utils/utils';
import AttachmentList from '../widgets/AttachmentList';
import ButtonLink from '../widgets/ButtonLink';
import Button from '../widgets/Button';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import InviteCollaboratorDialog from './InviteCollaboratorDialog';
import { Chip } from '@mui/material';
import map from 'lodash/map';
import find from 'lodash/find';
import { post } from '../../utils/ajax';
import CreateCollaborationDiscussionDialog from './CreateCollaborationDiscussionDialog';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import Link from 'react-router-dom/Link';
import distanceInWords from 'date-fns/formatDistance';

const ViewCollaboration = props => {
	const { collaboration, currentUserId } = props;
	const [accepted, setAccepted] = React.useState(false);
	const [showInviteDialog, setShowInviteDialog] = React.useState(false);
	const [showDiscussionDialog, setShowDiscussionDialog] = React.useState(false);

	const participant = find(collaboration.participants, p => p.userId === currentUserId && p.status === 'Invited');
	const isInvited = !!participant;

	const acceptInvitation = () => {
		props.setLoading(true);
		post({
			url: `/api/collaborations/accept-invite?collaborationId=${collaboration.collaborationId}&inviteToken=${participant.invitationToken}`,
			data: null,
			onSuccess: data => {
				props.setLoading(false);
				if (data.success) {
					setAccepted(true);
					props.showSuccessNotification(`Thank you for accepting the invitation to collaborate on ${collaboration.title}`);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				props.setLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	};
	

	return (
		<div className="collaboration-container">
			<span className="h2">
				{collaboration.title}
				{props.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
			</span>
			<div className="row" style={{ marginBottom: '10px' }}>
				<div className="user-details">
					{collaboration.authorName && 
						<div className="author-avatar">
							<Avatar src={collaboration.authorAvatar} fullName={collaboration.authorName} length={50} />
						</div>
					}
					<div className="details-box">
						{collaboration.authorName && 
							<span className="author-name">
								{collaboration.authorName}
							</span>
						}	
						Created {prettifyDate(collaboration.createDate)}
						{collaboration.updateDate && `, Updated ${prettifyDate(collaboration.updateDate)}`}
						{collaboration.location &&
							<div style={{ marginBottom: 10 }}>{collaboration.location}, {collaboration.startDate && prettifyDate(collaboration.startDate)} {collaboration.duration && `(${collaboration.duration})`}</div>
						}								
					</div>
				</div>
				<div className="tags">
					{map(collaboration.tags, (t, i) => <Tag key={i} label={t.name} />)}
					<div>{collaboration.channels.map((c, index) => <Tag key={index} label={c.channelName} colour={props.primaryColour} style={{ marginRight: 5 }} />)}</div>
				</div>
			</div>
			<Card header="Summary">
				<div dangerouslySetInnerHTML={{ __html: collaboration.summary }} />
			</Card>
			<Card header="Opportunity Objectives">
				<div dangerouslySetInnerHTML={{ __html: collaboration.objectives }} />
			</Card>
					<Card header="Specific Requirements">
				<div dangerouslySetInnerHTML={{ __html: collaboration.requirements }} />
			</Card>
			<Card header="Attachments">
				{collaboration.attachments.length > 0 &&
					<React.Fragment>
						<span className="h4">Attachments</span>
						<AttachmentList 
							attachments={collaboration.attachments} 
							downloadLink={attachment => `/Collaboration/DownloadAttachment?collaborationId=${collaboration.collaborationId}&attachmentId=${attachment.collaborationAttachmentId}`} 
						/>
					</React.Fragment>
				}
			</Card>
			<Card header="Participants">
				<ul style={{ listStyle: 'none', padding: 0 }}>
					{collaboration.participants.map((p, i) => {
						const status = accepted ? 'Accepted' : p.status;
						return (
							<li key={i} style={{ marginBottom: 5 }}>
								<Avatar src={p.avatar} fullName={p.fullName} style={{ marginRight: 5 }} />
								<span>{p.fullName}</span>
								<Chip label={status} style={{ height: 20, marginLeft: 10 }} />
							</li>
						);
					})}
				</ul>
			</Card>
			{isInvited && !accepted &&
				<div className="row" style={{ marginBottom: '10px' }}>
					<Button onClick={() => acceptInvitation()} style={{ marginRight: 10 }}>
						Accept Invite
					</Button>
				</div>
			}
			{(props.canEdit) && 
				<ButtonLink to={`/collaborations/edit/${collaboration.collaborationId}`} style={{ marginRight: 10 }}>
					Edit collaboration
				</ButtonLink>
			}
			{(props.canEdit || props.isParticipant) && 
				<ButtonLink to={`/collaborations/enquiries?collaborationId=${collaboration.collaborationId}`} color="secondary" style={{ marginRight: 10 }}>
					View Enquiries
				</ButtonLink>
			}
			{props.canEdit && 
				<React.Fragment>
					<InviteCollaboratorDialog
						open={showInviteDialog}
						onClose={() => {
							setShowInviteDialog(false);
						}}
						collaborationId={collaboration.collaborationId}
					/>
					<Button 
						onClick={() => {
							setShowInviteDialog(true);
						}}
						style={{ marginRight: 10 }}
						color="secondary"
					>
						Invite Collaborators
					</Button>
				</React.Fragment>
			}

			<h3>
				Discussions
				<Button 
					className="add-button" 
					variant="outlined" 
					onClick={() => setShowDiscussionDialog(true)}
					style={{ float: 'right' }}
				>
					Create discussion
				</Button>
			</h3>
			{props.discussionsLoading ? 
				<CircularProgress size={24} />
				:
				<Paper>
					<DataTable data={props.discussions} emptyText="There are no discussions to display...">
						<DataTableColumn name="subject" label="Subject" renderer={d => 
							<Link style={{ color: "inherit" }} to={`/collaborations/${d.collaborationId}/discussion/${d.collaborationDiscussionId}`}>
								<Avatar src={d.authorAvatar} fullName={d.authorName} length={25} style={{ marginRight: 5 }} />
								<span>{d.subject}</span>
							</Link>
						} />
						<DataTableColumn name="comments" label="Number of Comments" renderer={d =>
							d.comments.length
						} />
						<DataTableColumn label="Last Comment" renderer={d => 
							d.lastComment ? distanceInWords(new Date(d.lastComment), new Date(), { addSuffix: true }) : ""
						} />
					</DataTable>
				</Paper>
			}
			
			<CreateCollaborationDiscussionDialog
				open={showDiscussionDialog}
				onClose={() => setShowDiscussionDialog(false)}
				onSubmit={discussion => {
					setShowDiscussionDialog(false);
					props.saveDiscussion(discussion);
				}}
				hideTopic
			/>
		</div>
	);
};

ViewCollaboration.propTypes = {
	collaboration: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isParticipant: PropTypes.bool.isRequired,
	canViewEnquiries: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	discussionsLoading: PropTypes.bool,
	discussions: PropTypes.array
};

ViewCollaboration.defaultProps = {
	discussionsLoading: false,
	discussions: []
};

export default ViewCollaboration;