import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStats } from './store/dashboard';
import { getUserNotifications } from './store/userNotifications';
import { getSettings } from './store/settings';
import { resetLoading } from './store/loading';
import { showModal } from './store/modal';
import { startsWith } from 'lodash';
import { getUserDataFromCookie } from './utils/user';
import { get } from './utils/ajax';

class Startup extends React.Component {
	static inited = false;

	static init = (dispatch, forceInit) => {
		if (!forceInit && Startup.inited) return;
		Startup.inited = true;

		if (!startsWith(window.location.pathname, "/academics/edit")) {
			const user = getUserDataFromCookie();
			
			if (user.academicId) {
				// Check if academic profile is complete and if not show popup
				get({
					url: `/api/academics/${user.academicId}`,
					onSuccess: result => {
						if (result && !(result.faculty && result.role && result.qualifications && result.channelId)) {
							dispatch(showModal("INCOMPLETE_ACADEMIC_PROFILE"));
						}
					},
					onError: error => console.error(error)
				});
			}
		}

		dispatch(resetLoading());
		dispatch(getStats());
		dispatch(getUserNotifications(10));
		dispatch(getSettings());
	}

	// Fetch dashboard stats on startup
	UNSAFE_componentWillMount = () => {
		if (this.props.isLoggedIn) Startup.init(this.props.dispatch);
	}

	render = () => this.props.children;
};

Startup.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isLoggedIn: PropTypes.bool
};

Startup.defaultProps = {
	isLoggedIn: false
};

const mapStateToProps = state => ({
	isLoggedIn: state.context.isLoggedIn
});	

export default connect(mapStateToProps, dispatch => ({ dispatch }))(Startup);
