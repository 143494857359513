import React from 'react';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import PropTypes from 'prop-types';

const WefController = props => {
	return (
		<PageLayout 
			title="Wef" 
			pageContent={
				<React.Fragment>
					<h2>WEF</h2>
					<iframe
						title="wef"
						src="https://cdn.intelligence.weforum.org/iframe.html?topicid=a1Gb00000015MIVEA2&apikey=35e42c22-f047-415e-b879-fa0e95300b7d&openinnewwindow=true"
						style={{ border: "0px solid white" }}
						name="intelligenceWidgetIframe"
						scrolling="no"
						frameBorder="0px"
						marginHeight="0px"
						marginWidth="0px"
						height="700px"
						width="100%"
					></iframe>
				</React.Fragment>
			} 
			breadcrumbs={["Wef"]} 
		/>
	);
};

WefController.propTypes = { 
	currentUserId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WefController);
