import React from 'react';
import PropTypes from 'prop-types';
import { 
	Grid,
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions 
} from '@mui/material';
import Button from '../widgets/Button';
import { get } from '../../utils/ajax';
import { CircularProgress } from '@mui/material';
import Checkbox from '../widgets/Checkbox';
import Avatar from '../widgets/Avatar';
import TextField from '../widgets/TextField';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const AddCollaboratorDialog = props => {
	const [usersLoading, setUsersLoading] = React.useState(false);
	const [nonSkillsLogiqCollaborator, setNonSkillsLogiqCollaborator] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [name, setName] = React.useState("");
	const [users, setUsers] = React.useState([]);
	const [user, setUser] = React.useState(null);
	const [searchText, setSearchText] = React.useState("");

	const getInviteableUsers = () => {
		setUsersLoading(true);
	
		get({
			url: "/api/collaborations/inviteable-users",
			onSuccess: data => {
				setUsersLoading(false);
				setUsers(orderBy(data, 'fullName'));
			},
			onError: () => {
				setUsersLoading(false);
			}
		});
	}

	const loadData = () => {
		getInviteableUsers();
	};

	const loading = usersLoading;

	let filteredUsers = users;
	if (searchText) {
		const query = new RegExp(searchText, "i");
		filteredUsers = filter(users, u => query.test(u.fullName));
	}

	const AddCollaboratorContent = nonSkillsLogiqCollaborator ? (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<TextField label="Email" value={email} onChange={e => setEmail(e.target.value)} />
			</Grid>
			<Grid item xs={12}>
				<TextField label="Name" value={name} onChange={e => setName(e.target.value)} />
			</Grid>
		</Grid>
		) : (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField 
						placeholder="Enter Search Text"
						value={searchText} 
						onChange={e => setSearchText(e.target.value)} 
						startAdornment={<SearchIcon />}
					/>
				</Grid>
				<Grid item xs={12}>
					{loading ? <CircularProgress size={24} /> : map(filteredUsers, (u, i) => {
						const participant = find(props.existingParticipants, p => p.userId === u.userId);
						const status = participant ? participant.status : null;
						return (
							<Checkbox 
								key={i}
								disabled={status === "Accepted"}
								label={
									<React.Fragment>
										<Avatar src={u.avatar} fullName={u.fullName} style={{ marginRight: 5 }} />
										<span>{u.fullName}</span>
										{status && <Chip label={status} style={{ height: 20, marginLeft: 10 }} />}
									</React.Fragment>
								}
								checked={user && user.userId === u.userId}
								onChange={() => {
									setUser(u);
								}}
							/>
						);
					})}
				</Grid>
			</Grid>
		);

	let disableAdd = loading;
	if (nonSkillsLogiqCollaborator) {
		if (!email && !name) disableAdd = true;
	} else {
		if (!user) disableAdd = true;
	}

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            TransitionProps={{
                onEnter: loadData
            }}>
			<DialogTitle>
				Add a Collaborator
				<Checkbox 
					label="Enter Non SkillsLogiq Collaborator"
					checked={nonSkillsLogiqCollaborator} 
					onChange={e => setNonSkillsLogiqCollaborator(e.target.checked)} 
					style={{ width: "auto", float: 'right', marginTop: '-5px', marginLeft: '10px' }}
					labelPlacement="start"
				/>
			</DialogTitle>
			<DialogContent>
				{AddCollaboratorContent}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button 
					disabled={disableAdd} 
					onClick={() => {
						if (nonSkillsLogiqCollaborator) {
							props.onAddNonSLCollaborator(email, name);
						} else {
							props.onAddCollaborator(user)
						}
					}}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
    );
};

AddCollaboratorDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	existingParticipants: PropTypes.array.isRequired,
	onAddCollaborator: PropTypes.func.isRequired,
	onAddNonSLCollaborator: PropTypes.func.isRequired
};

export default AddCollaboratorDialog;
