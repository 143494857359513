import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDiscussion, saveDiscussion, deleteDiscussion, isLoading } from '../../store/discussion';
import { canEditDiscussion, canEditCommunityDiscussion } from '../../store/security';
import { showModal } from '../../store/modal';
import { revokeAccess } from '../../store/context';
import PageLayout from '../PageLayout';
import EditDiscussion from './EditDiscussion';

const EditDiscussionController = props => {
	const discussionId = Number(props.match.params.discussionId);

	React.useEffect(() => props.getDiscussion(discussionId), [discussionId]);

	React.useEffect(() => {
		if (props.discussion.discussionId === discussionId && !props.canEdit) props.revokeAccess();
	}, [props.discussion.discussionId]);

	return <PageLayout title="Edit discussion" pageContent={
		<EditDiscussion
			discussion={props.discussion}
			saveDiscussion={props.saveDiscussion}
			deleteDiscussion={props.deleteDiscussion}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Discussion", path: `/${props.isCommunity ? "my-community/" : ""}discussion` }, "Edit discussion"]} />;
};

EditDiscussionController.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	discussion: PropTypes.object.isRequired,
	getDiscussion: PropTypes.func.isRequired,
	saveDiscussion: PropTypes.func.isRequired,
	deleteDiscussion: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool
};

EditDiscussionController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	canEdit: ownProps.isCommunity ? canEditCommunityDiscussion(state) : canEditDiscussion(state),
	discussion: state.discussion.discussion,
	loading: isLoading(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getDiscussion: discussionId => dispatch(getDiscussion(discussionId)),
	saveDiscussion: discussion => dispatch(saveDiscussion(discussion, ownProps.isCommunity)),
	deleteDiscussion: discussionId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this discussion?",
		onOk: () => dispatch(deleteDiscussion(discussionId, ownProps.isCommunity))
	})),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDiscussionController);