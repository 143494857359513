import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStudents, isLoading } from '../../store/students';
import { canSubmitStudents } from '../../store/security';
import { truncateString } from '../../utils/utils';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import SearchField from '../widgets/SearchField';
import ButtonLink from '../widgets/ButtonLink';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';
import PageLayout from '../PageLayout';

const StudentsController = props => {
	const [search, setSearch] = React.useState("");

	React.useEffect(props.getStudents, []);

	return <PageLayout title="Students" pageContent={
		<Card>
			{/* Search Students */}
			<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Students Datatable */}
			<DataTable data={props.students} search={search} tableFooter={<ButtonLink to="/students/create">Add student</ButtonLink>}>
				<DataTableColumn name="name" label="Name" renderer={student => 
					<Link to={`/students/${student.studentId}`} style={{ color: props.primaryColour }}>
						<Avatar src={student.avatar} fullName={student.name} style={{ marginRight: 5 }} />
						<span>{student.name}</span>
					</Link>
				} />
				<DataTableColumn 
					name="institutionName" 
					label="Institution"
					canFilter
					filterType="AutoComplete"
					loadItems={{ route: "organisations?type=Institution", mapItem: "name" }} 
				/>
				<DataTableColumn name="email" label="Email" />
				<DataTableColumn name="currentProgram" label="Current Program" renderer={student => truncateString(student.currentProgram, 50)} />
				<DataTableColumn name="registrationDate" label="Registration Date" dataType="date" align="center" />
			</DataTable>
		</Card>
	} breadcrumbs={["Students"]} />;
};

StudentsController.propTypes = { 
	students: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	students: state.students.students,
	loading: isLoading(state),
	canSubmit: canSubmitStudents(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getStudents: () => dispatch(getStudents())
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsController);
