import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCourse, isLoading } from '../../store/courses';
import { canEditCourse, canMakePublic } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewCourse from './ViewCourse';
import MakePublicButton from '../widgets/MakePublicButton';

const ViewCourseController = props => {
	React.useEffect(() => props.getCourse(props.match.params.courseId), [props.match.params.courseId]);

	return (
		<PageLayout 
			title="View course" 
			pageContent={
				<ViewCourse 
					course={props.course} 
					loading={props.loading} 
					canEdit={props.canEdit(props.course)} 
					fromAdmin={props.fromAdmin} 
					primaryColour={props.primaryColour}
				/>
			} 
			breadcrumbs={[
				{ label: "Courses", path: props.fromAdmin ? "/admin/courses" : "/courses" }, 
				"View course"
			]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);
};

ViewCourseController.propTypes = {
	course: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.func.isRequired,
	fromAdmin: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired,
	canMakePublic: PropTypes.bool.isRequired
};

ViewCourseController.defaultProps = {
	fromAdmin: false
};

const mapStateToProps = state => ({
	course: state.courses.course,
	loading: isLoading(state),
	canEdit: canEditCourse(state),
	primaryColour: state.theme.primaryColour,
	canMakePublic: canMakePublic(state)
});

const mapDispatchToProps = dispatch => ({
	getCourse: courseId => dispatch(getCourse(courseId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCourseController);