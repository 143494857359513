import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { withForm } from '../../utils/forms';
import { getSettings, saveSettings, isLoading } from '../../store/settings';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { settingsTabViewProps } from '../../store/security';
import AvatarUploader from '../widgets/AvatarUploader';
import TabContainer from '../widgets/TabContainer';
import TextField from '../widgets/TextField';
import Checkbox from '../widgets/Checkbox';
import Button from '../widgets/Button';
import Select from '../widgets/Select';
import Form from '../widgets/Form';
import Tab from '../widgets/Tab';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import map from 'lodash/map';

const homePageWidgets = ["None", "Problem search", "Submit problem", "Tool search", "Top tools", "Feature showcase", 
	"Trending problems", "Contributor leaderboard", "Trending tags", "Dashboard", "User Competencies", "Demo Content"];

const navWidgets = ["Academic count", "Offer count", "Course count", "Event count", "Organisation count", "Problem count", 
	"Research centre count", "Report count", "Tool count", "User count", "My Competencies", "Career Pathway"];

const form = {
	initValues: props => omit(props.settings, "saveResult", "isLoading", "single", "plural", "acSingle", "pcSingle", "sections"),
	fields: [
		{
			name: "problemDuration",
			required: true,
			label: "Problem duration",
			type: "number",
			helpText: "Number of days before a problem expires."
		},
		{
			name: "leaderBoardSize",
			required: true,
			label: "Leader board size",
			type: "number",
			helpText: "Number of users to display within home page leader board."
		},
		{
			name: "alertOnVotes",
			required: true,
			label: "Alert on votes",
			type: "number",
			helpText: "Number of votes accumulated before notifying admin users."
		},
		{
			name: "logo",
			label: "Logo",
			type: "imageSelector",
			helpText: "Banner image displayed at the top of the page",
			background: "#ffffff"
		},
		{
			name: "emailLogo",
			label: "Email Logo",
			type: "imageSelector",
			imageText: "Logo displayed in email header. If not set the primary logo will be used.",
			background: "#539be2"
		},
		{
			name: "siteBannerImage",
			required: true,
			label: "Site banner image",
			type: "imageSelector",
			helpText: "Banner image displayed at the top of the page",
			height: 68,
			width: 230,
			background: "#156184",
			images: ["/images/gov-coatarms.png"]
		},
		{
			name: "aboutContent",
			required: true,
			label: "About page content",
			type: "textEditor",
			showLink: true,
			helpText: "Content used to describe Hub in About Page"
		},
		{
			name: "copyrightContent",
			required: true,
			label: "Copyright page content",
			type: "textEditor",
			showLink: true,
			helpText: "Content found in Copyright"
		},
		{
			name: "helpContent",
			required: true,
			label: "Help page content",
			type: "textEditor",
			showLink: true,
			helpText: "Content found in Help Page"
		},
		{
			name: "disclaimerContent",
			required: true,
			label: "Disclaimer page content",
			type: "textEditor",
			showLink: true,
			helpText: "Content found in Disclaimer"
		},
		{
			name: "fundingContent",
			required: true,
			label: "Funding page content",
			type: "textEditor",
			showLink: true,
			helpText: "Content found in Funding"
		},
		{
			name: "termsDialogContent",
			required: true,
			label: "Terms & Conditions content",
			type: "textEditor",
			showLink: true,
			helpText: "Content found in Terms Dialog"
		},
		{
			name: "loginAcceptTermsContent",
			label: "Login page Accept Terms content",
			type: "textEditor",
			showLink: true
		},
		{
			name: "registrationInitialText",
			label: "Registration page initial text",
			type: "textEditor",
			showLink: true
		},
		{
			name: "contactUsText",
			required: true,
			label: "Contact us instructions",
			multiline: true,
			helpText: "Instructions provided on Contact Us"
		},
		{
			name: "contactUsEmailAddress",
			label: "Contact us email",
			helpText: "Destination emails for Contact Us submissions (Note: Overrides default address for notifications)"
		},
		{
			name: "siteLinks",
			required: true,
			label: "Site links",
			type: "dragList",
			itemTemplate: { label: "", to: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={4}>
						<TextField label="Label" value={item.label} onChange={e => updateItem(e.target.value, "label")} />
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField startAdornment="https://" label="Link" value={item.to} onChange={e => updateItem(e.target.value, "to")} />
					</Grid>
				</Grid>,
			helpText: "Links displayed within page footer next to Copyright and Disclaimer"
		},
		{
			name: "facebook",
			label: "Facebook account",
			startAdornment: "https://www.facebook.com/",
			validate: value => value.match(/^[a-z0-9_.]+$/i) ? "" : "Facebook account should be a valid address",
			helpText: "Link to Facebook page contained in page footer"
		},
		{
			name: "twitter",
			label: "Twitter Account",
			startAdornment: "https://www.twitter.com/",
			validate: value => value.match(/^[a-z0-9_.]+$/i) ? "" : "Twitter Account should be a valid address",
			helpText: "Link to Twitter page contained in page footer"
		},
		{
			name: "linkedIn",
			label: "LinkedIn Account",
			startAdornment: "https://www.linkedin.com/in/",
			validate: value => value.match(/^[a-z0-9_.]+$/i) ? "" : "Linkedin Account should be a valid address",
			helpText: "Link to LinkedIn page contained in page footer"
		},
		{
			name: "generalAdminEmailAddress",
			required: true,
			label: "General administration email address",
			helpText: "Destination admin email for all notifications by default"
		},
		{
			name: "userRegistrationEmailContent",
			required: true,
			label: "User registration email content",
			type: "textEditor",
			helpText: "Content used within user registration emails",
			variables: [
				{ name: "UserName", description: "Username of User" },
				{ name: "FullName", description: "Full name of User" },
				{ name: "Email", description: "Email of User" }
			]
		},
		{
			name: "problemRegistrationEmailAddress",
			label: "Problem registration email address",
			helpText: "Destination admin email for problem registration notifications (Note: Overrides default address for notifications)"
		},
		{
			name: "problemRegistrationEmailContent",
			required: true,
			label: "Problem registration email content",
			type: "textEditor",
			helpText: "Content used within problem registration notification emails for admins",
			variables: [
				{ name: "Author", description: "Author Name" }, 
				{ name: "ProblemTitle", description: "Title of Problem" }
			]
		},
		{
			name: "applicationEmailAddress",
			label: "Application email address",
			helpText: "Destination admin email for application notifications (Note: Overrides default address for notifications)"
		},
		{
			name: "applicationConfirmationEmailContent",
			required: true,
			label: "Application confirmation email content",
			type: "textEditor",
			helpText: "Content used within application confirmation emails for users",
			variables: [
				{ name: "OfferTitle", description: "Title of Offer applied for" }, 
				{ name: "ExpiryDate", description: "Expiry Date of Offer (e.g. \"Friday, 29 May 2015\")" }
			]
		},
		{
			name: "offerConfirmationEmailContent",
			required: true,
			label: "Offer confirmation email content",
			type: "textEditor",
			helpText: "Content used within offer confirmation emails for admins",
			variables: [
				{ name: "Author", description: "Author Name" },
				{ name: "OfferTitle", description: "Title of Offer" }
			]
		},
		{
			name: "contactUsConfirmationEmailContent",
			required: true,
			label: "Contact us confirmation email content",
			type: "textEditor",
			helpText: "Content used within Contact Us confirmation emails for users",
			variables: [
				{ name: "Subject", description: "Subject of Request" },
				{ name: "Message", description: "Request Message" }
			]
		},
		{
			name: "contactUsReceivedEmailContent",
			required: true,
			label: "Contact us received email content",
			type: "textEditor",
			helpText: "Content used within Contact Us request emails for admins",
			variables: [
				{ name: "UserName", description: "Username of User" },
				{ name: "FullName", description: "Full Name of User" },
				{ name: "Email", description: "Email of User" },
				{ name: "Subject", description: "Subject of Request" },
				{ name: "Message", description: "Request Message" },
				{ name: "LogRocketUrl", description: "LogRocket Url" }
			]
		},
		{
			name: "eventRegistrationConfirmationEmailContent",
			required: true,
			label: "Event registration confirmation email content",
			type: "textEditor",
			helpText: "Content used within Event Registration confirmation emails for users",
			variables: [
				{ name: "EventTitle", description: "Title of Event" },
				{ name: "StartDate", description: "Start Date of Event" },
				{ name: "StartTime", description: "Start Time of Event" },
				{ name: "ContactEmail", description: "Contact Email for Event" }
			]
		},
		{
			name: "eventRegistrationReceivedEmailContent",
			required: true,
			label: "Event registration received email content",
			type: "textEditor",
			helpText: "Content used within Event Registration Received emails for admins",
			variables: [
				{ name: "UserName", description: "Username of User" },
				{ name: "FullName", description: "Full Name of User" },
				{ name: "Email", description: "Email of User" },
				{ name: "EventTitle", description: "Title of Event" }
			]
		},
		{
			name: "problemVoteAlertEmailContent",
			required: true,
			label: "Problem vote alert email content",
			type: "textEditor",
			helpText: "Content used within vote alert emails for admins",
			variables: [
				{ name: "Author", description: "Author Name" },
				{ name: "ProblemTitle", description: "Title of Problem" },
				{ name: "VoteCount", description: "Number of Votes Received" }
			]
		},
		{
			name: "eventRequestReceivedEmailContent",
			required: true,
			label: "Event request received email content",
			type: "textEditor",
			helpText: "Content used within event request emails for admins",
			variables: [
				{ name: "Username", description: "Username of author" },
				{ name: "ContactEmail", description: "Contact email of requested email" },
				{ name: "ContactPhone", description: "Contact phone of requested event" },
				{ name: "EventTitle", description: "Title of Event" },
				{ name: "Description", description: "Description of Event" },
				{ name: "StartDate", description: "Commencement date of Event" },
				{ name: "StartTime", description: "Commencement Time of Event" }
			]
		},
		{
			name: "eventRequestConfirmationEmailContent",
			required: true,
			label: "Event request received email content",
			type: "textEditor",
			helpText: "Content used within event request emails for admins",
			variables: [
				{ name: "EventTitle", description: "Title of Event" },
				{ name: "StartDate", description: "Commencement date of Event" },
				{ name: "StartTime", description: "Commencement Time of Event" }
			]
		},
		{
			name: "emailSignature",
			label: "Email signature",
			type: "textEditor",
			helpText: "Signature at bottom of all emails from DIH"
		},
		{
			name: "mySkillsButton1Label",
			label: "My Skills Button 1 Label",
			required: true
		},
		{
			name: "mySkillsButton1Report",
			label: "My Skills Button 1 Report",
			required: true
		},
		{
			name: "mySkillsButton2Label",
			label: "My Skills Button 2 Label",
			required: true
		},
		{
			name: "mySkillsButton2Report",
			label: "My Skills Button 2 Report"
		},
		{
			name: "showPageBanner",
			label: "Show page banner",
			type: "checkbox",
			helpText: "Show or hide page banner"
		},
		{
			name: "pageBannerContent",
			label: "Page banner vontent",
			type: "textEditor",
			showLink: true,
			helpText: "Content displayed in banner at top of page"
		},
		{
			name: "hiddenUsers",
			label: "Hidden leader board users",
			type: "autoComplete",
			loadItems: {
				route: "users/internal",
				mapItem: u => ({ label: u.userName, value: u.userId })
			},
			isMulti: true,
			helpText: "Users hidden from leaderboard results"
		},
		{
			name: "homeHeaderText",
			required: false,
			label: "Home header text",
			multiline: true,
			max: 1000,
			rowsMax: 4,
			helpText: "Text displayed on the the top of the home page"
		},
		{
			name: "homePageLayout",
			label: "Home page layout",
			type: "dragList",
			itemTemplate: { primary: "None", secondary: "None" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={6}>
						<Select required label="Primary content" items={homePageWidgets} value={item.primary} onChange={e => updateItem(e.target.value, "primary")} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Select label="Secondary content" items={homePageWidgets} value={item.secondary} onChange={e => updateItem(e.target.value, "secondary")} />
					</Grid>
				</Grid>,
			helpText: "Layout of home page content (primary content will take up full width if no secondary widget is selected)"
		},
		{
			name: "featuresHeading",
			required: true,
			label: "Features heading",
			helpText: "Heading of features panel"
		},
		{
			name: "featuresText",
			required: false,
			label: "Features text",
			multiline: true,
			helpText: "Text description of features panel"
		},
		{
			name: "reports",
			label: "Manage reports",
			type: "dragList",
			itemTemplate: { reportId: 0, name: "", category: "", description: "", file: "", image: "", visible: true, fileName: "" },
			renderItem: (item, index, updateItem) => 
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField label="Name" value={item.name} onChange={e => updateItem(e.target.value, "name")} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField label="Category" value={item.category} onChange={e => updateItem(e.target.value, "category")} />
					</Grid>
					<Grid item xs={12} sm={10}>
						<TextField label="Description" value={item.description} onChange={e => updateItem(e.target.value, "description")} />
					</Grid>
					<Grid item xs={12} sm={2}>
						<Checkbox label="Visible" checked={item.visible} onChange={e => updateItem(e.target.checked, "visible")} />
					</Grid>
					<Grid item xs={12} sm>
						<TextField label="File name" value={item.fileName} onChange={e => updateItem(e.target.value, "fileName")} endAdornment=".rdl" />
					</Grid>
					<Grid item xs={12} sm="auto">
						<AvatarUploader label="Image" value={item.image} onChange={(value, file) => updateItem(value, "image", file)} />
					</Grid>
				</Grid>,
			helpText: "Manage documents available in Reports",
			showBorder: true,
			cellStyle: { padding: "12px 0" }
		},
		{
			name: "showTenantTitle",
			label: "Show tenant title",
			type: "checkbox",
			helpText: "Show or hide name of tenant in top right corner of site in wide display"
		},
		{
			name: "problemOverrideSingle",
			placeholder: "problem",
			style: { padding: "10px 0" }
		},
		{
			name: "problemOverridePlural",
			placeholder: "problems",
			style: { padding: "10px 0" }
		},
		{
			name: "primaryCompetencySingle",
			placeholder: "Competency",
			style: { padding: "10px 0" }
		},
		{
			name: "primaryCompetencyDescription",
			style: { padding: "10px 0" }
		},
		{
			name: "aspirationalCompetencySingle",
			placeholder: "Aspirational Competency",
			style: { padding: "10px 0" }
		},
		{
			name: "aspirationalCompetencyDescription",
			style: { padding: "10px 0" }
		},
		{
			name: "notificationUserRegistration",
			label: "User Registration",
			type: "checkbox",
			helpText: "Enable email notifications for new user registrations"
		},
		{
			name: "notificationProblemRegistration",
			label: "Problem Registration",
			type: "checkbox",
			helpText: "Enable email notifications for problem registrations"
		},
		{
			name: "notificationProblemVoteAlert",
			label: "Problem Vote Alert",
			type: "checkbox",
			helpText: "Enable email notifications for when the count of endorsements for a problem has reached the vote alert threshold"
		},
		{
			name: "notificationOfferCreated",
			label: "Offer Created",
			type: "checkbox",
			helpText: "Enable email notifications for new offers"
		},
		{
			name: "notificationOfferClosed",
			label: "Offer Closed",
			type: "checkbox",
			helpText: "Enable email notifications for when a chalenge has been closed"
		},
		{
			name: "notificationCompetenciesSaved",
			label: "Competencies Saved",
			type: "checkbox",
			helpText: "Enable email notifications for when a user a saves or updates their competencies"
		},
		{
			name: "navbarWidgets",
			label: "Navbar Widgets",
			type: "dragList",
			renderItem: (item, index, updateItem) => 
				<Select key={index} label="Widget" items={navWidgets} value={item} onChange={e => updateItem(e.target.value)} />,
			helpText: "Content and ordering of Navbar Widgets"
		},
		{
			name: "submitOfferText",
			label: "Submit Offer Text",
			helpText: "Override text on submit offer button"
		},
		{
			name: "submitProblemHeaderContent",
			label: "Submit Problem Header Content",
			type: "textEditor"
		},
		{
			name: "submitProblemTitleHelpText",
			label: "Submit Problem Title Help Text",
			multiline: true
		},
		{
			name: "submitProblemDescriptionHelpText",
			label: "Submit Problem Description Help Text",
			multiline: true
		},
		{
			name: "submitProblemSolutionHelpText",
			label: "Submit Problem Solution Help Text",
			multiline: true
		},
		{
			name: "eventVisibilityInternalDescription",
			label: "Internal Event Description",
			helpText: "Description provided on event details page for Internal events"
		},
		{
			name: "eventVisibilityExternalDescription",
			label: "Internal Event Description",
			helpText: "Description provided on event details page for External events"
		},
		{
			name: "eventVisibilityInternalAndExternalDescription",
			label: "Internal Event Description",
			helpText: "Description provided on event details page for Internal and External events"
		},
		{
			name: "loginBackground",
			label: "Login Background",
			type: "imageSelector",
			images: ["/images/login_background.jpg"],
			defaultValue: "/images/login_background.jpg"
		},
		{
			name: "loginBackgroundColour",
			label: "Login Background Colour",
			type: "colourEditor"
		},
		{
			name: "projectEnquiryReceivedEmailContent",
			required: true,
			label: "Project enquiry received email content",
			type: "textEditor",
			helpText: "Content used within project enquiries for student projects",
			variables: [
				{ name: "UserName", description: "Username of User" },
				{ name: "FullName", description: "Full Name of User" },
				{ name: "ProjectTitle", description: "Title of project" },
				{ name: "Email", description: "Email of user enquiring" },
				{ name: "SubmissionDate", description: "Date of submission for enquiry" },
				{ name: "EnquiryContent", description: "Content of enquiry" }
			]
		},
		{
			name: "featureContactReceivedEmailContent",
			required: true,
			label: "Feature contact received email content",
			type: "textEditor",
			helpText: "Content used within contact feature requests",
			variables: [
				{ name: "UserName", description: "Username of User" },
				{ name: "FullName", description: "Full Name of User" },
				{ name: "Email", description: "Email of user" },
				{ name: "Subject", description: "Request subject" },
				{ name: "Message", description: "Request message" },
				{ name: "FeatureTitle", description: "Title of contact feature" },
				{ name: "SubmissionDate", description: "Date of submission for enquiry" }
			]
		},
		{
			name: "featureContactConfirmationEmailContent",
			required: true,
			label: "Feature contact confirmation email content",
			type: "textEditor",
			helpText: "Content used within contact feature request confirmations",
			variables: [
				{ name: "Subject", description: "Request subject" },
				{ name: "Message", description: "Request message" },
				{ name: "FeatureTitle", description: "Title of contact feature" },
				{ name: "SubmissionDate", description: "Date of submission for enquiry" }
			]
		},
		{
			name: "collaborationEnquiryReceivedEmailContent",
			required: true,
			label: "Collaboration enquiry received email content",
			type: "textEditor",
			helpText: "Content used within enquiries for collaborations",
			variables: [
				{ name: "UserName", description: "Username of User" },
				{ name: "FullName", description: "Full Name of User" },
				{ name: "CollaborationTitle", description: "Title of collaboration" },
				{ name: "Email", description: "Email of user enquiring" },
				{ name: "SubmissionDate", description: "Date of submission for enquiry" },
				{ name: "EnquiryContent", description: "Content of enquiry" }
			]
		}
	]
};

const sendSwitch = {
	siteLinks: value => JSON.stringify(value),
	hiddenUsers: value => map(value, u => u.value || u).join(";"),
	homePageLayout: value => JSON.stringify(value),
	reports: value => JSON.stringify(value),
	navbarWidgets: value => JSON.stringify(value)
};

const Settings = props => {
	const { viewProps: view, values, formFiles, fields, loading } = props;

	React.useEffect(props.getSettings, []);

	const handleSubmit = fieldNames => {
		if (props.validateFields(fieldNames)) {
			// Convert settings to collection form and parse values if required
			const mapped = map(pick(values, fieldNames), (value, name) => 
				({ name, value: sendSwitch[name] ? sendSwitch[name](value) : value })
			);

			props.saveSettings(mapped, pick(formFiles, fieldNames));
		}
	}

	const settingsPanel = (fieldNames, renderBody) =>
		<Form onSubmit={() => handleSubmit(fieldNames)}>
			{renderBody ? renderBody() :
				<Grid container spacing={3}>
					{!loading && map(fieldNames, (s, index) => 
						fields[s] && <Grid item key={index} xs={12}>{fields[s]()}</Grid>
					)}
				</Grid>
			}
			<Button type="submit" loading={loading} style={{ marginTop: 20 }}>Update settings</Button>
		</Form>;

	const content = (
		<TabContainer renderOnLoad>
			<Tab label="Theme" show={view.theme}>
				{settingsPanel(["logo", "loginBackground", "loginBackgroundColour", "emailLogo"])}
			</Tab>
			<Tab label="Problem Settings" show={view.problemSettings}>
				{settingsPanel([
					"leaderBoardSize", 
					"alertOnVotes", 
					"hiddenUsers", 
					"submitProblemHeaderContent",
					"submitProblemTitleHelpText",
					"submitProblemDescriptionHelpText",
					"submitProblemSolutionHelpText"
				])}
			</Tab>
			<Tab label="Help &amp; Resources" show={view.helpResources}>
				{settingsPanel([
					"aboutContent", 
					"helpContent", 
					"loginAcceptTermsContent",
					"registrationInitialText",
					"copyrightContent",
					"disclaimerContent",
					"fundingContent",
					"contactUsText",
					"termsDialogContent",
					"showPageBanner",
					"pageBannerContent",
					"showTenantTitle",
					"submitOfferText",
					"eventVisibilityInternalDescription",
					"eventVisibilityExternalDescription",
					"eventVisibilityInternalAndExternalDescription"
				])}
			</Tab>
			<Tab label="Site Links" show={view.siteLinks}>
				{settingsPanel(["siteLinks", "facebook", "twitter", "linkedIn"])}
			</Tab>
			<Tab label="General Administration" show={view.generalAdministration}>
				{settingsPanel([
					"generalAdminEmailAddress", 
					"userRegistrationEmailContent", 
					"problemRegistrationEmailAddress", 
					"problemRegistrationEmailContent", 
					"applicationEmailAddress", 
					"contactUsEmailAddress", 
					"contactUsConfirmationEmailContent", 
					"contactUsReceivedEmailContent", 
					"eventRegistrationConfirmationEmailContent",
					"eventRegistrationReceivedEmailContent",
					"problemVoteAlertEmailContent",
					"eventRequestReceivedEmailContent",
					"eventRequestConfirmationEmailContent",
					"emailSignature",
					"mySkillsButton1Label",
					"mySkillsButton1Report",
					"mySkillsButton2Label",
					"mySkillsButton2Report",
					"projectEnquiryReceivedEmailContent",
					"collaborationEnquiryReceivedEmailContent",
					"featureContactReceivedEmailContent",
					"featureContactConfirmationEmailContent"
				])}
			</Tab>
			<Tab label="Home Page" show={view.homePage}>
				{settingsPanel(["homeHeaderText", "homePageLayout", "featuresHeading", "featuresText"])}
			</Tab>
			<Tab label="Navigation" show={view.navigation}>
				{settingsPanel(["navbarWidgets"])}
			</Tab>
			<Tab label="Manage Reports" show={view.manageReports}>
				{settingsPanel(["reports"])}
			</Tab>
			<Tab label="Terminology" show={view.terminology}>
				{settingsPanel([
					"problemOverrideSingle", 
					"problemOverridePlural", 
					"primaryCompetencySingle", 
					"aspirationalCompetencySingle",
					"primaryCompetencyDescription", 
					"aspirationalCompetencyDescription"
				], () =>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Keyword</TableCell>
								<TableCell>Single</TableCell>
								<TableCell>Plural</TableCell>
								<TableCell>Description</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Problem</TableCell>
								<TableCell>{fields.problemOverrideSingle()}</TableCell>
								<TableCell>{fields.problemOverridePlural()}</TableCell>
								<TableCell />
							</TableRow>
							<TableRow>
								<TableCell>Primary Competency</TableCell>
								<TableCell>{fields.primaryCompetencySingle()}</TableCell>
								<TableCell />
								<TableCell>{fields.primaryCompetencyDescription()}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Aspirational Competency</TableCell>
								<TableCell>{fields.aspirationalCompetencySingle()}</TableCell>
								<TableCell />
								<TableCell>{fields.aspirationalCompetencyDescription()}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}
			</Tab>
			<Tab label="Notifications" show={view.notifications}>
				{settingsPanel([
					"notificationUserRegistration",
					"notificationProblemRegistration",
					"notificationProblemVoteAlert",
					"notificationOfferCreated",
					"notificationOfferClosed",
					"notificationCompetenciesSaved"
				])}
			</Tab>
		</TabContainer>
	);

	return <PageLayout title="Site settings" pageContent={content} breadcrumbs={["Settings"]} />;
};

Settings.propTypes = {
	settings: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	getSettings: PropTypes.func.isRequired,
	saveSettings: PropTypes.func.isRequired,
	viewProps: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	settings: state.settings,
	loading: isLoading(state),
	viewProps: settingsTabViewProps(state)
});

const mapDispatchToProps = dispatch => ({
	getSettings: () => dispatch(getSettings()),
	saveSettings: (settings, files) => dispatch(saveSettings(settings, files)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(Settings));
