import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (window.SkillsLogiq.SentryDSN) {
	Sentry.init({
		environment: window.SkillsLogiq.Environment,
		dsn: window.SkillsLogiq.SentryDSN,
		release: "skillslogiq@" + process.env.npm_package_version,
		integrations: [new Integrations.BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
}