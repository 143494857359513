const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const showSuccessNotification = (message) => ({ type: SHOW_NOTIFICATION, notificationType: 'success', message });
export const showErrorNotification = (message) => ({ type: SHOW_NOTIFICATION, notificationType: 'error', message });
export const showInfoNotification = (message) => ({ type: SHOW_NOTIFICATION, notificationType: 'info', message });
export const showWarningNotification = (message) => ({ type: SHOW_NOTIFICATION, notificationType: 'warning', message });
export const hideNotification = () => ({ type: HIDE_NOTIFICATION });

const initialState = {
	show: false,
	type: 'info',
	message: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOW_NOTIFICATION:
			return {
				...state,
				show: true,
				type: action.notificationType,
				message: action.message
			};
		case HIDE_NOTIFICATION:
			return {
				...state,
				show: false,
				message: ''
			};
		default:
			return state;
	}
};
