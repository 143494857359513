import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification } from '../store/notifications';
import filter from 'lodash/filter';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	problemChat: []
};

const CLEAR_PROBLEM_CHAT = "CLEAR_PROBLEM_CHAT";
const GET_PROBLEM_CHAT_REQUEST = "GET_PROBLEM_CHAT_REQUEST";
const GET_PROBLEM_CHAT_SUCCESS = "GET_PROBLEM_CHAT_SUCCESS";
const GET_PROBLEM_CHAT_FAILURE = "GET_PROBLEM_CHAT_FAILURE";
const SAVE_PROBLEM_CHAT_REQUEST = "SAVE_PROBLEM_CHAT_REQUEST";
const SAVE_PROBLEM_CHAT_SUCCESS = "SAVE_PROBLEM_CHAT_SUCCESS";
const SAVE_PROBLEM_CHAT_FAILURE = "SAVE_PROBLEM_CHAT_FAILURE";
const DELETE_PROBLEM_CHAT_REQUEST = "DELETE_PROBLEM_CHAT_REQUEST";
const DELETE_PROBLEM_CHAT_SUCCESS = "DELETE_PROBLEM_CHAT_SUCCESS";
const DELETE_PROBLEM_CHAT_FAILURE = "DELETE_PROBLEM_CHAT_FAILURE";

export const isLoading = createLoadingSelector(["GET_PROBLEM_CHAT", "SAVE_PROBLEM_CHAT"]);

export const clearProblemChat = () => ({ type: CLEAR_PROBLEM_CHAT });

export const getProblemChat = problemId =>
	createFetchAction({
		url: `/api/problems/${problemId}/chat`,
		startAction: GET_PROBLEM_CHAT_REQUEST,
		onError: error => [getProblemChatFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getProblemChatSuccess(data)
	});

export const getProblemChatSuccess = data => ({ type: GET_PROBLEM_CHAT_SUCCESS, payload: { data } });
export const getProblemChatFailure = error => ({ type: GET_PROBLEM_CHAT_FAILURE, payload: { error } });

export const saveProblemChat = (problemId, message) =>
	createPostAction({
		url: `/api/problems/${problemId}/chat`,
		data: { message },
		startAction: SAVE_PROBLEM_CHAT_REQUEST,
		onError: error => [saveProblemChatFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveProblemChatSuccess(data)];
			} else {
				return [saveProblemChatSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveProblemChatSuccess = data => ({ type: SAVE_PROBLEM_CHAT_SUCCESS, data });
export const saveProblemChatFailure = error => ({ type: SAVE_PROBLEM_CHAT_FAILURE, error });

export const deleteProblemChat = message =>
	createPostAction({
		url: `/api/problems/${message.problemId}/delete-chat/${message.problemChatId}`,
		startAction: DELETE_PROBLEM_CHAT_REQUEST,
		onError: error => [deleteProblemChatFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteProblemChatSuccess(data)];
			} else {
				return [deleteProblemChatSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteProblemChatSuccess = data => ({ type: DELETE_PROBLEM_CHAT_SUCCESS, data });
export const deleteProblemChatFailure = error => ({ type: DELETE_PROBLEM_CHAT_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_PROBLEM_CHAT:
			return { ...state, problemChat: [] };
		case GET_PROBLEM_CHAT_REQUEST:
			return { ...state, saveResult: emptySaveResult, problemChat: [] };
		case GET_PROBLEM_CHAT_SUCCESS:
			return { ...state, isLoading: false, problemChat: action.payload.data };
		case SAVE_PROBLEM_CHAT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_PROBLEM_CHAT_SUCCESS:
			return {
				...state,
				...(action.data.success && { problemChat: action.data.object }), 
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DELETE_PROBLEM_CHAT_REQUEST:
			return { ...state, isLoading: true };
		case DELETE_PROBLEM_CHAT_SUCCESS:
			return {
				...state,
				problemChat: filter(state.problemChat, c => c.problemChatId !== action.data.objectId), 
				isLoading: false
			};
		case DELETE_PROBLEM_CHAT_FAILURE:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};
