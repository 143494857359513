import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { getFeatures, isLoading } from '../../store/features';
import { showModal } from '../../store/modal';
import FeatureBox from '../widgets/FeatureBox';
import Card from '../widgets/Card';
import map from 'lodash/map';
import ContactDialog from './ContactDialog';

const FeatureShowcase = React.memo(props => {
	const [contactDialog, setContactDialog] = React.useState({ open: false, feature: {} });

	React.useEffect(props.getFeatures, []);

	return (
		<React.Fragment>
			<Card title={props.featuresHeading} subtitle={props.featuresText} shrink loading={props.loading}>
				<Grid item sm={12} container spacing={1}>
					{map(props.features, (f, i) => 
						<Grid key={i} item sm={12} md={f.fullWidth ? 12 : 6}>
							<FeatureBox 
								feature={f} 
								showWarning={props.showFeatureWarning} 
								showContact={f => setContactDialog({ open: true, feature: f })} 
							/>
						</Grid>
					)}
				</Grid>
			</Card>
			<ContactDialog 
				open={contactDialog.open} 
				feature={contactDialog.feature} 
				onClose={() => setContactDialog({ open: false, feature: {} })} 
			/>
		</React.Fragment>
	);
});

FeatureShowcase.propTypes = { 
	features: PropTypes.arrayOf(PropTypes.object).isRequired,
	featuresHeading: PropTypes.string.isRequired,
	featuresText: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	features: state.features.features,
	featuresHeading: state.settings.featuresHeading || "",
	featuresText: state.settings.featuresText || "",
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getFeatures: () => dispatch(getFeatures("Internal")),
	showFeatureWarning: type => dispatch(showModal("MESSAGE", { 
		title: `${type} not available`, 
		message: `The associated ${type} is no longer available.`
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureShowcase);