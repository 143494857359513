import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';

const MentorCard = React.memo(({ mentor: m }) => 
	<Card className="mentor-card">
		<Link to={`/mentors/${m.mentorId}`} className="h3">
			{m.image && <Avatar src={m.image} fullName={m.fullName} style={{ marginRight: 5 }} />}
			{m.fullName}
		</Link>
		{m.industryName}, {m.discipline}
		<br />
		{m.seniority} {m.yearsExperience} Years
	</Card>
);

MentorCard.propTypes = {
	mentor: PropTypes.shape({
		mentorId: PropTypes.number,
		fullName: PropTypes.string,
		industryName: PropTypes.string,
		industryCode: PropTypes.string,
		IndustryGroup: PropTypes.string,
		seniority: PropTypes.string,
		yearsExperience: PropTypes.number,
		discipline: PropTypes.string,
		courses: PropTypes.array,
		academicJobs: PropTypes.array,
		professionalQualifications: PropTypes.string,
		professionalJobs: PropTypes.array,
		executiveExperience: PropTypes.string,
		createdBy: PropTypes.string,
		createdAt: PropTypes.string,
		UpdatedBy: PropTypes.string,
		UpdatedAt: PropTypes.string,
		tenantId: PropTypes.number,
		image: PropTypes.string
	}).isRequired
};

export default MentorCard;