import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from '../widgets/Button';
import Checkbox from '../widgets/Checkbox';
import map from 'lodash/map';

const EditChannelsDialog = props => {
	const [allChannels, setAllChannels] = React.useState(false);
	const [selection, setSelection] = React.useState([]);
	const { open } = props;

	const onOpen = () => {
		setSelection(props.subscribed);
		setAllChannels(props.allChannels);
	}

	return (
        <Dialog
            open={open}
            onClose={props.onClose}
            TransitionProps={{
                onEnter: onOpen
            }}>
			<DialogTitle>Update channels</DialogTitle>
			<DialogContent>
				<Checkbox label="All Channels" checked={allChannels} onChange={e => setAllChannels(e.target.checked)} />
				{map(props.channels, (t, i) =>
					<Checkbox 
						key={i}
						label={t.name}
						checked={selection.some(s => s === t.channelId)}
						disabled={allChannels}
						onChange={e => {
							if (e.target.checked) {
								setSelection([...selection, t.channelId]);
							} else {
								setSelection(selection.filter(s => s !== t.channelId));
							}
						}}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button onClick={() => props.onSubmit(selection, allChannels)}>Save</Button>
			</DialogActions>
		</Dialog>
    );
};

EditChannelsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	subscribed: PropTypes.array.isRequired,
	channels: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	allChannels: PropTypes.bool.isRequired
};

export default EditChannelsDialog;