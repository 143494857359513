import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { get } from '../../utils/ajax';
import { map } from 'lodash';

const MyTopCompetencies = ({ userId, setLoading, colourPalette }) => {
	const [data, setData] = React.useState({});

	const loadData = () => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/my-top-competencies?userId=${userId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (userId) {
			loadData();
		}
	}, [userId]);

	const chartData = data.data || [];
	const maxLevel = Math.max(data.maxLevel, ...map(chartData, d => d.level));

	return (
		<ReactEcharts 
			option={{
				color: colourPalette,
				legend: { show: false },
				tooltip: {},
				grid: { containLabel: true },
				yAxis: {
					type: "category", 
					data: chartData.map(d => d.unitCode)
				},
				xAxis: {
					type : "value",
					axisLabel: { formatter: "{value}" },
					min: 0,
					max: maxLevel,
					minInterval: 1
				},
				series: [
					{
						type: "bar", 
						name: "Skill Level", 
						data: chartData.map(d => ({
							value: d.level
						}))
					}
				]
			}}
			style={{ height: "100%" }} 
		/>
	);
};

MyTopCompetencies.propTypes = {
	setLoading: PropTypes.func.isRequired,
	colourPalette: PropTypes.array
};

MyTopCompetencies.defaultProps = {
	colourPalette: undefined
};

export default MyTopCompetencies;
