import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditDiscussion from './EditDiscussion';
import { get, post } from '../../utils/ajax';
import { emptySaveResult } from '../../variables';
import { canEditCollaboration } from '../../store/security';

const EditDiscussionController = props => {
	const { collaborationId, collaborationDiscussionId } = props.match.params;
	const [loading, setLoading] = React.useState(false);
	const [discussion, setDiscussion] = React.useState(null);
	const [saveResult, setSaveResult] = React.useState(emptySaveResult);

	React.useEffect(() => {
		setLoading(true);
		get({
			url: `/api/collaborations/discussion/${collaborationDiscussionId}`,
			onSuccess: data => {
				setLoading(false);
				setDiscussion(data);
			},
			onError: () => setLoading(false)
		});
	}, [collaborationDiscussionId]);

	const saveDiscussion = (discussion) => {
		setLoading(true);
		post({
			url: `/api/collaborations/${discussion.collaborationId}/save-discussion`,
			data: discussion,
			onSuccess: data => {
				setLoading(false);
				setSaveResult(data);

				if (data.success) {
					setDiscussion(data.object);
					props.showSuccessNotification(data.message);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: () => setLoading(false)
		});
	};

	const deleteDiscussion = (discussion) => {
		props.promptDeleteDiscussion(() => {
			setLoading(true);
			post({
				url: `/api/collaborations/${discussion.collaborationId}/delete-discussion/${discussion.discussionCollaborationId}`,
				data: discussion,
				onSuccess: data => {
					setLoading(false);
					setSaveResult(data);

					if (data.success) {
						setDiscussion(data.object);
						props.showSuccessNotification(data.message);
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: () => setLoading(false)
			});
		});
	};

	return (
		<PageLayout 
			title="Edit discussion" 
			pageContent={
				<EditDiscussion
					discussion={discussion}
					saveDiscussion={saveDiscussion}
					deleteDiscussion={deleteDiscussion}
					loading={loading}
					saveResult={saveResult}
				/>
			} 
			breadcrumbs={[
				{ label: "Collaboration", path: `/collaborations/${collaborationId}` }, 
				"Edit discussion"
			]} 
		/>
	);
};

EditDiscussionController.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

EditDiscussionController.defaultProps = {
};

const mapStateToProps = (state, ownProps) => ({
	canEdit: canEditCollaboration(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptDeleteDiscussion: onOk => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this discussion?",
		onOk: () => onOk
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDiscussionController);