import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../widgets/Card';
import map from 'lodash/map';
import Tag from '../widgets/Tag';
import { get } from '../../utils/ajax';
import { CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from 'react-router-dom/Link';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	toolCard: {
		'& .card-body': {
			'& .label': {
				backgroundColor: theme.palette.primary.main
			},
			'& .academic': {
				'& svg': {
					color: theme.palette.secondary.main
				}
			}
		}
	}
});

const ToolCard = ({ toolId, onDelete, canEdit, errorColour, classes }) => {
	const [loading, setLoading] = React.useState(true);
	const [tool, setTool] = React.useState({});

	React.useEffect(() => {
		get({
			url: `/api/tools/${toolId}`,
			onSuccess: (data) => {
				setLoading(false);
				setTool(data);
			},
			onError: (error) => {
				setLoading(false);
			}
		})

	}, [toolId]);

	return (
        <Card 
			className={`tool-card ${classes.toolCard}`}
			title={
				<Link to={`/tools/${tool.toolId}`}>
					<span className="h3">{tool.name}</span>
				</Link>
			}
			titleRightContent={
				canEdit ? 
				<IconButton onClick={onDelete} aria-label="Delete Tool" size="large">
					<DeleteIcon style={{ errorColour }} />
				</IconButton>
				: undefined
			}
		>
			{loading ? 
				<CircularProgress size={24} /> : 
				<React.Fragment>
					<div className="description">{tool.description}</div>
					<div className="tags">
						{map(tool.tags, (t, i) => <Tag key={i} label={t.name} />)}
					</div>

				</React.Fragment>
			}
		</Card>
    );
};

ToolCard.propTypes = { 
	canEdit: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

ToolCard.defaultProps = {
	canEdit: false
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ToolCard, styles));
