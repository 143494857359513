import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from './AutoComplete';
import map from 'lodash/map';
import find from 'lodash/find';

const AreaOfExpertiseEditor = React.memo(props => 
	<AutoComplete 
		className="dih-input"
		label="Area Of Expertise"
		placeholder={props.placeholder}
		isMulti
		value={map(props.value, v => ({ label: v.name, value: v._value || String(v.areaOfExpertiseId), __isNew__: v.areaOfExpertiseId === 0 }))}
		loadItems={{
			route: `areas-of-expertise`,
			mapItem: t => ({ label: t.name, value: String(t.areaOfExpertiseId) })
		}}
		onChange={values => props.onChange(map(values, t => {
			if (t.__isNew__) return { areaOfExpertiseId: 0, name: t.label, _value: t.value };

			const areaOfExpertiseId = Number(t.value);
			return find(props.value, t2 => t2.areaOfExpertiseId === areaOfExpertiseId) || { areaOfExpertiseId, name: t.label };
		}))}
		canCreate
		helpText="Use your Enter or Tab key to separate each Area Of Expertise"
	/>);

AreaOfExpertiseEditor.propTypes = {
	placeholder: PropTypes.string,
	value: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	styles: PropTypes.object
};

AreaOfExpertiseEditor.defaultProps = {
	placeholder: "",
	value: [],
	styles: {}
};

export default AreaOfExpertiseEditor;
