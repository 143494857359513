import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../../utils/forms';
import { 
	Grid,
	IconButton, 
	Table, 
	TableHead, 
	TableRow, 
	TableCell, 
	TableBody 
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Card from '../../widgets/Card';
import Checkbox from '../../widgets/Checkbox';
import TextField from '../../widgets/TextField';
import Wizard from '../../widgets/Wizard/Wizard';
import UserDialog from '../../widgets/UserDialog';
import Tag from '../../widgets/Tag';
import map from 'lodash/map';
import find from 'lodash/find';
import uniqueId from 'lodash/uniqueId';
import forEach from 'lodash/forEach';
import some from 'lodash/some';

const form = {
	initValues: ({ 
		tenantId, 
		organisation: o = {}, 
		defaultAreaId, 
		defaultSkillsFramework, 
		defaultSkillsProfileId,
		defaultPrimarySkillsProfileGroupId,
		defaultSecondarySkillsProfileGroupId,
		defaultInitialAssessmentProfileId
	}) => ({ 
		tenantId: tenantId,
		type: "Organisation",
		showUserTour: true, 
		visibility: 'Private',
		secondaryProfileVisibility: 'Show',
		defaultCoursesView: "Linked",
		initialAssessmentSkillsView: "Graphical",
		...o, 
		defaultRoles: map(o.defaultRoles, r => ({ ...r, label: r.name, value: r.roleId })), 
		defaultChannels: map(o.defaultChannels, c => ({ ...c, label: c.name, value: c.channelId })),
		positions: [],
		areaId: defaultAreaId,
		skillsFramework: defaultSkillsFramework,
		defaultSkillsProfileId: defaultSkillsProfileId,
		primarySkillsProfileGroupId: defaultPrimarySkillsProfileGroupId,
		secondarySkillsProfileGroupId: defaultSecondarySkillsProfileGroupId,
		initialAssessmentProfileId: defaultInitialAssessmentProfileId,
		allowSelfRegistration: true
	}),
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			required: true,
			loadItems: {
				route: "tenants",
				mapItem: t => ({ label: t.name, value: t.tenantId })
			}
		},
		{
			name: "name",
			label: "Name",
			required: true,
		},
		{
			name: "country",
			label: "Country"
		},
		{
			name: "type",
			label: "Type",
			required: true,
			type: "select",
			items: ["Organisation", "Institution"]
		},
		{
			name: "hide",
			label: "Hide",
			type: "checkbox"
		},
		{
			name: "logo",
			label: "Logo",
			type: "avatar"
		},
		{
			name: "accreditedLogo",
			label: "Accredited Logo",
			type: "avatar"
		},
		{
			name: "userTour",
			label: "User Tour",
			type: "fileUploader"
		},
		{
			name: "registrationProcess",
			label: "Registration Process",
			type: "select",
			items: ["Anonymous", "Controlled", { label: "Admin Only", value: "AdminOnly" }, "Hidden"]
		},
		{
			name: "userCanSelectSkillsFramework",
			label: "Can Select Competency Framework",
			type: "checkbox",
			helpText: "Allow User to select competency framework"
		},
		{
			name: "autoShowAddCompetencies",
			label: "Auto-show Add Competencies for primary Skills Profile",
			type: "checkbox",
			helpText: "Automatically show 'Add Competencies' dialog when users competencies are incomplete"
		},
		{
			name: "autoShowAddAspirationalCompetencies",
			label: "Auto-show Add Competencies for aspirational Skills Profile",
			type: "checkbox",
			helpText: "Automatically show 'Add Competencies' dialog when users competencies are incomplete"
		},
		{
			name: "autoExpandCompetencies",
			label: "Auto-expand Competencies",
			type: "checkbox",
			helpText: "Automatically expand competencies in 'Add Competencies' dialog"
		},
		{
			name: "showNotCompetent",
			label: "Show Not Competent",
			type: "checkbox",
			helpText: "Show or hide option to set user as not competent for unit in 'Add Competencies' dialog"
		},
		{
			name: "defaultPositionTitle",
			label: "Default Position",
			helpText: "Default position for new users (will be created upon submission)"
		},
		{
			name: "defaultSkillsProfileId",
			label: "Default Skills Profile",
			type: "profilePicker",
			helpText: "Default skills profile for new users"
		},
		{
			name: "initialAssessmentProfileId",
			label: "Initial Assessment Profile",
			type: "profilePicker",
			helpText: "Initial Assessment Profile"
		},
		{
			name: "defaultRoles",
			label: "Default Roles",
			type: "autoComplete",
			loadItems: {
				route: props => `roles/by-tenant/${props.tenantId}`,
				mapItem: ({ roleId, name }) => ({ label: name, value: roleId, roleId })
			},
			isMulti: true,
			helpText: "Default roles for new users"
		},
		{
			name: "defaultChannels",
			label: "Default Channels",
			type: "autoComplete",
			loadItems: {
				route: props => `channels/by-tenant/${props.tenantId}`,
				mapItem: ({ channelId, name }) => ({ label: name, value: channelId, channelId })
			},
			isMulti: true,
			helpText: "Default subscribed channels for new users",
			chipColour: props => props.theme.secondaryColour
		},
		{
			name: "showUserTour",
			label: "Show User Tour",
			type: "checkbox",
			helpText: "Show the user tour when the user logs in"
		},
		{
			name: "contactEmail",
			label: "Contact Email"
		},
		{
			name: "primarySkillsProfileGroupId",
			label: "Primary Skills Profile Group",
			type: "profilePicker",
			groupOnly: true,
			helpText: "Default group for selecting skills profile within primary competencies page"
		},
		{
			name: "secondarySkillsProfileGroupId",
			label: "Secondary Skills Profile Group",
			type: "profilePicker",
			groupOnly: true,
			helpText: "Default group for selecting skills profile within secondary competencies page"
		},
		{
			name: "includePrimaryAndSecondaryProfilesInStats",
			label: "Include Primary and Secondary Profile in Competency Stats",
			type: "checkbox"
		},
		{
			name: "completeUserAssessment",
			label: "Complete Initial User Assessment",
			type: "checkbox",
			helpText: "Whether users should indicate that they have completed assessment"
		},
		{
			name: "skillsFrameworks",
			label: "Skills Frameworks",
			type: "frameworkPicker",
			isMulti: true
		},
		{
			name: "canCreatePublicTool",
			label: "Can Create Public Tools",
			type: "checkbox",
			helpText: "Enable users to set a tool's visibility to \"Public\""
		},
		{
			name: "hideDepartmentDuringRegistration",
			label: "Hide Department During Registration",
			type: "checkbox"
		},
		{
			name: "hidePositionDuringRegistration",
			label: "Hide Position During Registration",
			type: "checkbox"
		},
		{
			name: "secondaryProfileVisibility",
			label: "Secondary Profile Visibility",
			required: true,
			type: "select",
			items: ["Show", "Hide", "Disable"]
		},
		{
			name: "defaultCoursesView",
			label: "Default Courses View",
			type: "select",
			required: true,
			items: [
				{ label: "All Courses", value: "All" },
				{ label: "Linked Courses Only", value: "Linked" }
			]
		},
		{
			name: "showMembershipLevelId",
			label: "Show Membership Level",
			type: "checkbox"
		},
		{
			name: "showReviewStatus",
			label: "Show Review Status",
			type: "checkbox"
		},
		{
			name: "graphicalSkillsView",
			label: "Graphical Skills View",
			type: "checkbox"
		},
		{
			name: "initialAssessmentSkillsView",
			label: "Initial Assessment Skills View",
			required: true,
			type: "select",
			items: ["Graphical", "Textual"]
		},
		{
			name: "allowSelfRegistration",
			label: "Allow Self Registration",
			type: "checkbox"
		},
		{
			name: "departments",
			label: "Business Units",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
				if (value.length < 1) return "At least one business unit is required";
				if (some(value, d => !d.name)) return "All business units must have a name";

				return "";
			},
			widget: props => 
				<React.Fragment>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '60px' }}>
									<IconButton
                                        onClick={() => { 
											const departments = props.value || [];
											departments.push({
												clientId:  uniqueId("department-"),
												name: "",
												description: "",
												isDefault: departments.length === 0
											});
											props.onChange(departments);
										}}
                                        style={{ marginLeft: 5 }}
                                        size="large">
										<AddCircleIcon />
									</IconButton>
								</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>Is Default</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{map(props.value, (d, i) =>
								<TableRow key={`department-${i}`}>
									<TableCell style={{ width: '60px' }}>
										<IconButton
                                            onClick={() => { 
												const departments = props.value;
												departments.splice(i, 1);
												props.onChange(departments);
											}}
                                            style={{ marginLeft: 5 }}
                                            size="large">
											<DeleteIcon />
										</IconButton>
									</TableCell>
									<TableCell>
										<TextField
											disableUnderline={true}
											value={d.name}
											placeholder="Name..."
											onChange={e => {
												const departments = props.value;
												const department = find(departments, d2 => d2.clientId === d.clientId);
												department.name = e.target.value;
												props.onChange(departments);
											}}
										/>
									</TableCell>
									<TableCell>
										<TextField
											disableUnderline={true}
											value={d.description}
											placeholder="Description..."
											onChange={e => {
												const departments = props.value;
												const department = find(departments, d2 => d2.clientId === d.clientId);
												department.description = e.target.value;
												props.onChange(departments);
											}}
										/>
									</TableCell>
									<TableCell>
										<Checkbox
											checked={d.isDefault}
											onChange={e => {
												const departments = props.value;
												forEach(departments, d2 => {
													d2.isDefault = (d2.clientId === d.clientId);
												});
												props.onChange(departments);
											}}
										/>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</React.Fragment>
			},
			{
				name: "positions",
				label: "Positions",
				type: "custom",
				validate: (value, values) => {
					if (value.length < 1) return "At least one position is required";
					if (some(value, p => !p.title)) return "All positions must have a title";
	
					return "";
				},
				widget: props => 
					<React.Fragment>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: '60px' }}>
										<IconButton
                                            onClick={() => { 
												const positions = props.value || [];
												positions.push({
													clientId:  uniqueId("position-"),
													title: "",
													isDefault: positions.length === 0
												});
												props.onChange(positions);
											}}
                                            style={{ marginLeft: 5 }}
                                            size="large">
											<AddCircleIcon />
										</IconButton>
									</TableCell>
									<TableCell>Title</TableCell>
									<TableCell>Is Default</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{map(props.value, (p, i) =>
									<TableRow key={`position-${i}`}>
										<TableCell style={{ width: '60px' }}>
											<IconButton
                                                onClick={() => { 
													const positions = props.value;
													positions.splice(i, 1);
													props.onChange(positions);
												}}
                                                style={{ marginLeft: 5 }}
                                                size="large">
												<DeleteIcon />
											</IconButton>
										</TableCell>
										<TableCell>
											<TextField
												disableUnderline={true}
												value={p.title}
												placeholder="Title..."
												onChange={e => {
													const positions = props.value;
													const position = find(positions, p2 => p2.clientId === p.clientId);
													position.title = e.target.value;
													props.onChange(positions);
												}}
											/>
										</TableCell>
										<TableCell>
											<Checkbox
												checked={p.isDefault}
												onChange={e => {
													const positions = props.value;
													forEach(positions, p2 => {
														p2.isDefault = (p2.clientId === p.clientId);
													});
													props.onChange(positions);
												}}
											/>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</React.Fragment>
		},
		{
			name: "users",
			label: "Users",
			type: "custom",
			defaultValue: [],
			validate: (value, values) => {
				return value.length > 0 ? "" : "At least one user is required";
			},
			widget: props => {
				const [user, setUser] = React.useState(null);
				const [isNewUser, setIsNewUser] = React.useState(true);
				const [showUserDialog, setShowUserDialog] = React.useState(false);

				return (
                    <React.Fragment>
                        <UserDialog
                            tenantId={props.ownProps.tenantId}
                            show={showUserDialog} 
                            onCancel={() => {
                                setShowUserDialog(false);
                            }}
                            departments={map(props.values.departments, d => ({ value: d.clientId, label: d.name }))}
                            positions={map(props.values.positions, p => ({ value: p.clientId, label: p.title }))}
                            user={user}
                            isNew={isNewUser}
                            onOk={(newUser) => {
                                const users = props.value || [];
                                users.push(
                                    newUser
                                );
                                props.onChange(users);
                                setShowUserDialog(false);
                            }}
                        />
                        
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '110px', padding: 0 }}>
                                        <IconButton
                                            onClick={() => { 
                                                const defaultDepartment = find(props.values.departments, d => d.isDefault);
                                                const defaultPosition = find(props.values.positions, p => p.isDefault);
                                                setUser({
                                                    department: defaultDepartment.clientId,
                                                    position: defaultPosition.clientId
                                                });
                                                setIsNewUser(true);
                                                setShowUserDialog(true); 
                                            }}
                                            style={{ marginLeft: 5 }}
                                            size="large">
                                            <AddCircleIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Business Unit</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Roles</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {map(props.value, (u, i) =>
                                    <TableRow key={`user-${i}`}>
                                        <TableCell style={{ width: '60px' }}>
                                            <IconButton
                                                onClick={() => { 
                                                    setIsNewUser(false);
                                                    setUser(u); 
                                                    setShowUserDialog(true);
                                                }}
                                                style={{ marginLeft: 5 }}
                                                size="large">
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => { 
                                                    const users = props.value;
                                                    users.splice(i, 1);
                                                    props.onChange(users);
                                                }}
                                                style={{ marginLeft: 5 }}
                                                size="large">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>{u.username}</TableCell>
                                        <TableCell>{u.email}</TableCell>
                                        <TableCell>{u.department.label}</TableCell>
                                        <TableCell>{u.position.label}</TableCell>
                                        <TableCell>
                                            {map(u.roles, r => <Tag key={r.roleId} label={r.name} />)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </React.Fragment>
                );
			}
		}
	]
};

const CreateOrganisation = props => {
	const { fields, values } = props;

	const errors = [];
	forEach(Object.keys(props.error), (fieldName, i) => {
		if (props.error[fieldName]) {
			errors.push(`${find(form.fields, f => f.name === fieldName).label}: ${props.error[fieldName]}`);
		}
	});

	return (
		<Card className="create-organisation">
			<Wizard
					errors={errors}
					okButtonText="Create Organisation"
					onCancel={props.onClose}
					onComplete={(onSuccess, onError) => {
						props.createNewOrganisation(
							values, 
							props.formFiles.logo, 
							props.formFiles.accreditedLogo,
							props.formFiles.userTour,
							() => {},
							(error) => { onError(); }
						);
					}}
				 	steps={[
						{
							number: 1,
							title: 'Organisation',
							validate: () => {
								const fields = ["name", "type", "country", "hide", "registrationProcess", "logo", "accreditedLogo", "userTour", "contactEmail"];
								if (props.canEditTenant) fields.push("tenantId");
								return props.validateFields(fields);
							},
							content:
								<Grid container spacing={3}>
									<Grid item xs={12} md={props.canEditTenant ? 6 : 12}>{fields.name()}</Grid>
									{props.canEditTenant && 
										<Grid item xs={12} md={6}>{fields.tenantId()}</Grid>
									}
									<Grid item xs={12} md>{fields.type()}</Grid>
									<Grid item xs md>{fields.country()}</Grid>
									<Grid item xs="auto">{fields.hide()}</Grid>
									{/* Spacer */}
									<Grid item xs={12} style={{ padding: 0 }} />
									<Grid item xs sm={6}>{fields.registrationProcess()}</Grid>
									<Grid item xs="auto">{fields.logo()}</Grid>
									<Grid item xs="auto">{fields.accreditedLogo()}</Grid>
									<Grid item xs={12} sm={6}>{fields.contactEmail()}</Grid>
								</Grid>
						},
						{
							number: 2,
							title: 'Settings',
							validate: () => props.validateFields(["showUserTour", "completeUserAssessment", "canCreatePublicTool", "hideDepartmentDuringRegistration", "hidePositionDuringRegistration", "defaultRoles", "defaultChannels", "defaultCoursesView", "showMembershipLevelId", "graphicalSkillsView"]),
							content: 
								<Grid container spacing={3}>
									<Grid item xs={12}>{fields.showUserTour()}</Grid>
									<Grid item xs={12}>{fields.completeUserAssessment()}</Grid> 
									<Grid item xs={12}>{fields.canCreatePublicTool()}</Grid>
									<Grid item xs={12} sm={6}>{fields.hideDepartmentDuringRegistration()}</Grid>
									<Grid item xs={12} sm={6}>{fields.hidePositionDuringRegistration()}</Grid>
									<Grid item xs={12}>{fields.defaultRoles()}</Grid>
									<Grid item xs={12}>{fields.defaultChannels()}</Grid>
									<Grid item xs={12} sm={6}>{fields.defaultCoursesView()}</Grid>
									<Grid item xs={12} sm={6}>{fields.allowSelfRegistration()}</Grid>
									<Grid item xs={12} sm="auto">{fields.showMembershipLevelId()}</Grid>
									<Grid item xs={12} sm="auto">{fields.graphicalSkillsView()}</Grid>
								</Grid>
						},
						{
							number: 3,
							title: 'Skills Competencies',
							validate: () => props.validateFields([
								"autoShowAddCompetencies", 
								"autoShowAddAspirationalCompetencies", 
								"autoExpandCompetencies", 
								"userCanSelectSkillsFramework", 
								"showNotCompetent",
								"includePrimaryAndSecondaryProfilesInStats",
								"canCreatePublicTool",
								"defaultSkillsProfileId",
								"initialAssessmentProfileId",
								"primarySkillsProfileGroupId",
								"secondarySkillsProfileGroupId",
								"secondarySkillsProfileGroupId",
								"skillsFrameworks",
								"initialAssessmentSkillsView",
								"showReviewStatus",
								"graphicalSkillsView"
							]),
							content: 
								<Grid container spacing={3}>
									<Grid item xs={12}>{fields.autoShowAddCompetencies()}</Grid>
									<Grid item xs={12}>{fields.autoShowAddAspirationalCompetencies()}</Grid>
									<Grid item xs={12}>{fields.autoExpandCompetencies()}</Grid>
									<Grid item xs={12}>{fields.userCanSelectSkillsFramework()}</Grid>
									<Grid item xs={12}>{fields.showNotCompetent()}</Grid>
									<Grid item xs={12}>{fields.includePrimaryAndSecondaryProfilesInStats()}</Grid> 
									<Grid item xs={12}>{fields.canCreatePublicTool()}</Grid>
									<Grid item xs={12} sm={6}>{fields.defaultSkillsProfileId()}</Grid>
									<Grid item xs={12} sm={6}>{fields.initialAssessmentProfileId()}</Grid>
									<Grid item xs={12} sm={6}>{fields.primarySkillsProfileGroupId()}</Grid>
									<Grid item xs={12} sm={6}>{fields.secondarySkillsProfileGroupId()}</Grid>
									<Grid item xs={12} sm={6}>{fields.secondaryProfileVisibility()}</Grid>
									<Grid item xs={12} sm={6}>{fields.skillsFrameworks()}</Grid>
									<Grid item xs={12} sm={6}>{fields.initialAssessmentSkillsView()}</Grid>
									<Grid item xs={12} sm="auto">{fields.showReviewStatus()}</Grid>
									<Grid item xs={12} sm="auto">{fields.graphicalSkillsView()}</Grid>
								</Grid>
						},
						{
							number: 4,
							title: 'Business Units',
							validate: () => {
								return props.validateFields(["departments"]);
							},
							content: 
								<React.Fragment>
									<p>You must add at least one Business Unit</p>
									<Grid container spacing={3}>
										<Grid item xs={12}>{fields.departments()}</Grid>
									</Grid>
								</React.Fragment>
						},
						{
							number: 5,
							title: 'Positions',
							validate: () => props.validateFields(["positions"]),
							content: 
								<React.Fragment>
									<p>You must have at least one Position</p>
									<Grid container spacing={3}>
										<Grid item xs={12}>{fields.positions()}</Grid>
									</Grid>
								</React.Fragment>
						},
						{
							number: 6,
							title: 'Users',
							validate: () => props.validateFields(["users"]),
							content: 
								<React.Fragment>
								<p>You must have at least one User</p>
								<Grid container spacing={3}>
									<Grid item xs={12}>{fields.users()}</Grid>
								</Grid>
							</React.Fragment>
						}
					]}
				>
			</Wizard>
		</Card>
	);
};

CreateOrganisation.propTypes = { 
	tenantId: PropTypes.number.isRequired,
	organisationId: PropTypes.number,
	organisation: PropTypes.object,
	createNewOrganisation: PropTypes.func.isRequired,
	deleteOrganisation: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	canEditTenant: PropTypes.bool,
	theme: PropTypes.object.isRequired
};

CreateOrganisation.defaultProps = {
	organisationId: 0,
	organisation: {},
	deleteOrganisation: () => null,
	isNew: false,
	canEditTenant: false
};

export default withForm(form)(CreateOrganisation);