import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '../widgets/Card';
import Tag from '../widgets/Tag';
import map from 'lodash/map';
import { truncateString } from '../../utils/utils';
import Link from 'react-router-dom/Link';
import FavouriteButton from './FavouriteButton';

const CentreCard = React.memo(({ centre }) => 
	<Grid item xs={12} sm={6} md={4} lg={3}>
		<Card style={{ margin: 0 }} className={`centre-card`}>
			<div className="header" style={{ textAlign: 'center' }}>
				{centre.logo && <Link to={`/research-centres/${centre.centreId}`}><img src={centre.logo} alt="" style={{ maxHeight: '200px', maxWidth: '100%' }} /></Link>}
				<Link className="h3" to={`/research-centres/${centre.centreId}`} style={{ margin: 0 }}>{centre.title}</Link>
				<FavouriteButton centreId={centre.centreId} isFavourite={centre.isFavourite} />
			</div>
			<div className="body">{truncateString(centre.description, 300)}</div>
			{map(centre.channels, c => <Tag key={c.channelId} label={c.name} />)}
			<div className="tags">
				{map(centre.tags, (t, i) => <Tag key={i} label={t.name} />)}
			</div>
		</Card>
	</Grid>
);

CentreCard.propTypes = {
	centre: PropTypes.shape({
		centreId: PropTypes.number,
		title: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
		website: PropTypes.string,
		location: PropTypes.string,
		description: PropTypes.string,
		logo: PropTypes.string,
		research: PropTypes.string,
		institutions: PropTypes.string,
		caseStudies: PropTypes.string,
		tags: PropTypes.array
	}).isRequired
};

export default CentreCard;