import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import Button from '../widgets/Button';
import { withStyles } from 'tss-react/mui';
import Tick from '../images/icons/tick_aqua.png';
import Cross from '../images/icons/cross_red.png';
import {
	useStripe
} from '@stripe/react-stripe-js';
import { 
	showSuccessNotification, 
	showErrorNotification 
} from '../../store/notifications';
import { get, post } from '../../utils/ajax';
import { changeLocation } from '../../store/location';
import find from 'lodash/find';
import { canSubscribe } from '../../store/security';

const styles = theme => ({
	pricingTable: {
		borderSpacing: '10px',
		borderCollapse: 'separate',
		
		'& th': {
			borderRadius: '10px',
			padding: '15px',
			fontSize: '20px'
		}
	},
	pricingFooter: {
		fontSize: '20px',
		fontWeight: 600
	},
	upgradeButton: {
		width: '100%'
	},
	introOfferHeader: {
		textAlign: 'left'
	},
	introOfferText: {
		fontSize: '16px',
		fontWeight: 600,
		textAlign: 'left'
	}
});

const Yes = () => <img src={Tick} alt="" height={25} />;
const No = () => <img src={Cross} alt="" height={25} />;
const pricingRow = (feature, basic, professional, creator) => 
	<tr>
		<td style={{ textAlign: 'left', fontWeight: 600 }}>{feature}</td>
		<td colSpan={2}>{basic ? <Yes /> : <No />}</td>
		<td colSpan={2}>{professional ? <Yes /> : <No />}</td>
		<td colSpan={2}>{creator ? <Yes /> : <No />}</td>
	</tr>;
const pricingRowIndented = (feature, basic, professional, creator) => 
	<tr>
		<td style={{ textAlign: 'left', paddingLeft: '20px', fontStyle: 'italic', fontWeight: 600 }}>{feature}</td>
		<td colSpan={2}>{basic ? <Yes /> : <No />}</td>
		<td colSpan={2}>{professional ? <Yes /> : <No />}</td>
		<td colSpan={2}>{creator ? <Yes /> : <No />}</td>
	</tr>;

const PricingController = props => {
	const stripe = useStripe();
	const [products, setProducts] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [selectedProduct, setSelectedProduct] = React.useState(null);
	const classes = props.classes;
	const canSubscribe = props.canSubscribe;

	React.useEffect(() => {
		setLoading(false);
		get({
			url: `/api/stripe/products`,
			onSuccess: (data) => {
				setLoading(false);
				setProducts(data);
			},
			onError: (error) => {
				setLoading(false);
			}
		});
	}, []);

	const subscribe = (product) => {
		setSelectedProduct(`${product.name}.${product.duration}`);
		setLoading(true);
		if (product.stripePriceId) {
			post({
				url: '/api/stripe/create-session',
				data: {
					priceId: product.stripePriceId
				},
				onSuccess: (data) => {
					setLoading(false);
					stripe.redirectToCheckout({
						// Make the id field from the Checkout Session creation API response
						// available to this file, so you can provide it as parameter here
						// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
						sessionId: data
					}).then(function (result) {
					});
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		} else {
			// No stripe product/price assigned to product so create subscription and return
			post({
				url: '/api/subscription/subscribe',
				data: {
					productId: product.productId
				},
				onSuccess: (data) => {
					setLoading(false);
					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToSubscriptionComplete();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		}
	};

	return (
		<PageLayout 
			title="Learning Accelerator" 
			loading={loading}
			pageContent={
				<div style={{
					maxWidth: '1000px',
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto',
					fontFamily: 'Montserrat,sans-serif'
				}}>
					<div>
						{!canSubscribe && <p>You do not have access to subscribe</p>}
						<table className={classes.pricingTable}>
							<thead>
								<tr>
									<th rowSpan={2} style={{ background: '#2AAEB3', color: '#fff' }}>FEATURES</th>
									<th colSpan={6} style={{ background: '#146185', color: '#fff' }}>FOR L&D PROVIDERS</th>
								</tr>
								<tr>
									<th colSpan={2} style={{ background: '#F7B85B', color: '#fff', width: '300px' }}>BASIC</th>
									<th colSpan={2} style={{ background: '#FE7751', color: '#fff', width: '300px' }}>PROFESSIONAL</th>
									<th colSpan={2} style={{ background: '#A34C96', color: '#fff', width: '300px' }}>CREATOR</th>
								</tr>
							</thead>
							<tbody>
								{pricingRow('My Skills Profile', true, true, true)}
								{pricingRow('Summary Skills Profile', true, true, true)}
								{pricingRow('Professional Skills Profile', false, true, true)}
								{pricingRow('Opportunities', false, true, true)}
								{pricingRow('Collaboration', false, true, true)}
								{pricingRow('Catalogues:', false, false, true)}
								{pricingRowIndented('Course Catalogue', false, false, true)}
								{pricingRowIndented('IP Catalogue', false, false, true)}
								{pricingRowIndented('Microlearning Platform', false, false, true)}
								<tr>
									<td style={{ textAlign: 'left', fontWeight: 'bold' }}>Learning Accelerator Inclusions</td>
									<td colSpan={2}></td>
									<td colSpan={2}></td>
									<td colSpan={2}><div style={{ background: '#A34C96', color: '#fff', fontWeight: 'bold', padding: '10px 0' }}>Expert Panel</div></td>
								</tr>
								{pricingRowIndented('Global Executive Roundtables', false, false, true)}
								{pricingRowIndented('Research Insights', false, false, true)}
								{pricingRowIndented('Ask the Expert', false, false, true)}
								{pricingRowIndented('Consulting Services', false, false, true)}
								<tr>
									<td colSpan={7} style={{ fontStyle: 'italic', fontWeight: 500 }}>All other Learning Accelerator features are exclusive to corporate learning teams only</td>
								</tr>
								<tr className={classes.pricingFooter}>
									<td style={{ color: '#2AAEB3' }}>COST PER ORGANISATION</td>
									<td colSpan={2} style={{ color: '#F7B85B' }}>FREE</td>
									<td style={{ color: '#FE7751' }}>
										<div>Pay Monthly</div>
										<div>$40</div>
									</td>
									<td style={{ color: '#FE7751' }}>
										<div>Pay Yearly</div>
										<div>$400</div>
										<div>2 Months Free</div>
									</td>
									<td style={{ color: '#A34C96' }}>
										<div>Pay Monthly</div>
										<div>$95</div>
									</td>
									<td style={{ color: '#A34C96' }}>
										<div>Pay Yearly</div>
										<div>$950</div>
										<div>2 Months Free</div>
									</td>
								</tr>
								<tr>
									<td colSpan={3}></td>
									<td>
										<Button 
											className={classes.upgradeButton}
											loading={loading && selectedProduct === 'Professional.Monthly'}
											disabled={!canSubscribe || (loading && selectedProduct !== 'Professional.Monthly')}
											onClick={() => {
												const product = find(products, p => p.name === 'Professional' && p.duration === 'Monthly');
												if (product) subscribe(product);
											}}
										>
											Upgrade
										</Button>
									</td>
									<td>
										<Button 
											className={classes.upgradeButton}
											loading={loading && selectedProduct === 'Professional.Annual'}
											disabled={!canSubscribe || (loading && selectedProduct !== 'Professional.Annual')}
											onClick={() => {
												const product = find(products, p => p.name === 'Professional' && p.duration === 'Annual');
												if (product) subscribe(product);
											}}
										>
											Upgrade
										</Button>
									</td>
									<td colSpan={2}>
										<Button 
											className={classes.upgradeButton}
											disabled={!canSubscribe || loading}
										>
											Coming Soon
										</Button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			} 
			breadcrumbs={[
				"Upgrade"
			]} />
	);
};

PricingController.propTypes = {
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	goToSubscriptionComplete: PropTypes.func.isRequired,
	canSubscribe: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	canSubscribe: canSubscribe(state)
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	goToSubscriptionComplete: () => dispatch(changeLocation('subscription-complete'))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(PricingController, styles, { withTheme: true }));
