import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import map from 'lodash/map';
import NotSubscribedField from '../widgets/NotSubscribedField';

const form = {
	initValues: ({ profile: p = {} }) => ({ 
		...p, 
		categories: map(p.categories, c => ({ ...c, label: c.name, value: c.categoryId }))
	}),
	fields: [
		{
			name: "title",
			label: "",
			required: true,
			helpText: "Title of profile"
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				waitFor: props => props.profile && props.profile.organisationId !== undefined ? true : false,
				filter: (props, value) => {
					if (props.isOwn) {
						return value.organisationId === props.userOrgId;
					} else {
						return props.profile.organisationId === value.organisationId || !value.businessProfileId; 
					}
				},
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		},
		{
			name: "summary",
			label: "Summary",
			helpText: "Add your brief business summary",
			required: true,
			multiline: true
		},
		{
			name: "email",
			label: "Email",
			validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid email address"
		},
		{
			name: "phone",
			label: "Phone number"
		},
		{
			name: "website",
			label: "Website"
		},
		{
			name: "location",
			label: "Business Location"
		},
		{
			name: "logo",
			label: "Logo",
			type: "imageUploader",
			single: true
		},
		{
			name: "areasOfExpertise",
			label: "Area Of Expertise",
			type: "areaOfExpertiseEditor"
		},
		{
			name: "about",
			label: "About",
			type: "textEditor"
		},
		{
			name: "products",
			label: "Products/Services",
			type: "textEditor"
		},
		{
			name: "caseStudies",
			label: "Case Studies",
			type: "textEditor"
		},
		{
			name: "accredited",
			label: "ILP Member",
			type: "checkbox"
		},
		{
			name: "accreditationId",
			label: "Membership Status",
			type: "autoComplete",
			loadItems: {
				route: "accreditations",
				mapItem: a => ({ label: a.name, value: a.accreditationId })
			},
			defaultItem: ({ label: "None", value: null })
		},
		{
			name: "categories",
			label: "Categories",
			type: "autoComplete",
			loadItems: {
				route: "categories",
				mapItem: ({ categoryId, name }) => ({ label: name, value: categoryId, categoryId })
			},
			isMulti: true
		},
		{
			name: "publishBusinessProfile",
			label: "Publish Business Profile",
			type: "checkbox"
		}
	]
};

const EditBusinessProfile = props => {
	const { fields, loading, isNew, values } = props;

	return (
		<Form onSubmit={() => props.validateFields() && props.saveProfile(props.values, props.formFiles.logo)}>
			<Grid container className="business-profile-container">
				<Grid item xs={12}>
					<Card title={`${isNew ? "Create" : "Edit"} profile`} loading={loading} className="edit-busines-profile">
						<Grid container spacing={3}>
							<Grid item xs={12}>{fields.title()}</Grid>
							<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
							<Grid item xs={12} sm={6}>{fields.location()}</Grid>
							<Grid item xs={12} sm={6}>{fields.website()}</Grid>
							<Grid item xs={12}>{fields.summary()}</Grid>
							<Grid item xs={12}>
								{props.isSubscribed ?
									fields.about()
									:
									<NotSubscribedField label="About" />
								}
							</Grid>
							<Grid item xs={12}>
								{props.isSubscribed ?
									fields.products()
									:
									<NotSubscribedField label="Products/Services" />
								}
							</Grid>
							<Grid item xs={12}>
								{props.isSubscribed ?
									fields.caseStudies()
									:
									<NotSubscribedField label="Case studies" />
								}
							</Grid>
							<Grid item xs={12} sm={6}>{fields.email()}</Grid>
							<Grid item xs={12} sm={6}>{fields.phone()}</Grid>
							<Grid item xs={12}>{fields.logo()}</Grid>
							<Grid item xs={12}>{fields.areasOfExpertise()}</Grid>
							<Grid item xs={12}>{fields.categories()}</Grid>
							<Grid item xs={12} sm={6}>{fields.publishBusinessProfile()}</Grid>
							<Grid item xs={12} sm={6}>{fields.accredited()}</Grid>
							{values.accredited && 
								<Grid item xs={12}>{fields.accreditationId()}</Grid>
							}
						</Grid>
					</Card>
				</Grid>

				<Grid item xs={12}>
					<div className="form-buttons" style={{ margin: 0 }}>
						<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} profile</Button>
						{!isNew && 
							<Button className="delete-button" loading={loading} onClick={() => props.deleteProfile(props.profile.businessProfileId)}>
								Delete profile
							</Button>
						}
					</div>
				</Grid>
			</Grid>
		</Form>
	);
};

EditBusinessProfile.propTypes = { 
	profile: PropTypes.object,
	saveProfile: PropTypes.func.isRequired,
	deleteProfile: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isSubscribed: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditBusinessProfile.defaultProps = {
	profile: {},
	deleteProfile: () => null,
	isNew: false
};

export default withForm(form)(EditBusinessProfile);