import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { prettifyDate, stripHtml } from '../../utils/utils';
import RowIcons from '../widgets/RowIcons';
import Tag from '../widgets/Tag';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	collaborationRow: {
		'&:hover': {
			borderColor: theme.palette.primary.main
		},
		'&.active': {
			borderColor: theme.palette.primary.main
		}
	}
});

const CollaborationRow = React.memo(props => {
	const { collaboration } = props;

	return (
		<div className={`collaboration-row ${props.classes.collaborationRow}`}>
			<Link to={`/collaborations/${collaboration.collaborationId}`} className="h4 text-themecolor">{collaboration.title}</Link>
			<p className="truncate-text">{unescape(stripHtml(collaboration.summary))}</p>
			<span className="dates">
				Created {prettifyDate(collaboration.createDate)}
				{collaboration.updateDate && `, Updated ${prettifyDate(collaboration.updateDate)}`}
			</span>
			<div className="tags">
				<Tag colour={props.primaryColour} label={`${collaboration.participants.length} Participant${collaboration.participants.length === 1 ? "" : "s"}`} />
				{collaboration.channels.map((c, index) => <Tag key={index} label={c.channelName} />)}
			</div>
			{props.canEdit &&
				<div className="row-footer">
					<RowIcons 
						type="Collaboration" 
						editLink={`/collaborations/edit/${collaboration.collaborationId}`} 
						deleteFunc={() => props.onDelete(collaboration.collaborationId)} 
					/>
				</div>
			}
		</div>
	);
});

CollaborationRow.propTypes = {
	collaboration: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired
};

export default withStyles(CollaborationRow, styles);