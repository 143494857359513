import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveOffer, isLoading } from '../../store/offers';
import PageLayout from '../PageLayout';
import EditOffer from './EditBusinessChallenge';
 
const CreateOfferController = props => {
	const [offer, setOffer] = React.useState({});

	React.useEffect(() => {
		if (props.location.fromProblem) {
			const { title, description } = props.location.fromProblem;
			setOffer({ title, description });
		}
	}, [props.location]);

	return <PageLayout title="Create Research Match" pageContent={
		<EditOffer
			offer={offer}
			saveResult={props.saveResult}
			saveOffer={props.saveOffer}
			loading={props.loading}
			submitText={props.submitText}
			isNew
		/>
	} breadcrumbs={[{ label: "Research Match", path: "/research-match" }, "Create Match"]} />;
};

CreateOfferController.propTypes = { 
	saveOffer: PropTypes.func.isRequired,
	saveResult: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	submitText: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.offers.saveResult,
	loading: isLoading(state),
	isAdmin: state.context.isAdministrator,
	submitText: state.settings.submitOfferText || "Create Match"
});

const mapDispatchToProps = dispatch => ({
	saveOffer: (offer, files) => dispatch(saveOffer(offer, files, 'research-match'))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOfferController);