import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../utils/forms';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import FormButtons from '../widgets/FormButtons';
import StatBox from '../widgets/StatBox';
import { sectionColours } from '../../utils/utils';

const form = {
	initValues: props => props.tenant,
	fields: [
		{
			name: "name",
			label: "Name",
			required: true
		},
		{
			name: "title",
			label: "Title",
			required: true
		},
		{
			name: "active",
			label: "Active",
			type: "checkbox"
		},
		{ 		
			name: "logo",
			label: "Logo",
			type: "imageSelector"
		},
		{ 
			name: "backgroundImage",
			label: "Background Image",
			type: "imageSelector",
			imageText: "",
			images: ["/images/login_background.jpg"]
		},
		{ 
			name: "favicon",
			label: "Website Icon",
			imageText: "The icon must be a .ico file",
			accept: ".ico",
			type: "imageSelector"
		},
		{
			name: "subdomain",
			label: "Subdomain"
		},
		{
			name: "copyFromTenantId",
			label: "Copy Data From",
			required: false,
			type: "autoComplete",
			loadItems: {
				route: "tenants",
				mapItem: tenant => ({ label: tenant.name, value: tenant.tenantId })
			},
			helpText: "Select a tenant to copy data from"
		},
		{
			name: "applicationThemeId",
			label: "UI Theme",
			type: "autoComplete",
			loadItems: {
				route: "application-themes",
				mapItem: theme => ({ label: theme.name, value: theme.applicationThemeId })
			},
			defaultItem: { label: "(Use Default)", value: null }
		},
		{
			name: "communityType",
			label: "Community Type",
			required: false,
			type: "select",
			items: ["Community", "CLO Accelerator"]
		},
		{
			name: "showSkillsPassportIntro",
			label: "Show Skills Passport Intro",
			type: "checkbox"
		},
		{
			name: "hasCopyright",
			label: "Has Copyright",
			type: "checkbox"
		},
		{
			name: "copyright",
			label: "Copyright"
		},
		{
			name: "emailAddress",
			label: "Email Address"
		},
		{
			name: "restrictCoursesToTenant",
			label: "Restrict Courses to this Tenant",
			type: "checkbox"
		},
		{
			name: "showPoweredBy",
			label: "Show Powered By",
			type: "checkbox"
		}
	]
};

const EditTenant = props => {
	const { tenant, loading, values, fields } = props,
		isNew = !tenant || (tenant && tenant._isNew);

	return (
		<Card title={values.name} loading={loading}>
			<div className="row-flex m-t-40">
				<StatBox text="Total Users" number={tenant.userCount} colour={sectionColours.users} boxClass="col-md-2" />
				<StatBox text="Total Problems" number={tenant.problemCount} colour={sectionColours.problems} boxClass="col-md-2" />
				<StatBox text="Total Events" number={tenant.eventCount} colour={sectionColours.events} boxClass="col-md-2" />
				<StatBox text="Total Research Centres" number={tenant.researchCentreCount} colour={sectionColours.centres} boxClass="col-md-2" />
				<StatBox text="Total Offers" number={tenant.offerCount} colour={sectionColours.offers} boxClass="col-md-2" />
				<StatBox text="Total Tools" number={tenant.toolCount} colour={sectionColours.tools} boxClass="col-md-2" />
				<StatBox text="Total Courses" number={tenant.courseCount} colour={sectionColours.courses} boxClass="col-md-2" />
				<StatBox text="Total Academics" number={tenant.academicCount} colour={sectionColours.academics} boxClass="col-md-2" />
				<StatBox text="Total Organisations" number={tenant.organisationCount} colour={sectionColours.organisations} boxClass="col-md-2" />
				<StatBox text="Total Activities" number={tenant.activityCount} colour={sectionColours.activities} boxClass="col-md-2" />
				<StatBox text="Total Communities" number={tenant.communityCount} colour={sectionColours.communities} boxClass="col-md-2" />
				<StatBox text="Total Channels" number={tenant.channelCount} colour={sectionColours.themes} boxClass="col-md-2" />
				<StatBox text="Total IP" number={tenant.ipCount} colour={sectionColours.ip} boxClass="col-md-2" />
			</div>
			<Form>
				<Grid container spacing={3}>
					<Grid item xs={12}>{fields.name()}</Grid>
					<Grid item xs={12}>{fields.title()}</Grid>
					<Grid item xs={12}>{fields.active()}</Grid>
					<Grid item xs={12}>{fields.applicationThemeId()}</Grid>
					<Grid item xs={12}>{fields.communityType()}</Grid>
					<Grid item xs={12}>{fields.logo()}</Grid>
					<Grid item xs={12}>{fields.backgroundImage()}</Grid>
					<Grid item xs={12}>{fields.favicon()}</Grid>
					<Grid item xs={12}>{fields.subdomain()}</Grid>
					{isNew && <Grid item xs={12}>{fields.copyFromTenantId()}</Grid>}
					<Grid item xs={6}>{fields.hasCopyright()}</Grid>
					<Grid item xs={6}>{fields.copyright()}</Grid>
					<Grid item xs={6}>{fields.showSkillsPassportIntro()}</Grid>
					<Grid item xs={12}>{fields.emailAddress()}</Grid>
					<Grid item xs={6}>{fields.restrictCoursesToTenant()}</Grid>
					<Grid item xs={6}>{fields.showPoweredBy()}</Grid>
				</Grid>
			</Form>
			<FormButtons 
				loading={loading}
				success={props.saveResult.success}
				onSave={() => props.validateFields() && props.onSave(values, props.formFiles.logo, props.formFiles.backgroundImage, props.formFiles.favicon)}
				showDelete={!isNew}
				onDelete={() => props.onDelete(tenant)}
			/>
		</Card>
	);
};

EditTenant.propTypes = {
	tenant: PropTypes.object,
	saveResult: PropTypes.object.isRequired,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	loading: PropTypes.bool.isRequired
};

EditTenant.defaultProps = {
	tenant: null,
	onDelete: () => null
};

export default withForm(form)(EditTenant);
