import React from 'react';
import Button from '../widgets/Button';

const PassportInto = props => {
	return (
		<div style={{
			maxWidth: '1000px',
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto'
		}}>
			<p style={{
				fontSize: 'x-large',
				fontWeight: 'bold',
				marginBottom: 0
			}}>Skills Passport – </p>
			<p style={{
				fontSize: 'x-large'
			}}>Your Personal L&amp;D Competency Profile</p>
			<p>
				Your Skills Passport is a competency assessment tool based on the ILP’s L&D Capability Model ©. The The ILP Learning and Development Capability Model outlines what capabilities learning and development professionals require for long-term success within their profession and industry.
			</p>
			<img src='/images/upgrade/ILP Framework.png' alt="" align="left" style={{ height: '400px', marginRight: '10px', marginBottom: '10px' }} />
			<p style={{ textAlign: 'left' }}>
				Across six dimensions, we have identified over 200 behavioural elements covering four levels of expertise, that help learning and development professionals identify their development areas.
			</p>
			<p style={{ textAlign: 'left' }}>
				Your Skills Passport is a FREE personal self-assessment tool that provides you with a series of skills dashboards and a personal Skills profile Report
			</p>
			<p style={{ clear: 'both' }}>
				If you are a part of a corporate learning or talent development team, you can  undertake a teams-based assessment to identify team strengths and weaknesses and skills-based risks to improve your organisational L&D capability.
			</p>
			<Button 
				className="skip-button" 
				onClick={props.onSkip}
			>Skip</Button>
		</div>
	);
};

PassportInto.propTypes = {
};

export default PassportInto;
