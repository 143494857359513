import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../store/modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	simpleModal: {
		'& .yes-button': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				background: '#09305d'
			}
		},
		'& .no-button': {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				background: '#791d1a'
			}
		}
	}
});

const SimpleModal = props => {
	const close = () => {
		props.hideModal(); 
		props.onCancel();
	}
	
	const cancel = () => {
		props.hideModal(); 
		props.onCancel();
	}

	const ok = () => {
		props.hideModal(); 
		props.onOk();
	}

	return (
        <Dialog
            open
            onClose={close}
            aria-labelledby={props.title}
            className={`simple-modal ${props.classes.simpleModal}`}>
			<DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button className="no-button" onClick={cancel} autoFocus>No</Button>
				<Button className="yes-button" onClick={ok}>Yes</Button>
			</DialogActions>
		</Dialog>
    );
};

SimpleModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

SimpleModal.defaultProps = {
	onCancel: () => null
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(withStyles(SimpleModal, styles));
