import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { showErrorNotification, showSuccessNotification } from '../../store/notifications';
import { withForm } from '../../utils/forms';
import { post } from '../../utils/ajax';
import TextField from '../widgets/TextField';
import Button from '../widgets/Button';
import some from 'lodash/some';

const form = {
	fields: [
		{
			name: "subject",
			required: props => props.feature.contactSubjectRequired,
			label: "Subject"
		},
		{
			name: "message",
			required: props => props.feature.contactMessageRequired,
			label: "Message",
			type: "textEditor"
		}
	]
};

const ContactDialog = props => {
	const [loading, setLoading] = React.useState(false);
	const { feature: f } = props;

	const resetForm = () => props.updateValues({ subject: "", message: "" });

	const handleSubmit = () => {
		if (props.validateFields()) {
			setLoading(true);

			post({
				url: "/api/features/contact",
				data: {
					email: f.contactEmail,
					subject: props.values.subject,
					message: props.values.message,
					featureTitle: f.title
				},
				onSuccess: result => {
					setLoading(false);

					if (result.success) {
						props.onClose();
						props.showSuccessNotification(result.message);
					} else {
						props.showErrorNotification(result.message);
					}
				},
				onError: error => {
					setLoading(false);
					props.showErrorNotification(error.message);
				}
			});
		}
	}

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
            TransitionProps={{
                onEnter: resetForm
            }}>
			<DialogTitle>{f.contactHeader}</DialogTitle>
			<DialogContent className="discussion-dialog">
				<div dangerouslySetInnerHTML={{ __html: f.contactBody }} />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField label="Full name" value={props.fullName} disabled />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField label="Email" value={props.email} disabled />
					</Grid>
					<Grid item xs={12}>{props.fields.subject()}</Grid>
					<Grid item xs={12}>{props.fields.message()}</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button loading={loading} onClick={props.onClose}>Cancel</Button>
				<Button loading={loading} disabled={loading || some(props.error)} onClick={handleSubmit}>Submit</Button>
			</DialogActions>
		</Dialog>
    );
};

ContactDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	feature: PropTypes.object.isRequired,
	fullName: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	fullName: state.context.fullName,
	email: state.context.email
});

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ContactDialog));