import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
 import { 
	showSuccessNotification, 
	showErrorNotification 
} from '../../store/notifications';
import { getQueryParameterByName } from '../../utils/utils';
import { get, post } from '../../utils/ajax';
import SubscriptionLevel from './SubscriptionLevel';
import { Grid } from '@mui/material';
import { showModal } from '../../store/modal';
import { changeLocation } from '../../store/location';

const SubscriptionCompleteController = props => {
	const [loading, setLoading] = React.useState(false);
	const [subscription, setSubscription] = React.useState(null);

	React.useEffect(() => {
		const sessionId = getQueryParameterByName('session_id');
		setLoading(true);
		if (sessionId) {
			get({
				url: `/api/subscription/by-session?sessionId=${sessionId}`,
				onSuccess: (data) => {
					setLoading(false);
					setSubscription(data);
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		} else {
			get({
				url: `/api/subscription`,
				onSuccess: (data) => {
					setLoading(false);
					setSubscription(data);
					console.log(data);
				},
				onError: (error) => {
					setLoading(false);
				}
			});
		}
	}, []);

	const cancelSubscrption = (product) => {
		props.promptCancelSubscription(cancelReason => {
			setLoading(true);
			post({
				url: '/api/subscription/cancel',
				data: {
					reason: cancelReason
				},
				onSuccess: (data) => {
					setLoading(false);
					if (data.success) {
						props.showSuccessNotification(data.message);
						props.goToSubscriptionPage();
					} else {
						props.showErrorNotification(data.message);
					}
				},
				onError: (error) => {
					props.showErrorNotification(error.message);
					setLoading(false);
				}
			});
		});
	};


	return (
        <PageLayout 
			title="Subscription Complete" 
			pageClass="subscription-page"
			loading={loading}
			pageContent={
				<React.Fragment>
					<h2 className="subscription-page__header">Subscription Complete</h2>
					<p className="subscription-page__thank-you-text">Thank you for your payment for CLO Central. You will also be automatically emailed a receipt for payment to your registered email address.</p>
					<p className="subscription-page__thank-you-text">If you have any issues in accessing CLO Central, please email the team at <a href="mailto:Information@clocentral.com">Information@clocentral.com</a></p>
					<Grid container spacing={3} alignItems="flex-end" justifyContent="center">
						{subscription &&
							<Grid item xs={4}>
								<SubscriptionLevel 
									isSubscribed={true}
									canSubscribe={true}
									subscription={{
										name: subscription.product.name,
										price: subscription.product.price,
										duration: subscription.product.duration,
										description: subscription.product.description,
									}}
									backgroundColour={subscription.product.backgroundColour}
									foreColour={subscription.product.foreColour}
									buttonText="Cancel Subscription"
									onClick={() => {
										cancelSubscrption();
									}}
								/>
							</Grid>
						}
					</Grid>
					{/* <p className="subscription-page__disclaimer">Disclaimer text</p> */}

				</React.Fragment>
			}
			breadcrumbs={["Subscription"]} 
		/>
    );
};

SubscriptionCompleteController.propTypes = { 
	goToSubscriptionPage: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	promptCancelSubscription: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = dispatch => ({
	goToSubscriptionPage: () => dispatch(changeLocation('/subscription')),
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	promptCancelSubscription: onOk => dispatch(showModal("PROMPT", { 
		title: "Cancel Subscription",
		label: "Reason",
		placeholder: "Please enter why you wish to cancel your subscription",
		onOk
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCompleteController);
