import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Button from '../widgets/Button';
import Popover from '@mui/material/Popover';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: 'none'
	},
	paper: {
		padding: theme.spacing(1)
	}
}));

const SubscriptionLevel = ({ 
	subscription, 
	isSubscribed, 
	canSubscribe, 
	meetsMinimumMembershipLevel, 
	hasCompletedAssessmentAndSelectProProfile, 
	buttonText, 
	onClick,
	backgroundColour,
	foreColour
}) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	
	const open = Boolean(anchorEl);

	let cannotSubscribeMessage = "";
	if (!meetsMinimumMembershipLevel) {
		cannotSubscribeMessage = "Your current membership level does not include access to this subscription";
	} else if (!hasCompletedAssessmentAndSelectProProfile) {
		cannotSubscribeMessage = "This subscription option is not available until initial assessment is complete and professional profile selected";
	} else if (!canSubscribe) {
		cannotSubscribeMessage = "You cannot subscribe to this option";
	}

	const style = {
		padding: '20px', 
		opacity: canSubscribe ? 1 : 0.8
	};
	if (backgroundColour) style.backgroundColor = backgroundColour;
	if (foreColour) style.color = foreColour;

	return (
	<React.Fragment>
		<Card 
			className="subscription-level" 
			style={style}
			aria-owns={open ? 'mouse-over-popover' : undefined}
			aria-haspopup="true"
			onMouseEnter={handlePopoverOpen}
			onMouseLeave={handlePopoverClose}
			ribbon={isSubscribed ? "Subscribed" : undefined} 
			ribbonStatus={"default"}
		>
			<div className="subscription-level__name" style={{ marginTop: isSubscribed ? '20px' : 0}}>
				{subscription.name}
			</div>
			<div className="subscription-level__description quill-display-block" dangerouslySetInnerHTML={{ __html: subscription.description}}>
			</div>
			<div className="subscription-level__price">
				<span className="subscription-level__price__dollar-sign">$</span>{subscription.price}
			</div>
			<div className="subscription-level__duration">
				{subscription.duration}
			</div>
		</Card>
		<div className="subscription-level__select-button">
			<div className="subscription-level__select-button__wrapper">
				<Button disabled={!canSubscribe} onClick={onClick}>{buttonText}</Button>
			</div>
		</div>
		{!canSubscribe &&
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography>{cannotSubscribeMessage}</Typography>
			</Popover>
		}
	</React.Fragment>
	);
}


SubscriptionLevel.propTypes = { 
	subscription: PropTypes.object.isRequired,
	buttonText: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};


export default SubscriptionLevel;
