import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import map from 'lodash/map';
import round from 'lodash/round';

const SkillsGap = ({ userId, setLoading, organisationId, departmentId }) => {
	const [data, setData] = React.useState([]);

	const loadData = () => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/skills-gap?userId=${userId}&organisationId=${organisationId}&departmentId=${departmentId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (userId || organisationId) {
			loadData();
		}
	}, [userId, organisationId, departmentId]);

	const chartData = map(data, d => ({
		name: `${d.specialisationTitle}`,
		fullLabel: `${d.specialisationTitle}`,
		value: d.competentPercentage,
		itemStyle: {
			//color: `#${d.colour}`
		}
	})) || [];

	return (
		<Chart 
			info={{
				title: 'Skills Gap',
				text: 
					<React.Fragment>
						<p>The Skills Gap chart shows the percentage of Competent vs Required Competencies.</p>
						<p>The larger the segment, the higher the value.</p>
					</React.Fragment>
			}}
			option={{
				tooltip: {
					trigger: 'item',
					formatter: params => `${params.data.name}: ${round(params.data.value, 1)}%`
				},
				series: [
					{
						type: 'treemap',
						roam: false,
						nodeClick: false,
						breadcrumb: {
							show: false
						},
						data: chartData
					}
				]
			}}
			style={{ height: "100%" }} 
		/>
	);
};

SkillsGap.propTypes = {
	organisationId: PropTypes.number,
	departmentId: PropTypes.number,
	userId: PropTypes.string,
	setLoading: PropTypes.func.isRequired
};

SkillsGap.defaultProps = {
	userId: null,
	organisationId: null,
	departmentId: null
};

export default SkillsGap;
