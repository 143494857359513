import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { canSubmitMentees } from '../../store/security';
import { searchMentees, isLoading } from '../../store/mentees';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import MenteeCard from './MenteeCard';
import map from 'lodash/map';
import pick from 'lodash/pick';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchMentees(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		}
	]
};

const MenteesController = props => {
	const { mentees } = props;

	React.useEffect(() => props.searchMentees(props.searchArgs), []);
	
	return <PageLayout title="Mentees" pageContent={
		<React.Fragment>
			<Grid container spacing={3} direction="row-reverse">
				{props.canSubmit &&
					<Grid item xs="auto">
						<ButtonLink to="/mentees/create">Create mentee</ButtonLink>
					</Grid>
				}
				<Grid item xs={12} sm>{props.fields.search()}</Grid>
			</Grid>
			{mentees.length === 0 ? <p style={{ marginTop: 10 }}>No mentees found...</p> :
				<Grid container spacing={3}>
					{map(mentees, (m, i) => 
						<Grid key={i} item xs={12} sm={6} lg={4}>
							<MenteeCard mentee={m} />
						</Grid>
					)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={["Mentees"]} />;
};

MenteesController.propTypes = { 
	mentees: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchMentees: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	mentees: state.mentees.mentees,
	loading: isLoading(state),
	searchArgs: {
		search: "",
		...pick(getQueryParameters(ownProps.location.search), "search")
	},
	canSubmit: canSubmitMentees(state)
});

const mapDispatchToProps = dispatch => ({
	searchMentees: args => dispatch(searchMentees(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(MenteesController));