import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../widgets/Card';
import { Link } from 'react-router-dom'
import DataTable from '../../widgets/dataTable/DataTable';
import DataTableColumn from '../../widgets/dataTable/DataTableColumn';
import ButtonLink from '../../widgets/ButtonLink';
import PageLayout from '../../PageLayout';
import { get } from '../../../utils/ajax';

const UserOrganisationsController = props => {
	const [state, setState] = React.useState({ orgs: [], isLoading: true });

	React.useEffect(() => {
		get({
			url: '/api/user-organisations',
			onSuccess: data => setState({ orgs: data, isLoading: false }),
			onError: () => setState(prevState => ({ ...prevState, isLoading: false }))
		});
	}, []);

	return (
		<PageLayout 
			title="User Organisations" 
			pageContent={
				<Card loading={props.loading}>
					<DataTable data={state.orgs} tableFooter={<ButtonLink to="/admin/user-organisations/add">Add new</ButtonLink>}>
						<DataTableColumn name="name" label="Name" renderer={o => 
							<Link to={`/admin/user-organisations/${o.userOrganisationId}`} style={{ color: props.primaryColour }}>
								<span>{o.name}</span>
							</Link>
						} />
						{props.isGlobalAdministrator && <DataTableColumn name="tenantName" label="Tenant" />}
					</DataTable>
				</Card>
			} 
			breadcrumbs={["User Organisations"]} 
		/>
	);
};

UserOrganisationsController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOrganisationsController);