import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';

const form = {
	initValues: props => props.group,
	fields: [
		{
			name: "profileGroupName",
			label: "Name",
			required: true
		},
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		},
		{
			name: "description",
			label: "Description",
			multiline: true,
			helpText: "Description of group within profile picker"
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true,
			helpText: "Image used to represent group within profile picker"
		}
	]
};

const EditProfileGroup = props => {
	const { fields, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Create" : "Edit"} profile group`} loading={loading}>
			<Form onSubmit={() => props.validateFields() && props.saveGroup(props.values, props.formFiles.image)}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>{fields.profileGroupName()}</Grid>
					<Grid item xs={12} md={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12}>{fields.image()}</Grid>
				</Grid>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} group</Button>
				</div>
			</Form>
		</Card>
	);
};

EditProfileGroup.propTypes = { 
	group: PropTypes.object,
	saveGroup: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditProfileGroup.defaultProps = {
	group: {},
	isNew: false
};

export default withForm(form)(EditProfileGroup);