import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Paper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { prettifyDate } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import Tag from '../widgets/Tag';
import Avatar from '../widgets/Avatar';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import distanceInWords from 'date-fns/formatDistance';

const today = new Date();

const artifactKey = {
	Innovation: "Patent", 
	"Provisional Application": "Patent", 
	"Granted Patent": "Patent", 
	Trademark: "Trademark", 
	Document: "Copyright", 
	Database: "Copyright", 
	Image: "Copyright", 
	Recording: "Copyright", 
	"Software Code": "Copyright"
}

const YesIcon = ({ colour }) => <DoneIcon style={{ color: colour, marginRight: 5 }} />,
	NoIcon = ({ colour }) => <ClearIcon style={{ color: colour, marginRight: 5 }} />;

const ViewIP = ({ ip, loading, canEdit, successColour, errorColour, canViewEnquiries }) => {
	if (loading || isEmpty(ip)) return <CircularProgress size={24} style={{ marginLeft: 10 }} />;

	// Helper function to render reference paper (agreement/ third party)
	const renderReference = (item, key) => 
		<Grid key={key} item xs={12} sm={4}>
			<Paper square className="paper">
				<span className="h5">{item.party}</span>
				<span><PersonIcon />{item.contact}{item.email && `, ${item.email}`}</span>
				{item.phone && <span><PhoneIcon />{item.phone}</span>}
			</Paper>
		</Grid>;

	return (
		<div className="ip-container">
			<h2>{ip.title}{ip.projectName && ` - ${ip.projectName}`}</h2>
			<div className="ip-details">
				<div className="contact-details">
					<Avatar className="contact-avatar" fullName={ip.projectContactPerson} length={50} style={{ margin: "0 1rem" }} />
					<div className="details-box">
						<span className="contact-person">{ip.projectContactPerson}</span>
						<small>{ip.email}</small>
						<small className="text-muted">Published {prettifyDate(ip.dateCreated)}</small>
						{ip.lastUpdate && <small className="text-muted">Updated {prettifyDate(ip.lastUpdate)}</small>}
					</div>
				</div>
				<div style={{ marginBottom: 20 }}>
					<span>{ip.commercialAvailable ? <YesIcon colour={successColour} /> : <NoIcon colour={errorColour} />}Available for Commercialisation</span>
					<span style={{ marginLeft: 10 }}>{ip.commercialApproved ? <YesIcon /> : <NoIcon />}Approved for Commercialisation</span>
					<span className="label label-rounded label-light-info" style={{ marginLeft: 5 }}>{ip.views} views</span>
				</div>
				{/* Commercial Approver Details */}
				{ip.commercialApproved &&
					<div style={{ marginBottom: 20 }}>
						<h3>Commercial Approver Details</h3>
						<div className="contact-details">
							<Avatar className="contact-avatar" fullName={ip.commercialApproverName} length={50} style={{ margin: "0 1rem" }} />
							<div className="details-box">
								<span className="contact-person">{ip.projectContactPerson}</span>
								<small>{ip.commercialApproverEmail}</small>
								<small>{ip.commercialApproverPhone}</small>
							</div>
						</div>
					</div>
				}
				{/* Artifacts List */}
				<h3>Artifacts</h3>
				{ip.artifacts.length === 0 ? <p>No artifacts attached...</p> : 
					<Grid container spacing={3} style={{ marginBottom: 20 }}>
						{map(ip.artifacts, (a, i) =>
							<Grid key={i} item xs={12} sm={6}>
								<Paper square className="paper">
									<span className="h5">
										{a.title}
										<Tag label={`${artifactKey[a.type]} - ${a.type}`} colour="#1e7994" style={{ float: "right" }} />
									</span>
									<span>Created by {a.authors.join(", ")}</span>
									{a.description ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: a.description }} /> : <p>No description provided...</p>}
									<span>Ownership: <Tag label={a.ownership} /></span>
									{a.reference && <span>File Reference: #{a.reference}</span>}
									<span>{a.concerns ? <YesIcon /> : <NoIcon />}Security/ Confidentiality concerns</span>
									<span>{a.sharing ? <YesIcon /> : <NoIcon />}Available for sharing</span>
									<small>
										{ip.lastUpdate ? `Updated ${distanceInWords(today, ip.lastUpdate)} ago` :
											`Created ${distanceInWords(today, ip.dateCreated)} ago`
										}
									</small>
								</Paper>
							</Grid>
						)}
					</Grid>
				}
				{/* Agreements List */}
				<h3>Confidentiality Agreements</h3>
				{ip.agreements.length === 0 ? <p>No agreements attached...</p> : 
					<Grid container spacing={3} style={{ marginBottom: 20 }}>
						{map(ip.agreements, (a, i) => renderReference(a, i))}
					</Grid>
				}
				{/* Third Party IP List */}
				<h3>Third Party IP</h3>
				{ip.thirdParty.length === 0 ? <p>No third party IP attached...</p> : 
					<Grid container spacing={3} style={{ marginBottom: 20 }}>
						{map(ip.thirdParty, (t, i) => renderReference(t, i))}
					</Grid>
				}
				{canEdit && <ButtonLink to={`/ip/edit/${ip.ipId}`} style={{ marginRight: 10 }}>Edit IP</ButtonLink>}
				{canViewEnquiries && 
					<ButtonLink to={`/ip/enquiries?ipId=${ip.ipId}`} color="secondary" style={{ marginRight: 10 }}>
						View Enquiries
					</ButtonLink>
				}
			</div>
		</div>
	);
};

ViewIP.propTypes = {
	currentUserId: PropTypes.string,
	ip: PropTypes.shape({
		ipId: PropTypes.number,
		title: PropTypes.string,
		agency: PropTypes.string,
		businessUnit: PropTypes.string,
		projectContactPerson: PropTypes.string,
		email: PropTypes.string,
		commercialAvailable: PropTypes.bool,
		commercialApproved: PropTypes.bool,
		commercialApproverName: PropTypes.string,
		commercialApproverPhone: PropTypes.string,
		commercialApproverEmail: PropTypes.string,
		artifacts: PropTypes.array,
		agreements: PropTypes.array,
		thirdParty: PropTypes.array,
		DateCreated: PropTypes.string,
		LastUpdate: PropTypes.string
	}).isRequired,
	loading: PropTypes.bool,
	canEdit: PropTypes.bool.isRequired,
	canViewEnquiries: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired,
	successColour: PropTypes.string.isRequired
};

ViewIP.defaultProps = {
	currentUserId: null,
	loading: false
}

export default ViewIP;
