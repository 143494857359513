import React from 'react';
import PropTypes from 'prop-types';
import ProblemDetails from './ProblemDetails';
import { CircularProgress, Grid } from '@mui/material';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import Button from '../widgets/Button';
import isEmpty from 'lodash/isEmpty';
import Chat from '../widgets/Chat';
import capitalise from 'lodash/capitalize';

const ViewProblem = props => {
	const { problem: p, single, plural } = props;

	const download = attachment => 
		window.open(`/Problem/DownloadAttachment?problemId=${p.problemId}&attachmentId=${attachment.attachmentId}`, "_self");

	return props.loading || isEmpty(p) ? <CircularProgress size={24} style={{ marginLeft: 10 }} /> : (
		<div className="problem-container">
			<h2>{p.title}</h2>
			<Grid container spacing={4}>
				<Grid item xs={12} lg={8} style={{ paddingBottom: 0 }}>
					<ProblemDetails problem={p} single={single} plural={plural} />
				</Grid>
				<Grid item xs={12} lg={8}>
					<Card header="Description">
						<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: p.description }}></div>
					</Card>
					<Card header="Solution">
						<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: p.solution }}></div>
					</Card>
					<Card header="Attachments">
						{(!p.attachments || p.attachments.length === 0) ? <p>There are no attachments for this problem.</p> :
							<ul className="list-unstyled">
								{p.attachments.map((a, i) =>
									<li className="media" key={i}>
										<i className="display-5 m-r-10 mdi mdi-file-pdf"></i>
										<div className="media-body">
											<h5 className="mt-0 mb-1">{a.name}</h5>
											<p>{a.description}</p>
											<Button variant="outlined" onClick={() => download(a)}>
												Download now
											</Button>
										</div>
									</li>
								)}
							</ul>
						}
					</Card>
					{props.canEdit && <ButtonLink to={`/${props.isCommunity ? "my-community/" : ""}problems/edit/${p.problemId}`}>Edit {single}</ButtonLink>}
					{p.status !== "archived" && props.isAdmin && 
						<Button 
							style={{ float: "right", background: "#2f3d4a" }} 
							onClick={() => props.archiveProblem(p.problemId)}
						>
							Archive {capitalise(single)}
						</Button>
					}
					{props.canSubmitOffers && 
						<ButtonLink 
							color="secondary"
							style={{ float: "right", marginRight: 10 }} 
							to={{ pathname: "/offers/create", fromProblem: p }}
						>
							Create Offer
						</ButtonLink>
					}
				</Grid>
				<Grid item xs={12} lg={4}>
					<Chat
						title="Discussion"
						saveMessage={message => props.saveProblemChat(p.problemId, message)}
						currentUserId={props.currentUserId}
						messages={props.problemChat}
						loading={props.chatLoading}
						removeMessage={props.deleteChatMessage}
						canRemove={props.canDeleteChatMessages}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

ViewProblem.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	saveProblemChat: PropTypes.func.isRequired,
	problem: PropTypes.object.isRequired,
	problemChat: PropTypes.array.isRequired,
	deleteChatMessage: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	chatLoading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canSubmitOffers: PropTypes.bool.isRequired,
	canDeleteChatMessages: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	isCommunity: PropTypes.bool
};

ViewProblem.defaultProps = {
	isCommunity: false
};

export default ViewProblem;
