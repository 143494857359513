import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Pagination from '../widgets/Pagination';
import map from 'lodash/map';
import slice from 'lodash/slice';
import ProblemRow from '../widgets/ProblemRow';
import EventCard from '../widgets/EventCard';
import OfferBox from '../widgets/OfferBox';
import capitalise from 'lodash/capitalize';

const itemsPerPage = 10;

const SearchResults = props => {
	const [currentPage, setCurrentPage] = React.useState(1);
	const { problems, events, offers, isAdmin } = props,
		results = [...problems, ...events, ...offers],
		from = (currentPage - 1) * itemsPerPage;

	// Calculate markers of each header
	const markers = [
		problems.length > 0 ? 0 : null, // Problems
		events.length > 0 ? problems.length : null, // Events
		offers.length > 0 ? problems.length + events.length : null // Offers
	];

	return (
		<Card title="Results" loading={props.loading}>
			{results.length === 0 ? "No results found." :
				<div className="comment-widgets">
					{map(slice(results, from, from + itemsPerPage), (r, index) =>
						<React.Fragment key={index}>
							{/* Insert Section Header if at specified marker */}
							{({
								[markers[0]]: <h2>{capitalise(props.plural)} ({problems.length} matches)</h2>,
								[markers[1]]: <h2 style={{ marginTop: index > 0 ? 20 : 0 }}>Events ({events.length} matches)</h2>,
								[markers[2]]: <h2 style={{ marginTop: index > 0 ? 20 : 0 }}>Offers ({offers.length} matches)</h2>
							})[(currentPage - 1) * itemsPerPage + index] || ""}
							{/* Draw Item */}
							{r.problemId && <ProblemRow problem={r} isAdmin={isAdmin} extended />}
							{r.eventId && <EventCard event={r} style={{ display: "inline-block", padding: 12 }} />}
							{r.offerId && <OfferBox offer={r} isAdmin={isAdmin} style={{ display: "inline-block", padding: 12 }} />}
						</React.Fragment>
					)}
					{/* Only render Pagination after problems have finished loading */}
					{!props.loading &&
						<Pagination
							maxPages={10}
							pageCount={Math.ceil(results.length / itemsPerPage)} 
							currentPage={currentPage}
							changePage={page => setCurrentPage(page)}
						/>
					}
				</div>
			}
		</Card>
	);
};

SearchResults.propTypes = {
	problems: PropTypes.arrayOf(PropTypes.object).isRequired,
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	offers: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	plural: PropTypes.string.isRequired
};

export default SearchResults;
