import React from 'react';
import { connect } from 'react-redux';
import Button from '../widgets/Button';
import ButtonLink from '../widgets/ButtonLink';
import { withRouter } from 'react-router-dom';
import { withForm } from '../../utils/forms';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import Form from '../widgets/Form';

const form = {
	fields: [{
		name: "email",
		required: true,
		label: "Recovery Email",
		validate: value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email must be a valid address",
		style: { margin: "10px 0 25px" }
	}]
};

const PasswordReset = props => {
	const [loading, setLoading] = React.useState(false);

	const handleSubmit = () => {
		if (!props.validateFields()) return;
		
		setLoading(true);

		post({
			url: `/api/users/reset-password?email=${props.values.email}`,
			onSuccess: result => {
				setLoading(false);

				if (result.success) {
					props.showSuccessNotification(result.message);
					props.history.push("/login");
				} else {
					props.showErrorNotification(result.message);
				}
			},
			onError: error => {
				setLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	}


	return (
		<div className="reset-box">
			<h1 className="h3">Reset Password</h1>
			<small>Recover your password by filling out your email address.</small>
			<Form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
				{props.fields.email()}
				<Button type="submit" size="large" loading={loading} color="secondary">Send Password Reset Link</Button>
			</Form>
			<ButtonLink to="/login" variant="text" size="small" color="primary" style={{ marginTop: 10 }}>Return to Login</ButtonLink>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(null, mapDispatchToProps)(withRouter(withForm(form)(PasswordReset)));