import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContent, isLoading } from '../../store/content';
import { canEditContent, canViewBusinessProfiles } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewContent from './ViewContent';

const ViewContentController = props => {
	React.useEffect(() => props.getContent(props.match.params.contentId), [props.match.params.contentId]);

	return (
		<PageLayout 
			title="View Resource Bundle"
			pageContent={
				<ViewContent 
					content={props.content} 
					loading={props.loading} 
					canEdit={props.canEdit(props.content)} 
					canViewBusinessProfiles={props.canViewBusinessProfiles}
					accreditedLogo={props.accreditedLogo}
					primaryColour={props.primaryColour}
				/>
			} 
			breadcrumbs={[
				{ label: "Resource Catalogue", path: "/resource-catalogue" }, 
				"View Resource Bundle"
			]} 
		/>
	);
};

ViewContentController.propTypes = {
	content: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.func.isRequired,
	canViewBusinessProfiles: PropTypes.bool.isRequired,
	accreditedLogo: PropTypes.string.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	content: state.content.content,
	loading: isLoading(state),
	canEdit: canEditContent(state),
	canViewBusinessProfiles: canViewBusinessProfiles(state),
	accreditedLogo: state.context.organisationAccreditedLogo || "",
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getContent: contentId => dispatch(getContent(contentId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewContentController);