import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveProblem, isLoading } from '../../store/problems';
import { getQueryParameters } from '../../utils/utils';
import PageLayout from '../PageLayout';
import EditProblem from './EditProblem';
import capitalise from 'lodash/capitalize';

const CreateProblemController = props => {
	const [title] = React.useState(getQueryParameters(props.location.search).title || "", []);

	return <PageLayout title={`Submit ${props.single}`} pageContent={
		<EditProblem 
			problem={{ title, _isNew: true }}
			loading={props.loading}
			onSave={props.saveProblem}
			single={props.single}
			plural={props.plural}
			headerContent={props.headerContent}
			titleHelpText={props.titleHelpText}
			descriptionHelpText={props.descriptionHelpText}
			solutionHelptext={props.solutionHelptext}
			isNew
		/>
	} breadcrumbs={[{ label: capitalise(props.plural), path: `/${props.isCommunity ? "my-community/" : ""}problems` }, `Submit ${props.single}`]} />;
};

CreateProblemController.propTypes = { 
	saveProblem: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	single: PropTypes.string.isRequired,
	plural: PropTypes.string.isRequired,
	isCommunity: PropTypes.bool,
	headerContent: PropTypes.string.isRequired,
	titleHelpText: PropTypes.string.isRequired,
	descriptionHelpText: PropTypes.string.isRequired,
	solutionHelptext: PropTypes.string.isRequired
};

CreateProblemController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	single: state.settings.single,
	plural: state.settings.plural,
	headerContent: state.settings.submitProblemHeaderContent || "",
	titleHelpText: state.settings.submitProblemTitleHelpText || "",
	descriptionHelpText: state.settings.submitProblemDescriptionHelpText || "",
	solutionHelptext: state.settings.submitProblemSolutionHelpText || ""
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveProblem: (problem, newFiles) => dispatch(saveProblem(problem, newFiles, ownProps.isCommunity))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProblemController);