import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	CircularProgress, 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions,
	IconButton,
	Snackbar,
	Tab,
	Tabs
} from '@mui/material';
import { showErrorNotification, showSuccessNotification } from '../../store/notifications';
import { withForm } from '../../utils/forms';
import { post } from '../../utils/ajax';
import Button from '../widgets/Button';
import {
    FileCopy as CopyIcon
}from '@mui/icons-material';

const form = {
	fields: [

	]
};

const MakePublicDialog = props => {
	const [loading, setLoading] = React.useState(false);
	const [publicUrl, setPublicUrl] = React.useState(null);
	const [showCopiedNotification, setShowCopiedNotification] = React.useState(false);
	const [tab, setTab] = React.useState('Link');
	
	const onChangeTab = (event, tab) => {
		setTab(tab === 1 ? 'Embed' : 'Link');
	}

	React.useEffect(() => {
		if (props.open) {
			generateToken();
		}
	}, [props.open]);

	const generateToken = () => {
		setLoading(true);

		post({
			url: "/api/token/generate-public-token",
			data: {
				target: window.location.href
			},
			onSuccess: result => {
				setLoading(false);
				setPublicUrl(result);
			},
			onError: error => {
				setLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	};

	const embedCode = `
<iframe 
	src="${publicUrl}"
	width="100%"
	frameBorder="0"
/>
	`;

	return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
			<DialogTitle>{}</DialogTitle>
			<DialogContent className="make-public-dialog">
				{loading ? 
					<CircularProgress />
					:
					<div>
						<Tabs value={tab} onChange={onChangeTab}>
							<Tab label="Link" />
							<Tab label="Embed" />
						</Tabs>
						
						{tab === 'Link' && 
							<>
								<h3>
									Copy the URL below
									<IconButton
										onClick={() => {
											setShowCopiedNotification(true);
											navigator.clipboard.writeText(publicUrl);
										}}
										size="large"><CopyIcon /></IconButton>
								</h3>
								<p>
									{publicUrl}
								</p>
							</>
						}
						{tab === 'Embed' && 
							<>
								<h3>
									Copy the embed text below
									<IconButton
										onClick={() => {
											setShowCopiedNotification(true);
											navigator.clipboard.writeText(embedCode);
										}}
										size="large"><CopyIcon />
									</IconButton>
								</h3>

								<pre>{embedCode}</pre>
							</>
						}
					</div>
				}
				<Snackbar
					open={showCopiedNotification}
					onClose={() => setShowCopiedNotification(false)}
					autoHideDuration={2000}
					message="Copied to clipboard"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Close</Button>
			</DialogActions>
		</Dialog>
    );
};

MakePublicDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(MakePublicDialog));