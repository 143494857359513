import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, CircularProgress, Tabs, Tab, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import { searchTools, searchSubscribedTools, isLoading } from '../../store/tools';
import { canSubmitTools, canSubmitCommunityTools } from '../../store/security';
import ButtonLink from '../widgets/ButtonLink';
import PageLayout from '../PageLayout';
import ToolCard from './ToolCard';
import pick from 'lodash/pick';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchTools(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "channel",
			label: "Filter by channel",
			type: "autoComplete",
			loadItems: { 
				route: props => `channels?subscribedOnly=${props.isCommunity}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "status",
			label: "Filter by status",
			type: "select",
			items: [{ label: "(All)", value: "all" }, "Published", "Draft"]
		}, 
		{
			name: "tag",
			label: "Filter by tag",
			type: "autoComplete",
			loadItems: { 
				route: (props, values) => `tags/tools?visibility=${values.visibility}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		}, 
		{ name: "visibility" }
	]
};

const tabs = ["favourites", "my", "private", "public"];

const ToolsController = props => {
	const [tab, setTab] = React.useState(0);
	const { tools, fields, isCommunity } = props;

	React.useEffect(() => {
		const { searchArgs } = props;
		
		props.searchTools(searchArgs);
		setTab(tabs.indexOf(searchArgs.visibility));
	}, [isCommunity]);

	const handleVisibilityChange = (e, tab) => {
		const visibility = tabs[tab];

		props.updateValues({ visibility });
		props.searchTools({ ...props.values, visibility });
		setTab(tab);
	}

	return <PageLayout title="Tools" pageContent={
		<React.Fragment>
			<Paper style={{ display: "inline-block" }}>
				<Tabs value={tab} onChange={handleVisibilityChange}>
					<Tab label="Favourites" />
					<Tab label="My" />
					<Tab label="Private" />
					<Tab label="All" />
				</Tabs>
			</Paper>
			{props.canSubmit && 
				<ButtonLink style={{ float: "right" }} to={`/${isCommunity ? "my-community/" : ""}tools/create`}>
					Create new tool
				</ButtonLink>
			}
			<Grid container spacing={3} alignItems="flex-end" style={{ paddingTop: 20 }}>
				<Grid item xs={12} sm={3}>{fields.search()}</Grid>
				<Grid item xs={12} sm={3}>{fields.tag()}</Grid>
				<Grid item xs={12} sm={3}>{fields.channel()}</Grid>
				<Grid item xs={12} sm={3}>{fields.status()}</Grid>
			</Grid>
			{tools.length === 0 ? <p style={{ marginTop: 10 }}>No tools found...</p> :
				<Grid container spacing={3}>
					{map(tools, (a, i) => <ToolCard isCommunity={isCommunity} key={i} tool={a} />)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={[isCommunity ? { label: "My Community", path: "/my-community" } : "", "Tools"]} />;
};

ToolsController.propTypes = { 
	tools: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	searchTools: PropTypes.func.isRequired,
	isCommunity: PropTypes.bool
};

ToolsController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	tools: orderBy(state.tools.tools || [], "name"),
	loading: isLoading(state),
	canSubmit: ownProps.isCommunity ? canSubmitCommunityTools(state) : canSubmitTools(state),
	searchArgs: { 
		status: "all", 
		channel: "all", 
		tag: "all",
		visibility: "public", 
		...pick(getQueryParameters(ownProps.location.search), "search", "status", "channel", "tag", "visibility") 
	}
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	searchTools: args => dispatch(ownProps.isCommunity ? searchSubscribedTools(args) : searchTools(args))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(ToolsController));
