import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../store/modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '../widgets/TextField';

const Prompt = props => {
	const [value, setValue] = React.useState("");

	const close = () => {
		props.hideModal(); 
		props.onCancel();
	}
	
	const cancel = () => {
		props.hideModal(); 
		props.onCancel();
	}

	const ok = () => {
		props.hideModal(); 
		props.onOk(value);
	}

	return (
		<Dialog open onClose={close} aria-labelledby={props.title} className="prompt-modal">
			<DialogTitle id="prompt-modal__title">{props.title}</DialogTitle>
			<DialogContent>
				<TextField 
					required={props.required}
					value={value}
					label={props.label}
					placeholder={props.placeholder}
					onChange={e => setValue(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button className="prompt-modal__cancel" onClick={cancel} autoFocus>{props.cancelButtonLabel}</Button>
				<Button className="prompt-modal__ok" disabled={props.required && !value} onClick={ok}>{props.okButtonLabel}</Button>
			</DialogActions>
		</Dialog>
	);
};

Prompt.propTypes = {
	hideModal: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool
};

Prompt.defaultProps = {
	okButtonLabel: 'Ok',
	cancelButtonLabel: 'Cancel',
	required: true,
	placeholder: '',
	onCancel: () => null
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(Prompt);