import React from 'react';
import PropTypes from 'prop-types';
import CourseCard from './CourseCard';
import { 
	Grid, 
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@mui/material';
// import CourseDialog from './CourseDialog';
import map from 'lodash/map';
import YesIcon from '@mui/icons-material/Check';
import NoIcon from '@mui/icons-material/Clear';
import some from 'lodash/some';
import Card from '../widgets/Card';

const SuggestCourses = props => {
	// const [showCourseDialog, setShowCourseDialog] = React.useState(false);
	// const { loading } = props;
	let suggestion = props.suggestion || {};
	const { successColour, errorColour } = props;
	
	return (
		<React.Fragment>
			<span className="h3">Top Courses</span>
			{!props.loading && suggestion.courses.length === 0 ? <p style={{ marginTop: 10 }}>No matches found...</p> :
				<Grid container spacing={3}>
					{map(suggestion.courses, (c, i) => 
						<CourseCard 
							key={i} 
							course={c} 
						/>
					)}
				</Grid>
			}

			<Card 
				title="Course Matches"
				className="course-matches"
				style={{ marginTop: '20px' }}
			>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell />
							{
								map(suggestion.courses, c => {
									return (
										<TableCell>
											{c.title}
										</TableCell>
									);
								})
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							map(suggestion.missingUnits, u => 
								<TableRow>
									<TableCell>`{u.unitCode} - {u.unitTitle}`</TableCell>
									{
										map(suggestion.courses, c => {
											const achieves = some(c.competencies, comp => comp.unitOfCompetencyId === u.unitOfCompetencyId);

											return (
												<TableCell>
													{achieves ? <YesIcon style={{ color: successColour, verticalAlign: "middle" }} /> : <NoIcon style={{ color: errorColour, verticalAlign: "middle" }} /> }
												</TableCell>
											);
										})
									}
								</TableRow>
							)
						}
					</TableBody>
				</Table>
			</Card>
			{/* <CourseDialog 
				title="Add Attachment to Course"
				filePlaceholderText="Click here to select attachment file"
				open={showCourseDialog}
				onOk={(resource, file) => {
					if (file) setFormFiles([...formFiles, file]);

					props.updateValues({ resources: [...resources, resource] });
					setShowCourseDialog(false);
				}}
				onClose={() => setShowCourseDialog(false)}
				resourceTypes={["File"]}
			/> */}
		</React.Fragment>
	);
};

SuggestCourses.propTypes = { 
	suggestion: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	errorColour: PropTypes.string.isRequired,
	successColour: PropTypes.string.isRequired
};

SuggestCourses.defaultProps = {
	suggestion: null
};

export default SuggestCourses;
