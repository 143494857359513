import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import SearchResults from './SearchResults';
import { searchProblems, isLoading as problemsLoading } from '../../store/problems';
import { searchEvents, isLoading as eventsLoading } from '../../store/events';
import { searchOffers, isLoading as offersLoading } from '../../store/offers';
import Card from '../widgets/Card';
import SearchField from '../widgets/SearchField';
import { getQueryParameters } from '../../utils/utils';

const SearchController = props => {
	const [search, setSearch] = React.useState("");

	React.useState(() => {
		const { search = "" } = getQueryParameters(props.location.search);
		setSearch(search);
	
		// Only run search on mount if not already searching
		if (!props.loading) props.searchResults(search);
	}, []);

	return <PageLayout title="Search Results" pageContent={
		<React.Fragment>
			<Card title="Search results" subtitle="Search all records">
				<SearchField
					value={search} 
					onChange={value => setSearch(value)} 
					onSubmit={() => props.searchResults(search)}
					loading={props.loading}
				/>
			</Card>
			<SearchResults 
				problems={props.problems}
				events={props.events}
				offers={props.offers}
				search={search} 
				isAdmin={props.isAdmin} 
				loading={props.loading}
				plural={props.plural}
			/> 
		</React.Fragment>
	} breadcrumbs={["Search"]} />;
};

SearchController.propTypes = { 
	problems: PropTypes.arrayOf(PropTypes.object).isRequired,
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	offers: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	plural: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	problems: state.problems.problems,
	events: state.events.events,
	offers: state.offers.offers,
	loading: problemsLoading(state) || eventsLoading(state) || offersLoading(state),
	isAdmin: state.context.isAdministrator,
	plural: state.settings.plural
});

const mapDispatchToProps = dispatch => ({
	searchResults: search => [
		dispatch(searchProblems(search)),
		dispatch(searchEvents(search)),
		dispatch(searchOffers(search))
	]
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchController);
