import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import NewApplication from './NewApplication';
import { getOffer, getApplicationByOffer, saveApplication, submitApplication, isLoading } from '../../store/offers';
import { showModal } from '../../store/modal';
import isEmpty from 'lodash/isEmpty';

class NewApplicationController extends React.Component {
	UNSAFE_componentWillMount() {
		const offerId = this.props.match.params.offerId;

		this.props.getOffer(offerId);
		this.props.getApplicationByOffer(offerId);
	}

	render = () => 
		<PageLayout title="Response to Research Match" pageContent={
			<NewApplication
				application={this.props.application}
				offer={this.props.offer}
				loading={this.props.loading || isEmpty(this.props.offer)}
				saveApplication={this.props.saveApplication}
				submitApplication={this.props.submitApplication}
			/>
		} breadcrumbs={[
			{ label: "Research Match", path: "/research-match"},
			{ label: "View Research Match", path: `/research-match/${this.props.match.params.offerId}`},
			"Submit Application"
		]} />;
};

NewApplicationController.propTypes = {
	offer: PropTypes.object.isRequired,
	application: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	submitApplication: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	offer: state.offers.offer,
	application: state.offers.application,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getOffer: offerId => dispatch(getOffer(offerId)),
	getApplicationByOffer: offerId => dispatch(getApplicationByOffer(offerId)),
	saveApplication: (application, newFiles) => dispatch(saveApplication(application, newFiles)),
	submitApplication: (application, newFiles) => dispatch(showModal("SIMPLE", {
		title: "Is your application ready to submit?",
		message: "",
		onOk: () => dispatch(submitApplication(application, newFiles, 'research-match'))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewApplicationController);
