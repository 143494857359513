import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withForm } from '../../../utils/forms';
import FormButtons from '../../widgets/FormButtons';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';

const form = {
	initValues: props => ({ ...props.userOrganisation }),
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			loadItems: {
				route: "tenants",
				mapItem: t => ({ label: t.name, value: t.tenantId })
			}
		},
		{
			name: "name",
			label: "Organisation Name",
			required: true,
			placeholder: "",
			disabled: props => !props.canEdit
		},
		{
			name: "allowSelfRegistration",
			label: "Allow Self Registration",
			type: "checkbox"
		}
	]
};

const EditUserOrganisation = props => {
	const { fields, values, loading, isNew } = props;

	const onSubmit = () => {
		if (props.validateFields()) {
			props.onSave({ ...values });
		}
	}

	return (
		<Card 
			title={isNew ? "Add User Organisation" : "Edit User Organisation"} 
			className="edit-user-organisation" 
			loading={loading}
		>
			<Form onSubmit={onSubmit}>
				<Grid container spacing={3}>
					{props.canSelectTenant && isNew && <Grid item xs={12}>{fields.tenantId()}</Grid>}
					<Grid item xs={12}>{fields.name()}</Grid>
					<Grid item xs={12} sm={6}>{fields.allowSelfRegistration()}</Grid>
				</Grid>
			</Form>
			{props.canEdit && 
				<FormButtons 
					loading={loading}
					success={props.saveResult.success}
					onSave={onSubmit}
					showDelete={!isNew}
					onDelete={() => props.onDelete(values.userOrganisationId)}
				/>
			}
		</Card>
	);
};

EditUserOrganisation.propTypes = { 
	canEdit: PropTypes.bool,
	canSelectTenant: PropTypes.bool,
	userOrganisation: PropTypes.object,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool
};

EditUserOrganisation.defaultProps = {
	canEdit: false,
	canSelectTenant: false,
	role: {},
	userOrganisation: () => null,
	isNew: false
};

export default withForm(form)(EditUserOrganisation);
