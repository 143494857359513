import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import CommentIcon from '@mui/icons-material/Comment';
import Avatar from '../widgets/Avatar';
import { stripHtml, truncateString } from '../../utils/utils';
import unescape from 'lodash/unescape';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	discussionRow: {
		'& .label': {
			backgroundColor: theme.palette.primary.main
		}
	}
});

const DiscussionRow = React.memo(({ discussion: d, classes }) => 
	<div className={`discussion-row ${classes.discussionRow}`}>
		<Avatar src={d.authorAvatar} fullName={d.authorName} length={50} style={{ marginRight: 10 }} />
		<div>
			<Link className="h4" to={`/my-community/discussion/${d.discussionId}`}>{d.subject}</Link>
			<div className="description">{truncateString(unescape(stripHtml(d.description)), 250)}</div>
			<div className="stats">
				{/* <VisibilityIcon style={{ marginRight: 5 }} />
				{d.views} Views */}
				<CommentIcon style={{ margin: "0 5px 0px 10px" }} />
				{d.comments} Comments
			</div>
		</div>
	</div>
);

DiscussionRow.propTypes = {
	discussion: PropTypes.object.isRequired
};

export default withStyles(DiscussionRow, styles);