import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../../utils/forms';
import Card from '../../widgets/Card';
import Form from '../../widgets/Form';
import FormButtons from '../../widgets/FormButtons';
import { Grid } from '@mui/material';

const form = {
	initValues: props => props.product,
	fields: [
		{
			name: "tenantId",
			label: "Tenant",
			type: "autoComplete",
			defaultItem: {
				label: 'None',
				value: null
			},
			loadItems: {
				route: "tenants", 
				mapItem: t => ({ label: t.name, value: t.tenantId })
			},
			onChange: values => {
				values.organisationId = null;
			}
		},
		{
			name: "organisationId",
			label: "Organisation",
			type: "autoComplete",
			defaultItem: {
				label: 'None',
				value: null,
				organisation: {
				}
			},
			loadItems: {
				route: (props, values) => `organisations/by-tenant/${props.isGlobalAdministrator ? values.tenantId : props.tenantId}?type=Organisation`,
				mapItem: o => ({ label: o.name, value: o.organisationId, organisation: o })
			}
		},
		{
			name: "stripeProductId",
			label: "Stripe Product",
			type: "autoComplete",
			defaultItem: {
				label: 'None',
				value: null,
				product: {
					name: '',
					description: ''
				}
			},
			loadItems: {
				route: "stripe/stripe-products",
				mapItem: p => ({ label: p.name, value: p.id, product: p })
			},
			onChange: (values, value) => {
				values.stripePriceId = null;
				values.name = value.product.name;
				values.description = value.product.description;
			}
		},
		{
			name: "stripePriceId",
			label: "Stripe Price",
			type: "autoComplete",
			defaultItem: {
				label: 'None',
				value: null
			},
			loadItems: {
				route: (props, values) => values.stripeProductId ? `stripe/stripe-prices?stripeProductId=${values.stripeProductId}` : null, 
				mapItem: p => ({ label: `$${p.unit_amount_decimal / 100.0} per ${p.recurring.interval}`, value: p.id, price: p.unit_amount_decimal / 100.0 })
			},
			onChange: (values, value) => {
				values.price = value.price;
			}
		},
		{
			name: "name",
			label: "Product Name",
			required: true
		},
		{
			name: "description",
			label: "Description",
			type: "textEditor"
		},
		{
			name: "productType",
			label: "Product Type",
			type: "select",
			items: ["Subscription"],
			required: true
		},
		{
			name: "duration",
			label: "Duration"
		},
		{
			name: "price",
			label: "Price",
			type: "number"
		},
		{
			name: "order",
			label: "Order",
			type: "number"
		},
		{
			name: "active",
			label: "Active",
			type: "checkbox"
		},
		{
			name: "clearRolesOnSubscription",
			label: "Clear Roles on Subscription",
			type: "checkbox"
		},
		{
			name: 'requiresMembership',
			label: 'Requires Membership',
			type: 'checkbox'
		},
		{
			name: 'minimumMembershipLevel',
			label: 'Minimum Membership Level',
			type: "autoComplete",
			defaultItem: {
				label: 'None',
				value: null
			},
			loadItems: {
				route: (props, values) => `organisations/membership-levels?organisationId=${values.organisationId}`, 
				mapItem: l => ({ label: l.title, value: l.membershipLevelId})
			}
		},
		{
			name: 'mustCompleteAssessmentAndSelectProProfile',
			label: 'Must complete initial assessment and select professional profile',
			type: 'checkbox'
		},
		{
			name: 'noPaymentRequiredOnUpgrade',
			label: 'No payment required on upgrade',
			type: 'checkbox'
		},
		{
			name: "roles",
			label: "Roles",
			type: "autoComplete",
			loadItems: {
				route: "roles",
				mapItem: r => ({ label: r.name, value: r.roleId })
			},
			getValue: values => values.roles.map(r => r.roleId),
			setValues: (newValue, values) => ({ ...values, roles: newValue.map(r => ({ name: r.label, roleId: r.value })) }),
			isMulti: true
		}
	]
};

const EditProduct = props => {
	const { fields, loading, values, isNew } = props;

	return (
		<Card title={isNew ? "Create Product" : "Edit Product"} className="edit-product" loading={loading}>
			<Form onSubmit={() => props.validateFields() && props.saveProduct(values)}>
				<Grid container spacing={3}>
					{props.isGlobalAdministrator && <Grid item xs={12} md={6}>{fields.tenantId()}</Grid>}
					{values.tenantId && <Grid item xs={12} md={6}>{fields.organisationId()}</Grid>}
					<Grid item xs={12} md={6}>{fields.stripeProductId()}</Grid>
					<Grid item xs={12} md={6}>{fields.stripePriceId()}</Grid>
					<Grid item xs={12} md={6}>{fields.name()}</Grid>
					<Grid item xs={12} md={6}>{fields.productType()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
					<Grid item xs={12} md={6}>{fields.duration()}</Grid>
					<Grid item xs={12} md={6}>{fields.price()}</Grid>
					<Grid item xs={12} md={6}>{fields.clearRolesOnSubscription()}</Grid>
					<Grid item xs={12} md={6}>{fields.mustCompleteAssessmentAndSelectProProfile()}</Grid>
					{values.organisationId && <Grid item xs={12} md={6}>{fields.requiresMembership()}</Grid>}
					<Grid item xs={12} md={6}>{values.organisationId && values.requiresMembership && fields.minimumMembershipLevel()}</Grid>
					<Grid item xs={12} md={6}>{fields.noPaymentRequiredOnUpgrade()}</Grid>
					<Grid item xs={12}>{fields.roles()}</Grid>
					<Grid item xs={12} md={6}>{fields.order()}</Grid>
					<Grid item xs={12} md={6}>{fields.active()}</Grid>
				</Grid>
			</Form>
			<FormButtons 
				loading={loading}
				success={props.saveResult.success}
				onSave={() => props.validateFields() && props.saveProduct(values)}
				showDelete={!isNew}
				onDelete={() => props.deleteProduct(values)}
			/>
		</Card>
	);
};

EditProduct.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	product: PropTypes.object,
	saveProduct: PropTypes.func.isRequired,
	deleteProduct: PropTypes.func,
	loading: PropTypes.bool.isRequired
};

EditProduct.defaultProps = {
	product: {},
	deleteProduct: () => null
};

export default withForm(form)(EditProduct);
