import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputLabel, Input, FormControl, FormHelperText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import ResourceDialog from './ResourceDialog';

const ResourcePicker = React.memo(props => {
	const [open, setOpen] = React.useState(false);
	const [id] = React.useState(props.id || uniqueId("resource-picker-"));

	const { resources, value } = props,
		placeholder = props.isMulti ? value.map(r => r.name).join(", ") : (resources.find(r => r.resourceId === value) || {}).name;

	// Open picker if showPicker is true and resources have finished loading
	React.useEffect(() => {
		if (props.showPicker && !open && resources.length > 0) onOpen();
	}, [props.showPicker, resources.length]);

	const onOpen = () => setOpen(true);

	const onChange = value => {
		setOpen(false);
		props.onChange(value);
		props.onClose();
	};

	const filterIcon = <FilterListIcon fontSize="small" onClick={props.disabled ? undefined : onOpen} />;
		
	return (
		<React.Fragment>
			<FormControl className={classNames("card-picker", { "disabled": props.disabled })} fullWidth style={props.style}>
				<InputLabel htmlFor={id} disabled={props.disabled}>
					{props.label || (props.isMulti ? "Resources" : "Resource")}
					{props.required && <span style={{ color: props.errorColour }}> *</span>}
				</InputLabel>
				<Input id={id} value={placeholder || ""} disabled endAdornment={
					(props.isMulti ? value.length === 0 : value === 0) || props.disableClearable ? filterIcon :
					<React.Fragment>
						<ClearIcon fontSize="small" onClick={props.disabled ? undefined : () => onChange(props.isMulti ? [] : 0)} /> | {filterIcon}
					</React.Fragment>
				} />
				{props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
			</FormControl>
			<ResourceDialog
				value={value}
				open={open}
				onChange={onChange}
				onClose={() => setOpen(false)}
				isMulti={props.isMulti}
				subscribedOnly={props.subscribedOnly}
			/>
		</React.Fragment>
	);
});

ResourcePicker.propTypes = {
	label: PropTypes.node,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	helpText: PropTypes.node,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	showPicker: PropTypes.bool,
	onClose: PropTypes.func,
	disableClearable: PropTypes.bool,
	isMulti: PropTypes.bool,
	subscribedOnly: PropTypes.bool,
	errorColour: PropTypes.string.isRequired
};

ResourcePicker.defaultProps = {
	label: "",
	required: false,
	helpText: "",
	disabled: false,
	style: {},
	showPicker: false,
	onClose: () => {},
	disableClearable: false,
	isMulti: false,
	subscribedOnly: false
};

const mapStateToProps = state => ({
	resources: state.resources.resources,
	errorColour: state.theme.errorColour
});

export default connect(mapStateToProps, null)(ResourcePicker);