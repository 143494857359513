import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import range from 'lodash/range';

class Pagination extends React.Component {
	// Return the first page to be rendered
	getFirstPage = () => {
		return Math.max(this.state.last - this.props.maxPages + 1, 1);
	}

	// Return the last page to be rendered
	getLastPage = () => {
		return Math.min(this.props.maxPages, this.props.pageCount);
	}
	
	state = {
		pageCount: this.props.pageCount,
		last: this.getLastPage()
	}

	// Set the page number and update the final page if required
	changePage = page => {
		const { pageCount, last } = this.state;

		this.props.changePage(page);

		if (page <= this.getFirstPage()) {
			this.setState({ last: page === 1 ? this.getLastPage() : last - 1 });
		} else if (page >= last) {
			this.setState({ last: page === pageCount ? pageCount : last + 1 });
		}
	}

	render() {
		const { currentPage } = this.props,
			{ pageCount, last } = this.state, 
			first = this.getFirstPage();

		// If number of problems has been updated/ filtered, reset pagination properties
		if (pageCount !== this.props.pageCount) {
			const newLast = this.getLastPage();

			this.setState({ pageCount: this.props.pageCount, last: newLast });
			if (currentPage > newLast) this.props.changePage(newLast);
		}

		// Helper method to render page link
		const renderPage = page => 
			<a className="page-link" onClick={() => this.changePage(page)}>{(page < first || page > last) ? "..." : page}</a>;

		return pageCount === 0 ? null : (
			<ul className="pagination justify-content-center">
				<li className={classNames("page-item", { "disabled": currentPage === 1 })}>
					<a className="page-link" onClick={() => this.changePage(currentPage - 1)}>Previous</a>
				</li>
				{range(first, last + 1).map(index => 
					<li key={index} className={classNames("page-item", { "active": index === currentPage })}>
						{index === first ? renderPage(1) : index === last ? renderPage(pageCount) : renderPage(index)}
					</li>
				)}
				<li className={classNames("page-item", { "disabled": currentPage === pageCount })}>
					<a className="page-link" onClick={() => this.changePage(currentPage + 1)}>Next</a>
				</li>
			</ul>
		);
	}
};

Pagination.propTypes = {
	pageCount: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	changePage: PropTypes.func.isRequired,
	maxPages: PropTypes.number.isRequired
};

export default Pagination;
