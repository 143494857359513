import PropTypes from 'prop-types';
import React from 'react';
// import Lightbox from 'react-images';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import map from 'lodash/map';

const Gallery = React.memo(props => {
	const [, setState] = React.useState({ open: false, index: 0 });
	const { heading, subheading, images } = props;
		// { open, index } = state;

	const handleRemove = (event, index) => {
		event.stopPropagation();
		props.remove(index);
	}

	return (
        <React.Fragment>
			{heading && <span className="h4">{heading}</span>}
			{subheading && <p>{subheading}</p>}
			{/* Render Gallery */}
			{images.length === 0 && !props.hidePlaceholder && <p>{props.placeholder}</p>}
			{images.length > 0 &&
				<div className="dih-image-gallery" style={props.style}>
					{map(images, (image, i) =>
						<span key={i} className="thumbnail" onClick={() => setState({ open: true, index: i })}>
							<img src={image.src || image.thumbnail} alt={image.caption} />
							{props.remove &&
								<IconButton
                                    aria-label="Remove"
                                    style={{ position: "absolute", top: 10, right: 10 }}
                                    onClick={e => handleRemove(e, i)}
                                    size="large">
									{/* Blend mode: difference adds more contrast */}
									<ClearIcon style={{ color: "white", mixBlendMode: "exclusion" }} />
								</IconButton>
							}
						</span>
					)}
				</div>
			}
			{/* Render Lightbox */}
			{/* <Lightbox
				currentImage={index}
				images={images}
				isOpen={open}
				onClickPrev={() => setState({ open, index: index - 1 })}
				onClickNext={() => setState({ open, index: index + 1 })}
				onClickThumbnail={index => setState({ open, index })}
				onClose={() => setState({ open: false, index })}
				showThumbnails={props.showThumbnails}
			/> */}
		</React.Fragment>
    );
});

Gallery.propTypes = {
	heading: PropTypes.string,
	subheading: PropTypes.string,
	images: PropTypes.array,
	showThumbnails: PropTypes.bool,
	remove: PropTypes.func,
	style: PropTypes.object,
	placeholder: PropTypes.string,
	hidePlaceholder: PropTypes.bool
};

Gallery.defaultProps = {
	heading: "",
	subheading: "",
	images: [],
	showThumbnails: true,
	remove: null,
	style: {},
	placeholder: "There are no images to display...",
	hidePlaceholder: false
}

export default Gallery;