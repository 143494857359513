import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import { Link } from 'react-router-dom'
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import SearchField from '../widgets/SearchField';
import ButtonLink from '../widgets/ButtonLink';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

const Tenants = props => {
	const [search, setSearch] = React.useState("");
	
	return (
        <Card loading={props.loading}>
			<SearchField label="Search tenants" value={search} onChange={value => setSearch(value)} loading={props.loading} />
			{/* Tenants Datatable */}
			<DataTable data={props.tenants} search={search} enableExport tableFooter={
				props.canSubmit && <ButtonLink to="/admin/tenants/add">Add new tenant</ButtonLink>
			}>
				<DataTableColumn style={{ padding: 0, width: 35 }} headerStyle={{ padding: 0 }} renderer={c =>
					<IconButton
                        onClick={() => props.deleteTenant(c)}
                        disabled={!props.canEdit}
                        style={{ padding: 2 }}
                        aria-label="Delete Tenant"
                        size="large">
						<DeleteIcon style={{ color: props.canEdit ? props.errorColour : undefined }} />
					</IconButton>
				} />
				<DataTableColumn 
					name="fullName" 
					label="Tenant" 
					renderer={data => !props.canEdit ? data.name :
						<Link to={`tenants/${data.tenantId}`} style={{ color: props.primaryColour }}>
							<span>{data.name}</span>
						</Link>
					}
				/>
				<DataTableColumn name="title" label="Title" />
				<DataTableColumn name="active" label="Active" dataType="bool" />
			</DataTable>
		</Card>
    );
};

Tenants.propTypes = {
	tenants: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	deleteTenant: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired
};

export default Tenants;
