import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { addOrUpdate, toDate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import { changeLocation } from './location';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import filter from 'lodash/filter';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: false,
	message: "",
	fields: [] 
};

const newEvent = {
	eventId: 0,
	eventType: "Event",
	visibility: "Internal and External",
	attachments: [],
	eventStartDate: new Date(),
	eventEndDate: new Date()
};

const initialState = {
	saveResult: emptySaveResult,
	event: { ...newEvent },
	events: [],
	isVoting: {}
};

const CREATE_NEW_EVENT = "CREATE_NEW_EVENT";
const CLEAR_EVENT = "CLEAR_EVENT";
const CLEAR_EVENTS = "CLEAR_EVENTS";
const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";
const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";
const SEARCH_EVENTS_REQUEST = "SEARCH_EVENTS_REQUEST";
const SEARCH_EVENTS_SUCCESS = "SEARCH_EVENTS_SUCCESS";
const SEARCH_EVENTS_FAILURE = "SEARCH_EVENTS_FAILURE";
const SAVE_EVENT_REQUEST = "SAVE_EVENT_REQUEST";
const SAVE_EVENT_SUCCESS = "SAVE_EVENT_SUCCESS";
const SAVE_EVENT_FAILURE = "SAVE_EVENT_FAILURE";
const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const isLoading = createLoadingSelector(["GET_EVENTS", "GET_EVENT", "DELETE_EVENT", "SAVE_EVENT", "SEARCH_EVENTS", "GET_VENDOR_EVENTS", "REQUEST_EVENT"]);

export const createNewEvent = () => ({ type: CREATE_NEW_EVENT });
export const clearEvents = () => ({ type: CLEAR_EVENTS });
export const clearEvent = () => ({ type: CLEAR_EVENT });

export const getEvents = (startDate, endDate, showUnapproved, subscribedOnly) =>
	createFetchAction({
		url: `/api/events?fromDate=${startDate ? startDate.toJSON() : ""}&toDate=${endDate ? endDate.toJSON() : ""}&showUnapproved=${Boolean(showUnapproved)}&subscribedOnly=${Boolean(subscribedOnly)}`,
		startAction: GET_EVENTS_REQUEST,
		onError: error => [getEventsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEventsSuccess(map(data, e => ({
			...e,
			eventStartDate: toDate(e.eventStartDate),
			eventEndDate: toDate(e.eventEndDate)
		})))
	});

export const getEventsSuccess = data => ({ type: GET_EVENTS_SUCCESS, payload: { data } });
export const getEventsFailure = error => ({ type: GET_EVENTS_FAILURE, payload: { error } });

export const searchEvents = search => 
	createFetchAction({
		url: `/api/events/search?search=${encodeURIComponent(search)}`,
		startAction: SEARCH_EVENTS_REQUEST,
		onError: error => [searchEventsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchEventsSuccess(map(data, e => ({
			...e,
			eventStartDate: toDate(e.eventStartDate),
			eventEndDate: toDate(e.eventEndDate)
		})))
	});

export const searchEventsSuccess = data => ({ type: SEARCH_EVENTS_SUCCESS, payload: { data } });
export const searchEventsFailure = error => ({ type: SEARCH_EVENTS_FAILURE, payload: { error } });

const GET_VENDOR_EVENTS_REQUEST = "GET_VENDOR_EVENTS_REQUEST";
const GET_VENDOR_EVENTS_SUCCESS = "GET_VENDOR_EVENTS_SUCCESS";
const GET_VENDOR_EVENTS_FAILURE = "GET_VENDOR_EVENTS_FAILURE";

export const getVendorEvents = () =>
	createFetchAction({
		url: `/api/events/vendor-events`,
		startAction: GET_VENDOR_EVENTS_REQUEST,
		onError: error => [getVendorEventsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getVendorEventsSuccess(map(data, e => ({
			...e,
			eventStartDate: toDate(e.eventStartDate),
			eventEndDate: toDate(e.eventEndDate)
		})))
	});

export const getVendorEventsSuccess = data => ({ type: GET_VENDOR_EVENTS_SUCCESS, payload: { data } });
export const getVendorEventsFailure = error => ({ type: GET_VENDOR_EVENTS_FAILURE, payload: { error } });

export const getEvent = eventId => 
	createFetchAction({
		url: `/api/events/${eventId}`,
		startAction: GET_EVENT_REQUEST,
		onError: error => [getEventFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEventSuccess({
			...data,
			eventStartDate: toDate(data.eventStartDate),
			eventEndDate: toDate(data.eventEndDate)
		})
	});

export const getEventSuccess = data => ({ type: GET_EVENT_SUCCESS, payload: { data } });
export const getEventFailure = error => ({ type: GET_EVENT_FAILURE, payload: { error } });

export const saveEvent = (event, newFiles, isCommunity) => {
	const errors = [];

	if (!event.title) errors.push({ fieldName: "Title", valid: false, message: "Title is required" });

	if (errors.length > 0) return { type: SAVE_EVENT_SUCCESS, data: errors };

	const formData = new FormData();
	formData.append("Event", encodeURIComponent(JSON.stringify(event)));
	forEach(newFiles, f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/events",
		data: formData,
		startAction: SAVE_EVENT_REQUEST,
		onError: error => [saveEventFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.object) {
				data.object = {
					...data.object,
					eventStartDate: toDate(data.object.eventStartDate),
					eventEndDate: toDate(data.object.eventEndDate)
				}

				return [saveEventSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}events/${data.object.eventId}`)];
			} else {
				return [saveEventSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveEventSuccess = data => ({ type: SAVE_EVENT_SUCCESS, data });
export const saveEventFailure = error => ({ type: SAVE_EVENT_FAILURE, error });

export const deleteEvent = (eventId, isCommunity) => 
	createPostAction({
		url: `/api/events/${eventId}/delete`,
		startAction: DELETE_EVENT_REQUEST,
		onError: error => [deleteEventFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteEventSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}events`)];
			} else {
				return [deleteEventSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteEventSuccess = data => ({ type: DELETE_EVENT_SUCCESS, data });
export const deleteEventFailure = error => ({ type: DELETE_EVENT_FAILURE, error });

const REQUEST_EVENT_REQUEST = "REQUEST_EVENT_REQUEST";
const REQUEST_EVENT_SUCCESS = "REQUEST_EVENT_SUCCESS";
const REQUEST_EVENT_FAILURE = "REQUEST_EVENT_FAILURE";

export const requestEvent = (event, newFiles, isCommunity) => {
	const formData = new FormData();
	formData.append("Event", encodeURIComponent(JSON.stringify(event)));
	forEach(newFiles, f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/events/request",
		data: formData,
		startAction: REQUEST_EVENT_REQUEST,
		onError: error => [requestEventFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data.success) {
				return [requestEventSuccess(data), showSuccessNotification(data.message), changeLocation(`/${isCommunity ? "my-community/" : ""}events`)];
			} else {
				return [requestEventSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
}
	

export const requestEventSuccess = data => ({ type: REQUEST_EVENT_SUCCESS, data });
export const requestEventFailure = error => ({ type: REQUEST_EVENT_FAILURE, error });

const REGISTER_EVENT_REQUEST = "REGISTER_EVENT_REQUEST";
const REGISTER_EVENT_SUCCESS = "REGISTER_EVENT_SUCCESS";
const REGISTER_EVENT_FAILURE = "REGISTER_EVENT_FAILURE";

export const registerForEvent = event => {
	if (event.registrationUrl) {
		window.open(event.registrationUrl, "_blank");
	} else {
		return createFetchAction({
			url: `/api/events/${event.eventId}/register`,
			startAction: REGISTER_EVENT_REQUEST,
			onError: error => [registerForEventFailure(error), showErrorNotification(error.message)],
			onSuccess: data => registerForEventSuccess(data)
		});
	}
};

export const registerForEventSuccess = data => ({ type: REGISTER_EVENT_SUCCESS, payload: { data } });
export const registerForEventFailure = error => ({ type: REGISTER_EVENT_FAILURE, payload: { error } });

export const getSubscribedEvents = limit => 
	createFetchAction({
		url: `/api/events/subscribed?limit=${limit}`,
		startAction: GET_EVENTS_REQUEST,
		onError: error => [getEventsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEventsSuccess(map(data, e => ({
			...e,
			eventStartDate: toDate(e.eventStartDate),
			eventEndDate: toDate(e.eventEndDate)
		})))
	});

const sanitizeEvent = event => ({ ...event, description: DOMPurify.sanitize(event.description) });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_EVENTS:
			return { ...state, events: [] };
		case CLEAR_EVENT:
			return { ...state, event: {} };
		case GET_EVENTS_REQUEST:
		case SEARCH_EVENTS_REQUEST:
		case GET_VENDOR_EVENTS_REQUEST:
			return { ...state, events: [], saveResult: emptySaveResult };
		case GET_EVENTS_SUCCESS:
		case SEARCH_EVENTS_SUCCESS:
		case GET_VENDOR_EVENTS_SUCCESS:
			return { ...state, events: map(action.payload.data, sanitizeEvent), isLoading: false };
		case GET_EVENT_REQUEST:
			return {
				...state,
				event: {},
				isLoading: true,
				saveResult: emptySaveResult
			};
		case GET_EVENT_SUCCESS:
			return { ...state, event: sanitizeEvent(action.payload.data), isLoading: false };
		case DELETE_EVENT_SUCCESS:
			return { ...state, events: filter(state.events, c => c.eventId !== action.data.objectId) };
		case CREATE_NEW_EVENT:
			return { ...state, event: { ...newEvent } };	
		case SAVE_EVENT_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_EVENT_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { events: addOrUpdate(state.events, sanitizeEvent(object), { eventId: object.eventId }) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case REGISTER_EVENT_REQUEST:
			return { ...state, saveResult: emptySaveResult, message: "" };
		case REGISTER_EVENT_FAILURE:
			return { ...state, saveResult: emptySaveResult };
		case REGISTER_EVENT_SUCCESS:
			return {
				...state,
				saveResult: {
					success: action.data.success,
					message: action.data.message
				}
			};
		default:
			return state;
	}
};
