import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearStudentProject, getStudentProject, getEnquiries, getProjectEnquiries, isLoading } from '../../store/studentProjects';
import { getQueryParameters, stripHtml, truncateString } from '../../utils/utils';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import SearchField from '../widgets/SearchField';
import Card from '../widgets/Card';
import PageLayout from '../PageLayout';

const EnquiriesController = props => {
	const [search, setSearch] = React.useState("");
	const [projectTitles, setProjectTitles] = React.useState([]);
	const { project, enquiries } = props;

	React.useEffect(() => {
		const { projectId } = getQueryParameters(props.location.search);

		props.getProject(projectId);
		props.getEnquiries(projectId);
	}, [props.location.search]);

	// Store unique project titles to save calculating on each cycle
	React.useEffect(() => {
		setProjectTitles(enquiries.reduce((acc, e) => acc.includes(e.projectTitle) ? acc : [...acc, e.projectTitle], []));
	}, [enquiries.length]);

	return (
		<PageLayout title="Project Enquiries" pageContent={
			<Card title={`Search Enquiries${project.studentProjectId ? `: ${project.title}` : ""}`}>
				<SearchField value={search} onChange={value => setSearch(value)} loading={props.loading} />
				{/* Datatable */}
				<DataTable data={enquiries} search={search} enableExport>
					<DataTableColumn name="fullName" label="User" dataType="avatar" />
					{!project.studentProjectId &&
						<DataTableColumn 
							name="projectTitle" 
							label="Project"
							canFilter 
							filterType="AutoComplete"
							items={projectTitles}
							renderer={e => <Link to={`/business-challenges/${e.studentProjectId}`}>{e.projectTitle}</Link>} 
						/>
					}
					<DataTableColumn name="content" label="Content" renderer={e => truncateString(stripHtml(e.content), project.studentProjectId ? 100 : 50)} />
					<DataTableColumn name="submissionDate" label="Submission Date" dataType="date" />
					<DataTableColumn name="status" label="Status" />
				</DataTable>
			</Card>
		} breadcrumbs={[{ label: "Research Match", path: "/business-challenges" }, "Project Enquiries"]} />
	);
};

EnquiriesController.propTypes = { 
	project: PropTypes.object.isRequired,
	enquiries: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	project: state.studentProjects.project,
	enquiries: state.studentProjects.enquiries,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getProject: projectId => dispatch(projectId ? getStudentProject(projectId) : clearStudentProject()),
	getEnquiries: projectId => dispatch(projectId ? getProjectEnquiries(projectId) : getEnquiries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnquiriesController);