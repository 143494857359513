import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { showErrorNotification, showSuccessNotification } from '../../store/notifications';
import { withForm } from '../../utils/forms';
import { post } from '../../utils/ajax';
import Button from '../widgets/Button';
import some from 'lodash/some';

const form = {
	fields: [
		{
			name: "message",
			label: "Message",
			type: "textEditor"
		}
	]
};

const RequestListingDialog = props => {
	const [loading, setLoading] = React.useState(false);

	const resetForm = () => props.updateValues({ subject: "", message: "" });

	const handleSubmit = () => {
		if (props.validateFields()) {
			setLoading(true);

			post({
				url: "/api/research-match/request-listing",
				data: {
					message: props.values.message
				},
				onSuccess: result => {
					setLoading(false);

					if (result.success) {
						props.onClose();
						props.showSuccessNotification(result.message);
					} else {
						props.showErrorNotification(result.message);
					}
				},
				onError: error => {
					setLoading(false);
					props.showErrorNotification(error.message);
				}
			});
		}
	}

	return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
            TransitionProps={{
                onEnter: resetForm
            }}>
			<DialogTitle>Request Listing Access</DialogTitle>
			<DialogContent className="request-listing-dialog">
				<Grid container spacing={3}>
					<Grid item xs={12}>{props.fields.message()}</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button loading={loading} onClick={props.onClose}>Cancel</Button>
				<Button loading={loading} disabled={loading || some(props.error)} onClick={handleSubmit}>Submit</Button>
			</DialogActions>
		</Dialog>
    );
};

RequestListingDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(RequestListingDialog));