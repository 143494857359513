import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Button from '../widgets/Button';
import FileUploader from '../widgets/FileUploader';

const form = {
	fieldStyle: { marginBottom: "10px" },
	fields: [
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			}
		},
		{
			name: "profileGroupId",
			label: "Profile Group",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: (props, values) => `skills-tracker/profile-groups?organisationId=${values.organisationId || 0}`,
				mapItem: pg => ({ label: pg.profileGroupName, value: pg.profileGroupId })
			},
			helpText: "Course Provider"
		}
	]
};

const ImportSkillsProfiles = props => {
	const { fields, loading } = props;
	const [state, setState] = React.useState({
		fileName: null
	});

	const setFile = e => {
		const file = e.target.files[0];
		setState({ ...state, fileName: file.name, file: file });

	};

	return (
		<Card title="Import Skills Profiles" className="import-skills-profiles" loading={loading}>
			<Form onSubmit={() => {
				if (props.validateFields()) {
					props.importProfiles(props.values, state.file)
				}
			}}>
				{fields.organisationId()}
				{fields.profileGroupId()}
				<span className="h3">Import File</span>
				<FileUploader accept=".xlsx" value={state.fileName} onChange={setFile} placeholder="Select upload file" />

				<Button type="submit" loading={loading}>Import</Button>
			</Form>
		</Card>
	);
};

ImportSkillsProfiles.propTypes = { 
	framework: PropTypes.object,
	importProfiles: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

ImportSkillsProfiles.defaultProps = {
	framework: {},
};

export default withForm(form)(ImportSkillsProfiles);
