import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getCourses, searchCourses, isLoading, importData, deleteCourse, copyCourse } from '../../store/courses';
import { canEditCourse, canSubmitCourses } from '../../store/security';
import { showModal } from '../../store/modal';
import { withForm } from '../../utils/forms';
import PageLayout from '../PageLayout';
import CopyCourseDialog from '../courses/CopyCourseDialog';
import CompetenciesViewer from '../widgets/CompetenciesViewer';
import FileUploader from '../widgets/FileUploader';
import CourseCard from '../courses/CourseCard';
import ButtonLink from '../widgets/ButtonLink';
import Checkbox from '../widgets/Checkbox';
import map from 'lodash/map';
import Button from '../widgets/Button';
import PagePagination from '../widgets/PagePagination';

let timer = null;
const form = {
	initValues: props => ({ _pageNum: props.pageNum, _pageSize: props.pageSize }),
	onChange: (props, values) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchCourses(values.search, 1, values._pageSize), 500);
	},
	fields: [{
		name: "search",
		placeholder: "Search",
		startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
	}]
};

const AdminCoursesController = props => {
	const [viewer, setViewer] = React.useState({ open: false, competencies: [] });
	const [copier, setCopier] = React.useState({ open: false, course: {} });
	const [importer, setImporter] = React.useState({ open: false, courses: {}, competencies: {}, replace: true });

	React.useEffect(() => props.searchCourses(null, 1, 25), []);

	const readImportFile = name => e => {
		if (e.target.files.length > 0) {
			const file = e.target.files[0];
			setImporter({ ...importer, [name]: { name: file.name, file } });
		}
	}

	const onImportClose = () => {
		if (!props.importing) {
			setImporter({ open: false, courses: {}, competencies: {}, replace: true });
		}
	}

	return <PageLayout title="Admin Courses" pageContent={
		<React.Fragment>
			<Grid container spacing={3} direction="row-reverse">
				{props.canSubmit &&
					<Grid item xs="auto">
						<ButtonLink to="/admin/courses/create">Create course</ButtonLink>
						<Button color="secondary" style={{ marginLeft: 10 }} onClick={() => setImporter({ ...importer, open: true })}>
							Import data
						</Button>
					</Grid>
				}
				<Grid item xs={12} sm>{props.fields.search()}</Grid>
			</Grid>
			{props.courses.length === 0 ? <p style={{ marginTop: 10 }}>No courses found...</p> :
				<Grid container spacing={3}>
					{map(props.courses, (c, i) => 
						<CourseCard 
							key={i} 
							course={c} 
							canEdit={props.canEdit(c)}
							showCompetencies={() => setViewer({ open: true, competencies: c.competencies })} 
							deleteCourse={props.deleteCourse}
							copyCourse={() => setCopier({ open: true, course: c })}
							organisationId={props.organisationId}
							organisationLogo={props.organisationLogo}
							fromAdmin
						/>
					)}
				</Grid>
			}
			<PagePagination
				pluralItemName="Courses"
				pageNum={props.pageNum}
				pageSize={props.pageSize}
				total={props.total}
				totalPages={props.totalPages}
				onChangePage={page => {
					props.updateValues({
						...props.values,
						_pageNum: page,
						_pageSize: props.pageSize
					});
					props.searchCourses(props.values.search, page, props.pageSize);
				}}
				onChangeRowsPerPage={rowsPerPage => {
					props.updateValues({
						...props.values,
						_pageNum: 1,
						_pageSize: rowsPerPage
					});
					props.searchCourses(props.values.search, 1, rowsPerPage);
				}}
			/>
			<CompetenciesViewer 
				competencies={viewer.competencies} 
				open={viewer.open} 
				onClose={() => setViewer({ open: false, competencies: [] })} 
			/>
			<CopyCourseDialog
				title={`${copier.course.title} - Copy`}
				open={copier.open} 
				copyCourse={(title, include) => {
					props.copyCourse(copier.course.courseId, title, include);
					setCopier({ open: false, course: {} });
				}}
				onClose={() => setCopier({ open: false, course: {} })} 
			/>
			<Dialog open={importer.open} onClose={onImportClose} maxWidth="sm" fullWidth>
				<DialogTitle>Import data</DialogTitle>
				<DialogContent>
					<span className="h5">Import courses</span>
					<FileUploader accept=".csv" value={importer.courses.name || ""} onChange={readImportFile("courses")} placeholder="Upload CSV" />
					<Button variant="text" color="primary" href="/templates/Course%20Template.xlsx" download>
						Download courses template
					</Button>
					<span className="h5" style={{ marginTop: 20 }}>Import competencies</span>
					<Grid container spacing={3}>
						<Grid item xs="auto">
							<FileUploader accept=".csv" value={importer.competencies.name || ""} onChange={readImportFile("competencies")} placeholder="Upload CSV" />
						</Grid>
						<Grid item xs>
							<Checkbox label="Replace existing competencies" checked={importer.replace} onChange={e => setImporter({ ...importer, replace: e.target.checked })} />
						</Grid>
					</Grid>
					<Button variant="text" color="primary" href="/templates/Course%20Competency%20Template.xlsx" download>
						Download competencies template
					</Button>
				</DialogContent>
				<DialogActions>
					<Button onClick={onImportClose} autoFocus>Cancel</Button>
					<Button onClick={() => props.importData(importer.courses.file, importer.competencies.file, importer.replace, onImportClose)} 
						loading={props.importing} disabled={!importer.courses.file && !importer.competencies.file}>
						Import
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	} breadcrumbs={["Courses"]} />;
};

AdminCoursesController.propTypes = { 
	courses: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	importing: PropTypes.bool.isRequired,
	searchCourses: PropTypes.func.isRequired,
	importData: PropTypes.func.isRequired,
	deleteCourse: PropTypes.func.isRequired,
	copyCourse: PropTypes.func.isRequired,
	canEdit: PropTypes.func.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	organisationLogo: PropTypes.string.isRequired,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number
};

AdminCoursesController.defaultProps = {
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null
};

const mapStateToProps = state => ({
	courses: state.courses.courses,
	pageNum: state.courses.pageNum,
	pageSize: state.courses.pageSize,
	totalPages: state.courses.totalPages,
	total: state.courses.total,
	loading: isLoading(state),
	isAdmin: state.context.isAdministrator,
	importing: state.courses.importing,
	canEdit: canEditCourse(state),
	canSubmit: canSubmitCourses(state),
	organisationId: state.context.organisationId,
	organisationLogo: state.context.organisationLogo || ""
});

const mapDispatchToProps = dispatch => ({
	searchCourses: (search, pageNum, pageSize) => dispatch(search ? searchCourses({ search, category: "all", mode: "all", theme: "all" }, pageNum, pageSize) : getCourses(pageNum, pageSize)),
	importData: (coursesFile, competenciesFile, replace, onSuccess) => dispatch(importData(coursesFile, competenciesFile, replace, onSuccess)),
	deleteCourse: courseId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this course?",
		onOk: () => dispatch(deleteCourse(courseId))
	})),
	copyCourse: (courseId, title, includeCompetencies) => dispatch(copyCourse(courseId, title, includeCompetencies))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(AdminCoursesController));