import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import SearchField from '../widgets/SearchField';
import ButtonLink from '../widgets/ButtonLink';
import { Link } from 'react-router-dom'

const SkillsProfiles = ({ skillsProfiles, loading, primaryColour }) => {
	const [search, setSearch] = React.useState("");

	return (
		<Card 
			title="Skills Profiles" 
			loading={loading} 
			titleRightContent={
				<React.Fragment>
					<ButtonLink to="/admin/import-skills-profiles">Import Skills Profiles</ButtonLink>
					<ButtonLink to="/admin/skills-profile" style={{ marginLeft: '10px' }}>Add new Skills Profile</ButtonLink>
					<a href='/templates/Profile%20Template.xlsx' download style={{ marginLeft: '10px', marginTop: '10px' }}>Download Import Template</a>
				</React.Fragment>
			}
		>
			{/* Search SkillsProfiles */}
			<SearchField label="Search profiles" value={search} onChange={value => setSearch(value)} loading={loading} addPadding />
			{/* SkillsProfiles Datatable */}
			<DataTable data={skillsProfiles} search={search} enableExport>
				<DataTableColumn name="profileName" label="Profile" renderer={data => 
					<Link to={`/admin/skills-profile/${data.profileId}`} style={{ color: primaryColour }}>
						<span>{data.profileName}</span>
					</Link>
				} />
				<DataTableColumn name="description" label="Description" renderer={g => g.description || <em>There is no description provided...</em>} />
				<DataTableColumn name="unitCount" label="Number of Units" />
				<DataTableColumn 
					label="Organisations" 
					canFilter
					filterType="AutoComplete"
					loadItems={{
						route: "organisations", 
						mapItem: o => ({ label: o.name, value: o.organisationId })
					}} 
					filter={(row, f) => row.organisations.includes(f.label)}
					renderer={data => data.organisations.join(", ")} 
				/>
			</DataTable>
		</Card>
	);
};

SkillsProfiles.propTypes = {
	skillsProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

export default SkillsProfiles;