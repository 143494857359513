import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canEditUIThemes } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewTheme from './ViewTheme';
import axios from 'axios';
import { get } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';

const ViewThemeController = props => {
    const [state, setState] = React.useState({
		isLoading: false,
		theme: null
	});

    React.useEffect(() => {
		axios.all([
			get({
				url: `/api/application-themes/${props.match.params.themeId}`,
				onSuccess: data => setState(prevState => ({ ...prevState, theme: data })),
				onError: error => props.showErrorNotification(error.message)
			})
		]).then(() => setState(prevState => ({ ...prevState, isLoading: false })));
	}, [props.match.params.themeId]);

	return (
		<PageLayout 
			title="View Theme" 
			pageContent={
				<ViewTheme 
					theme={state.theme} 
					loading={state.isLoading} 
					canEdit={props.canEdit} 
				/>
			} 
			breadcrumbs={[
				{ label: "Themes", path: "/ui-themes" }, 
				"View Theme"
			]} 
		/>
	);
};

ViewThemeController.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	canEdit: canEditUIThemes(state)
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewThemeController);
