import React from 'react';
import PropTypes from 'prop-types';

const ProblemStatus = React.memo(props => {
	const styles = {
		cursor: "default",
		fontWeight: "bold",
		marginRight: "5px",
		color: "fff",
		backgroundColor: ({
			approved: "#257431",
			archived: "#2f3d4a",
			cancelled: "#d1332e",
			closed: "#2f3d4a",
			new: "#1e7994",
			pending: "#a4660e"
		})[props.status]
	};

	return <span className="label" style={styles}>{props.status}</span>;
});

ProblemStatus.propTypes = {
	status: PropTypes.oneOf(["approved", "archived", "cancelled", "closed", "new", "pending"]).isRequired
};

export default ProblemStatus;
