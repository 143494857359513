import React from 'react';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import Card from '../widgets/Card';

const Disclaimer = props => 
	<PageLayout title="Disclaimer" pageContent={
		<Card>
			<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.content }} />
		</Card>
	} breadcrumbs={["Disclaimer"]} />;

export default connect(state => ({
	content: state.settings.disclaimerContent
}), null)(Disclaimer);