import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from '../widgets/Button';
import Form from '../widgets/Form';

const form = {
	initValues: props => ({ attachments: [], ...props.credentials }),
	fieldStyle: { marginBottom: "10px" },
	fields: [
		{
			name: "skills",
			label: "Skills",
			type: "textEditor"
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Credentials/DownloadAttachment?credentialsId=${attachment.credentialsId}&attachmentId=${attachment.credentialsAttachmentId}`, "_self")
		}
	]
};

const CredentialsDialog = props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="md"
        scroll="body"
        TransitionProps={{
            onEnter: () => {
                if (props.credentials !== null) {
                    props.updateValues(props.credentials);
                } else {
                    props.resetForm();
                }
            }
        }}>
		<DialogTitle id="dialog-title">Credentials</DialogTitle>
		<DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
			<Form>
				{props.fields.skills()}
				{props.fields.attachments()}
			</Form>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => props.onOk(props.values, props.formFiles["attachments"])} className="ok-button">Ok</Button>
			<Button onClick={props.onClose} autoFocus>Cancel</Button>
		</DialogActions>
	</Dialog>;

CredentialsDialog.propTypes = {
	credentials: PropTypes.object,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired
};

CredentialsDialog.defaultProps = {
	credentials: null
};

export default withForm(form)(CredentialsDialog);