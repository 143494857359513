import { createFetchAction, createPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import DOMPurify from 'dompurify';

// Allow target=_blank in sanitized html input
DOMPurify.addHook("afterSanitizeAttributes", node => {
	if ("target" in node) {
		node.setAttribute("target", "_blank");
		node.setAttribute("rel", "noopener noreferrer");
	}
});

const tempSections = ["skills", "courses", "myCommunity", "engagement", "innovation"];

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	isLoading: false,
	single: "problem",
	plural: "problems",
	pcSingle: "Competency",
	acSingle: "Aspirational Competency",
	sections: ["home", ...tempSections],
	navbarWidgets: []
};

const CLEAR_SETTINGS = "CLEAR_SETTINGS";
const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";
const GET_SETTING_REQUEST = "GET_SETTING_REQUEST";
const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
const GET_SETTING_FAILURE = "GET_SETTING_FAILURE";
const SAVE_SETTINGS_REQUEST = "SAVE_SETTINGS_REQUEST";
const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";
const SAVE_SETTINGS_FAILURE = "SAVE_SETTINGS_FAILURE";
const RESET_SETTINGS_REQUEST = "RESET_SETTINGS_REQUEST";

export const isLoading = createLoadingSelector(["GET_SETTINGS", "GET_SETTING", "SAVE_SETTINGS", "RESET_SETTINGS"]);

export const clearSettings = () => ({ type: CLEAR_SETTINGS });

export const getSettingsToPersist = (settings) => settings;
const LOAD_PERSISTED_SETTINGS = "LOAD_PERSISTED_SETTINGS";
export const loadPersistedSettings = (settings) => ({ type: LOAD_PERSISTED_SETTINGS, settings });

export const getSettings = () =>
	createFetchAction({
		url: "/api/settings",
		startAction: GET_SETTINGS_REQUEST,
		onError: error => [getSettingsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getSettingsSuccess(data)
	});

export const fetchSettings = names =>
	createFetchAction({
		url: `/api/settings/fetch?name=${names.join("&name=")}`,
		startAction: GET_SETTINGS_REQUEST,
		onError: error => [getSettingsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getSettingsSuccess(data)
	});

export const getSettingsSuccess = data => ({ type: GET_SETTINGS_SUCCESS, payload: { data } });
export const getSettingsFailure = error => ({ type: GET_SETTINGS_FAILURE, payload: { error } });

export const getSetting = (name, fallback = "") =>
	createFetchAction({
		url: `/api/settings/get?name=${name}&fallback=${fallback}`,
		startAction: GET_SETTING_REQUEST,
		onError: error => [getSettingFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getSettingSuccess(data)
	});

export const getSettingSuccess = data => ({ type: GET_SETTING_SUCCESS, payload: { data } });
export const getSettingFailure = error => ({ type: GET_SETTING_FAILURE, payload: { error } });

export const saveSettings = (settings, files) => {
	const data = new FormData();
	data.append("Settings", encodeURIComponent(JSON.stringify(settings)));

	if (files.logo) data.append("LogoFile", files.logo);
	if (files.emailLogo) data.append("EmailLogoFile", files.emailLogo);
	if (files.loginBackground) data.append("loginBackgroundFile", files.loginBackground);
	if (files.reports) files.reports.forEach(f => data.append("ReportImageFiles", f.image));

	return createPostAction({
		url: "/api/settings",
		data,
		startAction: SAVE_SETTINGS_REQUEST,
		onError: error => [saveSettingsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveSettingsSuccess(data), showSuccessNotification(data.message), getSettings()];
			} else {
				return [saveSettingsSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveSettingsSuccess = data => ({ type: SAVE_SETTINGS_SUCCESS, data });
export const saveSettingsFailure = error => ({ type: SAVE_SETTINGS_FAILURE, error });

// Lookup table for any required parsing
const receiveSwitch = {
	siteLinks: value => JSON.parse(value),
	showPageBanner: value => value === "true",
	hiddenUsers: value => value ? value.split(";") : [],
	showSubmitProblem: value => value === "true",
	showSearchProblems: value => value === "true",
	showSearchTools: value => value === "true",
	homePageLayout: value => JSON.parse(value),
	reports: value => JSON.parse(value),
	showTenantTitle: value => value === "true",
	notificationUserRegistration: value => value === "true",
	notificationProblemRegistration: value => value === "true",
	notificationProblemVoteAlert: value => value === "true",
	notificationOfferCreated: value => value === "true",
	notificationOfferClosed: value => value === "true",
	notificationCompetenciesSaved: value => value === "true",
	navbarWidgets: value => JSON.parse(value)
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_PERSISTED_SETTINGS:
			return {
				...state,
				...action.settings
			};
		case CLEAR_SETTINGS:
			return initialState;
		case GET_SETTINGS_REQUEST:
			return { ...state, isLoading: true };
		case GET_SETTINGS_SUCCESS:
			const settings = mapValues(keyBy(action.payload.data, "name"), s => {
				if (s.name === "emailSignature" || s.name.endsWith("Content")) return DOMPurify.sanitize(s.value);

				return receiveSwitch[s.name] ? receiveSwitch[s.name](s.value) : s.value;
			});

			return { 
				...state, 
				...settings, 
				single: settings.problemOverrideSingle || "problem",
				plural: settings.problemOverridePlural || "problems",
				pcSingle: settings.primaryCompetencySingle || "Competency",
				acSingle: settings.aspirationalCompetencySingle || "Aspirational Competency",
				isLoading: false 
			};
		case GET_SETTING_REQUEST:
			return { ...state, isLoading: true };
		case GET_SETTING_SUCCESS:
			const { name, value } = action.payload.data;
			
			return { 
				...state, 
				isLoading: false, 
				[name]: receiveSwitch[name] ? receiveSwitch[name](value) : value 
			};
		case RESET_SETTINGS_REQUEST:
		case SAVE_SETTINGS_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_SETTINGS_SUCCESS:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		default:
			return state;
	}
};
