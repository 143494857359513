import React from 'react';
import ErrorPageLayout from './components/ErrorPageLayout';
import ButtonLink from './components/widgets/ButtonLink';

const NotFound = () => 
	<ErrorPageLayout
		headerContent={<div className="text-black text-5xl md-text-15xl font-black">404</div>}
		bodyContent={
			<React.Fragment>
				<p className="text-grey-darker text-2xl md-text-3xl font-light mb-8 leading-normal">
					Sorry, the page you are looking for could not be found.                    
				</p>
				<ButtonLink to="/" className="bg-transparent text-grey-darkest font-bold uppercase tracking-wide py-3 px-6 border-2 border-grey-light hover:border-grey rounded-lg">
					Go Home
				</ButtonLink>
			</React.Fragment>
		}
	/>;

export default NotFound;
