import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import { changeLocation } from './location';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	user: {}, 
	users: [],
	views: [],
	endorsements: [],
	logs: [],
	skills: {},
	credentials: {}
};

const CLEAR_USER = "CLEAR_USER";
const CLEAR_USERS = "CLEAR_USERS";
const GET_USER_REQUEST = "GET_USER_REQUEST";
const GET_USER_SUCCESS = "GET_USER_SUCCESS";
const GET_USER_FAILURE = "GET_USER_FAILURE";
const GET_USERS_REQUEST = "GET_USERS_REQUEST";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const GET_USERS_FAILURE = "GET_USERS_FAILURE";
const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";
const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
const DEACTIVATE_USER_REQUEST = "DEACTIVATE_USER_REQUEST";
const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
const DEACTIVATE_USER_FAILURE = "DEACTIVATE_USER_FAILURE";
const REACTIVATE_USER_REQUEST = "REACTIVATE_USER_REQUEST";
const REACTIVATE_USER_SUCCESS = "REACTIVATE_USER_SUCCESS";
const REACTIVATE_USER_FAILURE = "REACTIVATE_USER_FAILURE";
const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
const GET_ENDORSEMENTS_REQUEST = "GET_ENDORSEMENTS_REQUEST";
const GET_ENDORSEMENTS_SUCCESS = "GET_ENDORSEMENTS_SUCCESS";
const GET_ENDORSEMENTS_FAILURE = "GET_ENDORSEMENTS_FAILURE";
const GET_VIEWS_REQUEST = "GET_VIEWS_REQUEST";
const GET_VIEWS_SUCCESS = "GET_VIEWS_SUCCESS";
const GET_VIEWS_FAILURE = "GET_VIEWS_FAILURE";
const GET_SKILLS_REQUEST = "GET_SKILLS_REQUEST";
const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS";
const GET_SKILLS_FAILURE = "GET_SKILLS_FAILURE";
const SAVE_SKILLS_REQUEST = "SAVE_SKILLS_REQUEST";
const SAVE_SKILLS_SUCCESS = "SAVE_SKILLS_SUCCESS";
const SAVE_SKILLS_FAILURE = "SAVE_SKILLS_FAILURE";
const GET_CREDENTIALS_REQUEST = "GET_CREDENTIALS_REQUEST";
const GET_CREDENTIALS_SUCCESS = "GET_CREDENTIALS_SUCCESS";
const GET_CREDENTIALS_FAILURE = "GET_CREDENTIALS_FAILURE";
const SAVE_CREDENTIALS_REQUEST = "SAVE_CREDENTIALS_REQUEST";
const SAVE_CREDENTIALS_SUCCESS = "SAVE_CREDENTIALS_SUCCESS";
const SAVE_CREDENTIALS_FAILURE = "SAVE_CREDENTIALS_FAILURE";

export const isLoading = createLoadingSelector([
	"GET_USERS", 
	"GET_USER", 
	"SAVE_USER", 
	"DELETE_USER",
	"DEACTIVATE_USER",
	"REACTIVATE_USER", 
	"CHANGE_PASSWORD",
	"GET_ENDORSEMENTS", 
	"GET_VIEWS"
]);

export const clearUsers = () => ({ type: CLEAR_USERS });
export const clearUser = () => ({ type: CLEAR_USER });

export const getUsers = () =>
	createFetchAction({
		url: "/api/users",
		startAction: GET_USERS_REQUEST,
		onError: error => [getUsersFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUsersSuccess(data)
	});

export const getDepartmentUsers = (departmentId, onSuccess, onError) =>
	createFetchAction({
		url: `/api/users/by-department/${departmentId}`,
		startAction: GET_USERS_REQUEST,
		onError: error => {
			if (onError) onError(error);
			return [getUsersFailure(error), showErrorNotification(error.message)]
		},
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getUsersSuccess(data);
		}
	});

export const getTeamSkillsUsers = (onSuccess, onError) =>
	createFetchAction({
		url: `/api/users/team-skills-users`,
		startAction: GET_USERS_REQUEST,
		onError: error => {
			if (onError) onError(error);
			return [getUsersFailure(error), showErrorNotification(error.message)]
		},
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			return getUsersSuccess(data);
		}
	});

export const getUsersSuccess = data => ({ type: GET_USERS_SUCCESS, payload: { data } });
export const getUsersFailure = error => ({ type: GET_USERS_FAILURE, payload: { error } });

export const getUser = userId => 
	createFetchAction({
		url: `/api/users/${userId}`,
		startAction: GET_USER_REQUEST,
		onError: error => [getUserFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserSuccess(data)
	});

export const getUserInfo = userId =>
	createFetchAction({
		url: `/api/users/${userId}/info`,
		startAction: GET_USER_REQUEST,
		onError: error => [getUserFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getUserSuccess(data)
	});

export const getUserSuccess = data => ({ type: GET_USER_SUCCESS, payload: { data } });
export const getUserFailure = error => ({ type: GET_USER_FAILURE, payload: { error } });

export const saveUser = (user, avatarFile) => {
	const errors = [];

	if (!user.email) {
		errors.push({
			field: "email",
			valid: false,
			message: " Email is required"
		});
	}

	if (errors.length > 0) return { type: SAVE_USER_SUCCESS, data: errors };

	const formData = new FormData();
	formData.append("User", encodeURIComponent(JSON.stringify(user)));
	if (avatarFile) formData.append("AvatarFile", avatarFile);

	return createFormPostAction({
		url: "/api/users",
		data: formData,
		startAction: SAVE_USER_REQUEST,
		onError: error => [saveUserFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveUserSuccess(data), showSuccessNotification(data.message), changeLocation(`/users/${data.object.userId}`)];
			} else {
				return [saveUserSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveUserSuccess = data => ({ type: SAVE_USER_SUCCESS, data });
export const saveUserFailure = error => ({ type: SAVE_USER_FAILURE, error });

export const deleteUser = userId =>
	createPostAction({
		url: `/api/users/${userId}/delete`,
		startAction: DELETE_USER_REQUEST,
		onError: error => [deleteUserFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteUserSuccess(data), showSuccessNotification(data.message), changeLocation("/users")];
			} else {
				return [deleteUserSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteUserSuccess = data => ({ type: DELETE_USER_SUCCESS, data });
export const deleteUserFailure = error => ({ type: DELETE_USER_FAILURE, error });

export const deactivateUser = userId =>
	createPostAction({
		url: `/api/users/${userId}/deactivate`,
		startAction: DEACTIVATE_USER_REQUEST,
		onError: error => [deactivateUserFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deactivateUserSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [deactivateUserSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deactivateUserSuccess = data => ({ type: DEACTIVATE_USER_SUCCESS, data });
export const deactivateUserFailure = error => ({ type: DEACTIVATE_USER_FAILURE, error });

export const reactivateUser = userId =>
	createPostAction({
		url: `/api/users/${userId}/reactivate`,
		startAction: REACTIVATE_USER_REQUEST,
		onError: error => [reactivateUserFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [reactivateUserSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [reactivateUserSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const reactivateUserSuccess = data => ({ type: REACTIVATE_USER_SUCCESS, data });
export const reactivateUserFailure = error => ({ type: REACTIVATE_USER_FAILURE, error });

export const changePassword = (userId, newPassword) =>
	createPostAction({
		url: `api/users/${userId}/reset-password`,
		data: { newPassword },
		startAction: CHANGE_PASSWORD_REQUEST,
		onError: error => [showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [changePasswordSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [changePasswordFailure(data), showErrorNotification(data.message)];
			}
		}
	});

export const changePasswordSuccess = data => ({ type: CHANGE_PASSWORD_SUCCESS, data });
export const changePasswordFailure = error => ({ type: CHANGE_PASSWORD_FAILURE, error });

export const getEndorsements = problemId =>
	createFetchAction({
		url: `/api/users/${problemId}/endorsements`,
		startAction: GET_ENDORSEMENTS_REQUEST,
		onError: error => [getEndorsementsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getEndorsementsSuccess(data)
	});

export const getEndorsementsSuccess = data => ({ type: GET_ENDORSEMENTS_SUCCESS, payload: { data } });
export const getEndorsementsFailure = error => ({ type: GET_ENDORSEMENTS_FAILURE, payload: { error } });

export const getViews = problemId =>
	createFetchAction({
		url: `/api/users/${problemId}/views`,
		startAction: GET_VIEWS_REQUEST,
		onError: error => [getViewsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getViewsSuccess(data)
	});

export const getViewsSuccess = data => ({ type: GET_VIEWS_SUCCESS, payload: { data } });
export const getViewsFailure = error => ({ type: GET_VIEWS_FAILURE, payload: { error } });

export const getSkills = onSuccess =>
	createFetchAction({
		url: "/api/users/skills",
		startAction: GET_SKILLS_REQUEST,
		onError: error => [getSkillsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (onSuccess) onSuccess(data);
			getSkillsSuccess(data);
		}
	});

export const getSkillsSuccess = data => ({ type: GET_SKILLS_SUCCESS, payload: { data } });
export const getSkillsFailure = error => ({ type: GET_SKILLS_FAILURE, payload: { error } });

export const saveSkills = skills =>
	createFormPostAction({
		url: "/api/users/save-skills",
		data: skills,
		startAction: SAVE_SKILLS_REQUEST,
		onError: error => [saveSkillsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => [saveSkillsSuccess(data), showSuccessNotification(data.message)]
	});

export const saveSkillsSuccess = data => ({ type: SAVE_SKILLS_SUCCESS, payload: { data } });
export const saveSkillsFailure = error => ({ type: SAVE_SKILLS_FAILURE, payload: { error } });

export const getCredentials = () =>
	createFetchAction({
		url: "/api/users/credentials",
		startAction: GET_CREDENTIALS_REQUEST,
		onError: error => [getCredentialsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCredentialsSuccess(data)
	});

export const getCredentialsSuccess = data => ({ type: GET_CREDENTIALS_SUCCESS, payload: { data } });
export const getCredentialsFailure = error => ({ type: GET_CREDENTIALS_FAILURE, payload: { error } });

export const saveCredentials = (credentials, newFiles) => {
	const formData = new FormData();
	formData.append("Credentials", JSON.stringify(credentials));
	forEach(newFiles, f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/users/save-credentials",
		data: formData,
		startAction: SAVE_CREDENTIALS_REQUEST,
		onError: error => [saveCredentialsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveCredentialsSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [saveCredentialsSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveCredentialsSuccess = data => ({ type: SAVE_CREDENTIALS_SUCCESS, data });
export const saveCredentialsFailure = error => ({ type: SAVE_CREDENTIALS_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_USERS:
		case GET_USERS_REQUEST:
			return { ...state, users: [] };
		case CLEAR_USER:
			return { ...state, user: {} };
		case GET_USERS_SUCCESS:
			return { ...state, isLoading: false, users: action.payload.data };
		case GET_USER_REQUEST:
			return { 
				...state, 
				isLoading: true, 
				saveResult: emptySaveResult, 
				user: {} 
			};
		case GET_USER_SUCCESS:
			return { ...state, isLoading: false, user: action.payload.data };
		case DELETE_USER_SUCCESS:
			return { ...state, users: filter(state.users, c => c.userId !== action.data.objectId) };
		case SAVE_USER_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: emptySaveResult,
				message: null
			};
		case SAVE_USER_SUCCESS:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				users: addOrUpdate(state.users, action.data.object, { userId: action.data.object.userId }), 
				user: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case DEACTIVATE_USER_SUCCESS:
			return { ...state, user: { ...state.user, isActive: false } };
		case REACTIVATE_USER_SUCCESS:
			return { ...state, user: { ...state.user, isActive: true } };
		case GET_ENDORSEMENTS_REQUEST:
			return { ...state, endorsements: [] };
		case GET_ENDORSEMENTS_SUCCESS:
			return { ...state, isLoading: false, endorsements: action.payload.data };
		case GET_VIEWS_REQUEST:
			return { ...state, views: [] };
		case GET_VIEWS_SUCCESS:
			return { ...state, isLoading: false, views: action.payload.data };
		case GET_USER_FAILURE:
		case GET_USERS_FAILURE:
		case SAVE_USER_FAILURE:
		case DELETE_USER_FAILURE:
		case GET_ENDORSEMENTS_FAILURE:
		case GET_VIEWS_FAILURE:
		case SAVE_SKILLS_FAILURE:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: "",
					fields: action.data
				}
			};
		case GET_SKILLS_REQUEST:
			return { ...state, isLoading: true, skills: {} };
		case GET_SKILLS_SUCCESS:
			return { ...state, isLoading: false, skills: action.payload.data };
		case CHANGE_PASSWORD_REQUEST:
		case SAVE_SKILLS_REQUEST:
		case SAVE_CREDENTIALS_REQUEST:
			return { ...state, isLoading: true };
		case CHANGE_PASSWORD_SUCCESS:
		case CHANGE_PASSWORD_FAILURE:
		case SAVE_SKILLS_SUCCESS:
		case GET_SKILLS_FAILURE:
		case GET_CREDENTIALS_FAILURE:
			return { ...state, isLoading: false };
		case GET_CREDENTIALS_REQUEST:
			return { ...state, isLoading: true, credentials: {} };
		case GET_CREDENTIALS_SUCCESS:
			return { ...state, isLoading: false, credentials: action.payload.data || {} };
		case SAVE_CREDENTIALS_SUCCESS:
			return {
				...state,
				...(action.data.success && { credentials: action.data.object }),
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message
				}
			};
		default:
			return state;
	}
};
