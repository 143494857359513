import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { addOrUpdate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from '../store/notifications';
import { changeLocation } from './location';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	tenant: {}, 
	tenants: []
};

const newTenant = {
	_isNew: true,
	active: true,
	tenantId: 0,
	name: "",
	title: ""
};

const CREATE_NEW_TENANT = "CREATE_NEW_TENANT";
const CLEAR_TENANT = "CLEAR_TENANT";
const CLEAR_TENANTS = "CLEAR_TENANTS";
const GET_TENANT_REQUEST = "GET_TENANT_REQUEST";
const GET_TENANT_SUCCESS = "GET_TENANT_SUCCESS";
const GET_TENANT_FAILURE = "GET_TENANT_FAILURE";
const GET_TENANTS_REQUEST = "GET_TENANTS_REQUEST";
const GET_TENANTS_SUCCESS = "GET_TENANTS_SUCCESS";
const GET_TENANTS_FAILURE = "GET_TENANTS_FAILURE";
const SAVE_TENANT_REQUEST = "SAVE_TENANT_REQUEST";
const SAVE_TENANT_SUCCESS = "SAVE_TENANT_SUCCESS";
const SAVE_TENANT_FAILURE = "SAVE_TENANT_FAILURE";
const DELETE_TENANT_REQUEST = "DELETE_TENANT_REQUEST";
const DELETE_TENANT_SUCCESS = "DELETE_TENANT_SUCCESS";
const DELETE_TENANT_FAILURE = "DELETE_TENANT_FAILURE";

export const isLoading = createLoadingSelector(["GET_TENANTS", "GET_TENANT", "DELETE_TENANT", "SAVE_TENANT"]);

export const createNewTenant = () => ({ type: CREATE_NEW_TENANT });
export const clearTenants = () => ({ type: CLEAR_TENANTS });
export const clearTenant = () => ({ type: CLEAR_TENANT });

export const getTenants = () =>
	createFetchAction({
		url: "/api/tenants",
		startAction: GET_TENANTS_REQUEST,
		onError: error => [getTenantsFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getTenantsSuccess(data)
	});

export const getTenantsSuccess = data => ({ type: GET_TENANTS_SUCCESS, payload: { data } });
export const getTenantsFailure = error => ({ type: GET_TENANTS_FAILURE, payload: { error } });

export const getTenant = tenantId => 
	createFetchAction({
		url: `/api/tenants/${tenantId}`,
		startAction: GET_TENANT_REQUEST,
		onError: error => [getTenantFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getTenantSuccess(data)
	});

export const getTenantSuccess = data => ({ type: GET_TENANT_SUCCESS, payload: { data } });
export const getTenantFailure = error => ({ type: GET_TENANT_FAILURE, payload: { error } });

export const saveTenant = (tenant, logoFile, backgroundImageFile, faviconFile) => {
	const errors = [];

	if (!tenant.name) {
		errors.push({
			field: "name",
			valid: false,
			message: "Name is required"
		});
	}
	if (!tenant.title) {
		errors.push({
			field: "title",
			valid: false,
			message: "Title is required"
		});
	}
	
	if (errors.length > 0) return { type: SAVE_TENANT_SUCCESS, data: errors };

	const formData = new FormData();
	formData.append('Tenant', encodeURIComponent(JSON.stringify(tenant)));
	if (logoFile) formData.append('LogoFile', logoFile);
	if (backgroundImageFile) formData.append('BackgroundImageFile', backgroundImageFile);
	if (faviconFile) formData.append('FaviconFile', faviconFile);

	return createFormPostAction({
		url: "/api/tenants",
		data: formData,
		startAction: SAVE_TENANT_REQUEST,
		onError: error => [saveTenantFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveTenantSuccess(data), showSuccessNotification(data.message), changeLocation(`/admin/tenants/${data.object.tenantId}`)];
			} else {
				return [saveTenantSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveTenantSuccess = data => ({ type: SAVE_TENANT_SUCCESS, data });
export const saveTenantFailure = error => ({ type: SAVE_TENANT_FAILURE, error });

export const deleteTenant = tenant =>
	createPostAction({
		url: `/api/tenants/${tenant.tenantId}/delete`,
		data: tenant,
		startAction: DELETE_TENANT_REQUEST,
		onError: error => [deleteTenantFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteTenantSuccess(data), showSuccessNotification(data.message), changeLocation(`/admin/tenants`)];
			} else {
				return [deleteTenantSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteTenantSuccess = data => ({ type: DELETE_TENANT_SUCCESS, data });
export const deleteTenantFailure = error => ({ type: DELETE_TENANT_FAILURE, error });

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_TENANTS:
			return { ...state, tenants: [] };
		case CLEAR_TENANT:
			return { ...state, tenant: {} };
		case CREATE_NEW_TENANT:
			return { ...state, tenant: cloneDeep(newTenant) };
		case GET_TENANTS_REQUEST:
			return { ...state, saveResult: emptySaveResult, tenants: [] };
		case GET_TENANTS_SUCCESS:
			return { ...state, isLoading: false, tenants: action.payload.data };
		case GET_TENANT_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: emptySaveResult,
				tenant: { }
			};
		case GET_TENANT_SUCCESS:
			return { ...state, isLoading: false, tenant: action.payload.data };
		case DELETE_TENANT_SUCCESS:
			return { ...state, tenants: filter(state.tenants, c => c.tenantId !== action.data.objectId) };
		case SAVE_TENANT_REQUEST:
			return {
				...state,
				isLoading: true,
				saveResult: emptySaveResult,
				message: null
			};
		case SAVE_TENANT_SUCCESS:
			if (!action.data.success) {
				return {
					...state,
					isLoading: false,
					saveResult: {
						success: action.data.success,
						message: action.data.message,
						fields: action.data.fields
					} 
				};
			}
			
			return {
				...state,
				tenants: addOrUpdate(state.tenants, action.data.object, { tenantId: action.data.object.tenantId }), 
				tenant: action.data.object,
				isLoading: false,
				saveResult: {
					success: action.data.success,
					message: action.data.message,
					fields: action.data.fields
				}
			};
		case GET_TENANT_FAILURE:
		case GET_TENANTS_FAILURE:
		case SAVE_TENANT_FAILURE:
		case DELETE_TENANT_FAILURE:
			return {
				...state,
				isLoading: false,
				saveResult: {
					success: false,
					message: "",
					fields: action.data
				}
			};
		default:
			return state;
	}
};
