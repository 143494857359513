import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	CircularProgress,
    IconButton
} from '@mui/material';
import {
    Delete as DeleteIcon
} from '@mui/icons-material';

const getTitleHeaderStyles = (primaryColour) => ({
	position: "absolute",
	top: 0,
	paddingLeft: "20px",
	paddingTop: "20px",
	width: "100%",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	paddingRight: "10px",
	color: primaryColour
});

const WidgetLoading = () =>
	<div style={{
		height: "100%",
		marginTop: "-10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}}>
		<CircularProgress />
	</div>;

const Dashboardwidget = props => {
	const [loading, setLoading] = React.useState(false);
	const WrappedWidget = props.widget;

	const headerStyles = {
		...getTitleHeaderStyles(props.primaryColour),
		...props.headerStyles
	};

	switch (props.type) {
		case 'WEF':
			return (
				<React.Fragment>
					<h2 style={headerStyles}>{props.title}</h2>
					{loading && <WidgetLoading />}
					<div style={{ display: loading? 'none' : 'block', height: '100%' }}>
						<intelligence-widget
							apikey="35e42c22-f047-415e-b879-fa0e95300b7d"
							topicid={props.topicId}
							layout="wide"
							openinnewwindow="true"
						>
						</intelligence-widget>
						{/* <iframe
							title="wef"
							src={`https://cdn.intelligence.weforum.org/iframe.html?topicid=${props.topicId}&apikey=35e42c22-f047-415e-b879-fa0e95300b7d&openinnewwindow=true`}
							style={{ border: "0px solid white", minHeight: '500px' }}
							name="intelligenceWidgetIframe"
							scrolling="no"
							frameBorder="0px"
							marginHeight="0px"
							marginWidth="0px"
							width="100%"
						></iframe> */}
					</div>
				</React.Fragment>
			);
		default:
			return (
                <React.Fragment>
					<h2 style={headerStyles}>{props.title}</h2>
					{loading && <WidgetLoading />}
					<div style={{ display: loading? 'none' : 'block', height: '100%' }}>
						<WrappedWidget 
                            loading={loading} 
                            setLoading={setLoading}
							colourPalette={props.colourPalette} 
                            {...props} 
                        />
					</div>
					{props.onDelete && 
						<IconButton
                            style={{
								position: 'absolute',
								top: '5px',
								right: '5px'
							}}
                            onClick={props.onDelete}
                            size="large">
							<DeleteIcon />
						</IconButton>
					}
				</React.Fragment>
            );
	}
};

Dashboardwidget.propTypes = {
	type: PropTypes.string,
	onDelete: PropTypes.func
};

Dashboardwidget.defaultProps = {
	type: null,
	onDelete: null
};

const mapStateToProps = state => ({
	primaryColour: state.theme.primaryColour,
	colourPalette: state.theme.colourPalette
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboardwidget);
