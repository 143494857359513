import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	activities: [],
	activity: {}
};

const CLEAR_ACTIVITIES = "CLEAR_ACTIVITIES";
const GET_ACTIVITIES_REQUEST = "GET_ACTIVITIES_REQUEST";
const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
const GET_ACTIVITIES_FAILURE = "GET_ACTIVITIES_FAILURE";
const SEARCH_ACTIVITIES_REQUEST = "SEARCH_ACTIVITIES_REQUEST";
const SEARCH_ACTIVITIES_SUCCESS = "SEARCH_ACTIVITIES_SUCCESS";
const SEARCH_ACTIVITIES_FAILURE = "SEARCH_ACTIVITIES_FAILURE";
const GET_ACTIVITY_REQUEST = "GET_ACTIVITY_REQUEST";
const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
const GET_ACTIVITY_FAILURE = "GET_ACTIVITY_FAILURE";
const SAVE_ACTIVITY_REQUEST = "SAVE_ACTIVITY_REQUEST";
const SAVE_ACTIVITY_SUCCESS = "SAVE_ACTIVITY_SUCCESS";
const SAVE_ACTIVITY_FAILURE = "SAVE_ACTIVITY_FAILURE";
const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";

export const isLoading = createLoadingSelector(["GET_ACTIVITIES", "SEARCH_ACTIVITIES", "GET_ACTIVITY"]);

export const getActivities = () =>
	createFetchAction({
		url: "/api/activities",
		startAction: GET_ACTIVITIES_REQUEST,
		onError: error => [getActivitiesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getActivitiesSuccess(data)
	});

export const getActivitiesSuccess = data => ({ type: GET_ACTIVITIES_SUCCESS, payload: { data } });
export const getActivitiesFailure = error => ({ type: GET_ACTIVITIES_FAILURE, payload: { error } });

export const searchActivities = args => 
	createFetchAction({
		url: `/api/activities/search?${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_ACTIVITIES_REQUEST,
		onError: error => [searchActivitiesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchActivitiesSuccess(data)
	});

export const searchActivitiesSuccess = data => ({ type: SEARCH_ACTIVITIES_SUCCESS, payload: { data } });
export const searchActivitiesFailure = error => ({ type: SEARCH_ACTIVITIES_FAILURE, payload: { error } });

export const getActivity = activityId =>
	createFetchAction({
		url: `/api/activities/${activityId}`,
		startAction: GET_ACTIVITY_REQUEST,
		onError: error => [getActivityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getActivitySuccess(data)
	});

export const getActivitySuccess = data => ({ type: GET_ACTIVITY_SUCCESS, payload: { data } });
export const getActivityFailure = error => ({ type: GET_ACTIVITY_FAILURE, payload: { error } });

export const saveActivity = (activity, newFiles) => {
	const formData = new FormData();
	formData.append("Activity", encodeURIComponent(JSON.stringify({
		...activity,
		materialsNeeded: JSON.stringify(activity.materialsNeeded),
		attachments: [...activity.images, ...activity.files],
		videos: JSON.stringify(activity.videos),
		links: JSON.stringify(activity.links)
	})));

	forEach(newFiles, f => formData.append("NewFiles", f));

	return createFormPostAction({
		url: "/api/activities",
		data: formData,
		startAction: SAVE_ACTIVITY_REQUEST,
		onError: error => [saveActivityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveActivitySuccess(data), showSuccessNotification(data.message), changeLocation(`/activities/${data.object.activityId}`)];
			} else {
				return [saveActivitySuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveActivitySuccess = data => ({ type: SAVE_ACTIVITY_SUCCESS, data });
export const saveActivityFailure = error => ({ type: SAVE_ACTIVITY_FAILURE, error });

export const deleteActivity = activityId =>
	createPostAction({
		url: `/api/activities/${activityId}/delete`,
		startAction: DELETE_ACTIVITY_REQUEST,
		onError: error => [deleteActivityFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteActivitySuccess(data), showSuccessNotification(data.message), changeLocation(`/activities`)];
			} else {
				return [deleteActivitySuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteActivitySuccess = data => ({ type: DELETE_ACTIVITY_SUCCESS, data });
export const deleteActivityFailure = error => ({ type: DELETE_ACTIVITY_FAILURE, error });

const formatActivity = activity => ({ 
	...activity,
	materialsNeeded: JSON.parse(activity.materialsNeeded),
	images: activity.attachments ? activity.attachments.filter(a => a.attachmentType === "image") : [],
	files: activity.attachments ? activity.attachments.filter(a => a.attachmentType !== "image") : [],
	videos: activity.videos ? JSON.parse(activity.videos) : [], 
	links: activity.links ? JSON.parse(activity.links) : [],
	description: DOMPurify.sanitize(activity.description) 
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ACTIVITIES:
			return { ...state, activities: [] };
		case GET_ACTIVITIES_REQUEST:
			return { ...state, activities: [], isLoading: true };
		case SEARCH_ACTIVITIES_REQUEST:
			return { ...state, isLoading: true };
		case GET_ACTIVITIES_SUCCESS:
		case SEARCH_ACTIVITIES_SUCCESS:
			return { ...state, activities: map(action.payload.data, formatActivity), isLoading: false };
		case GET_ACTIVITY_REQUEST:
			return { ...state, activity: {}, isLoading: true };
		case GET_ACTIVITY_SUCCESS:
			return { ...state, activity: formatActivity(action.payload.data), isLoading: true };
		case SAVE_ACTIVITY_REQUEST:
		case DELETE_ACTIVITY_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_ACTIVITY_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { activity: formatActivity(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_ACTIVITY_SUCCESS:
			return { ...state, activities: state.activities.filter(a => a.activityId !== action.data.objectId) };
		default:
			return state;
	}
};