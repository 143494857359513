import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import StatBox from '../widgets/StatBox';
import SearchField from '../widgets/SearchField';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import Checkbox from '../widgets/Checkbox';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

const statusLookup = {
	Closed: "warning",
	Declined: "danger",
	Pending: "info",
	"Project Proposal": "megna",
	Showcase: "primary",
	Draft: "inverse"
}

class AllApplications extends React.Component {
	state = {
		search: "",
		filterPending: false
	}

	render() {
		const { offer, vendor, applications, isOwn, loading } = this.props,
			{ search, filterPending } = this.state;

		return (
			<Card 
				title={vendor ? `${isOwn ? "My" : vendor.company || "Vendor"} Applications` : 
					`Research Match Applications${isEmpty(offer) ? "" : `: ${offer.title}`}`
				}
				subtitle="View applications submitted by vendors for research match"
				loading={loading}
			>
				{/* Stats */}
				<div className="row-flex m-t-40">
					<StatBox text="Applications Submitted" number={applications.length} />
					<StatBox
						text="Showcases"
						number={reduce(applications, (sum, a) => sum + (a.status === "Showcase"), 0)}
						colour="#0091b4"
					/>
					<StatBox
						text="Project Proposals"
						number={reduce(applications, (sum, a) => sum + (a.status === "Project Proposal"), 0)}
						colour="#7460ee"
					/>
				</div>
				{/* Search Applications */}
				<SearchField 
					label="Search applications" 
					value={search} 
					onChange={search => this.setState({ search })} 
					loading={loading}
					addPadding
				/>
				{/* Show Pending Checkbox */}
				<Checkbox 
					checked={filterPending} 
					label="Show Pending Only" 
					onChange={() => this.setState({ filterPending: !filterPending })} 
					style={{ top: 8, left: 20 }} 
				/>
				{/* Datatable */}
				<DataTable data={filterPending ? filter(applications, a => a.status === "Pending") : applications} search={search} enableExport>
					<DataTableColumn name="fullName" label="Vendor" renderer={a =>
						<Link to={`/research-match/applications/${a.applicationId}`}>{a.fullName}</Link>
					} hidden={Boolean(vendor)} />
					<DataTableColumn name="offerTitle" label="Offer" renderer={a =>
						<Link to={`/research-match/applications/${a.applicationId}`}>{a.offerTitle}</Link>
					} width={600} hidden={Boolean(offer)} />
					<DataTableColumn name="stage" label="Stage" />
					<DataTableColumn name="applicationDate" label="Application Date" dataType="date" />
					<DataTableColumn name="status" label="Status" renderer={a =>
						<span className={`label label-light-${statusLookup[a.status]}`}>{a.status}</span>
					} />
				</DataTable>
			</Card>
		);
	}
};

AllApplications.propTypes = {
	offer: PropTypes.object,
	vendor: PropTypes.object,
	applications: PropTypes.arrayOf(PropTypes.object),
	isOwn: PropTypes.bool,
	loading: PropTypes.bool.isRequired
};

AllApplications.defaultProps = {
	offer: null,
	applications: [],
	vendor: null,
	isOwn: false
}

export default AllApplications;
