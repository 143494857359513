import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import CareerPathway from './CareerPathway';

const CareerPathwayController = props => {
	const [loading, setLoading] = React.useState(false);

	return (
		<PageLayout 
			title="Career Pathway" 
			loading={loading}
			pageContent={
				<CareerPathway 
					loading={loading}
					setLoading={l => setLoading(l)}
					defaultOccupation={props.position}
					colourPalette={props.colourPalette}
				/> 
			}
			breadcrumbs={["Career Pathway"]} 
		/>
	);
};

CareerPathwayController.propTypes = { 
	position: PropTypes.string,
	colourPalette: PropTypes.array.isRequired
};

CareerPathwayController.defaultProps = { 
	position: ''
};


const mapStateToDispatch = state => ({
	position: state.context.position,
	colourPalette: state.theme.colourPalette
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToDispatch, mapDispatchToProps)(CareerPathwayController);
