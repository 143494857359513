import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { get } from '../../utils/ajax';
import { showErrorNotification } from '../../store/notifications';

const DashboardWrapper = props => {
	const [loading, setLoading] = React.useState(false);
	const [dashboard, setDashboard] = React.useState(null);

	React.useEffect(() => {
		setLoading(true);
		get({
			url: `/api/dashboard`,
			onSuccess: (data) => {
				setLoading(false);
				setDashboard({
					layout: JSON.parse(data.layout),
					widgets: JSON.parse(data.widgets)
				});
			},
			onError: (error) => {
				setLoading(false);
				setDashboard({
					layout: null,
					widgets: null
				});
				props.showErrorNotification(error.message);
			}
		})
	}, []);

	return (
		<Dashboard 
			admin={false}
			dashboard={dashboard}
			loading={loading}
			organisationId={props.organisationId}
			userId={props.userId}
			pcSingle={props.pcSingle}
			acSingle={props.acSingle}
		/> 
	);
};

DashboardWrapper.propTypes = { 
	showErrorNotification: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	organisationId: PropTypes.number.isRequired,
	pcSingle: PropTypes.string.isRequired,
	acSingle: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	userId: state.context.userId,
	organisationId: state.context.organisationId,
	pcSingle: state.settings.pcSingle,
	acSingle: state.settings.acSingle
});

const mapDispatchToProps = dispatch => ({
	showErrorNotification: (message) => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper);
