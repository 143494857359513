import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { 
	canSubmitBusinessProfiles, 
	canAdminBusinessProfile, 
	hasFunction 
} from '../../store/security';
import { 
	searchBusinessProfiles, 
	rateBusinessProfile,
	isLoading 
} from '../../store/businessProfiles';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import ButtonLink from '../widgets/ButtonLink';
import BusinessProfileCard from './BusinessProfileCard';
import map from 'lodash/map';
import pick from 'lodash/pick';
import filter from 'lodash/filter';
import Card from '../widgets/Card';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchBusinessProfiles(values), 500);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "category",
			label: "Filter by Category",
			type: "autoComplete",
			loadItems: { route: "categories", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "order",
			label: "Order By",
			type: "select",
			defaultValue: "Rating",
			items: ["Rating", "Title"]
		}
	]
}

const BusinessProfilesController = props => {
	let { profiles } = props;

	profiles = filter(profiles, p => {
		// Dont show profiles if not filled out unless admin
		return props.canAdmin || p.summary;
	});

	React.useEffect(() => {
		props.searchBusinessProfiles(props.searchArgs);
	}, []);

	const onChangeRating = (profile, rating) => {
		props.rateBusinessProfile(profile.businessProfileId, rating);
	};
	
	return <PageLayout title="Business Profiles" pageContent={
		<React.Fragment>
				<div style={{ textAlign: "right" }}>
					{props.canEdit &&
						<ButtonLink to="/business-profiles/edit">Edit profile</ButtonLink>
					}
					{props.canSubmit &&
						<ButtonLink to="/business-profiles/create" style={{ marginLeft: '10px' }}>Create profile</ButtonLink>
					}
				</div>
			<Card style={{ margin: "20px 0", zIndex: 10, minHeight: 0 }}>
				<Grid container spacing={3} alignItems="flex-end">
					<Grid item xs={12} sm={12}>{props.fields.search()}</Grid>
					<Grid item xs={12} sm={6}>{props.fields.category()}</Grid>
					<Grid item xs={12} sm={6}>{props.fields.order()}</Grid>
				</Grid>
			</Card>
			{profiles.length === 0 ? <p style={{ marginTop: 10 }}>No profiles found...</p> :
				<Grid container spacing={3}>
					{map(profiles, (c, i) => 
						<BusinessProfileCard 
							key={i} 
							profile={c} 
							canAdmin={props.canAdmin}
							accreditedLogo={props.accreditedLogo} 
							onChangeRating={onChangeRating}
						/>
					)}
				</Grid>
			}
		</React.Fragment>
	} breadcrumbs={["Business profiles"]} />;
};

BusinessProfilesController.propTypes = { 
	profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchBusinessProfiles: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	accreditedLogo: PropTypes.string,
	canAdmin: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired
};

BusinessProfilesController.defaultProps = {
	accreditedLogo: null
};

const mapStateToProps = (state, ownProps) => ({
	profiles: state.businessProfiles.profiles,
	loading: isLoading(state),
	searchArgs: {
		category: "all",
		...pick(getQueryParameters(ownProps.location.search), "search", "category", "order")
	},
	canSubmit: canSubmitBusinessProfiles(state),
	canAdmin: canAdminBusinessProfile(state),
	accreditedLogo: state.context.organisationAccreditedLogo,
	canEdit: hasFunction("BusinessProfileEdit")
});

const mapDispatchToProps = dispatch => ({
	searchBusinessProfiles: args => dispatch(searchBusinessProfiles(args)),
	rateBusinessProfile: (profileId, rating) => dispatch(rateBusinessProfile(profileId, rating)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(BusinessProfilesController));