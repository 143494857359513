import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getDiscussion, isLoading, getComments, saveComment, removeComment, isCommentsLoading } from '../../store/discussion';
import { canEditDiscussion } from '../../store/security';
import { showModal } from '../../store/modal';
import { prettifyDate, truncateString } from '../../utils/utils';
import { post } from '../../utils/ajax';
import ButtonLink from '../widgets/ButtonLink';
import Avatar from '../widgets/Avatar';
import Chat from '../widgets/Chat';
import Tag from '../widgets/Tag';

const topicSwitch = {
	"Course": "courses",
	"Event": "events",
	"Resource": "resources",
	"Tool": "tools"
};

const ViewDiscussion = props => {
	const { discussionId, discussion } = props;

	React.useEffect(() => {
		props.getDiscussion(discussionId);
		props.getComments(discussionId);

		post({ url: `/api/discussion/${discussionId}/view` });
	}, [discussionId]);

	return (
		<div className="view-discussion">
			<Grid container direction="row-reverse" alignContent="flex-end" spacing={24}>
				{discussion.topicId && !props.hideTopic &&
					<Grid item xs="auto" style={{ marginBottom: 10 }}>
						<ButtonLink 
							to={`/${props.isCommunity ? "my-community/" : ""}${topicSwitch[discussion.topicType]}/${discussion.topicId}`}
							startIcon={<ExitToAppIcon />}
							color="secondary"
						>
							{truncateString(discussion.topicName, 50)}
						</ButtonLink>
					</Grid>
				}
				<Grid item xs={12} md className="header">
					<Avatar src={discussion.authorAvatar} fullName={discussion.authorName} length={50} />
					<div className="subject">
						<h2>
							<span style={{ color: "#98aab3" }}>#{discussion.discussionId}</span> {discussion.subject}
						</h2>
						<Tag label={discussion.community || ""} colour={props.primaryColour} />
					</div>
				</Grid>
			</Grid>
			<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: discussion.description }} />
			<div className="dates">
				Created {prettifyDate(discussion.createDate)}
				{discussion.updateDate && `, Updated ${prettifyDate(discussion.updateDate)}`}
			</div>
			{props.canEdit && 
				<ButtonLink to={`/${props.isCommunity ? "my-community/" : ""}discussion/edit/${discussion.discussionId}`} style={{ marginTop: 10 }}>
					Edit details
				</ButtonLink>
			}
			<Chat
				title="Comments"
				currentUserId={props.currentUserId}
				messages={props.comments.map(c => ({ ...c, createDate: c.date }))}
				saveMessage={message => props.saveComment(discussion.discussionId, message)}
				canRemove={props.canRemoveChat}
				removeMessage={props.removeComment}
				loading={props.commentsLoading}
				emptyText="There are no comments to display..."
			/>
		</div>
	);
};

ViewDiscussion.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	discussion: PropTypes.shape({
		discussionId: PropTypes.number,
		subject: PropTypes.string,
		description: PropTypes.string,
		communityId: PropTypes.number,
		community: PropTypes.string,
		authorId: PropTypes.string,
		authorName: PropTypes.string,
		authorAvatar: PropTypes.string,
		registrationDate: PropTypes.string,
		createDate: PropTypes.string,
		updateDate: PropTypes.string,
		updatedBy: PropTypes.string,
		channels: PropTypes.array
	}).isRequired,
	getDiscussion: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	comments: PropTypes.arrayOf(PropTypes.object).isRequired,
	getComments: PropTypes.func.isRequired,
	saveComment: PropTypes.func.isRequired,
	canRemoveChat: PropTypes.bool.isRequired,
	removeComment: PropTypes.func.isRequired,
	commentsLoading: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool.isRequired,
	hideTopic: PropTypes.bool,
	primaryColour: PropTypes.string.isRequired
};

ViewDiscussion.defaultProps = {
	hideTopic: false
};

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	discussion: state.discussion.discussion,
	loading: isLoading(state),
	canEdit: canEditDiscussion(state),
	comments: state.discussion.comments,
	canRemoveChat: state.context.isAdministrator,
	commentsLoading: isCommentsLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispathToProps = dispatch => ({
	getDiscussion: discussionId => dispatch(getDiscussion(discussionId)),
	getComments: discussionId => dispatch(getComments(discussionId)),
	saveComment: (discussionId, message) => dispatch(saveComment(discussionId, message)),
	removeComment: comment => dispatch(showModal("CONFIRM_DELETE", {
		title: "Remove?",
		message: "Are you sure you want to remove this comment?",
		onOk: () => dispatch(removeComment(comment))
	}))
});

export default connect(mapStateToProps, mapDispathToProps)(ViewDiscussion);