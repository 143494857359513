import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewOffer from './ViewOffer';
import { getOffer, isLoading } from '../../store/offers';
import { canEditOffer } from '../../store/security';
import { post } from '../../utils/ajax';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';

const ViewOfferController = props => {
	const [showEnquiry, setShowEnquiry] = React.useState(false);
	const [enquiryLoading, setEnquiryLoading] = React.useState(false);

	React.useEffect(() => {
		const { offerId } = props.match.params;
	
		props.getOffer(offerId);
		post({ url: `/api/offers/${offerId}/log-view` });
	}, []);

	const sendEnquiry = values => {
		setEnquiryLoading(true);

		post({
			url: `/api/offers/${props.offer.offerId}/enquire`,
			data: values,
			onSuccess: result => {
				setEnquiryLoading(false);
				setShowEnquiry(false);
				
				if (result.success) {
					props.showSuccessNotification(result.message);
				} else {
					props.showErrorNotification(result.message);
				}
			},
			onError: error => {
				setEnquiryLoading(false);
				props.showErrorNotification(error.message);
			}
		});
	}

	return <PageLayout title="View Offer" pageContent={
		<ViewOffer 
			offer={props.offer}
			loading={props.loading}
			canEdit={props.canEdit}
			isAdmin={props.isAdmin}
			fullName={props.fullName}
			email={props.email}
			showEnquiry={showEnquiry}
			updateShowEnquiry={() => setShowEnquiry(!showEnquiry)}
			enquiryLoading={enquiryLoading}
			sendEnquiry={sendEnquiry}
		/>
	} breadcrumbs={[{ label: "Offers", path: "/offers" }, "View Offer"]} />;
};

ViewOfferController.propTypes = {
	offer: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	fullName: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	offer: state.offers.offer,
	loading: isLoading(state),
	canEdit: canEditOffer(state),
	isAdmin: state.context.isAdministrator,
	fullName: state.context.fullName,
	email: state.context.email
});

const mapDispatchToProps = dispatch => ({
	getOffer: offerId => dispatch(getOffer(offerId)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	showErrorNotification: message => dispatch(showErrorNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOfferController);
