import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Button from '../widgets/Button';
import { withForm } from '../../utils/forms';

const form = {
	fields: [
		{
			name: "title",
			label: "Content title",
			required: true
		}
	]
};

const CopyContentDialog = props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        TransitionProps={{
            onEntering: () => props.updateValues({ title: props.title })
        }}>
		<DialogTitle>Copy Content</DialogTitle>
		<DialogContent>
			{props.fields.title()}
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose}>Cancel</Button>
			<Button onClick={() => props.copyContent(props.values.title)}>Confirm</Button>
		</DialogActions>
	</Dialog>;

CopyContentDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	copyContent: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default withForm(form)(CopyContentDialog);
