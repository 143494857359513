import React from 'react';
import { connect } from 'react-redux';

const PageBanner = React.memo(props => 
	<aside className="page-banner">
		<div className="quill-display-block" dangerouslySetInnerHTML={{ __html: props.content }} />
	</aside>
);

export default connect(state => ({
	content: state.settings.pageBannerContent
}), null)(PageBanner);
