const initialState = {
	modalType: null,
	modalProps: {}
};

const SHOW_MODAL = "SHOW_MODAL";
const HIDE_MODAL = "HIDE_MODAL";

export const showModal = (modalType, modalProps) => ({ type: SHOW_MODAL, modalType, modalProps });

export const hideModal = () => ({ type: HIDE_MODAL });

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				modalType: action.modalType,
				modalProps: action.modalProps
			};
		case HIDE_MODAL:
			return initialState;
		default:
			return state;
	}
};
