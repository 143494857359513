import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveCourse, isLoading } from '../../store/courses';
import { get } from '../../utils/ajax';
import PageLayout from '../PageLayout';
import EditCourse from './EditCourse';
import { canAdminCourses } from '../../store/security';

const CreateCourseController = props => {
	const [organisations, setOrganisations] = React.useState([]);

	React.useEffect(() => {
		get({
			url: "/api/organisations?type=Organisation",
			onSuccess: data => setOrganisations(data.map(o => ({ label: o.name, value: o.organisationId })))
		});
	}, []);

	return <PageLayout title="Create new course" pageContent={
		<EditCourse 
			saveCourse={props.saveCourse(props.fromAdmin)} 
			loading={props.loading} 
			organisationId={props.organisationId} 
			canAdminCourses={props.canAdminCourses}
			organisations={organisations}
		/>
	} breadcrumbs={[{ label: "Courses", path: props.fromAdmin ? "/admin/courses" : "/courses" }, "Create course"]} />;
};	

CreateCourseController.propTypes = {
	saveCourse: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	fromAdmin: PropTypes.bool,
	canAdminCourses: PropTypes.bool.isRequired
};

CreateCourseController.defaultProps = {
	fromAdmin: false
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	canAdminCourses: canAdminCourses(state)
});

const mapDispatchToProps = dispatch => ({
	saveCourse: fromAdmin => (course, image, newFiles) => dispatch(saveCourse(course, image, newFiles, fromAdmin))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourseController);