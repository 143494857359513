import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { createLoadingSelector } from './loading';
import { showSuccessNotification, showErrorNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	centres: [],
	pageNum: null,
	pageSize: null,
	total: null,
	totalPages: null,
	centre: {},
	isFavouriting: {}
};

const CLEAR_CENTRES = "CLEAR_CENTRES";
const GET_CENTRES_REQUEST = "GET_CENTRES_REQUEST";
const GET_CENTRES_SUCCESS = "GET_CENTRES_SUCCESS";
const GET_CENTRES_FAILURE = "GET_CENTRES_FAILURE";
const SEARCH_CENTRES_REQUEST = "SEARCH_CENTRES_REQUEST";
const SEARCH_CENTRES_SUCCESS = "SEARCH_CENTRES_SUCCESS";
const SEARCH_CENTRES_FAILURE = "SEARCH_CENTRES_FAILURE";
const GET_CENTRE_REQUEST = "GET_CENTRE_REQUEST";
const GET_CENTRE_SUCCESS = "GET_CENTRE_SUCCESS";
const GET_CENTRE_FAILURE = "GET_CENTRE_FAILURE";
const SAVE_CENTRE_REQUEST = "SAVE_CENTRE_REQUEST";
const SAVE_CENTRE_SUCCESS = "SAVE_CENTRE_SUCCESS";
const SAVE_CENTRE_FAILURE = "SAVE_CENTRE_FAILURE";
const DELETE_CENTRE_REQUEST = "DELETE_CENTRE_REQUEST";
const DELETE_CENTRE_SUCCESS = "DELETE_CENTRE_SUCCESS";
const DELETE_CENTRE_FAILURE = "DELETE_CENTRE_FAILURE";
const FAVOURITE_CENTRE_REQUEST = "FAVOURITE_CENTRE_REQUEST";
const FAVOURITE_CENTRE_SUCCESS = "FAVOURITE_CENTRE_SUCCESS";
const FAVOURITE_CENTRE_FAILURE = "FAVOURITE_CENTRE_FAILURE";
const PUBLISH_CENTRE_REQUEST = "PUBLISH_CENTRE_REQUEST";
const PUBLISH_CENTRE_SUCCESS = "PUBLISH_CENTRE_SUCCESS";
const PUBLISH_CENTRE_FAILURE = "PUBLISH_CENTRE_FAILURE";
const UNPUBLISH_CENTRE_REQUEST = "UNPUBLISH_CENTRE_REQUEST";
const UNPUBLISH_CENTRE_SUCCESS = "UNPUBLISH_CENTRE_SUCCESS";
const UNPUBLISH_CENTRE_FAILURE = "UNPUBLISH_CENTRE_FAILURE";

export const isLoading = createLoadingSelector(["GET_CENTRES", "SEARCH_CENTRES", "GET_CENTRE"]);

export const getCentres = (pageNum, pageSize) =>
	createFetchAction({
		url: `/api/research-centres?pageNum=${pageNum}&pageSize=${pageSize}`,
		startAction: GET_CENTRES_REQUEST,
		onError: error => [getCentresFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCentresSuccess(data)
	});

export const getCentresSuccess = data => ({ type: GET_CENTRES_SUCCESS, payload: { data } });
export const getCentresFailure = error => ({ type: GET_CENTRES_FAILURE, payload: { error } });

export const searchCentres = (args, pageNum, pageSize) => 
	createFetchAction({
		url: `/api/research-centres/search?PageNum=${pageNum}&PageSize=${pageSize}&${map(Object.keys(args), k => `${k}=${encodeURIComponent(args[k])}`).join("&")}`,
		startAction: SEARCH_CENTRES_REQUEST,
		onError: error => [searchCentresFailure(error), showErrorNotification(error.message)],
		onSuccess: data => searchCentresSuccess(data)
	});

export const searchCentresSuccess = data => ({ type: SEARCH_CENTRES_SUCCESS, payload: { data } });
export const searchCentresFailure = error => ({ type: SEARCH_CENTRES_FAILURE, payload: { error } });

export const getCentre = centreId =>
	createFetchAction({
		url: `/api/research-centres/${centreId}`,
		startAction: GET_CENTRE_REQUEST,
		onError: error => [getCentreFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getCentreSuccess(data)
	});

export const getCentreSuccess = data => ({ type: GET_CENTRE_SUCCESS, payload: { data } });
export const getCentreFailure = error => ({ type: GET_CENTRE_FAILURE, payload: { error } });

export const saveCentre = (centre, logoFile) => {
	const formData = new FormData();
	formData.append("ResearchCentre", encodeURIComponent(JSON.stringify(centre)));

	if (logoFile) formData.append("LogoFile", logoFile);

	return createFormPostAction({
		url: "/api/research-centres",
		data: formData,
		startAction: SAVE_CENTRE_REQUEST,
		onError: error => [saveCentreFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveCentreSuccess(data), showSuccessNotification(data.message), changeLocation(`/research-centres/${data.object.centreId}`)];
			} else {
				return [saveCentreSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveCentreSuccess = data => ({ type: SAVE_CENTRE_SUCCESS, data });
export const saveCentreFailure = error => ({ type: SAVE_CENTRE_FAILURE, error });

export const deleteCentre = centreId =>
	createPostAction({
		url: `/api/research-centres/${centreId}/delete`,
		startAction: DELETE_CENTRE_REQUEST,
		onError: error => [deleteCentreFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteCentreSuccess(data), showSuccessNotification(data.message), changeLocation("/research-centres")];
			} else {
				return [deleteCentreSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteCentreSuccess = data => ({ type: DELETE_CENTRE_SUCCESS, data });
export const deleteCentreFailure = error => ({ type: DELETE_CENTRE_FAILURE, error });

export const favouriteCentre = centreId =>
	createPostAction({
		url: `/api/research-centres/${centreId}/favourite`,
		startAction: FAVOURITE_CENTRE_REQUEST,
		startActionData: { centreId },
		onError: error => [favouriteCentreFailure(error, centreId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteCentreSuccess(data, centreId)];
			} else {
				return [favouriteCentreSuccess(data, centreId), showErrorNotification(data.message)];
			}
		}
	});

export const unfavouriteCentre = centreId =>
	createPostAction({
		url: `/api/research-centres/${centreId}/unfavourite`,
		startAction: FAVOURITE_CENTRE_REQUEST,
		startActionData: { centreId },
		onError: error => [favouriteCentreFailure(error, centreId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [favouriteCentreSuccess(data, centreId)];
			} else {
				return [favouriteCentreSuccess(data, centreId), showErrorNotification(data.message)];
			}
		}
	});

export const favouriteCentreSuccess = (data, centreId) => ({ type: FAVOURITE_CENTRE_SUCCESS, data, centreId });
export const favouriteCentreFailure = (error, centreId) => ({ type: FAVOURITE_CENTRE_FAILURE, error, centreId });

export const publishCentre = centreId =>
	createPostAction({
		url: `/api/research-centres/${centreId}/publish`,
		startAction: PUBLISH_CENTRE_REQUEST,
		startActionData: { centreId },
		onError: error => [publishCentreFailure(error, centreId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [publishCentreSuccess(data, centreId), showSuccessNotification(data.message)];
			} else {
				return [publishCentreSuccess(data, centreId), showErrorNotification(data.message)];
			}
		}
	});
export const publishCentreSuccess = (data, centreId) => ({ type: PUBLISH_CENTRE_SUCCESS, data, centreId });
export const publishCentreFailure = (error, centreId) => ({ type: PUBLISH_CENTRE_FAILURE, error, centreId });

export const unpublishCentre = centreId =>
	createPostAction({
		url: `/api/research-centres/${centreId}/unpublish`,
		startAction: UNPUBLISH_CENTRE_REQUEST,
		startActionData: { centreId },
		onError: error => [unpublishCentreFailure(error, centreId), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [unpublishCentreSuccess(data, centreId), showSuccessNotification(data.message)];
			} else {
				return [unpublishCentreSuccess(data, centreId), showErrorNotification(data.message)];
			}
		}
	});
export const unpublishCentreSuccess = (data, centreId) => ({ type: UNPUBLISH_CENTRE_SUCCESS, data, centreId });
export const unpublishCentreFailure = (error, centreId) => ({ type: UNPUBLISH_CENTRE_FAILURE, error, centreId });


const sanitizeCentre = centre => ({
	...centre,
	research: DOMPurify.sanitize(centre.research),
	institutions: DOMPurify.sanitize(centre.institutions),
	caseStudies: DOMPurify.sanitize(centre.caseStudies)
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_CENTRES:
			return { ...state, centres: [] };
		case GET_CENTRES_REQUEST:
			return { 
				...state, 
				centres: []
			};
		case SEARCH_CENTRES_REQUEST:
			return { ...state, isLoading: true };
		case GET_CENTRES_SUCCESS:
		case SEARCH_CENTRES_SUCCESS:
			return { 
				...state, 
				centres: map(action.payload.data.data, sanitizeCentre), 
				isLoading: false,
				pageNum: action.payload.data.pageNum,
				pageSize: action.payload.data.pageSize,
				total: action.payload.data.total,
				totalPages: action.payload.data.totalPages
			};
		case GET_CENTRE_REQUEST:
			return { ...state, centre: {}, isLoading: true };
		case GET_CENTRE_SUCCESS:
			return { ...state, centre: sanitizeCentre(action.payload.data), isLoading: false };
		case SAVE_CENTRE_REQUEST:
		case DELETE_CENTRE_REQUEST:
		case PUBLISH_CENTRE_REQUEST:
		case UNPUBLISH_CENTRE_REQUEST:
			return { ...state, isLoading: true, saveResult: emptySaveResult };
		case SAVE_CENTRE_SUCCESS:
		case PUBLISH_CENTRE_SUCCESS:
		case UNPUBLISH_CENTRE_SUCCESS:
			const { success, message, fields, object } = action.data;

			return {
				...state,
				...(success && { centre: sanitizeCentre(object) }),
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case DELETE_CENTRE_SUCCESS:
			return { ...state, centres: state.centres.filter(a => a.centreId !== action.data.objectId) };
		case FAVOURITE_CENTRE_REQUEST:
			return {
				...state,
				saveResult: emptySaveResult,
				message: null,
				isFavouriting: { ...state.isFavouriting, [action.data.centreId]: true }
			};
		case FAVOURITE_CENTRE_FAILURE:
			return {
				...state,
				saveResult: emptySaveResult,
				isFavouriting: { ...state.isFavouriting, [action.centreId]: false }
			};
		case FAVOURITE_CENTRE_SUCCESS:
			const { centreId, isFavourite, favSuccess, favMessage } = action.data;

			return {
				...state,
				centres: map(state.centres, t => t.centreId === centreId ? { ...t, isFavourite } : t),
				centre: state.centre.centreId === centreId ? { ...state.centre, isFavourite } : state.centre,
				saveResult: { favSuccess, favMessage },
				isFavouriting: { ...state.isFavouriting, [action.centreId]: false }
			}
		default:
			return state;
	}
};