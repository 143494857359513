import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveReport, isLoading } from '../../../store/reports';
import PageLayout from '../../PageLayout';
import EditReport from './EditReport';

const CreateReportController = props => 
	<PageLayout title="Create report" pageContent={
		<EditReport saveReport={props.saveReport} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Manage reports", path: "/admin/reports" }, "Create report"]} />;

CreateReportController.propTypes = {
	saveReport: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveReport: (report, imageFile) => dispatch(saveReport(report, imageFile))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateReportController);