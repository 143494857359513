import React from 'react';
import ProblemTitleField from '../components/widgets/ProblemTitleField';
import AttachmentEditor from '../components/widgets/AttachmentEditor';
import InsightAttachmentEditor from '../components/widgets/InsightAttachmentEditor';
import FrameworkPicker from '../components/widgets/FrameworkPicker';
import AvatarUploader from '../components/widgets/AvatarUploader';
import ResourcePicker from '../components/widgets/ResourcePicker';
import ImageSelector from '../components/widgets/ImageSelector';
import ImageUploader from '../components/widgets/ImageUploader';
import VideoUploader from '../components/widgets/VideoUploader';
import ProfilePicker from '../components/widgets/ProfilePicker';
import AutoComplete from '../components/widgets/AutoComplete';
import FileUploader from '../components/widgets/FileUploader';
import TextEditor from '../components/widgets/TextEditor';
import DateField from '../components/widgets/DateField';
import TagEditor from '../components/widgets/TagEditor';
import AreaOfExpertiseEditor from '../components/widgets/AreaOfExpertiseEditor';
import TextField from '../components/widgets/TextField';
import Checkbox from '../components/widgets/Checkbox';
import DragList from '../components/widgets/DragList';
import Select from '../components/widgets/Select';
import ColourEditor from '../components/widgets/ColourEditor';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import set from 'lodash/set';
import format from 'date-fns/format';
import produce from 'immer';
import { moveItem } from './utils';

const maxValues = {
	textField: 8000,
	number: 100000
};

export const withForm = form => WrappedComponent => {
	const FormComponent = props => {
		const onChange = React.useRef({});

		const onChangeForm = (fnChange) => {
			setState(produce(draft => {
				fnChange(draft);
			}, state));
		}
		const [state, setState] = React.useState(() => {
			const values = {}, formFiles = {}, images = {}, videos = {}, error = {};

			forEach(form.fields, f => {
				// Check if an object is supplied
				if (typeof f === "object") {
					// Set default props
					if (!f.type) f.type = "textField";
					if (!f.max && f.max !== false && maxValues[f.type]) f.max = maxValues[f.type];
					if (!f.defaultValue) {
						const value = {
							areaOfExpertiseEditor: [],
							attachmentEditor: [],
							insightAttachmentEditor: [],
							autoComplete: f.defaultItem ? f.defaultItem.value : f.isMulti ? [] : "",
							checkbox: false,
							dragList: [],
							imageUploader: f.single ? "" : [],
							videoUploader: f.single ? "" : [],
							tagEditor: [],
							number: 0,
							profilePicker: 0,
							frameworkPicker: f.isMulti ? [] : 0,
							resourcePicker: f.isMulti ? [] : 0
						}[f.type];

						f.defaultValue = value || value === false || value === 0 ? value : "";
					}

					if (["attachmentEditor", "insightAttachmentEditor", "imageUploader", "videoUploader"].includes(f.type) && !f.single) {
						formFiles[f.name] = [];
						images[f.name] = {};
						videos[f.name] = {};
					}

					error[f.name] = "";
					values[f.name] = f.defaultValue;

					onChange.current[f.name] = (value, file) => {
						setState(produce(draft => {
							const single = f.type !== "autoComplete" || f.isMulti ? value : value.value;
							draft.values = f.setValues ? f.setValues(single, draft.values) : { ...draft.values, [f.name]: single };
							draft.error[f.name] = validateField(f, draft.values, single);
							if (file) {
								draft.formFiles[f.name] = file;
							}
							if (f.onChange) f.onChange(draft.values, value);
							if (form.onChange) form.onChange(props, draft.values, f.name);
							
							if (!draft.dirty) {
								draft.dirty = true;
								if (props.handleDirty) props.handleDirty();
							}
						}, state));
					}
				}
			});

			return { values, error, formFiles, images, videos, brokenAvatar: {}, valid: true, dirty: false };
		});

		React.useEffect(() => {
			if (props.loading) return;

			if (props.saveResult) {
				setState(produce(draft => {
					forEach(props.saveResult.fields, f => {
						if (f.fieldName) draft.error[camelCase(f.fieldName)] = f.message;
					});
				}, state));
			}

			if (form.initValues) {
				const newValues = form.initValues(props);
				if (!newValues) return;

				// Don't do anything if initValues hasn't changed
				if (!isEqual(state.initValues, newValues)) {
					setState(produce(draft => {
						draft.initValues = newValues;

						// Merge previous state values to allow for values to be set during initialisation,
						// use replaceExisting to force state on each update (useful when transitioning 
						// between view and edit)
						const updatedValues = form.replaceExisting ? newValues : { ...state.values, ...newValues };

						if (!isEqual(updatedValues, state.prevValues)) {
							draft.prevValues = cloneDeep(updatedValues);
							draft.values = cloneDeep(updatedValues);
						}
					}, state));
				}
			}
		});

		// Allow values to be passed to avoid scope issues
		const validateField = (f, values, value) => {
			const required = isFunction(f.required) ? f.required(props, values) : f.required,
				trimmed = typeof(value) === "string" ? value.trim() : value || f.defaultValue;

			// If empty, return error if required
			if (!trimmed || (f.type === "textEditor" && !trimmed.replace(/<[^>]+>/gm, ""))) {
				return required ? f.requiredText || "This field is required" : "";
			}

			if (f.isMulti && trimmed.length === 0) {
				return required ? f.requiredText || "A selection is required" : "";
			}

			// Check for max field size
			const maxValue = isFunction(f.max) ? f.max(props, values) : f.max;
			if (maxValue) {
				if (f.type === "number" && trimmed > maxValue) {
					return `Max value is ${maxValue}`;
				} else if (trimmed.length > maxValue) {
					return `Max ${maxValue} ${f.isMulti ? "items" : "characters"} only (${trimmed.length})`;
				}
			}

			// Pass new value to validate function including other values in case needed
			return f.validate ? f.validate(value, values) : "";
		}

		const setValue = (name, value) => setState(produce(draft => {
			draft.values[name] = value;
		}, state));

		const { values, error, formFiles, images, videos } = state;
		const formProps = {
			values,
			error,
			formFiles,
			images,
			videos,
			valid: state.valid,
			dirty: state.dirty,
			onChange: onChange.current,
			resetForm: () => {
				const defaultValues = {}, defaultFiles = {};

				form.fields.forEach(f => {
					defaultValues[f.name] = f.defaultValue;
					if (["attachmentEditor", "insightAttachmentEditor", "imageUploader", "videoUploader"].includes(f.type)) defaultFiles[f.name] = [];
				});

				setState({ ...state, error: {}, brokenAvatar: {}, valid: true, dirty: false, values: { ...defaultValues, ...state.initValues }, formFiles: defaultFiles });
			},
			updateValues: newValues => newValues && setState({ ...state, values: { ...values, ...newValues } }),
			fields: mapValues(keyBy(form.fields, "name"), f => () => {
				// Allow custom values using a value func
				const value = f.getValue ? f.getValue(values) : values[f.name],
					placeholder = isFunction(f.placeholder) ? f.placeholder(props, values) : f.placeholder,
					required = isFunction(f.required) ? f.required(props, values) : f.required,
					disabled = isFunction(f.disabled) ? f.disabled(props, values) : f.disabled,
					helpText = error[f.name] || f.helpText,
					style = { ...form.fieldStyle, ...f.style };

				switch (f.type) {
					case "areaOfExpertiseEditor":
						return <AreaOfExpertiseEditor
							value={value}
							label={f.label}
							placeholder={placeholder}
							onChange={onChange.current[f.name]}
							error={Boolean(error[f.name])}
							style={style}
							scope={f.scope}
						/>;
					case "attachmentEditor":
						return <AttachmentEditor 
							label={f.label}
							attachments={value}
							add={files => {
								// The files arg will be garbage collected if we wait
								// until inside the setState callback so store in local
								// vars here first
								const newAttachments = [], newFormFiles = [];

								forEach(files, file => {
									newAttachments.push({
										fileName: file.name,
										name: file.name,
										sizeBytes: file.size,
										attachmentType: "document",
										_isNew: true
									});
						
									newFormFiles.push(file);
								});

								setState(produce(draft => {
									const attachments = draft.values[f.name],
										formFiles = draft.formFiles[f.name];
									
									forEach(newAttachments, a => attachments.push(a));
									forEach(newFormFiles, file => formFiles.push(file));
								}, state));
							}}
							types={f.attachmentTypes}
							delete={index => setState(produce(draft => {
								draft.values[f.name].splice(index, 1);
							}, state))}
							download={f.download}
							update={(index, newValues) => setState(produce(draft => {
								draft.values[f.name][index] = newValues;
							}, state))}
							style={style}
						/>;
					case "insightAttachmentEditor":
						return <InsightAttachmentEditor 
							label={f.label}
							attachments={value}
							add={files => {
								// The files arg will be garbage collected if we wait
								// until inside the setState callback so store in local
								// vars here first
								const newAttachments = [], newFormFiles = [];

								forEach(files, file => {
									newAttachments.push({
										fileName: file.name,
										name: file.name,
										sizeBytes: file.size,
										attachmentType: "document",
										_isNew: true
									});
						
									newFormFiles.push(file);
								});

								setState(produce(draft => {
									const attachments = draft.values[f.name],
										formFiles = draft.formFiles[f.name];
									
									forEach(newAttachments, a => attachments.push(a));
									forEach(newFormFiles, file => formFiles.push(file));
								}, state));
							}}
							types={f.attachmentTypes}
							delete={index => setState(produce(draft => {
								draft.values[f.name].splice(index, 1);
							}, state))}
							download={f.download}
							update={(index, newValues) => setState(produce(draft => {
								draft.values[f.name][index] = newValues;
							}, state))}
							style={style}
						/>;
					case "autoComplete":
						let loadItems = {};

						if (isFunction(f.loadItems)) {
							loadItems = f.loadItems(props, values);
						} else {
							loadItems = { ...f.loadItems };
							
							if (isFunction(loadItems.route)) {
								loadItems.route = loadItems.route(props, values);
							}

							if (loadItems.onSuccess) {
								loadItems.onSuccess = mapped => f.loadItems.onSuccess(props, setValue, mapped);
							}

							if (isFunction(loadItems.filter)) {
								loadItems.filter = value => f.loadItems.filter(props, value);
							}

							if (isFunction(loadItems.waitFor)) {
								loadItems.waitFor = loadItems.waitFor(props);
							}

							if (isFunction(loadItems.mapItem)) {
								loadItems.mapItem = (value, i, collection) => f.loadItems.mapItem(value, i, collection, props);
							}
						}

						return <AutoComplete
							id={`field-${f.name}`}
							required={required}
							items={isFunction(f.items) ? f.items(props) : f.items}
							isMulti={f.isMulti}
							isSingleMulti={f.isSingleMulti}
							loadItems={loadItems}
							defaultItem={f.defaultItem}
							value={value}
							label={f.label}
							onChange={onChange.current[f.name]}
							error={Boolean(error[f.name])}
							helpText={helpText}
							style={style}
							disabled={disabled}
							canCreate={isFunction(f.canCreate) ? f.canCreate(props, values) : f.canCreate}
							mapValue={f.mapValue}
							formatCreateLabel={f.formatCreateLabel}
							onCreateOption={f.onCreateOption}
							chipColour={isFunction(f.chipColour) ? f.chipColour(props) : f.chipColour}
							chipStyle={f.chipStyle}
							types={f.attachmentTypes}
							openMenuOnClick={f.openMenuOnClick}
							filterOption={f.filterOption}
						/>;
					case "avatar":
						return <AvatarUploader
							id={f.id}
							name={f.name}
							label={f.label}
							value={value}
							style={style}
							helpText={helpText}
							onChange={onChange.current[f.name]}
							onError={() => setState(produce(draft => {
								draft.brokenAvatar[f.name] = true;
							}, state))}
							broken={state.brokenAvatar[f.name]}
						/>;
					case "checkbox":
						return <Checkbox
							name={f.name}
							label={isFunction(f.label) ? f.label(setValue) : f.label}
							required={required}
							error={Boolean(error[f.name])}
							helpText={helpText}
							checked={Boolean(value)}
							onChange={e => onChange.current[f.name](e.target.checked)}
							style={style}
						/>;
					case "date":
						return <DateField
							value={value}
							label={f.label}
							placeholder={placeholder}
							required={required}
							onChange={date => onChange.current[f.name](date !== null && f.stripTime ? new Date(format(date, "yyyy-MM-dd")) : date)}
							error={Boolean(error[f.name])}
							helpText={helpText}
							style={style}
							showTime={f.showTime}
							clearable={f.clearable}
						/>;
					case "dragList":
						// Update handler for items with optional field name and file object
						const updateItem = index => (value, field, file) => setState(produce(draft => {
							set(draft.values, field ? [f.name, index, field] : [f.name, index], value);

							if (file) set(draft.formFiles, field ? [f.name, index, field] : [f.name, index], file);
						}, state));

						return <DragList
							id={f.id}
							label={f.label}
							required={required}
							error={Boolean(error[f.name])}
							helpText={helpText}
							style={style}
							cellStyle={f.cellStyle}
							hideArrows={f.hideArrows}
							simple={f.simple}
							showBorder={f.showBorder}
							dragOnly={f.dragOnly}
							addItem={index => setState(produce(draft => {
								if (f.addToEnd) {
									draft.values[f.name].push(f.itemTemplate);
								} else {
									draft.values[f.name].splice(index, 0, f.itemTemplate);
								}
							}, state))}
							removeItem={index => setState(produce(draft => {
								draft.values[f.name].splice(index, 1);
							}, state))}
							moveItem={(from, to) => setState(produce(draft => {
								// Move value
								const copy = [...value];
								moveItem(copy, from, to);
								draft.values[f.name] = copy;

								// Move formfiles if needed
								if (draft.formFiles[f.name]) {
									const files = [...formFiles[f.name]];
									moveItem(files, from, to);
									draft.formFiles[f.name] = files;
								}
							}, state))}
						>
							{/* Map each item in draglist using render method, passing update handler directly */}
							{map(value, (item, index) => f.renderItem(item, index, updateItem(index), props))}
						</DragList>;
					case "fileUploader":
						return <FileUploader
							id={f.id}
							required={required}
							style={f.style}
							helpText={helpText}
							value={value}
							label={f.label}
							placeholder={f.placeholder}
							accept={f.accept}
							onChange={e => setState(produce(draft => {
								if (e.target.files) {
									const file = e.target.files[0];
									draft.formFiles[f.name] = file;
									draft.values[f.name] = file.name;
								}
							}))}
						/>;
					case "frameworkPicker":
						return <FrameworkPicker
							required={required}
							value={value}
							label={f.label}
							onChange={value => onChange.current[f.name](value)}
							helpText={helpText}
							disabled={disabled}
							style={style}
							disableClearable={f.disableClearable}
							isMulti={f.isMulti}
							organisationId={f.organisationId}
						/>
					case "imageSelector":
						return <ImageSelector 
							label={f.label}
							required={required}
							error={Boolean(error[f.name])}
							imageText={f.imageText}
							helpText={helpText}
							images={f.images || []}
							style={style}
							value={value}
							onChange={onChange.current[f.name]}
							height={f.height}
							width={f.width}
							background={f.background}
							accept={f.accept}
						/>;
					case "imageUploader":
						return <ImageUploader
							id={f.id}
							style={f.style}
							helpText={helpText}
							value={value}
							label={f.label}
							srcProperty={f.imageSrcProperty}
							images={images[f.name]}
							maxUploads={f.maxUploads}
							placeholder={f.placeholder}
							single={f.single}
							required={required}
							addImage={file => {
								setState(produce(draft => {
									if (f.single) {
										draft.formFiles[f.name] = file;
										draft.values[f.name] = file.name;
									} else {
										draft.formFiles[f.name].push(file);
										draft.values[f.name].push({
											fileName: file.name,
											name: file.name,
											sizeBytes: file.size,
											attachmentType: "image",
											_isNew: true
										});
									}
								}, state));

								let imageOnLoad = null;
								if (f.single) {
									imageOnLoad = ev => 
										setState(produce(draft => {
											draft.images[f.name] = ev.target.result;
										}, state));
								} else {
									imageOnLoad = ev => 
										setState(produce(draft => {
											draft.images[f.name][file.name] = ev.target.result;
										}, state));
								}
								const reader = new FileReader();
								reader.onload = imageOnLoad;
								reader.readAsDataURL(file);
							}}
							removeImage={index => setState(produce(draft => {
								if (f.single) {
									draft.values[f.name] = "";
									draft.formFiles[f.name] = null;
								} else {
									draft.values[f.name].splice(index, 1);
									draft.formFiles[f.name].splice(index, 1);
								}
							}, state))}
						/>;
					case "videoUploader":
						return <VideoUploader
							id={f.id}
							style={f.style}
							helpText={helpText}
							value={value}
							label={f.label}
							srcProperty={f.videoSrcProperty}
							videos={videos[f.name]}
							maxUploads={f.maxUploads}
							placeholder={f.placeholder}
							single={f.single}
							required={required}
							addVideo={file => {
								setState(produce(draft => {
									if (f.single) {
										draft.formFiles[f.name] = file;
										draft.values[f.name] = file.name;
									} else {
										draft.formFiles[f.name].push(file);
										draft.values[f.name].push({
											fileName: file.name,
											name: file.name,
											sizeBytes: file.size,
											attachmentType: "video",
											_isNew: true
										});
									}
								}, state));

								let videoOnLoad = null;
								if (f.single) {
									videoOnLoad = ev => 
										setState(produce(draft => {
											draft.videos[f.name] = ev.target.result;
										}, state));
								} else {
									videoOnLoad = ev => 
										setState(produce(draft => {
											draft.videos[f.name][file.name] = ev.target.result;
										}, state));
								}
								const reader = new FileReader();
								reader.onload = videoOnLoad;
								reader.readAsDataURL(file);
							}}
							removeVideo={index => setState(produce(draft => {
								if (f.single) {
									draft.values[f.name] = "";
									draft.formFiles[f.name] = null;
								} else {
									draft.values[f.name].splice(index, 1);
									draft.formFiles[f.name].splice(index, 1);
								}
							}, state))}
						/>;
					case "problem-title":
						return <ProblemTitleField 
							required={required}
							value={value}
							label={f.label}
							placeholder={placeholder}
							onChange={e => onChange.current[f.name](e.target.value)}
							error={Boolean(error[f.name])}
							helpText={helpText}
							style={style}
							startAdornment={f.startAdornment}
							disabled={disabled}
						/>;
					case "profilePicker":
						return <ProfilePicker
							required={required}
							value={value}
							label={f.label}
							onChange={value => onChange.current[f.name](value)}
							organisationId={f.organisationId ? (isFunction(f.organisationId) ? f.organisationId(props, values) : f.organisationId) : null}
							defaultGroup={f.defaultGroup}
							groupOnly={f.groupOnly}
							helpText={helpText}
							disabled={disabled}
							style={style}
							disableClearable={f.disableClearable}
						/>
					case "resourcePicker":
						return <ResourcePicker
							required={required}
							value={value}
							label={f.label}
							onChange={value => onChange.current[f.name](value)}
							helpText={helpText}
							disabled={disabled}
							style={style}
							disableClearable={f.disableClearable}
							isMulti={f.isMulti}
							subscribedOnly={f.subscribedOnly ? (isFunction(f.subscribedOnly) ? f.subscribedOnly(props) : f.subscribedOnly) : false}
						/>;
					case "select":
						const items = isFunction(f.items) ? f.items(props) : f.items;

						return <Select
							required={required}
							name={f.name}
							value={value}
							label={f.label}
							onChange={e => onChange.current[f.name](e.target.value)}
							error={Boolean(error[f.name])}
							helpText={helpText}
							items={items}
							style={style}
							disabled={disabled}
							displayEmpty={f.displayEmpty}
						/>;
					case "tagEditor":
						return <TagEditor
							value={value}
							label={f.label}
							placeholder={placeholder}
							onChange={onChange.current[f.name]}
							error={Boolean(error[f.name])}
							style={style}
							scope={f.scope}
						/>;
					case "textEditor":
						return <TextEditor
							required={required}
							name={f.name}
							value={value}
							label={f.label}
							placeholder={placeholder}
							onChange={onChange.current[f.name]}
							error={Boolean(error[f.name])}
							helpText={helpText}
							style={style}
							labelStyle={f.labelStyle}
							hideModules={f.hideModules}
							showLink={f.showLink}
							showImage={f.showImage}
							variables={f.variables}
							height={f.height}
						/>;
					case "colourEditor":
						return <ColourEditor
							value={value}
							label={f.label}
							placeholder={placeholder}
							onChange={e => onChange.current[f.name](e.target.value)}
							error={Boolean(error[f.name])}
							style={style}
						/>;
					case "custom":
						const Widget = f.widget;
						return <Widget
							required={required}
							field={f}
							value={value}
							placeholder={placeholder}
							onChange={onChange.current[f.name]}
							error={Boolean(error[f.name])}
							helpText={helpText}
							style={style}
							values={values}
							ownProps={props}
							onChangeForm={onChangeForm}
						/>;
					default:
						return (
                            <TextField 
                                required={required}
                                value={f.type === "number" ? value || 0 : value || ""}
                                label={f.label}
                                placeholder={placeholder}
                                onChange={e => onChange.current[f.name](e.target.value)}
                                error={Boolean(error[f.name])}
                                helpText={helpText}
                                type={f.type}
                                style={style}
                                labelStyle={f.labelStyle}
                                startAdornment={f.startAdornment && isFunction(f.startAdornment) ? f.startAdornment(props) : f.startAdornment}
                                endAdornment={f.endAdornment && isFunction(f.endAdornment) ? f.endAdornment(props) : f.endAdornment}
                                disabled={disabled}
                                multiline={f.multiline}
                                maxRows={f.rowsMax}
                                onBlur={f.onBlur ? e => f.onBlur(e.target.value, values, setValue) : null}
                            />
                        );
				}
			}),
			validateFields: fields => {
				const newError = { ...error };
				let valid = true;

				// Validate all fields if an array of fields is not given
				forEach(fields ? form.fields.filter(f => fields.includes(f.name)) : form.fields, f => {
					const result = validateField(f, values, values[f.name]);

					newError[f.name] = result;
					valid = valid && !result;
				});

				setState({ ...state, error: newError, valid });

				if (!valid && props.handleError) props.handleError();

				return valid;
			}
		};

		return <WrappedComponent {...formProps} {...props} />;
	}

	return FormComponent;
};