import React from 'react';
import PropTypes from 'prop-types';
import Card from '../widgets/Card';
import Form from '../widgets/Form';
import Grid from '@mui/material/Grid';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
import DataTable from '../widgets/dataTable/DataTable';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import Checkbox from '../widgets/Checkbox';

const form = {
	initValues: props => ({ channels: [], ...props.community }),
	fields: [
		{
			name: "organisationId",
			label: "Organisation",
			required: true,
			type: "autoComplete",
			loadItems: {
				route: "organisations",
				mapItem: org => ({ label: org.name, value: org.organisationId })
			},
			helpText: "Current organisation"
		},
		{
			name: "name",
			label: "Name",
			required: true,
			helpText: "Name of Community"
		},
		{
			name: "description",
			label: "Description",
			required: true,
			multiline: true,
			helpText: "Description of Community",
			max: 400
		},
		{ name: "channels" }
	]
};

const EditCommunity = props => {
	const { community, fields, values, loading, isNew } = props;

	return (
		<Card title={`${isNew ? "Add" : "Edit"} community`} loading={loading} className="edit-community">
			<Form onSubmit={() => props.validateFields() && props.saveCommunity(props.values)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>{fields.name()}</Grid>
					<Grid item xs={12} sm={6}>{fields.organisationId()}</Grid>
					<Grid item xs={12}>{fields.description()}</Grid>
				</Grid>
				<DataTable data={props.channels} disableScroll>
					<DataTableColumn renderer={c =>
						<Checkbox
							checked={values.channels ? values.channels.some(c2 => c2.channelId === c.channelId) : false}
							onChange={evt => {
								if (evt.target.checked) {
									props.updateValues({ channels: [...values.channels, c] });
								} else {
									props.updateValues({ channels: values.channels.filter(c2 => c2.channelId !== c.channelId) });
								}
							}}
						/>
					} />,
					<DataTableColumn name="name" label="Name" />
					<DataTableColumn name="description" label="Description" />
				</DataTable>
				<div className="form-buttons">
					<Button type="submit" loading={loading}>{isNew ? "Save" : "Update"} community</Button>
					{!isNew && 
						<Button className="delete-button" loading={loading} onClick={() => props.deleteCommunity(community.communityId)}>
							Delete Community
						</Button>
					}
				</div>
			</Form>
		</Card>
	);
};

EditCommunity.propTypes = {
	community: PropTypes.object,
	saveCommunity: PropTypes.func.isRequired,
	deleteCommunity: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	channels: PropTypes.array.isRequired
};

EditCommunity.defaultProps = {
	community: {},
	deleteCommunity: () => null,
	isNew: false
};

export default withForm(form)(EditCommunity);