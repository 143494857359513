import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

const ThemeColour = ({ label, backgroundColour, textColour }) =>
	<div style={{
		background: backgroundColour,
		color: textColour,
		padding: '5px 40px',
		borderRadius: '5px',
		marginBottom: '5px'
	}}>
		{label}
	</div>
	;
	
const PaletteColour = ({ colour }) =>
	<div style={{
		backgroundColor: colour,
		width: '40px',
		height: '40px',
		display: 'inline-block',
		marginRight: '5px',
		border: '1px solid #333'
	}}>
	</div>;

const ViewTheme = props => {
	const { theme } = props;
	
	return props.loading || isEmpty(theme) ? <CircularProgress size={24} /> : (
		<Card className="theme-details">
			<div className="header">
					<h2>{theme.name}</h2>
			</div>

			<ThemeColour 
				label="Primary Colour"
				backgroundColour={theme.primaryColour}
				textColour={theme.primaryColourText}
			/>
			<ThemeColour 
				label="Secondary Colour"
				backgroundColour={theme.secondaryColour}
				textColour={theme.secondaryColourText}
			/>

			{theme.colourPalette && theme.colourPalette.length > 0 &&
				<div>
					<span className="h3">Colour Palette</span>
					{map(theme.colourPalette, c =>
						<PaletteColour colour={c} />
					)}
				</div>
			}

			{props.canEdit && theme.applicationThemeId > 0 && 
				<ButtonLink to={`/ui-themes/edit/${theme.applicationThemeId}`} style={{ marginTop: 20 }}>
					Edit theme
				</ButtonLink>
			}
		</Card>
	);
};

ViewTheme.propTypes = {
	theme: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool
};

ViewTheme.defaultProps = {
	canEdit: false
};

export default ViewTheme;