import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestEvent, isLoading } from '../../store/events';
import { canSubmitEvents } from '../../store/security';
import { revokeAccess } from '../../store/context';
import PageLayout from '../PageLayout';
import EditEvent from './EditEvent';

const RequestEventController = props => {
	// React.useEffect(() => {
	// 	// Revoke access if the user can create events normally
	// 	if (props.canSubmitEvents) props.revokeAccess();
	// }, []);

	return (
		<PageLayout title="Request Event" pageContent={
			<EditEvent saveEvent={props.requestEvent} loading={props.loading} isNew isRequest />
		} breadcrumbs={[{ label: "Events",  path: `/${props.isCommunity ? "my-community/" : ""}events` }, "Request Event"]} />
	);
};

RequestEventController.propTypes = {
	requestEvent: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	canSubmit: PropTypes.bool.isRequired,
	revokeAccess: PropTypes.func.isRequired
};

RequestEventController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	canSubmit: canSubmitEvents(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	requestEvent: (event, newFiles) => dispatch(requestEvent(event, newFiles, ownProps.isCommunity)),
	revokeAccess: () => dispatch(revokeAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestEventController);