import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../widgets/Card';
import ReactEcharts from 'echarts-for-react';
import { withRouter } from 'react-router-dom';
import { get } from '../../utils/ajax';
import round from 'lodash/round';

const UserCompetencies = props => {
	const [state, setState] = React.useState({ data: [], loading: true });

	React.useEffect(() => {
		get({
			url: `/api/competencies/${props.userId}/competencies-and-comparisons`,
			onSuccess: data => setState(prevState => ({ ...prevState, data, loading: false })),
			onError: () => setState(prevState => ({ ...prevState, loading: false }))
		});
	}, []);

	return (
		<Card title={props.title} loading={state.loading} shrink>
			<ReactEcharts style={{ height: "300px" }} option={{
				color: props.colourPalette,
				legend: {},
				tooltip: {
					formatter: params => `${round(params.data[0], 1)}%`
				},
				grid: { containLabel: true },
				yAxis: {
					type: "category", 
					data: state.data.map(d => d.category)
				},
				xAxis: {
					type : "value",
					axisLabel: { formatter: "{value}%" }
				},
				series: [
					{ type: "bar", name: "Meets Target %", data: state.data.map(d => [d.competentPercentage * 100, d.category]) },
				]
			}} />
		</Card>
	);
};

UserCompetencies.propTypes = {
	title: PropTypes.string,
	userId: PropTypes.string.isRequired,
	colourPalette: PropTypes.array
};

UserCompetencies.defaultProps = {
	title: "User Competencies",
	colourPalette: undefined
};

export default connect(state => ({ 
	userId: state.context.userId,
	colourPalette: state.theme.colourPalette
}), null)(withRouter(UserCompetencies));
