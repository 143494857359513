import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import SkillsProfile from './SkillsProfile';
import { 
	getSkillsProfile,
	saveSkillsProfile,
	isLoading
} from '../../store/skillsProfiles';
import remove from 'lodash/remove';
import some from 'lodash/some';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import { showWarningNotification } from '../../store/notifications';
import produce from 'immer';
import { Prompt } from 'react-router'

const SkillsProfileController = props => {
	const skillsProfileId = props.match.params.skillsProfileId;
	const [state, setState] = React.useState({
		promptSelection: false,
		dirty: false,
		skillsProfile: { units: [] }
	});

	const onWindowUnload = React.useCallback(() => state.dirty, []);
	
	React.useEffect(() => {
		if (skillsProfileId) {
			props.getSkillsProfile(skillsProfileId);	
		}

		window.onbeforeunload = onWindowUnload;
	
		return () => {
			window.onbeforeunload = undefined;
		};
	}, [onWindowUnload]);

	React.useEffect(() => {
		setState({ ...state, skillsProfile: skillsProfileId ? props.skillsProfile : { units: [] } })
	}, [props.skillsProfile])

	const checkDirty = draft => {
		if (!draft.dirty) props.showSaveNotification();
		draft.dirty = true;
	}

	const addUnits = units => 
		setState(produce(draft => {
			forEach(draft.skillsProfile.units, u => {
				const unit = find(units, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
				if (unit) {
					u.priority = unit.priority;
				}
			});

			remove(draft.skillsProfile.units, u => {
				return !some(units, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId);
			});

			units.forEach(u => {
				if (!some(draft.skillsProfile.units, u2 => u2.unitOfCompetencyId === u.unitOfCompetencyId)) {
					if (u.elements.length > 0) u.elements[0].isSelected = true;
					draft.skillsProfile.units.push(u);
				}
			});

			checkDirty(draft);
			draft.promptSelection = false;
		}, state));

	const removeUnit = unitOfCompetencyId =>
		setState(produce(draft => {
			remove(draft.skillsProfile.units, u => {
				return u.unitOfCompetencyId === unitOfCompetencyId;
			});

			checkDirty(draft);
			draft.promptSelection = false;
		}, state));

	const selectElement = (unit, element, selected) => {
		setState(produce(draft => {
			const u = find(draft.skillsProfile.units, u2 => u2.unitOfCompetencyId === unit.unitOfCompetencyId);
			forEach(u.elements, e => {
				// Ignore select because we always want one element selected. If
				// they unselect an element this will ensure there is still one 
				// selected
				e.isSelected = e.unitElementId === element.unitElementId; 
			});
		}, state));
	};

	return <PageLayout title="Edit Skills Profile" pageContent={
		<React.Fragment>
			<Prompt when={state.dirty} message="You have unsaved changes. Are you sure you want to leave?" />	
			<SkillsProfile 
				skillsProfile={state.skillsProfile} 
				addUnits={addUnits}
				removeUnit={removeUnit}
				selectElement={selectElement}
				onSave={(skillsProfile, image) => {
					setState(prevState => ({ ...prevState, dirty: false }));
					props.saveSkillsProfile(skillsProfile, image);
				}}
				loading={props.loading}
				promptSelection={state.promptSelection}
				resetPromptSelection={() => setState({ ...state, promptSelection: false })}
				dirty={state.dirty}
				errorColour={props.errorColour}
			/>
		</React.Fragment>
	} breadcrumbs={[{ label: "Skills Profiles", path: "/admin/skills-profiles" }, "Edit Skills Profile"]} />;
};

SkillsProfileController.propTypes = { 
	loading: PropTypes.bool.isRequired,
	skillsProfile: PropTypes.object.isRequired,
	errorColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state),
	skillsProfile: state.skillsProfiles.skillsProfile,
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	getSkillsProfile: skillsProfileId => dispatch(getSkillsProfile(skillsProfileId)),
	saveSkillsProfile: (skillsProfile, image) => dispatch(saveSkillsProfile(skillsProfile, image)),
	showSaveNotification: () => dispatch(showWarningNotification("You have unsaved changes."))
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillsProfileController);
