import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/NoteAdd';
import uniqueId from 'lodash/uniqueId';

const IconUploadButton = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("upload-button-"));
	const { accept, disabled } = props;

	const onChange = e => {
		// Filter elements by accepted file extension
		props.onChange(!accept ? e.target.files : [...e.target.files].filter(f => {
			const ext = f.name.substring(f.name.lastIndexOf(".")).toLowerCase();
			return typeof accept === "string" ? ext === accept : accept.includes(ext);
		}));

		e.target.value = "";
	}

	return (
        <div style={{ display: "inline-block", marginLeft: 5 }}>
			<input
				id={id}
				multiple={props.multiple}
				type="file"
				accept={typeof accept === "string" ? accept : accept.join(",")}
				onChange={onChange}
				style={{ display: "none" }}
				disabled={disabled}
			/>
			<label htmlFor={id}>
				<IconButton
                    variant={props.variant}
                    component="span"
                    color={props.color}
                    disabled={disabled}
                    aria-label="Upload Button"
                    size="large">
					<UploadIcon />
				</IconButton>
			</label>
		</div>
    );
});

IconUploadButton.propTypes = {
	disabled: PropTypes.bool,
	variant: PropTypes.string,
	color: PropTypes.string,
	accept: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.string
	]),
	multiple: PropTypes.bool
};

IconUploadButton.defaultProps = {
	disabled: false,
	variant: "contained",
	color: "primary",
	accept: "",
	multiple: false
};

export default IconUploadButton;