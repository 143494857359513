import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { getProblemsByAuthor, isLoading } from '../../store/problems';
import UserProblems from '../problems/UserProblems';

const SubmissionsController = props => {
	React.useEffect(() => props.getProblems(props.currentUserId), []);

	return <PageLayout title="My submissions" pageContent={
		<UserProblems problems={props.problems} loading={props.loading} isOwn />
	} breadcrumbs={[{ label: "Profile", path: "/profile" }, "Submissions"]} />;
};

SubmissionsController.propTypes = {
	problems: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentUserId: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	problems: state.problems.problems,
	currentUserId: state.context.userId,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getProblems: authorId => dispatch(getProblemsByAuthor(authorId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionsController);
