import React from 'react';
import PropTypes from 'prop-types';

const ConditionalWrapper = React.memo(({ on, wrapper, children }) => on ? wrapper(children) : children);

ConditionalWrapper.propTypes = {
	on: PropTypes.bool.isRequired,
	wrapper: PropTypes.func.isRequired
};

export default ConditionalWrapper;
