import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import Button from '../widgets/Button';
import Form from '../widgets/Form';

const PasswordReset = props => {
	const { fields, handleSubmit, loading } = props;

	return (
		<div style={{ padding: "1.5rem 1.5rem 3rem" }}>
			<Form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					{/* Old Password */}
					<Grid item xs={12} sm={4}>
						{fields.oldPassword()}
					</Grid>
					{/* New Password */}
					<Grid item xs={12} sm={4}>
						{fields.newPassword()}
					</Grid>
					{/* Confirm Password */}
					<Grid item xs={12} sm={4}>
						{fields.cpassword()}
					</Grid>
				</Grid>
				<Button type="submit" loading={loading} style={{ marginTop: 20 }}>Update password</Button>
			</Form>
		</div>
	);
};

export default connect()(PasswordReset);