import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import { 
	getSkillsProfilesByTenant,
	isLoading
} from '../../store/skillsProfiles';
import SkillsProfiles from './SkillsProfiles';

const SkillsProfilesController = props => {
	React.useEffect(props.getSkillsProfilesByTenant, []);

	return <PageLayout title="Skills Profiles" pageContent={
		<SkillsProfiles skillsProfiles={props.skillsProfiles} loading={props.loading} />
	} breadcrumbs={["Skills Profiles"]} />;
};

SkillsProfilesController.propTypes = { 
	skillsProfiles: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	skillsProfiles: state.skillsProfiles.skillsProfiles,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getSkillsProfilesByTenant: () => dispatch(getSkillsProfilesByTenant())
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillsProfilesController);
