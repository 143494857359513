import { createFetchAction, createPostAction, createFormPostAction } from '../utils/reducer-utils';
import { addOrUpdate, toDate } from '../utils/utils';
import { createLoadingSelector } from './loading';
import { showErrorNotification, showSuccessNotification } from './notifications';
import { changeLocation } from './location';
import map from 'lodash/map';
import filter from 'lodash/filter';
import DOMPurify from 'dompurify';

const emptySaveResult = {
	success: null,
	message: null,
	fields: [] 
};

const initialState = {
	saveResult: emptySaveResult,
	feature: {},
	features: []
};

const CLEAR_FEATURE = "CLEAR_FEATURE";
const CLEAR_FEATURES = "CLEAR_FEATURES";
const GET_FEATURE_REQUEST = "GET_FEATURE_REQUEST";
const GET_FEATURE_SUCCESS = "GET_FEATURE_SUCCESS";
const GET_FEATURE_FAILURE = "GET_FEATURE_FAILURE";
const GET_FEATURES_REQUEST = "GET_FEATURES_REQUEST";
const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";
const GET_FEATURES_FAILURE = "GET_FEATURES_FAILURE";
const SAVE_FEATURE_REQUEST = "SAVE_FEATURE_REQUEST";
const SAVE_FEATURE_SUCCESS = "SAVE_FEATURE_SUCCESS";
const SAVE_FEATURE_FAILURE = "SAVE_FEATURE_FAILURE";
const DELETE_FEATURE_REQUEST = "DELETE_FEATURE_REQUEST";
const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS";
const DELETE_FEATURE_FAILURE = "DELETE_FEATURE_FAILURE";
const SAVE_FEATURE_ORDER_REQUEST = "SAVE_FEATURE_ORDER_REQUEST";
const SAVE_FEATURE_ORDER_SUCCESS = "SAVE_FEATURE_ORDER_SUCCESS";
const SAVE_FEATURE_ORDER_FAILURE = "SAVE_FEATURE_ORDER_FAILURE";

export const isLoading = createLoadingSelector(["GET_FEATURES", "GET_FEATURE", "DELETE_FEATURE", 
	"SAVE_FEATURE", "SEARCH_FEATURES", "GET_EXTERNAL_FEATURES", "SAVE_FEATURE_ORDER"]);

export const clearFeatures = () => ({ type: CLEAR_FEATURES });
export const clearFeature = () => ({ type: CLEAR_FEATURE });

export const getFeatures = location =>
	createFetchAction({
		url: `/api/features?location=${location || ""}`,
		startAction: GET_FEATURES_REQUEST,
		onError: error => [getFeaturesFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getFeaturesSuccess(map(data, e => ({ ...e, createDate: toDate(e.createDate) })))
	});

export const getFeaturesSuccess = data => ({ type: GET_FEATURES_SUCCESS, payload: { data } });
export const getFeaturesFailure = error => ({ type: GET_FEATURES_FAILURE, payload: { error } });

export const getFeature = featureId =>
	createFetchAction({
		url: `/api/features/${featureId}`,
		startAction: GET_FEATURE_REQUEST,
		onError: error => [getFeatureFailure(error), showErrorNotification(error.message)],
		onSuccess: data => getFeatureSuccess({ ...data, createDate: toDate(data.createDate) })
	});

export const getFeatureSuccess = data => ({ type: GET_FEATURE_SUCCESS, payload: { data } });
export const getFeatureFailure = error => ({ type: GET_FEATURE_FAILURE, payload: { error } });

export const saveFeature = (feature, files) => {
	const formData = new FormData();
	formData.append("Feature", encodeURIComponent(JSON.stringify({ 
		...feature, 
		links: JSON.stringify(feature.links), 
		videos: JSON.stringify(feature.videos)
	})));

	if (files.imageUrl) formData.append("ImageFile", files.imageUrl);
	if (files.articleImage) formData.append("ArticleImage", files.articleImage);
	files.attachments.forEach(a => formData.append("Attachments", a));

	return createFormPostAction({
		url: "/api/features",
		data: formData,
		startAction: SAVE_FEATURE_REQUEST,
		onError: error => [saveFeatureFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.object) {
				data.object.createDate = toDate(data.object.createDate);

				return [saveFeatureSuccess(data), showSuccessNotification(data.message), changeLocation(`/features/edit/${data.object.featureId}`)];
			} else {
				return [saveFeatureSuccess(data), showErrorNotification(data.message)];
			}
		}
	});
};

export const saveFeatureSuccess = data => ({ type: SAVE_FEATURE_SUCCESS, data });
export const saveFeatureFailure = error => ({ type: SAVE_FEATURE_FAILURE, error });

export const deleteFeature = featureId =>
	createPostAction({
		url: `/api/features/${featureId}/delete`,
		startAction: DELETE_FEATURE_REQUEST,
		onError: error => [deleteFeatureFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [deleteFeatureSuccess(data), showSuccessNotification(data.message), changeLocation("/features")];
			} else {
				return [deleteFeatureSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const deleteFeatureSuccess = data => ({ type: DELETE_FEATURE_SUCCESS, data });
export const deleteFeatureFailure = error => ({ type: DELETE_FEATURE_FAILURE, error });

export const saveFeatureOrder = order =>
	createFormPostAction({
		url: `/api/features/save-order?order=${order.join(",")}`,
		startAction: SAVE_FEATURE_ORDER_REQUEST,
		onError: error => [saveFeatureOrderFailure(error), showErrorNotification(error.message)],
		onSuccess: data => {
			if (data && data.success) {
				return [saveFeatureOrderSuccess(data), showSuccessNotification(data.message)];
			} else {
				return [saveFeatureOrderSuccess(data), showErrorNotification(data.message)];
			}
		}
	});

export const saveFeatureOrderSuccess = data => ({ type: SAVE_FEATURE_ORDER_SUCCESS, data });
export const saveFeatureOrderFailure = error => ({ type: SAVE_FEATURE_ORDER_FAILURE, error });

const formatfeature = feature => ({ 
	...feature,
	links: feature.links ? JSON.parse(feature.links) : [],
	videos: feature.videos ? JSON.parse(feature.videos) : [],
	articleContent: feature.featureType !== "Google Form" ? DOMPurify.sanitize(feature.articleContent)  : feature.articleContent
});

export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_FEATURES:
			return { ...state, features: [] };
		case CLEAR_FEATURE:
			return { ...state, feature: {} };
		case GET_FEATURES_REQUEST:
			return { ...state, saveResult: emptySaveResult, features: [] };
		case GET_FEATURES_SUCCESS:
			return { ...state, features: map(action.payload.data, formatfeature), isLoading: false };
		case GET_FEATURE_REQUEST:
			return {
				...state,
				feature: {},
				isLoading: true,
				saveResult: emptySaveResult
			};
		case GET_FEATURE_SUCCESS:
			return { ...state, feature: formatfeature(action.payload.data), isLoading: false };
		case DELETE_FEATURE_SUCCESS:
			return { ...state, features: filter(state.features, c => c.featureId !== action.data.objectId) };
		case SAVE_FEATURE_REQUEST:
		case SAVE_FEATURE_ORDER_REQUEST:
			return { ...state, isLoading: true };
		case SAVE_FEATURE_SUCCESS:
			const { success, message, fields, object } = action.data;	
			
			return {
				...state,
				...(success && { features: addOrUpdate(state.features, formatfeature(object), { featureId: object.featureId }) }), 
				isLoading: false,
				saveResult: { success, message, fields }
			};
		case SAVE_FEATURE_ORDER_SUCCESS:
			return {
				...state,
				...(action.data.success && { features: map(action.data.object, formatfeature) }), 
				isLoading: false
			};
		case SAVE_FEATURE_ORDER_FAILURE:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};