import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import round from 'lodash/round';

const CompetencyPercentage = (props) => {
	const { organisationId, setLoading, onChangeDepartment, colourPalette } = props;
	const [data, setData] = React.useState([]);
	const [departmentId, setDepartmentId] = React.useState(0);
	const by = departmentId > 0 ? 'position' : 'department';

	const loadData = (depId) => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/dashboard/competency-percentage?organisationId=${organisationId}&departmentId=${depId}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		setDepartmentId(props.departmentId);
		if (organisationId) {
			loadData(props.departmentId);
		}
	}, [organisationId, props.departmentId]);

	return (
		<Chart 
			toolbox={{
				myTool2: {
					show: (departmentId > 0),
					title: 'Drill Up',
					icon: 'path://M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z',
					onclick: () => {
						if (onChangeDepartment) {
							onChangeDepartment(0);
						} else if (departmentId > 0) {
							setDepartmentId(0);
							loadData(0);
						}
					}
				}
			}}
			option={{
				color: colourPalette,
				legend: {},
				tooltip: {
					trigger: 'item',
					formatter: params => `${params.name}: ${round(params.data[0], 1)}%<br />Units: ${params.data[2].unitCount}`
				},
				grid: { containLabel: true },
				yAxis: {
					type: "category", 
					data: data.map(d => d[by])
				},
				xAxis: {
					type : "value",
					axisLabel: { formatter: "{value}%" }
				},
				series: [
					{
						type: "bar", 
						stack: "1", 
						name: "Meets Target %", 
						data: data.map(d => [round(d.competent / d.unitCount * 100, 2), d[by], d])
					},
					{
						type: "bar", 
						stack: "1", 
						name: "Below Target %", 
						data: data.map(d => [100 - round((d.competent / d.unitCount * 100), 2), d[by],  d])
					}
				]
			}}
			style={{ height: "100%" }} 
			onEvents={{
				"click": by !== "department" ? undefined : param => {
					if (onChangeDepartment) {
						onChangeDepartment(param.data[2].departmentId);
					} else if (departmentId === 0) {
						setDepartmentId(param.data[2].departmentId);
						loadData(param.data[2].departmentId);
					}
				}
			}}
		/>
	);
};

CompetencyPercentage.propTypes = {
	organisationId: PropTypes.number.isRequired,
	departmentId: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired,
	onChangeDepartment: PropTypes.func,
	colourPalette: PropTypes.array
};

CompetencyPercentage.defaultProps = {
	onChangeDepartment: null,
	colourPalette: undefined
};

export default CompetencyPercentage;
