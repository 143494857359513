import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { favouriteCLO_Course, unfavouriteCLO_Course } from '../../store/cloCourses';

const FavouriteButton = React.memo(props => {
	const { isFavourite, isActive, primaryColour } = props,
		Icon = isFavourite ? StarIcon : StarBorderIcon;

	return (
		<Icon 
			onClick={() => !isActive && props.favourite()} 
			style={{ 
				color: primaryColour, 
				cursor: "pointer", 
				position: 'absolute',
				top: '14px',
				right: '5px'
			}}
		/>
	);
});

FavouriteButton.propTypes = {
	clO_CourseId: PropTypes.number.isRequired,
	isFavourite: PropTypes.bool.isRequired,
	isActive: PropTypes.bool.isRequired,
	favourite: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	isActive: state.cloCourses.isFavouriting[ownProps.clO_CourseId] || false,
	primaryColour: state.theme.primaryColour
});	

const mapDispatchToProps = (dispatch, ownProps) => ({
	favourite: () => dispatch(ownProps.isFavourite ? unfavouriteCLO_Course(ownProps.clO_CourseId) : favouriteCLO_Course(ownProps.clO_CourseId))
});

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteButton);
