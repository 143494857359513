import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getChannels } from '../../store/channels';
import { saveDiscussion } from '../../store/discussion';
import { getResource, isLoading } from '../../store/resources';
import { canEditResource, canEditCommunityResource } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewResource from './ViewResource';

const ViewResourceController = props => {
	React.useEffect(() => props.getResource(props.match.params.resourceId), [props.match.params.resourceId]);

	React.useEffect(props.getChannels, []);

	return <PageLayout title="View resource" pageContent={
		<ViewResource 
			resource={props.resource} 
			loading={props.loading} 
			canEdit={props.canEdit} 
			isCommunity={props.isCommunity} 
			channels={props.channels}
			saveDiscussion={props.saveDiscussion}
			primaryColour={props.primaryColour}
			secondaryColour={props.secondaryColour}
		/>
	} breadcrumbs={[{ label: "Resources", path: `/${props.isCommunity ? "my-community/" : ""}resources` }, "View resource"]} />;
};

ViewResourceController.propTypes = {
	resource: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	channels: PropTypes.array.isRequired,
	getChannels: PropTypes.func.isRequired,
	saveDiscussion: PropTypes.func.isRequired,
	primaryColour: PropTypes.string.isRequired,
	secondaryColour: PropTypes.string.isRequired
};

ViewResourceController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	resource: state.resources.resource,
	loading: isLoading(state),
	canEdit: ownProps.isCommunity ? canEditCommunityResource(state) : canEditResource(state),
	channels: state.channels.channels,
	primaryColour: state.theme.primaryColour,
	secondaryColour: state.theme.secondaryColour
});

const mapDispatchToProps = dispatch => ({
	getResource: resourceId => dispatch(getResource(resourceId)),
	getChannels: () => dispatch(getChannels()),
	saveDiscussion: discussion => dispatch(saveDiscussion(discussion))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewResourceController);