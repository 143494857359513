import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import { truncateString, stripHtml } from '../../utils/utils';
import FeatureBox from '../widgets/FeatureBox';
import MediaCard from '../widgets/MediaCard';

const ribbonSwitch = {
	External: "warning",
	Internal: "info",
	None: "default"
};

const itemSource = {
	beginDrag: props => ({ index: props.index }),
	canDrag: props => props.canDrag
};
  
const itemTarget = {
	hover(props, monitor) {
		const { index: dragIndex } = monitor.getItem();

		// Swap position if hover target index is different
		if (dragIndex !== props.index) {
			props.moveItem(dragIndex, props.index);

			// Update index position of drag item in monitor
			monitor.getItem().index = props.index;
		}
	}
};

const DragFeature = React.memo(props => {
	const { feature: f, canEdit, connectDragSource, connectDropTarget } = props;
	const contentOnly = f.featureType === "Article" && f.articleContentOnly;

	return !props.reorder ? <FeatureBox feature={f} canEdit={canEdit} /> : connectDragSource(connectDropTarget(
		<div 
			className="drag-feature"
			style={{ opacity: props.isDragging ? 0.5 : 1 }} 
			onMouseEnter={() => props.setDrag(true)} 
			onMouseLeave={() => props.setDrag(false)}
		>
			<MediaCard
				className="feature-box"
				title={f.title} 
				image={contentOnly ? undefined : f.imageUrl}
				ribbon={f.location}
				ribbonStatus={ribbonSwitch[f.location]}
			>
				{contentOnly ? <div className="quill-display-block" dangerouslySetInnerHTML={{ __html: f.articleContent }} /> : 
					<p>{truncateString(stripHtml(f.description), 150)}</p>
				}
			</MediaCard>
		</div>
	));
});

DragFeature.props = {
	feature: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	addItem: PropTypes.func.isRequired,
	moveItem: PropTypes.func.isRequired,
	setDrag: PropTypes.func.isRequired,
	canDrag: PropTypes.bool.isRequired,
	reorder: PropTypes.bool.isRequired
};

const connectTarget = connect => ({
	connectDropTarget: connect.dropTarget()
});
  
const connectSource = (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
});

/* eslint-disable new-cap */
export default DropTarget("dragItem", itemTarget, connectTarget)(
	DragSource("dragItem", itemSource, connectSource)(DragFeature)
);