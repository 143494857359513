import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';
import { canSubmitActivities, canSubmitTools } from '../../store/security';
import { changeLocation } from '../../store/location';
import { post } from '../../utils/ajax';
import { emptySaveResult } from '../../variables';
import PageLayout from '../PageLayout';
import LDCourse from './LDCourse';
import forEach from 'lodash/forEach';

const defaultCourse = {
	code: "",
	title: "",
	description: "",
	objectives: [],
	tools: [],
	resources: [],
	sessions: [{ name: "Session 1", time: "60min", tools: [], activities: [], resources: [] }]
};

const CreateLDCourseeController = props => {
	const [state, setState] = React.useState({ isLoading: false, saveResult: emptySaveResult });

	const saveCourse = (ldCourse, attachments) => {
		setState(prevState => ({ ...prevState, isLoading: true }));
		
		const formData = new FormData();
		formData.append("Course", encodeURIComponent(JSON.stringify(ldCourse)));
		forEach(attachments || [], a => formData.append("Attachments", a.file, a.clientId));

		post({
			url: "/api/ld-courses",
			data: formData,
			onSuccess: data => {
				setState({ saveResult: data, isLoading: false });

				if (data.success) {
					props.showSuccessNotification(data.message);
					props.goToEditUrl(data.object);
				} else {
					props.showErrorNotification(data.message);
				}
			},
			onError: error => {
				setState(prevState => ({ ...prevState, isLoading: false }));
				props.showErrorNotification(error.message);
			}
		});
	};

	return <PageLayout title="Add L&amp;D Course" pageContent={
		<LDCourse 
			canEdit
			ldCourse={defaultCourse}
			onSave={saveCourse} 
			saveResult={state.saveResult}
			loading={state.isLoading}
			canSubmitActivities={props.canSubmitActivities}
			canSubmitTools={props.canSubmitTools}
			isNew
		/>
	} breadcrumbs={[{ label: "L&D Courses",  path: "/course-design" }, "Add L&D Course"]} />;
};

CreateLDCourseeController.propTypes = { 
	isGlobalAdministrator: PropTypes.bool.isRequired,
	tenantId: PropTypes.number.isRequired,
	goToEditUrl: PropTypes.func.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired,
	canSubmitActivities: PropTypes.bool.isRequired,
	canSubmitTools: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	isGlobalAdministrator: state.context.isGlobalAdministrator,
	tenantId: state.context.tenantId,
	canSubmitActivities: canSubmitActivities(state),
	canSubmitTools: canSubmitTools(state)
});

const mapDispatchToProps = dispatch => ({
	goToEditUrl: ldCourse => dispatch(changeLocation(`/course-design/${ldCourse.lD_CourseId}`)),
	showErrorNotification: message => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLDCourseeController);
