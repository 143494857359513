import React from 'react';
import Card from '../widgets/Card';
import Grid from '@mui/material/Grid';
import Image1 from '../images/tenant/demo/Screen Shot 2020-02-22.png';
import Image2 from '../images/tenant/demo/Screen Shot 2020-02-22[1].png';

const DemoContent = props =>
	<Card title={props.title} shrink>
		<Grid container spacing={3} justifyContent="space-between" alignItems="flex-end">
			<Grid item xs={12} md={6}>
				<img 
					src={Image1}
					alt=""
					style={{ width: '100%' }}
				/>
			</Grid>
			<Grid item xs={12} sm={8} md={6}>
				<img 
					src={Image2}
					alt=""
					style={{ width: '100%' }}
				/>
			</Grid>
		</Grid>
			
	</Card>
;

DemoContent.propTypes = {
};

DemoContent.defaultProps = {
	title: "Demo Content"
};

export default DemoContent;
