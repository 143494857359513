import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CompareIcon from '@mui/icons-material/Compare';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { truncateString } from '../../utils/utils';
import Tag from '../widgets/Tag';

const iconSwitch = {
	Video: OndemandVideoIcon,
	Article: DescriptionIcon,
	"Case Study": AssignmentIcon,
	Template: CompareIcon
};

const ResourceRow = React.memo(({ resource: r }) => 
	<div className="resource-row">
		<Link className="h4 name" to={`/my-community/resources/${r.resourceId}`}>
			{iconSwitch[r.type] ? React.createElement(iconSwitch[r.type]) : ""}
			{r.name}
		</Link>
		{r.academicId &&
			<span className="h6 academic">
				<Tooltip title="Academic Author"><VerifiedUserIcon /></Tooltip>
				{r.academicName}
			</span>
		}
		<span className="label">{r.channel}</span>
		<div className="description">{truncateString(r.description, 250)}</div>
		<div className="tags">
			{r.tags.map(t => <Tag key={t.tagId} label={t.name} />)}
		</div>
	</div>
);

ResourceRow.propTypes = {
	resource: PropTypes.object.isRequired
};

export default ResourceRow;