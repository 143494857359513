import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import EditMentee from './EditMentee';
import { isLoading, saveMentee } from '../../store/mentees';

const CreateMenteeController = props => 
	<PageLayout title="Create Mentee" pageContent={
		<EditMentee saveMentee={props.saveMentee} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Mentees", path: `/mentees` }, "Create Mentee"]} />;

CreateMenteeController.propTypes = {
	saveMentee: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveMentee: mentee => dispatch(saveMentee(mentee))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMenteeController);