import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import Button from '../widgets/Button';
import { withForm } from '../../utils/forms';

const form = {
	fields: [
		{
			name: "title",
			label: "Course title",
			required: true
		},
		{
			name: "include",
			type: "checkbox",
			label: "Include competencies"
		}
	]
}

const CopyCourseDialog = props => 
	<Dialog
        open={props.open}
        onClose={props.onClose}
        TransitionProps={{
            onEntering: () => props.updateValues({ title: props.title, include: false })
        }}>
		<DialogTitle>Copy course</DialogTitle>
		<DialogContent>
			<Grid container spacing={3}>
				<Grid item xs={12}>{props.fields.title()}</Grid>
				<Grid item xs={12}>{props.fields.include()}</Grid>
			</Grid>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.onClose}>Cancel</Button>
			<Button onClick={() => props.copyCourse(props.values.title, props.values.include)}>Confirm</Button>
		</DialogActions>
	</Dialog>;

CopyCourseDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	copyCourse: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default withForm(form)(CopyCourseDialog);
