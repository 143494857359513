import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';

const MediaCard = React.memo(props => {
	const { className, title, link, image, ribbon, ribbonStatus, placeholder, loading } = props,
		imageComponent = <img className="pcard-img-top img-responsive" src={image} alt={placeholder} style={{ ...props.imageStyle }} />,
		titleComponent = <span className={classNames("h4 pcard-title", { "text-themecolor": Boolean(link), "text-link": Boolean(link) })}>{title}</span>;
	
	return (
		<div className={`pcard ${className}`} style={{ flexShrink: 0, ...props.style }} onClick={props.onClick}>
			{props.rootContent || undefined}
			{ribbon && <div className={`ribbon ribbon-bookmark ribbon-${ribbonStatus}`} onClick={props.ribbonOnClick}>{ribbon}</div>}
			{image && (link ? <Link to={link} style={props.linkStyle}>{imageComponent}</Link> : imageComponent)}
			<div className="pcard-body" style={{ marginTop: (ribbon && !image) ? 35 : 0, ...props.bodyStyle }}>
				{title && (link ? <Link to={link}>{titleComponent}</Link> : title !== "" && titleComponent)}
				{loading ? <CircularProgress size={24} /> : props.children}
			</div> 
		</div>
	);
});

MediaCard.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	link: PropTypes.string,
	image: PropTypes.string,
	placeholder: PropTypes.string,
	ribbon: PropTypes.node,
	ribbonStatus: PropTypes.string,
	ribbonOnClick: PropTypes.func,
	loading: PropTypes.bool,
	children: PropTypes.node.isRequired,
	bodyStyle: PropTypes.object,
	style: PropTypes.object,
	linkStyle: PropTypes.object,
	imageStyle: PropTypes.object,
	onClick: PropTypes.func
};

MediaCard.defaultProps = {
	className: "",
	title: "",
	link: "",
	image: "",
	placeholder: "",
	ribbon: "",
	ribbonStatus: "default",
	ribbonOnClick: undefined,
	loading: false,
	bodyStyle: null,
	style: null,
	linkStyle: null,
	imageStyle: null,
	onClick: undefined
};

export default MediaCard;
