import React from 'react';
import PropTypes from 'prop-types';

const StatBox = React.memo(props =>
	<div className={props.boxClass}>
		<div className="card card-inverse card-info" style={{ background: props.colour, padding: 0 }}>
			<div className="box bg-info text-center" style={{ padding: 10 }}>
				<h1 className="font-light text-white">{props.number}</h1>
				<span className="h6 text-white">{props.text}</span>
			</div>
		</div>
	</div>
);

StatBox.propTypes = {
	text: PropTypes.string.isRequired,
	number: PropTypes.number,
	colour: PropTypes.string,
	boxClass: PropTypes.string
};

StatBox.defaultProps = {
	number: 0,
	colour: "#2f3d4a",
	boxClass: "col-md-6 col-lg-3 col-xlg-3"
};

export default StatBox;