import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import Card from '../widgets/Card';
import ViewOffer from '../offers/ViewBusinessChallenge';
import Button from '../widgets/Button';

const form = {
	initValues: props => ({ offerId: props.offer.offerId, attachments: [], ...props.application }),
	fields: [
		{
			name: "solution",
			label: "Research Match Response",
			required: true,
			type: "textEditor"
		},
		{
			name: "stage",
			label: "Solution Stage",
			type: "select",
			items: ["Idea", "Prototype", "Product"]
		},
		{
			name: "attachments",
			type: "attachmentEditor",
			download: attachment =>
				window.open(`/Application/DownloadAttachment?applicationId=${attachment.applicationId}&applicationAttachmentId=${attachment.applicationAttachmentId}`, "_self")
		},
		{
			name: "additionalChallengeDetails",
			label: "Additional Match Details",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Application/DownloadAdditionalChallengeDetails?applicationId=${attachment.applicationId}&additionalChallengeDetailsId=${attachment.applicationAdditionalChallengeDetailsId}`, "_self")
		},
		{
			name: "nonDisclosureAgreement",
			label: "Non Disclosure Agreement",
			type: "attachmentEditor",
			download: attachment => 
				window.open(`/Application/DownloadNonDisclosureAgreement?applicationId=${attachment.applicationId}&nonDisclosureAgreementId=${attachment.applicationNonDisclosureAgreementId}`, "_self")
		}
	]
};

class NewApplication extends React.Component {
	saveApplication = submit => {
		if (this.props.validateFields()) {
			const props = [
				this.props.values, 
				this.props.formFiles
			];

			if (submit) {
				this.props.submitApplication(...props);
			} else {
				this.props.saveApplication(...props);
			}
		}
	}

	render() {
		const { offer, loading, fields } = this.props;

		return (
			<div className="application-form">
				<Card header="You are responding to the following research match">
					<ViewOffer loading={loading} offer={offer} preview />
				</Card>
				<Card header="Proposed Response">
					<h5>Enter your Response</h5>
					<p>Enter a summary of your response to this research match, for greater detail you can also attach files (pdf, doc) to this application.</p>
					<p>Please note this is your response to how you can assist with developing a solution OR offering an existing solution</p>
					{fields.solution()}
				</Card>
				<Card header="Attach any PDF or documents to help explain and support your response.">
					{fields.attachments()}
				
					{fields.additionalChallengeDetails()}
					{fields.nonDisclosureAgreement()}
				</Card>
				
				<Button onClick={this.saveApplication} color="secondary" style={{ marginRight: 10 }}>
					Save as Draft
				</Button>
				<Button onClick={() => this.saveApplication(true)}>
					Submit Response
				</Button>
			</div>
		);
	}
};

NewApplication.propTypes = { 
	application: PropTypes.object.isRequired,
	offer: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	saveApplication: PropTypes.func.isRequired,
	submitApplication: PropTypes.func.isRequired
};

export default withForm(form)(NewApplication);
