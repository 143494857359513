import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMentee, isLoading } from '../../store/mentees';
import { canEditMentee } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewMentee from './ViewMentee';

const ViewMenteeController = props => {
	React.useEffect(() => props.getMentee(props.match.params.menteeId), [props.match.params.menteeId]);

	return <PageLayout title="View mentee" pageContent={
		<ViewMentee mentee={props.mentee} loading={props.loading} canEdit={props.canEdit} />
	} breadcrumbs={[{ label: "Mentees", path: "/mentees" }, "View mentee"]} />;
};

ViewMenteeController.propTypes = {
	mentee: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	mentee: state.mentees.mentee,
	loading: isLoading(state),
	canEdit: canEditMentee(state)
});

const mapDispatchToProps = dispatch => ({
	getMentee: menteeId => dispatch(getMentee(menteeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMenteeController);