import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveFeature, isLoading } from '../../store/features';
import PageLayout from '../PageLayout';
import EditFeature from './EditFeature';

const CreateFeatureController = props => 
	<PageLayout title="Add Feature" pageContent={
		<EditFeature saveFeature={props.saveFeature} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Features", path: "/features" }, "Add Feature"]} />;

CreateFeatureController.propTypes = {
	saveFeature: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveFeature: (feature, files) => dispatch(saveFeature(feature, files))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFeatureController);