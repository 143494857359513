import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBusinessProfile, saveBusinessProfile, deleteBusinessProfile, isLoading } from '../../store/businessProfiles';
import { showModal } from '../../store/modal';
import PageLayout from '../PageLayout';
import EditBusinessProfile from './EditBusinessProfile';

const EditBusinessProfileController = props => {
	const { profileId } = props.match.params;
	React.useEffect(() => {
		props.getProfile(profileId);
	}, [profileId]);

	return <PageLayout title="Edit business profile" pageContent={
		<EditBusinessProfile
			profile={props.profile}
			isOwn={profileId === 'own'}
			userOrgId={props.userOrgId}
			saveProfile={props.saveProfile}
			deleteProfile={props.deleteProfile}
			loading={props.loading}
			isSubscribed={props.isSubscribed}
		/>
	} breadcrumbs={[{ label: "Business profiles", path: "/business-profiles" }, "Edit business profile"]} />;
};

EditBusinessProfileController.propTypes = {
	profile: PropTypes.object.isRequired,
	getProfile: PropTypes.func.isRequired,
	saveProfile: PropTypes.func.isRequired,
	deleteProfile: PropTypes.func.isRequired,
	isSubscribed: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	profile: state.businessProfiles.profile,
	isSubscribed: state.context.isSubscribed,
	loading: isLoading(state),
	userOrgId: state.context.organisationId
});

const mapDispatchToProps = dispatch => ({
	getProfile: profileId => dispatch(getBusinessProfile(profileId)),
	saveProfile: (profile, logoFile) => dispatch(saveBusinessProfile(profile, logoFile)),
	deleteProfile: profileId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this profile?",
		onOk: () => dispatch(deleteBusinessProfile(profileId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBusinessProfileController);
