import React from 'react';
import PropTypes from 'prop-types';
import Chart from '../widgets/Chart';
import { get } from '../../utils/ajax';
import floor from 'lodash/floor';

const PrimaryProfile = ({ userId, setLoading, loading, colourPalette }) => {
	const [data, setData] = React.useState([]);
	const [priority, setPriority] = React.useState(null);

	const loadData = (p) => {
		setLoading(true);
		setData([]);
		get({
			url: `/api/competencies/${userId}/competencies-for-profile-by-priority${p? `?priority=${p}` : ''}`,
			onSuccess: data => {
				setLoading(false);
				setData(data);
			},
			onError: () => {
				setLoading(false);
			}
		});
	};

	React.useEffect(() => {
		if (userId) {
			loadData(priority);
		}
	}, [userId]);

	let chartData = [];
	if (priority) {
		chartData = data.map(d => ({
			...d,
			label: `${d.unitCode} - ${d.unitTitle}`,
			competentPercent: d.competent > 0 ? 100 : 0,
			notCompetentPercent: d.competent > 0 ? 0 : 100
		}));
	} else {
		chartData = data.map(d => ({
			...d,
			label: `${data.length > 1 ? `${d.priority}\nPriority` : "My Competencies"} (${d.unitCount})`,
			priority: d.priority,
			competentPercent: d.unitCount ? d.competent / d.unitCount * 100 : 100,
			notCompetentPercent: d.unitCount ? 100 - (d.competent / d.unitCount * 100) : 0
		}));
	}

    if (!loading && chartData.length === 0) {
        chartData = [
            {
                label: 'My Competencies',
                competentPercent: 100,
                notCompetentPercent: 0
            }
        ]
    }

	return (
		<Chart 
			toolbox={{
				myTool2: {
					show: !!priority,
					title: 'Drill Up',
					icon: 'path://M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z',
					onclick: () => {
						setPriority(null);
						loadData(null);
					}
				}
			}}
			option={{
				color: colourPalette,
				legend: {},
				tooltip: {
					formatter: params => {
						if (priority) {
							return `${params.name}<br />${params.data[2].competent ? 'Competent' : 'Not Competent'}`;
						} else {
							return `${params.name}: ${floor(params.data[0])}%<br />Units: ${params.data[2].unitCount}`;
						}
					}
				},
				grid: { containLabel: true },
				yAxis: {
					type: "category", 
					data: chartData.map(d => d.label)
				},
				xAxis: {
					type : "value",
					axisLabel: { formatter: "{value}%" }
				},
				series: [
					{ type: "bar", stack: "1", name: "Meets Target %", data: chartData.map(d => [d.competentPercent, d.label, d]) },
					{ type: "bar", stack: "1", name: "Below Target %", data: chartData.map(d => [d.notCompetentPercent, d.label, d]) }
				]
			}}
			style={{ height: "100%" }} 
			onEvents={{
				"click": param => {
					if (priority === null) {
						setPriority(param.data[2].priority);
						loadData(param.data[2].priority);
					}
				}
			}}
		/>
	);
};

PrimaryProfile.propTypes = {
	userId: PropTypes.string.isRequired,
	setLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
	colourPalette: PropTypes.array
};

PrimaryProfile.defaultProps = {
    loading: false,
	colourPalette: undefined
};

export default PrimaryProfile;
