import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import Avatar from '../widgets/Avatar';
import Card from '../widgets/Card';

const MenteeCard = React.memo(({ mentee: m }) => 
	<Card className="mentee-card">
		<Link to={`/mentees/${m.menteeId}`} className="h3">
			<Avatar src={m.avatar} fullName={m.fullName} style={{ marginRight: 5 }} />
			{m.fullName}
		</Link>
		<span className="institution">
			{m.institutionLogo && <img src={m.institutionLogo} alt={`${m.institutionName} Logo`} style={{ height: 32, marginRight: 5 }} />}
			<strong>{m.institutionName}</strong>
		</span>
	</Card>
);

MenteeCard.propTypes = {
	mentee: PropTypes.object.isRequired
};

export default MenteeCard;