import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMenu, saveMenu, deleteMenu, isLoading } from '../../../store/menu';
import { showModal } from '../../../store/modal';
import PageLayout from '../../PageLayout';
import EditMenu from './EditMenu';

const EditMenuController = props => {
	React.useEffect(() => props.getMenu(props.match.params.menuId), [props.match.params.menuId]);

	return <PageLayout title="Edit menu" pageContent={
		<EditMenu
			menu={props.menu}
			saveMenu={props.saveMenu}
			deleteMenu={props.deleteMenu}
			loading={props.loading}
		/>
	} breadcrumbs={[{ label: "Menus", path: "/admin/menu" }, "Edit menu"]} />;
};

EditMenuController.propTypes = {
	menu: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	getMenu: PropTypes.func.isRequired,
	saveMenu: PropTypes.func.isRequired,
	deleteMenu: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	menu: state.menu.menu,
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	getMenu: menuId => dispatch(getMenu(menuId)),
	saveMenu: menu => dispatch(saveMenu(menu)),
	deleteMenu: menuId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this menu?",
		onOk: () => dispatch(deleteMenu(menuId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMenuController);
